import React          from "react";
import { withRouter } from "react-router-dom";
import { Link }       from "react-router-dom";

import { API_ROOT, HEADERS } from "../../constants/";

import ItemFetcher    from "../../lib/ItemFetcher";
import Linkedable     from "../shared/Linkedable";
import mapsData       from "../../data/maps";
import Collapsible    from "../shared/Collapsible"
import ShopTable      from '../locations/ShopTable';

class InfoPage extends React.Component {
  constructor(props) {
    super(props);
    this.mapImage = "https://drive.google.com/uc?id=1MpKes32FfAQkuH_UbjjvjBOzwzZHIsvT"; //make a constant
    this.fetcher = this.setFetcher();

    this.state = {
      keyed: this.fetchItem(),
      image: 0
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.keyed !== prevProps.keyed) {
      this.fetcher = this.setFetcher();
      this.setState({keyed: this.fetchItem(this.props.keyed)})
    }
  }

  setFetcher() {
    return new ItemFetcher(this.props.keyed);
  }

  fetchItem(keyed) {
    const res = this.fetcher.fetch();

    if(!res) {
      // uggggh seriously make this model agnostic
      // this.getCharacters(keyed);
    }
    else {
      return res;
    }
  }

  collapseToggle(kind) {
    this.state.show === kind ? this.setState({show: ""}) : this.setState({show: kind})
  }

  getCharacters(character) {
    // Abstract out to something more Character specific
    fetch(
      `${API_ROOT}/characters`,
      {
        headers: HEADERS
      }
    ).then(res => res.json()
    ).then((res) => {
      this.setState({keyed: res.characters[character]})
    });
  }

  fieldShowOrNot(label, field, isLinkable) {
    if(this.state.keyed[field]) {
      let renderedText = this.state.keyed[field];

      if(isLinkable) {
        renderedText = <Linkedable body={ this.state.keyed[field]}/>
      }

      return this.labeled(label, renderedText)
    }
  }

  labeled(label, text) {
    return (
      <div className="labeled">
        <div className="left-label">
          <strong>
            {label}:&nbsp;
          </strong>
        </div>

        <div className="right-blurb">
          {text}
        </div>
      </div>
    )
  }

  selectImage() {
    let founded;

    if(this.state.keyed.src === "use_map"){
      founded = mapsData["First World"].Primavera.points.find((point) => {
        return point.label === this.state.keyed.name
      })
    }

    if(founded || this.state.keyed.label) {
      return this.mapImage;
    }
    else {
      return this.state.keyed.src;
    }
  }

  getStores() {
    return this.fetcher.shopsFromParent();
  }

  render() {
    if(!this.state.keyed) {
      return '';
    }
    else {
      const srcImage = this.selectImage();

      const stores = this.getStores() || [];

      return (
        <div className="standard-panel">
          <div className="most-a-panel">
            <h1>
              {this.state.keyed.name || this.state.keyed.label}
            </h1>
            <div className="character-info">
              {this.state.keyed.tagLine}

              {
                this.state.keyed.beliefs &&
                <>
                  <h2>
                    Beliefs
                  </h2>

                  {
                    this.state.keyed.beliefs &&
                    <ol>
                      {
                        this.state.keyed.beliefs.map((belief, indy) => {
                          return (
                            <li key={indy}>
                              {belief}
                            </li>
                          )
                        })
                      }
                    </ol>
                  }
                </>
              }

              {
                this.state.keyed.goals &&
                <>
                  <h2>
                    Goals
                  </h2>

                  <Linkedable body={this.state.keyed.goals}/>
                </>
              }

              <h2>
                Background
              </h2>

              <Linkedable body={this.state.keyed.blurb}/>

              {
                this.state.keyed.commandments && this.state.keyed.commandments[0] &&
                <>
                  <h2>
                    Commandments of {this.state.keyed.divineTagLine}:
                  </h2>

                    <ul>
                      {
                        this.state.keyed.commandments.map((member, indy) => {
                          return (
                            <li key={indy}>
                              <Linkedable body={member}/>
                            </li>
                          )
                        })
                      }
                    </ul>
                </>
              }

              {
                this.state.keyed.piety && this.state.keyed.piety[0] &&
                <>
                  <h2>
                    Boons for Piety of {this.state.keyed.divineTagLine}:
                  </h2>

                    <ul>
                      <li>
                        <strong>Devotee:</strong> <Linkedable body={this.state.keyed.piety[0] || "???"}/>
                      </li>
                      <li>
                        <strong>Votary:</strong> <Linkedable body={this.state.keyed.piety[1] || "???"}/>
                      </li>
                      <li>
                        <strong>Disciple:</strong> <Linkedable body={this.state.keyed.piety[2] || "???"}/>
                      </li>
                      <li>
                        <strong>Champion:</strong> <Linkedable body={this.state.keyed.piety[3] || "???"}/>
                      </li>
                    </ul>
                </>
              }

              {
                stores[0] &&
                <>
                  <h2>
                    Sub Locations
                  </h2>
                  {
                    stores.map((store, indy) => {
                      return (
                        <ul>
                          <li>
                            <Link to={`/pages/${store.name}`}>
                              {store.name}
                            </Link>
                          </li>
                        </ul>
                      )
                    })
                  }
                </>
              }

              <h2>
                Recent Events
              </h2>

              <Linkedable body={this.state.keyed.recentEvents}/>
            </div>
          </div>

          <div className="side-panel">
            {
              this.state.image === 0 &&
              srcImage === this.mapImage &&
              <div className={"prima-map-key"}>
                <span
                  className={"prima-inset-key"}
                  style={{
                    top: `${this.state.keyed.top - 425}px`, // 300
                    left: `${this.state.keyed.left - 305}px`, // 100
                  }}
                >

                </span>
                <img
                  alt="inset"
                  src={srcImage}
                />
              </div>
            }
            <div className="info-card">
              {
                srcImage === this.mapImage && //150 less, 250 less
                <>

                  <div className="char-image">
                    <div
                      style={{
                        scale: "0.6",
                        marginTop: "-500px"
                      }}
                    >
                    <span> ⮕ </span>
                      {
                        this.state.image === 0 &&
                        <img
                          className="zoom-map"
                          src={srcImage}
                          alt="character-portrait"
                          style={{
                            top: `-${this.state.keyed.top - 220}px`,
                            left: `-${this.state.keyed.left - 150}px`
                          }}
                        />
                      }
                    </div>
                  </div>

                  <div>
                    <span onClick={() => {this.setState({image: 0})}}> 1 </span>
                    <span onClick={() => {this.setState({image: 1})}}> 2 </span>
                  </div>
                </>
              }


              {
                srcImage !== this.mapImage &&
                <img
                  className="char-image"
                  src={srcImage}
                  alt="character-portrait"
                />
              }



              <h3>{this.state.keyed.name}</h3>

              <div className="basic-info">

                {
                  // Event
                }

                {this.fieldShowOrNot("Type", "type")}

                {this.fieldShowOrNot("Date", "date")}

                {this.fieldShowOrNot("Location", "location", true)}

                {this.fieldShowOrNot("Cause", "cause")}

                {this.fieldShowOrNot("Outcome", "outcome", true)}

                {
                  // Location
                }

                {this.fieldShowOrNot("Location", "parentLocation")}

                {this.fieldShowOrNot("Region", "region")}

                {this.fieldShowOrNot("Nation", "nation")}

                {this.fieldShowOrNot("Size", "size")}

                {
                  // Faction
                }

                {this.fieldShowOrNot("Alignments", "alignments")}

                {
                  this.state.keyed.members && this.state.keyed.members[0] &&
                  <div className="labeled">
                    <div className="left-label">
                      <strong>
                        Members:
                      </strong>
                    </div>

                    <div className="right-blurb">
                      <ul>
                        {
                          this.state.keyed.members.map((member, indy) => {
                            return (
                              <li key={indy}>
                                <Linkedable body={member}/>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </div>
                  </div>
                }

                {
                 // Character
                }

                {this.fieldShowOrNot("Race", "race")}

                {this.fieldShowOrNot("Divine Rank", "divineRank")}

                {this.fieldShowOrNot("Pantheon", "divinePantheon")}

                {this.fieldShowOrNot("Cleric Domain", "divineDomain")}

                {this.fieldShowOrNot("Gender", "gender")}

                {this.fieldShowOrNot("Class", "class")}

                {this.fieldShowOrNot("Status", "status")}

                {
                  this.state.keyed.adherents && this.state.keyed.adherents[0] &&
                  <div className="labeled">
                    <div className="left-label">
                      <strong>
                        Adherents:
                      </strong>
                    </div>

                    <div className="right-blurb">
                      <ul>
                        {
                          this.state.keyed.adherents.map((member, indy) => {
                            return (
                              <li key={indy}>
                                <Linkedable body={member}/>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
          <div>
              {
                this.state.keyed.items &&
                <>
                  <h2>
                    Shop Items
                  </h2>

                  {
                    Object.keys(this.state.keyed.items).map((key, indy) => {
                      return (
                        <div key={indy}>
                          <Collapsible
                            title={key}
                            toggle={this.collapseToggle.bind(this)}
                            kind={key}
                            show={this.state.show}
                          >
                            <ShopTable items={this.state.keyed.items[key]}/>
                          </Collapsible>
                        </div>
                      )
                    })
                  }
                </>
              }
          </div>
        </div>
      )
    }
  }
}

export default withRouter(InfoPage);
