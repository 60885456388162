import React         from "react";

import ShopRow       from './ShopRow'

class ShopTable extends React.Component {
  shownItems(collection) {
    let keys = Object.keys(collection);

    if(keys[0] === "hidden") {
      let toggled = "shown";

      if(window.location.search.match(/showHidden=true/)) {
        toggled = "hidden";
      }

      collection = collection[toggled];
      keys = Object.keys(collection);
    }

    return keys.map((rarity, indy) => {
      return (
        collection[rarity].map((item, indy) => {
          return (
            <ShopRow key={indy} item={item}/>
          )
        })
      )
    })
  }

  render () {
    return (
      <table>
        <thead>
          <tr>
            <th>
            </th>

            <th>
              Item
            </th>

            <th>
              Type
            </th>

            <th>
              Made By
            </th>

            <th>
              Rarity
            </th>

            <th>
              Atn
            </th>

            <th>
              Base Price
            </th>
          </tr>
        </thead>
        <tbody>
            {
              this.shownItems(this.props.items)

            }
          </tbody>
      </table>
    )
  }
}

export default ShopTable;
