const shopsData =
[
  {
    name: "Ancestor's Forge",
    blurb: "Dhudaric Darkflayer",
    parentLocation: "thon_daral",
    src: "https://www.worldanvil.com/uploads/images/3a1566a84be328eb5c9459354da32052.jpg",
    items: {
      armor: {
        "none": [
            {
                "name": "Breastplate",
                "source": "PHB",
                "type": "MA",
                "rarity": "none",
                "value": 40000,
                make: "Dwarven",
                "armor": true,
            },
            {
                "name": "Chain Mail",
                "source": "PHB",
                "type": "HA",
                "rarity": "none",
                "value": 7500,
                "strength": "13",
                make: "Dwarven",
                "armor": true,
            },
            {
                "name": "Chain Shirt",
                "source": "PHB",
                "type": "MA",
                "rarity": "none",
                "value": 5000,
                make: "Dwarven",
                "armor": true,
            },
            {
                "name": "Half Plate Armor",
                "source": "PHB",
                "type": "MA",
                "rarity": "none",
                "value": 75000,
                make: "Dwarven",
                "armor": true,
            },
            {
                "name": "Hide Armor",
                "source": "PHB",
                "type": "MA",
                "rarity": "none",
                "value": 1000,
                make: "Dwarven",
                "armor": true,
            },
            {
                "name": "Leather Armor",
                "source": "PHB",
                "type": "LA",
                "rarity": "none",
                "value": 1000,
                make: "Dwarven",
                "armor": true,
            },
            {
                "name": "Padded Armor",
                "source": "PHB",
                "type": "LA",
                "rarity": "none",
                "value": 500,
                make: "Dwarven",
                "armor": true,

            },
            {
                "name": "Plate Armor",
                "source": "PHB",
                "type": "HA",
                "rarity": "none",
                "value": 150000,
                "strength": "15",
                make: "Dwarven",
                "armor": true,
            },
            {
                "name": "Ring Mail",
                "source": "PHB",
                "type": "HA",
                "rarity": "none",
                "value": 3000,
                make: "Dwarven",
                "armor": true,
            },
            {
                "name": "Scale Mail",
                "source": "PHB",
                "type": "MA",
                "rarity": "none",
                "value": 5000,
                make: "Dwarven",
                "armor": true,
            },
            {
                "name": "Spiked Armor",
                "source": "SCAG",
                "type": "MA",
                "rarity": "unknown",
                "value": 7500,
                make: "Dwarven",
                "armor": true,
            },
            {
                "name": "Splint Armor",
                "source": "PHB",
                "type": "HA",
                "rarity": "none",
                "value": 20000,
                "strength": "15",
                make: "Dwarven",
                "armor": true,
            },
            {
                "name": "Studded Leather Armor",
                "source": "PHB",
                "type": "LA",
                "rarity": "none",
                "value": 4500,
                make: "Dwarven",
                "armor": true,
            },
            {
                "name": "Ring Mail",
                "type": "HA",
                "rarity": "none",
                "value": 3000,
                "armor": true,
                "stealth": true,
                "make": "Giant"
            },
            {
                "name": "Hide Armor",
                "type": "MA",
                "rarity": "none",
                "value": 1000,
                "armor": true,
                "make": "Orc"
            },
            {
                "name": "Padded Armor",
                "type": "LA",
                "rarity": "none",
                "value": 500,
                "armor": true,
                "stealth": true,
                "make": "Older Imperial"
            },
            {
                "name": "Plate Armor",
                "type": "HA",
                "rarity": "none",
                "value": 150000,
                "armor": true,
                "stealth": true,
                "make": "Celestial"
            },
            {
                "name": "Plate Armor",
                "type": "HA",
                "rarity": "none",
                "value": 150000,
                "armor": true,
                "stealth": true,
                "make": "Newer Imperial"
            },
            {
                "name": "Splint Armor",
                "type": "HA",
                "rarity": "none",
                "value": 20000,
                "armor": true,
                "stealth": true,
                "make": "Fiend"
            }
        ],
        "common": [
            {
                "name": "Smoldering  Half Plate Armor",
                "type": "MA",
                "rarity": "common",
                "value": 85000,
                "armor": true,
                "stealth": true,
                "make": "Fair Isle Gold Elf"
            },
            {
                "name": "Smoldering  Hide Armor",
                "type": "MA",
                "rarity": "common",
                "value": 11000,
                "armor": true,
                "make": "Dwarven"
            },
            {
                "name": "Smoldering  Half Plate Armor",
                "type": "MA",
                "rarity": "common",
                "value": 85000,
                "armor": true,
                "stealth": true,
                "make": "Dwarven"
            },
            {
                "name": "Chain Mail  of Gleaming",
                "type": "HA",
                "rarity": "common",
                "value": 17500,
                "armor": true,
                "stealth": true,
                "make": "Timocracy Dragonborn"
            },
            {
                "name": "Smoldering  Studded Leather Armor",
                "type": "MA",
                "rarity": "common",
                "value": 11000,
                "armor": true,
                "make": "Elemental Fire"
            }
        ],
        "uncommon": [
            {
                "name": "Adamantine  Spiked Armor",
                "type": "MA",
                "rarity": "uncommon",
                "value": 57500,
                "armor": true,
                "stealth": true,
                "make": "Dwarven"
            },
            {
                "name": "Adamantine  Scale Mail",
                "type": "MA",
                "rarity": "uncommon",
                "value": 95000,
                "armor": true,
                "stealth": true,
                "make": "Dwarven"
            },
            {
                "name": "Mariner's  Chain Shirt",
                "type": "MA",
                "rarity": "uncommon",
                "value": 75000,
                "armor": true,
                "make": "Lizardfolk"
            },
            {
                "name": "Mariner's  Studded Leather",
                "type": "MA",
                "rarity": "uncommon",
                "value": 75000,
                "armor": true,
                "make": "Lizardfolk"
            },
            {
                "name": "Mariner's  Ring Mail",
                "type": "HA",
                "rarity": "uncommon",
                "value": 53000,
                "armor": true,
                "stealth": true,
                "make": "Ancient Imperial"
            },
            {
                "name": "Mithral  Chain Mail",
                "type": "MA",
                "rarity": "uncommon",
                "value": 87500,
                "armor": true,
                "make": "Timocracy Dragonborn"
            }
        ],
        "rare": [
            {
                "name": "+1 Chain Mail",
                "source": "PHB",
                "type": "HA",
                "rarity": "rare",
                "value": 115000,
                "strength": "13",
                make: "Dwarven",
                "armor": true,
            },
            {
                "name": "+1 Half Plate Armor",
                "source": "PHB",
                "type": "MA",
                "rarity": "rare",
                "value": 175000,
                make: "Dwarven",
                "armor": true,
            },
            {
                "name": "+1 Breastplate",
                "source": "PHB",
                "type": "MA",
                "rarity": "rare",
                "value": 140000,
                make: "Dwarven",
                "armor": true,
            },
            {
                "name": "+1 Studded Leather Armor",
                "type": "LA",
                "rarity": "rare",
                "value": 102000,
                "armor": true,
                "make": "Lizardfolk"
            },
            {
                "name": "Leather Armor  of Psychic Resistance",
                "type": "LA",
                "rarity": "rare",
                reqAttune: true,
                "value": 202000,
                "armor": true,
                "make": "Drow"
            },
            {
                "name": "Scale Mail  of Fire Resistance",
                "type": "MA",
                "rarity": "rare",
                reqAttune: true,
                "value": 215000,
                "armor": true,
                "stealth": true,
                "make": "Pentarchy Humans (Green)"
            }
        ],
        "very rare": [
            {
                "name": "+2 Studded Leather Armor",
                "type": "MA",
                "rarity": "very rare",
                "value": "?",
                "armor": true,
                "make": "Old Oxkadi"
            },
            {
                "name": "+2 Plate Armor",
                "type": "HA",
                "rarity": "very rare",
                "value": "?",
                "armor": true,
                "stealth": true,
                "make": "Ancient Imperial"
            },
        ],
        "legendary": [],
        "artifact": []
      },
      weapons: {
        "none": [
            {
                "name": "Battleaxe (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 1000,
                "weaponCategory": "martial",
                "axe": true,
                "weapon": true
            },
            {
                "name": "Dagger (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 200,
                "weaponCategory": "simple",
                "range": "20/60",
                "dagger": true,
                "weapon": true
            },
            {
                "name": "Flail (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 1000,
                "weaponCategory": "martial",
                "weapon": true
            },
            {
                "name": "Glaive (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 2000,
                "weaponCategory": "martial",
                "weapon": true
            },
            {
                "name": "Greataxe (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 3000,
                "weaponCategory": "martial",
                "axe": true,
                "weapon": true
            },
            {
                "name": "Greatclub (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 20,
                "weaponCategory": "simple",
                "club": true,
                "weapon": true
            },
            {
                "name": "Greatsword (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 5000,
                "weaponCategory": "martial",
                "sword": true,
                "weapon": true
            },
            {
                "name": "Halberd (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 2000,
                "weaponCategory": "martial",
                "weapon": true
            },
            {
                "name": "Handaxe (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 500,
                "weaponCategory": "simple",
                "range": "20/60",
                "axe": true,
                "weapon": true
            },
            {
                "name": "Heavy Crossbow (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "R",
                "rarity": "none",
                "value": 5000,
                "weaponCategory": "martial",
                "range": "100/400",
                "crossbow": true,
                "weapon": true,
            },
            {
                "name": "Javelin (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 50,
                "weaponCategory": "simple",
                "range": "30/120",
                "spear": true,
                "weapon": true
            },
            {
                "name": "Lance (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 1000,
                "weaponCategory": "martial",
                "weapon": true,
            },
            {
                "name": "Light Crossbow (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "R",
                "rarity": "none",
                "value": 2500,
                "weaponCategory": "simple",
                "range": "80/320",
                "crossbow": true,
                "weapon": true,
            },
            {
                "name": "Light Hammer (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 200,
                "weaponCategory": "simple",
                "range": "20/60",
                "hammer": true,
                "weapon": true
            },
            {
                "name": "Light Repeating Crossbow (∞)",
                make: "Dwarven",
                "source": "OotA",
                "type": "R",
                "rarity": "unknown",
                "weaponCategory": "simple",
                "range": "40/160",
                "crossbow": true,
                "weapon": true,
            },
            {
                "name": "Longbow (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "R",
                "rarity": "none",
                "value": 5000,
                "weaponCategory": "martial",
                "range": "150/600",
                "bow": true,
                "weapon": true,
            },
            {
                "name": "Longsword (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 1500,
                "weaponCategory": "martial",
                "sword": true,
                "weapon": true
            },
            {
                "name": "Mace (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 500,
                "weaponCategory": "simple",
                "mace": true,
                "weapon": true
            },
            {
                "name": "Maul (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 1000,
                "weaponCategory": "martial",
                "hammer": true,
                "weapon": true
            },
            {
                "name": "Morningstar (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 1500,
                "weaponCategory": "martial",
                "mace": true,
                "weapon": true
            },
            {
                "name": "Net (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "R",
                "rarity": "none",
                "value": 100,
                "weaponCategory": "martial",
                "range": "5/15",
                "net": true,
                "weapon": true,
            },
            {
                "name": "Pike (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 500,
                "weaponCategory": "martial",
                "weapon": true
            },
            {
                "name": "Quarterstaff (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 20,
                "weaponCategory": "simple",
                "weapon": true
            },
            {
                "name": "Rapier (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 2500,
                "weaponCategory": "martial",
                "sword": true,
                "weapon": true
            },
            {
                "name": "Scimitar (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 2500,
                "weaponCategory": "martial",
                "sword": true,
                "weapon": true
            },
            {
                "name": "Shortbow (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "R",
                "rarity": "none",
                "value": 2500,
                "weaponCategory": "simple",
                "range": "80/320",
                "bow": true,
                "weapon": true,
            },
            {
                "name": "Shortsword (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 1000,
                "weaponCategory": "martial",
                "sword": true,
                "weapon": true
            },
            {
                "name": "Sickle (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 100,
                "weaponCategory": "simple",
                "weapon": true
            },
            {
                "name": "Sling (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "R",
                "rarity": "none",
                "value": 10,
                "weaponCategory": "simple",
                "range": "30/120",
                "weapon": true,
            },
            {
                "name": "Spear (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 100,
                "weaponCategory": "simple",
                "range": "20/60",
                "spear": true,
                "weapon": true
            },
            {
                "name": "Trident (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 500,
                "weaponCategory": "martial",
                "range": "20/60",
                "weapon": true
            },
            {
                "name": "War Pick (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 500,
                "weaponCategory": "martial",
                "weapon": true
            },
            {
                "name": "Warhammer (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 1500,
                "weaponCategory": "martial",
                "hammer": true,
                "weapon": true
            },
            {
                "name": "Whip (∞)",
                make: "Dwarven",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 200,
                "weaponCategory": "martial",
                "weapon": true
            },
            {
                "name": "Yklwa (∞)",
                make: "Dwarven",
                "source": "ToA",
                "type": "M",
                "rarity": "none",
                "value": 100,
                "weaponCategory": "simple",
                "range": "10/30",
                "weapon": true,
            },
            {
                "name": "Maul",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 1100,
                "weaponCategory": "martial",
                "make": "Elemental Earth"
            },
            {
                "name": "Battleaxe",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 1800,
                "weaponCategory": "martial",
                "hammer": true,
                "weapon": true,
                "make": "Krateo Dragonborn"
            },
            {
                "name": "Blowgun",
                "source": "PHB",
                "type": "R",
                "rarity": "none",
                "value": 1000,
                "weaponCategory": "martial",
                "weapon": true,
                "make": "Lizardfolk"
            },
            {
                "name": "Flail",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 1300,
                "weaponCategory": "martial",
                "weapon": true,
                "make": "Pentarchy Humans (White)"
            },
            {
                "name": "Longsword",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 1600,
                "weaponCategory": "martial",
                "sword": true,
                "weapon": true,
                "make": "Newer Imperial"
            },
            {
                "name": "Heavy Crossbow",
                "source": "PHB",
                "type": "R",
                "rarity": "none",
                "value": 5200,
                "weaponCategory": "martial",
                "crossbow": true,
                "weapon": true,
                "make": "Newer Imperial"
            },
            {
                "name": "Yklwa",
                "source": "ToA",
                "type": "M",
                "rarity": "none",
                "value": 200,
                "weaponCategory": "simple",
                "weapon": true,
                "make": "Timocracy Dragonborn"
            },
            {
                "name": "Yklwa",
                "source": "ToA",
                "type": "M",
                "rarity": "none",
                "value": 200,
                "weaponCategory": "simple",
                "weapon": true,
                "make": "Timocracy Dragonborn"
            },
            {
                "name": "Flail",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 1300,
                "weaponCategory": "martial",
                "weapon": true,
                "make": "Older Imperial"
            },
            {
                "name": "Longbow",
                "source": "PHB",
                "type": "R",
                "rarity": "none",
                "value": 5200,
                "weaponCategory": "martial",
                "bow": true,
                "weapon": true,
                "make": "Newer Oxkadi"
            },
            {
                "name": "Quarterstaff",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 100,
                "weaponCategory": "simple",
                "weapon": true,
                "make": "Wood Elven"
            },
            {
                "name": "Flail",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 1500,
                "weaponCategory": "martial",
                "weapon": true,
                "make": "Southern Barbarian"
            },
            {
                "name": "Shortsword",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 4000,
                "weaponCategory": "martial",
                "sword": true,
                "weapon": true,
                "make": "Kinaphalic Dragonborn"
            },
            {
                "name": "Shortsword",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 1300,
                "weaponCategory": "martial",
                "sword": true,
                "weapon": true,
                "make": "Iron Host Hobgoblin"
            },
            {
                "name": "Morningstar",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 1700,
                "weaponCategory": "martial",
                "mace": true,
                "weapon": true,
                "make": "Different Imperial"
            },
            {
                "name": "Hoopak",
                "source": "DSotDQ",
                "type": "M",
                "rarity": "none",
                "value": 50,
                "weaponCategory": "martial",
                "weapon": true,
                "make": "Lizardfolk"
            },
            {
                "name": "Net",
                "source": "PHB",
                "type": "R",
                "rarity": "none",
                "value": 100,
                "weaponCategory": "martial",
                "net": true,
                "weapon": true,
                "make": "Newer Imperial"
            },
            {
                "name": "Scimitar",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 2900,
                "weaponCategory": "martial",
                "sword": true,
                "weapon": true,
                "make": "Southern Barbarian"
            },
            {
                "name": "Trident",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 700,
                "weaponCategory": "martial",
                "weapon": true,
                "make": "Iron Host Hobgoblin"
            },
            {
                "name": "Lance",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 1300,
                "weaponCategory": "martial",
                "weapon": true,
                "make": "Older Imperial"
            }
        ],
        "common": [
            {
                "name": "Silver  Battlexe",
                "source": "XGE",
                "type": "M",
                "rarity": "common",
                "value": 6500,
                "weaponCategory": "martial",
                "sword": true,
                "weapon": true,
                "make": "Dwarven"
            },
            {
                "name": "Moon-Touched  Warhammer",
                "source": "XGE",
                "type": "M",
                "rarity": "common",
                "value": 6500,
                "weaponCategory": "martial",
                "sword": true,
                "weapon": true,
                "make": "Dwarven"
            },
            {
                "name": "Moon-Touched  Greatsword",
                "source": "XGE",
                "type": "M",
                "rarity": "common",
                "value": 8500,
                "weaponCategory": "martial",
                "sword": true,
                "weapon": true,
                "make": "Giant"
            },
            {
                "name": "Silver Handaxe",
                "source": "XGE",
                "type": "M",
                "rarity": "common",
                "value": 6000,
                "weaponCategory": "martial",
                "sword": true,
                "weapon": true,
                "make": "Dwarven"
            },
            {
                "name": "Moon-Touched Dagger",
                "source": "XGE",
                "type": "M",
                "rarity": "common",
                "value": 4000,
                "weaponCategory": "martial",
                "sword": true,
                "weapon": true,
                "make": "Dwarven"
            },
            {
                "name": "Moon-Touched  Quarterstaff",
                "source": "XGE",
                "type": "M",
                "rarity": "common",
                "value": 5500,
                "weaponCategory": "martial",
                "sword": true,
                "weapon": true,
                "make": "Fey"
            },

        ],
        "uncommon": [
            {
                "name": "Pike  of Warning",
                "source": "DMG",
                "type": "M",
                "rarity": "uncommon",
                "value": 105000,
                reqAttune: true,
                "weaponCategory": "martial",
                "weapon": true,
                "make": "Aetherse"
            },
            {
                "name": "Handaxe  of Warning",
                reqAttune: true,
                "source": "DMG",
                "type": "M",
                "rarity": "uncommon",
                "value": 100000,
                "weaponCategory": "simple",
                "axe": true,
                "weapon": true,
                "make": "Bastion"
            },
            {
                "name": "Hellfire  Greatclub",
                "source": "BGDIA",
                "type": "M",
                "rarity": "uncommon",
                "value": 40000,
                "weaponCategory": "simple",
                "club": true,
                "weapon": true,
                "make": "Fiend"
            },
            {
                "name": "+1 Warhammer",
                "source": "PHB",
                "type": "M",
                "rarity": "uncommon",
                "value": 41500,
                "weaponCategory": "martial",
                "mace": true,
                "weapon": true,
                "make": "Dwarven"
            },
            {
                "name": "+1 Battle Axe",
                "source": "PHB",
                "type": "M",
                "rarity": "uncommon",
                "value": 41500,
                "weaponCategory": "martial",
                "mace": true,
                "weapon": true,
                "make": "Krateocracy Dragonborn"
            },
            {
                "name": "+1 Polehammer",
                "source": "PHB",
                "type": "M",
                "rarity": "uncommon",
                "value": 42000,
                "weaponCategory": "martial",
                "hammer": true,
                "weapon": true,
                "make": "Older Imperial"
            }
        ],
        "rare": [
            {
                "name": "+2  Khopesh",
                "source": "VGM",
                "type": "M",
                "rarity": "rare",
                "value": 600000,
                "weaponCategory": "martial",
                "sword": true,
                "weapon": true,
                "make": "Ancient Oxkadi"
            },
            {
                "name": "Giant Slayer Greataxe",
                "source": "DMG",
                "type": "M",
                "rarity": "rare",
                "value": 502500,
                "weaponCategory": "martial",
                "sword": true,
                "weapon": true,
                "make": "Draconic"
            }
        ],
        "very rare": [
            {
                "name": "Frost Brand Warhammer",
                "source": "DMG",
                "type": "M",
                "rarity": "very rare",
                "value": "?",
                reqAttune: true,
                "weaponCategory": "simple",
                "sword": true,
                "weapon": true,
                "make": "Pentarchy Humans (White)"
            }
        ],
        "legendary": [],
        "artifact": []
      }
    }
  },
  {
    name: "Shadow and Blade",
    src: "https://pbs.twimg.com/media/ExAw6BQXEAAwBS2.jpg",
    blurb: "Ivlathra",
    parentLocation: "thon_daral",
    items: {
      kitchen: {
        none: [
          {
            "name": "Chef's Knife",
            make: "Shadar-Kai",
            "source": "PHB",
            "type": "M",
            "rarity": "none",
            "value": 200,
            "weaponCategory": "simple",
            "range": "20/60",
            "dagger": true,
            "weapon": true
          },
          {
            "name": "Chef's Knife",
            make: "Lunar Elven",
            "source": "PHB",
            "type": "M",
            "rarity": "none",
            "value": 200,
            "weaponCategory": "simple",
            "range": "20/60",
            "dagger": true,
            "weapon": true
          },
          {
            "name": "Chef's Knife",
            make: "Fair Isle Elven",
            "source": "PHB",
            "type": "M",
            "rarity": "none",
            "value": 200,
            "weaponCategory": "simple",
            "range": "20/60",
            "dagger": true,
            "weapon": true
          },
          {
            "name": "Chef's Knife",
            make: "Gilded Forest Isle Elven",
            "source": "PHB",
            "type": "M",
            "rarity": "none",
            "value": 200,
            "weaponCategory": "simple",
            "range": "20/60",
            "dagger": true,
            "weapon": true
          },
          {
            "name": "Chef's Knife",
            make: "Newer Imperial",
            "source": "PHB",
            "type": "M",
            "rarity": "none",
            "value": 200,
            "weaponCategory": "simple",
            "range": "20/60",
            "dagger": true,
            "weapon": true
          },
          {
            "name": "Chef's Knife",
            make: "Newer Oxdai",
            "source": "PHB",
            "type": "M",
            "rarity": "none",
            "value": 200,
            "weaponCategory": "simple",
            "range": "20/60",
            "dagger": true,
            "weapon": true
          },
          {
            "name": "Yaginaba Knife",
            make: "Shadar-Kai",
            "source": "PHB",
            "type": "M",
            "rarity": "none",
            "value": 300,
            "weaponCategory": "simple",
            "range": "20/60",
            "dagger": true,
            "weapon": true
          },
          {
            "name": "Yaginaba Knife",
            make: "Elemental Fire",
            "source": "PHB",
            "type": "M",
            "rarity": "none",
            "value": 300,
            "weaponCategory": "simple",
            "range": "20/60",
            "dagger": true,
            "weapon": true
          },
          {
            "name": "Nakiri Knife",
            make: "Iron Host Hobgoblin",
            "source": "PHB",
            "type": "M",
            "rarity": "none",
            "value": 300,
            "weaponCategory": "simple",
            "range": "20/60",
            "dagger": true,
            "weapon": true
          },
          {
            "name": "Cleaver",
            make: "Orcs",
            "source": "PHB",
            "type": "M",
            "rarity": "none",
            "value": 300,
            "weaponCategory": "simple",
            "range": "20/60",
            "dagger": true,
            "weapon": true
          },
          {
            "name": "Parrying Knife",
            make: "Shadar-Kai",
            "source": "PHB",
            "type": "M",
            "rarity": "none",
            "value": 300,
            "weaponCategory": "simple",
            "range": "20/60",
            "dagger": true,
            "weapon": true
          },
           {
            "name": "Carving Knife",
            make: "Shadar-Kai",
            "source": "PHB",
            "type": "M",
            "rarity": "none",
            "value": 300,
            "weaponCategory": "simple",
            "range": "20/60",
            "dagger": true,
            "weapon": true
          },
        ],
        common: [
          {
            "name": "Moon-Touched Chef's Knife",
            "type": "M",
            "rarity": "common",
            "value": 5200,
            "weaponCategory": "martial",
            "sword": true,
            "weapon": true,
            "make": "Shadar-Kai"
          },          {
            "name": "Chef's Knife of Gleaming",
            "type": "M",
            "rarity": "common",
            "value": 5200,
            "weaponCategory": "martial",
            "sword": true,
            "weapon": true,
            "make": "Lunar Elven"
          },
          {
            "name": "Moon-Touched Chef's Knife",
            "type": "M",
            "rarity": "common",
            "value": 5000,
            "weaponCategory": "martial",
            "sword": true,
            "weapon": true,
            "make": "Newer Imperial"
          },
          {
            "name": "Moon-Touched Santoku",
            "type": "M",
            "rarity": "common",
            "value": 5200,
            "weaponCategory": "martial",
            "sword": true,
            "weapon": true,
            "make": "Shadar-Kai"
          },
          {
            "name": "Moon-Touched Bread Knife",
            "type": "M",
            "rarity": "common",
            "value": 5200,
            "weaponCategory": "martial",
            "sword": true,
            "weapon": true,
            "make": "Shadar-Kai"
          },
        ],
        "uncommon": [
          {
            "name": "+1 Chef's Knife",
            "type": "M",
            "rarity": "uncommon",
            "value": 21200,
            "weaponCategory": "simple",
            "dagger": true,
            "weapon": true,
            "make": "Kinaphalic Dragonborn"
          },
          {
            "name": "+1 Boning Knife",
            "type": "M",
            "rarity": "uncommon",
            "value": 20000,
            "weaponCategory": "simple",
            "dagger": true,
            "weapon": true,
            "make": "Elemental Air"
          },
          {
            "name": "+1 Parrying Knife",
            "type": "M",
            "rarity": "uncommon",
            "value": 22200,
            "weaponCategory": "simple",
            "dagger": true,
            "weapon": true,
            "make": "Ancient Oxkadi"
          },
          {
            "name": "Breadknife of Warning",
            "type": "M",
            "rarity": "uncommon",
            "value": 60200,
            "weaponCategory": "simple",
            "dagger": true,
            "weapon": true,
            "reqAttune": true,
            "make": "Celestial"
          },
        ],
        "rare": [
          {
            "name": "+2 Chef's Knife",
            "type": "M",
            "rarity": "rare",
            "value": "?",
            "weaponCategory": "martial",
            "sword": true,
            "weapon": true,
            "make": "Aetherse"
          },
        ]
      },
      weapons: {
        none: [
          {
            "name": "Arrow",
            make: "Shadar-Kai",
            "type": "A",
            "rarity": "none",
            "value": 5
          },
          {
            "name": "Arrows (20)",
            make: "Shadar-Kai",
            "type": "A",
            "rarity": "none",
            "value": 100,
          },
          {
            "name": "Double-Bladed Scimitar",
            make: "Shadar-Kai",
            "type": "M",
            "rarity": "none",
            "value": 10000,
            "weaponCategory": "martial",
            "sword": true,
            "weapon": true,
          },
          {
            "name": "Glaive",
            make: "Shadar-Kai",
            "type": "M",
            "rarity": "none",
            "value": 2000,
            "weaponCategory": "martial",
            "weapon": true
          },
          {
            "name": "Greatsword",
            make: "Shadar-Kai",
            "type": "M",
            "rarity": "none",
            "value": 5000,
            "weaponCategory": "martial",
            "sword": true,
            "weapon": true
          },
          {
            "name": "Halberd",
            make: "Shadar-Kai",
            "type": "M",
            "rarity": "none",
            "value": 2000,
            "weaponCategory": "martial",
            "weapon": true
          },
          {
            "name": "Handaxe",
            make: "Shadar-Kai",
            "type": "M",
            "rarity": "none",
            "value": 500,
            "weaponCategory": "simple",
            "axe": true,
            "weapon": true
          },
          {
            "name": "Longbow",
            make: "Shadar-Kai",
            "type": "R",
            "rarity": "none",
            "value": 5000,
            "weaponCategory": "martial",
            "bow": true,
            "weapon": true,
            "ammoType": "arrow|phb"
          },
          {
            "name": "Longsword",
            make: "Shadar-Kai",
            "type": "M",
            "rarity": "none",
            "value": 1500,
            "weaponCategory": "martial",
            "sword": true,
            "weapon": true
          },
          {
            "name": "Pike",
            make: "Shadar-Kai",
            "type": "M",
            "rarity": "none",
            "value": 500,
            "weaponCategory": "martial",
            "weapon": true
          },
          {
            "name": "Rapier",
            make: "Shadar-Kai",
            "type": "M",
            "rarity": "none",
            "value": 2500,
            "weaponCategory": "martial",
            "sword": true,
            "weapon": true
          },
          {
            "name": "Kerym (Elven Shaska)",
            make: "Shadar-Kai",
            "type": "M",
            "rarity": "none",
            "value": 3000,
            "weaponCategory": "martial",
            "sword": true,
            "weapon": true
          },
          {
            "name": "Scimitar",
            make: "Shadar-Kai",
            "type": "M",
            "rarity": "none",
            "value": 2500,
            "weaponCategory": "martial",
            "sword": true,
            "weapon": true
          },
          {
            "name": "Shortbow",
            make: "Shadar-Kai",
            "type": "R",
            "rarity": "none",
            "value": 2500,
            "weaponCategory": "simple",
            "bow": true,
            "weapon": true,
            "ammoType": "arrow|phb"
          },
          {
            "name": "Shortsword",
            make: "Shadar-Kai",
            "type": "M",
            "rarity": "none",
            "value": 1000,
            "weaponCategory": "martial",
            "sword": true,
            "weapon": true
          },
          {
            "name": "Sickle",
            make: "Shadar-Kai",
            "type": "M",
            "rarity": "none",
            "value": 100,
            "weaponCategory": "simple",
            "weapon": true
          },
          {
            "name": "Spear",
            make: "Shadar-Kai",
            "type": "M",
            "rarity": "none",
            "value": 100,
            "weaponCategory": "simple",
            "spear": true,
            "weapon": true
          },
          {
            "name": "Yklwa",
            make: "Shadar-Kai",
            "type": "M",
            "rarity": "none",
            "value": 100,
            "weaponCategory": "simple",
            "weapon": true,
          },
          {
              "name": "Longsword",
              "type": "M",
              "rarity": "none",
              "value": 1500,
              "weaponCategory": "martial",
              "sword": true,
              "weapon": true,
              "make": "Different Imperial"
          },
          {
              "name": "Shortsword",
              "type": "M",
              "rarity": "none",
              "value": 1000,
              "weaponCategory": "martial",
              "sword": true,
              "weapon": true,
              "make": "Unity Banner Hobgoblin"
          },
          {
              "name": "Shortsword",
              "type": "M",
              "rarity": "none",
              "value": 1000,
              "weaponCategory": "martial",
              "sword": true,
              "weapon": true,
              "make": "Timocracy Dragonborn"
          },
          {
              "name": "Pike",
              "type": "M",
              "rarity": "none",
              "value": 500,
              "weaponCategory": "martial",
              "weapon": true,
              "make": "Iron Host Hobgoblin"
          },
          {
              "name": "Dagger",
              "type": "M",
              "rarity": "none",
              "value": 200,
              "weaponCategory": "simple",
              "dagger": true,
              "weapon": true,
              "make": "Ancient Oxkadi"
          },
          {
              "name": "Dagger",
              "type": "M",
              "rarity": "none",
              "value": 200,
              "weaponCategory": "simple",
              "dagger": true,
              "weapon": true,
              "make": "Ancient Imperial"
          },
          {
              "name": "Shaska",
              make: "Newer Oxkadi",
              "type": "M",
              "rarity": "none",
              "value": 3000,
              "weaponCategory": "martial",
              "sword": true,
              "weapon": true
          },
          {
              "name": "Battleaxe",
              "type": "M",
              "rarity": "none",
              "value": 1000,
              "weaponCategory": "martial",
              "axe": true,
              "weapon": true,
              "make": "Ancient Oxkadi"
          }
        ],
        "common": [
          {
              "name": "Moon-Touched Kerym (Shashka)",
              "type": "M",
              "rarity": "common",
              "value": 7000,
              "weaponCategory": "martial",
              "sword": true,
              "weapon": true,
              "make": "Lunar Elf"
          },
          {
              "name": "Moon-Touched Dagger",
              "type": "M",
              "rarity": "common",
              "value": 4500,
              "weaponCategory": "martial",
              "sword": true,
              "weapon": true,
              "make": "Lunar Elf"
          },
          {
              "name": "Moon-Touched  Shortsword",
              "type": "M",
              "rarity": "common",
              "value": 4500,
              "weaponCategory": "martial",
              "sword": true,
              "weapon": true,
              "make": "Gnomish"
          },
          {
              "name": "Moon-Touched  Greatsword",
              "type": "M",
              "rarity": "common",
              "value": 7000,
              "weaponCategory": "martial",
              "sword": true,
              "weapon": true,
              "make": "Shadar-Kai"
          },
          {
              "name": "Moon-Touched  Scimitar",
              "type": "M",
              "rarity": "common",
              "value": 5000,
              "weaponCategory": "martial",
              "sword": true,
              "weapon": true,
              "make": "Ancient Imperial"
          },
          {
              "name": "Silver Longsword",
              "type": "M",
              "rarity": "common",
              "value": 4500,
              "weaponCategory": "martial",
              "sword": true,
              "weapon": true,
              "make": "Newer Imperial"
          }
        ],
        "uncommon": [
            {
                "name": "+1  Dagger",
                "type": "M",
                "rarity": "none",
                "value": 40000,
                "weaponCategory": "simple",
                "dagger": true,
                "weapon": true,
                "make": "Lunar Elven"
            },
            {
                "name": "+1 Greatsword",
                "type": "M",
                "rarity": "uncommon",
                "value": 43000,
                "weaponCategory": "martial",
                "axe": true,
                "weapon": true,
                "make": "Southern Barbarian"
            },
            {
                "name": "+1 Shashka",
                "type": "M",
                "rarity": "common",
                "value": 41000,
                "weaponCategory": "martial",
                "sword": true,
                "weapon": true,
                "make": "Newer Oxadi"
            },
            {
                "name": "+1 Kerym (Shashka)",
                "type": "M",
                "rarity": "uncommon",
                "value": 43000,
                "weaponCategory": "martial",
                "sword": true,
                "weapon": true,
                "make": "Lunar Elf"
            },
            {
                "name": "+1 Kerym (Shashka)",
                "type": "M",
                "rarity": "uncommon",
                "value": 43000,
                "weaponCategory": "martial",
                "sword": true,
                "weapon": true,
                "make": "Shadar-Kai"
            },
            {
                "name": "Longbow  of Warning",
                "type": "R",
                "rarity": "uncommon",
                "value": 102500,
                "weaponCategory": "martial",
                "bow": true,
                "weapon": true,
                "reqAttune": true,
                "ammoType": "arrow|phb",
                "make": "Ancient Oxkadi"
            },
            {
                "name": "Dagger of Warning",
                "type": "M",
                "rarity": "uncommon",
                "value": 89500,
                "weaponCategory": "simple",
                "dagger": true,
                "reqAttune": true,
                "weapon": true,
                "make": "Abberation"
            },
            {
                "name": "+1  Glaive",
                "type": "M",
                "rarity": "uncommon",
                "value": 42000,
                "weaponCategory": "martial",
                "weapon": true,
                "make": "Elemental Water"
            }
        ],
        "rare": [
            {
                "name": "Acheron Blade Longsword",
                "type": "M",
                "reqAttune": true,
                "rarity": "rare",
                "value": 465000,
                "weaponCategory": "martial",
                "sword": true,
                "weapon": true,
                "make": "Iron Host Hobgoblin"
            },
            {
                "name": "+2 Kerym (Shashka)",
                "type": "M",
                "rarity": "rare",
                "value": 420000,
                "weaponCategory": "martial",
                "sword": true,
                "weapon": true,
                "make": "Wood Elf"
            },
            {
                "name": "Flametongue Shashka",
                "reqAttune": true,
                "type": "M",
                "rarity": "rare",
                "value": 460000,
                "weaponCategory": "martial",
                "sword": true,
                "weapon": true,
                "make": "Shadar-Kai"
            },
            {
                "name": "Corpse Slayer Shortsword",
                "type": "M",
                "reqAttune": true,
                "rarity": "rare",
                "value": 420000,
                "weaponCategory": "martial",
                "hammer": true,
                "weapon": true,
                "make": "Kinather Dragonborn"
            },
            {
                "name": "Gambler's Blade  Shortsword",
                "type": "M",
                "reqAttune": true,
                "rarity": "rare",
                "value": 410000,
                "weaponCategory": "martial",
                "sword": true,
                "weapon": true,
                "make": "Different Imperial"
            },
            {
                "name": "Giant Slayer Scimitar",
                "type": "M",
                "rarity": "rare",
                "value": 510000,
                "weaponCategory": "martial",
                "weapon": true,
                "make": "Shadar-Kai"
            },
            {
                "name": "Giant Slayer Shortsword",
                "type": "M",
                "rarity": "rare",
                "weaponCategory": "martial",
                "value": 515000,
                "weapon": true,
                "make": "Shadar-Kai"
            },
            {
                "name": "Fiend Slayer Shortsword",
                "type": "M",
                "rarity": "rare",
                "weaponCategory": "martial",
                "value": 485000,
                "weapon": true,
                "make": "Shadar-Kai"
            },
            {
                "name": "Elemental Slayer Shortsword",
                "type": "M",
                "rarity": "rare",
                "value": 415000,
                "weaponCategory": "martial",
                "weapon": true,
                "make": "Shadar-Kai"
            },
            {
                "name": "Ooze Slayer Shortsword",
                "type": "M",
                "rarity": "rare",
                "value": 395000,
                "weaponCategory": "martial",
                "weapon": true,
                "make": "Shadar-Kai"
            },
            {
                "name": "Fey Slayer Iron Shortsword",
                "type": "M",
                "rarity": "rare",
                "value": 370000,
                "weaponCategory": "martial",
                "weapon": true,
                "make": "Shadar-Kai"
            },
            {
                "name": "Shortsword of Life Stealing",
                "type": "M",
                "rarity": "rare",
                "weaponCategory": "martial",
                "weapon": true,
                "value": 425000,
                "reqAttune": true,
                "make": "Shadar-Kai"
            },
            {
                "name": "Dragon Slayer Sickle",
                "type": "M",
                "rarity": "rare",
                "value": 456000,
                "weaponCategory": "martial",
                "weapon": true,
                "make": "Shadar-Kai"
            },
            {
                "name": "Sun blade",
                "type": "M",
                source: "DMG",
                "rarity": "rare",
                "value": 425000,
                "weaponCategory": "martial",
                "weapon": true,
                "reqAttune": true,
                "make": "Celestial"
            },
            {
                "name": "Crystal Rapier",
                "type": "M",
                "rarity": "rare",
                "value": 435000,
                "weaponCategory": "martial",
                "sword": true,
                "weapon": true,
                "reqAttune": true,
                "make": "Draconic"
            },
            {
                "name": "Rapier of Wounding",
                "type": "M",
                "rarity": "rare",
                "value": 355000,
                "weaponCategory": "martial",
                "axe": true,
                "weapon": true,
                "reqAttune": true,
                "make": "Fair Isle Elven"
            },
            {
                "name": "Sickle of Certain Death",
                "type": "M",
                "rarity": "rare",
                "value": 400000,
                "weaponCategory": "simple",
                "dagger": true,
                "weapon": true,
                "make": "Ancient Imperial"
            },
            {
                "name": "Shortsword of Certain Death",
                "type": "M",
                "rarity": "rare",
                "value": 415000,
                "weaponCategory": "simple",
                "dagger": true,
                "weapon": true,
                "make": "Ancient Imperial"
            },
        ],
        "very rare": [
            {
                "name": "Dancing Rapier",
                "type": "M",
                "rarity": "very rare",
                "value": "?",
                "weaponCategory": "martial",
                "axe": true,
                "weapon": true,
                "reqAttune": true,
                "make": "Gnome"
            },
            {
                "name": "Dancing Greatsword",
                "type": "M",
                "rarity": "very rare",
                "value": "?",
                "weaponCategory": "martial",
                "axe": true,
                "weapon": true,
                "reqAttune": true,
                "make": "Elemental Water"
            },
            {
                "name": "Rapier of the Medusa",
                "type": "M",
                "rarity": "very rare",
                "value": "?",
                "weaponCategory": "martial",
                "axe": true,
                "weapon": true,
                "reqAttune": true,
                "make": "Ancient Imperial"
            },
            {
                "name": "Scimitar of Sharpness",
                "type": "M",
                "rarity": "very rare",
                "value": "?",
                "weaponCategory": "martial",
                "weapon": true,
                "reqAttune": true,
                "make": "Shadar-Kai"
            },
            {
                "name": "Frost Brand Longsword",
                "type": "M",
                "rarity": "very rare",
                "value": "?",
                "weaponCategory": "martial",
                "weapon": true,
                "reqAttune": true,
                "make": "Shadar-Kai"
            }
        ],
        "legendary": [],
        "artifact": []
      }
    }
  },
  {
    name: "A Magic Item Shop",
    blurb: "Morning Mist of Solemn Gulch",
    parentLocation: "thon_daral",
    src: "https://i.redd.it/autsrsuk52461.jpg",
    items: {
      wands: {
        "none": [
            {
                    "name": "Wand",
                    "source": "PHB",
                    "page": 151,
                    "srd": true,
                    "basicRules": true,
                    "type": "SCF",
                    "scfType": "druid",
                    "rarity": "none",
                    "weight": 1,
                    "value": 1000,
                    "make": "Tabaxi"
                },
                {
                    "name": "Yew Wand",
                    "source": "PHB",
                    "page": 151,
                    "srd": true,
                    "basicRules": true,
                    "type": "SCF",
                    "scfType": "druid",
                    "rarity": "none",
                    "weight": 1,
                    "value": 1000,
                    "make": "Tabaxi"
                },
                {
                    "name": "Yew Wand",
                    "source": "PHB",
                    "page": 151,
                    "srd": true,
                    "basicRules": true,
                    "type": "SCF",
                    "scfType": "druid",
                    "rarity": "none",
                    "weight": 1,
                    "value": 1000,
                    "make": "Tabaxi"
                },
        ],
        common: [
          {
                "name": "Risian Pine  Wand",
                "source": "ERLW",
                "page": 151,
                "srd": true,
                "basicRules": true,
                "type": "SCF",
                "scfType": "arcane",
                "rarity": "common",
                "weight": 1,
                wand: true,
                "value": 1500,
                "make": "Tabaxi",
                reqAttune: true
            },
            {
                "name": "Xorian Wenge  Wand",
                "source": "ERLW",
                "page": 151,
                "srd": true,
                "basicRules": true,
                "type": "SCF",
                "scfType": "arcane",
                "rarity": "common",
                "weight": 4,
                "value": 1500,
                "wand": true,
                "make": "Tabaxi",
                reqAttune: true
            },
            {
                "name": "Risian Shale  Wand",
                "source": "ERLW",
                "page": 151,
                "srd": true,
                "basicRules": true,
                "type": "SCF",
                "scfType": "arcane",
                "rarity": "common",
                "weight": 2,
                wand: true,
                "value": 1500,
                "make": "Tabaxi",
                reqAttune: true
            },
          {
              "name": "Fernian Ash  Wand",
              "source": "ERLW",
              "page": 151,
              "srd": true,
              "basicRules": true,
              "type": "SCF",
              "scfType": "arcane",
              "rarity": "common",
              "weight": 4,
              "value": 1500,
              wand: true,
              "make": "Tabaxi",
              reqAttune: true
          },
          {
                "name": "Risian Pine  Wand",
                "source": "ERLW",
                "page": 151,
                "srd": true,
                "basicRules": true,
                "type": "SCF",
                "scfType": "arcane",
                "rarity": "common",
                "weight": 4,
                "value": 1500,
                "wand": true,
                "make": "Tabaxi",
                reqAttune: true
            },
            {
                "name": "Xorian Wenge  Wand",
                "source": "ERLW",
                "page": 151,
                "srd": true,
                "basicRules": true,
                "type": "SCF",
                "scfType": "arcane",
                "rarity": "common",
                wand: true,
                "weight": 3,
                "value": 1500,
                "make": "Tabaxi",
                reqAttune: true
            },
            {
                "name": "Wand of Smiles",
                "source": "XGE",
                "page": 140,
                "value": 7500,
                "type": "WD",
                "tier": "minor",
                "rarity": "common",
                "weight": 1,
                "recharge": "dawn",
                "rechargeAmount": 3,
                "charges": 3,
                "entries": [
                    "This wand has 3 charges. While holding it, you can use an action to expend 1 of its charges and target a humanoid you can see within 30 feet of you. The target must succeed on a DC 10 Charisma saving throw or be forced to smile for 1 minute.",
                    "The wand regains all expended charges daily at dawn. If you expend the wand's last charge, roll a {@dice d20}. On a 1, the wand transforms into a {@item wand of scowls|xge}."
                ],
                "make": "Tabaxi"
            }
        ],
        uncommon: [
            {
                "name": "Wand of Web",
                "source": "DMG",
                "page": 212,
                "srd": true,
                "type": "WD",
                "tier": "major",
                "rarity": "uncommon",
                "value": 67500,
                "reqAttune": "by a spellcaster",
                "reqAttuneTags": [
                    {
                        "spellcasting": true
                    }
                ],
                "weight": 1,
                "recharge": "dawn",
                "rechargeAmount": "{@dice 1d6 + 1}",
                "charges": 7,
                "entries": [
                    "This wand has 7 charges. While holding it, you can use an action to expend 1 of its charges to cast the {@spell web} spell (save DC 15) from it.",
                    "The wand regains {@dice 1d6 + 1} expended charges daily at dawn. If you expend the wand's last charge, roll a {@dice d20}. On a 1, the wand crumbles into ashes and is destroyed."
                ],
                "attachedSpells": [
                    "web"
                ],
                "lootTables": [
                    "Magic Item Table F"
                ],
                "hasFluffImages": true,
                "make": "Tabaxi"
        },
          {
                "name": "Wand of Magic Detection",
                "source": "DMG",
                "page": 211,
                "srd": true,
                "basicRules": true,
                "value": 87500,
                "type": "WD",
                "tier": "minor",
                "rarity": "uncommon",
                "weight": 1,
                "recharge": "dawn",
                "rechargeAmount": "{@dice 1d3}",
                "charges": 3,
                "entries": [
                    "This wand has 3 charges. While holding it, you can expend 1 charge as an action to cast the {@spell detect magic} spell from it. The wand regains {@dice 1d3} expended charges daily at dawn."
                ],
                "optionalfeatures": [
                    "replicate magic item|tce",
                    "replicate magic item|uaartificerrevisited"
                ],
                "attachedSpells": [
                    "detect magic"
                ],
                "lootTables": [
                    "Magic Item Table B"
                ],
                "hasFluffImages": true,
                "make": "Tabaxi"
            },
            {
                "name": "Wand of Secrets",
                "source": "DMG",
                "value": 110000,
                "page": 211,
                "srd": true,
                "type": "WD",
                "tier": "minor",
                "rarity": "uncommon",
                "weight": 1,
                "recharge": "dawn",
                "rechargeAmount": "{@dice 1d3}",
                "charges": 3,
                "entries": [
                    "The wand has 3 charges. While holding it, you can use an action to expend 1 of its charges, and if a secret door or trap is within 30 feet of you, the wand pulses and points at the one nearest to you. The wand regains {@dice 1d3} expended charges daily at dawn."
                ],
                "optionalfeatures": [
                    "replicate magic item|tce",
                    "replicate magic item|uaartificerrevisited"
                ],
                "lootTables": [
                    "Magic Item Table B"
                ],
                "hasFluffImages": true,
                "make": "Tabaxi"
            },
        ],
        rare:[
            {
                "name": "Wand of Winter",
                "source": "HotDQ",
                "page": 94,
                "otherSources": [
                    {
                        "source": "ToD",
                        "page": 178
                    }
                ],
                "type": "WD",
                "rarity": "rare",
                "value": 450000,
                "reqAttune": true,
                "weight": 1,
                "recharge": "dawn",
                "rechargeAmount": "{@dice 1d6 + 1}",
                "charges": 7,
                "entries": [
                    "This wand looks and feels like an icicle. You must be attuned to the wand to use it.",
                    "The wand has 7 charges, which are used to fuel the spells within it. With the wand in hand, you can use your action to cast one of the following spells from the wand, even if you are incapable of casting spells: {@spell ray of frost} (no charges, or 1 charge to cast at 5th level; +5 to hit with ranged spell attack), {@spell sleet storm} (3 charges; spell save DC 15), or {@spell ice storm} (4 charges; spell save DC 15). No components are required. The wand regains {@dice 1d6 + 1} expended charges each day at dawn. If you expend the wand's last charge, roll a {@dice d20}. On a 20, the wand melts away, forever destroyed."
                ],
                "attachedSpells": [
                    "ray of frost",
                    "sleet storm",
                    "ice storm"
                ],
                "hasFluffImages": true,
                "make": "Tabaxi"
            },
             {
                "name": "Wand of Wonder",
                "source": "DMG",
                "page": 212,
                "srd": true,
                "value": 400000,
                "type": "WD",
                "tier": "major",
                "rarity": "rare",
                "reqAttune": "by a spellcaster",
                "reqAttuneTags": [
                    {
                        "spellcasting": true
                    }
                ],
                "weight": 1,
                "recharge": "dawn",
                "rechargeAmount": "{@dice 1d6 + 1}",
                "charges": 7,
                "entries": [
                    "This wand has 7 charges. While holding it, you can use an action to expend 1 of its charges and choose a target within 120 feet of you. The target can be a creature, an object, or a point in space. Roll {@dice d100} and consult the following table to discover what happens.",
                    "If the effect causes you to cast a spell from the wand, the spell's save DC is 15. If the spell normally has a range expressed in feet, its range becomes 120 feet if it isn't already.",
                    "If an effect covers an area, you must center the spell on and include the target. If an effect has multiple possible subjects, the DM randomly determines which ones are affected.",
                    "The wand regains {@dice 1d6 + 1} expended charges daily at dawn. If you expend the wand's last charge, roll a {@dice d20}. On a 1, the wand crumbles into dust and is destroyed.",
                    {
                        "type": "table",
                        "colLabels": [
                            "{@dice d100}",
                            "Effect"
                        ],
                        "colStyles": [
                            "col-1 text-center",
                            "col-11"
                        ],
                        "rows": [
                            [
                                "01-05",
                                "You cast {@spell slow}."
                            ],
                            [
                                "06-10",
                                "You cast {@spell faerie fire}."
                            ],
                            [
                                "11-15",
                                "You are {@condition stunned} until the start of your next turn, believing something awesome just happened."
                            ],
                            [
                                "16-20",
                                "You cast {@spell gust of wind}."
                            ],
                            [
                                "21-25",
                                "You cast {@spell detect thoughts} on the target you chose. If you didn't target a creature, you instead take {@damage 1d6} psychic damage."
                            ],
                            [
                                "26-30",
                                "You cast {@spell stinking cloud}."
                            ],
                            [
                                "31-33",
                                "Heavy rain falls in a 60-foot radius centered on the target. The area becomes lightly obscured. The rain falls until the start of your next turn."
                            ],
                            [
                                "34-36",
                                "An animal appears in the unoccupied space nearest the target. The animal isn't under your control and acts as it normally would. Roll a {@dice d100} to determine which animal appears. On a 01-25, a {@creature rhinoceros} appears; on a 26-50, an {@creature elephant} appears; and on a 51-100, a {@creature rat} appears."
                            ],
                            [
                                "37-46",
                                "You cast {@spell lightning bolt}."
                            ],
                            [
                                "47-49",
                                "A cloud of 600 oversized butterflies fills a 30-foot radius centered on the target. The area becomes heavily obscured. The butterflies remain for 10 minutes."
                            ],
                            [
                                "50-53",
                                "You enlarge the target as if you had cast {@spell enlarge/reduce}. If the target can't be affected by that spell or if you didn't target a creature, you become the target."
                            ],
                            [
                                "54-58",
                                "You cast {@spell darkness}."
                            ],
                            [
                                "59-62",
                                "Grass grows on the ground in a 60-foot radius centered on the target. If grass is already there, it grows to ten times its normal size and remains overgrown for 1 minute."
                            ],
                            [
                                "63-65",
                                "An object of the DM's choice disappears into the Ethereal Plane. The object must be neither worn nor carried, within 120 feet of the target, and no larger than 10 feet in any dimension."
                            ],
                            [
                                "66-69",
                                "You shrink yourself as if you had cast {@spell enlarge/reduce} on yourself."
                            ],
                            [
                                "70-79",
                                "You cast {@spell fireball}."
                            ],
                            [
                                "80-84",
                                "You cast {@spell invisibility} on yourself."
                            ],
                            [
                                "85-87",
                                "Leaves grow from the target. If you chose a point in space as the target, leaves sprout from the creature nearest to that point. Unless they are picked off, the leaves turn brown and fall off after 24 hours."
                            ],
                            [
                                "88-90",
                                "A stream of {@dice 1d4 × 10} gems, each worth 1 gp, shoots from the wand's tip in a line 30 feet long and 5 feet wide. Each gem deals 1 bludgeoning damage, and the total damage of the gems is divided equally among all creatures in the line."
                            ],
                            [
                                "91-95",
                                "A burst of colorful shimmering light extends from you in a 30-foot radius. You and each creature in the area that can see must succeed on a DC 15 Constitution saving throw or become {@condition blinded} for 1 minute. A creature can repeat the saving throw at the end of each of its turns, ending the effect on itself on a success."
                            ],
                            [
                                "96-97",
                                "The target's skin turns bright blue for {@dice 1d10} days. If you chose a point in space, the creature nearest to that point is affected."
                            ],
                            [
                                "98-00",
                                "If you targeted a creature, it must make a DC 15 Constitution saving throw. If you didn't target a creature, you become the target and must make the saving throw. If the saving throw fails by 5 or more, the target is instantly {@condition petrified}. On any other failed save, the target is {@condition restrained} and begins to turn to stone. While {@condition restrained} in this way, the target must repeat the saving throw at the end of its next turn, becoming {@condition petrified} on a failure or ending the effect on a success. The petrification lasts until the target is freed by the {@spell greater restoration} spell or similar magic."
                            ]
                        ]
                    }
                ],
                "attachedSpells": [
                    "slow",
                    "faerie fire",
                    "gust of wind",
                    "detect thoughts",
                    "stinking cloud",
                    "lightning bolt",
                    "enlarge/reduce",
                    "darkness",
                    "fireball",
                    "invisibility"
                ],
                "lootTables": [
                    "Magic Item Table G"
                ],
                "hasFluffImages": true,
                "make": "Tabaxi"
            },
            {
                "name": "Wand of Enemy Detection",
                "source": "DMG",
                "page": 210,
                "value": 390000,
                "srd": true,
                "type": "WD",
                "tier": "major",
                "rarity": "rare",
                "reqAttune": true,
                "weight": 1,
                "recharge": "dawn",
                "rechargeAmount": "{@dice 1d6 + 1}",
                "charges": 7,
                "entries": [
                    "This wand has 7 charges. While holding it, you can use an action and expend 1 charge to speak its command word. For the next minute, you know the direction of the nearest creature hostile to you within 60 feet, but not its distance from you. The wand can sense the presence of hostile creatures that are ethereal, {@condition invisible}, disguised, or hidden, as well as those in plain sight. The effect ends if you stop holding the wand.",
                    "The wand regains {@dice 1d6 + 1} expended charges daily at dawn. If you expend the wand's last charge, roll a {@dice d20}. On a 1, the wand crumbles into ashes and is destroyed."
                ],
                "lootTables": [
                    "Magic Item Table G"
                ],
                "hasFluffImages": true,
                "make": "Tabaxi"
            },
        ]
      },
      scrolls: {
        "common": [
          {
            "name": "Spell Scroll (Hideous Laughter)",
            "source": "DMG",
            "page": 200,
            value: 2500,
            "srd": true,
            "type": "SC",
            "tier": "minor",
            "rarity": "common",
            "spellScrollLevel": 1,
          },
          {
            "name": "Spell Scroll (Identify)",
            "source": "DMG",
            "page": 200,
            value: 2500,
            "srd": true,
            "type": "SC",
            "tier": "minor",
            "rarity": "common",
            "spellScrollLevel": 1,
          },
          {
            "name": "Spell Scroll (Entangle)",
            "source": "DMG",
            "page": 200,
            value: 2500,
            "srd": true,
            "type": "SC",
            "tier": "minor",
            "rarity": "common",
            "spellScrollLevel": 1,
          },
          {
            "name": "Spell Scroll (Purify Food And Drink)",
            "source": "DMG",
            "page": 200,
            value: 2500,
            "srd": true,
            "type": "SC",
            "tier": "minor",
            "rarity": "common",
            "spellScrollLevel": 1,
          },
          {
            "name": "Spell Scroll (Hunter's Mark)",
            "source": "DMG",
            "page": 200,
            value: 2500,
            "srd": true,
            "type": "SC",
            "tier": "minor",
            "rarity": "common",
            "spellScrollLevel": 1,
          },
          {
            "name": "Spell Scroll (Burning Hands)",
            "source": "DMG",
            "page": 200,
            value: 2500,
            "srd": true,
            "type": "SC",
            "tier": "minor",
            "rarity": "common",
            "spellScrollLevel": 1,
          },
          {
            "name": "Spell Scroll (Goodberry)",
            "source": "DMG",
            "page": 200,
            value: 2500,
            "srd": true,
            "type": "SC",
            "tier": "minor",
            "rarity": "common",
            "spellScrollLevel": 1,
          },
          {
            "name": "Spell Scroll (Cure Wounds)",
            "source": "DMG",
            "page": 200,
            value: 2500,
            "srd": true,
            "type": "SC",
            "tier": "minor",
            "rarity": "common",
            "spellScrollLevel": 1,
          },
          {
            "name": "Spell Scroll (Longstrider)",
            "source": "DMG",
            "page": 200,
            value: 2500,
            "srd": true,
            "type": "SC",
            "tier": "minor",
            "rarity": "common",
            "spellScrollLevel": 1,
          },
          // {
          //   "name": "Spell Scroll (1st Level)",
          //   "source": "DMG",
          //   "page": 200,
          //   "srd": true,
          //   "type": "SC",
          //   "tier": "minor",
          //   "rarity": "common",
          //   "spellScrollLevel": 1,
          // },
        ],
        "uncommon": [
          {
            "name": "Spell Scroll (Shatter)",
            "source": "DMG",
            "page": 201,
            "srd": true,
            value: 5000,
            "type": "SC",
            "tier": "minor",
            "rarity": "uncommon",
            "spellScrollLevel": 2,
          },
          {
            "name": "Spell Scroll (Gust Of Wind)",
            "source": "DMG",
            "page": 201,
            "srd": true,
            value: 5000,
            "type": "SC",
            "tier": "minor",
            "rarity": "uncommon",
            "spellScrollLevel": 2,
          },
          {
            "name": "Spell Scroll (Blur)",
            "source": "DMG",
            "page": 201,
            "srd": true,
            value: 5000,
            "type": "SC",
            "tier": "minor",
            "rarity": "uncommon",
            "spellScrollLevel": 2,
          },
          {
            "name": "Spell Scroll (Pass Without Trace)",
            "source": "DMG",
            "page": 201,
            "srd": true,
            value: 5000,
            "type": "SC",
            "tier": "minor",
            "rarity": "uncommon",
            "spellScrollLevel": 2,
          },
          {
            "name": "Spell Scroll (Mirror Image)",
            "source": "DMG",
            "page": 201,
            "srd": true,
            value: 5000,
            "type": "SC",
            "tier": "minor",
            "rarity": "uncommon",
            "spellScrollLevel": 2,
          },
          {
            "name": "Spell Scroll (Spike Growth)",
            "source": "DMG",
            "page": 201,
            "srd": true,
            value: 5000,
            "type": "SC",
            "tier": "minor",
            "rarity": "uncommon",
            "spellScrollLevel": 2,
          },
          {
            "name": "Spell Scroll (Animal Messenger)",
            "source": "DMG",
            "page": 201,
            "srd": true,
            value: 5000,
            "type": "SC",
            "tier": "minor",
            "rarity": "uncommon",
            "spellScrollLevel": 2,
          },

          // {
          //   "name": "Spell Scroll (2nd Level)",
          //   "source": "DMG",
          //   "page": 201,
          //   "srd": true,
          //   "type": "SC",
          //   "tier": "minor",
          //   "rarity": "uncommon",
          //   "spellScrollLevel": 2,
          // },
          {
            "name": "Spell Scroll (Daylight)",
            "source": "DMG",
            "page": 202,
            value: 20000,
            "srd": true,
            "type": "SC",
            "tier": "minor",
            "rarity": "uncommon",
            "spellScrollLevel": 3,
          },
          {
            "name": "Spell Scroll (Dispel Magic)",
            "source": "DMG",
            "page": 202,
            value: 20000,
            "srd": true,
            "type": "SC",
            "tier": "minor",
            "rarity": "uncommon",
            "spellScrollLevel": 3,
          },
          {
            "name": "Spell Scroll (Nondetection)",
            "source": "DMG",
            "page": 202,
            value: 20000,
            "srd": true,
            "type": "SC",
            "tier": "minor",
            "rarity": "uncommon",
            "spellScrollLevel": 3,
          },
          // {
          //   "name": "Spell Scroll (3rd Level)",
          //   "source": "DMG",
          //   "page": 202,
          //   "srd": true,
          //   "type": "SC",
          //   "tier": "minor",
          //   "rarity": "uncommon",
          //   "spellScrollLevel": 3,
          // },
        ],
        rare: [
          {
            "name": "Spell Scroll (Wall of Fire)",
            "source": "DMG",
            "page": 203,
            "srd": true,
            "type": "SC",
            value: 35000,
            "tier": "minor",
            "rarity": "rare",
            "spellScrollLevel": 4,
          },
          {
            "name": "Spell Scroll (Conjure Woodland Beings)",
            "source": "DMG",
            "page": 203,
            "srd": true,
            "type": "SC",
            value: 35000,
            "tier": "minor",
            "rarity": "rare",
            "spellScrollLevel": 4,
          },
          {
            "name": "Spell Scroll (Dimension Door)",
            "source": "DMG",
            "page": 203,
            "srd": true,
            "type": "SC",
            value: 35000,
            "tier": "minor",
            "rarity": "rare",
            "spellScrollLevel": 4,
          },
          // {
          //   "name": "Spell Scroll (4th Level)",
          //   "source": "DMG",
          //   "page": 203,
          //   "srd": true,
          //   "type": "SC",
          //   "tier": "minor",
          //   "rarity": "rare",
          //   "spellScrollLevel": 4,
          // },
          {
            "name": "Spell Scroll (Wall Of Stone)",
            "source": "DMG",
            "page": 204,
            value: 35000,
            "srd": true,
            "type": "SC",
            "tier": "minor",
            "rarity": "rare",
            "spellScrollLevel": 5,
          },
          {
            "name": "Spell Scroll (Geas)",
            "source": "DMG",
            "page": 204,
            value: 35000,
            "srd": true,
            "type": "SC",
            "tier": "minor",
            "rarity": "rare",
            "spellScrollLevel": 5,
          },
          {
            "name": "Spell Scroll (Insect Plague)",
            "source": "DMG",
            "page": 204,
            value: 35000,
            "srd": true,
            "type": "SC",
            "tier": "minor",
            "rarity": "rare",
            "spellScrollLevel": 5,
          },
          {
            "name": "Spell Scroll (Animate Objects)",
            "source": "DMG",
            "page": 204,
            value: 35000,
            "srd": true,
            "type": "SC",
            "tier": "minor",
            "rarity": "rare",
            "spellScrollLevel": 5,
          },
          // {
          //   "name": "Spell Scroll (5th Level)",
          //   "source": "DMG",
          //   "page": 204,
          //   "srd": true,
          //   "type": "SC",
          //   "tier": "minor",
          //   "rarity": "rare",
          //   "spellScrollLevel": 5,
          // },
        ],
        very_rare: [
          {
            "name": "Spell Scroll (Globe of Invulnerability)",
            "source": "DMG",
            "page": 205,
            value: 200000,
            "srd": true,
            "type": "SC",
            "tier": "minor",
            "rarity": "very rare",
            "spellScrollLevel": 6,
          },
          {
            "name": "Spell Scroll (Sunbeam)",
            "source": "DMG",
            "page": 205,
            value: 200000,
            "srd": true,
            "type": "SC",
            "tier": "minor",
            "rarity": "very rare",
            "spellScrollLevel": 6,
          },
          {
            "name": "Spell Scroll (Eyebite)",
            "source": "DMG",
            "page": 205,
            value: 200000,
            "srd": true,
            "type": "SC",
            "tier": "minor",
            "rarity": "very rare",
            "spellScrollLevel": 6,
          },
          // {
          //   "name": "Spell Scroll (6th Level)",
          //   "source": "DMG",
          //   "page": 205,
          //   "srd": true,
          //   "type": "SC",
          //   "tier": "minor",
          //   "rarity": "very rare",
          //   "spellScrollLevel": 6,
          // },
        ]
      },
      sorcerer_specific: {
        "uncommon": [
            {
                "name": "+1 Bloodwell Vial",
                "source": "TCE",
                "page": 122,
                value: "50000",
                "rarity": "uncommon",
                "reqAttune": "by a sorcerer",
                "reqAttuneTags": [
                    {
                        "class": "sorcerer"
                    }
                ],
                "wondrous": true,
                "bonusSpellAttack": "+1",
                "bonusSpellSaveDc": "+1",
                "focus": [
                    "Sorcerer"
                ],
                "entries": [
                    "To attune to this vial, you must place a few drops of your blood into it. The vial can't be opened while your attunement to it lasts. If your attunement to the vial ends, the contained blood turns to ash. You can use the vial as a spellcasting focus for your spells while wearing or holding it, and you gain a +1 bonus to spell attack rolls and to the saving throw DCs of your sorcerer spells.",
                    "In addition, when you roll any Hit Dice to recover hit points while you are carrying the vial, you can regain 5 sorcery points. This property of the vial can't be used again until the next dawn."
                ],
                "type": "SCF",
                "make": "Tabaxi"
            },
            {
                "name": "Feywild Shard",
                value: "45000",
                "source": "TCE",
                "page": 127,
                "rarity": "uncommon",
                "reqAttune": "by a sorcerer",
                "reqAttuneTags": [
                    {
                        "class": "sorcerer"
                    }
                ],
                "wondrous": true,
                "weight": 1,
                "focus": [
                    "Sorcerer"
                ],
                "entries": [
                    "This warm crystal glints with the sunset colors of the Feywild sky and evokes whispers of emotional memory. As an action, you can attach the shard to a Tiny object (such as a weapon or a piece of jewelry) or detach it. It falls off if your attunement to it ends. You can use the shard as a spellcasting focus while you hold or wear it.",
                    "When you use a Metamagic option on a spell while you are holding or wearing the shard, you can roll on the {@table Wild Magic Surge|PHB} table in the {@book Player's Handbook|PHB}. If the result is a spell, it is too wild to be affected by your Metamagic, and if it normally requires concentration, it doesn't require concentration in this case; the spell lasts for its full duration.",
                    "If you don't have the Wild Magic Sorcerous Origin, once this property is used to roll on the {@table Wild Magic Surge|PHB} table, it can't be used again until the next dawn."
                ],
                "hasFluffImages": true,
                "type": "SCF",
                "make": "Sylvan"
            },
        ],
        "rare": [
          {
              "name": "Outer Essence Shard (Lawful)",
              "source": "TCE",
              "page": 133,
              "rarity": "rare",
              "reqAttune": "by a sorcerer",
              "reqAttuneTags": [
                  {
                      "class": "sorcerer"
                  }
              ],
              "wondrous": true,
              "weight": 1,
              value: "450000",
              "focus": [
                  "Sorcerer"
              ],
              "entries": [
                  "This flickering crystal holds the essence of an Outer Plane. As an action, you can attach the shard to a Tiny object (such as a weapon or a piece of jewelry) or detach it. It falls off if your attunement to it ends. You can use the shard as a spellcasting focus while you hold or wear it.",
                  "When you use a Metamagic option on a spell while you are holding or wearing the shard, you can use that property.",
                  {
                      "type": "entries",
                      "name": "Property",
                      "entries": [
                          "You can end one of the following conditions affecting yourself or one creature you can see within 30 feet of you: {@condition charmed}, {@condition blinded}, {@condition deafened}, {@condition frightened}, {@condition poisoned}, or {@condition stunned}."
                      ]
                  }
              ],
              "make": "Celestial"
          },
            {
                "name": "Elemental Essence Shard (Earth)",
                "source": "TCE",
                "page": 127,
                "rarity": "rare",
                "reqAttune": "by a sorcerer",
                "reqAttuneTags": [
                    {
                        "class": "sorcerer"
                    }
                ],
                "wondrous": true,
                value: "390000",
                "weight": 1,
                "focus": [
                    "Sorcerer"
                ],
                "entries": [
                    "This crackling crystal contains the essence of an elemental plane. As an action, you can attach the shard to a Tiny object (such as a weapon or a piece of jewelry) or detach it. It falls off if your attunement to it ends. You can use the shard as a spellcasting focus while you hold or wear it.",
                    "When you use a Metamagic option on a spell while you are holding or wearing the shard, you can use the following property:",
                    {
                        "type": "entries",
                        "name": "Property",
                        "entries": [
                            "You gain resistance to a damage type of your choice until the start of your next turn."
                        ]
                    }
                ],
                "type": "SCF",
                "make": "Elemental Earth"
            },
            {
                "name": "Elemental Essence Shard (Water)",
                "source": "TCE",
                "page": 127,
                "rarity": "rare",
                "reqAttune": "by a sorcerer",
                "reqAttuneTags": [
                    {
                        "class": "sorcerer"
                    }
                ],
                "wondrous": true,
                "weight": 1,
                value: "415000",
                "focus": [
                    "Sorcerer"
                ],
                "entries": [
                    "This crackling crystal contains the essence of an elemental plane. As an action, you can attach the shard to a Tiny object (such as a weapon or a piece of jewelry) or detach it. It falls off if your attunement to it ends. You can use the shard as a spellcasting focus while you hold or wear it.",
                    "When you use a Metamagic option on a spell while you are holding or wearing the shard, you can use the following property:",
                    {
                        "type": "entries",
                        "name": "Property",
                        "entries": [
                            "You create a wave of water that bursts out from you in a 10-foot radius. Each creature of your choice that you can see in that area takes {@damage 2d6} cold damage and must succeed on a Strength saving throw against your spell save DC or be pushed 10 feet away from you and fall {@condition prone}."
                        ]
                    }
                ],
                "type": "SCF",
                "make": "Elemental Water"
            },
        ],
        "very rare": [
        ],
        "legendary": [],
        "artifact": []
      },
      focuses: {
        "none": [
                {
                    "name": "Reliquary",
                    "source": "PHB",
                    "page": 151,
                    "srd": true,
                    "basicRules": true,
                    "type": "SCF",
                    "scfType": "holy",
                    "rarity": "none",
                    "weight": 2,
                    "value": 500,
                    "make": "Fair Isle Gold Elf"
                },
                {
                    "name": "Wooden Staff",
                    "source": "PHB",
                    "page": 151,
                    "srd": true,
                    "basicRules": true,
                    "type": "SCF",
                    "scfType": "druid",
                    "rarity": "none",
                    "weight": 4,
                    "value": 500,
                    "weaponCategory": "simple",
                    "property": [
                        "V"
                    ],
                    "dmg1": "1d6",
                    "dmgType": "B",
                    "dmg2": "1d8",
                    "make": "Tabaix"
                },
                {
                    "name": "Rod",
                    "source": "PHB",
                    "page": 151,
                    "srd": true,
                    "basicRules": true,
                    "type": "SCF",
                    "scfType": "arcane",
                    "rarity": "none",
                    "weight": 2,
                    "value": 1000,
                    "make": "Pentarchy Humans (Green)"
                },
                {
                    "name": "Emblem",
                    "source": "PHB",
                    "page": 151,
                    "srd": true,
                    "basicRules": true,
                    "type": "SCF",
                    "scfType": "holy",
                    "rarity": "none",
                    "value": 500,
                    "make": "Gnome"
                },
                {
                    "name": "Reliquary",
                    "source": "PHB",
                    "page": 151,
                    "srd": true,
                    "basicRules": true,
                    "type": "SCF",
                    "scfType": "holy",
                    "rarity": "none",
                    "weight": 2,
                    "value": 500,
                    "make": "Fair Isle Gold Elf"
                },
                {
                    "name": "Sprig of Mistletoe",
                    "source": "PHB",
                    "page": 151,
                    "srd": true,
                    "basicRules": true,
                    "type": "SCF",
                    "scfType": "druid",
                    "rarity": "none",
                    "value": 100,
                    "make": "Giant"
                },
                {
                    "name": "Emblem",
                    "source": "PHB",
                    "page": 151,
                    "srd": true,
                    "basicRules": true,
                    "type": "SCF",
                    "scfType": "holy",
                    "rarity": "none",
                    "value": 500,
                    "make": "Gnome"
                },
                {
                    "name": "Wooden Staff",
                    "source": "PHB",
                    "page": 151,
                    "srd": true,
                    "basicRules": true,
                    "type": "SCF",
                    "scfType": "druid",
                    "rarity": "none",
                    "weight": 4,
                    "value": 500,
                    "weaponCategory": "simple",
                    "property": [
                        "V"
                    ],
                    "dmg1": "1d6",
                    "dmgType": "B",
                    "dmg2": "1d8",
                    "make": "Tabaxi"
                },
                {
                    "name": "Wooden Staff",
                    "source": "PHB",
                    "page": 151,
                    "srd": true,
                    "basicRules": true,
                    "type": "SCF",
                    "scfType": "druid",
                    "rarity": "none",
                    "weight": 4,
                    "value": 500,
                    "weaponCategory": "simple",
                    "property": [
                        "V"
                    ],
                    "dmg1": "1d6",
                    "dmgType": "B",
                    "dmg2": "1d8",
                    "make": "Tabaxi"
                },
                {
                    "name": "Wooden Staff",
                    "source": "PHB",
                    "page": 151,
                    "srd": true,
                    "basicRules": true,
                    "type": "SCF",
                    "scfType": "druid",
                    "rarity": "none",
                    "weight": 4,
                    "value": 500,
                    "weaponCategory": "simple",
                    "property": [
                        "V"
                    ],
                    "dmg1": "1d6",
                    "dmgType": "B",
                    "dmg2": "1d8",
                    "make": "Tabaxi"
                },
                {
                    "name": "Reliquary",
                    "source": "PHB",
                    "page": 151,
                    "srd": true,
                    "basicRules": true,
                    "type": "SCF",
                    "scfType": "holy",
                    "rarity": "none",
                    "weight": 2,
                    "value": 500,
                    "make": "Tabaxi"
            }
        ],
        "common": [
          {
                "name": "Xorian Marble  Crystal",
                "source": "ERLW",
                "page": 151,
                "srd": true,
                "basicRules": true,
                "type": "SCF",
                "scfType": "arcane",
                "rarity": "common",
                "weight": 1,
                "value": 2000,
                "make": "Tabaxi",
                reqAttune: true
          },
          {
              "name": "Lamannian Flint  Orb",
              "source": "ERLW",
              "page": 151,
              "srd": true,
              "basicRules": true,
              "type": "SCF",
              "scfType": "arcane",
              "rarity": "common",
              "weight": 3,
              "value": 2000,
              "make": "Tabaxi",
              reqAttune: true
          },
          {
                "name": "Hat of Wizardry",
                "source": "XGE",
                "page": 137,
                value: "9500",
                "tier": "minor",
                "rarity": "common",
                "reqAttune": "by a wizard",
                "reqAttuneTags": [
                    {
                        "class": "wizard"
                    }
                ],
                "wondrous": true,
                "focus": [
                    "Wizard"
                ],
                "entries": [
                    "This antiquated, cone—shaped hat is adorned with gold crescent moons and stars. While you are wearing it, you gain the following benefits:",
                    {
                        "type": "list",
                        "items": [
                            "You can use the hat as a spellcasting focus for your wizard spells.",
                            "You can try to cast a cantrip that you don't know. The cantrip must be on the wizard spell list, and you must make a DC 10 Intelligence ({@skill Arcana}) check. If the check succeeds, you cast the spell. If the check fails, so does the spell, and the action used to cast the spell is wasted. In either case, you can't use this property again until you finish a long rest."
                        ]
                    }
                ],
                "type": "SCF",
                "make": "Tabaxi"
            },
            {
                "name": "Mabaran Ebony  Orb",
                "source": "ERLW",
                "page": 151,
                "srd": true,
                "basicRules": true,
                "type": "SCF",
                "scfType": "arcane",
                "rarity": "common",
                "weight": 3,
                "value": 2000,
                reqAttune: true,
                "make": "Tabaxi"
            },
            {
                "name": "Fernian Basalt  Rod",
                "source": "ERLW",
                "page": 151,
                "srd": true,
                "basicRules": true,
                "type": "SCF",
                "scfType": "arcane",
                "rarity": "common",
                "weight": 2,
                "value": 2000,
                reqAttune: true,
                "make": "Tabaxi"
            },
            {
                "name": "Kythrian Skarn  Orb",
                "source": "ERLW",
                "page": 151,
                "srd": true,
                "basicRules": true,
                "type": "SCF",
                "scfType": "arcane",
                "rarity": "common",
                "weight": 3,
                "value": 2000,
                reqAttune: true,
                "make": "Tabaxi"
            },
            {
                "name": "Xorian Wenge  Wooden Staff",
                "source": "ERLW",
                "page": 151,
                "srd": true,
                "basicRules": true,
                "type": "SCF",
                "scfType": "druid",
                "rarity": "common",
                "weight": 4,
                "value": 2000,
                "weaponCategory": "simple",
                "property": [
                    "V"
                ],
                "dmg1": "1d6",
                "dmgType": "B",
                "dmg2": "1d8",
                reqAttune: true,
                "make": "Tabaxi",
                "staff": true
            },
        ],
        "uncommon": [
            {
                "name": "+1 All-Purpose Tool",
                "source": "TCE",
                "page": 119,
                "value": 55000,
                "rarity": "uncommon",
                "reqAttune": "by an artificer",
                "reqAttuneTags": [
                    {
                        "class": "artificer|tce"
                    }
                ],
                "wondrous": true,
                "bonusSpellAttack": "+1",
                "bonusSpellSaveDc": "+1",
                "focus": [
                    "Artificer"
                ],
                "entries": [
                    "This simple screwdriver can transform into a variety of tools; as an action, you can touch the item and transform it into any type of artisan's tool of your choice (see the \"Equipment\" chapter in the {@book Player's Handbook|PHB} for a list of {@item artisan's tools|PHB}). Whatever form the tool takes, you are proficient with it.",
                    "While holding this tool, you gain a +1 bonus to the spell attack rolls and the saving throw DCs of your artificer spells.",
                    "As an action, you can focus on the tool to channel your creative forces. Choose a cantrip that you don't know from any class list. For 8 hours, you can cast that cantrip, and it counts as an artificer cantrip for you. Once this property is used, it can't be used again until the next dawn."
                ],
                "type": "SCF",
                "make": "Tabaxi"
            },
            {
                "name": "Rod of Retribution",
                "source": "EGW",
                "rarity": "uncommon",
                "reqAttune": true,
                "wondrous": true,
                rod: true,
                "value": 45000,
                "type": "RD",
                "make": "Tabaxi"
            },
            {
                "name": "Slumbering Dragon-Touched Focus",
                "source": "FTD",
                "page": 26,
                "rarity": "uncommon",
                "reqAttune": "by a spellcaster",
                "reqAttuneTags": [
                    {
                        "spellcasting": true
                    }
                ],
                "wondrous": true,
                "value": 78000,
                "focus": [
                    "Artificer",
                    "Bard",
                    "Cleric",
                    "Druid",
                    "Paladin",
                    "Ranger",
                    "Sorcerer",
                    "Warlock",
                    "Wizard"
                ],
                "entries": [
                    "This wondrous item can be a scepter, an orb, an amulet, a crystal, or another finely crafted object. It typically incorporates imagery of dragons' wings, claws, teeth, or scales.",
                    "You have advantage on initiative rolls. While you are holding the focus, it can function as a spellcasting focus for all your spells."
                ],
                "type": "SCF",
                "make": "Tabaxi"
            },
            {
                "name": "+1 Arcane Grimoire",
                "source": "TCE",
                "page": 120,
                "rarity": "uncommon",
                "value": 56000,
                "reqAttune": "by a wizard",
                "reqAttuneTags": [
                    {
                        "class": "wizard"
                    }
                ],
                "wondrous": true,
                "weight": 3,
                "bonusSpellAttack": "+1",
                "bonusSpellSaveDc": "+1",
                "focus": [
                    "Wizard"
                ],
                "entries": [
                    "While you are holding this leather-bound book, you can use it as a spellcasting focus for your wizard spells, and you gain a +1 bonus to spell attack rolls and to the saving throw DCs of your wizard spells.",
                    "You can use this book as a spellbook. In addition, when you use your Arcane Recovery feature, you can increase the number of spell slot levels you regain by 1."
                ],
                "type": "SCF",
                "make": "Tabaxi"
            }
        ],
        "rare": [
            {
                "name": "Heart Weaver's Primer",
                "source": "TCE",
                "page": 128,
                "value": 425000,
                "rarity": "rare",
                "reqAttune": "by a wizard",
                "reqAttuneTags": [
                    {
                        "class": "wizard"
                    }
                ],
                "wondrous": true,
                "weight": 3,
                "recharge": "dawn",
                "rechargeAmount": "{@dice 1d3}",
                "charges": 3,
                "focus": [
                    "Wizard"
                ],
                "entries": [
                    "This pristine book smells faintly of a random scent you find pleasing. When found, the book contains the following spells: {@spell antipathy/sympathy}, {@spell charm person}, {@spell dominate person}, {@spell enthrall}, {@spell hypnotic pattern}, {@spell modify memory}, and {@spell suggestion}. It functions as a spellbook for you.",
                    "While you are holding the book, you can use it as a spellcasting focus for your wizard spells.",
                    "The book has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. You can use the charges in the following ways while holding it:",
                    {
                        "type": "list",
                        "items": [
                            "If you spend 1 minute studying the book, you can expend 1 charge to replace one of your prepared wizard spells with a different spell in the book. The new spell must be of the enchantment school.",
                            "When you cast an enchantment spell, you can expend 1 charge to impose disadvantage on the first saving throw one target makes against the spell."
                        ]
                    }
                ],
                "attachedSpells": [
                    "antipathy/sympathy",
                    "charm person",
                    "dominate person",
                    "enthrall",
                    "hypnotic pattern",
                    "modify memory",
                    "suggestion"
                ],
                "type": "SCF",
                "make": "Tabaxi"
            },
            {
                "name": "Protective Verses",
                "source": "TCE",
                "page": 134,
                "rarity": "rare",
                "value": 430000,
                "reqAttune": "by a wizard",
                "reqAttuneTags": [
                    {
                        "class": "wizard"
                    }
                ],
                "wondrous": true,
                "weight": 3,
                "recharge": "dawn",
                "rechargeAmount": "{@dice 1d3}",
                "charges": 3,
                "focus": [
                    "Wizard"
                ],
                "entries": [
                    "This leather-bound spellbook is reinforced with iron and silver fittings and an iron lock (DC 20 to open). As an action, you can touch the book's cover and cause it to lock as if you cast {@spell arcane lock} on it. When found, the book contains the following spells: {@spell arcane lock}, {@spell dispel magic}, {@spell globe of invulnerability}, {@spell glyph of warding}, {@spell Mordenkainen's private sanctum}, {@spell protection from evil and good||protection from evil}, and {@spell symbol}. It functions as a spellbook for you.",
                    "While you are holding the book, you can use it as a spellcasting focus for your wizard spells.",
                    "The book has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. You can use the charges in the following ways while holding it:",
                    {
                        "type": "list",
                        "items": [
                            "If you spend 1 minute studying the book, you can expend 1 charge to replace one of your prepared wizard spells with a different spell in the book. The new spell must be of the abjuration school.",
                            "When you cast an abjuration spell, you can expend 1 charge to grant a creature you can see within 30 feet of you {@dice 2d10} temporary hit points."
                        ]
                    }
                ],
                "attachedSpells": [
                    "arcane lock",
                    "dispel magic",
                    "globe of invulnerability",
                    "glyph of warding",
                    "mordenkainen's private sanctum",
                    "protection from evil and good",
                    "symbol"
                ],
                "type": "SCF",
                "make": "Tabaxi"
            },

            {
                "name": "Rod of the Vonindod",
                "source": "SKT",
                "value": 390000,
                "rarity": "rare",
                "reqAttune": true,
                "wondrous": true,
                rod: true,
                type: "RD",
                "make": "Tabaxi"
            },
            {
                "name": "Astromancy Archive",
                "source": "TCE",
                "page": 120,
                "value": 445000,
                "rarity": "rare",
                "reqAttune": "by a wizard",
                "reqAttuneTags": [
                    {
                        "class": "wizard"
                    }
                ],
                "wondrous": true,
                "weight": 3,
                "recharge": "dawn",
                "rechargeAmount": "{@dice 1d3}",
                "charges": 3,
                "focus": [
                    "Wizard"
                ],
                "entries": [
                    "This brass disc of articulated, concentric rings unfolds into an armillary sphere. As a bonus action, you can unfold it into the sphere or back into a disc. When found, it contains the following spells, which are wizard spells for you while you are attuned to it: {@spell augury}, {@spell divination}, {@spell find the path}, {@spell foresight}, {@spell locate creature}, and {@spell locate object}. It functions as a spellbook for you, with spells encoded on the rings.",
                    "While you are holding the archive, you can use it as a spellcasting focus for your wizard spells.",
                    "The archive has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. You can use the charges in the following ways while holding it:",
                    {
                        "type": "list",
                        "items": [
                            "If you spend 1 minute studying the archive, you can expend 1 charge to replace one of your prepared wizard spells with a different spell in the archive. The new spell must be of the divination school.",
                            "When a creature you can see within 30 feet of you makes an attack roll, an ability check, or a saving throw, you can use your reaction to expend 1 charge and force the creature to roll a {@dice d4} and apply the number rolled as a bonus or penalty (your choice) to the original roll. You can do this after you see the roll but before its effects are applied."
                        ]
                    }
                ],
                "attachedSpells": [
                    "augury",
                    "divination",
                    "find the path",
                    "foresight",
                    "locate creature",
                    "locate object"
                ],
                "type": "SCF",
                "make": "Tabaxi"
            },

        ],
        "very rare": [
            {
                "name": "Crystalline Chronicle",
                "value": "?",
                "source": "TCE",
                "page": 124,
                "rarity": "very rare",
                "reqAttune": "by a wizard",
                "reqAttuneTags": [
                    {
                        "class": "wizard"
                    }
                ],
                "wondrous": true,
                "weight": 3,
                "recharge": "dawn",
                "rechargeAmount": "{@dice 1d3}",
                "charges": 3,
                "focus": [
                    "Wizard"
                ],
                "entries": [
                    "An etched crystal sphere the size of a grapefruit hums faintly and pulses with irregular flares of inner light. While you are touching the crystal, you can retrieve and store information and spells within the crystal at the same rate as reading and writing. When found, the crystal contains the following spells: {@spell detect thoughts}, {@spell intellect fortress|TCE}, {@spell Rary's telepathic bond}, {@spell sending}, {@spell telekinesis}, {@spell Tasha's mind whip|TCE}, and {@spell Tenser's floating disk}. It functions as a spellbook for you, with its spells and other writing psychically encoded within it.",
                    "While you are holding the crystal, you can use it as a spellcasting focus for your wizard spells, and you know the {@spell mage hand}, {@spell mind sliver|TCE} (appears in this book), and {@spell message} cantrips if you don't already know them.",
                    "The crystal has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. You can use the charges in the following ways while holding it:",
                    {
                        "type": "list",
                        "items": [
                            "If you spend 1 minute studying the information within the crystal, you can expend 1 charge to replace one of your prepared wizard spells with a different spell in the book.",
                            "When you cast a wizard spell, you can expend 1 charge to cast the spell without verbal, somatic, or material components of up to 100 gp value."
                        ]
                    }
                ],
                "attachedSpells": [
                    "detect thoughts",
                    "intellect fortress|tce",
                    "mage hand",
                    "message",
                    "mind sliver|tce",
                    "rary's telepathic bond",
                    "sending",
                    "tasha's mind whip|tce",
                    "telekinesis",
                    "tenser's floating disk"
                ],
                "hasFluffImages": true,
                "type": "SCF",
                "make": "Tabaxi"
            },
            {
                "name": "+3 All-Purpose Tool",
                "value": "?",
                "source": "TCE",
                "page": 119,
                "rarity": "very rare",
                "reqAttune": "by an artificer",
                "reqAttuneTags": [
                    {
                        "class": "artificer|tce"
                    }
                ],
                "wondrous": true,
                "bonusSpellAttack": "+3",
                "bonusSpellSaveDc": "+3",
                "focus": [
                    "Artificer"
                ],
                "entries": [
                    "This simple screwdriver can transform into a variety of tools; as an action, you can touch the item and transform it into any type of artisan's tool of your choice (see the \"Equipment\" chapter in the {@book Player's Handbook|PHB} for a list of {@item artisan's tools|PHB}). Whatever form the tool takes, you are proficient with it.",
                    "While holding this tool, you gain a +3 bonus to the spell attack rolls and the saving throw DCs of your artificer spells.",
                    "As an action, you can focus on the tool to channel your creative forces. Choose a cantrip that you don't know from any class list. For 8 hours, you can cast that cantrip, and it counts as an artificer cantrip for you. Once this property is used, it can't be used again until the next dawn."
                ],
                "type": "SCF",
                "make": "Tabaxi"
            }
        ],
        "legendary": [],
        "artifact": []
      },
      tattoos: {
        "common": [
              {
                  "name": "Spellwrought Tattoo of Shield",
                  "source": "TCE",
                  "page": 11,
                  "rarity": "common",
                  "wondrous": true,
                  "tattoo": true,
                  "entries": [
                      "This tattoo contains a single spell, wrought on your skin by a magic needle. To use the tattoo, you must hold the needle against your skin where you want the tattoo to appear and speak the command word. The needle turns into the ink that becomes the tattoo, which appears on your skin. Once the tattoo is on your skin, you can cast its spell, requiring no material components. The tattoo glows faintly while you cast the spell and for the spell's duration. Once the spell ends, the tattoo vanishes from your skin.",
                      "A spell cast from this tattoo has a spellcasting ability modifier of +3, a save DC of 13 and an attack bonus of +5."
                  ],
                  value: "5500",
                  "make": "Tabaxi"
              },
              {
                  "name": "Spellwrought Tattoo of Faerie Fire",
                  "source": "TCE",
                  "page": 11,
                  "rarity": "common",
                  "wondrous": true,
                  "tattoo": true,
                  "entries": [
                      "This tattoo contains a single spell, wrought on your skin by a magic needle. To use the tattoo, you must hold the needle against your skin where you want the tattoo to appear and speak the command word. The needle turns into the ink that becomes the tattoo, which appears on your skin. Once the tattoo is on your skin, you can cast its spell, requiring no material components. The tattoo glows faintly while you cast the spell and for the spell's duration. Once the spell ends, the tattoo vanishes from your skin.",
                      "A spell cast from this tattoo has a spellcasting ability modifier of +3, a save DC of 13 and an attack bonus of +5."
                  ],
                  value: "5500",
                  "make": "Tabaxi"
              },
              {
                  "name": "Spellwrought Tattoo of Silent Image",
                  "source": "TCE",
                  "page": 11,
                  "rarity": "common",
                  "wondrous": true,
                  "tattoo": true,
                  "entries": [
                      "This tattoo contains a single spell, wrought on your skin by a magic needle. To use the tattoo, you must hold the needle against your skin where you want the tattoo to appear and speak the command word. The needle turns into the ink that becomes the tattoo, which appears on your skin. Once the tattoo is on your skin, you can cast its spell, requiring no material components. The tattoo glows faintly while you cast the spell and for the spell's duration. Once the spell ends, the tattoo vanishes from your skin.",
                      "A spell cast from this tattoo has a spellcasting ability modifier of +3, a save DC of 13 and an attack bonus of +5."
                  ],
                  value: "5500",
                  "make": "Tabaxi"
              },
              {
                  "name": "Spellwrought Tattoo of Healing Word",
                  "source": "TCE",
                  "page": 11,
                  "rarity": "common",
                  "wondrous": true,
                  "tattoo": true,
                  "entries": [
                      "This tattoo contains a single spell, wrought on your skin by a magic needle. To use the tattoo, you must hold the needle against your skin where you want the tattoo to appear and speak the command word. The needle turns into the ink that becomes the tattoo, which appears on your skin. Once the tattoo is on your skin, you can cast its spell, requiring no material components. The tattoo glows faintly while you cast the spell and for the spell's duration. Once the spell ends, the tattoo vanishes from your skin.",
                      "A spell cast from this tattoo has a spellcasting ability modifier of +3, a save DC of 13 and an attack bonus of +5."
                  ],
                  value: "5500",
                  "make": "Tabaxi"
              },
                {
                  "name": "Spellwrought Tattoo Chaos Bolt",
                  "source": "TCE",
                  "page": 11,
                  "rarity": "common",
                  "wondrous": true,
                  "tattoo": true,
                  "entries": [
                      "This tattoo contains a single spell, wrought on your skin by a magic needle. To use the tattoo, you must hold the needle against your skin where you want the tattoo to appear and speak the command word. The needle turns into the ink that becomes the tattoo, which appears on your skin. Once the tattoo is on your skin, you can cast its spell, requiring no material components. The tattoo glows faintly while you cast the spell and for the spell's duration. Once the spell ends, the tattoo vanishes from your skin.",
                      "A spell cast from this tattoo has a spellcasting ability modifier of +3, a save DC of 13 and an attack bonus of +5."
                  ],
                  value: "5500",
                  "make": "Tabaxi"
              },
              {
                  "name": "Spellwrought Tattoo Shield of Faith",
                  "source": "TCE",
                  "page": 11,
                  "rarity": "common",
                  "wondrous": true,
                  "tattoo": true,
                  "entries": [
                      "This tattoo contains a single spell, wrought on your skin by a magic needle. To use the tattoo, you must hold the needle against your skin where you want the tattoo to appear and speak the command word. The needle turns into the ink that becomes the tattoo, which appears on your skin. Once the tattoo is on your skin, you can cast its spell, requiring no material components. The tattoo glows faintly while you cast the spell and for the spell's duration. Once the spell ends, the tattoo vanishes from your skin.",
                      "A spell cast from this tattoo has a spellcasting ability modifier of +3, a save DC of 13 and an attack bonus of +5."
                  ],
                  value: "5500",
                  "make": "Tabaxi"
              },
              {
                  "name": "Spellwrought Tattoo Shield of Featherfall",
                  "source": "TCE",
                  "page": 11,
                  "rarity": "common",
                  "wondrous": true,
                  "tattoo": true,
                  "entries": [
                      "This tattoo contains a single spell, wrought on your skin by a magic needle. To use the tattoo, you must hold the needle against your skin where you want the tattoo to appear and speak the command word. The needle turns into the ink that becomes the tattoo, which appears on your skin. Once the tattoo is on your skin, you can cast its spell, requiring no material components. The tattoo glows faintly while you cast the spell and for the spell's duration. Once the spell ends, the tattoo vanishes from your skin.",
                      "A spell cast from this tattoo has a spellcasting ability modifier of +3, a save DC of 13 and an attack bonus of +5."
                  ],
                  value: "5500",
                  "make": "Tabaxi"
              },
              {
                  "name": "Spellwrought Tattoo Thunderwave",
                  "source": "TCE",
                  "page": 11,
                  "rarity": "common",
                  "wondrous": true,
                  "tattoo": true,
                  "entries": [
                      "This tattoo contains a single spell, wrought on your skin by a magic needle. To use the tattoo, you must hold the needle against your skin where you want the tattoo to appear and speak the command word. The needle turns into the ink that becomes the tattoo, which appears on your skin. Once the tattoo is on your skin, you can cast its spell, requiring no material components. The tattoo glows faintly while you cast the spell and for the spell's duration. Once the spell ends, the tattoo vanishes from your skin.",
                      "A spell cast from this tattoo has a spellcasting ability modifier of +3, a save DC of 13 and an attack bonus of +5."
                  ],
                  value: "5500",
                  "make": "Tabaxi"
              },
            {
                "name": "Illuminator's Tattoo",
                "source": "TCE",
                "page": 10,
                "rarity": "common",
                "reqAttune": true,
                "wondrous": true,
                "tattoo": true,
                "entries": [
                    "This tattoo contains beautiful calligraphy, images of writing implements, and the like. While this tattoo is on your skin, you can write with your fingertip as if it were an ink pen that never runs out of ink.",
                    "As an action, you can touch a piece of writing up to one page in length and speak a creature's name. The writing becomes {@condition invisible} to everyone other than you and the named creature for the next 24 hours. Either of you can dismiss the invisibility by touching the script (no action required). Once used, this action can't be used again until the next dawn.",
                    {
                        "type": "entries",
                        "name": "Tattoo Attunement",
                        "entries": [
                            "To attune to this item, you hold the needle to your skin where you want the tattoo to appear, pressing the needle there throughout the attunement process. When the attunement is complete, the needle turns into the ink that becomes the tattoo, which appears on the skin. If you have multiple magic tattoos, they count as a single magic item with regard to the number of magic items you can attune to.",
                            "If your attunement to the tattoo ends, the tattoo vanishes, and the needle reappears in the closest unoccupied space to you."
                        ]
                    }
                ],
                value: "8500",
                "make": "Tabaxi"
            },
        ],
        "rare": [
            {
                "name": "Spellwrought Tattoo of Teleportation Circle",
                "source": "TCE",
                value: "64000",
                "wondrous": true,
                "tattoo": true,
                "rarity": "rare",
                "make": "Tabaxi"
            },
        ],
      },
      wonderous_items: {
        "none": [
          {
            "name": "Douche Canoe",
            "source": "ToA",
            "page": 31,
            "type": "SHP",
            "rarity": "none",
            "weight": 100,
            "value": 5000,
            "crew": 1,
            "vehAc": 11,
            "vehHp": 50,
            "vehSpeed": 2,
            "capPassenger": 6,
            "entries": [
                "A canoe can be purchased in Port Nyanzaru for 50 gp. It holds up to six Medium creatures and has a maximum speed of 2 mph. It is otherwise identical to a {@item rowboat}."
            ],
            "make": "Tabaxi"
          },
          {
              "name": "Paper (one sheet)",
              "source": "PHB",
              "page": 150,
              "srd": true,
              "basicRules": true,
              "type": "G",
              "rarity": "none",
              "value": 20,
              "make": "Newer Oxkadi"
          },
          {
              "name": "Monster Hunter's Pack",
              "source": "VRGR",
              "page": 34,
              "otherSources": [
                  {
                      "source": "CoS",
                      "page": 209
                  }
              ],
              "type": "G",
              "rarity": "none",
              "weight": 48.5,
              "value": 3300,
              "entries": [
                  "Includes:",
                  {
                      "type": "list",
                      "items": [
                          "a {@item chest|phb}",
                          "a {@item crowbar|phb}",
                          "a {@item hammer|phb}",
                          "three wooden stakes",
                          "a {@item holy symbol|phb}",
                          "a {@item holy water (flask)|phb|flask of holy water}",
                          "a set of {@item manacles|phb}",
                          "a {@item steel mirror|phb}",
                          "a {@item oil (flask)|phb|flask of oil}",
                          "a {@item tinderbox|phb}",
                          "3 {@item torch|phb|torches}"
                      ]
                  }
              ],
              "packContents": [
                  "backpack|phb",
                  "chest|phb",
                  "crowbar|phb",
                  "hammer|phb",
                  {
                      "special": "wooden stake",
                      "quantity": 3
                  },
                  "holy symbol|phb",
                  "holy water (flask)|phb",
                  "manacles|phb",
                  "steel mirror|phb",
                  "oil (flask)|phb",
                  "tinderbox|phb",
                  {
                      "item": "torch|phb",
                      "quantity": 3
                  }
              ],
              "make": "Newer Imperial"
          },
          {
            "name": "Winged Boots",
            "source": "DMG",
            "page": 214,
            "srd": true,
            "tier": "major",
            make: "Tabaxi",
            "rarity": "uncommon",
            "reqAttune": true,
            "wondrous": true,
            value: "5500",
            "modifySpeed": {
            "equal": {
            "fly": "walk"
            }
            },
            "entries": [
            "While you wear these boots, you have a flying speed equal to your walking speed. You can use the boots to fly for up to 4 hours, all at once or in several shorter flights, each one using a minimum of 1 minute from the duration. If you are flying when the duration expires, you descend at a rate of 30 feet per round until you land.",
            "The boots regain 2 hours of flying capability for every 12 hours they aren't in use."
            ],
            "optionalfeatures": [
            "replicate magic item|tce",
            "replicate magic item|uaartificerrevisited"
            ],
            "lootTables": [
            "Magic Item Table F"
            ],
            "hasFluffImages": true
          },
        ],
        "common": [
                {
                  "name": "Cloak of Many Fashions",
                  "source": "XGE",
                  "page": 136,
                  "tier": "minor",
                  "rarity": "common",
                  "wondrous": true,
                  "entries": [
                      "While wearing this cloak, you can use a bonus action to change the style, color, and apparent quality of the garment. The cloak's weight doesn't change. Regardless of its appearance, the cloak can't be anything but a cloak. Although it can duplicate the appearance of other magic cloaks, it doesn't gain their magical properties."
                  ],
                  "hasFluffImages": true,
                  value: "5000",
                  "make": "Tabaxi"
              },
              {
                  "name": "Vox Seeker",
                  "source": "EGW",
                  "page": 270,
                  "rarity": "common",
                  "wondrous": true,
                  "entries": [
                      "This clockwork device resembles a metal crab the size of a dinner plate. Every action used to wind up the device allows it to operate for 1 minute, to a maximum of 10 minutes. While operational, the item uses the accompanying {@creature Vox Seeker|EGW} stat block. This automaton is under the DM's control. A vox seeker reduced to 0 hit points is destroyed."
                  ],
                  value: "10000",
                  "make": "Tabaxi"
              },
            {
                "name": "Heward's Handy Spice Pouch",
                "source": "XGE",
                "page": 137,
                "tier": "minor",
                "rarity": "common",
                "wondrous": true,
                "recharge": "dawn",
                "rechargeAmount": "{@dice 1d6 + 4}",
                "charges": 10,
                "entries": [
                    "This belt pouch appears empty and has 10 charges. While holding the pouch, you can use an action to expend 1 of its charges, speak the name of any nonmagical food seasoning (such as salt, pepper, saffron, or cilantro), and remove a pinch of the desired seasoning from the pouch. A pinch is enough to season a single meal. The pouch regains {@dice 1d6 + 4} expended charges daily at dawn."
                ],
                value: "6500",
                "make": "Tabaxi"
            },
            {
                "name": "Lantern of Tracking",
                "source": "IDRotF",
                "page": 314,
                "rarity": "common",
                "wondrous": true,
                "entries": [
                    "This hooded lantern burns for 6 hours on 1 pint of oil, shedding bright light in a 30-foot radius and dim light for an additional 30 feet.",
                    "Each lantern of tracking is designed to track down a certain type of creature, which is determined by rolling on the Lantern of Tracking table. Once determined, this creature type can't be changed. While the lantern is within 300 feet of any creature of that type, its flame turns bright green. The lantern doesn't pinpoint the creature's exact location, however.",
                    {
                        "type": "table",
                        "caption": "Lantern of Tracking",
                        "colLabels": [
                            "1d10",
                            "Creature Type"
                        ],
                        "colStyles": [
                            "col-2 text-center",
                            "col-10"
                        ],
                        "rows": [
                            [
                                "1",
                                "Aberrations"
                            ],
                            [
                                "2",
                                "Celestials"
                            ],
                            [
                                "3",
                                "Constructs"
                            ],
                            [
                                "4",
                                "Dragons"
                            ],
                            [
                                "5",
                                "Elementals"
                            ],
                            [
                                "6",
                                "Fey"
                            ],
                            [
                                "7",
                                "Fiends"
                            ],
                            [
                                "8",
                                "Giants"
                            ],
                            [
                                "9",
                                "Monstrosities"
                            ],
                            [
                                "10",
                                "Undead"
                            ]
                        ]
                    }
                ],
                value: "6500",
                "make": "Tabaxi"
            },
            {
                "name": "Mystery Key",
                "source": "XGE",
                "page": 138,
                "tier": "minor",
                "rarity": "common",
                "wondrous": true,
                "entries": [
                    "A question mark is worked into the head of this key. The key has a {@chance 5} chance of unlocking any lock into which it's inserted. Once it unlocks something, the key disappears."
                ],
                value: "8000",
                "make": "Tabaxi"
            },
            {
                "name": "Breathing Bubble",
                "source": "EGW",
                "page": 266,
                "otherSources": [
                    {
                        "source": "CRCotN",
                        "page": 213
                    }
                ],
                "rarity": "common",
                "wondrous": true,
                "entries": [
                    "This translucent, bubble-like sphere has a slightly tacky outer surface, and you gain the item's benefits only while wearing it over your head like a helmet.",
                    "The bubble contains 1 hour of breathable air. The bubble regains all its expended air daily at dawn."
                ],
                value: "8500",
                "make": "Tabaxi"
            },
            {
                "name": "Potion of Comprehension",
                "source": "WDMM",
                "page": 62,
                "type": "P",
                "rarity": "common",
                "entries": [
                    "When you drink this potion, you gain the effect of a {@spell comprehend languages} spell for 1 hour. This liquid is a clear concoction with bits of salt and soot swirling in it."
                ],
                value: "2000",
                "make": "Tabaxi"
            },
            {
                "name": "Potion of Healing",
                "source": "DMG",
                "page": 187,
                "srd": true,
                "otherSources": [
                    {
                        "source": "PHB",
                        "page": 153
                    }
                ],
                "type": "P",
                "tier": "minor",
                "rarity": "common",
                "weight": 0.5,
                "entries": [
                    "You regain {@dice 2d4 + 2} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
                ],
                "lootTables": [
                    "Magic Item Table A"
                ],
                value: "5000",
                "make": "Tabaxi"
            },
            {
                "name": "Medal of the Maze",
                "source": "CRCotN",
                "page": 214,
                "rarity": "common",
                "wondrous": true,
                "entries": [
                    "When you use an action to trace the maze inscribed on this medal, you gain advantage on Wisdom checks and know the quickest route to the end of any nonmagical path or maze for 1 hour. Once this property has been used, it can't be used again, and the medal becomes nonmagical."
                ],
                "hasFluffImages": true,
                value: "4500",
                "make": "Tabaxi"
            },
            {
                "name": "Cast-Off  Breastplate",
                "source": "XGE",
                "page": 145,
                "srd": true,
                "basicRules": true,
                "type": "MA",
                "rarity": "common",
                "weight": 20,
                "value": 50000,
                "ac": 14,
                "armor": true,
                "entries": [
                    "This armor consists of a fitted metal chest piece worn with supple leather. Although it leaves the legs and arms relatively unprotected, this armor provides good protection for the wearer's vital organs while leaving the wearer relatively unencumbered."
                ],
                "make": "Tabaxi"
            }
        ],
        "uncommon": [
            {
                "name": "Amulet of the Drunkard",
                "source": "EGW",
                "page": 265,
                "rarity": "uncommon",
                "wondrous": true,
                "entries": [
                    "This amulet smells of old, ale-stained wood. While wearing it, you can regain {@dice 4d4 + 4} hit points when you drink a pint of beer, ale, mead, or wine. Once the amulet has restored hit points, it can't do so again until the next dawn."
                ],
                "hasFluffImages": true,
                "make": "Tabaxi"
            },
            {
                "name": "Elemental Gem, Blue Sapphire",
                "source": "DMG",
                "page": 167,
                value: "50000",
                "srd": true,
                "tier": "minor",
                "rarity": "uncommon",
                "wondrous": true,
                "entries": [
                    "This gem contains a mote of elemental energy. When you use an action to break the gem, an {@creature air elemental} is summoned as if you had cast the {@spell conjure elemental} spell, and the gem's magic is lost."
                ],
                "attachedSpells": [
                    "conjure elemental"
                ],
                "make": "Elemental Air"
            },
            {
                "name": "Ring of Obscuring",
                "source": "EGW",
                "page": 269,
                "type": "RG",
                "rarity": "uncommon",
                "reqAttune": true,
                "recharge": "dawn",
                "rechargeAmount": "{@dice 1d3}",
                "charges": 3,
                "entries": [
                    "This band of iron resembles a skull and is cold to the touch. It has 3 charges and regains {@dice 1d3} expended charges daily at dawn. As an action while wearing the ring, you can expend 1 of its charges to cast the {@spell fog cloud} spell from it, with the following changes: the cloud is centered on you when it first appears, and the spell lasts for 1 minute (no concentration required)."
                ],
                "attachedSpells": [
                    "fog cloud"
                ],
                "hasFluffImages": true,
                "make": "Tabaxi"
            },
            {
                "name": "Potion of Acid Resistance",
                "source": "DMG",
                "page": 188,
                "srd": true,
                "type": "P",
                "resist": [
                    "acid"
                ],
                "tier": "minor",
                "rarity": "uncommon",
                "hasRefs": true,
                value: 30000,
                "entries": [
                    "{#itemEntry Potion of Resistance}"
                ],
                "make": "Tabaxi"
            },
            {
                "name": "Sending Stone",
                "source": "AI",
                "page": 33,
                value: "50000",
                "rarity": "uncommon",
                "wondrous": true,
                "entries": [
                    "Also at rank 1, you are given use of an Acquisitions Incorporated sending stone, an uncommon magic item that resembles a gemstone in a bold setting. It functions like a normal sending stone, except that it has no matching stone and allows communication with Head Office, specific secretarians you know, and the secretarian nearest your location. You must succeed on a DC 15 Intelligence ({@skill Arcana}) check to establish contact. Once the stone is successfully used, it can't be used again until the next dawn.",
                    "Making contact with another secretarian assumes that they are in possession of their own sending stone.",
                    {
                        "type": "table",
                        "caption": "Quirks of Your Sending Stone",
                        "colLabels": [
                            "d8",
                            "quirk"
                        ],
                        "colStyles": [
                            "col-2 text-center",
                            "col-10"
                        ],
                        "rows": [
                            [
                                "1",
                                "It's a flip stone."
                            ],
                            [
                                "2",
                                "It gets great reception everywhere except in your headquarters."
                            ],
                            [
                                "3",
                                "It sometimes picks up other magical conversations."
                            ],
                            [
                                "4",
                                "It's the perfect size, shape, and weight to be used as a skipping stone."
                            ],
                            [
                                "5",
                                "It heats up when you use it, to the extent that it once burned through your gloves."
                            ],
                            [
                                "6",
                                "It has an obnoxious ringtone that you can't work out how to change."
                            ],
                            [
                                "7",
                                "It fails to notify you of incoming messages except for a faint pulsating glow."
                            ],
                            [
                                "8",
                                "It's voice activated, so that every time you talk to someone, it tries to send a message to someone else."
                            ]
                        ]
                    },
                    {
                        "type": "entries",
                        "name": "Rumor Mill",
                        "entries": [
                            "Also at rank 2, whenever your franchise begins a major quest or mission, make a DC 15 Intelligence ({@skill History}) check. On a success, you can learn up to three rumors related to creatures or organizations involved in the mission, which come to you through your sending stone. These rumors reflect current or historical knowledge possessed by Acquisitions Incorporated or the organization's many contacts."
                        ]
                    },
                    {
                        "type": "entries",
                        "name": "Improved Rumor Mill",
                        "entries": [
                            "At rank 3, when you use your Rumor Mill feature, the DM provides you with a sense of which parts of a particular rumor are inaccurate, if any. You do not necessarily learn the truth behind those false rumors."
                        ]
                    }
                ],
                "hasFluffImages": true,
                "make": "Tabaxi"
            },
            {
                "name": "Potion of Thunder Resistance",
                "source": "DMG",
                "page": 188,
                "srd": true,
                "type": "P",
                "resist": [
                    "thunder"
                ],
                "tier": "minor",
                "rarity": "uncommon",
                "hasRefs": true,
                value: 30000,
                "entries": [
                    "{#itemEntry Potion of Resistance}"
                ],
                "make": "Older Imperial"
            },
            {
                  "name": "Cloak of the Manta Ray",
                  "source": "DMG",
                  "page": 159,
                  "srd": true,
                  "tier": "minor",
                  "rarity": "uncommon",
                  "wondrous": true,
                  "modifySpeed": {
                      "static": {
                          "swim": 60
                      }
                  },
                  "entries": [
                      "While wearing this cloak with its hood up, you can breathe underwater, and you have a swimming speed of 60 feet. Pulling the hood up or down requires an action."
                  ],
                  "optionalfeatures": [
                      "replicate magic item|tce",
                      "replicate magic item|uaartificerrevisited"
                  ],
                  "lootTables": [
                      "Magic Item Table B"
                  ],
                  "hasFluffImages": true,
                  "make": "Tabaxi"
              },
              {
                  "name": "Ring of Truth Telling",
                  "source": "WDH",
                  value: "35000",
                  "page": 192,
                  "type": "RG",
                  "rarity": "uncommon",
                  "reqAttune": true,
                  "entries": [
                      "While wearing this ring, you have advantage on Wisdom ({@skill Insight}) checks to determine whether someone is lying to you."
                  ],
                  "make": "Tabaxi"
              },
              {
                  "name": "Eversmoking Bottle",
                  "source": "DMG",
                  "page": 168,
                  "srd": true,
                  "tier": "major",
                  value: "65000",
                  "rarity": "uncommon",
                  "wondrous": true,
                  "weight": 1,
                  "entries": [
                      "Smoke leaks from the lead-stoppered mouth of this brass bottle, which weighs 1 pound. When you use an action to remove the stopper, a cloud of thick smoke pours out in a 60-foot radius from the bottle. The cloud's area is heavily obscured. Each minute the bottle remains open and within the cloud, the radius increases by 10 feet until it reaches its maximum radius of 120 feet.",
                      "The cloud persists as long as the bottle is open. Closing the bottle requires you to speak its command word as an action. Once the bottle is closed, the cloud disperses after 10 minutes. A moderate wind (11 to 20 miles per hour) can also disperse the smoke after 1 minute, and a strong wind (21 or more miles per hour) can do so after 1 round."
                  ],
                  "lootTables": [
                      "Magic Item Table F"
                  ],
                  "hasFluffImages": true,
                  "make": "Tabaxi"
              }
        ],
        "rare": [
              {
                "name": "Elven Chain",
                "source": "DMG",
                "page": 168,
                "srd": true,
                "type": "MA",
                "tier": "major",
                "rarity": "rare",
                "weight": 20,
                "ac": 13,
                "grantsProficiency": true,
                "bonusAc": "+1",
                "entries": [
                    "You gain a +1 bonus to AC while you wear this armor. You are considered proficient with this armor even if you lack proficiency with medium armor."
                ],
                "lootTables": [
                    "Magic Item Table G"
                ],
                "hasFluffImages": true,
                "make": "Lunar Elven"
            },
            {
              "name": "Butcher's Bib",
              "source": "EGW",
              value: "425000",
              make: "Older Imperial",
              "page": 266,
              "rarity": "rare",
              "reqAttune": true,
              "wondrous": true,
              "entries": [
              "This black leather apron is perpetually covered by blood, even after being washed off. You gain the following benefits while wearing the apron:",
                {
                  "type": "list",
                  "items": [
                    "Once per turn when you roll damage for a melee attack with a weapon, you can reroll the weapon's damage dice. If you do so, you must use the second total.",
                    "Your weapon attacks that deal slashing damage score a critical hit on a roll of 19 or 20 on the {@dice d20}."
                  ]
                }
              ],
          },
        ],
        "very rare": [
            {
                "name": "Red Dragon Scale Mail",
                "source": "DMG",
                "page": 165,
                "srd": true,
                "type": "MA",
                "resist": [
                    "fire"
                ],
                "tier": "major",
                value: "?",
                "rarity": "very rare",
                "reqAttune": true,
                "weight": 45,
                "ac": 14,
                "bonusAc": "+1",
                "bonusSavingThrow": "+1",
                "stealth": true,
                "entries": [
                    "Dragon scale mail is made of the scales of one kind of dragon. Sometimes dragons collect their cast-off scales and gift them to humanoids. Other times, hunters carefully skin and preserve the hide of a dead dragon. In either case, dragon scale mail is highly valued. While wearing this armor, you gain a +1 bonus to AC, you have advantage on saving throws against the Frightful Presence and breath weapons of dragons, and you have resistance to fire damage.",
                    "Additionally, you can focus your senses as an action to magically discern the distance and direction to the closest red dragon within 30 miles of you. This special action can't be used again until the next dawn."
                ],
                "make": "Tabaxi"
            },
            {
              "name": "Dispelling Stone",
              "source": "EGW",
              "wondrous": true,
            }
        ],
        "legendary": [],
        "artifact": []
      },

    }
  },

  {
    name: "Nature's Blessing and Curse",
    src: "https://64.media.tumblr.com/d3be041f49739a32abae123bad538364/tumblr_p9gtydNQva1tqcvoho1_500.jpg",
    blurb: "Oshra Beach",
    parentLocation: "thon_daral",
    items: {
      potions: {
        "none": [
            {
                "name": "Lover's Bane Antidote",
                "source": "DMG",
                "type": "P",
                "rarity": "common",
                "make": "Lizardfolk"
            },
            {
                "name": "Lover's Bane Antidote",
                "source": "DMG",
                "type": "P",
                "rarity": "common",
                "make": "Lizardfolk"
            },
            {
                "name": "Lover's Bane Antidote",
                "source": "DMG",
                "type": "P",
                "rarity": "common",
                "make": "Lizardfolk"
            },
        ],
        "common": [
            {
                "name": "Potion of Comprehension",
                "source": "WDMM",
                "page": 62,
                "type": "P",
                "rarity": "common",
                "entries": [
                    "When you drink this potion, you gain the effect of a {@spell comprehend languages} spell for 1 hour. This liquid is a clear concoction with bits of salt and soot swirling in it."
                ],
                "make": "Lizardfolk"
            },
            {
                "name": "Potion of Healing",
                "source": "DMG",
                "page": 187,
                "srd": true,
                "otherSources": [
                    {
                        "source": "PHB",
                        "page": 153
                    }
                ],
                "type": "P",
                "tier": "minor",
                "rarity": "common",
                "weight": 0.5,
                "value": 5000,
                "entries": [
                    "You regain {@dice 2d4 + 2} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
                ],
                "lootTables": [
                    "Magic Item Table A"
                ],
                "make": "Lizardfolk"
            },
            {
                "name": "Potion of Comprehension",
                "source": "WDMM",
                "page": 62,
                "type": "P",
                "rarity": "common",
                "entries": [
                    "When you drink this potion, you gain the effect of a {@spell comprehend languages} spell for 1 hour. This liquid is a clear concoction with bits of salt and soot swirling in it."
                ],
                "make": "Lizardfolk"
            },
            {
                "name": "Potion of Comprehension",
                "source": "WDMM",
                "page": 62,
                "type": "P",
                "rarity": "common",
                "entries": [
                    "When you drink this potion, you gain the effect of a {@spell comprehend languages} spell for 1 hour. This liquid is a clear concoction with bits of salt and soot swirling in it."
                ],
                "make": "Lizardfolk"
            },
            {
                "name": "Potion of Healing",
                "source": "DMG",
                "page": 187,
                "srd": true,
                "otherSources": [
                    {
                        "source": "PHB",
                        "page": 153
                    }
                ],
                "type": "P",
                "tier": "minor",
                "rarity": "common",
                "weight": 0.5,
                "value": 5000,
                "entries": [
                    "You regain {@dice 2d4 + 2} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
                ],
                "lootTables": [
                    "Magic Item Table A"
                ],
                "make": "Lizardfolk"
            },
            {
                "name": "Potion of Healing",
                "source": "DMG",
                "page": 187,
                "srd": true,
                "otherSources": [
                    {
                        "source": "PHB",
                        "page": 153
                    }
                ],
                "type": "P",
                "tier": "minor",
                "rarity": "common",
                "weight": 0.5,
                "value": 5000,
                "entries": [
                    "You regain {@dice 2d4 + 2} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
                ],
                "lootTables": [
                    "Magic Item Table A"
                ],
                "make": "Lizardfolk"
            },
            {
                "name": "Potion of Healing",
                "source": "DMG",
                "page": 187,
                "srd": true,
                "otherSources": [
                    {
                        "source": "PHB",
                        "page": 153
                    }
                ],
                "type": "P",
                "tier": "minor",
                "rarity": "common",
                "weight": 0.5,
                "value": 5000,
                "entries": [
                    "You regain {@dice 2d4 + 2} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
                ],
                "lootTables": [
                    "Magic Item Table A"
                ],
                "make": "Lizardfolk"
            },
            {
                "name": "Potion of Healing",
                "source": "DMG",
                "page": 187,
                "srd": true,
                "otherSources": [
                    {
                        "source": "PHB",
                        "page": 153
                    }
                ],
                "type": "P",
                "tier": "minor",
                "rarity": "common",
                "weight": 0.5,
                "value": 5000,
                "entries": [
                    "You regain {@dice 2d4 + 2} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
                ],
                "lootTables": [
                    "Magic Item Table A"
                ],
                "make": "Lizardfolk"
            },
            {
                "name": "Potion of Healing",
                "source": "DMG",
                "page": 187,
                "srd": true,
                "otherSources": [
                    {
                        "source": "PHB",
                        "page": 153
                    }
                ],
                "type": "P",
                "tier": "minor",
                "rarity": "common",
                "weight": 0.5,
                "value": 5000,
                "entries": [
                    "You regain {@dice 2d4 + 2} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
                ],
                "lootTables": [
                    "Magic Item Table A"
                ],
                "make": "Lizardfolk"
            },
            {
                "name": "Potion of Watchful Rest",
                "source": "WDMM",
                "page": 62,
                "type": "P",
                "rarity": "common",
                "entries": [
                    "When you drink this potion, you gain the following benefits for the next 8 hours: magic can't put you to sleep, and you can remain awake during a long rest and still gain its benefits. This sweet, amber-colored brew has no effect on creatures that don't require sleep, such as elves."
                ],
                "make": "Lizardfolk"
            },
            {
                "name": "Potion of Watchful Rest",
                "source": "WDMM",
                "page": 62,
                "type": "P",
                "rarity": "common",
                "entries": [
                    "When you drink this potion, you gain the following benefits for the next 8 hours: magic can't put you to sleep, and you can remain awake during a long rest and still gain its benefits. This sweet, amber-colored brew has no effect on creatures that don't require sleep, such as elves."
                ],
                "make": "Lizardfolk"
            },
        ],
        "uncommon": [
            {
                "name": "Oil of Slipperiness",
                "source": "DMG",
                "type": "P",
                value: "40000",
                "rarity": "uncommon",
                "make": "Lizardfolk"
            },
            {
                "name": "Potion of Hill Giant Strength",
                "source": "DMG",
                "type": "P",
                "rarity": "uncommon",
                "make": "Lizardfolk"
            },
             {
                "name": "Potion of Growth",
                "source": "DMG",
                value: "27000",
                "type": "P",
                "rarity": "uncommon",
                "make": "Lizardfolk"
            },
            {
                "name": "Potion of Psychic Resistance",
                "source": "DMG",
                "page": 188,
                "srd": true,
                "type": "P",
                "resist": [
                    "psychic"
                ],
                "tier": "minor",
                "rarity": "uncommon",
                "hasRefs": true,
                value: 30000,
                "entries": [
                    "{#itemEntry Potion of Resistance}"
                ],
                "make": "Lizardfolk"
            },
            {
                "name": "Potion of Greater Healing",
                value: "10000",
                "source": "DMG",
                "page": 187,
                "srd": true,
                "type": "P",
                "tier": "minor",
                "rarity": "uncommon",
                "entries": [
                    "You regain {@dice 4d4 + 4} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
                ],
                "lootTables": [
                    "Magic Item Table A",
                    "Magic Item Table B"
                ],
                "make": "Lizardfolk"
            },
            {
                "name": "Potion of Greater Healing",
                value: "10000",
                "source": "DMG",
                "page": 187,
                "srd": true,
                "type": "P",
                "tier": "minor",
                "rarity": "uncommon",
                "entries": [
                    "You regain {@dice 4d4 + 4} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
                ],
                "lootTables": [
                    "Magic Item Table A",
                    "Magic Item Table B"
                ],
                "make": "Lizardfolk"
            },
            {
                "name": "Potion of Greater Healing",
                value: "10000",
                "source": "DMG",
                "page": 187,
                "srd": true,
                "type": "P",
                "tier": "minor",
                "rarity": "uncommon",
                "entries": [
                    "You regain {@dice 4d4 + 4} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
                ],
                "lootTables": [
                    "Magic Item Table A",
                    "Magic Item Table B"
                ],
                "make": "Lizardfolk"
            },
            {
                "name": "Potion of Greater Healing",
                value: "10000",
                "source": "DMG",
                "page": 187,
                "srd": true,
                "type": "P",
                "tier": "minor",
                "rarity": "uncommon",
                "entries": [
                    "You regain {@dice 4d4 + 4} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
                ],
                "lootTables": [
                    "Magic Item Table A",
                    "Magic Item Table B"
                ],
                "make": "Lizardfolk"
            },
            {
                "name": "Philter of Love",
                "source": "DMG",
                "page": 184,
                value: "90000",
                "srd": true,
                "type": "P",
                "tier": "minor",
                "rarity": "uncommon",
                "entries": [
                    "The next time you see a creature within 10 minutes after drinking this philter, you become {@condition charmed} by that creature for 1 hour. If the creature is of a species and gender you are normally attracted to, you regard it as your true love while you are {@condition charmed}. This potion's rose-hued, effervescent liquid contains one easy-to-miss bubble shaped like a heart."
                ],
                "lootTables": [
                    "Magic Item Table B"
                ],
                "make": "Lizardfolk"
            },
            {
                "name": "Potion of Force Resistance",
                "source": "DMG",
                "page": 188,
                "srd": true,
                "type": "P",
                "resist": [
                    "force"
                ],
                "tier": "minor",
                "rarity": "uncommon",
                "hasRefs": true,
                value: 30000,
                "entries": [
                    "{#itemEntry Potion of Resistance}"
                ],
                "make": "Lizardfolk"
            },
            {
                "name": "Blood of the Lycanthrope Antidote",
                "source": "IMR",
                "page": 94,
                "type": "P",
                "rarity": "uncommon",
                "entries": [
                    "This clear potion has dark red flecks within, resembling clotted blood. When you drink this potion, it removes the curse of lycanthropy from you if that curse was imposed by a lycanthrope's bite or similar effect."
                ],
                "make": "Lizardfolk"
            },
            {
                "name": "Potion of Poison Resistance",
                "source": "DMG",
                "page": 188,
                "srd": true,
                "type": "P",
                "resist": [
                    "poison"
                ],
                "tier": "minor",
                "rarity": "uncommon",
                "hasRefs": true,
                value: 30000,
                "entries": [
                    "{#itemEntry Potion of Resistance}"
                ],
                "make": "Lizardfolk"
            }
        ],
        "rare": [

            {
                "name": "Oil of Etherealness",
                "source": "DMG",
                "type": "P",
                "rarity": "rare",
                "make": "Lizardfolk",
                value: "190000"

            },
            {
                "name": "Potion of Clairvoyance",
                "source": "DMG",
                "type": "P",
                "rarity": "rare",
                "make": "Lizardfolk",
                value: "95000"
            },
            {
                "name": "Potion of Maximum Power",
                "source": "EGW",
                value: 25000,
                "page": 268,
                "type": "P",
                "rarity": "rare",
                "entries": [
                    "The first time you cast a damage-dealing spell of 4th level or lower within 1 minute after drinking the potion, instead of rolling dice to determine the damage dealt, you can instead use the highest number possible for each die.",
                    "This glowing purple liquid smells of sugar and plum, but it has a muddy taste."
                ],
                "make": "Lizardfolk"
            },
            {
                "name": "Potion of Mind Reading",
                value: 18000,
                "source": "DMG",
                "page": 188,
                "srd": true,
                "type": "P",
                "tier": "minor",
                "rarity": "rare",
                "entries": [
                    "When you drink this potion, you gain the effect of the {@spell detect thoughts} spell (save DC 13). The potion's dense, purple liquid has an ovoid cloud of pink floating in it."
                ],
                "attachedSpells": [
                    "detect thoughts"
                ],
                "lootTables": [
                    "Magic Item Table C"
                ],
                "make": "Lizardfolk"
            },
            {
                "name": "Potion of Superior Healing",
                value: "50000",
                "source": "DMG",
                "page": 187,
                "srd": true,
                "type": "P",
                "tier": "minor",
                "rarity": "rare",
                "entries": [
                    "You regain {@dice 8d4 + 8} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
                ],
                "lootTables": [
                    "Magic Item Table C"
                ],
                "make": "Lizardfolk"
            },
             {
                "name": "Potion of Heroism",
                value: 18000,
                "source": "DMG",
                "page": 188,
                "srd": true,
                "type": "P",
                "tier": "minor",
                "rarity": "rare",
                "entries": [
                    "For 1 hour after drinking it, you gain 10 temporary hit points that last for 1 hour. For the same duration, you are under the effect of the {@spell bless} spell (no concentration required). This blue potion bubbles and steams as if boiling."
                ],
                "attachedSpells": [
                    "bless"
                ],
                "lootTables": [
                    "Magic Item Table C"
                ],
                "make": "Lizardfolk"
            },
            {
                "name": "Potion of Heroism",
                value: 18000,
                "source": "DMG",
                "page": 188,
                "srd": true,
                "type": "P",
                "tier": "minor",
                "rarity": "rare",
                "entries": [
                    "For 1 hour after drinking it, you gain 10 temporary hit points that last for 1 hour. For the same duration, you are under the effect of the {@spell bless} spell (no concentration required). This blue potion bubbles and steams as if boiling."
                ],
                "attachedSpells": [
                    "bless"
                ],
                "lootTables": [
                    "Magic Item Table C"
                ],
                "make": "Lizardfolk"
            }
        ],
        "very rare": [
            {
                "name": "Potion of Invisibility",
                "source": "DMG",
                "page": 188,
                "srd": true,
                "basicRules": true,
                "type": "P",
                value: 50000,
                "tier": "minor",
                "rarity": "very rare",
                "entries": [
                    "This potion's container looks empty but feels as though it holds liquid. When you drink it, you become {@condition invisible} for 1 hour. Anything you wear or carry is {@condition invisible} with you. The effect ends early if you attack or cast a spell."
                ],
                "lootTables": [
                    "Magic Item Table D"
                ],
                "make": "Lizardfolk"
            }
        ],
        "legendary": [],
        "artifact": []
      },
      poisons: {
        hidden: {
          "none": [
            {
              "name": "Basic Poison (vial)",
              "source": "PHB",
              "page": 153,
              "srd": true,
              "basicRules": true,
              "type": "G",
              "rarity": "none",
              "value": 15000,
              "poison": true,
              madeBy: "Lizardfolk",
              "entries": [
              "You can use the poison in this vial to coat one slashing or piercing weapon or up to three pieces of ammunition. Applying the poison takes an action. A creature hit by the poisoned weapon or ammunition must make a DC 10 Constitution saving throw or take {@damage 1d4} poison damage. Once applied, the poison retains potency for 1 minute before drying."
              ]
            },
            {
            "name": "Burnt Othur Fumes",
            "source": "DMG",
            "page": 258,
            "srd": true,
            "type": "G",
            "rarity": "none",
            "value": 55000,
            "poison": true,
            madeBy: "Lizardfolk",
            "entries": [
            "A creature subjected to this poison must succeed on a DC 13 Constitution saving throw or take 10 ({@damage 3d6}) poison damage, and must repeat the saving throw at the start of each of its turns. On each successive failed save, the character takes 3 ({@damage 1d6}) poison damage. After three successful saves, the poison ends."
            ],
            "poisonTypes": [
            "inhaled"
            ]
            },
            {
            "name": "Carrion Crawler Mucus",
            "source": "DMG",
            "page": 258,
            "srd": "Crawler Mucus (Contact)",
            "type": "G",
            "rarity": "none",
            "value": 25000,
            "poison": true,
            madeBy: "Lizardfolk",
            "entries": [
            "This poison must be harvested from a dead or {@condition incapacitated} {@creature carrion crawler}. A creature subjected to this poison must succeed on a DC 13 Constitution saving throw or be {@condition poisoned} for 1 minute. The {@condition poisoned} creature is {@condition paralyzed}. The creature can repeat the saving throw at the end of each of its turns, ending the effect on itself on a success."
            ],
            "poisonTypes": [
            "contact"
            ]
            },
            {
            "name": "Oil of Taggit",
            "source": "DMG",
            "page": 258,
            "srd": true,
            "type": "G",
            "rarity": "none",
            "value": 45000,
            "poison": true,
            madeBy: "Lizardfolk",
            "entries": [
            "A creature subjected to this poison must succeed on a DC 13 Constitution saving throw or become {@condition poisoned} for 24 hours. The {@condition poisoned} creature is {@condition unconscious}. The creature wakes up if it takes damage."
            ],
            "poisonTypes": [
            "contact"
            ]
            },
            {
            "name": "Torpor",
            "source": "DMG",
            "page": 258,
            "srd": true,
            "type": "G",
            "rarity": "none",
            "value": 65000,
            "poison": true,
            madeBy: "Lizardfolk",
            "entries": [
            "A creature subjected to this poison must succeed on a DC 15 Constitution saving throw or become {@condition poisoned} for {@dice 4d6} hours. The {@condition poisoned} creature is {@condition incapacitated}."
            ],
            "poisonTypes": [
            "ingested"
            ]
            },
            {
            "name": "Truth Serum",
            "source": "DMG",
            "page": 258,
            "srd": true,
            "type": "G",
            "rarity": "none",
            "value": 15000,
            "poison": true,
            madeBy: "Lizardfolk",
            "entries": [
            "A creature subjected to this poison must succeed on a DC 11 Constitution saving throw or become {@condition poisoned} for 1 hour. The {@condition poisoned} creature can't knowingly speak a lie, as if under the effect of a {@spell zone of truth} spell."
            ],
            "poisonTypes": [
            "ingested"
            ],
            "attachedSpells": [
            "zone of truth"
            ]
            },
            {
            "name": "Purple Worm Poison",
            "source": "DMG",
            "page": 258,
            "srd": true,
            "type": "G",
            "rarity": "none",
            "value": 205000,
            "poison": true,
            madeBy: "Lizardfolk",
            "entries": [
            "This poison must be harvested from a dead or {@condition incapacitated} {@creature purple worm}. A creature subjected to this poison must make a DC 19 Constitution saving throw, taking 42 ({@damage 12d6}) poison damage on a failed save, or half as much damage on a successful one."
            ],
            "poisonTypes": [
            "injury"
            ]
            },
            {
            "name": "Pale Tincture",
            "source": "DMG",
            "page": 258,
            "srd": true,
            "type": "G",
            "rarity": "none",
            "value": 25000,
            "poison": true,
            madeBy: "Lizardfolk",
            "entries": [
            "A creature subjected to this poison must succeed on a DC 16 Constitution saving throw or take 3 ({@damage 1d6}) poison damage and become {@condition poisoned}. The {@condition poisoned} creature must repeat the saving throw every 24 hours, taking 3 ({@damage 1d6}) poison damage on a failed save. Until this poison ends, the damage the poison deals can't be healed by any means. After seven successful saving throws, the effect ends and the creature can heal normally."
            ],
            "poisonTypes": [
            "ingested"
            ]
            },
            {
            "name": "Essence of Ether",
            "source": "DMG",
            "page": 258,
            "srd": true,
            "type": "G",
            "rarity": "none",
            "value": 32500,
            "poison": true,
            madeBy: "Lizardfolk",
            "entries": [
            "A creature subjected to this poison must succeed on a DC 15 Constitution saving throw or become {@condition poisoned} for 8 hours. The {@condition poisoned} creature is {@condition unconscious}. The creature wakes up if it takes damage or if another creature takes an action to shake it awake."
            ],
            "poisonTypes": [
            "inhaled"
            ]
            },
            {
            "name": "Midnight Tears",
            "source": "DMG",
            "page": 258,
            "srd": true,
            "type": "G",
            "rarity": "none",
            "value": 155000,
            "poison": true,
            madeBy: "Lizardfolk",
            "entries": [
            "A creature that ingests this poison suffers no effect until the stroke of midnight. If the poison has not been neutralized before then, the creature must succeed on a DC 17 Constitution saving throw, taking 31 ({@damage 9d6}) poison damage on a failed save, or half as much damage on a successful one."
            ],
            "poisonTypes": [
            "ingested"
            ]
            },
            {
            "name": "Malice",
            "source": "DMG",
            "page": 258,
            "srd": true,
            "type": "G",
            "rarity": "none",
            "value": 25000,
            "poison": true,
            madeBy: "Lizardfolk",
            "entries": [
            "A creature subjected to this poison must succeed on a DC 15 Constitution saving throw or become {@condition poisoned} for 1 hour. The {@condition poisoned} creature is {@condition blinded}."
            ],
            "poisonTypes": [
            "inhaled"
            ]
            },
            {
            "name": "Wyvern Poison",
            "source": "DMG",
            "page": 258,
            "srd": true,
            "type": "G",
            "rarity": "none",
            "value": 125000,
            "poison": true,
            madeBy: "Lizardfolk",
            "entries": [
            "This poison must be harvested from a dead or {@condition incapacitated} {@creature wyvern}. A creature subjected to this poison must make a DC 15 Constitution saving throw, taking 24 ({@damage 7d6}) poison damage on a failed save, or half as much damage on a successful one."
            ],
            "poisonTypes": [
            "injury"
            ]
            },
          ]
        },
        shown: {
          none: [
            {
              name: "None...poisons are dangerous and...expensive...oooh look over there"
            }
          ]
        }
      }
    }
  },
  {
    name: "Forked Tongue Fletching",
    blurb: "Yashazha",
    parentLocation: "thon_daral",
    src: "https://i.imgur.com/5eNsbn3.png",
    items:{
      "ammo": {
        "none": [
            {
                "name": "Arrow",
                "source": "PHB",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "none",
                "weight": 0.05,
                "value": 5,
                "make": "Yuan-Ti"
            },
            {
                "name": "Sling Bullets (20)",
                "source": "PHB",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "none",
                "weight": 1.5,
                "value": 4,
                "packContents": [
                    {
                        "item": "sling bullet|phb",
                        "quantity": 20
                    }
                ],
                "make": "Yuan-Ti"
            },
            {
                "name": "Blowgun Needle",
                "source": "PHB",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "none",
                "weight": 0.02,
                "value": 2,
                "make": "Yuan-Ti"
            },
            {
                "name": "Arrows (20)",
                "source": "PHB",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "none",
                "weight": 1,
                "value": 100,
                "packContents": [
                    {
                        "item": "arrow|phb",
                        "quantity": 20
                    }
                ],
                "make": "Yuan-Ti"
            },
            {
                "name": "Sling Bullet",
                "source": "PHB",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "none",
                "weight": 0.075,
                "value": 0.2,
                "make": "Yuan-Ti"
            },
            {
                "name": "Sling Bullet",
                "source": "PHB",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "none",
                "weight": 0.075,
                "value": 0.2,
                "make": "Yuan-Ti"
            },
            {
                "name": "Blowgun Needles (50)",
                "source": "PHB",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "none",
                "weight": 1,
                "value": 100,
                "packContents": [
                    {
                        "item": "blowgun needle|phb",
                        "quantity": 50
                    }
                ],
                "make": "Yuan-Ti"
            },
            {
                "name": "Crossbow Bolts (20)",
                "source": "PHB",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "none",
                "weight": 1.5,
                "value": 100,
                "packContents": [
                    {
                        "item": "crossbow bolt|phb",
                        "quantity": 20
                    }
                ],
                "make": "Yuan-Ti"
            },
            {
                "name": "Arrow",
                "source": "PHB",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "none",
                "weight": 0.05,
                "value": 5,
                "make": "Yuan-Ti"
            },
            {
                "name": "Javelin (20)",
                "source": "PHB",
                "page": 149,
                "srd": true,
                "basicRules": true,
                "type": "M",
                "rarity": "none",
                "weight": 2,
                "value": 50,
                "weaponCategory": "simple",
                "property": [
                  "T"
                ],
                "range": "30/120",
                "dmg1": "1d6",
                "dmgType": "P",
                "spear": true,
                "weapon": true,
                make: "Yuan-Ti"
            },
            {
                "name": "Javelin",
                "source": "PHB",
                "page": 149,
                "srd": true,
                "basicRules": true,
                "type": "M",
                "rarity": "none",
                "weight": 2,
                "value": 50,
                "weaponCategory": "simple",
                "property": [
                  "T"
                ],
                "range": "30/120",
                "dmg1": "1d6",
                "dmgType": "P",
                "spear": true,
                "weapon": true,
                make: "Yuan-Ti"
            },
            {
                "name": "Javelin",
                "source": "PHB",
                "page": 149,
                "srd": true,
                "basicRules": true,
                "type": "M",
                "rarity": "none",
                "weight": 2,
                "value": 50,
                "weaponCategory": "simple",
                "property": [
                  "T"
                ],
                "range": "30/120",
                "dmg1": "1d6",
                "dmgType": "P",
                "spear": true,
                "weapon": true,
                make: "Yuan-Ti"
            }
        ],
        "common": [
            {
                "name": "Walloping  Crossbow Bolts (20)",
                "source": "XGE",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "common",
                "weight": 1.5,
                "value": 10000,
                "packContents": [
                    {
                        "item": "crossbow bolt|phb",
                        "quantity": 20
                    }
                ],
                "make": "Yuan-Ti"
            },
            {
                "name": "Walloping  Arrows (20)",
                "source": "XGE",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "common",
                "weight": 0.05,
                "value": 10000,
                "make": "Yuan-Ti"
            },
            {
                "name": "Unbreakable Arrow",
                "source": "XGE",
                "page": 139,
                "type": "A",
                "tier": "minor",
                "value": 10,
                "rarity": "common",
                "entries": [
                    "This arrow can't be broken, except when it is within an {@spell antimagic field}."
                ],
                "hasFluffImages": true,
                "make": "Yuan-Ti"
            },
            {
                "name": "Grappling Hook Arrow",
                "source": "???",
                value: "300",
                "type": "A",
                "tier": "minor",
                "rarity": "common",
                "entries": [
                    "This arrow will hold 300lbs while attached to 100ft of rope"
                ],
                "make": "Yuan-Ti"
            },
            {
                "name": "Water Arrow",
                "source": "???",
                value: "300",
                "type": "A",
                "tier": "minor",
                "rarity": "common",
                "entries": [
                    "This arrow bursts into burst of water to put out all fire in 5 feet"
                ],
                "make": "Yuan-Ti"
            },
            {
                "name": "Trickstrike Arrow ",
                "source": "???",
                value: "300",
                "type": "A",
                "tier": "minor",
                "rarity": "common",
                "entries": [
                    "This arrow teleports mid-firing to appear elsewhere to confuse foe"
                ],
                "make": "Yuan-Ti"
            },
            {
                "name": "Alchemist Fire Arrow",
                "source": "???",
                value: "200",
                "type": "A",
                "tier": "minor",
                "rarity": "common",
                "entries": [
                    "Arrow has vial of Alchemical Fire, additional 1d4 fire damage"
                ],
                "make": "Yuan-Ti"
            },
            {
                "name": "Vampiric Arrow",
                "source": "???",
                value: "300",
                "type": "A",
                "tier": "minor",
                "rarity": "common",
                "entries": [
                    "Recover the piercing damage dealt by the bow as vampiric tendrils leech life back to you"
                ],
                "make": "Yuan-Ti"
            },
            {
                "name": "Unbreakable Arrows (20)",
                "source": "XGE",
                "page": 139,
                value: "1000",
                "type": "A",
                "tier": "minor",
                "rarity": "common",
                "entries": [
                    "This arrow can't be broken, except when it is within an {@spell antimagic field}."
                ],
                "hasFluffImages": true,
                "make": "Yuan-Ti"
            },
            {
                "name": "Walloping  Crossbow Bolts (20)",
                "source": "XGE",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "common",
                "weight": 1.5,
                "value": 1000,
                "packContents": [
                    {
                        "item": "crossbow bolt|phb",
                        "quantity": 20
                    }
                ],
                "make": "Yuan-Ti"
            },
            {
                "name": "Walloping  Crossbow Bolts (20)",
                "source": "XGE",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "common",
                "weight": 1.5,
                "value": 1000,
                "packContents": [
                    {
                        "item": "crossbow bolt|phb",
                        "quantity": 20
                    }
                ],
                "make": "Yuan-Ti"
            },
            {
                "name": "Walloping  Blowgun Needles (50)",
                "source": "XGE",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "common",
                "weight": 1,
                "value": 1000,
                "packContents": [
                    {
                        "item": "blowgun needle|phb",
                        "quantity": 50
                    }
                ],
                "make": "Yuan-Ti"
            },
            {
                "name": "Walloping  Crossbow Bolts (20)",
                "source": "XGE",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "common",
                "weight": 1.5,
                "value": 1000,
                "packContents": [
                    {
                        "item": "crossbow bolt|phb",
                        "quantity": 20
                    }
                ],
                "make": "Yuan-Ti"
            },
            {
                "name": "Walloping  Sling Bullets (20)",
                "source": "XGE",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "common",
                "weight": 1.5,
                "value": 1000,
                "packContents": [
                    {
                        "item": "sling bullet|phb",
                        "quantity": 20
                    }
                ],
                "make": "Yuan-Ti"
            }
        ],
        "uncommon": [
            {
                "name": "+1  Arrows (20)",
                "source": "DMG",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "uncommon",
                "weight": 1,
                "value": 40000,
                "packContents": [
                    {
                        "item": "arrow|phb",
                        "quantity": 20
                    }
                ],
                "make": "Yuan-Ti"
            },
            {
                "name": "+1  Arrow",
                "source": "DMG",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "uncommon",
                "weight": 0.05,
                "value": 2500,
                "make": "Yuan-Ti"
            },
            {
                "name": "+1  Crossbow Bolts (20)",
                "source": "DMG",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "uncommon",
                "weight": 1.5,
                "value": 40000,
                "packContents": [
                    {
                        "item": "crossbow bolt|phb",
                        "quantity": 20
                    }
                ],
                "make": "Yuan-Ti"
            },
            {
                "name": "+1  Blowgun Needle",
                "source": "DMG",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "uncommon",
                "weight": 0.02,
                "value": 1500,
                "make": "Yuan-Ti"
            },
            {
                "name": "+1  Blowgun Needles (50)",
                "source": "DMG",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "uncommon",
                "weight": 1,
                "value": 70000,
                "packContents": [
                    {
                        "item": "blowgun needle|phb",
                        "quantity": 50
                    }
                ],
                "make": "Yuan-Ti"
            },
            {
                "name": "+1  Arrow",
                "source": "DMG",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "uncommon",
                "weight": 2500,
                "value": 2500,
                "make": "Elven"
            },
            {
                "name": "Fireburst Arrow",
                value: "10000",
                "source": "???",
                "type": "A",
                "tier": "minor",
                "rarity": "uncommon",
                "entries": [
                    "Instead of hitting, this arrow explodes at point in range. Each creature in 10 foot radius must make a Dex Save Against Spell/Battle Master DC to do 6d6 damage or half on save"
                ],
                "make": "Yuan-Ti"
            },
            {
                "name": "Flash Freeze Arrow",
                value: "10000",
                "source": "???",
                "type": "A",
                "tier": "minor",
                "rarity": "uncommon",
                "entries": [
                    "Instead of hitting, this arrow explodes at point in range. Each creature must make a Con Save Against Spell/Battle Master DC to do 3d8 damage in 15 foot radius and for 1 minutes frozen restrained unless an action is taken to free them. On save half damage and no freezing"
                ],
                "make": "Yuan-Ti"
            },
            {
                "name": "+1 Javelin",
                make: "Gnome",
                "source": "PHB",
                "type": "M",
                "rarity": "none",
                "value": 2500,
                "weaponCategory": "simple",
                "range": "30/120",
                "spear": true,
                "weapon": true
            },
            {
                "name": "+1 Yklwa",
                "source": "ToA",
                "type": "M",
                "rarity": "none",
                "value": 42500,
                "weaponCategory": "simple",
                "weapon": true,
                "make": "Timocracy Dragonborn"
            },
        ],
        "rare": [
            {
                "name": "+2  Arrow",
                "source": "DMG",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "rare",
                "weight": 0.05,
                "value": 7500,
                "make": "Yuan-Ti"
            },
            {
                "name": "+2  Crossbow Bolt",
                "source": "DMG",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "rare",
                "weight": 1.5,
                "value": 7500,
                "packContents": [
                    {
                        "item": "crossbow bolt|phb",
                        "quantity": 20
                    }
                ],
                "make": "Yuan-Ti"
            },
            {
                "name": "+2  Sling Bullet",
                "source": "DMG",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "rare",
                "weight": 1.5,
                "value": 7000,
                "packContents": [
                    {
                        "item": "sling bullet|phb",
                        "quantity": 20
                    }
                ],
                "make": "Yuan-Ti"
            },
            {
                "name": "+2  Blowgun Needle",
                "source": "DMG",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "rare",
                "weight": 0.02,
                "value": 6500,
                "make": "Yuan-Ti"
            },
            {
                "name": "+2  Blowgun Needles (50)",
                "source": "DMG",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "rare",
                "weight": 1,
                "value": 30000,
                "packContents": [
                    {
                        "item": "blowgun needle|phb",
                        "quantity": 50
                    }
                ],
                "make": "Yuan-Ti"
            },
            {
                "name": "+2  Blowgun Needle",
                "source": "DMG",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "rare",
                "weight": 0.02,
                "value": 6500,
                "make": "Lizardfolk"
            },
            {
                "name": "+2  Arrow",
                "source": "DMG",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "rare",
                "weight": 1,
                "value": 7500,
                "packContents": [
                    {
                        "item": "arrow|phb",
                        "quantity": 20
                    }
                ],
                "make": "Gnome Isle Elven"
            }
        ],
        "very rare": [
            {
                "name": "Arrow  of Giant Slaying",
                "source": "DMG",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "very rare",
                "weight": 0.075,
                "value": "?",
                "make": "Yuan-Ti"
            },
            {
                "name": "Arrow  of Elemental Slaying",
                "source": "DMG",
                "page": 150,
                "srd": true,
                "basicRules": true,
                "type": "A",
                "rarity": "very rare",
                "weight": 0.075,
                "value": "?",
                "make": "Yuan-Ti"
            }
        ],
        "legendary": [],
        "artifact": []
      },
      weapons: {
          "none": [
              {
                "name": "Longbow",
                "source": "PHB",
                "type": "R",
                "rarity": "none",
                "value": 5000,
                "weaponCategory": "martial",
                "bow": true,
                "weapon": true,
                "make": "Yuan-Ti"
            },
            {
                "name": "Light Crossbow",
                make: "Gnome",
                "source": "PHB",
                "type": "R",
                "rarity": "none",
                "value": 2500,
                "weaponCategory": "simple",
                "range": "80/320",
                "crossbow": true,
                "weapon": true,
            },
          ],
          "uncommon": [
            {
                "name": "+1 Light Crossbow",
                make: "Newer Imperial",
                "source": "PHB",
                "type": "R",
                "rarity": "none",
                "value": 2500,
                "weaponCategory": "simple",
                "range": "80/320",
                "crossbow": true,
                "weapon": true,
            },
          ],
          "rare": [
            {
                "name": "Light Crossbow of Certain Death",
                make: "Older Imperial",
                "source": "EGW",
                "type": "R",
                "rarity": "none",
                "value": 2500,
                "weaponCategory": "simple",
                "range": "80/320",
                "crossbow": true,
                "weapon": true,
            },
          ],
          "very rare": [],
          "legendary": [],
          "artifact": []
      }
    }
  },
  {
    name: "Zicklefockle Luthier",
    blurb: "Ianaros Zicklefockle",
    parentLocation: "thon_daral",
    src: "https://i0.wp.com/www.sageadvice.eu/wp-content/uploads/2017/08/DFMegs1UMAACBXG.jpg",
    items: {
        instruments: {
        "none": [
        {
            "name": "Pan Flute",
            "source": "PHB",
            "page": 154,
            "srd": true,
            "basicRules": true,
            "type": "INS",
            "rarity": "none",
            "weight": 2,
            "value": 1200,
            "make": "Gnome"
        },
        {
            "name": "Shawm",
            "source": "PHB",
            "page": 154,
            "srd": true,
            "basicRules": true,
            "type": "INS",
            "rarity": "none",
            "weight": 1,
            "value": 200,
            "make": "Gnome"
        },
        {
            "name": "Longhorn",
            "source": "SCAG",
            "page": 124,
            value: "100",
            "type": "INS",
            "rarity": "none",
            "entries": [
                "A Faerûnian flute of sophisticated make, found only in areas with skilled artisans, as in great cities or elven enclaves."
            ],
            "make": "Gnome"
        },
        {
            "name": "Songhorn",
            "source": "SCAG",
            "page": 124,
            value: "150",
            "type": "INS",
            "rarity": "none",
            "entries": [
                "A recorder, a simple type of flute, usually carved from wood."
            ],
            "make": "Gnome"
        },
        {
            "name": "Tantan",
            "source": "SCAG",
            make: "Gnome",
            value: "100",
            "page": 124,
            "type": "INS",
            "rarity": "none",
            "entries": [
                "A tambourine, a popular instrument with halflings and humans south of the Dalelands."
            ]
        },
        {
            "name": "Lute",
            "source": "PHB",
            "page": 154,
            "srd": true,
            "basicRules": true,
            "type": "INS",
            "rarity": "none",
            "weight": 2,
            "value": 3500,
            "make": "Gnome"
        }
    ],
    "common": [
        {
            "name": "Instrument of Scribing",
            "source": "XGE",
            "page": 138,
            "type": "INS",
            "tier": "minor",
            "rarity": "common",
            "reqAttune": true,
            "wondrous": true,
            "recharge": "dawn",
            "rechargeAmount": 3,
            "charges": 3,
            "entries": [
                "This musical instrument has 3 charges. While you are playing it, you can use an action to expend 1 charge from the instrument and write a magical message on a nonmagical object or surface that you can see within 30 feet of you. The message can be up to six words long and is written in a language you know. If you are a bard, you can scribe an additional seven words and choose to make the message glow faintly, allowing it to be seen in nonmagical darkness. Casting {@spell dispel magic} on the message erases it. Otherwise, the message fades away after 24 hours.",
                "The instrument regains all expended charges daily at dawn."
            ],
            "make": "Gnome"
        },
        {
            "name": "Instrument of Illusions (Violin)",
            "source": "XGE",
            "page": 137,
            "type": "INS",
            "tier": "minor",
            "rarity": "common",
            "reqAttune": true,
            "wondrous": true,
            "entries": [
                "While you are playing this musical instrument, you can create harmless, illusory visual effects within a 5-foot-radius sphere centered on the instrument. If you are a bard, the radius increases to 15 feet. Sample visual effects include luminous musical notes, a spectral dancer, butterflies, and gently falling snow. The magical effects have neither substance nor sound, and they are obviously illusory. The effects end when you stop playing."
            ],
            "make": "Gnome"
        },
        {
            "name": "Instrument of Illusions (Drum)",
            "source": "XGE",
            "page": 137,
            "type": "INS",
            "tier": "minor",
            "rarity": "common",
            "reqAttune": true,
            "wondrous": true,
            "entries": [
                "While you are playing this musical instrument, you can create harmless, illusory visual effects within a 5-foot-radius sphere centered on the instrument. If you are a bard, the radius increases to 15 feet. Sample visual effects include luminous musical notes, a spectral dancer, butterflies, and gently falling snow. The magical effects have neither substance nor sound, and they are obviously illusory. The effects end when you stop playing."
            ],
            "make": "Gnome"
        },
        {
            "name": "Instrument of Illusions (Lyre)",
            "source": "XGE",
            "page": 137,
            "type": "INS",
            "tier": "minor",
            "rarity": "common",
            "reqAttune": true,
            "wondrous": true,
            "entries": [
                "While you are playing this musical instrument, you can create harmless, illusory visual effects within a 5-foot-radius sphere centered on the instrument. If you are a bard, the radius increases to 15 feet. Sample visual effects include luminous musical notes, a spectral dancer, butterflies, and gently falling snow. The magical effects have neither substance nor sound, and they are obviously illusory. The effects end when you stop playing."
            ],
            "make": "Gnome"
        },
        {
            "name": "Instrument of Illusions (Shawm)",
            "source": "XGE",
            "page": 137,
            "type": "INS",
            "tier": "minor",
            "rarity": "common",
            "reqAttune": true,
            "wondrous": true,
            "entries": [
                "While you are playing this musical instrument, you can create harmless, illusory visual effects within a 5-foot-radius sphere centered on the instrument. If you are a bard, the radius increases to 15 feet. Sample visual effects include luminous musical notes, a spectral dancer, butterflies, and gently falling snow. The magical effects have neither substance nor sound, and they are obviously illusory. The effects end when you stop playing."
            ],
            "make": "Gnome"
        }
    ],
    "uncommon": [
        {
            "name": "Instrument of the Bards, Mac-Fuirmidh Cittern",
            value: "600000",
            "source": "DMG",
            "page": 176,
            "type": "INS",
            "tier": "major",
            "rarity": "uncommon",
            "reqAttune": "by a bard",
            "reqAttuneTags": [
                {
                    "class": "bard"
                }
            ],
            "wondrous": true,
            "weight": 2,
            "entries": [
                "An instrument of the bards is an exquisite example of its kind, superior to an ordinary instrument in every way. Seven types of these instruments exist, each named after a legendary bard college. A creature that attempts to play the instrument without being attuned to it must succeed on a DC 15 Wisdom saving throw or take {@damage 2d4} psychic damage.",
                "You can use an action to play the instrument and cast one of its spells. Once the instrument has been used to cast a spell, it can't be used to cast that spell again until the next dawn. The spells use your spellcasting ability and spell save DC.",
                "You can play the instrument while casting a spell that causes any of its targets to be {@condition charmed} on a failed saving throw, thereby imposing disadvantage on the save. This effect applies only if the spell has a somatic or a material component.",
                "All instruments of the bards can be used to cast the following spells: {@spell fly}, {@spell invisibility}, {@spell levitate}, and {@spell protection from evil and good}.",
                "In addition, the Mac-Fuirmidh cittern can be used to cast {@spell barkskin}, {@spell cure wounds}, and {@spell fog cloud}."
            ],
            "attachedSpells": [
                "barkskin",
                "fly",
                "invisibility",
                "levitate",
                "protection from evil and good",
                "cure wounds",
                "fog cloud"
            ],
            "lootTables": [
                "Magic Item Table F"
            ],
            "hasFluffImages": true,
            "make": "Gnome"
        },
        {
            "name": "Pipes of Haunting",
            "value": "270000",
            "source": "DMG",
            "type": "INS",
            "make": "Gnome",
            "rarity": "uncommon",
        },
        {
            "name": "Pipes of the Sewers",
            "source": "DMG",
            "page": 185,
            value: "250000",
            "srd": true,
            "type": "INS",
            "tier": "major",
            "rarity": "uncommon",
            "reqAttune": true,
            "wondrous": true,
            "weight": 2,
            "recharge": "dawn",
            "rechargeAmount": "{@dice 1d3}",
            "charges": 3,
            "entries": [
                "You must be proficient with wind instruments to use these pipes. While you are attuned to the pipes, ordinary rats and giant rats are indifferent toward you and will not attack you unless you threaten or harm them.",
                "The pipes have 3 charges. If you play the pipes as an action, you can use a bonus action to expend 1 to 3 charges, calling forth one {@creature swarm of rats} with each expended charge, provided that enough rats are within half a mile of you to be called in this fashion (as determined by the DM). If there aren't enough rats to form a swarm, the charge is wasted. Called swarms move toward the music by the shortest available route but aren't under your control otherwise. The pipes regain {@dice 1d3} expended charges daily at dawn.",
                "Whenever a {@creature swarm of rats} that isn't under another creature's control comes within 30 feet of you while you are playing the pipes, you can make a Charisma check contested by the swarm's Wisdom check. If you lose the contest, the swarm behaves as it normally would and can't be swayed by the pipes' music for the next 24 hours. If you win the contest, the swarm is swayed by the pipes' music and becomes friendly to you and your companions for as long as you continue to play the pipes each round as an action. A friendly swarm obeys your commands. If you issue no commands to a friendly swarm, it defends itself but otherwise takes no actions. If a friendly swarm starts its turn and can't hear the pipes' music, your control over that swarm ends, and the swarm behaves as it normally would and can't be swayed by the pipes' music for the next 24 hours."
            ],
            "optionalfeatures": [
                "replicate magic item|tce",
                "replicate magic item|uaartificerrevisited"
            ],
            "lootTables": [
                "Magic Item Table F"
            ],
            "hasFluffImages": true,
            "make": "Gnome"
        },
        {
            "name": "+1 Rhythm-Maker's Drum",
            "source": "TCE",
            value: "55000",
            "page": 134,
            "type": "INS",
            "rarity": "uncommon",
            "reqAttune": "by a bard",
            "reqAttuneTags": [
                {
                    "class": "bard"
                }
            ],
            "wondrous": true,
            "weight": 3,
            "grantsProficiency": true,
            "bonusSpellAttack": "+1",
            "bonusSpellSaveDc": "+1",
            "entries": [
                "While holding this {@item drum|PHB}, you gain a +1 bonus to spell attack rolls and to the saving throw DCs of your bard spells.",
                "As an action, you can play the {@item drum|PHB} to regain one use of your Bardic Inspiration feature. This property of the {@item drum|PHB} can't be used again until the next dawn."
            ],
            "make": "Gnome"
        },
        {
            "name": "+1 Rhythm-Maker's Flute",
            value: "55000",
            "source": "TCE",
            "page": 134,
            "type": "INS",
            "rarity": "uncommon",
            "reqAttune": "by a bard",
            "reqAttuneTags": [
                {
                    "class": "bard"
                }
            ],
            "wondrous": true,
            "weight": 3,
            "grantsProficiency": true,
            "bonusSpellAttack": "+1",
            "bonusSpellSaveDc": "+1",
            "entries": [
                "While holding this {@item drum|PHB}, you gain a +1 bonus to spell attack rolls and to the saving throw DCs of your bard spells.",
                "As an action, you can play the {@item drum|PHB} to regain one use of your Bardic Inspiration feature. This property of the {@item drum|PHB} can't be used again until the next dawn."
            ],
            "make": "Gnome"
        },
        {
            "name": "+1 Rhythm-Maker's Harp",
            value: "55000",
            "source": "TCE",
            "page": 134,
            "type": "INS",
            "rarity": "uncommon",
            "reqAttune": "by a bard",
            "reqAttuneTags": [
                {
                    "class": "bard"
                }
            ],
            "wondrous": true,
            "weight": 3,
            "grantsProficiency": true,
            "bonusSpellAttack": "+1",
            "bonusSpellSaveDc": "+1",
            "entries": [
                "While holding this {@item drum|PHB}, you gain a +1 bonus to spell attack rolls and to the saving throw DCs of your bard spells.",
                "As an action, you can play the {@item drum|PHB} to regain one use of your Bardic Inspiration feature. This property of the {@item drum|PHB} can't be used again until the next dawn."
            ],
            "make": "Gnome"
        }
    ],
    "rare": [
        {
            "name": "Reveler's Concertina",
            "source": "TCE",
            "page": 134,
            "type": "INS",
            "rarity": "rare",
            value: "550000",
            "reqAttune": "by a bard",
            "reqAttuneTags": [
                {
                    "class": "bard"
                }
            ],
            "wondrous": true,
            "entries": [
                "While holding this concertina, you gain a +2 bonus to the saving throw DC of your bard spells.",
                "As an action, you can use the concertina to cast {@spell Otto's irresistible dance} from the item. This property of the concertina can't be used again until the next dawn."
            ],
            "attachedSpells": [
                "otto's irresistible dance"
            ],
            "make": "Gnome"
        },
        {
            "name": "Siren Song Lyre",
            "source": "MOT",
            value: "450000",
            "page": 198,
            "type": "INS",
            "rarity": "rare",
            "reqAttune": true,
            "wondrous": true,
            "entries": [
                "You can use an action to play this lyre and cast one of the following spells from it: {@spell animal friendship}, {@spell charm person}, {@spell enthrall}, {@spell suggestion}. If the spell requires a saving throw, the spell save DC is 13.",
                "Once the instrument has been used to cast a spell, it can't be used to cast that spell again until the next dawn."
            ],
            "attachedSpells": [
                "animal friendship",
                "charm person",
                "enthrall",
                "suggestion"
            ],
            "make": "Gnome"
        },
        {
            "name": "Instrument of the Bards, Cli Lyre",
            value: "1000000",
            "source": "DMG",
            "page": 176,
            "type": "INS",
            "tier": "major",
            "rarity": "rare",
            "reqAttune": "by a bard",
            "reqAttuneTags": [
                {
                    "class": "bard"
                }
            ],
            "wondrous": true,
            "weight": 2,
            "entries": [
                "An instrument of the bards is an exquisite example of its kind, superior to an ordinary instrument in every way. Seven types of these instruments exist, each named after a legendary bard college. A creature that attempts to play the instrument without being attuned to it must succeed on a DC 15 Wisdom saving throw or take {@damage 2d4} psychic damage.",
                "You can use an action to play the instrument and cast one of its spells. Once the instrument has been used to cast a spell, it can't be used to cast that spell again until the next dawn. The spells use your spellcasting ability and spell save DC.",
                "You can play the instrument while casting a spell that causes any of its targets to be {@condition charmed} on a failed saving throw, thereby imposing disadvantage on the save. This effect applies only if the spell has a somatic or a material component.",
                "All instruments of the bards can be used to cast the following spells: {@spell fly}, {@spell invisibility}, {@spell levitate}, and {@spell protection from evil and good}.",
                "In addition, the Cli lyre can be used to cast {@spell stone shape}, {@spell wall of fire}, and {@spell wind wall}."
            ],
            "attachedSpells": [
                "stone shape",
                "fly",
                "invisibility",
                "levitate",
                "protection from evil and good",
                "wall of fire",
                "wind wall"
            ],
            "lootTables": [
                "Magic Item Table G"
            ],
            "hasFluffImages": true,
            "make": "Gnome"
        }
    ],
    "very rare": [
        {
            "name": "+3 Rhythm-Maker's Drum",
            value: "?",
            "source": "TCE",
            "page": 134,
            "type": "INS",
            "rarity": "very rare",
            "reqAttune": "by a bard",
            "reqAttuneTags": [
                {
                    "class": "bard"
                }
            ],
            "wondrous": true,
            "weight": 3,
            "grantsProficiency": true,
            "bonusSpellAttack": "+3",
            "bonusSpellSaveDc": "+3",
            "entries": [
                "While holding this {@item drum|PHB}, you gain a +3 bonus to spell attack rolls and to the saving throw DCs of your bard spells.",
                "As an action, you can play the {@item drum|PHB} to regain one use of your Bardic Inspiration feature. This property of the {@item drum|PHB} can't be used again until the next dawn."
            ],
            "make": "Gnome"
        }
    ],
    "legendary": [],
    "artifact": []
    }
    }

  },
  {
    name: "Ecstasy and Fire: Monk Supply, Dojo, Icecream, and 'Massage' Parlor",
    blurb: "Spudi",
    parentLocation: "thon_daral",
    // https://drive.google.com/file/d/1KOzJStn_n5H9PAuSai8ToSC2iu59GNr6/view?usp=sharing
    src: "https://drive.google.com/uc?id=1KOzJStn_n5H9PAuSai8ToSC2iu59GNr6",
    items: {
      weapons: {
        "none": [
          {
            "name": "Dart (20)",
            "source": "PHB",
            "page": 149,
            make: "Drushit",
            "type": "R",
            "rarity": "none",
            "weight": 0.25,
            "weaponCategory": "simple",
            "range": "20/60",
            "dmg1": "1d4",
            value: 100,
            "dmgType": "P",
            "weapon": true
          },
          {
            "name": "Throwing Stars (Dart) (20)",
            "source": "PHB",
            "page": 149,
            make: "Drushit",
            "type": "R",
            "rarity": "none",
            "weight": 0.25,
            "weaponCategory": "simple",
            "range": "20/60",
            "dmg1": "1d4",
            value: 100,
            "dmgType": "P",
            "weapon": true
          },
          {
            "name": "Nunchaku",
            make: "Tabaxi",
            "type": "M",
            "rarity": "none",
            "value": 500,
            "weaponCategory": "martial",
            "sword": true,
            "weapon": true
          },
          {
            "name": "Kama",
            make: "Modern Oxkadi",
            "type": "M",
            "rarity": "none",
            "value": 900,
            "weaponCategory": "martial",
            "sword": true,
            "weapon": true
          },
          {
            "name": "Bologorash (chained hand axe)",
            make: "Urzoth Orcs",
            "type": "M",
            "rarity": "none",
            "value": 900,
            "weaponCategory": "martial",
            "sword": true,
            "weapon": true
          },
          {
            "name": "Razor Chain (Whip)",
            make: "Drushit",
            "type": "M",
            "rarity": "none",
            "value": 1100,
            "weaponCategory": "martial",
            "sword": true,
            "weapon": true
          },
        ],
        uncommon: [
          {
            "name": "Seeker Dart",
            "source": "PotA",
            "page": 223,
            make: "Drushit",
            "baseItem": "dart|phb",
            "type": "R",
            "rarity": "uncommon",
            "weight": 0.25,
            value: 50000,
            "weaponCategory": "simple",
            "property": [
            "F",
            "T"
            ],
          },
          {
            "name": "+1 Throwing Stars (Dart) (20)",
            "source": "PHB",
            make: "Drushit",
            "page": 149,
            "type": "R",
            "rarity": "none",
            "weight": 0.25,
            "value": 10000,
            "weaponCategory": "simple",
            "range": "20/60",
            "dmg1": "1d4",
            "dmgType": "P",
            "weapon": true
          },
          {
            "name": "+1 Bologorash (Chained Hand Axe)",
            make: "Urzoth Orcs",
            "type": "M",
            "rarity": "none",
            "value": 31500,
            "weaponCategory": "martial",
            "sword": true,
            "weapon": true
          },
          {
            "name": "+1 Razor Chain (Whip)",
            make: "Drushit",
            "type": "M",
            "rarity": "none",
            "value": 33500,
            "weaponCategory": "martial",
            "sword": true,
            "weapon": true
          },
        ]
      },
      monk_items: {
        rare: [
          {
            "name": "+2 Dragonhide Belt",
            "source": "FTD",
            "wondrous": true,
            reqAttune: "by a monk",
            rarity: "rare"
          }
        ]
      }
    }
  }

]
export default shopsData;
