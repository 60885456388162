import React                 from "react";

import Collapsible           from "../shared/Collapsible"
import DivineBlock           from "./DivineBlock"


import characterData         from "../../data/characters";

class Divines extends React.Component {
  constructor(props) {
    super(props);

    this.state = {show: ""}
  }

  collapseToggle(kind) {
    this.state.show === kind ? this.setState({show: ""}) : this.setState({show: kind})
  }

  byRank(divinities) {
    let obj = {exalted: {}, intermediate: {}, lesser: {outside: {}, hells: {}, abyss: {}}, demigods: {}}

    Object.keys(divinities).forEach((divinityKey) => {
      const ranked = divinities[divinityKey].divineRank || "";

      if(ranked === "Exalted") {
        obj.exalted[divinityKey] = divinities[divinityKey];
      }
      else if(ranked === "Intermediate")  {
        obj.intermediate[divinityKey] = divinities[divinityKey];
      }
      else if(ranked === "Lesser Idol")  {
        if (divinities[divinityKey].plane === "The Nine Hells") {
          obj.lesser.hells[divinityKey] = divinities[divinityKey];
        }
        else if (divinities[divinityKey].race === "Demon Prince") {
          obj.lesser.abyss[divinityKey] = divinities[divinityKey];
        }
        else {
          obj.lesser.outside[divinityKey] = divinities[divinityKey];
        }

      }
      else  {
        obj.demigods[divinityKey] = divinities[divinityKey];
      }
    })

    return obj;
  }

  dividiedUp(divinities) {
    let obj = {evil: {}, neutral: {}, good: {}}

    divinities = divinities || [];

    Object.keys(divinities).forEach((divinityKey) => {
      const aligns = divinities[divinityKey].alignment || "";

      if(aligns.match("CE") || aligns.match("NE") || aligns.match("LE")) {
        obj.evil[divinityKey] = divinities[divinityKey];
      }
      else if(aligns.match("CG") || aligns.match("NG") || aligns.match("LG")) {
        obj.good[divinityKey] = divinities[divinityKey];
      }
      else  {
        obj.neutral[divinityKey] = divinities[divinityKey];
      }
    })

    return obj;
  }

  render() {
    const data = this.byRank(characterData.npcs.elderFigures);
    return (
      <div className="divine-display">
        <div class="pyramid">
          <div class="pyramid_section">
            <br/><br/>
            <h3>
              High <br/>Powers
            </h3>
          </div>

          <div class="pyramid_section">
            <h3>
              Exalted <br/>Deities
            </h3>
          </div>

          <div class="pyramid_section">
            <h3>
              Deity
            </h3>
          </div>

          <div class="pyramid_section">
            <h3>
              Lesser Idols
            </h3>
          </div>

          <div class="pyramid_section">
            <h3>
              Demigods
            </h3>
          </div>

          <div class="pyramid_section">
            <h3>
              Exarchs/Chosen
            </h3>
          </div>
        </div>


        <Collapsible
            title="Religious Practices"
            toggle={this.collapseToggle.bind(this)}
            kind="background"
            show={this.state.show}
          >
            <div>
              There are multiple schools of thought in religious practices:
              <ol>
                <li>
                  When you die, gods will chose those appealing to them to come to their planes. Therefore chose a deity and live thier whole life aligned with them. Evil individuals tend to this method, as entering the evil planes with a benefactor is far better than the alternative.
                  <br/>
                   For example:
                   <ul>
                    <li>
                      Erathis: Follow her by paying taxes, serving in the military, expanding civilization, and have relationships be formalized with marriage being a contract designed to order civilization, not be for passion.
                    </li>
                    <li>
                      Pelor: Follow him by following laws that are just, do good for others, view relationships romantically first, with formality as the best way to do best for all generations.
                    </li>
                    <li>
                      Sehanine: Follow her by finding tax loopholes, following your passions (but not to be cruel), being lose with relationships as they are for beings to consent to passions, and allowing others freedom to act.
                    </li>
                  </ul>
                </li>

                <li>
                  When you die, there is a judgement and you will go to the plane you lived best in. One does not want to be damned to the lower planes here.
                </li>
              </ol>
            </div>
          </Collapsible>


        <h2>Exalted Deities</h2>
        <Collapsible
            title="Details"
            toggle={this.collapseToggle.bind(this)}
            kind="exalted-details"
            show={this.state.show}
          >
            <div>
              <p>
                There is clear evidence that number of worshippers impact the powers of a deity. Moving up or down the hierarchy as their popularity waxes or wanes. However, certain deities seem to keep a high level of might despite any setback their churches face.
              </p>
              <p>
                Phineas Stoneblood labled them "Exalted Deities" a title that has stuck and apparently been adopted across the planes. His hypothesis is that they posses some sort of spark/item/power that fuels their abilities. Scholars are attempting to study how Vecna ascended to Exalted status, since he does not appear to be original, but the god of secrets hides them well.
              </p>
            </div>
        </Collapsible>

        <DivineBlock divines={this.dividiedUp(data.exalted)} showDomain={true}/>

        <h2>Deities</h2>
        <DivineBlock divines={this.dividiedUp(data.intermediate)} showDomain={true}/>

        <h2>Lesser Idols</h2>
        <DivineBlock divines={this.dividiedUp(data.lesser.outside)} showDomain={true}/>

        <h3>Archdevils</h3>
        <DivineBlock divines={this.dividiedUp(data.lesser.hells)} showDomain={false} showPatron={true}/>

        <h3>Demon Princes</h3>
        <DivineBlock divines={this.dividiedUp(data.lesser.abyss)} showDomain={false} showPatron={true}/>

        <h2>Demigods</h2>
        <DivineBlock divines={this.dividiedUp(data.demigods)} showRank={true} showDomain={false} showPatron={true}/>
      </div>
    )
  }
}

export default Divines;
