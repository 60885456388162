const itemsData = {
"_meta": {
"internalCopies": [
"item"
]
},
"item": [
{
"name": "+1 All-Purpose Tool",
"source": "TCE",
"page": 119,
"rarity": "uncommon",
"reqAttune": "by an artificer",
"reqAttuneTags": [
{
"class": "artificer|tce"
}
],
"wondrous": true,
"bonusSpellAttack": "+1",
"bonusSpellSaveDc": "+1",
"focus": [
"Artificer"
],
"entries": [
"This simple screwdriver can transform into a variety of tools; as an action, you can touch the item and transform it into any type of artisan's tool of your choice (see the \"Equipment\" chapter in the {@book Player's Handbook|PHB} for a list of {@item artisan's tools|PHB}). Whatever form the tool takes, you are proficient with it.",
"While holding this tool, you gain a +1 bonus to the spell attack rolls and the saving throw DCs of your artificer spells.",
"As an action, you can focus on the tool to channel your creative forces. Choose a cantrip that you don't know from any class list. For 8 hours, you can cast that cantrip, and it counts as an artificer cantrip for you. Once this property is used, it can't be used again until the next dawn."
]
},
{
"name": "+1 Amulet of the Devout",
"source": "TCE",
"page": 119,
"rarity": "uncommon",
"reqAttune": "by a cleric or paladin",
"reqAttuneTags": [
{
"class": "cleric"
},
{
"class": "paladin"
}
],
"wondrous": true,
"weight": 1,
"bonusSpellAttack": "+1",
"bonusSpellSaveDc": "+1",
"entries": [
"This amulet bears the symbol of a deity inlaid with precious stones or metals. While you wear the holy symbol, you gain a +1 bonus to spell attack rolls and the saving throw DCs of your spells.",
"While you wear this amulet, you can use your Channel Divinity feature without expending one of the feature's uses. Once this property is used, it can't be used again until the next dawn."
]
},
{
"name": "+1 Arcane Grimoire",
"source": "TCE",
"page": 120,
"rarity": "uncommon",
"reqAttune": "by a wizard",
"reqAttuneTags": [
{
"class": "wizard"
}
],
"wondrous": true,
"weight": 3,
"bonusSpellAttack": "+1",
"bonusSpellSaveDc": "+1",
"focus": [
"Wizard"
],
"entries": [
"While you are holding this leather-bound book, you can use it as a spellcasting focus for your wizard spells, and you gain a +1 bonus to spell attack rolls and to the saving throw DCs of your wizard spells.",
"You can use this book as a spellbook. In addition, when you use your Arcane Recovery feature, you can increase the number of spell slot levels you regain by 1."
]
},
{
"name": "+1 Bloodwell Vial",
"source": "TCE",
"page": 122,
"rarity": "uncommon",
"reqAttune": "by a sorcerer",
"reqAttuneTags": [
{
"class": "sorcerer"
}
],
"wondrous": true,
"bonusSpellAttack": "+1",
"bonusSpellSaveDc": "+1",
"focus": [
"Sorcerer"
],
"entries": [
"To attune to this vial, you must place a few drops of your blood into it. The vial can't be opened while your attunement to it lasts. If your attunement to the vial ends, the contained blood turns to ash. You can use the vial as a spellcasting focus for your spells while wearing or holding it, and you gain a +1 bonus to spell attack rolls and to the saving throw DCs of your sorcerer spells.",
"In addition, when you roll any Hit Dice to recover hit points while you are carrying the vial, you can regain 5 sorcery points. This property of the vial can't be used again until the next dawn."
]
},
{
"name": "+1 Dragonhide Belt",
"source": "FTD",
"page": 23,
"rarity": "uncommon",
"reqAttune": "by a monk",
"reqAttuneTags": [
{
"class": "monk"
}
],
"wondrous": true,
"entries": [
"This finely detailed belt is made of dragonhide. While wearing it, you gain a +1 bonus to the saving throw DCs of your ki features. In addition, you can use an action to regain ki points equal to a roll of your Martial Arts die. You can't use this action again until the next dawn."
]
},
{
"name": "+1 Moon Sickle",
"source": "TCE",
"page": 133,
"baseItem": "sickle|PHB",
"type": "M",
"rarity": "uncommon",
"reqAttune": "by a druid or ranger",
"reqAttuneTags": [
{
"class": "druid"
},
{
"class": "ranger"
}
],
"weight": 2,
"weaponCategory": "simple",
"property": [
"L"
],
"dmg1": "1d4",
"dmgType": "S",
"bonusWeapon": "+1",
"bonusSpellAttack": "+1",
"bonusSpellSaveDc": "+1",
"focus": [
"Druid",
"Ranger"
],
"weapon": true,
"entries": [
"This silver-bladed sickle glimmers softly with moonlight. While holding this magic weapon, you gain a +1 bonus to attack and damage rolls made with it, and you gain a +1 bonus to spell attack rolls and the saving throw DCs of your druid and ranger spells. In addition, you can use the sickle as a spellcasting focus for your druid and ranger spells.",
"When you cast a spell that restores hit points, you can roll a {@dice d4} and add the number rolled to the amount of hit points restored, provided you are holding the sickle."
]
},
{
"name": "+1 Rhythm-Maker's Drum",
"source": "TCE",
"page": 134,
"type": "INS",
"rarity": "uncommon",
"reqAttune": "by a bard",
"reqAttuneTags": [
{
"class": "bard"
}
],
"wondrous": true,
"weight": 3,
"grantsProficiency": true,
"bonusSpellAttack": "+1",
"bonusSpellSaveDc": "+1",
"entries": [
"While holding this {@item drum|PHB}, you gain a +1 bonus to spell attack rolls and to the saving throw DCs of your bard spells.",
"As an action, you can play the {@item drum|PHB} to regain one use of your Bardic Inspiration feature. This property of the {@item drum|PHB} can't be used again until the next dawn."
]
},
{
"name": "+1 Rod of the Pact Keeper",
"source": "DMG",
"page": 197,
"type": "RD",
"tier": "major",
"rarity": "uncommon",
"reqAttune": "by a warlock",
"reqAttuneTags": [
{
"class": "warlock"
}
],
"weight": 2,
"bonusSpellAttack": "+1",
"bonusSpellSaveDc": "+1",
"entries": [
"While holding this rod, you gain a +1 bonus to spell attack rolls and to the saving throw DCs of your warlock spells.",
"In addition, you can regain one warlock spell slot as an action while holding the rod. You can't use this property again until you finish a long rest."
],
"lootTables": [
"Magic Item Table F"
]
},
{
"name": "+1 Wand of the War Mage",
"source": "DMG",
"page": 212,
"srd": true,
"type": "WD",
"tier": "major",
"rarity": "uncommon",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"weight": 1,
"bonusSpellAttack": "+1",
"entries": [
"While you are holding this wand, you gain a +1 bonus to spell attack rolls. In addition, you ignore half cover when making a spell attack."
],
"lootTables": [
"Magic Item Table F"
]
},
{
"name": "+2 All-Purpose Tool",
"source": "TCE",
"page": 119,
"rarity": "rare",
"reqAttune": "by an artificer",
"reqAttuneTags": [
{
"class": "artificer|tce"
}
],
"wondrous": true,
"bonusSpellAttack": "+2",
"bonusSpellSaveDc": "+2",
"focus": [
"Artificer"
],
"entries": [
"This simple screwdriver can transform into a variety of tools; as an action, you can touch the item and transform it into any type of artisan's tool of your choice (see the \"Equipment\" chapter in the {@book Player's Handbook|PHB} for a list of {@item artisan's tools|PHB}). Whatever form the tool takes, you are proficient with it.",
"While holding this tool, you gain a +2 bonus to the spell attack rolls and the saving throw DCs of your artificer spells.",
"As an action, you can focus on the tool to channel your creative forces. Choose a cantrip that you don't know from any class list. For 8 hours, you can cast that cantrip, and it counts as an artificer cantrip for you. Once this property is used, it can't be used again until the next dawn."
]
},
{
"name": "+2 Amulet of the Devout",
"source": "TCE",
"page": 119,
"rarity": "rare",
"reqAttune": "by a cleric or paladin",
"reqAttuneTags": [
{
"class": "cleric"
},
{
"class": "paladin"
}
],
"wondrous": true,
"weight": 1,
"bonusSpellAttack": "+2",
"bonusSpellSaveDc": "+2",
"entries": [
"This amulet bears the symbol of a deity inlaid with precious stones or metals. While you wear the holy symbol, you gain a +2 bonus to spell attack rolls and the saving throw DCs of your spells.",
"While you wear this amulet, you can use your Channel Divinity feature without expending one of the feature's uses. Once this property is used, it can't be used again until the next dawn."
]
},
{
"name": "+2 Arcane Grimoire",
"source": "TCE",
"page": 120,
"rarity": "rare",
"reqAttune": "by a wizard",
"reqAttuneTags": [
{
"class": "wizard"
}
],
"wondrous": true,
"weight": 3,
"bonusSpellAttack": "+2",
"bonusSpellSaveDc": "+2",
"focus": [
"Wizard"
],
"entries": [
"While you are holding this leather-bound book, you can use it as a spellcasting focus for your wizard spells, and you gain a +2 bonus to spell attack rolls and to the saving throw DCs of your wizard spells.",
"You can use this book as a spellbook. In addition, when you use your Arcane Recovery feature, you can increase the number of spell slot levels you regain by 1."
]
},
{
"name": "+2 Bloodwell Vial",
"source": "TCE",
"page": 122,
"rarity": "rare",
"reqAttune": "by a sorcerer",
"reqAttuneTags": [
{
"class": "sorcerer"
}
],
"wondrous": true,
"bonusSpellAttack": "+2",
"bonusSpellSaveDc": "+2",
"focus": [
"Sorcerer"
],
"entries": [
"To attune to this vial, you must place a few drops of your blood into it. The vial can't be opened while your attunement to it lasts. If your attunement to the vial ends, the contained blood turns to ash. You can use the vial as a spellcasting focus for your spells while wearing or holding it, and you gain a +2 bonus to spell attack rolls and to the saving throw DCs of your sorcerer spells.",
"In addition, when you roll any Hit Dice to recover hit points while you are carrying the vial, you can regain 5 sorcery points. This property of the vial can't be used again until the next dawn."
]
},
{
"name": "+2 Dragonhide Belt",
"source": "FTD",
"page": 23,
"rarity": "rare",
"reqAttune": "by a monk",
"reqAttuneTags": [
{
"class": "monk"
}
],
"wondrous": true,
"entries": [
"This finely detailed belt is made of dragonhide. While wearing it, you gain a +2 bonus to the saving throw DCs of your ki features. In addition, you can use an action to regain ki points equal to a roll of your Martial Arts die. You can't use this action again until the next dawn."
]
},
{
"name": "+2 Moon Sickle",
"source": "TCE",
"page": 133,
"baseItem": "sickle|PHB",
"type": "M",
"rarity": "rare",
"reqAttune": "by a druid or ranger",
"reqAttuneTags": [
{
"class": "druid"
},
{
"class": "ranger"
}
],
"weight": 2,
"weaponCategory": "simple",
"property": [
"L"
],
"dmg1": "1d4",
"dmgType": "S",
"bonusWeapon": "+2",
"bonusSpellAttack": "+2",
"bonusSpellSaveDc": "+2",
"focus": [
"Druid",
"Ranger"
],
"weapon": true,
"entries": [
"This silver-bladed sickle glimmers softly with moonlight. While holding this magic weapon, you gain a +2 bonus to attack and damage rolls made with it, and you gain a +2 bonus to spell attack rolls and the saving throw DCs of your druid and ranger spells. In addition, you can use the sickle as a spellcasting focus for your druid and ranger spells.",
"When you cast a spell that restores hit points, you can roll a {@dice d4} and add the number rolled to the amount of hit points restored, provided you are holding the sickle."
]
},
{
"name": "+2 Rhythm-Maker's Drum",
"source": "TCE",
"page": 134,
"type": "INS",
"rarity": "rare",
"reqAttune": "by a bard",
"reqAttuneTags": [
{
"class": "bard"
}
],
"wondrous": true,
"weight": 3,
"grantsProficiency": true,
"bonusSpellAttack": "+2",
"bonusSpellSaveDc": "+2",
"entries": [
"While holding this {@item drum|PHB}, you gain a +2 bonus to spell attack rolls and to the saving throw DCs of your bard spells.",
"As an action, you can play the {@item drum|PHB} to regain one use of your Bardic Inspiration feature. This property of the {@item drum|PHB} can't be used again until the next dawn."
]
},
{
"name": "+2 Rod of the Pact Keeper",
"source": "DMG",
"page": 197,
"type": "RD",
"tier": "major",
"rarity": "rare",
"reqAttune": "by a warlock",
"reqAttuneTags": [
{
"class": "warlock"
}
],
"weight": 2,
"bonusSpellAttack": "+2",
"bonusSpellSaveDc": "+2",
"entries": [
"While holding this rod, you gain a +2 bonus to spell attack rolls and to the saving throw DCs of your warlock spells.",
"In addition, you can regain one warlock spell slot as an action while holding the rod. You can't use this property again until you finish a long rest."
],
"lootTables": [
"Magic Item Table G"
]
},
{
"name": "+2 Wand of the War Mage",
"source": "DMG",
"page": 212,
"srd": true,
"type": "WD",
"tier": "major",
"rarity": "rare",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"weight": 1,
"bonusSpellAttack": "+2",
"entries": [
"While you are holding this wand, you gain a +2 bonus to spell attack rolls. In addition, you ignore half cover when making a spell attack."
],
"lootTables": [
"Magic Item Table G"
]
},
{
"name": "+3 All-Purpose Tool",
"source": "TCE",
"page": 119,
"rarity": "very rare",
"reqAttune": "by an artificer",
"reqAttuneTags": [
{
"class": "artificer|tce"
}
],
"wondrous": true,
"bonusSpellAttack": "+3",
"bonusSpellSaveDc": "+3",
"focus": [
"Artificer"
],
"entries": [
"This simple screwdriver can transform into a variety of tools; as an action, you can touch the item and transform it into any type of artisan's tool of your choice (see the \"Equipment\" chapter in the {@book Player's Handbook|PHB} for a list of {@item artisan's tools|PHB}). Whatever form the tool takes, you are proficient with it.",
"While holding this tool, you gain a +3 bonus to the spell attack rolls and the saving throw DCs of your artificer spells.",
"As an action, you can focus on the tool to channel your creative forces. Choose a cantrip that you don't know from any class list. For 8 hours, you can cast that cantrip, and it counts as an artificer cantrip for you. Once this property is used, it can't be used again until the next dawn."
]
},
{
"name": "+3 Amulet of the Devout",
"source": "TCE",
"page": 119,
"rarity": "very rare",
"reqAttune": "by a cleric or paladin",
"reqAttuneTags": [
{
"class": "cleric"
},
{
"class": "paladin"
}
],
"wondrous": true,
"weight": 1,
"bonusSpellAttack": "+3",
"bonusSpellSaveDc": "+3",
"entries": [
"This amulet bears the symbol of a deity inlaid with precious stones or metals. While you wear the holy symbol, you gain a +3 bonus to spell attack rolls and the saving throw DCs of your spells.",
"While you wear this amulet, you can use your Channel Divinity feature without expending one of the feature's uses. Once this property is used, it can't be used again until the next dawn."
]
},
{
"name": "+3 Arcane Grimoire",
"source": "TCE",
"page": 120,
"rarity": "very rare",
"reqAttune": "by a wizard",
"reqAttuneTags": [
{
"class": "wizard"
}
],
"wondrous": true,
"weight": 3,
"bonusSpellAttack": "+3",
"bonusSpellSaveDc": "+3",
"focus": [
"Wizard"
],
"entries": [
"While you are holding this leather-bound book, you can use it as a spellcasting focus for your wizard spells, and you gain a +3 bonus to spell attack rolls and to the saving throw DCs of your wizard spells.",
"You can use this book as a spellbook. In addition, when you use your Arcane Recovery feature, you can increase the number of spell slot levels you regain by 1."
]
},
{
"name": "+3 Bloodwell Vial",
"source": "TCE",
"page": 122,
"rarity": "very rare",
"reqAttune": "by a sorcerer",
"reqAttuneTags": [
{
"class": "sorcerer"
}
],
"wondrous": true,
"bonusSpellAttack": "+3",
"bonusSpellSaveDc": "+3",
"focus": [
"Sorcerer"
],
"entries": [
"To attune to this vial, you must place a few drops of your blood into it. The vial can't be opened while your attunement to it lasts. If your attunement to the vial ends, the contained blood turns to ash. You can use the vial as a spellcasting focus for your spells while wearing or holding it, and you gain a +3 bonus to spell attack rolls and to the saving throw DCs of your sorcerer spells.",
"In addition, when you roll any Hit Dice to recover hit points while you are carrying the vial, you can regain 5 sorcery points. This property of the vial can't be used again until the next dawn."
]
},
{
"name": "+3 Dragonhide Belt",
"source": "FTD",
"page": 23,
"rarity": "very rare",
"reqAttune": "by a monk",
"reqAttuneTags": [
{
"class": "monk"
}
],
"wondrous": true,
"entries": [
"This finely detailed belt is made of dragonhide. While wearing it, you gain a +3 bonus to the saving throw DCs of your ki features. In addition, you can use an action to regain ki points equal to a roll of your Martial Arts die. You can't use this action again until the next dawn."
]
},
{
"name": "+3 Moon Sickle",
"source": "TCE",
"page": 133,
"baseItem": "sickle|PHB",
"type": "M",
"rarity": "very rare",
"reqAttune": "by a druid or ranger",
"reqAttuneTags": [
{
"class": "druid"
},
{
"class": "ranger"
}
],
"weight": 2,
"weaponCategory": "simple",
"property": [
"L"
],
"dmg1": "1d4",
"dmgType": "S",
"bonusWeapon": "+3",
"bonusSpellAttack": "+3",
"bonusSpellSaveDc": "+3",
"focus": [
"Druid",
"Ranger"
],
"weapon": true,
"entries": [
"This silver-bladed sickle glimmers softly with moonlight. While holding this magic weapon, you gain a +3 bonus to attack and damage rolls made with it, and you gain a +3 bonus to spell attack rolls and the saving throw DCs of your druid and ranger spells. In addition, you can use the sickle as a spellcasting focus for your druid and ranger spells.",
"When you cast a spell that restores hit points, you can roll a {@dice d4} and add the number rolled to the amount of hit points restored, provided you are holding the sickle."
]
},
{
"name": "+3 Rhythm-Maker's Drum",
"source": "TCE",
"page": 134,
"type": "INS",
"rarity": "very rare",
"reqAttune": "by a bard",
"reqAttuneTags": [
{
"class": "bard"
}
],
"wondrous": true,
"weight": 3,
"grantsProficiency": true,
"bonusSpellAttack": "+3",
"bonusSpellSaveDc": "+3",
"entries": [
"While holding this {@item drum|PHB}, you gain a +3 bonus to spell attack rolls and to the saving throw DCs of your bard spells.",
"As an action, you can play the {@item drum|PHB} to regain one use of your Bardic Inspiration feature. This property of the {@item drum|PHB} can't be used again until the next dawn."
]
},
{
"name": "+3 Rod of the Pact Keeper",
"source": "DMG",
"page": 197,
"type": "RD",
"tier": "major",
"rarity": "very rare",
"reqAttune": "by a warlock",
"reqAttuneTags": [
{
"class": "warlock"
}
],
"weight": 2,
"bonusSpellAttack": "+3",
"bonusSpellSaveDc": "+3",
"entries": [
"While holding this rod, you gain a +3 bonus to spell attack rolls and to the saving throw DCs of your warlock spells.",
"In addition, you can regain one warlock spell slot as an action while holding the rod. You can't use this property again until you finish a long rest."
],
"lootTables": [
"Magic Item Table H"
]
},
{
"name": "+3 Wand of the War Mage",
"source": "DMG",
"page": 212,
"srd": true,
"type": "WD",
"tier": "major",
"rarity": "very rare",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"weight": 1,
"bonusSpellAttack": "+3",
"entries": [
"While you are holding this wand, you gain a +3 bonus to spell attack rolls. In addition, you ignore half cover when making a spell attack."
],
"lootTables": [
"Magic Item Table H"
]
},
{
"name": "Abacus",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 2,
"value": 200
},
{
"name": "Abracadabrus",
"source": "IDRotF",
"page": 314,
"rarity": "very rare",
"wondrous": true,
"weight": 25,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d20}",
"charges": 20,
"entries": [
"An abracadabrus is an ornate, gemstone-studded wooden chest that weighs 25 pounds while empty. Its interior compartment is a cube measuring 1½ feet on a side.",
"The chest has 20 charges. A creature can use an action to touch the closed lid of the chest and expend 1 of the chest's charges while naming one or more nonmagical objects (including raw materials, foodstuffs, and liquids) worth a total of 1 gp or less. The named objects magically appear in the chest, provided they can all fit inside it and the chest doesn't contain anything else. For example, the chest can conjure a plate of strawberries, a bowl of hot soup, a flagon of water, a stuffed animal, or a bag of twenty caltrops. Food and drink conjured by the chest are delicious, and they spoil if not consumed after 24 hours. Gems and precious metals created by the chest disappear after 1 minute.",
"The chest regains {@dice 1d20} expended charges daily at dawn. If the item's last charge is expended, roll a {@dice d20}. On a 1, the chest loses its magic (becoming an ordinary chest), and its gemstones turn to dust."
],
"miscTags": [
"CF/W"
]
},
{
"name": "Absorbing Tattoo",
"source": "UA2020SpellsAndMagicTattoos",
"page": 8,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"entries": [
"This tattoo incorporates designs that emphasize one color more than others. While the tattoo is on your skin, you have resistance to a type of damage associated with that color, as shown on the table below. The DM chooses the color or determines it randomly.",
{
"type": "table",
"caption": "",
"colLabels": [
"d10",
" Damage Type",
"Color"
],
"colStyles": [
"col-2 text-center",
"col-5",
"col-5"
],
"rows": [
[
"1",
"Acid",
"Green"
],
[
"2",
"Cold",
"Blue"
],
[
"3",
"Fire",
"Red"
],
[
"4",
"Force",
"White"
],
[
"5",
"Lightning",
"Yellow"
],
[
"6",
"Necrotic",
"Black"
],
[
"7",
"Poison",
"Violet"
],
[
"8",
"Psychic",
"Silver"
],
[
"9",
"Radiant",
"Gold"
],
[
"10",
"Thunder",
"Orange"
]
]
},
{
"type": "entries",
"name": "Damage Absorption",
"entries": [
"When you take damage of the chosen type, you can use your reaction to gain immunity against that instance of the damage, and you regain a number of hit points equal to half the damage you would have taken. Once this reaction is used, it can't be used again until the next dawn."
]
},
{
"type": "entries",
"name": "Tattoo Attunement",
"entries": [
"To attune to this item, you hold the needle to your skin where you want the tattoo to appear, pressing the needle there throughout the attunement process. When the attunement is complete, the needle turns into the ink that becomes the tattoo, which appears on the skin. If you have multiple magic tattoos, they count as a single magic item with regard to the number of magic items you can attune to.",
"If your attunement to the tattoo ends, the tattoo vanishes, and the needle reappears in the closest unoccupied space to you."
]
}
]
},
{
"name": "Acid (vial)",
"source": "PHB",
"page": 148,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 1,
"value": 2500,
"entries": [
"As an action, you can splash the contents of this vial onto a creature within 5 feet of you or throw the vial up to 20 feet, shattering it on impact. In either case, make a ranged attack against a creature or object, treating the acid as an improvised weapon. On a hit, the target takes {@damage 2d6} acid damage."
]
},
{
"name": "Acid Absorbing Tattoo",
"source": "TCE",
"page": 119,
"resist": [
"acid"
],
"detail1": "green",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"hasRefs": true,
"entries": [
"{#itemEntry Absorbing Tattoo|TCE}"
]
},
{
"name": "Adamantine Bar",
"source": "WDH",
"page": 90,
"type": "TG",
"rarity": "none",
"weight": 10,
"value": 100000
},
{
"name": "Adjustable Stilts",
"source": "WDH",
"page": 47,
"type": "OTH",
"rarity": "unknown",
"weight": 8,
"entries": [
"The stilts take 1 minute to put on or remove. They increase the height of any humanoid wearing them by 2 to 5 feet. Each stilt weighs 8 pounds and is 1 foot long when fully collapsed."
]
},
{
"name": "Airship",
"source": "DMG",
"page": 119,
"type": "AIR",
"rarity": "none",
"value": 2000000,
"crew": 10,
"vehAc": 13,
"vehHp": 300,
"vehSpeed": 8,
"capPassenger": 20,
"capCargo": 1
},
{
"name": "Akmon, Hammer of Purphoros",
"source": "MOT",
"page": 198,
"baseItem": "warhammer|PHB",
"type": "M",
"resist": [
"fire"
],
"conditionImmune": [
"exhaustion"
],
"rarity": "artifact",
"reqAttune": true,
"weight": 2,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "B",
"dmg2": "1d10",
"grantsProficiency": true,
"bonusWeapon": "+3",
"weapon": true,
"entries": [
"Purphoros's hammer works wonders and disasters, the god of the forge using it to craft ground-breaking marvels and dangerous inventions in equal measure. Purphoros rarely bestows Akmon on mortals but occasionally leaves it unattended at his forge in Mount Velus. When Purphoros does permit a mortal to use the hammer, it's usually so they might bring an important work into being, wreck a force of destruction, or forge something remarkable somewhere the god isn't welcome.",
{
"type": "entries",
"name": "Hammer of the Forge",
"entries": [
"This magic warhammer grants a +3 bonus to attack and damage rolls made with it. When you hit with an attack using it, the target takes an extra {@damage 3d10} fire damage."
]
},
{
"type": "entries",
"name": "Blessing of the Forge",
"entries": [
"If you are a worshiper of Purphoros, you gain all the following benefits for which you have the required piety:",
{
"type": "list",
"style": "list-hang-notitle",
"items": [
{
"type": "item",
"name": "Piety 10+",
"entry": "The hammer has 1 randomly determined {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} property."
},
{
"type": "item",
"name": "Piety 25+",
"entry": "The hammer has 1 randomly determined {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial} property."
},
{
"type": "item",
"name": "Piety 50+",
"entry": "The hammer has 1 additional randomly determined {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial} property."
}
]
},
"If you aren't a worshiper of Purphoros, the hammer has 2 randomly determined {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental} properties.",
"See \"Artifacts\" in chapter 7 of the Dungeon Master's Guide for details on randomly determined properties."
]
},
{
"type": "entries",
"name": "Reforged",
"entries": [
"While holding the hammer, you have resistance to fire damage and are immune to {@condition exhaustion}. Additionally, you have proficiency with {@item smith's tools|PHB} and have advantage on all ability checks made using them."
]
},
{
"type": "entries",
"name": "Spells",
"entries": [
"While the hammer is on your person, you can use an action to cast one of the following spells (save DC 18): {@spell animate objects}, {@spell heat metal}, {@spell fabricate}, {@spell magic weapon}, {@spell mending}, {@spell shatter}. Once you use the hammer to cast a spell, the spell can't be cast again from it until the next dusk."
]
},
{
"type": "entries",
"name": "Destroying the Hammer",
"entries": [
"To destroy the hammer, it must be taken to the realm of Tizerus, in the Underworld. There it must be coated in clay from the Mire of Punishment. The heat of the hammer hardens the clay, which fuses to it after one month. Once fully hardened, the clay-covered hammer must be swallowed and digested by a kraken."
]
}
],
"attachedSpells": [
"animate objects",
"heat metal",
"fabricate",
"magic weapon",
"mending",
"shatter"
],
"hasFluffImages": true
},
{
"name": "Alchemical Compendium",
"source": "TCE",
"page": 119,
"rarity": "rare",
"reqAttune": "by a wizard",
"reqAttuneTags": [
{
"class": "wizard"
}
],
"wondrous": true,
"weight": 3,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"focus": [
"Wizard"
],
"entries": [
"Acrid odors cling to this stained, heavy volume. The book's metal fittings are copper, iron, lead, silver, and gold, some frozen mid-transition from one metal to another. When found, the book contains the following spells: {@spell enlarge/reduce}, {@spell feather fall}, {@spell flesh to stone}, {@spell gaseous form}, {@spell magic weapon}, and {@spell polymorph}. It functions as a spellbook for you.",
"While you are holding the book, you can use it as a spellcasting focus for your wizard spells.",
"The book has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. You can use the charges in the following ways while holding it:",
{
"type": "list",
"items": [
"If you spend 1 minute studying the book, you can expend 1 charge to replace one of your prepared wizard spells with a different spell in the book. The new spell must be of the transmutation school.",
"As an action, you can touch a nonmagical object that isn't being worn or carried and spend a number of charges to transform the target into another object. For 1 charge, the object can be no larger than 1 foot on a side. You can spend additional charges to increase the maximum dimensions by 2 feet per charge. The new object must have a gold value equal to or less than the original."
]
}
],
"attachedSpells": [
"enlarge/reduce",
"feather fall",
"flesh to stone",
"gaseous form",
"magic weapon",
"polymorph"
]
},
{
"name": "Alchemist's Doom",
"source": "SCC",
"page": 179,
"_copy": {
"name": "Alchemist's Fire (flask)",
"source": "PHB"
},
"type": "G",
"rarity": "unknown",
"value": null,
"entries": [
"This sticky, adhesive fluid ignites when exposed to air. As an action, you can throw this flask up to 20 feet, shattering it on impact. Make a ranged attack against a creature or object, treating the alchemist's doom as an improvised weapon. On a hit, the target takes 7 ({@dice 2d6}) fire damage at the start of each of its turns. A creature can end this damage by using its action to make a DC 10 Dexterity check to extinguish the flames."
]
},
{
"name": "Alchemist's Fire (flask)",
"source": "PHB",
"page": 148,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 1,
"value": 5000,
"entries": [
"This sticky, adhesive fluid ignites when exposed to air. As an action, you can throw this flask up to 20 feet, shattering it on impact. Make a ranged attack against a creature or object, treating the alchemist's fire as an improvised weapon. On a hit, the target takes {@damage 1d4} fire damage at the start of each of its turns. A creature can end this damage by using its action to make a DC 10 Dexterity check to extinguish the flames."
]
},
{
"name": "Alchemist's Supplies",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"additionalSources": [
{
"source": "XGE",
"page": 79
}
],
"type": "AT",
"rarity": "none",
"weight": 8,
"value": 5000,
"additionalEntries": [
"Alchemist's supplies enable a character to produce useful concoctions, such as acid or alchemist's fire.",
{
"type": "entries",
"name": "Components",
"entries": [
"Alchemist's supplies include two glass beakers, a metal frame to hold a beaker in place over an open flame, a glass stirring rod, a small mortar and pestle, and a pouch of common alchemical ingredients, including salt, powdered iron, and purified water."
]
},
{
"type": "entries",
"name": "Arcana",
"entries": [
"Proficiency with alchemist's supplies allows you to unlock more information on {@skill Arcana} checks involving potions and similar materials."
]
},
{
"type": "entries",
"name": "Investigation",
"entries": [
"When you inspect an area for clues, proficiency with alchemist's supplies grants additional insight into any chemicals or other substances that might have been used in the area."
]
},
{
"type": "entries",
"name": "Alchemical Crafting",
"entries": [
"You can use this tool proficiency to create alchemical items. A character can spend money to collect raw materials, which weigh 1 pound for every 50 gp spent. The DM can allow a character to make a check using the indicated skill with advantage. As part of a long rest, you can use alchemist's supplies to make one dose of {@item acid (vial)|phb|acid}, {@item alchemist's fire (flask)|phb|alchemist's fire}, {@item antitoxin (vial)|phb|antitoxin}, {@item oil (flask)|phb|oil}, {@item perfume (vial)|phb|perfume}, or {@item soap|phb}. Subtract half the value of the created item from the total gp worth of raw materials you are carrying."
]
},
{
"type": "table",
"caption": "Alchemist's Supplies",
"colLabels": [
"Activity",
"DC"
],
"colStyles": [
"col-10",
"col-2 text-center"
],
"rows": [
[
"Create a puff of thick smoke",
"10"
],
[
"Identify a poison",
"10"
],
[
"Identify a substance",
"15"
],
[
"Start a fire",
"15"
],
[
"Neutralize acid",
"20"
]
]
}
]
},
{
"name": "Alchemy Jug",
"source": "DMG",
"page": 150,
"tier": "minor",
"rarity": "uncommon",
"wondrous": true,
"weight": 12,
"entries": [
"This ceramic jug appears to be able to hold a gallon of liquid and weighs 12 pounds whether full or empty. Sloshing sounds can be heard from within the jug when it is shaken, even if the jug is empty.",
"You can use an action and name one liquid from the table below to cause the jug to produce the chosen liquid. Afterward, you can uncork the jug as an action and pour that liquid out, up to 2 gallons per minute. The maximum amount of liquid the jug can produce depends on the liquid you named.",
"Once the jug starts producing a liquid, it can't produce a different one, or more of one that has reached its maximum, until the next dawn.",
{
"type": "table",
"colLabels": [
"Liquid",
"Max Amount"
],
"colStyles": [
"col-2",
"col-10"
],
"rows": [
[
"Acid",
"8 ounces"
],
[
"Basic poison",
"½ ounce"
],
[
"Beer",
"4 gallons"
],
[
"Honey",
"1 gallon"
],
[
"Mayonnaise",
"2 gallons"
],
[
"Oil",
"1 quart"
],
[
"Vinegar",
"2 gallons"
],
[
"Water, fresh",
"8 gallons"
],
[
"Water, salt",
"12 gallons"
],
[
"Wine",
"1 gallon"
]
]
}
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table B"
],
"miscTags": [
"CF/W"
],
"hasFluffImages": true
},
{
"name": "Alchemy Jug (Blue)",
"source": "CM",
"page": 144,
"rarity": "uncommon",
"wondrous": true,
"weight": 12,
"entries": [
"This ceramic jug appears to be able to hold a gallon of liquid and weighs 12 pounds whether full or empty. Sloshing sounds can be heard from within the jug when it is shaken, even if the jug is empty.",
"You can use an action and name one liquid from the table below to cause the jug to produce the chosen liquid. Afterward, you can uncork the jug as an action and pour that liquid out, up to 2 gallons per minute. The maximum amount of liquid the jug can produce depends on the liquid you named.",
"Once the jug starts producing a liquid, it can't produce a different one, or more of one that has reached its maximum, until the next dawn.",
{
"type": "table",
"colStyles": [
"col-6 text-center",
"col-6 text-center"
],
"colLabels": [
"Liquid",
"Max Amount"
],
"rows": [
[
"Beer",
"4 gallons"
],
[
"Boiling Hot Tea",
"1 quart"
],
[
"Honey",
"1 gallon"
],
[
"Mayonnaise",
"2 gallons"
],
[
"Oil",
"quart"
],
[
"Vinegar",
"2 gallons"
],
[
"Water, fresh",
"8 gallons"
],
[
"Water, salt",
"12 gallons"
],
[
"Wine",
"1 gallon"
]
]
}
],
"miscTags": [
"CF/W"
]
},
{
"name": "Alchemy Jug (Orange)",
"source": "CM",
"page": 144,
"rarity": "uncommon",
"wondrous": true,
"weight": 12,
"entries": [
"This ceramic jug appears to be able to hold a gallon of liquid and weighs 12 pounds whether full or empty. Sloshing sounds can be heard from within the jug when it is shaken, even if the jug is empty.",
"You can use an action and name one liquid from the table below to cause the jug to produce the chosen liquid. Afterward, you can uncork the jug as an action and pour that liquid out, up to 2 gallons per minute. The maximum amount of liquid the jug can produce depends on the liquid you named.",
"Once the jug starts producing a liquid, it can't produce a different one, or more of one that has reached its maximum, until the next dawn.",
{
"type": "table",
"colStyles": [
"col-6 text-center",
"col-6 text-center"
],
"colLabels": [
"Liquid",
"Max Amount"
],
"rows": [
[
"Beer",
"4 gallons"
],
[
"Honey",
"1 gallon"
],
[
"Mayonnaise",
"2 gallons"
],
[
"Oil",
"quart"
],
[
"Soy Sauce",
"1 gallon"
],
[
"Vinegar",
"2 gallons"
],
[
"Water, fresh",
"8 gallons"
],
[
"Water, salt",
"12 gallons"
],
[
"Wine",
"1 gallon"
]
]
}
],
"miscTags": [
"CF/W"
]
},
{
"name": "Ale (Gallon)",
"source": "PHB",
"page": 158,
"srd": true,
"basicRules": true,
"type": "FD",
"rarity": "none",
"value": 20
},
{
"name": "Ale (Mug)",
"source": "PHB",
"page": 158,
"srd": true,
"basicRules": true,
"type": "FD",
"rarity": "none",
"value": 4
},
{
"name": "Alexandrite",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 50000,
"entries": [
"A transparent dark green gemstone."
]
},
{
"name": "Amber",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 10000,
"entries": [
"A transparent watery gold to rich gold gemstone."
]
},
{
"name": "Amber Runestone",
"source": "NRH-TLT",
"page": 6,
"rarity": "uncommon",
"wondrous": true,
"entries": [
"This piece of polished amber is engraved with many tiny runes. As a bonus action, you can mentally command the stone to shed bright light in a 15-foot radius and dim light for an additional 15 feet. The light is sunlight. While the light persists, you can also use a bonus action to expand or reduce its radius of bright and dim light by 5 feet each, to a maximum of 30 feet each or a minimum of 10 feet each."
]
},
{
"name": "Amethyst",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 10000,
"entries": [
"A transparent deep purple gemstone."
]
},
{
"name": "Amethyst Lodestone",
"source": "FTD",
"page": 22,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"modifySpeed": {
"equal": {
"fly": "walk"
}
},
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6}",
"charges": 6,
"entries": [
"This fist-sized chunk of amethyst is infused with an amethyst dragon's ability to bend gravitational forces. While you are carrying the lodestone, you have advantage on Strength saving throws.",
"The lodestone has 6 charges for the following properties, which you can use while you are holding the stone. The stone regains {@dice 1d6} expended charges daily at dawn.",
{
"type": "entries",
"name": "Flight",
"entries": [
"As a bonus action, you can expend 1 charge to gain the power of flight for 10 minutes. For the duration, you gain a flying speed equal to your walking speed, and you can hover."
]
},
{
"type": "entries",
"name": "Gravitational Thrust",
"entries": [
"As an action, you can expend 1 charge to focus gravity around a creature you can see within 60 feet of you. The target must succeed on a DC 18 Strength saving throw or be pushed up to 20 feet in a direction of your choice."
]
},
{
"type": "entries",
"name": "Reverse Gravity",
"entries": [
"As an action, you can expend 3 charges to cast {@spell reverse gravity} from the stone (save DC 18)."
]
}
],
"attachedSpells": [
"reverse gravity"
]
},
{
"name": "Amulet",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "SCF",
"scfType": "holy",
"rarity": "none",
"weight": 1,
"value": 500
},
{
"name": "Amulet of dinosaur feathers (Sun Empire)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 2500
},
{
"name": "Amulet of Harmony",
"source": "NRH-AT",
"page": 8,
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"entries": [
"The amulet is a golden disk about 4 inches across. There are symbols etched into its surface and there are six bezels that hold the Gems of Power.",
"The Amulet of Harmony has the following properties:",
{
"type": "list",
"items": [
"While attuned to this amulet, you feel a pull in the direction of the Prismatic Well.",
"If the Prismatic Well is dormant, you can restore its power by expending your own energy. You must be within 10-feet of the well to restore its power and you must say the words, \"I invoke the power of the Prismatic Well and restore the colors of Harmony for all.\" If you do so, roll a {@dice d6}. On a 2-6, the power of the well is restored. On a 1, the well is restored but you fall into a deep, magical slumber for 1 year and cannot be roused except by a remove curse spell or similar magic.",
"You can turn off the power of the Prismatic Well, plunging all of Harmony into a polarized world where everything is horribly black and white. To do this, you must be within 10-feet of the well and you must say the words, \"I revoke the power of the Prismatic Well and withhold the colors of Harmony to all.\" If you do so, roll a {@dice d6}. On a 3-6, the well is turned off. On 1-2, the power of the well remains and you turn into a statue of black stone."
]
},
"Only a wish spell can undo this effect."
]
},
{
"name": "Amulet of Health",
"source": "DMG",
"page": 150,
"srd": true,
"basicRules": true,
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"weight": 1,
"ability": {
"static": {
"con": 19
}
},
"entries": [
"Your Constitution score is 19 while you wear this amulet. It has no effect on you if your Constitution score is already 19 or higher without it."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Amulet of Proof against Detection and Location",
"source": "DMG",
"page": 150,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"weight": 1,
"entries": [
"While wearing this amulet, you are hidden from {@filter divination|spells|school=D} magic. You can't be targeted by such magic or perceived through magical scrying sensors."
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Amulet of Protection from Turning",
"source": "TftYP",
"page": 228,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"weight": 1,
"entries": [
"While you wear this amulet of silver and turquoise, you have advantage on saving throws against effects that turn undead.",
"If you fail a saving throw against such an effect, you can choose to succeed instead. You can do so three times, and expended uses recharge daily at dawn. Each time an effect that turns undead is used against you, the amulet glows with silvery blue light for a few seconds."
]
},
{
"name": "Amulet of the Black Skull",
"source": "ToA",
"page": 206,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"weight": 1,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6}",
"charges": 6,
"entries": [
"This amulet is carved from obsidian and shaped like a screaming humanoid skull, with ruby eyes and emeralds for teeth. It hangs from an iron chain necklace.",
"The amulet has 6 charges and regains {@dice 1d6} charges daily at dawn. While wearing the amulet, you can use an action to expend 1 of its charges to transport yourself and anything you are wearing or carrying to a location within 100 feet of you. The destination you choose doesn't need to be in your line of sight, but it must be familiar to you (in other words, a place you have seen or visited), and it must be on the same plane of existence as you. This effect isn't subject to the magic restrictions placed on the Tomb of the Nine Gods; thus, the amulet can be used to enter and exit the tomb.",
"If you aren't undead, you must make a DC 16 Constitution saving throw each time you use the amulet to teleport. On a failed saving throw, the black skull cackles as you are transformed in transit. The transformation takes effect as soon as you arrive at the destination, and is determined randomly by rolling percentile dice and consulting the Black Skull Transformation table.",
{
"type": "table",
"caption": "Black Skull Transformation",
"colLabels": [
"{@dice d100}",
"Transformation"
],
"colStyles": [
"col-1 text-center",
"col-11"
],
"rows": [
[
"01-20",
"The symbol of {@creature Acererak|ToA} is burned into your flesh, a curse that can only be removed with a {@spell remove curse} spell or similar magic. Until the curse ends, your hit points can't be restored by magic."
],
[
"21-35",
"You grow larger as if affected by an {@spell enlarge/reduce} spell, except the effect lasts for 1 hour."
],
[
"36-50",
"You grow smaller as if affected by an {@spell enlarge/reduce} spell, except the effect lasts for 1 hour."
],
[
"51-70",
"You arrive at the destination wearing nothing but the amulet of the black skull. Everything else that you were wearing or carrying appears in a random unoccupied space within 100 feet of you."
],
[
"71-95",
"You are {@condition paralyzed} for 1 minute or until this effect is ended with a {@spell lesser restoration} spell or similar magic."
],
[
"96-00",
"You become {@condition petrified}. This effect can be ended only with a {@spell greater restoration} spell or similar magic."
]
]
}
]
},
{
"name": "Amulet of the Drunkard",
"source": "EGW",
"page": 265,
"rarity": "uncommon",
"wondrous": true,
"entries": [
"This amulet smells of old, ale-stained wood. While wearing it, you can regain {@dice 4d4 + 4} hit points when you drink a pint of beer, ale, mead, or wine. Once the amulet has restored hit points, it can't do so again until the next dawn."
],
"hasFluffImages": true
},
{
"name": "Amulet of the Planes",
"source": "DMG",
"page": 150,
"srd": true,
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"weight": 1,
"entries": [
"While wearing this amulet, you can use an action to name a location that you are familiar with on another plane of existence. Then make a DC 15 Intelligence check. On a successful check, you cast the {@spell plane shift} spell. On a failure, you and each creature and object within 15 feet of you travel to a random destination. Roll a {@dice d100}. On a 1-60, you travel to a random location on the plane you named. On a 61-100, you travel to a randomly determined plane of existence."
],
"attachedSpells": [
"plane shift"
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Animated Shield",
"source": "DMG",
"page": 151,
"srd": true,
"type": "S",
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"weight": 6,
"ac": 2,
"entries": [
"While holding this shield, you can speak its command word as a bonus action to cause it to animate. The shield leaps into the air and hovers in your space to protect you as if you were wielding it, leaving your hands free. The shield remains animated for 1 minute, until you use a bonus action to end this effect, or until you are {@condition incapacitated} or die, at which point the shield falls to the ground or into your hand if you have one free."
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Antitoxin (vial)",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"value": 5000,
"entries": [
"A creature that drinks this vial of liquid gains advantage on saving throws against poison for 1 hour. It confers no benefit to undead or constructs."
]
},
{
"name": "Apparatus of Kwalish",
"source": "DMG",
"page": 151,
"srd": "Apparatus of the Crab",
"tier": "major",
"rarity": "legendary",
"wondrous": true,
"weight": 500,
"entries": [
"This item first appears to be a Large sealed iron barrel weighing 500 pounds. The barrel has a hidden catch, which can be found with a successful DC 20 Intelligence ({@skill Investigation}) check. Releasing the catch unlocks a hatch at one end of the barrel, allowing two Medium or smaller creatures to crawl inside. Ten levers are set in a row at the far end, each in a neutral position, able to move either up or down. When certain levers are used, the apparatus transforms to resemble a giant lobster.",
"The apparatus of Kwalish is a Large object with the following statistics:",
{
"type": "list",
"style": "list-hang-notitle",
"items": [
{
"type": "item",
"name": "Armor Class:",
"entry": "20"
},
{
"type": "item",
"name": "Hit Points:",
"entry": "200"
},
{
"type": "item",
"name": "Speed:",
"entry": "30 ft., swim 30 ft. (or 0 ft. for both if the legs and tail aren't extended)"
},
{
"type": "item",
"name": "Damage Immunities:",
"entry": "poison, psychic"
}
]
},
"To be used as a vehicle, the apparatus requires one pilot. While the apparatus's hatch is closed, the compartment is airtight and watertight. The compartment holds enough air for 10 hours of breathing, divided by the number of breathing creatures inside.",
"The apparatus floats on water. It can also go underwater to a depth of 900 feet. Below that, the vehicle takes {@damage 2d6} bludgeoning damage per minute from pressure.",
"A creature in the compartment can use an action to move as many as two of the apparatus's levers up or down. After each use, a lever goes back to its neutral position. Each lever, from left to right, functions as shown in the Apparatus of Kwalish Levers table.",
{
"type": "table",
"caption": "Apparatus of Kwalish Levers:",
"colLabels": [
"Lever",
"Up",
"Down"
],
"colStyles": [
"col-1 text-center",
"col-5-5",
"col-5-5"
],
"rows": [
[
"1",
"Legs and tail extend, allowing the apparatus to walk and swim.",
"Legs and tail retract, reducing the apparatus's speed to 0 and making it unable to benefit from bonuses to speed."
],
[
"2",
"Forward window shutter opens.",
"Forward window shutter closes."
],
[
"3",
"Side window shutters open (two per side).",
"Side window shutters close (two per side)."
],
[
"4",
"Two claws extend from the front sides of the apparatus.",
"The claws retract."
],
[
"5",
"Each extended claw makes the following melee weapon attack: {@hit 8} to hit, reach 5 ft., one target. {@h} 7 ({@damage 2d6}) bludgeoning damage.",
"Each extended claw makes the following melee weapon attack: {@hit 8} to hit, reach 5 ft., one target. {@h} The target is {@condition grappled} (escape {@dc 15})."
],
[
"6",
"The apparatus walks or swims forward.",
"The apparatus walks or swims backward."
],
[
"7",
"The apparatus turns 90 degrees left.",
"The apparatus turns 90 degrees right."
],
[
"8",
"Eyelike fixtures emit bright light in a 30-foot radius and dim light for an additional 30 feet.",
"The light turns off."
],
[
"9",
"The apparatus sinks as much as 20 feet in liquid.",
"The apparatus rises up to 20 feet in liquid."
],
[
"10",
"The rear hatch unseals and opens.",
"The rear hatch closes and seals."
]
]
}
],
"lootTables": [
"Magic Item Table I"
],
"seeAlsoVehicle": [
"Apparatus of Kwalish|DMG"
],
"hasFluffImages": true
},
{
"name": "Aquamarine",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 50000,
"entries": [
"A transparent pale blue-green gemstone."
]
},
{
"name": "Arcanaloth's Music Box",
"source": "ToA",
"page": 188,
"type": "OTH",
"rarity": "unknown (magic)",
"value": 75000,
"entries": [
"This music box is made of dark wood with gold filigree. Each of its five sides is sculpted with the image of a horned woman playing a different {@item musical instrument|PHB}: a {@item dulcimer|PHB}, a {@item flute|PHB}, a harp, a {@item lyre|PHB}, and a {@item viol|PHB}. While touching the box, you can use an action to make it play music featuring one of the instruments shown, which can be heard up to 60 feet away. You can also use an action to stop the music."
]
},
{
"name": "Arcane Cannon",
"source": "EGW",
"page": 265,
"rarity": "very rare",
"wondrous": true,
"entries": [
"This Large cannon is imbued with magic. It requires no ammunition and doesn't need to be loaded. It takes one action to aim the cannon and one action to fire it. After the cannon has fired, it must recharge for 5 minutes before it can be fired again.",
"The creature firing the cannon chooses the effect from the following options:",
{
"type": "entries",
"name": "Acid Jet",
"entries": [
"The cannon discharges acid in a line 300 feet long and 5 feet wide. Each creature in that line must make a DC 15 Dexterity saving throw, taking 22 ({@damage 4d10}) acid damage on a failed save, or half as much damage on a successful one. In addition, a creature that fails its saving throw takes 11 ({@damage 2d10}) acid damage at the start of each of its turns; a creature can end this damage by using its action to wash off the acid with a pint or more of water."
]
},
{
"type": "entries",
"name": "Fire Jet",
"entries": [
"The cannon discharges fire in a line 300 feet long and 5 feet wide. Each creature in the area must make a DC 15 Dexterity saving throw, taking 33 ({@damage 6d10}) fire damage on a failed save, or half as much damage on a successful one. The fire ignites any flammable objects in the area that aren't being worn or carried."
]
},
{
"type": "entries",
"name": "Frost Shot",
"entries": [
"The cannon shoots a ball of frost to a point you can see within 1,200 feet of the cannon. The ball then expands to form a 30-foot-radius sphere centered on that point. Each creature in that area must make a DC 15 Constitution saving throw. On a failed save, a creature takes 22 ({@damage 4d10}) cold damage, and its speed is reduced by 10 feet for 1 minute. On a successful save, the creature takes half as much damage, and its speed isn't reduced. A creature whose speed is reduced by this effect can repeat the save at the end of each of its turns, ending the effect on itself on a success."
]
},
{
"type": "entries",
"name": "Lightning Shot",
"entries": [
"The cannon shoots a ball of lightning to a point you can see within 1,200 feet of the cannon. The lightning then expands to form a 20-foot-radius sphere centered on that point. Each creature in that area must make a DC 15 Dexterity saving throw, taking 33 ({@damage 6d10}) lightning damage on a failed save, or half as much damage on a successful one. Creatures wearing metal armor have disadvantage on the save."
]
},
{
"type": "entries",
"name": "Poison Spray",
"entries": [
"The cannon expels poison gas in a 60-foot cone. Each creature in that area must make a DC 15 Constitution saving throw. On a failed save, the creature takes 22 ({@damage 4d10}) poison damage and is {@condition poisoned} for 1 minute. On a successful save, the creature takes half as much damage and isn't {@condition poisoned}. A creature {@condition poisoned} in this way can repeat the saving throw at the end of each of its turns, ending the effect on itself on a success."
]
}
]
},
{
"name": "Arcane Propulsion Arm",
"source": "ERLW",
"page": 276,
"rarity": "very rare",
"reqAttune": "by a creature missing a hand or an arm",
"wondrous": true,
"property": [
"T"
],
"range": "20/60",
"dmg1": "1d8",
"dmgType": "O",
"weapon": true,
"entries": [
"This prosthetic appendage was developed by artificers of House Cannith. To attune to this item, you must attach it to your arm at the wrist, elbow, or shoulder, at which point the prosthetic magically forms a copy of the appendage it's replacing.",
"While attached, the prosthetic provides these benefits:",
{
"type": "list",
"items": [
"The prosthetic is a fully capable part of your body.",
"You can take an action to remove the prosthetic, and it removes itself if your attunement to it ends. It can't be removed against your will.",
"The prosthetic is a magic melee weapon with which you're proficient. It deals {@damage 1d8} force damage on a hit and has the thrown property, with a normal range of 20 feet and a long range of 60 feet. When thrown, the prosthetic detaches and flies at the target of the attack, then immediately returns to you and reattaches."
]
}
],
"optionalfeatures": [
"replicate magic item|tce"
]
},
{
"name": "Armor of Invulnerability",
"source": "DMG",
"page": 152,
"srd": true,
"type": "HA",
"tier": "major",
"rarity": "legendary",
"reqAttune": true,
"weight": 65,
"ac": 18,
"strength": "15",
"stealth": true,
"entries": [
"You have resistance to nonmagical damage while you wear this armor. Additionally, you can use an action to make yourself immune to nonmagical damage for 10 minutes or until you are no longer wearing the armor. Once this special action is used, it can't be used again until the next dawn."
],
"lootTables": [
"Magic Item Table I"
],
"hasFluffImages": true
},
{
"name": "Armor of Vulnerability (Bludgeoning)",
"source": "DMG",
"page": 152,
"srd": true,
"baseItem": "plate armor|phb",
"type": "HA",
"resist": [
"bludgeoning"
],
"vulnerable": [
"piercing",
"slashing"
],
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"curse": true,
"weight": 65,
"ac": 18,
"strength": "15",
"stealth": true,
"entries": [
"While wearing this armor, you have resistance to bludgeoning damage.",
{
"type": "entries",
"name": "Curse",
"entries": [
"This armor is cursed, a fact that is revealed only when an {@spell identify} spell is cast on the armor or you attune to it. Attuning to the armor curses you until you are targeted by the {@spell remove curse} spell or similar magic; removing the armor fails to end the curse. While cursed you have vulnerability to piercing and slashing damage."
]
}
]
},
{
"name": "Armor of Vulnerability (Piercing)",
"source": "DMG",
"page": 152,
"srd": true,
"baseItem": "plate armor|phb",
"type": "HA",
"resist": [
"piercing"
],
"vulnerable": [
"bludgeoning",
"slashing"
],
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"curse": true,
"weight": 65,
"ac": 18,
"strength": "15",
"stealth": true,
"entries": [
"While wearing this armor, you have resistance to piercing damage.",
{
"type": "entries",
"name": "Curse",
"entries": [
"This armor is cursed, a fact that is revealed only when an {@spell identify} spell is cast on the armor or you attune to it. Attuning to the armor curses you until you are targeted by the {@spell remove curse} spell or similar magic; removing the armor fails to end the curse. While cursed you have vulnerability to bludgeoning and slashing damage."
]
}
]
},
{
"name": "Armor of Vulnerability (Slashing)",
"source": "DMG",
"page": 152,
"srd": true,
"baseItem": "plate armor|phb",
"type": "HA",
"resist": [
"slashing"
],
"vulnerable": [
"bludgeoning",
"piercing"
],
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"curse": true,
"weight": 65,
"ac": 18,
"strength": "15",
"stealth": true,
"entries": [
"While wearing this armor, you have resistance to slashing damage.",
{
"type": "entries",
"name": "Curse",
"entries": [
"This armor is cursed, a fact that is revealed only when an {@spell identify} spell is cast on the armor or you attune to it. Attuning to the armor curses you until you are targeted by the {@spell remove curse} spell or similar magic; removing the armor fails to end the curse. While cursed you have vulnerability to bludgeoning and piercing damage."
]
}
]
},
{
"name": "Arrow-Catching Shield",
"source": "DMG",
"page": 152,
"srd": true,
"type": "S",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"weight": 6,
"ac": 2,
"bonusAc": "+2",
"entries": [
"You gain a +2 bonus to AC against ranged attacks while you wield this shield. This bonus is in addition to the shield's normal bonus to AC. In addition, whenever an attacker makes a ranged attack against a target within 5 feet of you, you can use your reaction to become the target of the attack instead."
],
"lootTables": [
"Magic Item Table G"
]
},
{
"name": "Ascendant Dragon Vessel",
"source": "FTD",
"_copy": {
"name": "Wakened Dragon Vessel",
"source": "FTD",
"_preserve": {
"page": true
}
},
"rarity": "legendary",
"entries": [
"This vessel can be a potion bottle, drinking horn, or other container meant to hold a liquid.",
"As a bonus action, if the vessel is empty, you can speak the command word to fill the vessel with one of the following (your choice): ale, olive oil, mead, wine, whiskey, a {@item potion of healing}, a {@item potion of greater healing}, a {@item potion of superior healing}, a {@item potion of supreme healing}, a {@item potion of climbing}, a {@item potion of fire breath}, a {@item potion of flying}, or a {@item potion of dragon's majesty|FTD}. Once this property is used, it can't be used until the next dawn. A potion you create in this way loses its magical properties if it isn't imbibed within 24 hours."
]
},
{
"name": "Ascendant Dragon-Touched Focus",
"source": "FTD",
"_copy": {
"name": "Wakened Dragon-Touched Focus",
"source": "FTD",
"_preserve": {
"page": true
}
},
"rarity": "legendary",
"entries": [
"This wondrous item can be a scepter, an orb, an amulet, a crystal, or another finely crafted object. It typically incorporates imagery of dragons' wings, claws, teeth, or scales.",
"You have advantage on initiative rolls. While you are holding the focus, it can function as a spellcasting focus for all your spells.",
"The focus gains an additional property determined by the family of the dragon in whose hoard it became Stirring:",
{
"type": "list",
"style": "list-hang-notitle",
"items": [
{
"type": "item",
"name": "Chromatic",
"entries": [
"Whenever you use a spell slot to cast a spell that deals acid, cold, fire, lightning, or poison damage, roll a {@dice d6}, and you gain a bonus equal to the number rolled to one of the spell's damage rolls."
]
},
{
"type": "item",
"name": "Gem",
"entries": [
"Whenever you use a spell slot to cast a spell, you can immediately teleport to an unoccupied space you can see within 15 feet of you."
]
},
{
"type": "item",
"name": "Metallic",
"entries": [
"When a creature you can see within 30 feet of you makes a saving throw, you can use your reaction to give that creature advantage on the saving throw."
]
}
]
},
"While you are holding a Wakened focus, you can use it to cast certain spells. Once the item is used to cast a given spell, it can't be used to cast that spell again until the next dawn. The spells are determined by the family of the dragon in whose hoard it became Wakened.",
{
"type": "list",
"style": "list-hang-notitle",
"items": [
{
"type": "item",
"name": "Chromatic",
"entries": [
"{@spell Hold monster}, {@spell Rime's binding ice|FTD}"
]
},
{
"type": "item",
"name": "Gem",
"entries": [
"{@spell Rary's telepathic bond}, {@spell Raulothim's psychic lance|FTD}"
]
},
{
"type": "item",
"name": "Metallic",
"entries": [
"{@spell Fizban's platinum shield|FTD}, {@spell legend lore}"
]
}
]
},
"When you cast a spell of 1st level or higher while holding this focus, you can treat the spell as if it were cast using a 9th-level spell slot. Once this property is used, it can't be used again until the next dawn."
]
},
{
"name": "Ascendant Scaled Ornament",
"source": "FTD",
"_copy": {
"name": "Wakened Scaled Ornament",
"source": "FTD",
"_preserve": {
"page": true
}
},
"rarity": "legendary",
"entries": [
"This ornament can be jewelry, a cloak, or another wearable accessory. It appears to be fashioned from a dragon's scale, tooth, or claw, or it incorporates images in those shapes.",
"You gain a +1 bonus to AC, and you can't be {@condition charmed} or {@condition frightened}. Moreover, each creature of your choice within 30 feet of you has advantage on saving throws it makes to avoid being {@condition charmed} or {@condition frightened} or to end those conditions on itself.",
"When you would take damage of the type dealt by the breath of the dragon in whose hoard the ornament became Wakened, you can use your reaction to take no damage instead, and you regain hit points equal to the damage you would have taken. Once this property is used, it can't be used again until the next dawn.",
"While you are wearing the ornament, you gain a flying speed equal to your walking speed and can hover. While you are flying using this speed, spectral dragon wings appear on your back."
]
},
{
"name": "Assassin's Blood",
"source": "DMG",
"page": 258,
"srd": true,
"type": "G",
"rarity": "none",
"value": 15000,
"poison": true,
"entries": [
"A creature subjected to this poison must make a DC 10 Constitution saving throw. On a failed save, it takes 6 ({@damage 1d12}) poison damage and is {@condition poisoned} for 24 hours. On a successful save, the creature takes half damage and isn't {@condition poisoned}."
],
"poisonTypes": [
"ingested"
]
},
{
"name": "Astral Shard",
"source": "TCE",
"page": 120,
"rarity": "rare",
"reqAttune": "by a sorcerer",
"reqAttuneTags": [
{
"class": "sorcerer"
}
],
"wondrous": true,
"weight": 1,
"focus": [
"Sorcerer"
],
"entries": [
"This crystal is a solidified shard of the Astral Plane, swirling with silver mist. As an action, you can attach the shard to a Tiny object (such as a weapon or a piece of jewelry) or detach it. It falls off if your attunement to it ends. You can use the shard as a spellcasting focus while you hold or wear it.",
"When you use a Metamagic option on a spell while you are holding or wearing the shard, immediately after casting the spell you can teleport to an unoccupied space you can see within 30 feet of you."
]
},
{
"name": "Astromancy Archive",
"source": "TCE",
"page": 120,
"rarity": "rare",
"reqAttune": "by a wizard",
"reqAttuneTags": [
{
"class": "wizard"
}
],
"wondrous": true,
"weight": 3,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"focus": [
"Wizard"
],
"entries": [
"This brass disc of articulated, concentric rings unfolds into an armillary sphere. As a bonus action, you can unfold it into the sphere or back into a disc. When found, it contains the following spells, which are wizard spells for you while you are attuned to it: {@spell augury}, {@spell divination}, {@spell find the path}, {@spell foresight}, {@spell locate creature}, and {@spell locate object}. It functions as a spellbook for you, with spells encoded on the rings.",
"While you are holding the archive, you can use it as a spellcasting focus for your wizard spells.",
"The archive has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. You can use the charges in the following ways while holding it:",
{
"type": "list",
"items": [
"If you spend 1 minute studying the archive, you can expend 1 charge to replace one of your prepared wizard spells with a different spell in the archive. The new spell must be of the divination school.",
"When a creature you can see within 30 feet of you makes an attack roll, an ability check, or a saving throw, you can use your reaction to expend 1 charge and force the creature to roll a {@dice d4} and apply the number rolled as a bonus or penalty (your choice) to the original roll. You can do this after you see the roll but before its effects are applied."
]
}
],
"attachedSpells": [
"augury",
"divination",
"find the path",
"foresight",
"locate creature",
"locate object"
]
},
{
"name": "Atlas of Endless Horizons",
"source": "TCE",
"page": 120,
"rarity": "rare",
"reqAttune": "by a wizard",
"reqAttuneTags": [
{
"class": "wizard"
}
],
"wondrous": true,
"weight": 3,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"focus": [
"Wizard"
],
"entries": [
"This thick book is bound in dark leather, crisscrossed with inlaid silver lines suggesting a map or chart. When found, the book contains the following spells, which are wizard spells for you while you are attuned to the book: {@spell arcane gate}, {@spell dimension door}, {@spell gate}, {@spell misty step}, {@spell plane shift}, {@spell teleportation circle}, and {@spell word of recall}. It functions as a spellbook for you.",
"While you are holding the book, you can use it as a spellcasting focus for your wizard spells.",
"The book has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. You can use the charges in the following ways while holding it:",
{
"type": "list",
"items": [
"If you spend 1 minute studying the book, you can expend 1 charge to replace one of your prepared wizard spells with a different spell in the book. The new spell must be of the conjuration school.",
"When you are hit by an attack, you can use your reaction to expend 1 charge to teleport up to 10 feet to an unoccupied space you can see. If your new position is out of range of the attack, it misses you."
]
}
],
"attachedSpells": [
"arcane gate",
"dimension door",
"gate",
"misty step",
"plane shift",
"teleportation circle",
"word of recall"
],
"hasFluffImages": true
},
{
"name": "Axe Beak",
"source": "IDRotF",
"page": 20,
"type": "MNT",
"rarity": "none",
"value": 5000,
"entries": [
"An {@creature axe beak}'s splayed toes allow it to run across snow, and it can carry as much weight as a mule. A domesticated axe beak can be purchased in Ten-Towns for 50 gp."
]
},
{
"name": "Axe of the Dwarvish Lords",
"source": "DMG",
"page": 221,
"baseItem": "battleaxe|phb",
"type": "M",
"immune": [
"poison"
],
"rarity": "artifact",
"reqAttune": true,
"curse": true,
"weight": 4,
"weaponCategory": "martial",
"property": [
"T",
"V"
],
"range": "20/60",
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"grantsProficiency": true,
"bonusWeapon": "+3",
"entries": [
"Seeing the peril his people faced, a young dwarf prince came to believe that his people needed something to unite them. Thus, he set out to forge a weapon that would be such a symbol.",
"Venturing deep under the mountains, deeper than any dwarf had ever delved, the young prince came to the blazing heart of a great volcano. With the aid of Moradin, the dwarven god of creation, he first crafted four great tools: the {@italic Brutal Pick}, the {@italic Earthheart Forge}, the {@italic Anvil of Songs}, and the {@italic Shaping Hammer}. With them, he forged the {@italic Axe of the Dwarvish Lords}.",
"Armed with the artifact, the prince returned to the dwarf clans and brought peace. His axe ended grudges and answered slights. The clans became allies, and they threw back their enemies and enjoyed an era of prosperity. This young dwarf is remembered as the First King. When he became old, he passed the weapon, which had become his badge of office, to his heir. The rightful inheritors passed the axe on for many generations.",
"Later, in a dark era marked by treachery and wickedness, the axe was lost in a bloody civil war fomented by greed for its power and the status it bestowed. Centuries later, the dwarves still search for the axe, and many adventurers have made careers of chasing after rumors and plundering old vaults to find it.",
{
"name": "Magic Weapon",
"type": "entries",
"entries": [
"The {@italic Axe of the Dwarvish Lords} is a magic weapon that grants a +3 bonus to attack and damage rolls made with it. The axe also functions as a {@item belt of dwarvenkind}, a {@item dwarven thrower}, and a {@item sword of sharpness}."
]
},
{
"type": "entries",
"name": "Random Properties",
"entries": [
"The axe has the following randomly determined properties:",
{
"type": "list",
"items": [
"2 {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial properties}",
"1 {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial property}",
"2 {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental properties}"
]
}
]
},
{
"type": "entries",
"name": "Blessings of Moradin",
"entries": [
"If you are a dwarf attuned to the axe, you gain the following benefits:",
{
"type": "list",
"items": [
"You have immunity to poison damage.",
"The range of your {@sense darkvision} increases by 60 feet.",
"You gain proficiency with {@item artisan's tools|PHB} related to blacksmithing, brewing, and stonemasonry."
]
}
]
},
{
"type": "entries",
"name": "Conjure Earth Elemental",
"entries": [
"If you are holding the axe, you can use your action to cast the {@spell conjure elemental} spell from it, summoning an {@creature earth elemental}. You can't use this property again until the next dawn."
]
},
{
"type": "entries",
"name": "Travel the Depths",
"entries": [
"You can use an action to touch the axe to a fixed piece of dwarven stonework and cast the {@spell teleport} spell from the axe. If your intended destination is underground, there is no chance of a mishap or arriving somewhere unexpected. You can't use this property again until 3 days have passed."
]
},
{
"type": "entries",
"name": "Curse",
"entries": [
"The axe bears a curse that affects any non-dwarf that becomes attuned to it. Even if the attunement ends, the curse remains. With each passing day, the creature's physical appearance and stature become more dwarflike. After seven days, the creature looks like a typical dwarf, but the creature neither loses its racial traits nor gains the racial traits of a dwarf. The physical changes wrought by the axe aren't considered magical in nature (and therefore can't be dispelled), but they can be undone by any effect that removes a curse, such as a {@spell greater restoration} or {@spell remove curse} spell."
]
},
{
"type": "entries",
"name": "Destroying the Axe",
"entries": [
"The only way to destroy the axe is to melt it down in the {@italic Earthheart Forge}, where it was created. It must remain in the burning forge for fifty years before it finally succumbs to the fire and is consumed."
]
}
],
"attachedSpells": [
"conjure elemental",
"teleport"
],
"hasFluffImages": true
},
{
"name": "Azorius Guild Signet",
"source": "GGR",
"page": 178,
"type": "RG",
"rarity": "uncommon",
"reqAttune": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"This ring, adorned with the symbol of Azorius, allows you to cast {@spell ensnaring strike}. A guild signet is sometimes awarded to a guild member whose renown score in that guild is 5 or higher, as a reward for performing special services for the guild. Aside from its magical properties, the ring is also an indicator of Azorius's recognition and favor.",
"A signet has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. While wearing it, you can expend 1 charge to cast the associated spell (save DC 13)."
],
"attachedSpells": [
"ensnaring strike"
],
"hasFluffImages": true
},
{
"name": "Azorius Keyrune",
"source": "GGR",
"page": 177,
"rarity": "rare",
"reqAttune": "by a member of the Azorius guild",
"reqAttuneTags": [
{
"background": "azorius functionary|ggr"
}
],
"wondrous": true,
"entries": [
"This keyrune is carved from white marble and lapis lazuli to resemble a noble bird of prey. It can become a {@creature giant eagle} for up to 1 hour. While the transformed eagle is within 1 mile of you, you can communicate with it telepathically. As an action, you can see through the eagle's eyes and hear what it hears until the start of your next turn, and you gain the benefit of its keen sight. During this time, you are deaf and blind with regard to your own senses.",
"When you use an action to speak the item's command word and place the keyrune on the ground in an unoccupied space within 5 feet of you, the keyrune transforms into a {@creature giant eagle}. If there isn't enough space for the eagle, the keyrune doesn't transform.",
"The creature is friendly to you, your companions, and other members of your guild (unless those guild members are hostile to you). It understands your languages and obeys your spoken commands. If you issue no commands, the eagle takes the {@action Dodge} action and moves to avoid danger.",
"At the end of the duration, the creature reverts to its keyrune form. It reverts early if it drops to 0 hit points or if you use an action to speak the command word again while touching it. When the creature reverts to its keyrune form, it can't transform again until 36 hours have passed."
],
"hasFluffImages": true
},
{
"name": "Azuredge",
"source": "WDH",
"page": 189,
"baseItem": "battleaxe|phb",
"type": "M",
"rarity": "legendary",
"reqAttune": true,
"sentient": true,
"weight": 4,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"bonusWeapon": "+3",
"recharge": "dawn",
"rechargeAmount": 3,
"charges": 3,
"entries": [
"Forged by the archwizard Ahghairon, this intelligent battleaxe was crafted to defend Waterdeep. Its current wielder is a former member of Force Grey named {@creature Meloon Wardragon|WDH}, but the weapon is searching for a new owner.",
"Azuredge has a solid steel handle etched with tiny runes, wrapped in blue dragon hide with a star sapphire set into the pommel. The axe head is forged from silver, electrum, and steel alloys whose edges constantly shimmer with a deep blue luminescence.",
"You gain a +3 bonus to attack and damage rolls made with this magic weapon. The {@spell shield} spell provides no defense against the axe, which passes through that spell's barrier of magical force.",
"When you hit a fiend or an undead with the axe, cold blue flames erupt from its blade and deal an extra {@damage 2d6} radiant damage to the target.",
{
"type": "entries",
"name": "Hurling",
"entries": [
"The battleaxe has 3 charges. You can expend 1 charge and make a ranged attack with the axe, hurling it as if it had the thrown property with a normal range of 60 feet and a long range of 180 feet. Whether it hits or misses, the axe flies back to you at the end of the current turn, landing in your open hand or at your feet in your space (as you choose). The axe regains all expended charges daily at dawn."
]
},
{
"type": "entries",
"name": "Illumination",
"entries": [
"While holding the axe, you can use an action to cause the axe to glow blue or to quench the glow. This glow sheds bright light in a 30-foot radius and dim light for an additional 30 feet."
]
},
{
"type": "entries",
"name": "Sentience",
"entries": [
"Azuredge is a sentient lawful neutral weapon with an Intelligence of 12, a Wisdom of 15, and a Charisma of 15. It has hearing and {@sense darkvision} out to a range of 120 feet.",
"The weapon communicates telepathically with its wielder and can speak, read, and understand Common. It has a calm, delicate voice. The weapon can sense the presence of non-lawful creatures within 120 feet of it."
]
},
{
"type": "entries",
"name": "Personality",
"entries": [
"Azuredge is sworn to protect Waterdeep, and it desires to be wielded by a law-abiding person willing to dedicate everything to the city's defense. The weapon is patient and takes its time finding its ideal wielder.",
"If someone tries to use Azuredge against its will, the axe can become ten times heavier than normal, and can magically adhere to any Medium or larger object or surface it comes into contact with. Once it does so, the axe can't be wielded. Nothing short of a {@spell wish} spell can separate the axe from the item or surface to which it is adhered without destroying one or the other, though the axe can choose to end the effect at any time."
]
}
],
"attachedSpells": [
"shield"
]
},
{
"name": "Azurite",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 1000,
"entries": [
"An opaque mottled deep blue gemstone."
]
},
{
"name": "Baba Yaga's Mortar and Pestle",
"source": "TCE",
"page": 121,
"rarity": "artifact",
"reqAttune": true,
"wondrous": true,
"bonusWeapon": "+3",
"recharge": "dawn",
"rechargeAmount": 12,
"charges": 12,
"entries": [
"The creations of the immortal hag Baba Yaga defy the laws of mortal magic. Among the notorious implements that cement her legend on countless worlds are the artifacts that propel her through the planes: Baba Yaga's Mortar and Pestle. These signature tools of Baba Yaga are a single artifact for purposes of attunement. Should the two objects become separated, the pestle appears next to the mortar at the next dawn.",
{
"type": "entries",
"name": "Random Properties",
"entries": [
"This artifact has the following random properties, which you can determine by rolling on the tables in the \"Artifacts\" section of the {@book Dungeon Master's Guide|DMG}:",
{
"type": "list",
"items": [
"2 {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} properties",
"1 {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial} property",
"1 {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental} property"
]
}
]
},
{
"type": "entries",
"name": "Properties of the Mortar",
"entries": [
"The mortar is a Tiny wooden bowl. However, the mortar increases in size to accommodate anything you place inside, expanding-if there's enough space-up to Large size, meaning it can hold even a Large creature."
]
},
{
"type": "entries",
"name": "Properties of the Pestle",
"entries": [
"The pestle is a 6-inch-long, worn wooden tool. Once during your turn while you are holding the pestle, you can extend it into a quarterstaff or shrink it back into a pestle (no action required). As a quarterstaff, the pestle is a magic weapon that grants a +3 bonus to attack and damage rolls made with it.",
"The pestle has 12 charges. When you hit with a melee attack using the pestle, you can expend up to 3 of its charges to deal an extra {@damage 1d8} force damage for each charge expended. The pestle regains all expended charges daily at dawn."
]
},
{
"type": "entries",
"name": "Perfect Tools",
"entries": [
"While holding the mortar and pestle, you can use your action to say the name of any nonmagical plant, mineral, or fluid and an amount of the material worth 10 gp or less. The mortar instantly fills with the desired amount of that material. Once you use this action, you can't do so again until you finish a short or long rest.",
"You can also use the artifact as {@item alchemist's supplies|PHB}, {@item brewer's supplies|PHB}, {@item cook's utensils|PHB}, an {@item herbalism kit|PHB}, and a {@item poisoner's kit|PHB}. You have advantage on any check you make using the artifact as one of these tools."
]
},
{
"type": "entries",
"name": "Primal Parts",
"entries": [
"As an action while the pestle and the mortar is within 5 feet of you, you can command the pestle to grind. For the next minute, or until you use your action to verbally command it to stop, the pestle moves on its own, grinding the contents of the mortar into a mush or fine powder that's equally useful for cooking or alchemy. At the start of each of your turns, whatever is in the mortar takes {@damage 4d10} force damage. If this reduces the target's hit points to 0, the target is reduced to powder, pulp, or paste, as appropriate. Only magic items are unaffected. If you wish, when the pestle stops, you can have the mortar separate its contents-like powdered bone, crushed herbs, pulped organs-into separate piles."
]
},
{
"type": "entries",
"name": "Traverse the Night",
"entries": [
"If you are holding the pestle while you are inside the mortar, you can use your action to verbally command the mortar to travel to a specific place or creature.",
"You don't need to know where your destination is, but it must be a specific destination-not just the nearest river or a red dragon's lair. If the stated destination is within 1,000 miles of you, the mortar lifts into the air and vanishes. You and any creatures in the mortar travel through a dreamlike sky, with hazy reflections of the world passing by below. Creatures might see images of you streaking through the sky between your point of origin and the destination. You arrive at the location 1 hour later or, if it is night, 1 minute later."
]
},
{
"type": "entries",
"name": "Bones Know Their Home",
"entries": [
"When you command the mortar to travel, you can instead throw out the dust or paste of something ground by the mortar and name a location on a different plane of existence or a different world on the Material Plane. If that material came from a creature native to the named plane or world, the mortar travels through an empty night sky to an unoccupied space at that destination, arriving in 1 minute."
]
},
{
"type": "entries",
"name": "Destroying the Mortar and Pestle",
"entries": [
"The mortar and pestle are destroyed if they are crushed underfoot by the {@i Dancing Hut of Baba Yaga} or by Baba Yaga herself."
]
}
],
"hasFluffImages": true
},
{
"name": "Baba Yaga's Pestle",
"source": "TCE",
"page": 121,
"baseItem": "quarterstaff|PHB",
"type": "M",
"rarity": "artifact",
"reqAttune": true,
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"bonusWeapon": "+3",
"recharge": "dawn",
"rechargeAmount": 12,
"charges": 12,
"weapon": true,
"entries": [
"The pestle is a 6-inch-long, worn wooden tool. Once during your turn while you are holding the pestle, you can extend it into a quarterstaff or shrink it back into a pestle (no action required). As a quarterstaff, the pestle is a magic weapon that grants a +3 bonus to attack and damage rolls made with it.",
"The pestle has 12 charges. When you hit with a melee attack using the pestle, you can expend up to 3 of its charges to deal an extra {@damage 1d8} force damage for each charge expended. The pestle regains all expended charges daily at dawn."
]
},
{
"name": "Backpack",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 5,
"value": 200,
"entries": [
"A backpack can hold one cubic foot or 30 pounds of gear. You can also strap items, such as a bedroll or a coil of rope, to the outside of a backpack."
],
"containerCapacity": {
"weight": [
30
]
}
},
{
"name": "Backpack Parachute",
"source": "WDH",
"page": 47,
"type": "OTH",
"rarity": "unknown",
"entries": [
"A humanoid wearing this piece of gear can deploy the parachute as a reaction while falling, or as an action otherwise. The parachute requires at least a 10-foot cube of unoccupied space in which to deploy, and it doesn't open fast enough to slow a fall of less than 60 feet. If it has sufficient time and space to deploy properly, the parachute allows its wearer to land without taking falling damage. Once it has been used, the parachute takes 10 minutes to repack."
]
},
{
"name": "Badge of the Watch",
"source": "WDH",
"page": 189,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"bonusAc": "+2",
"entries": [
"A badge of the Watch is given only to those who have earned the trust of the Open Lord of Waterdeep. The badge, signifying the rank of captain in Waterdeep's City Watch, bears the emblem of Waterdeep and is meant to be worn or carried.",
"While wearing the badge, you gain a +2 bonus to AC if you aren't using a {@item shield|phb}.",
"If the badge is more than 5 feet away from you for more than 1 minute, it vanishes and harmlessly reappears on a surface within 5 feet of the Open Lord. While holding the badge, the Open Lord knows your location, provided the two of you are on the same plane of existence and your attunement to the badge hasn't ended.",
"As an action, the Open Lord can touch the badge and end your attunement to it."
],
"hasFluffImages": true
},
{
"name": "Bag of Beans",
"source": "DMG",
"page": 152,
"srd": true,
"tier": "minor",
"rarity": "rare",
"wondrous": true,
"weight": 0.5,
"entries": [
"Inside this heavy cloth bag are {@dice 3d4} dry beans. The bag weighs ½ pound plus ¼ pound for each bean it contains.",
"If you dump the bag's contents out on the ground, they explode in a 10-foot radius, extending from the beans. Each creature in the area, including you, must make a DC 15 Dexterity saving throw, taking {@damage 5d4} fire damage on a failed save, or half as much damage on a successful one. The fire ignites flammable objects in the area that aren't being worn or carried.",
"If you remove a bean from the bag, plant it in dirt or sand, and then water it, the bean produces an effect 1 minute later from the ground where it was planted. The DM can choose an effect from the following table, determine it randomly, or create an effect.",
{
"type": "table",
"colLabels": [
"{@dice d100}",
"Effect"
],
"colStyles": [
"col-1 text-center",
"col-11"
],
"rows": [
[
"01",
"{@dice 5d4} toadstools sprout. If a creature eats a toadstool, roll any die. On an odd roll, the eater must succeed on a DC 15 Constitution saving throw or take {@damage 5d6} poison damage and become {@condition poisoned} for 1 hour. On an even roll, the eater gains {@dice 5d6} temporary hit points for 1 hour."
],
[
"02-10",
"A geyser erupts and spouts water, beer, berry juice, tea, vinegar, wine, or oil (DM's choice) 30 feet into the air for {@dice 1d12} rounds."
],
[
"11-20",
"A {@creature treant} sprouts. There's a {@chance 50} chance that the treant is chaotic evil and attacks."
],
[
"21-30",
"An animate, immobile stone statue in your likeness rises. It makes verbal threats against you. If you leave it and others come near, it describes you as the most heinous of villains and directs the newcomers to find and attack you. If you are on the same plane of existence as the statue, it knows where you are. The statue becomes inanimate after 24 hours."
],
[
"31-40",
"A campfire with blue flames springs forth and burns for 24 hours (or until it is extinguished)."
],
[
"41-50",
"{@dice 1d6 + 6} {@creature shrieker||shriekers} sprout."
],
[
"51-60",
"{@dice 1d4 + 8} bright pink toads crawl forth. Whenever a toad is touched, it transforms into a Large or smaller monster of the DM's choice. The monster remains for 1 minute, then disappears in a puff of bright pink smoke."
],
[
"61-70",
"A hungry {@creature bulette} burrows up and attacks."
],
[
"71-80",
"A fruit tree grows. It has {@dice 1d10 + 20} fruit, {@dice 1d8} of which act as randomly determined magic potions, while one acts as an ingested poison of the DM's choice. The tree vanishes after 1 hour. Picked fruit remains, retaining any magic for 30 days."
],
[
"81-90",
"A nest of {@dice 1d4 + 3} eggs springs up. Any creature that eats an egg must make a DC 20 Constitution saving throw. On a successful save, a creature permanently increases its lowest ability score by 1, randomly choosing among equally low scores. On a failed save, the creature takes {@damage 10d6} force damage from an internal magical explosion."
],
[
"91-99",
"A pyramid with a 60-foot-square base bursts upward. Inside is a sarcophagus containing a {@creature mummy lord}. The pyramid is treated as the {@creature mummy lord||mummy lord's} lair, and its sarcophagus contains treasure of the DM's choice."
],
[
"00",
"A giant beanstalk sprouts, growing to a height of the DM's choice. The top leads where the DM chooses, such as to a great view, a {@creature cloud giant||cloud giant's} castle, or a different plane of existence."
]
]
}
],
"lootTables": [
"Magic Item Table C"
],
"hasFluffImages": true
},
{
"name": "Bag of Bounty",
"source": "UAWGE",
"page": 116,
"rarity": "uncommon",
"wondrous": true,
"entries": [
"This is a sturdy leather sack with tiny Siberys shards embedded into the lining. If you have the Mark of Hospitality you can use an action to cast {@spell create food and water}, drawing a feast from within the bag. You shape this meal with your thoughts. You can create the standard bland fare without requiring any sort of check, but you can attempt to create finer food by making a Charisma check; if you're proficient with {@item cook's utensils|PHB}, add your bonus to this check. A failed check results in a sour and squalid meal.",
{
"type": "table",
"colLabels": [
"Food Quality",
"Difficulty"
],
"colStyles": [
"col-6",
"col-6"
],
"rows": [
[
"Poor",
"No roll required"
],
[
"Modest",
10
],
[
"Comfortable",
13
],
[
"Wealthy",
15
],
[
"Aristocratic",
18
]
]
},
"A bag of bounty can be used up to three times over the course of a day. After that, the bag can't be used again until the next dawn."
]
},
{
"name": "Bag of Devouring",
"source": "DMG",
"page": 153,
"srd": true,
"tier": "minor",
"rarity": "very rare",
"wondrous": true,
"weight": 15,
"entries": [
"This bag superficially resembles a {@item bag of holding} but is a feeding orifice for a gigantic extradimensional creature. Turning the bag inside out closes the orifice.",
"The extradimensional creature attached to the bag can sense whatever is placed inside the bag. Animal or vegetable matter placed wholly in the bag is devoured and lost forever. When part of a living creature is placed in the bag, as happens when someone reaches inside it, there is a {@chance 50} chance that the creature is pulled inside the bag. A creature inside the bag can use its action to try to escape with a successful DC 15 Strength check. Another creature can use its action to reach into the bag to pull a creature out, doing so with a successful DC 20 Strength check (provided it isn't pulled inside the bag first). Any creature that starts its turn inside the bag is devoured, its body destroyed.",
"Inanimate objects can be stored in the bag, which can hold a cubic foot of such material. However, once each day, the bag swallows any objects inside it and spits them out into another plane of existence. The DM determines the time and plane.",
"If the bag is pierced or torn, it is destroyed, and anything contained within it is transported to a random location on the Astral Plane."
],
"lootTables": [
"Magic Item Table D"
],
"hasFluffImages": true
},
{
"name": "Bag of Holding",
"source": "DMG",
"page": 153,
"srd": true,
"basicRules": true,
"tier": "minor",
"rarity": "uncommon",
"wondrous": true,
"weight": 15,
"entries": [
"This bag has an interior space considerably larger than its outside dimensions, roughly 2 feet in diameter at the mouth and 4 feet deep. The bag can hold up to 500 pounds, not exceeding a volume of 64 cubic feet. The bag weighs 15 pounds, regardless of its contents. Retrieving an item from the bag requires an action.",
"If the bag is overloaded, pierced, or torn, it ruptures and is destroyed, and its contents are scattered in the Astral Plane. If the bag is turned inside out, its contents spill forth, unharmed, but the bag must be put right before it can be used again. Breathing creatures inside the bag can survive up to a number of minutes equal to 10 divided by the number of creatures (minimum 1 minute), after which time they begin to suffocate.",
"Placing a bag of holding inside an extradimensional space created by a {@item Heward's handy haversack}, {@item portable hole}, or similar item instantly destroys both items and opens a gate to the Astral Plane. The gate originates where the one item was placed inside the other. Any creature within 10 feet of the gate is sucked through it to a random location on the Astral Plane. The gate then closes. The gate is one-way only and can't be reopened."
],
"containerCapacity": {
"weight": [
500
],
"weightless": true
},
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table A",
"Magic Item Table B"
],
"hasFluffImages": true
},
{
"name": "Bag of Tricks, Gray",
"source": "DMG",
"page": 154,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"wondrous": true,
"weight": 0.5,
"entries": [
"This ordinary bag, made from gray cloth, appears empty. Reaching inside the bag, however, reveals the presence of a small, fuzzy object.",
"You can use an action to pull the fuzzy object from the bag and throw it up to 20 feet. When the object lands, it transforms into a creature you determine by rolling a {@dice d8} and consulting the table. The creature vanishes at the next dawn or when it is reduced to 0 hit points.",
"The creature is friendly to you and your companions, and it acts on your turn. You can use a bonus action to command how the creature moves and what action it takes on its next turn, or to give it general orders, such as to attack your enemies. In the absence of such orders, the creature acts in a fashion appropriate to its nature.",
"Once three fuzzy objects have been pulled from the bag, the bag can't be used again until the next dawn.",
{
"type": "table",
"colLabels": [
"{@dice d8}",
"Creature"
],
"colStyles": [
"col-1 text-center",
"col-11"
],
"rows": [
[
"1",
"{@creature Weasel}"
],
[
"2",
"{@creature Giant rat}"
],
[
"3",
"{@creature Badger}"
],
[
"4",
"{@creature Boar}"
],
[
"5",
"{@creature Panther}"
],
[
"6",
"{@creature Giant badger}"
],
[
"7",
"{@creature Dire wolf}"
],
[
"8",
"{@creature Giant elk}"
]
]
}
],
"lootTables": [
"Magic Item Table F"
]
},
{
"name": "Bag of Tricks, Rust",
"source": "DMG",
"page": 154,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"wondrous": true,
"weight": 0.5,
"entries": [
"This ordinary bag, made from rust cloth, appears empty. Reaching inside the bag, however, reveals the presence of a small, fuzzy object.",
"You can use an action to pull the fuzzy object from the bag and throw it up to 20 feet. When the object lands, it transforms into a creature you determine by rolling a {@dice d8} and consulting the table. The creature vanishes at the next dawn or when it is reduced to 0 hit points.",
"The creature is friendly to you and your companions, and it acts on your turn. You can use a bonus action to command how the creature moves and what action it takes on its next turn, or to give it general orders, such as to attack your enemies. In the absence of such orders, the creature acts in a fashion appropriate to its nature.",
"Once three fuzzy objects have been pulled from the bag, the bag can't be used again until the next dawn.",
{
"type": "table",
"colLabels": [
"{@dice d8}",
"Creature"
],
"colStyles": [
"col-1 text-center",
"col-11"
],
"rows": [
[
"1",
"{@creature Rat}"
],
[
"2",
"{@creature Owl}"
],
[
"3",
"{@creature Mastiff}"
],
[
"4",
"{@creature Goat}"
],
[
"5",
"{@creature Giant goat}"
],
[
"6",
"{@creature Giant boar}"
],
[
"7",
"{@creature Lion}"
],
[
"8",
"{@creature Brown bear}"
]
]
}
],
"lootTables": [
"Magic Item Table F"
]
},
{
"name": "Bag of Tricks, Tan",
"source": "DMG",
"page": 154,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"wondrous": true,
"weight": 0.5,
"entries": [
"This ordinary bag, made from tan cloth, appears empty. Reaching inside the bag, however, reveals the presence of a small, fuzzy object.",
"You can use an action to pull the fuzzy object from the bag and throw it up to 20 feet. When the object lands, it transforms into a creature you determine by rolling a {@dice d8} and consulting the table. The creature vanishes at the next dawn or when it is reduced to 0 hit points.",
"The creature is friendly to you and your companions, and it acts on your turn. You can use a bonus action to command how the creature moves and what action it takes on its next turn, or to give it general orders, such as to attack your enemies. In the absence of such orders, the creature acts in a fashion appropriate to its nature.",
"Once three fuzzy objects have been pulled from the bag, the bag can't be used again until the next dawn.",
{
"type": "table",
"colLabels": [
"{@dice d8}",
"Creature"
],
"colStyles": [
"col-1 text-center",
"col-11"
],
"rows": [
[
"1",
"{@creature Jackal}"
],
[
"2",
"{@creature Ape}"
],
[
"3",
"{@creature Baboon}"
],
[
"4",
"{@creature Axe beak}"
],
[
"5",
"{@creature Black bear}"
],
[
"6",
"{@creature Giant weasel}"
],
[
"7",
"{@creature Giant hyena}"
],
[
"8",
"{@creature Tiger}"
]
]
}
],
"lootTables": [
"Magic Item Table F"
]
},
{
"name": "Bagpipes",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"type": "INS",
"rarity": "none",
"weight": 6,
"value": 3000
},
{
"name": "Balance of Harmony",
"source": "TftYP",
"page": 228,
"rarity": "uncommon",
"wondrous": true,
"weight": 1,
"entries": [
"This scale bears celestial symbols on one pan and fiendish symbols on the other. You can use the scale to cast {@spell detect evil and good} as a ritual. Doing so requires you to place the scale on a solid surface, then sprinkle the pans with holy water or place a transparent gem worth 100 gp in each pan. The scale remains motionless if it detects nothing, tips to one side or the other for good (consecrated) or evil (desecrated), and fluctuates slightly if it detects a creature appropriate to the spell but neither good nor evil. By touching the scales after casting the ritual, you instantly learn any information the spell can normally convey, and then the effect ends."
],
"attachedSpells": [
"detect evil and good"
]
},
{
"name": "Ball Bearing",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 0.002,
"value": 0.1,
"entries": [
"Most commonly found inside a {@item Ball Bearings (Bag of 1,000)|phb|bag of ball bearings}."
]
},
{
"name": "Ball Bearings (bag of 1,000)",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 2,
"value": 100,
"entries": [
"As an action, you can spill these tiny metal balls from their pouch to cover a level area 10 feet square. A creature moving across the covered area must succeed on a DC 10 Dexterity saving throw or fall {@condition prone}. A creature moving through the area at half speed doesn't need to make the saving throw."
],
"packContents": [
{
"item": "ball bearing|phb",
"quantity": 1000
}
],
"atomicPackContents": true
},
{
"name": "Balloon Pack",
"source": "PotA",
"page": 222,
"rarity": "uncommon",
"wondrous": true,
"entries": [
"This backpack contains the spirit of an {@creature air elemental} and a compact leather balloon. While you're wearing the backpack, you can deploy the balloon as an action and gain the effect of the {@spell levitate} spell for 10 minutes, targeting yourself and requiring no concentration. Alternatively, you can use a reaction to deploy the balloon when you're falling and gain the effect of the {@spell feather fall} spell for yourself.",
"When either spell ends, the balloon slowly deflates as the elemental spirit escapes and returns to the Elemental Plane of Air. As the balloon deflates, you descend gently toward the ground for up to 60 feet. If you are still in the air at the end of this distance, you fall if you have no other means of staying aloft.",
"After the spirit departs, the backpack's property is unusable unless the backpack is recharged for 1 hour in an elemental air node, which binds another spirit to the backpack."
],
"attachedSpells": [
"levitate",
"feather fall"
]
},
{
"name": "Band of Loyalty",
"source": "UAWGE",
"page": 115,
"type": "RG",
"rarity": "common",
"reqAttune": true,
"entries": [
"If you are reduced to zero hit points while attuned to a band of loyalty, you instantly die. These rings are favored by spies who can't afford to fall into enemy hands."
]
},
{
"name": "Banded Agate",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 1000,
"entries": [
"A translucent striped brown, blue, white, or red gemstone."
]
},
{
"name": "Banner of the Krig Rune",
"source": "SKT",
"page": 233,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"bonusWeaponAttack": "+1",
"bonusAc": "+1",
"entries": [
"Crafted from a thick, red fabric, this banner measures 5 feet high and 3 feet wide. The krig (war) rune is displayed on the fabric with round, metal plates sewn into it. It can be attached to a 10-foot pole to serve as a standard. Furling or unfurling the banner requires an action. The banner has the following properties.",
{
"name": "Mark of Courage",
"type": "entries",
"entries": [
"As a bonus action, you can touch the unfurled banner and cause it to emanate courage. You and your allies are immune to the {@condition frightened} condition while within 20 feet of it. This benefit lasts for 10 minutes or until the banner is furled. Once you use this property, you can't use it again until you finish a short or long rest."
]
},
{
"name": "Sentinel Standard",
"type": "entries",
"entries": [
"You can see {@condition invisible} creatures while they are within 20 feet of the unfurled banner and within your line of sight."
]
},
{
"type": "entries",
"name": "Standard's Shield",
"entries": [
"As a bonus action, you can touch the unfurled banner and invoke this power. Any ranged attack roll that targets you or an ally of yours has disadvantage if the target is within 20 feet of the unfurled banner. This benefit lasts for 1 minute or until the banner is furled. Once you use this property, you can't use it again until you finish a short or long rest."
]
},
{
"name": "Gift of Battle",
"type": "entries",
"entries": [
"You can transfer the banner's magic to a place by tracing the krig rune on the ground with your finger. The point where you trace it becomes the center of a spherical area of magic that has a 500-foot radius and that is fixed to the place. The transfer takes 8 hours of work that requires the banner to be within 5 feet of you and during which you choose creatures, creature types, or both that will benefit from the magic. At the end, the banner is destroyed, and the area gains the following property:",
"While in the 500-foot-radius sphere, the creatures you chose during the transfer process are immune to the {@condition frightened} condition and gain a +1 bonus to attack rolls and AC."
]
}
]
},
{
"name": "Barking Box",
"source": "WDH",
"page": 47,
"type": "OTH",
"rarity": "unknown",
"entries": [
"This metal cube, 6 inches on a side, has a crank on top. Using an action to wind the crank activates the box for 8 hours. While activated, the box barks whenever it detects vibrations within 15 feet of it, as long as the box and the source of the vibrations are in contact with the same ground or substance. A switch on one side of the box sets the device to emit either a small dog's bark or a large dog's bark."
]
},
{
"name": "Barrel",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 70,
"value": 200,
"entries": [
"A barrel can hold 40 gallons of liquid or 4 cubic feet of solids."
]
},
{
"name": "Barrier Peaks Trinket",
"source": "LLK",
"page": 7,
"type": "G",
"rarity": "none",
"entries": [
"At any point while the characters are searching through unusual equipment or detritus in the Monastery of the Distressed Body or the city of Daoine Gloine, roll on the following table to determine what they find. Each character finds one Barrier Peaks trinket over the course of the adventure.",
{
"type": "table",
"caption": "Barrier Peaks Trinkets",
"colLabels": [
"d100",
"Trinket"
],
"colStyles": [
"text-center col-2",
"col-10"
],
"rows": [
[
"01–02",
"A handheld device containing a glowing green gem that darkens when no oxygen is present"
],
[
"03–04",
"A foot-long, egg-shaped object made from stitched leather"
],
[
"05–06",
"A black metal cylinder that dictates the history of an unknown plant or animal species when held"
],
[
"07–08",
"A cylindrical jar containing a pickled crustacean of unknown origin"
],
[
"09–10",
"A small thumb-button storage cylinder that releases a useless iron key when pressed"
],
[
"11–12",
"An unusual heraldic cloak pin that emits a short musical fanfare when tapped"
],
[
"13–14",
"A handheld tube that sucks in dust when squeezed and captures it in a detachable compartment"
],
[
"15–16",
"A scintillating disk of unknown material"
],
[
"17–18",
"A dial that can be twisted to slowly click back to its origin, whereupon it emits a loud ringing noise"
],
[
"19–20",
"A hovering, apple-sized orb of metal that follows you around"
],
[
"21–22",
"The {@condition petrified} cocoon of an unknown insect"
],
[
"23–24",
"A bronze gauntlet set with many slots, and which violently expels any object pressed into those slots"
],
[
"25–26",
"A box that plays an illusory message in an unknown language when opened"
],
[
"27–28",
"A rod that causes you to forget the last five minutes when you press a button near its tip"
],
[
"29–30",
"A palm-sized cylinder that emits a harmless ray of glowing blue light when squeezed"
],
[
"31–32",
"A bead that suppresses your hearing when secreted inside either ear, causing you to be {@condition deafened}"
],
[
"33–34",
"An amulet that displays your current health as a green bar above your head, with the bar retracting as your hit point total decreases"
],
[
"35–36",
"A casket containing one hundred tasteless blue pills that produce no discernible effect when swallowed"
],
[
"37–38",
"A metal mechanical puzzle with no apparent solution"
],
[
"39–40",
"A metal spinning top that never tips over when spun"
],
[
"41–42",
"Two strips of cloth-like material, each coated with a soft, hair-like fuzz on one side"
],
[
"43–44",
"A simple wire pyramid that preserves any foodstuffs it is placed over"
],
[
"45–46",
"A star chart labeled in an unknown script"
],
[
"47–48",
"A rectangle of black glass that displays indecipherable arcane runes when you swipe your finger across it"
],
[
"49–50",
"A schematic that shows the inner workings of an impossibly complex device"
],
[
"51–52",
"An odd pair of comfortable shoes made from supple, multicolored material"
],
[
"53–54",
"A mirror that makes you appear more beautiful when you tap your reflection"
],
[
"55–56",
"A mechanical metal puppy that playfully follows you around when activated"
],
[
"57–58",
"A talking bracelet that speaks only to correct your grammar"
],
[
"59–60",
"A bar of soap that can remove any stain"
],
[
"61–62",
"A journal in Common, written by someone in a world similar to but not quite the same as your own"
],
[
"63–64",
"A tub containing one serving of disgusting but nutritious goop that refills itself slowly over the course of one week"
],
[
"65–66",
"An instruction manual for activating a mysterious, world-destroying device"
],
[
"67–68",
"A small supple disk that displays weird moving symbols when placed over either eye"
],
[
"69–70",
"A tiny desk set with large, colorful buttons, each of which plays a discordant musical fanfare when pressed"
],
[
"71–72",
"A pair of tinted spectacles that reduce the glare of the sun when worn"
],
[
"73–74",
"An inflatable bedroll made from an unknown material, and which slowly deflates when used"
],
[
"75–76",
"A rod tipped with a blunt metal pincer whose grip can be adjusted by turning a screw"
],
[
"77–78",
"A battered helmet with a transparent orange visor that flips into place when donned"
],
[
"79–80",
"An animated map of a mysterious city that appears to be tracking the movements of five creatures"
],
[
"81–82",
"A cylinder of mist that holds your hair perfectly in shape when sprayed onto your head"
],
[
"83–84",
"A talking wand that tells you the name of any plant you point it at"
],
[
"85–86",
"A metal bracelet that displays the number of steps you've taken since your last long rest"
],
[
"87–88",
"A tiny handheld device that projects a glowing dot onto whatever you point it at"
],
[
"89–90",
"A rectangular piece of glass that displays a twelve-digit countdown on its surface"
],
[
"91–92",
"A wall chart of mysterious formulae arranged into a color-coded grid"
],
[
"93–94",
"A handheld device that solves any math problem you input using its buttons"
],
[
"95–96",
"A ball of speckled brown fur that appears to be alive"
],
[
"97–98",
"A complicated crystal board game that you don't know how to play"
],
[
"99–00",
"A large glass rectangle that displays a storm of black and white patterns when you press a button on its underside"
]
]
}
]
},
{
"name": "Barrier Tattoo (Large)",
"source": "TCE",
"page": 122,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"entries": [
"Produced by a special needle, this magic tattoo depicts protective imagery and uses ink that resembles liquid metal.",
{
"type": "entries",
"name": "Tattoo Attunement",
"entries": [
"To attune to this item, you hold the needle to your skin where you want the tattoo to appear, pressing the needle there throughout the attunement process. When the attunement is complete, the needle turns into the ink that becomes the tattoo, which appears on the skin.",
"If your attunement to the tattoo ends, the tattoo vanishes, and the needle reappears in your space."
]
},
{
"type": "entries",
"name": "Protection",
"entries": [
"While you aren't wearing armor, the tattoo grants you an Armor Class of 18. You can use a shield and still gain this benefit."
]
}
]
},
{
"name": "Barrier Tattoo (Medium)",
"source": "TCE",
"page": 122,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"entries": [
"Produced by a special needle, this magic tattoo depicts protective imagery and uses ink that resembles liquid metal.",
{
"type": "entries",
"name": "Tattoo Attunement",
"entries": [
"To attune to this item, you hold the needle to your skin where you want the tattoo to appear, pressing the needle there throughout the attunement process. When the attunement is complete, the needle turns into the ink that becomes the tattoo, which appears on the skin.",
"If your attunement to the tattoo ends, the tattoo vanishes, and the needle reappears in your space."
]
},
{
"type": "entries",
"name": "Protection",
"entries": [
"While you aren't wearing armor, the tattoo grants you an Armor Class of 15 + your Dexterity modifier (maximum of +2). You can use a shield and still gain this benefit."
]
}
]
},
{
"name": "Barrier Tattoo (Rare)",
"source": "UA2020SpellsAndMagicTattoos",
"page": 9,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"entries": [
"This tattoo depicts protective imagery and uses ink that resembles liquid metal. While you aren't wearing armor, the tattoo grants you an Armor Class of 15 + your Dexterity modifier (maximum of +2). You can use a shield and still gain this benefit.",
{
"type": "entries",
"name": "Tattoo Attunement",
"entries": [
"To attune to this item, you hold the needle to your skin where you want the tattoo to appear, pressing the needle there throughout the attunement process. When the attunement is complete, the needle turns into the ink that becomes the tattoo, which appears on the skin. If you have multiple magic tattoos, they count as a single magic item with regard to the number of magic items you can attune to.",
"If your attunement to the tattoo ends, the tattoo vanishes, and the needle reappears in the closest unoccupied space to you."
]
}
]
},
{
"name": "Barrier Tattoo (Small)",
"source": "TCE",
"page": 122,
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"entries": [
"Produced by a special needle, this magic tattoo depicts protective imagery and uses ink that resembles liquid metal.",
{
"type": "entries",
"name": "Tattoo Attunement",
"entries": [
"To attune to this item, you hold the needle to your skin where you want the tattoo to appear, pressing the needle there throughout the attunement process. When the attunement is complete, the needle turns into the ink that becomes the tattoo, which appears on the skin.",
"If your attunement to the tattoo ends, the tattoo vanishes, and the needle reappears in your space."
]
},
{
"type": "entries",
"name": "Protection",
"entries": [
"While you aren't wearing armor, the tattoo grants you an Armor Class of 12 + your dexterity bonus. You can use a shield and still gain this benefit."
]
}
]
},
{
"name": "Barrier Tattoo (Uncommon)",
"source": "UA2020SpellsAndMagicTattoos",
"page": 9,
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"entries": [
"This tattoo depicts protective imagery and uses ink that resembles liquid metal. While you aren't wearing armor, the tattoo grants you an Armor Class of 12 + your Dexterity modifier. You can use a shield and still gain this benefit.",
{
"type": "entries",
"name": "Tattoo Attunement",
"entries": [
"To attune to this item, you hold the needle to your skin where you want the tattoo to appear, pressing the needle there throughout the attunement process. When the attunement is complete, the needle turns into the ink that becomes the tattoo, which appears on the skin. If you have multiple magic tattoos, they count as a single magic item with regard to the number of magic items you can attune to.",
"If your attunement to the tattoo ends, the tattoo vanishes, and the needle reappears in the closest unoccupied space to you."
]
}
]
},
{
"name": "Barrier Tattoo (Very Rare)",
"source": "UA2020SpellsAndMagicTattoos",
"page": 9,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"entries": [
"This tattoo depicts protective imagery and uses ink that resembles liquid metal. While you aren't wearing armor, the tattoo grants you an Armor Class of 18. You can use a shield and still gain this benefit.",
{
"type": "entries",
"name": "Tattoo Attunement",
"entries": [
"To attune to this item, you hold the needle to your skin where you want the tattoo to appear, pressing the needle there throughout the attunement process. When the attunement is complete, the needle turns into the ink that becomes the tattoo, which appears on the skin. If you have multiple magic tattoos, they count as a single magic item with regard to the number of magic items you can attune to.",
"If your attunement to the tattoo ends, the tattoo vanishes, and the needle reappears in the closest unoccupied space to you."
]
}
]
},
{
"name": "Basic Fishing Equipment",
"source": "AAG",
"page": 0,
"type": "G",
"rarity": "none",
"value": 10,
"entries": [
"Basic Fishing Equipment includes a pole, a line, a hook, and either a lure or some bait.",
"Fishing is a popular pastime in Wildspace and the Astral Sea, though this activity isn't possible aboard a ship that is moving faster than its flying speed. At the end of each hour spent fishing, a character can make a DC 15 Wisdom ({@skill Survival}) check. A failed check indicates no fish is caught during that hour. On a successful check, roll a {@dice d10} and consult the Fishing table to determine the catch.",
{
"type": "table",
"caption": "",
"colLabels": [
"d10",
"Catch"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"1–2",
"Tiny, inedible fish (a creature that consumes it is poisoned for 1 hour)"
],
[
"3–5",
"Tiny, edible fish (feeds one person)"
],
[
"6–8",
"Small, edible fish (feeds up to four people)"
],
[
"9",
"Hostile {@creature space eel|BAM} (see {@book Boo's Astral Menagerie|BAM}; feeds up to twelve people)"
],
[
"10",
"Hostile {@creature gray scavver|BAM} (see {@book Boo's Astral Menagerie|BAM}; feeds up to twenty people), some other creature of the DM's choosing, or an Tiny object of the DM's choosing"
]
]
}
]
},
{
"name": "Basic Poison (vial)",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"value": 10000,
"poison": true,
"entries": [
"You can use the poison in this vial to coat one slashing or piercing weapon or up to three pieces of ammunition. Applying the poison takes an action. A creature hit by the poisoned weapon or ammunition must make a DC 10 Constitution saving throw or take {@damage 1d4} poison damage. Once applied, the poison retains potency for 1 minute before drying."
]
},
{
"name": "Basket",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 2,
"value": 40,
"entries": [
"A basket holds 2 cubic feet or 40 pounds of gear."
],
"containerCapacity": {
"weight": [
40
]
}
},
{
"name": "Battering Shield",
"source": "EGW",
"page": 266,
"baseItem": "shield|PHB",
"type": "S",
"rarity": "rare",
"reqAttune": true,
"weight": 6,
"ac": 2,
"bonusAc": "+1",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"While holding this iron tower shield, you gain a +1 bonus to AC. This bonus is in addition to the shield's normal bonus to AC.",
"Additionally, the shield has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. If you are holding the shield and push a creature within your reach at least 5 feet away, you can expend 1 charge to push that creature an additional 10 feet, knock it {@condition prone}, or both."
]
},
{
"name": "Battle Standard of Infernal Power",
"source": "BGDIA",
"page": 223,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"This hell-forged battle standard is made of infernal iron and fitted with a small, unopenable cage containing a {@creature quasit}. The trapped {@creature quasit} is {@condition incapacitated}, and its cage has AC 19, 10 hit points, and immunity to all types of damage except force damage. If killed or somehow released, the {@creature quasit} disappears in a cloud of smoke, and a new one appears in the cage, provided the cage is intact.",
"While you hold the banner, your weapon attacks and those of all allied creatures within 300 feet of you count as magical for the purposes of overcoming damage immunities and resistances."
],
"hasFluffImages": true
},
{
"name": "Bead of Force",
"source": "DMG",
"page": 154,
"srd": true,
"tier": "minor",
"rarity": "rare",
"wondrous": true,
"weight": 0.0625,
"entries": [
"This small black sphere measures ¾ of an inch in diameter and weighs an ounce. Typically, {@dice 1d4 + 4} {@italic beads of force} are found together.",
"You can use an action to throw the bead up to 60 feet. The bead explodes on impact and is destroyed. Each creature within a 10-foot radius of where the bead landed must succeed on a DC 15 Dexterity saving throw or take {@damage 5d4} force damage. A sphere of transparent force then encloses the area for 1 minute. Any creature that failed the save and is completely within the area is trapped inside this sphere. Creatures that succeeded on the save, or are partially within the area, are pushed away from the center of the sphere until they are no longer inside it. Only breathable air can pass through the sphere's wall. No attack or other effect can.",
"An enclosed creature can use its action to push against the sphere's wall, moving the sphere up to half the creature's walking speed. The sphere can be picked up, and its magic causes it to weigh only 1 pound, regardless of the weight of creatures inside."
],
"lootTables": [
"Magic Item Table C"
]
},
{
"name": "Bead of Nourishment",
"source": "XGE",
"page": 136,
"tier": "minor",
"rarity": "common",
"wondrous": true,
"entries": [
"This spongy, flavorless, gelatinous bead dissolves on your tongue and provides as much nourishment as 1 day of rations."
],
"miscTags": [
"CF/W"
]
},
{
"name": "Bead of Refreshment",
"source": "XGE",
"page": 136,
"tier": "minor",
"rarity": "common",
"wondrous": true,
"entries": [
"This spongy, flavorless, gelatinous bead dissolves in liquid, transforming up to a pint of the liquid into fresh, cold drinking water. The bead has no effect on magical liquids or harmful substances such as poison."
]
},
{
"name": "Bedroll",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 7,
"value": 100
},
{
"name": "Bejeweled ivory drinking horn with gold filigree",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 750000
},
{
"name": "Bejeweled ivory drinking horn with gold inlay (Brazen Coalition)",
"source": "PSX",
"page": 25,
"type": "$",
"rarity": "none",
"value": 750000
},
{
"name": "Belashyrra's Beholder Crown",
"source": "ERLW",
"page": 276,
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 3}",
"charges": 10,
"entries": [
"This symbiotic crown is carved from dark purple and mauve stone, with ten points like stalks set with gemstones resembling the eyestalks of a {@creature beholder}. To attune to this item, you must wear it on your head for the entire attunement period, during which the crown's hidden tendrils burrow into your scalp to bond with your skull.",
"While wearing the crown, you can see normally in darkness, both magical and nonmagical, to a distance of 120 feet.",
{
"type": "entries",
"name": "Spells",
"entries": [
"The crown has 10 charges. While wearing it, you can use an action to expend some of its charges to cast one of the following spells from it (spell save DC 16): {@spell charm person} (1 charge), {@spell disintegrate} (6 charges), {@spell fear} (3 charges), {@spell finger of death} (7 charges), {@spell flesh to stone} (6 charges), {@spell hold person} (2 charges), {@spell ray of enfeeblement} (2 charges), {@spell sleep} (1 charge), {@spell slow} (3 charges), {@spell telekinesis} (5 charges).",
"The crown regains {@dice 1d6 + 3} expended charges daily at dawn."
]
},
{
"type": "entries",
"name": "Symbiotic Nature",
"entries": [
"The crown can't be removed from you while you're attuned to it, and you can't voluntarily end your attunement to it. If you're targeted by a spell that ends a curse, your attunement to the crown ends, and it detaches from you.",
"The daelkyr {@creature Belashyrra|ERLW} made these crowns. While on the same plane of existence as the crown, Belashyrra can see through its eyestalks."
]
}
],
"attachedSpells": [
"charm person",
"disintegrate",
"fear",
"finger of death",
"flesh to stone",
"hold person",
"ray of enfeeblement",
"sleep",
"slow",
"telekinesis"
]
},
{
"name": "Bell",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"value": 100
},
{
"name": "Bell Branch",
"source": "TCE",
"page": 122,
"rarity": "rare",
"reqAttune": "by a druid or warlock",
"reqAttuneTags": [
{
"class": "druid"
},
{
"class": "warlock"
}
],
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"focus": [
"Druid",
"Warlock"
],
"entries": [
"This silver implement is shaped like a tree branch and is strung with small golden bells. The branch is a spellcasting focus for your spells while you hold it.",
"The branch has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. You can use the charges in the following ways while holding it:",
{
"type": "list",
"items": [
"As a bonus action, you can expend 1 charge to detect the presence of aberrations, celestials, constructs, elementals, fey, fiends, or undead within 60 feet of you. If such creatures are present and don't have total cover from you, the bells ring softly, their tone indicating the creature types present.",
"As an action, you can expend 1 charge to cast {@spell protection from evil and good}."
]
}
],
"attachedSpells": [
"protection from evil and good"
]
},
{
"name": "Belt of Cloud Giant Strength",
"source": "DMG",
"page": 155,
"srd": true,
"tier": "major",
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"ability": {
"static": {
"str": 27
}
},
"entries": [
"While wearing this belt, your Strength score changes to 27. The item has no effect on you if your Strength without the belt is equal to or greater than the belt's score."
],
"lootTables": [
"Magic Item Table I"
]
},
{
"name": "Belt of Dwarvenkind",
"source": "DMG",
"page": 155,
"srd": true,
"resist": [
"poison"
],
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"ability": {
"con": 2
},
"entries": [
"While wearing this belt, you gain the following benefits:",
{
"type": "list",
"items": [
"Your Constitution score increases by 2, to a maximum of 20.",
"You have advantage on Charisma ({@skill Persuasion}) checks made to interact with dwarves."
]
},
"In addition, while attuned to the belt, you have a {@chance 50} chance each day at dawn of growing a full beard if you're capable of growing one, or a visibly thicker beard if you already have one.",
"If you aren't a dwarf, you gain the following additional benefits while wearing the belt:",
{
"type": "list",
"items": [
"You have advantage on saving throws against poison, and you have resistance against poison damage.",
"You have {@sense darkvision} out to a range of 60 feet.",
"You can speak, read, and write Dwarvish."
]
}
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Belt of Fire Giant Strength",
"source": "DMG",
"page": 155,
"srd": true,
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"ability": {
"static": {
"str": 25
}
},
"entries": [
"While wearing this belt, your Strength score changes to 25. The item has no effect on you if your Strength without the belt is equal to or greater than the belt's score."
],
"lootTables": [
"Magic Item Table H"
]
},
{
"name": "Belt of Frost Giant Strength",
"source": "DMG",
"page": 155,
"srd": true,
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"ability": {
"static": {
"str": 23
}
},
"entries": [
"While wearing this belt, your Strength score changes to 23. The item has no effect on you if your Strength without the belt is equal to or greater than the belt's score."
],
"lootTables": [
"Magic Item Table H"
]
},
{
"name": "Belt of Hill Giant Strength",
"source": "DMG",
"page": 155,
"srd": true,
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"ability": {
"static": {
"str": 21
}
},
"entries": [
"While wearing this belt, your Strength score changes to 21. The item has no effect on you if your Strength without the belt is equal to or greater than the belt's score."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table G"
]
},
{
"name": "Belt of Stone Giant Strength",
"source": "DMG",
"page": 155,
"srd": true,
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"ability": {
"static": {
"str": 23
}
},
"entries": [
"While wearing this belt, your Strength score changes to 23. The item has no effect on you if your Strength without the belt is equal to or greater than the belt's score."
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Belt of Storm Giant Strength",
"source": "DMG",
"page": 155,
"srd": true,
"tier": "major",
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"ability": {
"static": {
"str": 29
}
},
"entries": [
"While wearing this belt, your Strength score changes to 29. The item has no effect on you if your Strength without the belt is equal to or greater than the belt's score."
],
"lootTables": [
"Magic Item Table I"
],
"hasFluffImages": true
},
{
"name": "Birdpipes",
"source": "SCAG",
"page": 124,
"type": "INS",
"rarity": "none",
"entries": [
"Pan pipes or satyr pipes, also known as the shalm, these are sacred to {@deity Lliira|Faerûnian|scag} and popular with wood elf and wild elf bards."
]
},
{
"name": "Bit and bridle",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TAH",
"rarity": "none",
"weight": 1,
"value": 200
},
{
"name": "Biza's Breath",
"source": "JttRC",
"page": 0,
"type": "G",
"rarity": "none",
"poison": true,
"entries": [
"A creature subjected to this poison must succeed on a DC 16 Constitution saving throw or become {@condition poisoned} for 1 minute. The {@condition poisoned} creature must use its action to make a melee attack against a randomly determined creature within its reach. If there is no other creature within its reach, the {@condition poisoned} creature does nothing on its turn. The creature can repeat the saving throw at the end of each of its turns, ending the effect on itself on a success."
],
"poisonTypes": [
"inhaled"
]
},
{
"name": "Black Chromatic Rose",
"source": "WBtW",
"page": 208,
"resist": [
"acid"
],
"detail1": "drips acid",
"rarity": "rare",
"wondrous": true,
"hasRefs": true,
"entries": [
"{#itemEntry Chromatic Rose|WBtW}"
]
},
{
"name": "Black Crystal Tablet",
"source": "WDMM",
"page": 284,
"rarity": "legendary",
"reqAttune": "by a creature that has proficiency in the {@skill Arcana} skill",
"reqAttuneTags": [
{
"skillProficiency": "arcana"
}
],
"wondrous": true,
"entries": [
"Any creature that attunes to the tablet must make a DC 20 Wisdom saving throw at the end of its next long rest. On a failed save, the creature becomes afflicted with a random form of long-term madness (see \"{@book Madness|dmg|8|Madness}\" in chapter 8 of the {@book Dungeon Master's Guide|dmg}).",
"As an action, a creature attuned to the Black Crystal Tablet can use it to cast {@spell eyebite} or {@spell gate} (the portal created by this spell links to the Far Realm only). After the tablet is used to cast a spell, it cannot be used again until the next dawn."
],
"attachedSpells": [
"eyebite",
"gate"
]
},
{
"name": "Black Dragon Mask",
"source": "HotDQ",
"page": 94,
"otherSources": [
{
"source": "ToD",
"page": 179
}
],
"resist": [
"acid"
],
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"grantsProficiency": true,
"entries": [
"This horned mask of glossy ebony has horns and a skull-like mien. The mask reshapes to fit a wearer attuned to it. While you are wearing the mask and attuned to it, you can access the following properties.",
{
"type": "entries",
"name": "Damage Absorption",
"entries": [
"You have resistance to acid damage. If you already have damage resistance to acid from another source, you gain immunity to acid damage. If you already have immunity to acid damage from another source, you regain hit points equal to half of any acid damage you are dealt."
]
},
{
"type": "entries",
"name": "Draconic Majesty",
"entries": [
"While you are wearing no armor, you can add your Charisma bonus to your Armor Class."
]
},
{
"type": "entries",
"name": "Dragon Breath",
"entries": [
"If you have a breath weapon that requires rest to recharge, it gains a recharge of 6."
]
},
{
"type": "entries",
"name": "Dragon Sight",
"entries": [
"You gain {@sense darkvision} with a radius of 60 feet, or an additional 60 feet of {@sense darkvision} if you already have that sense. Once per day, you can gain {@sense blindsight} out to a range of 30 feet for 5 minutes."
]
},
{
"type": "entries",
"name": "Dragon Tongue",
"entries": [
"You can speak and understand Draconic. You also have advantage on any Charisma check you make against Black dragons."
]
},
{
"type": "entries",
"name": "Legendary Resistance",
"entries": [
"(1/Day) If you fail a saving throw, you can choose to succeed instead."
]
},
{
"type": "entries",
"name": "Water Breathing",
"entries": [
"You can breathe underwater."
]
}
]
},
{
"name": "Black Dragon Scale Mail",
"source": "DMG",
"page": 165,
"srd": true,
"type": "MA",
"resist": [
"acid"
],
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"weight": 45,
"ac": 14,
"bonusAc": "+1",
"bonusSavingThrow": "+1",
"stealth": true,
"entries": [
"Dragon scale mail is made of the scales of one kind of dragon. Sometimes dragons collect their cast-off scales and gift them to humanoids. Other times, hunters carefully skin and preserve the hide of a dead dragon. In either case, dragon scale mail is highly valued. While wearing this armor, you gain a +1 bonus to AC, you have advantage on saving throws against the Frightful Presence and breath weapons of dragons, and you have resistance to acid damage.",
"Additionally, you can focus your senses as an action to magically discern the distance and direction to the closest black dragon within 30 miles of you. This special action can't be used again until the next dawn."
]
},
{
"name": "Black Ghost Orchid Seed",
"source": "JttRC",
"page": 0,
"type": "G",
"rarity": "none",
"entries": [
"Every few years, a ghost orchid colony grows a black pod as thick as a human fist that holds three soft, black seeds. A creature that consumes one of these black seeds is affected by the spell {@spell feign death}. If the creature is unaware of the pod's effects or does not wish to be affected, it can resist the effect by succeeding on a DC 16 Constitution saving throw. Otherwise, it is considered to be a willing recipient of the spell."
]
},
{
"name": "Black Opal",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 100000,
"entries": [
"A translucent dark green with black mottling and golden flecks gemstone."
]
},
{
"name": "Black Pearl",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 50000,
"entries": [
"An opaque pure black gemstone."
]
},
{
"name": "Black Sap",
"source": "EGW",
"page": 152,
"type": "G",
"rarity": "none",
"value": 30000,
"entries": [
"This tarry substance harvested from the dark boughs of the death's head willow is a powerful intoxicant. It can be smoked as a concentrate or injected directly into the bloodstream. A creature subjected to a dose of black sap cannot be {@condition charmed} or {@condition frightened} for {@dice 1d6} hours.",
"For each dose of black sap consumed, a creature must succeed on a DC 15 Constitution saving throw or become {@condition poisoned} for {@dice 2d4} hours—an effect that is cumulative with multiple doses."
]
},
{
"name": "Black Sapphire",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 500000,
"entries": [
"A translucent lustrous black with glowing highlights gemstone."
]
},
{
"name": "Black velvet mask stitched with silver thread",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 2500
},
{
"name": "Blackrazor",
"source": "DMG",
"page": 216,
"baseItem": "greatsword|phb",
"type": "M",
"rarity": "legendary",
"reqAttune": "by a creature of non-lawful alignment",
"reqAttuneTags": [
{
"alignment": [
"NX",
"C",
"G",
"NY",
"E"
]
}
],
"sentient": true,
"weight": 6,
"weaponCategory": "martial",
"property": [
"H",
"2H"
],
"dmg1": "2d6",
"dmgType": "S",
"bonusWeapon": "+3",
"entries": [
"Hidden in the dungeon of White Plume Mountain, Blackrazor shines like a piece of night sky filled with stars. Its black scabbard is decorated with pieces of cut obsidian.",
"You gain a +3 bonus to attack and damage rolls made with this magic weapon. It has the following additional properties.",
{
"name": "Devour Soul",
"type": "entries",
"entries": [
"Whenever you use it to reduce a creature to 0 hit points, the sword slays the creature and devours its soul, unless it is a construct or an undead. A creature whose soul has been devoured by Blackrazor can be restored to life only by a {@spell wish} spell.",
"When it devours a soul, Blackrazor grants you temporary hit points equal to the slain creature's hit point maximum. These hit points fade after 24 hours. As long as these temporary hit points last and you keep Blackrazor in hand, you have advantage on attack rolls, saving throws, and ability checks.",
"If you hit an undead with this weapon, you take {@damage 1d10} necrotic damage and the target regains {@dice 1d10} hit points. If this necrotic damage reduces you to 0 hit points, Blackrazor devours your soul."
]
},
{
"name": "Soul Hunter",
"type": "entries",
"entries": [
"While you hold the weapon. you are aware of the presence of Tiny or larger creatures within 60 feet of you that aren't constructs or undead. You also can't be {@condition charmed} or {@condition frightened}.",
"Blackrazor can cast the {@spell haste} spell on you once per day. It decides when to cast the spell and maintains concentration on it so that you don't have to."
]
},
{
"name": "Sentience",
"type": "entries",
"entries": [
"Blackrazor is a sentient chaotic neutral weapon with an Intelligence of 17, a Wisdom of 10, and a Charisma of 19. It has hearing and {@sense darkvision} out to a range of 120 feet.",
"The weapon can speak, read, and understand Common, and can communicate with its wielder telepathically. Its voice is deep and echoing. While you are attuned to it, Blackrazor also understands every language you know."
]
},
{
"name": "Personality",
"type": "entries",
"entries": [
"Blackrazor speaks with an imperious tone, as though accustomed to being obeyed.",
"The sword's purpose is to consume souls. It doesn't care whose souls it eats, including the wielder's. The sword believes that all matter and energy sprang from a void of negative energy and will one day return to it. Blackrazor is meant to hurry that process along.",
"Despite its nihilism, Blackrazor feels a strange kinship to {@item Wave} and {@item Whelm}, two other weapons locked away under White Plume Mountain. It wants the three weapons to be united again and wielded together in combat, even though it violently disagrees with {@item Whelm} and finds {@item Wave} tedious.",
"Blackrazor's hunger for souls must be regularly fed. If the sword goes three days or more without consuming a soul, a conflict between it and its wielder occurs at the next sunset."
]
}
],
"attachedSpells": [
"haste"
]
},
{
"name": "Blackstaff",
"source": "WDH",
"page": 190,
"rarity": "legendary",
"reqAttune": "by the blackstaff heir, who must be a wizard",
"reqAttuneTags": [
{
"class": "wizard"
}
],
"sentient": true,
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"bonusWeapon": "+2",
"bonusSpellAttack": "+2",
"bonusAc": "+2",
"bonusSavingThrow": "+2",
"recharge": "dawn",
"rechargeAmount": "{@dice 2d8 + 4}",
"charges": 20,
"staff": true,
"entries": [
"The Blackstaff is a sentient, rune-carved staff set with thin silver veins. It is the symbol of office for the Blackstaff, the highest-ranking wizard in Waterdeep. As the rightful owner of the Blackstaff, Vajra Safahr is the only one who can become attuned to it. The staff can, however, choose a new owner (see \"Personality\" below).",
"This staff can be wielded as a magic quarterstaff that grants a +2 bonus to attack and damage rolls made with it. While holding it, you gain a +2 bonus to Armor Class, saving throws, and spell attack rolls.",
"The staff has 20 charges for the following properties. The staff regains {@dice 2d8 + 4} expended charges daily at dawn. If you expend the last charge, roll a {@dice d20}. On a 1, the staff retains its +2 bonus to attack and damage roll but loses all other properties. On a 20, the staff regain {@dice 1d8 + 2} charges.",
{
"name": "Power Strike",
"type": "entries",
"entries": [
"When you hit with a melee attack using the staff, you can expend 1 charge to deal an extra {@damage 1d6} force damage to the target."
]
},
{
"type": "entries",
"name": "Spells",
"entries": [
"While holding this staff, you can use an action to expend 1 or more of its charges to cast one of the following spells from it, using your spell save DC and spell attack bonus: {@spell cone of cold} (5 charges), {@spell fireball} (5th-level version, 5 charges), {@spell globe of invulnerability} (6 charges), {@spell hold monster} (5 charges), {@spell levitate} (2 charges). {@spell lightning bolt} (5th-level version, 5 charges), {@spell magic missile} (1 charge), {@spell ray of enfeeblement} (1 charge), or {@spell wall of force} (5 charges)."
]
},
{
"name": "Retributive Strike",
"type": "entries",
"entries": [
"You can use an action to break the staff over your knee or against a solid surface, performing a retributive strike. The staff is destroyed and releases its remaining magic in an explosion that expands to fill a 30-foot-radius sphere centered on it.",
"You have a {@chance 50} chance to instantly travel to a random plane of existence, avoiding the explosion. If you fail to avoid the effect, you take force damage equal to 16 × the number of charges in the staff. Every other creature in the area must make a DC 17 Dexterity saving throw. On a failed save, a creature takes an amount of damage based on how far away it is from the point of origin, as shown in the following table. On a successful save, a creature takes half as much damage.",
{
"type": "table",
"colLabels": [
"Distance from Origin",
"Effect"
],
"colStyles": [
"col-3",
"col-9"
],
"rows": [
[
"10 ft. away or closer",
"8 × the number of charges in the staff"
],
[
"11 to 20 ft. away",
"6 × the number of charges in the staff"
],
[
"21 to 30 ft. away",
"4 × the number of charges in the staff"
]
]
}
]
},
{
"type": "entries",
"name": "Animate Walking Statues",
"entries": [
"You can expend 1 or more of the staff's charges as an action to animate or deactivate one or more of the {@creature walking statue of Waterdeep|wdh|walking statues of Waterdeep}. You must be in the city to use this property, and you can animate or deactivate one statue for each charge expended. An animated statue obeys the telepathic commands of Khelben Arunsun's spirit, which is trapped inside the staff (see \"Personality\" below). A walking statue becomes inanimate if deactivated or if the staff is broken."
]
},
{
"type": "entries",
"name": "Dispel Magic",
"entries": [
"You can expend 1 of the staff's charges as a bonus action to cast {@spell dispel magic} on a creature, an object, or a magical effect that you touch with the tip of the staff. If the target is an unwilling creature or an object in the possession of such a creature, you must hit the creature with a melee attack using the Blackstaff before you can expend the charge to cast the spell."
]
},
{
"type": "entries",
"name": "Drain Magic",
"entries": [
"This property affects only creatures that use spell slots. When you hit such a creature with a melee attack using the Blackstaff, you can expend 1 of the staff's charges as a bonus action, causing the target to expend one spell slot of the highest spell level it can cast without casting a spell. If the target has already expended all its spell slots, nothing happens. Spell slots that are expended in this fashion are regained when the target finishes a long rest, as normal."
]
},
{
"type": "entries",
"name": "Master of Enchantment",
"entries": [
"When you cast an enchantment spell of 1st level or higher while holding the staff, you can make an Intelligence ({@skill Arcana}) check with a DC of 10 + the level of the spell. If the check succeeds, you cast the spell without expending a spell slot."
]
},
{
"type": "entries",
"name": "Sentience",
"entries": [
"The Blackstaff is a sentient staff of neutral alignment, with an Intelligence of 22, a Wisdom of 15, and a Charisma of 18. It has hearing and {@sense darkvision} out to a range of 120 feet, and it can communicate telepathically with any creature that is holding it."
]
},
{
"type": "entries",
"name": "Personality",
"entries": [
"The staff has the spirits of all previous Blackstaffs trapped within it. Its creator, Khelben Arunsun, is the dominant personality among them. Like Khelben, the staff is extremely devious and manipulative. It prefers to counsel its owner without exerting outright control. The staff's primary goal is to protect Waterdeep and its Open Lord, currently Laeral Silverhand. Its secondary goal is to help its wielder become more powerful.",
"In the event that the holder of the office of the Blackstaff no longer serves the staff's wishes, the staff ceases to function until it finds a worthy inheritor—someone whose loyalty to Waterdeep is beyond reproach."
]
},
{
"type": "entries",
"name": "Spirit Trap",
"entries": [
"When the Blackstaff dies, the spirit of that individual becomes trapped in the staff along with the spirits of the previous Blackstaffs. (A Blackstaff whose spirit is trapped in the staff can't be raised from the dead.)",
"Destroying the staff would release the spirits trapped inside it, but in that event, Khelben's spirit can lodge itself inside any one piece of the staff that remains. The piece containing Khelben's spirit has the staff's Sentience property but none of its other properties. As long as this piece of the staff exists, Khelben's spirit can make the staff whole again whenever he wishes. When the staff is remade, the spirits of the previous Blackstaffs become trapped inside it again."
]
}
],
"attachedSpells": [
"cone of cold",
"fireball",
"globe of invulnerability",
"hold monster",
"levitate",
"lightning bolt",
"magic missile",
"ray of enfeeblement",
"wall of force",
"dispel magic"
]
},
{
"name": "Blade of Avernus",
"source": "BGDIA",
"page": 150,
"baseItem": "longsword|phb",
"type": "M",
"rarity": "artifact",
"reqAttune": true,
"weight": 3,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"bonusWeapon": "+3",
"entries": [
"You gain a +3 bonus to attack and damage rolls made with this magic weapon. In addition, the weapon ignores resistance to slashing damage.",
"When you attack a creature that has at least one head with this weapon and roll a 20 on the attack roll, you cut off one of the creature's heads. The creature dies if it can't survive without the lost head. A creature is immune to this effect if it is immune to slashing damage, doesn't have or need a head, has legendary actions, or the DM decides that the creature is too big for its head to be cut off with this weapon. Such a creature instead takes an extra {@damage 6d8} slashing damage from the hit.",
{
"type": "entries",
"name": "Random Properties",
"entries": [
"The sword has the following randomly determined properties, as described in chapter 7 of the Dungeon Master's Guide:",
{
"type": "list",
"items": [
"2 {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial properties}",
"1 {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial property}",
"1 {@table Artifact Properties; Major Detrimental Properties|dmg|major detrimental property}, which is suppressed while the sword is attuned to an archdevil"
]
}
]
},
{
"type": "entries",
"name": "Ruler of Avernus",
"entries": [
"As an action, you can point this sword at one devil you can see within 60 feet of you. That devil must succeed on a DC 22 Charisma saving throw or be {@condition charmed} by you for 1 hour. While {@condition charmed} in this way, the devil follows your orders to the best of its ability. If you command the devil to do something it perceives as suicidal, the effect ends and you can no longer use this power of the sword against that devil."
]
},
{
"type": "entries",
"name": "Infernal Recall",
"entries": [
"As an action, you can use the sword to cast {@spell word of recall}. Creatures transported by this spell appear in an unoccupied space within 30 feet of the current ruler of Avernus, or a sanctuary you designate if you rule Avernus."
]
},
{
"type": "entries",
"name": "Destroying the Sword",
"entries": [
"The sword can be destroyed by casting it into the River Styx or melting it down in the forge that created it."
]
}
],
"attachedSpells": [
"word of recall"
]
},
{
"name": "Blade of Broken Mirrors (Awakened)",
"source": "EGW",
"_copy": {
"name": "Blade of Broken Mirrors (Dormant)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Awakened",
"entries": [
"When the dagger reaches an awakened state, it gains the following properties:",
{
"type": "list",
"items": [
"The weapon's bonus to attack and damage rolls increases to +2.",
"While holding the weapon, you can use an action to cast one of the following spells from it (save DC 15): {@spell fabricate}, {@spell hallucinatory terrain}, {@spell major image}, or {@spell phantasmal killer}. Once a spell has been cast using the dagger, that spell can't be cast from the dagger again until the next dawn."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"bonusWeapon": "+2",
"attachedSpells": [
"fabricate",
"hallucinatory terrain",
"major image",
"phantasmal killer"
]
},
{
"name": "Blade of Broken Mirrors (Dormant)",
"source": "EGW",
"page": 275,
"baseItem": "dagger|phb",
"type": "M",
"rarity": "artifact",
"reqAttune": "by a humanoid",
"reqAttuneTags": [
{
"creatureType": "humanoid"
}
],
"sentient": true,
"weight": 1,
"weaponCategory": "simple",
"property": [
"F",
"L",
"T"
],
"range": "20/60",
"dmg1": "1d4",
"dmgType": "P",
"bonusWeapon": "+1",
"weapon": true,
"entries": [
"A weapon of Tharizdun, this dagger is a piece of jagged stone whose blade is scribed with a maze-like pattern with no beginning or end.",
{
"type": "entries",
"name": "Sentience",
"entries": [
"The Blade of Broken Mirrors is a sentient chaotic evil weapon with an Intelligence of 21, a Wisdom of 24, and a Charisma of 24. It has hearing and {@sense darkvision} out to a range of 120 feet.",
"The weapon communicates telepathically with its wielder and can speak, read, and understand Abyssal and Common."
]
},
{
"type": "entries",
"name": "Personality",
"entries": [
"An insane {@creature glabrezu} named Ragazuu lives within the Blade of Broken Mirrors. The weapon yearns to cause chaos. It learns its wielder's principles, then uses those ideals to encourage reckless action. The dagger whispers ideas of revolution."
]
},
{
"type": "entries",
"name": "Dormant",
"entries": [
"The dagger grants the following benefits in its dormant state:",
{
"type": "list",
"items": [
"You can speak, read, and write Abyssal.",
"You gain a +1 bonus to attack and damage rolls made with this magic weapon. Immediately after you make a ranged attack with this weapon, it returns to your hand.",
"Whenever you kill a humanoid with an attack using the Blade of Broken Mirrors, the blade remembers the creature's appearance. While holding the dagger, you can use an action to change your form to match any humanoid the blade remembers. Your statistics, other than your size, don't change. Any equipment you are wearing or carrying isn't transformed. You can revert to your true appearance as an action. You revert to your true appearance automatically when you die. When the Blade of Broken Mirrors attunes to a new wielder, the appearances of humanoids it has killed are wiped from its memory."
]
}
]
},
{
"type": "inset",
"name": "Betrayer Artifact Properties",
"entries": [
"The Arms of the Betrayers advance in power in the same manner as the Vestiges of Divergence. In its dormant state, each of these artifacts has one {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} property and one {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental} property. When the artifact attains an awakened state, it gains an additional {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} property and an additional {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental} property. When the item reaches its exalted state, it gains a {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial} property. See \"Artifact Properties\" in chapter 7 of the Dungeon Master's Guide for more information."
]
}
]
},
{
"name": "Blade of Broken Mirrors (Exalted)",
"source": "EGW",
"_copy": {
"name": "Blade of Broken Mirrors (Awakened)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Exalted",
"entries": [
"When the dagger reaches an exalted state, it gains the following properties:",
{
"type": "list",
"items": [
"The weapon's bonus to attack and damage rolls increases to +3.",
"The saving throw DC for spells cast from the dagger increases to 17.",
"While holding the dagger, you can turn {@condition invisible} as an action. Anything you are wearing or carrying is {@condition invisible} with you. You remain {@condition invisible} until you stop holding the dagger, until you attack or cast a spell that forces a creature to make a saving throw, or until you use a bonus action to become visible again."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"bonusWeapon": "+3"
},
{
"name": "Blanket",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 3,
"value": 50
},
{
"name": "Blast Scepter",
"source": "WDMM",
"page": 310,
"type": "RD",
"scfType": "arcane",
"rarity": "very rare",
"reqAttune": true,
"focus": [
"Sorcerer",
"Warlock",
"Wizard"
],
"entries": [
"The Blast Scepter can be used as an arcane focus.",
"Whoever is attuned to the Blast Scepter gains resistance to fire and lightning damage and can, as an action, use it to cast {@spell thunderwave} as a 4th-level spell (save DC 16) without expending a spell slot."
],
"attachedSpells": [
"thunderwave"
]
},
{
"name": "Blasting Powder",
"source": "EGW",
"page": 225,
"type": "G",
"rarity": "none",
"entries": [
"This volatile alchemical powder comes in a small pouch. When ignited by an open flame or a fuse, the powder explodes. Each creature within 5 feet of the exploding pouch must make a DC 13 Dexterity saving throw, taking {@damage 3d6} bludgeoning damage on a failed save, or half as much damage on a successful one.",
"A character can bind multiple pouches of blasting powder together so they explode at the same time. Each additional pouch increases the damage by {@dice 1d6} (maximum of {@dice 10d6}) and the blast radius by 5 feet (maximum of 20 feet)."
]
},
{
"name": "Blight Ichor",
"source": "EGW",
"page": 152,
"type": "G",
"rarity": "none",
"value": 20000,
"entries": [
"This bitter chartreuse concoction is distilled from a fungus native to the Blightshore badlands. The sickly green liqueur harbors potent psychedelic properties. Provided it is neither a construct nor undead, a creature subjected to a dose of blight ichor gains advantage on Intelligence and Wisdom checks, as well as vulnerability to psychic damage, for 1 hour. For each dose of blight ichor consumed, the creature must succeed on a DC 15 Constitution saving throw or become {@condition poisoned} for {@dice 1d6} hours and suffer the effects of a {@spell confusion} spell for 1 minute. An undead creature subjected to a dose of blight ichor gains advantage on all Dexterity checks and is immune to the {@condition frightened} condition for 1 hour."
]
},
{
"name": "Block and Tackle",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 5,
"value": 100,
"entries": [
"A set of pulleys with a cable threaded through them and a hook to attach to objects, a block and tackle allows you to hoist up to four times the weight you can normally lift."
]
},
{
"name": "Blod Stone",
"source": "SKT",
"page": 233,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"This diamond contains the blood of a creature—blood that appears in the form of the blod (blood) rune. While the item is on your person, you can use your action to divine the location of the creature nearest to you that is related to the blood in the item and that isn't undead. You sense the distance and direction of the creature relative to your location. The creature is either the one whose blood is in the item or a blood relative.",
"This item is made from a large diamond worth at least 5,000 gp. When the blood of a creature is poured onto it during the creation process, the blood seeps into the heart of the gem. If the gem is destroyed, the blood evaporates and is gone forever. A vengeful being might use a blod stone to hunt down an entire bloodline. Such stones are sometimes given as gifts to siblings or handed down from parent to child."
]
},
{
"name": "Blood Fury Tattoo",
"source": "TCE",
"page": 122,
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"recharge": "dawn",
"rechargeAmount": 10,
"charges": 10,
"entries": [
"Produced by a special needle, this magic tattoo evokes fury in its form and colors.",
{
"type": "entries",
"name": "Tattoo Attunement",
"entries": [
"To attune to this item, you hold the needle to your skin where you want the tattoo to appear, pressing the needle there throughout the attunement process. When the attunement is complete, the needle turns into the ink that becomes the tattoo, which appears on the skin.",
"If your attunement to the tattoo ends, the tattoo vanishes, and the needle reappears in your space."
]
},
{
"type": "entries",
"name": "Bloodthirsty Strikes",
"entries": [
"The tattoo has 10 charges, and it regains all expended charges daily at dawn. While this tattoo is on your skin, you gain the following benefits:",
{
"type": "list",
"items": [
"When you hit a creature with a weapon attack, you can expend a charge to deal an extra {@damage 4d6} necrotic damage to the target, and you regain a number of hit points equal to the necrotic damage dealt.",
"When a creature you can see damages you, you can expend a charge and use your reaction to make a melee attack against that creature, with advantage on your attack roll."
]
}
]
}
]
},
{
"name": "Blood Fury Tattoo",
"source": "UA2020SpellsAndMagicTattoos",
"page": 10,
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"bonusWeaponCritDamage": "4d6",
"critThreshold": 19,
"entries": [
"This tattoo evokes fury in its form and colors. While this tattoo is on your skin, you gain the following benefits:",
{
"type": "list",
"items": [
"Your attack rolls score a critical hit on a {@dice d20} roll of 19 or 20.",
"When you score a critical hit against a creature, that target takes an extra {@damage 4d6} necrotic damage, and you gain a number of temporary hit points equal to the necrotic damage dealt.",
"When a creature you can see damages you, you can use your reaction to make a melee attack against that creature, with advantage on your attack roll."
]
},
{
"type": "entries",
"name": "Tattoo Attunement",
"entries": [
"To attune to this item, you hold the needle to your skin where you want the tattoo to appear, pressing the needle there throughout the attunement process. When the attunement is complete, the needle turns into the ink that becomes the tattoo, which appears on the skin. If you have multiple magic tattoos, they count as a single magic item with regard to the number of magic items you can attune to.",
"If your attunement to the tattoo ends, the tattoo vanishes, and the needle reappears in the closest unoccupied space to you."
]
}
]
},
{
"name": "Blood of the Lycanthrope",
"source": "IMR",
"page": 93,
"rarity": "none",
"poison": true,
"entries": [
"This poison is created from blood harvested from a dead or {@condition incapacitated} lycanthrope in its animal or hybrid form. A creature subjected to this poison must succeed on a DC 12 Constitution saving throw or be cursed with lycanthropy (see the Monster Manual). The curse lasts until removed by the {@spell remove curse} spell or similar magic.",
"The type of lycanthropy depends on the lycanthrope used to create the poison. To determine the type of lycanthropy randomly, roll a {@dice d6}:",
{
"type": "table",
"colLabels": [
"d6",
"Lycanthropy"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"1-3",
"{@creature Wererat}"
],
[
"4-5",
"{@creature Werewolf}"
],
[
"6",
"{@creature Wereboar}"
]
]
}
],
"poisonTypes": [
"injury"
]
},
{
"name": "Blood of the Lycanthrope Antidote",
"source": "IMR",
"page": 94,
"type": "P",
"rarity": "uncommon",
"entries": [
"This clear potion has dark red flecks within, resembling clotted blood. When you drink this potion, it removes the curse of lycanthropy from you if that curse was imposed by a lycanthrope's bite or similar effect."
]
},
{
"name": "Blood Spear",
"source": "CoS",
"page": 221,
"baseItem": "spear|phb",
"type": "M",
"rarity": "uncommon",
"reqAttune": true,
"weight": 3,
"weaponCategory": "simple",
"property": [
"T",
"V"
],
"range": "20/60",
"dmg1": "1d6",
"dmgType": "P",
"dmg2": "1d8",
"bonusWeapon": "+2",
"entries": [
"Kavan was a ruthless chieftain whose tribe lived in the Balinok Mountains centuries before the arrival of {@creature Strahd von Zarovich|CoS}. Although he was very much alive, Kavan had some traits in common with vampires: he slept during the day and hunted at night, he drank the blood of his prey, and he lived underground. In battle, he wielded a spear stained with blood. His was the first blood spear, a weapon that drains life from those it kills and transfers that life to its wielder, imbuing that individual with the stamina to keep fighting.",
"When you hit with a melee attack using this magic spear and reduce the target to 0 hit points, you gain {@dice 2d6} temporary hit points.",
"Any creature can wield the spear, but only the character {@adventure chosen by Kavan|CoS|14|Blood Spear of Kavan} to wield it gains a +2 bonus to attack and damage rolls made with this magic weapon."
],
"hasFluffImages": true
},
{
"name": "Bloodaxe",
"source": "EGW",
"page": 266,
"baseItem": "greataxe|PHB",
"type": "M",
"rarity": "very rare",
"reqAttune": true,
"weight": 7,
"weaponCategory": "martial",
"property": [
"H",
"2H"
],
"dmg1": "1d12",
"dmgType": "S",
"bonusWeapon": "+2",
"axe": true,
"weapon": true,
"entries": [
"You gain a +2 bonus to attack and damage rolls made with this magic axe. The axe deals an extra {@damage 1d6} necrotic damage to creatures that aren't constructs or undead. If you reduce such a creature to 0 hit points with an attack using this axe, you gain 10 temporary hit points.",
"This axe is forged from a dark, rust-colored metal and once belonged to the goliath barbarian Grog Strongjaw of Vox Machina."
]
},
{
"name": "Bloodstone",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 5000,
"entries": [
"An opaque dark gray with red flecks gemstone."
]
},
{
"name": "Blue Chromatic Rose",
"source": "WBtW",
"page": 208,
"resist": [
"lightning"
],
"detail1": "crackles with lightning",
"rarity": "rare",
"wondrous": true,
"hasRefs": true,
"entries": [
"{#itemEntry Chromatic Rose|WBtW}"
]
},
{
"name": "Blue Dragon Mask",
"source": "RoTOS",
"page": 4,
"resist": [
"lightning"
],
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"grantsProficiency": true,
"entries": [
"This mask of glossy azure has spikes around its edges and a ridged horn in its center. The mask reshapes to fit a wearer attuned to it. While you are wearing the mask and attuned to it, you can access the following properties.",
{
"type": "entries",
"name": "Damage Absorption",
"entries": [
"You have resistance against lightning damage. If you already have resistance to lightning damage from another source, you instead have immunity to lightning damage. If you already have immunity to lightning damage from another source, whenever you are subjected to lightning damage, you take none of that damage and regain a number of hit points equal to half the damage dealt of that type."
]
},
{
"type": "entries",
"name": "Draconic Majesty",
"entries": [
"While you are wearing no armor, you can add your Charisma bonus to your Armor Class."
]
},
{
"type": "entries",
"name": "Dragon Breath",
"entries": [
"If you have a breath weapon that requires rest to recharge, it gains a recharge of 6."
]
},
{
"type": "entries",
"name": "Dragon Sight",
"entries": [
"You gain {@sense darkvision} out to 60 feet, or to an additional 60 feet if you already have that sense. Once per day, you can gain {@sense blindsight} out to 30 feet for 5 minutes."
]
},
{
"type": "entries",
"name": "Dragon Tongue",
"entries": [
"You can speak and understand Draconic. You also have advantage on any Charisma check you make against Blue Dragons."
]
},
{
"type": "entries",
"name": "Legendary Resistance",
"entries": [
"(1/Day) If you fail a saving throw, you can choose to succeed instead."
]
},
{
"type": "entries",
"name": "Lingering Shock",
"entries": [
"If you deal lightning damage to a creature, it can't take reactions until its next turn."
]
}
]
},
{
"name": "Blue Dragon Scale Mail",
"source": "DMG",
"page": 165,
"srd": true,
"type": "MA",
"resist": [
"lightning"
],
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"weight": 45,
"ac": 14,
"bonusAc": "+1",
"bonusSavingThrow": "+1",
"stealth": true,
"entries": [
"Dragon scale mail is made of the scales of one kind of dragon. Sometimes dragons collect their cast-off scales and gift them to humanoids. Other times, hunters carefully skin and preserve the hide of a dead dragon. In either case, dragon scale mail is highly valued. While wearing this armor, you gain a +1 bonus to AC, you have advantage on saving throws against the Frightful Presence and breath weapons of dragons, and you have resistance to lightning damage.",
"Additionally, you can focus your senses as an action to magically discern the distance and direction to the closest blue dragon within 30 miles of you. This special action can't be used again until the next dawn."
]
},
{
"name": "Blue Quartz",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 1000,
"entries": [
"A transparent pale blue gemstone."
]
},
{
"name": "Blue Sapphire",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 100000,
"entries": [
"A transparent blue-white to medium blue gemstone."
]
},
{
"name": "Blue Spinel",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 50000,
"entries": [
"A transparent deep blue gemstone."
]
},
{
"name": "Bob",
"source": "ToA",
"page": 89,
"baseItem": "battleaxe|phb",
"type": "M",
"rarity": "unknown (magic)",
"weight": 4,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"bonusWeapon": "+1",
"entries": [
"This +1 battleaxe floats on water and other liquids, and grants its bearer advantage on Strength ({@skill Athletics}) checks made to swim."
]
},
{
"name": "Bobbing Lily Pad",
"source": "WBtW",
"page": 208,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"This magic vehicle is a 10-foot-diameter leaf that floats on water. It has tendrils that propel it across land and across the water's surface (but not underwater), as well as through the air. It has a walking, flying, and swimming speed of 20 feet, and it can hover. It moves according to your spoken directions while you are riding it.",
"The lily pad can transport up to 300 pounds without hindrance. It can carry up to twice this weight, but it moves at half speed if it carries more than its normal capacity."
]
},
{
"name": "Bomb",
"source": "DMG",
"page": 268,
"type": "EXP",
"rarity": "none",
"weight": 1,
"value": 15000,
"age": "renaissance",
"entries": [
"As an action, a character can light this bomb and throw it at a point up to 60 feet away. Each creature within 5 feet of that point must succeed on a DC 12 Dexterity saving throw or take {@damage 3d6} fire damage."
]
},
{
"name": "Bombard",
"source": "AAG",
"page": 0,
"type": "SPC",
"rarity": "none",
"value": 5000000,
"crew": 12,
"vehAc": 15,
"vehHp": 300,
"vehDmgThresh": 20,
"vehSpeed": 4,
"capCargo": 150,
"entries": [
"Bombards are built by giff. The major feature of each ship is an enormous cannon that fires massive cannon balls capable of blowing other ships to smithereens. (The cannon is included in the cost of the ship.) A bombard can carry up to fourteen giant cannon balls, each of which weighs 10 tons. These cannon balls make up most of the weight of the ship's cargo. A winch mounted on the aft deck is used to load the cannon balls on board.",
"A bombard can float and sail on water, but it can't land safely on the ground (its keel would cause it to roll on its side)."
],
"seeAlsoVehicle": [
"Bombard|AAG"
]
},
{
"name": "Bonecounter",
"source": "SDW",
"baseItem": "mace|phb",
"type": "M",
"rarity": "rare",
"weight": 4,
"weaponCategory": "simple",
"dmg1": "1d6",
"dmgType": "B",
"bonusWeapon": "+2",
"entries": [
"This weapon is a magical +2 mace called Bonecounter. Whenever this weapon is used to destroy an undead creature, a single silver piece appears in the wielder's pocket."
]
},
{
"name": "Book",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 5,
"value": 2500,
"entries": [
"A book might contain poetry, historical accounts, information pertaining to a particular field of lore, diagrams and notes on gnomish contraptions, or just about anything else that can be represented using text or pictures. A book of spells is a {@item spellbook|phb}."
]
},
{
"name": "Book of Exalted Deeds",
"source": "DMG",
"page": 222,
"rarity": "artifact",
"reqAttune": "by a creature of good alignment",
"reqAttuneTags": [
{
"alignment": [
"G"
]
}
],
"wondrous": true,
"weight": 5,
"ability": {
"wis": 2
},
"entries": [
"The definitive treatise on all that is good in the multiverse, the fabled Book of Exalted Deeds figures prominently in many religions. Rather than being a scripture devoted to a particular faith, the book's various authors filled the pages with their own vision of true virtue, providing guidance for defeating evil.",
"The Book of Exalted Deeds rarely lingers in one place. As soon as the book is read, it vanishes to some other corner of the multiverse where its moral guidance can bring light to a darkened world. Although attempts have been made to copy the work, efforts to do so fail to capture its magical nature or translate the benefits it offers to those pure of heart and firm of purpose.",
"A heavy clasp, wrought to look like angel wings, keeps the book's contents secure. Only a creature of good alignment that is attuned to the book can release the clasp that holds it shut. Once the book is opened, the attuned creature must spend 80 hours reading and studying the book to digest its contents and gain its benefits. Other creatures that peruse the book's open pages can read the text but glean no deeper meaning and reap no benefits. An evil creature that tries to read from the book takes {@damage 24d6} radiant damage. This damage ignores resistance and immunity, and can't be reduced or avoided by any means. A creature reduced to 0 hit points by this damage disappears in a blinding flash and is destroyed, leaving its possessions behind.",
"Benefits granted by the Book of Exalted Deeds last only as long as you strive to do good. If you fail to perform at least one act of kindness or generosity within the span of 10 days, or if you willingly perform an evil act, you lose all the benefits granted by the book.",
{
"name": "Random Properties",
"type": "entries",
"entries": [
"The Book of Exalted Deeds has the following random properties:"
]
},
{
"type": "list",
"items": [
"2 {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial properties}",
"2 {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial properties}"
]
},
{
"name": "Increased Wisdom",
"type": "entries",
"entries": [
"After you spend the requisite amount of time reading and studying the book, your Wisdom score increases by 2, to a maximum of 24. You can't gain this benefit from the book more than once."
]
},
{
"name": "Enlightened Magic",
"type": "entries",
"entries": [
"Once you've read and studied the book, any spell slot you expend to cast a cleric or paladin spell counts as a spell slot of one level higher."
]
},
{
"name": "Halo",
"type": "entries",
"entries": [
"Once you've read and studied the book, you gain a protective halo. This halo sheds bright light in a 10-foot radius and dim light for an additional 10 feet. You can dismiss or manifest the halo as a bonus action. While present, the halo gives you advantage on Charisma ({@skill Persuasion}) checks made to interact with good creatures and Charisma ({@skill Intimidation}) checks made to interact with evil creatures. In addition, fiends and undead within the halo's bright light make attack rolls against you with disadvantage."
]
},
{
"name": "Destroying the Book",
"type": "entries",
"entries": [
"It is rumored that the Book of Exalted Deeds can't be destroyed as long as good exists in the multiverse. However, drowning the book in the River Styx removes all writing and imagery from its pages and renders the book powerless for {@dice 1d100} years."
]
}
],
"hasFluffImages": true
},
{
"name": "Book of Vile Darkness",
"source": "DMG",
"page": 222,
"rarity": "artifact",
"reqAttune": true,
"wondrous": true,
"weight": 5,
"ability": {
"choose": [
{
"from": [
"str",
"dex",
"con",
"int",
"wis",
"cha"
],
"count": 1,
"amount": 2
}
]
},
"entries": [
"The contents of this foul manuscript of ineffable wickedness are the meat and drink of those in evil's thrall. No mortal was meant to know the secrets it contains, knowledge so horrid that to even glimpse the scrawled pages invites madness.",
"Most believe the lich-god Vecna authored the Book of Vile Darkness. He recorded in its pages every diseased idea, every unhinged thought, and every example of blackest magic he came across or devised. Vecna covered every vile topic he could, making the book a gruesome catalog of all mortal wrongs.",
"Other practitioners of evil have held the book and added their own input to its catalog of vile knowledge. Their additions are clear, for the writers of later works stitched whatever they were writing into the tome or, in some cases, made notations and additions to existing text. There are places where pages are missing, torn. or covered so completely with ink, blood, and scratches that the original text can't be divined.",
"Nature can't abide the book's presence. Ordinary plants wither in its presence, animals are unwilling to approach it, and the book gradually destroys whatever it touches. Even stone cracks and turns to powder if the book rests on it long enough.",
"A creature attuned to the book must spend 80 hours reading and studying it to digest its contents and reap its benefits. The creature can then freely modify the book's contents, provided that those modifications advance evil and expand the lore already contained within.",
"Whenever a non-evil creature attunes to the Book of Vile Darkness, that creature must make a DC 17 Charisma saving throw. On a failed save, the creature's alignment changes to neutral evil.",
"The Book of Vile Darkness remains with you only as long as you strive to work evil in the world. If you fail to perform at least one evil act within the span of 10 days, or if you willingly perform a good act, the book disappears. If you die while attuned to the book, an entity of great evil claims your soul. You can't be restored to life by any means while your soul remains imprisoned.",
{
"name": "Random Properties",
"type": "entries",
"entries": [
"The Book of Vile Darkness has the following random properties:"
]
},
{
"type": "list",
"items": [
"3 {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial properties}",
"1 {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial property}",
"3 {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental properties}",
"2 {@table Artifact Properties; Major Detrimental Properties|dmg|major detrimental properties}"
]
},
{
"name": "Adjusted Ability Scores",
"type": "entries",
"entries": [
"After you spend the requisite amount of time reading and studying the book, one ability score of your choice increases by 2, to a maximum of 24. Another ability score of your choice decreases by 2, to a minimum of 3. The book can't adjust your ability scores again."
]
},
{
"name": "Mark of Darkness",
"type": "entries",
"entries": [
"After you spend the requisite amount of time reading and studying the book, you acquire a physical disfigurement as a hideous sign of your devotion to vile darkness. An evil rune might appear on your face, your eyes might become glossy black, or horns might sprout from your forehead. Or you might become wizened and hideous, lose all facial features, gain a forked tongue, or some other feature the DM chooses. The mark of darkness grants you advantage on Charisma ({@skill Persuasion}) checks made to interact with evil creatures and Charisma ({@skill Intimidation}) checks made to interact with non-evil creatures."
]
},
{
"name": "Command Evil",
"type": "entries",
"entries": [
"While you are attuned to the book and holding it, you can use an action to cast the {@spell dominate monster} spell on an evil target (save DC 18). You can't use this property again until the next dawn."
]
},
{
"name": "Dark Lore",
"type": "entries",
"entries": [
"You can reference the Book of Vile Darkness whenever you make an Intelligence check to recall information about some aspect of evil, such as lore about demons. When you do so, double your proficiency bonus on that check."
]
},
{
"name": "Dark Speech",
"type": "entries",
"entries": [
"While you carry the Book of Vile Darkness and are attuned to it, you can use an action to recite word from its pages in a foul language known as Dark Speech. Each time you do so, you take {@damage 1d12} psychic damage, and each non-evil creature within 15 feet of you takes {@damage 3d6} psychic damage."
]
},
{
"name": "Destroying the Book",
"type": "entries",
"entries": [
"The Book of Vile Darkness allows pages to be torn from it, but any evil lore contained on those pages finds its way back into the book eventually, usually when a new author adds pages to the tome.",
"If a {@creature solar} tears the book in two, the book is destroyed for {@dice 1d100} years, after which it reforms in some dark corner of the multiverse.",
"A creature attuned to the book for one hundred years can unearth a phrase hidden in the original text that, when translated to Celestial and spoken aloud, destroys both the speaker and the book in a blinding flash of radiance. However, as long as evil exists in the multiverse, the book reforms {@dice 1d10 × 100} years later.",
"If all evil in the multiverse is wiped out, the book turns to dust and is forever destroyed."
]
}
],
"attachedSpells": [
"dominate monster"
],
"hasFluffImages": true
},
{
"name": "Bookmark",
"source": "ToA",
"page": 206,
"baseItem": "dagger|phb",
"type": "M",
"rarity": "legendary",
"reqAttune": true,
"weight": 1,
"weaponCategory": "simple",
"property": [
"F",
"L",
"T"
],
"range": "20/60",
"dmg1": "1d4",
"dmgType": "P",
"bonusWeapon": "+3",
"entries": [
"This +3 dagger belongs to {@creature Artus Cimber|ToA}. While you have the dagger drawn, you can use a bonus action to activate one of the following properties:",
{
"type": "list",
"items": [
"Cause a blue gem set into the dagger's pommel to shed bright light in a 20-foot radius and dim light for an additional 20 feet, or make the gem go dark.",
"Turn the dagger into a compass that, while resting on your palm, points north.",
"Cast {@spell dimension door} from the dagger. Once this property is used, it can't be used again until the next dawn.",
"Cast {@spell compulsion} (save DC 15) from the dagger. The range of the spell increases to 90 feet but it targets only spiders that are beasts. Once this property is used, it can't be used again until the next dawn."
]
}
],
"attachedSpells": [
"dimension door",
"compulsion"
]
},
{
"name": "Boots of Elvenkind",
"source": "DMG",
"page": 155,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"wondrous": true,
"entries": [
"While you wear these boots, your steps make no sound, regardless of the surface you are moving across. You also have advantage on Dexterity ({@skill Stealth}) checks that rely on moving silently."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Boots of False Tracks",
"source": "XGE",
"page": 136,
"tier": "minor",
"rarity": "common",
"wondrous": true,
"entries": [
"Only humanoids can wear these boots. While wearing the boots, you can choose to have them leave tracks like those of another kind of humanoid of your size."
]
},
{
"name": "Boots of Levitation",
"source": "DMG",
"page": 155,
"srd": true,
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"While you wear these boots, you can use an action to cast the {@spell levitate} spell on yourself at will."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"attachedSpells": [
"levitate"
],
"lootTables": [
"Magic Item Table G"
]
},
{
"name": "Boots of Speed",
"source": "DMG",
"page": 155,
"srd": true,
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"modifySpeed": {
"multiply": {
"walk": 2
}
},
"entries": [
"While you wear these boots, you can use a bonus action and click the boots' heels together. If you do, the boots double your walking speed, and any creature that makes an opportunity attack against you has disadvantage on the attack roll. If you click your heels together again, you end the effect.",
"When the boots' property has been used for a total of 10 minutes, the magic ceases to function until you finish a long rest."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Boots of Striding and Springing",
"source": "DMG",
"page": 156,
"srd": true,
"basicRules": true,
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"modifySpeed": {
"static": {
"walk": 30
}
},
"entries": [
"While you wear these boots, your walking speed becomes 30 feet, unless your walking speed is higher, and your speed isn't reduced if you are encumbered or wearing heavy armor. In addition, you can jump three times the normal distance, though you can't jump farther than your remaining movement would allow."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Boots of the Winterlands",
"source": "DMG",
"page": 156,
"srd": true,
"resist": [
"cold"
],
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"entries": [
"These furred boots are snug and feel quite warm. While you wear them, you gain the following benefits:",
{
"type": "list",
"items": [
"You have resistance to cold damage.",
"You ignore {@quickref difficult terrain||3} created by ice or snow.",
"You can tolerate temperatures as low as -50 degrees Fahrenheit without any additional protection. If you wear heavy clothes, you can tolerate temperatures as low as -100 degrees Fahrenheit."
]
}
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Boros Guild Signet",
"source": "GGR",
"page": 178,
"type": "RG",
"rarity": "uncommon",
"reqAttune": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"This ring, adorned with the symbol of Boros, allows you to cast {@spell heroism}. A guild signet is sometimes awarded to a guild member whose renown score in that guild is 5 or higher, as a reward for performing special services for the guild. Aside from its magical properties, the ring is also an indicator of Boros' recognition and favor.",
"A signet has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. While wearing it, you can expend 1 charge to cast the associated spell (save DC 13)."
],
"attachedSpells": [
"heroism"
],
"hasFluffImages": true
},
{
"name": "Boros Keyrune",
"source": "GGR",
"page": 177,
"rarity": "rare",
"reqAttune": "by a member of the Boros guild",
"reqAttuneTags": [
{
"background": "boros legionnaire|ggr"
}
],
"wondrous": true,
"entries": [
"Carved from red sandstone with white granite elements to resemble a member of the Boros Legion, this keyrune can become a {@creature veteran} (human) for up to 8 hours. In addition to fighting on your behalf, this {@creature veteran} cheerfully offers tactical advice, which is usually sound. Anyone who talks with the transformed keyrune or examines it closely can easily recognize that it is an artificial human.",
"When you use an action to speak the item's command word and place the keyrune on the ground in an unoccupied space within 5 feet of you, the keyrune transforms into a {@creature veteran} (human). If there isn't enough space for the {@creature veteran}, the keyrune doesn't transform.",
"The creature is friendly to you, your companions, and other members of your guild (unless those guild members are hostile to you). It understands your languages and obeys your spoken commands. If you issue no commands, the creature takes the {@action Dodge} action and moves to avoid danger.",
"At the end of the duration, the creature reverts to its keyrune form. It reverts early if it drops to 0 hit points or if you use an action to speak the command word again while touching it. When the creature reverts to its keyrune form, it can't transform again until 36 hours have passed."
],
"hasFluffImages": true
},
{
"name": "Bottle of Boundless Coffee",
"source": "SCC",
"page": 38,
"rarity": "common",
"wondrous": true,
"entries": [
"This metal bottle carries delicious, warm coffee. The bottle comes with a stopper, which is attached to the bottle by a little chain. Even when open, the bottle won't accept any liquid other than the coffee it produces. The coffee inside is always comfortably warm, and none of the heat can be felt through the bottle.",
"Each time you drink the coffee, roll a {@dice d20}. On a 1, the bottle refuses to dispense coffee for the next hour. If you pour coffee from the bottle, rather than drinking from it, the coffee vanishes the moment it leaves the bottle."
]
},
{
"name": "Bottle stopper cork embossed with gold leaf and set with amethysts",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 75000
},
{
"name": "Bottled Breath",
"source": "PotA",
"page": 222,
"type": "P",
"rarity": "uncommon",
"entries": [
"This bottle contains a breath of elemental air. When you inhale it, you either exhale it or hold it.",
"If you exhale the breath, you gain the effect of the {@spell gust of wind} spell. If you hold the breath, you don't need to breathe for 1 hour, though you can end this benefit early (for example, to speak). Ending it early doesn't give you the benefit of exhaling the breath."
],
"attachedSpells": [
"gust of wind"
]
},
{
"name": "Bowl of Commanding Water Elementals",
"source": "DMG",
"page": 156,
"srd": true,
"tier": "major",
"rarity": "rare",
"wondrous": true,
"weight": 3,
"entries": [
"While this bowl is filled with water, you can use an action to speak the bowl's command word and summon a {@creature water elemental}, as if you had cast the {@spell conjure elemental} spell. The bowl can't be used this way again until the next dawn.",
"The bowl is about 1 foot in diameter and half as deep. It weighs 3 pounds and holds about 3 gallons."
],
"attachedSpells": [
"conjure elemental"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Box of turquoise animal figurines",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 25000
},
{
"name": "Bracelet of Rock Magic",
"source": "TftYP",
"page": 228,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"curse": true,
"weight": 1,
"entries": [
"While you wear this gold bracelet, it grants you immunity to being {@condition petrified}, and it allows you to cast {@spell flesh to stone} (save DC 15) as an action. Once the spell has been cast three times, the bracelet can no longer cast it. Thereafter, you can cast {@spell stone shape} as an action. After you have done this thirteen times, the bracelet loses its magic and turns from gold to lead.",
{
"type": "entries",
"name": "Curse",
"entries": [
"The bracelet's affinity with earth manifests as an unusual curse. Creatures of flesh that are strongly related to earth and stone, such as stone giants and dwarves, have advantage on the saving throw against {@spell flesh to stone} cast from the bracelet. If such a creature's save is successful, the bracelet breaks your attunement to it and casts the spell on you. You make your saving throw with disadvantage, and on a failed save you are {@condition petrified} instantly."
]
}
],
"attachedSpells": [
"flesh to stone",
"stone shape"
]
},
{
"name": "Bracer of Flying Daggers",
"source": "WDH",
"page": 190,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"This armband appears to have thin {@item dagger|phb|daggers} strapped to it. As an action, you can pull up to two magic daggers from the bracer and immediately hurl them, making a ranged attack with each dagger. A dagger vanishes if you don't hurl it right away, and the daggers disappear right after they hit or miss. The bracer never runs out of daggers."
]
},
{
"name": "Bracers of Archery",
"source": "DMG",
"page": 156,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"grantsProficiency": true,
"bonusWeaponDamage": "+2",
"entries": [
"While wearing these bracers, you have proficiency with the longbow and shortbow, and you gain a +2 bonus to damage rolls on ranged attacks made with such weapons."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Bracers of Defense",
"source": "DMG",
"page": 156,
"srd": true,
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"bonusAc": "+2",
"entries": [
"While wearing these bracers, you gain a +2 bonus to AC if you are wearing no armor and using no shield."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Brass Dragon Scale Mail",
"source": "DMG",
"page": 165,
"srd": true,
"type": "MA",
"resist": [
"fire"
],
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"weight": 45,
"ac": 14,
"bonusAc": "+1",
"bonusSavingThrow": "+1",
"stealth": true,
"entries": [
"Dragon scale mail is made of the scales of one kind of dragon. Sometimes dragons collect their cast-off scales and gift them to humanoids. Other times, hunters carefully skin and preserve the hide of a dead dragon. In either case, dragon scale mail is highly valued. While wearing this armor, you gain a +1 bonus to AC, you have advantage on saving throws against the Frightful Presence and breath weapons of dragons, and you have resistance to fire damage.",
"Additionally, you can focus your senses as an action to magically discern the distance and direction to the closest brass dragon within 30 miles of you. This special action can't be used again until the next dawn."
]
},
{
"name": "Brass mug with jade inlay",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 25000
},
{
"name": "Brazier of Commanding Fire Elementals",
"source": "DMG",
"page": 156,
"srd": true,
"tier": "major",
"rarity": "rare",
"wondrous": true,
"weight": 5,
"entries": [
"While a fire burns in this brass brazier, you can use an action to speak the brazier's command word and summon a {@creature fire elemental}, as if you had cast the {@spell conjure elemental} spell. The brazier can't be used this way again until the next dawn.",
"The brazier weighs 5 pounds."
],
"attachedSpells": [
"conjure elemental"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Breathing Bubble",
"source": "EGW",
"page": 266,
"otherSources": [
{
"source": "CRCotN",
"page": 213
}
],
"rarity": "common",
"wondrous": true,
"entries": [
"This translucent, bubble-like sphere has a slightly tacky outer surface, and you gain the item's benefits only while wearing it over your head like a helmet.",
"The bubble contains 1 hour of breathable air. The bubble regains all its expended air daily at dawn."
]
},
{
"name": "Brewer's Supplies",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"additionalSources": [
{
"source": "XGE",
"page": 79
}
],
"type": "AT",
"rarity": "none",
"weight": 9,
"value": 2000,
"additionalEntries": [
"Brewing is the art of producing beer. Not only does beer serve as an alcoholic beverage, but the process of brewing purifies water. Crafting beer takes weeks of fermentation, but only a few hours of work.",
{
"type": "entries",
"name": "Components",
"entries": [
"Brewer's supplies include a large glass jug, a quantity of hops, a siphon, and several feet of tubing."
]
},
{
"type": "entries",
"name": "History",
"entries": [
"Proficiency with brewer's supplies gives you additional insight on Intelligence ({@skill History}) checks concerning events that involve alcohol as a significant element."
]
},
{
"type": "entries",
"name": "Medicine",
"entries": [
"This tool proficiency grants additional insight when you treat anyone suffering from alcohol poisoning or when you can use alcohol to dull pain."
]
},
{
"type": "entries",
"name": "Persuasion",
"entries": [
"A stiff drink can help soften the hardest heart. Your proficiency with brewer's supplies can help you ply someone with drink, giving them just enough alcohol to mellow their mood."
]
},
{
"type": "entries",
"name": "Potable Water",
"entries": [
"Your knowledge of brewing enables you to purify water that would otherwise be undrinkable. As part of a long rest, you can purify up to 6 gallons of water, or 1 gallon as part of a short rest."
]
},
{
"type": "table",
"caption": "Brewer's Supplies",
"colLabels": [
"Activity",
"DC"
],
"colStyles": [
"col-10",
"col-2 text-center"
],
"rows": [
[
"Detect poison or impurities in a drink",
"10"
],
[
"Identify alcohol",
"15"
],
[
"Ignore effects of alcohol",
"20"
]
]
}
]
},
{
"name": "Bridle of Capturing",
"source": "IMR",
"page": 94,
"rarity": "rare",
"wondrous": true,
"entries": [
"This fine leather bridle tugs at the hand when first held, as though it longs to reach out for a beast nearby. When you hold one end of the bridle, you can use an action to speak its command word, causing the other end to lash out at a beast you can see within 20 feet of you. The target must succeed on a DC 17 Charisma saving throw or have the bridle tie itself around its neck, then fall under your command as if subjected to the {@spell dominate beast} spell. Once the targeted is affected, you do not need to hold the other end of the bridle to command it. With a successful saving throw, the target becomes immune to the bridle's power until the next dawn.",
"A creature controlled by the bridle can be released by the creature that bound it as a bonus action. A creature controlled by the bridle can make a DC 17 Charisma check each day at dawn. On a success, the creature is no longer affected by the bridle."
],
"attachedSpells": [
"dominate beast"
]
},
{
"name": "Bronze crown",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 25000
},
{
"name": "Bronze Dragon Scale Mail",
"source": "DMG",
"page": 165,
"srd": true,
"type": "MA",
"resist": [
"lightning"
],
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"weight": 45,
"ac": 14,
"bonusAc": "+1",
"bonusSavingThrow": "+1",
"stealth": true,
"entries": [
"Dragon scale mail is made of the scales of one kind of dragon. Sometimes dragons collect their cast-off scales and gift them to humanoids. Other times, hunters carefully skin and preserve the hide of a dead dragon. In either case, dragon scale mail is highly valued. While wearing this armor, you gain a +1 bonus to AC, you have advantage on saving throws against the Frightful Presence and breath weapons of dragons, and you have resistance to lightning damage.",
"Additionally, you can focus your senses as an action to magically discern the distance and direction to the closest bronze dragon within 30 miles of you. This special action can't be used again until the next dawn."
]
},
{
"name": "Bronze spyglass (Brazen Coalition)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 25000
},
{
"name": "Brooch of Living Essence",
"source": "EGW",
"page": 266,
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"entries": [
"While wearing this nondescript brooch, spells and anything else that would detect or reveal your creature type treat you as humanoid, and those that would reveal your alignment treat it as neutral."
]
},
{
"name": "Brooch of Shielding",
"source": "DMG",
"page": 156,
"srd": true,
"resist": [
"force"
],
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"entries": [
"While wearing this brooch, you have resistance to force damage, and you have immunity to damage from the {@spell magic missile} spell."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Broom of Flying",
"source": "DMG",
"page": 156,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"wondrous": true,
"weight": 3,
"entries": [
"This wooden broom, which weighs 3 pounds, functions like a mundane broom until you stand astride it and speak its command word. It then hovers beneath you and can be ridden in the air. It has a flying speed of 50 feet. It can carry up to 400 pounds, but its flying speed becomes 30 feet while carrying over 200 pounds. The broom stops hovering when you land.",
"You can send the broom to travel alone to a destination within 1 mile of you if you speak the command word, name the location, and are familiar with that place. The broom comes back to you when you speak another command word, provided that the broom is still within 1 mile of you."
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Bucket",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 2,
"value": 5,
"entries": [
"A bucket holds 3 gallons of liquid or ½ cubic foot of solids."
]
},
{
"name": "Bullseye Lantern",
"source": "PHB",
"page": 152,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 2,
"value": 1000,
"entries": [
"A bullseye lantern casts bright light in a 60-foot cone and dim light for an additional 60 feet. Once lit, it burns for 6 hours on a flask (1 pint) of oil."
]
},
{
"name": "Burglar's Pack",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 44.5,
"value": 1600,
"entries": [
"Includes:",
{
"type": "list",
"items": [
"a {@item backpack|phb}",
"a {@item Ball Bearings (Bag of 1,000)|phb|bag of 1,000 ball bearings}",
"10 feet of string",
"a {@item bell|phb}",
"5 {@item candle|phb|candles}",
"a {@item crowbar|phb}",
"a {@item hammer|phb}",
"10 {@item piton|phb|pitons}",
"a {@item hooded lantern|phb}",
"2 {@item Oil (flask)|phb|flasks of oil}",
"5 days {@item Rations (1 day)|phb|rations}",
"a {@item tinderbox|phb}",
"a {@item waterskin|phb}",
"{@item Hempen Rope (50 feet)|phb|50 feet of hempen rope}"
]
}
],
"packContents": [
"backpack|phb",
"ball bearings (bag of 1,000)|phb",
{
"special": "10 feet of string"
},
"bell|phb",
{
"item": "candle|phb",
"quantity": 5
},
"crowbar|phb",
"hammer|phb",
{
"item": "piton|phb",
"quantity": 10
},
"hooded lantern|phb",
{
"item": "oil (flask)|phb",
"quantity": 2
},
{
"item": "rations (1 day)|phb",
"quantity": 5
},
"tinderbox|phb",
"waterskin|phb",
"hempen rope (50 feet)|phb"
]
},
{
"name": "Burnt Othur Fumes",
"source": "DMG",
"page": 258,
"srd": true,
"type": "G",
"rarity": "none",
"value": 50000,
"poison": true,
"entries": [
"A creature subjected to this poison must succeed on a DC 13 Constitution saving throw or take 10 ({@damage 3d6}) poison damage, and must repeat the saving throw at the start of each of its turns. On each successive failed save, the character takes 3 ({@damage 1d6}) poison damage. After three successful saves, the poison ends."
],
"poisonTypes": [
"inhaled"
]
},
{
"name": "Butcher's Bib",
"source": "EGW",
"page": 266,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"This black leather apron is perpetually covered by blood, even after being washed off. You gain the following benefits while wearing the apron:",
{
"type": "list",
"items": [
"Once per turn when you roll damage for a melee attack with a weapon, you can reroll the weapon's damage dice. If you do so, you must use the second total.",
"Your weapon attacks that deal slashing damage score a critical hit on a roll of 19 or 20 on the {@dice d20}."
]
}
],
"hasFluffImages": true
},
{
"name": "Calligrapher's Supplies",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"additionalSources": [
{
"source": "XGE",
"page": 79
}
],
"type": "AT",
"rarity": "none",
"weight": 5,
"value": 1000,
"additionalEntries": [
"Calligraphy treats writing as a delicate, beautiful art. Calligraphers produce text that is pleasing to the eye, using a style that is difficult to forge. Their supplies also give them some ability to examine scripts and determine if they are legitimate, since a calligrapher's training involves long hours of studying writing and attempting to replicate its style and design.",
{
"type": "entries",
"name": "Components",
"entries": [
"Calligrapher's supplies include ink, a dozen sheets of parchment, and three quills."
]
},
{
"type": "entries",
"name": "Arcana",
"entries": [
"Although calligraphy is of little help in deciphering the content of magical writings, proficiency with these supplies can aid in identifying who wrote a script of a magical nature."
]
},
{
"type": "entries",
"name": "History",
"entries": [
"This tool proficiency can augment the benefit of successful checks made to analyze or investigate ancient writings, scrolls, or other texts, including runes etched in stone or messages in frescoes or other displays."
]
},
{
"type": "entries",
"name": "Decipher Treasure Map",
"entries": [
"This tool proficiency grants you expertise in examining maps. You can make an Intelligence check to determine a map's age, whether a map includes any hidden messages, or similar facts."
]
},
{
"type": "table",
"caption": "Calligrapher's Supplies",
"colLabels": [
"Activity",
"DC"
],
"colStyles": [
"col-6",
"col-6"
],
"rows": [
[
"Identify writer of nonmagical script",
"10"
],
[
"Determine writer's state of mind",
"15"
],
[
"Spot forged text",
"15"
],
[
"Forge a signature",
"20"
]
]
}
]
},
{
"name": "Caltrop",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 0.1,
"value": 5,
"entries": [
"As an action, you can spread a single bag of caltrops to cover a 5-foot-square area. Any creature that enters the area must succeed on a DC 15 Dexterity saving throw or stop moving and take 1 piercing damage. Until the creature regains at least 1 hit point, its walking speed is reduced by 10 feet. A creature moving through the area at half speed doesn't need to make the saving throw."
]
},
{
"name": "Caltrops (bag of 20)",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 2,
"value": 100,
"entries": [
"As an action, you can spread a single bag of caltrops to cover a 5-foot-square area. Any creature that enters the area must succeed on a DC 15 Dexterity saving throw or stop moving and take 1 piercing damage. Until the creature regains at least 1 hit point, its walking speed is reduced by 10 feet. A creature moving through the area at half speed doesn't need to make the saving throw."
],
"packContents": [
{
"item": "caltrop|phb",
"quantity": 20
}
],
"atomicPackContents": true
},
{
"name": "Camel",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "MNT",
"rarity": "none",
"value": 5000,
"carryingCapacity": 480,
"speed": 50
},
{
"name": "Candle",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"value": 1,
"entries": [
"For 1 hour, a candle sheds bright light in a 5-foot radius and dim light for an additional 5 feet."
]
},
{
"name": "Candle Mace",
"source": "BGDIA",
"page": 39,
"baseItem": "mace|phb",
"type": "M",
"rarity": "unknown (magic)",
"weight": 4,
"weaponCategory": "simple",
"dmg1": "1d6",
"dmgType": "B",
"bonusWeapon": "+1",
"weapon": true,
"entries": [
"You have a +1 bonus to attack and damage rolls made with this magic weapon. The head of this mace sheds bright light in a 5-foot-radius and dim light for an additional 5 feet. When you wield this mace, you can extinguish or ignite its light as an action."
]
},
{
"name": "Candle of Invocation",
"source": "DMG",
"page": 157,
"srd": true,
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"This slender taper is dedicated to a deity and shares that deity's alignment. The candle's alignment can be detected with the {@spell detect evil and good} spell. The DM chooses the god and associated alignment or determines the alignment randomly.",
{
"type": "table",
"colLabels": [
"{@dice d20}",
"Alignment"
],
"colStyles": [
"col-1 text-center",
"col-11"
],
"rows": [
[
"1-2",
"Chaotic evil"
],
[
"3-4",
"Chaotic neutral"
],
[
"5-7",
"Chaotic good"
],
[
"8-9",
"Neutral evil"
],
[
"10-11",
"Neutral"
],
[
"12-13",
"Neutral good"
],
[
"14-15",
"Lawful evil"
],
[
"16-17",
"Lawful neutral"
],
[
"18-20",
"Lawful good"
]
]
},
"The candle's magic is activated when the candle is lit, which requires an action. After burning for 4 hours, the candle is destroyed. You can snuff it out early for use at a later time. Deduct the time it burned in increments of 1 minute from the candle's total burn time.",
"While lit, the candle sheds dim light in a 30-foot radius. Any creature within that light whose alignment matches that of the candle makes attack rolls, saving throws, and ability checks with advantage. In addition, a cleric or druid in the light whose alignment matches the candle's can cast 1st-level spells he or she has prepared without expending spell slots, though the spell's effect is as if cast with a 1st-level slot.",
"Alternatively, when you light the candle for the first time, you can cast the {@spell gate} spell with it. Doing so destroys the candle."
],
"attachedSpells": [
"gate"
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Candle of the Deep",
"source": "XGE",
"page": 136,
"tier": "minor",
"rarity": "common",
"wondrous": true,
"entries": [
"The flame of this candle is not extinguished when immersed in water. It gives off light and heat like a normal candle."
]
},
{
"name": "Canoe",
"source": "ToA",
"page": 31,
"type": "SHP",
"rarity": "none",
"weight": 100,
"value": 5000,
"crew": 1,
"vehAc": 11,
"vehHp": 50,
"vehSpeed": 2,
"capPassenger": 6,
"entries": [
"A canoe can be purchased in Port Nyanzaru for 50 gp. It holds up to six Medium creatures and has a maximum speed of 2 mph. It is otherwise identical to a {@item rowboat}."
]
},
{
"name": "Canvas (1 sq. yd.)",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TG",
"rarity": "none",
"value": 10
},
{
"name": "Cap of Water Breathing",
"source": "DMG",
"page": 157,
"tier": "minor",
"rarity": "uncommon",
"wondrous": true,
"entries": [
"While wearing this cap underwater, you can speak its command word as an action to create a bubble of air around your head. It allows you to breathe normally underwater. This bubble stays with you until you speak the command word again, the cap is removed, or you are no longer underwater."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table B"
]
},
{
"name": "Cape of the Mountebank",
"source": "DMG",
"page": 157,
"srd": true,
"tier": "major",
"rarity": "rare",
"wondrous": true,
"entries": [
"This cape smells faintly of brimstone. While wearing it, you can use it to cast the {@spell dimension door} spell as an action. This property of the cape can't be used again until the next dawn.",
"When you disappear, you leave behind a cloud of smoke, and you appear in a similar cloud of smoke at your destination. The smoke lightly obscures the space you left and the space you appear in, and it dissipates at the end of your next turn. A light or stronger wind disperses the smoke."
],
"attachedSpells": [
"dimension door"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Carnelian",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 5000,
"entries": [
"An opaque orange to red-brown gemstone."
]
},
{
"name": "Carpenter's Tools",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"additionalSources": [
{
"source": "XGE",
"page": 80
}
],
"type": "AT",
"rarity": "none",
"weight": 6,
"value": 800,
"additionalEntries": [
"Skill at carpentry enables a character to construct wooden structures. A carpenter can build a house, a shack, a wooden cabinet, or similar items.",
{
"type": "entries",
"name": "Components",
"entries": [
"Carpenter's tools include a saw, a hammer, nails, a hatchet, a square, a ruler, an adze, a plane, and a chisel."
]
},
{
"type": "entries",
"name": "History",
"entries": [
"This tool proficiency aids you in identifying the use and the origin of wooden buildings and other large wooden objects."
]
},
{
"type": "entries",
"name": "Investigation",
"entries": [
"You gain additional insight when inspecting areas within wooden structures, because you know tricks of construction that can conceal areas from discovery."
]
},
{
"type": "entries",
"name": "Perception",
"entries": [
"You can spot irregularities in wooden walls or floors, making it easier to find trap doors and secret passages."
]
},
{
"type": "entries",
"name": "Stealth",
"entries": [
"You can quickly assess the weak spots in a wooden floor, making it easier to avoid the places that creak and groan when they're stepped on."
]
},
{
"type": "entries",
"name": "Fortify",
"entries": [
"With 1 minute of work and raw materials, you can make a door or window harder to force open. Increase the DC needed to open it by 5."
]
},
{
"type": "entries",
"name": "Temporary Shelter",
"entries": [
"As part of a long rest, you can construct a lean-to or a similar shelter to keep your group dry and in the shade for the duration of the rest. Because it was fashioned quickly from whatever wood was available, the shelter collapses {@dice 1d3} days after being assembled."
]
},
{
"type": "table",
"caption": "Carpenter's Tools",
"colLabels": [
"Activity",
"DC"
],
"colStyles": [
"col-10",
"col-2 text-center"
],
"rows": [
[
"Build a simple wooden structure",
"10"
],
[
"Design a complex wooden structure",
"15"
],
[
"Find a weak point in a wooden wall",
"15"
],
[
"Pry apart a door",
"20"
]
]
}
]
},
{
"name": "Carpet of Flying, 3 ft. × 5 ft.",
"source": "DMG",
"page": 157,
"srd": true,
"tier": "major",
"rarity": "very rare",
"wondrous": true,
"entries": [
"You can speak the carpet's command word as an action to make the carpet hover and fly. It moves according to your spoken directions, provided that you are within 30 feet of it.",
"A 3 ft. × 5 ft. carpet can carry up to 200 lb. at a fly speed of 80 feet. A carpet can carry up to twice this weight, but it flies at half speed if it carries more than its normal capacity."
]
},
{
"name": "Carpet of Flying, 4 ft. × 6 ft.",
"source": "DMG",
"page": 157,
"srd": true,
"tier": "major",
"rarity": "very rare",
"wondrous": true,
"entries": [
"You can speak the carpet's command word as an action to make the carpet hover and fly. It moves according to your spoken directions, provided that you are within 30 feet of it.",
"A 4 ft. × 6 ft. carpet can carry up to 400 lb. at a fly speed of 60 feet. A carpet can carry up to twice this weight, but it flies at half speed if it carries more than its normal capacity."
]
},
{
"name": "Carpet of Flying, 5 ft. × 7 ft.",
"source": "DMG",
"page": 157,
"srd": true,
"tier": "major",
"rarity": "very rare",
"wondrous": true,
"entries": [
"You can speak the carpet's command word as an action to make the carpet hover and fly. It moves according to your spoken directions, provided that you are within 30 feet of it.",
"A 5 ft. × 7 ft. carpet can carry up to 600 lb. at a fly speed of 40 feet. A carpet can carry up to twice this weight, but it flies at half speed if it carries more than its normal capacity."
]
},
{
"name": "Carpet of Flying, 6 ft. × 9 ft.",
"source": "DMG",
"page": 157,
"srd": true,
"tier": "major",
"rarity": "very rare",
"wondrous": true,
"entries": [
"You can speak the carpet's command word as an action to make the carpet hover and fly. It moves according to your spoken directions, provided that you are within 30 feet of it.",
"A 6 ft. × 9 ft. carpet can carry up to 800 lb. at a fly speed of 30 feet. A carpet can carry up to twice this weight, but it flies at half speed if it carries more than its normal capacity."
]
},
{
"name": "Carriage",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "VEH",
"rarity": "none",
"weight": 600,
"value": 10000
},
{
"name": "Carrion Crawler Mucus",
"source": "DMG",
"page": 258,
"srd": "Crawler Mucus (Contact)",
"type": "G",
"rarity": "none",
"value": 20000,
"poison": true,
"entries": [
"This poison must be harvested from a dead or {@condition incapacitated} {@creature carrion crawler}. A creature subjected to this poison must succeed on a DC 13 Constitution saving throw or be {@condition poisoned} for 1 minute. The {@condition poisoned} creature is {@condition paralyzed}. The creature can repeat the saving throw at the end of each of its turns, ending the effect on itself on a success."
],
"poisonTypes": [
"contact"
]
},
{
"name": "Cart",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "VEH",
"rarity": "none",
"weight": 200,
"value": 1500
},
{
"name": "Cartographer's Map Case",
"source": "AI",
"page": 20,
"rarity": "common",
"wondrous": true,
"entries": [
{
"type": "entries",
"name": "Map of Shortcuts",
"entries": [
"At rank 3, your map case becomes a common magic item. In addition to storing normal maps, your cartographer's map case can be used to generate a special map identifying a shortcut. You can use your action to make a DC 15 Wisdom ({@skill Perception}) check, with a success revealing a map buried in your cartographer's map case noting a relevant shortcut. Your travel time is reduced by half while you follow that route. If you succeed at the check by 5 or more, the map includes notes on the terrain, granting you advantage on the next ability check you make to travel through the mapped area in the next hour. Once you use this feature, you cannot use it again until you finish a long rest."
]
},
{
"type": "entries",
"name": "Map of the Moment",
"entries": [
"Starting at rank 3, you can use an action to make a DC 15 Wisdom ({@skill Perception}) check and search your cartographer's map case to find a map either related to your current mission or inspiring a new one. On a success, you find a map with a prominent landmark. The map has information on the natural terrain within one mile of the landmark. Once you use this feature, you cannot use it again until dawn seven days later.",
{
"type": "table",
"caption": "Possible Map Mission Landmarks",
"colLabels": [
"d8",
"Landmark"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"1",
"The Ancient Sarcophagus of Gerald Smith"
],
[
"2",
"A tree labeled \"This tree\""
],
[
"3",
"\"The Last Resting Place of My Rich Brother that is Also a Bear Cave\""
],
[
"4",
"A range of peaks known as the Slightly Wobbly Spires"
],
[
"5",
"An ever-flowing, nonmagical spigot of pure, clear water in the middle of nowhere"
],
[
"6",
"A cave filled with screaming bioluminescent worms"
],
[
"7",
"The most haunted forest for ten miles in any direction"
],
[
"8",
"A huge pile of rocks with no other rocks within sight"
]
]
}
]
}
],
"hasFluffImages": true
},
{
"name": "Cartographer's Tools",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"additionalSources": [
{
"source": "XGE",
"page": 80
}
],
"type": "AT",
"rarity": "none",
"weight": 6,
"value": 1500,
"additionalEntries": [
"Using cartographer's tools, you can create accurate maps to make travel easier for yourself and those who come after you. These maps can range from large-scale depictions of mountain ranges to diagrams that show the layout of a dungeon level.",
{
"type": "entries",
"name": "Components",
"entries": [
"Cartographer's tools consist of a quill, ink, parchment, a pair of compasses, calipers, and a ruler."
]
},
{
"type": "entries",
"name": "Arcana, History, Religion",
"entries": [
"You can use your knowledge of maps and locations to unearth more detailed information when you use these skills. For instance, you might spot hidden messages in a map, identify when the map was made to determine if geographical features have changed since then, and so forth."
]
},
{
"type": "entries",
"name": "Nature",
"entries": [
"Your familiarity with physical geography makes it easier for you to answer questions or solve issues relating to the terrain around you."
]
},
{
"type": "entries",
"name": "Survival",
"entries": [
"Your understanding of geography makes it easier to find paths to civilization, to predict areas where villages or towns might be found, and to avoid becoming lost. You have studied so many maps that common patterns, such as how trade routes evolve and where settlements arise in relation to geographic locations, are familiar to you."
]
},
{
"type": "entries",
"name": "Craft a Map",
"entries": [
"While traveling, you can draw a map as you go in addition to engaging in other activity."
]
},
{
"type": "table",
"caption": "Cartographer's Tools",
"colLabels": [
"Activity",
"DC"
],
"colStyles": [
"col-10",
"col-2 text-center"
],
"rows": [
[
"Determine a map's age and origin",
"10"
],
[
"Estimate direction and distance to a landmark",
"15"
],
[
"Discern that a map is fake",
"15"
],
[
"Fill in a missing part of a map",
"20"
]
]
}
]
},
{
"name": "Carved bone statuette",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 2500
},
{
"name": "Carved harp of exotic wood with ivory inlay and zircon gems",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 75000
},
{
"name": "Carved ivory statuette",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 25000
},
{
"name": "Carved jade statuette (River Heralds)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 25000
},
{
"name": "Catapult Munition",
"source": "SCC",
"page": 174,
"type": "G",
"rarity": "unknown",
"entries": [
"A catapult munition roughly the size and weight of a cannonball.",
"The munition can be thrown up to 30 feet and explodes on impact. Any creature within a 15-foot-radius sphere centered on the point of impact must make a DC 14 Dexterity saving throw, taking 35 ({@damage 10d6}) fire damage on a failed save, or half as much damage on a successful one. In addition, each object in that area that isn't being worn or carried takes 35 ({@damage 10d6}) fire damage."
]
},
{
"name": "Cauldron of Plenty",
"source": "IDRotF",
"page": 314,
"rarity": "rare",
"wondrous": true,
"weight": 50,
"entries": [
"This cauldron is made of thick copper that has turned green with age. It is 4 feet wide, has a mouth 3½ feet in diameter, weighs 50 pounds, and can hold up to 30 gallons of liquid. Embossed on its bulging sides are images of satyrs and nymphs in repose, holding ladles. The cauldron comes with a lid and has side handles. It sits on five little clawed feet that keep it from tipping.",
"If water is poured into the cauldron and stirred for 1 minute, it transforms into a hearty, hot stew, which can provide one nourishing meal for up to four people per gallon. The stew remains hot while in the cauldron, then cools naturally after it is removed. The outside of the cauldron remains safe to touch despite the heat of the stew.",
"The cauldron can create stew three times. It then ceases to function until the next dawn, when it regains all its uses."
],
"miscTags": [
"CF/W"
]
},
{
"name": "Cauldron of Rebirth",
"source": "TCE",
"page": 122,
"rarity": "very rare",
"reqAttune": "by a druid or warlock",
"reqAttuneTags": [
{
"class": "druid"
},
{
"class": "warlock"
}
],
"wondrous": true,
"entries": [
"This Tiny pot bears relief scenes of heroes on its cast iron sides. You can use the cauldron as a spellcasting focus for your spells, and it functions as a suitable component for the {@spell scrying} spell. When you finish a long rest, you can use the cauldron to create a {@item potion of greater healing}. The potion lasts for 24 hours, then loses its magic if not consumed.",
"As an action, you can cause the cauldron to grow large enough for a Medium creature to crouch within. You can revert the cauldron to its normal size as an action, harmlessly shunting anything that can't fit inside to the nearest unoccupied space.",
"If you place the corpse of a humanoid into the cauldron and cover the corpse with 200 pounds of salt (which costs 10 gp) for at least 8 hours, the salt is consumed and the creature returns to life as if by {@spell raise dead} at the next dawn. Once used, this property can't be used again for 7 days."
],
"attachedSpells": [
"raise dead",
"scrying"
],
"hasFluffImages": true
},
{
"name": "Censer of Controlling Air Elementals",
"source": "DMG",
"page": 158,
"srd": true,
"tier": "major",
"rarity": "rare",
"wondrous": true,
"weight": 1,
"entries": [
"While incense is burning in this censer, you can use an action to speak the censer's command word and summon an {@creature air elemental}, as if you had cast the {@spell conjure elemental} spell. The censer can't be used this way again until the next dawn.",
"This 6-inch-wide, 1-foot-high vessel resembles a chalice with a decorated lid. It weighs 1 pound."
],
"attachedSpells": [
"conjure elemental"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Ceremonial electrum dagger with a black pearl in the pommel",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 75000
},
{
"name": "Ceremonial silver dagger with gold pommel and black pearl (Legion of Dusk)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 75000
},
{
"name": "Chain (10 feet)",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 10,
"value": 500,
"entries": [
"A chain has 10 hit points. It can be burst with a successful DC 20 Strength check."
]
},
{
"name": "Chalcedony",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 5000,
"entries": [
"An opaque white gemstone."
]
},
{
"name": "Chalk (1 piece)",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"value": 1
},
{
"name": "Chariot",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"additionalSources": [
{
"source": "MOT",
"page": 196
}
],
"type": "VEH",
"rarity": "none",
"weight": 100,
"value": 25000,
"additionalEntries": [
{
"type": "inset",
"name": "Chariots",
"source": "MOT",
"page": 196,
"entries": [
"Chariots and the creatures pulling them work like controlled mounts, as described in the mounted combat rules in the {@book Player's Handbook|PHB|9|Mounted Combat}, but with the following differences:",
{
"type": "list",
"items": [
"Mounting or dismounting a chariot costs you 5 feet of movement, rather than a number of feet equal to half your speed.",
"Being mounted on a chariot grants you half cover.",
"A chariot's speed is equal to the speed of the slowest creature pulling it.",
"If multiple creatures are pulling the chariot, they all act on the same initiative, and they must take the same action on their turn."
]
}
]
}
]
},
{
"name": "Charlatan's Die",
"source": "XGE",
"page": 136,
"tier": "minor",
"rarity": "common",
"reqAttune": true,
"wondrous": true,
"entries": [
"Whenever you roll this six—sided die, you can control which number it rolls."
]
},
{
"name": "Charm of Plant Command",
"source": "GoS",
"page": 229,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": 3,
"charges": 3,
"entries": [
"This fist-sized charm is made from a bundle of dried plant stems wrapped in silver thread. Hung on a leather thong, it is typically worn around the neck or attached to a belt.",
"This charm has 3 charges. While you bear the charm, you can expend 1 charge as an action to cast the {@spell speak with plants} spell. For the duration of the spell, you also have advantage on Charisma checks made to influence the behavior, demeanor, and attitude of plants. The charm regains all expended charges at dawn each day."
],
"attachedSpells": [
"speak with plants"
]
},
{
"name": "Charred Wand of Magic Missiles",
"source": "WDH",
"page": 77,
"_copy": {
"name": "Wand of Magic Missiles",
"source": "DMG",
"_mod": {
"entries": {
"mode": "appendArr",
"items": "Each time you expend a charge from this wand, there is a {@chance 50|50 percent} chance that nothing happens and the charge is wasted."
}
}
}
},
{
"name": "Chest",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 25,
"value": 500,
"entries": [
"A chest holds 12 cubic feet or 300 pounds of gear."
],
"containerCapacity": {
"weight": [
300
]
}
},
{
"name": "Chest of Preserving",
"source": "WDMM",
"page": 139,
"rarity": "common",
"wondrous": true,
"weight": 25,
"entries": [
"Food and other perishable items do not age or decay while inside a Chest of Preserving. The chest is 2½ feet long, 1½ feet wide, and 1 foot tall with a half-barrel lid. The chest has a lock, which can be picked with {@item thieves' tools|PHB} and a successful DC 15 Dexterity check. Smashing the lock or any other part of the chest renders it nonmagical."
]
},
{
"name": "Chicken",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TG",
"rarity": "none",
"value": 2
},
{
"name": "Chime of Opening",
"source": "DMG",
"page": 158,
"srd": true,
"tier": "minor",
"rarity": "rare",
"wondrous": true,
"weight": 1,
"entries": [
"This hollow metal tube measures about 1 foot long and weighs 1 pound. You can strike it as an action, pointing it at an object within 120 feet of you that can be opened, such as a door, lid, or lock. The chime issues a clear tone, and one lock or latch on the object opens unless the sound can't reach the object. If no locks or latches remain, the object itself opens.",
"The chime can be used ten times. After the tenth time it cracks and becomes useless."
],
"lootTables": [
"Magic Item Table C"
],
"hasFluffImages": true
},
{
"name": "Chip of Creation",
"source": "AitFR-AVT",
"page": 11,
"otherSources": [
{
"source": "AitFR-FCD",
"page": 13
}
],
"rarity": "artifact",
"reqAttune": true,
"wondrous": true,
"entries": [
"This solid, hand-sized flake of rock is a minor piece of the {@item Stone of Creation|AitFR-AVT} and contains a portion of its power.",
{
"type": "entries",
"name": "Linked Room",
"entries": [
"Each chip of creation is inextricably linked to its own demiplanar room, as per the {@spell demiplane} spell. This room exists as long as both this chip and the Stone of Creation do. A door to the room only exists when created using a {@spell demiplane} spell, whether a chip of creation is used in the casting or not."
]
},
{
"type": "entries",
"name": "Demiplane",
"entries": [
"While attuned to a chip of creation, you can cast {@spell demiplane} at will but only to reach the demiplanar room associated with that chip of creation or another demiplane that you know the nature and contents of, and only if the target demiplane was created by another piece of the {@item Stone of Creation|AitFR-AVT}."
]
},
{
"type": "entries",
"name": "Destroying a Chip",
"entries": [
"A chip of creation is an artifact in its own right and impervious to most damage, but a {@spell disintegrate} spell is sufficient to destroy it. After it has been attuned once, a chip that has gone {@dice 1d4 + 1} days unattuned becomes an inert, powerless bit of rock.",
"If the {@item Stone of Creation|AitFR-AVT} is destroyed, all its chips break attunement, lose all magical properties, and linked demiplanar rooms cease to exist at once. Creatures or objects within the room are instantly expelled into the immediate vicinity of the destroyed chip.",
"If it reaches the Elemental Chaos or is struck by a forked, metal rod attuned to the Elemental Chaos (as per the {@spell plane shift} spell), a chip is destroyed."
]
}
],
"attachedSpells": [
"demiplane"
]
},
{
"name": "Chronolometer",
"source": "AI",
"page": 220,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"bonusSavingThrow": "+1",
"entries": [
"While attuned to this device, you have a +1 bonus to Intelligence saving throws. The first time you attune to the chronolometer, you choose one language you don't know. You subsequently know that language while attuned to the device.",
{
"type": "entries",
"name": "Time Bandit",
"entries": [
"At the start of your turn, roll a {@dice d6} (no action required). On a 1–3, you slow down time, gaining an additional action on your turn and doubling your speed until the end of the turn. On a 4–6, you go forward in time to warn yourself of what is to come. The next time you fail a saving throw, attack roll, or ability check, you can reroll the check and take either result. Once you use this feature of the chronolometer, it cannot be used again until the next dawn."
]
},
{
"type": "entries",
"name": "Fate Swap",
"entries": [
"As a reaction when a creature you can see within 30 feet of you takes damage, that creature gains an additional action if it is the creature's turn, or can take an action immediately even though it isn't the creature's turn. Once you use this feature of the chronolometer, it cannot be used again until the next dawn."
]
},
{
"type": "entries",
"name": "Part of a Whole",
"entries": [
"While this component is not installed in the {@item Orrery of the Wanderer|AI}, its magic might function sporadically or with unpredictable side effects, as determined by the DM."
]
}
]
},
{
"name": "Chrysoberyl",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 10000,
"entries": [
"A transparent yellow-green to pale green gemstone."
]
},
{
"name": "Chrysoprase",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 5000,
"entries": [
"A translucent green gemstone."
]
},
{
"name": "Chunk of Meat",
"source": "PHB",
"page": 158,
"srd": true,
"basicRules": true,
"type": "FD",
"rarity": "none",
"value": 30
},
{
"name": "Cinnamon",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TG",
"rarity": "none",
"weight": 1,
"value": 200
},
{
"name": "Circlet of Blasting",
"source": "DMG",
"page": 158,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"wondrous": true,
"entries": [
"While wearing this circlet, you can use an action to cast the {@spell scorching ray} spell with it. When you make the spell's attacks, you do so with an attack bonus of {@hit 5}. The circlet can't be used this way again until the next dawn."
],
"attachedSpells": [
"scorching ray"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Circlet of Human Perfection",
"source": "WDMM",
"page": 30,
"type": "OTH",
"rarity": "uncommon",
"reqAttune": "by a humanoid",
"reqAttuneTags": [
{
"creatureType": "humanoid"
}
],
"entries": [
"The Circlet of Human Perfection transforms its attuned wearer into an attractive human of average height and weight. The circlet chooses the physical characteristics of the form, such as age, gender, skin color, hair color, and voice. Except for size, the wearer's statistics and racial traits don't change, nor do items worn or carried by the wearer. Removing the circlet ends the effect."
]
},
{
"name": "Citrine",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 5000,
"entries": [
"A transparent pale yellow-brown gemstone."
]
},
{
"name": "Claw of the Wyrm Rune",
"source": "SKT",
"page": 233,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"This dragon's claw has been covered with a coat of molten silver, upon which has been inscribed the wyrm (dragon) rune. The claw has the following properties.",
{
"name": "Wyrmslayer",
"type": "entries",
"entries": [
"As an action, you can point the claw at a dragon within 30 feet of you. The dragon must then succeed on a DC 15 Constitution saving throw or gain vulnerability to all damage types until the end of your next turn. This property can be used three times. The claw regains all expended uses at the next dawn."
]
},
{
"name": "Wyrm Shield",
"type": "entries",
"entries": [
"While the claw is displayed on your person, you have resistance to the damage caused by any dragon's breath weapon."
]
},
{
"name": "Wyrm Ward",
"type": "entries",
"entries": [
"You can transfer the claw's magic to a place by tracing the wyrm rune on the ground with your finger. The point where you trace it becomes the center of a spherical area of magic that has a 100-foot radius and that is fixed to the place. The transfer takes 8 hours of work that requires the claw to be within 5 feet of you. At the end, the claw is destroyed, and the area gains the following property:",
"While in the 100-foot-radius sphere, any dragon has disadvantage on saving throws and can have a flying speed no higher than 10 feet."
]
}
]
},
{
"name": "Claws of the Umber Hulk",
"source": "PotA",
"page": 222,
"otherSources": [
{
"source": "IMR",
"page": 94
}
],
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"grantsProficiency": true,
"modifySpeed": {
"static": {
"burrow": 20
}
},
"entries": [
"These heavy gauntlets of brown iron are forged in the shape of an {@creature umber hulk||umber hulk's} claws, and they fit the wearer's hands and forearms all the way up to the elbow. While wearing both claws, you gain a burrowing speed of 20 feet, and you can tunnel through solid rock at a rate of 1 foot per round.",
"You can use a claw as a melee weapon while wearing it. You have proficiency with it, and it deals {@damage 1d8} slashing damage on a hit (your Strength modifier applies to the attack and damage rolls, as normal).",
"While wearing the claws, you can't manipulate objects or cast spells with somatic components."
]
},
{
"name": "Cleansing Stone",
"source": "ERLW",
"page": 276,
"otherSources": [
{
"source": "UAWGE",
"page": 115
}
],
"rarity": "common",
"wondrous": true,
"entries": [
"A cleansing stone is a sphere 1 foot in diameter, engraved with mystic sigils. When touching the stone, you can use an action to activate it and remove dirt and grime from your garments and your person.",
"Such stones are often embedded in pedestals in public squares in Aundair or in high-end Ghallanda inns."
]
},
{
"name": "Climber's Kit",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 12,
"value": 2500,
"entries": [
"A climber's kit includes special pitons, boot tips, gloves, and a harness. You can use the climber's kit as an action to anchor yourself; when you do, you can't fall more than 25 feet from the point where you anchored yourself, and you can't climb more than 25 feet away from that point without undoing the anchor."
]
},
{
"name": "Cloak of Arachnida",
"source": "DMG",
"page": 158,
"srd": true,
"resist": [
"poison"
],
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"modifySpeed": {
"equal": {
"climb": "walk"
}
},
"entries": [
"This fine garment is made of black silk interwoven with faint silvery threads. While wearing it, you gain the following benefits:",
{
"type": "list",
"items": [
"You have resistance to poison damage.",
"You have a climbing speed equal to your walking speed.",
"You can move up, down, and across vertical surfaces and upside down along ceilings, while leaving your hands free.",
"You can't be caught in webs of any sort and can move through webs as if they were {@quickref difficult terrain||3}.",
"You can use an action to cast the {@spell web} spell (save DC 13). The web created by the spell fills twice its normal area. Once used, this property of the cloak can't be used again until the next dawn."
]
}
],
"attachedSpells": [
"web"
],
"lootTables": [
"Magic Item Table H"
]
},
{
"name": "Cloak of Billowing",
"source": "XGE",
"page": 136,
"tier": "minor",
"rarity": "common",
"wondrous": true,
"entries": [
"While wearing this cloak, you can use a bonus action to make it billow dramatically."
]
},
{
"name": "Cloak of Displacement",
"source": "DMG",
"page": 158,
"srd": true,
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"While you wear this cloak, it projects an illusion that makes you appear to be standing in a place near your actual location, causing any creature to have disadvantage on attack rolls against you. If you take damage, the property ceases to function until the start of your next turn. This property is suppressed while you are {@condition incapacitated}, {@condition restrained}, or otherwise unable to move."
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Cloak of Elvenkind",
"source": "DMG",
"page": 158,
"srd": true,
"basicRules": true,
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"entries": [
"While you wear this cloak with its hood up, Wisdom ({@skill Perception}) checks made to see you have disadvantage, and you have advantage on Dexterity ({@skill Stealth}) checks made to hide, as the cloak's color shifts to camouflage you. Pulling the hood up or down requires an action."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Cloak of Invisibility",
"source": "DMG",
"page": 158,
"tier": "major",
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"entries": [
"While wearing this cloak, you can pull its hood over your head to cause yourself to become {@condition invisible}. While you are {@condition invisible}, anything you are carrying or wearing is {@condition invisible} with you. You become visible when you cease wearing the hood. Pulling the hood up or down requires an action.",
"Deduct the time you are invisible, in increments of 1 minute, from the cloak's maximum duration of 2 hours. After 2 hours of use, the cloak ceases to function. For every uninterrupted period of 12 hours the cloak goes unused, it regains 1 hour of duration."
],
"lootTables": [
"Magic Item Table I"
],
"hasFluffImages": true
},
{
"name": "Cloak of Many Fashions",
"source": "XGE",
"page": 136,
"tier": "minor",
"rarity": "common",
"wondrous": true,
"entries": [
"While wearing this cloak, you can use a bonus action to change the style, color, and apparent quality of the garment. The cloak's weight doesn't change. Regardless of its appearance, the cloak can't be anything but a cloak. Although it can duplicate the appearance of other magic cloaks, it doesn't gain their magical properties."
],
"hasFluffImages": true
},
{
"name": "Cloak of Protection",
"source": "DMG",
"page": 159,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"bonusAc": "+1",
"bonusSavingThrow": "+1",
"entries": [
"You gain a +1 bonus to AC and saving throws while you wear this cloak."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Cloak of the Bat",
"source": "DMG",
"page": 159,
"srd": true,
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"While wearing this cloak, you have advantage on Dexterity ({@skill Stealth}) checks. In an area of dim light or darkness, you can grip the edges of the cloak with both hands and use it to fly at a speed of 40 feet. If you ever fail to grip the cloak's edges while flying in this way, or if you are no longer in dim light or darkness, you lose this flying speed.",
"While wearing the cloak in an area of dim light or darkness, you can use your action to cast {@spell polymorph} on yourself, transforming into a {@creature bat}. While you are in the form of the {@creature bat}, you retain your Intelligence, Wisdom, and Charisma scores. The cloak can't be used this way again until the next dawn."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"attachedSpells": [
"polymorph"
],
"lootTables": [
"Magic Item Table G"
]
},
{
"name": "Cloak of the Manta Ray",
"source": "DMG",
"page": 159,
"srd": true,
"tier": "minor",
"rarity": "uncommon",
"wondrous": true,
"modifySpeed": {
"static": {
"swim": 60
}
},
"entries": [
"While wearing this cloak with its hood up, you can breathe underwater, and you have a swimming speed of 60 feet. Pulling the hood up or down requires an action."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table B"
],
"hasFluffImages": true
},
{
"name": "Clockwork Amulet",
"source": "XGE",
"page": 137,
"tier": "minor",
"rarity": "common",
"wondrous": true,
"entries": [
"This copper amulet contains tiny interlocking gears and is powered by magic from Mechanus, a plane of clockwork predictability. A creature that puts an ear to the amulet can hear faint ticking and whirring noises coming from within.",
"When you make an attack roll while wearing the amulet, you can forgo rolling the {@dice d20} to get a 10 on the die. Once used, this property can't be used again until the next dawn."
]
},
{
"name": "Clockwork Dog",
"source": "SKT",
"page": 44,
"type": "OTH",
"rarity": "unknown (magic)",
"entries": [
"This Tiny clockwork dog made of copper and tin comes with a copper wind-up key. As an action, you can use the key to wind the dog, after which it follows you for 12 hours. At the end of that duration, the clockwork dog stops until wound again. The dog has AC 5, 1 hit point, and a walking speed of 30 feet."
]
},
{
"name": "Cloth-of-gold vestments",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 2500
},
{
"name": "Cloth-of-gold vestments (Legion of Dusk)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 2500
},
{
"name": "Clothes of Mending",
"source": "XGE",
"page": 137,
"tier": "minor",
"rarity": "common",
"wondrous": true,
"entries": [
"This elegant outfit of traveler's clothes magically mends itself to counteract daily wear and tear. Pieces of the outfit that are destroyed can't be repaired in this way."
]
},
{
"name": "Clothing, cold weather",
"source": "IDRotF",
"page": 20,
"type": "G",
"rarity": "none",
"weight": 5,
"value": 1000,
"entries": [
"This outfit consists of a heavy fur coat or cloak over layers of wool clothing, as well as a fur-lined hat or hood, goggles, and fur-lined leather boots and gloves.",
"As long as cold weather clothing remains dry, its wearer automatically succeeds on saving throws against the effects of {@hazard extreme cold}."
]
},
{
"name": "Cloves",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TG",
"rarity": "none",
"weight": 1,
"value": 300
},
{
"name": "Cobbler's Tools",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"additionalSources": [
{
"source": "XGE",
"page": 80
}
],
"type": "AT",
"rarity": "none",
"weight": 5,
"value": 500,
"additionalEntries": [
"Although the cobbler's trade might seem too humble for an adventurer, a good pair of boots will see a character across rugged wilderness and through deadly dungeons.",
{
"type": "entries",
"name": "Components",
"entries": [
"Cobbler's tools consist of a hammer, an awl, a knife, a shoe stand, a cutter, spare leather, and thread."
]
},
{
"type": "entries",
"name": "Arcana, History",
"entries": [
"Your knowledge of shoes aids you in identifying the magical properties of enchanted boots or the history of such items."
]
},
{
"type": "entries",
"name": "Investigation",
"entries": [
"Footwear holds a surprising number of secrets. You can learn where someone has recently visited by examining the wear and the dirt that has accumulated on their shoes. Your experience in repairing shoes makes it easier for you to identify where damage might come from."
]
},
{
"type": "entries",
"name": "Maintain Shoes",
"entries": [
"As part of a long rest, you can repair your companions' shoes. For the next 24 hours, up to six creatures of your choice who wear shoes you worked on can travel up to 10 hours a day without making saving throws to avoid {@condition exhaustion}."
]
},
{
"type": "entries",
"name": "Craft Hidden Compartment",
"entries": [
"With 8 hours of work, you can add a hidden compartment to a pair of shoes. The compartment can hold an object up to 3 inches long and 1 inch wide and deep. You make an Intelligence check using your tool proficiency to determine the Intelligence ({@skill Investigation}) check DC needed to find the compartment."
]
},
{
"type": "table",
"caption": "Cobbler's Tools",
"colLabels": [
"Activity",
"DC"
],
"colStyles": [
"col-10",
"col-2 text-center"
],
"rows": [
[
"Determine a shoe's age and origin",
"10"
],
[
"Find a hidden compartment in a boot heel",
"15"
]
]
}
]
},
{
"name": "Coiling Grasp Tattoo",
"source": "TCE",
"page": 123,
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"entries": [
"Produced by a special needle, this magic tattoo has long intertwining designs.",
{
"type": "entries",
"name": "Tattoo Attunement",
"entries": [
"To attune to this item, you hold the needle to your skin where you want the tattoo to appear, pressing the needle there throughout the attunement process. When the attunement is complete, the needle turns into the ink that becomes the tattoo, which appears on the skin.",
"If your attunement to the tattoo ends, the tattoo vanishes, and the needle reappears in your space."
]
},
{
"type": "entries",
"name": "Grasping Tendrils",
"entries": [
"While the tattoo is on your skin, you can, as an action, cause the tattoo to extrude into inky tendrils, which reach for a creature you can see within 15 feet of you. The creature must succeed on a DC 14 Strength saving throw or take {@damage 3d6} force damage and be {@condition grappled} by you. As an action, the creature can escape the grapple by succeeding on a DC 14 Strength ({@skill Athletics}) or Dexterity ({@skill Acrobatics}) check. The grapple also ends if you halt it (no action required), if the creature is ever more than 15 feet away from you, or if you use this tattoo on a different creature."
]
}
]
},
{
"name": "Coiling Grasp Tattoo",
"source": "UA2020SpellsAndMagicTattoos",
"page": 9,
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"entries": [
"This tattoo has long intertwining designs. While the tattoo is on your skin, you can, as an action, cause the tattoo to extrude into inky tendrils, which reach for a creature you can see within 15 feet of you. The creature must succeed on a DC 14 Strength saving throw or take {@damage 3d6} force damage and be {@condition grappled} by you. As an action, the creature can escape the grapple by succeeding on a DC 14 Strength ({@skill Athletics}) or Dexterity ({@skill Acrobatics}) check. The grapple also ends if you halt it (no action required), if the creature is ever more than 15 feet away from you, or if you use this tattoo on a different creature.",
{
"type": "entries",
"name": "Tattoo Attunement",
"entries": [
"To attune to this item, you hold the needle to your skin where you want the tattoo to appear, pressing the needle there throughout the attunement process. When the attunement is complete, the needle turns into the ink that becomes the tattoo, which appears on the skin. If you have multiple magic tattoos, they count as a single magic item with regard to the number of magic items you can attune to.",
"If your attunement to the tattoo ends, the tattoo vanishes, and the needle reappears in the closest unoccupied space to you."
]
}
]
},
{
"name": "Coin of Decisionry",
"source": "AI",
"page": 22,
"rarity": "varies",
"wondrous": true,
"entries": [
"Starting at rank 2, your coin of decisionry becomes a common magic item. When you flip the coin, it always lands with the Acquisitions Incorporated sigil face down, and a message appears on the \"tails\" face. Roll a {@dice d4} on the following table to determine the message.",
{
"type": "table",
"colStyles": [
"col-2 text-center",
"col-10"
],
"colLabels": [
"d4",
"Decision"
],
"rows": [
[
"1",
"Lucrative"
],
[
"2",
"Brand Appeal"
],
[
"3",
"Indeterminate"
],
[
"4",
"Ruinous"
]
]
},
"The coin has absolutely no divination abilities, and its results when you use it are random. But nobody else knows that. When a creature within 10 feet of you flips the coin (after having had its powerful prognostication powers dutifully explained), you can exert your will to control its operation as a bonus action, choosing the result that appears after it lands as a means of gently coercing the user toward a specific course of action. The creature flipping the coin can detect your manipulation with a successful DC 13 Wisdom ({@skill Insight}) check.",
{
"type": "entries",
"name": "Better Odds",
"entries": [
"When you reach rank 3, your coin of decisionry gains a measure of actual divination power and becomes an uncommon magic item. In addition to its normal function, you can use an action to flip the coin of decisionry twice while pondering a specific plan or objective, noting both random results. If you succeed on a DC 15 Intelligence ({@skill Arcana}) check, you learn which of the two results is more applicable to the course of action. This property of the coin can't be used again until the next dawn."
]
}
],
"hasFluffImages": true
},
{
"name": "Coin of Delving",
"source": "EGW",
"page": 266,
"rarity": "common",
"wondrous": true,
"entries": [
"This scintillating copper coin sheds dim light in a 5-foot radius. If dropped a distance greater than 5 feet, the coin issues a melodious ringing sound when it hits a surface. Any creature that can hear the chime can determine the distance the coin dropped based on the tone."
]
},
{
"name": "Cold Absorbing Tattoo",
"source": "TCE",
"page": 119,
"resist": [
"cold"
],
"detail1": "blue",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"hasRefs": true,
"entries": [
"{#itemEntry Absorbing Tattoo|TCE}"
]
},
{
"name": "Common Clothes",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 3,
"value": 50
},
{
"name": "Common Glamerweave",
"source": "ERLW",
"page": 277,
"otherSources": [
{
"source": "UAWGE",
"page": 115
}
],
"rarity": "common",
"wondrous": true,
"entries": [
"Glamerweave is clothing imbued with harmless illusory magic. While wearing the common version of these clothes, you can use a bonus action to create a moving illusory pattern within the cloth."
]
},
{
"name": "Common Wine (Pitcher)",
"source": "PHB",
"page": 158,
"srd": true,
"basicRules": true,
"type": "FD",
"rarity": "none",
"value": 20
},
{
"name": "Component Pouch",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 2,
"value": 2500,
"entries": [
"A component pouch is a small, watertight leather belt pouch that has compartments to hold all the material components and other special items you need to cast your spells, except for those components that have a specific cost (as indicated in a spell's description)."
]
},
{
"name": "Concertina",
"source": "RMBRE",
"page": 26,
"rarity": "rare",
"wondrous": true,
"entries": [
"A concertina is a less charming kind of accordion.",
"In addition to knocking out a killer polka, a creature playing the concertina can use an action to cause an area of mist or water up to 30 feet in diameter and 6 inches deep to freeze solid.",
"Once used, this feature of the concertina can't be used again until the next dawn.",
"Any creatures touching the floor must succeed on a DC 20 Dexterity saving throw or be {@condition restrained} by the ice. As an action, a {@condition restrained} creature can attempt to free itself or another creature within its reach from the ice, doing so with a successful DC 20 Strength ({@skill Athletics}) check. A character freed from the ice is no longer {@condition restrained} by it. The ice melts in an hour."
]
},
{
"name": "Conch of Safe Rest",
"source": "TTP",
"page": 17,
"type": "OTH",
"rarity": "unknown (magic)",
"weight": 2,
"entries": [
"You can use an action to blow this pearlescent conch like a {@item horn|PHB}, creating an effect identical with that of a {@spell Leomund's tiny hut} spell. The conch disappears once the spell takes effect. The effect ends if you leave the spell's area."
]
},
{
"name": "Conch of Teleportation",
"source": "SKT",
"page": 234,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"This item is an ordinary, albeit rather large, conch shell that has been inscribed with the uvar rune. The conch measures 2½ feet long and weighs 20 pounds.",
"As an action, you can cast the {@spell teleport} spell by blowing into the shell. The destination is fixed, and there is no chance of either a mishap or the spell being off target. Anyone teleported by the conch appears in a specific location designated by the item's creator at the time the uvar rune is inscribed on the conch. It doesn't allow teleportation to any other destination. Once its spell is cast, the conch can't be used again until the next dawn."
],
"attachedSpells": [
"teleport"
],
"hasFluffImages": true
},
{
"name": "Cook's Utensils",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"additionalSources": [
{
"source": "XGE",
"page": 80
}
],
"type": "AT",
"rarity": "none",
"weight": 8,
"value": 100,
"additionalEntries": [
"Adventuring is a hard life. With a cook along on the journey, your meals will be much better than the typical mix of hardtack and dried fruit.",
{
"type": "entries",
"name": "Components",
"entries": [
"Cook's utensils include a metal pot, knives, forks, a stirring spoon, and a ladle."
]
},
{
"type": "entries",
"name": "History",
"entries": [
"Your knowledge of cooking techniques allows you to assess the social patterns involved in a culture's eating habits."
]
},
{
"type": "entries",
"name": "Medicine",
"entries": [
"When administering treatment, you can transform medicine that is bitter or sour into a pleasing concoction."
]
},
{
"type": "entries",
"name": "Survival",
"entries": [
"When foraging for food, you can make do with ingredients you scavenge that others would be unable to transform into nourishing meals."
]
},
{
"type": "entries",
"name": "Prepare Meals",
"entries": [
"As part of a short rest, you can prepare a tasty meal that helps your companions regain their strength. You and up to five creatures of your choice regain 1 extra hit point per Hit Die spent during a short rest, provided you have access to your cook's utensils and sufficient food."
]
},
{
"type": "table",
"caption": "Cook's Utensils",
"colLabels": [
"Activity",
"DC"
],
"colStyles": [
"col-10",
"col-2 text-center"
],
"rows": [
[
"Create a typical meal",
"10"
],
[
"Duplicate a meal",
"10"
],
[
"Spot poison or impurities in food",
"15"
],
[
"Create a gourmet meal",
"15"
]
]
}
]
},
{
"name": "Copper",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TG",
"rarity": "none",
"weight": 1,
"value": 50
},
{
"name": "Copper (cp)",
"source": "PHB",
"page": 143,
"type": "$",
"rarity": "none",
"weight": 0.02,
"value": 1,
"entries": [
"Common coins come in several different denominations based on the relative worth of the metal from which they are made. The three most common coins are the gold piece (gp), the silver piece (sp), and the copper piece (cp).",
"With one gold piece, a character can buy a belt pouch, 50 feet of good rope, or a goat. A skilled (but not exceptional) artisan can earn one gold piece a day. The gold piece is the standard unit of measure for wealth, even if the coin itself is not commonly used. When merchants discuss deals that involve goods or services worth hundreds or thousands of gold pieces, the transactions don't usually involve the exchange of individual coins. Rather, the gold piece is a standard measure of value, and the actual exchange is in gold bars, letters of credit, or valuable goods.",
"One gold piece is worth ten silver pieces, the most prevalent coin among commoners. A silver piece buys a laborer's work for a day, a flask of lamp oil, or a night's rest in a poor inn.",
"One silver piece is worth ten copper pieces, which are common among laborers and beggars. A single copper piece buys a candle, a torch, or a piece of chalk.",
"In addition, unusual coins made of other precious metals sometimes appear in treasure hoards. The electrum piece (ep) and the platinum piece (pp) originate from fallen empires and lost kingdoms, and they sometimes arouse suspicion and skepticism when used in transactions. An electrum piece is worth five silver pieces, and a platinum piece is worth ten gold pieces.",
"A standard coin weighs about a third of an ounce, so fifty coins weigh a pound."
],
"hasFluffImages": true
},
{
"name": "Copper Alms-Coin",
"source": "GGR",
"page": 9,
"type": "$",
"rarity": "none",
"value": 1
},
{
"name": "Copper chalice with silver filigree",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 2500
},
{
"name": "Copper Dragon Scale Mail",
"source": "DMG",
"page": 165,
"srd": true,
"type": "MA",
"resist": [
"acid"
],
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"weight": 45,
"ac": 14,
"bonusAc": "+1",
"bonusSavingThrow": "+1",
"stealth": true,
"entries": [
"Dragon scale mail is made of the scales of one kind of dragon. Sometimes dragons collect their cast-off scales and gift them to humanoids. Other times, hunters carefully skin and preserve the hide of a dead dragon. In either case, dragon scale mail is highly valued. While wearing this armor, you gain a +1 bonus to AC, you have advantage on saving throws against the Frightful Presence and breath weapons of dragons, and you have resistance to acid damage.",
"Additionally, you can focus your senses as an action to magically discern the distance and direction to the closest copper dragon within 30 miles of you. This special action can't be used again until the next dawn."
]
},
{
"name": "Copper stein with silver filigree (Brazen Coalition)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 2500
},
{
"name": "Copper Zib",
"source": "GGR",
"page": 9,
"type": "$",
"rarity": "none",
"value": 1
},
{
"name": "Coral",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 10000,
"entries": [
"An opaque crimson gemstone."
]
},
{
"name": "Costume Clothes",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 4,
"value": 500
},
{
"name": "Cotton Cloth (1 sq. yd.)",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TG",
"rarity": "none",
"value": 50
},
{
"name": "Cow",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TG",
"rarity": "none",
"value": 1000
},
{
"name": "Cracked Driftglobe",
"source": "CM",
"page": 110,
"rarity": "uncommon",
"wondrous": true,
"weight": 1,
"entries": [
"This small sphere of thick glass weighs 1 pound. If you are within 60 feet of it, you can speak its command word and cause it to emanate the {@spell light} spell."
],
"attachedSpells": [
"light"
]
},
{
"name": "Crampons",
"source": "IDRotF",
"page": 20,
"type": "G",
"rarity": "none",
"weight": 0.25,
"value": 200,
"entries": [
"A crampon is a metal plate with spikes that is strapped to the sole of a boot. A creature wearing crampons can't fall {@condition prone} while moving across {@hazard slippery ice}."
]
},
{
"name": "Crook of Rao",
"source": "TCE",
"page": 123,
"rarity": "artifact",
"reqAttune": true,
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6}",
"charges": 6,
"entries": [
"Ages ago, the serene god Rao created a tool to shield his fledgling faithful against the evils of the Lower Planes. Yet, as eons passed, mortals developed their own methods of dealing with existential threats, and the crook was largely forgotten. In recent ages, though, the Crook of Rao was rediscovered and leveraged against the rising power of the Witch Queen Iggwilv (one of the names of the wizard Tasha). Although she was defeated, Iggwilv managed to damage the crook during the battle, infecting it with an insidious curse-and the potential for future victory. In the aftermath, the crook was again lost. Occasionally it reappears, but the famed artifact is not what it was. Whether or not the artifact's bearers realize its full threat, few risk using the Crook of Rao -potentially for the final time.",
{
"type": "entries",
"name": "Random Properties",
"entries": [
"The artifact has the following random properties, which you can determine by rolling on the tables in the \"Artifacts\" section of the {@book Dungeon Master's Guide|DMG}:",
{
"type": "list",
"items": [
"2 {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} properties",
"1 {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial} property",
"1 {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental} property"
]
}
]
},
{
"type": "entries",
"name": "Spells",
"entries": [
"The crook has 6 charges. While holding it, you can use an action to expend 1 or more of its charges to cast one of the following spells (save DC 18) from it: {@spell aura of life} (2 charges), {@spell aura of purity} (2 charges), {@spell banishment} (1 charge), {@spell beacon of hope} (1 charge), {@spell mass cure wounds} (3 charges). The crook regains {@dice 1d6} expended charges daily at dawn."
]
},
{
"type": "entries",
"name": "Absolute Banishment",
"entries": [
"While you are attuned to the crook and holding it, you can spend 10 minutes to banish all but the mightiest fiends within 1 mile of you. Any fiend with a challenge rating of 19 or higher is unaffected. Each banished fiend is sent back to its home plane and can't return to the plane the Crook of Rao banished it from for 100 years."
]
},
{
"type": "entries",
"name": "Failing Matrix",
"entries": [
"Whenever the Crook of Rao's Absolute Banishment property is used, or when its last charge is expended, roll on the Extraplanar Reversal table. Any creatures conjured as a result of this effect appear in random unoccupied spaces within 60 feet of you and are not under your control."
]
},
{
"type": "entries",
"name": "Iggwilv's Curse",
"entries": [
"When the Crook was last used against Iggwilv, the Witch Queen lashed out at the artifact, infecting its magical matrix. Over the years, this curse has spread within the crook, threatening to violently pervert its ancient magic. If this occurs, the Crook of Rao, as it is currently known, is destroyed, its magical matrix inverting and exploding into a 50-foot-diameter portal. This portal functions as a permanent {@spell gate} spell cast by Iggwilv. The gate then, once per round on initiative count 20, audibly speaks a fiend's name in Iggwilv's voice, doing so until the gate calls on every fiend ever banished by the Crook of Rao. If the fiend still exists, it is drawn through the gate. This process takes eighteen years to complete, at the end of which the gate becomes a permanent portal to Pazunia, the first layer of the Abyss.",
{
"type": "table",
"caption": "Extraplanar Reversal",
"colLabels": [
"d100",
"Effect"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"1-25",
"A portal to a random plane opens. The portal closes after 5 minutes."
],
[
"26-45",
"{@dice 2d4} {@creature imp||imps} and {@dice 2d4} {@creature quasit||quasits} appear."
],
[
"46-60",
"{@dice 1d8} {@creature succubus||succubi}/{@creature incubus||incubi} appear."
],
[
"61-70",
"{@dice 1d10} {@creature barbed devil||barbed devils} and {@dice 1d10} {@creature vrock||vrocks} appear."
],
[
"71-80",
"1 {@creature arcanaloth}, 1 {@creature night hag}, and 1 {@creature rakshasa} appear."
],
[
"81-85",
"1 {@creature ice devil} and 1 {@creature marilith} appear."
],
[
"86-90",
"1 {@creature balor} and 1 {@creature pit fiend} appear. At the DM's discretion, a portal opens into the presence of an archdevil or demon lord instead, then closes after 5 minutes."
],
[
"91-00",
"Iggwilv's Curse (see the Iggwilv's Curse property)."
]
]
}
]
},
{
"type": "entries",
"name": "Destroying or Repairing the Crook",
"entries": [
"The Crook of Rao can either be destroyed or repaired by journeying to Mount Celestia and obtaining a tear from the eternally serene god Rao. One way to make the emotionless god cry would be to reunite Rao with the spirit of his first worshiper who sought revelations beyond the multiverse long ago. The Crook dissolves if immersed in the god's tear for a year and a day. If washed in the tear daily for 30 days, the Crook loses its Failing Matrix property."
]
}
],
"attachedSpells": [
"aura of life",
"aura of purity",
"banishment",
"beacon of hope",
"gate",
"mass cure wounds"
],
"hasFluffImages": true
},
{
"name": "Crossbow Bolt Case",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 1,
"value": 100,
"entries": [
"This wooden case can hold up to twenty {@item crossbow bolt|phb|crossbow bolts}."
],
"containerCapacity": {
"item": [
{
"crossbow bolt|phb": 20
}
]
}
},
{
"name": "Crowbar",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 5,
"value": 200,
"entries": [
"Using a crowbar grants advantage to Strength checks where the crowbar's leverage can be applied."
]
},
{
"name": "Crown of the Forest",
"source": "IMR",
"page": 7,
"rarity": "uncommon",
"wondrous": true,
"entries": [
"While wearing this crown, a character automatically fails any Intelligence ({@skill Investigation}) check made to see through an illusion. An illusion that is touched still has its true nature revealed. However, a character wearing a crown has such a strong sense that the illusion is real that they might not know which of their senses to believe.",
"If this crown is closely examined by a character who succeeds on a DC 20 Intelligence ({@skill Nature}) or Wisdom ({@skill Perception}) check, or with a {@spell detect magic} spell cast using a 3rd-level or higher spell slot, the nature of its magic is discovered."
]
},
{
"name": "Crystal Ball",
"source": "DMG",
"page": 159,
"srd": true,
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"weight": 3,
"entries": [
"This crystal ball is about 6 inches in diameter. While touching it, you can cast the {@spell scrying} spell (save DC 17) with it."
],
"attachedSpells": [
"scrying"
],
"lootTables": [
"Magic Item Table H"
]
},
{
"name": "Crystal Ball of Mind Reading",
"source": "DMG",
"page": 159,
"tier": "major",
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"weight": 3,
"entries": [
"This {@item crystal ball} is about 6 inches in diameter. While touching it, you can cast the {@spell scrying} spell (save DC 17) with it.",
"You can use an action to cast the {@spell detect thoughts} spell (save DC 17) while you are {@spell scrying} with the {@item crystal ball}, targeting creatures you can see within 30 feet of the spell's sensor. You don't need to concentrate on this {@spell detect thoughts} to maintain it during its duration, but it ends if {@spell scrying} ends."
],
"attachedSpells": [
"scrying",
"detect thoughts"
],
"lootTables": [
"Magic Item Table I"
]
},
{
"name": "Crystal Ball of Telepathy",
"source": "DMG",
"page": 159,
"tier": "major",
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"weight": 3,
"entries": [
"This {@item crystal ball} is about 6 inches in diameter. While touching it, you can cast the {@spell scrying} spell (save DC 17) with it.",
"While {@spell scrying} with the {@item crystal ball}, you can communicate telepathically with creatures you can see within 30 feet of the spell's sensor. You can also use an action to cast the {@spell suggestion} spell (save DC 17) through the sensor on one of those creatures. You don't need to concentrate on this suggestion to maintain it during its duration, but it ends if {@spell scrying} ends. Once used, the suggestion power of the {@item crystal ball} can't be used again until the next dawn."
],
"attachedSpells": [
"scrying",
"suggestion"
],
"lootTables": [
"Magic Item Table I"
]
},
{
"name": "Crystal Ball of True Seeing",
"source": "DMG",
"page": 159,
"tier": "major",
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"weight": 3,
"entries": [
"This {@item crystal ball} is about 6 inches in diameter. While touching it, you can cast the {@spell scrying} spell (save DC 17) with it.",
"While {@spell scrying} with the {@item crystal ball}, you have {@sense truesight} with a radius of 120 feet centered on the spell's sensor."
],
"attachedSpells": [
"scrying"
],
"lootTables": [
"Magic Item Table I"
]
},
{
"name": "Crystalline Chronicle",
"source": "TCE",
"page": 124,
"rarity": "very rare",
"reqAttune": "by a wizard",
"reqAttuneTags": [
{
"class": "wizard"
}
],
"wondrous": true,
"weight": 3,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"focus": [
"Wizard"
],
"entries": [
"An etched crystal sphere the size of a grapefruit hums faintly and pulses with irregular flares of inner light. While you are touching the crystal, you can retrieve and store information and spells within the crystal at the same rate as reading and writing. When found, the crystal contains the following spells: {@spell detect thoughts}, {@spell intellect fortress|TCE}, {@spell Rary's telepathic bond}, {@spell sending}, {@spell telekinesis}, {@spell Tasha's mind whip|TCE}, and {@spell Tenser's floating disk}. It functions as a spellbook for you, with its spells and other writing psychically encoded within it.",
"While you are holding the crystal, you can use it as a spellcasting focus for your wizard spells, and you know the {@spell mage hand}, {@spell mind sliver|TCE} (appears in this book), and {@spell message} cantrips if you don't already know them.",
"The crystal has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. You can use the charges in the following ways while holding it:",
{
"type": "list",
"items": [
"If you spend 1 minute studying the information within the crystal, you can expend 1 charge to replace one of your prepared wizard spells with a different spell in the book.",
"When you cast a wizard spell, you can expend 1 charge to cast the spell without verbal, somatic, or material components of up to 100 gp value."
]
}
],
"attachedSpells": [
"detect thoughts",
"intellect fortress|tce",
"mage hand",
"message",
"mind sliver|tce",
"rary's telepathic bond",
"sending",
"tasha's mind whip|tce",
"telekinesis",
"tenser's floating disk"
],
"hasFluffImages": true
},
{
"name": "Cube of Force",
"source": "DMG",
"page": 159,
"srd": true,
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d20}",
"charges": 36,
"entries": [
"This cube is about an inch across. Each face has a distinct marking on it that can be pressed. The cube starts with 36 charges, and it regains {@dice 1d20} expended charges daily at dawn.",
"You can use an action to press one of the cube's faces, expending a number of charges based on the chosen face, as shown in the Cube of Force Faces table. Each face has a different effect. If the cube has insufficient charges remaining, nothing happens. Otherwise, a barrier of {@condition invisible} force springs into existence, forming a cube 15 feet on a side. The barrier is centered on you, moves with you, and lasts for 1 minute, until you use an action to press the cube's sixth face, or the cube runs out of charges. You can change the barrier's effect by pressing a different face of the cube and expending the requisite number of charges, resetting the duration. If your movement causes the barrier to come into contact with a solid object that can't pass through the cube, you can't move any closer to that object as long as the barrier remains.",
{
"type": "table",
"caption": "Cube of Force Faces",
"colLabels": [
"Face",
"Charges",
"Effect"
],
"colStyles": [
"col-1 text-center",
"col-1 text-center",
"col-10"
],
"rows": [
[
"1",
"1",
"Gases, wind, and fog can't pass through the barrier."
],
[
"2",
"2",
"Nonliving matter can't pass through the barrier. Walls, floors, and ceilings can pass through at your discretion."
],
[
"3",
"3",
"Living matter can't pass through the barrier."
],
[
"4",
"4",
"Spell effects can't pass through the barrier."
],
[
"5",
"5",
"Nothing can pass through the barrier. Walls, floors, and ceilings can pass through at your discretion."
],
[
"6",
"0",
"The barrier deactivates."
]
]
},
"The cube loses charges when the barrier is targeted by certain spells or comes into contact with certain spell or magic item effects, as shown in the table below.",
{
"type": "table",
"colLabels": [
"Spell or item",
"Charges Lost"
],
"colStyles": [
"col-3",
"col-9"
],
"rows": [
[
"{@spell Disintegrate}",
"{@dice 1d12}"
],
[
"{@item Horn of blasting}",
"{@dice 1d10}"
],
[
"{@spell Passwall}",
"{@dice 1d6}"
],
[
"{@spell Prismatic spray}",
"{@dice 1d20}"
],
[
"{@spell Wall of fire}",
"{@dice 1d4}"
]
]
}
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Cubic Gate",
"source": "DMG",
"page": 160,
"srd": true,
"tier": "major",
"rarity": "legendary",
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"This cube is 3 inches across and radiates palpable magical energy. The six sides of the cube are each keyed to a different plane of existence, one of which is the Material Plane. The other sides are linked to planes determined by the DM.",
"You can use an action to press one side of the cube to cast the {@spell gate} spell with it, opening a portal to the plane keyed to that side. Alternatively, if you use an action to press one side twice, you can cast the {@spell plane shift} spell (save DC 17) with the cube and transport the targets to the plane keyed to that side.",
"The cube has 3 charges. Each use of the cube expends 1 charge. The cube regains {@dice 1d3} expended charges daily at dawn."
],
"attachedSpells": [
"gate",
"plane shift"
],
"lootTables": [
"Magic Item Table I"
],
"hasFluffImages": true
},
{
"name": "Cuddly Strixhaven Mascot",
"source": "SCC",
"page": 38,
"rarity": "common",
"wondrous": true,
"entries": [
"Representing one of the mascots of Strixhaven, this soft, Tiny, magic toy is perfect for cuddling. If you press it to your arm, shoulder, or leg as an action, the toy stays attached there for 1 hour or until you use an action to remove it.",
"The toy can also be used to fight off fear. When you make a saving throw to avoid or end the {@condition frightened} condition on yourself, you can give yourself advantage on the roll if the toy is on your person. You must decide to do so before rolling the {@dice d20}. If the save succeeds, you can't use the toy in this way until you finish a long rest."
]
},
{
"name": "Cursed Luckstone",
"source": "GoS",
"page": 229,
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"curse": true,
"entries": [
"This flat, gray-and-black river stone is inscribed with an unknown arcane symbol and feels cool to the touch. While carrying the stone, you can gain advantage on one ability check of your choice. The stone can't be used this way again until the next dawn.",
{
"type": "entries",
"name": "Curse",
"entries": [
"This item is cursed. Attuning to it curses you until you are targeted by a {@spell remove curse} spell or similar magic. As long as you remain cursed, you cannot discard the stone, which immediately teleports back into your pocket or pack. After you use the stone's magic, your next two ability checks are made with disadvantage."
]
}
]
},
{
"name": "Daern's Instant Fortress",
"source": "DMG",
"page": 160,
"srd": "Instant Fortress",
"tier": "major",
"rarity": "rare",
"wondrous": true,
"entries": [
"You can use an action to place this 1-inch metal cube on the ground and speak its command word. The cube rapidly grows into a fortress that remains until you use an action to speak the command word that dismisses it, which works only if the fortress is empty.",
"The fortress is a square tower, 20 feet on a side and 30 feet high, with arrow slits on all sides and a battlement atop it. Its interior is divided into two floors. with a ladder running along one wall to connect them. The ladder ends at a trapdoor leading to the roof. When activated, the tower has a small door on the side facing you. The door opens only at your command, which you can speak as a bonus action. It is immune to the {@spell knock} spell and similar magic, such as that of a {@item chime of opening}.",
"Each creature in the area where the fortress appears must make a DC 15 Dexterity saving throw, taking {@damage 10d10} bludgeoning damage on a failed save, or half as much damage on a successful one. In either case, the creature is pushed to an unoccupied space outside but next to the fortress. Objects in the area that aren't being worn or carried take this damage and are pushed automatically.",
"The tower is made of adamantine, and its magic prevents it from being tipped over. The roof, the door, and the walls each have 100 hit points, immunity to damage from nonmagical weapons excluding siege weapons, and resistance to all other damage. Only a {@spell wish} spell can repair the fortress (this use of the spell counts as replicating a spell of 8th level or lower). Each casting of {@spell wish} causes the roof, the door, or one wall to regain 50 hit points."
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Dagger of Blindsight",
"source": "WDMM",
"page": 86,
"baseItem": "dagger|phb",
"type": "M",
"rarity": "rare",
"reqAttune": true,
"weight": 1,
"weaponCategory": "simple",
"property": [
"F",
"L",
"T"
],
"range": "20/60",
"dmg1": "1d4",
"dmgType": "P",
"entries": [
"This rare magic item requires attunement. A creature attuned to it gains {@sense blindsight} out to a range of 30 feet. The dagger has a saw-toothed edge and a black pearl nested in its pommel."
]
},
{
"name": "Dagger of Guitar Solos",
"source": "WDMM",
"page": 228,
"baseItem": "dagger|phb",
"type": "M",
"rarity": "unknown (magic)",
"weight": 1,
"value": 75000,
"weaponCategory": "simple",
"property": [
"F",
"L",
"T"
],
"range": "20/60",
"dmg1": "1d4",
"dmgType": "P",
"weapon": true,
"entries": [
"This drow-made dagger is decorated with silver web filigree. It magically plays a fragment of a guitar solo when struck or used to strike a foe."
]
},
{
"name": "Dagger of Venom",
"source": "DMG",
"page": 161,
"srd": true,
"baseItem": "dagger|phb",
"type": "M",
"tier": "major",
"rarity": "rare",
"weight": 1,
"weaponCategory": "simple",
"property": [
"F",
"L",
"T"
],
"range": "20/60",
"dmg1": "1d4",
"dmgType": "P",
"bonusWeapon": "+1",
"entries": [
"You gain a +1 bonus to attack and damage rolls made with this magic weapon.",
"You can use an action to cause thick, black poison to coat the blade. The poison remains for 1 minute or until an attack using this weapon hits a creature. That creature must succeed on a DC 15 Constitution saving throw or take {@damage 2d10} poison damage and become {@condition poisoned} for 1 minute. The dagger can't be used this way again until the next dawn."
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Damselfly Ship",
"source": "AAG",
"page": 0,
"type": "SPC",
"rarity": "none",
"value": 2000000,
"crew": 9,
"vehAc": 19,
"vehHp": 200,
"vehDmgThresh": 15,
"vehSpeed": 8,
"capCargo": 5,
"entries": [
"This swift but cramped ship is made mostly of metal. It can't float on water, but its legs enable it to land safely on the ground. A sliding hatch just behind the wings allows access to the ship's mangonel turret. The ship's cargo hold can easily be turned into crew cabins or another weapon deck.",
"Damselfly ships are often used as courier vessels and armored transports. Explorers and pirates like them because they're fast and sturdy. Military leaders use them as command ships for the same reasons.",
"Damselfly ship owners are a proud lot, fond of painting their ships in colorful patterns as well as customizing their vessels with special equipment. Large, private gatherings of damselfly ship owners are not uncommon; they use these get-togethers to show off their ships and to race one another through asteroid belts and other obstacle courses, either for rewards or bragging rights."
],
"seeAlsoVehicle": [
"Damselfly Ship|AAG"
]
},
{
"name": "Dancing Monkey Fruit",
"source": "ToA",
"page": 205,
"type": "OTH",
"rarity": "unknown (magic)",
"value": 500,
"entries": [
"This rare magical fruit produces enough juice to fill a vial. Any humanoid that eats a dancing monkey fruit or drinks its juice must succeed on a DC 14 Constitution saving throw or begin a comic dance that lasts for 1 minute. Humanoids that can't be {@condition poisoned} are immune to this magical effect.",
"The dancer must use all its movement to dance without leaving its space and has disadvantage on attack rolls and Dexterity saving throws, and other creatures have advantage on attack rolls against it. Each time it takes damage, the dancer can repeat the saving throw, ending the effect on itself on a success. When the dancing effect ends, the humanoid suffers the {@condition poisoned} condition for 1 hour."
]
},
{
"name": "Danoth's Visor (Awakened)",
"source": "EGW",
"_copy": {
"name": "Danoth's Visor (Dormant)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Awakened",
"entries": [
"When Danoth's Visor reaches an awakened state, it gains the following properties:",
{
"type": "list",
"items": [
"You see {@condition invisible} creatures and objects within 60 feet of you as if they were visible, and you can see into the Ethereal Plane. Ethereal creatures and objects appear ghostly and translucent.",
"As a bonus action, you can speak a command word and use the goggles to see into and through solid matter. This vision has a radius of 60 feet and lasts for 1 minute. To you, solid objects within that radius appear transparent. The vision can penetrate 1 foot of stone, 1 inch of common metal, or up to 3 feet of wood or dirt. Thicker substances block the vision, as does a thin sheet of lead. This property can't be used again until the next dawn.",
"As a bonus action, you can speak a command word to switch the goggles into spyglass mode. While in this mode, creatures and objects viewed through the goggles are magnified to twice their size. Speaking the command word again reverts the goggles to their normal operation."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"hasFluffImages": true
},
{
"name": "Danoth's Visor (Dormant)",
"source": "EGW",
"page": 270,
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"entries": [
"These mithral-frame goggles with clear diamond lenses were used by the evoker Danoth Oro to spot {@condition invisible} enemies and scout areas from afar.",
{
"type": "entries",
"name": "Dormant",
"entries": [
"While wearing the goggles in their dormant state, you can see normally in darkness, both magical and nonmagical, to a distance of 60 feet. Additionally, you have advantage on Intelligence ({@skill Investigation}) and Wisdom ({@skill Perception}) checks that rely on sight."
]
}
],
"hasFluffImages": true
},
{
"name": "Danoth's Visor (Exalted)",
"source": "EGW",
"_copy": {
"name": "Danoth's Visor (Awakened)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Exalted",
"entries": [
"When Danoth's Visor reaches an exalted state, it gains the following properties:",
{
"type": "list",
"items": [
"You automatically detect illusions you can see and automatically succeed on saving throws against them. In addition, you see a bright aura around any creature that isn't in its true form.",
"As an action, you can cast the {@spell antimagic field} spell from the visor. This property can't be used again until the next dawn."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"attachedSpells": [
"antimagic field"
],
"hasFluffImages": true
},
{
"name": "Dark Shard Amulet",
"source": "XGE",
"page": 137,
"tier": "minor",
"rarity": "common",
"reqAttune": "by a warlock",
"reqAttuneTags": [
{
"class": "warlock"
}
],
"wondrous": true,
"focus": [
"Warlock"
],
"entries": [
"This amulet is fashioned from a single shard of resilient extraplanar material originating from the realm of your warlock patron. While you are wearing it, you gain the following benefits:",
{
"type": "list",
"items": [
"You can use the amulet as a spellcasting focus for your warlock spells.",
"You can try to cast a cantrip that you don't know. The cantrip must be on the warlock spell list, and you must make a DC 10 Intelligence ({@skill Arcana}) check. If the check succeeds, you cast the spell. If the check fails, so does the spell, and the action used to cast the spell is wasted. In either case, you can't use this property again until you finish a long rest."
]
}
]
},
{
"name": "Dawnbringer",
"source": "OotA",
"page": 222,
"baseItem": "longsword|phb",
"type": "M",
"rarity": "legendary",
"reqAttune": "by a creature of non-evil alignment",
"reqAttuneTags": [
{
"alignment": [
"L",
"NX",
"C",
"G",
"NY"
]
}
],
"sentient": true,
"weight": 3,
"weaponCategory": "martial",
"property": [
"F",
"V"
],
"dmg1": "1d8",
"dmgType": "R",
"dmg2": "1d10",
"bonusWeapon": "+2",
"entries": [
"Lost for ages in the Underdark, Dawnbringer appears to be a gilded longsword hilt. While grasping the hilt, you can use a bonus action to make a blade of pure radiance spring from the hilt, or cause the blade to disappear. While the blade exists, this magic longsword has the finesse property. If you are proficient with {@item shortsword|phb|shortswords} or {@item longsword|phb|longswords}, you are proficient with Dawnbringer.",
"You gain a +2 bonus to attack and damage rolls made with this weapon, which deals radiant damage instead of slashing damage. When you hit an undead with it, that target takes an extra {@damage 1d8} radiant damage.",
"The sword's luminous blade emits bright light in a 15-foot radius and dim light for an additional 15 feet. The light is sunlight. While the blade persists, you can use an action to expand or reduce its radius of bright and dim light by 5 feet each, to a maximum of 30 feet each or a minimum of 10 feet each.",
"While holding the weapon, you can use an action to touch a creature with the blade and cast {@spell lesser restoration} on that creature. Once used, this ability can't be used again until the next dawn.",
{
"name": "Sentience",
"type": "entries",
"entries": [
"Dawnbringer is a sentient neutral good weapon with an Intelligence of 12, a Wisdom of 15, and a Charisma of 14. It has hearing and {@sense darkvision} out to a range of 120 feet.",
"The sword can speak, read, and understand Common, and it can communicate with its wielder telepathically. Its voice is kind and feminine. It knows every language you know while attuned to it."
]
},
{
"name": "Personality",
"type": "entries",
"entries": [
"Forged by ancient sun worshippers, Dawnbringer is meant to bring light into darkness and to fight creatures of darkness. It is kind and compassionate to those in need, but fierce and destructive to its enemies.",
"Long years lost in darkness have made Dawnbringer frightened of both the dark and abandonment. It prefers that its blade always be present and shedding light in areas of darkness, and it strongly resists being parted from its wielder for any length of time."
]
}
],
"attachedSpells": [
"lesser restoration"
],
"hasFluffImages": true
},
{
"name": "Decanter of Endless Water",
"source": "DMG",
"page": 161,
"srd": true,
"tier": "minor",
"rarity": "uncommon",
"wondrous": true,
"weight": 2,
"entries": [
"This stoppered flask sloshes when shaken, as if it contains water. The decanter weighs 2 pounds.",
"You can use an action to remove the stopper and speak one of three command words, whereupon an amount of fresh water or salt water (your choice) pours out of the flask. The water stops pouring out at the start of your next turn. Choose from the following options:",
{
"type": "list",
"items": [
"\"Stream\" produces 1 gallon of water.",
"\"Fountain\" produces 5 gallons of water.",
"\"Geyser\" produces 30 gallons of water that gushes forth in a geyser 30 feet long and 1 foot wide. As a bonus action while holding the decanter, you can aim the geyser at a creature you can see within 30 feet of you. The target must succeed on a DC 13 Strength saving throw or take {@damage 1d4} bludgeoning damage and fall {@condition prone}. Instead of a creature, you can target an object that isn't being worn or carried and that weighs no more than 200 pounds. The object is either knocked over or pushed up to 15 feet away from you."
]
}
],
"lootTables": [
"Magic Item Table C"
],
"miscTags": [
"CF/W"
],
"hasFluffImages": true
},
{
"name": "Deck of Illusions",
"source": "DMG",
"page": 161,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"wondrous": true,
"entries": [
"This box contains a set of parchment cards. A full deck has 34 cards. A deck found as treasure is usually missing {@dice 1d20 - 1} cards.",
"The magic of the deck functions only if cards are drawn at random (you can use an altered deck of playing cards to simulate the deck). You can use an action to draw a card at random from the deck and throw it to the ground at a point within 30 feet of you.",
"An illusion of one or more creatures forms over the thrown card and remains until dispelled. An illusory creature appears real, of the appropriate size, and behaves as if it were a real creature, except that it can do no harm. While you are within 120 feet of the illusory creature and can see it, you can use an action to move it magically anywhere within 30 feet of its card. Any physical interaction with the illusory creature reveals it to be an illusion, because objects pass through it. Someone who uses an action to visually inspect the creature identifies it as illusory with a successful DC 15 Intelligence ({@skill Investigation}) check. The creature then appears translucent.",
"The illusion lasts until its card is moved or the illusion is dispelled. When the illusion ends, the image on its card disappears, and that card can't be used again.",
{
"type": "table",
"colLabels": [
"{@dice 1d33|Playing Card}",
"Illusion"
],
"colStyles": [
"col-3",
"col-9"
],
"rows": [
[
{
"type": "cell",
"roll": {
"exact": 1
},
"entry": "Ace of hearts"
},
"{@creature adult red dragon||Red dragon}"
],
[
{
"type": "cell",
"roll": {
"exact": 2
},
"entry": "King of hearts"
},
"{@creature Knight} and four {@creature guard||guards}"
],
[
{
"type": "cell",
"roll": {
"exact": 3
},
"entry": "Queen of hearts"
},
"{@creature Succubus||Succubus/Incubus}"
],
[
{
"type": "cell",
"roll": {
"exact": 4
},
"entry": "Jack of hearts"
},
"{@creature Druid}"
],
[
{
"type": "cell",
"roll": {
"exact": 5
},
"entry": "Ten of hearts"
},
"{@creature Cloud giant}"
],
[
{
"type": "cell",
"roll": {
"exact": 6
},
"entry": "Nine of hearts"
},
"{@creature Ettin}"
],
[
{
"type": "cell",
"roll": {
"exact": 7
},
"entry": "Eight of hearts"
},
"{@creature Bugbear}"
],
[
{
"type": "cell",
"roll": {
"exact": 8
},
"entry": "Two of hearts"
},
"{@creature Goblin}"
],
[
{
"type": "cell",
"roll": {
"exact": 9
},
"entry": "Ace of diamonds"
},
"{@creature Beholder}"
],
[
{
"type": "cell",
"roll": {
"exact": 10
},
"entry": "King of diamonds"
},
"{@creature Archmage} and {@creature mage} apprentice"
],
[
{
"type": "cell",
"roll": {
"exact": 11
},
"entry": "Queen of diamonds"
},
"{@creature Night hag}"
],
[
{
"type": "cell",
"roll": {
"exact": 12
},
"entry": "Jack of diamonds"
},
"{@creature Assassin}"
],
[
{
"type": "cell",
"roll": {
"exact": 13
},
"entry": "Ten of diamonds"
},
"{@creature Fire giant}"
],
[
{
"type": "cell",
"roll": {
"exact": 14
},
"entry": "Nine of diamonds"
},
"{@creature oni||Ogre mage}"
],
[
{
"type": "cell",
"roll": {
"exact": 15
},
"entry": "Eight of diamonds"
},
"{@creature Gnoll}"
],
[
{
"type": "cell",
"roll": {
"exact": 16
},
"entry": "Two of diamonds"
},
"{@creature Kobold}"
],
[
{
"type": "cell",
"roll": {
"exact": 17
},
"entry": "Ace of spades"
},
"{@creature Lich}"
],
[
{
"type": "cell",
"roll": {
"exact": 18
},
"entry": "King of spades"
},
"{@creature Priest} and two {@creature acolyte||acolytes}"
],
[
{
"type": "cell",
"roll": {
"exact": 19
},
"entry": "Queen of spades"
},
"{@creature Medusa}"
],
[
{
"type": "cell",
"roll": {
"exact": 20
},
"entry": "Jack of spades"
},
"{@creature Veteran}"
],
[
{
"type": "cell",
"roll": {
"exact": 21
},
"entry": "Ten of spades"
},
"{@creature Frost giant}"
],
[
{
"type": "cell",
"roll": {
"exact": 22
},
"entry": "Nine of spades"
},
"{@creature Troll}"
],
[
{
"type": "cell",
"roll": {
"exact": 23
},
"entry": "Eight of spades"
},
"{@creature Hobgoblin}"
],
[
{
"type": "cell",
"roll": {
"exact": 24
},
"entry": "Two of spades"
},
"{@creature Goblin}"
],
[
{
"type": "cell",
"roll": {
"exact": 25
},
"entry": "Ace of clubs"
},
"{@creature Iron golem}"
],
[
{
"type": "cell",
"roll": {
"exact": 26
},
"entry": "King of clubs"
},
"{@creature Bandit captain} and three {@creature bandit||bandits}"
],
[
{
"type": "cell",
"roll": {
"exact": 27
},
"entry": "Queen of clubs"
},
"{@creature Erinyes}"
],
[
{
"type": "cell",
"roll": {
"exact": 28
},
"entry": "Jack of clubs"
},
"{@creature Berserker}"
],
[
{
"type": "cell",
"roll": {
"exact": 29
},
"entry": "Ten of clubs"
},
"{@creature Hill giant}"
],
[
{
"type": "cell",
"roll": {
"exact": 30
},
"entry": "Nine of clubs"
},
"{@creature Ogre}"
],
[
{
"type": "cell",
"roll": {
"exact": 31
},
"entry": "Eight of clubs"
},
"{@creature Orc}"
],
[
{
"type": "cell",
"roll": {
"exact": 32
},
"entry": "Two of clubs"
},
"{@creature Kobold}"
],
[
{
"type": "cell",
"roll": {
"min": 33,
"max": 34
},
"entry": "jokers (2)"
},
"You (the deck's owner)"
]
]
}
],
"lootTables": [
"Magic Item Table F"
]
},
{
"name": "Deck of Many Things",
"source": "DMG",
"page": 162,
"srd": true,
"tier": "major",
"rarity": "legendary",
"wondrous": true,
"ability": {
"from": [
"str",
"dex",
"con",
"int",
"wis",
"cha"
],
"count": 1,
"amount": 2
},
"grantsProficiency": true,
"entries": [
"Usually found in a box or pouch, this deck contains a number of cards made of ivory or vellum. Most (75 percent) of these decks have only thirteen cards, but the rest have twenty-two.",
"Before you draw a card, you must declare how many cards you intend to draw and then draw them randomly (you can use an altered deck of playing cards to simulate the deck). Any cards drawn in excess of this number have no effect. Otherwise, as soon as you draw a card from the deck, its magic takes effect. You must draw each card no more than 1 hour after the previous draw. If you fail to draw the chosen number, the remaining number of cards fly from the deck on their own and take effect all at once.",
"Once a card is drawn, it fades from existence. Unless the card is the Fool or the Jester, the card reappears in the deck, making it possible to draw the same card twice.",
{
"type": "entries",
"name": "A Question of Enmity",
"entries": [
"Two of the cards in a deck of many things can earn a character the enmity of another being. With the Flames card, the enmity is overt. The character should experience the devil's malevolent efforts on multiple occasions. Seeking out the fiend shouldn't be a simple task, and the adventurer should clash with the devil's allies and followers a few times before being able to confront the devil itself."
]
},
"In the case of the Rogue card, the enmity is secret and should come from someone thought to be a friend or an ally. As Dungeon Master, you should wait for a dramatically appropriate moment to reveal this enmity, leaving the adventurer guessing who is likely to become a betrayer.",
{
"type": "table",
"colLabels": [
"{@dice 1d22|Playing Card}",
"Card"
],
"colStyles": [
"col-6 text-center",
"col-6 text-center"
],
"rows": [
[
{
"type": "cell",
"roll": {
"exact": 1
},
"entry": "Ace of diamonds"
},
"Vizier*"
],
[
{
"type": "cell",
"roll": {
"exact": 2
},
"entry": "King of diamonds"
},
"Sun"
],
[
{
"type": "cell",
"roll": {
"exact": 3
},
"entry": "Queen of diamonds"
},
"Moon"
],
[
{
"type": "cell",
"roll": {
"exact": 4
},
"entry": "Jack of diamonds"
},
"Star"
],
[
{
"type": "cell",
"roll": {
"exact": 5
},
"entry": "Two of diamonds"
},
"Comet*"
],
[
{
"type": "cell",
"roll": {
"exact": 6
},
"entry": "Ace of hearts"
},
"The Fates*"
],
[
{
"type": "cell",
"roll": {
"exact": 7
},
"entry": "King of hearts"
},
"Throne"
],
[
{
"type": "cell",
"roll": {
"exact": 8
},
"entry": "Queen of hearts"
},
"Key"
],
[
{
"type": "cell",
"roll": {
"exact": 9
},
"entry": "Jack of hearts"
},
"Knight"
],
[
{
"type": "cell",
"roll": {
"exact": 10
},
"entry": "Two of hearts"
},
"Gem*"
],
[
{
"type": "cell",
"roll": {
"exact": 11
},
"entry": "Ace of clubs"
},
"Talons*"
],
[
{
"type": "cell",
"roll": {
"exact": 12
},
"entry": "King of clubs"
},
"The Void"
],
[
{
"type": "cell",
"roll": {
"exact": 13
},
"entry": "Queen of clubs"
},
"Flames"
],
[
{
"type": "cell",
"roll": {
"exact": 14
},
"entry": "Jack of clubs"
},
"Skull"
],
[
{
"type": "cell",
"roll": {
"exact": 15
},
"entry": "Two of clubs"
},
"Idiot*"
],
[
{
"type": "cell",
"roll": {
"exact": 16
},
"entry": "Ace of spades"
},
"Donjon*"
],
[
{
"type": "cell",
"roll": {
"exact": 17
},
"entry": "King of spades"
},
"Ruin"
],
[
{
"type": "cell",
"roll": {
"exact": 18
},
"entry": "Queen of spades"
},
"Euryale"
],
[
{
"type": "cell",
"roll": {
"exact": 19
},
"entry": "Jack of spades"
},
"Rogue"
],
[
{
"type": "cell",
"roll": {
"exact": 20
},
"entry": "Two of spades"
},
"Balance*"
],
[
{
"type": "cell",
"roll": {
"exact": 21
},
"entry": "Joker (with TM)"
},
"Fool*"
],
[
{
"type": "cell",
"roll": {
"exact": 22
},
"entry": "Joker (without TM)"
},
"Jester"
]
],
"footnotes": [
"* Found only in a deck with twenty-two cards"
]
},
{
"type": "entries",
"name": "Vizier",
"entries": [
"At any time you choose within one year of drawing this card, you can ask a question in meditation and mentally receive a truthful answer to that question. Besides information, the answer helps you solve a puzzling problem or other dilemma. In other words, the knowledge comes with wisdom on how to apply it."
]
},
{
"type": "entries",
"name": "Sun",
"entries": [
"You gain 50,000 XP, and a wondrous item (which the DM determines randomly) appears in your hands."
]
},
{
"type": "entries",
"name": "Moon",
"entries": [
"You are granted the ability to cast the {@spell wish} spell {@dice 1d3} times."
]
},
{
"type": "entries",
"name": "Star",
"entries": [
"Increase one of your ability scores by 2. The score can exceed 20 but can't exceed 24."
]
},
{
"type": "entries",
"name": "Comet",
"entries": [
"If you single-handedly defeat the next hostile monster or group of monsters you encounter, you gain experience points enough to gain one level. Otherwise, this card has no effect."
]
},
{
"type": "entries",
"name": "The Fates",
"entries": [
"Reality's fabric unravels and spins anew, allowing you to avoid or erase one event as if it never happened. You can use the card's magic as soon as you draw the card or at any other time before you die."
]
},
{
"type": "entries",
"name": "Throne",
"entries": [
"You gain proficiency in the {@skill Persuasion} skill, and you double your proficiency bonus on checks made with that skill. In addition, you gain rightful ownership of a small keep somewhere in the world. However, the keep is currently in the hands of monsters, which you must clear out before you can claim the keep as yours."
]
},
{
"type": "entries",
"name": "Key",
"entries": [
"A rare or rarer magic weapon with which you are proficient appears in your hands. The DM chooses the weapon."
]
},
{
"type": "entries",
"name": "Knight",
"entries": [
"You gain the service of a 4th-level fighter who appears in a space you choose within 30 feet of you. The fighter is of the same race as you and serves you loyally until death, believing the fates have drawn him or her to you. You control this character."
]
},
{
"type": "entries",
"name": "Gem",
"entries": [
"Twenty-five pieces of jewelry worth 2,000 gp each or fifty gems worth 1,000 gp each appear at your feet."
]
},
{
"type": "entries",
"name": "Talons",
"entries": [
"Every magic item you wear or carry disintegrates. Artifacts in your possession aren't destroyed but do vanish."
]
},
{
"type": "entries",
"name": "The Void",
"entries": [
"This black card spells disaster. Your soul is drawn from your body and contained in an object in a place of the DM's choice. One or more powerful beings guard the place. While your soul is trapped in this way, your body is {@condition incapacitated}. A {@spell wish} spell can't restore your soul, but the spell reveals the location of the object that holds it. You draw no more cards."
]
},
{
"type": "entries",
"name": "Flames",
"entries": [
"A powerful devil becomes your enemy. The devil seeks your ruin and plagues your life, savoring your suffering before attempting to slay you. This enmity lasts until either you or the devil dies."
]
},
{
"type": "entries",
"name": "Skull",
"entries": [
"You summon an {@creature avatar of death|dmg}-a ghostly humanoid skeleton clad in a tattered black robe and carrying a spectral scythe. It appears in a space of the DM's choice within 10 feet of you and attacks you, warning all others that you must win the battle alone. The avatar fights until you die or it drops to 0 hit points, whereupon it disappears. If anyone tries to help you, the helper summons its own {@creature avatar of death|dmg}. A creature slain by an {@creature avatar of death|dmg} can't be restored to life."
]
},
{
"type": "entries",
"name": "Idiot",
"entries": [
"Permanently reduce your Intelligence by {@dice 1d4 + 1} (to a minimum score of 1). You can draw one additional card beyond your declared draws."
]
},
{
"type": "entries",
"name": "Donjon",
"entries": [
"You disappear and become entombed in a state of suspended animation in an extradimensional sphere. Everything you were wearing and carrying stays behind in the space you occupied when you disappeared. You remain imprisoned until you are found and removed from the sphere. You can't be located by any divination magic, but a {@spell wish} spell can reveal the location of your prison. You draw no more cards."
]
},
{
"type": "entries",
"name": "Ruin",
"entries": [
"All forms of wealth that you carry or own, other than magic items, are lost to you. Portable property vanishes. Businesses, buildings, and land you own are lost in a way that alters reality the least. Any documentation that proves you should own something lost to this card also disappears."
]
},
{
"type": "entries",
"name": "Euryale",
"entries": [
"The card's {@creature medusa}-like visage curses you. You take a -2 penalty on saving throws while cursed in this way. Only a god or the magic of The Fates card can end this curse."
]
},
{
"type": "entries",
"name": "Rogue",
"entries": [
"A nonplayer character of the DM's choice becomes hostile toward you. The identity of your new enemy isn't known until the NPC or someone else reveals it. Nothing less than a {@spell wish} spell or divine intervention can end the NPC's hostility toward you."
]
},
{
"type": "entries",
"name": "Balance",
"entries": [
"Your mind suffers a wrenching alteration, causing your alignment to change. Lawful becomes chaotic, good becomes evil, and vice versa. If you are true neutral or unaligned, this card has no effect on you."
]
},
{
"type": "entries",
"name": "Fool",
"entries": [
"You lose 10,000 XP, discard this card, and draw from the deck again, counting both draws as one of your declared draws. If losing that much XP would cause you to lose a level, you instead lose an amount that leaves you with just enough XP to keep your level."
]
},
{
"type": "entries",
"name": "Jester",
"entries": [
"You gain 10,000 XP, or you can draw two additional cards beyond your declared draws."
]
}
],
"attachedSpells": [
"wish"
],
"lootTables": [
"Magic Item Table I"
],
"hasFluffImages": true
},
{
"name": "Deck of Several Things",
"source": "LLK",
"page": 53,
"rarity": "legendary",
"wondrous": true,
"ability": {
"choose": [
{
"from": [
"str",
"dex",
"con",
"int",
"wis",
"cha"
],
"count": 1
}
]
},
"grantsProficiency": true,
"entries": [
"Stored in a leather pouch, this unique deck contains twenty-two colored cards made of some strong but unknown metal, each of which features a design printed as a mosaic of raised dots. Before you draw a card, you must declare how many cards you intend to draw and then draw them randomly (you can use an altered deck of playing cards to simulate the deck). Any cards drawn in excess of this number have no effect. Otherwise, as soon as you draw a card from the deck, its magic takes effect. You must draw each card no more than 1 hour after the previous draw. If you fail to draw the chosen number, the remaining number of cards fly from the deck on their own and take effect all at once.",
"Once a card is drawn, it fades from existence. Unless the card is the Fool or the Jester, the card reappears in the deck, making it possible to draw the same card twice.",
{
"type": "table",
"colLabels": [
"{@dice 1d22|Playing Card}",
"Card"
],
"colStyles": [
"col-6 text-center",
"col-6 text-center"
],
"rows": [
[
{
"type": "cell",
"roll": {
"exact": 1
},
"entry": "Ace of diamonds"
},
"Vizier"
],
[
{
"type": "cell",
"roll": {
"exact": 2
},
"entry": "King of diamonds"
},
"Sun"
],
[
{
"type": "cell",
"roll": {
"exact": 3
},
"entry": "Queen of diamonds"
},
"Moon"
],
[
{
"type": "cell",
"roll": {
"exact": 4
},
"entry": "Jack of diamonds"
},
"Star"
],
[
{
"type": "cell",
"roll": {
"exact": 5
},
"entry": "Two of diamonds"
},
"Comet"
],
[
{
"type": "cell",
"roll": {
"exact": 6
},
"entry": "Ace of hearts"
},
"The Fates"
],
[
{
"type": "cell",
"roll": {
"exact": 7
},
"entry": "King of hearts"
},
"Throne"
],
[
{
"type": "cell",
"roll": {
"exact": 8
},
"entry": "Queen of hearts"
},
"Key"
],
[
{
"type": "cell",
"roll": {
"exact": 9
},
"entry": "Jack of hearts"
},
"Knight"
],
[
{
"type": "cell",
"roll": {
"exact": 10
},
"entry": "Two of hearts"
},
"Gem"
],
[
{
"type": "cell",
"roll": {
"exact": 11
},
"entry": "Ace of clubs"
},
"Talons"
],
[
{
"type": "cell",
"roll": {
"exact": 12
},
"entry": "King of clubs"
},
"The Void"
],
[
{
"type": "cell",
"roll": {
"exact": 13
},
"entry": "Queen of clubs"
},
"Flames"
],
[
{
"type": "cell",
"roll": {
"exact": 14
},
"entry": "Jack of clubs"
},
"Skull"
],
[
{
"type": "cell",
"roll": {
"exact": 15
},
"entry": "Two of clubs"
},
"Idiot"
],
[
{
"type": "cell",
"roll": {
"exact": 16
},
"entry": "Ace of spades"
},
"Donjon"
],
[
{
"type": "cell",
"roll": {
"exact": 17
},
"entry": "King of spades"
},
"Ruin"
],
[
{
"type": "cell",
"roll": {
"exact": 18
},
"entry": "Queen of spades"
},
"Euryale"
],
[
{
"type": "cell",
"roll": {
"exact": 19
},
"entry": "Jack of spades"
},
"Rogue"
],
[
{
"type": "cell",
"roll": {
"exact": 20
},
"entry": "Two of spades"
},
"Balance"
],
[
{
"type": "cell",
"roll": {
"exact": 21
},
"entry": "Joker (with TM)"
},
"Fool"
],
[
{
"type": "cell",
"roll": {
"exact": 22
},
"entry": "Joker (without TM)"
},
"Jester"
]
]
},
{
"type": "entries",
"name": "Balance",
"entries": [
"Your mind suffers a wrenching alteration, causing your alignment to change for the duration of the adventure. Lawful becomes chaotic, good becomes evil, and vice versa. If you are true neutral or unaligned, this card has no effect on you."
]
},
{
"type": "entries",
"name": "Comet",
"entries": [
"If you single-handedly defeat the next hostile monster or group of monsters you encounter, you have advantage on ability checks made using one skill of your choice for the duration of the adventure. Otherwise, this card has no effect."
]
},
{
"type": "entries",
"name": "Donjon",
"entries": [
"You are instantly teleported to and confined within the prison of the Monastery of the Distressed Body (area M6). Everything you were wearing and carrying stays behind in the space you occupied when you disappeared. You draw no more cards."
]
},
{
"type": "entries",
"name": "Euryale",
"entries": [
"The card's medusa-like visage curses you. You take a −1 penalty on saving throws for the duration of the adventure."
]
},
{
"type": "entries",
"name": "The Fates",
"entries": [
"Reality's fabric unravels and spins anew, allowing you to avoid or erase one event as if it never happened. You can use the card's magic as soon as you draw the card or at any other point during the adventure."
]
},
{
"type": "entries",
"name": "Flames",
"entries": [
"The Grand Master of the Monastery of the Distressed Body becomes your enemy. The bone devil seeks your ruin, savoring your suffering before attempting to slay you. If the Grand Master has already been defeated, you gain the enmity of Garret Levistusson's patron—a similarly powerful devil."
]
},
{
"type": "entries",
"name": "Fool",
"entries": [
"For the duration of the adventure, you lose proficiency with one skill or gain disadvantage on all checks made with one skill (with the skill and the penalty determined by the DM). Discard this card and draw from the deck again, counting both draws as one of your declared draws."
]
},
{
"type": "entries",
"name": "Gem",
"entries": [
"The 1,000 gp hoard of the leprechaun from the Wilderness Encounters table (see appendix A) appears at your feet. If that treasure has already been claimed, you gain an equivalent hoard."
]
},
{
"type": "entries",
"name": "Idiot",
"entries": [
"Reduce your Intelligence by {@dice 1d4 + 1} (to a minimum score of 1) for the duration of the adventure. You can draw one additional card beyond your declared draws."
]
},
{
"type": "entries",
"name": "Jester",
"entries": [
"You gain proficiency in a skill of your choice for the duration of the adventure, or you can draw two additional cards beyond your declared draws."
]
},
{
"type": "entries",
"name": "Key",
"entries": [
"A common or uncommon magic weapon with which you are proficient, or a {@item spell scroll} featuring a spell of a level you can cast, appears in your hands. The DM chooses the weapon or spell, which you possess for the duration of this adventure."
]
},
{
"type": "entries",
"name": "Knight",
"entries": [
"You gain the service of any of the NPCs in the \"{@book Hirelings|phb|5|services}\" section not currently with the party, who appears in a space you choose within 30 feet of you. The NPC serves you loyally for the duration of the adventure, believing that the fates have drawn them to you. You control this character."
]
},
{
"type": "entries",
"name": "Moon",
"entries": [
"You are granted the ability to cast any spell of 5th level or lower, and can use that ability {@dice 1d3} times for the duration of the adventure."
]
},
{
"type": "entries",
"name": "Rogue",
"entries": [
"An NPC of the DM's choice becomes secretly hostile toward you. The identity of your new enemy isn't known until the NPC or someone else reveals it. Any enchantment spell cast on the NPC at 6th level or higher can end the NPC's hostility toward you."
]
},
{
"type": "entries",
"name": "Ruin",
"entries": [
"All forms of wealth that you carry or own, other than magic items, are lost to you. This wealth can be recovered either in the treasury of the Monastery of the Distressed Body (area M10) or Kwalish's lab in Daoine Gloine (area O7), whichever comes later in the adventure."
]
},
{
"type": "entries",
"name": "Skull",
"entries": [
"You summon an avatar of death—a mechanical skeleton (use bone naga statistics) clad in a tattered black robe. It appears in a space of the DM's choice within 10 feet of you and attacks you, warning all others that you must win the battle alone. The avatar fights until you die or it drops to 0 hit points, whereupon it disappears. If anyone tries to help you, the helper summons its own avatar of death. A creature slain by an avatar of death can't be restored to life."
]
},
{
"type": "entries",
"name": "Star",
"entries": [
"Increase one of your ability scores by 1 for the duration of the adventure. The score can exceed 20 but can't exceed 24."
]
},
{
"type": "entries",
"name": "Sun",
"entries": [
"You gain proficiency in the skill of your choice for the duration of the adventure. In addition, a common or uncommon wondrous item appears in your hands. The DM chooses the item, which you possess for the duration of this adventure."
]
},
{
"type": "entries",
"name": "Talons",
"entries": [
"Every magic item you wear or carry is lost to you. These items can be recovered either in the treasury of the Monastery of the Distressed Body (area M10) or Kwalish's lab in Daoine Gloine (area O7), whichever comes later in the adventure."
]
},
{
"type": "entries",
"name": "Throne",
"entries": [
"You gain proficiency in the {@skill Persuasion} skill and you double your proficiency bonus on checks made with that skill for the duration of the adventure. In addition, the Monastery of the Distressed Body's brains in jars regard you thereafter as the monastery's rightful master. You must defeat or otherwise clear out the Grand Master and its servants before you can claim the monastery as yours."
]
},
{
"type": "entries",
"name": "Vizier",
"entries": [
"At any one time you choose within the duration of the adventure, you can ask a question in meditation and mentally receive a truthful answer to that question. Besides information, the answer helps you solve a puzzling problem or other dilemma. In other words, the knowledge comes with wisdom on how to apply it."
]
},
{
"type": "entries",
"name": "The Void",
"entries": [
"This black card spells disaster. Your soul is drawn from your body and held within machinery in either the control room of the Monastery of the Distressed Body (area M8) or Kwalish's lab in Daoine Gloine (area O7), whichever comes later in the adventure. While your soul is trapped in this way, your body is {@condition incapacitated}. {@spell Divination}, {@spell contact other plane}, or a similar spell of 4th level or higher reveals the location of the machinery that holds your soul. You draw no more cards."
]
},
{
"type": "inset",
"name": "Using the Deck of Several Things",
"entries": [
"The special nature of this deck (carried by Mary Greymalkin if she is used as an NPC) means that many of its effects operate only within and with respect to this specific adventure. This typically covers any time the characters spend between setting out for the Barrier Peaks and the resolution of whatever events transpire in Kwalish's lab (area O7).",
"For cards that effectively remove a character from play for a period of time (Donjon and the Void), you can allow a player to take on the role of one of the party's NPC hirelings, or introduce a temporary character as an NPC met during the party's journey. Alternatively, you can decide that only some aspect of the character's will disappears and is imprisoned, leaving the character to operate in a robotic state until freed."
]
}
]
},
{
"name": "Dekella, Bident of Thassa",
"source": "MOT",
"page": 199,
"baseItem": "trident|PHB",
"type": "M",
"rarity": "artifact",
"reqAttune": true,
"weight": 4,
"weaponCategory": "martial",
"property": [
"T",
"V"
],
"range": "20/60",
"dmg1": "1d6",
"dmgType": "P",
"dmg2": "1d8",
"bonusWeapon": "+3",
"modifySpeed": {
"static": {
"swim": 60
}
},
"weapon": true,
"entries": [
"Thassa wields Dekella, a two-pronged weapon gifted to her by Purphoros. When the god of the sea bestows her weapon on a mortal, it's often so they might work her will far from the ocean, right some wrong affecting those she'd prefer not to harm, or give a mortal a glimpse of her terrible responsibilities.",
{
"type": "entries",
"name": "Bident of the Deep",
"entries": [
"Thassa's signature weapon thrums with the icy currents of the deep ocean. This weapon functions as a trident that grants a +3 bonus to attack and damage rolls made with it. When you hit with an attack using the bident, the target takes an extra {@damage 2d10} cold damage."
]
},
{
"type": "entries",
"name": "Blessing of the Deep",
"entries": [
"If you are a worshiper of Thassa, you gain all the following benefits for which you have the required piety:",
{
"type": "list",
"style": "list-hang-notitle",
"items": [
{
"type": "item",
"name": "Piety 10+",
"entry": "You can breathe underwater, and you gain a swimming speed of 60 feet."
},
{
"type": "item",
"name": "Piety 25+",
"entry": "The bident has 1 randomly determined {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} property."
},
{
"type": "item",
"name": "Piety 50+",
"entry": "The bident has 1 randomly determined {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial} property."
}
]
},
"If you aren't a worshiper of Thassa, the bident has 1 {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental} property and 1 {@table Artifact Properties; Major Detrimental Properties|dmg|major detrimental} property, both randomly determined.",
"See \"Artifacts\" in chapter 7 of the Dungeon Master's Guide for details on randomly determined properties."
]
},
{
"type": "entries",
"name": "Command the Deep",
"entries": [
"The bident holds the power to command the waves and its creatures. As an action, you can change the condition of the sea within 1 mile of you, creating strong winds and heavy rain that cause violent waves (see \"Weather at Sea\" in chapter 5 of the Dungeon Master's Guide) or calming a storm. In either case, the unnatural weather lasts for 1 hour before returning to normal. Once used, this property of the bident can't be used again until the next dusk.",
"Additionally, you can cast the {@spell dominate monster} spell (save DC 18) from the bident, but only on beasts and monstrosities that have an innate swimming speed. Once used, this property of the bident can't be used again until the next dusk."
]
},
{
"type": "entries",
"name": "Aquatic Metamorphosis",
"entries": [
"You can cast the {@spell true polymorph} spell (save DC 18) from the bident, but you must cast it on a creature to turn it into a kind of creature that has an innate swimming speed. Once used, this property of the bident can't be used again until the next dusk."
]
},
{
"type": "entries",
"name": "Destroying the Bident",
"entries": [
"To destroy the bident, it must be heated by the breath of an {@creature ancient red dragon} and then, while still hot, immersed in the Tartyx River."
]
}
],
"attachedSpells": [
"dominate monster",
"true polymorph"
],
"hasFluffImages": true
},
{
"name": "Demon Armor",
"source": "DMG",
"page": 165,
"srd": true,
"type": "HA",
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"curse": true,
"weight": 65,
"ac": 18,
"strength": "15",
"bonusWeapon": "+1",
"bonusAc": "+1",
"stealth": true,
"entries": [
"While wearing this armor, you gain a +1 bonus to AC, and you can understand and speak Abyssal. In addition, the armor's clawed gauntlets turn unarmed strikes with your hands into magic weapons that deal slashing damage, with a +1 bonus to attack and damage rolls and a damage die of {@dice 1d8}.",
{
"type": "entries",
"name": "Curse",
"entries": [
"Once you don this cursed armor, you can't doff it unless you are targeted by the {@spell remove curse} spell or similar magic. While wearing the armor, you have disadvantage on attack rolls against demons and on saving throws against their spells and special abilities."
]
}
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Demonomicon of Iggwilv",
"source": "TCE",
"page": 125,
"rarity": "artifact",
"reqAttune": true,
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d8}",
"charges": 8,
"entries": [
"An expansive treatise documenting the Abyss's infinite layers and inhabitants, the Demonomicon of Iggwilv is the most thorough and blasphemous tome of demonology in the multiverse. The tome recounts both the oldest and most current profanities of the Abyss and demons. Demons have attempted to censor the text, and while sections have been ripped from the book's spine, the general chapters remain, ever revealing demonic secrets. And the book holds more than blasphemies. Caged behind lines of script roils a secret piece of the Abyss itself, which keeps the book up-to-date, no matter how many pages are removed, and it longs to be more than mere reference material.",
{
"type": "entries",
"name": "Random Properties",
"entries": [
"The artifact has the follow random properties, which you can determine by rolling on the tables in the \"Artifacts\" section of the {@book Dungeon Master's Guide|DMG}:",
{
"type": "list",
"items": [
"2 {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} properties",
"1 {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental} property",
"1 {@table Artifact Properties; Major Detrimental Properties|dmg|major detrimental} property"
]
}
]
},
{
"type": "entries",
"name": "Spells",
"entries": [
"The book has 8 charges. It regains {@dice 1d8} expended charges daily at dawn. While holding it, you can use an action to cast {@spell Tasha's hideous laughter} from it or to expend 1 or more of its charges to cast one of the following spells (save DC 20) from it: {@spell magic circle} (1 charge), {@spell magic jar} (3 charges), {@spell planar ally} (3 charges), {@spell planar binding} (2 charges), {@spell plane shift} (to layers of the Abyss only; 3 charges), {@spell summon fiend|TCE} (3 charges)."
]
},
{
"type": "entries",
"name": "Abyssal Reference",
"entries": [
"You can reference the {@i Demonomicon} whenever you make an Intelligence check to discern information about demons or a Wisdom ({@skill Survival}) check related to the Abyss. When you do so, you can add double your proficiency bonus to the check."
]
},
{
"type": "entries",
"name": "Fiendish Scourging",
"entries": [
"Your magic causes pain to fiends. While carrying the book, when you make a damage roll for a spell you cast against a fiend, you use the maximum possible result instead of rolling."
]
},
{
"type": "entries",
"name": "Ensnarement",
"entries": [
"While carrying the book, whenever you cast the {@spell magic circle} spell naming only fiends, or the {@spell planar binding} spell targeting a fiend, the spell is cast at 9th level, regardless of what level spell slot you used, if any. Additionally, the fiend has disadvantage on its saving throw against the spell."
]
},
{
"type": "entries",
"name": "Containment",
"entries": [
"The first 10 pages of the {@i Demonomicon} are blank. As an action while holding the book, you can target a fiend that you can see that is trapped within a {@spell magic circle}. The fiend must succeed on a DC 20 Charisma saving throw with disadvantage or become trapped within one of the {@i Demonomicon's} empty blank pages, which fills with writing detailing the trapped creature's widely known name and depravities. Once used, this action can't be used again until the next dawn.",
"When you finish a long rest, if you and the {@i Demonomicon} are on the same plane of existence, the trapped creature of the highest challenge rating within the book can attempt to possess you. You must make a DC 20 Charisma saving throw. On a failure, you are possessed by the creature, which controls you like a puppet. The possessing creature can release you as an action, appearing in the closest unoccupied space. On a successful save, the fiend can't try to possess you again for 7 days.",
"When the tome is discovered, it has {@dice 1d4} fiends occupying its pages, typically an assortment of demons."
]
},
{
"type": "entries",
"name": "Destroying the Demonomicon",
"entries": [
"To destroy the book, six different demon lords must each tear out a sixth of the book's pages. If this occurs, the pages reappear after 24 hours. Before all those hours pass, anyone who opens the book's remaining binding is transported to a nascent layer of the Abyss that lies hidden within the book. At the heart of this deadly, semi-sentient domain lies a long-lost artifact, {@i Fraz-Urb'luu's Staff}. If the staff is dragged from the pocket plane, the tome is reduced to a mundane and quite out-of-date copy of the {@i Tome of Zyx}, the work that served as the foundation for the {@i Demonomicon}. Once the staff emerges, the demon lord Fraz-Urb'luu instantly knows."
]
}
],
"attachedSpells": [
"magic circle",
"magic jar",
"planar ally",
"planar binding",
"plane shift",
"summon fiend|tce",
"tasha's hideous laughter"
],
"hasFluffImages": true
},
{
"name": "Devastation Orb of Air",
"source": "PotA",
"page": 222,
"rarity": "very rare",
"wondrous": true,
"entries": [
"A devastation orb is an elemental bomb that can be created at the site of an elemental node by performing a ritual with an elemental weapon. The type of orb created depends on the node used. For example, an air node creates a devastation orb of air. The ritual takes 1 hour to complete and requires 2,000 gp worth of special components, which are consumed.",
"A devastation orb measures 12 inches in diameter, weighs 10 pounds, and has a solid outer shell. The orb detonates {@dice 1d100} hours after its creation, releasing the elemental energy it contains. The orb gives no outward sign of how much time remains before it will detonate. Spells such as {@spell identify} and {@spell divination} can be used to ascertain when the orb will explode. An orb has AC 10, 15 hit points, and immunity to poison and psychic damage. Reducing it to 0 hit points causes it to explode instantly.",
"A special container can be crafted to contain a devastation orb and prevent it from detonating. The container must be inscribed with symbols of the orb's opposing element. For example, a case inscribed with earth symbols can be used to contain a devastation orb of air and keep it from detonating. While in the container, the orb thrums. If it is removed from the container after the time when it was supposed to detonate, it explodes {@dice 1d6} rounds later, unless it is returned to the container.",
"Regardless of the type of orb, its effect is contained within a sphere with a 1 mile radius. The orb is the sphere's point of origin. The orb is destroyed after one use.",
{
"name": "Air Orb",
"type": "entries",
"entries": [
"When this orb detonates, it creates a powerful windstorm that lasts for 1 hour. Whenever a creature ends its turn exposed to the wind, the creature must succeed on a DC 18 Constitution saving throw or take {@damage 1d4} bludgeoning damage, as the wind and debris batter it. The wind is strong enough to uproot weak trees and destroy light structures after at least 10 minutes of exposure. Otherwise, the rules for {@hazard strong wind} apply, as detailed in chapter 5 of the Dungeon Master's Guide."
]
}
]
},
{
"name": "Devastation Orb of Earth",
"source": "PotA",
"page": 222,
"rarity": "very rare",
"wondrous": true,
"entries": [
"A devastation orb is an elemental bomb that can be created at the site of an elemental node by performing a ritual with an elemental weapon. The type of orb created depends on the node used. For example, an air node creates a {@item devastation orb of air|PotA}. The ritual takes 1 hour to complete and requires 2,000 gp worth of special components, which are consumed.",
"A devastation orb measures 12 inches in diameter, weighs 10 pounds, and has a solid outer shell. The orb detonates {@dice 1d100} hours after its creation, releasing the elemental energy it contains. The orb gives no outward sign of how much time remains before it will detonate. Spells such as {@spell identify} and {@spell divination} can be used to ascertain when the orb will explode. An orb has AC 10, 15 hit points, and immunity to poison and psychic damage. Reducing it to 0 hit points causes it to explode instantly.",
"A special container can be crafted to contain a devastation orb and prevent it from detonating. The container must be inscribed with symbols of the orb's opposing element. For example, a case inscribed with earth symbols can be used to contain a {@item devastation orb of air|PotA} and keep it from detonating. While in the container, the orb thrums. If it is removed from the container after the time when it was supposed to detonate, it explodes {@dice 1d6} rounds later, unless it is returned to the container.",
"Regardless of the type of orb, its effect is contained within a sphere with a 1 mile radius. The orb is the sphere's point of origin. The orb is destroyed after one use.",
{
"name": "Earth Orb",
"type": "entries",
"entries": [
"When this orb detonates, it subjects the area to the effects of the {@spell earthquake} spell for 1 minute (spell save DC 18). For the purpose of the spell's effects, the spell is cast on the turn that the orb explodes."
]
}
]
},
{
"name": "Devastation Orb of Fire",
"source": "PotA",
"page": 222,
"rarity": "very rare",
"wondrous": true,
"entries": [
"A devastation orb is an elemental bomb that can be created at the site of an elemental node by performing a ritual with an elemental weapon. The type of orb created depends on the node used. For example, an air node creates a {@item devastation orb of air|PotA}. The ritual takes 1 hour to complete and requires 2,000 gp worth of special components, which are consumed.",
"A devastation orb measures 12 inches in diameter, weighs 10 pounds, and has a solid outer shell. The orb detonates {@dice 1d100} hours after its creation, releasing the elemental energy it contains. The orb gives no outward sign of how much time remains before it will detonate. Spells such as {@spell identify} and {@spell divination} can be used to ascertain when the orb will explode. An orb has AC 10, 15 hit points, and immunity to poison and psychic damage. Reducing it to 0 hit points causes it to explode instantly.",
"A special container can be crafted to contain a devastation orb and prevent it from detonating. The container must be inscribed with symbols of the orb's opposing element. For example, a case inscribed with earth symbols can be used to contain a {@item devastation orb of air|PotA} and keep it from detonating. While in the container, the orb thrums. If it is removed from the container after the time when it was supposed to detonate, it explodes {@dice 1d6} rounds later, unless it is returned to the container.",
"Regardless of the type of orb, its effect is contained within a sphere with a 1 mile radius. The orb is the sphere's point of origin. The orb is destroyed after one use.",
{
"name": "Fire Orb",
"type": "entries",
"entries": [
"When this orb detonates, it creates a dry heat wave that lasts for 24 hours. Within the area of effect, the rules for {@hazard extreme heat} apply, as detailed in chapter 5 of the Dungeon Master's Guide. At the end of each hour, there is a ten percent chance that the heat wave starts a wildfire in a random location within the area of effect. The wildfire covers a 10-foot-square area initially but expands to fill another 10-foot square each round until the fire is extinguished or burns itself out. A creature that comes within 10 feet of a wildfire for the first time on a turn or starts its turn there takes {@damage 3d6} fire damage."
]
}
]
},
{
"name": "Devastation Orb of Water",
"source": "PotA",
"page": 222,
"rarity": "very rare",
"wondrous": true,
"entries": [
"A devastation orb is an elemental bomb that can be created at the site of an elemental node by performing a ritual with an elemental weapon. The type of orb created depends on the node used. For example, an air node creates a {@item devastation orb of air|PotA}. The ritual takes 1 hour to complete and requires 2,000 gp worth of special components, which are consumed.",
"A devastation orb measures 12 inches in diameter, weighs 10 pounds, and has a solid outer shell. The orb detonates {@dice 1d100} hours after its creation, releasing the elemental energy it contains. The orb gives no outward sign of how much time remains before it will detonate. Spells such as {@spell identify} and {@spell divination} can be used to ascertain when the orb will explode. An orb has AC 10, 15 hit points, and immunity to poison and psychic damage. Reducing it to 0 hit points causes it to explode instantly.",
"A special container can be crafted to contain a devastation orb and prevent it from detonating. The container must be inscribed with symbols of the orb's opposing element. For example, a case inscribed with earth symbols can be used to contain a {@item devastation orb of air|PotA} and keep it from detonating. While in the container, the orb thrums. If it is removed from the container after the time when it was supposed to detonate, it explodes {@dice 1d6} rounds later, unless it is returned to the container.",
"Regardless of the type of orb, its effect is contained within a sphere with a 1 mile radius. The orb is the sphere's point of origin. The orb is destroyed after one use.",
{
"name": "Water Orb",
"type": "entries",
"entries": [
"When this orb detonates, it creates a torrential rainstorm that lasts for 24 hours. Within the area of effect, the rules for {@hazard heavy precipitation} apply, as detailed in chapter 5 of the Dungeon Master's Guide. If there is a substantial body of water in the area, it floods after {@dice 2d10} hours of heavy rain, rising 10 feet above its banks and inundating the surrounding area. The flood advances at a rate of 100 feet per round, moving away from the body of water where it began until it reaches the edge of the area of effect: at that point, the water flows downhill (and possibly recedes back to its origin). Light structures collapse and wash away. Any Large or smaller creature caught in the flood's path is swept away. The flooding destroys crops and might trigger mudslides, depending on the terrain."
]
}
]
},
{
"name": "Devlin's Staff of Striking",
"source": "ToA",
"page": 141,
"_copy": {
"name": "Staff of Striking",
"source": "DMG",
"_mod": {
"entries": {
"mode": "appendArr",
"items": "If you attune to this staff, you transform into a goat-humanoid hybrid over the course of 3 days. Tieflings are immune to this curse. Throughout the first day, shaggy fur begins to grow all over your body. After 24 hours, your eyes become goat-like, and stumpy horns sprout from your brow. On the last day, your fingers and toes meld into double digits, and the horns grow to full length. This transformation doesn't prevent you from wielding weapons or casting spells. {@spell Remove curse}, {@spell greater restoration}, or any other effect that ends a curse restores your original appearance, but only a {@spell wish} spell can rid the staff of its power to transform those who attune to it."
}
}
},
"curse": true
},
{
"name": "Devotee's Censer",
"source": "TCE",
"page": 126,
"baseItem": "flail|PHB",
"type": "M",
"rarity": "rare",
"reqAttune": "by a cleric or paladin",
"reqAttuneTags": [
{
"class": "cleric"
},
{
"class": "paladin"
}
],
"weight": 2,
"weaponCategory": "martial",
"dmg1": "1d8",
"dmgType": "B",
"weapon": true,
"entries": [
"The rounded head of this flail is perforated with tiny holes, arranged in symbols and patterns. The flail counts as a holy symbol for you. When you hit with an attack using this magic flail, the target takes an extra {@damage 1d8} radiant damage.",
"As a bonus action, you can speak the command word to cause the flail to emanate a thin cloud of incense out to 10 feet for 1 minute. At the start of each of your turns, you and any other creatures in the incense each regain {@dice 1d4} hit points. This property can't be used again until the next dawn."
],
"hasFluffImages": true
},
{
"name": "Diamond",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 500000,
"entries": [
"A transparent blue-white, canary, pink, brown, or blue gemstone."
]
},
{
"name": "Dice Set",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"type": "GS",
"rarity": "none",
"value": 10
},
{
"name": "Dimensional Loop",
"source": "AI",
"page": 220,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"bonusSavingThrow": "+1",
"entries": [
"While attuned to this device, you have a +1 bonus to Strength saving throws, and you have {@sense darkvision} out to a range of 60 feet. If you already have {@sense darkvision}, its range increases by 30 feet.",
{
"type": "entries",
"name": "Dimensional Cloak",
"entries": [
"As a bonus action, you send your body out of phase with the material world for 1 minute, granting you advantage on Dexterity ({@skill Stealth}) checks made to hide, and imposing disadvantage on attack rolls against you. Once you use this feature of the dimensional loop, it cannot be used again until the next dawn."
]
},
{
"type": "entries",
"name": "Fold Space",
"entries": [
"Choose a space you can see within 60 feet of you (no action required). You treat that space as if it were within 5 feet of you until the end of your turn. This allows you to move immediately to that space without provoking opportunity attacks, or to interact with objects or creatures in that space as though they were next to you (including allowing you to make melee attacks into that space). Once you use this feature of the dimensional loop, it cannot be used again until the next dawn."
]
},
{
"type": "entries",
"name": "Part of a Whole",
"entries": [
"While this component is not installed in the {@item Orrery of the Wanderer|AI}, its magic might function sporadically or with unpredictable side effects, as determined by the DM."
]
}
]
},
{
"name": "Dimensional Shackles",
"source": "DMG",
"page": 165,
"srd": true,
"tier": "major",
"rarity": "rare",
"wondrous": true,
"entries": [
"You can use an action to place these shackles on an {@condition incapacitated} creature. The shackles adjust to fit a creature of Small to Large size. In addition to serving as mundane manacles, the shackles prevent a creature bound by them from using any method of extradimensional movement, including teleportation or travel to a different plane of existence. They don't prevent the creature from passing-through an interdimensional portal.",
"You and any creature you designate when you use the shackles can use an action to remove them. Once every 30 days, the bound creature can make a DC 30 Strength ({@skill Athletics}) check. On a success, the creature breaks free and destroys the shackles."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Dimir Guild Signet",
"source": "GGR",
"page": 178,
"type": "RG",
"rarity": "uncommon",
"reqAttune": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"This ring, adorned with the symbol of Dimir, allows you to cast {@spell disguise self}. A guild signet is sometimes awarded to a guild member whose renown score in that guild is 5 or higher, as a reward for performing special services for the guild. Aside from its magical properties, the ring is also an indicator of Dimir's recognition and favor.",
"A signet has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. While wearing it, you can expend 1 charge to cast the associated spell (save DC 13)."
],
"attachedSpells": [
"disguise self"
],
"hasFluffImages": true
},
{
"name": "Dimir Keyrune",
"source": "GGR",
"page": 177,
"rarity": "very rare",
"reqAttune": "by a member of the Dimir guild",
"reqAttuneTags": [
{
"background": "dimir operative|ggr"
}
],
"wondrous": true,
"entries": [
"This keyrune, carved from black stone accented with steel, resembles a stylized horror. On command, it transforms into an {@creature intellect devourer} that resembles the Dimir guild symbol, with six bladelike legs. The creature exists for up to 24 hours. During that time, it pursues only a single mission you give it—usually an assignment to take over someone's body, either to impersonate that person for a brief time or to extract secrets from their mind. When the mission is complete, the creature returns to you, reports its success, and reverts to its keyrune form.",
"When you use an action to speak the item's command word and place the keyrune on the ground in an unoccupied space within 5 feet of you, the keyrune transforms into a {@creature intellect devourer}. If there isn't enough space for the creature, the keyrune doesn't transform.",
"The creature is friendly to you, your companions, and other members of your guild (unless those guild members are hostile to you). It understands your languages and obeys your spoken commands. If you issue no commands, the creature takes the {@action Dodge} action and moves to avoid danger.",
"At the end of the duration, the creature reverts to its keyrune form. It reverts early if it drops to 0 hit points or if you use an action to speak the command word again while touching it. When the creature reverts to its keyrune form, it can't transform again until 36 hours have passed."
],
"hasFluffImages": true
},
{
"name": "Diplomat's Pack",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 36,
"value": 3900,
"entries": [
"Includes:",
{
"type": "list",
"items": [
"a {@item chest|phb}",
"2 {@item Map or Scroll Case|phb|cases for maps and scrolls}",
"a set of {@item fine clothes|phb}",
"a {@item Ink (1-ounce bottle)|phb|bottle of ink}",
"an {@item ink pen|phb}",
"a {@item lamp|phb}",
"2 {@item Oil (flask)|phb|flasks of oil}",
"5 {@item Paper (one sheet)|phb|sheets of paper}",
"a {@item Perfume (vial)|phb|vial of perfume}",
"{@item sealing wax|phb}",
"{@item soap|phb}"
]
}
],
"packContents": [
"chest|phb",
{
"item": "map or scroll case|phb",
"quantity": 2
},
"fine clothes|phb",
"ink (1-ounce bottle)|phb",
"ink pen|phb",
"lamp|phb",
{
"item": "oil (flask)|phb",
"quantity": 2
},
{
"item": "paper (one sheet)|phb",
"quantity": 5
},
"perfume (vial)|phb",
"sealing wax|phb",
"soap|phb"
]
},
{
"name": "Disguise Kit",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"additionalSources": [
{
"source": "XGE",
"page": 81
}
],
"type": "T",
"rarity": "none",
"weight": 3,
"value": 2500,
"entries": [
"This pouch of cosmetics, hair dye, and small props lets you create disguises that change your physical appearance. Proficiency with this kit lets you add your proficiency bonus to any ability checks you make to create a visual disguise."
],
"additionalEntries": [
"The perfect tool for anyone who wants to engage in trickery, a disguise kit enables its owner to adopt a false identity.",
{
"type": "entries",
"name": "Components",
"entries": [
"A disguise kit includes cosmetics, hair dye, small props, and a few pieces of clothing."
]
},
{
"type": "entries",
"name": "Deception",
"entries": [
"In certain cases, a disguise can improve your ability to weave convincing lies."
]
},
{
"type": "entries",
"name": "Intimidation",
"entries": [
"The right disguise can make you look more fearsome, whether you want to scare someone away by posing as a plague victim or intimidate a gang of thugs by taking the appearance of a bully."
]
},
{
"type": "entries",
"name": "Performance",
"entries": [
"A cunning disguise can enhance an audience's enjoyment of a performance, provided the disguise is properly designed to evoke the desired reaction."
]
},
{
"type": "entries",
"name": "Persuasion",
"entries": [
"Folk tend to trust a person in uniform. If you disguise yourself as an authority figure, your efforts to persuade others are often more effective."
]
},
{
"type": "entries",
"name": "Create Disguise",
"entries": [
"As part of a long rest, you can create a disguise. It takes you 1 minute to don such a disguise once you have created it. You can carry only one such disguise on you at a time without drawing undue attention, unless you have a bag of holding or a similar method to keep them hidden. Each disguise weighs 1 pound.",
"At other times, it takes 10 minutes to craft a disguise that involves moderate changes to your appearance, and 30 minutes for one that requires more extensive changes."
]
},
{
"type": "table",
"caption": "Disguise Kit",
"colLabels": [
"Activity",
"DC"
],
"colStyles": [
"col-10",
"col-2 text-center"
],
"rows": [
[
"Cover injuries or distinguishing marks",
"10"
],
[
"Spot a disguise being used by someone else",
"15"
],
[
"Copy a humanoid's appearance",
"20"
]
]
}
]
},
{
"name": "Dispelling Stone",
"source": "EGW",
"page": 266,
"rarity": "very rare",
"wondrous": true,
"entries": [
"This smooth, rainbow-colored, egg-shaped stone can be thrown up to 30 feet and explodes in a 10-foot-radius sphere of magical energy on impact, destroying the stone. Any active spell of 5th level or lower in the sphere ends."
]
},
{
"name": "Docent",
"source": "ERLW",
"page": 276,
"otherSources": [
{
"source": "UAWGE",
"page": 120
}
],
"rarity": "rare",
"reqAttune": "by a warforged",
"reqAttuneTags": [
{
"race": "warforged|erlw"
}
],
"wondrous": true,
"sentient": true,
"entries": [
"A docent is a small metal sphere, about 2 inches across, studded with dragonshards. To attune to a docent, you must embed the item somewhere on your body, such as your chest or your eye socket.",
{
"type": "entries",
"name": "Sentience",
"entries": [
"A docent is a sentient item of any alignment with an Intelligence of 16, a Wisdom of 14, and a Charisma of 14. It perceives the world through your senses. It communicates telepathically with you and can speak, read, and understand any language it knows (see \"Random Properties\" below)."
]
},
{
"type": "entries",
"name": "Life Support",
"entries": [
"Whenever you end your turn with 0 hit points, the docent can make a Wisdom ({@skill Medicine}) check with a +6 bonus. If this check succeeds, the docent stabilizes you."
]
},
{
"type": "entries",
"name": "Random Properties",
"entries": [
"A docent has the following properties:",
"{@b Languages}. The docent knows Common, Giant, and {@dice 1d4} additional languages chosen by the DM. If a docent knows fewer than six languages, it can learn a new language after it hears or reads the language through your senses.",
"{@b Skills}. The docent has a +7 bonus to one of the following skills (roll a {@dice d4}): (1) {@skill Arcana}, (2) {@skill History}, (3) {@skill Investigation}, or (4) {@skill Nature}.",
"{@b Spells}. The docent knows one of the following spells and can cast it at will, requiring no components (roll a {@dice d6}): (1–2) {@spell detect evil and good} or (3–6) {@spell detect magic}. The docent decides when to cast the spell."
]
},
{
"type": "entries",
"name": "Personality",
"entries": [
"A docent is designed to advise and assist the warforged it's attached to. One of the simple functions of a docent is to serve as a translator. The docent's properties are under its control, and if you have a bad relationship with your docent, it might refuse to assist you."
]
}
],
"attachedSpells": [
"detect evil and good",
"detect magic"
]
},
{
"name": "Documancy Satchel",
"source": "AI",
"page": 24,
"rarity": "varies",
"wondrous": true,
"entries": [
"At rank 2, your documancy satchel becomes a common magic item, allowing you to magically send and receive documents to and from Head Office through a special pouch. Your documancy satchel magically produces prewritten and signature-ready contracts at your request, covering most common contractual needs. It also occasionally produces sticky notes printed with useful information and inspirational quotes from Head Office.",
{
"type": "entries",
"name": "Satchel of Holding",
"entries": [
"At rank 3, your documancy satchel gains additional features and becomes an uncommon magic item. One of the satchel's pouches now functions as a {@item bag of holding}.",
"Additionally, you can use an action to draw forth from the documancy satchel a {@item spell scroll} of {@spell comprehend languages}. The scroll vanishes when used, or ten minutes after it appears. This property of the bag can't be used again until the next dawn."
]
},
{
"type": "entries",
"name": "Scroll Humidor",
"entries": [
"At rank 4, your documancy satchel gains additional power and becomes a rare magic item. Within the satchel, a dedicated extradimensional space can hold up to thirty documents or {@item spell scroll||spell scrolls}. Placing a single document into the scroll humidor is an action. Drawing forth a desired scroll is a bonus action."
]
}
],
"attachedSpells": [
"comprehend languages"
],
"hasFluffImages": true
},
{
"name": "Dodecahedron of Doom",
"source": "WDMM",
"page": 284,
"rarity": "rare",
"wondrous": true,
"entries": [
"This twelve-sided metal die is 12 inches across and bears the numbers 1 through 12 engraved on its pentagonal sides. The dodecahedron contains arcane clockwork mechanisms that whir and click whenever the die is cast.",
"The dodecahedron can be hurled up to 60 feet as an action. A random magical effect occurs when the die comes to rest after rolling across the ground for at least 10 feet. If an effect requires a target and no eligible target is within range, nothing happens. Spells cast by the dodecahedron require no components. Roll a {@dice d12} and consult the following table to determine the effect:",
{
"type": "table",
"colLabels": [
"d12",
"effect"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"1–2",
"The dodecahedron explodes and is destroyed. Each creature within 20 feet of the exploding die must make a DC 13 Dexterity saving throw, taking 40 ({@damage 9d8}) force damage on a failed save, or half as much damage on a successful one."
],
[
"3–4",
"The dodecahedron casts {@spell light} on itself. The effect lasts until a creature touches the die."
],
[
"5–6",
"The dodecahedron casts {@spell ray of frost} (+5 to hit), targeting a random creature within 60 feet of it that doesn't have total cover against the attack."
],
[
"7–8",
"The dodecahedron casts {@spell shocking grasp} (+5 to hit) on the next creature that touches it."
],
[
"9–10",
"The dodecahedron casts {@spell darkness} on itself. The effect has a duration of 10 minutes."
],
[
"11–12",
"The next creature to touch the dodecahedron gains {@dice 1d10} temporary hit points that last for 1 hour."
]
]
}
],
"attachedSpells": [
"light",
"ray of frost",
"shocking grasp",
"darkness"
]
},
{
"name": "Dogsled",
"source": "IDRotF",
"page": 20,
"type": "VEH",
"rarity": "none",
"weight": 300,
"value": 2000,
"entries": [
"An empty sled costs 20 gp, weighs 300 pounds, and has room at the back for one driver."
]
},
{
"name": "Donkey",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "MNT",
"rarity": "none",
"value": 800,
"carryingCapacity": 420,
"speed": 40
},
{
"name": "Dowsing Dagger",
"source": "XMtS",
"page": 19,
"rarity": "unknown (magic)",
"entries": [
"A curved jade blade fashioned by the River Heralds, this {@item +1 dagger} can detect the presence of fresh water within 200 feet of the wielder."
]
},
{
"name": "Draakhorn",
"source": "RoT",
"page": 93,
"otherSources": [
{
"source": "ToD",
"page": 168
}
],
"rarity": "artifact",
"wondrous": true,
"entries": [
"The Draakhorn was a gift from {@creature Tiamat|RoT} in the war between dragons and giants. It was once the horn of her {@creature ancient red dragon} consort, Ephelomon, that she gave to dragonkind to help them in their war against the giants. The Draakhorn is a signaling device, and it is so large that it requires two Medium creatures (or one Large or larger) to hold it while a third creature sounds it, making the earth resonate to its call. The horn has been blasted with fire into a dark ebony hue and is wrapped in bands of bronze with draconic runes that glow with purple eldritch fire.",
"The low, moaning drone of the Draakhorn discomfits normal animals within a few miles, and it alerts all dragons within two thousand miles to rise and be wary, for great danger is at hand. Coded blasts were once used to signal specific messages. Knowledge of those codes has been lost to the ages.",
"Those with knowledge of the Draakhorn's history know that it was first built to signal danger to chromatic dragons—a purpose the Cult of the Dragon has corrupted to call chromatic dragons to the Well of Dragons from across the North.",
"Within 50 feet of any enclosed space where the horn is blown, the air begins to shimmer from the sound. Any character within 20 feet of the entry to the enclosed space must succeed on a DC 12 Strength check to continue pushing against the pressure of the sound. A failure indicates the character can advance no farther toward the entry.",
"For any character entering the enclosed space, the sound fades to silence—because any creature that enters the enclosed space is temporarily {@condition deafened} and must make a DC 12 Constitution saving throw. Success indicates the deafness ends 2 minutes after the Draakhorn ceases to sound. Failure indicates the character remains {@condition deafened} for 1 hour after the Draakhorn ceases to sound.",
"While the horn is sounding, a creature must make a DC 15 Constitution saving throw the first time on a turn the creature enters a 150-foot cone in front of the horn or starts its turn there. On a failed save, the creature takes 27 ({@damage 6d8}) thunder damage and is knocked {@condition prone}. On a successful save, the creature takes half damage and isn't knocked {@condition prone}."
],
"hasFluffImages": true
},
{
"name": "Draconic Longsword",
"source": "ToA",
"page": 173,
"baseItem": "longsword|phb",
"type": "M",
"rarity": "unknown (magic)",
"weight": 3,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"sword": true,
"weapon": true,
"entries": [
"This longsword has a dragon-shaped hilt. While you carry it, you gain the ability to speak and understand the Draconic language."
]
},
{
"name": "Draft Horse",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "MNT",
"rarity": "none",
"value": 5000,
"carryingCapacity": 540,
"speed": 40
},
{
"name": "Dragon",
"source": "WDH",
"page": 169,
"type": "$",
"rarity": "none",
"value": 100,
"entries": [
"Gold coin, half again as large as a {@item nib|wdh} (1 dragon = 100 {@item nib|wdh|nibs})"
]
},
{
"name": "Dragon Sensing Longsword",
"source": "PotA",
"page": 90,
"baseItem": "longsword|phb",
"type": "M",
"rarity": "unknown (magic)",
"weight": 3,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"bonusWeapon": "+1",
"entries": [
"This +1 longsword is made of dragon bone and with a dragon-leather grip. It has rubies in its pommel and hilt. The sword grows warm and the rubies glow slightly when the sword is within 120 feet of a dragon."
]
},
{
"name": "Dragon Thighbone Club",
"source": "SKT",
"page": 105,
"baseItem": "greatclub|phb",
"type": "M",
"rarity": "unknown (magic)",
"reqAttune": "optional",
"weight": 250,
"weaponCategory": "simple",
"property": [
"2H"
],
"dmg1": "1d8",
"dmgType": "B",
"weapon": true,
"entries": [
"This red dragon's thighbone is 14 feet long, 250 lbs, and is wrapped in old leather, suggesting that it was once used as a giant's greatclub.",
"When you hit a creature of the dragon type with this weapon, it deals an extra {@damage 2d8} bludgeoning damage.",
"If you attune to the greatclub, it magically shrinks to a size that you can wield effectively."
]
},
{
"name": "Dragon's Blood",
"source": "ERLW",
"page": 244,
"type": "OTH",
"rarity": "none",
"entries": [
"Introduced into Sharn by Daask, dragon's blood is a potent and highly addictive stimulant. In addition to inducing euphoria, it can enhance spellcasting ability or even temporarily imbue a user with the ability to cast sorcerer spells. The drug's effects are potentially dangerous and always unpredictable. This isn't something a player character should want to use; adventurers are more likely to interfere with Daask smugglers or deal with an addict who accidentally casts a {@spell fireball} in a crowded street. The specific effects of dragon's blood are up to you, but you can take inspiration from the {@table Wild Magic Surge|PHB} table in the {@book Player's Handbook|PHB}."
]
},
{
"name": "Dragonchess Set",
"source": "PHB",
"page": 154,
"basicRules": true,
"type": "GS",
"rarity": "none",
"weight": 0.5,
"value": 100
},
{
"name": "Dragongleam",
"source": "HotDQ",
"page": 69,
"otherSources": [
{
"source": "ToD",
"page": 84
}
],
"baseItem": "spear|phb",
"type": "M",
"rarity": "unknown (magic)",
"weight": 3,
"weaponCategory": "simple",
"property": [
"T",
"V"
],
"range": "20/60",
"dmg1": "1d6",
"dmgType": "P",
"dmg2": "1d8",
"charges": 10,
"weapon": true,
"entries": [
"This spear is enchanted with 10 charges of a {@spell daylight} spell for use in twilight or dark forest underbrush. The command phrase is \"Tiamat's eyes shine,\" written in Draconic runes on the spear's crossguard."
],
"attachedSpells": [
"daylight"
]
},
{
"name": "Dragonguard",
"source": "LMoP",
"page": 48,
"type": "MA",
"rarity": "rare",
"weight": 20,
"ac": 14,
"bonusAc": "+1",
"entries": [
"You have a +1 bonus to AC while wearing this armor.",
"This +1 breastplate has a gold dragon motif worked into its design. Created for a human hero of Neverwinter named Tergon, it grants its wearer advantage on saving throws against the breath weapons of creatures that have the dragon type."
]
},
{
"name": "Dragonstaff of Ahghairon",
"source": "WDH",
"page": 191,
"rarity": "legendary",
"reqAttune": true,
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d10}",
"charges": 10,
"staff": true,
"entries": [
"While holding the dragonstaff of Ahghairon, you have advantage on saving throws against the spells and breath weapons of dragons, as well as the breath weapons of other creatures of the dragon type (such as dragon turtles).",
"A creature of the dragon type that you touch with the staff can move through the city of Waterdeep, ignoring Ahghairon's dragonward (see \"Ahghairon's Dragonward,\" in the Introduction). This effect lasts until the creature is touched again by the staff or until a time you proclaim when you confer the benefit.",
"The staff has 10 charges. While holding it, you can expend 1 charge as an action to cast the {@spell command} spell. If you target a dragon with this casting, the dragon has disadvantage on its saving throw. The staff regains {@dice 1d10} charges daily at dawn."
],
"attachedSpells": [
"command"
],
"hasFluffImages": true
},
{
"name": "Dragontooth Dagger",
"source": "RoT",
"page": 94,
"otherSources": [
{
"source": "ToD",
"page": 178
}
],
"baseItem": "dagger|phb",
"type": "M",
"rarity": "rare",
"weight": 1,
"weaponCategory": "simple",
"property": [
"F",
"L",
"T"
],
"range": "20/60",
"dmg1": "1d4",
"dmgType": "P",
"bonusWeapon": "+1",
"entries": [
"A dagger fashioned from the tooth of a dragon. While the blade is obviously a fang or predator's tooth, the handle is leather wrapped around the root of the tooth, and there is no crossguard.",
"You gain a +1 bonus to attack and damage rolls made with this weapon. On a hit with this weapon, the target takes an extra {@damage 1d6} acid damage.",
{
"name": "Draconic Potency",
"type": "entries",
"entries": [
"Against enemies of the Cult of the Dragon, the dagger's bonus to attack and damage rolls increases to 2, and the extra acid damage increases to {@dice 2d6}."
]
}
],
"hasFluffImages": true
},
{
"name": "Dread Helm",
"source": "XGE",
"page": 137,
"tier": "minor",
"rarity": "common",
"wondrous": true,
"entries": [
"This fearsome steel helm makes your eyes glow red while you wear it."
],
"hasFluffImages": true
},
{
"name": "Dreamlily",
"source": "ERLW",
"page": 244,
"type": "OTH",
"rarity": "none",
"entries": [
"A psychoactive liquid that smells and tastes like your favorite beverage, essence of dreamlily is a Sarlonan opiate. First imported to help manage pain during the Last War, it's now the most commonly abused substance in Sharn. Though dreamlily isn't illegal if used for medicinal purposes, it's heavily taxed, and thus most dreamlily is smuggled in and sold on the black market. Dreamlily dens can be found across the lower wards. Consuming dreamlily causes disorienting euphoria and brings about remarkable resistance to pain. A creature under the effects of dreamlily is {@condition poisoned} for 1 hour. While {@condition poisoned} in this way, the creature is immune to fear, and the first time it drops to 0 hit points without being killed outright, it drops to 1 hit point instead. A dose of dreamlily costs around 1 gp, or up to ten times that if purchased through legal channels. There are many varieties of the drug, however, and the duration or the price might vary accordingly."
]
},
{
"name": "Driftglobe",
"source": "DMG",
"page": 166,
"tier": "minor",
"rarity": "uncommon",
"wondrous": true,
"weight": 1,
"entries": [
"This small sphere of thick glass weighs 1 pound. If you are within 60 feet of it, you can speak its command word and cause it to emanate the {@spell light} or {@spell daylight} spell. Once used, the {@spell daylight} effect can't be used again until the next dawn.",
"You can speak another command word as an action to make the illuminated globe rise into the air and float no more than 5 feet off the ground. The globe hovers in this way until you or another creature grasps it. If you move more than 60 feet from the hovering globe, it follows you until it is within 60 feet of you. It takes the shortest route to do so. If prevented from moving, the globe sinks gently to the ground and becomes inactive, and its light winks out."
],
"attachedSpells": [
"light",
"daylight"
],
"lootTables": [
"Magic Item Table A",
"Magic Item Table B"
],
"hasFluffImages": true
},
{
"name": "Drow Poison",
"source": "DMG",
"page": 258,
"srd": true,
"type": "G",
"rarity": "none",
"value": 20000,
"poison": true,
"entries": [
"This poison is typically made only by the {@creature drow}, and only in a place far removed from sunlight. A creature subjected to this poison must succeed on a DC 13 Constitution saving throw or be {@condition poisoned} for 1 hour. If the saving throw fails by 5 or more, the creature is also {@condition unconscious} while {@condition poisoned} in this way. The creature wakes up if it takes damage or if another creature takes an action to shake it awake."
],
"poisonTypes": [
"injury"
]
},
{
"name": "Drown",
"source": "PotA",
"page": 224,
"baseItem": "trident|phb",
"type": "M",
"resist": [
"cold"
],
"rarity": "legendary",
"reqAttune": true,
"weight": 4,
"weaponCategory": "martial",
"property": [
"T",
"V"
],
"range": "20/60",
"dmg1": "1d6",
"dmgType": "P",
"dmg2": "1d8",
"grantsProficiency": true,
"bonusWeapon": "+1",
"entries": [
"A steel trident decorated with bronze barnacles along the upper part of its haft, Drown has a sea-green jewel just below the tines and a silver shell at the end of its haft. It floats on the surface if dropped onto water, and it floats in place if it is released underwater. The trident is always cool to the touch, and it is immune to any damage due to exposure to water. Drown contains a spark of {@creature Olhydra|pota}, the Princess of Evil Water.",
"You gain a +1 bonus to attack and damage rolls made with this magic weapon. When you hit with it, the targets take an extra {@damage 1d8} cold damage.",
{
"name": "Water Mastery",
"type": "entries",
"entries": [
"You gain the following benefits while you hold Drown:"
]
},
{
"type": "list",
"items": [
"You can speak Aquan fluently.",
"You have resistance to cold damage.",
"You can cast {@spell dominate monster} (save DC 17) on a {@creature water elemental}. Once you have done so, Drown can't be used this way again until the next dawn."
]
},
{
"name": "Tears of Endless Anguish",
"type": "entries",
"entries": [
"While inside a water node, you can perform a ritual called the Tears of Endless Anguish, using Drown to create a {@item devastation orb of water|PotA}. Once you perform the ritual, Drown can't be used to perform the ritual again until the next dawn."
]
},
{
"name": "Flaw",
"type": "entries",
"entries": [
"Drown makes its wielder covetous. While attuned to the weapon, you gain the following flaw: \"I demand and deserve the largest share of the spoils, and I refuse to part with anything that's mine.\" In addition, if you are attuned to Drown for 24 consecutive hours, barnacles form on your skin. The barnacles can be removed with a {@spell greater restoration} spell or similar magic, but not while you are attuned to the weapon."
]
}
],
"attachedSpells": [
"dominate monster"
],
"hasFluffImages": true
},
{
"name": "Drum",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"type": "INS",
"rarity": "none",
"weight": 3,
"value": 600
},
{
"name": "Dulcimer",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"type": "INS",
"rarity": "none",
"weight": 10,
"value": 2500
},
{
"name": "Dungeoneer's Pack",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 61.5,
"value": 1200,
"entries": [
"Includes:",
{
"type": "list",
"items": [
"a {@item backpack|phb}",
"a {@item crowbar|phb}",
"a {@item hammer|phb}",
"10 {@item piton|phb|pitons}",
"10 {@item torch|phb|torches}",
"a {@item tinderbox|phb}",
"10 days of {@item Rations (1 day)|phb|rations}",
"a {@item waterskin|phb}",
"{@item Hempen Rope (50 feet)|phb|50 feet of hempen rope}"
]
}
],
"packContents": [
"backpack|phb",
"crowbar|phb",
"hammer|phb",
{
"item": "piton|phb",
"quantity": 10
},
{
"item": "torch|phb",
"quantity": 10
},
"tinderbox|phb",
{
"item": "rations (1 day)|phb",
"quantity": 10
},
"waterskin|phb",
"hempen rope (50 feet)|phb"
]
},
{
"name": "Duplicitous Manuscript",
"source": "TCE",
"page": 126,
"rarity": "rare",
"reqAttune": "by a wizard",
"reqAttuneTags": [
{
"class": "wizard"
}
],
"wondrous": true,
"weight": 3,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"focus": [
"Wizard"
],
"entries": [
"To you, this book is a magical spellbook. To anyone else, the book appears to be a volume of verbose romance fiction. As an action, you can change the book's appearance and alter the plot of the romance.",
"When found, the book contains the following spells: {@spell hallucinatory terrain}, {@spell major image}, {@spell mirror image}, {@spell mislead}, {@spell Nystul's magic aura}, {@spell phantasmal force}, and {@spell silent image}. It functions as a spellbook for you.",
"While you are holding the book, you can use it as a spellcasting focus for your wizard spells.",
"The book has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. You can use the charges in the following ways while holding it:",
{
"type": "list",
"items": [
"If you spend 1 minute studying the book, you can expend 1 charge to replace one of your prepared wizard spells with a different spell in the book. The new spell must be of the illusion school.",
"When a creature you can see makes an Intelligence ({@skill Investigation}) check to discern the true nature of an illusion spell you cast, or makes a saving throw against an illusion spell you cast, you can use your reaction and expend 1 charge to impose disadvantage on the roll."
]
}
],
"attachedSpells": [
"hallucinatory terrain",
"major image",
"mirror image",
"mislead",
"nystul's magic aura",
"phantasmal force",
"silent image"
]
},
{
"name": "Duskcrusher",
"source": "EGW",
"page": 266,
"baseItem": "warhammer|PHB",
"type": "M",
"rarity": "very rare",
"reqAttune": true,
"weight": 2,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "R",
"dmg2": "1d10",
"bonusWeapon": "+2",
"weapon": true,
"entries": [
"This item takes the form of a leather-wrapped metal rod emblazoned with the symbol of Pelor, the Dawn Father. While grasping the rod, you can use a bonus action to cause a warhammer head of crackling radiance to spring into existence. The warhammer's radiant head emits bright light in a 15-foot radius and dim light for an additional 15 feet. The light is sunlight. You can use an action to make the radiant head disappear.",
"While the radiant head is active, you gain a +2 bonus to attack and damage rolls made with this magic weapon, and attacks with the weapon deal radiant damage instead of bludgeoning damage. An undead creature hit by the weapon takes an extra {@damage 1d8} radiant damage.",
"While you are holding Duskcrusher and its radiant head is active, you can use an action to cast the {@spell sunbeam} spell (save DC 15) from the weapon, and this action can't be used again until the next dawn."
],
"attachedSpells": [
"sunbeam"
],
"hasFluffImages": true
},
{
"name": "Dust of Corrosion",
"source": "WBtW",
"page": 209,
"rarity": "uncommon",
"wondrous": true,
"entries": [
"As an action, you can throw this dust into the air, filling a 10-foot cube that extends out from you. Surfaces and objects made of nonmagical ferrous metal in the area instantly corrode and turn to dust, becoming useless and unsalvageable. Any creature in the area that is made wholly or partly out of ferrous metal must make a DC 13 Constitution saving throw, taking {@damage 4d8} necrotic damage on a failed save, or half as much damage on a successful one.",
"Found in a small packet, this dust is made from finely ground rust monster antennae. There is enough dust in each packet for one use."
]
},
{
"name": "Dust of Deliciousness",
"source": "EGW",
"page": 267,
"rarity": "uncommon",
"wondrous": true,
"entries": [
"This reddish brown dust can be sprinkled over any edible substance to greatly improve the flavor. The dust also dulls the eater's senses: anyone eating food treated with this dust has disadvantage on Wisdom ability checks and Wisdom saving throws for 1 hour. There is enough dust to flavor six servings."
]
},
{
"name": "Dust of Disappearance",
"source": "DMG",
"page": 166,
"srd": true,
"tier": "minor",
"rarity": "uncommon",
"wondrous": true,
"entries": [
"Found in a small packet, this powder resembles very fine sand. There is enough of it for one use. When you use an action to throw the dust into the air, you and each creature and object within 10 feet of you become {@condition invisible} for {@dice 2d4} minutes. The duration is the same for all subjects, and the dust is consumed when its magic takes effect. If a creature affected by the dust attacks or casts a spell, the invisibility ends for that creature."
],
"lootTables": [
"Magic Item Table B"
],
"hasFluffImages": true
},
{
"name": "Dust of Dryness",
"source": "DMG",
"page": 166,
"srd": true,
"tier": "minor",
"rarity": "uncommon",
"wondrous": true,
"entries": [
"This small packet contains {@dice 1d6 + 4} pinches of dust. You can use an action to sprinkle a pinch of it over water. The dust turns a cube of water 15 feet on a side into one marble-sized pellet, which floats or rests near where the dust was sprinkled. The pellet's weight is negligible.",
"Someone can use an action to smash the pellet against a hard surface, causing the pellet to shatter and release the water the dust absorbed. Doing so ends that pellet's magic.",
"An elemental composed mostly of water that is exposed to a pinch of the dust must make a DC 13 Constitution saving throw, taking {@damage 10d6} necrotic damage on a failed save, or half as much damage on a successful one."
],
"lootTables": [
"Magic Item Table B"
]
},
{
"name": "Dust of Sneezing and Choking",
"source": "DMG",
"page": 166,
"srd": true,
"tier": "minor",
"rarity": "uncommon",
"wondrous": true,
"entries": [
"Found in a small container, this powder resembles very fine sand. It appears to be {@item dust of disappearance}, and an {@spell identify} spell reveals it to be such. There is enough of it for one use.",
"When you use an action to throw a handful of the dust into the air, you and each creature that needs to breathe within 30 feet of you must succeed on a DC 15 Constitution saving throw or become unable to breathe while sneezing uncontrollably. A creature affected in this way is {@condition incapacitated} and suffocating. As long as it is conscious, a creature can repeat the saving throw at the end of each of its turns, ending the effect on it on a success. The {@spell lesser restoration} spell can also end the effect on a creature."
],
"lootTables": [
"Magic Item Table B"
],
"hasFluffImages": true
},
{
"name": "Dust of the Mummy",
"source": "IMR",
"page": 93,
"rarity": "none",
"poison": true,
"entries": [
"This poison is created during a mummy's embalming process, distilled from the dead creature's removed organs. A creature subjected to this poison must succeed on a DC 12 Constitution saving throw or be cursed with mummy rot. The cursed creature can't regain hit points, and its hit point maximum decreases by 10 ({@dice 3d6}) for every 24 hours that elapse. If the curse reduces the creature's hit point maximum to 0, the creature dies, and its body turns to dust. The curse lasts until removed by the {@spell remove curse} spell or similar magic."
],
"poisonTypes": [
"inhaled"
]
},
{
"name": "Dwarven Plate",
"source": "DMG",
"page": 167,
"srd": true,
"type": "HA",
"tier": "major",
"rarity": "very rare",
"weight": 65,
"ac": 18,
"strength": "15",
"bonusAc": "+2",
"stealth": true,
"entries": [
"While wearing this armor, you gain a +2 bonus to AC. In addition, if an effect moves you against your will along the ground, you can use your reaction to reduce the distance you are moved by up to 10 feet."
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Dwarven Thrower",
"source": "DMG",
"page": 167,
"srd": true,
"baseItem": "warhammer|phb",
"type": "M",
"tier": "major",
"rarity": "very rare",
"reqAttune": "by a dwarf",
"reqAttuneTags": [
{
"race": "dwarf"
}
],
"weight": 2,
"weaponCategory": "martial",
"property": [
"T",
"V"
],
"range": "20/60",
"dmg1": "1d8",
"dmgType": "B",
"dmg2": "1d10",
"bonusWeapon": "+3",
"entries": [
"You gain a +3 bonus to attack and damage rolls made with this magic weapon. It has the thrown property with a normal range of 20 feet and a long range of 60 feet. When you hit with a ranged attack using this weapon, it deals an extra {@damage 1d8} damage or, if the target is a giant, {@damage 2d8} damage. Immediately after the attack, the weapon flies back to your hand."
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Dynamite (stick)",
"source": "DMG",
"page": 268,
"type": "EXP",
"rarity": "none",
"weight": 1,
"age": "modern",
"entries": [
"As an action, a creature can light a stick of dynamite and throw it at a point up to 60 feet away. Each creature within 5 feet of that point must make a DC 12 Dexterity saving throw, taking {@damage 3d6} bludgeoning damage on a failed save, or half as much damage on a successful one.",
"A character can bind sticks of dynamite together so they explode at the same time. Each additional stick increases the damage by {@damage 1d6} (to a maximum of {@damage 10d6}) and the burst radius by 5 feet (to a maximum of 20 feet).",
"Dynamite can be rigged with a longer fuse to explode after a set amount of time, usually 1 to 6 rounds. Roll initiative for the dynamite. After the set number of rounds goes by, the dynamite explodes on that initiative."
]
},
{
"name": "Dyrrn's Tentacle Whip",
"source": "ERLW",
"page": 276,
"baseItem": "whip|phb",
"type": "M",
"rarity": "very rare",
"reqAttune": true,
"weight": 3,
"weaponCategory": "martial",
"property": [
"F",
"R"
],
"dmg1": "1d4",
"dmgType": "S",
"bonusWeapon": "+2",
"weapon": true,
"entries": [
"This long, whip-like strand of tough muscle bears a sharp stinger at one end. To attune to this symbiotic weapon, you wrap the whip around your wrist for the entire attunement period, during which time the whip painfully embeds its tendrils into your arm.",
"You gain a +2 bonus to attack and damage rolls made with this magic whip, but attack rolls made against aberrations with this weapon have disadvantage. A creature hit by this weapon takes an extra {@damage 1d6} psychic damage. When you roll a 20 on the {@dice d20} for an attack roll with this weapon, the target is {@condition stunned} until the end of its next turn.",
"As a bonus action, you can sheathe the whip by causing it to retract into your arm, or draw the whip out of your arm again.",
{
"type": "entries",
"name": "Symbiotic Nature",
"entries": [
"The whip can't be removed from you while you're attuned to it, and you can't voluntarily end your attunement to it. If you're targeted by a spell that ends a curse, your attunement to the whip ends, and it detaches from you."
]
}
]
},
{
"name": "Eagle Whistle",
"source": "TftYP",
"page": 228,
"rarity": "rare",
"wondrous": true,
"weight": 1,
"modifySpeed": {
"equal": {
"fly": "walk"
},
"multiply": {
"fly": 2
}
},
"entries": [
"While you blow an eagle whistle continuously, you can fly twice as fast as your walking speed. You can blow the whistle continuously for a number of rounds equal to 5 + five times your Constitution modifier (minimum of 1 round) or until you talk, hold your breath, or start suffocating. A use of the whistle also ends if you land. If you are aloft when you stop blowing the whistle, you fall. The whistle has three uses. It regains expended uses daily at dawn."
]
},
{
"name": "Ear Horn of Hearing",
"source": "XGE",
"page": 137,
"tier": "minor",
"rarity": "common",
"wondrous": true,
"entries": [
"While held up to your ear, this horn suppresses the effects of the {@condition deafened} condition on you, allowing you to hear normally."
]
},
{
"name": "Earring of Message",
"source": "CRCotN",
"page": 212,
"rarity": "common",
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d4 + 1}",
"charges": 5,
"entries": [
"The blue crystal of this earring is wrapped with delicate copper wire. The earring has 5 charges. While wearing it, you can use an action to expend 1 charge and cast the {@spell message} spell. The earring regains {@dice 1d4 + 1} expended charges daily at dawn."
],
"attachedSpells": [
"message"
],
"hasFluffImages": true
},
{
"name": "Earworm",
"source": "ERLW",
"page": 277,
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d4}",
"charges": 4,
"entries": [
"To attune to this symbiont, you must hold it against the skin behind your ear for the entire attunement period, whereupon it burrows into your head and bonds to your skull. While the earworm is inside you, you can speak, read, and write Deep Speech.",
{
"type": "entries",
"name": "Spells",
"entries": [
"The earworm has 4 charges. You can cast the following spells from it, expending the necessary number of charges (spell save DC 15): {@spell detect thoughts} (2 charges) or {@spell dissonant whispers} (1 charge). Each time you use the earworm to cast the {@spell detect thoughts} spell, it sends the information gleaned to the nearest daelkyr, or to the next nearest earworm until it reaches a daelkyr.",
"The earworm regains {@dice 1d4} expended charges daily at dawn."
]
},
{
"type": "entries",
"name": "Symbiotic Nature",
"entries": [
"The earworm can't be removed from you while you're attuned to it, and you can't voluntarily end your attunement to it. If you're targeted by a spell that ends a curse, your attunement to the earworm ends, and it exits your body."
]
}
],
"attachedSpells": [
"dissonant whispers",
"detect thoughts"
]
},
{
"name": "Efreeti Bottle",
"source": "DMG",
"page": 167,
"srd": true,
"tier": "major",
"rarity": "very rare",
"wondrous": true,
"weight": 1,
"entries": [
"This painted brass bottle weighs 1 pound. When you use an action to remove the stopper, a cloud of thick smoke flows out of the bottle. At the end of your turn, the smoke disappears with a flash of harmless fire, and an {@creature efreeti} appears in an unoccupied space within 30 feet of you.",
"The first time the bottle is opened, the DM rolls to determine what happens.",
{
"type": "table",
"colLabels": [
"{@dice d100}",
"Effect"
],
"colStyles": [
"col-1 text-center",
"col-11"
],
"rows": [
[
"01-10",
"The {@creature efreeti} attacks you. After fighting for 5 rounds, the {@creature efreeti} disappears, and the bottle loses its magic."
],
[
"11-90",
"The {@creature efreeti} serves you for 1 hour, doing as you command. Then the {@creature efreeti} returns to the bottle, and a new stopper contains it. The stopper can't be removed for 24 hours. The next two times the bottle is opened, the same effect occurs. If the bottle is opened a fourth time, the {@creature efreeti} escapes and disappears, and the bottle loses its magic."
],
[
"91-00",
"The {@creature efreeti} can cast the {@spell wish} spell three times for you. It disappears when it grants the final wish or after 1 hour, and the bottle loses its magic."
]
]
}
],
"attachedSpells": [
"wish"
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Efreeti Chain",
"source": "DMG",
"page": 167,
"type": "HA",
"immune": [
"fire"
],
"tier": "major",
"rarity": "legendary",
"reqAttune": true,
"weight": 55,
"ac": 16,
"strength": "13",
"bonusAc": "+3",
"stealth": true,
"entries": [
"While wearing this armor, you gain a +3 bonus to AC, you are immune to fire damage, and you can understand and speak Primordial. In addition, you can stand on and walk across molten rock as if it were solid ground."
],
"lootTables": [
"Magic Item Table I"
],
"hasFluffImages": true
},
{
"name": "Elder Cartographer's Glossography",
"source": "AI",
"page": 20,
"rarity": "uncommon",
"wondrous": true,
"entries": [
"When you attain rank 4, you gain a small tome that is an uncommon magic item. The elder cartographer's glossography grants advantage on Intelligence or Wisdom checks related to geographical features or locations."
]
},
{
"name": "Eldritch Claw Tattoo",
"source": "TCE",
"page": 126,
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"bonusWeapon": "+1",
"entries": [
"Produced by a special needle, this magic tattoo depicts clawlike forms and other jagged shapes.",
{
"type": "entries",
"name": "Tattoo Attunement",
"entries": [
"To attune to this item, you hold the needle to your skin where you want the tattoo to appear, pressing the needle there throughout the attunement process. When the attunement is complete, the needle turns into the ink that becomes the tattoo, which appears on the skin.",
"If your attunement to the tattoo ends, the tattoo vanishes, and the needle reappears in your space."
]
},
{
"type": "entries",
"name": "Magical Strikes",
"entries": [
"While the tattoo is on your skin, your unarmed strikes are considered magical for the purpose of overcoming immunity and resistance to nonmagical attacks, and you gain a +1 bonus to attack and damage rolls with unarmed strikes."
]
},
{
"type": "entries",
"name": "Eldritch Maul",
"entries": [
"As a bonus action, you can empower the tattoo for 1 minute. For the duration, each of your melee attacks with a weapon or an unarmed strike can reach a target up to 15 feet away from you, as inky tendrils launch toward the target. In addition, your melee attacks deal an extra {@damage 1d6} force damage on a hit. Once used, this bonus action can't be used again until the next dawn."
]
}
]
},
{
"name": "Eldritch Claw Tattoo",
"source": "UA2020SpellsAndMagicTattoos",
"page": 9,
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"bonusWeapon": "+1",
"entries": [
"This tattoo depicts clawlike forms and other jagged shapes. While the tattoo is on your skin, your unarmed strikes are considered magical for the purpose of overcoming immunity and resistance to nonmagical attacks, and you gain a +1 bonus to attack and damage rolls with unarmed strikes.",
{
"type": "entries",
"name": "Eldritch Maul",
"entries": [
"As a bonus action, you can empower the tattoo for 1 minute. For the duration, each of your melee weapon attacks can reach a target up to 30 feet away from you, as tendrils of ink launch from your weapon or unarmed strike toward the target. In addition, your melee weapon attacks deal an extra {@damage 1d6} force damage on a hit. Once used, this bonus action can't be used again until the next dawn."
]
},
{
"type": "entries",
"name": "Tattoo Attunement",
"entries": [
"To attune to this item, you hold the needle to your skin where you want the tattoo to appear, pressing the needle there throughout the attunement process. When the attunement is complete, the needle turns into the ink that becomes the tattoo, which appears on the skin. If you have multiple magic tattoos, they count as a single magic item with regard to the number of magic items you can attune to.",
"If your attunement to the tattoo ends, the tattoo vanishes, and the needle reappears in the closest unoccupied space to you."
]
}
]
},
{
"name": "Eldritch Staff",
"source": "WBtW",
"page": 209,
"srd": true,
"type": "M",
"rarity": "very rare",
"reqAttune": true,
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"bonusWeapon": "+1",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 4}",
"charges": 10,
"staff": true,
"entries": [
"This staff can be wielded as a magic quarterstaff that grants a +1 bonus to attack and damage rolls made with it.",
"The staff has 10 charges and regains {@dice 1d6 + 4} expended charges daily at dawn. If you expend the last charge, roll a {@dice d20}. On a 1, the staff is destroyed in an otherwise harmless burst of eldritch energy.",
{
"type": "entries",
"name": "Eldritch Attack",
"entries": [
"When you hit with a melee attack using the staff, you can expend up to 3 of its charges. For each charge you expend, the target takes an extra {@damage 1d8} lightning damage."
]
},
{
"type": "entries",
"name": "Eldritch Escape",
"entries": [
"If you take damage while holding the staff, you can use your reaction to expend 3 of the staff's charges, whereupon you turn {@condition invisible} and teleport yourself, along with any equipment you are wearing or carrying, up to 60 feet to an unoccupied space that you can see. You remain {@condition invisible} until the start of your next turn or until you attack, cast a spell, or deal damage."
]
}
]
},
{
"name": "Electrum (ep)",
"source": "PHB",
"page": 143,
"type": "$",
"rarity": "none",
"weight": 0.02,
"value": 50,
"entries": [
"Common coins come in several different denominations based on the relative worth of the metal from which they are made. The three most common coins are the gold piece (gp), the silver piece (sp), and the copper piece (cp).",
"With one gold piece, a character can buy a belt pouch, 50 feet of good rope, or a goat. A skilled (but not exceptional) artisan can earn one gold piece a day. The gold piece is the standard unit of measure for wealth, even if the coin itself is not commonly used. When merchants discuss deals that involve goods or services worth hundreds or thousands of gold pieces, the transactions don't usually involve the exchange of individual coins. Rather, the gold piece is a standard measure of value, and the actual exchange is in gold bars, letters of credit, or valuable goods.",
"One gold piece is worth ten silver pieces, the most prevalent coin among commoners. A silver piece buys a laborer's work for a day, a flask of lamp oil, or a night's rest in a poor inn.",
"One silver piece is worth ten copper pieces, which are common among laborers and beggars. A single copper piece buys a candle, a torch, or a piece of chalk.",
"In addition, unusual coins made of other precious metals sometimes appear in treasure hoards. The electrum piece (ep) and the platinum piece (pp) originate from fallen empires and lost kingdoms, and they sometimes arouse suspicion and skepticism when used in transactions. An electrum piece is worth five silver pieces, and a platinum piece is worth ten gold pieces.",
"A standard coin weighs about a third of an ounce, so fifty coins weigh a pound."
],
"hasFluffImages": true
},
{
"name": "Electrum 50-Zib Coin",
"source": "GGR",
"page": 9,
"type": "$",
"rarity": "none",
"value": 50
},
{
"name": "Elemental Essence Shard",
"source": "TCE",
"page": 127,
"rarity": "rare",
"reqAttune": "by a sorcerer",
"reqAttuneTags": [
{
"class": "sorcerer"
}
],
"wondrous": true,
"weight": 1,
"entries": [
"This crackling crystal contains the essence of an elemental plane. As an action, you can attach the shard to a Tiny object (such as a weapon or a piece of jewelry) or detach it. It falls off if your attunement to it ends. You can use the shard as a spellcasting focus while you hold or wear it.",
"Roll a {@dice d4} and consult the Elemental Essence Shards table to determine the shard's essence and property. When you use a Metamagic option on a spell while you are holding or wearing the shard, you can use that property.",
{
"type": "table",
"caption": "Elemental Essence Shards",
"colLabels": [
"d4",
"Element",
"Shard"
],
"colStyles": [
"col-2 text-center",
"col-2 text-center",
"col-8"
],
"rows": [
[
"1",
"Air",
"{@item Elemental Essence Shard (Air)|TCE}"
],
[
"2",
"Earth",
"{@item Elemental Essence Shard (Earth)|TCE}"
],
[
"3",
"Fire",
"{@item Elemental Essence Shard (Fire)|TCE}"
],
[
"4",
"Water",
"{@item Elemental Essence Shard (Water)|TCE}"
]
]
}
]
},
{
"name": "Elemental Essence Shard (Air)",
"source": "TCE",
"page": 127,
"rarity": "rare",
"reqAttune": "by a sorcerer",
"reqAttuneTags": [
{
"class": "sorcerer"
}
],
"wondrous": true,
"weight": 1,
"focus": [
"Sorcerer"
],
"entries": [
"This crackling crystal contains the essence of an elemental plane. As an action, you can attach the shard to a Tiny object (such as a weapon or a piece of jewelry) or detach it. It falls off if your attunement to it ends. You can use the shard as a spellcasting focus while you hold or wear it.",
"When you use a Metamagic option on a spell while you are holding or wearing the shard, you can use the following property:",
{
"type": "entries",
"name": "Property",
"entries": [
"You can immediately fly up to 60 feet without provoking opportunity attacks."
]
}
]
},
{
"name": "Elemental Essence Shard (Earth)",
"source": "TCE",
"page": 127,
"rarity": "rare",
"reqAttune": "by a sorcerer",
"reqAttuneTags": [
{
"class": "sorcerer"
}
],
"wondrous": true,
"weight": 1,
"focus": [
"Sorcerer"
],
"entries": [
"This crackling crystal contains the essence of an elemental plane. As an action, you can attach the shard to a Tiny object (such as a weapon or a piece of jewelry) or detach it. It falls off if your attunement to it ends. You can use the shard as a spellcasting focus while you hold or wear it.",
"When you use a Metamagic option on a spell while you are holding or wearing the shard, you can use the following property:",
{
"type": "entries",
"name": "Property",
"entries": [
"You gain resistance to a damage type of your choice until the start of your next turn."
]
}
]
},
{
"name": "Elemental Essence Shard (Fire)",
"source": "TCE",
"page": 127,
"rarity": "rare",
"reqAttune": "by a sorcerer",
"reqAttuneTags": [
{
"class": "sorcerer"
}
],
"wondrous": true,
"weight": 1,
"focus": [
"Sorcerer"
],
"entries": [
"This crackling crystal contains the essence of an elemental plane. As an action, you can attach the shard to a Tiny object (such as a weapon or a piece of jewelry) or detach it. It falls off if your attunement to it ends. You can use the shard as a spellcasting focus while you hold or wear it.",
"When you use a Metamagic option on a spell while you are holding or wearing the shard, you can use the following property:",
{
"type": "entries",
"name": "Property",
"entries": [
"One target of the spell that you can see catches fire. The burning target takes {@damage 2d10} fire damage at the start of its next turn, and then the flames go out."
]
}
]
},
{
"name": "Elemental Essence Shard (Water)",
"source": "TCE",
"page": 127,
"rarity": "rare",
"reqAttune": "by a sorcerer",
"reqAttuneTags": [
{
"class": "sorcerer"
}
],
"wondrous": true,
"weight": 1,
"focus": [
"Sorcerer"
],
"entries": [
"This crackling crystal contains the essence of an elemental plane. As an action, you can attach the shard to a Tiny object (such as a weapon or a piece of jewelry) or detach it. It falls off if your attunement to it ends. You can use the shard as a spellcasting focus while you hold or wear it.",
"When you use a Metamagic option on a spell while you are holding or wearing the shard, you can use the following property:",
{
"type": "entries",
"name": "Property",
"entries": [
"You create a wave of water that bursts out from you in a 10-foot radius. Each creature of your choice that you can see in that area takes {@damage 2d6} cold damage and must succeed on a Strength saving throw against your spell save DC or be pushed 10 feet away from you and fall {@condition prone}."
]
}
]
},
{
"name": "Elemental Gem, Blue Sapphire",
"source": "DMG",
"page": 167,
"srd": true,
"tier": "minor",
"rarity": "uncommon",
"wondrous": true,
"entries": [
"This gem contains a mote of elemental energy. When you use an action to break the gem, an {@creature air elemental} is summoned as if you had cast the {@spell conjure elemental} spell, and the gem's magic is lost."
],
"attachedSpells": [
"conjure elemental"
]
},
{
"name": "Elemental Gem, Emerald",
"source": "DMG",
"page": 167,
"srd": true,
"tier": "minor",
"rarity": "uncommon",
"wondrous": true,
"entries": [
"This gem contains a mote of elemental energy. When you use an action to break the gem, a {@creature water elemental} is summoned as if you had cast the {@spell conjure elemental} spell, and the gem's magic is lost."
],
"attachedSpells": [
"conjure elemental"
]
},
{
"name": "Elemental Gem, Red Corundum",
"source": "DMG",
"page": 167,
"srd": true,
"tier": "minor",
"rarity": "uncommon",
"wondrous": true,
"entries": [
"This gem contains a mote of elemental energy. When you use an action to break the gem, a {@creature fire elemental} is summoned as if you had cast the {@spell conjure elemental} spell, and the gem's magic is lost."
],
"attachedSpells": [
"conjure elemental"
]
},
{
"name": "Elemental Gem, Yellow Diamond",
"source": "DMG",
"page": 167,
"srd": true,
"tier": "minor",
"rarity": "uncommon",
"wondrous": true,
"entries": [
"This gem contains a mote of elemental energy. When you use an action to break the gem, an {@creature earth elemental} is summoned as if you had cast the {@spell conjure elemental} spell, and the gem's magic is lost."
],
"attachedSpells": [
"conjure elemental"
]
},
{
"name": "Elephant",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "MNT",
"rarity": "none",
"value": 20000,
"carryingCapacity": 1320,
"speed": 40
},
{
"name": "Elixir of Health",
"source": "DMG",
"page": 168,
"type": "P",
"tier": "minor",
"rarity": "rare",
"entries": [
"When you drink this potion, it cures any disease afflicting you, and it removes the {@condition blinded}, {@condition deafened}, {@condition paralyzed}, and {@condition poisoned} conditions. The clear red liquid has tiny bubbles of light in it."
],
"lootTables": [
"Magic Item Table C"
]
},
{
"name": "Elven Chain",
"source": "DMG",
"page": 168,
"srd": true,
"type": "MA",
"tier": "major",
"rarity": "rare",
"weight": 20,
"ac": 13,
"grantsProficiency": true,
"bonusAc": "+1",
"entries": [
"You gain a +1 bonus to AC while you wear this armor. You are considered proficient with this armor even if you lack proficiency with medium armor."
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Elven Trinket",
"source": "MTF",
"page": 64,
"type": "G",
"rarity": "none",
"entries": [
{
"type": "table",
"colLabels": [
"d8",
"Trinket"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"1",
"A small notebook that causes anything written in it to disappear after 1 hour"
],
[
"2",
"A crystal lens made of ivory and gold that causes anything observed through it to appear to be surrounded by motes of multicolored light"
],
[
"3",
"A small golden pyramid inscribed with elven symbols and about the size of a walnut"
],
[
"4",
"A cloak pin made from enamel in the shape of a butterfly; when you take the pin off, it turns into a real butterfly, and returns when you are ready to put your cloak back on again"
],
[
"5",
"A golden compass that points toward the nearest portal to the Feywild within 10 miles"
],
[
"6",
"A small silver spinning top that, when spun, endlessly spins until interrupted"
],
[
"7",
"A small songbird made of enamel, gold wire, and precious stone; uttering the songbird's name in Elvish causes the trinket to emit that bird's birdsong"
],
[
"8",
"A small enamel flower that, when put in one's hair, animates, tying back the wearer's hair with a living vine with flowers; plucking a single flower from this vine returns it to its inanimate form"
]
]
}
]
},
{
"name": "Emblem",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "SCF",
"scfType": "holy",
"rarity": "none",
"value": 500
},
{
"name": "Embroidered glove set with jewel chips",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 250000
},
{
"name": "Embroidered silk and velvet mantle set with numerous moonstones",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 250000
},
{
"name": "Embroidered silk handkerchief",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 2500
},
{
"name": "Emerald",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 100000,
"entries": [
"A transparent deep bright green gemstone."
]
},
{
"name": "Emerald Pen",
"source": "FTD",
"page": 23,
"rarity": "uncommon",
"wondrous": true,
"entries": [
"This pen is tipped with an emerald nib and requires no ink to write. While holding this pen, you can cast {@spell illusory script} at will, requiring no material components."
],
"attachedSpells": [
"illusory script"
]
},
{
"name": "Enchanted Three-Dragon Ante Set",
"source": "LLK",
"page": 15,
"_copy": {
"name": "Three-Dragon Ante Set",
"source": "PHB",
"_mod": {
"entries": {
"mode": "appendArr",
"items": "One card in this deck is enchanted to appear as whatever specific card its owner commands. This magic works only 25 percent of the time, but the deck is worth 500 gp to any serious gambler who doesn't mind the risk—or who isn't told about it."
}
}
},
"value": null
},
{
"name": "Enduring Spellbook",
"source": "XGE",
"page": 137,
"tier": "minor",
"rarity": "common",
"wondrous": true,
"entries": [
"This spellbook, along with anything written on its pages, can't be damaged by fire or immersion in water. In addition, the spellbook doesn't deteriorate with age."
]
},
{
"name": "Entertainer's Pack",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 38,
"value": 4000,
"entries": [
"Includes:",
{
"type": "list",
"items": [
"a {@item backpack|phb}",
"a {@item bedroll|phb}",
"2 {@item Costume Clothes|phb|costumes}",
"5 {@item candle|phb|candles}",
"5 days of {@item Rations (1 day)|phb|rations}",
"a {@item waterskin|phb}",
"a {@item disguise kit|phb}"
]
}
],
"packContents": [
"backpack|phb",
"bedroll|phb",
{
"item": "costume clothes|phb",
"quantity": 2
},
{
"item": "candle|phb",
"quantity": 5
},
{
"item": "rations (1 day)|phb",
"quantity": 5
},
"waterskin|phb",
"disguise kit|phb"
]
},
{
"name": "Ephixis, Bow of Nylea",
"source": "MOT",
"page": 200,
"baseItem": "shortbow|PHB",
"type": "R",
"rarity": "artifact",
"reqAttune": true,
"weight": 2,
"weaponCategory": "simple",
"property": [
"A",
"2H"
],
"range": "80/320",
"dmg1": "1d6",
"dmgType": "P",
"bonusWeapon": "+3",
"critThreshold": 19,
"weapon": true,
"entries": [
"Nylea wields the shortbow Ephixis, the bow whose arrows herald the turning of the seasons. Nylea is quick to let her favored followers borrow her bow, particularly when they intend to use it to perform great deeds, put an end to foul creatures, or cast down the arrogant.",
{
"type": "entries",
"name": "Bow of the Wild",
"entries": [
"This divine weapon includes a shortbow and a quiver with four arrows, each tied to one of the four seasons. You gain a +3 bonus to attack and damage rolls made with this bow, and you suffer no disadvantage when attacking at the weapon's long range. Additionally, Ephixis scores a critical hit on a {@dice d20} roll of 19 or 20."
]
},
{
"type": "entries",
"name": "Blessing of the Wild",
"entries": [
"If you are a worshiper of Nylea, you gain all the following benefits for which you have the required piety:",
{
"type": "list",
"style": "list-hang-notitle",
"items": [
{
"type": "item",
"name": "Piety 10+",
"entry": "The bow has 1 randomly determined {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} property."
},
{
"type": "item",
"name": "Piety 25+",
"entry": "The bow has 1 randomly determined {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial} property."
}
]
},
"If you aren't a worshiper of Nylea, the bow has 1 randomly determined {@table Artifact Properties; Major Detrimental Properties|dmg|major detrimental} property.",
"See \"Artifacts\" in chapter 7 of the Dungeon Master's Guide for details on randomly determined properties."
]
},
{
"type": "entries",
"name": "Arrows of the Seasons",
"entries": [
"The four arrows—each associated with a season—that accompany this bow can be fired only from it. Each arrow disappears immediately after it's used, and it reappears in the quiver at the next dusk. The save DC against spells cast with the arrows is 18. Each arrow has a unique property:"
]
},
{
"type": "entries",
"name": "Spring",
"entries": [
"As an action, you can fire this arrow, targeting a beast or a plant creature you can see within 320 feet of you. On a hit, the arrow deals no damage, and the target gains the benefits of the {@spell awaken} spell for 8 hours."
]
},
{
"type": "entries",
"name": "Summer",
"entries": [
"As an action, you can fire this arrow at an unoccupied space on the ground that you can see within 320 feet of you, no attack roll required. A Nyxborn lynx is summoned in that space (use the stat block for a {@creature tiger} with the {@book Nyxborn traits|MOT|6|Nyxborn Creatures} from chapter 6). The lynx understands your verbal commands and obeys them as best it can, and it takes its turns immediately after yours. The emissary remains for 1 hour before fading away."
]
},
{
"type": "entries",
"name": "Autumn",
"entries": [
"As an action, you can fire this arrow at a space on the ground that you can see within 320 feet of you, no attack roll required, casting the {@spell wall of thorns} spell there."
]
},
{
"type": "entries",
"name": "Winter",
"entries": [
"As an action, you can fire this arrow at a space that you can see within 320 feet of you, no attack roll required, casting the {@spell ice storm} spell there."
]
},
{
"type": "entries",
"name": "Destroying the Bow",
"entries": [
"The bow can't be destroyed without first destroying all its arrows. The winter arrow must be fed to a cerberus on the winter solstice. The spring arrow must be planted at the base of a Black Oak of Asphodel on the spring equinox. The summer arrow must be broken by a sea giant on the summer solstice. Then the autumn arrow must be shot into the carcass of a hydra on the autumnal equinox. When all four arrows are destroyed, the bow and quiver dissolve into dust."
]
}
],
"ammoType": "arrow|phb",
"attachedSpells": [
"awaken",
"wall of thorns",
"ice storm"
],
"hasFluffImages": true
},
{
"name": "Ersatz Eye",
"source": "XGE",
"page": 137,
"otherSources": [
{
"source": "EGW"
}
],
"tier": "minor",
"rarity": "common",
"reqAttune": true,
"wondrous": true,
"entries": [
"This artificial eye replaces a real one that was lost or removed. While the ersatz eye is embedded in your eye socket, it can't be removed by anyone other than you, and you can see through the tiny orb as though it were a normal eye."
]
},
{
"name": "Essence of Ether",
"source": "DMG",
"page": 258,
"srd": true,
"type": "G",
"rarity": "none",
"value": 30000,
"poison": true,
"entries": [
"A creature subjected to this poison must succeed on a DC 15 Constitution saving throw or become {@condition poisoned} for 8 hours. The {@condition poisoned} creature is {@condition unconscious}. The creature wakes up if it takes damage or if another creature takes an action to shake it awake."
],
"poisonTypes": [
"inhaled"
]
},
{
"name": "Everbright Lantern",
"source": "ERLW",
"page": 277,
"otherSources": [
{
"source": "UAWGE",
"page": 115
}
],
"rarity": "common",
"wondrous": true,
"entries": [
"This bullseye lantern contains an Eberron dragonshard that sheds light comparable to that produced by a {@spell continual flame} spell. An everbright lantern sheds light in a 120-foot cone; the closest 60 feet is bright light, and the farthest 60 feet is dim light."
]
},
{
"name": "Eversmoking Bottle",
"source": "DMG",
"page": 168,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"wondrous": true,
"weight": 1,
"entries": [
"Smoke leaks from the lead-stoppered mouth of this brass bottle, which weighs 1 pound. When you use an action to remove the stopper, a cloud of thick smoke pours out in a 60-foot radius from the bottle. The cloud's area is heavily obscured. Each minute the bottle remains open and within the cloud, the radius increases by 10 feet until it reaches its maximum radius of 120 feet.",
"The cloud persists as long as the bottle is open. Closing the bottle requires you to speak its command word as an action. Once the bottle is closed, the cloud disperses after 10 minutes. A moderate wind (11 to 20 miles per hour) can also disperse the smoke after 1 minute, and a strong wind (21 or more miles per hour) can do so after 1 round."
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Exotic Saddle",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TAH",
"rarity": "none",
"weight": 40,
"value": 6000,
"entries": [
"An exotic saddle is required for riding any aquatic or flying mount."
]
},
{
"name": "Explorer's Pack",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 59,
"value": 1000,
"entries": [
"Includes:",
{
"type": "list",
"items": [
"a {@item backpack|phb}",
"a {@item bedroll|phb}",
"a {@item mess kit|phb}",
"a {@item tinderbox|phb}",
"10 {@item torch|phb|torches}",
"10 days of {@item Rations (1 day)|phb|rations}",
"a {@item waterskin|phb}",
"{@item Hempen Rope (50 feet)|phb|50 feet of hempen rope}"
]
}
],
"packContents": [
"backpack|phb",
"bedroll|phb",
"mess kit|phb",
"tinderbox|phb",
{
"item": "torch|phb",
"quantity": 10
},
{
"item": "rations (1 day)|phb",
"quantity": 10
},
"waterskin|phb",
"hempen rope (50 feet)|phb"
]
},
{
"name": "Explosive Seed",
"source": "EGW",
"page": 225,
"type": "G",
"rarity": "none",
"entries": [
"This acorn-sized sphere of brass contains a small amount of blasting powder and a clockwork trigger. An explosive seed can be thrown up to 30 feet as an action, detonating on impact. Each creature within 5 feet of the exploding seed must make a DC 10 Dexterity saving throw, taking {@damage 1d8} bludgeoning damage on a failed save, or half as much damage on a successful one."
]
},
{
"name": "Eye Agate",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 1000,
"entries": [
"A translucent circles of gray, white, brown, blue, or green gemstone."
]
},
{
"name": "Eye of Vecna",
"source": "DMG",
"page": 224,
"rarity": "artifact",
"reqAttune": true,
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d4 + 4}",
"charges": 8,
"entries": [
"Seldom is the name of Vecna spoken except in a hushed voice. Vecna was, in his time, one of the mightiest of all wizards. Through dark magic and conquest, he forged a terrible empire. For all his power, Vecna couldn't escape his own mortality. He began to fear death and take steps to prevent his end from ever coming about.",
"{@creature Orcus|MTF}, the demon prince of undeath, taught Vecna a ritual that would allow him to live on as a {@creature lich}. Beyond death, he became the greatest of all liches. Even though his body gradually withered and decayed, Vecna continued to expand his evil dominion. So formidable and hideous was his temper that his subjects feared to speak his name. He was the Whispered One, the Master of the Spider Throne, the Undying King, and the Lord of the Rotted Tower.",
"Some say that Vecna's lieutenant Kas coveted the Spider Throne for himself, or that the sword his lord made for him seduced him into rebellion. Whatever the reason, Kas brought the Undying King's rule to an end in a terrible battle that left Vecna's tower a heap of ash. Of Vecna, all that remained were one hand and one eye, grisly artifacts that still seek to work the Whispered One's will in the world.",
"The Eye of Vecna and the {@item Hand of Vecna} might be found together or separately. The eye looks like a bloodshot organ torn free from the socket. The hand is a mummified and shriveled left extremity.",
"To attune to the eye, you must gouge out your own eye and press the artifact into the empty socket. The eye grafts itself to your head and remains there until you die. Once in place, the eye transforms into a golden eye with a slit for a pupil, much like that of a cat. If the eye is ever removed, you die.",
"To attune to the hand, you must lop off your left hand at the wrist and the press the artifact against the stump. The hand grafts itself to your arm and becomes a functioning appendage. If the hand is ever removed, you die.",
{
"name": "Random Properties",
"type": "entries",
"entries": [
"The Eye of Vecna and the {@item Hand of Vecna} each have the following random properties:"
]
},
{
"type": "list",
"items": [
"1 {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial property}",
"1 {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial property}",
"1 {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental property}"
]
},
{
"name": "Properties of the Eye",
"type": "entries",
"entries": [
"Your alignment changes to neutral evil, and you gain the following benefits:"
]
},
{
"type": "list",
"items": [
"You have {@sense truesight}.",
"You can use an action to see as if you were wearing a {@item ring of X-ray vision}. You can end this effect as a bonus action.",
"The eye has 8 charges. You can use an action and expend 1 or more charges to cast one of the following spells (save DC 18) from it: {@spell clairvoyance} (2 charges), {@spell crown of madness} (1 charge), {@spell disintegrate} (4 charges), {@spell dominate monster} (5 charges), or {@spell eyebite} (4 charges). The eye regains {@dice 1d4 + 4} expended charges daily at dawn. Each time you cast a spell from the eye, there is a {@chance 5} chance that Vecna tears your soul from your body, devours it, and then takes control of the body like a puppet. If that happens, you become an NPC under the DM's control."
]
},
{
"name": "Properties of the Eye and Hand",
"type": "entries",
"entries": [
"If you are attuned to both the hand and eye, you gain the following additional benefits:",
{
"type": "list",
"items": [
"You are immune to disease and poison.",
"Using the eye's X-ray vision never causes you to suffer {@condition exhaustion}.",
"You experience premonitions of danger and, unless you are {@condition incapacitated}, can't be {@quickref Surprise|PHB|3|0|surprised}.",
"If you start your turn with at least 1 hit point, you regain {@dice 1d10} hit points.",
"If a creature has a skeleton, you can attempt to turn its bones to jelly with a touch of the {@item Hand of Vecna}. You can do so by using an action to make a melee attack against a creature you can reach, using your choice of your melee attack bonus for weapons or spells. On a hit, the target must succeed on a DC 18 Constitution saving throw or drop to 0 hit points.",
"You can use an action to cast {@spell wish}. This property can't be used again until 30 days have passed."
]
}
]
},
{
"name": "Destroying the Eye and Hand",
"type": "entries",
"entries": [
"If the Eye of Vecna and the {@item Hand of Vecna} are both attached to the same creature, and that creature is slain by the {@item Sword of Kas}, both the eye and the hand burst into flame, turn to ash, and are destroyed forever. Any other attempt to destroy the eye or hand seems to work, but the artifact reappears in one of Vecna's many hidden vaults, where it waits to be rediscovered."
]
}
],
"attachedSpells": [
"wish",
"clairvoyance",
"crown of madness",
"disintegrate",
"dominate monster",
"eyebite"
],
"hasFluffImages": true
},
{
"name": "Eye patch with a mock eye set in blue sapphire and moonstone",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 250000
},
{
"name": "Eye patch with a mock eye set in blue sapphire and moonstone (Brazen Coalition)",
"source": "PSX",
"page": 25,
"type": "$",
"rarity": "none",
"value": 250000
},
{
"name": "Eyes of Charming",
"source": "DMG",
"page": 168,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": 3,
"charges": 3,
"entries": [
"These crystal lenses fit over the eyes. They have 3 charges. While wearing them, you can expend 1 charge as an action to cast the {@spell charm person} spell (save DC 13) on a humanoid within 30 feet of you, provided that you and the target can see each other. The lenses regain all expended charges daily at dawn."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"attachedSpells": [
"charm person"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Eyes of Minute Seeing",
"source": "DMG",
"page": 168,
"srd": true,
"tier": "minor",
"rarity": "uncommon",
"wondrous": true,
"entries": [
"These crystal lenses fit over the eyes. While wearing them, you can see much better than normal out to a range of 1 foot. You have advantage on Intelligence ({@skill Investigation}) checks that rely on sight while searching an area or studying an object within that range."
],
"lootTables": [
"Magic Item Table C"
]
},
{
"name": "Eyes of the Eagle",
"source": "DMG",
"page": 168,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"entries": [
"These crystal lenses fit over the eyes. While wearing them, you have advantage on Wisdom ({@skill Perception}) checks that rely on sight. In conditions of clear visibility, you can make out details of even extremely distant creatures and objects as small as 2 feet across."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Faerie Dust",
"source": "SKT",
"page": 35,
"type": "OTH",
"rarity": "unknown (magic)",
"entries": [
"A pinch of this dust can substitute for the material components of any enchantment spell of 3rd level or lower. If a pinch of faerie dust is sprinkled on a creature, roll percentile dice and consult the Faerie Dust table to determine the effect.",
{
"type": "table",
"caption": "Faerie Dust",
"colLabels": [
"d100",
"Magical Effect"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"01-70",
"The creature sprinkled with dust gains a flying speed of 60 feet for 10 minutes."
],
[
"71-80 ",
"The creature sprinkled with dust must succeed on a DC 11 Constitution saving throw or fall {@condition unconscious} for 1 minute. The creature awakens if it takes damage or if it is shaken or slapped as an action."
],
[
"81-90",
"The creature sprinkled with dust must succeed on a DC 11 Wisdom saving throw or be affected by a {@spell confusion} spell."
],
[
"91-00",
"The creature sprinkled with dust becomes {@condition invisible} for 1 hour. Any equipment it is wearing or carrying is {@condition invisible} as long as it is on the creature's person. The effect on the creature ends if it attacks, deals any damage, or casts a spell."
]
]
}
]
},
{
"name": "Failed Experiment Wand",
"source": "AI",
"page": 162,
"type": "WD",
"rarity": "rare",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"weight": 1,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 1}",
"charges": 2,
"entries": [
"This wand has 2 charges. While holding it, you can use an action to expend 1 or more of its charges to cast either a green-flamed {@spell fireball} or a blue {@spell lightning bolt} at random from it (save DC 15).",
"For 1 charge, you cast the 3rd-level version of the spell. You can increase the spell slot level by one for each additional charge you expend.",
"The wand regains {@dice 1d6 + 1} expended charges daily at dawn. If you expend the wand's last charge, roll a {@dice d20}. On a 1, the wand crumbles into ashes and is destroyed."
],
"attachedSpells": [
"fireball",
"lightning bolt"
]
},
{
"name": "Falkir's Helm of Pigheadedness",
"source": "WDMM",
"page": 141,
"type": "OTH",
"rarity": "unknown (magic)",
"curse": true,
"entries": [
"This steel helm is shaped like the head of a boar. Once you don the helm, it can't be removed until you die or until a {@spell remove curse} spell or similar magic is cast on it. If you wear the helm and are a humanoid, you gain the following flaw until the helm is removed: \"I'm exceedingly stubborn and think I'm right all the time.\" (This flaw supersedes any conflicting flaw.)"
]
},
{
"name": "Fane-Eater",
"source": "BGDIA",
"page": 223,
"baseItem": "battleaxe|phb",
"type": "M",
"rarity": "legendary",
"reqAttune": "by an evil cleric or paladin",
"reqAttuneTags": [
{
"class": "cleric",
"alignment": [
"E"
]
},
{
"class": "paladin",
"alignment": [
"E"
]
}
],
"weight": 4,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"bonusWeapon": "+3",
"entries": [
"Fane-Eater is a battleaxe belonging to {@creature Arkhan the Cruel|BGDIA}.",
"You gain a +3 bonus to attack and damage rolls with Fane-Eater. If you attack a creature with this weapon and roll a 20 on the attack roll, the creature takes an extra {@damage 2d8} necrotic damage, and you regain a number of hit points equal to the necrotic damage taken."
]
},
{
"name": "Far Gear",
"source": "AI",
"page": 221,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"bonusSavingThrow": "+1",
"entries": [
"While attuned to this device, you have a +1 bonus to Charisma saving throws, and you have advantage on Charisma ({@skill Intimidation}) checks.",
{
"type": "entries",
"name": "Aberrant Ally",
"entries": [
"You can conjure an aberrant creature from the chaos of the multiverse to serve you. This functions as the {@spell conjure celestial} spell (no concentration required), except the creature you summon is an aberration of challenge rating 4 or lower. Once you use this feature of the far gear, it cannot be used again until the next dawn."
]
},
{
"type": "entries",
"name": "Unnatural Bane",
"entries": [
"You can cast the {@spell bane} spell (save DC 15), which affects any number of creatures within range for 1 minute. Once you use this feature of the far gear, it cannot be used again until the next dawn."
]
},
{
"type": "entries",
"name": "Part of a Whole",
"entries": [
"While this component is not installed in the {@item Orrery of the Wanderer|AI}, its magic might function sporadically or with unpredictable side effects, as determined by the DM."
]
}
],
"attachedSpells": [
"conjure celestial",
"bane"
]
},
{
"name": "Far Realm Shard",
"source": "TCE",
"page": 127,
"rarity": "rare",
"reqAttune": "by a sorcerer",
"reqAttuneTags": [
{
"class": "sorcerer"
}
],
"wondrous": true,
"weight": 1,
"focus": [
"Sorcerer"
],
"entries": [
"This writhing crystal is steeped in the warped essence of the Far Realm. As an action, you can attach the shard to a Tiny object (such as a weapon or a piece of jewelry) or detach it. It falls off if your attunement to it ends. You can use the shard as a spellcasting focus while you hold or wear it.",
"When you use a Metamagic option on a spell while you are holding or wearing the shard, you can cause a slimy tentacle to rip through the fabric of reality and strike one creature you can see within 30 feet of you. The creature must succeed on a Charisma saving throw against your spell save DC or take {@damage 3d6} psychic damage and become {@condition frightened} of you until the start of your next turn."
],
"hasFluffImages": true
},
{
"name": "Fargab",
"source": "DSotDQ",
"page": 188,
"type": "G",
"rarity": "none",
"entries": [
"These backpack-sized devices are created in pairs, with matching numbers engraved on them, and allow communication over a long distance using radio frequencies. While wearing a fargab, you can use an action to speak into the device's mouthpiece and send a short message of twenty-five words or less to another creature wearing the matched fargab while it is within 18 miles of you. The message emits from the speakers of the device and can be heard up to 10 feet away from the device. If no creature is wearing the fargab, the speakers make static noises instead."
],
"hasFluffImages": true
},
{
"name": "Feather of Diatryma Summoning",
"source": "WDH",
"page": 191,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"This bright plume is made from the feather of a {@creature diatryma|wdh} (pronounced dee-ah-TRY-mah), a Large, colorful, flightless bird native to the Underdark. If you use an action to speak the command word and throw the feather into a Large unoccupied space on the ground within 5 feet of you, the feather becomes a living diatryma for up to 6 hours, after which it reverts to its feather form. It reverts to feather form early if it drops to 0 hit points or if you use an action to speak the command word again while touching the bird.",
"When the diatryma reverts to feather form, the magic of the feather can't be used again until 7 days have passed.",
"The diatryma uses the statistics of an {@creature axe beak}, except that its beak deals piercing damage instead of slashing damage. The creature is friendly to you and your companions, and it can be used as a mount. It understands your languages and obeys your spoken commands. If you issue no commands, the diatryma defends itself but takes no other actions."
]
},
{
"name": "Feather Token",
"source": "ERLW",
"page": 277,
"otherSources": [
{
"source": "UAWGE",
"page": 115
}
],
"rarity": "common",
"wondrous": true,
"entries": [
"This small metal disk is inscribed with the image of a feather. When you fall at least 20 feet while the token is on your person, you descend 60 feet per round and take no damage from falling. The token's magic is expended after you land, whereupon the disk becomes nonmagical."
]
},
{
"name": "Feathered mantle with emerald clasp (Sun Empire)",
"source": "PSX",
"page": 25,
"type": "$",
"rarity": "none",
"value": 250000
},
{
"name": "Feed (per day)",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TAH",
"rarity": "none",
"weight": 10,
"value": 5
},
{
"name": "Feywild Shard",
"source": "TCE",
"page": 127,
"rarity": "uncommon",
"reqAttune": "by a sorcerer",
"reqAttuneTags": [
{
"class": "sorcerer"
}
],
"wondrous": true,
"weight": 1,
"focus": [
"Sorcerer"
],
"entries": [
"This warm crystal glints with the sunset colors of the Feywild sky and evokes whispers of emotional memory. As an action, you can attach the shard to a Tiny object (such as a weapon or a piece of jewelry) or detach it. It falls off if your attunement to it ends. You can use the shard as a spellcasting focus while you hold or wear it.",
"When you use a Metamagic option on a spell while you are holding or wearing the shard, you can roll on the {@table Wild Magic Surge|PHB} table in the {@book Player's Handbook|PHB}. If the result is a spell, it is too wild to be affected by your Metamagic, and if it normally requires concentration, it doesn't require concentration in this case; the spell lasts for its full duration.",
"If you don't have the Wild Magic Sorcerous Origin, once this property is used to roll on the {@table Wild Magic Surge|PHB} table, it can't be used again until the next dawn."
],
"hasFluffImages": true
},
{
"name": "Feywild Trinket",
"source": "WBtW",
"page": 7,
"type": "G",
"rarity": "none",
"entries": [
"The Feywild Trinkets table that follows offers a selection of trinkets appropriate for Feywild-themed adventures such as this one. Players whose characters have the feylost or Witchlight hand background can roll on this table instead of the {@book Trinkets|PHB|5|Trinkets} table in the {@book Player's Handbook|PHB} to determine what trinkets their characters possess. The adventure includes a few encounters that require you to roll on the table to determine what random trinkets the characters find in key locations.",
{
"type": "table",
"caption": "Feywild Trinkets",
"colLabels": [
"d100",
"Trinket"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"01",
"Cookie cutter shaped like a unicorn"
],
[
"02",
"Two yew rings linked together"
],
[
"03",
"Silver hand mirror with a nymph-shaped handle"
],
[
"04",
"Painted wooden key whose teeth change configuration every day at dawn"
],
[
"05",
"Delicate silver cameo with pictures of twin children opposite one another"
],
[
"06",
"Golden pendant charm shaped like a leprechaun"
],
[
"07",
"Tiny wooden box containing a croquet set sized for pixies or sprites"
],
[
"08",
"Tiny pair of sharp, iron scissors"
],
[
"09",
"Chess piece shaped like a dancing satyr wearing a bishop's hat and clutching a gnarled staff"
],
[
"10",
"Saltshaker shaped like a wizard's tower"
],
[
"11",
"Crystal orb that allows an elf who holds it to sleep"
],
[
"12",
"Pendant that shows the phases of the moon"
],
[
"13",
"Large iron fingernail"
],
[
"14",
"Tiny electrum whistle that only Fey can hear"
],
[
"15",
"Wooden jigsaw puzzle piece as big as a saucer, with a painted image of a jug on it"
],
[
"16",
"Spool of glistening silver thread"
],
[
"17",
"Sheet of music that goblins find upsetting when they hear it played or sung"
],
[
"18",
"Rotten ogre's tooth with the Elvish glyph for \"moon\" etched into it"
],
[
"19",
"Vitrified eye of a displacer beast"
],
[
"20",
"Tiny duskwood coffin containing the ashes of a troll"
],
[
"21",
"Old invitation to a banquet in the Summer Court, written in ink on vellum in Sylvan"
],
[
"22",
"Gossamer shawl that glows faintly in moonlight"
],
[
"23",
"Ball-and-cup toy that plays a short, victorious jingle whenever the ball lands in the cup"
],
[
"24",
"Sprite's skull covered in ink fingerprints"
],
[
"25",
"Silver fork with the outer tines bent sideways"
],
[
"26",
"A soot-stained sock in which a nugget of coal magically appears each day at dawn"
],
[
"27",
"Tiny wooden stool (sized for a pixie or sprite) that gives splinters to those who hold it"
],
[
"28",
"Tiny clockwork dragonfly that slowly beats its wings (but can't fly) when wound up"
],
[
"29",
"Toy unicorn made of wood, painted with bright colors"
],
[
"30",
"Pixie plushie that sings when you squeeze it"
],
[
"31",
"1-inch-square painting of a sleeping elf"
],
[
"32",
"Thimble that helps you daydream when worn"
],
[
"33",
"Pumpkin cupcake that magically regenerates itself in its paper cup each day at dawn"
],
[
"34",
"Fake Three-Dragon Ante card depicting a faerie dragon"
],
[
"35",
"Teacup made from a varnished mushroom cap that magically keeps its liquid contents lukewarm"
],
[
"36",
"Rock that floats and is small enough to hide in your closed fist"
],
[
"37",
"Tiny bottle filled with rainwater collected from the Feywild"
],
[
"38",
"Opalescent conch shell that laughs when you hold it to your ear"
],
[
"39",
"Vial of viscous liquid labeled \"Fomorian spit. Do not drink!\""
],
[
"40",
"Wax candle that roars and crackles like a bonfire while lit"
],
[
"41",
"Potted daffodil that sways when near a source of music"
],
[
"42",
"8-ounce, glass wine bottle that magically reassembles itself 1 minute after being broken"
],
[
"43",
"Tiny wooden sylph figurehead from a model ship"
],
[
"44",
"Tiny pumpkin-shaped cauldron carved out of bog oak"
],
[
"45",
"Bar of soap that smells like something memorable from your childhood"
],
[
"46",
"Piece of orange parchment folded to look like a knight astride a unicorn"
],
[
"47",
"Tinted glasses so dark that they can't be seen through"
],
[
"48",
"8-inch-long glass ant figurine"
],
[
"49",
"Piece of parchment bearing a child's drawing of an oni"
],
[
"50",
"Tiny hourglass without sand in it"
],
[
"51",
"Empty vial with corked ends at the top and bottom"
],
[
"52",
"Pair of green leprechaun boots tied together by their laces"
],
[
"53",
"Smoking pipe made from a tree root"
],
[
"54",
"Red cap that droops down to one's shoulders when worn"
],
[
"55",
"Mask that helps you remember your dreams if you wear it while you sleep"
],
[
"56",
"Notebook that shows what's written on it only when held upside down"
],
[
"57",
"Wooden top with four sides, each bearing the image of child enjoying a different season"
],
[
"58",
"Tiny beehive wig made for sprites or pixies"
],
[
"59",
"Wooden mouse figurine that squeaks when held"
],
[
"60",
"Stuffed oni doll with a creepy smile and one missing eye"
],
[
"61",
"Empty bag labeled \"Candy\""
],
[
"62",
"Tinted glass monocle that makes things look green"
],
[
"63",
"Black executioner's hood sized for a pixie or sprite"
],
[
"64",
"Piano key carved from a satyr's horn"
],
[
"65",
"Tiny wooden lute with cat hairs for strings"
],
[
"66",
"Iron needle with an eye that refuses to let thread pass through it"
],
[
"67",
"Tiny sundial that casts a shadow only in moonlight"
],
[
"68",
"Wooden pan flute that attracts harmless local fauna when played"
],
[
"69",
"Silvered pinecone"
],
[
"70",
"Flask of spectral glowworms that change color to reflect the mood of the flask's holder"
],
[
"71",
"Wooden apple painted blue"
],
[
"72",
"Tuning fork that sounds the tone for the F key"
],
[
"73",
"Nunchaku sized for a pixie or sprite"
],
[
"74",
"Copper coin with a smiling satyr's face on one side and a satyr's skull on the other"
],
[
"75",
"Severed chicken's foot attached to a leather cord"
],
[
"76",
"Collection of baby teeth in a tiny wooden box"
],
[
"77",
"Pinwheel whirligig that spins even when there's no wind"
],
[
"78",
"Child's parasol covered in moss and leaves"
],
[
"79",
"Wooden magnifying glass missing its lens"
],
[
"80",
"Glossy mushroom with a red, bell-shaped cap that jingles when shook"
],
[
"81",
"Pouch of seeds that smell like home"
],
[
"82",
"Petrified robin's egg"
],
[
"83",
"Wooden spoon with a hole in the center"
],
[
"84",
"Paper wasp nest in a jar"
],
[
"85",
"Sprig of rosemary wrapped with ribbon at one end"
],
[
"86",
"Tiny, unfurnished dollhouse sized for a pixie child"
],
[
"87",
"Paintbrush made entirely of ceramic—even the bristles"
],
[
"88",
"Candlestick whose candlelight looks like a tiny, dancing fairy made of fire"
],
[
"89",
"Spectacle frames in the shape of butterfly wings"
],
[
"90",
"Set of false wooden teeth"
],
[
"91",
"Tiny book of fairytales"
],
[
"92",
"Rucksack in which one potato magically appears each day at dawn"
],
[
"93",
"Pixie's winter jacket lined with fox fur"
],
[
"94",
"Tasseled wine charm shaped like a sprite"
],
[
"95",
"Weak magnetic wand"
],
[
"96",
"100-sided die the size of a plum, cut from coal"
],
[
"97",
"Glass slipper, missing its mate"
],
[
"98",
"Tiny dreamcatcher"
],
[
"99",
"Barbell sized for a pixie or sprite"
],
[
"100",
"Music box that plays a sprightly tune you remember from your childhood"
]
]
}
],
"hasFluffImages": true
},
{
"name": "Figurine of Wondrous Power, Bronze Griffon",
"source": "DMG",
"page": 169,
"srd": true,
"tier": "major",
"rarity": "rare",
"wondrous": true,
"entries": [
"A figurine of wondrous power is a statuette of a beast small enough to fit in a pocket. If you use an action to speak the command word and throw the figurine to a point on the ground within 60 feet of you, the figurine becomes a living creature. If the space where the creature would appear is occupied by other creatures or objects, or if there isn't enough space for the creature, the figurine doesn't become a creature.",
"The creature is friendly to you and your companions. It understands your languages and obeys your spoken commands. If you issue no commands, the creature defends itself but takes no other actions.",
"The creature exists for a duration specific to each figurine. At the end of the duration, the creature reverts to its figurine form. It reverts to a figurine early if it drops to 0 hit points or if you use an action to speak the command word again while touching it. When the creature becomes a figurine again, its property can't be used again until a certain amount of time has passed, as specified in the figurine's description.",
"{@creature griffon||Bronze Griffon}.",
"This bronze statuette is of a {@creature griffon} rampant. It can become a {@creature griffon} for up to 6 hours. Once it has been used, it can't be used again until 5 days have passed."
],
"lootTables": [
"Magic Item Table G"
]
},
{
"name": "Figurine of Wondrous Power, Ebony Fly",
"source": "DMG",
"page": 169,
"srd": true,
"tier": "major",
"rarity": "rare",
"wondrous": true,
"entries": [
"A figurine of wondrous power is a statuette of a beast small enough to fit in a pocket. If you use an action to speak the command word and throw the figurine to a point on the ground within 60 feet of you, the figurine becomes a living creature. If the space where the creature would appear is occupied by other creatures or objects, or if there isn't enough space for the creature, the figurine doesn't become a creature.",
"The creature is friendly to you and your companions. It understands your languages and obeys your spoken commands. If you issue no commands, the creature defends itself but takes no other actions.",
"The creature exists for a duration specific to each figurine. At the end of the duration, the creature reverts to its figurine form. It reverts to a figurine early if it drops to 0 hit points or if you use an action to speak the command word again while touching it. When the creature becomes a figurine again, its property can't be used again until a certain amount of time has passed, as specified in the figurine's description.",
"{@creature giant fly|dmg|Ebony Fly}.",
"This ebony statuette is carved in the likeness of a horsefly. It can become a {@creature giant fly|dmg} for up to 12 hours and can be ridden as a mount. Once it has been used, it can't be used again until 2 days have passed."
],
"lootTables": [
"Magic Item Table G"
]
},
{
"name": "Figurine of Wondrous Power, Golden Lions",
"source": "DMG",
"page": 169,
"srd": true,
"tier": "major",
"rarity": "rare",
"wondrous": true,
"entries": [
"A figurine of wondrous power is a statuette of a beast small enough to fit in a pocket. If you use an action to speak the command word and throw the figurine to a point on the ground within 60 feet of you, the figurine becomes a living creature. If the space where the creature would appear is occupied by other creatures or objects, or if there isn't enough space for the creature, the figurine doesn't become a creature.",
"The creature is friendly to you and your companions. It understands your languages and obeys your spoken commands. If you issue no commands, the creature defends itself but takes no other actions.",
"The creature exists for a duration specific to each figurine. At the end of the duration, the creature reverts to its figurine form. It reverts to a figurine early if it drops to 0 hit points or if you use an action to speak the command word again while touching it. When the creature becomes a figurine again, its property can't be used again until a certain amount of time has passed, as specified in the figurine's description.",
"{@creature lion||Golden Lions}.",
"These gold statuettes of lions are always created in pairs. You can use one figurine or both simultaneously. Each can become a {@creature lion} for up to 1 hour. Once a {@creature lion} has been used, it can't be used again until 7 days have passed."
],
"lootTables": [
"Magic Item Table G"
]
},
{
"name": "Figurine of Wondrous Power, Ivory Goats",
"source": "DMG",
"page": 169,
"srd": true,
"tier": "major",
"rarity": "rare",
"wondrous": true,
"bonusWeapon": "+1",
"charges": 24,
"entries": [
"A figurine of wondrous power is a statuette of a beast small enough to fit in a pocket. If you use an action to speak the command word and throw the figurine to a point on the ground within 60 feet of you, the figurine becomes a living creature. If the space where the creature would appear is occupied by other creatures or objects, or if there isn't enough space for the creature, the figurine doesn't become a creature.",
"The creature is friendly to you and your companions. It understands your languages and obeys your spoken commands. If you issue no commands, the creature defends itself but takes no other actions.",
"The creature exists for a duration specific to each figurine. At the end of the duration, the creature reverts to its figurine form. It reverts to a figurine early if it drops to 0 hit points or if you use an action to speak the command word again while touching it. When the creature becomes a figurine again, its property can't be used again until a certain amount of time has passed, as specified in the figurine's description.",
"{@creature riding horse||Ivory Goats}.",
"These ivory statuettes of goats are always created in sets of three. Each goat looks unique and functions differently from the others. Their properties are as follows:",
{
"type": "list",
"items": [
"The goat of traveling can become a Large goat with the same statistics as a {@creature riding horse}. It has 24 charges, and each hour or portion thereof it spends in beast form costs 1 charge. While it has charges, you can use it as often as you wish. When it runs out of charges, it reverts to a figurine and can't be used again until 7 days have passed, when it regains all its charges.",
"The goat of travail becomes a {@creature giant goat} for up to 3 hours. Once it has been used, it can't be used again until 30 days have passed.",
"The goat of terror becomes a {@creature giant goat} for up to 3 hours. The goat can't attack, but you can remove its horns and use them as weapons. One horn becomes a +1 lance, and the other becomes a +2 longsword. Removing a horn requires an action, and the weapons disappear and the horns return when the goat reverts to figurine form. In addition, the goat radiates a 30-foot-radius aura of terror while you are riding it. Any creature hostile to you that starts its turn in the aura must succeed on a DC 15 Wisdom saving throw or be {@condition frightened} of the goat for 1 minute, or until the goat reverts to figurine form. The {@condition frightened} creature can repeat the saving throw at the end of each of its turns, ending the effect on itself on a success. Once it successfully saves against the effect, a creature is immune to the goat's aura for the next 24 hours. Once the figurine has been used, it can't be used again until 15 days have passed."
]
}
],
"lootTables": [
"Magic Item Table G"
]
},
{
"name": "Figurine of Wondrous Power, Marble Elephant",
"source": "DMG",
"page": 170,
"srd": true,
"tier": "major",
"rarity": "rare",
"wondrous": true,
"entries": [
"A figurine of wondrous power is a statuette of a beast small enough to fit in a pocket. If you use an action to speak the command word and throw the figurine to a point on the ground within 60 feet of you, the figurine becomes a living creature. If the space where the creature would appear is occupied by other creatures or objects, or if there isn't enough space for the creature, the figurine doesn't become a creature.",
"The creature is friendly to you and your companions. It understands your languages and obeys your spoken commands. If you issue no commands, the creature defends itself but takes no other actions.",
"The creature exists for a duration specific to each figurine. At the end of the duration, the creature reverts to its figurine form. It reverts to a figurine early if it drops to 0 hit points or if you use an action to speak the command word again while touching it. When the creature becomes a figurine again, its property can't be used again until a certain amount of time has passed, as specified in the figurine's description.",
"{@creature elephant||Marble Elephant}.",
"This marble statuette is about 4 inches high and long. It can become an {@creature elephant} for up to 24 hours. Once it has been used, it can't be used again until 7 days have passed."
],
"lootTables": [
"Magic Item Table G"
]
},
{
"name": "Figurine of Wondrous Power, Obsidian Steed",
"source": "DMG",
"page": 170,
"srd": true,
"tier": "major",
"rarity": "very rare",
"wondrous": true,
"entries": [
"A figurine of wondrous power is a statuette of a beast small enough to fit in a pocket. If you use an action to speak the command word and throw the figurine to a point on the ground within 60 feet of you, the figurine becomes a living creature. If the space where the creature would appear is occupied by other creatures or objects, or if there isn't enough space for the creature, the figurine doesn't become a creature.",
"The creature is friendly to you and your companions. It understands your languages and obeys your spoken commands. If you issue no commands, the creature defends itself but takes no other actions.",
"The creature exists for a duration specific to each figurine. At the end of the duration, the creature reverts to its figurine form. It reverts to a figurine early if it drops to 0 hit points or if you use an action to speak the command word again while touching it. When the creature becomes a figurine again, its property can't be used again until a certain amount of time has passed, as specified in the figurine's description.",
"{@creature nightmare||Obsidian Steed}.",
"This polished obsidian horse can become a {@creature nightmare} for up to 24 hours. The {@creature nightmare} fights only to defend itself. Once it has been used, it can't be used again until 5 days have passed.",
"If you have a good alignment, the figurine has a {@chance 10} chance each time you use it to ignore your orders, including a command to revert to figurine form. If you mount the {@creature nightmare} while it is ignoring your orders, you and the {@creature nightmare} are instantly transported to a random location on the plane of Hades, where the {@creature nightmare} reverts to figurine form."
],
"lootTables": [
"Magic Item Table H"
]
},
{
"name": "Figurine of Wondrous Power, Onyx Dog",
"source": "DMG",
"page": 170,
"srd": true,
"tier": "major",
"rarity": "rare",
"wondrous": true,
"entries": [
"A figurine of wondrous power is a statuette of a beast small enough to fit in a pocket. If you use an action to speak the command word and throw the figurine to a point on the ground within 60 feet of you, the figurine becomes a living creature. If the space where the creature would appear is occupied by other creatures or objects, or if there isn't enough space for the creature, the figurine doesn't become a creature.",
"The creature is friendly to you and your companions. It understands your languages and obeys your spoken commands. If you issue no commands, the creature defends itself but takes no other actions.",
"The creature exists for a duration specific to each figurine. At the end of the duration, the creature reverts to its figurine form. It reverts to a figurine early if it drops to 0 hit points or if you use an action to speak the command word again while touching it. When the creature becomes a figurine again, its property can't be used again until a certain amount of time has passed, as specified in the figurine's description.",
"{@creature mastiff||Onyx Dog}.",
"This onyx statuette of a dog can become a {@creature mastiff} for up to 6 hours. The {@creature mastiff} has an Intelligence of 8 and can speak Common. It also has {@sense darkvision} out to a range of 60 feet and can see {@condition invisible} creatures and objects within that range. Once it has been used, it can't be used again until 7 days have passed."
],
"lootTables": [
"Magic Item Table G"
]
},
{
"name": "Figurine of Wondrous Power, Serpentine Owl",
"source": "DMG",
"page": 170,
"srd": true,
"tier": "major",
"rarity": "rare",
"wondrous": true,
"entries": [
"A figurine of wondrous power is a statuette of a beast small enough to fit in a pocket. If you use an action to speak the command word and throw the figurine to a point on the ground within 60 feet of you, the figurine becomes a living creature. If the space where the creature would appear is occupied by other creatures or objects, or if there isn't enough space for the creature, the figurine doesn't become a creature.",
"The creature is friendly to you and your companions. It understands your languages and obeys your spoken commands. If you issue no commands, the creature defends itself but takes no other actions.",
"The creature exists for a duration specific to each figurine. At the end of the duration, the creature reverts to its figurine form. It reverts to a figurine early if it drops to 0 hit points or if you use an action to speak the command word again while touching it. When the creature becomes a figurine again, its property can't be used again until a certain amount of time has passed, as specified in the figurine's description.",
"{@creature giant owl||Serpentine Owl}.",
"This serpentine statuette of an owl can become a {@creature giant owl} for up to 8 hours. Once it has been used, it can't be used again until 2 days have passed. The owl can telepathically communicate with you at any range if you and it are on the same plane of existence."
],
"lootTables": [
"Magic Item Table G"
]
},
{
"name": "Figurine of Wondrous Power, Silver Raven",
"source": "DMG",
"page": 170,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"wondrous": true,
"entries": [
"A figurine of wondrous power is a statuette of a beast small enough to fit in a pocket. If you use an action to speak the command word and throw the figurine to a point on the ground within 60 feet of you, the figurine becomes a living creature. If the space where the creature would appear is occupied by other creatures or objects, or if there isn't enough space for the creature, the figurine doesn't become a creature.",
"The creature is friendly to you and your companions. It understands your languages and obeys your spoken commands. If you issue no commands, the creature defends itself but takes no other actions.",
"The creature exists for a duration specific to each figurine. At the end of the duration, the creature reverts to its figurine form. It reverts to a figurine early if it drops to 0 hit points or if you use an action to speak the command word again while touching it. When the creature becomes a figurine again, its property can't be used again until a certain amount of time has passed, as specified in the figurine's description.",
"{@creature raven||Silver Raven}.",
"This silver statuette of a raven can become a raven for up to 12 hours. Once it has been used, it can't be used again until 2 days have passed. While in raven form, the figurine allows you to cast the {@spell animal messenger} spell on it at will."
],
"attachedSpells": [
"animal messenger"
],
"lootTables": [
"Magic Item Table F"
]
},
{
"name": "Finder's Goggles",
"source": "ERLW",
"page": 277,
"otherSources": [
{
"source": "UAWGE",
"page": 115
}
],
"rarity": "uncommon",
"reqAttune": "by a creature with the Mark of Finding",
"reqAttuneTags": [
{
"race": "half-orc (variant; mark of finding)|erlw"
},
{
"race": "human (variant; mark of finding)|erlw"
}
],
"wondrous": true,
"entries": [
"The lenses of these garish goggles are carved from Siberys dragonshards. While wearing these lenses, you gain the following benefits:",
{
"type": "list",
"items": [
"When you make a Wisdom ({@skill Insight}) check, you can roll a {@dice d4} and add the number rolled to the check.",
"As an action, you can use the goggles to examine an object to identify the aura of the last creature that touched it. Make a Wisdom ({@skill Insight}) check against a DC of 13 + the number of days since the last contact occurred. On a success, you learn the creature's type and can immediately use the goggles to cast {@spell locate creature} to find that creature. This property can't be used again until the next dawn."
]
}
],
"attachedSpells": [
"locate creature"
]
},
{
"name": "Fine Clothes",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 6,
"value": 1500
},
{
"name": "Fine gold chain set with a fire opal",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 250000
},
{
"name": "Fine gold chain with fire opals (Legion of Dusk)",
"source": "PSX",
"page": 25,
"type": "$",
"rarity": "none",
"value": 250000
},
{
"name": "Fine robe with dinosaur feathers and silver embroidery (Sun Empire)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 25000
},
{
"name": "Fine steel rapier with gold filigree hilt (Legion of Dusk)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 75000
},
{
"name": "Fine Wine (Bottle)",
"source": "PHB",
"page": 158,
"srd": true,
"basicRules": true,
"type": "FD",
"rarity": "none",
"value": 1000
},
{
"name": "Finely articulated jade glove (River Heralds)",
"source": "PSX",
"page": 25,
"type": "$",
"rarity": "none",
"value": 250000
},
{
"name": "Fire Absorbing Tattoo",
"source": "TCE",
"page": 119,
"resist": [
"fire"
],
"detail1": "red",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"hasRefs": true,
"entries": [
"{#itemEntry Absorbing Tattoo|TCE}"
]
},
{
"name": "Fire Opal",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 100000,
"entries": [
"A translucent fiery red gemstone."
]
},
{
"name": "Fish Suit",
"source": "AAG",
"page": 22,
"rarity": "very rare",
"wondrous": true,
"entries": [
"This bulky suit, which fully encases your head and body, takes 1 minute to don or doff. While worn, it enables you to breathe in an airless environment and renders you immune to the harmful effects of any gas that surrounds you. The suit also grants you a swimming speed equal to your walking speed while underwater, or a flying speed equal to your walking speed in an environment with no gravity."
],
"hasFluffImages": true
},
{
"name": "Fishing Tackle",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 4,
"value": 100,
"entries": [
"This kit includes a wooden rod, silken line, corkwood bobbers, steel hooks, lead sinkers, velvet lures, and narrow netting."
]
},
{
"name": "Flail of Tiamat",
"source": "FTD",
"page": 23,
"baseItem": "flail|PHB",
"type": "M",
"rarity": "legendary",
"reqAttune": true,
"weight": 2,
"weaponCategory": "martial",
"dmg1": "1d8",
"dmgType": "B",
"bonusWeapon": "+3",
"weapon": true,
"entries": [
"This magic flail is made in the image of Tiamat, with five jagged heads shaped like the heads of five different chromatic dragons. You gain a +3 bonus to attack and damage rolls made with this flail. When you hit with an attack roll using it, the target takes an extra {@damage 5d4} damage of your choice of one of the following damage types: acid, cold, fire, lightning, or poison.",
"While holding the flail, you can use an action and speak a command word to cause the heads to breathe multicolored flames in a 90-foot cone. Each creature in that area must make a DC 18 Dexterity saving throw. On a failed save, it takes {@damage 14d6} damage of one of the following damage types (your choice): acid, cold, fire, lightning, or poison. On a successful save, it takes half as much damage. Once this action is used, it can't be used again until the next dawn."
]
},
{
"name": "Flame Tongue Shortsword of Greed",
"source": "TftYP",
"page": 179,
"baseItem": "shortsword|phb",
"type": "M",
"rarity": "unknown (magic)",
"reqAttune": true,
"weight": 2,
"weaponCategory": "martial",
"property": [
"F",
"L"
],
"dmg1": "1d6",
"dmgType": "P",
"sword": true,
"weapon": true,
"entries": [
"You can use a bonus action to speak this magic sword's command word, causing flames to erupt from the blade. These flames shed bright light in a 40-foot radius and dim light for an additional 40 feet. While the sword is ablaze, it deals an extra {@damage 2d6} fire damage to any target it hits. The flames last until you use a bonus action to speak the command word again or until you drop or sheathe the sword.",
"While you are attuned to this sword you can use an action to mentally command it to detect gems and jewels. You learn the kind and number of such objects within 60 feet of the sword."
]
},
{
"name": "Flask",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 1,
"value": 2,
"entries": [
"A flask holds 1 pint of liquid."
]
},
{
"name": "Flensing Claws (Huge)",
"source": "VGM",
"page": 81,
"type": "OTH",
"rarity": "unknown",
"dmg1": "2d8",
"dmgType": "S",
"entries": [
"Flensing claws take the form of articulated digits that extend into long metal blades. The claws are knitted into the flesh and bones of a creature's arms and can't be removed without surgical amputation.",
"Each set of flensing claws is designed for a specific creature and can't be used by anyone else. A creature equipped with flensing claws can use its action to make one melee weapon attack with the claws. The creature adds its proficiency bonus and Strength modifier to any attack roll made with the claws, and its Strength modifier to its damage roll when it hits a target with the claws."
]
},
{
"name": "Flensing Claws (Large)",
"source": "VGM",
"page": 81,
"type": "OTH",
"rarity": "unknown",
"dmg1": "1d12",
"dmgType": "S",
"entries": [
"Flensing claws take the form of articulated digits that extend into long metal blades. The claws are knitted into the flesh and bones of a creature's arms and can't be removed without surgical amputation.",
"Each set of flensing claws is designed for a specific creature and can't be used by anyone else. A creature equipped with flensing claws can use its action to make one melee weapon attack with the claws. The creature adds its proficiency bonus and Strength modifier to any attack roll made with the claws, and its Strength modifier to its damage roll when it hits a target with the claws."
]
},
{
"name": "Flensing Claws (Medium)",
"source": "VGM",
"page": 81,
"type": "OTH",
"rarity": "unknown",
"dmg1": "1d10",
"dmgType": "S",
"entries": [
"Flensing claws take the form of articulated digits that extend into long metal blades. The claws are knitted into the flesh and bones of a creature's arms and can't be removed without surgical amputation.",
"Each set of flensing claws is designed for a specific creature and can't be used by anyone else. A creature equipped with flensing claws can use its action to make one melee weapon attack with the claws. The creature adds its proficiency bonus and Strength modifier to any attack roll made with the claws, and its Strength modifier to its damage roll when it hits a target with the claws."
]
},
{
"name": "Flensing Claws (Small)",
"source": "VGM",
"page": 81,
"type": "OTH",
"rarity": "unknown",
"dmg1": "1d8",
"dmgType": "S",
"entries": [
"Flensing claws take the form of articulated digits that extend into long metal blades. The claws are knitted into the flesh and bones of a creature's arms and can't be removed without surgical amputation.",
"Each set of flensing claws is designed for a specific creature and can't be used by anyone else. A creature equipped with flensing claws can use its action to make one melee weapon attack with the claws. The creature adds its proficiency bonus and Strength modifier to any attack roll made with the claws, and its Strength modifier to its damage roll when it hits a target with the claws."
]
},
{
"name": "Flour",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TG",
"rarity": "none",
"weight": 1,
"value": 2
},
{
"name": "Flute",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"type": "INS",
"rarity": "none",
"weight": 1,
"value": 200
},
{
"name": "Flying Chariot",
"source": "MOT",
"page": 196,
"rarity": "rare",
"wondrous": true,
"bonusAc": "+1",
"entries": [
"The chariot's riders and creatures pulling the chariot gain a +1 bonus to their AC.",
"If this magic chariot is pulled by one or more flying creatures, it too can fly.",
{
"type": "inset",
"name": "Chariots",
"entries": [
"Chariots and the creatures pulling them work like controlled mounts, as described in the mounted combat rules in the {@book Player's Handbook|PHB|9|Mounted Combat}, but with the following differences:",
{
"type": "list",
"items": [
"Mounting or dismounting a chariot costs you 5 feet of movement, rather than a number of feet equal to half your speed.",
"Being mounted on a chariot grants you half cover.",
"A chariot's speed is equal to the speed of the slowest creature pulling it.",
"If multiple creatures are pulling the chariot, they all act on the same initiative, and they must take the same action on their turn."
]
}
]
}
],
"hasFluffImages": true
},
{
"name": "Flying Citadel Helm",
"source": "DSotDQ",
"page": 190,
"rarity": "very rare",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"wondrous": true,
"entries": [
"The function of this ornate chair is to propel and maneuver a flying citadel on which it has been installed. The chair has AC 15, 18 hit points, and immunity to poison and psychic damage. It is destroyed if reduced to 0 hit points.",
"The sensation of being attuned to a flying citadel helm is akin to the pins-and-needles effect one experiences after one's arm or leg falls asleep, but not as intense.",
"While attuned to a flying citadel helm and sitting in it, you gain the following abilities for as long as you maintain concentration (as if concentrating on a spell):",
{
"type": "list",
"items": [
"You can use the flying citadel helm to move the citadel through the air, up to 80 feet per round, or up to 8 miles per hour.",
"You can steer the citadel, albeit in a somewhat clumsy fashion, in much the way that a rudder or oars can be used to maneuver a seafaring ship.",
"At any time, you can see and hear from the highest point outside the citadel as though you were at that location."
]
},
"If no creature attuned to the helm is maintaining concentration, the citadel remains motionless in its space.",
{
"type": "entries",
"name": "Transfer Attunement",
"entries": [
"You can use an action or a bonus action to touch a willing spellcaster, whereupon that creature attunes to the flying citadel helm immediately, and your attunement to the flying citadel helm ends."
]
},
{
"type": "entries",
"name": "Crash",
"entries": [
"Should the flying citadel helm be destroyed, the citadel it is installed on loses power and begins to crumble. If the crumbling citadel is in the air, it descends at a rate of 30 feet per round, or 300 feet per minute. Any creature on the citadel or on the ground within 120 feet of the citadel when it lands must make a DC 20 Dexterity saving throw, taking 39 ({@damage 6d12}) bludgeoning damage on a failed save, or half as much damage on a successful one."
]
}
]
},
{
"name": "Flying Fish Ship",
"source": "AAG",
"page": 0,
"type": "SPC",
"rarity": "none",
"value": 2000000,
"crew": 10,
"vehAc": 15,
"vehHp": 250,
"vehDmgThresh": 15,
"vehSpeed": 4.5,
"capCargo": 13,
"entries": [
"Aside from space galleons, flying fish ships are the most common vessels in Wildspace, favorites among merchants and adventurers alike.",
"A flying fish ship can float and sail on water, but it isn't built to land on the ground (the ventral fins would snap under the weight of the ship during landing, and the keel would cause the ship to roll to one side).",
"Typical weapons on a flying fish ship include a forward-mounted mangonel and an aftmounted ballista."
],
"seeAlsoVehicle": [
"Flying Fish Ship|AAG"
]
},
{
"name": "Folding Boat",
"source": "DMG",
"page": 170,
"srd": true,
"tier": "minor",
"rarity": "rare",
"wondrous": true,
"weight": 4,
"entries": [
"This object appears as a wooden box that measures 12 inches long, 6 inches wide, and 6 inches deep. It weighs 4 pounds and floats. It can be opened to store items inside. This item also has three command words, each requiring you to use an action to speak it.",
"One command word causes the box to unfold into a boat 10 feet long, 4 feet wide, and 2 feet deep. The boat has one pair of oars, an anchor, a mast, and a lateen sail. The boat can hold up to four Medium creatures comfortably.",
"The second command word causes the box to unfold into a ship 24 feet long, 8 feet wide; and 6 feet deep. The ship has a deck, rowing seats, five sets of oars, a steering oar, an anchor, a deck cabin, and a mast with a square sail. The ship can hold fifteen Medium creatures comfortably.",
"When the box becomes a vessel, its weight becomes that of a normal vessel its size, and anything that was stored in the box remains in the boat.",
"The third command word causes the folding boat to fold back into a box, provided that no creatures are aboard. Any objects in the vessel that can't fit inside the box remain outside the box as it folds. Any objects in the vessel that can fit inside the box do so."
],
"lootTables": [
"Magic Item Table C"
],
"hasFluffImages": true
},
{
"name": "Force Absorbing Tattoo",
"source": "TCE",
"page": 119,
"resist": [
"force"
],
"detail1": "white",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"hasRefs": true,
"entries": [
"{#itemEntry Absorbing Tattoo|TCE}"
]
},
{
"name": "Forgery Kit",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"additionalSources": [
{
"source": "XGE",
"page": 81
}
],
"type": "T",
"rarity": "none",
"weight": 5,
"value": 1500,
"entries": [
"This small box contains a variety of papers and parchments, pens and inks, seals and sealing wax, gold and silver leaf, and other supplies necessary to create convincing forgeries of physical documents. Proficiency with this kit lets you add your proficiency bonus to any ability checks you make to create a physical forgery of a document."
],
"additionalEntries": [
"A forgery kit is designed to duplicate documents and to make it easier to copy a person's seal or signature.",
{
"type": "entries",
"name": "Components",
"entries": [
"A forgery kit includes several different types of ink, a variety of parchments and papers, several quills, seals and sealing wax, gold and silver leaf, and small tools to sculpt melted wax to mimic a seal."
]
},
{
"type": "entries",
"name": "Arcana",
"entries": [
"A forgery kit can be used in conjunction with the Arcana skill to determine if a magic item is real or fake."
]
},
{
"type": "entries",
"name": "Deception",
"entries": [
"A well-crafted forgery, such as papers proclaiming you to be a noble or a writ that grants you safe passage, can lend credence to a lie."
]
},
{
"type": "entries",
"name": "History",
"entries": [
"A forgery kit combined with your knowledge of history improves your ability to create fake historical documents or to tell if an old document is authentic."
]
},
{
"type": "entries",
"name": "Investigation",
"entries": [
"When you examine objects, proficiency with a forgery kit is useful for determining how an object was made and whether it is genuine."
]
},
{
"type": "entries",
"name": "Other Tools",
"entries": [
"Knowledge of other tools makes your forgeries that much more believable. For example, you could combine proficiency with a forgery kit and proficiency with cartographer's tools to make a fake map."
]
},
{
"type": "entries",
"name": "Quick Fake",
"entries": [
"As part of a short rest, you can produce a forged document no more than one page in length. As part of a long rest, you can produce a document that is up to four pages long. Your Intelligence check using a forgery kit determines the DC for someone else's Intelligence ({@skill Investigation}) check to spot the fake."
]
},
{
"type": "table",
"caption": "Forgery Kit",
"colLabels": [
"Activity",
"DC"
],
"colStyles": [
"col-10",
"col-2 text-center"
],
"rows": [
[
"Mimic handwriting",
"15"
],
[
"Duplicate a wax seal",
"20"
]
]
}
]
},
{
"name": "Fragmentation Grenade",
"source": "DMG",
"page": 268,
"type": "EXP",
"rarity": "none",
"weight": 1,
"age": "modern",
"entries": [
"As an action, a character can throw a grenade at a point up to 60 feet away. With a grenade launcher, the character can propel the grenade up to 120 feet away.",
"Each creature within 20 feet of an exploding fragmentation grenade must make a DC 15 Dexterity saving throw, taking {@damage 5d6} piercing damage on a failed save, or half as much damage on a successful one."
]
},
{
"name": "Fulminating Treatise",
"source": "TCE",
"page": 128,
"rarity": "rare",
"reqAttune": "by a wizard",
"reqAttuneTags": [
{
"class": "wizard"
}
],
"wondrous": true,
"weight": 3,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"focus": [
"Wizard"
],
"entries": [
"This thick, scorched spellbook reeks of smoke and ozone, and sparks of energy crackles along the edges of its pages. When found, the book contains the following spells: {@spell contingency}, {@spell fireball}, {@spell gust of wind}, {@spell Leomund's tiny hut}, {@spell magic missile}, {@spell thunderwave}, and {@spell wall of force}. It functions as a spellbook for you.",
"While you are holding the book, you can use it as a spellcasting focus for your wizard spells.",
"The book has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. You can use the charges in the following ways while holding it:",
{
"type": "list",
"items": [
"If you spend 1 minute studying the book, you can expend 1 charge to replace one of your prepared wizard spells with a different spell in the book. The new spell must be of the evocation school.",
"When one creature you can see takes damage from an evocation spell you cast, you can use your reaction and expend 1 charge to deal an extra {@damage 2d6} force damage to the creature and knock the creature {@condition prone} if it is Large or smaller."
]
}
],
"attachedSpells": [
"contingency",
"fireball",
"gust of wind",
"leomund's tiny hut",
"magic missile",
"thunderwave",
"wall of force"
],
"hasFluffImages": true
},
{
"name": "Galder's Bubble Pipe",
"source": "LLK",
"page": 55,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": 3,
"charges": 3,
"entries": [
"This finely carved pipe blows odorless bubbles instead of smoke when used. The pipe has 3 charges, and it regains all spent charges daily at dawn. While you hold the pipe, you can expend charges to gain access to the following properties:",
{
"type": "list",
"items": [
"You can cast {@spell fog cloud} as an action (1 charge).",
"You can cast {@spell misty step} as a bonus action (2 charges).",
"You can summon a {@creature steam mephit} as an action (3 charges). The mephit is friendly to you, obeys your verbal commands, and acts on its own turn in the initiative order. It disappears in a harmless puff of steam after 1 minute or if it ends its turn more than 60 feet from the pipe."
]
}
],
"attachedSpells": [
"fog cloud",
"misty step"
]
},
{
"name": "Galley",
"source": "DMG",
"page": 119,
"srd": true,
"basicRules": true,
"type": "SHP",
"rarity": "none",
"value": 3000000,
"crew": 80,
"vehAc": 15,
"vehHp": 500,
"vehDmgThresh": 20,
"vehSpeed": 4,
"capCargo": 150,
"seeAlsoVehicle": [
"Galley"
]
},
{
"name": "Garnet",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 10000,
"entries": [
"A transparent red, brown-green, or violet gemstone."
]
},
{
"name": "Gauntlets of Flaming Fury",
"source": "BGDIA",
"page": 223,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"While you wear both of these steel gauntlets, any non-magical weapon you grasp with either gauntlet is treated as a magic weapon. As a bonus action, you can use the gauntlets to cause magical flames to envelop one or two melee weapons in your grasp. Each flaming weapon deals an extra {@damage 1d6} fire damage on a hit. The flames last until you sheath or let go of either weapon. Once used, this property can't be used again until the next dawn."
]
},
{
"name": "Gauntlets of Ogre Power",
"source": "DMG",
"page": 171,
"srd": true,
"basicRules": true,
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"ability": {
"static": {
"str": 19
}
},
"entries": [
"Your Strength score is 19 while you wear these gauntlets. They have no effect on you if your Strength is already 19 or higher without them."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Gavel of the Venn Rune",
"source": "SKT",
"page": 234,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"This wooden gavel is small by giant reckoning but nearly the size of a warhammer in human hands. The venn (friend) rune is inscribed in mithral in the base of the haft. Among giants, this item is used as part of rituals to resolve disputes. The gavel has the following properties.",
{
"name": "Arbiters Shield",
"type": "entries",
"entries": [
"At the start of every combat, attack rolls against you have disadvantage before the start of your first turn, provided that the gavel is on your person."
]
},
{
"name": "Bond of Amity",
"type": "entries",
"entries": [
"As an action, you can use the gavel to strike a point on a hard surface. The first time in the next minute that a creature within 60 feet of that point deals damage to another creature with an attack that hits, the attacker takes psychic damage equal to half the damage it dealt to the target. Once you use this property, you can't use it again until you finish a long rest."
]
},
{
"name": "Gift of Truth",
"type": "entries",
"entries": [
"You can transfer the gavel's magic to a place by tracing the venn rune on the ground with your finger. The point where you trace it becomes the center of a spherical area of magic that has a 30-foot radius and that is fixed to the place. The transfer takes 8 hours of work that requires the gavel to be within 5 feet of you. At the end, the gavel is destroyed, and the area gains the following property:",
"Whenever a creature utters a lie while within the 30-foot-radius sphere, that creature takes 5 psychic damage and flinches visibly."
]
}
]
},
{
"name": "Gem of Brightness",
"source": "DMG",
"page": 171,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"wondrous": true,
"weight": 1,
"charges": 50,
"entries": [
"This prism has 50 charges. While you are holding it, you can use an action to speak one of three command words to cause one of the following effects:",
{
"type": "list",
"items": [
"The first command word causes the gem to shed bright light in a 30-foot radius and dim light for an additional 30 feet. This effect doesn't expend a charge. It lasts until you use a bonus action to repeat the command word or until you use another function of the gem.",
"The second command word expends 1 charge and causes the gem to fire a brilliant beam of light at one creature you can see within 60 feet of you. The creature must succeed on a DC 15 Constitution saving throw or become {@condition blinded} for 1 minute. The creature can repeat the saving throw at the end of each of its turns, ending the effect on itself on a success.",
"The third command word expends 5 charges and causes the gem to flare with blinding light in a 30-foot cone originating from it. Each creature in the cone must make a saving throw as if struck by the beam created with the second command word."
]
},
"When all of the gem's charges are expended, the gem becomes a nonmagical jewel worth 50 gp."
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Gem of Seeing",
"source": "DMG",
"page": 172,
"srd": true,
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"weight": 1,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"This gem has 3 charges. As an action, you can speak the gem's command word and expend 1 charge. For the next 10 minutes, you have {@sense truesight} out to 120 feet when you peer through the gem.",
"The gem regains {@dice 1d3} expended charges daily at dawn."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Ghost Lantern",
"source": "ToA",
"page": 206,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"weight": 1,
"entries": [
"A restless spirit is trapped inside this lantern. While holding the lantern, you can command the spirit as a bonus action to shed bright light in a 30-foot radius and dim light for an additional 30 feet.",
"While holding the lantern, you can use an action to order the spirit to leave the lantern and duplicate the effect of the {@spell mage hand} spell. The spirit returns to the lantern when the spell ends.",
"If you fall {@condition unconscious} within 10 feet of the lantern, the spirit emerges from it, magically stabilizes you with a touch, and then quickly returns to the lantern.",
"The spirit is bound to the lantern and can't be harmed, turned, or raised from the dead.",
"Casting a {@spell dispel evil and good} spell on the lantern releases the spirit to the afterlife and renders the lantern nonmagical."
],
"attachedSpells": [
"mage hand"
],
"hasFluffImages": true
},
{
"name": "Ghost Step Tattoo",
"source": "TCE",
"page": 128,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"recharge": "dawn",
"rechargeAmount": 3,
"charges": 3,
"entries": [
"Produced by a special needle, this tattoo shifts and wavers on the skin, parts of it appearing blurred.",
{
"type": "entries",
"name": "Tattoo Attunement",
"entries": [
"To attune to this item, you hold the needle to your skin where you want the tattoo to appear, pressing the needle there throughout the attunement process. When the attunement is complete, the needle turns into the ink that becomes the tattoo, which appears on the skin.",
"If your attunement to the tattoo ends, the tattoo vanishes, and the needle reappears in your space."
]
},
{
"type": "entries",
"name": "Ghostly Form",
"entries": [
"The tattoo has 3 charges, and it regains all expended charges daily at dawn. As a bonus action while the tattoo is on your skin, you can expend 1 of the tattoo's charges to become incorporeal until the end of your next turn. For the duration, you gain the following benefits:",
{
"type": "list",
"items": [
"You have resistance to bludgeoning, piercing, and slashing damage from nonmagical attacks.",
"You can't be {@condition grappled} or {@condition restrained}.",
"You can move through creatures and solid objects as if they were {@quickref difficult terrain||3}. If you end your turn in a solid object, you take {@damage 1d10} force damage. If the effect ends while you are inside a solid object, you instead are shunted to the nearest unoccupied space, and you take {@damage 1d10} force damage for every 5 feet traveled."
]
}
]
}
]
},
{
"name": "Ghost Step Tattoo",
"source": "UA2020SpellsAndMagicTattoos",
"page": 11,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"recharge": "dawn",
"rechargeAmount": 3,
"charges": 3,
"entries": [
"This tattoo shifts and wavers on the skin, parts of it appearing blurred. The tattoo has 3 charges, and it regains all expended charges daily at dawn.",
"As a bonus action while the tattoo is on your skin, you can expend 1 of the tattoo's charges to become incorporeal until the end of your next turn. For the duration, you gain the following benefits:",
{
"type": "list",
"items": [
"You have resistance to bludgeoning, piercing, and slashing damage from nonmagical attacks.",
"You can't be {@condition grappled} or {@condition restrained}.",
"You can move through creatures and solid objects as if they were {@quickref difficult terrain||3}. If you end your turn in a solid object, you take {@damage 1d10} force damage. If the effect ends while you are inside a solid object, you instead are shunted to the nearest unoccupied space, and you take {@damage 1d10} force damage for every 5 feet traveled."
]
},
{
"type": "entries",
"name": "Tattoo Attunement",
"entries": [
"To attune to this item, you hold the needle to your skin where you want the tattoo to appear, pressing the needle there throughout the attunement process. When the attunement is complete, the needle turns into the ink that becomes the tattoo, which appears on the skin. If you have multiple magic tattoos, they count as a single magic item with regard to the number of magic items you can attune to.",
"If your attunement to the tattoo ends, the tattoo vanishes, and the needle reappears in the closest unoccupied space to you."
]
}
]
},
{
"name": "Ginger",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TG",
"rarity": "none",
"weight": 1,
"value": 100
},
{
"name": "Glamoured Studded Leather",
"source": "DMG",
"page": 172,
"srd": true,
"type": "LA",
"tier": "major",
"rarity": "rare",
"weight": 13,
"ac": 12,
"bonusAc": "+1",
"entries": [
"While wearing this armor, you gain a +1 bonus to AC. You can also use a bonus action to speak the armor's command word and cause the armor to assume the appearance of a normal set of clothing or some other kind of armor. You decide what it looks like, including color, style, and accessories, but the armor retains its normal bulk and weight. The illusory appearance lasts until you use this property again or remove the armor."
],
"lootTables": [
"Magic Item Table G"
]
},
{
"name": "Glass Bottle",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 2,
"value": 200,
"entries": [
"A bottle holds 1½ pints of liquid."
]
},
{
"name": "Glassblower's Tools",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"additionalSources": [
{
"source": "XGE",
"page": 82
}
],
"type": "AT",
"rarity": "none",
"weight": 5,
"value": 3000,
"additionalEntries": [
"Someone who is proficient with glassblower's tools has not only the ability to shape glass, but also specialized knowledge of the methods used to produce glass objects.",
{
"type": "entries",
"name": "Components",
"entries": [
"The tools include a blowpipe, a small marver, blocks, and tweezers. You need a source of heat to work glass."
]
},
{
"type": "entries",
"name": "Arcana, History",
"entries": [
"Your knowledge of glassmaking techniques aids you when you examine glass objects, such as potion bottles or glass items found in a treasure hoard. For instance, you can study how a glass potion bottle has been changed by its contents to help determine a potion's effects. (A potion might leave behind a residue, deform the glass, or stain it.)"
]
},
{
"type": "entries",
"name": "Investigation",
"entries": [
"When you study an area, your knowledge can aid you if the clues include broken glass or glass objects."
]
},
{
"type": "entries",
"name": "Identify Weakness",
"entries": [
"With 1 minute of study, you can identify the weak points in a glass object. Any damage dealt to the object by striking a weak spot is doubled."
]
},
{
"type": "table",
"caption": "Glassblower's Tools",
"colLabels": [
"Activity",
"DC"
],
"colStyles": [
"col-10",
"col-2 text-center"
],
"rows": [
[
"Identify source of glass",
"10"
],
[
"Determine what a glass object once held",
"20"
]
]
}
]
},
{
"name": "Glaur",
"source": "SCAG",
"page": 124,
"type": "INS",
"rarity": "none",
"entries": [
"Short, curved horns like a cornucopia. Played with valves, glaur sound like trumpets, while those without valves, known as gloon, have a more mournful sound."
]
},
{
"name": "Gloves of Missile Snaring",
"source": "DMG",
"page": 172,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"entries": [
"These gloves seem to almost meld into your hands when you don them. When a ranged weapon attack hits you while you're wearing them, you can use your reaction to reduce the damage by {@dice 1d10} + your Dexterity modifier, provided that you have a free hand. If you reduce the damage to 0, you can catch the missile if it is small enough for you to hold in that hand."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Gloves of Soul Catching",
"source": "CM",
"page": 169,
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"ability": {
"static": {
"con": 20
}
},
"entries": [
"Your Constitution score is 20 while you wear these gloves. This property of the gloves has no effect on you if your Constitution is already 20 or higher.",
"After making a successful unarmed strike while wearing these gloves, you can use the gloves to deal an extra {@damage 2d10} force damage to the target, and you regain a number of hit points equal to the force damage dealt. Alternatively, instead of regaining hit points in this way, you can choose to gain advantage on one attack roll, ability check, or saving throw you make before the end of your next turn."
]
},
{
"name": "Gloves of Swimming and Climbing",
"source": "DMG",
"page": 172,
"srd": true,
"basicRules": true,
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"entries": [
"While wearing these gloves, climbing and swimming don't cost you extra movement, and you gain a +5 bonus to Strength ({@skill Athletics}) checks made to climb or swim."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table F"
]
},
{
"name": "Gloves of Thievery",
"source": "DMG",
"page": 172,
"tier": "major",
"rarity": "uncommon",
"wondrous": true,
"entries": [
"These gloves are {@condition invisible} while worn. While wearing them, you gain a +5 bonus to Dexterity ({@skill Sleight of Hand}) checks and Dexterity checks made to pick locks."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table F"
]
},
{
"name": "Gnomengarde Grenade",
"source": "DC",
"rarity": "varies",
"wondrous": true,
"entries": [
"This small metallic object is comprised of rune-covered parts made from different metals, linked together with a variety of gears, bands, bits, and bobs. The metals are all different colors, making the grenade shine with a rainbow of color when held up in the light.",
"As a bonus action, a creature can turn a special key to arm the grenade. Once armed, the grenade explodes in a matter of seconds. As an action, the wielder can hurl the grenade up to 120 feet. At the end of their turn, the grenade explodes. All creatures within 60 feet of the grenade when it explodes suffer the following effects:",
{
"type": "list",
"items": [
"Each creature must make a DC 15 Dexterity saving throw, taking 28 ({@damage 8d6}) fire damage on a failed save, or half as much fire damage on a successful one.",
"Each creature must make a DC 15 Constitution saving throw. On a failed save, it takes 28 ({@damage 8d6}) thunder damage and is {@condition stunned} until the end of its next turn. On a successful save, it takes half as much thunder damage and is not {@condition stunned}.",
"The wielder rolls on the chart* for the {@item wand of wonder|dmg} three times, rerolling any duplicate results. Any effects that specify a target affect all creatures in range of the grenade when it explodes. Any effects that would affect the wielder of the wand instead affect the closest creature to the grenade when it goes off."
]
},
"The grenade explodes immediately if a creature places it in an extradimensional space or teleports while in possession of it. The effects of the explosion will be felt at both the origin and terminus of the teleportation, but only affecting all creatures within 30 feet of either location."
]
},
{
"name": "Goat",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TG",
"rarity": "none",
"value": 100
},
{
"name": "Goggles of Night",
"source": "DMG",
"page": 172,
"srd": true,
"basicRules": true,
"tier": "minor",
"rarity": "uncommon",
"wondrous": true,
"entries": [
"While wearing these dark lenses, you have {@sense darkvision} out to a range of 60 feet. If you already have {@sense darkvision}, wearing the goggles increases its range by 60 feet."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table B"
],
"hasFluffImages": true
},
{
"name": "Goggles of Object Reading",
"source": "EGW",
"page": 267,
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"entries": [
"These leather-framed goggles feature purple crystal lenses. While wearing the goggles, you have advantage on Intelligence ({@skill Arcana}) checks made to reveal information about a creature or object you can see.",
"In addition, you can cast the {@spell identify} spell using the googles. Once you do so, you can't do so again until the next dawn."
],
"attachedSpells": [
"identify"
]
},
{
"name": "Gold",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TG",
"rarity": "none",
"weight": 1,
"value": 5000
},
{
"name": "Gold (gp)",
"source": "PHB",
"page": 143,
"type": "$",
"rarity": "none",
"weight": 0.02,
"value": 100,
"entries": [
"Common coins come in several different denominations based on the relative worth of the metal from which they are made. The three most common coins are the gold piece (gp), the silver piece (sp), and the copper piece (cp).",
"With one gold piece, a character can buy a belt pouch, 50 feet of good rope, or a goat. A skilled (but not exceptional) artisan can earn one gold piece a day. The gold piece is the standard unit of measure for wealth, even if the coin itself is not commonly used. When merchants discuss deals that involve goods or services worth hundreds or thousands of gold pieces, the transactions don't usually involve the exchange of individual coins. Rather, the gold piece is a standard measure of value, and the actual exchange is in gold bars, letters of credit, or valuable goods.",
"One gold piece is worth ten silver pieces, the most prevalent coin among commoners. A silver piece buys a laborer's work for a day, a flask of lamp oil, or a night's rest in a poor inn.",
"One silver piece is worth ten copper pieces, which are common among laborers and beggars. A single copper piece buys a candle, a torch, or a piece of chalk.",
"In addition, unusual coins made of other precious metals sometimes appear in treasure hoards. The electrum piece (ep) and the platinum piece (pp) originate from fallen empires and lost kingdoms, and they sometimes arouse suspicion and skepticism when used in transactions. An electrum piece is worth five silver pieces, and a platinum piece is worth ten gold pieces.",
"A standard coin weighs about a third of an ounce, so fifty coins weigh a pound."
],
"hasFluffImages": true
},
{
"name": "Gold 5-Zino Coin",
"source": "GGR",
"page": 9,
"type": "$",
"rarity": "none",
"value": 500
},
{
"name": "Gold basin with rubies (Legion of Dusk)",
"source": "PSX",
"page": 25,
"type": "$",
"rarity": "none",
"value": 750000
},
{
"name": "Gold bird cage with electrum filigree",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 25000
},
{
"name": "Gold Canary Figurine of Wondrous Power",
"source": "FTD",
"page": 23,
"rarity": "legendary",
"wondrous": true,
"entries": [
"This gold statuette is carved in the likeness of a canary and is small enough to fit in a pocket. If you use an action to speak the command word and throw the figurine to a point on the ground within 60 feet of you, the figurine becomes a living creature in one of two forms (you choose). If there isn't enough space for the creature where it would appear, the figurine doesn't become a creature. The two forms are as follows:",
{
"type": "entries",
"name": "Giant Canary Form",
"entries": [
"The figurine becomes a {@creature giant canary|FTD} for up to 8 hours and can be ridden as a mount. Once the figurine has become a {@creature giant canary|FTD}, it can't be used this way again until the next dawn."
]
},
{
"type": "entries",
"name": "Gold Dragon Form",
"entries": [
"While you are missing half or more of your hit points, you can speak a different command word and the figurine becomes an {@creature adult gold dragon} (see its stat block in the Monster Manual) for up to 1 hour. The dragon can't use any legendary actions or lair actions. Once the figurine has become an {@creature adult gold dragon}, it can't be used this way again until 1 year has passed.",
"In either form, the creature is friendly to you and your companions. It understands your languages and obeys your spoken commands. If you issue no commands, the creature defends itself but takes no other actions.",
"The creature exists for a duration specific to each form. At the end of the duration, the creature reverts to its figurine form. It reverts to a figurine early if it drops to 0 hit points or if you use an action to speak the command word again while touching it. When the creature becomes a figurine again, its property can't be used again until a certain amount of time has passed, as specified in the description."
]
}
]
},
{
"name": "Gold chalice (Legion of Dusk)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 75000
},
{
"name": "Gold chalice set with emeralds (Legion of Dusk)",
"source": "PSX",
"page": 25,
"type": "$",
"rarity": "none",
"value": 750000
},
{
"name": "Gold circlet set with four aquamarines",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 250000
},
{
"name": "Gold cup set with emeralds",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 750000
},
{
"name": "Gold dragon comb set with red garnets as eyes",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 75000
},
{
"name": "Gold Dragon Scale Mail",
"source": "DMG",
"page": 165,
"srd": true,
"type": "MA",
"resist": [
"fire"
],
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"weight": 45,
"ac": 14,
"bonusAc": "+1",
"bonusSavingThrow": "+1",
"stealth": true,
"entries": [
"Dragon scale mail is made of the scales of one kind of dragon. Sometimes dragons collect their cast-off scales and gift them to humanoids. Other times, hunters carefully skin and preserve the hide of a dead dragon. In either case, dragon scale mail is highly valued. While wearing this armor, you gain a +1 bonus to AC, you have advantage on saving throws against the Frightful Presence and breath weapons of dragons, and you have resistance to fire damage.",
"Additionally, you can focus your senses as an action to magically discern the distance and direction to the closest gold dragon within 30 miles of you. This special action can't be used again until the next dawn."
]
},
{
"name": "Gold jewelry box with platinum filigree",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 750000
},
{
"name": "Gold jewelry box with platinum filigree (Brazen Coalition)",
"source": "PSX",
"page": 25,
"type": "$",
"rarity": "none",
"value": 750000
},
{
"name": "Gold locket with a painted portrait inside",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 2500
},
{
"name": "Gold locket with a painted portrait inside (Legion of Dusk)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 2500
},
{
"name": "Gold music box",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 250000
},
{
"name": "Gold music box (Brazen Coalition)",
"source": "PSX",
"page": 25,
"type": "$",
"rarity": "none",
"value": 250000
},
{
"name": "Gold pendant with black onyx (Legion of Dusk)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 25000
},
{
"name": "Gold ring set with bloodstones",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 25000
},
{
"name": "Gold ring with turquoise (Legion of Dusk)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 2500
},
{
"name": "Gold Zino",
"source": "GGR",
"page": 9,
"type": "$",
"rarity": "none",
"value": 100
},
{
"name": "Gold-plated ceremonial helmet and pauldrons (Legion of Dusk)",
"source": "PSX",
"page": 25,
"type": "$",
"rarity": "none",
"value": 250000
},
{
"name": "Gold-plated sextant with topaz (Brazen Coalition)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 75000
},
{
"name": "Golgari Guild Signet",
"source": "GGR",
"page": 178,
"type": "RG",
"rarity": "uncommon",
"reqAttune": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"This ring, adorned with the symbol of Golgari, allows you to cast {@spell entangle}. A guild signet is sometimes awarded to a guild member whose renown score in that guild is 5 or higher, as a reward for performing special services for the guild. Aside from its magical properties, the ring is also an indicator of Golgari's recognition and favor.",
"A signet has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. While wearing it, you can expend 1 charge to cast the associated spell (save DC 13)."
],
"attachedSpells": [
"entangle"
],
"hasFluffImages": true
},
{
"name": "Golgari Keyrune",
"source": "GGR",
"page": 177,
"rarity": "very rare",
"reqAttune": "by a member of the Golgari guild",
"reqAttuneTags": [
{
"background": "golgari agent|ggr"
}
],
"wondrous": true,
"entries": [
"Made from deep green jade with black veins, this keyrune has an insectile shape. It can transform into a {@creature giant scorpion} for up to 6 hours. The scorpion has an Intelligence of 4 and can communicate with you telepathically while it is within 60 feet of you, though its messages are largely limited to describing the passage of potential prey.",
"When you use an action to speak the item's command word and place the keyrune on the ground in an unoccupied space within 5 feet of you, the keyrune transforms into a {@creature giant scorpion}. If there isn't enough space for the creature, the keyrune doesn't transform.",
"The creature is friendly to you, your companions, and other members of your guild (unless those guild members are hostile to you). It understands your languages and obeys your spoken commands. If you issue no commands, the creature takes the {@action Dodge} action and moves to avoid danger.",
"At the end of the duration, the creature reverts to its keyrune form. It reverts early if it drops to 0 hit points or if you use an action to speak the command word again while touching it. When the creature reverts to its keyrune form, it can't transform again until 36 hours have passed."
],
"hasFluffImages": true
},
{
"name": "Grappling Hook",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 4,
"value": 200
},
{
"name": "Gravenhollow Compass Ring",
"source": "OotA",
"page": 141,
"type": "OTH",
"rarity": "unknown (magic)",
"entries": [
"This gold ring is fitted with a star ruby worth 1,000 gp. A {@spell detect magic} spell reveals that the gem radiates a faint aura of divination magic. The gem's star-shaped core is a magical compass that guides the ring's wearer along the safest, shortest route to Gravenhollow."
]
},
{
"name": "Greater Silver Sword",
"source": "MTF",
"page": 89,
"baseItem": "greatsword|phb",
"type": "M",
"resist": [
"psychic"
],
"rarity": "legendary",
"reqAttune": "by a creature that has psionic ability",
"reqAttuneTags": [
{
"psionics": true
}
],
"weight": 6,
"weaponCategory": "martial",
"property": [
"H",
"2H"
],
"dmg1": "2d6",
"dmgType": "S",
"bonusWeapon": "+3",
"entries": [
"This magic weapon grants a +3 bonus to attack and damage rolls made with it. While you hold the sword, you have advantage on Intelligence, Wisdom, and Charisma saving throws, you are immune to being {@condition charmed}, and you have resistance to psychic damage. In addition, if you score a critical hit with it against a creature's astral body, you can cut the silvery cord that tethers the target to its material body, instead of dealing damage."
],
"hasFluffImages": true
},
{
"name": "Green Chromatic Rose",
"source": "WBtW",
"page": 208,
"resist": [
"poison"
],
"detail1": "issues green gas",
"rarity": "rare",
"wondrous": true,
"hasRefs": true,
"entries": [
"{#itemEntry Chromatic Rose|WBtW}"
]
},
{
"name": "Green Copper Ewer",
"source": "CoS",
"page": 188,
"type": "OTH",
"rarity": "unknown (magic)",
"entries": [
"Any poisonous liquid poured into the ewer is instantly transformed into an equal amount of sweet wine. Furthermore, a creature that grasps the ewer's handle can command the ewer to fill with 1 gallon of wine, and it can't produce more wine until the next dawn."
]
},
{
"name": "Green Dragon Mask",
"source": "RoTOS",
"page": 4,
"resist": [
"poison"
],
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"grantsProficiency": true,
"entries": [
"This mottled green mask is surmounted by a frilled crest and has leathery spiked plates along its jaw. The mask reshapes to fit a wearer attuned to it. While you are wearing the mask and attuned to it, you can access the following properties",
{
"type": "entries",
"name": "Damage Absorption",
"entries": [
"You have resistance against poison damage. If you already have resistance to poison damage from another source, you instead have immunity to poison damage. If you already have immunity to poison damage from another source, whenever you are subjected to poison damage, you take none of that damage and regain a number of hit points equal to half the damage dealt of that type."
]
},
{
"type": "entries",
"name": "Draconic Majesty",
"entries": [
"While you are wearing no armor, you can add your Charisma bonus to your Armor Class."
]
},
{
"type": "entries",
"name": "Dragon Breath",
"entries": [
"If you have a breath weapon that requires rest to recharge, it gains a recharge of 6."
]
},
{
"type": "entries",
"name": "Dragon Sight",
"entries": [
"You gain {@sense darkvision} out to 60 feet, or to an additional 60 feet if you already have that sense. Once per day, you can gain {@sense blindsight} out to 30 feet for 5 minutes."
]
},
{
"type": "entries",
"name": "Dragon Tongue",
"entries": [
"You can speak and understand Draconic. You also have advantage on any Charisma check you make against Green Dragons."
]
},
{
"type": "entries",
"name": "Legendary Resistance",
"entries": [
"(1/Day) If you fail a saving throw, you can choose to succeed instead."
]
},
{
"type": "entries",
"name": "Water Breathing",
"entries": [
"You can breathe underwater."
]
}
]
},
{
"name": "Green Dragon Scale Mail",
"source": "DMG",
"page": 165,
"srd": true,
"type": "MA",
"resist": [
"poison"
],
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"weight": 45,
"ac": 14,
"bonusAc": "+1",
"bonusSavingThrow": "+1",
"stealth": true,
"entries": [
"Dragon scale mail is made of the scales of one kind of dragon. Sometimes dragons collect their cast-off scales and gift them to humanoids. Other times, hunters carefully skin and preserve the hide of a dead dragon. In either case, dragon scale mail is highly valued. While wearing this armor, you gain a +1 bonus to AC, you have advantage on saving throws against the Frightful Presence and breath weapons of dragons, and you have resistance to poison damage.",
"Additionally, you can focus your senses as an action to magically discern the distance and direction to the closest green dragon within 30 miles of you. This special action can't be used again until the next dawn."
]
},
{
"name": "Grenade Launcher",
"source": "DMG",
"page": 268,
"type": "G",
"rarity": "none",
"weight": 7,
"age": "modern",
"entries": [
"As an action, a character can throw a grenade at a point up to 60 feet away. With a grenade launcher, the character can propel the grenade up to 120 feet away."
]
},
{
"name": "Grimoire Infinitus (Awakened)",
"source": "EGW",
"_copy": {
"name": "Grimoire Infinitus (Dormant)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Awakened",
"entries": [
"When the Grimoire Infinitus reaches an awakened state, it gains the following properties:",
{
"type": "list",
"items": [
"While you carry the spellbook, you have advantage on saving throws against spells and magical effects.",
"When you prepare wizard spells using the grimoire as your spellbook, the number of spells you can prepare increases by 1 again."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"hasFluffImages": true
},
{
"name": "Grimoire Infinitus (Dormant)",
"source": "EGW",
"page": 271,
"rarity": "legendary",
"reqAttune": "by a wizard",
"reqAttuneTags": [
{
"class": "wizard"
}
],
"wondrous": true,
"weight": 3,
"entries": [
"Several of these spellbooks with gilded pages and silver-plated covers were created during the Age of Arcanum, but only one has been found since the Calamity ended. The book has an infinite number of pages, is three inches thick, eight inches wide, twelve inches long, and weighs three pounds.",
{
"type": "entries",
"name": "Dormant",
"entries": [
"Most of the book is blank, but the following spells are recorded in the first pages of the tome: {@spell alarm}, {@spell antimagic field}, {@spell Bigby's hand}, {@spell blight}, {@spell charm person}, {@spell confusion}, {@spell control weather}, {@spell create undead}, {@spell detect thoughts}, {@spell enlarge/reduce}, {@spell fear}, {@spell foresight}, {@spell gaseous form}, {@spell glyph of warding}, {@spell legend lore}, {@spell Leomund's tiny hut}, {@spell mass suggestion}, {@spell mislead}, {@spell misty step}, {@spell Mordenkainen's faithful hound}, {@spell prismatic spray}, {@spell ray of enfeeblement}, {@spell silent image}, {@spell teleport}, and {@spell thunderwave}.",
"You can use the grimoire as your spellbook, and you can scribe new spells into it as normal. When you prepare wizard spells using the grimoire, the number of wizard spells you can prepare increases by 1."
]
}
],
"hasFluffImages": true
},
{
"name": "Grimoire Infinitus (Exalted)",
"source": "EGW",
"_copy": {
"name": "Grimoire Infinitus (Awakened)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Exalted",
"entries": [
"When the Grimoire Infinitus reaches an exalted state, it gains the following properties:",
{
"type": "list",
"items": [
"You can now use your Arcane Recovery feature twice between long rests, rather than once.",
"When you prepare wizard spells using the grimoire as your spellbook, the number of spells you can prepare increases by 1 again."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"hasFluffImages": true
},
{
"name": "Grovelthrash (Awakened)",
"source": "EGW",
"_copy": {
"name": "Grovelthrash (Dormant)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Awakened",
"entries": [
"When the warhammer reaches an awakened state, it gains the following properties:",
{
"type": "list",
"items": [
"The weapon's bonus to attack and damage rolls increases to +2.",
"You gain a burrowing speed equal to your walking speed. You can use your burrowing speed to move through sand, loose earth, mud, or ice, not solid rock.",
"When a creature you can see within 30 feet of you hits you with an attack while you are wielding the warhammer, you can use your reaction to deal an amount of psychic damage to that creature equal to the damage you took from the attack. This property can't be used again until the next dawn."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"bonusWeapon": "+2",
"modifySpeed": {
"equal": {
"burrow": "walk"
}
},
"hasFluffImages": true
},
{
"name": "Grovelthrash (Dormant)",
"source": "EGW",
"page": 275,
"baseItem": "warhammer|PHB",
"type": "M",
"rarity": "artifact",
"reqAttune": true,
"sentient": true,
"weight": 2,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "B",
"dmg2": "1d10",
"bonusWeapon": "+1",
"weapon": true,
"entries": [
"Crafted from a single piece of obsidian, this warhammer of Torog hums with magical energy and is carved with the images of faces screaming in pain.",
{
"type": "entries",
"name": "Sentience",
"entries": [
"Grovelthrash is a sentient neutral evil weapon with an Intelligence of 18, a Wisdom of 15, and a Charisma of 19. It has hearing and {@sense darkvision} out to a range of 120 feet.",
"The weapon communicates telepathically with its wielder and can speak, read, and understand Abyssal, Common, and Infernal."
]
},
{
"type": "entries",
"name": "Personality",
"entries": [
"A covetous {@creature ultroloth} named Ciria lives within Grovelthrash. The weapon values material possessions and doling out pain. It compliments its wielder whenever they claim treasure and takes pleasure in harming others."
]
},
{
"type": "entries",
"name": "Dormant",
"entries": [
"The warhammer grants the following benefits in its dormant state:",
{
"type": "list",
"items": [
"You can speak, read, and write Abyssal and Infernal.",
"You gain a +1 bonus to attack and damage rolls made with this magic weapon. When you hit with an attack using this warhammer, you can deal an extra {@damage 2d6} bludgeoning damage to the target. If you do, you take {@damage 1d6} psychic damage. The warhammer deals double damage to objects and structures.",
"While holding this weapon, you have advantage on Wisdom ({@skill Insight}) checks made to discern a lie spoken in a language you understand."
]
}
]
},
{
"type": "inset",
"name": "Betrayer Artifact Properties",
"entries": [
"The Arms of the Betrayers advance in power in the same manner as the Vestiges of Divergence. In its dormant state, each of these artifacts has one {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} property and one {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental} property. When the artifact attains an awakened state, it gains an additional {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} property and an additional {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental} property. When the item reaches its exalted state, it gains a {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial} property. See \"Artifact Properties\" in chapter 7 of the Dungeon Master's Guide for more information."
]
}
],
"hasFluffImages": true
},
{
"name": "Grovelthrash (Exalted)",
"source": "EGW",
"_copy": {
"name": "Grovelthrash (Awakened)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Exalted",
"entries": [
"When the warhammer reaches an exalted state, it gains the following properties:",
{
"type": "list",
"items": [
"The weapon's bonus to attack and damage rolls increases to +3.",
"When you have fewer hit points than half your hit point maximum, the warhammer deals an extra {@damage 2d6} bludgeoning damage on a hit. You can burrow through solid rock at half your burrowing speed and leave a 5-foot-diameter tunnel in your wake.",
"As an action, you can use the warhammer to cast one of the following spells from it (save DC 17): {@spell earthquake}, {@spell meld into stone}, or {@spell stone shape}. Once a spell has been cast using the warhammer, that spell can't be cast from the warhammer again until the next dawn."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"bonusWeapon": "+3",
"attachedSpells": [
"earthquake",
"meld into stone",
"stone shape"
],
"hasFluffImages": true
},
{
"name": "Gruul Guild Signet",
"source": "GGR",
"page": 178,
"type": "RG",
"rarity": "uncommon",
"reqAttune": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"This ring, adorned with the symbol of Gruul, allows you to cast {@spell compelled duel}. A guild signet is sometimes awarded to a guild member whose renown score in that guild is 5 or higher, as a reward for performing special services for the guild. Aside from its magical properties, the ring is also an indicator of Gruul's recognition and favor.",
"A signet has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. While wearing it, you can expend 1 charge to cast the associated spell (save DC 13)."
],
"attachedSpells": [
"compelled duel"
],
"hasFluffImages": true
},
{
"name": "Gruul Keyrune",
"source": "GGR",
"page": 177,
"rarity": "rare",
"reqAttune": "by a member of the Gruul guild",
"reqAttuneTags": [
{
"background": "gruul anarch|ggr"
}
],
"wondrous": true,
"entries": [
"This crude keyrune is cobbled together from bits of rubble, broken glass, bone, and animal hair. One end resembles a horned beast. On command, the keyrune transforms into a ceratok, a horned creature much like a {@creature rhinoceros} (and with the same statistics). It remains in its ceratok form for 1 hour.",
"When you use an action to speak the item's command word and place the keyrune on the ground in an unoccupied space within 5 feet of you, the keyrune transforms into a ceratok. If there isn't enough space for the creature, the keyrune doesn't transform.",
"The creature is friendly to you, your companions, and other members of your guild (unless those guild members are hostile to you). It understands your languages and obeys your spoken commands. If you issue no commands, the creature takes the {@action Dodge} action and moves to avoid danger.",
"At the end of the duration, the creature reverts to its keyrune form. It reverts early if it drops to 0 hit points or if you use an action to speak the command word again while touching it. When the creature reverts to its keyrune form, it can't transform again until 36 hours have passed."
],
"hasFluffImages": true
},
{
"name": "Guardian Emblem",
"source": "TCE",
"page": 128,
"rarity": "uncommon",
"reqAttune": "by a cleric or paladin",
"reqAttuneTags": [
{
"class": "cleric"
},
{
"class": "paladin"
}
],
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": 3,
"charges": 3,
"entries": [
"This emblem is the symbol of a deity or a spiritual tradition. As an action, you can attach the emblem to a suit of armor or a shield or remove it.",
"The emblem has 3 charges. When you or a creature you can see within 30 feet of you suffers a critical hit while you're wearing the armor or wielding the shield that bears the emblem, you can use your reaction to expend 1 charge to turn the critical hit into a normal hit instead.",
"The emblem regains all expended charges daily at dawn."
]
},
{
"name": "Gulthias Staff",
"source": "CoS",
"page": 221,
"rarity": "rare",
"reqAttune": true,
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"recharge": "dusk",
"rechargeAmount": "{@dice 1d6 + 4}",
"charges": 10,
"staff": true,
"entries": [
"Made from the branch of a Gulthias tree (see the {@creature twig blight||blights} entry of the Monster Manual), a Gulthias staff is a spongy, black length of wood. Its evil makes beasts visibly uncomfortable while within 30 feet of it. The staff has 10 charges and regains {@dice 1d6 + 4} of its expended charges daily at dusk.",
"If the staff is broken or burned to ashes, its wood releases a terrible, inhuman scream that can be heard out to a range of 300 feet. All blights that can hear the scream immediately wither and die.",
{
"name": "Vampiric Strike",
"type": "entries",
"entries": [
"The staff can be wielded as a magic quarterstaff. On a hit, it deals damage as a normal quarterstaff, and you can expend 1 charge to regain a number of hit points equal to the damage dealt by the weapon. Each time a charge is spent, red blood oozes from the staff's pores, and you must succeed on a DC 12 Wisdom saving throw or be afflicted with {@table Short-Term Madness|DMG|short term madness} (see \"{@variantrule Madness|DMG}\" in chapter 8 of the Dungeon Master's Guide)."
]
},
{
"name": "Blight Bane",
"type": "entries",
"entries": [
"While you are attuned to the staff, blights and other evil plant creatures don't regard you as hostile unless you harm them."
]
}
],
"hasFluffImages": true
},
{
"name": "Gunpowder Horn",
"source": "DMG",
"page": 268,
"type": "EXP",
"rarity": "none",
"weight": 2,
"value": 3500,
"age": "renaissance",
"entries": [
"Gunpowder is chiefly used to propel a bullet out of the barrel of a pistol or rifle, or it is formed into a bomb. Gunpowder is sold in small wooden kegs or in water-resistant powder horns.",
"Setting fire to a container full of gunpowder can cause it to explode, dealing fire damage to creatures within 10 feet of it ({@damage 3d6} for a powder horn). A successful DC 12 Dexterity saving throw halves the damage. Setting fire to an ounce of gunpowder causes it to flare for 1 round, shedding bright light in a 30-foot radius and dim light for an additional 30 feet."
]
},
{
"name": "Gunpowder Keg",
"source": "DMG",
"page": 268,
"type": "EXP",
"rarity": "none",
"weight": 20,
"value": 25000,
"age": "renaissance",
"entries": [
"Gunpowder is chiefly used to propel a bullet out of the barrel of a pistol or rifle, or it is formed into a bomb. Gunpowder is sold in small wooden kegs or in water-resistant powder horns.",
"Setting fire to a container full of gunpowder can cause it to explode, dealing fire damage to creatures within 10 feet of it ({@damage 7d6} for a keg). A successful DC 12 Dexterity saving throw halves the damage. Setting fire to an ounce of gunpowder causes it to flare for 1 round, shedding bright light in a 30-foot radius and dim light for an additional 30 feet."
]
},
{
"name": "Gurt's Greataxe",
"source": "SKT",
"page": 234,
"baseItem": "greataxe|phb",
"type": "M",
"rarity": "legendary",
"reqAttune": true,
"weight": 325,
"weaponCategory": "martial",
"property": [
"H",
"2H"
],
"dmg1": "3d12",
"dmgType": "S",
"bonusWeapon": "+1",
"entries": [
"In the Year of the Icy Axe (123 DR), the {@creature frost giant} Lord Gurt fell to Uthgar Gardolfsson—leader of the folk who would become the Uthgardt barbarians—in a battle that marked the ascendance of humankind over the giants in the Dessarin Valley. Gurt's greataxe was buried in Morgur's Mound until it was unearthed and brought back to Waterdeep. After laying in the city's vaults for decades, the axe was given to Harshnag, a {@creature frost giant} adventurer, in recognition of his service to Waterdeep. Uthgardt barbarians recognize the weapon on sight and attack any giant that wields it.",
"You gain a +1 bonus to attack and damage rolls made with this magic weapon. It is sized for a giant, weighs 325 pounds, and deals {@damage 3d12} slashing damage on a hit, plus an extra {@damage 2d12} slashing damage if the target is human.",
"The axe sheds light as a torch when the temperature around it drops below 0 degrees Fahrenheit. The light can't be shut off in these conditions.",
"As an action, you can cast a version of the {@spell heat metal} spell (save DC 13) that deals cold damage instead of fire damage. Once this power is used, it can't be used again until the next dawn."
],
"attachedSpells": [
"heat metal"
]
},
{
"name": "Hacking Tools",
"source": "UAModernMagic",
"page": 4,
"type": "T",
"rarity": "none",
"age": "modern",
"entries": [
"This kit contains the hardware and software necessary to allow access into most computer systems and electronic devices. Proficiency with hacking tools lets you add your proficiency bonus to any Intelligence checks you make to connect to or make use of a computer system or electronic device. The kit fits snugly in a backpack or toolbox. "
]
},
{
"name": "Hag Eye",
"source": "MM",
"page": 177,
"type": "OTH",
"rarity": "unknown (magic)",
"entries": [
"A hag coven can craft a magic item called a hag eye, which is made from a real eye coated in varnish and often fitted to a pendant or other wearable item. The hag eye is usually entrusted to a minion for safekeeping and transport. A hag in the coven can take an action to see what the hag eye sees if the hag eye is on the same plane of existence. A hag eye has AC 10, 1 hit point, and {@sense darkvision} with a radius of 60 feet. If it is destroyed, each coven member takes {@damage 3d10} psychic damage and is {@condition blinded} for 24 hours.",
"A hag coven can have only one hag eye at a time, and creating a new one requires all three members of the coven to perform a ritual. The ritual takes 1 hour, and the hags can't perform it while {@condition blinded}. During the ritual, if the hags take any action other than performing the ritual, they must start over."
]
},
{
"name": "Hammer",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 3,
"value": 100
},
{
"name": "Hammer of Thunderbolts",
"source": "DMG",
"page": 173,
"srd": true,
"baseItem": "maul|phb",
"type": "M",
"tier": "major",
"rarity": "legendary",
"weight": 10,
"weaponCategory": "martial",
"property": [
"H",
"2H"
],
"dmg1": "2d6",
"dmgType": "B",
"ability": {
"str": 4
},
"bonusWeapon": "+1",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d4 + 1}",
"charges": 5,
"entries": [
"You gain a +1 bonus to attack and damage rolls made with this magic weapon.",
{
"type": "entries",
"name": "Giant's Bane (Requires Attunement)",
"entries": [
"You must be wearing a {@italic belt of giant strength} (any variety) and {@item gauntlets of ogre power} to attune to this weapon. The attunement ends if you take off either of those items. While you are attuned to this weapon and holding it, your Strength score increases by 4 and can exceed 20, but not 30. When you roll a 20 on an attack roll made with this weapon against a giant, the giant must succeed on a DC 17 Constitution saving throw or die."
]
},
"The hammer also has 5 charges. While attuned to it, you can expend 1 charge and make a ranged weapon attack with the hammer, hurling it as if it had the thrown property with a normal range of 20 feet and a long range of 60 feet. If the attack hits, the hammer unleashes a thunderclap audible out to 300 feet. The target and every creature within 30 feet of it must succeed on a DC 17 Constitution saving throw or be {@condition stunned} until the end of your next turn. The hammer regains {@dice 1d4 + 1} expended charges daily at dawn."
],
"lootTables": [
"Magic Item Table I"
],
"hasFluffImages": true
},
{
"name": "Hammerhead Ship",
"source": "AAG",
"page": 0,
"otherSources": [
{
"source": "SjA"
}
],
"type": "SPC",
"rarity": "none",
"value": 4000000,
"crew": 15,
"vehAc": 15,
"vehHp": 400,
"vehDmgThresh": 15,
"vehSpeed": 4,
"capCargo": 30,
"entries": [
"Hammerhead ships are popular craft, especially among pirates and merchants carrying heavy cargo. They can float on water and sail across it, but they aren't built to land on the ground (their keels would cause them to tip to one side). Standard weapons on a hammerhead ship include fore and aft mangonels, a ballista, and a reinforced bow for ramming."
],
"seeAlsoVehicle": [
"Hammerhead Ship|AAG"
]
},
{
"name": "Hammock of Worlds",
"source": "JttRC",
"page": 214,
"rarity": "legendary",
"wondrous": true,
"entries": [
"The Hammock of Worlds is a colorful hammock woven with traditional Ataguan designs. It can be used only by a member of the order known as the Green Doctors.",
"You can use an action to unfold and place the Hammock of Worlds on a solid surface, whereupon it creates a two-way portal to the Ghost Orchid Tepui in the Feywild or to the Drought Elder in the Far Realm (the user's choice). You can use an action to close an open portal by taking hold of the edges of the cloth and folding it up. Once Hammock of Worlds has opened a portal, it can't do so again for {@dice 1d8} hours."
],
"hasFluffImages": true
},
{
"name": "Hand Drum",
"source": "SCAG",
"page": 124,
"type": "INS",
"rarity": "none",
"entries": [
"A double-headed skin drum fitted with handles along its side."
]
},
{
"name": "Hand of Vecna",
"source": "DMG",
"page": 224,
"rarity": "artifact",
"reqAttune": true,
"wondrous": true,
"ability": {
"static": {
"str": 20
}
},
"recharge": "dawn",
"rechargeAmount": "{@dice 1d4 + 4}",
"charges": 8,
"entries": [
"Seldom is the name of Vecna spoken except in a hushed voice. Vecna was, in his time, one of the mightiest of all wizards. Through dark magic and conquest, he forged a terrible empire. For all his power, Vecna couldn't escape his own mortality. He began to fear death and take steps to prevent his end from ever coming about.",
"{@creature Orcus|MTF}, the demon prince of undeath, taught Vecna a ritual that would allow him to live on as a {@creature lich}. Beyond death, he became the greatest of all liches. Even though his body gradually withered and decayed, Vecna continued to expand his evil dominion. So formidable and hideous was his temper that his subjects feared to speak his name. He was the Whispered One, the Master of the Spider Throne, the Undying King, and the Lord of the Rotted Tower.",
"Some say that Vecna's lieutenant Kas coveted the Spider Throne for himself, or that the sword his lord made for him seduced him into rebellion. Whatever the reason, Kas brought the Undying King's rule to an end in a terrible battle that left Vecna's tower a heap of ash. Of Vecna, all that remained were one hand and one eye, grisly artifacts that still seek to work the Whispered One's will in the world.",
"The {@item Eye of Vecna} and the Hand of Vecna might be found together or separately. The eye looks like a bloodshot organ torn free from the socket. The hand is a mummified and shriveled left extremity.",
"To attune to the eye, you must gouge out your own eye and press the artifact into the empty socket. The eye grafts itself to your head and remains there until you die. Once in place, the eye transforms into a golden eye with a slit for a pupil, much like that of a cat. If the eye is ever removed, you die.",
"To attune to the hand, you must lop off your left hand at the wrist and the press the artifact against the stump. The hand grafts itself to your arm and becomes a functioning appendage. If the hand is ever removed, you die.",
{
"name": "Random Properties",
"type": "entries",
"entries": [
"The {@item Eye of Vecna} and the Hand of Vecna each have the following random properties:"
]
},
{
"type": "list",
"items": [
"1 {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial property}",
"1 {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial property}",
"1 {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental property}"
]
},
{
"name": "Properties of the Hand",
"type": "entries",
"entries": [
"Your alignment changes to neutral evil, and you gain the following benefits:"
]
},
{
"type": "list",
"items": [
"Your Strength score becomes 20, unless it is already 20 or higher.",
"Any melee spell attack you make with the hand, and any melee weapon attack made with a weapon held by it, deals an extra {@damage 2d8} cold damage on a hit.",
"The hand has 8 charges. You can use an action and expend 1 or more charges to cast one of the following spells (save DC 18) from it: {@spell finger of death} (5 charges), {@spell sleep} (1 charge), {@spell slow} (2 charges), or {@spell teleport} (3 charges). The hand regains {@dice 1d4 + 4} expended charges daily at dawn. Each time you cast a spell from the hand, it casts the {@spell suggestion} spell on you (save DC 18), demanding that you commit an evil act. The hand might have a specific act in mind or leave it up to you."
]
},
{
"name": "Properties of the Eye and Hand",
"type": "entries",
"entries": [
"If you are attuned to both the hand and eye, you gain the following additional benefits:",
{
"type": "list",
"items": [
"You are immune to disease and poison.",
"Using the eye's X-ray vision never causes you to suffer {@condition exhaustion}.",
"You experience premonitions of danger and, unless you are {@condition incapacitated}, can't be {@quickref Surprise|PHB|3|0|surprised}.",
"If you start your turn with at least 1 hit point, you regain {@dice 1d10} hit points.",
"If a creature has a skeleton, you can attempt to turn its bones to jelly with a touch of the Hand of Vecna. You can do so by using an action to make a melee attack against a creature you can reach, using your choice of your melee attack bonus for weapons or spells. On a hit, the target must succeed on a DC 18 Constitution saving throw or drop to 0 hit points.",
"You can use an action to cast {@spell wish}. This property can't be used again until 30 days have passed."
]
}
]
},
{
"name": "Destroying the Eye and Hand",
"type": "entries",
"entries": [
"If the {@item Eye of Vecna} and the Hand of Vecna are both attached to the same creature, and that creature is slain by the {@item Sword of Kas}, both the eye and the hand burst into flame, turn to ash, and are destroyed forever. Any other attempt to destroy the eye or hand seems to work, but the artifact reappears in one of Vecna's many hidden vaults, where it waits to be rediscovered."
]
}
],
"attachedSpells": [
"wish",
"finger of death",
"sleep",
"slow",
"teleport"
],
"hasFluffImages": true
},
{
"name": "Harbor Moon",
"source": "WDH",
"page": 169,
"type": "$",
"rarity": "none",
"value": 5000,
"entries": [
"Platinum crescent inset with electrum, about three inches long with a hole large enough for a {@item nib|wdh} to fit in (1 harbor moon = 5,000 {@item nib|wdh|nibs})"
]
},
{
"name": "Harkon's Bite",
"source": "VRGR",
"page": 137,
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"curse": true,
"bonusSavingThrow": "+1",
"entries": [
"A dire wolf tooth dangles from this simple cord necklace. While you wear it, the necklace grants you a +1 bonus to ability checks and saving throws.",
{
"type": "entries",
"name": "Curse",
"entries": [
"Attuning to Harkon's Bite curses you until either Harkon Lukas removes the necklace from you or you are targeted by a {@spell remove curse} spell or similar magic. As long as you remain cursed, you cannot remove the necklace.",
"Upon donning or removing the necklace, whether you are attuned to it or not, you are afflicted with {@variantrule Player Characters as Lycanthropes|mm|werewolf lycanthropy} as detailed in the Monster Manual. The curse lasts until the dawn after the next full moon. If you are still wearing the necklace at this time, you are afflicted with the lycanthropy again."
]
}
]
},
{
"name": "Hat of Disguise",
"source": "DMG",
"page": 173,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"entries": [
"While wearing this hat, you can use an action to cast the {@spell disguise self} spell from it at will. The spell ends if the hat is removed."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"attachedSpells": [
"disguise self"
],
"lootTables": [
"Magic Item Table F"
]
},
{
"name": "Hat of Vermin",
"source": "XGE",
"page": 137,
"tier": "minor",
"rarity": "common",
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": 3,
"charges": 3,
"entries": [
"This hat has 3 charges. While holding the hat, you can use an action to expend 1 of its charges and speak a command word that summons your choice of a {@creature bat}, a {@creature frog}, or a {@creature rat}. The summoned creature magically appears in the hat and tries to get away from you as quickly as possible. The creature is neither friendly nor hostile, and it isn't under your control. It behaves as an ordinary creature of its kind and disappears after 1 hour or when it drops to 0 hit points. The hat regains all expended charges daily at dawn."
]
},
{
"name": "Hat of Wizardry",
"source": "XGE",
"page": 137,
"tier": "minor",
"rarity": "common",
"reqAttune": "by a wizard",
"reqAttuneTags": [
{
"class": "wizard"
}
],
"wondrous": true,
"focus": [
"Wizard"
],
"entries": [
"This antiquated, cone—shaped hat is adorned with gold crescent moons and stars. While you are wearing it, you gain the following benefits:",
{
"type": "list",
"items": [
"You can use the hat as a spellcasting focus for your wizard spells.",
"You can try to cast a cantrip that you don't know. The cantrip must be on the wizard spell list, and you must make a DC 10 Intelligence ({@skill Arcana}) check. If the check succeeds, you cast the spell. If the check fails, so does the spell, and the action used to cast the spell is wasted. In either case, you can't use this property again until you finish a long rest."
]
}
]
},
{
"name": "Hazirawn",
"source": "HotDQ",
"page": 94,
"otherSources": [
{
"source": "ToD",
"page": 178
}
],
"baseItem": "greatsword|phb",
"type": "M",
"rarity": "legendary",
"reqAttune": true,
"reqAttuneAlt": "optional",
"sentient": true,
"weight": 6,
"weaponCategory": "martial",
"property": [
"H",
"2H"
],
"dmg1": "2d6",
"dmgType": "S",
"bonusWeapon": "+1",
"recharge": "midnight",
"rechargeAmount": "{@dice 1d4}",
"charges": 4,
"entries": [
"A sentient (neutral evil) greatsword, Hazirawn is capable of speech in Common and Netherese. Even if you aren't attuned to the sword, you gain a +1 bonus to attack and damage rolls made with this weapon. If you are not attuned to Hazirawn, you deal an extra {@damage 1d6} necrotic damage when you hit with the weapon.",
{
"name": "Increased Potency",
"type": "entries",
"entries": [
"While you are attuned to this weapon, its bonus on attack and damage rolls increases to +2, and a hit deals an extra {@damage 2d6} necrotic damage (instead of {@dice 1d6})."
]
},
{
"name": "Spells",
"type": "entries",
"entries": [
"Hazirawn has 4 charges to cast spells. As long as the sword is attuned to you and you are holding it in your hand, you can cast {@spell detect magic} (1 charge), {@spell detect evil and good} (1 charge), or {@spell detect thoughts} (2 charges). Each night at midnight, Hazirawn regains {@dice 1d4} expended charges."
]
},
{
"name": "Wounding",
"type": "entries",
"entries": [
"While you are attuned to the weapon, any creature that you hit with Hazirawn can't regain hit points for 1 minute. The target can make a DC 15 Constitution saving throw at the end of each of its turns, ending this effect early on a success."
]
}
],
"attachedSpells": [
"detect magic",
"detect evil and good",
"detect thoughts"
]
},
{
"name": "Headband of Intellect",
"source": "DMG",
"page": 173,
"srd": true,
"basicRules": true,
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"ability": {
"static": {
"int": 19
}
},
"entries": [
"Your Intelligence score is 19 while you wear this headband. It has no effect on you if your Intelligence is already 19 or higher without it."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Healer's Kit",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 3,
"value": 500,
"entries": [
"This kit is a leather pouch containing bandages, salves, and splints. The kit has ten uses. As an action, you can expend one use of the kit to stabilize a creature that has 0 hit points, without needing to make a Wisdom ({@skill Medicine}) check."
],
"hasFluffImages": true
},
{
"name": "Heart Weaver's Primer",
"source": "TCE",
"page": 128,
"rarity": "rare",
"reqAttune": "by a wizard",
"reqAttuneTags": [
{
"class": "wizard"
}
],
"wondrous": true,
"weight": 3,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"focus": [
"Wizard"
],
"entries": [
"This pristine book smells faintly of a random scent you find pleasing. When found, the book contains the following spells: {@spell antipathy/sympathy}, {@spell charm person}, {@spell dominate person}, {@spell enthrall}, {@spell hypnotic pattern}, {@spell modify memory}, and {@spell suggestion}. It functions as a spellbook for you.",
"While you are holding the book, you can use it as a spellcasting focus for your wizard spells.",
"The book has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. You can use the charges in the following ways while holding it:",
{
"type": "list",
"items": [
"If you spend 1 minute studying the book, you can expend 1 charge to replace one of your prepared wizard spells with a different spell in the book. The new spell must be of the enchantment school.",
"When you cast an enchantment spell, you can expend 1 charge to impose disadvantage on the first saving throw one target makes against the spell."
]
}
],
"attachedSpells": [
"antipathy/sympathy",
"charm person",
"dominate person",
"enthrall",
"hypnotic pattern",
"modify memory",
"suggestion"
]
},
{
"name": "Heartstone",
"source": "MM",
"page": 179,
"type": "OTH",
"rarity": "very rare",
"entries": [
"This lustrous black gem allows a {@creature night hag} to become ethereal while it is in her possession. The touch of a heartstone also cures any disease. Crafting a heartstone takes 30 days."
]
},
{
"name": "Hell Hound Cloak",
"source": "TftYP",
"page": 228,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"curse": true,
"weight": 1,
"entries": [
"This dark cloak is made of cured {@creature hell hound} hide. As an action, you can command the cloak to transform you into a {@creature hell hound} for up to 1 hour. The transformation otherwise functions as the {@spell polymorph} spell, but you can use a bonus action to revert to your normal form.",
{
"type": "entries",
"name": "Curse",
"entries": [
"This cloak is cursed with the essence of a {@creature hell hound}, and becoming attuned to it extends the curse to you. Until the curse is broken with {@spell remove curse} or similar magic, you are unwilling to part with the cloak, keeping it within reach at all times."
]
},
"The sixth time you use the cloak, and each time thereafter, you must make a DC 15 Charisma saving throw. On a failed save, the transformation lasts until dispelled or until you drop to 0 hit points, and you can't willingly return to normal form. If you ever remain in {@creature hell hound} form for 6 hours, the transformation becomes permanent and you lose your sense of self. All your statistics are then replaced by those of a {@creature hell hound}. Thereafter, only {@spell remove curse} or similar magic allows you to regain your identity and return to normal. If you remain in this permanent form for 6 days, only a {@spell wish} spell can reverse the transformation."
],
"attachedSpells": [
"polymorph"
]
},
{
"name": "Helm of Brilliance",
"source": "DMG",
"page": 173,
"srd": true,
"resist": [
"fire"
],
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"This dazzling helm is set with {@dice 1d10} diamonds, {@dice 2d10} rubies, {@dice 3d10} fire opals, and {@dice 4d10} opals. Any gem pried from the helm crumbles to dust. When all the gems are removed or destroyed, the helm loses its magic.",
"You gain the following benefits while wearing it:",
{
"type": "list",
"items": [
"You can use an action to cast one of the following spells (save DC 18), using one of the helm's gems of the specified type as a component: {@spell daylight} (opal), {@spell fireball} (fire opal), {@spell prismatic spray} (diamond), or {@spell wall of fire} (ruby). The gem is destroyed when the spell is cast and disappears from the helm.",
"As long as it has at least one diamond, the helm emits dim light in a 30-foot radius when at least one undead is within that area. Any undead that starts its turn in that area takes {@damage 1d6} radiant damage.",
"As long as the helm has at least one ruby, you have resistance to fire damage.",
"As long as the helm has at least one fire opal, you can use an action and speak a command word to cause one weapon you are holding to burst into flames. The flames emit bright light in a 10-foot radius and dim light for an additional 10 feet. The flames are harmless to you and the weapon. When you hit with an attack using the blazing weapon, the target takes an extra {@damage 1d6} fire damage. The flames last until you use a bonus action to speak the command word again or until you drop or stow the weapon."
]
},
"Roll a {@dice d20} if you are wearing the helm and take fire damage as a result of failing a saving throw against a spell. On a roll of 1, the helm emits beams of light from its remaining gems. Each creature within 60 feet of the helm other than you must succeed on a DC 17 Dexterity saving throw or be struck by a beam, taking radiant damage equal to the number of gems in the helm. The helm and its gems are then destroyed."
],
"attachedSpells": [
"daylight",
"fireball",
"prismatic spray",
"wall of fire"
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Helm of Comprehending Languages",
"source": "DMG",
"page": 173,
"srd": true,
"tier": "minor",
"rarity": "uncommon",
"wondrous": true,
"entries": [
"While wearing this helm, you can use an action to cast the {@spell comprehend languages} spell from it at will."
],
"attachedSpells": [
"comprehend languages"
],
"lootTables": [
"Magic Item Table B"
],
"hasFluffImages": true
},
{
"name": "Helm of Devil Command",
"source": "BGDIA",
"page": 223,
"rarity": "very rare",
"reqAttune": "by a creature that can speak Infernal",
"reqAttuneTags": [
{
"languageProficiency": "infernal"
}
],
"wondrous": true,
"charges": 3,
"entries": [
"This bulky, eyeless helmet is made for a {@creature pit fiend} but magically resizes to fit the heads of other wearers.",
"While wearing the helm, you can see out of it as though you weren't wearing it. In addition, you know the exact location and type of all devils within 1,000 feet of you. You can telepathically communicate with a devil within range, or you can broadcast your thoughts to all devils within range. The devils receiving your broadcasted thoughts have no special means of replying to them.",
"The helm has 3 charges. As an action, you can expend 1 charge to cast {@spell dominate monster} (save DC 21), which affects devils only. (The spell fails and the charge is wasted if you target any creature that's not a devil.) If a devil can see you when you cast this spell on it, the devil knows you tried to charm it. The helm regains all its charges 24 hours after its last charge is expended.",
"If you are not a devil, using the helm's {@spell dominate monster} property in the Nine Hells has a {@chance 20|20 percent} chance of attracting a {@creature narzugon|MTF}, which arrives on the back of a {@creature nightmare} mount in {@dice 1d4} hours. The narzugon tries to recover the helm, killing you if necessary to obtain it. If it gets the helm, the narzugon tries to deliver it to its infernal master."
],
"attachedSpells": [
"dominate monster"
],
"hasFluffImages": true
},
{
"name": "Helm of Telepathy",
"source": "DMG",
"page": 174,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"entries": [
"While wearing this helm, you can use an action to cast the {@spell detect thoughts} spell (save DC 13) from it. As long as you maintain concentration on the spell, you can use a bonus action to send a telepathic message to a creature you are focused on. It can reply—using a bonus action to do so—while your focus on it continues.",
"While focusing on a creature with {@spell detect thoughts}, you can use an action to cast the {@spell suggestion} spell (save DC 13) from the helm on that creature. Once used, the suggestion property can't be used again until the next dawn."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"attachedSpells": [
"detect thoughts",
"suggestion"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Helm of Teleportation",
"source": "DMG",
"page": 174,
"srd": true,
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"This helm has 3 charges. While wearing it, you can use an action and expend 1 charge to cast the {@spell teleport} spell from it. The helm regains {@dice 1d3} expended charges daily at dawn."
],
"attachedSpells": [
"teleport"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Helm of the Gods",
"source": "MOT",
"page": 196,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"While wearing this helm, you know whether there is a celestial or fiend within 30 feet of you, as well as where the creature is located, provided the creature isn't behind total cover.",
"Whenever you finish a long rest while wearing the helm, you can pray to one of the gods listed on the Helm of the Gods table and store the listed spell in the helm, replacing any spell that is already stored there. The save DC for the spell is 13.",
"The helm has 3 charges. To cast a spell from the helm, you must expend 1 charge, and the helm regains {@dice 1d3} charges daily at dawn.",
{
"type": "table",
"colStyles": [
"col-2 text-center",
"col-10 text-center"
],
"colLabels": [
"God",
"Spell"
],
"rows": [
[
"Athreos",
"{@spell protection from evil and good}"
],
[
"Ephara",
"{@spell sanctuary}"
],
[
"Erebos",
"{@spell inflict wounds}"
],
[
"Heliod",
"{@spell guiding bolt}"
],
[
"Iroas",
"{@spell heroism}"
],
[
"Karametra",
"{@spell goodberry}"
],
[
"Keranos",
"{@spell thunderous smite}"
],
[
"Klothys",
"{@spell entangle}"
],
[
"Kruphix",
"{@spell dissonant whispers}"
],
[
"Mogis",
"{@spell hellish rebuke}"
],
[
"Nylea",
"{@spell faerie fire}"
],
[
"Pharika",
"{@spell lesser restoration}"
],
[
"Phenax",
"{@spell charm person}"
],
[
"Purphoros",
"{@spell searing smite}"
],
[
"Thassa",
"{@spell identify}"
]
]
}
]
},
{
"name": "Helm of the Scavenger",
"source": "WDMM",
"page": 297,
"rarity": "legendary",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"wondrous": true,
"entries": [
"This ornate chair is designed to propel and maneuver a ship through space.",
{
"type": "entries",
"name": "Passive Properties",
"entries": [
"The following properties of the helm come into play even when no creature is attuned to it:",
{
"type": "list",
"items": [
"When placed aboard a vessel weighing between 1 and 100 tons, the helm generates an envelope of fresh air around the ship while it is in the void of space (but not underwater). This envelope extends out from the edges of the hull in all directions for a distance equal in length to the vessel's beam, so that creatures aboard and near the ship can breathe normally in space. The temperature within the air envelope is 70 degrees Fahrenheit.",
"When placed aboard a vessel weighing between 1 and 100 tons, the helm generates an artificial gravity field while the ship is in the void of space, so that creatures can walk on the ship's decks as they normally would. Creatures and objects that fall overboard bob in a gravity plane that extends out from the main deck for a distance equal in length to the vessel's beam."
]
}
]
},
{
"type": "entries",
"name": "Active Properties",
"entries": [
"The sensation of being attuned to the helm is akin to being immersed in warm water. While attuned to the helm, you gain the following abilities while you sit in it:",
{
"type": "list",
"items": [
"You can use the helm to propel the vessel across or through water and other liquids at a maximum speed in miles per hour equal to your highest-level unexpended spell slot.",
"You can use the helm to propel the vessel through air or space at a maximum speed in miles per hour equal to your highest-level unexpended spell slot × 10.",
"Provided you have at least one unexpended spell slot, you can steer the vessel, albeit in a somewhat clumsy fashion, in much the same way that oars or a rudder can maneuver a seafaring ship.",
"Whenever you like, you can see what's happening on and around the vessel as though you were standing in a location of your choice aboard it."
]
}
]
},
{
"type": "entries",
"name": "Drawback",
"entries": [
"While attuned to the helm, you cannot expend your own spell slots."
]
}
]
},
{
"name": "Helm of Underwater Action",
"source": "GoS",
"page": 229,
"otherSources": [
{
"source": "IMR",
"page": 94
}
],
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"modifySpeed": {
"static": {
"swim": 30
}
},
"entries": [
"While wearing this brass helmet, you can breathe underwater, you gain {@sense darkvision} with a range of 60 feet, and you gain a swimming speed of 30 feet."
]
},
{
"name": "Hematite",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 1000,
"entries": [
"An opaque gray-black gemstone."
]
},
{
"name": "Hempen Rope (50 feet)",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 10,
"value": 100,
"entries": [
"Rope, whether made of hemp or silk, has 2 hit points and can be burst with a DC 17 Strength check."
]
},
{
"name": "Herbalism Kit",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"additionalSources": [
{
"source": "XGE",
"page": 82
}
],
"type": "T",
"rarity": "none",
"weight": 3,
"value": 500,
"entries": [
"This kit contains a variety of instruments such as clippers, mortar and pestle, and pouches and vials used by herbalists to create remedies and potions. Proficiency with this kit lets you add your proficiency bonus to any ability checks you make to identify or apply herbs. Also, proficiency with this kit is required to create antitoxin and potions of healing."
],
"additionalEntries": [
"Proficiency with an herbalism kit allows you to identify plants and safely collect their useful elements.",
{
"type": "entries",
"name": "Components",
"entries": [
"An herbalism kit includes pouches to store herbs, clippers and leather gloves for collecting plants, a mortar and pestle, and several glass jars."
]
},
{
"type": "entries",
"name": "Arcana",
"entries": [
"Your knowledge of the nature and uses of herbs can add insight to your magical studies that deal with plants and your attempts to identify potions."
]
},
{
"type": "entries",
"name": "Investigation",
"entries": [
"When you inspect an area overgrown with plants, your proficiency can help you pick out details and clues that others might miss."
]
},
{
"type": "entries",
"name": "Medicine",
"entries": [
"Your mastery of herbalism improves your ability to treat illnesses and wounds by augmenting your methods of care with medicinal plants."
]
},
{
"type": "entries",
"name": "Nature and Survival",
"entries": [
"When you travel in the wild, your skill in herbalism makes it easier to identify plants and spot sources of food that others might overlook."
]
},
{
"type": "entries",
"name": "Identify Plants",
"entries": [
"You can identify most plants with a quick inspection of their appearance and smell."
]
},
{
"type": "table",
"caption": "Herbalism Kit",
"colLabels": [
"Activity",
"DC"
],
"colStyles": [
"col-10",
"col-2 text-center"
],
"rows": [
[
"Find plants",
"15"
],
[
"Identify poison",
"20"
]
]
}
]
},
{
"name": "Hew",
"source": "LMoP",
"page": 33,
"baseItem": "battleaxe|phb",
"type": "M",
"rarity": "unknown (magic)",
"weight": 4,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"bonusWeapon": "+1",
"entries": [
"This +1 battleaxe deals maximum damage when the wielder hits a plant creature or an object made of wood. The axe's creator was a dwarf smith who feuded with the dryads of a forest where he cut firewood. Whoever carries the axe feels uneasy whenever he or she travels through a forest."
]
},
{
"name": "Heward's Handy Haversack",
"source": "DMG",
"page": 174,
"srd": "Handy Haversack",
"tier": "minor",
"rarity": "rare",
"wondrous": true,
"weight": 5,
"entries": [
"This backpack has a central pouch and two side pouches, each of which is an extradimensional space. Each side pouch can hold up to 20 pounds of material, not exceeding a volume of 2 cubic feet. The large central pouch can hold up to 8 cubic feet or 80 pounds of material. The backpack always weighs 5 pounds, regardless of its contents.",
"Placing an object in the haversack follows the normal rules for interacting with objects. Retrieving an item from the haversack requires you to use an action. When you reach into the haversack for a specific item, the item is always magically on top.",
"The haversack has a few limitations. If it is overloaded, or if a sharp object pierces it or tears it, the haversack ruptures and is destroyed. If the haversack is destroyed, its contents are lost forever, although an artifact always turns up again somewhere. If the haversack is turned inside out, its contents spill forth, unharmed, and the haversack must be put right before it can be used again. If a breathing creature is placed within the haversack, the creature can survive for up to 10 minutes, after which time it begins to suffocate.",
"Placing the haversack inside an extradimensional space created by a {@item bag of holding}, {@item portable hole}, or similar item instantly destroys both items and opens a gate to the Astral Plane. The gate originates where the one item was placed inside the other. Any creature within 10-feet of the gate is sucked through it and deposited in a random location on the Astral Plane. The gate then closes. The gate is one-way only and can't be reopened."
],
"containerCapacity": {
"weight": [
20,
20,
80
],
"weightless": true
},
"lootTables": [
"Magic Item Table C"
],
"hasFluffImages": true
},
{
"name": "Heward's Handy Spice Pouch",
"source": "XGE",
"page": 137,
"tier": "minor",
"rarity": "common",
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 4}",
"charges": 10,
"entries": [
"This belt pouch appears empty and has 10 charges. While holding the pouch, you can use an action to expend 1 of its charges, speak the name of any nonmagical food seasoning (such as salt, pepper, saffron, or cilantro), and remove a pinch of the desired seasoning from the pouch. A pinch is enough to season a single meal. The pouch regains {@dice 1d6 + 4} expended charges daily at dawn."
]
},
{
"name": "Heward's Hireling Armor",
"source": "LLK",
"page": 55,
"baseItem": "leather armor|phb",
"type": "LA",
"rarity": "very rare",
"reqAttune": true,
"weight": 10,
"ac": 11,
"bonusAc": "+1",
"entries": [
"A number of Kwalish's experiments were attempts to research the works of the legendary mage Heward, who first crafted what he named hireling armor. While wearing this armor, you gain a +1 bonus to AC. In addition, the armor's animated straps can assist with the drawing and sheathing of weapons, such that you can draw or stow two one-handed weapons when you would normally be able to draw or stow only one.",
"This armor also has six pockets, each of which is an extradimensional space. Each pocket can hold up to 20 pounds of material, not exceeding a volume of 2 cubic feet. The armor always weighs 10 pounds, regardless of its pockets' contents. Placing an object into one of the armor's pockets follows the normal rules for interacting with objects. Retrieving an item from a pocket of the armor requires you to use an action. When you reach into a pocket for a specific item, the item is always magically on top.",
"Placing the armor inside an extradimensional space created by a {@item bag of holding}, a {@item Heward's handy haversack}, or a similar item instantly destroys both items and opens a gate to the Astral Plane. The gate originates where the one item was placed inside the other. Any creature within 10 feet of the gate is sucked through it and deposited in a random location on the Astral Plane. The gate then closes. The gate is one-way only and can't be reopened."
],
"containerCapacity": {
"weight": [
20,
20,
20,
20,
20,
20
],
"weightless": true
}
},
{
"name": "Hide of the Feral Guardian (Awakened)",
"source": "EGW",
"_copy": {
"name": "Hide of the Feral Guardian (Dormant)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Awakened",
"entries": [
"When the armor reaches an awakened state, it gains the following properties:",
{
"type": "list",
"items": [
"The AC bonus of the armor increases to +2.",
"While you are transformed by an effect that replaces any of your game statistics with those of another creature, your bonus to melee attack and damage rolls increases by 1 (to +2).",
"When you cast the {@spell polymorph} spell using this armor, you can transform into a {@creature cave bear}."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"bonusWeapon": "+2",
"bonusAc": "+2"
},
{
"name": "Hide of the Feral Guardian (Dormant)",
"source": "EGW",
"page": 271,
"baseItem": "studded leather armor|PHB",
"type": "LA",
"rarity": "legendary",
"reqAttune": true,
"weight": 13,
"ac": 12,
"bonusWeapon": "+1",
"bonusAc": "+1",
"entries": [
"It is believed that this polished and beautifully detailed leather armor was a gift from Melora, bestowed on a long-forgotten archdruid and champion of the natural world before the terrors of the Calamity.",
{
"type": "entries",
"name": "Dormant",
"entries": [
"While wearing the armor in its dormant state, you gain the following benefits:",
{
"type": "list",
"items": [
"The armor grants you a +1 bonus to AC.",
"While you are transformed by an effect that replaces any of your game statistics with those of another creature, you have a +1 bonus to melee attack and damage rolls, and you retain the benefits of this armor.",
"As an action, you can use the armor to cast {@spell polymorph} on yourself, transforming into a {@creature giant owl} while retaining your Intelligence, Wisdom, and Charisma scores. This property can't be used again until the next dawn."
]
}
]
}
],
"attachedSpells": [
"polymorph"
]
},
{
"name": "Hide of the Feral Guardian (Exalted)",
"source": "EGW",
"_copy": {
"name": "Hide of the Feral Guardian (Awakened)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Exalted",
"entries": [
"When the armor reaches an exalted state, it gains the following properties:",
{
"type": "list",
"items": [
"The AC bonus of the armor increases to +3.",
"While you are transformed by an effect that replaces any of your game statistics with those of another creature, your bonus to melee attack and damage rolls increases by 1 (to +3).",
"When you cast the {@spell polymorph} spell using this armor, you can transform into a {@creature guardian wolf|EGW}."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"bonusWeapon": "+3",
"bonusAc": "+3"
},
{
"name": "Holy Symbol of Ravenkind",
"source": "CoS",
"page": 222,
"rarity": "legendary",
"reqAttune": "by a cleric or paladin of good alignment",
"reqAttuneTags": [
{
"class": "cleric",
"alignment": [
"G"
]
},
{
"class": "paladin",
"alignment": [
"G"
]
}
],
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 4}",
"charges": 10,
"entries": [
"The Holy Symbol of Ravenkind is a unique holy symbol sacred to the good-hearted faithful of Barovia. It predates the establishment of any church in Barovia. According to legend, it was delivered to a paladin named Lugdana by a giant raven—or an angel in the form of a giant raven. Lugdana used the holy symbol to root out and destroy nests of vampires until her death. The high priests of Ravenloft kept and wore the holy symbol after Lugdana's passing.",
"The holy symbol is a platinum amulet shaped like the sun, with a large crystal embedded in its center.",
"The holy symbol has 10 charges for the following properties. It regains {@dice 1d6 + 4} charges daily at dawn.",
{
"name": "Hold Vampires",
"type": "entries",
"entries": [
"As an action, you can expend 1 charge and present the holy symbol to make it flare with holy power. Vampires and {@creature vampire spawn} within 30 feet of the holy symbol when it flares must make a DC 15 Wisdom saving throw. On a failed save, a target is {@condition paralyzed} for 1 minute. It can repeat the saving throw at the end of its turns to end the effect on itself."
]
},
{
"name": "Turn Undead",
"type": "entries",
"entries": [
"If you have the Turn Undead or the Turn the Unholy feature, you can expend 3 charges when you present the holy symbol while using that feature. When you do so, undead have disadvantage on their saving throws against the effect."
]
},
{
"name": "Sunlight",
"type": "entries",
"entries": [
"As an action, you can expend 5 charges while presenting the holy symbol to make it shed bright light in a 30-foot radius and dim light for an additional 30 feet. The light is sunlight and lasts for 10 minutes or until you end the effect (no action required)."
]
}
],
"hasFluffImages": true
},
{
"name": "Holy Water (flask)",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 1,
"value": 2500,
"entries": [
"As an action, you can splash the contents of this flask onto a creature within 5 feet of you or throw it up to 20 feet, shattering it on impact. In either case, make a ranged attack against a target creature, treating the holy water as an improvised weapon. If the target is a fiend or undead, it takes {@damage 2d6} radiant damage.",
"A cleric or paladin may create holy water by performing a special ritual. The ritual takes 1 hour to perform, uses 25 gp worth of powdered silver, and requires the caster to expend a 1st-level spell slot."
]
},
{
"name": "Hooded Lantern",
"source": "PHB",
"page": 152,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 2,
"value": 500,
"entries": [
"A hooded lantern casts bright light in a 30-foot radius and dim light for an additional 30 feet. Once lit, it burns for 6 hours on a flask (1 pint) of oil. As an action, you can lower the hood, reducing the light to dim light in a 5-foot radius."
]
},
{
"name": "Hook of Fisher's Delight",
"source": "IDRotF",
"page": 314,
"rarity": "rare",
"wondrous": true,
"entries": [
"This tiny silver fishhook has a little gold feather attached to it. For it to function, the feathered hook must be tied to the end of a fishing line and immersed in enough water to fill at least a 10-foot cube. At the end of each uninterrupted hour of immersion, roll a {@dice d6}. On a 6, a floppy, 6-inch-long magical fish appears on the end of the hook. The color and properties of the conjured fish are determined by rolling on the Hook of Fisher's Delight table. Once the hook conjures a fish, it can't do so again until the next dawn.",
{
"type": "table",
"caption": "Hook of Fisher's Delight",
"colStyles": [
"col-2 text-center",
"col-1",
"col-9"
],
"colLabels": [
"d20",
"Fish Color",
"Result"
],
"rows": [
[
"1-10",
"Green with copper bands",
"This tasty fish provides a day's worth of nourishment to one creature that eats it. The fish loses this property and rots if it's not eaten within 24 hours of being caught."
],
[
"11-14",
"Yellow with black stripes",
"Once removed from the hook, this awful-tasting fish can be thrown up to 120 feet, targeting a creature the thrower can see. The target must succeed on a DC 15 Strength saving throw or be knocked {@condition prone}. The fish then disappears."
],
[
"15-18",
"Blue with white bands",
"When released from the hook, this fish squirms free, sprouts wings, follows you around, and sings a beautiful tune in Aquan. It disappears after {@dice 2d4} hours or when reduced to 0 hit points. The fish uses the {@creature quipper} stat block, except that it can breathe air and has a flying speed of 30 feet."
],
[
"19-20",
"Gold with silver stripes",
"This tasty fish provides a day's worth of nourishment to one creature that eats it and grants {@dice 2d10} temporary hit points to that creature. The fish loses these properties and rots if it's not eaten within 24 hours of being caught."
]
]
}
],
"miscTags": [
"CF/W"
]
},
{
"name": "Horn",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"type": "INS",
"rarity": "none",
"weight": 2,
"value": 300
},
{
"name": "Horn of Blasting",
"source": "DMG",
"page": 174,
"srd": true,
"tier": "major",
"rarity": "rare",
"wondrous": true,
"weight": 2,
"entries": [
"You can use an action to speak the horn's command word and then blow the horn, which emits a thunderous blast in a 30-foot cone that is audible 600 feet away. Each creature in the cone must make a DC 15 Constitution saving throw. On a failed save, a creature takes {@damage 5d6} thunder damage and is {@condition deafened} for 1 minute. On a successful save, a creature takes half as much damage and isn't {@condition deafened}. Creatures and objects made of glass or crystal have disadvantage on the saving throw and take {@damage 10d6} thunder damage instead of {@dice 5d6}.",
"Each use of the horn's magic has a {@chance 20} chance of causing the horn to explode. The explosion deals {@damage 10d6} fire damage to the blower and destroys the horn."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Horn of Silent Alarm",
"source": "XGE",
"page": 137,
"tier": "minor",
"rarity": "common",
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d4}",
"charges": 4,
"entries": [
"This horn has 4 charges. When you use an action to blow it, one creature of your choice can hear the horns blare, provided the creature is within 600 feet of the horn and not {@condition deafened}. No other creature hears sound coming from the horn. The horn regains {@dice 1d4} expended charges daily at dawn."
]
},
{
"name": "Horn of the Endless Maze",
"source": "WDMM",
"page": 163,
"_copy": {
"name": "Horn of Valhalla, Brass",
"source": "DMG"
},
"entries": [
"You can use an action to blow this horn. In response, warrior spirits from the Abyss appear within 60 feet of you. These spirits look like minotaurs, and use the {@creature berserker} statistics. They return to Abyss after 1 hour or when they drop to 0 hit points. Once you use the horn, it can't be used again until 7 days have passed.",
"The horn summons {@dice 3d4 + 3} {@creature berserker||berserkers}. To use the horn, you must be proficient with all simple weapons.",
"If you blow the horn without meeting its requirement, the summoned {@creature berserker||berserkers} attack you. If you meet the requirement, they are friendly to you and your companions and follow your commands."
]
},
{
"name": "Horn of Valhalla, Brass",
"source": "DMG",
"page": 175,
"srd": true,
"tier": "major",
"rarity": "rare",
"wondrous": true,
"weight": 2,
"entries": [
"You can use an action to blow this horn. In response, warrior spirits from the plane of Ysgard appear within 60 feet of you. These spirits use the {@creature berserker} statistics. They return to Ysgard after 1 hour or when they drop to 0 hit points. Once you use the horn, it can't be used again until 7 days have passed.",
"A brass horn summons {@dice 3d4 + 3} {@creature berserker||berserkers}. To use the brass horn, you must be proficient with all simple weapons.",
"If you blow the horn without meeting its requirement, the summoned {@creature berserker||berserkers} attack you. If you meet the requirement, they are friendly to you and your companions and follow your commands."
],
"lootTables": [
"Magic Item Table G"
]
},
{
"name": "Horn of Valhalla, Bronze",
"source": "DMG",
"page": 175,
"srd": true,
"tier": "major",
"rarity": "very rare",
"wondrous": true,
"weight": 2,
"entries": [
"You can use an action to blow this horn. In response, warrior spirits from the plane of Ysgard appear within 60 feet of you. These spirits use the {@creature berserker} statistics. They return to Ysgard after 1 hour or when they drop to 0 hit points. Once you use the horn, it can't be used again until 7 days have passed.",
"A bronze horn summons {@dice 4d4 + 4} {@creature berserker||berserkers}. To use the bronze horn, you must be proficient with medium armor.",
"If you blow the horn without meeting its requirement, the summoned {@creature berserker||berserkers} attack you. If you meet the requirement, they are friendly to you and your companions and follow your commands."
],
"lootTables": [
"Magic Item Table H"
]
},
{
"name": "Horn of Valhalla, Iron",
"source": "DMG",
"page": 175,
"srd": true,
"tier": "major",
"rarity": "legendary",
"wondrous": true,
"weight": 2,
"entries": [
"You can use an action to blow this horn. In response, warrior spirits from the plane of Ysgard appear within 60 feet of you. These spirits use the {@creature berserker} statistics. They return to Ysgard after 1 hour or when they drop to 0 hit points. Once you use the horn, it can't be used again until 7 days have passed.",
"The iron horn summons {@dice 5d4 + 5} {@creature berserker||berserkers}. To use the iron horn, you must be proficient with all martial weapons.",
"If you blow the horn without meeting its requirement, the summoned {@creature berserker||berserkers} attack you. If you meet the requirement, they are friendly to you and your companions and follow your commands."
],
"lootTables": [
"Magic Item Table I"
]
},
{
"name": "Horn of Valhalla, Silver",
"source": "DMG",
"page": 175,
"srd": true,
"tier": "major",
"rarity": "rare",
"wondrous": true,
"weight": 2,
"entries": [
"You can use an action to blow this horn. In response, warrior spirits from the plane of Ysgard appear within 60 feet of you. These spirits use the {@creature berserker} statistics. They return to Ysgard after 1 hour or when they drop to 0 hit points. Once you use the horn, it can't be used again until 7 days have passed.",
"The silver horn summons {@dice 2d4 + 2} {@creature berserker||berserkers}.",
"The {@creature berserker||berserkers} are friendly to you and your companions and follow your commands."
],
"lootTables": [
"Magic Item Table G"
]
},
{
"name": "Horned Ring",
"source": "WDMM",
"page": 108,
"type": "RG",
"rarity": "very rare",
"reqAttune": true,
"entries": [
"Allows an attuned wearer to ignore Undermountain's magical restrictions (see \"Alterations to Magic\")."
]
},
{
"name": "Horror Trinket",
"source": "VRGR",
"page": 36,
"type": "G",
"rarity": "none",
"entries": [
"Before you finish making a character, roll once on the Horror Trinkets table for a unique object your character has with them at the start of their adventuring career. These trinkets hint toward various horrific tales and might lead to dreadful revelations or be nothing more than grim keepsakes. At the DM's discretion any of these trinkets might also be a Mist talisman (detailed in {@book chapter 3|VRGR|3|Mist Talismans}), a focal item that can lead the bearer through the Mists to a particular Domain of Dread.",
{
"type": "table",
"caption": "Horror Trinkets",
"colLabels": [
"d100",
"Trinket"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"01",
"A picture you drew as a child of your imaginary friend"
],
[
"02",
"A lock that opens when blood is dripped in its keyhole"
],
[
"03",
"Clothes stolen from a scarecrow"
],
[
"04",
"A spinning top carved with four faces: happy, sad, wrathful, and dead"
],
[
"05",
"The necklace of a sibling who died on the day you were born"
],
[
"06",
"A wig from someone executed by beheading"
],
[
"07",
"The unopened letter to you from your dying father"
],
[
"08",
"A pocket watch that runs backward for an hour every midnight"
],
[
"09",
"A winter coat stolen from a dying soldier"
],
[
"10",
"A bottle of invisible ink that can only be read at sunset"
],
[
"11",
"A wineskin that refills when interred with a dead person for a night"
],
[
"12",
"A set of silverware used by a king for his last meal"
],
[
"13",
"A spyglass that always shows the world suffering a terrible storm"
],
[
"14",
"A cameo with the profile's face scratched away"
],
[
"15",
"A lantern with a black candle that never runs out and that burns with green flame"
],
[
"16",
"A teacup from a child's tea set, stained with blood"
],
[
"17",
"A little black book that records your dreams, and yours alone, when you sleep"
],
[
"18",
"A necklace formed of the interlinked holy symbols of a dozen deities"
],
[
"19",
"A noose that feels heavier than it should"
],
[
"20",
"A birdcage into which small birds fly but once inside never eat or leave"
],
[
"21",
"A lepidopterist's box filled with dead moths with skull-like patterns on their wings"
],
[
"22",
"A jar of pickled ghouls' tongues"
],
[
"23",
"The wooden hand of a notorious pirate"
],
[
"24",
"A urn with the ashes of a dead relative"
],
[
"25",
"A hand mirror backed with a bronze depiction of a medusa"
],
[
"26",
"Pallid leather gloves crafted with ivory fingernails"
],
[
"27",
"Dice made from the knuckles of a notorious charlatan"
],
[
"28",
"A ring of keys for forgotten locks"
],
[
"29",
"Nails from the coffin of a murderer"
],
[
"30",
"A key to the family crypt"
],
[
"31",
"A bouquet of funerary flowers that always looks and smells fresh"
],
[
"32",
"A switch used to discipline you as a child"
],
[
"33",
"A music box that plays by itself whenever someone holding it dances"
],
[
"34",
"A walking cane with an iron ferrule that strikes sparks on stone"
],
[
"35",
"A flag from a ship lost at sea"
],
[
"36",
"A porcelain doll's head that always seems to be looking at you"
],
[
"37",
"A wolf's head wrought in silver that is also a whistle"
],
[
"38",
"A small mirror that shows a much older version of the viewer"
],
[
"39",
"A small, worn book of children's nursery rhymes"
],
[
"40",
"A mummified raven claw"
],
[
"41",
"A broken pendant of a silver dragon that's always cold to the touch"
],
[
"42",
"A small locked box that quietly hums a lovely melody at night, but you always forget it in the morning"
],
[
"43",
"An inkwell that makes one a little nauseous when staring at it"
],
[
"44",
"An old doll made from a dark, dense wood and missing a hand and a foot"
],
[
"45",
"A black executioner's hood"
],
[
"46",
"A pouch made of flesh, with a sinew drawstring"
],
[
"47",
"A tiny spool of black thread that never runs out"
],
[
"48",
"A tiny clockwork figurine of a dancer that's missing a gear and doesn't work"
],
[
"49",
"A black wooden pipe that creates puffs of smoke that look like skulls"
],
[
"50",
"A vial of perfume, the scent of which only certain creatures can detect"
],
[
"51",
"A stone that emits a single endless sigh"
],
[
"52",
"A rag doll with two red dots on its neck"
],
[
"53",
"A spring-loaded toy with a missing crank"
],
[
"54",
"A mason jar containing a harmless but agitated, animate ooze"
],
[
"55",
"A black wooden die with 1's on all the faces"
],
[
"56",
"A child's portrait with \"born\" written on the back, along with next year's date"
],
[
"57",
"A dagger-sized shark tooth"
],
[
"58",
"A finger that's taken root in a small pot"
],
[
"59",
"A toolbox containing the remains of a dangerous but broken clockwork arachnid"
],
[
"60",
"A pitcher-sized, opalescent snail shell that occasionally, inexplicably shudders or tips over"
],
[
"61",
"The logbook of an ice-breaking ship called The Haifisch"
],
[
"62",
"A small portrait of you as a child, alongside your identically dressed twin"
],
[
"63",
"A silver pocket watch with thirteen hours marked on the face"
],
[
"64",
"A woodcut of a wolf devouring its own hind leg"
],
[
"65",
"A planchette etched with raven skulls"
],
[
"66",
"A moist coral figurine of a lamprey with arms, legs, and a bipedal stance"
],
[
"67",
"A bronze fingertrap sculpted with roaring tigers"
],
[
"68",
"A pearl necklace that turns red under the full moon"
],
[
"69",
"A fossil of a fish with humanoid features"
],
[
"70",
"A plague doctor's mask"
],
[
"71",
"A paper talisman with smudged ink"
],
[
"72",
"A locket containing the smeared image of an eyeless figure"
],
[
"73",
"A canopic jar with a lid sculpted like a goat"
],
[
"74",
"A jack-o'-lantern made from a small, pale gourd"
],
[
"75",
"A single high-heeled, iron shoe"
],
[
"76",
"A candle made from a severed hand"
],
[
"77",
"A clockwork device that beats like a heart"
],
[
"78",
"A blank masquerade mask"
],
[
"79",
"A glass eye with a live worm inside"
],
[
"80",
"A sheet with two eyeholes cut in it"
],
[
"81",
"The deed to someplace called Tergeron Manor"
],
[
"82",
"An ornate, wax-sealed crimson envelope that resists all attempts to open it"
],
[
"83",
"A mourning veil trimmed in black lace"
],
[
"84",
"A straitjacket covered in charcoal runes"
],
[
"85",
"A tattered, burlap mask with a crooked smile painted on it"
],
[
"86",
"A green ribbon designed to be worn as a choker"
],
[
"87",
"Dentures with mismatched, sharpened teeth"
],
[
"88",
"A warm, fist-sized egg case"
],
[
"89",
"A copper ring with \"mine\" engraved on the inside"
],
[
"90",
"A glass ampoule containing a neon green fluid"
],
[
"91",
"An eye patch embroidered with a holy symbol"
],
[
"92",
"A severed big toe with a nail that continues to grow"
],
[
"93",
"A journal that has been heavily redacted"
],
[
"94",
"A glove with a mouth-like design stitched on the palm"
],
[
"95",
"An ornate but empty reliquary made of silver and fractured glass"
],
[
"96",
"A ceramic figure of a cat with too many eyes"
],
[
"97",
"A crumpled paper ticket bearing the words \"admit none\""
],
[
"98",
"An electrum coin with your face on one side"
],
[
"99",
"A shrunken gremishka head that twitches when anyone casts magic nearby"
],
[
"100",
"A sunburst amulet with a red stone at the center"
]
]
}
]
},
{
"name": "Horseshoes of a Zephyr",
"source": "DMG",
"page": 175,
"srd": true,
"tier": "minor",
"rarity": "very rare",
"wondrous": true,
"entries": [
"These iron horseshoes come in a set of four. While all four shoes are affixed to the hooves of a horse or similar creature, they allow the creature to move normally while floating 4 inches above the ground. This effect means the creature can cross or stand above nonsolid or unstable surfaces, such as water or lava. The creature leaves no tracks and ignores {@quickref difficult terrain||3}. In addition, the creature can move at normal speed for up to 12 hours a day without suffering {@condition exhaustion} from a forced march."
],
"lootTables": [
"Magic Item Table D"
]
},
{
"name": "Horseshoes of Speed",
"source": "DMG",
"page": 175,
"srd": true,
"tier": "minor",
"rarity": "rare",
"wondrous": true,
"entries": [
"These iron horseshoes come in a set of four. While all four shoes are affixed to the hooves of a horse or similar creature, they increase the creature's walking speed by 30 feet."
],
"lootTables": [
"Magic Item Table C"
]
},
{
"name": "Hourglass",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 1,
"value": 2500
},
{
"name": "Hunk of Cheese",
"source": "PHB",
"page": 158,
"srd": true,
"basicRules": true,
"type": "FD",
"rarity": "none",
"value": 10
},
{
"name": "Hunter's Coat",
"source": "EGW",
"page": 267,
"baseItem": "leather armor|PHB",
"type": "LA",
"rarity": "very rare",
"reqAttune": true,
"weight": 10,
"ac": 11,
"bonusAc": "+1",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"You have a +1 bonus to AC while wearing this armor.",
"The coat has 3 charges. When you hit a creature with an attack and that creature doesn't have all its hit points, you can expend 1 charge to deal an extra {@damage 1d10} necrotic damage to the target. The coat regains {@dice 1d3} expended charges daily at dawn.",
"The breastplate and shoulder protectors of this armor are made of leather that has been stiffened by being boiled in oil. The rest of the armor is made of softer and more flexible materials."
]
},
{
"name": "Hunting Trap",
"source": "PHB",
"page": 152,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 25,
"value": 500,
"entries": [
"When you use your action to set it, this trap forms a saw-toothed steel ring that snaps shut when a creature steps on a pressure plate in the center. The trap is affixed by a heavy chain to an immobile object, such as a tree or a spike driven into the ground. A creature that steps on the plate must succeed on a DC 13 Dexterity saving throw or take {@damage 1d4} piercing damage and stop moving. Thereafter, until the creature breaks free of the trap, its movement is limited by the length of the chain (typically 3 feet long). A creature can use its action to make a DC 13 Strength check, freeing itself or another creature within its reach on a success. Each failed check deals 1 piercing damage to the trapped creature."
]
},
{
"name": "Icewind Dale Trinket",
"source": "IDRotF",
"page": 263,
"type": "G",
"rarity": "none",
"entries": [
{
"type": "table",
"caption": "Icewind Dale Trinkets",
"colLabels": [
"d100",
"Trinket"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"01–04",
"A small wooden figurine of a yawning walrus, painted in red and black"
],
[
"05–08",
"A pair of scrimshaw cufflinks with an image of a fisherman on a boat engraved on them"
],
[
"09–12",
"A small iron key with a frayed blue and gold cord tied to it"
],
[
"13–16",
"A small illustrated book of Northlander myths that has pages missing"
],
[
"17–20",
"A damaged scrimshaw cameo depicting a merfolk"
],
[
"21–24",
"A stone from a burial cairn with a tiny Dwarvish rune carved into it"
],
[
"25–28",
"A ripped cloth sail with a symbol you don't recognize"
],
[
"29–32",
"An Ulu knife with a scrimshaw handle"
],
[
"33–36",
"A jar containing an unidentifiable, sweet, sticky substance"
],
[
"37–40",
"A delicate glass ball painted with snowflakes, capped by a metal loop with a tiny hook attached to it"
],
[
"41–44",
"An expedition log with missing pages and a pressed flower used as a bookmark"
],
[
"45–48",
"An owl figurine carved from whalebone"
],
[
"49–52",
"A sewing box that smells of old wood and has three spools of blue thread inside"
],
[
"53–56",
"A scrimshaw-handled ink pen with black runic designs along its length"
],
[
"57–60",
"A brooch made from a small insect encased in amber"
],
[
"61–64",
"A scrimshaw pepper shaker etched with the letter W"
],
[
"65–68",
"An old, wooden-handled ice pick stained with blood that won't wash off"
],
[
"69–72",
"A fabric doll bearing an angry expression"
],
[
"73–76",
"A set of wind chimes made from seashells"
],
[
"77–80",
"A beautiful silver tin that, when opened, emits the smell of rotting fish"
],
[
"81–84",
"A bloodstained dreamcatcher made from fishing line, gold wire, and snowy owlbear feathers"
],
[
"85–88",
"A figurine of a polar bear made of ice that never melts"
],
[
"89–92",
"A snow globe that doesn't need to be shaken"
],
[
"93–96",
"A piece of sea glass shaped like a unicorn's horn"
],
[
"97–00",
"A dark blue scarf that gets lighter in shade the higher the altitude of the wearer"
]
]
}
],
"hasFluffImages": true
},
{
"name": "Icon of Ravenloft",
"source": "CoS",
"page": 222,
"rarity": "legendary",
"reqAttune": "by a creature of good alignment",
"reqAttuneTags": [
{
"alignment": [
"G"
]
}
],
"wondrous": true,
"weight": 10,
"entries": [
"The Icon of Ravenloft is a 12-inch tall statuette made of the purest silver, weighing 10 pounds. It depicts a cleric kneeling in supplication.",
"The icon was given to Strahd by the archpriest Ciril Romulich, an old family friend, to consecrate the castle and its chapel.",
"While within 30 feet of the icon, a creature is under the effect of a {@spell protection from evil and good} spell against fiends and undead. Only a creature attuned to the icon can use its other properties.",
{
"name": "Augury",
"type": "entries",
"entries": [
"You can use an action to cast an {@spell augury} spell from the icon, with no material components required. Once used, this property can't be used again until the next dawn."
]
},
{
"name": "Bane of the Undead",
"type": "entries",
"entries": [
"You can use the icon as a holy symbol while using the Turn Undead or Turn the Unholy feature. If you do so, increase the save DC by 2."
]
},
{
"name": "Cure Wounds",
"type": "entries",
"entries": [
"While holding the icon, you can take an action to heal one creature that you can see within 30 feet of you. The target regains {@dice 3d8 + 3} hit points, unless it is an undead, a construct, or a fiend. Once used, this property can't be used again until the next dawn."
]
}
],
"attachedSpells": [
"protection from evil and good",
"augury"
],
"hasFluffImages": true
},
{
"name": "Iggwilv's Cauldron",
"source": "WBtW",
"page": 209,
"rarity": "artifact",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"wondrous": true,
"weight": 80,
"charges": 3,
"entries": [
"{@creature Iggwilv the Witch Queen|WBtW|Iggwilv} crafted this wondrous cauldron with the help of her adoptive mother, the archfey Baba Yaga. The cauldron has two forms. Only Iggwilv or Baba Yaga can change the cauldron from one form to another (by using an action to touch it), which either can do without being attuned to the item. In its first form, the cauldron is made of solid gold and embossed on the outside with images of bare-branched trees, falling leaves, and broomsticks. In its second form, the cauldron is made of iron and embossed on the outside with images of bats, toads, cats, lizards, and snakes—eight of each animal. In either form, the cauldron is roughly 3 feet in diameter and has a 2-foot-wide mouth, a round lid with a molded handle at the top, and eight clawed feet for stability. The cauldron weighs 80 pounds when empty, and it can hold up to 100 gallons of liquid.",
{
"type": "entries",
"name": "Attunement",
"entries": [
"Any Humanoid creature that attunes to the cauldron must succeed on a DC 15 Constitution saving throw or be aged to the point of decrepitude. In this state, the creature's speed is halved, the range of its vision and hearing is reduced to 30 feet, and it has disadvantage on all ability checks, attack rolls, and saving throws. The creature will reach the end of its natural life span in {@dice 3d8} days. Only a {@spell wish} spell or divine intervention can reverse this aging effect on the creature.",
"Three hags can attune to the cauldron simultaneously, provided they have formed a coven. If the coven disbands, the attunement ends for all three hags."
]
},
{
"type": "entries",
"name": "Random Properties",
"entries": [
"Regardless of the form it takes, the cauldron has the following random properties:",
{
"type": "list",
"items": [
"1 {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} property (determined by rolling on the {@table Artifact Properties; Minor Beneficial Properties|dmg|Minor Beneficial} Properties table in the Dungeon Master's Guide)",
"1 {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental} property (determined by rolling on the {@table Artifact Properties; Minor Detrimental Properties|dmg|Minor Detrimental} Properties table in the Dungeon Master's Guide)"
]
}
]
},
{
"type": "entries",
"name": "Gold Cauldron",
"entries": [
"The gold cauldron has the following properties:",
{
"type": "list",
"items": [
"If water is poured into the cauldron and stirred for 1 minute, it transforms into a hearty, hot stew that provides one nourishing meal for up to four people per gallon of water used. The stew remains hot while in the cauldron, then cools naturally after it is removed. The outside of the cauldron remains safe to touch despite the heat of the stew.",
"If wine is poured into the cauldron and stirred for 10 minutes, it transforms into a magical elixir that fortifies up to four people per gallon of wine used. Any creature that imbibes the elixir gains 10 temporary hit points. Any of the elixir that isn't consumed within 1 hour vanishes. Once used, this property of the cauldron can't be used again until the next dawn.",
"When the cauldron is filled with 90 gallons of water mixed with 10 gallons of wine, it can be used as the focus for a {@spell scrying} spell. When this spell is cast using the cauldron as its focus, the target of the spell fails its saving throw automatically, and the spell works even if its caster and the target are on different planes of existence. When the spell ends, all the liquid in the cauldron vanishes.",
"Dipping the bristles of an ordinary broom into the water-filled cauldron transforms the broom into a broom of flying for 3 days. Once used, this property of the cauldron can't be used again until the next dawn."
]
}
]
},
{
"type": "entries",
"name": "Iron Cauldron",
"entries": [
"The iron cauldron has the following properties:",
{
"type": "list",
"items": [
"You can use an action to scream into the empty cauldron, which magically summons a {@creature swarm of bats} that flies out of the cauldron and acts immediately after you in the initiative order. The swarm obeys your commands and disappears after 1 minute if it hasn't been destroyed by then. Once used, this property of the cauldron can't be used again until the next dawn.",
"If at least 1 gallon of blood is poured into the cauldron and stirred for 1 minute, it turns into a cloud of harmless black smoke that erupts from the cauldron and dissipates quickly. At the same time, all Humanoid creatures within a certain radius of the cauldron fall {@condition unconscious} for 1 hour, except those that are attuned to the cauldron or immune to being {@condition charmed}. The radius of the effect is 100 feet per gallon of blood used. An {@condition unconscious} creature awakens if it takes damage or if someone uses an action to shake or slap it. Once used, this property of the cauldron can't be used again until the next dawn.",
"Tying a dead frog or toad to the end of an ordinary branch and dipping it in the water-filled cauldron transforms the branch into a {@item wand of polymorph} with 3 charges. This wand can't recharge and crumbles to ashes when its final charge is expended. Once used, this property of the cauldron can't be used again for 8 days.",
"If you spend 1 minute touching the cauldron with a unicorn's horn while reciting the poem called \"The Witch Queen's Cauldron\" (see the accompanying sidebar), all creatures within 1,000 feet of the cauldron except those that are attuned to it become frozen in time. A time-frozen creature is {@condition incapacitated}, can't move or speak, doesn't age, and is unaware of its surroundings or the passage of time. Moreover, it can't be moved, harmed in any way, or affected by any other magic. All other conditions and effects on the creature are suppressed until it is no longer frozen in time. Destroying the cauldron, sending it to another plane of existence, or touching it with a unicorn's horn for 1 minute while reciting \"The Witch Queen's Cauldron\" ends the time-freezing effect on all creatures. The effect also ends on any creature that comes into contact with an antimagic field or is touched by a unicorn's horn."
]
}
]
},
{
"type": "entries",
"name": "Destroying the Cauldron",
"entries": [
"The cauldron is a Medium object with AC 19, 80 hit points, and immunity to damage from any source other than a flame tongue or frost brand weapon. Reducing the cauldron to 0 hit points with such a weapon shatters the cauldron into eight pieces of roughly equal size, whereupon all ongoing effects created by the cauldron end as it loses its magic.",
"The destruction of Iggwilv's Cauldron causes all hags in the multiverse to lose the Shared Spellcasting trait gained by being in a coven (see the \"Hag Covens\" sidebar in the Monster Manual)."
]
},
{
"type": "entries",
"name": "Reconstructing the Cauldron",
"entries": [
"If all eight pieces of the shattered cauldron are within 5 feet of one another, a {@spell wish} spell can reassemble them, restoring the cauldron and all its properties. The cauldron's reconstruction also restores the Shared Spellcasting trait of hag covens throughout the multiverse."
]
},
{
"type": "inset",
"name": "The Witch Queen's Cauldron",
"entries": [
"As explained in the description of Iggwilv's Cauldron, the poem titled \"The Witch Queen's Cauldron\" is an incantation that hints at the time-freezing property of the artifact:",
{
"type": "quote",
"entries": [
"Eight cats perch atop eight dead attending",
"Eight lizards flee from eight cats scavenging",
"Eight toads climbing meet eight dead and falling",
"Eight snakes sneak under eight bats screaming",
"Eight eyes open, always dreaming",
"All on the cauldron that is ever seeming"
]
}
]
}
],
"hasFluffImages": true
},
{
"name": "Illuminator's Tattoo",
"source": "TCE",
"page": 129,
"rarity": "common",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"entries": [
"Produced by a special needle, this magic tattoo features beautiful calligraphy, images of writing implements, and the like.",
{
"type": "entries",
"name": "Tattoo Attunement",
"entries": [
"To attune to this item, you hold the needle to your skin where you want the tattoo to appear, pressing the needle there throughout the attunement process. When the attunement is complete, the needle turns into the ink that becomes the tattoo, which appears on the skin.",
"If your attunement to the tattoo ends, the tattoo vanishes, and the needle reappears in your space."
]
},
{
"type": "entries",
"name": "Magical Scribing",
"entries": [
"While this tattoo is on your skin, you can write with your fingertip as if it were an ink pen that never runs out of ink.",
"As an action, you can touch a piece of writing up to one page in length and speak a creature's name. The writing becomes {@condition invisible} to everyone other than you and the named creature for the next 24 hours. Either of you can dismiss the invisibility by touching the script (no action required). Once used, this action can't be used again until the next dawn."
]
}
]
},
{
"name": "Illuminator's Tattoo",
"source": "UA2020SpellsAndMagicTattoos",
"page": 10,
"rarity": "common",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"entries": [
"This tattoo contains beautiful calligraphy, images of writing implements, and the like. While this tattoo is on your skin, you can write with your fingertip as if it were an ink pen that never runs out of ink.",
"As an action, you can touch a piece of writing up to one page in length and speak a creature's name. The writing becomes {@condition invisible} to everyone other than you and the named creature for the next 24 hours. Either of you can dismiss the invisibility by touching the script (no action required). Once used, this action can't be used again until the next dawn.",
{
"type": "entries",
"name": "Tattoo Attunement",
"entries": [
"To attune to this item, you hold the needle to your skin where you want the tattoo to appear, pressing the needle there throughout the attunement process. When the attunement is complete, the needle turns into the ink that becomes the tattoo, which appears on the skin. If you have multiple magic tattoos, they count as a single magic item with regard to the number of magic items you can attune to.",
"If your attunement to the tattoo ends, the tattoo vanishes, and the needle reappears in the closest unoccupied space to you."
]
}
]
},
{
"name": "Illusionist's Bracers",
"source": "GGR",
"page": 178,
"rarity": "very rare",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"wondrous": true,
"entries": [
"A powerful illusionist of House Dimir originally developed these bracers, which enabled her to create multiple minor illusions at once. The bracers' power, though, extends far beyond illusions.",
"While wearing the bracers, whenever you cast a cantrip, you can use a bonus action on the same turn to cast that cantrip a second time."
],
"hasFluffImages": true
},
{
"name": "Immovable Rod",
"source": "DMG",
"page": 175,
"srd": true,
"type": "RD",
"tier": "minor",
"rarity": "uncommon",
"weight": 2,
"entries": [
"This flat iron rod has a button on one end. You can use an action to press the button, which causes the rod to become magically fixed in place. Until you or another creature uses an action to push the button again, the rod doesn't move, even if it is defying gravity. The rod can hold up to 8,000 pounds of weight. More weight causes the rod to deactivate and fall. A creature can use an action to make a DC 30 Strength check, moving the fixed rod up to 10 feet on a success."
],
"lootTables": [
"Magic Item Table B"
],
"hasFluffImages": true
},
{
"name": "Infernal Puzzle Box",
"source": "BGDIA",
"page": 224,
"rarity": "uncommon",
"wondrous": true,
"entries": [
"An infernal puzzle box is a cube-shaped container 5 to 6 inches on a side, composed of airtight, interlocking parts made from materials found in the Nine Hells. Most of these boxes are made of infernal iron, though some are carved from bone or horn. Infernal puzzle boxes are used to safeguard diabolical contracts signed between devils and mortals, even after the terms of these contracts are fulfilled. An empty infernal puzzle box weighs 3 pounds regardless of the materials used to fashion it.",
"When an object small enough to fit inside an infernal puzzle box is placed in it, the container magically seals shut around the object, and no magic can force the box open. The sealed box becomes immune to all damage as well. Every infernal puzzle box is constructed with a unique means of opening it. The trick to solving the puzzle is always mundane, never magical. Once a creature figures out the trick or sequence of steps needed to open a particular infernal puzzle box, that creature can open the box as an action, allowing access to the box's contents.",
"A creature that spends 1 hour holding an infernal puzzle box while trying to open it can make a DC 30 Intelligence ({@skill Investigation}) check. If the check succeeds, the creature figures out the trick or sequence of steps needed to open the box. If the check fails by 5 or more, the creature must make a DC 18 Wisdom saving throw, taking 42 ({@damage 12d6}) psychic damage on a failed save, or half as much damage on a successful one."
]
},
{
"name": "Infernal Tack",
"source": "MTF",
"page": 167,
"otherSources": [
{
"source": "BGDIA",
"page": 224
},
{
"source": "MPMM",
"page": 190
}
],
"rarity": "legendary",
"reqAttune": "by a creature of evil alignment.",
"reqAttuneTags": [
{
"alignment": [
"E"
]
}
],
"wondrous": true,
"entries": [
"A rider binds a {@creature nightmare} to its service with infernal tack, which consists of a bridle, bit, reins, saddle, and stirrups, along with spurs that are worn by the rider. A nightmare equipped with infernal tack must serve whoever wears the spurs until the wearer dies or the tack is removed.",
"You can use an action to call a nightmare equipped with infernal tack by clashing the spurs together or scraping them through blood. The nightmare appears at the start of your next turn, within 20 feet of you. It acts as your ally and takes its turn on your initiative count. It remains for 1 day, until you or it dies, or until you dismiss it as an action. If the nightmare dies, it reforms in the Nine Hells within 24 hours, after which you can summon it again.",
"The tack doesn't conjure a nightmare; one must first be subdued so the tack can be placed on it. No nightmare accepts this forced servitude willingly, but some eventually form strong loyalties to their masters and become true partners in evil."
]
},
{
"name": "Infiltrator's Key (Awakened)",
"source": "EGW",
"_copy": {
"name": "Infiltrator's Key (Dormant)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Awakened",
"entries": [
"When the Infiltrator's Key reaches an awakened state, it gains the following properties:",
{
"type": "list",
"items": [
"While holding the key, you can use a bonus action to transform the key into a magic dagger or back into a key. While the key is in the form of a dagger, you gain a +1 bonus to attack and damage rolls made with it, and it returns to your hand immediately after it is used to make a ranged attack.",
"While holding the key, you can use an action to cast one of the following spells from it: {@spell alter self}, {@spell invisibility}, {@spell knock}, or {@spell pass without trace}. Once a spell has been cast using the key, it can't be used to cast that spell again until the next dawn."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"bonusWeapon": "+2",
"attachedSpells": [
"alter self",
"invisibility",
"knock",
"pass without trace"
],
"hasFluffImages": true
},
{
"name": "Infiltrator's Key (Dormant)",
"source": "EGW",
"page": 272,
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"grantsProficiency": true,
"bonusWeapon": "+1",
"entries": [
"This mithral skeleton key was forged using the blood of twelve master thieves executed for trying to steal magic items during the Age of Arcanum.",
{
"type": "entries",
"name": "Dormant",
"entries": [
"The Infiltrator's Key grants the following benefits in its dormant state:",
{
"type": "list",
"items": [
"The key can be used as {@item thieves' tools|phb} for the purpose of opening locks. When using the key, you are considered proficient in {@item thieves' tools|PHB} and you have advantage on ability checks made to open locks.",
"While holding the key, your steps are muffled, giving you advantage on Dexterity ({@skill Stealth}) checks made to move silently."
]
}
]
}
],
"hasFluffImages": true
},
{
"name": "Infiltrator's Key (Exalted)",
"source": "EGW",
"_copy": {
"name": "Infiltrator's Key (Awakened)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Exalted",
"entries": [
"When the Infiltrator's Key reaches an exalted state, it gains the following properties:",
{
"type": "list",
"items": [
"As a bonus action, you can touch the key to a floor, wall, or ceiling that is no more than 5 feet thick and cause a magical opening to appear in the surface. When you create the opening, you choose its length and width, up to 10 feet for each dimension. The opening lasts until the key passes through it to the other side, at which point it disappears (if a creature is in the opening when the doorway closes, the creature is safely shunted to the nearest unoccupied space). The key can't be used to create another opening until the next dawn.",
"While holding the key, you can use an action to cast one of the following spells from it: {@spell dimension door}, {@spell gaseous form}, or {@spell mislead}. Once a spell has been cast using the key, it can't be used to cast that spell again until the next dawn."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"bonusWeapon": "+3",
"attachedSpells": [
"alter self",
"invisibility",
"knock",
"pass without trace",
"dimension door",
"gaseous form",
"mislead"
],
"hasFluffImages": true
},
{
"name": "Ingot of the Skold Rune",
"source": "SKT",
"page": 234,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"bonusAc": "+1",
"entries": [
"This appears to be a simple ingot of iron ore, about a foot long and a few inches across. Inspection of its surface reveals the faint, silvery outline of the skold (shield) rune. The ingot has the following properties, which work only while it's on your person.",
{
"name": "Runic Shield",
"type": "entries",
"entries": [
"You have a +1 bonus to AC."
]
},
{
"name": "Shield Bond",
"type": "entries",
"entries": [
"As a bonus action, choose a creature that you can see within 30 feet of you, other than yourself. Until the end of your next turn, any damage the target takes is reduced to 1, but you take half the damage prevented in this way. The damage you take can't be reduced in any way. Once you use this property, you can't use it again until you finish a short or long rest."
]
},
{
"name": "Shield Ward",
"type": "entries",
"entries": [
"You can transfer the ingot's magic to a nonmagical item—a shield or a two-handed melee weapon-by tracing the skold rune there with your finger. The transfer takes 8 hours of work that requires the two items to be within 5 feet of each other. At the end, the ingot is destroyed, and the rune appears in silver on the chosen item, which gains a benefit based on its form:",
{
"type": "list",
"style": "list-hang-notitle",
"items": [
{
"type": "item",
"name": "Shield",
"entry": "The shield is now a rare magic item that requires attunement. Its magic gives you a +1 bonus to AC, and the first time after each of your long rests that damage reduces you to 0 hit points, you are instead reduced to 1 hit point. You must be wielding the shield to gain these benefits."
},
{
"type": "item",
"name": "Weapon",
"entry": "The weapon is now an uncommon magic weapon. It grants you a +1 bonus to AC while you're holding it."
}
]
}
]
}
]
},
{
"name": "Ink (1-ounce bottle)",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"value": 1000,
"entries": [
"Ink (1-ounce bottle)"
]
},
{
"name": "Ink Pen",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"value": 2
},
{
"name": "Insect Repellent (block of incense)",
"source": "ToA",
"page": 32,
"type": "G",
"rarity": "none",
"value": 10,
"charges": 1,
"entries": [
"Insect repellent can be purchased in Port Nyanzaru in two forms: blocks of incense or a greasy salve. The merchant prince Kwayothe controls the sale of both. Neither kind of repellent protects against giant insects or the vile swarms of insects described in appendix A of the Monster Manual.",
"When lit, it burns for 8 hours and repels normal insects within a 20-foot-radius centered on it."
]
},
{
"name": "Insect Repellent (greasy salve)",
"source": "ToA",
"page": 32,
"type": "G",
"rarity": "none",
"value": 100,
"charges": 20,
"entries": [
"Insect repellent can be purchased in Port Nyanzaru in two forms: blocks of incense or a greasy salve. The merchant prince Kwayothe controls the sale of both. Neither kind of repellent protects against giant insects or the vile swarms of insects described in appendix A of the Monster Manual.",
"A gourd or vial of salve contains 20 applications of odorless grease. One application protects its wearer against normal insects for 24 hours. The salve is waterproof so it doesn't wash off in rain."
]
},
{
"name": "Insignia of Claws",
"source": "HotDQ",
"page": 94,
"otherSources": [
{
"source": "ToD",
"page": 178
}
],
"rarity": "uncommon",
"wondrous": true,
"bonusWeapon": "+1",
"entries": [
"The jewels in the insignia of the Cult of the Dragon flare with purple light when you enter combat, empowering your natural fists or natural weapons.",
"While wearing the insignia you gain a +1 bonus to the attack rolls and the damage rolls you make with unarmed strikes and natural weapons. Such attacks are considered to be magical."
]
},
{
"name": "Instrument of Illusions",
"source": "XGE",
"page": 137,
"type": "INS",
"tier": "minor",
"rarity": "common",
"reqAttune": true,
"wondrous": true,
"entries": [
"While you are playing this musical instrument, you can create harmless, illusory visual effects within a 5-foot-radius sphere centered on the instrument. If you are a bard, the radius increases to 15 feet. Sample visual effects include luminous musical notes, a spectral dancer, butterflies, and gently falling snow. The magical effects have neither substance nor sound, and they are obviously illusory. The effects end when you stop playing."
]
},
{
"name": "Instrument of Scribing",
"source": "XGE",
"page": 138,
"type": "INS",
"tier": "minor",
"rarity": "common",
"reqAttune": true,
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": 3,
"charges": 3,
"entries": [
"This musical instrument has 3 charges. While you are playing it, you can use an action to expend 1 charge from the instrument and write a magical message on a nonmagical object or surface that you can see within 30 feet of you. The message can be up to six words long and is written in a language you know. If you are a bard, you can scribe an additional seven words and choose to make the message glow faintly, allowing it to be seen in nonmagical darkness. Casting {@spell dispel magic} on the message erases it. Otherwise, the message fades away after 24 hours.",
"The instrument regains all expended charges daily at dawn."
]
},
{
"name": "Instrument of the Bards, Anstruth Harp",
"source": "DMG",
"page": 176,
"type": "INS",
"tier": "major",
"rarity": "very rare",
"reqAttune": "by a bard",
"reqAttuneTags": [
{
"class": "bard"
}
],
"wondrous": true,
"weight": 2,
"entries": [
"An instrument of the bards is an exquisite example of its kind, superior to an ordinary instrument in every way. Seven types of these instruments exist, each named after a legendary bard college. A creature that attempts to play the instrument without being attuned to it must succeed on a DC 15 Wisdom saving throw or take {@damage 2d4} psychic damage.",
"You can use an action to play the instrument and cast one of its spells. Once the instrument has been used to cast a spell, it can't be used to cast that spell again until the next dawn. The spells use your spellcasting ability and spell save DC.",
"You can play the instrument while casting a spell that causes any of its targets to be {@condition charmed} on a failed saving throw, thereby imposing disadvantage on the save. This effect applies only if the spell has a somatic or a material component.",
"All instruments of the bards can be used to cast the following spells: {@spell fly}, {@spell invisibility}, {@spell levitate}, and {@spell protection from evil and good}.",
"In addition, the Anstruth harp can be used to cast {@spell control weather}, {@spell cure wounds} (5th level), and {@spell wall of thorns}."
],
"attachedSpells": [
"control weather",
"fly",
"invisibility",
"levitate",
"protection from evil and good",
"cure wounds",
"wall of thorns"
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Instrument of the Bards, Canaith Mandolin",
"source": "DMG",
"page": 176,
"type": "INS",
"tier": "major",
"rarity": "rare",
"reqAttune": "by a bard",
"reqAttuneTags": [
{
"class": "bard"
}
],
"wondrous": true,
"weight": 2,
"entries": [
"An instrument of the bards is an exquisite example of its kind, superior to an ordinary instrument in every way. Seven types of these instruments exist, each named after a legendary bard college. A creature that attempts to play the instrument without being attuned to it must succeed on a DC 15 Wisdom saving throw or take {@damage 2d4} psychic damage.",
"You can use an action to play the instrument and cast one of its spells. Once the instrument has been used to cast a spell, it can't be used to cast that spell again until the next dawn. The spells use your spellcasting ability and spell save DC.",
"You can play the instrument while casting a spell that causes any of its targets to be {@condition charmed} on a failed saving throw, thereby imposing disadvantage on the save. This effect applies only if the spell has a somatic or a material component.",
"All instruments of the bards can be used to cast the following spells: {@spell fly}, {@spell invisibility}, {@spell levitate}, and {@spell protection from evil and good}.",
"In addition, the Canaith mandolin can be used to cast {@spell cure wounds} (3rd level), {@spell dispel magic}, and {@spell protection from energy} (lightning only)."
],
"attachedSpells": [
"cure wounds",
"fly",
"invisibility",
"levitate",
"protection from evil and good",
"dispel magic",
"protection from energy"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Instrument of the Bards, Cli Lyre",
"source": "DMG",
"page": 176,
"type": "INS",
"tier": "major",
"rarity": "rare",
"reqAttune": "by a bard",
"reqAttuneTags": [
{
"class": "bard"
}
],
"wondrous": true,
"weight": 2,
"entries": [
"An instrument of the bards is an exquisite example of its kind, superior to an ordinary instrument in every way. Seven types of these instruments exist, each named after a legendary bard college. A creature that attempts to play the instrument without being attuned to it must succeed on a DC 15 Wisdom saving throw or take {@damage 2d4} psychic damage.",
"You can use an action to play the instrument and cast one of its spells. Once the instrument has been used to cast a spell, it can't be used to cast that spell again until the next dawn. The spells use your spellcasting ability and spell save DC.",
"You can play the instrument while casting a spell that causes any of its targets to be {@condition charmed} on a failed saving throw, thereby imposing disadvantage on the save. This effect applies only if the spell has a somatic or a material component.",
"All instruments of the bards can be used to cast the following spells: {@spell fly}, {@spell invisibility}, {@spell levitate}, and {@spell protection from evil and good}.",
"In addition, the Cli lyre can be used to cast {@spell stone shape}, {@spell wall of fire}, and {@spell wind wall}."
],
"attachedSpells": [
"stone shape",
"fly",
"invisibility",
"levitate",
"protection from evil and good",
"wall of fire",
"wind wall"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Instrument of the Bards, Doss Lute",
"source": "DMG",
"page": 176,
"type": "INS",
"tier": "major",
"rarity": "uncommon",
"reqAttune": "by a bard",
"reqAttuneTags": [
{
"class": "bard"
}
],
"wondrous": true,
"weight": 2,
"entries": [
"An instrument of the bards is an exquisite example of its kind, superior to an ordinary instrument in every way. Seven types of these instruments exist, each named after a legendary bard college. A creature that attempts to play the instrument without being attuned to it must succeed on a DC 15 Wisdom saving throw or take {@damage 2d4} psychic damage.",
"You can use an action to play the instrument and cast one of its spells. Once the instrument has been used to cast a spell, it can't be used to cast that spell again until the next dawn. The spells use your spellcasting ability and spell save DC.",
"You can play the instrument while casting a spell that causes any of its targets to be {@condition charmed} on a failed saving throw, thereby imposing disadvantage on the save. This effect applies only if the spell has a somatic or a material component.",
"All instruments of the bards can be used to cast the following spells: {@spell fly}, {@spell invisibility}, {@spell levitate}, and {@spell protection from evil and good}.",
"In addition, the Doss lute can be used to cast {@spell animal friendship}, {@spell protection from energy} (fire only), and {@spell protection from poison}."
],
"attachedSpells": [
"animal friendship",
"fly",
"invisibility",
"levitate",
"protection from evil and good",
"protection from energy",
"protection from poison"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Instrument of the Bards, Fochlucan Bandore",
"source": "DMG",
"page": 176,
"type": "INS",
"tier": "major",
"rarity": "uncommon",
"reqAttune": "by a bard",
"reqAttuneTags": [
{
"class": "bard"
}
],
"wondrous": true,
"weight": 2,
"entries": [
"An instrument of the bards is an exquisite example of its kind, superior to an ordinary instrument in every way. Seven types of these instruments exist, each named after a legendary bard college. A creature that attempts to play the instrument without being attuned to it must succeed on a DC 15 Wisdom saving throw or take {@damage 2d4} psychic damage.",
"You can use an action to play the instrument and cast one of its spells. Once the instrument has been used to cast a spell, it can't be used to cast that spell again until the next dawn. The spells use your spellcasting ability and spell save DC.",
"You can play the instrument while casting a spell that causes any of its targets to be {@condition charmed} on a failed saving throw, thereby imposing disadvantage on the save. This effect applies only if the spell has a somatic or a material component.",
"All instruments of the bards can be used to cast the following spells: {@spell fly}, {@spell invisibility}, {@spell levitate}, and {@spell protection from evil and good}.",
"In addition, the Fochlucan bandore can be used to cast {@spell entangle}, {@spell faerie fire}, {@spell shillelagh}, and {@spell speak with animals}."
],
"attachedSpells": [
"entangle",
"fly",
"invisibility",
"levitate",
"protection from evil and good",
"faerie fire",
"shillelagh",
"speak with animals"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Instrument of the Bards, Mac-Fuirmidh Cittern",
"source": "DMG",
"page": 176,
"type": "INS",
"tier": "major",
"rarity": "uncommon",
"reqAttune": "by a bard",
"reqAttuneTags": [
{
"class": "bard"
}
],
"wondrous": true,
"weight": 2,
"entries": [
"An instrument of the bards is an exquisite example of its kind, superior to an ordinary instrument in every way. Seven types of these instruments exist, each named after a legendary bard college. A creature that attempts to play the instrument without being attuned to it must succeed on a DC 15 Wisdom saving throw or take {@damage 2d4} psychic damage.",
"You can use an action to play the instrument and cast one of its spells. Once the instrument has been used to cast a spell, it can't be used to cast that spell again until the next dawn. The spells use your spellcasting ability and spell save DC.",
"You can play the instrument while casting a spell that causes any of its targets to be {@condition charmed} on a failed saving throw, thereby imposing disadvantage on the save. This effect applies only if the spell has a somatic or a material component.",
"All instruments of the bards can be used to cast the following spells: {@spell fly}, {@spell invisibility}, {@spell levitate}, and {@spell protection from evil and good}.",
"In addition, the Mac-Fuirmidh cittern can be used to cast {@spell barkskin}, {@spell cure wounds}, and {@spell fog cloud}."
],
"attachedSpells": [
"barkskin",
"fly",
"invisibility",
"levitate",
"protection from evil and good",
"cure wounds",
"fog cloud"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Instrument of the Bards, Ollamh Harp",
"source": "DMG",
"page": 176,
"type": "INS",
"tier": "major",
"rarity": "legendary",
"reqAttune": "by a bard",
"reqAttuneTags": [
{
"class": "bard"
}
],
"wondrous": true,
"weight": 2,
"entries": [
"An instrument of the bards is an exquisite example of its kind, superior to an ordinary instrument in every way. Seven types of these instruments exist, each named after a legendary bard college. A creature that attempts to play the instrument without being attuned to it must succeed on a DC 15 Wisdom saving throw or take {@damage 2d4} psychic damage.",
"You can use an action to play the instrument and cast one of its spells. Once the instrument has been used to cast a spell, it can't be used to cast that spell again until the next dawn. The spells use your spellcasting ability and spell save DC.",
"You can play the instrument while casting a spell that causes any of its targets to be {@condition charmed} on a failed saving throw, thereby imposing disadvantage on the save. This effect applies only if the spell has a somatic or a material component.",
"All instruments of the bards can be used to cast the following spells: {@spell fly}, {@spell invisibility}, {@spell levitate}, and {@spell protection from evil and good}.",
"In addition, the Ollamh harp can be used to cast {@spell confusion}, {@spell control weather}, and {@spell fire storm}."
],
"attachedSpells": [
"confusion",
"fly",
"invisibility",
"levitate",
"protection from evil and good",
"control weather",
"fire storm"
],
"lootTables": [
"Magic Item Table I"
],
"hasFluffImages": true
},
{
"name": "Ioun Stone of Vitality",
"source": "IMR",
"page": 94,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"bonusSavingThrow": "+1",
"entries": [
"An {@italic Ioun stone} is named after Ioun, a god of knowledge and prophecy revered on some worlds. Many types of {@italic Ioun stone} exist, each type a distinct combination of shape and color.",
"When you use an action to toss one of these stones into the air, the stone orbits your head at a distance of {@dice 1d3} feet and confers a benefit to you. Thereafter, another creature must use an action to grasp or net the stone to separate it from you, either by making a successful attack roll against AC 24 or a successful DC 24 Dexterity ({@skill Acrobatics}) check. You can use an action to seize and stow the stone, ending its effect.",
"A stone has AC 24, 10 hit points, and resistance to all damage. It is considered to be an object that is being worn while it orbits your head.",
"You gain a +1 bonus to death saving throws while this luminous green-and-blue marbled sphere orbits your head."
]
},
{
"name": "Ioun Stone, Absorption",
"source": "DMG",
"page": 176,
"srd": true,
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"An {@italic Ioun stone} is named after Ioun, a god of knowledge and prophecy revered on some worlds. Many types of {@italic Ioun stone} exist, each type a distinct combination of shape and color.",
"When you use an action to toss one of these stones into the air, the stone orbits your head at a distance of {@dice 1d3} feet and confers a benefit to you. Thereafter, another creature must use an action to grasp or net the stone to separate it from you, either by making a successful attack roll against AC 24 or a successful DC 24 Dexterity ({@skill Acrobatics}) check. You can use an action to seize and stow the stone, ending its effect.",
"A stone has AC 24, 10 hit points, and resistance to all damage. It is considered to be an object that is being worn while it orbits your head.",
"While this pale lavender ellipsoid orbits your head, you can use your reaction to cancel a spell of 4th level or lower cast by a creature you can see and targeting only you.",
"Once the stone has canceled 20 levels of spells, it burns out and turns dull gray, losing its magic. If you are targeted by a spell whose level is higher than the number of spell levels the stone has left, the stone can't cancel it."
],
"lootTables": [
"Magic Item Table H"
]
},
{
"name": "Ioun Stone, Agility",
"source": "DMG",
"page": 176,
"srd": true,
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"ability": {
"dex": 2
},
"entries": [
"An {@italic Ioun stone} is named after Ioun, a god of knowledge and prophecy revered on some worlds. Many types of {@italic Ioun stone} exist, each type a distinct combination of shape and color.",
"When you use an action to toss one of these stones into the air, the stone orbits your head at a distance of {@dice 1d3} feet and confers a benefit to you. Thereafter, another creature must use an action to grasp or net the stone to separate it from you, either by making a successful attack roll against AC 24 or a successful DC 24 Dexterity ({@skill Acrobatics}) check. You can use an action to seize and stow the stone, ending its effect.",
"A stone has AC 24, 10 hit points, and resistance to all damage. It is considered to be an object that is being worn while it orbits your head.",
"Your Dexterity score increases by 2, to a maximum of 20, while this deep red sphere orbits your head."
],
"lootTables": [
"Magic Item Table H"
]
},
{
"name": "Ioun Stone, Awareness",
"source": "DMG",
"page": 176,
"srd": true,
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"An {@italic Ioun stone} is named after Ioun, a god of knowledge and prophecy revered on some worlds. Many types of {@italic Ioun stone} exist, each type a distinct combination of shape and color.",
"When you use an action to toss one of these stones into the air, the stone orbits your head at a distance of {@dice 1d3} feet and confers a benefit to you. Thereafter, another creature must use an action to grasp or net the stone to separate it from you, either by making a successful attack roll against AC 24 or a successful DC 24 Dexterity ({@skill Acrobatics}) check. You can use an action to seize and stow the stone, ending its effect.",
"A stone has AC 24, 10 hit points, and resistance to all damage. It is considered to be an object that is being worn while it orbits your head.",
"You can't be {@quickref Surprise|PHB|3|0|surprised} while this dark blue rhomboid orbits your head."
],
"lootTables": [
"Magic Item Table G"
]
},
{
"name": "Ioun Stone, Fortitude",
"source": "DMG",
"page": 176,
"srd": true,
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"ability": {
"con": 2
},
"entries": [
"An {@italic Ioun stone} is named after Ioun, a god of knowledge and prophecy revered on some worlds. Many types of {@italic Ioun stone} exist, each type a distinct combination of shape and color.",
"When you use an action to toss one of these stones into the air, the stone orbits your head at a distance of {@dice 1d3} feet and confers a benefit to you. Thereafter, another creature must use an action to grasp or net the stone to separate it from you, either by making a successful attack roll against AC 24 or a successful DC 24 Dexterity ({@skill Acrobatics}) check. You can use an action to seize and stow the stone, ending its effect.",
"A stone has AC 24, 10 hit points, and resistance to all damage. It is considered to be an object that is being worn while it orbits your head.",
"Your Constitution score increases by 2, to a maximum of 20, while this pink rhomboid orbits your head."
],
"lootTables": [
"Magic Item Table H"
]
},
{
"name": "Ioun Stone, Greater Absorption",
"source": "DMG",
"page": 176,
"srd": true,
"tier": "major",
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"entries": [
"An {@italic Ioun stone} is named after Ioun, a god of knowledge and prophecy revered on some worlds. Many types of {@italic Ioun stone} exist, each type a distinct combination of shape and color.",
"When you use an action to toss one of these stones into the air, the stone orbits your head at a distance of {@dice 1d3} feet and confers a benefit to you. Thereafter, another creature must use an action to grasp or net the stone to separate it from you, either by making a successful attack roll against AC 24 or a successful DC 24 Dexterity ({@skill Acrobatics}) check. You can use an action to seize and stow the stone, ending its effect.",
"A stone has AC 24, 10 hit points, and resistance to all damage. It is considered to be an object that is being worn while it orbits your head.",
"While this marbled lavender and green ellipsoid orbits your head, you can use your reaction to cancel a spell of 8th level or lower cast by a creature you can see and targeting only you. Once the stone has canceled 50 levels of spells, it burns out and turns dull gray, losing its magic. If you are targeted by a spell whose level is higher than the number of spell levels the stone has left, the stone can't cancel it."
],
"lootTables": [
"Magic Item Table I"
]
},
{
"name": "Ioun Stone, Historical Knowledge",
"source": "LLK",
"page": 55,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"grantsProficiency": true,
"entries": [
"An {@italic Ioun stone} is named after Ioun, a god of knowledge and prophecy revered on some worlds. Many types of {@italic Ioun stone} exist, each type a distinct combination of shape and color.",
"When you use an action to toss one of these stones into the air, the stone orbits your head at a distance of {@dice 1d3} feet and confers a benefit to you. Thereafter, another creature must use an action to grasp or net the stone to separate it from you, either by making a successful attack roll against AC 24 or a successful DC 24 Dexterity ({@skill Acrobatics}) check. You can use an action to seize and stow the stone, ending its effect.",
"A stone has AC 24, 10 hit points, and resistance to all damage. It is considered to be an object that is being worn while it orbits your head.",
"You gain proficiency in the {@skill History} skill, or a +1 bonus to checks with that skill if already proficient, while this polished, steely sphere orbits your head."
]
},
{
"name": "Ioun Stone, Insight",
"source": "DMG",
"page": 176,
"srd": true,
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"ability": {
"wis": 2
},
"entries": [
"An {@italic Ioun stone} is named after Ioun, a god of knowledge and prophecy revered on some worlds. Many types of {@italic Ioun stone} exist, each type a distinct combination of shape and color.",
"When you use an action to toss one of these stones into the air, the stone orbits your head at a distance of {@dice 1d3} feet and confers a benefit to you. Thereafter, another creature must use an action to grasp or net the stone to separate it from you, either by making a successful attack roll against AC 24 or a successful DC 24 Dexterity ({@skill Acrobatics}) check. You can use an action to seize and stow the stone, ending its effect.",
"A stone has AC 24, 10 hit points, and resistance to all damage. It is considered to be an object that is being worn while it orbits your head.",
"Your Wisdom score increases by 2, to a maximum of 20, while this incandescent blue sphere orbits your head."
],
"lootTables": [
"Magic Item Table H"
]
},
{
"name": "Ioun Stone, Intellect",
"source": "DMG",
"page": 176,
"srd": true,
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"ability": {
"int": 2
},
"entries": [
"An {@italic Ioun stone} is named after Ioun, a god of knowledge and prophecy revered on some worlds. Many types of {@italic Ioun stone} exist, each type a distinct combination of shape and color.",
"When you use an action to toss one of these stones into the air, the stone orbits your head at a distance of {@dice 1d3} feet and confers a benefit to you. Thereafter, another creature must use an action to grasp or net the stone to separate it from you, either by making a successful attack roll against AC 24 or a successful DC 24 Dexterity ({@skill Acrobatics}) check. You can use an action to seize and stow the stone, ending its effect.",
"A stone has AC 24, 10 hit points, and resistance to all damage. It is considered to be an object that is being worn while it orbits your head.",
"Your Intelligence score increases by 2, to a maximum of 20, while this marbled scarlet and blue sphere orbits your head."
],
"lootTables": [
"Magic Item Table H"
]
},
{
"name": "Ioun Stone, Language Knowledge",
"source": "LLK",
"page": 55,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"An {@italic Ioun stone} is named after Ioun, a god of knowledge and prophecy revered on some worlds. Many types of {@italic Ioun stone} exist, each type a distinct combination of shape and color.",
"When you use an action to toss one of these stones into the air, the stone orbits your head at a distance of {@dice 1d3} feet and confers a benefit to you. Thereafter, another creature must use an action to grasp or net the stone to separate it from you, either by making a successful attack roll against AC 24 or a successful DC 24 Dexterity ({@skill Acrobatics}) check. You can use an action to seize and stow the stone, ending its effect.",
"A stone has AC 24, 10 hit points, and resistance to all damage. It is considered to be an object that is being worn while it orbits your head.",
"You are fluent in one additional language while this pulsating bit of red jeweled crystal orbits your head. The DM chooses the language bestowed by the stone."
]
},
{
"name": "Ioun Stone, Leadership",
"source": "DMG",
"page": 176,
"srd": true,
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"ability": {
"cha": 2
},
"entries": [
"An {@italic Ioun stone} is named after Ioun, a god of knowledge and prophecy revered on some worlds. Many types of {@italic Ioun stone} exist, each type a distinct combination of shape and color.",
"When you use an action to toss one of these stones into the air, the stone orbits your head at a distance of {@dice 1d3} feet and confers a benefit to you. Thereafter, another creature must use an action to grasp or net the stone to separate it from you, either by making a successful attack roll against AC 24 or a successful DC 24 Dexterity ({@skill Acrobatics}) check. You can use an action to seize and stow the stone, ending its effect.",
"A stone has AC 24, 10 hit points, and resistance to all damage. It is considered to be an object that is being worn while it orbits your head.",
"Your Charisma score increases by 2, to a maximum of 20, while this marbled pink and green sphere orbits your head."
],
"lootTables": [
"Magic Item Table H"
]
},
{
"name": "Ioun Stone, Mastery",
"source": "DMG",
"page": 176,
"srd": true,
"tier": "major",
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"bonusProficiencyBonus": "+1",
"entries": [
"An {@italic Ioun stone} is named after Ioun, a god of knowledge and prophecy revered on some worlds. Many types of {@italic Ioun stone} exist, each type a distinct combination of shape and color.",
"When you use an action to toss one of these stones into the air, the stone orbits your head at a distance of {@dice 1d3} feet and confers a benefit to you. Thereafter, another creature must use an action to grasp or net the stone to separate it from you, either by making a successful attack roll against AC 24 or a successful DC 24 Dexterity ({@skill Acrobatics}) check. You can use an action to seize and stow the stone, ending its effect.",
"A stone has AC 24, 10 hit points, and resistance to all damage. It is considered to be an object that is being worn while it orbits your head.",
"Your proficiency bonus increases by 1 while this pale green prism orbits your head."
],
"lootTables": [
"Magic Item Table I"
]
},
{
"name": "Ioun Stone, Natural Knowledge",
"source": "LLK",
"page": 55,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"grantsProficiency": true,
"entries": [
"An {@italic Ioun stone} is named after Ioun, a god of knowledge and prophecy revered on some worlds. Many types of {@italic Ioun stone} exist, each type a distinct combination of shape and color.",
"When you use an action to toss one of these stones into the air, the stone orbits your head at a distance of {@dice 1d3} feet and confers a benefit to you. Thereafter, another creature must use an action to grasp or net the stone to separate it from you, either by making a successful attack roll against AC 24 or a successful DC 24 Dexterity ({@skill Acrobatics}) check. You can use an action to seize and stow the stone, ending its effect.",
"A stone has AC 24, 10 hit points, and resistance to all damage. It is considered to be an object that is being worn while it orbits your head.",
"You gain proficiency in the {@skill Nature} skill, or a +1 bonus to checks with that skill if already proficient, while this burnished, brassy stone orbits your head."
]
},
{
"name": "Ioun Stone, Protection",
"source": "DMG",
"page": 176,
"srd": true,
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"bonusAc": "+1",
"entries": [
"An {@italic Ioun stone} is named after Ioun, a god of knowledge and prophecy revered on some worlds. Many types of {@italic Ioun stone} exist, each type a distinct combination of shape and color.",
"When you use an action to toss one of these stones into the air, the stone orbits your head at a distance of {@dice 1d3} feet and confers a benefit to you. Thereafter, another creature must use an action to grasp or net the stone to separate it from you, either by making a successful attack roll against AC 24 or a successful DC 24 Dexterity ({@skill Acrobatics}) check. You can use an action to seize and stow the stone, ending its effect.",
"A stone has AC 24, 10 hit points, and resistance to all damage. It is considered to be an object that is being worn while it orbits your head.",
"You gain a +1 bonus to AC while this dusty rose prism orbits your head."
],
"lootTables": [
"Magic Item Table G"
]
},
{
"name": "Ioun Stone, Regeneration",
"source": "DMG",
"page": 176,
"srd": true,
"tier": "major",
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"entries": [
"An {@italic Ioun stone} is named after Ioun, a god of knowledge and prophecy revered on some worlds. Many types of {@italic Ioun stone} exist, each type a distinct combination of shape and color.",
"When you use an action to toss one of these stones into the air, the stone orbits your head at a distance of {@dice 1d3} feet and confers a benefit to you. Thereafter, another creature must use an action to grasp or net the stone to separate it from you, either by making a successful attack roll against AC 24 or a successful DC 24 Dexterity ({@skill Acrobatics}) check. You can use an action to seize and stow the stone, ending its effect.",
"A stone has AC 24, 10 hit points, and resistance to all damage. It is considered to be an object that is being worn while it orbits your head.",
"You regain 15 hit points at the end of each hour this pearly white spindle orbits your head, provided that you have at least 1 hit point."
],
"lootTables": [
"Magic Item Table I"
]
},
{
"name": "Ioun Stone, Religious Knowledge",
"source": "LLK",
"page": 55,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"grantsProficiency": true,
"entries": [
"An {@italic Ioun stone} is named after Ioun, a god of knowledge and prophecy revered on some worlds. Many types of {@italic Ioun stone} exist, each type a distinct combination of shape and color.",
"When you use an action to toss one of these stones into the air, the stone orbits your head at a distance of {@dice 1d3} feet and confers a benefit to you. Thereafter, another creature must use an action to grasp or net the stone to separate it from you, either by making a successful attack roll against AC 24 or a successful DC 24 Dexterity ({@skill Acrobatics}) check. You can use an action to seize and stow the stone, ending its effect.",
"A stone has AC 24, 10 hit points, and resistance to all damage. It is considered to be an object that is being worn while it orbits your head.",
"You gain proficiency in the {@skill Religion} skill, or a +1 bonus to checks with that skill if already proficient, while this tiny golden gem orbits your head."
]
},
{
"name": "Ioun Stone, Reserve",
"source": "DMG",
"page": 176,
"srd": true,
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"An {@italic Ioun stone} is named after Ioun, a god of knowledge and prophecy revered on some worlds. Many types of {@italic Ioun stone} exist, each type a distinct combination of shape and color.",
"When you use an action to toss one of these stones into the air, the stone orbits your head at a distance of {@dice 1d3} feet and confers a benefit to you. Thereafter, another creature must use an action to grasp or net the stone to separate it from you, either by making a successful attack roll against AC 24 or a successful DC 24 Dexterity ({@skill Acrobatics}) check. You can use an action to seize and stow the stone, ending its effect.",
"A stone has AC 24, 10 hit points, and resistance to all damage. It is considered to be an object that is being worn while it orbits your head.",
"This vibrant purple prism stores spells cast into it, holding them until you use them. The stone can store up to 3 levels worth of spells at a time. When found, it contains {@dice 1d4 - 1} levels of stored spells chosen by the DM.",
"Any creature can cast a spell of 1st through 3rd level into the stone by touching it as the spell is cast. The spell has no effect, other than to be stored in the stone. If the stone can't hold the spell, the spell is expended without effect. The level of the slot used to cast the spell determines how much space it uses.",
"While this stone orbits your head, you can cast any spell stored in it. The spell uses the slot level, spell save DC, spell attack bonus, and spellcasting ability of the original caster, but is otherwise treated as if you cast the spell. The spell cast from the stone is no longer stored in it, freeing up space."
],
"lootTables": [
"Magic Item Table G"
]
},
{
"name": "Ioun Stone, Self-Preservation",
"source": "LLK",
"page": 55,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"bonusSavingThrow": "+1",
"entries": [
"An {@italic Ioun stone} is named after Ioun, a god of knowledge and prophecy revered on some worlds. Many types of {@italic Ioun stone} exist, each type a distinct combination of shape and color.",
"When you use an action to toss one of these stones into the air, the stone orbits your head at a distance of {@dice 1d3} feet and confers a benefit to you. Thereafter, another creature must use an action to grasp or net the stone to separate it from you, either by making a successful attack roll against AC 24 or a successful DC 24 Dexterity ({@skill Acrobatics}) check. You can use an action to seize and stow the stone, ending its effect.",
"A stone has AC 24, 10 hit points, and resistance to all damage. It is considered to be an object that is being worn while it orbits your head.",
"You gain a +1 bonus to Intelligence saving throws while this silvery gem orbits your head."
]
},
{
"name": "Ioun Stone, Strength",
"source": "DMG",
"page": 176,
"srd": true,
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"ability": {
"str": 2
},
"entries": [
"An {@italic Ioun stone} is named after Ioun, a god of knowledge and prophecy revered on some worlds. Many types of {@italic Ioun stone} exist, each type a distinct combination of shape and color.",
"When you use an action to toss one of these stones into the air, the stone orbits your head at a distance of {@dice 1d3} feet and confers a benefit to you. Thereafter, another creature must use an action to grasp or net the stone to separate it from you, either by making a successful attack roll against AC 24 or a successful DC 24 Dexterity ({@skill Acrobatics}) check. You can use an action to seize and stow the stone, ending its effect.",
"A stone has AC 24, 10 hit points, and resistance to all damage. It is considered to be an object that is being worn while it orbits your head.",
"Your Strength score increases by 2, to a maximum of 20, while this pale blue rhomboid orbits your head."
],
"lootTables": [
"Magic Item Table H"
]
},
{
"name": "Ioun Stone, Supreme Intellect",
"source": "LLK",
"page": 55,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"An {@italic Ioun stone} is named after Ioun, a god of knowledge and prophecy revered on some worlds. Many types of {@italic Ioun stone} exist, each type a distinct combination of shape and color.",
"When you use an action to toss one of these stones into the air, the stone orbits your head at a distance of {@dice 1d3} feet and confers a benefit to you. Thereafter, another creature must use an action to grasp or net the stone to separate it from you, either by making a successful attack roll against AC 24 or a successful DC 24 Dexterity ({@skill Acrobatics}) check. You can use an action to seize and stow the stone, ending its effect.",
"A stone has AC 24, 10 hit points, and resistance to all damage. It is considered to be an object that is being worn while it orbits your head.",
"You gain a +1 bonus to Intelligence checks while this faceted sphere orbits your head."
]
},
{
"name": "Ioun Stone, Sustenance",
"source": "DMG",
"page": 176,
"srd": true,
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"An {@italic Ioun stone} is named after Ioun, a god of knowledge and prophecy revered on some worlds. Many types of {@italic Ioun stone} exist, each type a distinct combination of shape and color.",
"When you use an action to toss one of these stones into the air, the stone orbits your head at a distance of {@dice 1d3} feet and confers a benefit to you. Thereafter, another creature must use an action to grasp or net the stone to separate it from you, either by making a successful attack roll against AC 24 or a successful DC 24 Dexterity ({@skill Acrobatics}) check. You can use an action to seize and stow the stone, ending its effect.",
"A stone has AC 24, 10 hit points, and resistance to all damage. It is considered to be an object that is being worn while it orbits your head.",
"You don't need to eat or drink while this clear spindle orbits your head."
],
"lootTables": [
"Magic Item Table G"
]
},
{
"name": "Iron",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TG",
"rarity": "none",
"weight": 1,
"value": 10
},
{
"name": "Iron Ball",
"source": "IDRotF",
"page": 238,
"type": "R",
"rarity": "unknown (magic)",
"weaponCategory": "simple",
"property": [
"F",
"T"
],
"range": "120",
"dmg1": "1d4",
"dmgType": "B",
"entries": [
"This ball is used to play a game of {@adventure Chain Lightning|IDRotF|17|Chain Lightning}."
]
},
{
"name": "Iron Bands of Bilarro",
"source": "DMG",
"page": 177,
"srd": "Iron Bands of Binding",
"tier": "major",
"rarity": "rare",
"wondrous": true,
"weight": 1,
"entries": [
"This rusty iron sphere measures 3 inches in diameter and weighs 1 pound. You can use an action to speak the command word and throw the sphere at a Huge or smaller creature you can see within 60 feet of you. As the sphere moves through the air, it opens into a tangle of metal bands.",
"Make a ranged attack roll with an attack bonus equal to your Dexterity modifier plus your proficiency bonus. On a hit, the target is {@condition restrained} until you take a bonus action to speak the command word again to release it. Doing so, or missing with the attack, causes the bands to contract and become a sphere once more.",
"A creature, including the one {@condition restrained}, can use an action to make a DC 20 Strength check to break the iron bands. On a success, the item is destroyed, and the {@condition restrained} creature is freed. If the check fails, any further attempts made by that creature automatically fail until 24 hours have elapsed.",
"Once the bands are used, they can't be used again until the next dawn."
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Iron Flask",
"source": "DMG",
"page": 178,
"srd": true,
"tier": "major",
"rarity": "legendary",
"wondrous": true,
"weight": 1,
"entries": [
"This iron bottle has a brass stopper. You can use an action to speak the flask's command word, targeting a creature that you can see within 60 feet of you. If the target is native to a plane of existence other than the one you're on, the target must succeed on a DC 17 Wisdom saving throw or be trapped in the flask. If the target has been trapped by the flask before, it has advantage on the saving throw. Once trapped, a creature remains in the flask until released. The flask can hold only one creature at a time. A creature trapped in the flask doesn't need to breathe, eat, or drink and doesn't age.",
"You can use an action to remove the flask's stopper and release the creature the flask contains. The creature is friendly to you and your companions for 1 hour and obeys your commands for that duration. If you give no commands or give it a command that is likely to result in its death, it defends itself but otherwise takes no actions. At the end of the duration, the creature acts in accordance with its normal disposition and alignment.",
"An {@spell identify} spell reveals that a creature is inside the flask, but the only way to determine the type of creature is to open the flask. A newly discovered bottle might already contain a creature chosen by the DM or determined randomly.",
{
"type": "table",
"colLabels": [
"{@dice d100}",
"Contents"
],
"colStyles": [
"col-1 text-center",
"col-11"
],
"rows": [
[
"01-50",
"Empty"
],
[
"51",
"{@creature Arcanaloth}"
],
[
"52",
"{@creature Cambion}"
],
[
"53-54",
"{@creature Dao}"
],
[
"55-57",
"Demon (type 1): {@creature barlgura}, {@creature shadow demon}, or {@creature vrock}"
],
[
"58-60",
"Demon (type 2): {@creature chasme} or {@creature hezrou}"
],
[
"61-62",
"Demon (type 3): {@creature glabrezu} or {@creature yochlol}"
],
[
"63-64",
"Demon (type 4): {@creature nalfeshnee}"
],
[
"65",
"Demon (type 5): {@creature marilith}"
],
[
"66",
"Demon (type 6): {@creature balor} or {@creature goristro}"
],
[
"67",
"{@creature Deva}"
],
[
"68-69",
"Devil (greater): {@creature horned devil}, {@creature erinyes}, {@creature ice devil}, or {@creature pit fiend}"
],
[
"70-72",
"Devil (lesser): {@creature imp}, {@creature spined devil}, {@creature bearded devil}, {@creature barbed devil}, {@creature chain devil}, or {@creature bone devil}"
],
[
"73-74",
"{@creature Djinni}"
],
[
"75-76",
"{@creature Efreeti}"
],
[
"77-78",
"Elemental (any)"
],
[
"79",
"{@creature Githyanki knight}"
],
[
"80",
"{@creature Githzerai zerth}"
],
[
"81-82",
"{@creature Invisible stalker}"
],
[
"83-84",
"{@creature Marid}"
],
[
"85-86",
"{@creature Mezzoloth}"
],
[
"87-88",
"{@creature Night hag}"
],
[
"89-90",
"{@creature Nycaloth}"
],
[
"91",
"{@creature Planetar}"
],
[
"92-93",
"{@creature Salamander}"
],
[
"94-95",
"Slaad (any)"
],
[
"96",
"{@creature Solar}"
],
[
"97-98",
"{@creature succubus||Succubus/Incubus}"
],
[
"99",
"{@creature Ultroloth}"
],
[
"00",
"{@creature Xorn}"
]
]
}
],
"lootTables": [
"Magic Item Table I"
],
"hasFluffImages": true
},
{
"name": "Iron Pot",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 10,
"value": 200,
"entries": [
"An iron pot holds 1 gallon of liquid."
]
},
{
"name": "Iron Spike",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 0.5,
"value": 10
},
{
"name": "Iron Spikes (10)",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 5,
"value": 100,
"packContents": [
{
"item": "iron spike|phb",
"quantity": 10
}
]
},
{
"name": "Ironfang",
"source": "PotA",
"page": 224,
"baseItem": "war pick|phb",
"type": "M",
"resist": [
"acid"
],
"rarity": "legendary",
"reqAttune": true,
"weight": 2,
"weaponCategory": "martial",
"dmg1": "1d8",
"dmgType": "P",
"grantsProficiency": true,
"bonusWeapon": "+2",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"A {@item war pick|phb} forged from a single piece of iron, Ironfang has a fang-like head inscribed with ancient runes. The pick is heavy in the hand, but when the wielder swings the pick in anger, the weapon seems almost weightless. This weapon is immune to any form of rust, acid, or corrosion—nothing seems to mark it. Ironfang contains a spark of {@creature Ogrémoch|pota}, the Prince of Evil Earth.",
"You gain a +2 bonus to attack and damage rolls made with this magic weapon. When you hit with it, the target takes an extra {@damage 1d8} thunder damage.",
{
"type": "entries",
"name": "Earth Mastery",
"entries": [
"You gain the following benefits while you hold Ironfang:",
{
"type": "list",
"items": [
"You can speak Terran fluently.",
"You have resistance to acid damage.",
"You have {@sense tremorsense|MM} out to a range of 60 feet.",
"You can sense the presence of precious metals and stones within 60 feet of you, but not their exact location.",
"You can cast {@spell dominate monster} (save DC 17) on an {@creature earth elemental}. Once you have done so, Ironfang can't be used this way again until the next dawn."
]
}
]
},
{
"type": "entries",
"name": "Shatter",
"entries": [
"Ironfang has 3 charges. You can use your action to expend 1 charge and cast the 2nd-level version of {@spell shatter} (DC 17). Ironfang regains {@dice 1d3} expended charges daily at dawn."
]
},
{
"type": "entries",
"name": "The Rumbling",
"entries": [
"While inside an earth node, you can perform a ritual called the Rumbling, using Ironfang to create a {@item devastation orb of earth|PotA}. Once you perform the ritual, Ironfang can't be used to perform the ritual again until the next dawn."
]
},
{
"type": "entries",
"name": "Flaw",
"entries": [
"Ironfang heightens its wielder's destructive nature. While attuned to the weapon, you gain the following flaw: \"I like to break things and cause ruin.\""
]
}
],
"attachedSpells": [
"dominate monster",
"shatter"
],
"hasFluffImages": true
},
{
"name": "Ivana's Whisper",
"source": "VRGR",
"page": 83,
"type": "G",
"rarity": "unknown",
"poison": true,
"entries": [
"This poison bears a distinct scent and chemical message from Ivana Boritsi. A creature subjected to this poison must succeed on a DC 18 Constitution saving throw or experience the effects of a {@spell dream} spell created by Ivana the next time they sleep. This poison is nonmagical, and Ivana doesn't directly communicate with those affected during the dream. Rather, she creates the illusion of speaking with her intended target by alchemically crafting her message, predicting her target's reactions, and chemically encoding in her responses. She wears this poison as a perfume or hides it within gift bouquets, allowing it to convey her message later."
],
"poisonTypes": [
"inhaled"
],
"attachedSpells": [
"dream"
]
},
{
"name": "Izzet Guild Signet",
"source": "GGR",
"page": 178,
"type": "RG",
"rarity": "uncommon",
"reqAttune": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"This ring, adorned with the symbol of Izzet, allows you to cast {@spell chaos bolt|XGE}. A guild signet is sometimes awarded to a guild member whose renown score in that guild is 5 or higher, as a reward for performing special services for the guild. Aside from its magical properties, the ring is also an indicator of Izzet's recognition and favor.",
"A signet has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. While wearing it, you can expend 1 charge to cast the associated spell (save DC 13)."
],
"attachedSpells": [
"chaos bolt|XGE"
],
"hasFluffImages": true
},
{
"name": "Izzet Keyrune",
"source": "GGR",
"page": 177,
"rarity": "rare",
"reqAttune": "by a member of the Izzet guild",
"reqAttuneTags": [
{
"background": "izzet engineer|ggr"
}
],
"wondrous": true,
"entries": [
"Formed of carved and polished red and blue stone, the keyrune includes bits of cable and wire. One end resembles a humanlike head, suggesting the jagged elemental form of the {@creature galvanice weird|GGR} that it can become for a duration of 3 hours. In this form, it will serve you as a bodyguard, lift and carry things for you, act as a test subject for your experiments, or aid you in any other way that its capabilities allow.",
"When you use an action to speak the item's command word and place the keyrune on the ground in an unoccupied space within 5 feet of you, the keyrune transforms into a {@creature galvanice weird|GGR}. If there isn't enough space for the creature, the keyrune doesn't transform.",
"The creature is friendly to you, your companions, and other members of your guild (unless those guild members are hostile to you). It understands your languages and obeys your spoken commands. If you issue no commands, the creature takes the {@action Dodge} action and moves to avoid danger.",
"At the end of the duration, the creature reverts to its keyrune form. It reverts early if it drops to 0 hit points or if you use an action to speak the command word again while touching it. When the creature reverts to its keyrune form, it can't transform again until 36 hours have passed."
],
"hasFluffImages": true
},
{
"name": "Jacinth",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 500000,
"entries": [
"A transparent fiery orange gemstone."
]
},
{
"name": "Jade",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 10000,
"entries": [
"A translucent light green, deep green, or white gemstone."
]
},
{
"name": "Jade bowl (River Heralds)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 25000
},
{
"name": "Jade breastplate (River Heralds)",
"source": "PSX",
"page": 25,
"type": "$",
"rarity": "none",
"value": 750000
},
{
"name": "Jade game board with solid gold playing pieces",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 750000
},
{
"name": "Jade headpiece (River Heralds)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 25000
},
{
"name": "Jade Serpent Staff",
"source": "WDMM",
"page": 92,
"type": "OTH",
"rarity": "unknown (magic)",
"entries": [
"This staff is broken into five pieces, each worth 100 gp for the jade alone. The entire staff can be restored with {@spell mending} cantrips; each casting of the spell repairs one break in the staff. If the staff is made whole, it transforms from an object into an animated jade serpent that has the statistics of a {@creature giant poisonous snake}, with these changes:",
{
"type": "list",
"items": [
"The serpent is a construct that understands and obeys whoever was holding the staff when it transformed.",
"It has immunity to poison damage and the {@condition poisoned} condition. It doesn't require air, food, drink, or sleep.",
"When it drops to 0 hit points, roll a {@dice d6}. On a roll of 1, the snake turns to dust and is destroyed. On any other roll, it changes back into a staff and breaks into {@dice 1d4 + 1} pieces that must be magically mended before the staff can be used again."
]
}
]
},
{
"name": "Jade sword with amber (River Heralds)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 75000
},
{
"name": "Jade totem with diamond eyes (River Heralds)",
"source": "PSX",
"page": 25,
"type": "$",
"rarity": "none",
"value": 750000
},
{
"name": "Jakarion's Staff of Frost",
"source": "CoS",
"page": 187,
"_copy": {
"name": "Staff of Frost",
"source": "DMG",
"_mod": {
"entries": {
"mode": "appendArr",
"items": "Imprinted on this staff is a fragment of a dead wizard's personality. The first character who touches the staff gains the following flaw: \"I crave power above all else, and will do anything to obtain more of it.\" This flaw trumps any conflicting personality trait."
}
}
}
},
{
"name": "Jasper",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 5000,
"entries": [
"An opaque blue, black, or brown gemstone."
]
},
{
"name": "Javelin of Backbiting",
"source": "TftYP",
"page": 229,
"baseItem": "javelin|phb",
"type": "M",
"rarity": "very rare",
"reqAttune": true,
"curse": true,
"weight": 2,
"weaponCategory": "simple",
"property": [
"T"
],
"range": "60/150",
"dmg1": "1d6",
"dmgType": "P",
"bonusWeapon": "+2",
"entries": [
"You gain a +2 bonus to attack and damage rolls made with this magic weapon. When you throw it, its normal and long ranges both increase by 30 feet, and it deals one extra die of damage on a hit. After you throw it and it hits or misses, it flies back to your hand immediately.",
{
"type": "entries",
"name": "Curse",
"entries": [
"This weapon is cursed, and becoming attuned to it extends the curse to you. Until the curse is broken with {@spell remove curse} or similar magic, you are unwilling to part with the weapon, keeping it within reach at all times. In addition, you have disadvantage on attack rolls made with weapons other than this one."
]
},
"Whenever you roll a 1 on an attack roll using this weapon, the weapon bends or flies to hit you in the back. Make a new attack roll with advantage against your own AC. If the result is a hit, you take damage as if you had attacked yourself with the javelin."
]
},
{
"name": "Javelin of Lightning",
"source": "DMG",
"page": 178,
"srd": true,
"baseItem": "javelin|phb",
"type": "M",
"tier": "major",
"rarity": "uncommon",
"weight": 2,
"weaponCategory": "simple",
"property": [
"T"
],
"range": "30/120",
"dmg1": "1d6",
"dmgType": "P",
"entries": [
"This javelin is a magic weapon. When you hurl it and speak its command word, it transforms into a bolt of lightning, forming a line 5 feet wide that extends out from you to a target within 120 feet. Each creature in the line excluding you and the target must make a DC 13 Dexterity saving throw, taking {@damage 4d6} lightning damage on a failed save, and half as much damage on a successful one. The lightning bolt turns back into a javelin when it reaches the target. Make a ranged weapon attack against the target. On a hit, the target takes damage from the javelin plus {@damage 4d6} lightning damage.",
"The javelin's property can't be used again until the next dawn. In the meantime, the javelin can still be used as a magic weapon."
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Jet",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 10000,
"entries": [
"An opaque deep black gemstone."
]
},
{
"name": "Jewel of Three Prayers (Awakened)",
"source": "CRCotN",
"_copy": {
"name": "Jewel of Three Prayers (Dormant)",
"source": "CRCotN",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Awakened State",
"entries": [
"In this state, the jewel has received the blessing of Avandra the Change Bringer. Three delicate spires unfurl from the jewel's center, like the buds of flowers opening in the spring. Three lapis lazuli stones rest like dewdrops on these spires.",
{
"type": "list",
"items": [
"The following benefits of the jewel improve:",
"The bonus that the jewel confers to your AC increases to +2.",
"Its number of charges increases to 5."
]
},
"The jewel gains the following additional properties, which you can use while wearing or holding it:",
{
"type": "list",
"items": [
"You can expend 1 of the jewel's charges (no action required) to end one of the following conditions on yourself: {@condition grappled}, {@condition paralyzed}, or {@condition restrained}.",
"When another creature you can see within 60 feet of you fails a saving throw, you can expend 1 of the jewel's charges as a reaction to enable that creature to reroll the saving throw, potentially turning a failure into a success. The creature must use the new roll."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"bonusAc": "+2",
"charges": 5,
"hasFluffImages": true
},
{
"name": "Jewel of Three Prayers (Dormant)",
"source": "CRCotN",
"page": 213,
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"bonusAc": "+1",
"recharge": "dawn",
"rechargeAmount": 3,
"charges": 3,
"entries": [
"The Jewel of Three Prayers is a Vestige of Divergence (see the \"Vestiges of Divergence\" sidebar). In ancient times, Alyxian the Apotheon bore this amulet as a symbol of his covenant with three Prime Deities: Sehanine the Moon Weaver, Avandra the Change Bringer, and Corellon the Arch Heart. When the jewel is found, only Sehanine's power thrums within its dormant heart. The power of the other two deities waits to be reawakened by a hero—or heroes—who can follow in Alyxian's footsteps.",
{
"type": "entries",
"name": "Dormant State",
"entries": [
"In this state, the Jewel of Three Prayers is a glittering golden disk attached to a fine golden chain. The chain magically resizes to function as a necklace for the creature that wears it.",
"In its Dormant State, the jewel has the following properties:",
{
"type": "list",
"items": [
"You gain a +1 bonus to AC while wearing the jewel.",
"While wearing or holding the jewel, you can use an action to cause it to shed bright light in a 15-foot radius and dim light for an additional 15 feet. The light lasts until you extinguish it (no action required).",
"The jewel has 3 charges and regains all its expended charges daily at dawn. While holding the jewel, you can expend 1 charge from it to cast the {@spell invisibility} spell."
]
}
]
}
],
"attachedSpells": [
"invisibility"
],
"hasFluffImages": true
},
{
"name": "Jewel of Three Prayers (Exalted)",
"source": "CRCotN",
"_copy": {
"name": "Jewel of Three Prayers (Awakened)",
"source": "CRCotN",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Exalted State",
"entries": [
"In this state, the jewel has received the blessing of Corellon the Arch Heart. A gleaming emerald surrounded by a halo of gold appears on the jewel.",
"The following benefits of the jewel improve:",
{
"type": "list",
"items": [
"The bonus that the jewel confers to your AC increases to +3.",
"Its number of charges increases to 7."
]
},
"The jewel gains the following additional properties, which you can use while wearing or holding it:",
{
"type": "list",
"items": [
"You gain the ability to breathe water, and you gain a swimming speed equal to your walking speed.",
"Each of your allies within 30 feet of you gains the ability to breathe water and gains a swimming speed equal to its walking speed.",
"As a bonus action, you can expend 1 of the jewel's charges to target yourself or one willing creature you can see within 15 feet of yourself. The target teleports to an unoccupied space of your choice within 15 feet of yourself, along with any equipment the target is wearing or carrying. The target appears in a flash of golden radiance, and each creature of your choice within 5 feet of the target's new location must make a DC 18 Constitution saving throw. On a failed save, the creature takes {@damage 4d10} radiant damage and is {@condition blinded} until the start of your next turn. On a successful save, the creature takes half as much damage and isn't {@condition blinded}."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"bonusAc": "+3",
"modifySpeed": {
"equal": {
"swim": "walk"
}
},
"charges": 7,
"hasFluffImages": true
},
{
"name": "Jeweled anklet",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 250000
},
{
"name": "Jeweled anklet (Brazen Coalition)",
"source": "PSX",
"page": 25,
"type": "$",
"rarity": "none",
"value": 250000
},
{
"name": "Jeweled gold crown",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 750000
},
{
"name": "Jeweled platinum ring",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 750000
},
{
"name": "Jeweler's Tools",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"additionalSources": [
{
"source": "XGE",
"page": 82
}
],
"type": "AT",
"rarity": "none",
"weight": 2,
"value": 2500,
"additionalEntries": [
"Training with jeweler's tools includes the basic techniques needed to beautify gems. It also gives you expertise in identifying precious stones.",
{
"type": "entries",
"name": "Components",
"entries": [
"Jeweler's tools consist of a small saw and hammer, files, pliers, and tweezers."
]
},
{
"type": "entries",
"name": "Arcana",
"entries": [
"Proficiency with jeweler's tools grants you knowledge about the reputed mystical uses of gems. This insight proves handy when you make {@skill Arcana} checks related to gems or gem-encrusted items."
]
},
{
"type": "entries",
"name": "Investigation",
"entries": [
"When you inspect jeweled objects, your proficiency with jeweler's tools aids you in picking out clues they might hold."
]
},
{
"type": "entries",
"name": "Identify Gems",
"entries": [
"You can identify gems and determine their value at a glance."
]
},
{
"type": "table",
"caption": "Jeweler's Tools",
"colLabels": [
"Activity",
"DC"
],
"colStyles": [
"col-10",
"col-2 text-center"
],
"rows": [
[
"Modify a gem's appearance",
"15"
],
[
"Determine a gem's history",
"20"
]
]
}
]
},
{
"name": "Jug",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 4,
"value": 2,
"entries": [
"A jug holds 1 gallon of liquid."
]
},
{
"name": "Junky +1 Dagger",
"source": "TftYP",
"page": 77,
"baseItem": "dagger|phb",
"type": "M",
"rarity": "unknown (magic)",
"weight": 1,
"weaponCategory": "simple",
"property": [
"F",
"L",
"T"
],
"range": "20/60",
"dmg1": "1d4",
"dmgType": "P",
"bonusWeapon": "+1",
"weapon": true,
"entries": [
"You have a +1 bonus to attack and damage rolls made with this dagger, which looks like junk. When it is used, its grip frays, its blade chips, and it flakes rust. If you get a natural 1 on an attack roll while wielding this weapon, it breaks and becomes nonmagical."
]
},
{
"name": "Kagonesti Forest Shroud",
"source": "DSotDQ",
"page": 190,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"This cloak appears to be made of autumnal leaves woven together. While you wear this cloak, you have advantage on Dexterity ({@skill Stealth}) checks, and you can use a bonus action to magically teleport, along with any equipment you are wearing or carrying, to an unoccupied space you can see within 30 feet of yourself. You then have advantage on the next attack roll you make before the end of the turn. Once this bonus action is used, it can't be used again until the next dawn."
],
"hasFluffImages": true
},
{
"name": "Keelboat",
"source": "DMG",
"page": 119,
"srd": true,
"basicRules": true,
"type": "SHP",
"rarity": "none",
"value": 300000,
"crew": 1,
"vehAc": 15,
"vehHp": 100,
"vehDmgThresh": 10,
"vehSpeed": 1,
"capPassenger": 6,
"capCargo": 0.5,
"entries": [
"Keelboats and rowboats are used on lakes and rivers. If going downstream, add the speed of the current (typically 3 miles per hour) to the speed of the vehicle. These vehicles can't be rowed against any significant current, but they can be pulled upstream by draft animals on the shores."
],
"seeAlsoVehicle": [
"Keelboat"
]
},
{
"name": "Keg of Alchemist's Fire",
"source": "AAG",
"page": 0,
"type": "G",
"rarity": "none",
"entries": [
"A keg of alchemist's fire lobbed at a creature or an object deals 21 ({@damage 6d6}) fire damage on a hit. Further, the target is set ablaze and takes the damage again every round (in the case of a creature, at the start of each of its turns) until the fire is put out. A creature within reach of the blaze can take an action to smother the flames using a blanket or carpet, reducing the fire damage by {@dice 2d6}. Three such actions are needed to fully put out the fire."
]
},
{
"name": "Keoghtom's Ointment",
"source": "DMG",
"page": 179,
"srd": "Restorative Ointment",
"basicRules": true,
"tier": "minor",
"rarity": "uncommon",
"wondrous": true,
"entries": [
"This glass jar, 3 inches in diameter, contains {@dice 1d4 + 1} doses of a thick mixture that smells faintly of aloe. The jar and its contents weigh ½ pound.",
"As an action, one dose of the ointment can be swallowed or applied to the skin. The creature that receives it regains {@dice 2d8 + 2} hit points, ceases to be {@condition poisoned}, and is cured of any disease."
],
"lootTables": [
"Magic Item Table B"
],
"hasFluffImages": true
},
{
"name": "Keycharm",
"source": "ERLW",
"page": 277,
"otherSources": [
{
"source": "UAWGE",
"page": 115
}
],
"rarity": "common",
"reqAttune": "by a creature with the Mark of Warding",
"reqAttuneTags": [
{
"race": "dwarf (mark of warding)|erlw"
}
],
"wondrous": true,
"entries": [
"This small stylized key plays a vital role in the work of House Kundarak. If you cast the {@spell alarm}, {@spell arcane lock}, or {@spell glyph of warding} spell, you can tie the effect to the keycharm so that whoever holds it receives the notification from the {@spell alarm} spell, bypasses the lock of the {@spell arcane lock} spell, or avoids triggering the glyph placed by the {@spell glyph of warding} spell. In addition, the holder (who needn't be attuned to the item) can take an action to end any one spell tied to it, provided the holder knows the command word you set for ending the tied spells. The keycharm can have up to three tied spells at one time."
]
},
{
"name": "Keystone of Creation",
"source": "AitFR-AVT",
"page": 12,
"otherSources": [
{
"source": "AitFR-DN",
"page": 14
},
{
"source": "AitFR-DN",
"page": 13
}
],
"rarity": "artifact",
"reqAttune": true,
"wondrous": true,
"entries": [
"A keystone of creation is a piece of the {@item Stone of Creation|AitFR-AVT}, usually between 6 to 10 inches on a side, and can be rough-hewn or cleanly cut and polished by a mason attuned to the {@item Stone of Creation|AitFR-AVT}.",
{
"type": "entries",
"name": "Random Properties",
"entries": [
"A keystone of creation has the following random properties:",
{
"type": "list",
"items": [
"1 {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} property",
"1 {@table Beneficial Manifestations|AitFR-AVT|beneficial} manifestation",
"1 {@table Detrimental Manifestations|AitFR-AVT|detrimental} manifestation"
]
}
]
},
{
"type": "entries",
"name": "Extradimensional Places",
"entries": [
"You can use the keystone to conjure an extradimensional space such as {@spell Mordenkainen's magnificent mansion}. The easiest way is to simply cast the spell using the artifact's power, which requires no spell slots or material components. Each time you cast a spell with the artifact, the subsequent casting replaces the prior one. For more detailed control of a conjured demiplane or extradimensional space, see \"Construction Details.\""
]
},
{
"type": "entries",
"name": "Raising Structures",
"entries": [
"You can use the keystone to conjure a physical structure on the Material Plane. The easiest way is to simply cast the {@spell mighty fortress|XGE} spell using the artifact's power, which requires no spell slots or material components. Each time you cast a spell with the artifact, the subsequent casting replaces the prior one. For more detailed control of a conjured demiplane or extradimensional space, see \"Construction Details.\" Locations conjured by keystones cannot become permanent through repeated spell castings."
]
},
{
"type": "entries",
"name": "Connected Spaces",
"entries": [
"You can use multiple keystones to create larger spaces, whether adjacent to each other or even interlinked. You can even combine structures on the Material Plane and extradimensional spaces this way. If you are attuned to one keystone when you attune to a second keystone, the keystones combine to become a single artifact (utilizing a single attunement slot), combining their features and their fates forevermore. You cannot attune to, and thus combine, more than three keystones at once."
]
},
{
"type": "entries",
"name": "Destroying a Keystone",
"entries": [
"A keystone is an artifact in its own right and impervious to most damage, but a {@spell disintegrate} spell is sufficient to destroy an unattuned keystone.",
"After it has been attuned at least once, a given keystone requires attunement to maintain its magical power. Otherwise, its creations on the Material Plane gradually rot and decay over {@dice 1d12 + 1} days, and a demiplane relying on the keystone collapses over {@dice 1d20 + 10} minutes. Additional destructive forces like fire may hasten the destruction. A demiplane created or upheld by a keystone ejects all creatures within it to their home planes when it collapses.",
"When a keystone's creation is fully destroyed, the keystone becomes an inert, powerless rock. If the {@item Stone of Creation|AitFR-AVT} is destroyed, all its keystones break attunement, lose all magical properties, and their creations decay or collapse to nothing.",
"If it reaches the Elemental Chaos or is struck by a forked, metal rod attuned to the Elemental Chaos (as per the {@spell plane shift} spell), a keystone is destroyed."
]
},
{
"type": "section",
"entries": [
{
"type": "entries",
"name": "Construction Details",
"entries": [
"You can customize a keystone's creation with cosmetic details you can imagine. You can also rearrange the floorplans and structural details of your creations as follows.",
{
"type": "entries",
"entries": [
{
"type": "entries",
"name": "Demiplane Design",
"entries": [
"With a single keystone, you can conjure an extradimensional space in the shape of a dome or pyramid, up to 1 mile on each side, with whatever terrain you wish. The edges of this space are solid and impassable but may be {@condition invisible}, so that your space can appear to go on forever if you wish. With additional keystones, you can add an additional square mile to your creation, adding or combining terrain types.",
"Each square mile can include a single structure up to the size of {@spell Mordenkainen's magnificent mansion}, with all the attendant servants and feasts, or any number of smaller structures that together equal a magnificent mansion in total area.",
"You cannot create flora or fauna that deal damage or otherwise harm creatures. If you create cliffs, waters, or other natural features, however, creatures in the space might fall, drown, or otherwise be harmed."
]
},
{
"type": "entries",
"name": "Physical Structure Design",
"entries": [
"With a single keystone, you can create a structure akin to a mighty fortress with the added flexibility to customize that fortress not only for cosmetic details but for substantive effects. With a single keystone, you can rearrange the walls, open spaces, floors, doors, and other features of the mighty fortress provided you do not exceed 150 10-foot-wide cubes of interior space. Your fortress can reach across a creek, river, or other waters. It can take reckless or even dangerous shapes to include deep pits, flooded chambers, and so on. Your design, once conjured, is not guaranteed to be capable of supporting its own weight or surviving the stresses of attacks, weather, or time.",
"You may post any number of your structure's servants as guards, if you wish. They have statistics identical to an unseen servant with the addition of a passive {@skill Perception} score equal to your own, though you cannot perceive through their senses. The servants can ring bells or otherwise manipulate the environment to raise an alarm."
]
},
{
"type": "entries",
"name": "Manifestations",
"entries": [
"Each place or structure conjured by a keystone of creation has special, unforeseen manifestations that affect its makeup and appearance. Some manifestations are beneficial, some are detrimental. These manifestations change each time a keystone is used to conjure a structure or place. The DM may roll or choose from the following tables, or devise unique manifestations based on the specific creations being conjured."
]
}
]
}
]
}
]
}
]
},
{
"name": "Khrusor, Spear of Heliod",
"source": "MOT",
"page": 200,
"baseItem": "spear|PHB",
"type": "M",
"rarity": "artifact",
"reqAttune": true,
"weight": 3,
"weaponCategory": "simple",
"property": [
"T",
"V"
],
"range": "20/60",
"dmg1": "1d6",
"dmgType": "P",
"dmg2": "1d8",
"bonusWeapon": "+3",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 4}",
"charges": 10,
"weapon": true,
"entries": [
"Heliod wields Khrusor, a spear blessed with the power of the sun. In his hands, it can be pitched from Nyx to any point on Theros. When Heliod lends his weapon to a mortal, it's typically so they can perform some great work in his name or as a test of their worthiness.",
{
"type": "entries",
"name": "Spear of the Sun",
"entries": [
"This spear grants a +3 bonus to attack and damage rolls made with it. When you hit with an attack using this spear, the target takes an extra {@damage 2d8} radiant damage."
]
},
{
"type": "entries",
"name": "Blessing of the Sun",
"entries": [
"If you are a worshiper of Heliod, you gain all the following benefits for which you have the required piety:",
{
"type": "list",
"style": "list-hang-notitle",
"items": [
{
"type": "item",
"name": "Piety 3+",
"entry": "You gain 15 temporary hit points each dawn."
},
{
"type": "item",
"name": "Piety 10+",
"entry": "The spear has 1 randomly determined {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} property."
},
{
"type": "item",
"name": "Piety 25+",
"entry": "The spear has 1 additional randomly determined {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} property."
},
{
"type": "item",
"name": "Piety 50+",
"entry": "The spear has 1 randomly determined {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial} property."
}
]
},
"If you aren't a worshiper of Heliod, the spear has 2 randomly determined {@table Artifact Properties; Major Detrimental Properties|dmg|major detrimental} properties.",
"See \"Artifacts\" in chapter 7 of the Dungeon Master's Guide for details on randomly determined properties."
]
},
{
"type": "entries",
"name": "Luminous",
"entries": [
"The spear sheds bright light in a 30-foot radius and dim light for an additional 30 feet. The light is sunlight."
]
},
{
"type": "entries",
"name": "Sun's Retaliation",
"entries": [
"When you take damage from a creature within 5 feet of you, you can use your reaction to make a melee attack with the spear against that creature. On a hit, the spear deals damage as normal, and the creature is {@condition blinded} until the start of its next turn. This property of the spear can't be used again until the next dawn."
]
},
{
"type": "entries",
"name": "Spells",
"entries": [
"The spear has 10 charges. While holding it, you can use an action to expend 1 or more of its charges to cast one of the following spells (save DC 18) from it: {@spell guiding bolt} (1 charge), {@spell daylight} (3 charges, targeting the tip of the spear only), {@spell sunbeam} (6 charges). The spear regains {@dice 1d6 + 4} expended charges daily at dawn."
]
},
{
"type": "entries",
"name": "Destroying the Spear",
"entries": [
"If taken to Erebos's palace in Tizerus, and used to sacrifice a champion of Heliod to Erebos, Khrusor is either destroyed or fundamentally twisted to Erebos's service."
]
}
],
"attachedSpells": [
"guiding bolt",
"sunbeam",
"daylight"
],
"hasFluffImages": true
},
{
"name": "Knave's Eye Patch",
"source": "WDH",
"page": 191,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"While wearing this eye patch, you gain these benefits:",
{
"type": "list",
"items": [
"You have advantage on Wisdom ({@skill Perception}) checks that rely on sight.",
"If you have the Sunlight Sensitivity trait, you are unaffected by the trait.",
"You are immune to magic that allows other creatures to read your thoughts or determine whether you are lying. Creatures can communicate telepathically with you only if you allow it."
]
}
]
},
{
"name": "Korolnor Scepter",
"source": "SKT",
"page": 234,
"baseItem": "club|phb",
"type": "M",
"rarity": "legendary",
"reqAttune": true,
"weight": 2,
"weaponCategory": "simple",
"property": [
"L"
],
"dmg1": "1d4",
"dmgType": "B",
"bonusWeapon": "+3",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 4}",
"charges": 10,
"entries": [
"The Korolnor Scepter is one of ten Ruling Scepters of Shanatar, forged by the dwarven gods and given to the ruling houses of the ancient dwarven empire. The Korolnor Scepter's location was unknown for the longest time until a {@creature storm giant} queen, Neri, found it in a barnacle-covered shipwreck at the bottom of the Trackless Sea. The Ruling Scepters are all roughly the same size and shape, but their materials and properties vary.",
"The Korolnor Scepter is a tapered mithral rod as thick and long as a dwarf's forearm, with a small platinum knob at the bottom and a rounded disk adorned with a ring of seven tiny blue gems at the top.",
"You gain a +3 bonus to attack and damage rolls made with this scepter, which can be wielded as a magic club.",
"You can use the properties of the {@item Wyrmskull Throne|SKT}, as well as the properties of the scepter itself. The scepter has 10 charges, and it regains {@dice 1d6 + 4} expanded charges at dawn. Its properties are as follows:",
{
"type": "list",
"items": [
"If you are underground or underwater, you can use an action to expend 1 charge to determine the distance to the surface.",
"As an action: you can expend 2 charges to cast the {@spell sending} spell from the scepter.",
"As an action: you can expend 3 charges to cast the {@spell teleport} spell from the scepter. If the destination is within 60 feet of the {@item Wyrmskull Throne|SKT}, there is no chance of a teleport error or mishap occurring."
]
}
],
"attachedSpells": [
"sending",
"teleport"
]
},
{
"name": "Kyrzin's Ooze",
"source": "ERLW",
"page": 278,
"resist": [
"acid",
"poison"
],
"conditionImmune": [
"poisoned"
],
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"This opalescent, symbiotic goo comes sealed in a jar and slowly shifts and moves, as if endlessly exploring the jar's interior. To attune to this item, you must first drink the contents of the jar, unlocking the following properties.",
{
"type": "entries",
"name": "Resistant",
"entries": [
"While attuned to Kyrzin's ooze, you have resistance to poison and acid damage, and you're immune to the {@condition poisoned} condition."
]
},
{
"type": "entries",
"name": "Amorphous",
"entries": [
"As an action, you can speak a command word and cause your body to assume the amorphous qualities of an ooze. For the next minute, you (along with any equipment you're wearing or carrying) can move through a space as narrow as 1 inch wide without squeezing. Once you use this property, it can't be used again until the next dawn."
]
},
{
"type": "entries",
"name": "Acid Breath",
"entries": [
"As an action, you can exhale acid in a 30-foot line that is 5 feet wide. Each creature in that line must make a DC 15 Dexterity saving throw, taking 36 ({@damage 8d8}) acid damage on a failed save, or half as much damage on a successful one. Once you use this property, it can't be used again until the next dawn."
]
},
{
"type": "entries",
"name": "Symbiotic Nature",
"entries": [
"The ooze can't be removed from you while you're attuned to it, and you can't voluntarily end your attunement to it. If you're targeted by a spell that ends a curse, your attunement to the ooze ends, as it seeps out of you.",
"If you die while the ooze is inside you, it bursts out and engulfs you, turning your corpse into a {@creature black pudding} allied with the daelkyr."
]
}
]
},
{
"name": "Ladder (10-foot)",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 25,
"value": 10,
"entries": [
"Ladder (10-foot)"
]
},
{
"name": "Lamp",
"source": "PHB",
"page": 152,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 1,
"value": 50,
"entries": [
"A lamp casts bright light in a 15-foot radius and dim light for an additional 30 feet. Once lit, it burns for 6 hours on a flask (1 pint) of oil."
]
},
{
"name": "Lamprey Ship",
"source": "AAG",
"page": 0,
"type": "SPC",
"rarity": "none",
"value": 2000000,
"crew": 15,
"vehAc": 15,
"vehHp": 250,
"vehDmgThresh": 15,
"vehSpeed": 4,
"capCargo": 6,
"entries": [
"Lamprey ships are antiquated, being among the oldest spelljamming ships still in use. Psurlons (see Boo's Astral Menagerie) are particularly fond of them.",
"Using the metal grappling jaws built into its bow, a lamprey ship can attach itself to another ship, which is a critical feature during boarding actions. Other standard weapons include four ballistae on the main deck.",
"A lamprey can float on water, though it can't land safely on the ground. Lamprey ships that land on the ground have the distressing habit of rolling over, as more than a few crews have discovered to their dismay."
],
"seeAlsoVehicle": [
"Lamprey Ship|AAG"
]
},
{
"name": "Lantern of Revealing",
"source": "DMG",
"page": 179,
"srd": true,
"tier": "minor",
"rarity": "uncommon",
"wondrous": true,
"weight": 2,
"entries": [
"While lit, this {@item hooded lantern|phb} burns for 6 hours on 1 pint of oil, shedding bright light in a 30-foot radius and dim light for an additional 30 feet. {@condition Invisible} creatures and objects are visible as long as they are in the lantern's bright light. You can use an action to lower the hood, reducing the light to dim light in a 5-foot radius."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table B"
],
"hasFluffImages": true
},
{
"name": "Lantern of Tracking",
"source": "IDRotF",
"page": 314,
"rarity": "common",
"wondrous": true,
"entries": [
"This hooded lantern burns for 6 hours on 1 pint of oil, shedding bright light in a 30-foot radius and dim light for an additional 30 feet.",
"Each lantern of tracking is designed to track down a certain type of creature, which is determined by rolling on the Lantern of Tracking table. Once determined, this creature type can't be changed. While the lantern is within 300 feet of any creature of that type, its flame turns bright green. The lantern doesn't pinpoint the creature's exact location, however.",
{
"type": "table",
"caption": "Lantern of Tracking",
"colLabels": [
"1d10",
"Creature Type"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"1",
"Aberrations"
],
[
"2",
"Celestials"
],
[
"3",
"Constructs"
],
[
"4",
"Dragons"
],
[
"5",
"Elementals"
],
[
"6",
"Fey"
],
[
"7",
"Fiends"
],
[
"8",
"Giants"
],
[
"9",
"Monstrosities"
],
[
"10",
"Undead"
]
]
}
]
},
{
"name": "Lapis Lazuli",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 1000,
"entries": [
"An opaque light and dark blue with yellow flecks gemstone."
]
},
{
"name": "Large gold bracelet",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 25000
},
{
"name": "Large gold bracelet (Legion of Dusk)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 25000
},
{
"name": "Large jade totem (River Heralds)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 75000
},
{
"name": "Large well-made tapestry",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 25000
},
{
"name": "Large well-made tapestry (Legion of Dusk)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 25000
},
{
"name": "Lash of Shadows (Awakened)",
"source": "EGW",
"_copy": {
"name": "Lash of Shadows (Dormant)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Awakened",
"entries": [
"When the whip reaches an awakened state, it gains the following properties:",
{
"type": "list",
"items": [
"The weapon's bonus to attack and damage rolls increases to +2.",
"The saving throw DC for the weapon's poisons increases to 15.",
"The weapon gains a new poison option called Ghoul's Blood. A creature that fails the saving throw against this poison is {@condition poisoned} for 1 minute. While {@condition poisoned} in this way, the creature is {@condition paralyzed}. The creature can repeat the saving throw at the end of each of its turns, ending the effect on itself on a success."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"bonusWeapon": "+2",
"hasFluffImages": true
},
{
"name": "Lash of Shadows (Dormant)",
"source": "EGW",
"page": 276,
"baseItem": "whip|PHB",
"type": "M",
"rarity": "artifact",
"reqAttune": true,
"sentient": true,
"weight": 3,
"weaponCategory": "martial",
"property": [
"F",
"R"
],
"dmg1": "1d4",
"dmgType": "S",
"bonusWeapon": "+1",
"weapon": true,
"entries": [
"This snakeskin whip is touched by the power of Zehir and ends in five animated serpent heads.",
{
"type": "entries",
"name": "Sentience",
"entries": [
"The Lash of Shadows is a sentient chaotic evil weapon with an Intelligence of 18, a Wisdom of 16, and a Charisma of 20. It has hearing and {@sense darkvision} out to a range of 120 feet.",
"The weapon communicates telepathically with its wielder and can speak, read, and understand Abyssal, Common, and Draconic."
]
},
{
"type": "entries",
"name": "Personality",
"entries": [
"A mad {@creature marilith} named Sizlifeth lives within the Lash of Shadows. The weapon has a short temper and experiences only extreme emotions. It is most happy when dealing harm."
]
},
{
"type": "entries",
"name": "Dormant",
"entries": [
"The whip grants the following benefits in its dormant state:",
{
"type": "list",
"items": [
"You can speak, read, and write Abyssal and Draconic.",
"You gain a +1 bonus to attack and damage rolls made with this magic weapon.",
"You can attempt to poison any creature that is hit by the whip. The creature must succeed on a DC 13 Constitution saving throw or suffer the effect of one of the following poisons of your choice; once a poison other than Serpent Venom has been used, that poison can't be used again until the next dawn:",
{
"type": "list",
"style": "list-hang-notitle",
"items": [
{
"type": "item",
"name": "Dead Eyes",
"entry": "A creature that fails the saving throw against this poison is {@condition poisoned} for 1 hour. While {@condition poisoned} in this way, the creature is {@condition blinded}."
},
{
"type": "item",
"name": "Serpent Venom",
"entry": "A creature that fails the saving throw against this poison takes {@damage 3d6} poison damage, or half as much damage on a successful save."
}
]
}
]
}
]
},
{
"type": "inset",
"name": "Betrayer Artifact Properties",
"entries": [
"The Arms of the Betrayers advance in power in the same manner as the Vestiges of Divergence. In its dormant state, each of these artifacts has one {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} property and one {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental} property. When the artifact attains an awakened state, it gains an additional {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} property and an additional {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental} property. When the item reaches its exalted state, it gains a {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial} property. See \"Artifact Properties\" in chapter 7 of the Dungeon Master's Guide for more information."
]
}
],
"hasFluffImages": true
},
{
"name": "Lash of Shadows (Exalted)",
"source": "EGW",
"_copy": {
"name": "Lash of Shadows (Awakened)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Exalted",
"entries": [
"When the whip reaches an exalted state, it gains the following additional properties:",
{
"type": "list",
"items": [
"The weapon's bonus to attack and damage rolls increases to +3.",
"The saving throw DC for the weapon's poisons increases to 17.",
"The weapon gains a new poison option called Cockatrice Tears. A creature that fails the saving throw against this poison begins to turn to stone and is {@condition restrained}. It must repeat the saving throw at the end of its next turn. On a success, the effect ends. On a failure, the creature is {@condition petrified} for 24 hours."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"bonusWeapon": "+3",
"hasFluffImages": true
},
{
"name": "Leather Golem Armor",
"source": "LLK",
"page": 55,
"baseItem": "leather armor|phb",
"type": "LA",
"resist": [
"lightning"
],
"rarity": "rare",
"reqAttune": true,
"curse": true,
"weight": 10,
"ac": 11,
"bonusAc": "+1",
"bonusSavingThrow": "+1",
"entries": [
"Strange rituals have repurposed the body of a flesh golem into this partially sentient suit of leather armor. While wearing this armor, you gain a +1 bonus to AC and to saving throws against spells and other magical effects. In addition, you gain the following properties:",
{
"type": "list",
"items": [
{
"type": "entries",
"name": "Immutable Form",
"entries": [
"You are immune to any spell or effect that would alter your form."
]
},
{
"type": "entries",
"name": "Lightning Absorption",
"entries": [
"You gain resistance to lightning damage. Whenever you take lightning damage, you gain 5 temporary hit points."
]
}
]
},
{
"type": "entries",
"name": "Curse",
"entries": [
"This armor is cursed, and becoming attuned to it extends the curse to you. Until the curse is broken with a {@spell remove curse} spell or similar magic, you are unwilling to part with the armor. In addition, while you wear the cursed armor, you gain the following properties:",
{
"type": "list",
"items": [
{
"type": "entries",
"name": "Aversion of Fire",
"entries": [
"If you take fire damage, you have disadvantage on attack rolls and ability checks until the end of your next turn."
]
},
{
"type": "entries",
"name": "Berserk",
"entries": [
"Whenever a critical hit is made against you, roll a {@dice d6}. On a 6, the armor causes you to go berserk. On each of your turns while berserk, you attack the nearest creature you can see. If no creature is near enough to move to and attack, you attack an object, with preference for an object smaller than yourself. Once the armor causes you to go berserk, it cannot be removed. You continue to attack until you are {@condition incapacitated} or until another creature is able to calm you with appropriate magic (such as a {@spell calm emotions} spell) or a successful DC 15 Charisma ({@skill Persuasion}) check."
]
}
]
}
]
}
]
},
{
"name": "Leatherworker's Tools",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"additionalSources": [
{
"source": "XGE",
"page": 82
}
],
"type": "AT",
"rarity": "none",
"weight": 5,
"value": 500,
"additionalEntries": [
"Knowledge of leatherworking extends to lore concerning animal hides and their properties. It also confers knowledge of leather armor and similar goods.",
{
"type": "entries",
"name": "Components",
"entries": [
"Leatherworker's tools include a knife, a small mallet, an edger, a hole punch, thread, and leather scraps."
]
},
{
"type": "entries",
"name": "Arcana",
"entries": [
"Your expertise in working with leather grants you added insight when you inspect magic items crafted from leather, such as boots and some cloaks."
]
},
{
"type": "entries",
"name": "Investigation",
"entries": [
"You gain added insight when studying leather items or clues related to them, as you draw on your knowledge of leather to pick out details that others would overlook."
]
},
{
"type": "entries",
"name": "Identify Hides",
"entries": [
"When looking at a hide or a leather item, you can determine the source of the leather and any special techniques used to treat it. For example, you can spot the difference between leather crafted using dwarven methods and leather crafted using halfling methods."
]
},
{
"type": "table",
"caption": "Leatherworker's Tools",
"colLabels": [
"Activity",
"DC"
],
"colStyles": [
"col-10",
"col-2 text-center"
],
"rows": [
[
"Modify a leather item's appearance",
"10"
],
[
"Determine a leather item's history",
"20"
]
]
}
]
},
{
"name": "Lesser Hammock of Worlds",
"source": "JttRC",
"page": 0,
"rarity": "legendary",
"wondrous": true,
"entries": [
"The Hammock of Worlds is a colorful hammock woven with traditional Ataguan designs.",
"You can use an action to unfold and place the lesser version of the Hammock of Worlds on a solid surface, whereupon it creates a two-way portal to another world or plane of existence. Each time the item opens a portal, the DM decides where it leads or the user can cause it to always reliably connect to the Ghost Orchid Tepui. You can use an action to close an open portal by taking hold of the edges of the cloth and folding it up. Once the lesser version of the Hammock of Worlds has opened a portal, it can't do so again for {@dice 1d8} hours."
],
"hasFluffImages": true
},
{
"name": "Libram of Souls and Flesh",
"source": "TCE",
"page": 129,
"rarity": "rare",
"reqAttune": "by a wizard",
"reqAttuneTags": [
{
"class": "wizard"
}
],
"wondrous": true,
"weight": 3,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"focus": [
"Wizard"
],
"entries": [
"With covers made of skin and fittings of bone, this tome is cold to the touch, and it whispers faintly. When found, the book contains the following spells, which are wizard spells for you while you are attuned to the book: {@spell animate dead}, {@spell circle of death}, {@spell false life}, {@spell finger of death}, {@spell speak with dead}, {@spell summon undead|TCE}, and {@spell vampiric touch}. It functions as a spellbook for you.",
"While you are holding the book, you can use it as a spellcasting focus for your wizard spells.",
"The book has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. You can use the charges in the following ways while holding it:",
{
"type": "list",
"items": [
"If you spend 1 minute studying the book, you can expend 1 charge to replace one of your prepared wizard spells with a different spell in the book. The new spell must be of the necromancy school.",
"As an action, you can expend 1 charge to take on a semblance of undeath for 10 minutes. For the duration, you take on a deathly appearance, and undead creatures are indifferent to you, unless you have damaged them. You also appear undead to all outward inspection and to spells used to determine the target's status. The effect ends if you deal damage or force a creature to make a saving throw."
]
}
],
"attachedSpells": [
"animate dead",
"circle of death",
"false life",
"finger of death",
"speak with dead",
"summon undead|tce",
"vampiric touch"
]
},
{
"name": "Lifewell Tattoo",
"source": "TCE",
"page": 129,
"resist": [
"necrotic"
],
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"entries": [
"Produced by a special needle, this magic tattoo features symbols of life and rebirth.",
{
"type": "entries",
"name": "Tattoo Attunement",
"entries": [
"To attune to this item, you hold the needle to your skin where you want the tattoo to appear, pressing the needle there throughout the attunement process. When the attunement is complete, the needle turns into the ink that becomes the tattoo, which appears on the skin.",
"If your attunement to the tattoo ends, the tattoo vanishes, and the needle reappears in your space."
]
},
{
"type": "entries",
"name": "Necrotic Resistance",
"entries": [
"You have resistance to necrotic damage."
]
},
{
"type": "entries",
"name": "Life Ward",
"entries": [
"When you would be reduced to 0 hit points, you drop to 1 hit point instead. Once used, this property can't be used again until the next dawn."
]
}
]
},
{
"name": "Lifewell Tattoo",
"source": "UA2020SpellsAndMagicTattoos",
"page": 10,
"resist": [
"necrotic"
],
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"entries": [
"This tattoo comprises symbols of life and rebirth. While this tattoo is on your skin, you have resistance to necrotic damage.",
{
"type": "entries",
"name": "Death Ward",
"entries": [
"When you would be reduced to 0 hit points, you drop to 1 hit point instead. Once used, this benefit can't be used again until the next dawn."
]
},
{
"type": "entries",
"name": "Tattoo Attunement",
"entries": [
"To attune to this item, you hold the needle to your skin where you want the tattoo to appear, pressing the needle there throughout the attunement process. When the attunement is complete, the needle turns into the ink that becomes the tattoo, which appears on the skin. If you have multiple magic tattoos, they count as a single magic item with regard to the number of magic items you can attune to.",
"If your attunement to the tattoo ends, the tattoo vanishes, and the needle reappears in the closest unoccupied space to you."
]
}
]
},
{
"name": "Lightbringer",
"source": "LMoP",
"page": 48,
"baseItem": "mace|phb",
"type": "M",
"rarity": "uncommon",
"weight": 4,
"weaponCategory": "simple",
"dmg1": "1d6",
"dmgType": "B",
"bonusWeapon": "+1",
"entries": [
"This +1 mace was made for a cleric of Lathander, the god of dawn. The head of the mace is shaped like a sunburst and made of solid brass. Named Lightbringer, this weapon glows as bright as a {@item torch|phb} when its wielder commands. While glowing, the mace deals an extra {@damage 1d6} radiant damage to undead creatures."
]
},
{
"name": "Lightning Absorbing Tattoo",
"source": "TCE",
"page": 119,
"resist": [
"lightning"
],
"detail1": "yellow",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"hasRefs": true,
"entries": [
"{#itemEntry Absorbing Tattoo|TCE}"
]
},
{
"name": "Linen (1 sq. yd.)",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TG",
"rarity": "none",
"value": 500
},
{
"name": "Living Gloves",
"source": "ERLW",
"page": 278,
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"grantsProficiency": true,
"entries": [
"These symbiotic gloves—made of thin chitin and sinew—pulse with a life of their own. To attune to them, you must wear them for the entire attunement period, during which the gloves bond with your skin.",
"While attuned to these gloves, you gain one of the following proficiencies (your choice when you attune to the gloves):",
{
"type": "list",
"items": [
"{@skill Sleight of Hand}",
"{@item Thieves' tools|PHB}",
"One kind of {@item artisan's tools|PHB} of your choice",
"One kind of {@item musical instrument|PHB} of your choice"
]
},
"When you make an ability check using the chosen proficiency, you add double your proficiency bonus to the check, instead of your normal proficiency bonus.",
{
"type": "entries",
"name": "Symbiotic Nature",
"entries": [
"The gloves can't be removed from you while you're attuned to them, and you can't voluntarily end your attunement to them. If you're targeted by a spell that ends a curse, your attunement to the gloves ends, and they can be removed."
]
}
]
},
{
"name": "Living Loot Satchel",
"source": "AI",
"page": 25,
"rarity": "varies",
"wondrous": true,
"entries": [
"As a rank 2 hoardsperson, you are granted the use of a living loot satchel, which is an uncommon magic item. It functions as a {@item bag of holding} and is available in a variety of colors and styles.",
{
"type": "table",
"caption": "Class-Based Living Loot Satchel",
"colStyles": [
"col-2",
"col-10"
],
"colLabels": [
"Class",
"Satchel"
],
"rows": [
[
"Barbarian",
"Broad belt with a dozen hanging pockets"
],
[
"Bard",
"Lute case"
],
[
"Cleric",
"Hollowed-out holy tome"
],
[
"Druid",
"Made from natural, organic, locally sourced woven fibers"
],
[
"Fighter",
"A thick, battle-scarred iron lockbox"
],
[
"Paladin",
"A metal case with fine engraving and scrollwork"
],
[
"Ranger",
"A fur-lined bindle"
],
[
"Rogue",
"A nondescript coin pouch"
],
[
"Sorcerer",
"A battered leather satchel, prone to spitting out multicolored sparks at the seams"
],
[
"Warlock",
"A patchwork monster-leather satchel with a \"purely decorative\" fanged mouth."
],
[
"Wizard",
"A pocket dimension hidden up your sleeve or inside your hat"
]
]
},
"The living loot satchel is a kind of magical being that safeguards the franchise's funds and valuables. Its innards are connected to a secure coffer within Head Office's vault in Waterdeep, to which the satchel periodically transfers the franchise's wealth. As an action, you can transfer any amount of your franchise funds back to your satchel with a successful DC 15 Dexterity ({@skill Sleight of Hand}) check.",
{
"type": "entries",
"name": "Secret Satchel",
"entries": [
"As a rank 3 hoardsperson, your living loot satchel gets an upgrade to function as the replica chest used for the {@spell Leomund's secret chest} spell, becoming a rare magic item. You can open the secret chest through your living loot satchel to deposit or withdraw items—even items that wouldn't normally fit in your satchel, but which fit within the chest. Thanks to Head Office striking deals you don't want to know about with extraplanar creatures you really don't want to know about, there is no chance for the spell to end."
]
},
{
"type": "entries",
"name": "That Thing You Need",
"entries": [
"At rank 3, you can use a bonus action to reach into your living loot satchel and make a DC 15 Dexterity ({@skill Sleight of Hand}) check. On a success, you draw forth an item of your choice on the Adventuring Gear table in chapter 5 of the Player's Handbook. The item must be of a size that can fit into your secret chest and be worth no more than 15 gp. Once you attempt to draw five items from your satchel, you cannot draw forth any more items until the next dawn."
]
},
{
"type": "entries",
"name": "Portable Hole Satchel",
"entries": [
"At rank 4, your living loot satchel receives another upgrade, becoming a very rare magic item. The secret chest accessed by your satchel now has the storage capacity of a {@item portable hole}—6 feet in diameter and 10 feet deep. As before, you can place any appropriately sized object into the portable-hole-sized chest, even if it wouldn't normally fit into your satchel."
]
},
{
"type": "entries",
"name": "That Expensive Thing You Need",
"entries": [
"Also at rank 4, when you use your That Thing You Need feature, you can requisition any item of up to 250 gp in value, as long as it would fit into the confines of your satchel's portable hole."
]
}
],
"attachedSpells": [
"Leomund's secret chest"
],
"hasFluffImages": true
},
{
"name": "Living Ship",
"source": "AAG",
"page": 0,
"type": "SPC",
"rarity": "none",
"value": 2500000,
"crew": 5,
"vehAc": 15,
"vehHp": 250,
"vehDmgThresh": 15,
"vehSpeed": 4.5,
"capCargo": 10,
"entries": [
"Druids, rangers, and clerics devoted to gods of nature are often found aboard living ships, as are Wildspace explorers who don't want to have to worry about their ship's air envelope becoming fouled during a long voyage.",
"This ship's main distinctive feature is the fully grown {@creature treant} on the aft deck. The treant has a speed of 0 because its roots are woven into the deck; it and the ship are inseparable. If the ship is reduced to 0 hit points, the treant dies of shock. The ship can function if its treant dies, but the treant can never be replaced with another.",
"When the treant finishes a long rest, it repairs the ship's hull, enabling the ship to regain {@dice 4d12} hit points, and refreshes the ship's air envelope (turning deadly air into foul air, or foul air into fresh air).",
"A living ship can float on water and sail across it, but it can't land safely on the ground (its keel would cause it to roll on its side). Its standard weaponry is an aft-mounted ballista."
],
"seeAlsoVehicle": [
"Living Ship|AAG"
]
},
{
"name": "Loadstone",
"source": "TftYP",
"page": 228,
"rarity": "rare",
"wondrous": true,
"curse": true,
"weight": 1,
"entries": [
"This stone is a large gem worth 150 gp.",
{
"type": "entries",
"name": "Curse",
"entries": [
"The stone is cursed, but its magical nature is hidden; {@spell detect magic} doesn't detect it. An {@spell identify} spell reveals the stone's true nature. If you use the {@action Dash} or {@action Disengage} action while the stone is on your person, its curse activates. Until the curse is broken with {@spell remove curse} or similar magic, your speed is reduced by 5 feet, and your maximum load and maximum lift capacities are halved. You also become unwilling to part with the stone."
]
}
]
},
{
"name": "Loaf of Bread",
"source": "PHB",
"page": 158,
"srd": true,
"basicRules": true,
"type": "FD",
"rarity": "none",
"value": 2
},
{
"name": "Lock",
"source": "PHB",
"page": 152,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 1,
"value": 1000,
"entries": [
"A key is provided with the lock. Without the key, a creature proficient with {@item thieves' tools|phb} can pick this lock with a successful DC 15 Dexterity check. Your DM may decide that better locks are available for higher prices."
]
},
{
"name": "Lock of Trickery",
"source": "XGE",
"page": 138,
"tier": "minor",
"rarity": "common",
"wondrous": true,
"entries": [
"This lock appears to be an ordinary lock (of the type described in chapter 5 of the Player's Handbook) and comes with a single key. The tumblers in this lock magically adjust to thwart burglars. Dexterity checks made to pick the lock have disadvantage."
]
},
{
"name": "Longhorn",
"source": "SCAG",
"page": 124,
"type": "INS",
"rarity": "none",
"entries": [
"A Faerûnian flute of sophisticated make, found only in areas with skilled artisans, as in great cities or elven enclaves."
]
},
{
"name": "Longship",
"source": "DMG",
"page": 119,
"srd": true,
"basicRules": true,
"type": "SHP",
"rarity": "none",
"value": 1000000,
"crew": 40,
"vehAc": 15,
"vehHp": 300,
"vehDmgThresh": 15,
"vehSpeed": 3,
"capPassenger": 150,
"capCargo": 10,
"seeAlsoVehicle": [
"Longship"
]
},
{
"name": "Lord's Ensemble",
"source": "WDH",
"page": 191,
"rarity": "very rare",
"reqAttune": "by a creature with a humanoid build",
"reqAttuneTags": [
{
"creatureType": "humanoid"
}
],
"wondrous": true,
"entries": [
"The Masked Lords of Waterdeep don this ensemble when meeting with one another. This raiment renders each lord indistinguishable from the others. The ensemble consists of three pieces—a helm, an amulet, and a robe—that function as a single magic item when worn together, but only within the city of Waterdeep and its sewers. You become attuned to the ensemble as a single item.",
{
"type": "entries",
"name": "Lord's Helm",
"entries": [
"This bucket helm covers your head and conceals your face. Screens over the eyes help to shroud your identity without blinding you. While you wear the helm, your voice is magically altered to sound genderless, and you are immune to magic that allows other creatures to read your thoughts, to determine whether you are lying, to know your alignment, or to know your creature type. Creatures can communicate telepathically with you only if you allow it."
]
},
{
"type": "entries",
"name": "Lord's Amulet",
"entries": [
"This amulet bears the crest of Waterdeep. It functions as an {@item amulet of proof against detection and location}."
]
},
{
"type": "entries",
"name": "Lord's Robe",
"entries": [
"This elegant robe functions as a {@item ring of free action}, and it creates the illusion that you have a nondescript, androgynous humanoid build and stand 6 feet tall."
]
}
],
"hasFluffImages": true
},
{
"name": "Lorehold Primer",
"source": "SCC",
"page": 39,
"rarity": "uncommon",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"The Lorehold Primer is a magic textbook created at Strixhaven's Lorehold College. The primer has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. If you make an Intelligence ({@skill History}) or Intelligence ({@skill Religion}) check while holding the primer, you can expend 1 charge to give yourself {@dice 1d4} bonus to the check, immediately after you roll the {@dice d20}.",
"In addition, if you study the primer at the end of a long rest, you can choose one 1st-level spell from the cleric or wizard spell list. Before you finish your next long rest, you can cast the chosen spell once without a spell slot if you are holding the primer. Your spellcasting ability for this spell is your choice of Intelligence, Wisdom, or Charisma."
]
},
{
"name": "Lost Crown of Besilmer",
"source": "PotA",
"page": 223,
"resist": [
"psychic"
],
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"This dwarven battle-helm consists of a sturdy open-faced steel helmet, decorated with a golden circlet above the brow from which seven small gold spikes project upward. You gain the following benefits while wearing the crown:",
{
"type": "list",
"items": [
"You have resistance to psychic damage.",
"You have advantage on saving throws against effects that would charm you.",
"You can use a bonus action to inspire one creature you can see that is within 60 feet of you and that can see or hear you. Once before the end of your next turn, the inspired creature can roll a {@dice d6} and add the number rolled to one ability check, attack roll, or saving throw it makes. This uses 1 charge from the crown. It has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn."
]
}
]
},
{
"name": "Lost Sword",
"source": "CoS",
"page": 81,
"baseItem": "shortsword|phb",
"type": "M",
"rarity": "unknown (magic)",
"reqAttune": true,
"sentient": true,
"weight": 2,
"weaponCategory": "martial",
"property": [
"F",
"L"
],
"dmg1": "1d6",
"dmgType": "P",
"bonusWeapon": "+1",
"entries": [
"The Lost Sword is a sentient lawful good {@item +1 shortsword} (Intelligence 11, Wisdom 13, Charisma 13). It has hearing and normal vision out to a range of 120 feet. It communicates by transmitting emotion to the creature carrying or wielding it.",
"The sword's purpose is to fight evil. The sword has the following additional properties:",
{
"type": "list",
"items": [
"The sword continually sheds bright light in a 15-foot radius and dim light for an additional 15 feet. Only by destroying the sword can this light be extinguished.",
"A lawful good creature can attune itself to the sword in 1 minute.",
"While attuned to the weapon, the sword's wielder can use the sword to cast the {@spell crusader's mantle} spell. Once used, this property of the sword can't be used again until the next dawn."
]
}
],
"attachedSpells": [
"crusader's mantle"
]
},
{
"name": "Luba's Tarokka of Souls",
"source": "TCE",
"page": 129,
"rarity": "artifact",
"reqAttune": true,
"wondrous": true,
"entries": [
"Not all lingering spirits are tragic souls, lost on their way to the hereafter. Some languish as prisoners, souls so wicked mortals dare not free them upon an unsuspecting afterlife.",
"Created by a figure of Vistani legend, Luba's Tarokka of Souls shaped the destiny of countless heroes. The prophecies of this deck of cards also revealed great evils and guided its creator into the path of nefarious forces. Untold times the deck's creator, Mother Luba, narrowly escaped doom, spared only by her keen insights. But even for her, not all wickedness could be escaped. In the most dire cases, Mother Luba managed to ensnare beings of pure evil amid the strands of fate, imprisoning them within her tarroka deck. There these foul spirits dwell still, trapped within a nether-realm hidden amid shuffling cards, waiting for fate to turn foul—as it inevitably will.",
"Like all tarokka decks, the {@i Tarokka of Souls} is a lavishly illustrated collection of fifty-four cards, comprising the fourteen cards of the high deck and forty other cards divided into four suits: coins, glyphs, stars, and swords.",
{
"type": "entries",
"name": "Random Properties",
"entries": [
"The artifact has the following random properties, which you can determine by rolling on the tables in the \"Artifacts\" section of the {@book Dungeon Master's Guide|DMG}:",
{
"type": "list",
"items": [
"2 {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental} properties",
"2 {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} properties"
]
}
]
},
{
"type": "entries",
"name": "Spells",
"entries": [
"While holding the deck, you can use an action to cast one of the following spells (save DC 18) from it: {@spell comprehend languages}, {@spell detect evil and good}, {@spell detect magic}, {@spell detect poison and disease}, {@spell locate object}, or {@spell scrying}. Once you use the deck to cast a spell, you can't cast that spell again from it until the next dawn."
]
},
{
"type": "entries",
"name": "Enduring Vision",
"entries": [
"While holding the deck, you automatically succeed on Constitution saving throws made to maintain your concentration on divination spells."
]
},
{
"type": "entries",
"name": "Twist of Fate",
"entries": [
"As an action, you can draw a card from the deck and twist the fortune of another creature you can see within 15 feet of you. Choose one of the following effects:",
{
"type": "list",
"style": "list-hang-notitle",
"items": [
{
"type": "item",
"name": "Weal",
"entry": "The creature has advantage on attack rolls, ability checks, and saving throws for the next hour."
},
{
"type": "item",
"name": "Woe",
"entry": "The creature has disadvantage on attack rolls, ability checks, and saving throws for the next hour."
},
"The deck can be used in this way twice, and you regain all expended uses at the next dawn."
]
}
]
},
{
"type": "entries",
"name": "Prisoners of Fate",
"entries": [
"Whenever you use the Twist of Fate property, there is a chance that one of the souls trapped in the deck escapes. Roll {@dice d100} and consult the Souls of the Tarokka table. If you roll one of the high cards, the soul associated with it escapes. You can find its statistics in the {@book Monster Manual|MM}. If you roll a soul that has already escaped, roll again.",
{
"type": "table",
"caption": "Souls of the Tarokka",
"colLabels": [
"d100",
"Card",
"Soul"
],
"colStyles": [
"col-2 text-center",
"col-5 text-center",
"col-5 text-center"
],
"rows": [
[
"1",
"Artifact",
"{@creature Flameskull}"
],
[
"2",
"Beast",
"{@creature Wraith}"
],
[
"3",
"Broken",
"{@creature Banshee}"
],
[
"4",
"Darklord",
"{@creature Vampire}"
],
[
"5",
"Donjon",
"{@creature Mummy}"
],
[
"6",
"Executioner",
"{@creature Death knight}"
],
[
"7",
"Ghost",
"{@creature Ghost}"
],
[
"8",
"Horseman",
"{@creature Mummy lord}"
],
[
"9",
"Innocent",
"{@creature Ghost}"
],
[
"10",
"Marionette",
"{@creature Mummy}"
],
[
"11",
"Mists",
"{@creature Wraith}"
],
[
"12",
"Raven",
"{@creature Vampire spawn}"
],
[
"13",
"Seer",
"{@creature Vampire}"
],
[
"14",
"Tempter",
"{@creature Vampire spawn}"
],
[
"15-100",
"—",
"—"
]
]
},
"The released soul appears at a random location within {@dice 10d10} miles of you and terrorizes the living. Until the released soul is destroyed, it gains the benefit of a weal from the deck's Twist of Fate property, and both you and the original target of Twist of Fate suffer the effect of woe."
]
},
{
"type": "entries",
"name": "Shuffling Fate",
"entries": [
"If you go 7 days without using the Twist of Fate property, your attunement to Luba's Tarroka of Souls ends, and you can't attune to it again until after another creature uses Twist of Fate on you."
]
},
{
"type": "entries",
"name": "Destroying the Deck",
"entries": [
"Luba's Tarokka of Souls can be destroyed only if all fourteen souls within are released and destroyed. This reveals a fifteenth soul, a {@creature lich}, that inhabits the Nether card, which appears only when the fourteen souls are defeated. If this ancient entity is destroyed, the Nether card vanishes and the deck becomes a normal tarokka deck, with no special properties, but it includes a new card of the DM's design.",
{
"type": "entries",
"name": "Mother Luba and the Vistani",
"entries": [
"The creator of the Tarokka of Souls, Mother Luba was one of the most influential leaders of the Vistani. For untold generations, the Vistani have wandered the Shadowfell, which includes terrifying demiplanes like the vampire-haunted realm of Barovia. These travelers have learned many secrets of these domains and encountered countless others wandering amid the Shadowfell's horrors. Most Vistani bands accept well-intentioned wayfarers from diverse walks and of disparate origins, embracing any who seek to find a home amid the endless roads and vistas hidden amid the mists.",
"A halfling Vistani, Mother Luba led one of the largest groups of Vistani in the Shadowfell. She hailed from the same world that Count Strahd von Zarovich and Madam Eva came from, and she created a community of kindness and resilience-ever rare to find in the Plane of Shadow. She led her people in welcoming strangers, feeding the hungry, and defying the cruel. She and Madam Eva were once friends, until Madam Eva began bargaining with the creatures of the night. \"We may wander amid the shadows,\" Mother Luba said. \"But we must ever serve as a light to our fellow travelers.\"",
"Some years ago, Mother Luba disappeared into the mists, leaving behind only the Tarokka of Souls. It is said that if you draw the Mists card from it, you can hear the whispers of her kind voice."
]
}
]
}
],
"attachedSpells": [
"comprehend languages",
"detect evil and good",
"detect magic",
"detect poison and disease",
"locate object",
"scrying"
],
"hasFluffImages": true
},
{
"name": "Lute",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"type": "INS",
"rarity": "none",
"weight": 2,
"value": 3500
},
{
"name": "Lute crafted of exotic wood with mother-of-pearl inlay and zircon gems (Brazen Coalition)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 75000
},
{
"name": "Luxon Beacon",
"source": "EGW",
"page": 268,
"rarity": "legendary",
"wondrous": true,
"entries": [
"This dodecahedron of faintly glowing crystal is heavier than it appears. A set of handles are affixed to its sides, and it pulsates and thrums when touched.",
{
"type": "entries",
"name": "Fragment of Possibility",
"entries": [
"A creature that touches the beacon and concentrates for 1 minute receives a Fragment of Possibility, which looks like a Tiny, grayish bead of energy that follows the creature around, staying within 1 foot of it at all times. The fragment lasts for 8 hours or until used. Once the beacon grants a Fragment of Possibility, it can't grant another until the next dawn. A creature with a Fragment of Possibility from a Luxon Beacon can't gain another Fragment of Possibility from any source.",
"When a creature with a Fragment of Possibility makes an attack roll, an ability check, or a saving throw, it can expend its fragment to roll an additional {@dice d20} and choose which of the {@dice d20}s to use. Alternatively, when an attack roll is made against the creature, it can expend its fragment to roll a {@dice d20} and choose which of the {@dice d20}s to use, the one it rolled or the one the attacker rolled.",
"If the original {@dice d20} roll has advantage or disadvantage, the creature rolls its {@dice d20} after advantage or disadvantage has been applied to the original roll."
]
},
{
"type": "entries",
"name": "Soul Snare",
"entries": [
"If a follower of the Luxon who has undergone a ritual of consecution dies within 100 miles of a Luxon Beacon, their soul is ensnared by it. This soul will be reincarnated within the body of a random humanoid baby developing within 100 miles of the beacon."
]
}
],
"hasFluffImages": true
},
{
"name": "Lyre",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"type": "INS",
"rarity": "none",
"weight": 2,
"value": 3000
},
{
"name": "Lyre of Building",
"source": "TCE",
"page": 131,
"type": "INS",
"rarity": "rare",
"reqAttune": "by a bard",
"reqAttuneTags": [
{
"class": "bard"
}
],
"wondrous": true,
"weight": 2,
"grantsProficiency": true,
"entries": [
"While holding this {@item lyre|PHB}, you can cast {@spell mending} as an action. You can also play the {@item lyre|PHB} as a reaction when an object or a structure you can see within 300 feet of you takes damage, causing it to be immune to that damage and any further damage of the same type until the start of your next turn.",
"In addition, you can play the {@item lyre|PHB} as an action to cast {@spell fabricate}, {@spell move earth}, {@spell passwall}, or {@spell summon construct|TCE}, and that spell can't be cast from it again until the next dawn."
],
"attachedSpells": [
"fabricate",
"mending",
"move earth",
"passwall",
"summon construct|tce"
]
},
{
"name": "Mace of Disruption",
"source": "DMG",
"page": 179,
"srd": true,
"baseItem": "mace|phb",
"type": "M",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"weight": 4,
"weaponCategory": "simple",
"dmg1": "1d6",
"dmgType": "B",
"entries": [
"When you hit a fiend or an undead with this magic weapon, that creature takes an extra {@damage 2d6} radiant damage. If the target has 25 hit points or fewer after taking this damage, it must succeed on a DC 15 Wisdom saving throw or be destroyed. On a successful save, the creature becomes {@condition frightened} of you until the end of your next turn.",
"While you hold this weapon, it sheds bright light in a 20-foot radius and dim light for an additional 20 feet."
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Mace of Smiting",
"source": "DMG",
"page": 179,
"srd": true,
"baseItem": "mace|phb",
"type": "M",
"tier": "major",
"rarity": "rare",
"weight": 4,
"weaponCategory": "simple",
"dmg1": "1d6",
"dmgType": "B",
"bonusWeapon": "+1",
"entries": [
"You gain a +1 bonus to attack and damage rolls made with this magic weapon. The bonus increases to +3 when you use the mace to attack a construct.",
"When you roll a 20 on an attack roll made with this weapon, the target takes an extra 7 bludgeoning damage, or an extra 14 bludgeoning damage if it's a construct. If a construct has 25 hit points or fewer after taking this damage, it is destroyed.",
"{@note Note: According to the SRD, it is an extra {@dice 2d6} and {@damage 4d6} bludgeoning damage, although {@link this is incorrect|https://rpg.stackexchange.com/a/174522/53884}}."
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Mace of Terror",
"source": "DMG",
"page": 180,
"srd": true,
"baseItem": "mace|phb",
"type": "M",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"weight": 4,
"weaponCategory": "simple",
"dmg1": "1d6",
"dmgType": "B",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"This magic weapon has 3 charges. While holding it, you can use an action and expend 1 charge to release a wave of terror. Each creature of your choice in a 30-foot radius extending from you must succeed on a DC 15 Wisdom saving throw or become {@condition frightened} of you for 1 minute. While it is {@condition frightened} in this way, a creature must spend its turns trying to move as far away from you as it can, and it can't willingly move to a space within 30 feet of you. It also can't take reactions. For its action it can use only the {@action Dash} action or try to escape from an effect that prevents it from moving. If it has nowhere it can move, the creature can use the {@action Dodge} action. At the end of each of its turns, a creature can repeat the saving throw, ending the effect on itself on a success.",
"The mace regains {@dice 1d3} expended charges daily at dawn."
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Mace of the Black Crown (Awakened)",
"source": "EGW",
"_copy": {
"name": "Mace of the Black Crown (Dormant)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Awakened",
"entries": [
"When the mace reaches an awakened state, it gains the following properties:",
{
"type": "list",
"items": [
"The weapon's bonus to attack and damage rolls increases to +2, and the extra fire damage dealt by the weapon when it is ignited increases to {@dice 2d6}.",
"The weapon's Summon Devil feature can also be used to summon a {@creature bearded devil}.",
"You have resistance to poison damage while holding this weapon."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"bonusWeapon": "+2",
"hasFluffImages": true
},
{
"name": "Mace of the Black Crown (Dormant)",
"source": "EGW",
"page": 276,
"baseItem": "mace|PHB",
"type": "M",
"resist": [
"fire",
"poison"
],
"rarity": "artifact",
"reqAttune": true,
"sentient": true,
"weight": 4,
"weaponCategory": "simple",
"dmg1": "1d6",
"dmgType": "B",
"bonusWeapon": "+1",
"weapon": true,
"entries": [
"This mace has a haft of black iron and a ruby head with a fiendish countenance. Carrying the boon of Asmodeus, it is fit for the most powerful servants of the Nine Hells.",
{
"type": "entries",
"name": "Sentience",
"entries": [
"The Mace of the Black Crown is a sentient lawful evil weapon with an Intelligence of 20, a Wisdom of 12, and a Charisma of 18. It has hearing and {@sense darkvision} out to a range of 120 feet.",
"The weapon communicates telepathically with its wielder and can speak, read, and understand Common and Infernal."
]
},
{
"type": "entries",
"name": "Personality",
"entries": [
"A greedy {@creature erinyes} named Xartaza lives within the Mace of the Black Crown. Xartaza wants to recruit more souls for Asmodeus, so the weapon pushes its wielder toward lawful evil actions by manipulating the wielder's dreams. A former Blood War general, Xartaza hates demons and relishes crafting sound battle plans in any conflict."
]
},
{
"type": "entries",
"name": "Dormant",
"entries": [
"The mace grants the following benefits in its dormant state:",
{
"type": "list",
"items": [
"You can speak, read, and write Infernal.",
"You gain a +1 bonus to attack and damage rolls made with this magic weapon.",
"While holding the mace, you can use a bonus action to speak its Infernal command word, causing flames to erupt from the head. These flames shed bright light in a 40-foot radius and dim light for an additional 40 feet. While the mace is ablaze, it deals an extra {@damage 1d6} fire damage to any target it hits. The flames last until you use a bonus action to speak the command word again or until you are no longer holding the mace.",
"While holding the mace, you can use an action to summon an {@creature imp}. Any devil you summon with this mace is friendly to you and your companions for the duration. The imp obeys any verbal commands that you issue to it and returns to the Nine Hells 10 minutes after you summoned it. This property can't be used again until the next dawn."
]
}
]
},
{
"type": "inset",
"name": "Betrayer Artifact Properties",
"entries": [
"The Arms of the Betrayers advance in power in the same manner as the Vestiges of Divergence. In its dormant state, each of these artifacts has one {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} property and one {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental} property. When the artifact attains an awakened state, it gains an additional {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} property and an additional {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental} property. When the item reaches its exalted state, it gains a {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial} property. See \"Artifact Properties\" in chapter 7 of the Dungeon Master's Guide for more information."
]
}
],
"hasFluffImages": true
},
{
"name": "Mace of the Black Crown (Exalted)",
"source": "EGW",
"_copy": {
"name": "Mace of the Black Crown (Awakened)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Exalted",
"entries": [
"When the mace reaches an exalted state, it gains the following properties:",
{
"type": "list",
"items": [
"The weapon's bonus to attack and damage rolls increases to +3, and the extra fire damage dealt by the weapon when it is ignited increases to {@dice 3d6}. Fire damage dealt by the mace ignores resistance to fire damage.",
"The weapon's Summon Devil feature can also be used to summon a {@creature barbed devil}.",
"You have resistance to fire damage while you hold this weapon."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"bonusWeapon": "+3",
"hasFluffImages": true
},
{
"name": "Macuahuitl",
"source": "TftYP",
"page": 70,
"baseItem": "longsword|phb",
"type": "M",
"rarity": "unknown (magic)",
"weight": 3,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"bonusWeapon": "+1",
"sword": true,
"weapon": true,
"entries": [
"You have a +1 bonus to attack and damage rolls made with this longsword, which is made of laminated wood, and inset with jagged teeth of obsidian. It deals an extra {@damage 2d6} damage to any creature of the plant type."
]
},
{
"name": "Maddgoth's Helm",
"source": "WDMM",
"page": 103,
"type": "OTH",
"rarity": "very rare",
"reqAttune": "by a humanoid",
"reqAttuneTags": [
{
"creatureType": "humanoid"
}
],
"entries": [
"While you wear this helm and are inside Maddgoth's castle, on its roof, or in its courtyard, you have immunity to all damage. If the helm is taken from the castle, it turns to dust and is destroyed."
]
},
{
"name": "Magnifying Glass",
"source": "PHB",
"page": 152,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"value": 10000,
"entries": [
"This lens allows a closer look at small objects. It is also useful as a substitute for flint and steel when starting fires. Lighting a fire with a magnifying glass requires light as bright as sunlight to focus, tinder to ignite, and about 5 minutes for the fire to ignite. A magnifying glass grants advantage on any ability check made to appraise or inspect an item that is small or highly detailed."
]
},
{
"name": "Malachite",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 1000,
"entries": [
"An opaque striated light and dark green gemstone."
]
},
{
"name": "Malice",
"source": "DMG",
"page": 258,
"srd": true,
"type": "G",
"rarity": "none",
"value": 25000,
"poison": true,
"entries": [
"A creature subjected to this poison must succeed on a DC 15 Constitution saving throw or become {@condition poisoned} for 1 hour. The {@condition poisoned} creature is {@condition blinded}."
],
"poisonTypes": [
"inhaled"
]
},
{
"name": "Manacles",
"source": "PHB",
"page": 152,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 6,
"value": 200,
"entries": [
"These metal restraints can bind a Small or Medium creature. Escaping the manacles requires a successful DC 20 Dexterity check. Breaking them requires a successful DC 20 Strength check. Each set of manacles comes with one key. Without the key, a creature proficient with {@item thieves' tools|PHB} can pick the manacles' lock with a successful DC 15 Dexterity check. Manacles have 15 hit points."
]
},
{
"name": "Mantle of Spell Resistance",
"source": "DMG",
"page": 180,
"srd": true,
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"You have advantage on saving throws against spells while you wear this cloak."
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Manual of Bodily Health",
"source": "DMG",
"page": 180,
"srd": true,
"tier": "major",
"rarity": "very rare",
"wondrous": true,
"weight": 5,
"ability": {
"con": 2
},
"entries": [
"This book contains health and diet tips, and its words are charged with magic. If you spend 48 hours over a period of 6 days or fewer studying the book's contents and practicing its guidelines, your Constitution score increases by 2, as does your maximum for that score. The manual then loses its magic, but regains it in a century."
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Manual of Clay Golems",
"source": "DMG",
"page": 180,
"srd": true,
"tier": "major",
"rarity": "very rare",
"wondrous": true,
"weight": 5,
"entries": [
"This tome contains information and incantations necessary to make a particular type of golem. The DM chooses the type or determines it randomly. To decipher and use the manual, you must be a spellcaster with at least two 5th-level spell slots. A creature that can't use a manual of golems and attempts to read it takes {@damage 6d6} psychic damage.",
"To create a {@creature clay golem}, you must spend 30 days, working without interruption with the manual at hand and resting no more than 8 hours per day. You must also pay 65,000 gp to purchase supplies. Once you finish creating the golem, the book is consumed in eldritch flames. The golem becomes animate when the ashes of the manual are sprinkled on it. It is under your control, and it understands and obeys your spoken commands."
],
"hasFluffImages": true
},
{
"name": "Manual of Flesh Golems",
"source": "DMG",
"page": 180,
"srd": true,
"tier": "major",
"rarity": "very rare",
"wondrous": true,
"weight": 5,
"entries": [
"This tome contains information and incantations necessary to make a particular type of golem. The DM chooses the type or determines it randomly. To decipher and use the manual, you must be a spellcaster with at least two 5th-level spell slots. A creature that can't use a manual of golems and attempts to read it takes {@damage 6d6} psychic damage.",
"To create a {@creature flesh golem}, you must spend 60 days, working without interruption with the manual at hand and resting no more than 8 hours per day. You must also pay 50,000 gp to purchase supplies. Once you finish creating the golem, the book is consumed in eldritch flames. The golem becomes animate when the ashes of the manual are sprinkled on it. It is under your control, and it understands and obeys your spoken commands."
],
"hasFluffImages": true
},
{
"name": "Manual of Gainful Exercise",
"source": "DMG",
"page": 180,
"srd": true,
"tier": "major",
"rarity": "very rare",
"wondrous": true,
"weight": 5,
"ability": {
"str": 2
},
"entries": [
"This book describes fitness exercises, and its words are charged with magic. If you spend 48 hours over a period of 6 days or fewer studying the book's contents and practicing its guidelines, your Strength score increases by 2, as does your maximum for that score. The manual then loses its magic, but regains it in a century."
],
"lootTables": [
"Magic Item Table H"
]
},
{
"name": "Manual of Iron Golems",
"source": "DMG",
"page": 180,
"srd": true,
"tier": "major",
"rarity": "very rare",
"wondrous": true,
"weight": 5,
"entries": [
"This tome contains information and incantations necessary to make a particular type of golem. The DM chooses the type or determines it randomly. To decipher and use the manual, you must be a spellcaster with at least two 5th-level spell slots. A creature that can't use a manual of golems and attempts to read it takes {@damage 6d6} psychic damage.",
"To create an {@creature iron golem}, you must spend 120 days, working without interruption with the manual at hand and resting no more than 8 hours per day. You must also pay 100,000 gp to purchase supplies. Once you finish creating the golem, the book is consumed in eldritch flames. The golem becomes animate when the ashes of the manual are sprinkled on it. It is under your control, and it understands and obeys your spoken commands."
],
"hasFluffImages": true
},
{
"name": "Manual of Quickness of Action",
"source": "DMG",
"page": 181,
"srd": true,
"tier": "major",
"rarity": "very rare",
"wondrous": true,
"weight": 5,
"ability": {
"dex": 2
},
"entries": [
"This book contains coordination and balance exercises, and its words are charged with magic. If you spend 48 hours over a period of 6 days or fewer studying the book's contents and practicing its guidelines, your Dexterity score increases by 2, as does your maximum for that score. The manual then loses its magic, but regains it in a century."
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Manual of Stone Golems",
"source": "DMG",
"page": 180,
"srd": true,
"tier": "major",
"rarity": "very rare",
"wondrous": true,
"weight": 5,
"entries": [
"This tome contains information and incantations necessary to make a particular type of golem. The DM chooses the type or determines it randomly. To decipher and use the manual, you must be a spellcaster with at least two 5th-level spell slots. A creature that can't use a manual of golems and attempts to read it takes {@damage 6d6} psychic damage.",
"To create a {@creature stone golem}, you must spend 90 days, working without interruption with the manual at hand and resting no more than 8 hours per day. You must also pay 80,000 gp to purchase supplies. Once you finish creating the golem, the book is consumed in eldritch flames. The golem becomes animate when the ashes of the manual are sprinkled on it. It is under your control, and it understands and obeys your spoken commands."
],
"hasFluffImages": true
},
{
"name": "Map or Scroll Case",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 1,
"value": 100,
"entries": [
"This cylindrical leather case can hold up to ten rolled-up {@item paper (one sheet)|phb|sheets of paper} or five rolled-up {@item parchment (one sheet)|phb|sheets of parchment}."
],
"containerCapacity": {
"item": [
{
"parchment (one sheet)|phb": 5,
"paper (one sheet)|phb": 10
}
]
}
},
{
"name": "Marble font with gold inlay (Legion of Dusk)",
"source": "PSX",
"page": 25,
"type": "$",
"rarity": "none",
"value": 750000
},
{
"name": "Mask of the Beast",
"source": "ToA",
"page": 207,
"rarity": "uncommon",
"wondrous": true,
"weight": 1,
"recharge": "dawn",
"rechargeAmount": 3,
"charges": 3,
"entries": [
"This wooden mask is shaped in the likeness of a beast's visage and has 3 charges. While wearing the mask you can expend 1 charge and use the mask to cast the {@spell animal friendship} spell as an action. The mask regains all expended charges at dawn."
],
"attachedSpells": [
"animal friendship"
],
"hasFluffImages": true
},
{
"name": "Mask of the Dragon Queen",
"source": "RoT",
"page": 94,
"otherSources": [
{
"source": "ToD",
"page": 179
}
],
"rarity": "artifact",
"reqAttune": true,
"wondrous": true,
"entries": [
"Individually, the five dragon masks resemble the dragons they are named for. When two or more of the dragon masks are assembled, however, they transform magically into the Mask of the Dragon Queen. Each mask shrinks to become the modeled head of a chromatic dragon, appearing to roar its devotion to {@creature Tiamat|RoT} where all the masks brought together are arranged crown-like on the wearer's head. Below the five masks, a new mask shapes itself, granting the wearer a draconic visage that covers the face, neck, and shoulders.",
"While you are attuned to and wear this mask, you can have any of the properties from any one mask. Additionally, you gain the Damage Absorption from each of the five dragon masks, and you gain five uses of the Legendary Resistance property."
],
"hasFluffImages": true
},
{
"name": "Mason's Tools",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"additionalSources": [
{
"source": "XGE",
"page": 83
}
],
"type": "AT",
"rarity": "none",
"weight": 8,
"value": 1000,
"additionalEntries": [
"Mason's tools allow you to craft stone structures, including walls and buildings crafted from brick.",
{
"type": "entries",
"name": "Components",
"entries": [
"Mason's tools consist of a trowel, a hammer, a chisel, brushes, and a square."
]
},
{
"type": "entries",
"name": "History",
"entries": [
"Your expertise aids you in identifying a stone building's date of construction and purpose, along with insight into who might have built it."
]
},
{
"type": "entries",
"name": "Investigation",
"entries": [
"You gain additional insight when inspecting areas within stone structures."
]
},
{
"type": "entries",
"name": "Perception",
"entries": [
"You can spot irregularities in stone walls or floors, making it easier to find trap doors and secret passages."
]
},
{
"type": "entries",
"name": "Demolition",
"entries": [
"Your knowledge of masonry allows you to spot weak points in brick walls. You deal double damage to such structures with your weapon attacks."
]
},
{
"type": "table",
"caption": "Mason's Tools",
"colLabels": [
"Activity",
"DC"
],
"colStyles": [
"col-10",
"col-2 text-center"
],
"rows": [
[
"Chisel a small hole in a stone wall",
"10"
],
[
"Find a weak point in a stone wall",
"15"
]
]
}
]
},
{
"name": "Masque Charm",
"source": "SCC",
"page": 127,
"rarity": "common",
"wondrous": true,
"entries": [
"A masque charm is a small silver pin. While wearing this charm, you can use an action to cast the {@spell disguise self} spell (DC 13 to discern the disguise). Once the spell is cast, it can't be cast from the charm again until the next sunset. When casting the spell, you can have the spell last for its normal 1 hour duration or for 6 hours. If you choose the 6-hour duration, the charm becomes nonmagical when the spell ends. In either case, the spell ends if the charm is removed from you."
],
"attachedSpells": [
"disguise self"
]
},
{
"name": "Masquerade Tattoo",
"source": "TCE",
"page": 131,
"rarity": "common",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"entries": [
"Produced by a special needle, this magic tattoo appears on your body as whatever you desire.",
{
"type": "entries",
"name": "Tattoo Attunement",
"entries": [
"To attune to this item, you hold the needle to your skin where you want the tattoo to appear, pressing the needle there throughout the attunement process. When the attunement is complete, the needle turns into the ink that becomes the tattoo, which appears on the skin.",
"If your attunement to the tattoo ends, the tattoo vanishes, and the needle reappears in your space."
]
},
{
"type": "entries",
"name": "Fluid Ink",
"entries": [
"As a bonus action, you can shape the tattoo into any color or pattern and move it to any area of your skin. Whatever form it takes, it is always obviously a tattoo. It can range in size from no smaller than a copper piece to an intricate work of art that covers all your skin."
]
},
{
"type": "entries",
"name": "Disguise Self",
"entries": [
"As an action, you can use the tattoo to cast the {@spell disguise self} spell (DC 13 to discern the disguise). Once the spell is cast from the tattoo, it can't be cast from the tattoo again until the next dawn."
]
}
],
"attachedSpells": [
"disguise self"
]
},
{
"name": "Masquerade Tattoo",
"source": "UA2020SpellsAndMagicTattoos",
"page": 11,
"rarity": "common",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"entries": [
"This tattoo appears on your skin as whatever you desire. As a bonus action, you can shape the tattoo into any color or pattern and move it to any area of your skin. Whatever form it takes, it is always obviously a tattoo. It can range in size from no smaller than a copper piece to an intricate work of art that covers all your skin.",
{
"type": "entries",
"name": "Disguise Self",
"entries": [
"As an action, you can use the tattoo to cast the {@spell disguise self} spell. Once the spell is cast from the tattoo, it can't be cast from the tattoo again until the next dawn."
]
},
{
"type": "entries",
"name": "Tattoo Attunement",
"entries": [
"To attune to this item, you hold the needle to your skin where you want the tattoo to appear, pressing the needle there throughout the attunement process. When the attunement is complete, the needle turns into the ink that becomes the tattoo, which appears on the skin. If you have multiple magic tattoos, they count as a single magic item with regard to the number of magic items you can attune to.",
"If your attunement to the tattoo ends, the tattoo vanishes, and the needle reappears in the closest unoccupied space to you."
]
}
],
"attachedSpells": [
"disguise self"
]
},
{
"name": "Master's Amulet",
"source": "MM",
"page": 271,
"type": "OTH",
"rarity": "unknown (magic)",
"entries": [
"Every {@creature shield guardian} has an amulet magically linked to it. A shield guardian can have only one corresponding amulet, and if that amulet is destroyed, the shield guardian is {@condition incapacitated} until a replacement amulet is created. A shield guardian's amulet is subject to direct attack if it isn't being worn or carried. It has AC 10, 10 hit points, and immunity to poison and psychic damage. Crafting an amulet requires 1 week and costs 1,000 gp in components.",
"A shield guardian's solitary focus is to protect the amulet's wearer. The amulet's wearer can command the guardian to attack its enemies or to guard the wielder against attack. If an attack threatens to injure the wearer, the construct can magically absorb the blow into its own body, even at a distance.",
"A spellcaster can store a single spell within a shield guardian, which can then cast the spell on command or under specific conditions. Many a wizard has been rendered helpless by enemies, only to surprise those foes when its shield guardian unleashes potent magical power."
]
},
{
"name": "Masterpiece painting in mahogany frame with gold inlay (Legion of Dusk)",
"source": "PSX",
"page": 25,
"type": "$",
"rarity": "none",
"value": 250000
},
{
"name": "Mastiff",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "MNT",
"rarity": "none",
"value": 2500,
"carryingCapacity": 195,
"speed": 40
},
{
"name": "Mastix, Whip of Erebos",
"source": "MOT",
"page": 201,
"baseItem": "whip|PHB",
"type": "M",
"rarity": "artifact",
"reqAttune": true,
"weight": 3,
"weaponCategory": "martial",
"property": [
"F",
"R"
],
"dmg1": "1d4",
"dmgType": "S",
"bonusWeapon": "+3",
"weapon": true,
"entries": [
"Erebos wields Mastix, a whip capable of extending like an impossibly long shadow. In the hands of the god of the dead, the whip snares the reluctant dead and drags them into his realm. When Erebos grants his weapon to a mortal follower, it's typically to reclaim a powerful soul or to humiliate Heliod.",
{
"type": "entries",
"name": "Whip of the Dead",
"entries": [
"Erebos's whip seethes with the enervating energy of the Underworld. This magic whip grants a +3 bonus to attack and damage rolls made with it. When you hit with an attack using this whip, the target takes an extra {@damage 2d8} necrotic damage and you regain hit points equal to half the amount of necrotic damage dealt.",
"Additionally, when you make an attack with the whip on your turn, you can increase the range of the attack to 300 feet. This property of the weapon can't be used again until the next dusk."
]
},
{
"type": "entries",
"name": "Blessing of the Dead",
"entries": [
"If you are a worshiper of Erebos, you gain all the following benefits for which you have the required piety:",
{
"type": "list",
"style": "list-hang-notitle",
"items": [
{
"type": "item",
"name": "Piety 1+",
"entry": "The whip has 1 randomly determined {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental} property—a burden Erebos imposes to test his faithful."
},
{
"type": "item",
"name": "Piety 25+",
"entry": "The whip has 1 randomly determined {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial} property."
},
{
"type": "item",
"name": "Piety 50+",
"entry": "The whip has 1 additional randomly determined {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial} property."
}
]
},
"If you aren't a worshiper of Erebos, the whip has 2 randomly determined {@table Artifact Properties; Major Detrimental Properties|dmg|major detrimental} properties.",
"See \"Artifacts\" in chapter 7 of the Dungeon Master's Guide for details on randomly determined properties."
]
},
{
"type": "entries",
"name": "Erebos's Claim",
"entries": [
"While carrying the whip, you can use an action to cast either {@spell circle of death} or {@spell dominate monster} (targeting only undead) from the whip. The save DC for these spells is 18. Once you use the whip to cast a spell, that spell can't be cast from it again until the next dusk."
]
},
{
"type": "entries",
"name": "Destroying the Whip",
"entries": [
"To destroy the whip, it must be taken to the heights of Mount Hiastos in Nyx, unraveled by a Returned, and left to bask in continual daylight for one month."
]
}
],
"hasFluffImages": true
},
{
"name": "Matalotok",
"source": "BGDIA",
"page": 224,
"baseItem": "warhammer|phb",
"type": "M",
"immune": [
"cold"
],
"rarity": "legendary",
"reqAttune": true,
"weight": 2,
"weaponCategory": "martial",
"property": [
"V"
],
"range": "20/60",
"dmg1": "1d8",
"dmgType": "B",
"dmg2": "1d10",
"entries": [
"Matalotok, the Frost Father, is an ancient hammer fashioned by Thrym, the god of frost giants. The favored weapon of the demon lord {@creature Kostchtchie|BGDIA}, Matalotok is frigid to the touch and wreathed in mist.",
"You are immune to cold damage while holding Matalotok. Whenever it deals damage to a creature, the hammer radiates a burst of intense cold in a 30-foot-radius sphere. Each creature in that area takes 10 ({@damage 3d6}) cold damage."
],
"hasFluffImages": true
},
{
"name": "Matchless Pipe",
"source": "WDH",
"page": 47,
"type": "OTH",
"rarity": "unknown",
"entries": [
"A switch made of flint is built into the bowl of this fine wooden smoking pipe. With a few flicks of the switch, the pipe lights itself."
]
},
{
"name": "Medal of Muscle",
"source": "CRCotN",
"page": 214,
"rarity": "common",
"wondrous": true,
"entries": [
"You can squeeze this medal tightly in the palm of your hand as an action. Doing so gives you advantage on Strength checks and Strength saving throws for 1 hour. Once this property has been used, it can't be used again, and the medal becomes nonmagical."
]
},
{
"name": "Medal of the Conch",
"source": "CRCotN",
"page": 214,
"rarity": "common",
"wondrous": true,
"modifySpeed": {
"equal": {
"swim": "walk"
}
},
"entries": [
"When you use an action to rub this medal, you gain a swimming speed equal to your walking speed for 1 hour. Once this property has been used, it can't be used again, and the medal becomes nonmagical."
]
},
{
"name": "Medal of the Horizonback",
"source": "CRCotN",
"page": 214,
"rarity": "common",
"wondrous": true,
"entries": [
"When you would be hit by an attack, you can use your reaction to increase your AC by 5 until the start of your next turn, including against the triggering attack. You must be wearing the medal and able to see the creature that made the triggering attack to use this property. Once this property has been used, it can't be used again, and the medal becomes nonmagical."
]
},
{
"name": "Medal of the Maze",
"source": "CRCotN",
"page": 214,
"rarity": "common",
"wondrous": true,
"entries": [
"When you use an action to trace the maze inscribed on this medal, you gain advantage on Wisdom checks and know the quickest route to the end of any nonmagical path or maze for 1 hour. Once this property has been used, it can't be used again, and the medal becomes nonmagical."
],
"hasFluffImages": true
},
{
"name": "Medal of the Meat Pie",
"source": "CRCotN",
"page": 214,
"rarity": "common",
"wondrous": true,
"entries": [
"You gain {@dice 2d4 + 2} temporary hit points when you use an action to press this medal to your mouth. Once this property has been used, it can't be used again, and the medal becomes nonmagical.",
"While magical, this medal is slightly warm to the touch (as if it's fresh from the oven) and smells faintly of baked pie crust."
]
},
{
"name": "Medal of the Wetlands",
"source": "CRCotN",
"page": 214,
"rarity": "common",
"wondrous": true,
"entries": [
"When you use an action to trace the edge of this medal, {@quickref difficult terrain||3} doesn't cost you extra movement for 1 hour. Once this property has been used, it can't be used again, and the medal becomes nonmagical."
]
},
{
"name": "Medal of Wit",
"source": "CRCotN",
"page": 214,
"rarity": "common",
"wondrous": true,
"entries": [
"You can press this medal to your temple as an action. Doing so gives you advantage on Intelligence checks and Intelligence saving throws for 1 hour. Once this property has been used, it can't be used again, and the medal becomes nonmagical."
]
},
{
"name": "Medallion of Thoughts",
"source": "DMG",
"page": 181,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"weight": 1,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"The medallion has 3 charges. While wearing it, you can use an action and expend 1 charge to cast the {@spell detect thoughts} spell (save DC 13) from it. The medallion regains {@dice 1d3} expended charges daily at dawn."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"attachedSpells": [
"detect thoughts"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Menga leaves (1 ounce)",
"source": "ToA",
"page": 205,
"type": "G",
"rarity": "unknown",
"weight": 0.0625,
"value": 200,
"entries": [
"The dried leaves of a menga bush can be ground, dissolved in a liquid, heated, and ingested. A creature that ingests 1 ounce of menga leaves in this fashion regains 1 hit point. A creature that ingests more than 5 ounces of menga leaves in a 24-hour period gains no additional benefit and must succeed on a DC 11 Constitution saving throw or fall {@condition unconscious} for 1 hour. The {@condition unconscious} creature awakens if it takes at least 5 damage on one turn.",
"A healthy menga bush usually has {@dice 2d6} ounces of leaves. Once picked, the leaves require 1 day to dry out before they can confer any benefit."
]
},
{
"name": "Merchant's Scale",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 3,
"value": 500,
"entries": [
"A scale includes a small balance, pans, and a suitable assortment of weights up to 2 pounds. With it, you can measure the exact weight of small objects, such as raw precious metals or trade goods, to help determine their worth."
]
},
{
"name": "Mess Kit",
"source": "PHB",
"page": 152,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 1,
"value": 20,
"entries": [
"This tin box contains a cup and simple cutlery. The box clamps together, and one side can be used as a cooking pan and the other as a plate or shallow bowl."
]
},
{
"name": "Midnight Tears",
"source": "DMG",
"page": 258,
"srd": true,
"type": "G",
"rarity": "none",
"value": 150000,
"poison": true,
"entries": [
"A creature that ingests this poison suffers no effect until the stroke of midnight. If the poison has not been neutralized before then, the creature must succeed on a DC 17 Constitution saving throw, taking 31 ({@damage 9d6}) poison damage on a failed save, or half as much damage on a successful one."
],
"poisonTypes": [
"ingested"
]
},
{
"name": "Mighty Servant of Leuk-o",
"source": "TCE",
"page": 131,
"rarity": "artifact",
"reqAttune": true,
"wondrous": true,
"entries": [
"Named for the warlord who infamously employed it, the {@creature Mighty Servant of Leuk-o|TCE} is a fantastically powerful, 10-foot-tall machine that turns into an animate construct when piloted. Crafted of a gleaming black alloy of unknown origin, the servant is often described as a combination of a disproportioned dwarf and an oversized beetle. The servant contains enough space for 1 ton of cargo and a crew compartment within, from which up to two Medium creatures can control it-and potentially execute a spree of unstoppable destruction.",
"Tales of the servant's origins involve more conjecture than fact, often referring to otherworldly beings, the mysterious Barrier Peaks in Oerth, and the supposedly related device known as the {@i Machine of Lum the Mad}. The best details on the device's origins and operation can be found in the {@i Mind of Metal}, a tome of artificer's secrets that connects the device to the traditions of the lost Olman people, and which was written by Lum the Mad's several times over granddaughter, Lum the Maestro, while she reconstructed the long disassembled Mighty Servant of Leuk-o.",
{
"type": "entries",
"name": "Dangerous Attunement",
"entries": [
"Two creatures can be attuned to the servant at a time. If a third creature tries to attune to it, nothing happens.",
"The servant's controls are accessed by a hatch in its upper back, which is easily opened while there are no creatures attuned to the artifact.",
"Attuning to the artifact requires two hours, which can be undertaken as part of a long rest, during which time you must be inside the servant, interacting with its controls. While crew members are attuning themselves, any creature or structure outside and within 50 feet of the servant has a {@chance 25|25 percent} chance of being accidentally targeted by one of its Destructive Fist attacks once during the attunement. This process must be undergone every time a creature attunes itself to the artifact."
]
},
{
"type": "entries",
"name": "Controlling the Servant",
"entries": [
"While any creatures are attuned to the artifact, attuned creatures can open the hatch as easily as any other door. Other creatures can open the hatch as an action with a successful DC 25 Dexterity check using {@item thieves' tools|PHB}. A {@spell knock} spell cast on the hatch also opens it until the start of the caster's next turn.",
"A creature can enter or exit through the hatch by spending 10 feet of movement. Those inside the servant have total cover from effects originating outside it. The controls within it allow creatures to see outside without obstruction.",
"While you are inside the servant, you can command it by using the controls. During your turn (for either attuned creature), you can command it in the following ways:",
{
"type": "list",
"items": [
"Open or close the hatch (no action required, once per turn)",
"Move the servant up to its speed (no action required)",
"As an action, you can command the servant to take one of the actions in its stat block or some other action.",
"When a creature provokes an opportunity attack from the servant, you can use your reaction to command the servant to make one Destructive Fist attack against that creature."
]
},
"While there are no attuned creatures inside the servant, it is an inert object."
]
},
{
"type": "entries",
"name": "Ghost in the Machine",
"entries": [
"Upon his death, the soul of the mighty warlord Leuk-o was drawn into the artifact and has become its animating force. The servant has been known to attack or move of its own accord, particularly if doing so will cause destruction. Once every 24 hours, the servant, at the DM's discretion, takes one action while uncrewed.",
"If the servant loses half of its hit points or more, each creature attuned to it must succeed on a DC 20 Wisdom saving throw or be {@condition charmed} for 24 hours. While {@condition charmed} in this way, the creature goes on a destructive spree, seeking to destroy structures and attack any unattuned creatures within sight of the servant, starting with those threatening the artifact-preferably using the servant, if possible."
]
},
{
"type": "entries",
"name": "Self-Destruct",
"entries": [
"By inputting a specific series of lever pulls and button presses, the servant's two crew members can cause it to explode. The self-destruct code is not revealed to crew members when they attune to the artifact. If the code is discovered (the DM determines how), it requires two attuned crew members to be inside the servant and spend their actions on 3 consecutive rounds performing the command. Should the crew members begin the process of entering the code, though, the servant uses its Ghost in the Machine property and turns the crew members against each other.",
"If the crew members successfully implement the code, at the end of the third round, the servant explodes. Every creature in a 100-foot-radius sphere centered on the servant must make a DC 25 Dexterity saving throw. On a failed save, a creature takes 87 ({@damage 25d6}) force damage, 87 ({@damage 25d6}) lightning damage, and 87 ({@damage 25d6}) thunder damage. On a successful save, a creature takes half as much damage. Objects and structures in the area take triple damage. Creatures inside the servant are slain instantly and leave behind no remains.",
"This does not destroy the servant permanently. Rather, {@dice 2d6} days later, its parts—left arm, left leg, right arm, right leg, lower torso, and upper torso—drop from the sky in random places within 1,000 miles of the explosion. If brought within 5 feet of one another, the pieces reconnect and reform the servant."
]
},
{
"type": "entries",
"name": "Destroying the Servant",
"entries": [
"The servant can be destroyed in two ways. After it has self-destructed, its disconnected pieces can be melted down in one of the forge-temples of its ancient Olman creators. Alternatively, if the servant strikes the Machine of Lum the Mad, both artifacts explode in an eruption that is three times the size and three times the damage as the servant's self-destruct property."
]
}
],
"attachedSpells": [
"knock"
],
"hasFluffImages": true
},
{
"name": "Military Saddle",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TAH",
"rarity": "none",
"weight": 30,
"value": 2000,
"entries": [
"A military saddle braces the rider, helping you keep your seat on an active mount in battle. It gives you advantage on any check you make to remain mounted."
]
},
{
"name": "Mind Flayer Skull",
"source": "WDMM",
"page": 197,
"type": "OTH",
"rarity": "unknown (magic)",
"entries": [
"While you have the skull in your possession, you are {@condition invisible} to mind flayers, as is anything you are wearing or carrying."
]
},
{
"name": "Mind Lash",
"source": "VGM",
"page": 81,
"type": "M",
"rarity": "rare",
"reqAttune": "by a mind flayer",
"weight": 3,
"property": [
"F",
"R"
],
"dmg1": "1d4",
"dmgType": "S",
"entries": [
"In the hands of any creature other than a {@creature mind flayer}, a mind lash functions as a normal whip. In the hands of an illithid, this magic weapon strips away a creature's will to survive as it also strips away flesh, dealing an extra {@damage 2d4} psychic damage to any target it hits. Any creature that takes psychic damage from the mind lash must also succeed on a DC 15 Wisdom saving throw or have disadvantage on Intelligence, Wisdom, and Charisma saving throws for 1 minute. The creature can repeat the saving throw at the end of each of its turns, ending the effect on itself on a success."
]
},
{
"name": "Miner's Pick",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 10,
"value": 200
},
{
"name": "Mirror of Life Trapping",
"source": "DMG",
"page": 181,
"srd": true,
"tier": "major",
"rarity": "very rare",
"wondrous": true,
"weight": 50,
"entries": [
"When this 4-foot-tall mirror is viewed indirectly, its surface shows faint images of creatures. The mirror weighs 50 pounds, and it has AC 11, 10 hit points, and vulnerability to bludgeoning damage. It shatters and is destroyed when reduced to 0 hit points.",
"If the mirror is hanging on a vertical surface and you are within 5 feet of it, you can use an action to speak its command word and activate it. It remains activated until you use an action to speak the command word again.",
"Any creature other than you that sees its reflection in the activated mirror while within 30 feet of it must succeed on a DC 15 Charisma saving throw or be trapped, along with anything it is wearing or carrying, in one of the mirror's twelve extradimensional cells. This saving throw is made with advantage if the creature knows the mirror's nature, and constructs succeed on the saving throw automatically.",
"An extradimensional cell is an infinite expanse filled with thick fog that reduces visibility to 10 feet. Creatures trapped in the mirror's cells don't age, and they don't need to eat, drink, or sleep. A creature trapped within a cell can escape using magic that permits planar travel. Otherwise, the creature is confined to the cell until freed.",
"If the mirror traps a creature but its twelve extradimensional cells are already occupied, the mirror frees one trapped creature at random to accommodate the new prisoner. A freed creature appears in an unoccupied space within sight of the mirror but facing away from it. If the mirror is shattered, all creatures it contains are freed and appear in unoccupied spaces near it.",
"While within 5 feet of the mirror, you can use an action to speak the name of one creature trapped in it or call out a particular cell by number. The creature named or contained in the named cell appears as an image on the mirror's surface. You and the creature can then communicate normally.",
"In a similar way, you can use an action to speak a second command word and free one creature trapped in the mirror. The freed creature appears, along with its possessions, in the unoccupied space nearest to the mirror and facing away from it."
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Mirror of Reflected Pasts",
"source": "DSotDQ",
"page": 190,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"weight": 25,
"entries": [
"This mirror of elven design allows those who stare into it to reflect on positive memories. The 3-foot-tall mirror weighs 25 pounds, and it has AC 11, 10 hit points, and vulnerability to bludgeoning damage. It shatters and is destroyed if reduced to 0 hit points.",
"While holding the mirror upright, you can use an action to speak its command word and activate it. While activated, the mirror hovers in the air, and it can be destroyed but not moved. It remains activated until you use an action to speak the command word again or your attunement to the mirror ends, at which point the mirror harmlessly floats to the ground. Once the mirror has been deactivated, it can't be activated again until the next dawn.",
"If a non-Construct creature other than you sees its reflection in the activated mirror while within 30 feet of it, that creature must succeed on a DC 15 Wisdom saving throw or become {@condition paralyzed} until the mirror is deactivated or until that creature can no longer see the mirror. A creature {@condition paralyzed} by the mirror can repeat the saving throw at the end of each of its turns, ending the effect on a success. If a creature's saving throw is successful or the effect ends for it, the creature is immune to this mirror's effect for the next 24 hours.",
"While {@condition paralyzed} by the mirror, the creature sees events from their past reflected in the mirror's glass. These memories aren't real, but rather idealized versions of those occurrences. Nearby observers can glimpse flashes of these memories if looking indirectly at the mirror."
]
},
{
"name": "Mirror of the Past",
"source": "TftYP",
"page": 228,
"rarity": "rare",
"wondrous": true,
"weight": 1,
"entries": [
"The holder of this platinum hand mirror can learn something about the history of a specific object or creature by taking an action to gaze into the mirror and think of the target. Instead of the holder's reflection, the mirror presents scenes from the target's past. Information conveyed is accurate, but it is random and cryptic, and presented in no particular order. Once it is activated, the mirror gives its information for 1 minute or less, then returns to normal. It can't be used again until the next dawn."
]
},
{
"name": "Mizzium Apparatus",
"source": "GGR",
"page": 179,
"rarity": "uncommon",
"reqAttune": "by a sorcerer, warlock, or wizard",
"reqAttuneTags": [
{
"class": "sorcerer"
},
{
"class": "warlock"
},
{
"class": "wizard"
}
],
"wondrous": true,
"weight": 8,
"focus": [
"Sorcerer",
"Warlock",
"Wizard"
],
"entries": [
"Innovation is a dangerous pursuit, at least the way the mages of the Izzet League engage in it. As protection against the risk of an experiment going awry, they have developed a device to help channel and control their magic. This apparatus is a collection of leather straps, flexible tubing, glass cylinders, and plates, bracers, and fittings made from a magic-infused metal alloy called mizzium, all assembled into a harness. The item weighs 8 pounds.",
"While you are wearing the mizzium apparatus, you can use it as an arcane focus. In addition, you can attempt to cast a spell that you do not know or have prepared. The spell you choose must be on your class's spell list and of a level for which you have a spell slot, and you must provide the spell's components.",
"You expend a spell slot to cast the spell as normal, but before resolving it you must make an Intelligence ({@skill Arcana}) check. The DC is 10 + twice the level of the spell slot you expend to cast the spell.",
"On a successful check, you cast the spell as normal, using your spell save DC and spellcasting ability modifier. On a failed check, you cast a different spell from the one you intended. Randomly determine the spell you cast by rolling on the table for the level of the spell slot you expended. If the slot is 6th level or higher, roll on the table for 5th-level spells.",
"If you try to cast a cantrip you don't know, the DC for the Intelligence ({@skill Arcana}) check is 10, and on a failed check, there is no effect.",
{
"type": "table",
"caption": "1st-Level Spells",
"colLabels": [
"d6",
"spell"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"1",
"{@spell burning hands}"
],
[
"2",
"{@spell chaos bolt|XGE}"
],
[
"3",
"{@spell color spray}"
],
[
"4",
"{@spell faerie fire}"
],
[
"5",
"{@spell fog cloud}"
],
[
"6",
"{@spell thunderwave}"
]
]
},
{
"type": "table",
"caption": "2nd-Level Spells",
"colLabels": [
"d6",
"spell"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"1",
"{@spell blur}"
],
[
"2",
"{@spell gust of wind}"
],
[
"3",
"{@spell heat metal}"
],
[
"4",
"{@spell Melf's acid arrow}"
],
[
"5",
"{@spell scorching ray}"
],
[
"6",
"{@spell shatter}"
]
]
},
{
"type": "table",
"caption": "3rd-Level Spells",
"colLabels": [
"d6",
"spell"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"1",
"{@spell fear}"
],
[
"2",
"{@spell feign death}"
],
[
"3",
"{@spell fireball}"
],
[
"4",
"{@spell gaseous form}"
],
[
"5",
"{@spell sleet storm}"
],
[
"6",
"{@spell stinking cloud}"
]
]
},
{
"type": "table",
"caption": "4th-Level Spells",
"colLabels": [
"d4",
"spell"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"1",
"{@spell confusion}"
],
[
"2",
"{@spell conjure minor elementals}"
],
[
"3",
"{@spell Evard's black tentacles}"
],
[
"4",
"{@spell ice storm}"
]
]
},
{
"type": "table",
"caption": "5th-Level Spells",
"colLabels": [
"d4",
"spell"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"1",
"{@spell animate objects}"
],
[
"2",
"{@spell cloudkill}"
],
[
"3",
"{@spell cone of cold}"
],
[
"4",
"{@spell flame strike}"
]
]
}
],
"hasFluffImages": true
},
{
"name": "Mizzium Mortar",
"source": "GGR",
"page": 179,
"rarity": "rare",
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d4}",
"charges": 4,
"entries": [
"This short tube, about 2 feet long and 6 inches in diameter, is made from mizzium, a magically enhanced metal alloy forged by the Izzet League. The end that's pointed toward a target is open, and a glowing ball of molten metal can be seen at the other end as long as the mortar has at least 1 charge remaining.",
"The mortar has 4 charges for the following properties. It regains {@dice 1d4} expended charges daily at dawn.",
{
"type": "entries",
"name": "Molten Spray",
"entries": [
"You can expend 1 charge as an action to loose a 30-foot cone of molten mizzium. Each creature in the area must make a DC 15 Dexterity saving throw, taking {@damage 5d4} fire damage on a failed save, or half as much damage on a successful one."
]
},
{
"type": "entries",
"name": "Mizzium Bombard",
"entries": [
"You can expend 3 charges as an action to launch a hail of molten projectiles in a 20-foot-radius, 40-foot-high cylinder centered on a point you can see within 60 feet of you. Each creature in the area must make a DC 15 Dexterity saving throw. A creature takes {@damage 5d8} fire damage on a failed save, or half as much damage on a successful one."
]
}
]
},
{
"name": "Monster Hunter's Pack",
"source": "VRGR",
"page": 34,
"otherSources": [
{
"source": "CoS",
"page": 209
}
],
"type": "G",
"rarity": "none",
"weight": 48.5,
"value": 3300,
"entries": [
"Includes:",
{
"type": "list",
"items": [
"a {@item chest|phb}",
"a {@item crowbar|phb}",
"a {@item hammer|phb}",
"three wooden stakes",
"a {@item holy symbol|phb}",
"a {@item holy water (flask)|phb|flask of holy water}",
"a set of {@item manacles|phb}",
"a {@item steel mirror|phb}",
"a {@item oil (flask)|phb|flask of oil}",
"a {@item tinderbox|phb}",
"3 {@item torch|phb|torches}"
]
}
],
"packContents": [
"backpack|phb",
"chest|phb",
"crowbar|phb",
"hammer|phb",
{
"special": "wooden stake",
"quantity": 3
},
"holy symbol|phb",
"holy water (flask)|phb",
"manacles|phb",
"steel mirror|phb",
"oil (flask)|phb",
"tinderbox|phb",
{
"item": "torch|phb",
"quantity": 3
}
]
},
{
"name": "Moodmark Paint",
"source": "GGR",
"page": 180,
"rarity": "common",
"wondrous": true,
"entries": [
"This thick, black paint is stored in a small jar, containing enough paint to apply moodmarks to one creature. The paint is dabbed on the face in spots or markings that often resemble the eyes of insects or spiders. Applying the paint in this way takes 1 minute.",
"For the next 8 hours, the marks change to reflect your mental state. A creature that can see you and makes a successful DC 10 Wisdom ({@skill Insight}) check can discern whether you are happy, sad, angry, disgusted, surprised, or afraid, as well as the main source of that emotion. For example, you might communicate fear caused by a monster you just saw around the corner, grief at the loss of a friend, or happiness derived from pride in your performance in combat. A dark elf has advantage on this check."
],
"hasFluffImages": true
},
{
"name": "Moonblade",
"source": "DMG",
"page": 217,
"baseItem": "longsword|phb",
"type": "M",
"rarity": "legendary",
"reqAttune": "by an elf or half-elf of neutral good alignment",
"reqAttuneTags": [
{
"race": "elf",
"alignment": [
"N",
"N"
]
},
{
"race": "half-elf",
"alignment": [
"N",
"G"
]
}
],
"sentient": true,
"weight": 3,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"bonusWeapon": "+1",
"entries": [
"Of all the magic items created by the elves, one of the most prized and jealously guarded is a moon blade. In ancient times, nearly all elven noble houses claimed one such blade. Over the centuries, some blades have faded from the world, their magic lost as family lines have become extinct. Other blades have vanished with their bearers during great quests. Thus, only a few of these weapons remain.",
"A moonblade passes down from parent to child. The sword chooses its bearer and remains bonded to that person for life. If the bearer dies, another heir can claim the blade. If no worthy heir exists, the sword lies dormant. It functions like a normal longsword until a worthy soul finds it and lays claim to its power.",
"A moonblade serves only one master at a time. The attunement process requires a special ritual in the throne room of an elven regent or in a temple dedicated to the elven gods.",
"A moonblade won't serve anyone it regards as craven, erratic, corrupt, or at odds with preserving and protecting elvenkind. If the blade rejects you, you make ability checks, attack rolls, and saving throws with disadvantage for 24 hours. If the blade accepts you, you become attuned to it and a new rune appears on the blade. You remain attuned to the weapon until you die or the weapon is destroyed.",
"A moonblade has one rune on its blade for each master it has served (typically {@dice 1d6 + 1}). The first rune always grants a +1 bonus to attack and damage rolls made with this magic weapon. Each rune beyond the first grants the moon blade an additional property. The DM chooses each property or determines it randomly on the Moon Blade Properties table.",
{
"type": "table",
"caption": "Moonblade Properties",
"colLabels": [
"{@dice d100}",
"Property"
],
"colStyles": [
"col-1 text-center",
"col-11"
],
"rows": [
[
"01-40",
"Increase the bonus to attack and damage rolls by 1, to a maximum of +3. Reroll if the moonblade already has a +3 bonus."
],
[
"41-80",
"The moonblade gains a randomly determined {@table Special Features; What Minor Property Does It Have|dmg|minor property} (see \"Special Features\" DMG p143)."
],
[
"81-82",
"The moonblade gains the finesse property."
],
[
"83-84",
"The moonblade gains the thrown property (range 20/60 feet)."
],
[
"85-86",
"The moonblade functions as a {@item defender}."
],
[
"87-90",
"The moon blade scores a critical hit on a roll of 19 or 20."
],
[
"91-92",
"When you hit with an attack using the moon blade, the attack deals an extra {@damage 1d6} slashing damage."
],
[
"93-94",
"When you hit a creature of a specific type (such as dragon, fiend, or undead) with the moonblade, the target takes an extra {@damage 1d6} damage of one of these types: acid, cold, fire, lightning, or thunder."
],
[
"95-96",
"You can use a bonus action to cause the moonblade to flash brightly. Each creature that can see you and is within 30 feet of you must succeed on a DC 15 Constitution saving throw or become {@condition blinded} for 1 minute. A creature can repeat the saving throw at the end of each of its turns, ending the effect on itself on a success. This property can't be used again until you take a short rest while attuned to the weapon."
],
[
"97-98",
"The moonblade functions as a {@item ring of spell storing}."
],
[
"99",
"You can use an action to call forth an elfshadow, provided that you don't already have one serving you. The elfshadow appears in an unoccupied space within 120 feet of you. It uses the statistics for a {@creature shadow}, except it is neutral, immune to effects that turn undead, and doesn't create new shadows. You control this creature, deciding how it acts and moves. It remains until it drops to 0 hit points or you dismiss it as an action."
],
[
"00",
"The moonblade functions as a {@item vorpal sword}."
]
]
},
{
"name": "Sentience",
"type": "entries",
"entries": [
"A moon blade is a sentient neutral good weapon with an Intelligence of 12, a Wisdom of 10, and a Charisma of 12. It has hearing and {@sense darkvision} out to a range of 120 feet.",
"The weapon communicates by transmitting emotions, sending a tingling sensation through the wielder's hand when it wants to communicate something it has sensed. It can communicate more explicitly, through visions or dreams, when the wielder is either in a trance or asleep."
]
},
{
"name": "Personality",
"type": "entries",
"entries": [
"Every moonblade seeks the advancement of elvenkind and elven ideals. Courage, loyalty, beauty, music, and life are all part of this purpose.",
"The weapon is bonded to the family line it is meant to serve. Once it has bonded with an owner who shares its ideals, its loyalty is absolute.",
"If a moon blade has a flaw, it is overconfidence. Once it has decided on an owner, it believes that only that person should wield it, even if the owner falls short of elven ideals."
]
}
],
"hasFluffImages": true
},
{
"name": "Moonstone",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 5000,
"entries": [
"A translucent white with pale blue glow gemstone."
]
},
{
"name": "Moorbounder",
"source": "EGW",
"page": 132,
"type": "MNT",
"rarity": "none",
"value": 40000,
"entries": [
"Beasts of burden are common throughout Xhorhas, and often horses and other riding animals are the best or only option. For those who require speed and have a little more coin to spend, the best option is often a moorbounder. However, moorbounders that haven't undergone proper training or established bonds of trust with their masters tend to attack and even eat their riders.",
"Moorbounders can cost anywhere from 300 to 500 gp each. They're often cheaper and more abundant in towns on the wastes than in cities like Rosohna."
]
},
{
"name": "Moss Agate",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 1000,
"entries": [
"A translucent pink or yellow-white with mossy gray or green markings gemstone."
]
},
{
"name": "Mule",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "MNT",
"rarity": "none",
"value": 800,
"carryingCapacity": 420,
"speed": 40
},
{
"name": "Mummy Rot Antidote",
"source": "IMR",
"page": 94,
"type": "P",
"rarity": "uncommon",
"entries": [
"White dust swirls constantly within this pale gray potion. When you drink the potion, it cures you of mummy rot."
]
},
{
"name": "Murgaxor's Elixir of Life",
"source": "SCC",
"page": 179,
"type": "G",
"rarity": "unknown",
"entries": [
"Whoever drinks this concoction gains advantage on death saving throws for 24 hours."
]
},
{
"name": "Murgaxor's Orb",
"source": "SCC",
"page": 126,
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"curse": true,
"sentient": true,
"entries": [
"Roiling green mist fills this glass orb, which the exiled Strixhaven mage {@creature Murgaxor|SCC} once used in foul magical experiments. Murgaxor's spirit has infused the orb, which he uses to spread a terrible curse among Strixhaven's students.",
{
"type": "entries",
"name": "Sentience",
"entries": [
"Murgaxor's orb is a sentient, chaotic evil magic item with the following properties:",
{
"type": "list",
"items": [
"The orb has an Intelligence of 20, a Wisdom of 16, and a Charisma of 16, as well as hearing and {@sense darkvision} out to a range of 30 feet.",
"The orb can speak, read, and understand Common, and it can communicate telepathically with any creature touching it.",
"At any time during your turn, the orb can cast the {@spell suggestion} spell (save DC 17), targeting you or one other creature that touched the orb within the last 24 hours. This isn't a power of the orb that you control."
]
}
]
},
{
"type": "entries",
"name": "Curse",
"entries": [
"Any Humanoid you touch while holding the orb must succeed on a DC 10 Wisdom saving throw or become cursed. Each creature cursed by the orb bears an echo of Murgaxor's hateful thoughts, and that creature suffers from headaches that are persistent but not debilitating until the curse ends. On your turn, the orb can use an action to produce one of the following effects, targeting one or more creatures it has cursed:"
]
},
{
"type": "entries",
"name": "Unconsciousness",
"entries": [
"The cursed creature falls {@condition unconscious} for 1 hour. The creature is roused if it takes damage or someone uses an action to shake or slap it awake."
]
},
{
"type": "entries",
"name": "Visions of Terror",
"entries": [
"The cursed creature sees terrifying visions, causing it to view all creatures that aren't also cursed as dangerous monsters for 10 minutes. The cursed creature must use its action each round to make one attack against the nearest non-cursed creature. If the cursed creature has multiple possible targets, it attacks one at random. This effect ends if the cursed creature is {@condition incapacitated}.",
"After either of these effects ends, the affected creature is no longer cursed. The curse can also be removed from a creature with a {@spell remove curse} spell or similar magic. All cases of the curse end if Murgaxor's orb is destroyed."
]
},
{
"type": "entries",
"name": "Magical Signature",
"entries": [
"As a side effect of the orb's curse, the spell {@spell detect magic} reveals an aura of enchantment surrounding creatures bearing the curse. This aura is distinctive, but in a way {@spell detect magic} offers no further details about."
]
},
{
"type": "entries",
"name": "Destroying the Orb",
"entries": [
"Murgaxor's orb has AC 18; 20 hit points; immunity to necrotic, poison, and psychic damage; and resistance to all other types of damage. If reduced to 0 hit points, the orb shatters."
]
}
],
"attachedSpells": [
"suggestion"
]
},
{
"name": "Muroosa Balm",
"source": "EGW",
"page": 70,
"type": "G",
"resist": [
"fire"
],
"rarity": "none",
"value": 10000,
"entries": [
"This paste made from the muroosa bush is known to help prevent sunburn, but it is also a fire retardant. After spending 1 minute applying a quarter pint of muroosa balm to your skin, you gain resistance against fire damage for 1 hour.",
"A dose of muroosa balm sufficient for treating sunburn costs 1 gp."
]
},
{
"name": "Mystery Key",
"source": "XGE",
"page": 138,
"tier": "minor",
"rarity": "common",
"wondrous": true,
"entries": [
"A question mark is worked into the head of this key. The key has a {@chance 5} chance of unlocking any lock into which it's inserted. Once it unlocks something, the key disappears."
]
},
{
"name": "Narycrash",
"source": "DSotDQ",
"page": 188,
"type": "G",
"rarity": "none",
"entries": [
"This backpack-sized device holds a balloon-based parachute. If you fall while wearing this device, you can use your reaction to deploy the parachute. Once deployed, the parachute rapidly inflates, and you descend 60 feet per round and take no damage from falling. When you are 10 feet away from the ground, roll a {@dice d20}. If you roll a 5 or less, the parachute gives out, and you begin to fall normally."
],
"hasFluffImages": true
},
{
"name": "Nature's Mantle",
"source": "TCE",
"page": 133,
"rarity": "uncommon",
"reqAttune": "by a druid or ranger",
"reqAttuneTags": [
{
"class": "druid"
},
{
"class": "ranger"
}
],
"wondrous": true,
"weight": 2,
"focus": [
"Druid",
"Ranger"
],
"entries": [
"This cloak shifts color and texture to blend with the terrain surrounding you. While wearing the cloak, you can use it as a spellcasting focus for your druid and ranger spells.",
"While you are in an area that is lightly obscured, you can {@action Hide} as a bonus action even if you are being directly observed."
],
"hasFluffImages": true
},
{
"name": "Nautiloid",
"source": "AAG",
"page": 0,
"type": "SPC",
"rarity": "none",
"value": 5000000,
"crew": 20,
"vehAc": 15,
"vehHp": 400,
"vehDmgThresh": 15,
"vehSpeed": 4.5,
"capCargo": 17,
"entries": [
"Built and used by mind flayers, nautiloids are designed exclusively for space travel. They can't float on water, nor can they land safely on the ground.",
"As an action, a creature attuned to a nautiloid's spelljamming helm and in physical contact with the ship can transport the nautiloid and all creatures and objects aboard it to a different plane of existence, at or near a destination envisioned by the spelljammer (or to a random location on the plane if no destination is envisioned). This property is a feature of the ship, not the spelljamming helm. Each time this property is used, roll a {@dice d6}. On a 5–6, the property recharges after 1 minute; otherwise, it can't be used again for 24 hours."
],
"seeAlsoVehicle": [
"Nautiloid|AAG"
]
},
{
"name": "Navigation Orb",
"source": "SKT",
"page": 235,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"A navigation orb is a hollow, 7-foot-diameter sphere of thin, polished mithral with a large skye (cloud) rune embossed on its outer surface. The orb levitates 10 feet above the ground and is keyed to a particular cloud castle, allowing you to control that castle's altitude and movement while the orb is inside the castle. If the orb is destroyed or removed from its castle, the castle's altitude and location remain fixed until the orb is returned or replaced.",
"As an action, you can cause one of the following effects to occur if you are touching the orb:",
"The castle moves at a speed of 1 mph in a straight line, in a direction of your choice, until the castle stops or is made to stop, or until another action is used to change its direction. If this movement brings the castle into contact with the ground, the castle lands gently.",
"The castle, if it is moving, comes to a gradual stop.",
"The castle makes a slow, 90-degree turn clockwise or counterclockwise (turning a northerly view into a westerly view, for example). The castle can turn while it is moving in a straight line.",
"Any creature touching the orb knows the altitude of the base of the castle above the ground or water below it."
]
},
{
"name": "Navigator's Tools",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"additionalSources": [
{
"source": "XGE",
"page": 83
}
],
"type": "T",
"rarity": "none",
"weight": 2,
"value": 2500,
"entries": [
"This set of instruments is used for navigation at sea. Proficiency with navigator's tools lets you chart a ship's course and follow navigation charts. In addition, these tools allow you to add your proficiency bonus to any ability check you make to avoid getting lost at sea."
],
"additionalEntries": [
"Proficiency with navigator's tools helps you determine a true course based on observing the stars. It also grants you insight into charts and maps while developing your sense of direction.",
{
"type": "entries",
"name": "Components",
"entries": [
"Navigator's tools include a sextant, a compass, calipers, a ruler, parchment, ink, and a quill."
]
},
{
"type": "entries",
"name": "Survival",
"entries": [
"Knowledge of navigator's tools helps you avoid becoming lost and also grants you insight into the most likely location for roads and settlements."
]
},
{
"type": "entries",
"name": "Sighting",
"entries": [
"By taking careful measurements, you can determine your position on a nautical chart and the time of day."
]
},
{
"type": "table",
"caption": "Navigator's Tools",
"colLabels": [
"Activity",
"DC"
],
"colStyles": [
"col-10",
"col-2 text-center"
],
"rows": [
[
"Plot a course",
"10"
],
[
"Discover your position on a nautical chart",
"15"
]
]
}
]
},
{
"name": "Necklace of Adaptation",
"source": "DMG",
"page": 182,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"weight": 1,
"entries": [
"While wearing this necklace, you can breathe normally in any environment, and you have advantage on saving throws made against harmful gases and vapors (such as {@spell cloudkill} and {@spell stinking cloud} effects, inhaled poisons, and the breath weapons of some dragons)."
],
"optionalfeatures": [
"replicate magic item|tce"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Necklace of electrum medallions with red and blue tournalines (Brazen Coalition)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 75000
},
{
"name": "Necklace of Fireballs",
"source": "DMG",
"page": 182,
"srd": true,
"tier": "minor",
"rarity": "rare",
"wondrous": true,
"weight": 1,
"entries": [
"This necklace has {@dice 1d6 + 3} beads hanging from it. You can use an action to detach a bead and throw it up to 60 feet away. When it reaches the end of its trajectory, the bead detonates as a 3rd-level {@spell fireball} spell (save DC 15).",
"You can hurl multiple beads, or even the whole necklace, as one action. When you do so, increase the level of the {@spell fireball} by 1 for each bead beyond the first."
],
"attachedSpells": [
"fireball"
],
"lootTables": [
"Magic Item Table C"
],
"hasFluffImages": true
},
{
"name": "Necklace of jade and pink pearls (River Heralds)",
"source": "PSX",
"page": 25,
"type": "$",
"rarity": "none",
"value": 250000
},
{
"name": "Necklace of Prayer Beads",
"source": "DMG",
"page": 182,
"srd": true,
"tier": "major",
"rarity": "rare",
"reqAttune": "by a cleric, druid, or paladin",
"reqAttuneTags": [
{
"class": "cleric"
},
{
"class": "druid"
},
{
"class": "paladin"
}
],
"wondrous": true,
"weight": 1,
"entries": [
"This necklace has {@dice 1d4 + 2} magic beads made from aquamarine, black pearl, or topaz. It also has many nonmagical beads made from stones such as amber, bloodstone, citrine, coral, jade, pearl, or quartz. If a magic bead is removed from the necklace, that bead loses its magic.",
"Six types of magic beads exist. The DM decides the type of each bead on the necklace or determines it randomly. A necklace can have more than one bead of the same type. To use one, you must be wearing the necklace. Each bead contains a spell that you can cast from it as a bonus action (using your spell save DC if a save is necessary). Once a magic bead's spell is cast, that bead can't be used again until the next dawn.",
{
"type": "table",
"colLabels": [
"{@dice d20}",
"Bead of...",
"Spell"
],
"colStyles": [
"col-1 text-center",
"col-2",
"col-9"
],
"rows": [
[
"1-6",
"Blessing",
"{@spell Bless}"
],
[
"7-12",
"Curing",
"{@spell Cure wounds} (2nd level) or {@spell lesser restoration}"
],
[
"13-16",
"Favor",
"{@spell Greater restoration}"
],
[
"17-18",
"Smiting",
"{@spell Branding smite}"
],
[
"19",
"Summons",
"{@spell Planar ally}"
],
[
"20",
"Wind walking",
"{@spell Wind walk}"
]
]
}
],
"attachedSpells": [
"bless",
"cure wounds",
"lesser restoration",
"greater restoration",
"branding smite",
"planar ally",
"wind walk"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Necklace string of small pink pearls",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 250000
},
{
"name": "Necrotic Absorbing Tattoo",
"source": "TCE",
"page": 119,
"resist": [
"necrotic"
],
"detail1": "black",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"hasRefs": true,
"entries": [
"{#itemEntry Absorbing Tattoo|TCE}"
]
},
{
"name": "Needle of Mending",
"source": "EGW",
"page": 268,
"baseItem": "dagger|PHB",
"type": "M",
"rarity": "rare",
"reqAttune": true,
"weight": 1,
"weaponCategory": "simple",
"property": [
"F",
"L",
"T"
],
"range": "20/60",
"dmg1": "1d4",
"dmgType": "P",
"bonusWeapon": "+1",
"weapon": true,
"entries": [
"This weapon is a magic dagger disguised as a sewing needle. When you hold it and use a bonus action to speak its command word, it transforms into a dagger or back into a needle.",
"You gain a +1 bonus to attack and damage rolls made with the dagger. While holding it, you can use an action to cast the {@spell mending} cantrip from it."
],
"attachedSpells": [
"mending"
]
},
{
"name": "Nepenthe",
"source": "VRGR",
"page": 86,
"baseItem": "longsword|PHB",
"type": "M",
"rarity": "legendary",
"reqAttune": "by a paladin",
"reqAttuneTags": [
{
"class": "paladin"
}
],
"sentient": true,
"weight": 3,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"bonusWeapon": "+3",
"sword": true,
"weapon": true,
"entries": [
"You gain a +3 bonus to attack and damage rolls made with this magic weapon. When you hit a fiend or an undead with it, that creature takes an extra {@damage 2d10} radiant damage.",
"While you hold the drawn sword, it creates an aura in a 10-foot radius around you. You and all creatures friendly to you in the aura have advantage on saving throws against spells and other magical effects. If you have 17 or more levels in the paladin class, the radius of the aura increases to 30 feet.",
{
"type": "entries",
"name": "Sentience",
"entries": [
"Nepenthe is a sentient, neutral evil weapon with an Intelligence of 10, a Wisdom of 8, and a Charisma of 18. It has hearing and {@sense darkvision} out to a range of 60 feet. It can read and understand Elvish. It can also speak Elvish, but only through the voice of its wielder, with whom the sword can communicate telepathically."
]
},
{
"type": "entries",
"name": "Personality",
"entries": [
"In its lifetime, the sword has beheaded thousands of criminals, not all of whom were guilty of the crimes for which they were convicted. The sword cannot distinguish the guilty from the innocent. With each beheading, it hungers for more justice and blood. The sword is corrupt and irredeemable."
]
}
],
"hasFluffImages": true
},
{
"name": "Nether Scroll of Azumar",
"source": "CM",
"page": 210,
"type": "SC",
"rarity": "legendary",
"entries": [
"Unlike most scrolls, a Nether Scroll of Azumar is not a consumable magic item. It takes 30 days of concentrated study—at least 8 hours per day—to attempt to understand this scroll. After completing this study, you must make a DC 25 Intelligence ({@skill Arcana}) check. If this check fails, you take {@damage 16d10} psychic damage, and you can attempt the check again after another 30 days of concentrated study.",
"When you succeed on the check, you gain the following benefits:",
{
"type": "list",
"items": [
"Your Intelligence score increases by 2, to a maximum of 22. Once you gain this benefit, you can't use this scroll to increase your Intelligence again.",
"You gain advantage on saving throws against spells and other magical effects."
]
},
"When you gain the scroll's benefits, a {@creature stone golem} magically appears in an unoccupied space within 60 feet of you and acts as your ally. If you die, the golem turns to dust."
]
},
{
"name": "Nib",
"source": "WDH",
"page": 169,
"type": "$",
"rarity": "none",
"value": 1,
"entries": [
"Copper coin about the size of a thumbnail (1 nib = 1 common copper coin)"
]
},
{
"name": "Night Caller",
"source": "TftYP",
"page": 228,
"rarity": "uncommon",
"wondrous": true,
"weight": 1,
"entries": [
"This whistle is carved from transparent crystal, and it resembles a tiny dragon curled up like a snail. The name Night Caller is etched on the whistle in Dwarvish runes. If a character succeeds on a DC 20 Intelligence ({@skill Arcana} or {@skill History}) check, the character recalls lore that says the {@creature duergar} made several such whistles for various groups in an age past.",
"If you blow the whistle in darkness or under the night sky, it allows you to cast the {@spell animate dead} spell. The target can be affected through up to 10 feet of soft earth or similar material, and if it is, it takes 1 minute to claw its way to the surface to serve you. Once the whistle has animated an undead creature, it can't do so again until 7 days have passed.",
"Once every 24 hours, you can blow the whistle to reassert control over one or two creatures you animated with it."
],
"attachedSpells": [
"animate dead"
]
},
{
"name": "Nightbringer",
"source": "MCV2DC",
"baseItem": "mace|PHB",
"type": "M",
"immune": [
"acid",
"cold",
"fire",
"lightning",
"poison"
],
"rarity": "legendary",
"reqAttune": true,
"weight": 4,
"weaponCategory": "simple",
"dmg1": "1d6",
"dmgType": "B",
"bonusWeapon": "+3",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6}",
"charges": 6,
"weapon": true,
"entries": [
"This heavy mace has a dark oaken handle and a head of blackened steel trimmed with gold. In combat, the mace's head is wreathed in inky black energy.",
"You gain a +3 bonus to attack and damage rolls made with this weapon. When you hit with an attack roll using it, the target takes an additional {@damage 4d4} radiant damage.",
"If you have an evil alignment, you also gain the following benefits when attuned to Nightbringer:",
{
"type": "entries",
"name": "Blessing of the Dragon Queen",
"entries": [
"You are immune to the {@condition charmed} and {@condition frightened} conditions, and you gain immunity to one of the following damage types (choose when you attune to the weapon): acid, cold, fire, lightning, or poison."
]
},
{
"type": "entries",
"name": "Eyes of Midnight",
"entries": [
"You have {@sense darkvision} with a range of 60 feet. If you already have {@sense darkvision}, its range increases by 60 feet."
]
},
{
"type": "entries",
"name": "Midnight Shroud",
"entries": [
"The weapon has 6 charges. When you hit a creature with an attack using this weapon, you can expend one charge to force the creature to make a DC 20 Constitution saving throw. On a failed save, the creature is {@condition blinded} until the start of your next turn. The weapon regains {@dice 1d6} expended charges daily at dawn."
]
}
]
},
{
"name": "Nightfall Pearl",
"source": "EGW",
"page": 268,
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"entries": [
"Used to summon night, this 6-inch-diameter, jet-black orb is cold to the touch. You can spend 10 minutes to activate it, causing the area within 10 miles of it at the moment of activation to become night even if it is daytime. This night lasts for 24 hours, until you cancel it as an action, or until your attunement to the pearl ends. Once used, the pearl can't be used again for 24 hours."
]
},
{
"name": "Nightspider",
"source": "AAG",
"page": 0,
"type": "SPC",
"rarity": "none",
"value": 5000000,
"crew": 25,
"vehAc": 19,
"vehHp": 300,
"vehDmgThresh": 15,
"vehSpeed": 4.5,
"capCargo": 50,
"entries": [
"Neogi terrorize Wildspace in ships of their own construction, which others call nightspiders. These vessels often wait in ambush for other ships. Once a target ship is identified, the neogi try to steal its spelljamming helm and capture its crew.",
"A nightspider's crew usually consists of nineteen neogi and up to a half-dozen umber hulks, which the neogi use as shock troops.",
"Nightspiders are designed for space travel alone and can't float on water or land safely on the ground (the weight of the ship would snap its spindly legs, destroying its weblike rigging in the process). Standard weaponry on a nightspider includes four ballistae (two forward-facing and two aft-facing) and a forward-facing mangonel."
],
"seeAlsoVehicle": [
"Nightspider|AAG"
]
},
{
"name": "Nimblewright Detector",
"source": "WDH",
"page": 47,
"type": "OTH",
"rarity": "unknown",
"entries": [
"To activate the nimblewright detector, a character must hold down its trigger. When the activated device comes within 500 feet of a nimblewright other than Nim, the umbrella begins to spin, whir, and click. The spinning, whirring, and clicking accelerates as the distance to the target lessens, reaching maximum velocity and volume when a nimblewright other than Nim is within 30 feet of the device."
]
},
{
"name": "Nolzur's Marvelous Pigments",
"source": "DMG",
"page": 183,
"srd": "Marvelous Pigments",
"tier": "minor",
"rarity": "very rare",
"wondrous": true,
"weight": 1,
"entries": [
"Typically found in {@dice 1d4} pots inside a fine wooden box with a brush (weighing 1 pound in total), these pigments allow you to create three-dimensional objects by painting them in two dimensions. The paint flows from the brush to form the desired object as you concentrate on its image.",
"Each pot of paint is sufficient to cover 1,000 square feet of a surface, which lets you create inanimate objects or terrain features—such as a door, a pit, flowers, trees, cells, rooms, or weapons—that are up to 10,000 cubic feet. It takes 10 minutes to cover 100 square feet.",
"When you complete the painting, the object or terrain feature depicted becomes a real, nonmagical object. Thus, painting a door on a wall creates an actual door that can be opened to whatever is beyond. Painting a pit on a floor creates a real pit, and its depth counts against the total area of objects you create.",
"Nothing created by the pigments can have a value greater than 25 gp. If you paint an object of greater value (such as a diamond or a pile of gold), the object looks authentic, but close inspection reveals it is made from paste, bone, or some other worthless material.",
"If you paint a form of energy such as fire or lightning, the energy appears but dissipates as soon as you complete the painting, doing no harm to anything."
],
"lootTables": [
"Magic Item Table D"
],
"hasFluffImages": true
},
{
"name": "Oathbow",
"source": "DMG",
"page": 183,
"srd": true,
"baseItem": "longbow|phb",
"type": "R",
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"weight": 2,
"weaponCategory": "martial",
"property": [
"A",
"H",
"2H"
],
"range": "150/600",
"dmg1": "1d8",
"dmgType": "P",
"entries": [
"When you nock an arrow on this bow, it whispers in Elvish, \"Swift defeat to my enemies.\" When you use this weapon to make a ranged attack, you can, as a command phrase, say, \"Swift death to you who have wronged me.\" The target of your attack becomes your sworn enemy until it dies or until dawn seven days later. You can have only one such sworn enemy at a time. When your sworn enemy dies, you can choose a new one after the next dawn.",
"When you make a ranged attack roll with this weapon against your sworn enemy, you have advantage on the roll. In addition, your target gains no benefit from cover, other than total cover, and you suffer no disadvantage due to long range. If the attack hits, your sworn enemy takes an extra {@damage 3d6} piercing damage.",
"While your sworn enemy lives, you have disadvantage on attack rolls with all other weapons."
],
"ammoType": "arrow|phb",
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Obsidian",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 1000,
"entries": [
"An opaque black gemstone."
]
},
{
"name": "Obsidian Flint Dragon Plate",
"source": "BGDIA",
"page": 224,
"baseItem": "plate armor|phb",
"type": "HA",
"rarity": "legendary",
"weight": 65,
"ac": 18,
"strength": "15",
"bonusAc": "+2",
"stealth": true,
"entries": [
"You gain a +2 bonus to AC and resistance to poison damage while you wear this armor. In addition, you gain advantage on ability checks and saving throws made to avoid or end the {@condition grappled} condition on yourself."
]
},
{
"name": "Obsidian statuette with gold fittings and inlay",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 75000
},
{
"name": "Obviator's Lenses",
"source": "AI",
"page": 30,
"rarity": "varies",
"wondrous": true,
"entries": [
"Also at rank 2, you gain the use of an uncommon magic item taking the form of a pair of spectacles, a spyglass, a monocle, or any other device with one or two lenses. When you look through the obviator's lenses, you use a bonus action to make them function as {@item eyes of minute seeing} or {@item eyes of the eagle}. Once you make this choice, it cannot be changed until the next dawn.",
{
"type": "entries",
"name": "Enhanced Lenses",
"entries": [
"At rank 4, your obviator's lenses gain additional power and become a rare magic item. The lenses now function as both {@item eyes of the eagle} and {@item eyes of minute seeing}.",
"Additionally, you can focus the power of the lenses to gain accuracy in combat, gaining advantage on a weapon attack roll (no action required). If that attack hits, roll one additional weapon damage die. This property of the lenses can't be used again until the next dawn."
]
}
]
},
{
"name": "Occultant Abacus",
"source": "AI",
"page": 31,
"rarity": "varies",
"reqAttune": true,
"wondrous": true,
"entries": [
"Head office grants you the use of a unique item known as an occultant abacus (sometimes just referred to as an occultant), whose beads resemble tiny skulls.",
{
"type": "entries",
"name": "Read the Kill",
"entries": [
"Also at rank 1, your occultant abacus not only tracks your franchise's kills, it helps you determine the impact of those kills on the franchise's fate. Over a period of 1 minute, you can study a creature killed by someone in your franchise within the last 24 hours, then grant the character who slew it a {@dice d10}. Once within the next hour, that character can add the {@dice d10} to an attack roll, ability check, or saving throw. If it's not clear who administered the killing blow, you grant this benefit to a random creature involved in the fight. Once you use this feature, you can't use it again until you finish a long rest.",
{
"type": "table",
"colLabels": [
"d6",
"Reading"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"1",
"Using a tiny bellows to pump one last breath into a corpse."
],
[
"2",
"Reading the entrails with special reading-the-entrails glasses."
],
[
"3",
"Burning a small piece of the body and looking for shapes in the smoke."
],
[
"4",
"A cautious, reasoned, professional guesstimate."
],
[
"5",
"Careful measurements. The distance between nostrils. The difference between the index finger and the middle finger. The elasticity of the ear lobe."
],
[
"6",
"You take a long, careful look, and then decide based on which fellow franchisee has been nice to you lately."
]
]
}
]
},
{
"type": "entries",
"name": "Eldritch Occultant",
"entries": [
"Starting at rank 2, your occultant abacus becomes an uncommon magic item that can track lives both eliminated and saved. While holding your occultant abacus within 5 feet of a creature killed within the past 24 hours, you can cast the {@spell augury} spell. The course of action you inquire about with the spell does not need to have any connection to the dead creature. This property of the occultant abacus can't be used again until the next dawn."
]
},
{
"type": "entries",
"name": "Bring Out Your Dead",
"entries": [
"Also at rank 2, you regain the use of your Read the Kill feature after you finish a short or long rest."
]
},
{
"type": "entries",
"name": "Bead of Instant Karma",
"entries": [
"At rank 3, one of the beads on your occultant abacus channels the power of instant karma, turning the occultant abacus into a rare magic item. As a reaction, you can target one creature you can see that is about to attempt an ability check, attack roll, or saving throw, either granting advantage or imposing disadvantage on the roll. The bead crumbles to dust when used, reappearing on your occultant abacus at the next dawn."
]
},
{
"type": "entries",
"name": "Bead of Diverted Karma",
"entries": [
"At rank 4, one of the beads on your occultant abacus allows you to divert karma to where it's needed, turning the occultant abacus into a very rare magic item. While the bead is unused, you know automatically when any creature you can see is about to make an ability check, attack roll, or saving throw with disadvantage. When such a roll is made, you can use a reaction to grant a {@dice d10} to a different creature you can see. That creature can add the {@dice d10} to any ability check, attack roll, or saving throw it makes within the next minute. The bead crumbles to dust when used, reappearing on your occultant abacus at the next dawn."
]
},
{
"type": "entries",
"name": "Correct the Balance",
"entries": [
"As a rank 4 occultant, you learn that sometimes the death of a creature has unintended consequences. Within seven days of a creature's death, you can use your occultant abacus to divine ways to reverse or mitigate events resulting from that death. As an action, make a DC 15 Intelligence ({@skill Religion}) check. On a success, you learn the relevant information based on the nature of the creature and its place in the world.",
"If you fail the check, this property of the occultant abacus can't be used again until the next dawn. If you succeed on the check, this property can't be used again until dawn seven days later."
]
}
],
"attachedSpells": [
"augury"
],
"hasFluffImages": true
},
{
"name": "Oil (flask)",
"source": "PHB",
"page": 152,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 1,
"value": 10,
"entries": [
"Oil usually comes in a clay flask that holds 1 pint. As an action, you can splash the oil in this flask onto a creature within 5 feet of you or throw it up to 20 feet, shattering it on impact. Make a ranged attack against a target creature or object, treating the oil as an improvised weapon. On a hit, the target is covered in oil. If the target takes any fire damage before the oil dries (after 1 minute), the target takes an additional 5 fire damage from the burning oil. You can also pour a flask of oil on the ground to cover a 5-foot-square area, provided that the surface is level. If lit, the oil burns for 2 rounds and deals 5 fire damage to any creature that enters the area or ends its turn in the area. A creature can take this damage only once per turn."
]
},
{
"name": "Oil of Etherealness",
"source": "DMG",
"page": 183,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "rare",
"entries": [
"Beads of this cloudy gray oil form on the outside of its container and quickly evaporate. The oil can cover a Medium or smaller creature, along with the equipment it's wearing and carrying (one additional vial is required for each size category above Medium). Applying the oil takes 10 minutes. The affected creature then gains the effect of the {@spell etherealness} spell for 1 hour."
],
"attachedSpells": [
"etherealness"
],
"lootTables": [
"Magic Item Table C"
]
},
{
"name": "Oil of Sharpness",
"source": "DMG",
"page": 184,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "very rare",
"bonusWeapon": "+3",
"entries": [
"This clear, gelatinous oil sparkles with tiny, ultrathin silver shards. The oil can coat one slashing or piercing weapon or up to 5 pieces of slashing or piercing ammunition. Applying the oil takes 1 minute. For 1 hour, the coated item is magical and has a +3 bonus to attack and damage rolls."
],
"lootTables": [
"Magic Item Table D"
]
},
{
"name": "Oil of Slipperiness",
"source": "DMG",
"page": 184,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "uncommon",
"entries": [
"This sticky black unguent is thick and heavy in the container, but it flows quickly when poured. The oil can cover a Medium or smaller creature, along with the equipment it's wearing and carrying (one additional vial is required for each size category above Medium). Applying the oil takes 10 minutes. The affected creature then gains the effect of a {@spell freedom of movement} spell for 8 hours.",
"Alternatively, the oil can be poured on the ground as an action, where it covers a 10-foot square, duplicating the effect of the {@spell grease} spell in that area for 8 hours."
],
"attachedSpells": [
"freedom of movement"
],
"lootTables": [
"Magic Item Table B"
]
},
{
"name": "Oil of Taggit",
"source": "DMG",
"page": 258,
"srd": true,
"type": "G",
"rarity": "none",
"value": 40000,
"poison": true,
"entries": [
"A creature subjected to this poison must succeed on a DC 13 Constitution saving throw or become {@condition poisoned} for 24 hours. The {@condition poisoned} creature is {@condition unconscious}. The creature wakes up if it takes damage."
],
"poisonTypes": [
"contact"
]
},
{
"name": "Old masterpiece painting",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 250000
},
{
"name": "Olisuba Leaf",
"source": "EGW",
"page": 70,
"type": "G",
"rarity": "none",
"value": 5000,
"entries": [
"These dried leaves of the Olisuba tree, when steeped to make a tea, can help a body recover from strenuous activity. If you drink a dose of Olisuba tea during a long rest, your {@condition exhaustion} level is reduced by 2 instead of 1 at the end of that long rest."
]
},
{
"name": "Onyx",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 5000,
"entries": [
"An opaque black and white banded, or pure black or white gemstone."
]
},
{
"name": "Opal",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 100000,
"entries": [
"A translucent pale blue with green and golden mottling gemstone."
]
},
{
"name": "Opal of the Ild Rune",
"source": "SKT",
"page": 235,
"resist": [
"cold"
],
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"This triangular fire opal measures about three inches on each side and is half an inch thick. The ild (fire) rune shimmers within its core, causing it to be slightly warm to the touch. The opal has the following properties, which work only while it's on your person.",
{
"name": "Ignite",
"type": "entries",
"entries": [
"As an action, you can ignite an object within 10 feet of you. The object must be flammable, and the fire starts in a circle no larger than 1 foot in diameter."
]
},
{
"name": "Fires Friend",
"type": "entries",
"entries": [
"You have resistance to cold damage."
]
},
{
"name": "Fire Tamer",
"type": "entries",
"entries": [
"As an action, you can extinguish any open flame within 10 feet of you. You choose how much fire to extinguish in that radius."
]
},
{
"name": "Gift of Flame",
"type": "entries",
"entries": [
"You can transfer the opal's magic to a nonmagical item—a weapon or a suit of armor—by tracing the ild rune there with your finger. The transfer takes 8 hours of work that requires the two items to be within 5 feet of each other. At the end, the opal is destroyed, and the rune appears in red on the chosen item, which gains a benefit based on its form:",
{
"type": "list",
"style": "list-hang-notitle",
"items": [
{
"type": "item",
"name": "Weapon",
"entry": "The weapon is now an uncommon magic weapon. It deals an extra {@damage 1d6} fire damage to any target it hits."
},
{
"type": "item",
"name": "Armor",
"entry": "The armor is now a rare magic item that requires attunement. You have resistance to cold damage while wearing the armor."
}
]
}
]
}
]
},
{
"name": "Opal of the Ild Rune",
"source": "UAPrestigeClassesRunMagic",
"page": 4,
"type": "MR",
"resist": [
"cold"
],
"rarity": "rare",
"reqAttune": true,
"entries": [
"This triangular fire opal measures about three inches on each side and is half an inch thick. The ild rune—the rune of fire—shimmers within its core. Grasping this object causes a split second of searing, fiery pain to pass through you. That pain quickly fades, giving way to a warming glow.",
{
"type": "entries",
"name": "Ignite (Simple Property)",
"entries": [
"As an action, you scribe the ild rune using ash onto a flammable object. That object immediately bursts into flame. While it burns, the fire extends 1 foot out from the rune you scribed."
]
},
{
"type": "entries",
"name": "Fire Tamer (Simple Property)",
"entries": [
"As an action, you touch an open flame and scribe the ild rune within it with a hand motion. This causes the flame to immediately extinguish. For a large blaze, the fire is extinguished in a 10-foot radius around you. You can extend this distance by expending a spell slot when using the ild rune in this manner. The radius extends by 20 feet per level of the expended spell slot."
]
},
{
"type": "entries",
"name": "Fire's Friend (Simple Property)",
"entries": [
"While you are attuned to this rune, you have resistance to cold damage."
]
},
{
"type": "entries",
"name": "Combustion (Complex Property)",
"entries": [
"As an action, you scribe this rune using ash onto a creature within your reach as you expend a spell slot. The creature automatically takes {@damage 1d10} fire damage plus {@damage 1d10} fire damage per level of the expended spell slot."
]
},
{
"type": "entries",
"name": "Flame Brand (Complex Property)",
"entries": [
"Over the course of a short rest, you inscribe this rune using ash onto a melee or ranged weapon, or onto up to 20 pieces of ammunition. The weapon or ammunition gains a ghostly aura of yellow flame and deals fire damage instead of piercing, slashing, or bludgeoning damage.",
"In addition, you can expend a spell slot while using this property to grant the weapon or ammunition a bonus to attack rolls and damage rolls equal to the spell slot's level divided by three.",
"These effects last for 24 hours or until you use this property again."
]
},
{
"type": "entries",
"name": "Flame Stoker (Complex Property)",
"entries": [
"While you are attuned to this rune, your fire attacks are deadlier. Whenever you roll fire damage from an attack or a spell you cast, you can reroll that damage and use the higher result."
]
}
]
},
{
"name": "Orb of Direction",
"source": "XGE",
"page": 138,
"tier": "minor",
"rarity": "common",
"wondrous": true,
"weight": 3,
"entries": [
"While holding this orb, you can use an action to determine which way is north. This property functions only on the Material Plane."
],
"hasFluffImages": true
},
{
"name": "Orb of Dragonkind",
"source": "DMG",
"page": 225,
"srd": true,
"rarity": "artifact",
"reqAttune": true,
"wondrous": true,
"weight": 3,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d4 + 3}",
"charges": 7,
"entries": [
"Ages past, on the world of Krynn, elves and humans waged a terrible war against evil dragons. When the world seemed doomed, the wizards of the Towers of High Sorcery came together and worked their greatest magic, forging five Orbs of Dragonkind (or Dragon Orbs) to help them defeat the dragons. One orb was taken to each of the five towers, and there they were used to speed the war toward a victorious end. The wizards used the orbs to lure dragons to them, then destroyed the dragons with powerful magic.",
"As the Towers of High Sorcery fell in later ages, the orbs were destroyed or faded into legend, and only three are thought to survive. Their magic has been warped and twisted over the centuries, so although their primary purpose of calling dragons still functions, they also allow some measure of control over dragons.",
"Each orb contains the essence of an evil dragon, a presence that resents any attempt to coax magic from it. Those lacking in force of personality might find themselves enslaved to an orb.",
"An orb is an etched crystal globe about 10 inches in diameter. When used, it grows to about 20 inches in diameter, and mist swirls inside it.",
"While attuned to an orb, you can use an action to peer into the orb's depths and speak its command word. You must then make a DC 15 Charisma check. On a successful check, you control the orb for as long as you remain attuned to it. On a failed check, you become {@condition charmed} by the orb for as long as you remain attuned to it.",
"While you are {@condition charmed} by the orb, you can't voluntarily end your attunement to it, and the orb casts {@spell suggestion} on you at will (save DC 18), urging you to work toward the evil ends it desires. The dragon essence within the orb might want many things: the annihilation of a particular people, freedom from the orb, to spread suffering in the world, to advance the worship of Takhisis ({@creature Tiamat|RoT|Tiamat's} name on Krynn), or something else the DM decides.",
{
"type": "entries",
"name": "Random Properties",
"entries": [
"An Orb of Dragonkind has the following random properties:",
{
"type": "list",
"items": [
"2 {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial properties}",
"1 {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental property}",
"1 {@table Artifact Properties; Major Detrimental Properties|dmg|major detrimental property}"
]
}
]
},
{
"type": "entries",
"name": "Spells",
"entries": [
"The orb has 7 charges and regains {@dice 1d4 + 3} expended charges daily at dawn. If you control the orb, you can use an action and expend 1 or more charges to cast one of the following spells (save DC 18) from it: {@spell cure wounds} (5th-level version, 3 charges), {@spell daylight} (1 charge), {@spell death ward} (2 charges), or {@spell scrying} (3 charges). You can also use an action to cast the {@spell detect magic} spell from the orb without using any charges."
]
},
{
"type": "entries",
"name": "Call Dragons",
"entries": [
"While you control the orb, you can use an action to cause the artifact to issue a telepathic call that extends in all directions for 40 miles. Evil dragons in range feel compelled to come to the orb as soon as possible by the most direct route. Dragon deities such as {@creature Tiamat|RoT} are unaffected by this call. Dragons drawn to the orb might be hostile toward you for compelling them against their will. Once you have used this property, it can't be used again for 1 hour."
]
},
{
"type": "entries",
"name": "Destroying an Orb",
"entries": [
"An Orb of Dragonkind appears fragile but is impervious to most damage, including the attacks and breath weapons of dragons. A {@spell disintegrate} spell or one good hit from a +3 magic weapon is sufficient to destroy an orb, however."
]
}
],
"attachedSpells": [
"detect magic",
"cure wounds",
"daylight",
"death ward",
"scrying"
],
"hasFluffImages": true
},
{
"name": "Orb of Gonging",
"source": "WDMM",
"page": 174,
"rarity": "common",
"wondrous": true,
"weight": 5,
"entries": [
"This common wondrous item is a hollow, 5-inch-diameter orb that weighs 5 pounds. Its outer shell is composed of notched bronze rings, which can be turned so that the notches line up. Aligning the notches requires an action, and doing so causes the orb to gong loudly until the notches are no longer aligned. The sounds are spaced 6 seconds apart and can be heard out to a range of 600 feet."
]
},
{
"name": "Orb of the Stein Rune",
"source": "SKT",
"page": 235,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"weight": 3,
"entries": [
"This orb of granite is about the size of an adult human's fist. The stein (stone) rune appears on it in the form of crystalline veins that run across the surface. The orb has the following properties, which work only while it's on your person.",
{
"type": "entries",
"name": "Indomitable Stand",
"entries": [
"As an action, you can channel the orb's magic to hold your ground. For the next minute or until you move any distance, you have advantage on all checks and saving throws to resist effects that force you to move. In addition, any enemy that moves to a space within 10 feet of you must succeed on a DC 12 Strength saving throw or be unable to move any farther this turn."
]
},
{
"type": "entries",
"name": "Stone Soul",
"entries": [
"You can't be {@condition petrified}."
]
},
{
"type": "entries",
"name": "Earthen Step",
"entries": [
"You can cast {@spell meld into stone} as a bonus action. Once you use this property, you can't use it again until you finish a short or long rest."
]
},
{
"type": "entries",
"name": "Gift of Stone",
"entries": [
"You can transfer the orb's magic to a nonmagical item—a shield or a pair of boots—by tracing the stein rune there with your finger. The transfer takes 8 hours of work that requires the two items to be within 5 feet of each other. At the end, the orb is destroyed, and the rune appears in silver on the chosen item, which gains a benefit based on its form:",
{
"type": "list",
"style": "list-hang-notitle",
"items": [
{
"type": "item",
"name": "Shield",
"entry": "The shield is now a rare magic item that requires attunement. While you wield it, you have resistance to all damage dealt by ranged weapon attacks."
},
{
"type": "item",
"name": "Boots",
"entry": "The pair of boots is now an uncommon magic item that requires attunement. While you wear the boots, you have advantage on Strength saving throws, and you can use your reaction to avoid being knocked {@condition prone}."
}
]
}
]
}
],
"attachedSpells": [
"meld into stone"
]
},
{
"name": "Orb of the Stein Rune",
"source": "UAPrestigeClassesRunMagic",
"page": 5,
"type": "MR",
"rarity": "rare",
"reqAttune": true,
"entries": [
"This spherical chunk of granite is about the size of a human fist. The stein rune—the rune of stone—appears on the orb as crystalline veins that play across its surface. When first grasped, the stone feels impossibly heavy, as if even a titan could not lift it. That feeling passes after a moment, allowing you to carry the stone with ease.",
{
"type": "entries",
"name": "Indomitable Stand (Simple Property)",
"entries": [
"As an action, you scribe the stein rune onto the ground at your feet. Until you move, you have advantage on all ability checks and saving throws to resist effects that would force you to move. In addition, any creature that moves within 10 feet of you must succeed on a DC 12 Strength saving throw or have its movement immediately end."
]
},
{
"type": "entries",
"name": "Stone Soul (Simple Property)",
"entries": [
"While you are attuned to this rune, you cannot be {@condition petrified}."
]
},
{
"type": "entries",
"name": "Stone's Secrets (Simple Property)",
"entries": [
"As an action, you scribe this rune onto a stone wall or floor. You learn the location and size of all creatures standing on or touching that surface within 30 feet of you, though only for the moment when the property is used."
]
},
{
"type": "entries",
"name": "Crushing Brand (Complex Property)",
"entries": [
"Over the course of a short rest, you inscribe this rune using dirt or crushed stone onto one weapon that deals bludgeoning damage. The weapon gains a ghostly brown aura, and bludgeoning damage dealt by the weapon ignores resistance and immunity. If you roll the maximum on the weapon's damage die or dice, the target of your attack is knocked {@condition prone} if it is a creature.",
"In addition, you can expend a spell slot to grant the weapon a bonus to attack rolls and damage rolls equal to the spell slot's level divided by three.",
"These effects last for 24 hours or until you use this property again."
]
},
{
"type": "entries",
"name": "Earthen Step (Complex Property)",
"entries": [
"While you are attuned to this rune, you can cast {@spell meld into stone} as a bonus action. You regain this ability after a short or long rest."
]
},
{
"type": "entries",
"name": "Overwhelming Bolt (Complex Property)",
"entries": [
"As an action, you scribe this rune using dirt or crushed rock onto a creature within your reach as you expend a spell slot. The creature must succeed on a Strength saving throw (DC 12 + the level of the expended spell slot). On a failure, the creature takes {@damage 2d8} bludgeoning damage plus {@damage 1d8} bludgeoning damage per level of the expended spell slot and is knocked {@condition prone}. On a successful saving throw, the creature takes half as much damage and is not knocked {@condition prone}."
]
}
]
},
{
"name": "Orb of the Veil",
"source": "EGW",
"page": 268,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"curse": true,
"weight": 3,
"ability": {
"wis": 2
},
"entries": [
"This onyx sphere bears deep, spiraling grooves and dangles from an iron chain. While the orb is on your person, you gain the following benefits:",
{
"type": "list",
"items": [
"Your Wisdom score increases by 2, as does your maximum for that score.",
"You gain {@sense darkvision} out to a range of 60 feet. If you already have {@sense darkvision}, the orb increases its range by 60 feet.",
"You have advantage on Wisdom checks to find hidden doors and paths."
]
},
{
"type": "entries",
"name": "Curse",
"entries": [
"The orb is cursed, and becoming attuned to it extends the curse to you. As long as you remain cursed, you are unwilling to part with the orb, keeping it on your person at all times. All nonmagical flames within 30 feet of you automatically extinguish, and fire damage dealt by you is halved."
]
}
]
},
{
"name": "Orb of Time",
"source": "XGE",
"page": 138,
"tier": "minor",
"rarity": "common",
"wondrous": true,
"weight": 3,
"entries": [
"While holding this orb, you can use an action to determine whether it is morning, afternoon, evening, or nighttime outside. This property functions only on the Material Plane."
]
},
{
"name": "Orc Stone",
"source": "IDRotF",
"page": 264,
"rarity": "uncommon",
"reqAttune": "by a recipient only",
"wondrous": true,
"entries": [
"I saved the life of an orc, who gave me a stone with the symbol of the Many-Arrows tribe (a humanoid skull pierced by three arrows) carved into it. The stone is an uncommon magic item that requires attunement, and only I can attune to it.",
"As an action, I can use the stone to summon the spirit of an orc warrior, which appears within 30 feet of me. The spirit uses the {@creature orc war chief} stat block in the Monster Manual and disappears after 10 minutes or when reduced to 0 hit points. The spirit understands any language I speak and obeys my commands.",
"After the stone is used three times, it turns to dust. (See Appendix B of Icewind Dale: Rime of the Frostmaiden)"
]
},
{
"name": "Orcsplitter",
"source": "PotA",
"page": 224,
"baseItem": "greataxe|phb",
"type": "M",
"rarity": "legendary",
"reqAttune": "by a dwarf, fighter, or paladin of good alignment",
"reqAttuneTags": [
{
"race": "dwarf",
"alignment": [
"G"
]
},
{
"class": "paladin",
"alignment": [
"G"
]
}
],
"sentient": true,
"weight": 7,
"weaponCategory": "martial",
"property": [
"H",
"2H"
],
"dmg1": "1d12",
"dmgType": "S",
"bonusWeapon": "+2",
"entries": [
"A mighty axe wielded long ago by the dwarf king Torhild Flametongue, Orcsplitter is a battered weapon that appears unremarkable at first glance. Its head is graven with the Dwarvish runes for \"orc,\" but the runes are depicted with a gap or slash through the markings; the word \"orc\" is literally split in two.",
"You gain the following benefits while holding this magic weapon:",
{
"type": "list",
"items": [
"You gain a +2 bonus to attack and damage rolls made with it.",
"When you roll a 20 on an attack roll with this weapon against an {@creature orc}, that {@creature orc} must succeed on a DC 17 Constitution saving throw or drop to 0 hit points.",
"You can't be {@quickref Surprise|PHB|3|0|surprised} by {@creature orc||orcs} while you're not {@condition incapacitated}. You are also aware when {@creature orc||orcs} are within 120 feet of you and aren't behind total cover, although you don't know their location.",
"You and any of your friends within 30 feet of you can't be {@condition frightened} while you're not {@condition incapacitated}."
]
},
{
"name": "Sentience",
"type": "entries",
"entries": [
"Orcsplitter is a sentient, lawful good weapon with an Intelligence of 6, a Wisdom of 15, and a Charisma of 10. It can see and hear out to 120 feet and has {@sense darkvision}. It communicates by transmitting emotions to its wielder, although on rare occasions it uses a limited form of telepathy to bring to the wielder's mind a couplet or stanza of ancient Dwarvish verse."
]
},
{
"name": "Personality",
"type": "entries",
"entries": [
"Orcsplitter is grim, taciturn, and inflexible. It knows little more than the desire to face {@creature orc||orcs} in battle and serve a courageous, just wielder. It disdains cowards and any form of duplicity, deception, or disloyalty. The weapon's purpose is to defend dwarves and to serve as a symbol of dwarven resolve. It hates the traditional foes of dwarves—giants, goblins, and, most of all, {@creature orc||orcs}—and silently urges its possessor to meet such creatures in battle."
]
}
]
},
{
"name": "Orcus Figurine",
"source": "CM",
"page": 44,
"rarity": "unknown (magic)",
"wondrous": true,
"entries": [
"Carved from an ogre's {@condition petrified} heart, the gray figurine depicts the Demon Prince of Undeath in ghastly detail, clutching his skull-topped wand in one hand and three severed heads by the hair in the other. The figurine smells like decaying flesh, and this scent is detectable out to a range of 5 feet.",
"The figurine is a Tiny object with AC 17, 3 hit points, and immunity to all types of damage except radiant damage. A {@spell detect evil and good} spell or similar magic reveals that the figurine has been desecrated. As long as it has at least 1 hit point, the figurine has the following magical properties:",
{
"type": "list",
"items": [
"Undead within 30 feet of the figurine can't be turned.",
"Dead creatures within 30 feet of the figurine can't be brought back to life.",
"A creature that holds the figurine while praying to Orcus for at least 1 hour has a {@chance 10|10 percent} chance of summoning a smoky avatar of the demon lord. Once this avatar is summoned, it can't be summoned again for 30 days. Orcus's avatar has the statistics of a {@creature wraith} except that it's chaotic evil. It attacks all non-undead creatures it encounters, and it disappears after 1 hour or when reduced to 0 hit points."
]
}
],
"hasFluffImages": true
},
{
"name": "Order of the Silver Dragon +2 Shield",
"source": "CoS",
"page": 68,
"type": "S",
"tier": "major",
"rarity": "rare",
"weight": 6,
"ac": 2,
"bonusAc": "+2",
"entries": [
"While holding this shield, you have a +2 bonus to AC. This bonus is in addition to the shield's normal bonus to AC.",
"The shield is emblazoned with a stylized silver dragon that is the emblem of the Order of the Silver Dragon (see {@adventure {@i Curse of Strahd}, chapter 7|CoS|7|The Order of the Silver Dragon}). The shield whispers warnings to its bearer, granting a +2 bonus to initiative if the bearer isn't {@condition incapacitated}.",
"A shield is made from wood or metal and is carried in one hand. Wielding a shield increases your Armor Class by 2. You can benefit from only one shield at a time."
]
},
{
"name": "Ornithopter of Flying",
"source": "WBtW",
"page": 212,
"rarity": "very rare",
"wondrous": true,
"weight": 25,
"entries": [
"You can use this contraption to fly, provided your weight (including whatever you are wearing or carrying) doesn't exceed 300 pounds. The ornithopter has a flying speed of 30 feet, and it moves according to your spoken directions while you are riding it. It can't hover. If the ornithopter loses its rider while airborne, it falls and can't fly again for {@dice 1d6 + 4} days.",
"The ornithopter is 8 feet long, has a 14-foot wingspan, and weighs 25 pounds."
],
"hasFluffImages": true
},
{
"name": "Orrery of the Wanderer",
"source": "AI",
"page": 220,
"type": "OTH",
"rarity": "artifact",
"reqAttune": true,
"wondrous": true,
"bonusAc": "+1",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d4 + 4}",
"charges": 7,
"entries": [
"This delicate and exquisitely crafted clockwork orrery features multiple geared components whose sweeping hands and dials represent the complex interplay of planar and magical realms. Standing two feet high, the orrery housing is a wondrous device imbued with magic of its own, but the power of its six clockwork components makes the artifact even more potent.",
"The Orrery of the Wanderer was created by a renowned clockwork mage known only as Lottie. She crafted the relic as a means of tapping into the power of the planes, and to channel the divination and foretelling powers of the stars. But Lottie soon realized that her creation was far more powerful than she had intended. As it tapped into planar magic, the orrery began to manifest the darkness and malevolence inherent to some of the planes. Eventually, it would impress upon its owner a desire to open portals into the most cursed and dangerous worlds, including the Far Realm.",
"To prevent the orrery from ever being so used, Lottie scattered the components that powered it, secreting them across the world. But the orrery's instinct for survival is strong, and its components have a way of inexorably coming together over decades or centuries, found by treasure hunters, stolen by monsters, found and stolen again—and moving closer to each other all the time.",
"Random Properties. The orrery has the following randomly determined properties:",
{
"type": "list",
"items": [
"2 {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial properties}",
"1 {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial property}",
"1 {@table Artifact Properties; Major Detrimental Properties|dmg|major detrimental properties}"
]
},
"The random properties of the orrery might function only when all its components are installed, or might function sporadically if any components are missing.",
{
"type": "entries",
"name": "The Sum of Its Parts",
"entries": [
"Each of the six components that powers the Orrery of the Wanderer is a powerful magic relic in its own right:",
{
"type": "list",
"style": "list-no-bullets",
"items": [
"The {@item chronolometer|AI}",
"The {@item dimensional loop|AI}",
"The {@item far gear|AI}",
"The {@item rotor of return|AI}",
"The {@item timepiece of travel|AI}",
"The {@item wheel of stars|AI}"
]
},
"Creatures can attune to the orrery's components individually. If attuned to an individual component, a creature must hold the component to make use of its features. A creature can also attune to the orrery and all the components installed in it. Attuning to an installed component doesn't count against the number of magic items you can normally attune to.",
"While a component is not installed in the orrery, its magic might function sporadically or with unpredictable side effects, as determined by the DM."
]
},
{
"type": "entries",
"name": "Future Echoes",
"entries": [
"With or without its components, the orrery lets you catch momentary glimpses of the future that warn you of danger. As long as the orrery is within 30 feet of you, you gain a +1 bonus to AC."
]
},
{
"type": "entries",
"name": "Into the Void",
"entries": [
"With or without its components, the attuned orrery infuses your spirit with the tumultuous energy of the planes. Over time, your alignment changes to chaotic neutral, and you are compelled to engage with and explore the most dangerous planar realms."
]
},
{
"type": "entries",
"name": "Master of Travel",
"entries": [
"While all six components of the Orrery of the Wanderer are installed, the artifact has 7 charges. While touching the orrery, you can use an action and expend 1 or more charges to cast one of the following spells (save DC 17): {@spell contact other plane} (3 charges), {@spell demiplane} (6 charges), {@spell gate} (7 charges), {@spell plane shift} (5 charges), or {@spell teleportation circle} (3 charges). The orrery regains {@dice 1d4 + 4} expended charges daily at dawn."
]
}
],
"attachedSpells": [
"contact other plane",
"demiplane",
"gate",
"plane shift",
"teleportation circle"
],
"hasFluffImages": true
},
{
"name": "Orzhov Guild Signet",
"source": "GGR",
"page": 178,
"type": "RG",
"rarity": "uncommon",
"reqAttune": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"This ring, adorned with the symbol of Orzhov, allows you to cast {@spell command}. A guild signet is sometimes awarded to a guild member whose renown score in that guild is 5 or higher, as a reward for performing special services for the guild. Aside from its magical properties, the ring is also an indicator of Orzhov's recognition and favor.",
"A signet has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. While wearing it, you can expend 1 charge to cast the associated spell (save DC 13)."
],
"attachedSpells": [
"command"
],
"hasFluffImages": true
},
{
"name": "Orzhov Keyrune",
"source": "GGR",
"page": 178,
"rarity": "rare",
"reqAttune": "by a member of the Orzhov guild",
"reqAttuneTags": [
{
"background": "orzhov representative|ggr"
}
],
"wondrous": true,
"entries": [
"This keyrune is carved from white marble with veins of black. The end is shaped like a thrull's head, with a gold faceplate affixed. On command, the keyrune transforms into a {@creature winged thrull|GGR} for up to 2 hours. If you don't come from an Orzhov oligarch family, it serves you grudgingly, clownishly aping your movements and mannerisms while carrying out your orders.",
"When you use an action to speak the item's command word and place the keyrune on the ground in an unoccupied space within 5 feet of you, the keyrune transforms into a {@creature winged thrull|GGR}. If there isn't enough space for the creature, the keyrune doesn't transform.",
"The creature is friendly to you, your companions, and other members of your guild (unless those guild members are hostile to you). It understands your languages and obeys your spoken commands. If you issue no commands, the creature takes the {@action Dodge} action and moves to avoid danger.",
"At the end of the duration, the creature reverts to its keyrune form. It reverts early if it drops to 0 hit points or if you use an action to speak the command word again while touching it. When the creature reverts to its keyrune form, it can't transform again until 36 hours have passed."
],
"hasFluffImages": true
},
{
"name": "Outer Essence Shard",
"source": "TCE",
"page": 133,
"rarity": "rare",
"reqAttune": "by a sorcerer",
"reqAttuneTags": [
{
"class": "sorcerer"
}
],
"wondrous": true,
"weight": 1,
"focus": [
"Sorcerer"
],
"entries": [
"This flickering crystal holds the essence of an Outer Plane. As an action, you can attach the shard to a Tiny object (such as a weapon or a piece of jewelry) or detach it. It falls off if your attunement to it ends. You can use the shard as a spellcasting focus while you hold or wear it.",
"Roll a {@dice d4} and consult the Outer Essence Shards table to determine the shard's essence and property. When you use a Metamagic option on a spell while you are holding or wearing the shard, you can use that property.",
{
"type": "table",
"caption": "Outer Essence Shards",
"colLabels": [
"d4",
"Property",
"Shard"
],
"colStyles": [
"col-2 text-center",
"col-2 text-center",
"col-8"
],
"rows": [
[
"1",
"Lawful",
"{@item Outer Essence Shard (Lawful)|TCE}"
],
[
"2",
"Chaotic",
"{@item Outer Essence Shard (Chaotic)|TCE}"
],
[
"3",
"Good",
"{@item Outer Essence Shard (Good)|TCE}"
],
[
"4",
"Evil",
"{@item Outer Essence Shard (Evil)|TCE}"
]
]
}
]
},
{
"name": "Outer Essence Shard (Chaotic)",
"source": "TCE",
"page": 133,
"rarity": "rare",
"reqAttune": "by a sorcerer",
"reqAttuneTags": [
{
"class": "sorcerer"
}
],
"wondrous": true,
"weight": 1,
"focus": [
"Sorcerer"
],
"entries": [
"This flickering crystal holds the essence of an Outer Plane. As an action, you can attach the shard to a Tiny object (such as a weapon or a piece of jewelry) or detach it. It falls off if your attunement to it ends. You can use the shard as a spellcasting focus while you hold or wear it.",
"When you use a Metamagic option on a spell while you are holding or wearing the shard, you can use that property.",
{
"type": "entries",
"name": "Property",
"entries": [
"Choose one creature who takes damage from the spell. That target has disadvantage on attack rolls and ability checks made before the start of your next turn."
]
}
]
},
{
"name": "Outer Essence Shard (Evil)",
"source": "TCE",
"page": 133,
"rarity": "rare",
"reqAttune": "by a sorcerer",
"reqAttuneTags": [
{
"class": "sorcerer"
}
],
"wondrous": true,
"weight": 1,
"focus": [
"Sorcerer"
],
"entries": [
"This flickering crystal holds the essence of an Outer Plane. As an action, you can attach the shard to a Tiny object (such as a weapon or a piece of jewelry) or detach it. It falls off if your attunement to it ends. You can use the shard as a spellcasting focus while you hold or wear it.",
"When you use a Metamagic option on a spell while you are holding or wearing the shard, you can use that property.",
{
"type": "entries",
"name": "Property",
"entries": [
"Choose one creature who takes damage from the spell. That target takes an extra {@damage 3d6} necrotic damage."
]
}
]
},
{
"name": "Outer Essence Shard (Good)",
"source": "TCE",
"page": 133,
"rarity": "rare",
"reqAttune": "by a sorcerer",
"reqAttuneTags": [
{
"class": "sorcerer"
}
],
"wondrous": true,
"weight": 1,
"focus": [
"Sorcerer"
],
"entries": [
"This flickering crystal holds the essence of an Outer Plane. As an action, you can attach the shard to a Tiny object (such as a weapon or a piece of jewelry) or detach it. It falls off if your attunement to it ends. You can use the shard as a spellcasting focus while you hold or wear it.",
"When you use a Metamagic option on a spell while you are holding or wearing the shard, you can use that property.",
{
"type": "entries",
"name": "Property",
"entries": [
"You or one creature of your choice that you can see within 30 feet of you gains {@dice 3d6} temporary hit points."
]
}
]
},
{
"name": "Outer Essence Shard (Lawful)",
"source": "TCE",
"page": 133,
"rarity": "rare",
"reqAttune": "by a sorcerer",
"reqAttuneTags": [
{
"class": "sorcerer"
}
],
"wondrous": true,
"weight": 1,
"focus": [
"Sorcerer"
],
"entries": [
"This flickering crystal holds the essence of an Outer Plane. As an action, you can attach the shard to a Tiny object (such as a weapon or a piece of jewelry) or detach it. It falls off if your attunement to it ends. You can use the shard as a spellcasting focus while you hold or wear it.",
"When you use a Metamagic option on a spell while you are holding or wearing the shard, you can use that property.",
{
"type": "entries",
"name": "Property",
"entries": [
"You can end one of the following conditions affecting yourself or one creature you can see within 30 feet of you: {@condition charmed}, {@condition blinded}, {@condition deafened}, {@condition frightened}, {@condition poisoned}, or {@condition stunned}."
]
}
]
},
{
"name": "Oversized Longbow",
"source": "WDH",
"page": 201,
"type": "R",
"rarity": "unknown",
"weight": 2,
"weaponCategory": "martial",
"property": [
"A",
"H",
"2H"
],
"range": "150/600",
"dmg1": "2d6",
"dmgType": "P",
"entries": [
"This unique weapon can be used only by a Medium or larger creature that has a Strength of 18 or higher. The bow shoots oversized arrows that deal piercing damage equal to {@dice 2d6} + the wielder's Strength modifier."
],
"ammoType": "arrow|phb"
},
{
"name": "Ox",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TG",
"rarity": "none",
"value": 1500
},
{
"name": "Pack Saddle",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TAH",
"rarity": "none",
"weight": 15,
"value": 500
},
{
"name": "Painted gold child's sarcophagus",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 750000
},
{
"name": "Painted gold war mask",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 75000
},
{
"name": "Painter's Supplies",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"additionalSources": [
{
"source": "XGE",
"page": 83
}
],
"type": "AT",
"rarity": "none",
"weight": 5,
"value": 1000,
"additionalEntries": [
"Proficiency with painter's supplies represents your ability to paint and draw. You also acquire an understanding of art history, which can aid you in examining works of art.",
{
"type": "entries",
"name": "Components",
"entries": [
"Painter's supplies include an easel, canvas, paints, brushes, charcoal sticks, and a palette."
]
},
{
"type": "entries",
"name": "Arcana, History, Religion",
"entries": [
"Your expertise aids you in uncovering lore of any sort that is attached to a work of art, such as the magical properties of a painting or the origins of a strange mural found in a dungeon."
]
},
{
"type": "entries",
"name": "Investigation, Perception",
"entries": [
"When you inspect a painting or a similar work of visual art, your knowledge of the practices behind creating it can grant you additional insight."
]
},
{
"type": "entries",
"name": "Painting and Drawing",
"entries": [
"As part of a short or long rest, you can produce a simple work of art. Although your work might lack precision, you can capture an image or a scene, or make a quick copy of a piece of art you saw."
]
},
{
"type": "table",
"caption": "Painter's Supplies",
"colLabels": [
"Activity",
"DC"
],
"colStyles": [
"col-10",
"col-2 text-center"
],
"rows": [
[
"Paint an accurate portrait",
"10"
],
[
"Create a painting with a hidden message",
"20"
]
]
}
]
},
{
"name": "Pair of engraved bone dice",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 2500
},
{
"name": "Pair of engraved bone dice (Brazen Coalition)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 2500
},
{
"name": "Pale Tincture",
"source": "DMG",
"page": 258,
"srd": true,
"type": "G",
"rarity": "none",
"value": 25000,
"poison": true,
"entries": [
"A creature subjected to this poison must succeed on a DC 16 Constitution saving throw or take 3 ({@damage 1d6}) poison damage and become {@condition poisoned}. The {@condition poisoned} creature must repeat the saving throw every 24 hours, taking 3 ({@damage 1d6}) poison damage on a failed save. Until this poison ends, the damage the poison deals can't be healed by any means. After seven successful saving throws, the effect ends and the creature can heal normally."
],
"poisonTypes": [
"ingested"
]
},
{
"name": "Pan Flute",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"type": "INS",
"rarity": "none",
"weight": 2,
"value": 1200
},
{
"name": "Paper (one sheet)",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"value": 20
},
{
"name": "Paper Bird",
"source": "WDH",
"page": 191,
"otherSources": [
{
"source": "IMR",
"page": 94
}
],
"rarity": "uncommon",
"wondrous": true,
"entries": [
"After you write a message of fifty words or fewer on this magic sheet of parchment and speak a creature's name, the parchment magically folds into a Tiny paper bird and flies to the recipient whose name you uttered. The recipient must be on the same plane of existence as you, otherwise the bird turns into ash as it takes flight.",
"The bird is an object that has 1 hit point, an Armor Class of 13, a flying speed of 60 feet, a Dexterity of 16 (+3), and a score of 1 (−5) in all other abilities, and it is immune to poison and psychic damage.",
"It travels to within 5 feet of its intended recipient by the most direct route, whereupon it turns into a nonmagical and inanimate sheet of parchment that can be unfolded only by the intended recipient. If the bird's hit points or speed is reduced to 0 or if it is otherwise immobilized, it turns into ash.",
"Paper birds usually come in small, flat boxes containing {@dice 1d6 + 3} sheets of the parchment."
],
"hasFluffImages": true
},
{
"name": "Parchment (one sheet)",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"value": 10
},
{
"name": "Pariah's Shield",
"source": "GGR",
"page": 180,
"type": "S",
"rarity": "rare",
"reqAttune": true,
"weight": 6,
"ac": 2,
"bonusAc": "+1",
"entries": [
"Soldiers of the Boros Legion consider it an honor to bear this shield, even knowing that it might be the last honor they receive. The front of the shield is sculpted to depict a grieving human face.",
"You gain a +1 bonus to AC for every two allies within 5 feet of you (up to a maximum of +3) while you wield this shield. This bonus is in addition to the shield's normal bonus to AC.",
"When a creature you can see within 5 feet of you takes damage, you can use your reaction to take that damage, instead of the creature taking it. When you do so, the damage type changes to force."
],
"hasFluffImages": true
},
{
"name": "Pathfinder's Greataxe",
"source": "PotA",
"page": 175,
"baseItem": "greataxe|phb",
"type": "M",
"rarity": "unknown (magic)",
"weight": 7,
"weaponCategory": "martial",
"property": [
"H",
"2H"
],
"dmg1": "1d12",
"dmgType": "S",
"bonusWeapon": "+1",
"entries": [
"When you wield this +1 greataxe, you always know the way to the nearest passage leading from underground toward the surface, as well as the approximate depth the axe is underground."
]
},
{
"name": "Pearl",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 10000,
"entries": [
"An opaque lustrous white, yellow, or pink gemstone."
]
},
{
"name": "Pearl of Power",
"source": "DMG",
"page": 184,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"wondrous": true,
"entries": [
"While this pearl is on your person, you can use an action to speak its command word and regain one expended spell slot. If the expended slot was of 4th level or higher, the new slot is 3rd level. Once you have used the pearl, it can't be used again until the next dawn."
],
"lootTables": [
"Magic Item Table F"
]
},
{
"name": "Pearl of Undead Detection",
"source": "WDMM",
"page": 76,
"type": "OTH",
"rarity": "unknown (magic)",
"value": 50000,
"entries": [
"This black pearl glows faintly when undead are within 120 feet of it."
]
},
{
"name": "Pennant of the Vind Rune",
"source": "SKT",
"page": 235,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"modifySpeed": {
"bonus": {
"*": 5
}
},
"entries": [
"This blue pennant is crafted from silk and is five feet long and whips about as if buffeted by a wind. The vind (wind) rune appears on its surface, looking almost like a cloud. The pennant has the following properties, which work only while it's on your person.",
{
"type": "entries",
"name": "Wind Step",
"entries": [
"As an action, you fly up to 20 feet. If you don't land at the end of this flight, you fall unless you have another means of staying aloft."
]
},
{
"type": "entries",
"name": "Comforting Wind",
"entries": [
"You can't suffocate."
]
},
{
"type": "entries",
"name": "Winds Grasp",
"entries": [
"As a reaction when you fall, you can cause yourself to take no damage from the fall. Once you use this property, you can't use it again until you finish a short or long rest."
]
},
{
"type": "entries",
"name": "Wind Walker",
"entries": [
"While you are attuned to this rune, you can cast {@spell levitate} as a bonus action. Once you use this property, you can't use it again until you finish a short or long rest."
]
},
{
"type": "entries",
"name": "Gift of Wind",
"entries": [
"You can transfer the pennant's magic to a nonmagical item—a suit of armor, a pair of boots, or a cloak—by tracing the vind rune there with your finger. The transfer takes 8 hours of work that requires the two items to be within 5 feet of each other. At the end, the pennant is destroyed, and the rune appears in silver on the chosen item, which gains a benefit based on its form:",
{
"type": "list",
"style": "list-hang-notitle",
"items": [
{
"type": "item",
"name": "Armor",
"entry": "The armor is now an uncommon magic item that requires attunement. You gain a bonus to speed of 5 feet while you wear the armor, and if it normally imposes disadvantage on {@skill Stealth} checks, it no longer does so."
},
{
"type": "item",
"name": "Boots/Cloak",
"entry": "The pair of boots or cloak is now a rare magic item that requires attunement. While wearing the item, you can convert up to 20 feet of your movement on each of your turns into flight. If you don't land at the end of this flight, you fall unless you have another means of staying aloft. You can also cast {@spell feather fall} once from the item, and you regain the ability to do so when you finish a short or long rest."
}
]
}
]
}
],
"attachedSpells": [
"levitate",
"feather fall"
]
},
{
"name": "Pennant of the Vind Rune",
"source": "UAPrestigeClassesRunMagic",
"page": 5,
"type": "MR",
"rarity": "rare",
"reqAttune": true,
"entries": [
"This five-foot-long blue pennant is crafted from silk and whips about as if buffeted by a strong breeze. The vind rune—the rune of wind—flickers across its surface like a shimmering cloud. Grasping the pennant causes you to feel a powerful gust of wind wash over you, tearing at your clothes and gear. Anyone watching you sees nothing out of the ordinary, and the sensation passes after a moment.",
{
"type": "entries",
"name": "Comforting Wind (Simple Property)",
"entries": [
"While you are attuned to this rune, you cannot suffocate or drown, and you gain advantage on saving throws against poisonous gases, inhaled poisons, and similar effects."
]
},
{
"type": "entries",
"name": "Wind Step (Simple Property)",
"entries": [
"As an action, you scribe the vind rune in the air around you and immediately fly 20 feet. If you do not land at the end of this flight, you fall."
]
},
{
"type": "entries",
"name": "Wind's Grasp (Simple Property)",
"entries": [
"As a reaction when you fall, you can scribe this rune in the air around you to take no damage from the fall."
]
},
{
"type": "entries",
"name": "Howling Brand (Complex Property)",
"entries": [
"Over the course of a short rest, you inscribe this rune in the air above one ranged weapon. The weapon gains a ghostly blue aura and has its normal and maximum range doubled. The weapon's attacks do not suffer disadvantage due to range.",
"In addition, you can expend a spell slot while using this property to grant the weapon a bonus to attack rolls and damage rolls equal to the spell slot's level divided by three.",
"These effects last for 24 hours or until you use this property again."
]
},
{
"type": "entries",
"name": "Shrieking Bolt (Complex Property)",
"entries": [
"As an action, you scribe this rune in the air between you and a creature you can see while you expend a spell slot. The creature must make a Strength saving throw (DC 12 + the spell slot's level). On a failure, it takes {@damage 2d8} bludgeoning damage plus {@damage 1d8} bludgeoning damage per level of the expended spell slot, and is pushed in a straight line directly away from you for 10 feet per level of the expended spell slot. On a successful saving throw, the creature takes half as much damage and is not pushed away from you."
]
},
{
"type": "entries",
"name": "Wind Walker (Complex Property)",
"entries": [
"While you are attuned to this rune, you can cast {@spell levitate} as a bonus action. You regain this ability after a short or long rest."
]
}
]
},
{
"name": "Pepper",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TG",
"rarity": "none",
"weight": 1,
"value": 200
},
{
"name": "Peregrine Mask",
"source": "GGR",
"page": 180,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"modifySpeed": {
"static": {
"fly": 60
}
},
"entries": [
"While wearing this winged helm, you have a flying speed of 60 feet. In addition, you have advantage on initiative rolls."
],
"hasFluffImages": true
},
{
"name": "Perfume (vial)",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"value": 500
},
{
"name": "Perfume of Bewitching",
"source": "XGE",
"page": 138,
"tier": "minor",
"rarity": "common",
"wondrous": true,
"entries": [
"This tiny vial contains magic perfume, enough for one use. You can use an action to apply the perfume to yourself, and its effect lasts 1 hour. For the duration, you have advantage on all Charisma checks directed at humanoids of challenge rating 1 or lower. Those subjected to the perfume's effect are not aware that they've been influenced by magic."
]
},
{
"name": "Periapt of Health",
"source": "DMG",
"page": 184,
"srd": true,
"tier": "minor",
"rarity": "uncommon",
"wondrous": true,
"weight": 1,
"entries": [
"You are immune to contracting any disease while you wear this pendant. If you are already infected with a disease, the effects of the disease are suppressed while you wear the pendant."
],
"lootTables": [
"Magic Item Table C"
],
"hasFluffImages": true
},
{
"name": "Periapt of Proof against Poison",
"source": "DMG",
"page": 184,
"srd": true,
"immune": [
"poison"
],
"tier": "major",
"rarity": "rare",
"wondrous": true,
"weight": 1,
"entries": [
"This delicate silver chain has a brilliant-cut black gem pendant. While you wear it, poisons have no effect on you. You are immune to the {@condition poisoned} condition and have immunity to poison damage."
],
"lootTables": [
"Magic Item Table G"
]
},
{
"name": "Periapt of Wound Closure",
"source": "DMG",
"page": 184,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"weight": 1,
"entries": [
"While you wear this pendant, you stabilize whenever you are dying at the start of your turn. In addition, whenever you roll a Hit Die to regain hit points, double the number of hit points it restores."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Peridot",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 50000,
"entries": [
"A transparent rich olive green gemstone."
]
},
{
"name": "Petrified Grung Egg",
"source": "ToA",
"page": 144,
"type": "OTH",
"resist": [
"poison"
],
"rarity": "unknown (magic)",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"entries": [
"While this egg is on your person, you can use an action to speak its command word and regain one expended spell slot. If the expended slot was of 4th level or higher, the new slot is 3rd level. Once you have used the egg, it can't be used again until the next dawn.",
"Additionally, while you are attuned to the egg, you gain resistance to poison damage.",
"The egg turns to dust and is destroyed if it leaves the Tomb of the Nine Gods."
]
},
{
"name": "Pewter mug with green spinels (Brazen Coalition)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 25000
},
{
"name": "Philter of Love",
"source": "DMG",
"page": 184,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "uncommon",
"entries": [
"The next time you see a creature within 10 minutes after drinking this philter, you become {@condition charmed} by that creature for 1 hour. If the creature is of a species and gender you are normally attracted to, you regard it as your true love while you are {@condition charmed}. This potion's rose-hued, effervescent liquid contains one easy-to-miss bubble shaped like a heart."
],
"lootTables": [
"Magic Item Table B"
]
},
{
"name": "Piercer",
"source": "AI",
"page": 121,
"baseItem": "shortsword|phb",
"type": "M",
"rarity": "rare",
"reqAttune": true,
"weight": 2,
"weaponCategory": "martial",
"property": [
"F",
"L"
],
"dmg1": "1d6",
"dmgType": "P",
"bonusWeapon": "+1",
"entries": [
"You have a +1 bonus to attack and damage rolls made with this magic weapon.",
"A character attuned to the sword regains the maximum possible number of hit points from expended Hit Dice. However, the attuned character must eat twice as much food each day (a minimum of 2 pounds) to avoid {@condition exhaustion} (see \"The Environment\" in chapter 8 of the Player's Handbook.)"
]
},
{
"name": "Pig",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TG",
"rarity": "none",
"value": 300
},
{
"name": "Pipe of Remembrance",
"source": "GoS",
"page": 229,
"rarity": "common",
"wondrous": true,
"entries": [
"This long, delicate wooden pipe features a bowl made from smooth river stone. When the pipe is lit, smoke exhaled from it does not dissipate, instead lingering around the bearer. After 10 minutes, the smoke forms moving shapes that reenact the bearer's most impressive and heroic achievements for 5 minutes. When this realistic performance is complete, the smoke dissipates. The pipe can't be used this way again until the next dawn."
]
},
{
"name": "Pipe of Smoke Monsters",
"source": "XGE",
"page": 138,
"tier": "minor",
"rarity": "common",
"wondrous": true,
"entries": [
"While smoking this pipe, you can use an action to exhale a puff of smoke that takes the form of a single creature, such as a dragon, a flumph, or a froghemoth. The form must be small enough to fit in a 1-foot cube and loses its shape after a few seconds, becoming an ordinary puff of smoke."
],
"hasFluffImages": true
},
{
"name": "Pipes of Haunting",
"source": "DMG",
"page": 185,
"srd": true,
"type": "INS",
"tier": "major",
"rarity": "uncommon",
"wondrous": true,
"weight": 2,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"You must be proficient with wind instruments to use these pipes. They have 3 charges. You can use an action to play them and expend 1 charge to create an eerie, spellbinding tune. Each creature within 30 feet of you that hears you play must succeed on a DC 15 Wisdom saving throw or become {@condition frightened} of you for 1 minute. If you wish, all creatures in the area that aren't hostile toward you automatically succeed on the saving throw. A creature that fails the saving throw can repeat it at the end of each of its turns, ending the effect on itself on a success. A creature that succeeds on its saving throw is immune to the effect of these pipes for 24 hours. The pipes regain {@dice 1d3} expended charges daily at dawn."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Pipes of the Sewers",
"source": "DMG",
"page": 185,
"srd": true,
"type": "INS",
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"weight": 2,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"You must be proficient with wind instruments to use these pipes. While you are attuned to the pipes, ordinary rats and giant rats are indifferent toward you and will not attack you unless you threaten or harm them.",
"The pipes have 3 charges. If you play the pipes as an action, you can use a bonus action to expend 1 to 3 charges, calling forth one {@creature swarm of rats} with each expended charge, provided that enough rats are within half a mile of you to be called in this fashion (as determined by the DM). If there aren't enough rats to form a swarm, the charge is wasted. Called swarms move toward the music by the shortest available route but aren't under your control otherwise. The pipes regain {@dice 1d3} expended charges daily at dawn.",
"Whenever a {@creature swarm of rats} that isn't under another creature's control comes within 30 feet of you while you are playing the pipes, you can make a Charisma check contested by the swarm's Wisdom check. If you lose the contest, the swarm behaves as it normally would and can't be swayed by the pipes' music for the next 24 hours. If you win the contest, the swarm is swayed by the pipes' music and becomes friendly to you and your companions for as long as you continue to play the pipes each round as an action. A friendly swarm obeys your commands. If you issue no commands to a friendly swarm, it defends itself but otherwise takes no actions. If a friendly swarm starts its turn and can't hear the pipes' music, your control over that swarm ends, and the swarm behaves as it normally would and can't be swayed by the pipes' music for the next 24 hours."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Pirate's Cutlass",
"source": "XMtS",
"page": 19,
"rarity": "unknown (magic)",
"entries": [
"This {@item +1 shortsword} is a terrifying weapon with a serrated edge. It grants its wielder advantage on Charisma ({@skill Intimidation}) checks when brandished."
]
},
{
"name": "Pitcher",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 4,
"value": 2,
"entries": [
"A pitcher holds 1 gallon of liquid."
]
},
{
"name": "Piton",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 0.25,
"value": 5
},
{
"name": "Piwafwi (Cloak of Elvenkind)",
"source": "OotA",
"page": 222,
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"entries": [
"This dark spider-silk cloak is made by {@creature drow}. It is a {@item cloak of elvenkind}. It loses its magic if exposed to sunlight for 1 hour without interruption.",
"While you wear this cloak with its hood up, Wisdom ({@skill Perception}) checks made to see you have disadvantage. and you have advantage on Dexterity ({@skill Stealth}) checks made to hide, as the cloak's color shifts to camouflage you. Pulling the hood up or down requires an action."
],
"hasFluffImages": true
},
{
"name": "Piwafwi of Fire Resistance",
"source": "OotA",
"page": 222,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"This dark spider-silk cloak is made by {@creature drow}. It is a {@item cloak of elvenkind}. It also grants resistance to fire damage while you wear it. It loses its magic if exposed to sunlight for 1 hour without interruption.",
"While you wear this cloak with its hood up, Wisdom ({@skill Perception}) checks made to see you have disadvantage. and you have advantage on Dexterity ({@skill Stealth}) checks made to hide, as the cloak's color shifts to camouflage you. Pulling the hood up or down requires an action."
]
},
{
"name": "Pixie Dust",
"source": "WBtW",
"page": 212,
"rarity": "uncommon",
"wondrous": true,
"entries": [
"As an action, you can sprinkle this dust on yourself or another creature you can see within 5 feet of you. The recipient gains a flying speed of 30 feet and the ability to hover for 1 minute. If the creature is airborne when this effect ends, it falls safely to the ground, taking no damage and landing on its feet.",
"A small packet holds enough pixie dust for one use."
]
},
{
"name": "Planar Key",
"source": "AitFR-THP",
"page": 13,
"rarity": "rare",
"wondrous": true,
"entries": [
"This key is etched with sigils.",
"A planar key begins existence with {@dice 1d4 + 1} charges and cannot regain charges. When the key's last charge is expended, the key's sigils and magic fade away, but the key remains.",
"While touching the key, you can use your action to expend 1 charge and have the key cast {@spell plane shift} limited to the key's predefined destination only. Each planar key links to a single destination defined when the key is made."
],
"attachedSpells": [
"plane shift"
]
},
{
"name": "Planecaller's Codex",
"source": "TCE",
"page": 134,
"rarity": "rare",
"reqAttune": "by a wizard",
"reqAttuneTags": [
{
"class": "wizard"
}
],
"wondrous": true,
"weight": 3,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"focus": [
"Wizard"
],
"entries": [
"The pages of this book are bound in fiend hide, and its cover is embossed with a diagram of the Great Wheel of the multiverse. When found, the book contains the following spells: {@spell banishment}, {@spell find familiar}, {@spell gate}, {@spell magic circle}, {@spell planar binding}, and {@spell summon elemental|TCE}. It functions as a spellbook for you.",
"While you are holding the book, you can use it as a spellcasting focus for your wizard spells.",
"The book has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. You can use the charges in the following ways while holding it:",
{
"type": "list",
"items": [
"If you spend 1 minute studying the book, you can expend 1 charge to replace one of your prepared wizard spells with a different spell in the book. The new spell must be of the conjuration school.",
"When you cast a conjuration spell that summons or creates one creature, you can expend 1 charge to grant that creature advantage on attack rolls for 1 minute."
]
}
],
"attachedSpells": [
"banishment",
"find familiar",
"gate",
"magic circle",
"planar binding",
"summon elemental|tce"
]
},
{
"name": "Plate Armor of Etherealness",
"source": "DMG",
"page": 185,
"srd": true,
"baseItem": "plate armor|phb",
"type": "HA",
"tier": "major",
"rarity": "legendary",
"reqAttune": true,
"weight": 65,
"ac": 18,
"strength": "15",
"stealth": true,
"entries": [
"While you're wearing this armor, you can speak its command word as an action to gain the effect of the {@spell etherealness} spell, which lasts for 10 minutes or until you remove the armor or use an action to speak the command word again. This property of the armor can't be used again until the next dawn."
],
"attachedSpells": [
"etherealness"
],
"lootTables": [
"Magic Item Table I"
]
},
{
"name": "Platinum",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TG",
"rarity": "none",
"weight": 1,
"value": 50000
},
{
"name": "Platinum (pp)",
"source": "PHB",
"page": 143,
"type": "$",
"rarity": "none",
"weight": 0.02,
"value": 1000,
"entries": [
"Common coins come in several different denominations based on the relative worth of the metal from which they are made. The three most common coins are the gold piece (gp), the silver piece (sp), and the copper piece (cp).",
"With one gold piece, a character can buy a belt pouch, 50 feet of good rope, or a goat. A skilled (but not exceptional) artisan can earn one gold piece a day. The gold piece is the standard unit of measure for wealth, even if the coin itself is not commonly used. When merchants discuss deals that involve goods or services worth hundreds or thousands of gold pieces, the transactions don't usually involve the exchange of individual coins. Rather, the gold piece is a standard measure of value, and the actual exchange is in gold bars, letters of credit, or valuable goods.",
"One gold piece is worth ten silver pieces, the most prevalent coin among commoners. A silver piece buys a laborer's work for a day, a flask of lamp oil, or a night's rest in a poor inn.",
"One silver piece is worth ten copper pieces, which are common among laborers and beggars. A single copper piece buys a candle, a torch, or a piece of chalk.",
"In addition, unusual coins made of other precious metals sometimes appear in treasure hoards. The electrum piece (ep) and the platinum piece (pp) originate from fallen empires and lost kingdoms, and they sometimes arouse suspicion and skepticism when used in transactions. An electrum piece is worth five silver pieces, and a platinum piece is worth ten gold pieces.",
"A standard coin weighs about a third of an ounce, so fifty coins weigh a pound."
],
"hasFluffImages": true
},
{
"name": "Platinum 10-Zino Coin",
"source": "GGR",
"page": 9,
"type": "$",
"rarity": "none",
"value": 1000
},
{
"name": "Platinum 100-Zino Coin",
"source": "GGR",
"page": 9,
"type": "$",
"rarity": "none",
"value": 10000
},
{
"name": "Platinum bracelet set with a sapphire",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 250000
},
{
"name": "Platinum headdress with topaz sun symbol (Sun Empire)",
"source": "PSX",
"page": 25,
"type": "$",
"rarity": "none",
"value": 250000
},
{
"name": "Platinum ring with yellow sapphire (Sun Empire)",
"source": "PSX",
"page": 25,
"type": "$",
"rarity": "none",
"value": 750000
},
{
"name": "Platinum Scarf",
"source": "FTD",
"page": 24,
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"bonusAc": "+1",
"entries": [
"This scarf is made of sturdy cloth and covered in platinum-colored scales.",
"As an action, you can pull a scale from the scarf and speak a command word. When you do so, choose one of the following effects:",
{
"type": "entries",
"name": "Breath of Life",
"entries": [
"The scale disappears, and you or a creature you touch regains {@dice 10d4} hit points."
]
},
{
"type": "entries",
"name": "Platinum Shield",
"entries": [
"For 1 hour or until you dismiss it (no action required), the scale becomes a {@item +1 shield}, which you or another creature can use. A creature wielding the shield has immunity to radiant damage."
]
},
{
"type": "entries",
"name": "Radiant Hammer",
"entries": [
"For 1 hour or until you dismiss it (no action required), the scale becomes a magic {@item light hammer|phb}, which you or another creature can use. The weapon deals {@damage 2d4} radiant damage, instead of the bludgeoning damage normal for a light hammer. It deals an extra {@damage 2d4} radiant damage to chromatic dragons.",
"Once three scales have been pulled from the scarf, no more scales can be removed until the next dawn, when all the missing scales grow back. If you pull off a scale but don't speak a command word, it disappears after 1 minute."
]
}
]
},
{
"name": "Platinum staff topped with amber (Sun Empire)",
"source": "PSX",
"page": 25,
"type": "$",
"rarity": "none",
"value": 750000
},
{
"name": "Playing Card Set",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"type": "GS",
"rarity": "none",
"value": 50
},
{
"name": "Poison Absorbing Tattoo",
"source": "TCE",
"page": 119,
"resist": [
"poison"
],
"detail1": "violet",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"hasRefs": true,
"entries": [
"{#itemEntry Absorbing Tattoo|TCE}"
]
},
{
"name": "Poisoner's Kit",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"additionalSources": [
{
"source": "XGE",
"page": 83
}
],
"type": "T",
"rarity": "none",
"weight": 2,
"value": 5000,
"entries": [
"A poisoner's kit includes the vials, chemicals, and other equipment necessary for the creation of poisons. Proficiency with this kit lets you add your proficiency bonus to any ability checks you make to craft or use poisons.",
"Additionally, the {@book Crafting and Harvesting Poison|dmg|8|Crafting and Harvesting Poison} rules require the use of a poisoner's kit."
],
"additionalEntries": [
"A poisoner's kit is a favored resource for thieves, assassins, and others who engage in skulduggery. It allows you to apply poisons and create them from various materials. Your knowledge of poisons also helps you treat them.",
{
"type": "entries",
"name": "Components",
"entries": [
"A poisoner's kit includes glass vials, a mortar and pestle, chemicals, and a glass stirring rod."
]
},
{
"type": "entries",
"name": "History",
"entries": [
"Your training with poisons can help you when you try to recall facts about infamous poisonings."
]
},
{
"type": "entries",
"name": "Investigation, Perception",
"entries": [
"Your knowledge of poisons has taught you to handle those substances carefully, giving you an edge when you inspect {@condition poisoned} objects or try to extract clues from events that involve poison."
]
},
{
"type": "entries",
"name": "Medicine",
"entries": [
"When you treat the victim of a poison, your knowledge grants you added insight into how to provide the best care to your patient."
]
},
{
"type": "entries",
"name": "Nature, Survival",
"entries": [
"Working with poisons enables you to acquire lore about which plants and animals are poisonous."
]
},
{
"type": "entries",
"name": "Handle Poison",
"entries": [
"Your proficiency allows you to handle and apply a poison without risk of exposing yourself to its effects."
]
},
{
"type": "table",
"caption": "Poisoner's Tools",
"colLabels": [
"Activity",
"DC"
],
"colStyles": [
"col-10",
"col-2 text-center"
],
"rows": [
[
"Spot a {@condition poisoned} object",
"10"
],
[
"Determine the effects of a poison",
"20"
]
]
}
]
},
{
"name": "Pole (10-foot)",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 7,
"value": 5
},
{
"name": "Pole of Angling",
"source": "XGE",
"page": 138,
"tier": "minor",
"rarity": "common",
"wondrous": true,
"entries": [
"While holding this 10-foot pole, you can speak a command word and transform it into a fishing pole with a hook, a line, and a reel. Speaking the command word again changes the fishing pole back into a normal 10-foot pole."
]
},
{
"name": "Pole of Collapsing",
"source": "XGE",
"page": 138,
"tier": "minor",
"rarity": "common",
"wondrous": true,
"entries": [
"While holding this 10-foot pole, you can use an action to speak a command word and cause it to collapse into a 1-foot-long rod, for ease of storage. The pole's weight doesn't change. You can use an action to speak a different command word and cause the rod to revert to a pole; however, the rod will elongate only as far as the surrounding space allows."
],
"hasFluffImages": true
},
{
"name": "Pony",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "MNT",
"rarity": "none",
"value": 3000,
"carryingCapacity": 225,
"speed": 40
},
{
"name": "Portable Hole",
"source": "DMG",
"page": 185,
"srd": true,
"tier": "minor",
"rarity": "rare",
"wondrous": true,
"entries": [
"This fine black cloth, soft as silk, is folded up to the dimensions of a handkerchief. It unfolds into a circular sheet 6 feet in diameter.",
"You can use an action to unfold a portable hole and place it on or against a solid surface, whereupon the portable hole creates an extradimensional hole 10 feet deep. The cylindrical space within the hole exists on a different plane, so it can't be used to create open passages. Any creature inside an open portable hole can exit the hole by climbing out of it.",
"You can use an action to close a portable hole by taking hold of the edges of the cloth and folding it up. Folding the cloth closes the hole, and any creatures or objects within remain in the extradimensional space. No matter what's in it, the hole weighs next to nothing.",
"If the hole is folded up, a creature within the hole's extradimensional space can use an action to make a DC 10 Strength check. On a successful check, the creature forces its way out and appears within 5 feet of the portable hole or the creature carrying it. A breathing creature within a closed portable hole can survive for up to 10 minutes, after which time it begins to suffocate.",
"Placing a portable hole inside an extradimensional space created by a {@item bag of holding}, {@item Heward's handy haversack}, or similar item instantly destroys both items and opens a gate to the Astral Plane. The gate originates where the one item was placed inside the other. Any creature within 10 feet of the gate is sucked through it and deposited in a random location on the Astral Plane. The gate then closes. The gate is one-way only and can't be reopened."
],
"lootTables": [
"Magic Item Table D"
]
},
{
"name": "Portable Ram",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 35,
"value": 400,
"entries": [
"You can use a portable ram to break down doors. When doing so, you gain a +4 bonus on the Strength check. One other character can help you use the ram, giving you advantage on this check."
]
},
{
"name": "Portfolio Keeper",
"source": "AI",
"page": 33,
"rarity": "varies",
"wondrous": true,
"entries": [
"At rank 2, you gain the use of a weathered leather pouch that is an uncommon magic item. The portfolio keeper holds and organizes notes, brochures, and business cards bearing your contact information. It also has an inexhaustible supply of brochures related to your franchise's current branding scheme.",
"When you meet someone for the first time, their details and a rough sketch are magically stored on a small parchment card in the portfolio keeper. You can access the details of any such stored card as a bonus action.",
{
"type": "entries",
"name": "Cards of Sending",
"entries": [
"At rank 3, the power of your portfolio keeper becomes more potent, making it a rare magic item. When you give out a business card stored in your portfolio keeper, you can choose to have the card allow its recipient to contact you through your {@item sending stone|AI} as if they had cast a {@spell sending} spell. A business card loses this power seven days after it has been given out, and no more than five cards can have this power at one time.",
"You can also use your {@item sending stone|AI} to cast {@spell sending} and contact anyone who has one of your business cards. Once you use this feature of the stone, you cannot use it again until dawn seven days later."
]
},
{
"type": "entries",
"name": "Charming Introduction",
"entries": [
"Also at rank 4, you are able to make a focused effort to keep a potential customer's attention. When you provide a humanoid with a business card from your portfolio keeper, you can cast {@spell charm person} on them (save DC 15). The spell ends prematurely if the character loses the business card."
]
}
],
"attachedSpells": [
"sending",
"charm person"
]
},
{
"name": "Pot of Awakening",
"source": "XGE",
"page": 138,
"otherSources": [
{
"source": "IMR",
"page": 95
}
],
"tier": "minor",
"rarity": "common",
"wondrous": true,
"entries": [
"If you plant an ordinary shrub in this 10-pound clay pot and let it grow for 30 days, the shrub magically transforms into an {@creature awakened shrub} at the end of that time. When the shrub awakens, its roots break the pot, destroying it.",
"The awakened shrub is friendly toward you. Absent commands from you, it does nothing."
]
},
{
"name": "Potion of Acid Resistance",
"source": "DMG",
"page": 188,
"srd": true,
"type": "P",
"resist": [
"acid"
],
"tier": "minor",
"rarity": "uncommon",
"hasRefs": true,
"entries": [
"{#itemEntry Potion of Resistance}"
]
},
{
"name": "Potion of Advantage",
"source": "WBtW",
"page": 212,
"type": "P",
"rarity": "uncommon",
"entries": [
"When you drink this potion, you gain advantage on one ability check, attack roll, or saving throw of your choice that you make within the next hour.",
"This potion takes the form of a sparkling, golden mist that moves and pours like water."
]
},
{
"name": "Potion of Animal Friendship",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "uncommon",
"entries": [
"When you drink this potion, you can cast the {@spell animal friendship} spell (save DC 13) for 1 hour at will. Agitating this muddy liquid brings little bits into view: a fish scale, a hummingbird tongue, a cat claw, or a squirrel hair."
],
"attachedSpells": [
"animal friendship"
],
"lootTables": [
"Magic Item Table B"
]
},
{
"name": "Potion of Aqueous Form",
"source": "MOT",
"page": 197,
"type": "P",
"rarity": "rare",
"modifySpeed": {
"static": {
"swim": 30
}
},
"entries": [
"When you drink this potion, you transform into a pool of water. You return to your true form after 10 minutes or if you are {@condition incapacitated} or die.",
"You're under the following effects while in this form:",
{
"type": "list",
"style": "list-hang-notitle",
"items": [
{
"type": "item",
"name": "Liquid Movement",
"entry": "You have a swimming speed of 30 feet. You can move over or through other liquids. You can enter and occupy the space of another creature. You can rise up to your normal height, and you can pass through even Tiny openings. You extinguish nonmagical flames in any space you enter."
},
{
"type": "item",
"name": "Watery Resilience",
"entry": "You have resistance to nonmagical damage. You also have advantage on Strength, Dexterity, and Constitution saving throws."
},
{
"type": "item",
"name": "Limitations",
"entry": "You can't talk, attack, cast spells, or activate magic items. Any objects you were carrying or wearing meld into your new form and are inaccessible, though you continue to be affected by anything you're wearing, such as armor."
}
]
}
]
},
{
"name": "Potion of Clairvoyance",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "rare",
"entries": [
"When you drink this potion, you gain the effect of the {@spell clairvoyance} spell. An eyeball bobs in this yellowish liquid but vanishes when the potion is opened."
],
"attachedSpells": [
"clairvoyance"
],
"lootTables": [
"Magic Item Table C"
]
},
{
"name": "Potion of Climbing",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "common",
"modifySpeed": {
"equal": {
"climb": "walk"
}
},
"entries": [
"When you drink this potion, you gain a climbing speed equal to your walking speed for 1 hour. During this time, you have advantage on Strength ({@skill Athletics}) checks you make to climb. The potion is separated into brown, silver, and gray layers resembling bands of stone. Shaking the bottle fails to mix the colors."
],
"lootTables": [
"Magic Item Table A"
]
},
{
"name": "Potion of Cloud Giant Strength",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "very rare",
"ability": {
"static": {
"str": 27
}
},
"entries": [
"When you drink this potion, your Strength score changes to 27 for 1 hour. The potion has no effect on you if your Strength is equal to or greater than that score.",
"This potion's transparent liquid has floating in it a sliver of fingernail from a {@creature cloud giant}."
],
"lootTables": [
"Magic Item Table D"
]
},
{
"name": "Potion of Cold Resistance",
"source": "DMG",
"page": 188,
"srd": true,
"type": "P",
"resist": [
"cold"
],
"tier": "minor",
"rarity": "uncommon",
"hasRefs": true,
"entries": [
"{#itemEntry Potion of Resistance}"
]
},
{
"name": "Potion of Comprehension",
"source": "WDMM",
"page": 62,
"type": "P",
"rarity": "common",
"entries": [
"When you drink this potion, you gain the effect of a {@spell comprehend languages} spell for 1 hour. This liquid is a clear concoction with bits of salt and soot swirling in it."
]
},
{
"name": "Potion of Diminution",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "rare",
"entries": [
"When you drink this potion, you gain the \"reduce\" effect of the {@spell enlarge/reduce} spell for {@dice 1d4} hours (no concentration required). The red in the potion's liquid continuously contracts to a tiny bead and then expands to color the clear liquid around it. Shaking the bottle fails to interrupt this process."
],
"attachedSpells": [
"enlarge/reduce"
],
"lootTables": [
"Magic Item Table C"
]
},
{
"name": "Potion of Dragon's Majesty",
"source": "FTD",
"page": 24,
"type": "P",
"rarity": "legendary",
"entries": [
"This potion looks like liquid gold, with a single scale from a {@filter chromatic|bestiary|search=chromatic dragon}, {@filter gem|bestiary|search=gem dragon}, or {@filter metallic|bestiary|search=metallic dragon} dragon suspended in it. When you drink this potion, you transform into an adult dragon of the same kind as the dragon the scale came from. The transformation lasts for 1 hour. Any equipment you are wearing or carrying melds into your new form or falls to the ground (your choice). For the duration, you use the game statistics of the adult dragon instead of your own, but you retain your languages, personality, and memories. You can't use a dragon's Change Shape or its legendary or lair actions."
]
},
{
"name": "Potion of Fire Breath",
"source": "DMG",
"page": 187,
"type": "P",
"tier": "minor",
"rarity": "uncommon",
"entries": [
"After drinking this potion, you can use a bonus action to exhale fire at a target within 30 feet of you. The target must make a DC 13 Dexterity saving throw, taking {@damage 4d6} fire damage on a failed save, or half as much damage on a successful one. The effect ends after you exhale the fire three times or when 1 hour has passed. This potion's orange liquid flickers, and smoke fills the top of the container and wafts out whenever it is opened."
],
"lootTables": [
"Magic Item Table B"
]
},
{
"name": "Potion of Fire Giant Strength",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "rare",
"ability": {
"static": {
"str": 25
}
},
"entries": [
"When you drink this potion, your Strength score changes to 25 for 1 hour. The potion has no effect on you if your Strength is equal to or greater than that score.",
"This potion's transparent liquid has floating in it a sliver of fingernail from a {@creature fire giant}."
],
"lootTables": [
"Magic Item Table C"
]
},
{
"name": "Potion of Fire Resistance",
"source": "DMG",
"page": 188,
"srd": true,
"type": "P",
"resist": [
"fire"
],
"tier": "minor",
"rarity": "uncommon",
"hasRefs": true,
"entries": [
"{#itemEntry Potion of Resistance}"
]
},
{
"name": "Potion of Flying",
"source": "DMG",
"page": 187,
"srd": true,
"basicRules": true,
"type": "P",
"tier": "minor",
"rarity": "very rare",
"modifySpeed": {
"equal": {
"fly": "walk"
}
},
"entries": [
"When you drink this potion, you gain a flying speed equal to your walking speed for 1 hour and can hover. If you're in the air when the potion wears off, you fall unless you have some other means of staying aloft. This potion's clear liquid floats at the top of its container and has cloudy white impurities drifting in it."
],
"lootTables": [
"Magic Item Table D"
]
},
{
"name": "Potion of Force Resistance",
"source": "DMG",
"page": 188,
"srd": true,
"type": "P",
"resist": [
"force"
],
"tier": "minor",
"rarity": "uncommon",
"hasRefs": true,
"entries": [
"{#itemEntry Potion of Resistance}"
]
},
{
"name": "Potion of Frost Giant Strength",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "rare",
"ability": {
"static": {
"str": 23
}
},
"entries": [
"When you drink this potion, your Strength score changes to 23 for 1 hour. The potion has no effect on you if your Strength is equal to or greater than that score.",
"This potion's transparent liquid has floating in it a sliver of fingernail from a {@creature frost giant}."
],
"lootTables": [
"Magic Item Table C"
]
},
{
"name": "Potion of Gaseous Form",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "rare",
"entries": [
"When you drink this potion, you gain the effect of the {@spell gaseous form} spell for 1 hour (no concentration required) or until you end the effect as a bonus action. This potion's container seems to hold fog that moves and pours like water."
],
"attachedSpells": [
"gaseous form"
],
"lootTables": [
"Magic Item Table C"
]
},
{
"name": "Potion of Giant Size",
"source": "SKT",
"page": 236,
"type": "P",
"rarity": "legendary",
"entries": [
"When you drink this potion, you become Huge for 24 hours if you are Medium or smaller, otherwise the potion does nothing. For that duration, your Strength becomes 25, if it isn't already higher, and your hit point maximum is doubled (your current hit points are doubled when you drink the potion). In addition, the reach of your melee attacks increases by 5 feet.",
"Everything you are carrying and wearing also increases in size for the duration. When rolling damage for weapons enlarged in this manner, roll three times the normal number of dice; for example, an enlarged longsword would deal {@damage 3d8} slashing damage (instead of {@dice 1d8}), or {@damage 3d10} slashing damage (instead of {@dice 1d10}) when used with two hands.",
"When the effect ends, any hit points you have above your hit point maximum become temporary hit points. This potion is a pale white liquid made from the tongue of a giant clam, with a pungent aroma akin to that of rotting algae. It tastes sweet, however, when consumed."
]
},
{
"name": "Potion of Greater Healing",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "uncommon",
"entries": [
"You regain {@dice 4d4 + 4} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
],
"lootTables": [
"Magic Item Table A",
"Magic Item Table B"
]
},
{
"name": "Potion of Greater Healing",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "uncommon",
"entries": [
"You regain {@dice 4d4 + 4} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
],
"lootTables": [
"Magic Item Table A",
"Magic Item Table B"
]
},
{
"name": "Potion of Greater Healing",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "uncommon",
"entries": [
"You regain {@dice 4d4 + 4} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
],
"lootTables": [
"Magic Item Table A",
"Magic Item Table B"
]
},
{
"name": "Potion of Greater Healing",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "uncommon",
"entries": [
"You regain {@dice 4d4 + 4} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
],
"lootTables": [
"Magic Item Table A",
"Magic Item Table B"
]
},
{
"name": "Potion of Greater Healing",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "uncommon",
"entries": [
"You regain {@dice 4d4 + 4} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
],
"lootTables": [
"Magic Item Table A",
"Magic Item Table B"
]
},
{
"name": "Potion of Greater Healing",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "uncommon",
"entries": [
"You regain {@dice 4d4 + 4} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
],
"lootTables": [
"Magic Item Table A",
"Magic Item Table B"
]
},
{
"name": "Potion of Greater Healing",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "uncommon",
"entries": [
"You regain {@dice 4d4 + 4} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
],
"lootTables": [
"Magic Item Table A",
"Magic Item Table B"
]
},
{
"name": "Potion of Greater Healing",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "uncommon",
"entries": [
"You regain {@dice 4d4 + 4} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
],
"lootTables": [
"Magic Item Table A",
"Magic Item Table B"
]
},
{
"name": "Potion of Greater Healing",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "uncommon",
"entries": [
"You regain {@dice 4d4 + 4} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
],
"lootTables": [
"Magic Item Table A",
"Magic Item Table B"
]
},
{
"name": "Potion of Growth",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "uncommon",
"entries": [
"When you drink this potion, you gain the \"enlarge\" effect of the {@spell enlarge/reduce} spell for {@dice 1d4} hours (no concentration required). The red in the potion's liquid continuously expands from a tiny bead to color the clear liquid around it and then contracts. Shaking the bottle fails to interrupt this process."
],
"attachedSpells": [
"enlarge/reduce"
],
"lootTables": [
"Magic Item Table B"
]
},
{
"name": "Potion of Healing",
"source": "DMG",
"page": 187,
"srd": true,
"otherSources": [
{
"source": "PHB",
"page": 153
}
],
"type": "P",
"tier": "minor",
"rarity": "common",
"weight": 0.5,
"value": 5000,
"entries": [
"You regain {@dice 2d4 + 2} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
],
"lootTables": [
"Magic Item Table A"
]
},
{
"name": "Potion of Healing",
"source": "DMG",
"page": 187,
"srd": true,
"otherSources": [
{
"source": "PHB",
"page": 153
}
],
"type": "P",
"tier": "minor",
"rarity": "common",
"weight": 0.5,
"value": 5000,
"entries": [
"You regain {@dice 2d4 + 2} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
],
"lootTables": [
"Magic Item Table A"
]
},
{
"name": "Potion of Healing",
"source": "DMG",
"page": 187,
"srd": true,
"otherSources": [
{
"source": "PHB",
"page": 153
}
],
"type": "P",
"tier": "minor",
"rarity": "common",
"weight": 0.5,
"value": 5000,
"entries": [
"You regain {@dice 2d4 + 2} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
],
"lootTables": [
"Magic Item Table A"
]
},
{
"name": "Potion of Healing",
"source": "DMG",
"page": 187,
"srd": true,
"otherSources": [
{
"source": "PHB",
"page": 153
}
],
"type": "P",
"tier": "minor",
"rarity": "common",
"weight": 0.5,
"value": 5000,
"entries": [
"You regain {@dice 2d4 + 2} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
],
"lootTables": [
"Magic Item Table A"
]
},
{
"name": "Potion of Heroism",
"source": "DMG",
"page": 188,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "rare",
"entries": [
"For 1 hour after drinking it, you gain 10 temporary hit points that last for 1 hour. For the same duration, you are under the effect of the {@spell bless} spell (no concentration required). This blue potion bubbles and steams as if boiling."
],
"attachedSpells": [
"bless"
],
"lootTables": [
"Magic Item Table C"
]
},
{
"name": "Potion of Hill Giant Strength",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "uncommon",
"ability": {
"static": {
"str": 21
}
},
"entries": [
"When you drink this potion, your Strength score changes to 21 for 1 hour. The potion has no effect on you if your Strength is equal to or greater than that score.",
"This potion's transparent liquid has floating in it a sliver of fingernail from a {@creature hill giant}."
],
"lootTables": [
"Magic Item Table B"
]
},
{
"name": "Potion of Invisibility",
"source": "DMG",
"page": 188,
"srd": true,
"basicRules": true,
"type": "P",
"tier": "minor",
"rarity": "very rare",
"entries": [
"This potion's container looks empty but feels as though it holds liquid. When you drink it, you become {@condition invisible} for 1 hour. Anything you wear or carry is {@condition invisible} with you. The effect ends early if you attack or cast a spell."
],
"lootTables": [
"Magic Item Table D"
]
},
{
"name": "Potion of Invulnerability",
"source": "DMG",
"page": 188,
"type": "P",
"resist": [
"acid",
"bludgeoning",
"cold",
"fire",
"force",
"lightning",
"necrotic",
"piercing",
"poison",
"psychic",
"radiant",
"slashing",
"thunder"
],
"tier": "minor",
"rarity": "rare",
"entries": [
"For 1 minute after you drink this potion, you have resistance to all damage. The potion's syrupy liquid looks like liquefied iron."
],
"lootTables": [
"Magic Item Table C"
]
},
{
"name": "Potion of Lightning Resistance",
"source": "DMG",
"page": 188,
"srd": true,
"type": "P",
"resist": [
"lightning"
],
"tier": "minor",
"rarity": "uncommon",
"hasRefs": true,
"entries": [
"{#itemEntry Potion of Resistance}"
]
},
{
"name": "Potion of Longevity",
"source": "DMG",
"page": 188,
"type": "P",
"tier": "minor",
"rarity": "very rare",
"entries": [
"When you drink this potion, your physical age is reduced by {@dice 1d6 + 6} years, to a minimum of 13 years. Each time you subsequently drink a potion of longevity, there is 10 percent cumulative chance that you instead age by {@dice 1d6 + 6} years. Suspended in this amber liquid are a scorpion's tail, an adder's fang, a dead spider, and a tiny heart that, against all reason, is still beating. These ingredients vanish when the potion is opened."
],
"lootTables": [
"Magic Item Table D"
]
},
{
"name": "Potion of Maximum Power",
"source": "EGW",
"page": 268,
"type": "P",
"rarity": "rare",
"entries": [
"The first time you cast a damage-dealing spell of 4th level or lower within 1 minute after drinking the potion, instead of rolling dice to determine the damage dealt, you can instead use the highest number possible for each die.",
"This glowing purple liquid smells of sugar and plum, but it has a muddy taste."
]
},
{
"name": "Potion of Mind Control (beast)",
"source": "TftYP",
"page": 229,
"type": "P",
"rarity": "rare",
"entries": [
"When you drink a potion of mind control, you can cast a dominate spell (save DC 15) on a specific creature if you do so before the end of your next turn. If you don't, the potion is wasted.",
"A potion of mind control produces the effect of a {@spell dominate beast} spell. If the target's initial saving throw fails, the effect lasts for 1 hour, with no concentration required on your part. The {@condition charmed} creature has disadvantage on new saving throws to break the effect during this time."
],
"attachedSpells": [
"dominate beast"
]
},
{
"name": "Potion of Mind Control (humanoid)",
"source": "TftYP",
"page": 229,
"type": "P",
"rarity": "rare",
"entries": [
"When you drink a potion of mind control, you can cast a dominate spell (save DC 15) on a specific creature if you do so before the end of your next turn. If you don't, the potion is wasted.",
"A potion of mind control produces the effect of a {@spell dominate person} spell. If the target's initial saving throw fails, the effect lasts for 1 hour, with no concentration required on your part. The {@condition charmed} creature has disadvantage on new saving throws to break the effect during this time."
],
"attachedSpells": [
"dominate person"
]
},
{
"name": "Potion of Mind Control (monster)",
"source": "TftYP",
"page": 229,
"type": "P",
"rarity": "very rare",
"entries": [
"When you drink a potion of mind control, you can cast a dominate spell (save DC 15) on a specific creature if you do so before the end of your next turn. If you don't, the potion is wasted.",
"A potion of mind control produces the effect of a {@spell dominate monster} spell. If the target's initial saving throw fails, the effect lasts for 1 hour, with no concentration required on your part. The {@condition charmed} creature has disadvantage on new saving throws to break the effect during this time."
],
"attachedSpells": [
"dominate monster"
]
},
{
"name": "Potion of Mind Reading",
"source": "DMG",
"page": 188,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "rare",
"entries": [
"When you drink this potion, you gain the effect of the {@spell detect thoughts} spell (save DC 13). The potion's dense, purple liquid has an ovoid cloud of pink floating in it."
],
"attachedSpells": [
"detect thoughts"
],
"lootTables": [
"Magic Item Table C"
]
},
{
"name": "Potion of Necrotic Resistance",
"source": "DMG",
"page": 188,
"srd": true,
"type": "P",
"resist": [
"necrotic"
],
"tier": "minor",
"rarity": "uncommon",
"hasRefs": true,
"entries": [
"{#itemEntry Potion of Resistance}"
]
},
{
"name": "Potion of Poison",
"source": "DMG",
"page": 188,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "uncommon",
"entries": [
"This concoction looks, smells, and tastes like a {@item potion of healing} or other beneficial potion. However, it is actually poison masked by illusion magic. An {@spell identify} spell reveals its true nature.",
"If you drink it, you take {@damage 3d6} poison damage, and you must succeed on a DC 13 Constitution saving throw or be {@condition poisoned}. At the start of each of your turns while you are {@condition poisoned} in this way, you take {@damage 3d6} poison damage. At the end of each of your turns, you can repeat the saving throw. On a successful save, the poison damage you take on your subsequent turns decreases by {@dice 1d6}. The poison ends when the damage decreases to 0."
],
"lootTables": [
"Magic Item Table B"
]
},
{
"name": "Potion of Poison Resistance",
"source": "DMG",
"page": 188,
"srd": true,
"type": "P",
"resist": [
"poison"
],
"tier": "minor",
"rarity": "uncommon",
"hasRefs": true,
"entries": [
"{#itemEntry Potion of Resistance}"
]
},
{
"name": "Potion of Possibility",
"source": "EGW",
"page": 268,
"type": "P",
"rarity": "very rare",
"entries": [
"When you drink this clear potion, you gain two Fragments of Possibility, each of which looks like a Tiny, grayish bead of energy that follows you around, staying within 1 foot of you at all times. Each fragment lasts for 8 hours or until used.",
"When you make an attack roll, an ability check, or a saving throw, you can expend your fragment to roll an additional {@dice d20} and choose which of the {@dice d20}s to use. Alternatively, when an attack roll is made against you, you can expend your fragment to roll a {@dice d20} and choose which of the {@dice d20}s to use, the one you rolled or the one the attacker rolled.",
"If the original {@dice d20} roll has advantage or disadvantage, you roll your {@dice d20} after advantage or disadvantage has been applied to the original roll.",
"While you have one or more Fragments of Possibility from this potion, you can't gain another Fragment of Possibility from any source."
]
},
{
"name": "Potion of Psychic Resistance",
"source": "DMG",
"page": 188,
"srd": true,
"type": "P",
"resist": [
"psychic"
],
"tier": "minor",
"rarity": "uncommon",
"hasRefs": true,
"entries": [
"{#itemEntry Potion of Resistance}"
]
},
{
"name": "Potion of Radiant Resistance",
"source": "DMG",
"page": 188,
"srd": true,
"type": "P",
"resist": [
"radiant"
],
"tier": "minor",
"rarity": "uncommon",
"hasRefs": true,
"entries": [
"{#itemEntry Potion of Resistance}"
]
},
{
"name": "Potion of Speed",
"source": "DMG",
"page": 188,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "very rare",
"entries": [
"When you drink this potion, you gain the effect of the {@spell haste} spell for 1 minute (no concentration required). The potion's yellow fluid is streaked with black and swirls on its own."
],
"attachedSpells": [
"haste"
],
"lootTables": [
"Magic Item Table D"
]
},
{
"name": "Potion of Stone Giant Strength",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "rare",
"ability": {
"static": {
"str": 23
}
},
"entries": [
"When you drink this potion, your Strength score changes to 23 for 1 hour. The potion has no effect on you if your Strength is equal to or greater than that score.",
"This potion's transparent liquid has floating in it a sliver of fingernail from a {@creature stone giant}."
],
"lootTables": [
"Magic Item Table C"
]
},
{
"name": "Potion of Storm Giant Strength",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "legendary",
"ability": {
"static": {
"str": 29
}
},
"entries": [
"When you drink this potion, your Strength score changes to 29 for 1 hour. The potion has no effect on you if your Strength is equal to or greater than that score.",
"This potion's transparent liquid has floating in it a sliver of fingernail from a {@creature storm giant}."
],
"lootTables": [
"Magic Item Table E"
]
},
{
"name": "Potion of Superior Healing",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "rare",
"entries": [
"You regain {@dice 8d4 + 8} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
],
"lootTables": [
"Magic Item Table C"
]
},
{
"name": "Potion of Supreme Healing",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "very rare",
"entries": [
"You regain {@dice 10d4 + 20} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
],
"lootTables": [
"Magic Item Table D",
"Magic Item Table E"
]
},
{
"name": "Potion of Superior Healing",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "rare",
"entries": [
"You regain {@dice 8d4 + 8} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
],
"lootTables": [
"Magic Item Table C"
]
},
{
"name": "Potion of Supreme Healing",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "very rare",
"entries": [
"You regain {@dice 10d4 + 20} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
],
"lootTables": [
"Magic Item Table D",
"Magic Item Table E"
]
},
{
"name": "Potion of Superior Healing",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "rare",
"entries": [
"You regain {@dice 8d4 + 8} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
],
"lootTables": [
"Magic Item Table C"
]
},
{
"name": "Potion of Supreme Healing",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "very rare",
"entries": [
"You regain {@dice 10d4 + 20} hit points when you drink this potion. The potion's red liquid glimmers when agitated."
],
"lootTables": [
"Magic Item Table D",
"Magic Item Table E"
]
},
{
"name": "Potion of Thunder Resistance",
"source": "DMG",
"page": 188,
"srd": true,
"type": "P",
"resist": [
"thunder"
],
"tier": "minor",
"rarity": "uncommon",
"hasRefs": true,
"entries": [
"{#itemEntry Potion of Resistance}"
]
},
{
"name": "Potion of Vitality",
"source": "DMG",
"page": 188,
"basicRules": true,
"type": "P",
"tier": "minor",
"rarity": "very rare",
"entries": [
"When you drink this potion, it removes any {@condition exhaustion} you are suffering and cures any disease or poison affecting you. For the next 24 hours, you regain the maximum number of hit points for any Hit Die you spend. The potion's crimson liquid regularly pulses with dull light, calling to mind a heartbeat."
],
"lootTables": [
"Magic Item Table D"
]
},
{
"name": "Potion of Watchful Rest",
"source": "WDMM",
"page": 62,
"type": "P",
"rarity": "common",
"entries": [
"When you drink this potion, you gain the following benefits for the next 8 hours: magic can't put you to sleep, and you can remain awake during a long rest and still gain its benefits. This sweet, amber-colored brew has no effect on creatures that don't require sleep, such as elves."
]
},
{
"name": "Potion of Water Breathing",
"source": "DMG",
"page": 188,
"srd": true,
"type": "P",
"tier": "minor",
"rarity": "uncommon",
"entries": [
"You can breathe underwater for 1 hour after drinking this potion. Its cloudy green fluid smells of the sea and has a jellyfish-like bubble floating in it."
],
"lootTables": [
"Magic Item Table B"
],
"hasFluffImages": true
},
{
"name": "Potter's Tools",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"additionalSources": [
{
"source": "XGE",
"page": 84
}
],
"type": "AT",
"rarity": "none",
"weight": 3,
"value": 1000,
"additionalEntries": [
"Potter's tools are used to create a variety of ceramic objects, most typically pots and similar vessels.",
{
"type": "entries",
"name": "Components",
"entries": [
"Potter's tools include potter's needles, ribs, scrapers, a knife, and calipers."
]
},
{
"type": "entries",
"name": "History",
"entries": [
"Your expertise aids you in identifying ceramic objects, including when they were created and their likely place or culture of origin."
]
},
{
"type": "entries",
"name": "Investigation, Perception",
"entries": [
"You gain additional insight when inspecting ceramics, uncovering clues others would overlook by spotting minor irregularities."
]
},
{
"type": "entries",
"name": "Reconstruction",
"entries": [
"By examining pottery shards, you can determine an object's original, intact form and its likely purpose."
]
},
{
"type": "table",
"caption": "Potter's Tools",
"colLabels": [
"Activity",
"DC"
],
"colStyles": [
"col-10",
"col-2 text-center"
],
"rows": [
[
"Determine what a vessel once held",
"10"
],
[
"Create a serviceable pot",
"15"
],
[
"Find a weak point in a ceramic object",
"20"
]
]
}
]
},
{
"name": "Pouch",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 1,
"value": 50,
"entries": [
"A cloth or leather pouch can hold up to 20 {@item sling bullet|phb|sling bullets} or 50 {@item blowgun needle|phb|blowgun needles}, among other things. A compartmentalized pouch for holding spell components is called a {@item component pouch|phb}. A pouch can hold up to ⅕ cubic foot or 6 pounds of gear."
],
"containerCapacity": {
"weight": [
6
],
"item": [
{
"sling bullet|phb": 20,
"blowgun needle|phb": 50
}
]
}
},
{
"name": "Powered Armor",
"source": "LLK",
"page": 56,
"baseItem": "plate armor|phb",
"type": "HA",
"rarity": "legendary",
"reqAttune": true,
"weight": 65,
"ac": 18,
"ability": {
"static": {
"str": 18
}
},
"bonusAc": "+1",
"entries": [
"Powered armor resembles a suit of unusual plate armor, with finely articulated joints connected by an oily, black, leather-like material. The armor has been worked to create the appearance of a heavily muscled warrior, and its great helm is unusual in that it has no openings—only a broad glass plate in the front with a second piece of glass above it. Strange plates, tubing, and large metal bosses adorn the armor in seemingly random fashion. On the back of the armor's left gauntlet is a rectangular metal box, from which projects a short rod tipped with a cone-shaped red crystal.",
"While wearing this armor, you gain the following benefits:",
{
"type": "list",
"items": [
"You have a +1 bonus to AC.",
"Your Strength score is 18 (this has no effect if your Strength is already 18 or higher).",
"You have advantage on death saving throws."
]
},
"The armor has further capabilities that can be powered either by energy cells or by your own life energy. You can use a bonus action to draw power from an energy cell or sacrifice hit points to gain one of the following benefits:",
{
"type": "list",
"items": [
"Emit a force field to gain {@dice 2d6 + 5} temporary hit points (1 charge or 5 hit points).",
"Activate boosters to gain a flying speed of 15 feet for 1 minute (1 charge or 5 hit points).",
"Fire arm-mounted laser: {@atk rw} {@hit 8} to hit, range 120 feet, one target. {@h}{@damage 2d6} radiant damage (1 charge or 5 hit points).",
"Translate any writing you can see in any nonmagical language, to a total of one thousand words over 1 minute (1 charge or 5 hit points).",
"Fill the armor with air, allowing you to breathe normally in any environment for up to 1 hour (1 charge or 5 hit points).",
"Gain {@sense darkvision} to a range of 60 feet for up to 1 hour (1 charge or 5 hit points)."
]
},
"The armor can accept only one energy cell at a time. It is found with one energy cell attached, containing {@dice 2d10} charges.",
{
"type": "inset",
"name": "Powered Armor Options",
"entries": [
"Depending on where and how it appears in the adventure, you might wish to modify the features of Kwalish's legendary powered armor.",
{
"type": "entries",
"name": "Automatic Defenses",
"entries": [
"Unless Kwalish deactivates the suit's automatic defenses, no one can approach the armor without setting those defenses off. Treat the armor as a {@creature shield guardian} that has stored a {@spell magic missile} spell cast using a 4th-level spell slot. When the armor is reduced to 0 hit points, its defenses are rendered inert and it can be safely approached."
]
},
{
"type": "entries",
"name": "Battle of Wills",
"entries": [
"When donned by a new user, the armor deems itself superior and attempts to take possession of that user. The user must succeed on a DC 13 Charisma saving throw or be possessed by the armor. While possessed, the user is {@condition incapacitated} and loses control of its body but retains its awareness. The armor uses the possessed user's statistics (as adjusted by the armor), but doesn't gain access to the user's knowledge, features, or proficiencies.",
"Freeing a creature trapped inside the armor first requires defeating the armor's automatic defenses (as above). The trapped creature can also attempt a DC 20 Charisma saving throw each day at dawn. On a successful save, the armor no longer controls the creature and can be safely donned by that creature at any time."
]
},
{
"type": "entries",
"name": "Stasis",
"entries": [
"Whenever a creature wearing the armor drops to 0 hit points, the armor places that creature into a state of stasis. While in this state, the creature is stable and does not make death saving throws, but the armor takes control of the creature (as above). Additionally, the armor attempts to assume the identity of the user, assuring their allies that nothing is amiss. Freeing the user first requires defeating the armor's automatic defenses (as above). A creature in stasis does not make Charisma saving throws to break the armor's control."
]
},
{
"type": "entries",
"name": "Alternative Power",
"entries": [
"Powered armor originally required energy cells to fuel it, but was adapted by Kwalish to be fueled by the life energy of the creature wearing it. You might decide that the armor can also draw power from additional sources, or that energy cells can be recharged with the aid of a tinker, inventor, or artificer. It might also be possible for allies to connect to the armor through the use of magic that generates a conduit something like an astral silver cord. While so connected, a willing ally can give up hit points as a reaction to fuel the armor's abilities."
]
}
]
}
]
},
{
"name": "Pressure Capsule",
"source": "GoS",
"page": 229,
"rarity": "common",
"wondrous": true,
"entries": [
"This small capsule is made of beeswax blended with sand and a variety of enchanted water plants. A creature who consumes a pressure capsule ignores the effects of swimming at depths greater than 100 feet (see \"{@book Unusual Environments|dmg|5|Unusual Environments}\" in chapter 5 of the Dungeon Master's Guide)."
]
},
{
"name": "Pride Silk",
"source": "EGW",
"page": 103,
"type": "G",
"rarity": "none",
"weight": 1,
"value": 10000,
"entries": [
"The hearty silk unique to the region has long been a point of pride with the locals. When processed and woven properly, the resulting cloth is pride silk: an extremely flexible material with the durability of tanned leather. Pride silk has become a favorite cloth for use on military ship sails, war banners, and other utilitarian applications, but has also gained popularity in affluent circles. High-end clothing made from pride silk is both fashionable and protective, leading many nobles and wealthy merchants to seek full outfits and formal gowns made with it. The tailors skilled enough to create such outfits are few and far between, so while pride silk might be expensive as a raw material, outfits made of the cloth are prohibitively expensive for most common folk."
]
},
{
"name": "Pride Silk Outfit",
"source": "EGW",
"page": 103,
"type": "G",
"rarity": "none",
"weight": 4,
"value": 50000,
"entries": [
"An outfit made of pride silk weighs 4 pounds and costs 500 gp. If you aren't wearing armor, your base Armor Class is 11 + your Dexterity modifier while wearing it."
]
},
{
"name": "Priest's Pack",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 24,
"value": 1900,
"entries": [
"Includes:",
{
"type": "list",
"items": [
"a {@item backpack|phb}",
"a {@item blanket|phb}",
"10 {@item candle|phb|candles}",
"a {@item tinderbox|phb}",
"an alms box",
"2 blocks of incense",
"a censer",
"vestments",
"2 days of {@item Rations (1 day)|phb|rations}",
"a {@item waterskin|phb}."
]
}
],
"packContents": [
"backpack|phb",
"blanket|phb",
{
"item": "candle|phb",
"quantity": 10
},
"tinderbox|phb",
{
"item": "rations (1 day)|phb",
"quantity": 2
},
"waterskin|phb",
{
"special": "alms box"
},
{
"special": "block of incense",
"quantity": 2
},
{
"special": "censer"
},
{
"special": "vestments"
}
]
},
{
"name": "Primal Amulet",
"source": "XMtS",
"page": 19,
"rarity": "unknown (magic)",
"entries": [
"This beaded jade necklace bears an ancient symbol of the River Heralds. When worn, the primal amulet allows its wearer to cast {@spell speak with animals}, {@spell locate object}, and {@spell pass without trace}. Once the amulet has been used to cast a spell, it can't be used to cast that spell again until the next dawn."
]
},
{
"name": "Prismari Primer",
"source": "SCC",
"page": 39,
"rarity": "uncommon",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"The Prismari Primer is a magic textbook created at Strixhaven's Prismari College. The primer has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. If you make a Dexterity ({@skill Acrobatics}) or a Charisma ({@skill Performance}) check while holding the primer, you can expend 1 charge to give yourself a {@dice 1d4} bonus to the check, immediately after you roll the {@dice d20}.",
"In addition, if you study the primer at the end of a long rest, you can choose one 1st-level spell from the bard or sorcerer spell list. Before you finish your next long rest, you can cast the chosen spell once without a spell slot if you are holding the primer. Your spellcasting ability for this spell is your choice of Intelligence, Wisdom, or Charisma."
]
},
{
"name": "Prismatic Well",
"source": "NRH-AT",
"page": 8,
"rarity": "legendary",
"wondrous": true,
"entries": [
"This well enhances the colors of the city of Harmony and invigorates the population. When the well is at full power, all good-aligned creatures that see the colors experience feelings of happiness and have advantage on all Charisma checks."
]
},
{
"name": "Professor Orb",
"source": "WDMM",
"page": 284,
"rarity": "rare",
"wondrous": true,
"sentient": true,
"weight": 5,
"entries": [
"Each professor orb takes the form of a smooth, solid, 5-pound sphere of smoky gray quartz about the size of a grapefruit. Close examination reveals two or more pinpricks of silver light deep inside the sphere.",
"A Professor Orb is sentient and has the personality of a scholar. Its alignment is determined by rolling on the alignment table in the \"{@book Sentient Magic Items|DMG|7|Sentient Magic Items}\" section in chapter 7 of the {@book Dungeon Master's Guide|dmg}. Regardless of its disposition, the orb has an Intelligence of 18, and Wisdom and Charisma scores determined by rolling {@dice 3d6} for each ability. The orb speaks, reads, and understands four languages, and can see and hear normally out to a range of 60 feet. Unlike most sentient items, the orb has no will of its own and can't initiate a conflict with the creature in possession of it.",
"A Professor Orb has extensive knowledge of four narrow academic subjects. When making an Intelligence check to recall lore from any of its areas of expertise, the orb has a +9 bonus to its roll (including its Intelligence modifier).",
"In addition to the knowledge it possesses, a professor orb can cast the {@spell mage hand} cantrip at will. It uses the spell only to transport itself. Its spellcasting ability is Intelligence."
],
"attachedSpells": [
"mage hand"
]
},
{
"name": "Professor Skant",
"source": "IDRotF",
"page": 315,
"_copy": {
"name": "Professor Orb",
"source": "WDMM"
},
"entries": [
"The {@item professor orb|WDMM} owned by Vellynne Harpell and stolen by Nass Lantomir calls itself Professor Skant. It is lawful good, and it has a Wisdom of 11 and a Charisma of 9 {@note (as a professor orb, it has an Intelligence of 18)}. It speaks and reads Common, Draconic, Elvish, and Loross (the dead language of the Empire of Netheril). Professor Skant is a chatterbox and assumes all humanoids are dunderheads. When elaborating on its areas of expertise, it adopts an unintentionally patronizing tone. It has the following four areas of expertise:",
{
"type": "list",
"items": [
"The history of Netheril (see the \"{@adventure Fate of Netheril|IDRotF|17|Fate of Netheril}\" sidebar)",
"Vampirism and the traits of vampires",
"Rituals surrounding the making, bottling, and drinking of Elverquisst (a rare, ruby-colored elven liquor distilled from sunshine and rare summer fruits)",
"The {@creature tarrasque} (see the Monster Manual)"
]
},
"When making an Intelligence check to recall lore from any of its areas of expertise, the orb has a +9 bonus to its roll (including its Intelligence modifier).",
"Professor Skant takes the form of a smooth, solid, 5-pound sphere of smoky gray quartz about the size of a grapefruit. Close examination reveals two or more pinpricks of silver light deep inside the sphere.",
"Professor Skant can see and hear normally out to a range of 60 feet. Unlike most sentient items, the orb has no will of its own and can't initiate a conflict with the creature in possession of it.",
"In addition to the knowledge it possesses, Professor Skant can cast the {@spell mage hand} cantrip at will. It uses the spell only to transport itself. Its spellcasting ability is Intelligence."
]
},
{
"name": "Propeller Helm",
"source": "WDMM",
"page": 251,
"rarity": "uncommon",
"reqAttune": "by a small humanoid",
"reqAttuneTags": [
{
"creatureType": "humanoid",
"size": "S"
}
],
"wondrous": true,
"entries": [
"While worn, the helm allows its wearer to use an action to cast the {@spell levitate} spell, requiring no components. The helm's propeller spins and whirs loudly until the spell ends. Each time the spell ends, there is a {@chance 50|50 percent} chance that the helm loses its magic and becomes nonmagical."
]
},
{
"name": "Prosthetic Limb",
"source": "TCE",
"page": 134,
"otherSources": [
{
"source": "ERLW",
"page": 278
},
{
"source": "EGW"
}
],
"rarity": "common",
"wondrous": true,
"entries": [
"This item replaces a lost limb-a hand, an arm, a foot, a leg, or a similar body part. While the prosthetic is attached, it functions identically to the part it replaces. You can detach or reattach it as an action, and it can't be removed against your will. It detaches if you die."
],
"hasFluffImages": true
},
{
"name": "Protective Verses",
"source": "TCE",
"page": 134,
"rarity": "rare",
"reqAttune": "by a wizard",
"reqAttuneTags": [
{
"class": "wizard"
}
],
"wondrous": true,
"weight": 3,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"focus": [
"Wizard"
],
"entries": [
"This leather-bound spellbook is reinforced with iron and silver fittings and an iron lock (DC 20 to open). As an action, you can touch the book's cover and cause it to lock as if you cast {@spell arcane lock} on it. When found, the book contains the following spells: {@spell arcane lock}, {@spell dispel magic}, {@spell globe of invulnerability}, {@spell glyph of warding}, {@spell Mordenkainen's private sanctum}, {@spell protection from evil and good||protection from evil}, and {@spell symbol}. It functions as a spellbook for you.",
"While you are holding the book, you can use it as a spellcasting focus for your wizard spells.",
"The book has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. You can use the charges in the following ways while holding it:",
{
"type": "list",
"items": [
"If you spend 1 minute studying the book, you can expend 1 charge to replace one of your prepared wizard spells with a different spell in the book. The new spell must be of the abjuration school.",
"When you cast an abjuration spell, you can expend 1 charge to grant a creature you can see within 30 feet of you {@dice 2d10} temporary hit points."
]
}
],
"attachedSpells": [
"arcane lock",
"dispel magic",
"globe of invulnerability",
"glyph of warding",
"mordenkainen's private sanctum",
"protection from evil and good",
"symbol"
]
},
{
"name": "Prying Blade",
"source": "XMtS",
"page": 19,
"rarity": "unknown (magic)",
"entries": [
"A hooked blade useful on board a ship or as a cutting tool in the wilderness, this {@item +1 shortsword} grants its wielder advantage on Strength ({@skill Athletics}) checks to climb or to escape while {@condition restrained}."
]
},
{
"name": "Psi Crystal",
"source": "IDRotF",
"page": 315,
"rarity": "uncommon",
"reqAttune": "by a creature with an intelligence score of 3 or higher",
"reqAttuneTags": [
{
"int": 3
}
],
"wondrous": true,
"entries": [
"This crystal grants you telepathy for as long as you remain attuned to it. See the {@book introduction of the Monster Manual|MM|0|Telepathy} for rules on how this telepathy works.",
"The crystal also glows with a purplish inner light while you are attuned to it.",
"The higher your intelligence, the greater the light's intensity and the greater the range of the telepathy (see the Psi Crystal Properties table).",
{
"type": "table",
"caption": "Psi Crystal Properties",
"colStyles": [
"col-2 text-center",
"col-2 text-center",
"col-8"
],
"colLabels": [
"Intelligence Score",
"Range of Telepathy",
"Light Intensity"
],
"rows": [
[
"3-7",
"15 feet",
"Dim light out to a range of 5 feet"
],
[
"8-11",
"30 feet",
"Bright light in a 5-foot radius and dim light for an additional 5 feet"
],
[
"12-15",
"60 feet",
"Bright light in a 10-foot radius and dim light for an additional 10 feet"
],
[
"16 or higher",
"120 feet",
"Bright light in a 15-foot radius and dim light for an additional 15 feet"
]
]
}
],
"hasFluffImages": true
},
{
"name": "Psychic Absorbing Tattoo",
"source": "TCE",
"page": 119,
"resist": [
"psychic"
],
"detail1": "silver",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"hasRefs": true,
"entries": [
"{#itemEntry Absorbing Tattoo|TCE}"
]
},
{
"name": "Purple Worm Poison",
"source": "DMG",
"page": 258,
"srd": true,
"type": "G",
"rarity": "none",
"value": 200000,
"poison": true,
"entries": [
"This poison must be harvested from a dead or {@condition incapacitated} {@creature purple worm}. A creature subjected to this poison must make a DC 19 Constitution saving throw, taking 42 ({@damage 12d6}) poison damage on a failed save, or half as much damage on a successful one."
],
"poisonTypes": [
"injury"
]
},
{
"name": "Pyroconverger",
"source": "GGR",
"page": 180,
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"entries": [
"A Pyroconverger is an Izzet-made flamethrower. It carries a risk of malfunction each time you use it.",
"As an action, you can cause the Pyroconverger to project fire in a 10-foot cone. Each creature in that area must make a DC 13 Dexterity saving throw, taking {@damage 4d6} fire damage on a failed save, or half as much damage on a successful one.",
"Each time you use the Pyroconverger, roll a {@dice d10} and add the number of times you have used it since your last long rest. If the total is 11 or higher, the Pyroconverger malfunctions: you take {@damage 4d6} fire damage, and you can't use the Pyroconverger again until you finish a long rest."
],
"hasFluffImages": true
},
{
"name": "Pyxis of Pandemonium",
"source": "MOT",
"page": 197,
"rarity": "legendary",
"wondrous": true,
"curse": true,
"entries": [
"A creature that touches this ornate wooden vessel for 1 minute gains the benefits of a short rest. That creature also gains the effects of the {@spell bless} spell until the creature finishes a short or long rest. The creature can't gain these benefits again until it finishes a long rest.",
"If the vessel is opened, roll on the Pyxis of Pandemonium table to determine what happens. Any spells cast by the vessel have a spell save DC of 17. One minute after the vessel is opened, it disappears. It reappears, sealed, in a random location on the same plane of existence 24 hours later.",
{
"type": "entries",
"name": "Curse",
"entries": [
"Any creature that gains the benefit of a short rest from the vessel hears cloying telepathic whispers emanating from it. That creature must make a DC 17 Wisdom saving throw. On a failed save, the creature is {@condition charmed} by the vessel for 1 hour. The {@condition charmed} creature does everything it can to open the vessel as soon as possible. On a successful save, the creature is immune to the vessel's whispers for 24 hours."
]
},
{
"type": "table",
"colStyles": [
"col-2 text-center",
"col-10"
],
"colLabels": [
"d8",
"Calamity"
],
"rows": [
[
"1",
"{@b Androphagia.} Each creature within 60 feet of the vessel must succeed on a DC 17 Wisdom saving throw or go berserk for 1 minute. The berserk creature must begin its turn using the {@action Attack} action to make one melee or ranged attack (its choice) against the creature nearest to it. The berserk creature can repeat the save at the end of its turn, ending the effect on itself on a success."
],
[
"2",
"{@b Bile Blight.} The vessel casts the {@spell harm} spell on each creature within 30 feet of it."
],
[
"3",
"{@b Flood.} The vessel casts the {@spell tsunami} spell at a point of the DM's choice within 120 feet of it."
],
[
"4",
"{@b Medusa's Gaze.} The vessel casts the {@spell flesh to stone} spell on each creature within 30 feet of it."
],
[
"5",
"{@b Labyrinth.} The vessel casts the {@spell maze} spell on each creature within 30 feet of it."
],
[
"6",
"{@b Nightmare.} Tendrils of shadow seep from the vessel and form into {@dice 1d4} {@creature shadow demon||shadow demons} (see the Monster Manual for their stat block), which appear in unoccupied spaces within 30 feet of it and are hostile."
],
[
"7",
"{@b Swarming Insects.} The vessel casts the {@spell insect plague} spell, centered on itself and with a radius of 30 feet."
],
[
"8",
"{@b Unbridled Revel.} The vessel casts the {@spell Otto's irresistible dance} spell on each creature within 30 feet of it."
]
]
}
],
"hasFluffImages": true
},
{
"name": "Quaal's Feather Token, Anchor",
"source": "DMG",
"page": 188,
"srd": "Feather Token, Anchor",
"tier": "minor",
"rarity": "rare",
"wondrous": true,
"entries": [
"This tiny object looks like a feather. Different types of feather tokens exist, each with a different single-use effect.",
{
"name": "Anchor",
"type": "entries",
"entries": [
"You can use an action to touch the token to a boat or ship. For the next 24 hours, the vessel can't be moved by any means. Touching the token to the vessel again ends the effect. When the effect ends, the token disappears."
]
}
]
},
{
"name": "Quaal's Feather Token, Bird",
"source": "DMG",
"page": 188,
"srd": "Feather Token, Bird",
"tier": "minor",
"rarity": "rare",
"wondrous": true,
"entries": [
"This tiny object looks like a feather. Different types of feather tokens exist, each with a different single-use effect.",
{
"name": "Bird",
"type": "entries",
"entries": [
"You can use an action to toss the token 5 feet into the air. The token disappears and an enormous, multicolored bird takes its place. The bird has the statistics of a {@creature roc}, but it obeys your simple commands and can't attack. It can carry up to 500 pounds while flying at its maximum speed (16 miles an hour for a maximum of 144 miles per day. with a one-hour rest for every 3 hours of flying), or 1,000 pounds at half that speed. The bird disappears after flying its maximum distance for a day or if it drops to 0 hit points. You can dismiss the bird as an action."
]
}
]
},
{
"name": "Quaal's Feather Token, Fan",
"source": "DMG",
"page": 188,
"srd": "Feather Token, Fan",
"tier": "minor",
"rarity": "rare",
"wondrous": true,
"entries": [
"This tiny object looks like a feather. Different types of feather tokens exist, each with a different single-use effect.",
{
"name": "Fan",
"type": "entries",
"entries": [
"If you are on a boat or ship, you can use an action to toss the token up to 10 feet in the air. The token disappears, and a giant flapping fan takes its place. The fan floats and creates a wind strong enough to fill the sails of one ship, increasing its speed by 5 miles per hour for 8 hours. You can dismiss the fan as an action."
]
}
]
},
{
"name": "Quaal's Feather Token, Swan Boat",
"source": "DMG",
"page": 188,
"srd": "Feather Token, Swan Boat",
"tier": "minor",
"rarity": "rare",
"wondrous": true,
"entries": [
"This tiny object looks like a feather. Different types of feather tokens exist, each with a different single-use effect.",
{
"name": "Swan Boat",
"type": "entries",
"entries": [
"You can use an action to touch the token to a body of water at least 60 feet in diameter. The token disappears, and a 50-foot-long, 20-foot-wide boat shaped like a swan takes its place. The boat is self-propelled and moves across water at a speed of 6 miles per hour. You can use an action while on the boat to command it to move or to turn up to 90 degrees. The boat can carry up to thirty-two Medium or smaller creatures. A Large creature counts as four Medium creatures, while a Huge creature counts as nine. The boat remains for 24 hours and then disappears. You can dismiss the boat as an action."
]
}
]
},
{
"name": "Quaal's Feather Token, Tree",
"source": "DMG",
"page": 188,
"srd": "Feather Token, Tree",
"tier": "minor",
"rarity": "rare",
"wondrous": true,
"entries": [
"This tiny object looks like a feather. Different types of feather tokens exist, each with a different single-use effect.",
{
"name": "Tree",
"type": "entries",
"entries": [
"You must be outdoors to use this token. You can use an action to touch it to an unoccupied space on the ground. The token disappears, and in its place a nonmagical oak tree springs into existence. The tree is 60 feet tall and has a 5-foot-diameter trunk, and its branches at the top spread out in a 20-foot radius."
]
}
]
},
{
"name": "Quaal's Feather Token, Whip",
"source": "DMG",
"page": 188,
"srd": "Feather Token, Whip",
"tier": "minor",
"rarity": "rare",
"wondrous": true,
"entries": [
"This tiny object looks like a feather. Different types of feather tokens exist, each with a different single-use effect.",
{
"name": "Whip",
"type": "entries",
"entries": [
"You can use an action to throw the token to a point within 10 feet of you. The token disappears, and a floating whip takes its place. You can then use a bonus action to make a melee spell attack against a creature within 10 feet of the whip, with an attack bonus of {@hit 9}. On a hit, the target takes {@damage 1d6 + 5} force damage.",
"As a bonus action on your turn, you can direct the whip to fly up to 20 feet and repeat the attack against a creature within 10 feet of it. The whip disappears after 1 hour, when you use an action to dismiss it, or when you are {@condition incapacitated} or die."
]
}
]
},
{
"name": "Quandrix Primer",
"source": "SCC",
"page": 39,
"rarity": "uncommon",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"The Quandrix Primer is a magic textbook created at Strixhaven's Quandrix College. The primer has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. If you make an Intelligence ({@skill Arcana}) or an Intelligence ({@skill Nature}) check while holding the primer, you can expend 1 charge to give yourself a {@dice 1d4} bonus to the check, immediately after you roll the {@dice d20}.",
"In addition, if you study the primer at the end of a long rest, you can choose one 1st-level spell from the druid or wizard spell list. Before you finish your next long rest, you can cast the chosen spell once without a spell slot if you are holding the primer. Your spellcasting ability for this spell is your choice of Intelligence, Wisdom, or Charisma."
]
},
{
"name": "Quartz",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 5000,
"entries": [
"A transparent white, smoky gray, or yellow gemstone."
]
},
{
"name": "Quiver",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 1,
"value": 100,
"entries": [
"A quiver can hold up to 20 {@item arrow|phb|arrows}."
],
"containerCapacity": {
"item": [
{
"arrow|phb": 20
}
]
}
},
{
"name": "Quiver of Ehlonna",
"source": "DMG",
"page": 189,
"srd": "Efficient Quiver",
"tier": "major",
"rarity": "uncommon",
"wondrous": true,
"weight": 2,
"entries": [
"Each of the quiver's three compartments connects to an extradimensional space that allows the quiver to hold numerous items while never weighing more than 2 pounds. The shortest compartment can hold up to sixty {@item arrow|phb|arrows}, {@item crossbow bolt|phb|bolts}, or similar objects. The midsize compartment holds up to eighteen {@item javelin|phb|javelins} or similar objects. The longest compartment holds up to six long objects, such as bows, {@item quarterstaff|phb|quarterstaffs}, or {@item spear|phb|spears}.",
"You can draw any item the quiver contains as if doing so from a regular quiver or scabbard."
],
"containerCapacity": {
"item": [
{
"crossbow bolt|phb": 60,
"arrow|phb": 60
},
{
"javelin|phb": 18
},
{
"quarterstaff|phb": 6,
"spear|phb": 6
}
],
"weightless": true
},
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Radiance",
"source": "CM",
"page": 87,
"type": "WD",
"rarity": "unknown (magic)",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"bonusSpellAttack": "+1",
"entries": [
"While holding this wand, you gain a +1 bonus to spell attack rolls. In addition, you ignore half cover when making a spell attack. Radiance is in the form of an exquisite golden hand mirror.",
"While surrounded by darkness, it sheds dim light in a 5-foot radius. A creature that is attuned to Radiance can use a bonus action while holding the mirror to cast the {@spell enhance ability} spell, choosing itself and no other creature as the spell's target. Once this property of the wand is used, it can't be used again until the next dawn."
],
"attachedSpells": [
"enhance ability"
]
},
{
"name": "Radiant Absorbing Tattoo",
"source": "TCE",
"page": 119,
"resist": [
"radiant"
],
"detail1": "gold",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"hasRefs": true,
"entries": [
"{#itemEntry Absorbing Tattoo|TCE}"
]
},
{
"name": "Rain Catcher",
"source": "ToA",
"page": 32,
"type": "G",
"rarity": "none",
"weight": 5,
"value": 100,
"entries": [
"A rain catcher is a simple contraption consisting of a 5-foot-square leather tarp and a wooden frame with legs. When the tarp is stretched across the wooden frame, it forms a basin that can catch 2 gallons of drinking water per inch of rainfall and hold up to 8 gallons. The tarp and wooden frame fold up for easy transport."
]
},
{
"name": "Rakdos Guild Signet",
"source": "GGR",
"page": 178,
"type": "RG",
"rarity": "uncommon",
"reqAttune": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"This ring, adorned with the symbol of Rakdos, allows you to cast {@spell hellish rebuke}. A guild signet is sometimes awarded to a guild member whose renown score in that guild is 5 or higher, as a reward for performing special services for the guild. Aside from its magical properties, the ring is also an indicator of Rakdos' recognition and favor.",
"A signet has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. While wearing it, you can expend 1 charge to cast the associated spell (save DC 13)."
],
"attachedSpells": [
"hellish rebuke"
],
"hasFluffImages": true
},
{
"name": "Rakdos Keyrune",
"source": "GGR",
"page": 178,
"rarity": "uncommon",
"reqAttune": "by a member of the Rakdos guild",
"reqAttuneTags": [
{
"background": "rakdos cultist|ggr"
}
],
"wondrous": true,
"entries": [
"This dark granite keyrune is marbled with scarlet veins and carved with the leering visage of a mischievous demon. When activated, it transforms into a {@creature cackler|GGR} for up to 1 hour.",
"When you use an action to speak the item's command word and place the keyrune on the ground in an unoccupied space within 5 feet of you, the keyrune transforms into a {@creature cackler|GGR}. If there isn't enough space for the creature, the keyrune doesn't transform.",
"The creature is friendly to you, your companions, and other members of your guild (unless those guild members are hostile to you). It understands your languages and obeys your spoken commands. If you issue no commands, the creature takes the {@action Dodge} action and moves to avoid danger.",
"At the end of the duration, the creature reverts to its keyrune form. It reverts early if it drops to 0 hit points or if you use an action to speak the command word again while touching it. When the creature reverts to its keyrune form, it can't transform again until 36 hours have passed."
],
"hasFluffImages": true
},
{
"name": "Rakdos Riteknife",
"source": "GGR",
"page": 180,
"baseItem": "dagger|phb",
"type": "M",
"rarity": "legendary",
"reqAttune": true,
"weight": 1,
"weaponCategory": "simple",
"property": [
"F",
"L",
"T"
],
"range": "20/60",
"dmg1": "1d4",
"dmgType": "P",
"bonusWeapon": "+1",
"entries": [
"You gain a +1 bonus to attack and damage rolls made with this magic weapon. Its blade is cruelly serrated, and its hilt resembles a demonic head and wings. Whenever you slay a creature with an attack using the dagger, the creature's soul is imprisoned inside the dagger, and that creature can be restored to life only by a {@spell wish} spell. The dagger can hold a maximum of five souls.",
"For each soul imprisoned in the dagger, your attacks with it deal an extra {@damage 1d4} necrotic damage on a hit. While the dagger is within 5 feet of you, your dreams are haunted by whispers from the trapped souls.",
"The dagger has the following additional properties.",
{
"type": "entries",
"name": "Siphon Vitality",
"entries": [
"As a bonus action, you can release any number of stored souls from the dagger to regain {@dice 1d10} hit points per soul released."
]
},
{
"type": "entries",
"name": "Annihilation",
"entries": [
"If the dagger holds five souls, you can use this property: As a reaction immediately after you hit a creature with the dagger and deal damage to that target, you can release all five souls. If the target now has fewer than 75 hit points, it must succeed on a DC 15 Constitution saving throw or die. If the target dies, you can't use this property again until you finish a long rest."
]
}
],
"hasFluffImages": true
},
{
"name": "Rations (1 day)",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 2,
"value": 50,
"entries": [
"Rations consist of dry foods suitable for extended travel, including jerky, dried fruit, hardtack, and nuts."
]
},
{
"name": "Red Chromatic Rose",
"source": "WBtW",
"page": 208,
"resist": [
"fire"
],
"detail1": "wreathed in fire",
"rarity": "rare",
"wondrous": true,
"hasRefs": true,
"entries": [
"{#itemEntry Chromatic Rose|WBtW}"
]
},
{
"name": "Red Dragon Mask",
"source": "RoTOS",
"page": 4,
"resist": [
"fire"
],
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"grantsProficiency": true,
"entries": [
"This mask of glossy crimson has swept-back horns and spiked cheek ridges. The mask reshapes to fit a wearer attuned to it. While you are wearing the mask and attuned to it, you can access the following properties.",
{
"type": "entries",
"name": "Damage Absorption",
"entries": [
"You have resistance against fire damage. If you already have resistance to fire damage from another source, you instead have immunity to fire damage. If you already have immunity to fire damage from another source, whenever you are subjected to fire damage, you take none of that damage and regain a number of hit points equal to half the damage dealt of that type."
]
},
{
"type": "entries",
"name": "Draconic Majesty",
"entries": [
"While you are wearing no armor, you can add your Charisma bonus to your Armor Class."
]
},
{
"type": "entries",
"name": "Dragon Breath",
"entries": [
"If you have a breath weapon that requires rest to recharge, it gains a recharge of 6."
]
},
{
"type": "entries",
"name": "Dragon Sight",
"entries": [
"You gain {@sense darkvision} out to 60 feet, or to an additional 60 feet if you already have that sense. Once per day, you can gain {@sense blindsight} out to 30 feet for 5 minutes."
]
},
{
"type": "entries",
"name": "Dragon Tongue",
"entries": [
"You can speak and understand Draconic. You also have advantage on any Charisma check you make against Red Dragons."
]
},
{
"type": "entries",
"name": "Legendary Resistance",
"entries": [
"(1/Day) If you fail a saving throw, you can choose to succeed instead."
]
},
{
"type": "entries",
"name": "Dragon Fire",
"entries": [
"If you deal fire damage to a creature or flammable object, it starts burning. At the start of each of its turns, a creature burning in this way takes {@damage 1d6} fire damage. A creature that can reach the burning target can use an action to extinguish the fire."
]
}
]
},
{
"name": "Red Dragon Scale Mail",
"source": "DMG",
"page": 165,
"srd": true,
"type": "MA",
"resist": [
"fire"
],
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"weight": 45,
"ac": 14,
"bonusAc": "+1",
"bonusSavingThrow": "+1",
"stealth": true,
"entries": [
"Dragon scale mail is made of the scales of one kind of dragon. Sometimes dragons collect their cast-off scales and gift them to humanoids. Other times, hunters carefully skin and preserve the hide of a dead dragon. In either case, dragon scale mail is highly valued. While wearing this armor, you gain a +1 bonus to AC, you have advantage on saving throws against the Frightful Presence and breath weapons of dragons, and you have resistance to fire damage.",
"Additionally, you can focus your senses as an action to magically discern the distance and direction to the closest red dragon within 30 miles of you. This special action can't be used again until the next dawn."
]
},
{
"name": "Reincarnation Dust",
"source": "EGW",
"page": 268,
"rarity": "very rare",
"wondrous": true,
"entries": [
"When this small pouch of purple dust is sprinkled on a dead humanoid or a piece of a dead humanoid, the dust is absorbed by the remains. If willing, the dead creature returns to life with a new body as if the {@spell reincarnate} spell had been cast on the remains."
],
"attachedSpells": [
"reincarnate"
]
},
{
"name": "Reliquary",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "SCF",
"scfType": "holy",
"rarity": "none",
"weight": 2,
"value": 500
},
{
"name": "Reszur",
"source": "PotA",
"page": 157,
"baseItem": "dagger|phb",
"type": "M",
"rarity": "uncommon",
"weight": 1,
"weaponCategory": "simple",
"property": [
"F",
"L",
"T"
],
"range": "20/60",
"dmg1": "1d4",
"dmgType": "P",
"bonusWeapon": "+1",
"entries": [
"You have a +1 bonus to attack and damage rolls made with this weapon, which doesn't make noise when it hits or cuts something.",
"The name \"Reszur\" is graven on the dagger's pommel. If the wielder speaks the name, the blade gives off a faint, cold glow, shedding dim light in a 10-foot radius until the wielder speaks the name again."
]
},
{
"name": "Reveler's Concertina",
"source": "TCE",
"page": 134,
"type": "INS",
"rarity": "rare",
"reqAttune": "by a bard",
"reqAttuneTags": [
{
"class": "bard"
}
],
"wondrous": true,
"entries": [
"While holding this concertina, you gain a +2 bonus to the saving throw DC of your bard spells.",
"As an action, you can use the concertina to cast {@spell Otto's irresistible dance} from the item. This property of the concertina can't be used again until the next dawn."
],
"attachedSpells": [
"otto's irresistible dance"
]
},
{
"name": "Rhodochrosite",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 1000,
"entries": [
"An opaque light pink gemstone."
]
},
{
"name": "Riding Horse",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "MNT",
"rarity": "none",
"value": 7500,
"carryingCapacity": 480,
"speed": 60
},
{
"name": "Riding Saddle",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TAH",
"rarity": "none",
"weight": 25,
"value": 1000
},
{
"name": "Ring of Acid Resistance",
"source": "DMG",
"page": 192,
"srd": true,
"basicRules": true,
"type": "RG",
"resist": [
"acid"
],
"detail1": "pearl",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"hasRefs": true,
"entries": [
"{#itemEntry Ring of Resistance}"
]
},
{
"name": "Ring of Air Elemental Command",
"source": "DMG",
"page": 190,
"srd": true,
"type": "RG",
"resist": [
"lightning"
],
"tier": "major",
"rarity": "legendary",
"reqAttune": true,
"modifySpeed": {
"equal": {
"fly": "walk"
}
},
"recharge": "dawn",
"rechargeAmount": "{@dice 1d4 + 1}",
"charges": 5,
"entries": [
"While wearing this ring, you have advantage on attack rolls against elementals from the Elemental Plane of Air, and they have disadvantage on attack rolls against you. In addition, you have access to properties based on the Elemental Plane of Air.",
"The ring has 5 charges. It regains {@dice 1d4 + 1} expended charges daily at dawn. Spells cast from the ring have a save DC of 17.",
"You can expend 2 of the ring's charges to cast {@spell dominate monster} on an {@creature air elemental}. In addition, when you fall, you descend 60 feet per round and take no damage from falling. You can also speak and understand Auran.",
"If you help slay an {@creature air elemental} while attuned to the ring, you gain access to the following additional properties:",
{
"type": "list",
"items": [
"You have resistance to lightning damage.",
"You have a flying speed equal to your walking speed and can hover.",
"You can cast the following spells from the ring, expending the necessary number of charges: {@spell chain lightning} (3 charges), {@spell gust of wind} (2 charges), or {@spell wind wall} (1 charge)."
]
}
],
"attachedSpells": [
"dominate monster",
"chain lightning",
"gust of wind",
"wind wall"
],
"lootTables": [
"Magic Item Table I"
],
"hasFluffImages": true
},
{
"name": "Ring of Animal Influence",
"source": "DMG",
"page": 189,
"srd": true,
"type": "RG",
"tier": "major",
"rarity": "rare",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"This ring has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. While wearing the ring, you can use an action to expend 1 of its charges to cast one of the following spells:",
{
"type": "list",
"items": [
"{@spell Animal friendship} (save DC 13)",
"{@spell Fear} (save DC 13), targeting only beasts that have an Intelligence of 3 or lower",
"{@spell Speak with animals}"
]
}
],
"attachedSpells": [
"animal friendship",
"fear",
"speak with animals"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Ring of Cold Resistance",
"source": "DMG",
"page": 192,
"srd": true,
"basicRules": true,
"type": "RG",
"resist": [
"cold"
],
"detail1": "tourmaline",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"hasRefs": true,
"entries": [
"{#itemEntry Ring of Resistance}"
]
},
{
"name": "Ring of Djinni Summoning",
"source": "DMG",
"page": 190,
"srd": true,
"type": "RG",
"tier": "major",
"rarity": "legendary",
"reqAttune": true,
"entries": [
"While wearing this ring, you can speak its command word as an action to summon a particular {@creature djinni} from the Elemental Plane of Air. The {@creature djinni} appears in an unoccupied space you choose within 120 feet of you. It remains as long as you concentrate (as if concentrating on a spell), to a maximum of 1 hour, or until it drops to 0 hit points. It then returns to its home plane.",
"While summoned, the {@creature djinni} is friendly to you and your companions. It obeys any commands you give it, no matter what language you use. If you fail to command it, the {@creature djinni} defends itself against attackers but takes no other actions.",
"After the {@creature djinni} departs, it can't be summoned again for 24 hours, and the ring becomes nonmagical if the {@creature djinni} dies."
],
"lootTables": [
"Magic Item Table I"
],
"hasFluffImages": true
},
{
"name": "Ring of Earth Elemental Command",
"source": "DMG",
"page": 190,
"srd": true,
"type": "RG",
"resist": [
"acid"
],
"tier": "major",
"rarity": "legendary",
"reqAttune": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d4 + 1}",
"charges": 5,
"entries": [
"While wearing this ring, you have advantage on attack rolls against elementals from the Elemental Plane of Earth and they have disadvantage on attack rolls against you. In addition, you have access to properties based on the Elemental Plane of Earth.",
"The ring has 5 charges. It regains {@dice 1d4 + 1} expended charges daily at dawn. Spells cast from the ring have a save DC of 17.",
"You can expend 2 of the ring's charges to cast {@spell dominate monster} on an {@creature earth elemental}. In addition, you can move in {@quickref difficult terrain||3} that is composed of rubble, rocks, or dirt as if it were normal terrain. You can also speak and understand Terran.",
"If you help slay an {@creature earth elemental} while attuned to the ring, you gain access to the following additional properties:",
{
"type": "list",
"items": [
"You have resistance to acid damage.",
"You can move through solid earth or rock as if those areas were {@quickref difficult terrain||3}. If you end your turn there, you are shunted out to the nearest unoccupied space you last occupied.",
"You can cast the following spells from the ring, expending the necessary number of charges: {@spell stone shape} (2 charges), {@spell stoneskin} (3 charges), or {@spell wall of stone} (3 charges)."
]
}
],
"attachedSpells": [
"dominate monster",
"stone shape",
"stoneskin",
"wall of stone"
],
"lootTables": [
"Magic Item Table I"
]
},
{
"name": "Ring of Evasion",
"source": "DMG",
"page": 191,
"srd": true,
"basicRules": true,
"type": "RG",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"This ring has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. When you fail a Dexterity saving throw while wearing it, you can use your reaction to expend 1 of its charges to succeed on that saving throw instead."
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Ring of Feather Falling",
"source": "DMG",
"page": 191,
"srd": true,
"type": "RG",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"entries": [
"When you fall while wearing this ring, you descend 60 feet per round and take no damage from falling."
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Ring of Fire Elemental Command",
"source": "DMG",
"page": 190,
"srd": true,
"type": "RG",
"immune": [
"fire"
],
"resist": [
"fire"
],
"tier": "major",
"rarity": "legendary",
"reqAttune": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d4 + 1}",
"charges": 5,
"entries": [
"While wearing this ring, you have advantage on attack rolls against elementals from the Elemental Plane of Fire and they have disadvantage on attack rolls against you. In addition, you have access to properties based on the Elemental Plane of Fire.",
"The ring has 5 charges. It regains {@dice 1d4 + 1} expended charges daily at dawn. Spells cast from the ring have a save DC of 17.",
"You can expend 2 of the ring's charges to cast {@spell dominate monster} on a {@creature fire elemental}. In addition, you have resistance to fire damage. You can also speak and understand Ignan.",
"If you help slay a {@creature fire elemental} while attuned to the ring, you gain access to the following additional properties:",
{
"type": "list",
"items": [
"You are immune to fire damage.",
"You can cast the following spells from the ring, expending the necessary number of charges: {@spell burning hands} (1 charge), {@spell fireball} (2 charges), and {@spell wall of fire} (3 charges)."
]
}
],
"attachedSpells": [
"dominate monster",
"burning hands",
"fireball",
"wall of fire"
],
"lootTables": [
"Magic Item Table I"
]
},
{
"name": "Ring of Fire Resistance",
"source": "DMG",
"page": 192,
"srd": true,
"basicRules": true,
"type": "RG",
"resist": [
"fire"
],
"detail1": "garnet",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"hasRefs": true,
"entries": [
"{#itemEntry Ring of Resistance}"
]
},
{
"name": "Ring of Force Resistance",
"source": "DMG",
"page": 192,
"srd": true,
"basicRules": true,
"type": "RG",
"resist": [
"force"
],
"detail1": "sapphire",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"hasRefs": true,
"entries": [
"{#itemEntry Ring of Resistance}"
]
},
{
"name": "Ring of Free Action",
"source": "DMG",
"page": 191,
"srd": true,
"type": "RG",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"entries": [
"While you wear this ring, {@quickref difficult terrain||3} doesn't cost you extra movement. In addition, magic can neither reduce your speed nor cause you to be {@condition paralyzed} or {@condition restrained}."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Ring of Invisibility",
"source": "DMG",
"page": 191,
"srd": true,
"type": "RG",
"tier": "major",
"rarity": "legendary",
"reqAttune": true,
"entries": [
"While wearing this ring, you can turn {@condition invisible} as an action. Anything you are wearing or carrying is {@condition invisible} with you. You remain {@condition invisible} until the ring is removed, until you attack or cast a spell, or until you use a bonus action to become visible again."
],
"lootTables": [
"Magic Item Table I"
],
"hasFluffImages": true
},
{
"name": "Ring of Jumping",
"source": "DMG",
"page": 191,
"srd": true,
"type": "RG",
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"entries": [
"While wearing this ring, you can cast the {@spell jump} spell from it as a bonus action at will, but can target only yourself when you do so."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"attachedSpells": [
"jump"
],
"lootTables": [
"Magic Item Table F"
]
},
{
"name": "Ring of Lightning Resistance",
"source": "DMG",
"page": 192,
"srd": true,
"basicRules": true,
"type": "RG",
"resist": [
"lightning"
],
"detail1": "citrine",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"hasRefs": true,
"entries": [
"{#itemEntry Ring of Resistance}"
]
},
{
"name": "Ring of Mind Shielding",
"source": "DMG",
"page": 191,
"srd": true,
"type": "RG",
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"entries": [
"While wearing this ring, you are immune to magic that allows other creatures to read your thoughts, determine whether you are lying, know your alignment, or know your creature type. Creatures can telepathically communicate with you only if you allow it.",
"You can use an action to cause the ring to become {@condition invisible} until you use another action to make it visible, until you remove the ring, or until you die.",
"If you die while wearing the ring, your soul enters it, unless it already houses a soul. You can remain in the ring or depart for the afterlife. As long as your soul is in the ring, you can telepathically communicate with any creature wearing it. A wearer can't prevent this telepathic communication."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Ring of Necrotic Resistance",
"source": "DMG",
"page": 192,
"srd": true,
"basicRules": true,
"type": "RG",
"resist": [
"necrotic"
],
"detail1": "jet",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"hasRefs": true,
"entries": [
"{#itemEntry Ring of Resistance}"
]
},
{
"name": "Ring of Obscuring",
"source": "EGW",
"page": 269,
"type": "RG",
"rarity": "uncommon",
"reqAttune": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"This band of iron resembles a skull and is cold to the touch. It has 3 charges and regains {@dice 1d3} expended charges daily at dawn. As an action while wearing the ring, you can expend 1 of its charges to cast the {@spell fog cloud} spell from it, with the following changes: the cloud is centered on you when it first appears, and the spell lasts for 1 minute (no concentration required)."
],
"attachedSpells": [
"fog cloud"
],
"hasFluffImages": true
},
{
"name": "Ring of Poison Resistance",
"source": "DMG",
"page": 192,
"srd": true,
"basicRules": true,
"type": "RG",
"resist": [
"poison"
],
"detail1": "amethyst",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"hasRefs": true,
"entries": [
"{#itemEntry Ring of Resistance}"
]
},
{
"name": "Ring of Protection",
"source": "DMG",
"page": 191,
"srd": true,
"basicRules": true,
"type": "RG",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"bonusAc": "+1",
"bonusSavingThrow": "+1",
"entries": [
"You gain a +1 bonus to AC and saving throws while wearing this ring."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Ring of Psychic Resistance",
"source": "DMG",
"page": 192,
"srd": true,
"basicRules": true,
"type": "RG",
"resist": [
"psychic"
],
"detail1": "jade",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"hasRefs": true,
"entries": [
"{#itemEntry Ring of Resistance}"
]
},
{
"name": "Ring of Radiant Resistance",
"source": "DMG",
"page": 192,
"srd": true,
"basicRules": true,
"type": "RG",
"resist": [
"radiant"
],
"detail1": "topaz",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"hasRefs": true,
"entries": [
"{#itemEntry Ring of Resistance}"
]
},
{
"name": "Ring of Red Fury",
"source": "CRCotN",
"page": 214,
"type": "RG",
"rarity": "very rare",
"reqAttune": true,
"modifySpeed": {
"equal": {
"swim": "walk"
}
},
"entries": [
"This ring has a stripe of ruidium running through it. While wearing the ring, you gain the following benefits:",
{
"type": "list",
"items": [
"You can breathe water.",
"You gain a swimming speed equal to your walking speed."
]
},
{
"type": "entries",
"name": "Ruidium Rage",
"entries": [
"As a bonus action, you can use the ring to gain the following benefits, which last for 1 minute or until you are {@condition incapacitated}:",
{
"type": "list",
"items": [
"You have advantage on Strength checks and Strength saving throws.",
"When you hit with an attack, you can add your proficiency bonus to the damage roll.",
"Difficult terrain doesn't cost you extra movement, and you are immune to the {@condition paralyzed} and {@condition restrained} conditions."
]
},
"You can't use this property of the ring again until you finish a long rest."
]
},
{
"type": "entries",
"name": "Ruidium Corruption",
"entries": [
"When you use the Ruidium Rage property of the ring, you must make a DC 20 Charisma saving throw. On a failed save, you gain 1 level of {@condition exhaustion}. If you are not already suffering from ruidium corruption, you become corrupted when you fail this save."
]
},
{
"type": "entries",
"name": "If Ruidium Is Destroyed",
"entries": [
"If the Apotheon is killed or redeemed, all the ruidium in Exandria is destroyed instantly, and the ring of red fury becomes a {@item ring of free action}."
]
}
],
"hasFluffImages": true
},
{
"name": "Ring of Regeneration",
"source": "DMG",
"page": 191,
"srd": true,
"type": "RG",
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"entries": [
"While wearing this ring, you regain {@dice 1d6} hit points every 10 minutes, provided that you have at least 1 hit point. If you lose a body part, the ring causes the missing part to regrow and return to full functionality after {@dice 1d6 + 1} days if you have at least 1 hit point the whole time."
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Ring of Shooting Stars",
"source": "DMG",
"page": 192,
"srd": true,
"type": "RG",
"tier": "major",
"rarity": "very rare",
"reqAttune": "outdoors at night",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6}",
"charges": 6,
"entries": [
"While wearing this ring in dim light or darkness, you can cast {@spell dancing lights} and {@spell light} from the ring at will. Casting either spell from the ring requires an action.",
"The ring has 6 charges for the following other properties. The ring regains {@dice 1d6} expended charges daily at dawn.",
{
"name": "Faerie Fire",
"type": "entries",
"entries": [
"You can expend 1 charge as an action to cast {@spell faerie fire} from the ring."
]
},
{
"name": "Ball Lightning",
"type": "entries",
"entries": [
"You can expend 2 charges as an action to create one to four 3-foot-diameter spheres of lightning. The more spheres you create, the less powerful each sphere is individually.",
"Each sphere appears in an unoccupied space you can see within 120 feet of you. The spheres last as long as you concentrate (as if concentrating on a spell), up to 1 minute. Each sphere sheds dim light in a 30-foot radius.",
"As a bonus action, you can move each sphere up to 30 feet, but no farther than 120 feet away from you. When a creature other than you comes within 5 feet of a sphere, the sphere discharges lightning at that creature and disappears. That creature must make a DC 15 Dexterity saving throw. On a failed save, the creature takes lightning damage based on the number of spheres you created. (4 spheres = {@dice 2d4}, 3 spheres = {@dice 2d6}, 2 spheres = {@dice 5d4}, 1 sphere = {@dice 4d12})"
]
},
{
"name": "Shooting Stars",
"type": "entries",
"entries": [
"You can expend 1 to 3 charges as an action. For every charge you expend, you launch a glowing mote of light from the ring at a point you can see within 60 feet of you. Each creature within a 15-foot cube originating from that point is showered in sparks and must make a DC 15 Dexterity saving throw, taking {@damage 5d4} fire damage on a failed save, or half as much damage on a successful one."
]
}
],
"attachedSpells": [
"dancing lights",
"faerie fire",
"light"
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Ring of Spell Storing",
"source": "DMG",
"page": 192,
"srd": true,
"type": "RG",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"entries": [
"This ring stores spells cast into it, holding them until the attuned wearer uses them. The ring can store up to 5 levels worth of spells at a time. When found, it contains {@dice 1d6 - 1} levels of stored spells chosen by the DM.",
"Any creature can cast a spell of 1st through 5th level into the ring by touching the ring as the spell is cast. The spell has no effect, other than to be stored in the ring. If the ring can't hold the spell, the spell is expended without effect. The level of the slot used to cast the spell determines how much space it uses.",
"While wearing this ring, you can cast any spell stored in it. The spell uses the slot level, spell save DC, spell attack bonus, and spellcasting ability of the original caster, but is otherwise treated as if you cast the spell. The spell cast from the ring is no longer stored in it, freeing up space."
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Ring of Spell Turning",
"source": "DMG",
"page": 193,
"srd": true,
"type": "RG",
"tier": "major",
"rarity": "legendary",
"reqAttune": true,
"entries": [
"While wearing this ring, you have advantage on saving throws against any spell that targets only you (not in an area of effect). In addition, if you roll a 20 for the save and the spell is 7th level or lower, the spell has no effect on you and instead targets the caster, using the slot level, spell save DC, attack bonus, and spellcasting ability of the caster."
],
"lootTables": [
"Magic Item Table I"
],
"hasFluffImages": true
},
{
"name": "Ring of Swimming",
"source": "DMG",
"page": 193,
"srd": true,
"type": "RG",
"tier": "minor",
"rarity": "uncommon",
"modifySpeed": {
"static": {
"swim": 40
}
},
"entries": [
"You have a swimming speed of 40 feet while wearing this ring."
],
"lootTables": [
"Magic Item Table B"
]
},
{
"name": "Ring of Telekinesis",
"source": "DMG",
"page": 193,
"srd": true,
"type": "RG",
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"entries": [
"While wearing this ring, you can cast the {@spell telekinesis} spell at will, but you can target only objects that aren't being worn or carried."
],
"attachedSpells": [
"telekinesis"
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Ring of Temporal Salvation",
"source": "EGW",
"page": 269,
"type": "RG",
"rarity": "rare",
"reqAttune": true,
"entries": [
"If you die while wearing this gray crystal ring, you vanish and reappear in an unoccupied space within 5 feet of the space you left (or the nearest unoccupied space). You have a number of hit points equal to {@dice 3d6} + your Constitution modifier. If your hit point maximum is lower than the number of hit points you regain, your hit point maximum rises to a similar amount. If you have any levels of {@condition exhaustion}, reduce your level of {@condition exhaustion} by 1. Once the ring is used, it turns to dust and is destroyed."
]
},
{
"name": "Ring of the Ram",
"source": "DMG",
"page": 193,
"srd": true,
"type": "RG",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"This ring has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. While wearing the ring, you can use an action to expend 1 to 3 of its charges to make a ranged spell attack against one creature you can see within 60 feet of you. The ring produces a spectral ram's head and makes its attack roll with a +7 bonus. On a hit, for each charge you spend, the target takes {@damage 2d10} force damage and is pushed 5 feet away from you.",
"Alternatively, you can expend 1 to 3 of the ring's charges as an action to try to break an object you can see within 60 feet of you that isn't being worn or carried. The ring makes a Strength check with a +5 bonus for each charge you spend."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Ring of Three Wishes",
"source": "DMG",
"page": 193,
"srd": true,
"type": "RG",
"tier": "major",
"rarity": "legendary",
"entries": [
"While wearing this ring, you can use an action to expend 1 of its 3 charges to cast the {@spell wish} spell from it. The ring becomes nonmagical when you use the last charge."
],
"attachedSpells": [
"wish"
],
"lootTables": [
"Magic Item Table I"
],
"hasFluffImages": true
},
{
"name": "Ring of Thunder Resistance",
"source": "DMG",
"page": 192,
"srd": true,
"basicRules": true,
"type": "RG",
"resist": [
"thunder"
],
"detail1": "spinel",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"hasRefs": true,
"entries": [
"{#itemEntry Ring of Resistance}"
]
},
{
"name": "Ring of Truth Telling",
"source": "WDH",
"page": 192,
"type": "RG",
"rarity": "uncommon",
"reqAttune": true,
"entries": [
"While wearing this ring, you have advantage on Wisdom ({@skill Insight}) checks to determine whether someone is lying to you."
]
},
{
"name": "Ring of Warmth",
"source": "DMG",
"page": 193,
"srd": true,
"type": "RG",
"resist": [
"cold"
],
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"entries": [
"While wearing this ring, you have resistance to cold damage. In addition, you and everything you wear and carry are unharmed by temperatures as low as -50 degrees Fahrenheit."
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Ring of Water Elemental Command",
"source": "DMG",
"page": 190,
"srd": true,
"type": "RG",
"tier": "major",
"rarity": "legendary",
"reqAttune": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d4 + 1}",
"charges": 5,
"entries": [
"While wearing this ring, you have advantage on attack rolls against elementals from the Elemental Plane of Water and they have disadvantage on attack rolls against you. In addition, you have access to properties based on the Elemental Plane of Water.",
"The ring has 5 charges. It regains {@dice 1d4 + 1} expended charges daily at dawn. Spells cast from the ring have a save DC of 17.",
"You can expend 2 of the ring's charges to cast {@spell dominate monster} on a {@creature water elemental}. In addition, you can stand on and walk across liquid surfaces as if they were solid ground. You can also speak and understand Aquan.",
"If you help slay a {@creature water elemental} while attuned to the ring, you gain access to the following additional properties:",
{
"type": "list",
"items": [
"You can breathe underwater and have a swimming speed equal to your walking speed.",
"You can cast the following spells from the ring, expending the necessary number of charges: {@spell create or destroy water} (1 charge), {@spell control water} (3 charges), {@spell ice storm} (2 charges), or {@spell wall of ice} (3 charges)."
]
}
],
"attachedSpells": [
"dominate monster",
"create or destroy water",
"control water",
"ice storm",
"wall of ice"
],
"lootTables": [
"Magic Item Table I"
]
},
{
"name": "Ring of Water Walking",
"source": "DMG",
"page": 193,
"srd": true,
"type": "RG",
"tier": "major",
"rarity": "uncommon",
"entries": [
"While wearing this ring, you can stand on and move across any liquid surface as if it were solid ground."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Ring of Winter",
"source": "ToA",
"page": 207,
"type": "RG",
"immune": [
"cold"
],
"rarity": "artifact",
"reqAttune": true,
"sentient": true,
"weight": 1,
"recharge": "dawn",
"rechargeAmount": 12,
"charges": 12,
"entries": [
"{@creature Artus Cimber|ToA} has kept this item in his possession for over a century. The Ring of Winter is a golden band that resizes to fit snugly on the finger of its wearer. A thin layer of frost coats the outside of the ring, which normal heat can't melt. The ring feels ice cold to the touch and initially numbs the hand that wears it, but this cold ceases to be felt by one who is attuned to this ring.",
"The Ring of Winter is sentient and tries to take control of any creature that wears it (see \"Sentient Magic Items\" chapter 7 of the Dungeon Master's Guide). If it succeeds, the ring compels its wearer to cause undue harm to everyone and everything around it, in a cold-hearted attempt to incur the wrath of enemies and bring the wearer's doom.",
{
"type": "entries",
"name": "Sentience",
"entries": [
"The Ring of Winter is a sentient chaotic evil item with an Intelligence of 14, a Wisdom of 14, and a Charisma of 17. The ring communicates by transmitting emotion to the creature carrying or wielding it, and it has hearing and normal vision out to a range of 60 feet. The ring craves destruction, and it likes inflicting indiscriminate harm on others."
]
},
{
"type": "entries",
"name": "Nondetection",
"entries": [
"The Ring of Winter defies attempts to magically locate it. Neither the ring nor its wearer can be targeted by any divination magic or perceived through magical scrying sensors."
]
},
{
"type": "entries",
"name": "Frozen Time",
"entries": [
"As long as you wear the ring, you don't age naturally. This effect is similar to suspended animation, in that your age doesn't catch up to you once the ring is removed. The ring doesn't protect its wearer from magical or supernatural aging effects, such as the Horrifying Visage of a {@creature ghost}."
]
},
{
"type": "entries",
"name": "Cold Immunity",
"entries": [
"While attuned to and wearing the ring, you have immunity to cold damage and don't suffer any ill effects from extreme cold (see chapter 5 of the Dungeon's Master Guide)."
]
},
{
"type": "entries",
"name": "Magic",
"entries": [
"The Ring of Winter has 12 charges and regains all its expended charges daily at dawn. While wearing the ring, you can expend the necessary number of charges to activate one of the following properties:",
{
"type": "list",
"items": [
"You can expend 1 charge as an action and use the ring to lower the temperature in a 120-foot-radius sphere centered on a point you can see within 300 feet of you. The temperature in that area drops 20 degrees per minute, to a minimum of -30 degrees Fahrenheit. Frost and ice begin to form on surfaces once the temperature drops below 32 degrees. This effect is permanent unless you use the ring to end it as an action, at which point the temperature in the area returns to normal at a rate of 10 degrees per minute.",
"You can cast one of the following spells from the ring (spell save DC 17) by expending the necessary number of charges: {@spell Bigby's hand} (2 charges; the hand is made of ice, is immune to cold damage, and deals bludgeoning damage instead of force damage as a clenched fist), {@spell cone of cold} (2 charges), flesh to ice (3 charges; as {@spell flesh to stone} except that the target turns to solid ice with the density and durability of stone), {@spell ice storm} (2 charges), {@spell Otiluke's freezing sphere} (3 charges), {@spell sleet storm} (1 charge), {@spell spike growth} (1 charge; the spikes are made of ice), or {@spell wall of ice} (2 charges).",
"You can expend the necessary number of charges as an action and use the ring to create either an inanimate ice object (2 charges) or an animated ice creature (4 charges). The ice object can't have any moving parts, must be able to fit inside a 10-foot cube, and has the density and durability of metal or stone (your choice). The ice creature must be modeled after a beast with a challenge rating of 2 or less. The ice creature has the same statistics as the beast it models, with the following changes: the creature is a construct with vulnerability to fire damage, immunity to cold and poison damage, and immunity to the following conditions: {@condition charmed}, {@condition exhaustion}, {@condition frightened}, {@condition paralyzed}, {@condition petrified}, and {@condition poisoned}. The ice creature obeys only its creator's commands. The ice object or creature appears in an unoccupied space within 60 feet of you. It melts into a pool of normal water after 24 hours or when it drops to 0 hit points. In extreme heat, it loses 5 ({@dice 1d10}) hit points per minute as it melts. Use the guidelines in chapter 8 of the Dungeon Master's Guide to determine the hit points of an inanimate object if they become necessary."
]
}
]
},
{
"type": "entries",
"name": "Other Properties",
"entries": [
"The Ring of Winter is rumored to possess other properties that can be activated only by an evil being whose will the ring can't break. Frost giants have long believed that the ring can be used to freeze entire worlds, while a {@creature djinni} in the service of a Calishite pasha once claimed that the ring could be used to summon and control white dragons, as well as the mighty ice primordial named Cryonax."
]
},
{
"type": "entries",
"name": "Destroying the Ring",
"entries": [
"The ring is nigh indestructible, resisting even the most intense magical heat. If it is placed on the finger of the powerful archfey known as the Summer Queen, the ring melts away and is destroyed forever."
]
}
],
"attachedSpells": [
"cone of cold",
"ice storm",
"Otiluke's freezing sphere",
"sleet storm",
"wall of ice",
"Bigby's hand",
"flesh to stone",
"spike growth"
],
"hasFluffImages": true
},
{
"name": "Ring of X-ray Vision",
"source": "DMG",
"page": 193,
"srd": true,
"type": "RG",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"entries": [
"While wearing this ring, you can use an action to speak its command word. When you do so, you can see into and through solid matter for 1 minute. This vision has a radius of 30 feet. To you, solid objects within that radius appear transparent and don't prevent light from passing through them. The vision can penetrate 1 foot of stone, 1 inch of common metal, or up to 3 feet of wood or dirt. Thicker substances block the vision, as does a thin sheet of lead.",
"Whenever you use the ring again before taking a long rest, you must succeed on a DC 15 Constitution saving throw or gain one level of {@condition exhaustion}."
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Rings of Shared Suffering",
"source": "UAWGE",
"page": 117,
"type": "RG",
"rarity": "uncommon",
"reqAttune": true,
"entries": [
"These rings come in linked pairs. If you possess the Mark of Sentinel, you can use a bonus action to form a link to the creature attuned to the other ring; from then on, whenever that creature suffers damage, they only suffer half of that damage and you take the rest. This effect continues until you end it as a bonus action or until you or the other creature removes their ring. This effect isn't limited by range. A creature cannot be attuned to more than one ring of shared suffering."
]
},
{
"name": "Robe of Eyes",
"source": "DMG",
"page": 193,
"srd": true,
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"This robe is adorned with eyelike patterns. While you wear the robe, you gain the following benefits:",
{
"type": "list",
"items": [
"The robe lets you see in all directions, and you have advantage on Wisdom ({@skill Perception}) checks that rely on sight.",
"You have {@sense darkvision} out to a range of 120 feet.",
"You can see {@condition invisible} creatures and objects, as well as see into the Ethereal Plane, out to a range of 120 feet."
]
},
"The eyes on the robe can't be closed or averted. Although you can close or avert your own eyes, you are never considered to be doing so while wearing this robe.",
"A {@spell light} spell cast on the robe or a {@spell daylight} spell cast within 5 feet of the robe causes you to be {@condition blinded} for 1 minute. At the end of each of your turns, you can make a Constitution saving throw (DC 11 for {@spell light} or DC 15 for {@spell daylight}), ending the blindness on a success."
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Robe of Scintillating Colors",
"source": "DMG",
"page": 194,
"srd": true,
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"This robe has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. While you wear it, you can use an action and expend 1 charge to cause the garment to display a shifting pattern of dazzling hues until the end of your next turn. During this time, the robe sheds bright light in a 30-foot radius and dim light for an additional 30 feet. Creatures that can see you have disadvantage on attack rolls against you. In addition, any creature in the bright light that can see you when the robe's power is activated must succeed on a DC 15 Wisdom saving throw or become {@condition stunned} until the effect ends."
],
"lootTables": [
"Magic Item Table H"
]
},
{
"name": "Robe of Serpents",
"source": "SKT",
"page": 236,
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"entries": [
"A robe of serpents is a stylish silk garment that is popular among wealthy nobles and retired assassins. The robe is emblazoned with {@dice 1d4 + 3} stylized serpents, all brightly colored.",
"As a bonus action on your turn, you can transform one of the robe's serpents into a {@creature giant poisonous snake}. The snake instantly falls from the robe, slithers into an unoccupied space next to you, and acts on your initiative count. The snake can tell friendly creatures from hostile ones and attacks the latter. The snake disappears in a harmless puff of smoke after 1 hour, when it drops to 0 hit points, or when you dismiss it (no action required). Once detached, a snake can't return to the robe. When all of the robe's snakes have detached, the robe becomes a nonmagical garment."
]
},
{
"name": "Robe of Stars",
"source": "DMG",
"page": 194,
"srd": true,
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"bonusSavingThrow": "+1",
"entries": [
"This black or dark blue robe is embroidered with small white or silver stars. You gain a +1 bonus to saving throws while you wear it.",
"Six stars, located on the robe's upper front portion, are particularly large. While wearing this robe, you can use an action to pull off one of the stars and use it to cast {@spell magic missile} as a 5th-level spell. Daily at dusk, {@dice 1d6} removed stars reappear on the robe.",
"While you wear the robe, you can use an action to enter the Astral Plane along with everything you are wearing and carrying. You remain there until you use an action to return to the plane you were on. You reappear in the last space you occupied, or if that space is occupied, the nearest unoccupied space."
],
"attachedSpells": [
"magic missile"
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Robe of Summer",
"source": "TftYP",
"page": 229,
"resist": [
"cold"
],
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"weight": 1,
"entries": [
"This elegant garment is made from fine cloth in hues of red, orange, and gold. While you wear the robe, you have resistance to cold damage. In addition, you are comfortable as if the temperature were that of a balmy day, so you suffer no ill effects from the weather's temperature extremes."
]
},
{
"name": "Robe of the Archmagi",
"source": "DMG",
"page": 194,
"srd": true,
"tier": "major",
"rarity": "legendary",
"reqAttune": "by a sorcerer, warlock, or wizard",
"reqAttuneTags": [
{
"class": "sorcerer"
},
{
"class": "warlock"
},
{
"class": "wizard"
}
],
"wondrous": true,
"bonusSpellAttack": "+2",
"bonusSpellSaveDc": "+2",
"entries": [
"This elegant garment is made from exquisite cloth of white, gray, or black and adorned with silvery runes. The robe's color corresponds to the alignment for which the item was created. A white robe was made for good, gray for neutral, and black for evil. You can't attune to a robe of the archmagi that doesn't correspond to your alignment.",
"You gain these benefits while wearing the robe:",
{
"type": "list",
"items": [
"If you aren't wearing armor, your base Armor Class is 15 + your Dexterity modifier.",
"You have advantage on saving throws against spell and other magical effects.",
"Your spell save DC and spell attack bonus each increase by 2."
]
}
],
"lootTables": [
"Magic Item Table I"
],
"hasFluffImages": true
},
{
"name": "Robe of Useful Items",
"source": "DMG",
"page": 195,
"srd": true,
"tier": "minor",
"rarity": "uncommon",
"wondrous": true,
"entries": [
"This robe has cloth patches of various shapes and colors covering it. While wearing the robe. you can use an action to detach one of the patches, causing it to become the object or creature it represents. Once the last patch is removed, the robe becomes an ordinary garment.",
"The robe has two of each of the following patches:",
{
"type": "list",
"items": [
"Dagger",
"Bullseye lantern (filled and lit)",
"Steel mirror",
"10-foot pole",
"Hempen rope (50 feet, coiled)",
"Sack"
]
},
"In addition, the robe has {@dice 4d4} other patches. The DM chooses the patches or determines them randomly.",
{
"type": "table",
"colLabels": [
"{@dice d100}",
"Patch"
],
"colStyles": [
"col-1 text-center",
"col-11"
],
"rows": [
[
"01-08",
"Bag of 100 gp"
],
[
"09-15",
"Silver coffer (1 foot long, 6 inches wide and deep) worth 500 gp"
],
[
"16-22",
"Iron door (up to 10 feet wide and 10 feet high, barred on one side of your choice), which you can place in an opening you can reach; it conforms to fit the opening, attaching and hinging itself"
],
[
"23-30",
"10 gems worth 100 gp each"
],
[
"31-44",
"Wooden ladder (24 feet long)"
],
[
"45-51",
"A {@creature riding horse} with saddle bags"
],
[
"52-59",
"Pit (a cube 10 feet on a side), which you can place on the ground within 10 feet of you"
],
[
"60-68",
"4 {@item potion of healing||potions of healing}"
],
[
"69-75",
"{@item Rowboat} (12 feet long)"
],
[
"76-83",
"Spell scroll containing one spell of 1st to 3rd level"
],
[
"84-90",
"2 {@creature mastiff||mastiffs}"
],
[
"91-96",
"Window (2 feet by 4 feet, up to 2 feet deep), which you can place on a vertical surface you can reach"
],
[
"97-00",
"{@item Portable ram|phb}"
]
]
}
],
"lootTables": [
"Magic Item Table B"
],
"hasFluffImages": true
},
{
"name": "Robes",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 4,
"value": 100
},
{
"name": "Rod of Absorption",
"source": "DMG",
"page": 195,
"srd": true,
"type": "RD",
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"weight": 2,
"entries": [
"While holding this rod, you can use your reaction to absorb a spell that is targeting only you and not with an area of effect. The absorbed spell's effect is canceled, and the spell's energy—not the spell itself—is stored in the rod. The energy has the same level as the spell when it was cast. The rod can absorb and store up to 50 levels of energy over the course of its existence. Once the rod absorbs 50 levels of energy, it can't absorb more. If you are targeted by a spell that the rod can't store, the rod has no effect on that spell.",
"When you become attuned to the rod, you know how many levels of energy the rod has absorbed over the course of its existence, and how many levels of spell energy it currently has stored.",
"If you are a spellcaster holding the rod, you can convert energy stored in it into spell slots to cast spells you have prepared or know. You can create spell slots only of a level equal to or lower than your own spell slots, up to a maximum of 5th level. You use the stored levels in place of your slots, but otherwise cast the spell as normal. For example, you can use 3 levels stored in the rod as a 3rd-level spell slot.",
"A newly found rod has {@dice 1d10} levels of spell energy stored in it already. A rod that can no longer absorb spell energy and has no energy remaining becomes nonmagical."
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Rod of Alertness",
"source": "DMG",
"page": 196,
"srd": true,
"type": "RD",
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"weight": 2,
"bonusAc": "+1",
"bonusSavingThrow": "+1",
"entries": [
"This rod has a flanged head and the following properties.",
{
"name": "Alertness",
"type": "entries",
"entries": [
"While holding the rod, you have advantage on Wisdom ({@skill Perception}) checks and on rolls for initiative."
]
},
{
"name": "Spells",
"type": "entries",
"entries": [
"While holding the rod, you can use an action to cast one of the following spells from it: {@spell detect evil and good}, {@spell detect magic}, {@spell detect poison and disease}, or {@spell see invisibility}."
]
},
{
"name": "Protective Aura",
"type": "entries",
"entries": [
"As an action, you can plant the haft end of the rod in the ground, whereupon the rod's head sheds bright light in a 60-foot radius and dim light for an additional 60 feet. While in that bright light, you and any creature that is friendly to you gain a +1 bonus to AC and saving throws and can sense the location of any {@condition invisible} hostile creature that is also in the bright light.",
"The rod's head stops glowing and the effect ends after 10 minutes, or when a creature uses an action to pull the rod from the ground. This property can't be used again until the next dawn."
]
}
],
"attachedSpells": [
"detect evil and good",
"detect magic",
"detect poison and disease",
"see invisibility"
],
"lootTables": [
"Magic Item Table H"
]
},
{
"name": "Rod of Lordly Might",
"source": "DMG",
"page": 196,
"srd": true,
"type": "RD",
"tier": "major",
"rarity": "legendary",
"reqAttune": true,
"weight": 2,
"bonusWeapon": "+3",
"entries": [
"This rod has a flanged head, and it functions as a magic mace that grants a +3 bonus to attack and damage roll made with it. The rod has properties associated with six different buttons that are set in a row along the haft. It has three other properties as well, detailed below.",
{
"name": "Six Buttons",
"type": "entries",
"entries": [
"You can press one of the rod's six buttons as a bonus action. A button's effect lasts until you push a different button or until you push the same button again, which causes the rod to revert to its normal form.",
"If you press button 1, the rod becomes a {@item flame tongue} as a fiery blade sprouts from the end opposite the rod's flanged head (you choose the type of sword).",
"If you press button 2, the rod's flanged head folds down and two crescent-shaped blades spring out, transforming the rod into a magic battleaxe that grants a +3 bonus to attack and damage rolls made with it.",
"If you press button 3, the rod's flanged head folds down, a spear point springs from the rod's tip, and the rod's handle lengthens into a 6-foot haft, transforming the rod into a magic spear that grants a +3 bonus to attack and damage rolls made with it.",
"If you press button 4, the rod transforms into a climbing pole up to 50 feet long, as you specify. In surfaces as hard as granite, a spike at the bottom and three hooks at the top anchor the pole. Horizontal bars 3 inches long fold out from the sides, 1 foot apart, forming a ladder. The pole can bear up to 4,000 pounds. More weight or lack of solid anchoring causes the rod to revert to its normal form.",
"If you press button 5, the rod transforms into a handheld battering ram and grants its user a +10 bonus to Strength checks made to break through doors, barricades, and other barriers.",
"If you press button 6, the rod assumes or remains in its normal form and indicates magnetic north. (Nothing happens if this function of the rod is used in a location that has no magnetic north.) The rod also gives you knowledge of your approximate depth beneath the ground or your height above it."
]
},
{
"name": "Drain Life",
"type": "entries",
"entries": [
"When you hit a creature with a melee attack using the rod, you can force the target to make a DC 17 Constitution saving throw. On a failure, the target rakes an extra {@damage 4d6} necrotic damage, and you regain a number of hit points equal to half that necrotic damage. This property can't be used again until the next dawn."
]
},
{
"name": "Paralyze",
"type": "entries",
"entries": [
"When you hit a creature with a melee attack using the rod, you can force the target to make a DC 17 Strength saving throw. On a failure, the target is {@condition paralyzed} for 1 minute. The target can repeat the saving throw at the end of each of its turns, ending the effect on a success. This property can't be used again until the next dawn."
]
},
{
"name": "Terrify",
"type": "entries",
"entries": [
"While holding the rod, you can use an action to force each creature you can see within 30 feet of you to make a DC 17 Wisdom saving throw. On a failure, a target is {@condition frightened} of you for 1 minute. A {@condition frightened} target can repeat the saving throw at the end of each of its turns, ending the effect on itself on a success. This property can't be used again until the next dawn."
]
}
],
"lootTables": [
"Magic Item Table I"
],
"hasFluffImages": true
},
{
"name": "Rod of Resurrection",
"source": "DMG",
"page": 197,
"type": "RD",
"tier": "major",
"rarity": "legendary",
"reqAttune": "by a cleric, druid, or paladin",
"reqAttuneTags": [
{
"class": "cleric"
},
{
"class": "druid"
},
{
"class": "paladin"
}
],
"weight": 2,
"recharge": "dawn",
"rechargeAmount": 1,
"charges": 5,
"entries": [
"The rod has 5 charges. While you hold it, you can use an action to cast one of the following spells from it: {@spell heal} (expends 1 charge) or {@spell resurrection} (expends 5 charges).",
"The rod regains 1 expended charge daily at dawn. If the rod is reduced to 0 charges, roll a {@dice d20}. On a 1, the rod disappears in a burst of radiance."
],
"attachedSpells": [
"heal",
"resurrection"
],
"lootTables": [
"Magic Item Table I"
],
"hasFluffImages": true
},
{
"name": "Rod of Retribution",
"source": "EGW",
"page": 269,
"type": "RD",
"rarity": "uncommon",
"reqAttune": true,
"weight": 2,
"recharge": "dawn",
"rechargeAmount": 3,
"charges": 3,
"entries": [
"This adamantine rod is tipped with a glowing crystalline eye. The rod has 3 charges and regains all its expended charges daily at dawn.",
"When a creature you can see within 60 feet of you damages you while you are holding this rod, you can use your reaction to expend 1 of the rod's charges to force the creature to make a DC 13 Dexterity saving throw. The creature takes {@damage 2d10} lightning damage on a failed save, or half as much damage on a successful one."
]
},
{
"name": "Rod of Rulership",
"source": "DMG",
"page": 197,
"srd": true,
"type": "RD",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"weight": 2,
"entries": [
"You can use an action to present the rod and command obedience from each creature of your choice that you can see within 120 feet of you. Each target must succeed on a DC 15 Wisdom saving throw or be {@condition charmed} by you for 8 hours. While {@condition charmed} in this way, the creature regards you as its trusted leader. If harmed by you or your companions, or commanded to do something contrary to its nature, a target ceases to be {@condition charmed} in this way. The rod can't be used again until the next dawn."
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Rod of Security",
"source": "DMG",
"page": 197,
"srd": true,
"type": "RD",
"tier": "major",
"rarity": "very rare",
"weight": 2,
"entries": [
"While holding this rod, you can use an action to activate it. The rod then instantly transports you and up to 199 other willing creatures you can see to a paradise that exists in an extraplanar space. You choose the form that the paradise takes. It could be a tranquil garden, lovely glade, cheery tavern, immense palace, tropical island, fantastic carnival, or whatever else you can imagine. Regardless of its nature, the paradise contains enough water and food to sustain its visitors. Everything else that can be interacted with inside the extraplanar space can exist only there. For example, a flower picked from a garden in the paradise disappears if it is taken outside the extraplanar space.",
"For each hour spent in the paradise, a visitor regains hit points as if it had spent 1 Hit Die. Also, creatures don't age while in the paradise, although time passes normally. Visitors can remain in the paradise for up to 200 days divided by the number of creatures present (round down).",
"When the time runs out or you use an action to end it, all visitors reappear in the location they occupied when you activated the rod, or an unoccupied space nearest that location. The rod can't be used again until ten days have passed."
],
"lootTables": [
"Magic Item Table H"
]
},
{
"name": "Rod of the Vonindod",
"source": "SKT",
"page": 236,
"type": "RD",
"rarity": "rare",
"reqAttune": true,
"weight": 100,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 4}",
"charges": 10,
"entries": [
"The {@creature fire giant} Duke Zalto hired a wizard to craft several of these adamantine rods. Each measures 4 feet long, weighs 100 pounds, and is sized to fit comfortably in a {@creature fire giant||fire giant's} hand. The rod has two prongs at one end and a molded handle grip on the opposite end.",
"The rod has 10 charges and regains {@dice 1d6 + 4} of its expended charges daily at dawn. As an action, you can grasp it by the handle and expend 1 charge to cast the {@spell locate object} spell from it. When the rod is used to detect objects made of adamantine, such as fragments of the Vonindod construct, its range increases to 10 miles."
],
"attachedSpells": [
"locate object"
]
},
{
"name": "Rope of Climbing",
"source": "DMG",
"page": 197,
"srd": true,
"tier": "minor",
"rarity": "uncommon",
"wondrous": true,
"weight": 3,
"entries": [
"This 60-foot length of silk rope weighs 3 pounds and can hold up to 3,000 pounds. If you hold one end of the rope and use an action to speak the command word, the rope animates. As a bonus action, you can command the other end to move toward a destination you choose. That end moves 10 feet on your turn when you first command it and 10 feet on each of your turns until reaching its destination, up to its maximum length away, or until you tell it to stop. You can also tell the rope to fasten itself securely to an object or to unfasten itself, to knot or unknot itself, or to coil itself for carrying.",
"If you tell the rope to knot, large knots appear at 1-foot intervals along the rope. While knotted, the rope shortens to a 50-foot length and grants advantage on checks made to climb it.",
"The rope has AC 20 and 20 hit points. It regains 1 hit point every 5 minutes as long as it has at least 1 hit point. If the rope drops to 0 hit points, it is destroyed."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table B"
]
},
{
"name": "Rope of Entanglement",
"source": "DMG",
"page": 197,
"srd": true,
"tier": "major",
"rarity": "rare",
"wondrous": true,
"weight": 3,
"entries": [
"This rope is 30 feet long and weighs 3 pounds. If you hold one end of the rope and use an action to speak its command word, the other end darts forward to entangle a creature you can see within 20 feet of you. The target must succeed on a DC 15 Dexterity saving throw or become {@condition restrained}.",
"You can release the creature by using a bonus action to speak a second command word. A target {@condition restrained} by the rope can use an action to make a DC 15 Strength or Dexterity check (target's choice). On a success, the creature is no longer {@condition restrained} by the rope.",
"The rope has AC 20 and 20 hit points. It regains 1 hit point every 5 minutes as long as it has at least 1 hit point. If the rope drops to 0 hit points, it is destroyed."
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Rope of Mending",
"source": "XGE",
"page": 138,
"tier": "minor",
"rarity": "common",
"wondrous": true,
"entries": [
"You can cut this 50-foot coil of hempen rope into any number of smaller pieces, and then use an action to speak a command word and cause the pieces to knit back together. The pieces must be in contact with each other and not otherwise in use. A rope of mending is forever shortened if a section of it is lost or destroyed."
]
},
{
"name": "Rotor of Return",
"source": "AI",
"page": 221,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"bonusSavingThrow": "+1",
"entries": [
"While attuned to this device, you have a +1 bonus to Constitution saving throws. You periodically receive flashbacks of old memories, and you can unerringly recall any event that took place within the previous 30 days.",
{
"type": "entries",
"name": "Borrow Object",
"entries": [
"You name a mundane item with a value of 50 gp or less and it appears in your hand or at your feet. This can be any item that appears in chapter 5, \"{@book Equipment|PHB|5},\" of the Player's Handbook, or any similar item selected with the DM's permission. The summoned item is transported to you from somewhere else in the world, but it is generic in nature, so that you might call for a longsword but you cannot borrow a specific creature's longsword. The item vanishes 10 minutes after it appears. Once you use this feature of the rotor of return, it cannot be used again until the next dawn."
]
},
{
"type": "entries",
"name": "Recall Code",
"entries": [
"As an action, you can set your current location as a point of return locked to the rotor. Anytime thereafter, you can use a bonus action to teleport to the rotor's point of return as long as you are within 500 feet of that point. Once you use the rotor of return to teleport, this feature cannot be used again until the next dawn."
]
},
{
"type": "entries",
"name": "Part of a Whole",
"entries": [
"While this component is not installed in the {@item Orrery of the Wanderer|AI}, its magic might function sporadically or with unpredictable side effects, as determined by the DM."
]
}
]
},
{
"name": "Rowboat",
"source": "DMG",
"page": 119,
"srd": true,
"basicRules": true,
"type": "SHP",
"rarity": "none",
"weight": 100,
"value": 5000,
"crew": 1,
"vehAc": 11,
"vehHp": 50,
"vehSpeed": 1.5,
"capPassenger": 3,
"entries": [
"Keelboats and rowboats are used on lakes and rivers, If going downstream, add the speed of the current (typically 3 miles per hour) to the speed of the vehicle. These vehicles can't be rowed against any significant current, but they can be pulled upstream by draft animals on the shores. A rowboat weighs 100 pounds, in case adventurers carry it over land."
],
"seeAlsoVehicle": [
"Rowboat"
]
},
{
"name": "Ruby",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 500000,
"entries": [
"A transparent clear red to deep crimson gemstone."
]
},
{
"name": "Ruby of the War Mage",
"source": "XGE",
"page": 138,
"tier": "minor",
"rarity": "common",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"wondrous": true,
"focus": true,
"entries": [
"Etched with eldritch runes, this 1-inch-diameter ruby allows you to use a {@filter simple or martial weapon|items|source=phb|category=basic|type=martial weapon;simple weapon} as a spellcasting focus for your spells. For this property to work, you must attach the ruby to the weapon by pressing the ruby against it for at least 10 minutes. Thereafter, the ruby can't be removed unless you detach it as an action or the weapon is destroyed. Not even an {@spell antimagic field} causes it to fall off. The ruby does fall off the weapon if your attunement to the ruby ends."
]
},
{
"name": "Ruby Weave Gem",
"source": "FTD",
"page": 24,
"rarity": "legendary",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": 3,
"charges": 3,
"focus": [
"Artificer",
"Bard",
"Cleric",
"Druid",
"Paladin",
"Ranger",
"Sorcerer",
"Warlock",
"Wizard"
],
"entries": [
"While you are holding this gem, you can use it as a spellcasting focus for your spells.",
"The gem has 3 charges and regains all expended charges daily at dawn. When you cast a spell while holding this gem, you can expend up to 3 charges to ignore the spell's material components with a gold piece cost, up to 500 gp per charge expended.",
"When you finish a long rest, choose a spell from any class list. The spell you choose must be of a level you can cast. You know the chosen spell and can cast it with your spell slots of the appropriate level until the end of your next long rest."
]
},
{
"name": "Ruidium Shield",
"source": "CRCotN",
"page": 215,
"baseItem": "shield|PHB",
"type": "S",
"resist": [
"psychic"
],
"rarity": "very rare",
"reqAttune": true,
"weight": 6,
"ac": 2,
"bonusAc": "+2",
"modifySpeed": {
"equal": {
"swim": "walk"
}
},
"entries": [
"Tendrils of ruidium extend across the metal surface of this shield. While this shield is on your person, you gain the following benefits:",
{
"type": "list",
"items": [
"You have resistance to psychic damage.",
"You can breathe water.",
"You gain a swimming speed equal to your walking speed."
]
},
{
"type": "entries",
"name": "Psychic Reflection",
"entries": [
"When you take psychic damage while holding the shield, you can use your reaction to choose another creature you can see within 30 feet of you. That creature takes the psychic damage you would have taken."
]
},
{
"type": "entries",
"name": "Ruidium Corruption",
"entries": [
"When you use the shield's Psychic Reflection property, you must make a DC 20 Charisma saving throw. On a failed save, you gain 1 level of {@condition exhaustion}. If you are not already suffering from ruidium corruption, you become corrupted when you fail this save."
]
},
{
"type": "entries",
"name": "If Ruidium Is Destroyed",
"entries": [
"If the Apotheon is killed or redeemed, all the ruidium in Exandria is destroyed instantly, and a ruidium shield becomes a {@item +2 shield}."
]
}
],
"hasFluffImages": true
},
{
"name": "Ruin's Wake (Awakened)",
"source": "EGW",
"_copy": {
"name": "Ruin's Wake (Dormant)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Awakened",
"entries": [
"When the spear reaches an awakened state, it gains the following properties:",
{
"type": "list",
"items": [
"The weapon's bonus to attack and damage rolls increases to +2, and the extra piercing damage dealt by the weapon increases to {@dice 2d8}.",
"When you hurl the spear and speak a command word, it transforms into a bolt of lightning, forming a line 5 feet wide that extends out from you to a creature you can see within 120 feet of you. Each creature in the line, excluding you, must make a DC 15 Dexterity saving throw, taking {@damage 8d6} lightning damage on a failed save, or half as much damage on a successful one. Ruin's Wake then returns to your hand. This property can't be used again until the next dawn."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"bonusWeapon": "+2",
"hasFluffImages": true
},
{
"name": "Ruin's Wake (Dormant)",
"source": "EGW",
"page": 277,
"baseItem": "spear|PHB",
"type": "M",
"rarity": "artifact",
"reqAttune": true,
"sentient": true,
"weight": 3,
"weaponCategory": "simple",
"property": [
"T",
"V"
],
"range": "20/60",
"dmg1": "1d6",
"dmgType": "P",
"dmg2": "1d8",
"bonusWeapon": "+1",
"weapon": true,
"entries": [
"This spear is made from the ivory bone of an ancient gold dragon and carved with an Orc hymn to Gruumsh.",
{
"type": "entries",
"name": "Sentience",
"entries": [
"Ruin's Wake is a sentient chaotic evil weapon with an Intelligence of 20, a Wisdom of 16, and a Charisma of 22. It has hearing and {@sense darkvision} out to a range of 120 feet.",
"The weapon communicates telepathically with its wielder and can speak, read, and understand Abyssal, Common, and Orc."
]
},
{
"type": "entries",
"name": "Personality",
"entries": [
"A slaughter-loving {@creature balor} named Yarrowish lives within Ruin's Wake. The weapon desires only to draw blood and pushes its wielder to solve problems with violence."
]
},
{
"type": "entries",
"name": "Dormant",
"entries": [
"The spear grants the following benefits in its dormant state:",
{
"type": "list",
"items": [
"You can speak, read, and write Abyssal and Orc.",
"You gain a +1 bonus to attack and damage rolls made with this magic weapon, and it deals an extra {@damage 1d8} piercing damage to any target it hits. Immediately after you make a ranged attack with this weapon, it flies back to your hand.",
"As a reaction when you are hit by a melee attack, you can make a melee attack with Ruin's Wake with advantage against the attacker. You can't use this property again until you finish a short or long rest."
]
}
]
},
{
"type": "inset",
"name": "Betrayer Artifact Properties",
"entries": [
"The Arms of the Betrayers advance in power in the same manner as the Vestiges of Divergence. In its dormant state, each of these artifacts has one {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} property and one {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental} property. When the artifact attains an awakened state, it gains an additional {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} property and an additional {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental} property. When the item reaches its exalted state, it gains a {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial} property. See \"Artifact Properties\" in chapter 7 of the Dungeon Master's Guide for more information."
]
}
],
"hasFluffImages": true
},
{
"name": "Ruin's Wake (Exalted)",
"source": "EGW",
"_copy": {
"name": "Ruin's Wake (Awakened)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Exalted",
"entries": [
"When the spear reaches an exalted state, it gains the following properties:",
{
"type": "list",
"items": [
"The weapon's bonus to attack and damage rolls increases to +3.",
"While holding the spear, you can let out a battle cry as a bonus action. Each creature you choose within 30 feet of you gains advantage on attack rolls until the start of your next turn. This property can't be used again until the next dawn.",
"When you reduce a creature to 0 hit points with an attack from the spear, you can regain hit points equal to the damage you dealt with the attack. This property can't be used again until the next dawn."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"bonusWeapon": "+3",
"hasFluffImages": true
},
{
"name": "Ruinblade",
"source": "IMR",
"page": 95,
"baseItem": "greatsword|phb",
"type": "M",
"rarity": "legendary",
"reqAttune": true,
"curse": true,
"sentient": true,
"weight": 6,
"weaponCategory": "martial",
"property": [
"H",
"2H"
],
"dmg1": "2d6",
"dmgType": "S",
"bonusWeapon": "+2",
"entries": [
"Ruinblade is a unique weapon possessed by {@creature Moghadam|IMR}, the architect of the Tomb of Horrors. A symbol of his authority, Moghadam carries the weapon during the time frame of the adventure to intimidate the tomb's workers. (In later years, after {@creature Acererak|ToA} trapped Moghadam in the completed tomb as an undead archwraith, Moghadam was able to use the weapon as his own phylactery.)",
"A small jade version of the green devil face of the Tomb of Horrors is fashioned into the hilt of the weapon, with the blade extending from its open mouth. The blade always appears ruined and rusting, and causes its surroundings to appear reflected in the blade as if in a state of decay.",
"You gain a +2 bonus to attack and damage rolls made with this magic weapon. When you hit an undead creature with it, the attack deals an extra {@damage 1d8} force damage.",
"In addition, the weapon has the following properties:",
{
"type": "entries",
"name": "Blighted Ruin",
"entries": [
"You can cast the {@spell blight} spell from the weapon (DC 15). Once used, this property of the weapon can't be used until the next dawn."
]
},
{
"type": "entries",
"name": "Destructive Ruin",
"entries": [
"You can cast the {@spell disintegrate} spell from the weapon, but can target only a nonmagical object or a creation of magical force. Once used, this property of the weapon can't be used until dawn seven days later."
]
},
{
"type": "entries",
"name": "Curse",
"entries": [
"This weapon is cursed, and becoming attuned to it extends the curse to you. Until the curse is broken with a {@spell remove curse} spell or similar magic, you are unwilling to part with the weapon.",
"The weapon must take direct part in the destruction of 1,000 gp worth of materials and objects every seven days, or a conflict arises between you and the weapon at the end of the seventh day."
]
},
{
"type": "entries",
"name": "Sentience",
"entries": [
"Ruinblade is a sentient lawful evil weapon with an Intelligence of 15, a Wisdom of 8, and a Charisma of 16. It has hearing and {@sense darkvision} out to a range of 120 feet.",
"The weapon can speak, read, and understand Common, and can communicate with its wielder telepathically. Its voice is a rough, ruined echo. While you are attuned to it, Ruinblade also understands every language you know."
]
},
{
"type": "entries",
"name": "Personality",
"entries": [
"Ruinblade speaks either with a dolorous or frenetic tone, depending on its mood. That mood improves when the sword is actively engaged in acts of destruction, but worsens if the blade is left inactive too long.",
"The weapon's purpose is to ruin and unmake existing objects, so that its owner can rebuild the world from a blank slate. It thus encourages its wielder to engage in constant destruction, claiming that this will allow the creation of a more superior vision from the ruin of what came before."
]
}
],
"attachedSpells": [
"blight",
"disintegrate"
]
},
{
"name": "Ruinstone",
"source": "DC",
"rarity": "artifact",
"wondrous": true,
"entries": [
"A fiery red crystal the size of a human palm.",
"Once per day as a bonus action, a villain can activate the ruinstone to undo one deed they have performed. The possibilities here are broad, but in combat it's simplest to allow them the opportunity to \"rewind\" one action and take it again, or to reroll a failed saving throw (if it can still take actions).",
"Each use of the artifact has a terrible side effect: someone known to the wielder is also erased from reality. After the villain uses the ruinstone, roll a {@dice d20}: on a 5-20, one of their allies disintegrates, on an 1-4, one of the player characters disintegrates.",
"If a creature bound its soul to the ruinstone in the \"Thalivar's Beacon\" quest, the artifact explodes when used and the creature who is bound to it disintegrates, disappearing from reality and can only be brought back with a {@spell wish} spell, or similar magic."
]
},
{
"name": "Ryath Root",
"source": "ToA",
"page": 205,
"type": "G",
"rarity": "unknown",
"value": 5000,
"entries": [
"Any creature that ingests a ryath root gains {@dice 2d4} temporary hit points. A creature that consumes more than one ryath root in a 24-hour period must succeed on a DC 13 Constitution saving throw or suffer the {@condition poisoned} condition for 1 hour."
]
},
{
"name": "Sack",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 0.5,
"value": 1,
"entries": [
"A sack can hold up to 1 cubic foot or 30 pounds of gear."
],
"containerCapacity": {
"weight": [
30
]
}
},
{
"name": "Saddle of the Cavalier",
"source": "DMG",
"page": 199,
"tier": "minor",
"rarity": "uncommon",
"wondrous": true,
"entries": [
"While in this saddle on a mount, you can't be dismounted against your will if you're conscious, and attack rolls against the mount have disadvantage."
],
"lootTables": [
"Magic Item Table B"
]
},
{
"name": "Saddlebags",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TAH",
"rarity": "none",
"weight": 8,
"value": 400
},
{
"name": "Saffron",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TG",
"rarity": "none",
"weight": 1,
"value": 1500
},
{
"name": "Sailing Ship",
"source": "DMG",
"page": 119,
"srd": true,
"basicRules": true,
"type": "SHP",
"rarity": "none",
"value": 1000000,
"crew": 20,
"vehAc": 15,
"vehHp": 300,
"vehDmgThresh": 15,
"vehSpeed": 2,
"capPassenger": 20,
"capCargo": 100,
"seeAlsoVehicle": [
"Sailing Ship"
]
},
{
"name": "Saint Markovia's Thighbone",
"source": "CoS",
"page": 222,
"type": "M",
"rarity": "rare",
"reqAttune": true,
"weight": 4,
"dmg1": "1d6",
"dmgType": "B",
"entries": [
"Saint Markovia's thighbone has the properties of a {@item mace of disruption}. If it scores one or more hits against a {@creature vampire} or a {@creature vampire spawn} in the course of a single battle, the thighbone crumbles into dust once the battle concludes.",
"As a youth, Markovia followed her heart and became a priest of the Morninglord soon after her eighteenth birthday. She proved to be a charismatic proselytizer and, before the age of thirty, had gained a reputation for allowing no evil to stand before her.",
"Markovia had long considered Strahd a mad tyrant, but only after his transformation into a {@creature vampire} did she dare to challenge him. As she rallied her followers and prepared to march on Castle Ravenloft, Strahd sent a group of {@creature vampire spawn} to her abbey. They confronted Markovia and were destroyed to a one.",
"Suffused with confidence born of a righteous victory, Markovia advanced on Castle Ravenloft. A great battle raged from the catacombs to the parapets. In the end, Markovia never returned to Barovia, and Strahd long afterward walked with a limp and a grimace of pain. It is said that he trapped Markovia in a crypt beneath his castle, and her remains linger there yet.",
"The essence of Markovia's saintliness passed partly into her bones as the rest of her body decomposed. Her remaining thighbone is imbued with power that inflicts grievous injury on the undead.",
"{@item Mace of Disruption}. When you hit a fiend or an undead with this magic weapon, that creature takes an extra {@damage 2d6} radiant damage. If the target has 25 hit points or fewer after taking this damage, it must succeed on a DC 15 Wisdom saving throw or be destroyed. On a successful save, the creature becomes {@condition frightened} of you until the end of your next turn.",
"While you hold this weapon, it sheds bright light in a 20-foot radius and dim light for an additional 20 feet."
],
"hasFluffImages": true
},
{
"name": "Salt",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TG",
"rarity": "none",
"weight": 1,
"value": 5
},
{
"name": "Sapphire Buckler",
"source": "FTD",
"page": 24,
"baseItem": "shield|PHB",
"type": "S",
"resist": [
"psychic",
"thunder"
],
"rarity": "very rare",
"reqAttune": true,
"weight": 6,
"ac": 2,
"entries": [
"This crystalline blue shield is fashioned from a sapphire dragon's scale and is created to aid in rooting out the influence of Aberrations. While wielding the shield, you have resistance to psychic and thunder damage. Also, when you take damage from a creature that is within 5 feet of you, you can use your reaction to deal {@damage 2d6} thunder damage to that creature.",
"As an action, you can use the shield to help you locate Aberrations until the end of your next turn. If any Aberrations are within 1 mile of you, the shield emits a low humming tone for a moment, and you know the direction of all Aberrations within that range. Once this property is used, it can't be used again until the next dawn."
]
},
{
"name": "Sardonyx",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 5000,
"entries": [
"An opaque bands of red and white gemstone."
]
},
{
"name": "Scarab of Protection",
"source": "DMG",
"page": 199,
"srd": true,
"tier": "major",
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"charges": 12,
"entries": [
"If you hold this beetle-shaped medallion in your hand for 1 round, an inscription appears on its surface revealing its magical nature. It provides two benefits while it is on your person:",
{
"type": "list",
"items": [
"You have advantage on saving throws against spells.",
"The scarab has 12 charges. If you fail a saving throw against a necromancy spell or a harmful effect originating from an undead creature, you can use your reaction to expend 1 charge and turn the failed save into a successful one. The scarab crumbles into powder and is destroyed when its last charge is expended."
]
}
],
"lootTables": [
"Magic Item Table I"
],
"hasFluffImages": true
},
{
"name": "Scholar's Pack",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 10,
"value": 4000,
"entries": [
"Includes:",
{
"type": "list",
"items": [
"a {@item backpack|phb}",
"a {@item book|phb} of lore",
"a {@item Ink (1-ounce bottle)|phb|bottle of ink}",
"an {@item ink pen|phb}",
"10 {@item Parchment (one sheet)|phb|sheets of parchment}",
"a little bag of sand",
"a small knife."
]
}
],
"packContents": [
"backpack|phb",
"book|phb",
"ink (1-ounce bottle)|phb",
"ink pen|phb",
"parchment (one sheet)|phb",
{
"special": "little bag of sand"
},
{
"special": "small knife"
}
]
},
{
"name": "Scimitar of Speed",
"source": "DMG",
"page": 199,
"srd": true,
"baseItem": "scimitar|phb",
"type": "M",
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"weight": 3,
"weaponCategory": "martial",
"property": [
"F",
"L"
],
"dmg1": "1d6",
"dmgType": "S",
"bonusWeapon": "+2",
"entries": [
"You gain a +2 bonus to attack and damage rolls made with this magic weapon. In addition, you can make one attack with it as a bonus action on each of your turns."
],
"lootTables": [
"Magic Item Table H"
]
},
{
"name": "Scissors of Shadow Snipping",
"source": "WBtW",
"page": 213,
"rarity": "rare",
"reqAttune": "by a fey or a spellcaster",
"reqAttuneTags": [
{
"creatureType": "fey"
},
{
"spellcasting": true
}
],
"wondrous": true,
"entries": [
"As an action, you make a few snips with these iron shears and cause the shadow of a Humanoid creature you can see within 5 feet of you to detach from its source. If the creature is unwilling to give up its shadow, it can make a DC 15 Charisma saving throw, retaining its shadow on a success. Whether or not the shadow is snipped, this property of the scissors can't be used again until the next dawn.",
"The detached shadow is rooted to the spot where it was snipped until you use a bonus action to cause it to behave in one of the following ways, either of which is possible only if you can see the shadow:",
{
"type": "list",
"items": [
"You control the shadow's movements and can make the shadow move up to 30 feet across a solid or liquid surface, in any direction you choose (including along vertical surfaces), provided it remains within your sight at all times. The shadow is harmless and unable to be harmed, and it is {@condition invisible} in darkness. It can't speak, and it doesn't require air, sleep, or nourishment.",
"You can relinquish control of the shadow, at which point it becomes autonomous and behaves as the DM wishes. It uses the {@creature shadow} stat block in the Monster Manual, but its creature type is Fey instead of Undead. A creature whose Strength is reduced to 0 by this shadow's Strength Drain attack does not die but falls {@condition unconscious} instead. The creature regains consciousness and all its Strength after finishing a short or long rest."
]
},
"A creature whose shadow has detached from it is cursed. If a shadowless creature is subjected to any spell that ends a curse, or if its detached shadow is reduced to 0 hit points, the detached shadow disappears, and the creature regains its normal shadow instantly."
]
},
{
"name": "Scorpion Armor",
"source": "ToA",
"page": 208,
"type": "HA",
"rarity": "rare",
"reqAttune": true,
"curse": true,
"weight": 65,
"ac": 18,
"strength": "15",
"entries": [
"This suit of plate armor is fashioned from {@creature giant scorpion} chitin. While wearing this armor, you gain the following benefits:",
{
"type": "list",
"items": [
"The armor improves your combat readiness, granting you a +5 bonus to initiative as long as you aren't {@condition incapacitated}.",
"The armor doesn't impose disadvantage on your Dexterity ({@skill Stealth}) checks.",
"The armor doesn't impose disadvantage on saving throws made to resist the effects of extreme heat (see chapter 5 of the Dungeon Master's Guide)."
]
},
{
"type": "entries",
"name": "Curse",
"entries": [
"This armor is cursed. Whenever you don or doff it, you must make a DC 15 Constitution saving throw, taking 100 ({@damage 10d10 + 45}) poison damage on a failed save, or half as much damage on a successful one. Only a {@spell wish} spell can remove the armor's curse."
]
}
]
},
{
"name": "Scorpion Ship",
"source": "AAG",
"page": 0,
"type": "SPC",
"rarity": "none",
"value": 2500000,
"crew": 12,
"vehAc": 19,
"vehHp": 250,
"vehDmgThresh": 15,
"vehSpeed": 3.5,
"capCargo": 12,
"entries": [
"Scorpion ships are archaic metal warships that have never lost their popularity, largely because of their versatility. Articulated legs enable a scorpion ship to land safely on the ground and move across a solid surface at a walking speed of 30 feet. The ship can't float on water, however. A ballista is mounted on the top deck, and a mangonel is perched on the end of the tail. The ship's two claws are relatively inefficient in combat, but a claw that grabs a creature can quickly take it out of action."
],
"seeAlsoVehicle": [
"Scorpion Ship|AAG"
]
},
{
"name": "Scribe's Pen",
"source": "ERLW",
"page": 278,
"otherSources": [
{
"source": "UAWGE",
"page": 115
}
],
"rarity": "common",
"reqAttune": "by a creature with the Mark of Scribing",
"reqAttuneTags": [
{
"race": "gnome (mark of scribing)|erlw"
}
],
"wondrous": true,
"entries": [
"You can use this pen to write on any surface. You decide whether the writing is visible or {@condition invisible}, but the writing is always visible to a person with the Mark of Scribing.",
"Any creature with the Mark of Scribing can use an action to touch the {@condition invisible} writing, making it visible to all.",
"If you use the pen to write on a creature that isn't a construct, the writing fades after 7 days."
]
},
{
"name": "Scroll of Protection from Aberrations",
"source": "DMG",
"page": 199,
"type": "SC",
"tier": "minor",
"rarity": "rare",
"entries": [
"Using an action to read the scroll encloses you in an {@condition invisible} barrier that extends from you to form a 5-foot-radius, 10-foot-high cylinder. For 5 minutes, this barrier prevents aberrations from entering or affecting anything within the cylinder. The cylinder moves with you and remains centered on you. However, if you move in such a way that an aberration would be inside the cylinder, the effect ends. A creature can attempt to overcome the barrier by using an action to make a DC 15 Charisma check. On a success, the creature ceases to be affected by the barrier."
]
},
{
"name": "Scroll of Protection from Beasts",
"source": "DMG",
"page": 199,
"type": "SC",
"tier": "minor",
"rarity": "rare",
"entries": [
"Using an action to read the scroll encloses you in an {@condition invisible} barrier that extends from you to form a 5-foot-radius, 10-foot-high cylinder. For 5 minutes, this barrier prevents beasts from entering or affecting anything within the cylinder. The cylinder moves with you and remains centered on you. However, if you move in such a way that a beast would be inside the cylinder, the effect ends. A creature can attempt to overcome the barrier by using an action to make a DC 15 Charisma check. On a success, the creature ceases to be affected by the barrier."
]
},
{
"name": "Scroll of Protection from Celestials",
"source": "DMG",
"page": 199,
"type": "SC",
"tier": "minor",
"rarity": "rare",
"entries": [
"Using an action to read the scroll encloses you in an {@condition invisible} barrier that extends from you to form a 5-foot-radius, 10-foot-high cylinder. For 5 minutes, this barrier prevents celestials from entering or affecting anything within the cylinder. The cylinder moves with you and remains centered on you. However, if you move in such a way that a celestial would be inside the cylinder, the effect ends. A creature can attempt to overcome the barrier by using an action to make a DC 15 Charisma check. On a success, the creature ceases to be affected by the barrier."
]
},
{
"name": "Scroll of Protection from Elementals",
"source": "DMG",
"page": 199,
"type": "SC",
"tier": "minor",
"rarity": "rare",
"entries": [
"Using an action to read the scroll encloses you in an {@condition invisible} barrier that extends from you to form a 5-foot-radius, 10-foot-high cylinder. For 5 minutes, this barrier prevents elementals from entering or affecting anything within the cylinder. The cylinder moves with you and remains centered on you. However, if you move in such a way that an elemental would be inside the cylinder, the effect ends. A creature can attempt to overcome the barrier by using an action to make a DC 15 Charisma check. On a success, the creature ceases to be affected by the barrier."
]
},
{
"name": "Scroll of Protection from Fey",
"source": "DMG",
"page": 199,
"type": "SC",
"tier": "minor",
"rarity": "rare",
"entries": [
"Using an action to read the scroll encloses you in an {@condition invisible} barrier that extends from you to form a 5-foot-radius, 10-foot-high cylinder. For 5 minutes, this barrier prevents fey from entering or affecting anything within the cylinder. The cylinder moves with you and remains centered on you. However, if you move in such a way that a fey would be inside the cylinder, the effect ends. A creature can attempt to overcome the barrier by using an action to make a DC 15 Charisma check. On a success, the creature ceases to be affected by the barrier."
]
},
{
"name": "Scroll of Protection from Fiends",
"source": "DMG",
"page": 199,
"type": "SC",
"tier": "minor",
"rarity": "rare",
"entries": [
"Using an action to read the scroll encloses you in an {@condition invisible} barrier that extends from you to form a 5-foot-radius, 10-foot-high cylinder. For 5 minutes, this barrier prevents fiends from entering or affecting anything within the cylinder. The cylinder moves with you and remains centered on you. However, if you move in such a way that a fiend would be inside the cylinder, the effect ends. A creature can attempt to overcome the barrier by using an action to make a DC 15 Charisma check. On a success, the creature ceases to be affected by the barrier."
]
},
{
"name": "Scroll of Protection from Plants",
"source": "DMG",
"page": 199,
"type": "SC",
"tier": "minor",
"rarity": "rare",
"entries": [
"Using an action to read the scroll encloses you in an {@condition invisible} barrier that extends from you to form a 5-foot-radius, 10-foot-high cylinder. For 5 minutes, this barrier prevents plants from entering or affecting anything within the cylinder. The cylinder moves with you and remains centered on you. However, if you move in such a way that a plant would be inside the cylinder, the effect ends. A creature can attempt to overcome the barrier by using an action to make a DC 15 Charisma check. On a success, the creature ceases to be affected by the barrier."
]
},
{
"name": "Scroll of Protection from Undead",
"source": "DMG",
"page": 199,
"type": "SC",
"tier": "minor",
"rarity": "rare",
"entries": [
"Using an action to read the scroll encloses you in an {@condition invisible} barrier that extends from you to form a 5-foot-radius, 10-foot-high cylinder. For 5 minutes, this barrier prevents undead from entering or affecting anything within the cylinder. The cylinder moves with you and remains centered on you. However, if you move in such a way that an undead would be inside the cylinder, the effect ends. A creature can attempt to overcome the barrier by using an action to make a DC 15 Charisma check. On a success, the creature ceases to be affected by the barrier."
]
},
{
"name": "Scroll of Tarrasque Summoning",
"source": "IDRotF",
"page": 315,
"type": "SC",
"rarity": "legendary",
"entries": [
"Using an action to read the scroll causes the {@creature tarrasque} (see the creature's entry in the Monster Manual) to appear in an unoccupied space you can see within 1 mile of you. The tarrasque disappears when it drops to 0 hit points and is hostile toward all creatures other than itself."
]
},
{
"name": "Scroll of the Comet",
"source": "IDRotF",
"page": 315,
"type": "SC",
"rarity": "legendary",
"entries": [
"By using an action to read the scroll, you cause a comet to fall from the sky and crash to the ground at a point you can see up to 1 mile away from you. You must be outdoors when you use the scroll, or nothing happens and the scroll is wasted.",
"The comet creates a 50-foot-deep, 500-foot-radius crater on impact. Any creature in that radius must make a DC 20 Dexterity saving throw, taking {@damage 30d10} force damage on a failed saving throw, or half as much damage on a successful one. All structures in the crater are destroyed, as are all nonmagical objects that aren't being worn or held."
]
},
{
"name": "Sealing Wax",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"value": 50
},
{
"name": "Seeker Dart",
"source": "PotA",
"page": 223,
"baseItem": "dart|phb",
"type": "R",
"rarity": "uncommon",
"weight": 0.25,
"weaponCategory": "simple",
"property": [
"F",
"T"
],
"range": "20/60",
"dmg1": "1d4",
"dmgType": "P",
"entries": [
"This small dart is decorated with designs like windy spirals that span the length of its shaft.",
"When you whisper the word \"seek\" and hurl this dart, it seeks out a target of your choice within 120 feet of you. You must have seen the target before, but you don't need to see it now. If the target isn't within range or if there is no clear path to it, the dart falls to the ground, its magic spent and wasted. Otherwise, elemental winds guide the dart instantly through the air to the target. The dart can pass though openings as narrow as 1 inch wide and can change direction to fly around corners.",
"When the dart reaches its target, the target must succeed on a DC 16 Dexterity saving throw or take {@damage 1d4} piercing damage and {@damage 3d4} lightning damage. The dart's magic is then spent, and it becomes an ordinary dart."
]
},
{
"name": "Sekolahian Worshiping Statuette",
"source": "GoS",
"page": 229,
"rarity": "common",
"wondrous": true,
"entries": [
"Skillfully carved from sandstone, this 1-foot-tall statuette depicts a shark twisting through the water with its mouth open. If any Tiny sea-dwelling animal is within 1 inch of the statuette's mouth, the shark flashes to life and deals 1 piercing damage to it. The shark can deal damage in this way no more than once per hour."
]
},
{
"name": "Selesnya Guild Signet",
"source": "GGR",
"page": 178,
"type": "RG",
"rarity": "uncommon",
"reqAttune": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"This ring, adorned with the symbol of Selesnya, allows you to cast {@spell charm person}. A guild signet is sometimes awarded to a guild member whose renown score in that guild is 5 or higher, as a reward for performing special services for the guild. Aside from its magical properties, the ring is also an indicator of Selesnya's recognition and favor.",
"A signet has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. While wearing it, you can expend 1 charge to cast the associated spell (save DC 13)."
],
"attachedSpells": [
"charm person"
],
"hasFluffImages": true
},
{
"name": "Selesnya Keyrune",
"source": "GGR",
"page": 178,
"rarity": "rare",
"reqAttune": "by a member of the Selesnya guild",
"reqAttuneTags": [
{
"background": "selesnya initiate|ggr"
}
],
"wondrous": true,
"entries": [
"Carved from white and green marble in the shape of a wolf's head, this keyrune transforms into a {@creature dire wolf}. The wolf persists for 8 hours. Its Intelligence is 6, and it understands Elvish and Sylvan but can't speak those languages. While it is within 1 mile of you, you can communicate with each other telepathically.",
"When you use an action to speak the item's command word and place the keyrune on the ground in an unoccupied space within 5 feet of you, the keyrune transforms into a {@creature dire wolf}. If there isn't enough space for the creature, the keyrune doesn't transform.",
"The creature is friendly to you, your companions, and other members of your guild (unless those guild members are hostile to you). It understands your languages and obeys your spoken commands. If you issue no commands, the creature takes the {@action Dodge} action and moves to avoid danger.",
"At the end of the duration, the creature reverts to its keyrune form. It reverts early if it drops to 0 hit points or if you use an action to speak the command word again while touching it. When the creature reverts to its keyrune form, it can't transform again until 36 hours have passed."
],
"hasFluffImages": true
},
{
"name": "Sending Stone",
"source": "AI",
"page": 33,
"rarity": "uncommon",
"wondrous": true,
"entries": [
"Also at rank 1, you are given use of an Acquisitions Incorporated sending stone, an uncommon magic item that resembles a gemstone in a bold setting. It functions like a normal sending stone, except that it has no matching stone and allows communication with Head Office, specific secretarians you know, and the secretarian nearest your location. You must succeed on a DC 15 Intelligence ({@skill Arcana}) check to establish contact. Once the stone is successfully used, it can't be used again until the next dawn.",
"Making contact with another secretarian assumes that they are in possession of their own sending stone.",
{
"type": "table",
"caption": "Quirks of Your Sending Stone",
"colLabels": [
"d8",
"quirk"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"1",
"It's a flip stone."
],
[
"2",
"It gets great reception everywhere except in your headquarters."
],
[
"3",
"It sometimes picks up other magical conversations."
],
[
"4",
"It's the perfect size, shape, and weight to be used as a skipping stone."
],
[
"5",
"It heats up when you use it, to the extent that it once burned through your gloves."
],
[
"6",
"It has an obnoxious ringtone that you can't work out how to change."
],
[
"7",
"It fails to notify you of incoming messages except for a faint pulsating glow."
],
[
"8",
"It's voice activated, so that every time you talk to someone, it tries to send a message to someone else."
]
]
},
{
"type": "entries",
"name": "Rumor Mill",
"entries": [
"Also at rank 2, whenever your franchise begins a major quest or mission, make a DC 15 Intelligence ({@skill History}) check. On a success, you can learn up to three rumors related to creatures or organizations involved in the mission, which come to you through your sending stone. These rumors reflect current or historical knowledge possessed by Acquisitions Incorporated or the organization's many contacts."
]
},
{
"type": "entries",
"name": "Improved Rumor Mill",
"entries": [
"At rank 3, when you use your Rumor Mill feature, the DM provides you with a sense of which parts of a particular rumor are inaccurate, if any. You do not necessarily learn the truth behind those false rumors."
]
}
],
"hasFluffImages": true
},
{
"name": "Sending Stones",
"source": "DMG",
"page": 199,
"tier": "minor",
"rarity": "uncommon",
"wondrous": true,
"entries": [
"Sending stones come in pairs, with each smooth stone carved to match the other so the pairing is easily recognized. While you touch one stone, you can use an action to cast the {@spell sending} spell from it. The target is the bearer of the other stone. If no creature bears the other stone, you know that fact as soon as you use the stone and don't cast the spell.",
"Once {@spell sending} is cast through the stones, they can't be used again until the next dawn. If one of the stones in a pair is destroyed, the other one becomes nonmagical."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"attachedSpells": [
"sending"
],
"lootTables": [
"Magic Item Table C"
],
"hasFluffImages": true
},
{
"name": "Sentinel Shield",
"source": "DMG",
"page": 199,
"type": "S",
"tier": "major",
"rarity": "uncommon",
"weight": 6,
"ac": 2,
"entries": [
"While holding this shield, you have advantage on initiative rolls and Wisdom ({@skill Perception}) checks. The shield is emblazoned with a symbol of an eye."
],
"lootTables": [
"Magic Item Table F"
]
},
{
"name": "Serpent Scale Armor",
"source": "CM",
"page": 98,
"baseItem": "scale mail|PHB",
"type": "MA",
"rarity": "uncommon",
"weight": 45,
"ac": 14,
"dexterityMax": null,
"entries": [
"This suit of magic armor is made from shimmering scales. While wearing it, you can apply your full Dexterity modifier (instead of a maximum of +2) when determining your Armor Class. In addition, this armor does not impose disadvantage on your Dexterity ({@skill Stealth}) checks."
]
},
{
"name": "Serpent Venom",
"source": "DMG",
"page": 258,
"srd": true,
"type": "G",
"rarity": "none",
"value": 20000,
"poison": true,
"entries": [
"This poison must be harvested from a dead or {@condition incapacitated} {@creature giant poisonous snake}. A creature subjected to this poison must succeed on a DC 11 Constitution saving throw, taking 10 ({@damage 3d6}) poison damage on a failed save, or half as much damage on a successful one."
],
"poisonTypes": [
"injury"
]
},
{
"name": "Serpent's Fang",
"source": "CM",
"page": 98,
"baseItem": "longsword|PHB",
"type": "M",
"rarity": "rare",
"weight": 3,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"sword": true,
"weapon": true,
"entries": [
"This single-edged magic sword is made from the scrimshawed fang of a giant serpent. Its hilt changes shape to adjust to the grasp of any creature that picks it up. The weapon deals an extra {@damage 1d10} poison damage to any target it hits."
]
},
{
"name": "Shadowfell Brand Tattoo",
"source": "TCE",
"page": 134,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"entries": [
"Produced by a special needle, this magic tattoo is dark in color and abstract.",
{
"type": "entries",
"name": "Tattoo Attunement",
"entries": [
"To attune to this item, you hold the needle to your skin where you want the tattoo to appear, pressing the needle there throughout the attunement process. When the attunement is complete, the needle turns into the ink that becomes the tattoo, which appears on the skin.",
"If your attunement to the tattoo ends, the tattoo vanishes, and the needle reappears in your space."
]
},
{
"type": "entries",
"name": "Shadow Essence",
"entries": [
"You gain {@sense darkvision} with a range of 60 feet, and you have advantage on Dexterity ({@skill Stealth}) checks."
]
},
{
"type": "entries",
"name": "Shadowy Defense",
"entries": [
"When you take damage, you can use your reaction to become insubstantial for a moment, halving the damage you take. Then the reaction can't be used again until the next sunset."
]
}
]
},
{
"name": "Shadowfell Brand Tattoo",
"source": "UA2020SpellsAndMagicTattoos",
"page": 12,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"entries": [
"This tattoo is dark in color and abstract. While it's on your skin, you have advantage on Dexterity ({@skill Stealth}) checks.",
{
"type": "entries",
"name": "Shadowy Defense",
"entries": [
"When you take damage, you can use your reaction to become shadowy and insubstantial for a moment, reducing the damage you take by half. Once used, this reaction can't be used again until the next dawn."
]
},
{
"type": "entries",
"name": "Tattoo Attunement",
"entries": [
"To attune to this item, you hold the needle to your skin where you want the tattoo to appear, pressing the needle there throughout the attunement process. When the attunement is complete, the needle turns into the ink that becomes the tattoo, which appears on the skin. If you have multiple magic tattoos, they count as a single magic item with regard to the number of magic items you can attune to.",
"If your attunement to the tattoo ends, the tattoo vanishes, and the needle reappears in the closest unoccupied space to you."
]
}
]
},
{
"name": "Shadowfell Shard",
"source": "TCE",
"page": 135,
"rarity": "rare",
"reqAttune": "by a sorcerer",
"reqAttuneTags": [
{
"class": "sorcerer"
}
],
"wondrous": true,
"weight": 1,
"focus": [
"Sorcerer"
],
"entries": [
"This dull, cold crystal sits heavy and leaden, saturated by the Shadowfell's despair. As an action, you can attach the shard to a Tiny object (such as a weapon or a piece of jewelry) or detach it. It falls off if your attunement to it ends. You can use the shard as a spellcasting focus while you hold or wear it.",
"When you use a Metamagic option on a spell while you are holding or wearing the shard, you can momentarily curse one creature targeted by the spell; choose one ability score, and until the end of your next turn, the creature has disadvantage on ability checks and saving throws that use that ability."
],
"hasFluffImages": true
},
{
"name": "Shard",
"source": "WDH",
"page": 169,
"type": "$",
"rarity": "none",
"value": 10,
"entries": [
"Silver coin, slightly smaller than the nib (1 shard = 10 {@item nib|wdh|nibs})"
]
},
{
"name": "Shard of the Ise Rune",
"source": "SKT",
"page": 236,
"resist": [
"fire"
],
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"This shard of ice is long and slender, roughly the size of a dagger. The ise (ice) rune glows within it. The shard has the following properties, which work only while it's on your person.",
{
"name": "Frigid Touch",
"type": "entries",
"entries": [
"As an action, you can touch a body of water and freeze the water in a 10-foot-radius sphere around the spot you touched. Once you use this property, you can't use it again until you finish a short or long rest."
]
},
{
"name": "Frost Friend",
"type": "entries",
"entries": [
"You have resistance to fire damage."
]
},
{
"name": "Icy Mantle",
"type": "entries",
"entries": [
"As an action, you can touch yourself or another creature with water on your finger. The water creates an icy mantle of protection. The next time within the next minute that the target takes bludgeoning, slashing, or piercing damage, that damage is reduced to 0, and the mantle is destroyed. Once you use this property, you can't use it again until you finish a short or long rest."
]
},
{
"name": "Winters Howl",
"type": "entries",
"entries": [
"As an action, you can cast {@spell sleet storm} (spell save DC 17). You regain this ability after you finish a short or long rest."
]
},
{
"name": "Gift of Frost",
"type": "entries",
"entries": [
"You can transfer the shard's magic to a nonmagical item—a cloak or a pair of boots-by tracing the ise rune there with your finger. The transfer takes 8 hours of work that requires the two items to be within 5 feet of each other. At the end, the shard is destroyed, and the rune appears in blue on the chosen item, which gains a benefit based on its form:",
{
"type": "list",
"style": "list-hang-notitle",
"items": [
{
"type": "item",
"name": "Cloak",
"entry": "The cloak is now a rare magic item that requires attunement. While wearing it, you have resistance to fire damage, and you have advantage on Dexterity ({@skill Stealth}) checks made while in snowy terrain."
},
{
"type": "item",
"name": "Boots",
"entry": "The pair of boots is now a rare magic item that requires attunement. While wearing it, you ignore {@quickref difficult terrain||3} while walking, and you can walk on water."
}
]
}
]
}
],
"attachedSpells": [
"sleet storm"
]
},
{
"name": "Shard of the Kalt Rune",
"source": "UAPrestigeClassesRunMagic",
"page": 6,
"type": "MR",
"resist": [
"fire"
],
"rarity": "rare",
"reqAttune": true,
"entries": [
"This long, slender shard of ice is roughly the size of a dagger. The kalt rune—the rune of ice—glows within the shard. When first grasped, the shard emits a painful cold that leaves your hand and arm numb. That feeling passes after a moment, allowing the shard to be handled normally.",
{
"type": "entries",
"name": "Frigid Touch (Simple Property)",
"entries": [
"As an action, you scribe the kalt rune on the surface of  any volume of water. The water freezes in a 10-foot radius around the spot where you scribed the rune."
]
},
{
"type": "entries",
"name": "Frost Friend (Simple Property)",
"entries": [
"While you are attuned to this rune, you have resistance to fire damage."
]
},
{
"type": "entries",
"name": "Icy Mantle (Simple Property)",
"entries": [
"As an action, you scribe the kalt rune using water onto yourself or another creature. The water instantly freezes into a mantle of protective ice that does not hinder movement or action. The next time the creature takes bludgeoning, slashing, or piercing damage, that damage is reduced to zero and the icy mantle is destroyed."
]
},
{
"type": "entries",
"name": "Freezing Bolt (Complex Property)",
"entries": [
"As an action, you scribe this rune using water onto a creature within your reach as you expend a spell slot. The rune freezes in place, and the creature must make a Constitution saving throw (DC 12 + the level of the expended spell slot). On a failure, the creature takes {@damage 2d8} cold damage plus {@damage 1d8} cold damage per level of the expended spell slot, and its speed is reduced to 0 until the end of your next turn. On a successful saving throw, the creature takes half as much damage and its speed is not affected."
]
},
{
"type": "entries",
"name": "Ice Brand (Complex Property)",
"entries": [
"Over the course of a short rest, you inscribe this rune using water onto a melee or ranged weapon, or onto up to 20 pieces of ammunition. The weapon or ammunition gains a ghostly white aura and deals cold damage instead of piercing, slashing, or bludgeoning damage.",
"In addition, you can expend a spell slot while using this property to grant the weapon or ammunition a bonus to attack rolls and damage rolls equal to the spell slot's level divided by three.",
"These effects last for 24 hours or until you use this property again."
]
},
{
"type": "entries",
"name": "Winter's Howl (Complex Property)",
"entries": [
"While you are attuned to this rune, you can cast {@spell sleet storm} as an action. You regain this ability after a short or long rest."
]
}
]
},
{
"name": "Shatterspike",
"source": "TftYP",
"page": 229,
"baseItem": "longsword|phb",
"type": "M",
"rarity": "uncommon",
"reqAttune": true,
"weight": 3,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"bonusWeapon": "+1",
"entries": [
"You have a +1 bonus to attack and damage rolls made with this magic weapon. If it hits an object, the hit is automatically a critical hit, and it can deal bludgeoning or slashing damage to the object (your choice). Further, damage from nonmagical sources can't harm the weapon."
]
},
{
"name": "Shatterstick",
"source": "BGDIA",
"page": 99,
"type": "OTH",
"rarity": "unknown",
"entries": [
"A shatterstick is a nonmagical, 12-inch-long, 4-pound stake made of blue-tinged infernal iron mined on Cania, the eighth layer of the Nine Hells. When embedded in earth or pounded into solid rock, the stake emits a seismic vibration in a 20-foot radius centered on itself for 1 minute, shaking the ground in that area for the duration. When the effects ends, the shatterstick breaks apart, becoming useless, and all structures within 20 feet of it take 35 ({@damage 10d6}) bludgeoning damage."
]
},
{
"name": "Shawm",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"type": "INS",
"rarity": "none",
"weight": 1,
"value": 200
},
{
"name": "Shawm",
"source": "SCAG",
"page": 124,
"type": "INS",
"rarity": "none",
"entries": [
"A double-reed instrument similar to an oboe or a bassoon, popular with gnomes, who have developed some bellows-powered versions."
]
},
{
"name": "Sheep",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TG",
"rarity": "none",
"value": 200
},
{
"name": "Shield Guardian Amulet",
"source": "IDRotF",
"page": 149,
"rarity": "rare",
"reqAttune": "by a humanoid",
"reqAttuneTags": [
{
"creatureType": "humanoid"
}
],
"wondrous": true,
"entries": [
"The amulet is a 4-inch-wide disk composed of silver-framed wood, with a rune carved into its face. A {@spell detect magic} spell reveals a magical aura of enchantment around the amulet.",
"Every {@creature shield guardian} has an amulet magically linked to it. A shield guardian can have only one corresponding amulet, and if that amulet is destroyed, the shield guardian is {@condition incapacitated} until a replacement amulet is created.",
"A shield guardian's amulet is subject to direct attack if it isn't being worn or carried. It has AC 10, 10 hit points, and immunity to poison and psychic damage. Crafting an amulet requires 1 week and costs 1,000 gp in components.",
"A shield guardian's solitary focus is to protect the amulet's wearer. The amulet's wearer can command the guardian to attack its enemies or to guard the wielder against attack. If an attack threatens to injure the wearer, the construct can magically absorb the blow into its own body, even at a distance.",
"A humanoid that attunes to this amulet knows the distance and direction of the shield guardian, provided the amulet and the guardian are on the same plane of existence. As an action, the amulet's attuned wearer can try to reactivate the shield guardian, doing so with a successful DC 20 Intelligence ({@skill Arcana}) check. Reactivation can only be attempted while the amulet and guardian are within 10 feet of each other."
],
"hasFluffImages": true
},
{
"name": "Shield of Expression",
"source": "XGE",
"page": 139,
"type": "S",
"tier": "minor",
"rarity": "common",
"weight": 6,
"ac": 2,
"entries": [
"The front of this shield is shaped in the likeness of a face. While bearing the shield, you can use a bonus action to alter the face's expression."
]
},
{
"name": "Shield of Far Sight",
"source": "VGM",
"page": 81,
"type": "S",
"rarity": "rare",
"weight": 6,
"ac": 2,
"entries": [
"A {@creature mind flayer} skilled at crafting magic items creates a shield of far sight by harvesting an eye from an intelligent humanoid and magically implanting it on the outer surface of a nonmagical shield. The shield becomes a magic item once the eyes is implanted, whereupon the {@creature mind flayer} can give the shield to a thrall or hang it on a wall in its lair. As long as the shield is on the same plane of existence as its creator, the {@creature mind flayer} can see through the shield's eye, which has {@sense darkvision} out to a range of 60 feet. While peering through this magical eye, the {@creature mind flayer} can use its Mind Blast action as though it were standing behind the shield.",
"If a shield of far sight is destroyed, the {@creature mind flayer} that created it is {@condition blinded} for {@dice 2d12} hours."
]
},
{
"name": "Shield of Missile Attraction",
"source": "DMG",
"page": 200,
"srd": true,
"type": "S",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"curse": true,
"weight": 6,
"ac": 2,
"entries": [
"While holding this shield, you have resistance to damage from ranged weapon attacks.",
{
"type": "entries",
"name": "Curse",
"entries": [
"This shield is cursed. Attuning to it curses you until you are targeted by the {@spell remove curse} spell or similar magic. Removing the shield fails to end the curse on you. Whenever a ranged weapon attack is made against a target within 10 feet of you, the curse causes you to become the target instead."
]
}
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Shield of Shouting",
"source": "ToA",
"page": 116,
"type": "S",
"rarity": "unknown (magic)",
"weight": 6,
"ac": 2,
"entries": [
"A {@spell detect magic} spell reveals an aura of transmutation magic around this shield, which has a minor magical property: words spoken by the shield's bearer are amplified and sound ten times louder than normal."
]
},
{
"name": "Shield of the Hidden Lord",
"source": "BGDIA",
"page": 225,
"type": "S",
"rarity": "legendary",
"reqAttune": true,
"sentient": true,
"weight": 6,
"ac": 2,
"bonusAc": "+2",
"recharge": "dawn",
"rechargeAmount": 3,
"charges": 3,
"entries": [
"The Shield of the Hidden Lord is of celestial origin and serves as a prison for the pit fiend Gargauth, whose mortal followers revere it as a god. Over time, Gargauth's evil has warped the shield's appearance, so that its celestial motif and designs have become twisted into a fiendish face that subtly moves in disturbing ways.",
"While holding this shield, you gain a +2 bonus to AC and resistance to fire damage.",
{
"type": "entries",
"name": "Sentience",
"entries": [
"The Shield of the Hidden Lord is sentient as long as it imprisons Gargauth. While sentient, the shield has the following properties:",
{
"type": "list",
"items": [
"The shield has an Intelligence of 22, a Wisdom of 18, and a Charisma of 24, as well as hearing and {@sense truesight} out to a range of 120 feet.",
"The shield can speak, read, and understand Common and Infernal, and it can communicate telepathically with any creature it can sense within 120 feet of it. Its voice is a deep, hollow whisper.",
"The shield has 3 charges. You can use an action to expend 1 charge to cast {@spell fireball} or 2 charges to cast {@spell wall of fire} from the shield (save DC 21 for each). The {@spell wall of fire} spell lasts for 1 minute (no concentration required). The shield regains all expended charges daily at dawn.",
"Anytime during your turn, the shield can choose to radiate an aura of dread for 1 minute. (This is not a power of the shield that you control.) Any creature hostile to you that starts its turn within 20 feet of the shield must make a DC 18 Wisdom saving throw. On a failed save, the creature is {@condition frightened} until the start of its next turn. On a successful save, the creature is immune to this power of the shield for the next 24 hours. Once the shield uses this power, it can't use it again until the next dawn."
]
}
]
},
{
"type": "entries",
"name": "Gargauth's Personality",
"entries": [
"Gargauth desperately seeks freedom but can't escape on its own. The pit fiend won't reveal its true nature, referring to itself only as the Hidden Lord. It drops hints and subtle suggestions that it is an angel trapped in an unholy prison. If released from the shield, the pit fiend honors the terms of whatever bargain it struck to facilitate its escape.",
"While trapped in the shield, Gargauth carefully steers the shield's current owner toward committing acts of cruelty and domination, hoping to condemn the individual's soul to the Nine Hells. Conflict occurs if the shield's owner does anything that would make it more difficult for Gargauth to escape its prison, such as leaving the shield in a place where others are unlikely to find it.",
"Gargauth doesn't know how to escape from the shield. The pit fiend believes (incorrectly) that it can break free of the shield if it's brought to the Nine Hells, for it's convinced that the shield's powers are weaker there."
]
},
{
"type": "entries",
"name": "Freeing Gargauth",
"entries": [
"Casting {@spell dispel evil and good} on the shield has a {@chance 1|1 percent} chance of freeing the pit fiend, or automatically succeeds if the spell is cast by a {@creature solar}, a {@creature planetar}, or an archdevil. A god can release the pit fiend by touching the shield and speaking Gargauth's name. When released, Gargauth appears in a random, unoccupied space as close to the shield as possible."
]
}
],
"attachedSpells": [
"fireball",
"wall of fire"
],
"hasFluffImages": true
},
{
"name": "Shield of the Uven Rune",
"source": "WDMM",
"page": 299,
"type": "S",
"immune": [
"cold"
],
"rarity": "very rare",
"reqAttune": true,
"weight": 6,
"ac": 2,
"bonusWeapon": "+1",
"entries": [
"This shield is made from the scale of an ancient white dragon. It has a rune burned into its outward-facing side. A character who examines the rune and succeeds on a DC 20 Intelligence ({@skill History}) check recognizes it as an uven (\"enemy\" in Giant) rune that confers great power.",
"While holding the shield, you benefit from the following properties.",
{
"type": "entries",
"name": "Winter's Friend",
"entries": [
"You are immune to cold damage."
]
},
{
"type": "entries",
"name": "Deadly Rebuke",
"entries": [
"Immediately after a creature hits you with a melee attack, you can use your reaction to deal {@damage 3d6} necrotic damage to that creature."
]
},
{
"type": "entries",
"name": "Bane",
"entries": [
"You can cast the {@spell bane} spell from the shield (save DC 17). The spell does not require concentration and lasts for 1 minute. Once you cast the spell from the shield, you can't do so again until you finish a short or long rest."
]
},
{
"type": "entries",
"name": "Gift of Vengeance",
"entries": [
"You can transfer the shield's magic to a nonmagical weapon by tracing the uven rune on the weapon with one finger. The transfer takes 8 hours of work that requires the two items to be within 5 feet of each other. At the end, the shield is destroyed, and the rune is etched or burned into the chosen weapon. This weapon becomes a rare magic item that requires attunement. It has the properties of a +1 weapon. The bonus increases to +3 when the weapon is used against one of the following creature types, chosen by you at the time of the magic weapon's creation: aberrations, celestials, constructs, dragons, elementals, fey, fiends, giants, or undead."
]
}
],
"attachedSpells": [
"bane"
]
},
{
"name": "Shiftweave",
"source": "ERLW",
"page": 279,
"otherSources": [
{
"source": "UAWGE",
"page": 115
}
],
"rarity": "common",
"wondrous": true,
"entries": [
"When a suit of shiftweave is created, up to five different outfits can be embedded into the cloth. While wearing the clothing, you can speak its command word as a bonus action to transform your outfit into your choice of one of the other designs contained within it. Regardless of its appearance, the outfit can't be anything but clothing. Although it can duplicate the look of other magical clothing, it doesn't gain their magical properties."
]
},
{
"name": "Shovel",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 5,
"value": 200
},
{
"name": "Shrike Ship",
"source": "AAG",
"page": 0,
"type": "SPC",
"rarity": "none",
"value": 2000000,
"crew": 11,
"vehAc": 15,
"vehHp": 250,
"vehDmgThresh": 15,
"vehSpeed": 8,
"capCargo": 20,
"entries": [
"This swift vessel is a relatively recent design, quickly gaining popularity with merchants and pirates. A shrike ship's legs enable it to land safely on the ground. The ship can float but isn't built for traveling on water and sinks quickly in rough seas.",
"Standard weaponry on a shrike ship includes three ballistae—one on the forecastle, one in the middle of the top deck, and one on the sterncastle. In a desperate situation, the ship's reinforced bow can be used as a piercing ram."
],
"seeAlsoVehicle": [
"Shrike Ship|AAG"
]
},
{
"name": "Signal Whistle",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"value": 5
},
{
"name": "Signet Ring",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"value": 500
},
{
"name": "Silk (1 sq. yd.)",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TG",
"rarity": "none",
"value": 1000
},
{
"name": "Silk robe with gold embroidery",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 25000
},
{
"name": "Silk Rope (50 feet)",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 5,
"value": 1000,
"entries": [
"Rope, whether made of hemp or silk, has 2 hit points and can be burst with a DC 17 Strength check."
]
},
{
"name": "Silken Spite (Awakened)",
"source": "EGW",
"_copy": {
"name": "Silken Spite (Dormant)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Awakened",
"entries": [
"The rapier grants the following benefits in its awakened state:",
{
"type": "list",
"items": [
"The weapon's bonus to attack and damage rolls increases to +2.",
"The saving throw DC for the weapon's poison increases to 15.",
"While holding the rapier, you can use an action to cast one of the following spells from it (save DC 15): {@spell cloudkill}, {@spell darkness}, {@spell levitate}, or {@spell web}. Once a spell has been cast using the rapier, that spell can't be cast from the rapier again until the next dawn."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"bonusWeapon": "+2",
"attachedSpells": [
"cloudkill",
"darkness",
"levitate",
"web"
]
},
{
"name": "Silken Spite (Dormant)",
"source": "EGW",
"page": 277,
"baseItem": "rapier|PHB",
"type": "M",
"rarity": "artifact",
"reqAttune": true,
"sentient": true,
"weight": 2,
"weaponCategory": "martial",
"property": [
"F"
],
"dmg1": "1d8",
"dmgType": "P",
"bonusWeapon": "+1",
"modifySpeed": {
"equal": {
"climb": "walk"
}
},
"sword": true,
"weapon": true,
"entries": [
"The blade of this rapier is made of spider silk. Its onyx pommel is tipped with a ruby cut in the shape of a spider, and the blade carries the blessing of Lolth.",
{
"type": "entries",
"name": "Sentience",
"entries": [
"Silken Spite is a sentient chaotic evil weapon with an Intelligence of 13, a Wisdom of 15, and a Charisma of 15. It has hearing and {@sense darkvision} out to a range of 120 feet.",
"The weapon communicates telepathically with its wielder and can speak, read, and understand Abyssal, Common, Elvish, and Undercommon."
]
},
{
"type": "entries",
"name": "Personality",
"entries": [
"A murderous {@creature yochlol} named Sinnafex lives within Silken Spite. The weapon speaks in whispers, pushing its wielder to trust no one and to kill those who cause the slightest personal offense."
]
},
{
"type": "entries",
"name": "Dormant",
"entries": [
"The rapier grants the following benefits in its dormant state:",
{
"type": "list",
"items": [
"You can speak, read, and write Abyssal, Elvish, and Undercommon.",
"You have {@sense darkvision} out to a range of 60 feet. If you already have {@sense darkvision}, being attuned to the rapier increases the range of your {@sense darkvision} by 60 feet.",
"You gain a +1 bonus to attack and damage rolls made with this magic weapon.",
"You can use an action to cause poison to coat the rapier's blade. The poison remains for 1 minute or until an attack using this weapon hits a creature. That creature must succeed on a DC 13 Constitution saving throw or become {@condition poisoned} for 1 hour. If the saving throw fails by 5 or more, the creature is also {@condition unconscious} while {@condition poisoned} in this way. The creature wakes up if it takes damage or if another creature uses its action to shake the creature awake.",
"While carrying the rapier, you can move up, down, and across vertical surfaces and upside down along ceilings, while leaving your hands free. You have a climbing speed equal to your walking speed. Additionally, you can't be caught in webs of any sort and can move through webs as if they were {@quickref difficult terrain||3}."
]
}
]
},
{
"type": "inset",
"name": "Betrayer Artifact Properties",
"entries": [
"The Arms of the Betrayers advance in power in the same manner as the Vestiges of Divergence. In its dormant state, each of these artifacts has one {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} property and one {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental} property. When the artifact attains an awakened state, it gains an additional {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} property and an additional {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental} property. When the item reaches its exalted state, it gains a {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial} property. See \"Artifact Properties\" in chapter 7 of the Dungeon Master's Guide for more information."
]
}
]
},
{
"name": "Silken Spite (Exalted)",
"source": "EGW",
"_copy": {
"name": "Silken Spite (Awakened)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Exalted",
"entries": [
"The rapier grants the following benefits in its exalted state:",
{
"type": "list",
"items": [
"The weapon's bonus to attack and damage rolls increases to +3.",
"The saving throw DC for the weapon's poison and spells cast from the weapon increases to 17.",
"Magical darkness doesn't impede your {@sense darkvision}.",
"While holding the weapon in dim light or darkness, you can use a bonus action to teleport up to 60 feet to an unoccupied space you can see that is also in dim light or darkness. This property can't be used again until the next dawn."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"bonusWeapon": "+3"
},
{
"name": "Silver",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TG",
"rarity": "none",
"weight": 1,
"value": 500
},
{
"name": "Silver (sp)",
"source": "PHB",
"page": 143,
"type": "$",
"rarity": "none",
"weight": 0.02,
"value": 10,
"entries": [
"Common coins come in several different denominations based on the relative worth of the metal from which they are made. The three most common coins are the gold piece (gp), the silver piece (sp), and the copper piece (cp).",
"With one gold piece, a character can buy a belt pouch, 50 feet of good rope, or a goat. A skilled (but not exceptional) artisan can earn one gold piece a day. The gold piece is the standard unit of measure for wealth, even if the coin itself is not commonly used. When merchants discuss deals that involve goods or services worth hundreds or thousands of gold pieces, the transactions don't usually involve the exchange of individual coins. Rather, the gold piece is a standard measure of value, and the actual exchange is in gold bars, letters of credit, or valuable goods.",
"One gold piece is worth ten silver pieces, the most prevalent coin among commoners. A silver piece buys a laborer's work for a day, a flask of lamp oil, or a night's rest in a poor inn.",
"One silver piece is worth ten copper pieces, which are common among laborers and beggars. A single copper piece buys a candle, a torch, or a piece of chalk.",
"In addition, unusual coins made of other precious metals sometimes appear in treasure hoards. The electrum piece (ep) and the platinum piece (pp) originate from fallen empires and lost kingdoms, and they sometimes arouse suspicion and skepticism when used in transactions. An electrum piece is worth five silver pieces, and a platinum piece is worth ten gold pieces.",
"A standard coin weighs about a third of an ounce, so fifty coins weigh a pound."
],
"hasFluffImages": true
},
{
"name": "Silver 25-Zib Coin",
"source": "GGR",
"page": 9,
"type": "$",
"rarity": "none",
"value": 25
},
{
"name": "Silver and gold brooch",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 75000
},
{
"name": "Silver chalice set with moonstones",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 75000
},
{
"name": "Silver Dragon Scale Mail",
"source": "DMG",
"page": 165,
"srd": true,
"type": "MA",
"resist": [
"cold"
],
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"weight": 45,
"ac": 14,
"bonusAc": "+1",
"bonusSavingThrow": "+1",
"stealth": true,
"entries": [
"Dragon scale mail is made of the scales of one kind of dragon. Sometimes dragons collect their cast-off scales and gift them to humanoids. Other times, hunters carefully skin and preserve the hide of a dead dragon. In either case, dragon scale mail is highly valued. While wearing this armor, you gain a +1 bonus to AC, you have advantage on saving throws against the Frightful Presence and breath weapons of dragons, and you have resistance to cold damage.",
"Additionally, you can focus your senses as an action to magically discern the distance and direction to the closest silver dragon within 30 miles of you. This special action can't be used again until the next dawn."
]
},
{
"name": "Silver ewer",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 2500
},
{
"name": "Silver headdress with amber and red-coral feathers (Sun Empire)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 75000
},
{
"name": "Silver medallion (Sun Empire)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 2500
},
{
"name": "Silver necklace with a gemstone pendant",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 25000
},
{
"name": "Silver necklace with an amber pendant (Sun Empire)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 25000
},
{
"name": "Silver shoulder piece with amber and garnet (Sun Empire)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 75000
},
{
"name": "Silver Sword",
"source": "MTF",
"page": 89,
"type": "M",
"tier": "major",
"rarity": "unknown (magic)",
"reqAttune": "by a creature that has psionic ability",
"reqAttuneTags": [
{
"psionics": true
}
],
"weight": 6,
"weaponCategory": "martial",
"property": [
"H",
"2H"
],
"dmg1": "2d6",
"dmgType": "S",
"bonusWeapon": "+3",
"entries": [
"You have a +3 bonus to attack and damage rolls made with this magic weapon. On a critical hit against a target in an astral body (as with the {@spell astral projection} spell), you can cut the silvery cord that tethers the target to its material body, instead of dealing damage."
]
},
{
"name": "Silver-plated steel longsword with jet set in hilt",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 75000
},
{
"name": "Silverquill Primer",
"source": "SCC",
"page": 39,
"rarity": "uncommon",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"The Silverquill Primer is a magic textbook created at Strixhaven's Silverquill College. The primer has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. If you make a Charisma ({@skill Intimidation}) or a Charisma ({@skill Persuasion}) check while holding the primer, you can expend 1 charge to give yourself a {@dice 1d4} bonus to the check, immediately after you roll the {@dice d20}.",
"In addition, if you study the primer at the end of a long rest, you can choose one 1st-level spell from the bard or cleric spell list. Before you finish your next long rest, you can cast the chosen spell once without a spell slot if you are holding the primer. Your spellcasting ability for this spell is your choice of Intelligence, Wisdom, or Charisma."
]
},
{
"name": "Simic Guild Signet",
"source": "GGR",
"page": 178,
"type": "RG",
"rarity": "uncommon",
"reqAttune": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"This ring, adorned with the symbol of Simic, allows you to cast {@spell expeditious retreat}. A guild signet is sometimes awarded to a guild member whose renown score in that guild is 5 or higher, as a reward for performing special services for the guild. Aside from its magical properties, the ring is also an indicator of Simic's recognition and favor.",
"A signet has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. While wearing it, you can expend 1 charge to cast the associated spell (save DC 13)."
],
"attachedSpells": [
"expeditious retreat"
],
"hasFluffImages": true
},
{
"name": "Simic Keyrune",
"source": "GGR",
"page": 178,
"rarity": "uncommon",
"reqAttune": "by a member of the Simic guild",
"reqAttuneTags": [
{
"background": "simic scientist|ggr"
}
],
"wondrous": true,
"entries": [
"This keyrune is assembled from coral, mother-of-pearl, and chrome and adorned with the spirals and curves characteristic of Simic ornamentation. The head resembles the shell of a sea creature. On command, the keyrune turns into a {@creature category 1 krasis|GGR} that has the Grabber and Stabilizing Legs adaptations. The transformation lasts for up to 5 hours.",
"When you use an action to speak the item's command word and place the keyrune on the ground in an unoccupied space within 5 feet of you, the keyrune transforms into a {@creature category 1 krasis|GGR} that has the Grabber and Stabilizing Legs adaptations. If there isn't enough space for the creature, the keyrune doesn't transform.",
"The creature is friendly to you, your companions, and other members of your guild (unless those guild members are hostile to you). It understands your languages and obeys your spoken commands. If you issue no commands, the creature takes the {@action Dodge} action and moves to avoid danger.",
"At the end of the duration, the creature reverts to its keyrune form. It reverts early if it drops to 0 hit points or if you use an action to speak the command word again while touching it. When the creature reverts to its keyrune form, it can't transform again until 36 hours have passed."
],
"hasFluffImages": true
},
{
"name": "Sinda berries (10)",
"source": "ToA",
"page": 205,
"type": "G",
"rarity": "unknown",
"value": 500,
"entries": [
"These berries are dark brown and bitter. A full-grown sinda berry bush has {@dice 4d6} berries growing on it. A bush plucked of all its berries grows new berries in {@dice 1d4} months. Picked berries lose their freshness and efficacy after 24 hours.",
"Fresh sinda berries can be eaten raw or crushed and added to a drink to dull the bitterness. A creature that consumes at least ten fresh sinda berries gains advantage on saving throws against disease and poison for the next 24 hours."
]
},
{
"name": "Siren Song Lyre",
"source": "MOT",
"page": 198,
"type": "INS",
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"You can use an action to play this lyre and cast one of the following spells from it: {@spell animal friendship}, {@spell charm person}, {@spell enthrall}, {@spell suggestion}. If the spell requires a saving throw, the spell save DC is 13.",
"Once the instrument has been used to cast a spell, it can't be used to cast that spell again until the next dawn."
],
"attachedSpells": [
"animal friendship",
"charm person",
"enthrall",
"suggestion"
]
},
{
"name": "Skyblinder Staff",
"source": "GGR",
"page": 181,
"rarity": "uncommon",
"reqAttune": true,
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"bonusWeapon": "+1",
"bonusSpellAttack": "+1",
"staff": true,
"entries": [
"You gain a +1 bonus to attack and damage rolls made with this magic quarterstaff. While holding it, you gain a +1 bonus to spell attack rolls.",
"If a flying creature you can see within 30 feet of you makes an attack roll against you, you can use your reaction to hold the staff aloft and cause it to flare with light. The attacker has disadvantage on the attack roll, and it must succeed on a DC 15 Constitution saving throw or be {@condition blinded} until the start of its next turn."
],
"hasFluffImages": true
},
{
"name": "Skyship",
"source": "EGW",
"page": 72,
"type": "AIR",
"rarity": "none",
"value": 10000000,
"crewMin": 6,
"crewMax": 10,
"vehSpeed": 10,
"capPassenger": 30,
"capCargo": 10,
"travelCost": 200,
"shippingCost": 100
},
{
"name": "Sled",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "VEH",
"rarity": "none",
"weight": 300,
"value": 2000
},
{
"name": "Sled Dog",
"source": "IDRotF",
"page": 20,
"type": "MNT",
"rarity": "none",
"value": 5000,
"entries": [
"A sled dog (use the {@creature wolf} stat block in appendix A of the Monster Manual) costs 50 gp and can pull 360 pounds.",
"Sled dogs must take a short rest after pulling a sled for 1 hour; otherwise, they gain one level of {@condition exhaustion}."
]
},
{
"name": "Sledgehammer",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 10,
"value": 200
},
{
"name": "Sling Bullets of Althemone",
"source": "MOT",
"page": 198,
"type": "A",
"rarity": "very rare",
"bonusWeapon": "+2",
"entries": [
"The sling bullets come in a pouch, which contains {@dice 1d4 + 4} bullets. Roll on the Magic Sling Bullets table for each bullet to determine its magical property.",
"You have a +2 bonus to attack and damage rolls made with each of these bullets. If a bullet misses its target, the bullet teleports back into the pouch. Once a bullet hits a target, the bullet loses its magic.",
{
"type": "table",
"caption": "Magic Sling Bullets",
"colStyles": [
"col-2 text-center",
"col-10"
],
"colLabels": [
"d4",
"Bullet"
],
"rows": [
[
"1",
"{@b Banishment.} A creature that takes damage from this bullet must succeed on a DC 15 Charisma saving throw or be banished as though affected by the {@spell banishment} spell."
],
[
"2",
"{@b Fulguration.} On a hit, this bullet deals an extra {@damage 2d8} lightning damage to its target. All other creatures within 10 feet of the target must each succeed on a DC 15 Constitution saving throw or take {@damage 1d8} thunder damage."
],
[
"3",
"{@b Stunning.} On a hit, this bullet deals an extra {@damage 1d10} force damage, and the target is {@condition stunned} until the end of your next turn."
],
[
"4",
"{@b Tracking.} A creature that takes damage from this bullet is marked with a glowing rune where the bullet hit. The mark lasts 24 hours. While the creature is marked, you always know the direction to it."
]
]
}
]
},
{
"name": "Slippers of Spider Climbing",
"source": "DMG",
"page": 200,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"modifySpeed": {
"equal": {
"climb": "walk"
}
},
"entries": [
"While you wear these light shoes, you can move up, down, and across vertical surfaces and upside down along ceilings, while leaving your hands free. You have a climbing speed equal to your walking speed. However, the slippers don't allow you to move this way on a slippery surface, such as one covered by ice or oil."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Slumbering Dragon Vessel",
"source": "FTD",
"page": 27,
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"entries": [
"This vessel can be a potion bottle, drinking horn, or other container meant to hold a liquid.",
"As a bonus action, if the vessel is empty, you can speak the command word to fill the vessel with one of the following (your choice): ale, olive oil, a {@item potion of healing}, or a {@item potion of climbing}. Once this property is used, it can't be used until the next dawn. A potion you create in this way loses its magical properties if it isn't imbibed within 24 hours."
]
},
{
"name": "Slumbering Dragon-Touched Focus",
"source": "FTD",
"page": 26,
"rarity": "uncommon",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"wondrous": true,
"focus": [
"Artificer",
"Bard",
"Cleric",
"Druid",
"Paladin",
"Ranger",
"Sorcerer",
"Warlock",
"Wizard"
],
"entries": [
"This wondrous item can be a scepter, an orb, an amulet, a crystal, or another finely crafted object. It typically incorporates imagery of dragons' wings, claws, teeth, or scales.",
"You have advantage on initiative rolls. While you are holding the focus, it can function as a spellcasting focus for all your spells."
]
},
{
"name": "Slumbering Scaled Ornament",
"source": "FTD",
"page": 27,
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"modifySpeed": {
"equal": {
"fly": "walk"
}
},
"entries": [
"This ornament can be jewelry, a cloak, or another wearable accessory. It appears to be fashioned from a dragon's scale, tooth, or claw, or it incorporates images in those shapes.",
"You have advantage on saving throws you make to avoid being {@condition charmed} or {@condition frightened} or to end those conditions on you."
]
},
{
"name": "Small gold bracelet",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 2500
},
{
"name": "Small gold idol",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 75000
},
{
"name": "Small gold statuette set with rubies",
"source": "DMG",
"page": 135,
"type": "$",
"rarity": "none",
"value": 750000
},
{
"name": "Small mirror set in a painted wooden frame",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 2500
},
{
"name": "Smith's Tools",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"additionalSources": [
{
"source": "XGE",
"page": 84
}
],
"type": "AT",
"rarity": "none",
"weight": 8,
"value": 2000,
"additionalEntries": [
"Smith's tools allow you to work metal, heating it to alter its shape, repair damage, or work raw ingots into useful items.",
{
"type": "entries",
"name": "Components",
"entries": [
"Smith's tools include hammers, tongs, charcoal, rags, and a whetstone."
]
},
{
"type": "entries",
"name": "Arcana and History",
"entries": [
"Your expertise lends you additional insight when examining metal objects, such as weapons."
]
},
{
"type": "entries",
"name": "Investigation",
"entries": [
"You can spot clues and make deductions that others might overlook when an investigation involves armor, weapons, or other metalwork."
]
},
{
"type": "entries",
"name": "Repair",
"entries": [
"With access to your tools and an open flame hot enough to make metal pliable, you can restore 10 hit points to a damaged metal object for each hour of work."
]
},
{
"type": "table",
"caption": "Smith's Tools",
"colLabels": [
"Activity",
"DC"
],
"colStyles": [
"col-10",
"col-2 text-center"
],
"rows": [
[
"Sharpen a dull blade",
"10"
],
[
"Repair a suit of armor",
"15"
],
[
"Sunder a nonmagical metal object",
"15"
]
]
}
]
},
{
"name": "Smoke Grenade",
"source": "DMG",
"page": 268,
"type": "EXP",
"rarity": "none",
"weight": 2,
"age": "modern",
"entries": [
"As an action, a character can throw a grenade at a point up to 60 feet away. With a grenade launcher, the character can propel the grenade up to 120 feet away.",
"One round after a smoke grenade lands, it emits a cloud of smoke that creates a heavily obscured area in a 20-foot radius. A moderate wind (at least 10 miles per hour) disperses the smoke in 4 rounds; a strong wind (20 or more miles per hour) disperses it in 1 round."
]
},
{
"name": "Smokepowder",
"source": "WDH",
"page": 192,
"rarity": "uncommon",
"wondrous": true,
"entries": [
"Smokepowder is a magical explosive chiefly used to propel a bullet out of the barrel of a firearm. It is stored in airtight wooden kegs or tiny, waterproof leather packets. A packet contains enough smokepowder for five shots, and a keg holds enough smokepowder for five hundred shots.",
"If smokepowder is set on fire, dropped, or otherwise handled roughly, it explodes and deals fire damage to each creature or object within 20 feet of it: {@dice 1d6} for a packet, {@dice 9d6} for a keg. A successful DC 12 Dexterity saving throw halves the damage.",
"Casting {@spell dispel magic} on smokepowder renders it permanently inert."
]
},
{
"name": "Snicker-Snack",
"source": "WBtW",
"page": 213,
"baseItem": "greatsword|PHB",
"type": "M",
"rarity": "legendary",
"reqAttune": "by a non-evil creature",
"reqAttuneTags": [
{
"alignment": [
"L",
"NX",
"C",
"G",
"NY"
]
}
],
"sentient": true,
"weight": 6,
"weaponCategory": "martial",
"property": [
"H",
"2H"
],
"dmg1": "2d6",
"dmgType": "S",
"grantsProficiency": true,
"bonusWeapon": "+3",
"sword": true,
"weapon": true,
"entries": [
"You gain a +3 bonus to attack and damage rolls made with this magic vorpal sword. In addition, the weapon ignores resistance to slashing damage. When you use this weapon to attack a creature that has at least one head and roll a 20 on the attack roll, you cut off one of the creature's heads. The creature dies if it can't survive without the lost head. A creature is immune to this effect if it is immune to slashing damage, it doesn't have or need a head, it has legendary actions, or the DM decides that the creature is too big for its head to be cut off with this weapon. Such a creature instead takes an extra {@damage 6d8} slashing damage from the hit.",
"While attuned to Snicker-Snack, you have proficiency with greatswords, and you can use your Charisma modifier instead of your Strength modifier for attack and damage rolls made with the weapon.",
{
"type": "entries",
"name": "Sentience",
"entries": [
"Snicker-Snack is a sentient, chaotic good greatsword with an Intelligence of 9, a Wisdom of 14, and a Charisma of 18. It has hearing and {@sense darkvision} out to a range of 120 feet. It can speak, read, and understand Common, and its voice sounds silvery and melodic. Snicker-Snack craves the destruction of evil Dragons and urges you to seek out these creatures and slay them.",
"Snicker-Snack has a fickle personality. It ends its attunement to you if you miss on attack rolls with the weapon three times in a row. Each time you finish a long rest after that happens, you can attempt to regain the sword's trust by making a contested Charisma check against Snicker-Snack. If you win the contest, your attunement to the weapon is instantly restored. Your attunement to the weapon can't be restored in any other way."
]
}
],
"hasFluffImages": true
},
{
"name": "Snowshoes",
"source": "IDRotF",
"page": 20,
"type": "G",
"rarity": "none",
"weight": 4,
"value": 200,
"entries": [
"Snowshoes reduce the likelihood of their wearer getting stuck in the deep snow."
]
},
{
"name": "Soap",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"value": 2
},
{
"name": "Songhorn",
"source": "SCAG",
"page": 124,
"type": "INS",
"rarity": "none",
"entries": [
"A recorder, a simple type of flute, usually carved from wood."
]
},
{
"name": "Soothsalts",
"source": "EGW",
"page": 152,
"type": "G",
"rarity": "none",
"value": 15000,
"entries": [
"Soothsalts are derived from a naturally occurring crystalline substance discovered throughout the wilds of the Miskath Strand. The crimson crystals have been mined from cavernous veins like those in the mouth of the Miskath Pit and found within smaller geode formations near sites ravaged by the Calamity. Soothsalts are consumed orally in lozenge-sized doses, and frequent users can be identified by the telltale crimson stain around their mouths. A creature subjected to a dose of soothsalts gains advantage on all Intelligence checks for {@dice 1d4} hours.",
"For each dose of soothsalts consumed, the creature must succeed on a DC 15 Constitution saving throw or gain one level of {@condition exhaustion}—an effect which is cumulative with multiple doses."
]
},
{
"name": "Sorcerous Spyglass",
"source": "XMtS",
"page": 19,
"rarity": "unknown (magic)",
"entries": [
"This elegant brass {@item spyglass|phb} features an intricate lens mechanism. A creature looking through the sorcerous spyglass has advantage on Wisdom ({@skill Perception}) checks to detect things that can be seen. In addition, the user can view magical auras while looking through the spyglass, as if under the effect of a {@spell detect magic} spell."
]
},
{
"name": "Soul Bag",
"source": "MM",
"page": 179,
"type": "OTH",
"rarity": "very rare",
"entries": [
"When an evil humanoid dies as a result of a {@creature night hag}'s Nightmare Haunting, the hag catches the soul in this black sack made of stitched flesh. A soul bag can hold only one evil soul at a time, and only the night hag who crafted the bag can catch a soul with it. Crafting a soul bag takes 7 days and a humanoid sacrifice (whose flesh is used to make the bag)."
]
},
{
"name": "Soul Coin",
"source": "BGDIA",
"page": 225,
"rarity": "uncommon",
"wondrous": true,
"entries": [
"Soul coins are about 5 inches across and about an inch thick, minted from infernal iron. Each coin weighs one-third of a pound, and is inscribed with Infernal writing and a spell that magically binds a single soul to the coin. Because each soul coin has a unique soul trapped within it, each has a story. A creature might have been imprisoned as a result of defaulting on a deal, while another might be the victim of a night hag's curse.",
{
"type": "entries",
"name": "Carrying Soul Coins",
"entries": [
"To hold a soul coin is to feel the soul bound within it—overcome with rage or fraught with despair.",
"An evil creature can carry as many soul coins as it wishes (up to its maximum weight allowance). A non-evil creature can carry a number of soul coins equal to or less than its Constitution modifier without penalty. A non-evil creature carrying a number of soul coins greater than its Constitution modifier has disadvantage on its attack rolls, ability checks, and saving throws."
]
},
{
"type": "entries",
"name": "Using a Soul Coin",
"entries": [
"A soul coin has 3 charges. A creature carrying the coin can use its action to expend 1 charge from a soul coin and use it to do one of the following:",
{
"type": "list",
"items": [
{
"type": "item",
"name": "Drain Life",
"entry": "You siphon away some of the soul's essence and gain {@dice 1d10} temporary hit points."
},
{
"type": "item",
"name": "Query",
"entry": "You telepathically ask the soul a question and receive a brief telepathic response, which you can understand. The soul knows only what it knew in life, but it must answer you truthfully and to the best of its ability. The answer is no more than a sentence or two and might be cryptic."
}
]
}
]
},
{
"type": "entries",
"name": "Freeing a Soul",
"entries": [
"Casting a spell that removes a curse on a soul coin frees the soul trapped within it, as does expending all of the coin's charges. The coin itself rusts from within and is destroyed once the soul is released. A freed soul travels to the realm of the god it served or the outer plane most closely tied to its alignment (DM's choice). The souls of lawful evil creatures released from soul coins typically emerge from the River Styx as {@creature lemure} devils.",
"A soul can also be freed by destroying the coin that contains it. A soul coin has AC 19, 1 hit point for each charge it has remaining, and immunity to all damage except that which is dealt by a {@item hellfire weapon|bgdia} or an infernal war machine's furnace (see \"{@adventure Soul Fuel|bgdia|11|Soul Fuel}\").",
"Freeing a soul from a soul coin is considered a good act, even if the soul belongs to an evil creature."
]
},
{
"type": "entries",
"name": "Hellish Currency",
"entries": [
"Soul coins are a currency of the Nine Hells and are highly valued by devils. The coins are used among the infernal hierarchy to barter for favors, bribe the unwilling, and reward the faithful for services rendered.",
"Soul coins are created by Mammon and his greater devils on Minauros, the third layer of the Nine Hells, in a vast chamber where the captured souls of evil mortals are bound into the coins. These coins are then distributed throughout the Nine Hells to be used for goods and services, infernal deals, dark bargains, and bribes."
]
}
],
"hasFluffImages": true
},
{
"name": "Sovereign Glue",
"source": "DMG",
"page": 200,
"srd": true,
"tier": "minor",
"rarity": "legendary",
"wondrous": true,
"entries": [
"This viscous, milky-white substance can form a permanent adhesive bond between any two objects. It must be stored in a jar or flask that has been coated inside with {@item oil of slipperiness}. When found, a container contains {@dice 1d6 + 1} ounces.",
"One ounce of the glue can cover a 1-foot square surface. The glue takes 1 minute to set. Once it has done so, the bond it creates can be broken only by the application of {@item universal solvent} or {@item oil of etherealness}, or with a {@spell wish} spell."
],
"lootTables": [
"Magic Item Table E"
],
"hasFluffImages": true
},
{
"name": "Space Galleon",
"source": "AAG",
"page": 0,
"type": "SPC",
"rarity": "none",
"value": 3000000,
"crew": 20,
"vehAc": 15,
"vehHp": 400,
"vehDmgThresh": 15,
"vehSpeed": 4,
"capCargo": 20,
"entries": [
"The space galleon is a mainstay among the host of vessels that ply Wildspace and the Astral Sea. Space galleons can easily pass for ordinary seafaring galleons. Because they can land on water and move across it like their namesakes, they can sail into ports on terrestrial worlds without attracting undue attention. Like an ordinary galleon, a space galleon isn't built to land on the ground (its keel would cause it to roll on its side). The ship's standard weaponry includes two forward-mounted ballistae and an aft-mounted mangonel."
],
"seeAlsoVehicle": [
"Space Galleon|AAG"
]
},
{
"name": "Speaking Stone",
"source": "ERLW",
"page": 279,
"rarity": "very rare",
"wondrous": true,
"entries": [
"The key to long-distance, virtually instantaneous communication across Khorvaire is House Sivis's network of message stations. Each station contains at least one speaking stone, which is carved from a Siberys dragonshard and inscribed with arcane symbols that uniquely identify it. If you're a gnome with the Mark of Scribing, you can touch the stone and use an action to cast the {@spell sending} spell from it. The target is any other speaking stone whose location or unique sequence of symbols you know. A creature within 5 feet of the stone hears the message as if they were the target.",
"In a Sivis message station, a gnome is always on duty by the speaking stone, listening for messages that might come in and transcribing them for delivery to their intended recipients."
],
"attachedSpells": [
"sending"
]
},
{
"name": "Spear of Backbiting",
"source": "TftYP",
"page": 229,
"baseItem": "spear|phb",
"type": "M",
"rarity": "very rare",
"reqAttune": true,
"curse": true,
"weight": 3,
"weaponCategory": "simple",
"property": [
"T",
"V"
],
"range": "50/90",
"dmg1": "1d6",
"dmgType": "P",
"dmg2": "1d8",
"bonusWeapon": "+2",
"entries": [
"You gain a +2 bonus to attack and damage rolls made with this magic weapon. When you throw it, its normal and long ranges both increase by 30 feet, and it deals one extra die of damage on a hit. After you throw it and it hits or misses, it flies back to your hand immediately.",
{
"type": "entries",
"name": "Curse",
"entries": [
"This weapon is cursed, and becoming attuned to it extends the curse to you. Until the curse is broken with {@spell remove curse} or similar magic, you are unwilling to part with the weapon, keeping it within reach at all times. In addition, you have disadvantage on attack rolls made with weapons other than this one."
]
},
"Whenever you roll a 1 on an attack roll using this weapon, the weapon bends or flies to hit you in the back. Make a new attack roll with advantage against your own AC. If the result is a hit, you take damage as if you had attacked yourself with the spear."
]
},
{
"name": "Spell Bottle",
"source": "EGW",
"page": 269,
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"entries": [
"This glass bottle can store one spell of up to 5th level at a time. When found, roll a {@dice d6} and subtract 1; the total determines the level of spell in the bottle (the DM chooses the spell, and 0 means the bottle is empty). A swirling blue vapor fills the bottle while it contains a spell.",
"When the bottle is empty, any creature can cast a spell of 1st through 5th level into it by touching it while casting. The spell has no effect other than to be stored in the bottle.",
"While holding the bottle, you can cast the spell stored in it. The spell uses the slot level, spell save DC, spell attack bonus, and spellcasting ability of the original caster, but is otherwise treated as if you cast the spell. The bottle becomes empty once the spell is cast.",
"If you're holding the empty bottle when you see a creature casting a spell within 60 feet of you, you can open the bottle as a reaction in an attempt to interrupt the spell. If the creature is casting a spell of 3rd level or lower, the spell has no effect, and it is stored in the bottle. If it is casting a spell of 4th level or higher, make an Intelligence check. The DC equals 10 + the spell's level. On a success, the spell has no effect, and it is stored in the bottle."
],
"hasFluffImages": true
},
{
"name": "Spell Gem (Amber)",
"source": "OotA",
"page": 223,
"otherSources": [
{
"source": "IMR",
"page": 95
}
],
"rarity": "very rare",
"reqAttune": "optional",
"wondrous": true,
"entries": [
"An amber spell gem can contain one spell from any class's spell list. You become aware of the spell when you learn the gem's properties. While holding the gem, you can cast the spell from it as an action if you know the spell or if the spell is on your class's spell list. Doing so doesn't require any components, and doesn't require attunement. The spell then disappears from the gem.",
"If the spell is of a higher level than you can normally cast, you must make an ability check using your spellcasting ability to determine whether you cast it successfully. The DC equals 10 + the spell's level. On a failed check, the spell disappears from the gem with no other effect.",
"An amber spell gem can store up to 4th level spells. Spells cast from the spell gem have a save DC of 15 and an attack bonus of {@hit 9}.",
"You can imbue the gem with a spell if you're attuned to it and it's empty. To do so, you cast the spell while holding the gem. The spell is stored in the gem instead of having any effect. Casting the spell must require either 1 action or 1 minute or longer, and the spell's level must be no higher than the gem's maximum. If the spell belongs to the school of abjuration and requires material components that are consumed, you must provide them, but they can be worth half as much as normal.",
"Once imbued with a spell, the gem can't be imbued again until the next dawn.",
"Deep gnomes created these magic gemstones and keep the creation process a secret."
]
},
{
"name": "Spell Gem (Bloodstone)",
"source": "OotA",
"page": 223,
"otherSources": [
{
"source": "IMR",
"page": 95
}
],
"rarity": "rare",
"reqAttune": "optional",
"wondrous": true,
"entries": [
"A bloodstone spell gem can contain one spell from any class's spell list. You become aware of the spell when you learn the gem's properties. While holding the gem, you can cast the spell from it as an action if you know the spell or if the spell is on your class's spell list. Doing so doesn't require any components, and doesn't require attunement. The spell then disappears from the gem.",
"If the spell is of a higher level than you can normally cast, you must make an ability check using your spellcasting ability to determine whether you cast it successfully. The DC equals 10 + the spell's level. On a failed check, the spell disappears from the gem with no other effect.",
"A bloodstone spell gem can store up to 3rd level spells. Spells cast from the spell gem have a save DC of 15 and an attack bonus of {@hit 7}.",
"You can imbue the gem with a spell if you're attuned to it and it's empty. To do so, you cast the spell while holding the gem. The spell is stored in the gem instead of having any effect. Casting the spell must require either 1 action or 1 minute or longer, and the spell's level must be no higher than the gem's maximum. If the spell belongs to the school of abjuration and requires material components that are consumed, you must provide them, but they can be worth half as much as normal.",
"Once imbued with a spell, the gem can't be imbued again until the next dawn.",
"Deep gnomes created these magic gemstones and keep the creation process a secret."
]
},
{
"name": "Spell Gem (Diamond)",
"source": "OotA",
"page": 223,
"otherSources": [
{
"source": "IMR",
"page": 95
}
],
"rarity": "legendary",
"reqAttune": "optional",
"wondrous": true,
"entries": [
"A diamond spell gem can contain one spell from any class's spell list. You become aware of the spell when you learn the gem's properties. While holding the gem, you can cast the spell from it as an action if you know the spell or if the spell is on your class's spell list. Doing so doesn't require any components, and doesn't require attunement. The spell then disappears from the gem.",
"If the spell is of a higher level than you can normally cast, you must make an ability check using your spellcasting ability to determine whether you cast it successfully. The DC equals 10 + the spell's level. On a failed check, the spell disappears from the gem with no other effect.",
"A diamond spell gem can store up to 9th level spells. Spells cast from the spell gem have a save DC of 19 and an attack bonus of {@hit 11}.",
"You can imbue the gem with a spell if you're attuned to it and it's empty. To do so, you cast the spell while holding the gem. The spell is stored in the gem instead of having any effect. Casting the spell must require either 1 action or 1 minute or longer, and the spell's level must be no higher than the gem's maximum. If the spell belongs to the school of abjuration and requires material components that are consumed, you must provide them, but they can be worth half as much as normal.",
"Once imbued with a spell, the gem can't be imbued again until the next dawn.",
"Deep gnomes created these magic gemstones and keep the creation process a secret."
]
},
{
"name": "Spell Gem (Jade)",
"source": "OotA",
"page": 223,
"otherSources": [
{
"source": "IMR",
"page": 95
}
],
"rarity": "very rare",
"reqAttune": "optional",
"wondrous": true,
"entries": [
"A jade spell gem can contain one spell from any class's spell list. You become aware of the spell when you learn the gem's properties. While holding the gem, you can cast the spell from it as an action if you know the spell or if the spell is on your class's spell list. Doing so doesn't require any components, and doesn't require attunement. The spell then disappears from the gem.",
"If the spell is of a higher level than you can normally cast, you must make an ability check using your spellcasting ability to determine whether you cast it successfully. The DC equals 10 + the spell's level. On a failed check, the spell disappears from the gem with no other effect.",
"A jade spell gem can store up to 5th level spells. Spells cast from the spell gem have a save DC of 17 and an attack bonus of {@hit 9}.",
"You can imbue the gem with a spell if you're attuned to it and it's empty. To do so, you cast the spell while holding the gem. The spell is stored in the gem instead of having any effect. Casting the spell must require either 1 action or 1 minute or longer, and the spell's level must be no higher than the gem's maximum. If the spell belongs to the school of abjuration and requires material components that are consumed, you must provide them, but they can be worth half as much as normal.",
"Once imbued with a spell, the gem can't be imbued again until the next dawn.",
"Deep gnomes created these magic gemstones and keep the creation process a secret."
]
},
{
"name": "Spell Gem (Lapis lazuli)",
"source": "OotA",
"page": 223,
"otherSources": [
{
"source": "IMR",
"page": 95
}
],
"rarity": "uncommon",
"reqAttune": "optional",
"wondrous": true,
"entries": [
"A lapis lazuli spell gem can contain one spell from any class's spell list. You become aware of the spell when you learn the gem's properties. While holding the gem, you can cast the spell from it as an action if you know the spell or if the spell is on your class's spell list. Doing so doesn't require any components, and doesn't require attunement. The spell then disappears from the gem.",
"If the spell is of a higher level than you can normally cast, you must make an ability check using your spellcasting ability to determine whether you cast it successfully. The DC equals 10 + the spell's level. On a failed check, the spell disappears from the gem with no other effect.",
"A lapis lazuli spell gem can store up to 1st level spells. Spells cast from the spell gem have a save DC of 13 and an attack bonus of {@hit 5}.",
"You can imbue the gem with a spell if you're attuned to it and it's empty. To do so, you cast the spell while holding the gem. The spell is stored in the gem instead of having any effect. Casting the spell must require either 1 action or 1 minute or longer, and the spell's level must be no higher than the gem's maximum. If the spell belongs to the school of abjuration and requires material components that are consumed, you must provide them, but they can be worth half as much as normal.",
"Once imbued with a spell, the gem can't be imbued again until the next dawn.",
"Deep gnomes created these magic gemstones and keep the creation process a secret."
]
},
{
"name": "Spell Gem (Obsidian)",
"source": "OotA",
"page": 223,
"otherSources": [
{
"source": "IMR",
"page": 95
}
],
"rarity": "uncommon",
"reqAttune": "optional",
"wondrous": true,
"entries": [
"An obsidian spell gem can contain one cantrip from any class's spell list. You become aware of the spell when you learn the gem's properties. While holding the gem, you can cast the spell from it as an action if you know the spell or if the spell is on your class's spell list. Doing so doesn't require any components, and doesn't require attunement. The spell then disappears from the gem.",
"If the spell is of a higher level than you can normally cast, you must make an ability check using your spellcasting ability to determine whether you cast it successfully. The DC equals 10 + the spell's level. On a failed check, the spell disappears from the gem with no other effect.",
"An obsidian spell gem can only store cantrips. Cantrips cast from the spell gem have a save DC of 13 and an attack bonus of {@hit 5}.",
"You can imbue the gem with a spell if you're attuned to it and it's empty. To do so, you cast the spell while holding the gem. The spell is stored in the gem instead of having any effect. Casting the spell must require either 1 action or 1 minute or longer, and the spell's level must be no higher than the gem's maximum. If the spell belongs to the school of abjuration and requires material components that are consumed, you must provide them, but they can be worth half as much as normal.",
"Once imbued with a spell, the gem can't be imbued again until the next dawn.",
"Deep gnomes created these magic gemstones and keep the creation process a secret."
]
},
{
"name": "Spell Gem (Quartz)",
"source": "OotA",
"page": 223,
"otherSources": [
{
"source": "IMR",
"page": 95
}
],
"rarity": "rare",
"reqAttune": "optional",
"wondrous": true,
"entries": [
"A quartz spell gem can contain one spell from any class's spell list. You become aware of the spell when you learn the gem's properties. While holding the gem, you can cast the spell from it as an action if you know the spell or if the spell is on your class's spell list. Doing so doesn't require any components, and doesn't require attunement. The spell then disappears from the gem.",
"If the spell is of a higher level than you can normally cast, you must make an ability check using your spellcasting ability to determine whether you cast it successfully. The DC equals 10 + the spell's level. On a failed check, the spell disappears from the gem with no other effect.",
"A quartz spell gem can store up to 2nd level spells. Spells cast from the spell gem have a save DC of 13 and an attack bonus of {@hit 5}.",
"You can imbue the gem with a spell if you're attuned to it and it's empty. To do so, you cast the spell while holding the gem. The spell is stored in the gem instead of having any effect. Casting the spell must require either 1 action or 1 minute or longer, and the spell's level must be no higher than the gem's maximum. If the spell belongs to the school of abjuration and requires material components that are consumed, you must provide them, but they can be worth half as much as normal.",
"Once imbued with a spell, the gem can't be imbued again until the next dawn.",
"Deep gnomes created these magic gemstones and keep the creation process a secret."
]
},
{
"name": "Spell Gem (Ruby)",
"source": "OotA",
"page": 223,
"otherSources": [
{
"source": "IMR",
"page": 95
}
],
"rarity": "legendary",
"reqAttune": "optional",
"wondrous": true,
"entries": [
"A ruby spell gem can contain one spell from any class's spell list. You become aware of the spell when you learn the gem's properties. While holding the gem, you can cast the spell from it as an action if you know the spell or if the spell is on your class's spell list. Doing so doesn't require any components, and doesn't require attunement. The spell then disappears from the gem.",
"If the spell is of a higher level than you can normally cast, you must make an ability check using your spellcasting ability to determine whether you cast it successfully. The DC equals 10 + the spell's level. On a failed check, the spell disappears from the gem with no other effect.",
"A ruby spell gem can store up to 8th level spells. Spells cast from the spell gem have a save DC of 18 and an attack bonus of {@hit 10}.",
"You can imbue the gem with a spell if you're attuned to it and it's empty. To do so, you cast the spell while holding the gem. The spell is stored in the gem instead of having any effect. Casting the spell must require either 1 action or 1 minute or longer, and the spell's level must be no higher than the gem's maximum. If the spell belongs to the school of abjuration and requires material components that are consumed, you must provide them, but they can be worth half as much as normal.",
"Once imbued with a spell, the gem can't be imbued again until the next dawn.",
"Deep gnomes created these magic gemstones and keep the creation process a secret."
]
},
{
"name": "Spell Gem (Star ruby)",
"source": "OotA",
"page": 223,
"otherSources": [
{
"source": "IMR",
"page": 95
}
],
"rarity": "legendary",
"reqAttune": "optional",
"wondrous": true,
"entries": [
"A star ruby spell gem can contain one spell from any class's spell list. You become aware of the spell when you learn the gem's properties. While holding the gem, you can cast the spell from it as an action if you know the spell or if the spell is on your class's spell list. Doing so doesn't require any components, and doesn't require attunement. The spell then disappears from the gem.",
"If the spell is of a higher level than you can normally cast, you must make an ability check using your spellcasting ability to determine whether you cast it successfully. The DC equals 10 + the spell's level. On a failed check, the spell disappears from the gem with no other effect.",
"A star ruby spell gem can store up to 7th level spells. Spells cast from the spell gem have a save DC of 18 and an attack bonus of {@hit 10}.",
"You can imbue the gem with a spell if you're attuned to it and it's empty. To do so, you cast the spell while holding the gem. The spell is stored in the gem instead of having any effect. Casting the spell must require either 1 action or 1 minute or longer, and the spell's level must be no higher than the gem's maximum. If the spell belongs to the school of abjuration and requires material components that are consumed, you must provide them, but they can be worth half as much as normal.",
"Once imbued with a spell, the gem can't be imbued again until the next dawn.",
"Deep gnomes created these magic gemstones and keep the creation process a secret."
]
},
{
"name": "Spell Gem (Topaz)",
"source": "OotA",
"page": 223,
"otherSources": [
{
"source": "IMR",
"page": 95
}
],
"rarity": "very rare",
"reqAttune": "optional",
"wondrous": true,
"entries": [
"A topaz spell gem can contain one spell from any class's spell list. You become aware of the spell when you learn the gem's properties. While holding the gem, you can cast the spell from it as an action if you know the spell or if the spell is on your class's spell list. Doing so doesn't require any components, and doesn't require attunement. The spell then disappears from the gem.",
"If the spell is of a higher level than you can normally cast, you must make an ability check using your spellcasting ability to determine whether you cast it successfully. The DC equals 10 + the spell's level. On a failed check, the spell disappears from the gem with no other effect.",
"A topaz spell gem can store up to 6th level spells. Spells cast from the spell gem have a save DC of 17 and an attack bonus of {@hit 10}.",
"You can imbue the gem with a spell if you're attuned to it and it's empty. To do so, you cast the spell while holding the gem. The spell is stored in the gem instead of having any effect. Casting the spell must require either 1 action or 1 minute or longer, and the spell's level must be no higher than the gem's maximum. If the spell belongs to the school of abjuration and requires material components that are consumed, you must provide them, but they can be worth half as much as normal.",
"Once imbued with a spell, the gem can't be imbued again until the next dawn.",
"Deep gnomes created these magic gemstones and keep the creation process a secret."
]
},
{
"name": "Spell Scroll (1st Level)",
"source": "DMG",
"page": 200,
"srd": true,
"basicRules": true,
"type": "SC",
"tier": "minor",
"rarity": "common",
"entries": [
"A spell scroll bears the words of a single spell, written as a mystical cipher. If the spell is on your class's spell list, you can read the scroll and cast its spell without providing any material components. Otherwise, the scroll is unintelligible. Casting the spell by reading the scroll requires the spell's normal casting time. Once the spell is cast, the words on the scroll fade, and it crumbles to dust. If the casting is interrupted, the scroll is not lost.",
"If the spell is on your class's spell list but of a higher level than you can normally cast, you must make an ability check using your spellcasting ability to determine whether you cast it successfully. The DC is 11. On a failed check, the spell disappears from the scroll with no other effect.",
"Once the spell is cast, the words on the scroll fade, and the scroll itself crumbles to dust.",
"A spell cast from this scroll has a save DC of 13 and an attack bonus of {@hit 5}.",
"A wizard spell on a spell scroll can be copied just as spells in spellbooks can be copied. When a spell is copied from a spell scroll, the copier must succeed on a DC 11 Intelligence ({@skill Arcana}) check. If the check succeeds, the spell is successfully copied. Whether the check succeeds or fails, the spell scroll is destroyed."
],
"spellScrollLevel": 1,
"lootTables": [
"Magic Item Table A"
]
},
{
"name": "Spell Scroll (2nd Level)",
"source": "DMG",
"page": 201,
"srd": true,
"basicRules": true,
"type": "SC",
"tier": "minor",
"rarity": "uncommon",
"entries": [
"A spell scroll bears the words of a single spell, written as a mystical cipher. If the spell is on your class's spell list, you can read the scroll and cast its spell without providing any material components. Otherwise, the scroll is unintelligible. Casting the spell by reading the scroll requires the spell's normal casting time. Once the spell is cast, the words on the scroll fade, and it crumbles to dust. If the casting is interrupted, the scroll is not lost.",
"If the spell is on your class's spell list but of a higher level than you can normally cast, you must make an ability check using your spellcasting ability to determine whether you cast it successfully. The DC is 12. On a failed check, the spell disappears from the scroll with no other effect.",
"Once the spell is cast, the words on the scroll fade, and the scroll itself crumbles to dust.",
"A spell cast from this scroll has a save DC of 13 and an attack bonus of {@hit 5}.",
"A wizard spell on a spell scroll can be copied just as spells in spellbooks can be copied. When a spell is copied from a spell scroll, the copier must succeed on a DC 12 Intelligence ({@skill Arcana}) check. If the check succeeds, the spell is successfully copied. Whether the check succeeds or fails, the spell scroll is destroyed."
],
"spellScrollLevel": 2,
"lootTables": [
"Magic Item Table A",
"Magic Item Table B"
]
},
{
"name": "Spell Scroll (3rd Level)",
"source": "DMG",
"page": 202,
"srd": true,
"basicRules": true,
"type": "SC",
"tier": "minor",
"rarity": "uncommon",
"entries": [
"A spell scroll bears the words of a single spell, written as a mystical cipher. If the spell is on your class's spell list, you can read the scroll and cast its spell without providing any material components. Otherwise, the scroll is unintelligible. Casting the spell by reading the scroll requires the spell's normal casting time. Once the spell is cast, the words on the scroll fade, and it crumbles to dust. If the casting is interrupted, the scroll is not lost.",
"If the spell is on your class's spell list but of a higher level than you can normally cast, you must make an ability check using your spellcasting ability to determine whether you cast it successfully. The DC is 13. On a failed check, the spell disappears from the scroll with no other effect.",
"Once the spell is cast, the words on the scroll fade, and the scroll itself crumbles to dust.",
"A spell cast from this scroll has a save DC of 15 and an attack bonus of {@hit 7}.",
"A wizard spell on a spell scroll can be copied just as spells in spellbooks can be copied. When a spell is copied from a spell scroll, the copier must succeed on a DC 13 Intelligence ({@skill Arcana}) check. If the check succeeds, the spell is successfully copied. Whether the check succeeds or fails, the spell scroll is destroyed."
],
"spellScrollLevel": 3,
"lootTables": [
"Magic Item Table B"
]
},
{
"name": "Spell Scroll (4th Level)",
"source": "DMG",
"page": 203,
"srd": true,
"basicRules": true,
"type": "SC",
"tier": "minor",
"rarity": "rare",
"entries": [
"A spell scroll bears the words of a single spell, written as a mystical cipher. If the spell is on your class's spell list, you can read the scroll and cast its spell without providing any material components. Otherwise, the scroll is unintelligible. Casting the spell by reading the scroll requires the spell's normal casting time. Once the spell is cast, the words on the scroll fade, and it crumbles to dust. If the casting is interrupted, the scroll is not lost.",
"If the spell is on your class's spell list but of a higher level than you can normally cast, you must make an ability check using your spellcasting ability to determine whether you cast it successfully. The DC is 14. On a failed check, the spell disappears from the scroll with no other effect.",
"Once the spell is cast, the words on the scroll fade, and the scroll itself crumbles to dust.",
"A spell cast from this scroll has a save DC of 15 and an attack bonus of {@hit 7}.",
"A wizard spell on a spell scroll can be copied just as spells in spellbooks can be copied. When a spell is copied from a spell scroll, the copier must succeed on a DC 14 Intelligence ({@skill Arcana}) check. If the check succeeds, the spell is successfully copied. Whether the check succeeds or fails, the spell scroll is destroyed."
],
"spellScrollLevel": 4,
"lootTables": [
"Magic Item Table C"
]
},
{
"name": "Spell Scroll (5th Level)",
"source": "DMG",
"page": 204,
"srd": true,
"basicRules": true,
"type": "SC",
"tier": "minor",
"rarity": "rare",
"entries": [
"A spell scroll bears the words of a single spell, written as a mystical cipher. If the spell is on your class's spell list, you can read the scroll and cast its spell without providing any material components. Otherwise, the scroll is unintelligible. Casting the spell by reading the scroll requires the spell's normal casting time. Once the spell is cast, the words on the scroll fade, and it crumbles to dust. If the casting is interrupted, the scroll is not lost.",
"If the spell is on your class's spell list but of a higher level than you can normally cast, you must make an ability check using your spellcasting ability to determine whether you cast it successfully. The DC is 15. On a failed check, the spell disappears from the scroll with no other effect.",
"Once the spell is cast, the words on the scroll fade, and the scroll itself crumbles to dust.",
"A spell cast from this scroll has a save DC of 17 and an attack bonus of {@hit 9}.",
"A wizard spell on a spell scroll can be copied just as spells in spellbooks can be copied. When a spell is copied from a spell scroll, the copier must succeed on a DC 15 Intelligence ({@skill Arcana}) check. If the check succeeds, the spell is successfully copied. Whether the check succeeds or fails, the spell scroll is destroyed."
],
"spellScrollLevel": 5,
"lootTables": [
"Magic Item Table C"
]
},
{
"name": "Spell Scroll (6th Level)",
"source": "DMG",
"page": 205,
"srd": true,
"basicRules": true,
"type": "SC",
"tier": "minor",
"rarity": "very rare",
"entries": [
"A spell scroll bears the words of a single spell, written as a mystical cipher. If the spell is on your class's spell list, you can read the scroll and cast its spell without providing any material components. Otherwise, the scroll is unintelligible. Casting the spell by reading the scroll requires the spell's normal casting time. Once the spell is cast, the words on the scroll fade, and it crumbles to dust. If the casting is interrupted, the scroll is not lost.",
"If the spell is on your class's spell list but of a higher level than you can normally cast, you must make an ability check using your spellcasting ability to determine whether you cast it successfully. The DC is 16. On a failed check, the spell disappears from the scroll with no other effect.",
"Once the spell is cast, the words on the scroll fade, and the scroll itself crumbles to dust.",
"A spell cast from this scroll has a save DC of 17 and an attack bonus of {@hit 9}.",
"A wizard spell on a spell scroll can be copied just as spells in spellbooks can be copied. When a spell is copied from a spell scroll, the copier must succeed on a DC 16 Intelligence ({@skill Arcana}) check. If the check succeeds, the spell is successfully copied. Whether the check succeeds or fails, the spell scroll is destroyed."
],
"spellScrollLevel": 6,
"lootTables": [
"Magic Item Table D"
]
},
{
"name": "Spell Scroll (7th Level)",
"source": "DMG",
"page": 206,
"srd": true,
"basicRules": true,
"type": "SC",
"tier": "minor",
"rarity": "very rare",
"entries": [
"A spell scroll bears the words of a single spell, written as a mystical cipher. If the spell is on your class's spell list, you can read the scroll and cast its spell without providing any material components. Otherwise, the scroll is unintelligible. Casting the spell by reading the scroll requires the spell's normal casting time. Once the spell is cast, the words on the scroll fade, and it crumbles to dust. If the casting is interrupted, the scroll is not lost.",
"If the spell is on your class's spell list but of a higher level than you can normally cast, you must make an ability check using your spellcasting ability to determine whether you cast it successfully. The DC is 17. On a failed check, the spell disappears from the scroll with no other effect.",
"Once the spell is cast, the words on the scroll fade, and the scroll itself crumbles to dust.",
"A spell cast from this scroll has a save DC of 18 and an attack bonus of {@hit 10}.",
"A wizard spell on a spell scroll can be copied just as spells in spellbooks can be copied. When a spell is copied from a spell scroll, the copier must succeed on a DC 17 Intelligence ({@skill Arcana}) check. If the check succeeds, the spell is successfully copied. Whether the check succeeds or fails, the spell scroll is destroyed."
],
"spellScrollLevel": 7,
"lootTables": [
"Magic Item Table D"
]
},
{
"name": "Spell Scroll (8th Level)",
"source": "DMG",
"page": 207,
"srd": true,
"basicRules": true,
"type": "SC",
"tier": "minor",
"rarity": "very rare",
"entries": [
"A spell scroll bears the words of a single spell, written as a mystical cipher. If the spell is on your class's spell list, you can read the scroll and cast its spell without providing any material components. Otherwise, the scroll is unintelligible. Casting the spell by reading the scroll requires the spell's normal casting time. Once the spell is cast, the words on the scroll fade, and it crumbles to dust. If the casting is interrupted, the scroll is not lost.",
"If the spell is on your class's spell list but of a higher level than you can normally cast, you must make an ability check using your spellcasting ability to determine whether you cast it successfully. The DC is 18. On a failed check, the spell disappears from the scroll with no other effect.",
"Once the spell is cast, the words on the scroll fade, and the scroll itself crumbles to dust.",
"A spell cast from this scroll has a save DC of 18 and an attack bonus of {@hit 10}.",
"A wizard spell on a spell scroll can be copied just as spells in spellbooks can be copied. When a spell is copied from a spell scroll, the copier must succeed on a DC 18 Intelligence ({@skill Arcana}) check. If the check succeeds, the spell is successfully copied. Whether the check succeeds or fails, the spell scroll is destroyed."
],
"spellScrollLevel": 8,
"lootTables": [
"Magic Item Table D",
"Magic Item Table E"
]
},
{
"name": "Spell Scroll (9th Level)",
"source": "DMG",
"page": 208,
"srd": true,
"basicRules": true,
"type": "SC",
"tier": "minor",
"rarity": "legendary",
"entries": [
"A spell scroll bears the words of a single spell, written as a mystical cipher. If the spell is on your class's spell list, you can read the scroll and cast its spell without providing any material components. Otherwise, the scroll is unintelligible. Casting the spell by reading the scroll requires the spell's normal casting time. Once the spell is cast, the words on the scroll fade, and it crumbles to dust. If the casting is interrupted, the scroll is not lost.",
"If the spell is on your class's spell list but of a higher level than you can normally cast, you must make an ability check using your spellcasting ability to determine whether you cast it successfully. The DC is 19. On a failed check, the spell disappears from the scroll with no other effect.",
"Once the spell is cast, the words on the scroll fade, and the scroll itself crumbles to dust.",
"A spell cast from this scroll has a save DC of 19 and an attack bonus of {@hit 11}.",
"A wizard spell on a spell scroll can be copied just as spells in spellbooks can be copied. When a spell is copied from a spell scroll, the copier must succeed on a DC 19 Intelligence ({@skill Arcana}) check. If the check succeeds, the spell is successfully copied. Whether the check succeeds or fails, the spell scroll is destroyed."
],
"spellScrollLevel": 9,
"lootTables": [
"Magic Item Table E"
]
},
{
"name": "Spell Scroll (Cantrip)",
"source": "DMG",
"page": 199,
"srd": true,
"basicRules": true,
"type": "SC",
"tier": "minor",
"rarity": "common",
"entries": [
"A spell scroll bears the words of a single spell, written as a mystical cipher. If the spell is on your class's spell list, you can read the scroll and cast its spell without providing any material components. Otherwise, the scroll is unintelligible. Casting the spell by reading the scroll requires the spell's normal casting time. Once the spell is cast, the words on the scroll fade, and it crumbles to dust. If the casting is interrupted, the scroll is not lost.",
"If the spell is on your class's spell list but of a higher level than you can normally cast, you must make an ability check using your spellcasting ability to determine whether you cast it successfully. The DC is 10. On a failed check, the spell disappears from the scroll with no other effect.",
"Once the spell is cast, the words on the scroll fade, and the scroll itself crumbles to dust.",
"A spell cast from this scroll has a save DC of 13 and an attack bonus of {@hit 5}."
],
"spellScrollLevel": 0,
"lootTables": [
"Magic Item Table A"
]
},
{
"name": "Spellbook",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 3,
"value": 5000,
"entries": [
"Essential for wizards, a spellbook is a leather-bound tome with 100 blank vellum pages suitable for recording spells."
]
},
{
"name": "Spellguard Shield",
"source": "DMG",
"page": 201,
"srd": true,
"type": "S",
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"weight": 6,
"ac": 2,
"entries": [
"While holding this shield, you have advantage on saving throws against spells and other magical effects, and spell attacks have disadvantage against you."
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Spelljamming Helm",
"source": "AAG",
"page": 23,
"rarity": "rare",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"wondrous": true,
"entries": [
"The function of this ornate chair is to propel and maneuver a ship on which it has been installed through space and air. It can also propel and maneuver a ship on water or underwater, provided the ship is built for such travel. The ship in question must weigh 1 ton or more.",
"The sensation of being attuned to a spelljamming helm is akin to the pins-and-needles effect one experiences after one's arm or leg falls asleep, but not as painful.",
"While attuned to a spelljamming helm and sitting in it, you gain the following abilities for as long as you maintain concentration (as if concentrating on a spell):",
{
"type": "list",
"items": [
"You can use the spelljamming helm to move the ship through space, air, or water up to the ship's speed. If the ship is in space and no other objects weighing 1 ton or more are within 1 mile of it, you can use the spelljamming helm to move the vessel fast enough to travel 100 million miles in 24 hours.",
"You can steer the vessel, albeit in a somewhat clumsy fashion, in much the way that a rudder or oars can be used to maneuver a seafaring ship.",
"At any time, you can see and hear what's happening on and around the vessel as though you were standing in a location of your choice aboard it."
]
},
{
"type": "entries",
"name": "Transfer Attunement",
"entries": [
"You can use an action to touch a willing spellcaster. That creature attunes to the spelljamming helm immediately, and your attunement to it ends."
]
},
{
"type": "inset",
"name": "Cost of a Spelljamming Helm",
"entries": [
"A spelljamming helm propels and steers a ship much as sails, oars, and rudders work on a seafaring vessel, and a spelljamming helm is easy to create if one has the proper spell. {@spell Create spelljamming helm|AAG} has a material component cost of 5,000 gp, so that's the least one can pay to acquire a spelljamming helm.",
"Wildspace merchants, including {@creature dohwar|BAM|dohwars} and {@creature mercane|BAM|mercanes} (both described in {@book Boo's Astral Menagerie|BAM}), typically sell a spelljamming helm for substantially more than it cost to make. How much more depends on the market, but 7,500 gp would be a reasonable demand. A desperate buyer in a seller's market might pay 10,000 gp or more."
]
}
],
"hasFluffImages": true
},
{
"name": "Spellshard",
"source": "ERLW",
"page": 279,
"otherSources": [
{
"source": "UAWGE",
"page": 115
}
],
"rarity": "common",
"wondrous": true,
"entries": [
"This polished Eberron dragonshard fits in the hand and stores information similar to a book. The shard can hold the equivalent of one book that's no more than 320 pages long. A shard can be created blank or already filled with information. When the shard is created, the creator can set a passphrase that must be spoken to access the information stored within.",
"While holding the shard, you can use an action to open your mind to the shard, seeing its content in your mind. On subsequent rounds, reading the text or scribing new text on blank \"pages\" in the shard requires concentration (as if concentrating on a spell) and takes the same amount of time it takes you to read and write normally. Thinking of a particular phrase or topic draws you to the first section in the shard that addresses it.",
"A wizard can use a spellshard as a spellbook, with the usual cost in gold and time to \"scribe\" a spell into the shard."
]
},
{
"name": "Spellwrought Tattoo (1st Level)",
"source": "TCE",
"page": 135,
"rarity": "common",
"wondrous": true,
"tattoo": true,
"entries": [
"Produced by a special needle, this magic tattoo contains a single {@filter 1st level spell|spells|level=1}, wrought on your skin by a magic needle. To use the tattoo, you must hold the needle against your skin and speak the command word. The needle turns into ink that becomes the tattoo, which appears on the skin in whatever design you like. Once the tattoo is there, you can cast its spell, requiring no material components. The tattoo glows faintly while you cast the spell and for the spell's duration. Once the spell ends, the tattoo vanishes from your skin.",
"The Ability modifier for this spell is +3; the Save DC is 13 and the attack bonus is +5."
]
},
{
"name": "Spellwrought Tattoo (1st Level)",
"source": "UA2020SpellsAndMagicTattoos",
"page": 11,
"rarity": "common",
"wondrous": true,
"tattoo": true,
"entries": [
"This tattoo contains a single spell, wrought on your skin by a magic needle. To use the tattoo, you must hold the needle against your skin where you want the tattoo to appear and speak the command word. The needle turns into the ink that becomes the tattoo, which appears on your skin. Once the tattoo is on your skin, you can cast its spell, requiring no material components. The tattoo glows faintly while you cast the spell and for the spell's duration. Once the spell ends, the tattoo vanishes from your skin.",
"A spell cast from this tattoo has a spellcasting ability modifier of +3, a save DC of 13 and an attack bonus of +5."
]
},
{
"name": "Spellwrought Tattoo (2nd Level)",
"source": "TCE",
"page": 135,
"rarity": "uncommon",
"wondrous": true,
"tattoo": true,
"entries": [
"Produced by a special needle, this magic tattoo contains a single {@filter 2nd level spell|spells|level=2}, wrought on your skin by a magic needle. To use the tattoo, you must hold the needle against your skin and speak the command word. The needle turns into ink that becomes the tattoo, which appears on the skin in whatever design you like. Once the tattoo is there, you can cast its spell, requiring no material components. The tattoo glows faintly while you cast the spell and for the spell's duration. Once the spell ends, the tattoo vanishes from your skin.",
"The Ability modifier for this spell is +3; the Save DC is 13 and the attack bonus is +5."
]
},
{
"name": "Spellwrought Tattoo (2nd Level)",
"source": "UA2020SpellsAndMagicTattoos",
"page": 11,
"rarity": "uncommon",
"wondrous": true,
"tattoo": true,
"entries": [
"This tattoo contains a single spell, wrought on your skin by a magic needle. To use the tattoo, you must hold the needle against your skin where you want the tattoo to appear and speak the command word. The needle turns into the ink that becomes the tattoo, which appears on your skin. Once the tattoo is on your skin, you can cast its spell, requiring no material components. The tattoo glows faintly while you cast the spell and for the spell's duration. Once the spell ends, the tattoo vanishes from your skin.",
"A spell cast from this tattoo has a spellcasting ability modifier of +3, a save DC of 13 and an attack bonus of +5."
]
},
{
"name": "Spellwrought Tattoo (3rd Level)",
"source": "TCE",
"page": 135,
"rarity": "uncommon",
"wondrous": true,
"tattoo": true,
"entries": [
"Produced by a special needle, this magic tattoo contains a single {@filter 3rd level spell|spells|level=3}, wrought on your skin by a magic needle. To use the tattoo, you must hold the needle against your skin and speak the command word. The needle turns into ink that becomes the tattoo, which appears on the skin in whatever design you like. Once the tattoo is there, you can cast its spell, requiring no material components. The tattoo glows faintly while you cast the spell and for the spell's duration. Once the spell ends, the tattoo vanishes from your skin.",
"The Ability modifier for this spell is +4; the Save DC is 15 and the attack bonus is +7."
]
},
{
"name": "Spellwrought Tattoo (3rd Level)",
"source": "UA2020SpellsAndMagicTattoos",
"page": 11,
"rarity": "uncommon",
"wondrous": true,
"tattoo": true,
"entries": [
"This tattoo contains a single spell, wrought on your skin by a magic needle. To use the tattoo, you must hold the needle against your skin where you want the tattoo to appear and speak the command word. The needle turns into the ink that becomes the tattoo, which appears on your skin. Once the tattoo is on your skin, you can cast its spell, requiring no material components. The tattoo glows faintly while you cast the spell and for the spell's duration. Once the spell ends, the tattoo vanishes from your skin.",
"A spell cast from this tattoo has a spellcasting ability modifier of +4, a save DC of 15 and an attack bonus of +7."
]
},
{
"name": "Spellwrought Tattoo (4th Level)",
"source": "TCE",
"page": 135,
"rarity": "rare",
"wondrous": true,
"tattoo": true,
"entries": [
"Produced by a special needle, this magic tattoo contains a single {@filter 4th level spell|spells|level=4}, wrought on your skin by a magic needle. To use the tattoo, you must hold the needle against your skin and speak the command word. The needle turns into ink that becomes the tattoo, which appears on the skin in whatever design you like. Once the tattoo is there, you can cast its spell, requiring no material components. The tattoo glows faintly while you cast the spell and for the spell's duration. Once the spell ends, the tattoo vanishes from your skin.",
"The Ability modifier for this spell is +4; the Save DC is 15 and the attack bonus is +7."
]
},
{
"name": "Spellwrought Tattoo (4th Level)",
"source": "UA2020SpellsAndMagicTattoos",
"page": 11,
"rarity": "rare",
"wondrous": true,
"tattoo": true,
"entries": [
"This tattoo contains a single spell, wrought on your skin by a magic needle. To use the tattoo, you must hold the needle against your skin where you want the tattoo to appear and speak the command word. The needle turns into the ink that becomes the tattoo, which appears on your skin. Once the tattoo is on your skin, you can cast its spell, requiring no material components. The tattoo glows faintly while you cast the spell and for the spell's duration. Once the spell ends, the tattoo vanishes from your skin.",
"A spell cast from this tattoo has a spellcasting ability modifier of +4, a save DC of 15 and an attack bonus of +7."
]
},
{
"name": "Spellwrought Tattoo (5th Level)",
"source": "TCE",
"page": 135,
"rarity": "rare",
"wondrous": true,
"tattoo": true,
"entries": [
"Produced by a special needle, this magic tattoo contains a single {@filter 5th level spell|spells|level=5}, wrought on your skin by a magic needle. To use the tattoo, you must hold the needle against your skin and speak the command word. The needle turns into ink that becomes the tattoo, which appears on the skin in whatever design you like. Once the tattoo is there, you can cast its spell, requiring no material components. The tattoo glows faintly while you cast the spell and for the spell's duration. Once the spell ends, the tattoo vanishes from your skin.",
"The Ability modifier for this spell is +5; the Save DC is 17 and the attack bonus is +9."
]
},
{
"name": "Spellwrought Tattoo (5th Level)",
"source": "UA2020SpellsAndMagicTattoos",
"page": 11,
"rarity": "rare",
"wondrous": true,
"tattoo": true,
"entries": [
"This tattoo contains a single spell, wrought on your skin by a magic needle. To use the tattoo, you must hold the needle against your skin where you want the tattoo to appear and speak the command word. The needle turns into the ink that becomes the tattoo, which appears on your skin. Once the tattoo is on your skin, you can cast its spell, requiring no material components. The tattoo glows faintly while you cast the spell and for the spell's duration. Once the spell ends, the tattoo vanishes from your skin.",
"A spell cast from this tattoo has a spellcasting ability modifier of +5, a save DC of 17 and an attack bonus of +9."
]
},
{
"name": "Spellwrought Tattoo (Cantrip)",
"source": "TCE",
"page": 135,
"rarity": "common",
"wondrous": true,
"tattoo": true,
"entries": [
"Produced by a special needle, this magic tattoo contains a single {@filter cantrip|spells|level=0}, wrought on your skin by a magic needle. To use the tattoo, you must hold the needle against your skin and speak the command word. The needle turns into ink that becomes the tattoo, which appears on the skin in whatever design you like. Once the tattoo is there, you can cast its spell, requiring no material components. The tattoo glows faintly while you cast the spell and for the spell's duration. Once the spell ends, the tattoo vanishes from your skin.",
"The Ability modifier for this spell is +3; the Save DC is 13 and the attack bonus is +5."
]
},
{
"name": "Spellwrought Tattoo (Cantrip)",
"source": "UA2020SpellsAndMagicTattoos",
"page": 11,
"rarity": "common",
"wondrous": true,
"tattoo": true,
"entries": [
"This tattoo contains a single spell, wrought on your skin by a magic needle. To use the tattoo, you must hold the needle against your skin where you want the tattoo to appear and speak the command word. The needle turns into the ink that becomes the tattoo, which appears on your skin. Once the tattoo is on your skin, you can cast its spell, requiring no material components. The tattoo glows faintly while you cast the spell and for the spell's duration. Once the spell ends, the tattoo vanishes from your skin.",
"A spell cast from this tattoo has a spellcasting ability modifier of +3, a save DC of 13, and an attack bonus of +5."
]
},
{
"name": "Sphere of Annihilation",
"source": "DMG",
"page": 201,
"srd": true,
"tier": "major",
"rarity": "legendary",
"wondrous": true,
"entries": [
"This 2-foot-diameter black sphere is a hole in the multiverse, hovering in space and stabilized by a magical field surrounding it.",
"The sphere obliterates all matter it passes through and all matter that passes through it. Artifacts are the exception. Unless an artifact is susceptible to damage from a sphere of annihilation, it passes through the sphere unscathed. Anything else that touches the sphere but isn't wholly engulfed and obliterated by it takes {@damage 4d10} force damage.",
"The sphere is stationary until someone controls it. If you are within 60 feet of an uncontrolled sphere, you can use an action to make a DC 25 Intelligence ({@skill Arcana}) check. On a success, the sphere levitates in one direction of your choice, up to a number of feet equal to 5 × your Intelligence modifier (minimum 5 feet). On a failure, the sphere moves 10 feet toward you. A creature whose space the sphere enters must succeed on a DC 13 Dexterity saving throw or be touched by it, taking {@damage 4d10} force damage.",
"If you attempt to control a sphere that is under another creature's control, you make an Intelligence ({@skill Arcana}) check contested by the other creature's Intelligence ({@skill Arcana}) check. The winner of the contest gains control of the sphere and can levitate it as normal.",
"If the sphere comes into contact with a planar portal, such as that created by the {@spell gate} spell, or an extradimensional space, such as that within a {@item portable hole}, the DM determines randomly what happens, using the following table.",
{
"type": "table",
"colLabels": [
"{@dice d100}",
"Result"
],
"colStyles": [
"col-1 text-center",
"col-11"
],
"rows": [
[
"01-50",
"The sphere is destroyed."
],
[
"51-85",
"The sphere moves through the portal or into the extradimensional space."
],
[
"86-00",
"A spatial rift sends each creature and object within 180 feet of the sphere, including the sphere, to a random plane of existence."
]
]
}
],
"lootTables": [
"Magic Item Table I"
]
},
{
"name": "Spider Staff",
"source": "LMoP",
"page": 53,
"rarity": "rare",
"reqAttune": true,
"weight": 6,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"recharge": "dusk",
"rechargeAmount": "{@dice 1d6 + 4}",
"charges": 10,
"staff": true,
"entries": [
"The top of this black, adamantine staff is shaped like a spider. The staff weighs 6 pounds. You must be attuned to the staff to gain its benefits and cast its spells. The staff can be wielded as a quarterstaff. It deals {@dice 1d6} extra poison damage on a hit when used to make a weapon attack.",
"The staff has 10 charges, which are used to fuel the spells within it. With the staff in hand, you can use your action to cast one of the following spells from the staff if the spell is on your class's spell list: {@spell spider climb} (1 charge) or {@spell web} (2 charges, spell save DC 15). No components are required.",
"The staff regains {@dice 1d6 + 4} expended charges each day at dusk. If you expend the staff's last charge, roll a {@dice d20}. On a 1, the staff crumbles to dust and is destroyed."
],
"attachedSpells": [
"spider climb",
"web"
]
},
{
"name": "Spies' Murmur",
"source": "GGR",
"page": 181,
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"entries": [
"This headpiece, crafted from dark metal, is worn curved around the ear. If you know a creature wearing another Spies' Murmur and that creature is within 1 mile of you, you can communicate telepathically with each other. As a bonus action, you can allow that creature to hear everything you hear for 1 hour. You can end this effect as a bonus action, and it ends if you're {@condition incapacitated}."
]
},
{
"name": "Spinel",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 10000,
"entries": [
"A transparent red, red-brown, or deep green gemstone."
]
},
{
"name": "Sprig of Mistletoe",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "SCF",
"scfType": "druid",
"rarity": "none",
"value": 100
},
{
"name": "Spyglass",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 1,
"value": 100000,
"entries": [
"Objects viewed through a spyglass are magnified to twice their size."
]
},
{
"name": "Spyglass of Clairvoyance",
"source": "AI",
"page": 19,
"rarity": "common",
"wondrous": true,
"entries": [
"At rank 2, your Head Office-supplied spyglass becomes a common magic item. As an action, you can look through the spyglass of clairvoyance at a location within 1 mile of you that is obstructing your view, such as a mountain, castle, or forest. You must then succeed on a DC 15 Wisdom check using {@item cartographer's tools|phb} to map the natural terrain found within three miles of that chosen point. You do not gain any knowledge of creatures, structures, or anything other than natural terrain. This property of the spyglass cannot be used again until the next dawn."
]
},
{
"name": "Squid Ship",
"source": "AAG",
"page": 0,
"otherSources": [
{
"source": "SjA"
}
],
"type": "SPC",
"rarity": "none",
"value": 2500000,
"crew": 13,
"vehAc": 15,
"vehHp": 300,
"vehDmgThresh": 15,
"vehSpeed": 3.5,
"capCargo": 20,
"entries": [
"Among the oldest types of spelljamming vessels, squid ships are popular with privateers and are often used as patrol ships. Standard weapons on a squid ship include a forward-mounted mangonel, two aft-mounted ballistae, and a reinforced bow for ramming. The tentacles that extend from the bow account for nearly half the ship's keel length.",
"Squid ships can float and sail on water, and they can land on the ground."
],
"seeAlsoVehicle": [
"Squid Ship|AAG"
]
},
{
"name": "Stabling (per day)",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TAH",
"rarity": "none",
"value": 50
},
{
"name": "Staff of Adornment",
"source": "XGE",
"page": 139,
"tier": "minor",
"rarity": "common",
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"staff": true,
"entries": [
"If you place an object weighing no more than 1 pound (such as a shard of crystal, an egg, or a stone) above the tip of the staff while holding it, the object floats an inch from the staff's tip and remains there until it is removed or until the staff is no longer in your possession. The staff can have up to three such objects floating over its tip at any given time. While holding the staff, you can make one or more of the objects slowly spin or turn in place."
]
},
{
"name": "Staff of Birdcalls",
"source": "XGE",
"page": 139,
"tier": "minor",
"rarity": "common",
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 4}",
"charges": 10,
"staff": true,
"entries": [
"This wooden staff is decorated with bird carvings. It has 10 charges. While holding it, you can use an action to expend 1 charge from the staff and cause it to create one of the following sounds out to a range of 60 feet: a finch's chirp, a raven's caw, a duck's quack, a chicken's cluck, a goose's honk, a loon's call, a turkey's gobble, a seagull's cry, an owl's hoot, or an eagle's shriek.",
"The staff regains {@dice 1d6 + 4} expended charges daily at dawn. If you expend the last charge, roll a {@dice d20}. On a 1, the staff explodes in a harmless cloud of bird feathers and is lost forever."
]
},
{
"name": "Staff of Charming",
"source": "DMG",
"page": 201,
"srd": true,
"tier": "major",
"rarity": "rare",
"reqAttune": "by a bard, cleric, druid, sorcerer, warlock, or wizard",
"reqAttuneTags": [
{
"class": "bard"
},
{
"class": "cleric"
},
{
"class": "druid"
},
{
"class": "sorcerer"
},
{
"class": "warlock"
},
{
"class": "wizard"
}
],
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"staff": true,
"entries": [
"While holding this staff, you can use an action to expend 1 of its 10 charges to cast {@spell charm person}, {@spell command}, or {@spell comprehend languages} from it using your spell save DC. The staff can also be used as a magic quarterstaff.",
"If you are holding the staff and fail a saving throw against an enchantment spell that targets only you, you can turn your failed save into a successful one. You can't use this property of the staff again until the next dawn. If you succeed on a save against an enchantment spell that targets only you, with or without the staff's intervention, you can use your reaction to expend 1 charge from the staff and turn the spell back on its caster as if you had cast the spell.",
"The staff regains {@dice 1d8 + 2} expended charges daily at dawn. If you expend the last charge, roll a {@dice d20}. On a 1, the staff becomes a nonmagical quarterstaff."
],
"attachedSpells": [
"charm person",
"command",
"comprehend languages"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Staff of Defense",
"source": "LMoP",
"page": 53,
"rarity": "rare",
"reqAttune": true,
"weight": 3,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"bonusAc": "+1",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 4}",
"charges": 10,
"staff": true,
"entries": [
"This slender, hollow staff is made of glass yet is as strong as oak. It weighs 3 pounds. You must be attuned to the staff to gain its benefits and cast its spells.",
"While holding the staff, you have a +1 bonus to your Armor Class.",
"The staff has 10 charges, which are used to fuel the spells within it. With the staff in hand, you can use your action to cast one of the following spells from the staff if the spell is on your class's spell list: {@spell mage armor} (1 charge) or {@spell shield} (2 charges). No components are required.",
"The staff regains {@dice 1d6 + 4} expended charges each day at dawn. If you expend the staff's last charge, roll a {@dice d20}. On a 1, the staff shatters and is destroyed."
],
"attachedSpells": [
"mage armor",
"shield"
]
},
{
"name": "Staff of Dunamancy",
"source": "EGW",
"page": 270,
"rarity": "very rare",
"reqAttune": "by a wizard",
"reqAttuneTags": [
{
"class": "wizard"
}
],
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 4}",
"charges": 10,
"staff": true,
"entries": [
"This staff of polished gray wood bears numerous runes carved along its length. The staff has 10 charges and regains {@dice 1d6 + 4} expended charges daily at dawn. If you expend the last charge, roll a {@dice d20}. On a 1, the staff turns into dust and is destroyed.",
"While holding the staff, you can use an action to expend 2 or more of its charges to cast one of the following spells from it, using your spell save DC and spell attack bonus: {@spell fortune's favor|egw} (2 charges), {@spell pulse wave|egw} (3 charges), or {@spell gravity sinkhole|egw} (4 charges).",
{
"type": "entries",
"name": "New Possibility",
"entries": [
"If you are holding the staff and fail a saving throw against a spell that targets only you, you can turn your failed save into a successful one. This property can't be used again until the next dawn."
]
}
],
"attachedSpells": [
"fortune's favor|egw",
"pulse wave|egw",
"gravity sinkhole|egw"
]
},
{
"name": "Staff of Fate",
"source": "CM",
"page": 183,
"srd": true,
"baseItem": "quarterstaff|PHB",
"type": "M",
"rarity": "very rare",
"reqAttune": true,
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"bonusWeapon": "+3",
"recharge": "special",
"charges": 6,
"staff": true,
"weapon": true,
"entries": [
"This transparent crystal staff can be wielded as a magic quarterstaff that grants a +3 bonus to attack and damage rolls made with it.",
{
"type": "entries",
"name": "Altered Outcome",
"entries": [
"The staff has 6 charges. As a bonus action, you can expend 1 of the staff's charges to give yourself or one other creature that you can see a {@dice d4}. The recipient can roll this {@dice d4} and add the number rolled to one ability check, attack roll, damage roll, or saving throw it makes before the start of your next turn. If this extra die is not used before then, it is lost.",
"If you expend the staff's last charge, roll a {@dice d20}. On a roll of 9 or lower, the staff becomes a nonmagical quarterstaff that breaks the first time it scores a hit and deals damage. On a roll of 10 or higher, the staff regains {@dice 1d6} of its expended charges."
]
}
]
},
{
"name": "Staff of Fire",
"source": "DMG",
"page": 201,
"srd": true,
"resist": [
"fire"
],
"tier": "major",
"rarity": "very rare",
"reqAttune": "by a druid, sorcerer, warlock, or wizard",
"reqAttuneTags": [
{
"class": "druid"
},
{
"class": "sorcerer"
},
{
"class": "warlock"
},
{
"class": "wizard"
}
],
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 4}",
"charges": 10,
"staff": true,
"entries": [
"You have resistance to fire damage while you hold this staff.",
"The staff has 10 charges. While holding it, you can use an action to expend 1 or more of its charges to cast one of the following spells from it, using your spell save DC: {@spell burning hands} (1 charge), {@spell fireball} (3 charges), or {@spell wall of fire} (4 charges).",
"The staff regains {@dice 1d6 + 4} expended charges daily at dawn. If you expend the last charge, roll a {@dice d20}. On a 1, the staff blackens, crumbles into cinders, and is destroyed."
],
"attachedSpells": [
"burning hands",
"fireball",
"wall of fire"
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Staff of Flowers",
"source": "XGE",
"page": 139,
"tier": "minor",
"rarity": "common",
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 4}",
"charges": 10,
"staff": true,
"entries": [
"This wooden staff has 10 charges. While holding it, you can use an action to expend 1 charge from the staff and cause a flower to sprout from a patch of earth or soil within 5 feet of you, or from the staff itself. Unless you choose a specific kind of flower, the staff creates a mildscented daisy. The flower is harmless and nonmagical, and it grows or withers as a normal flower would. The staff regains {@dice 1d6 + 4} expended charges daily at dawn. If you expend the last charge, roll a {@dice d20}. On a 1, the staff turns into flower petals and is lost forever."
]
},
{
"name": "Staff of Frost",
"source": "DMG",
"page": 202,
"srd": true,
"resist": [
"cold"
],
"tier": "major",
"rarity": "very rare",
"reqAttune": "by a druid, sorcerer, warlock, or wizard",
"reqAttuneTags": [
{
"class": "druid"
},
{
"class": "sorcerer"
},
{
"class": "warlock"
},
{
"class": "wizard"
}
],
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 4}",
"charges": 10,
"staff": true,
"entries": [
"You have resistance to cold damage while you hold this staff.",
"The staff has 10 charges. While holding it, you can use an action to expend 1 or more of its charges to cast one of the following spells from it, using your spell save DC: {@spell cone of cold} (5 charges), {@spell fog cloud} (1 charge), {@spell ice storm} (4 charges), or {@spell wall of ice} (4 charges).",
"The staff regains {@dice 1d6 + 4} expended charges daily at dawn. If you expend the last charge, roll a {@dice d20}. On a 1. the staff turns to water and is destroyed."
],
"attachedSpells": [
"cone of cold",
"fog cloud",
"ice storm",
"wall of ice"
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Staff of Healing",
"source": "DMG",
"page": 202,
"srd": true,
"tier": "major",
"rarity": "rare",
"reqAttune": "by a bard, cleric, or druid",
"reqAttuneTags": [
{
"class": "bard"
},
{
"class": "cleric"
},
{
"class": "druid"
}
],
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 4}",
"charges": 10,
"staff": true,
"entries": [
"This staff has 10 charges. While holding it, you can use an action to expend 1 or more of its charges to cast one of the following spells from it, using your spell save DC and spellcasting ability modifier: {@spell cure wounds} (1 charge per spell level, up to 4th), {@spell lesser restoration} (2 charges). or {@spell mass cure wounds} (5 charges).",
"The staff regains {@dice 1d6 + 4} expended charges daily at dawn. If you expend the last charge, roll a {@dice d20}. On a 1. the staff vanishes in a flash of light, lost forever."
],
"attachedSpells": [
"lesser restoration",
"mass cure wounds",
"cure wounds"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Staff of Power",
"source": "DMG",
"page": 202,
"srd": true,
"tier": "major",
"rarity": "very rare",
"reqAttune": "by a sorcerer, warlock, or wizard",
"reqAttuneTags": [
{
"class": "sorcerer"
},
{
"class": "warlock"
},
{
"class": "wizard"
}
],
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"bonusWeapon": "+2",
"bonusSpellAttack": "+2",
"bonusAc": "+2",
"bonusSavingThrow": "+2",
"recharge": "dawn",
"rechargeAmount": "{@dice 2d8 + 4}",
"charges": 20,
"staff": true,
"entries": [
"This staff can be wielded as a magic quarterstaff that grants a +2 bonus to attack and damage rolls made with it. While holding it, you gain a +2 bonus to Armor Class, saving throws, and spell attack rolls.",
"The staff has 20 charges for the following properties. The staff regains {@dice 2d8 + 4} expended charges daily at dawn. If you expend the last charge, roll a {@dice d20}. On a 1, the staff retains its +2 bonus to attack and damage roll but loses all other properties. On a 20, the staff regain {@dice 1d8 + 2} charges.",
{
"name": "Power Strike",
"type": "entries",
"entries": [
"When you hit with a melee attack using the staff, you can expend 1 charge to deal an extra {@damage 1d6} force damage to the target."
]
},
{
"type": "entries",
"name": "Spells",
"entries": [
"While holding this staff, you can use an action to expend 1 or more of its charges to cast one of the following spells from it, using your spell save DC and spell attack bonus: {@spell cone of cold} (5 charges), {@spell fireball} (5th-level version, 5 charges), {@spell globe of invulnerability} (6 charges), {@spell hold monster} (5 charges), {@spell levitate} (2 charges). {@spell lightning bolt} (5th-level version, 5 charges), {@spell magic missile} (1 charge), {@spell ray of enfeeblement} (1 charge), or {@spell wall of force} (5 charges)."
]
},
{
"name": "Retributive Strike",
"type": "entries",
"entries": [
"You can use an action to break the staff over your knee or against a solid surface, performing a retributive strike. The staff is destroyed and releases its remaining magic in an explosion that expands to fill a 30-foot-radius sphere centered on it.",
"You have a {@chance 50} chance to instantly travel to a random plane of existence, avoiding the explosion. If you fail to avoid the effect, you take force damage equal to 16 × the number of charges in the staff. Every other creature in the area must make a DC 17 Dexterity saving throw. On a failed save, a creature takes an amount of damage based on how far away it is from the point of origin, as shown in the following table. On a successful save, a creature takes half as much damage.",
{
"type": "table",
"colLabels": [
"Distance from Origin",
"Effect"
],
"colStyles": [
"col-3",
"col-9"
],
"rows": [
[
"10 ft. away or closer",
"8 × the number of charges in the staff"
],
[
"11 to 20 ft. away",
"6 × the number of charges in the staff"
],
[
"21 to 30 ft. away",
"4 × the number of charges in the staff"
]
]
}
]
}
],
"attachedSpells": [
"cone of cold",
"fireball",
"globe of invulnerability",
"hold monster",
"levitate",
"lightning bolt",
"magic missile",
"ray of enfeeblement",
"wall of force"
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Staff of Striking",
"source": "DMG",
"page": 203,
"srd": true,
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"bonusWeapon": "+3",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 4}",
"charges": 10,
"staff": true,
"entries": [
"This staff can be wielded as a magic quarterstaff that grants a +3 bonus to attack and damage rolls made with it.",
"The staff has 10 charges. When you hit with a melee attack using it, you can expend up to 3 of its charges. For each charge you expend, the target takes an extra {@damage 1d6} force damage. The staff regains {@dice 1d6 + 4} expended charges daily at dawn. If you expend the last charge, roll a {@dice d20}. On a 1, the staff becomes a nonmagical quarterstaff."
],
"lootTables": [
"Magic Item Table H"
]
},
{
"name": "Staff of Swarming Insects",
"source": "DMG",
"page": 203,
"srd": true,
"tier": "major",
"rarity": "rare",
"reqAttune": "by a bard, cleric, druid, sorcerer, warlock, or wizard",
"reqAttuneTags": [
{
"class": "bard"
},
{
"class": "cleric"
},
{
"class": "druid"
},
{
"class": "sorcerer"
},
{
"class": "warlock"
},
{
"class": "wizard"
}
],
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 4}",
"charges": 10,
"staff": true,
"entries": [
"This staff has 10 charges and regains {@dice 1d6 + 4} expended charges daily at dawn. If you expend the last charge, roll a {@dice d20}. On a 1, a {@creature swarm of insects} consumes and destroys the staff, then disperses.",
{
"name": "Spells",
"type": "entries",
"entries": [
"While holding the staff, you can use an action to expend some of its charges to cast one of the following spells from it, using your spell save DC: {@spell giant insect} (4 charges) or {@spell insect plague} (5 charges)."
]
},
{
"name": "Insect Cloud",
"type": "entries",
"entries": [
"While holding the staff, you can use an action and expend 1 charge to cause a swarm of harmless flying insects to spread out in a 30-foot radius from you. The insects remain for 10 minutes, making the area heavily obscured for creatures other than you. The swarm moves with you, remaining centered on you. A wind of at least 10 miles per hour disperses the swarm and ends the effect."
]
}
],
"attachedSpells": [
"giant insect",
"insect plague"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Staff of the Adder",
"source": "DMG",
"page": 203,
"tier": "major",
"rarity": "uncommon",
"reqAttune": "by a cleric, druid, or warlock",
"reqAttuneTags": [
{
"class": "cleric"
},
{
"class": "druid"
},
{
"class": "warlock"
}
],
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"staff": true,
"entries": [
"You can use a bonus action to speak this staff's command word and make the head of the staff become that of an animate poisonous snake for 1 minute. By using another bonus action to speak the command word again, you return the staff to its normal inanimate form.",
"You can make a melee attack using the snake head, which has a reach of 5 feet. Your proficiency bonus applies to the attack roll. On a hit, the target takes {@damage 1d6} piercing damage and must succeed on a DC 15 Constitution saving throw or take {@damage 3d6} poison damage.",
"The snake head can be attacked while it is animate. It has an Armor Class of 15 and 20 hit points. If the head drops to 0 hit points, the staff is destroyed. As long as it's not destroyed, the staff regains all lost hit points when it reverts to its inanimate form."
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Staff of the Forgotten One",
"source": "ToA",
"page": 208,
"rarity": "artifact",
"reqAttune": "by a sorcerer, warlock, or wizard",
"reqAttuneTags": [
{
"class": "sorcerer"
},
{
"class": "warlock"
},
{
"class": "wizard"
}
],
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"bonusWeapon": "+3",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d4 + 3}",
"charges": 7,
"staff": true,
"entries": [
"This crooked staff is carved from bone and topped with the skull of a forgotten {@creature archmage} whom {@creature Acererak|ToA} destroyed long ago. Etched into the skull's forehead is {@creature acererak|ToA|Acererak's} rune, which is known on many worlds as a sign of death.",
{
"type": "entries",
"name": "Beneficial Properties",
"entries": [
"While the staff is on your person, you gain the following benefits:",
{
"type": "list",
"items": [
"Your proficiency bonus to Intelligence ({@skill Arcana}) and Intelligence ({@skill History}) checks is doubled.",
"You can't be {@condition blinded}, {@condition charmed}, {@condition deafened}, {@condition frightened}, {@condition petrified}, or {@condition stunned}.",
"Undead with a challenge rating of 2 or lower will neither threaten nor attack you unless you harm them.",
"You can wield the staff as a +3 quarterstaff that deals an extra 10 ({@damage 3d6}) necrotic damage on a hit."
]
}
]
},
{
"type": "entries",
"name": "Invoke Curse",
"entries": [
"The Staff of the Forgotten One has 7 charges and regains {@dice 1d4 + 3} expended charges daily at dawn. While holding the staff, you can use an action to expend 1 charge and target one creature you can see within 60 feet of you. The target must succeed on a Constitution saving throw (using your spell save DC) or be cursed. While cursed in this way, the target can't regain hit points and has vulnerability to necrotic damage. A {@spell greater restoration}, {@spell remove curse}, or similar spell ends the curse on the target."
]
},
{
"type": "entries",
"name": "The Forgotten One",
"entries": [
"The bodiless life force of a dead {@creature archmage} empowers the staff and is imprisoned within it. The rune carved into the staff's skull protects {@creature Acererak|ToA} from this spirit's vengeance. Each time a creature other than {@creature Acererak|ToA} expends any of the staff's charges, there is a {@chance 50} chance that the life force tries to possess the staff wielder. The wielder must succeed on a DC 20 Charisma saving throw or be possessed, becoming an NPC under the DM's control. If the intruding life force is targeted by magic such as a {@spell dispel evil and good} spell, it becomes trapped in the staff once more. Once it takes control of another creature, the insane spirit of the dead {@creature archmage} attempts to destroy the staff."
]
},
{
"type": "entries",
"name": "Destroying the Staff",
"entries": [
"A creature in possession of the staff can use an action to break it over one knee or a solid surface. The staff is destroyed and releases its remaining magic in an explosion that expands to fill a 30-foot-radius sphere centered on it. Each creature in the area must make a DC 18 Dexterity saving throw, taking 132 ({@damage 24d10}) force damage on a failed save, or half as much damage on a successful one. When the staff is destroyed, the life force of the Forgotten One is released to the afterlife. Where it goes is anyone's guess."
]
}
]
},
{
"name": "Staff of the Ivory Claw",
"source": "EGW",
"page": 270,
"rarity": "rare",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"bonusSpellAttack": "+1",
"staff": true,
"entries": [
"This gray-and-cerulean staff is topped with a small dragon claw carved from ivory. While holding the staff, you gain a +1 bonus to spell attack rolls. Whenever you score a critical hit with a spell attack, the target takes an extra {@damage 3d6} radiant damage."
]
},
{
"name": "Staff of the Magi",
"source": "DMG",
"page": 203,
"srd": true,
"tier": "major",
"rarity": "legendary",
"reqAttune": "by a sorcerer, warlock, or wizard",
"reqAttuneTags": [
{
"class": "sorcerer"
},
{
"class": "warlock"
},
{
"class": "wizard"
}
],
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"bonusWeapon": "+2",
"bonusSpellAttack": "+2",
"recharge": "dawn",
"rechargeAmount": "{@dice 4d6 + 2}",
"charges": 50,
"staff": true,
"entries": [
"This staff can be wielded as a magic quarterstaff that grants a +2 bonus to attack and damage rolls made with it. While you hold it, you gain a +2 bonus to spell attack rolls.",
"The staff has 50 charges for the following properties. It regains {@dice 4d6 + 2} expended charges daily at dawn. If you expend the last charge, roll a {@dice d20}. On a 20, the staff regains {@dice 1d12 + 1} charges.",
{
"name": "Spell Absorption",
"type": "entries",
"entries": [
"While holding the staff, you have advantage on saving throws against spells. In addition, you can use your reaction when another creature casts a spell that targets only you. If you do, the staff absorbs the magic of the spell, canceling its effect and gaining a number of charges equal to the absorbed spell's level. However, if doing so brings the staff's total number of charges above 50, the staff explodes as if you activated its retributive strike (see below)."
]
},
{
"name": "Spells",
"type": "entries",
"entries": [
"While holding the staff, you can use an action to expend some of its charges to cast one of the following spells from it, using your spell save DC and spellcasting ability: {@spell conjure elemental} (7 charges), {@spell dispel magic} (3 charges), {@spell fireball} (7th-level version, 7 charges), {@spell flaming sphere} (2 charges), {@spell ice storm} (4 charges), {@spell invisibility} (2 charges), {@spell knock} (2 charges), {@spell lightning bolt} (7th-level version, 7 charges), {@spell passwall} (5 charges), {@spell plane shift} (7 charges), {@spell telekinesis} (5 charges), {@spell wall of fire} (4 charges), or {@spell web} (2 charges).",
"You can also use an action to cast one of the following spells from the staff without using any charges: {@spell arcane lock}, {@spell detect magic}, {@spell enlarge/reduce}, {@spell light}, {@spell mage hand}, or {@spell protection from evil and good}."
]
},
{
"name": "Retributive Strike",
"type": "entries",
"entries": [
"You can use an action to break the staff over your knee or against a solid surface, performing a retributive strike. The staff is destroyed and releases its remaining magic in an explosion that expands to fill a 30-foot-radius sphere centered on it.",
"You have a {@chance 50} chance to instantly travel to a random plane of existence, avoiding the explosion. If you fail to avoid the effect, you take force damage equal to 16 × the number of charges in the staff. Every other creature in the area must make a DC 17 Dexterity saving throw. On a failed save, a creature takes an amount of damage based on how far away it is from the point of origin, as shown in the following table. On a successful save, a creature takes half as much damage."
]
},
{
"type": "table",
"colLabels": [
"Distance from Origin",
"Damage"
],
"colStyles": [
"col-3",
"col-9"
],
"rows": [
[
"10 ft. away or closer",
"8 × the number of charges in the staff"
],
[
"11 to 20 ft. away",
"6 × the number of charges in the staff"
],
[
"21 to 30 ft. away",
"4 × the number of charges in the staff"
]
]
}
],
"attachedSpells": [
"conjure elemental",
"dispel magic",
"fireball",
"flaming sphere",
"ice storm",
"invisibility",
"knock",
"lightning bolt",
"passwall",
"plane shift",
"telekinesis",
"wall of fire",
"web",
"arcane lock",
"detect magic",
"enlarge/reduce",
"light",
"mage hand",
"protection from evil and good"
],
"lootTables": [
"Magic Item Table I"
],
"hasFluffImages": true
},
{
"name": "Staff of the Python",
"source": "DMG",
"page": 204,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"reqAttune": "by a cleric, druid, or warlock",
"reqAttuneTags": [
{
"class": "cleric"
},
{
"class": "druid"
},
{
"class": "warlock"
}
],
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"staff": true,
"entries": [
"You can use an action to speak this staff's command word and throw the staff on the ground within 10 feet of you. The staff becomes a {@creature giant constrictor snake} under your control and acts on its own initiative count. By using a bonus action to speak the command word again, you return the staff to its normal form in a space formerly occupied by the snake.",
"On your turn, you can mentally command the snake if it is within 60 feet of you and you aren't {@condition incapacitated}. You decide what action the snake takes and where it moves during its next turn, or you can issue it a general command, such as to attack your enemies or guard a location.",
"If the snake is reduced to 0 hit points, it dies and reverts to its staff form. The staff then shatters and is destroyed. If the snake reverts to staff form before losing all its hit points, it regains all of them."
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Staff of the Woodlands",
"source": "DMG",
"page": 204,
"srd": true,
"tier": "major",
"rarity": "rare",
"reqAttune": "by a druid",
"reqAttuneTags": [
{
"class": "druid"
}
],
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"bonusWeapon": "+2",
"bonusSpellAttack": "+2",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 4}",
"charges": 10,
"staff": true,
"entries": [
"This staff can be wielded as a magic quarterstaff that grants a +2 bonus to attack and damage rolls made with it. While holding it, you have a +2 bonus to spell attack rolls.",
"The staff has 10 charges for the following properties. It regains {@dice 1d6 + 4} expended charges daily at dawn. If you expend the last charge, roll a {@dice d20}. On a 1, the staff loses its properties and becomes a nonmagical quarterstaff.",
{
"type": "entries",
"name": "Spells",
"entries": [
"You can use an action to expend 1 or more of the staff's charges to cast one of the following spells from it, using your spell save DC: {@spell animal friendship} (1 charge), {@spell awaken} (5 charges), {@spell barkskin} (2 charges), {@spell locate animals or plants} (2 charges), {@spell speak with animals} (1 charge), {@spell speak with plants} (3 charges), or {@spell wall of thorns} (6 charges).",
"You can also use an action to cast the {@spell pass without trace} spell from the staff without using any charges."
]
},
{
"type": "entries",
"name": "Tree Form",
"entries": [
"You can use an action to plant one end of the staff in fertile earth and expend 1 charge to transform the staff into a healthy tree. The tree is 60 feet tall and has a 5-foot-diameter trunk, and its branches at the top spread out in a 20-foot radius. The tree appears ordinary but radiates a faint aura of transmutation magic if targeted by {@spell detect magic}. While touching the tree and using another action to speak its command word, you return the staff to its normal form. Any creature in the tree falls when it reverts to a staff."
]
}
],
"attachedSpells": [
"pass without trace",
"animal friendship",
"awaken",
"barkskin",
"locate animals or plants",
"speak with animals",
"speak with plants",
"wall of thorns"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Staff of Thunder and Lightning",
"source": "DMG",
"page": 204,
"srd": true,
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"bonusWeapon": "+2",
"staff": true,
"entries": [
"This staff can be wielded as a magic quarterstaff that grants a +2 bonus to attack and damage rolls made with it. It also has the following additional properties. When one of these properties is used, it can't be used again until the next dawn.",
{
"type": "entries",
"name": "Lightning",
"entries": [
"When you hit with a melee attack using the staff, you can cause the target to take an extra {@damage 2d6} lightning damage."
]
},
{
"type": "entries",
"name": "Thunder",
"entries": [
"When you hit with a melee attack using the staff, you can cause the staff to emit a crack of thunder, audible out to 300 feet. The target you hit must succeed on a DC 17 Constitution saving throw or become {@condition stunned} until the end of your next turn."
]
},
{
"type": "entries",
"name": "Lightning Strike",
"entries": [
"You can use an action to cause a bolt of lightning to leap from the staff's tip in a line that is 5 feet wide and 120 feet long. Each creature in that line must make a DC 17 Dexterity saving throw, taking {@damage 9d6} lightning damage on a failed save, or half as much damage on a successful one."
]
},
{
"type": "entries",
"name": "Thunderclap",
"entries": [
"You can use an action to cause the staff to issue a deafening thunderclap, audible out to 600 feet. Each creature within 60 feet of you (not including you) must make a DC 17 Constitution saving throw. On a failed save, a creature takes {@damage 2d6} thunder damage and becomes {@condition deafened} for 1 minute. On a successful save, a creature takes half damage and isn't {@condition deafened}."
]
},
{
"type": "entries",
"name": "Thunder and Lightning",
"entries": [
"You can use an action to use the Lightning Strike and Thunderclap properties at the same time. Doing so doesn't expend the daily use of those properties, only the use of this one."
]
}
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Staff of Withering",
"source": "DMG",
"page": 205,
"srd": true,
"tier": "major",
"rarity": "rare",
"reqAttune": "by a cleric, druid, or warlock",
"reqAttuneTags": [
{
"class": "cleric"
},
{
"class": "druid"
},
{
"class": "warlock"
}
],
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"staff": true,
"entries": [
"This staff has 3 charges and regains {@dice 1d3} expended charges daily at dawn.",
"The staff can be wielded as a magic quarterstaff. On a hit, it deals damage as a normal quarterstaff, and you can expend 1 charge to deal an extra {@damage 2d10} necrotic damage to the target. In addition, the target must succeed on a DC 15 Constitution saving throw or have disadvantage for 1 hour on any ability check or saving throw that uses Strength or Constitution."
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Star Moth",
"source": "AAG",
"page": 0,
"type": "SPC",
"rarity": "none",
"value": 4000000,
"crew": 13,
"vehAc": 13,
"vehHp": 400,
"vehDmgThresh": 15,
"vehSpeed": 5.5,
"capCargo": 30,
"entries": [
"Star moths are ships constructed and flown by astral elves, who don't like it when these ships end up in the hands of others.",
"A star moth's hull is fashioned from a grown and sculpted organic substance, and its enormous, decorative wings are made of shimmering crystal. The vessel is built for space travel, but its design does enable it to float on water or land safely on the ground. Standard weaponry includes two ballistae nestled in the star moth's eye holes and a mangonel mounted above the bridge."
],
"seeAlsoVehicle": [
"Star Moth|AAG"
]
},
{
"name": "Star rose quartz",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 5000,
"entries": [
"A translucent rosy stone with white star-shaped center gemstone."
]
},
{
"name": "Star Ruby",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 100000,
"entries": [
"A translucent ruby with white star-shaped center gemstone."
]
},
{
"name": "Star Sapphire",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 100000,
"entries": [
"A translucent blue sapphire with white star-shaped center gemstone."
]
},
{
"name": "Statuette of Saint Markovia",
"source": "CoS",
"page": 152,
"type": "OTH",
"rarity": "unknown (magic)",
"value": 25000,
"bonusSavingThrow": "+1",
"entries": [
"This golden statuette grants any good-aligned creature that carries it a +1 bonus to saving throws."
]
},
{
"name": "Steel",
"source": "WBtW",
"page": 214,
"baseItem": "longsword|PHB",
"type": "M",
"rarity": "very rare",
"reqAttune": "by a good-aligned creature",
"reqAttuneTags": [
{
"alignment": [
"G"
]
}
],
"sentient": true,
"weight": 3,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"bonusWeapon": "+2",
"sword": true,
"weapon": true,
"entries": [
"You have a +2 bonus to attack and damage rolls made with this magic weapon.",
{
"type": "entries",
"name": "Revivify",
"entries": [
"You can use an action to cast the {@spell revivify} spell from the sword. You must touch the target with the sword to cast the spell. Once this property of the weapon is used, it can't be used again until the next dawn."
]
},
{
"type": "entries",
"name": "Sentience",
"entries": [
"Steel is a sentient, lawful good longsword with an Intelligence of 8, a Wisdom of 11, and a Charisma of 15. It can see and hear out to a range of 60 feet. The sword can speak, read, and understand Common and Draconic. It frets over your well-being while you are attuned to it, and it doesn't like to back down from a fight."
]
}
],
"attachedSpells": [
"revivify"
],
"hasFluffImages": true
},
{
"name": "Steel Mirror",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 0.5,
"value": 500
},
{
"name": "Stirring Dragon Vessel",
"source": "FTD",
"_copy": {
"name": "Slumbering Dragon Vessel",
"source": "FTD",
"_preserve": {
"page": true
}
},
"rarity": "rare",
"entries": [
"This vessel can be a potion bottle, drinking horn, or other container meant to hold a liquid.",
"As a bonus action, if the vessel is empty, you can speak the command word to fill the vessel with one of the following (your choice): ale, olive oil, mead, a {@item potion of healing}, a {@item potion of greater healing}, a {@item potion of climbing}, or a {@item potion of fire breath}. Once this property is used, it can't be used until the next dawn. A potion you create in this way loses its magical properties if it isn't imbibed within 24 hours."
]
},
{
"name": "Stirring Dragon-Touched Focus",
"source": "FTD",
"_copy": {
"name": "Slumbering Dragon-Touched Focus",
"source": "FTD",
"_preserve": {
"page": true
}
},
"rarity": "rare",
"entries": [
"This wondrous item can be a scepter, an orb, an amulet, a crystal, or another finely crafted object. It typically incorporates imagery of dragons' wings, claws, teeth, or scales.",
"You have advantage on initiative rolls. While you are holding the focus, it can function as a spellcasting focus for all your spells.",
"The focus gains an additional property determined by the family of the dragon in whose hoard it became Stirring:",
{
"type": "list",
"style": "list-hang-notitle",
"items": [
{
"type": "item",
"name": "Chromatic",
"entries": [
"Whenever you use a spell slot to cast a spell that deals acid, cold, fire, lightning, or poison damage, roll a {@dice d6}, and you gain a bonus equal to the number rolled to one of the spell's damage rolls."
]
},
{
"type": "item",
"name": "Gem",
"entries": [
"Whenever you use a spell slot to cast a spell, you can immediately teleport to an unoccupied space you can see within 15 feet of you."
]
},
{
"type": "item",
"name": "Metallic",
"entries": [
"When a creature you can see within 30 feet of you makes a saving throw, you can use your reaction to give that creature advantage on the saving throw."
]
}
]
}
]
},
{
"name": "Stirring Scaled Ornament",
"source": "FTD",
"_copy": {
"name": "Slumbering Scaled Ornament",
"source": "FTD",
"_preserve": {
"page": true
}
},
"rarity": "rare",
"bonusAc": "+1",
"entries": [
"This ornament can be jewelry, a cloak, or another wearable accessory. It appears to be fashioned from a dragon's scale, tooth, or claw, or it incorporates images in those shapes.",
"You gain a +1 bonus to AC, and you can't be {@condition charmed} or {@condition frightened}. Moreover, each creature of your choice within 30 feet of you has advantage on saving throws it makes to avoid being {@condition charmed} or {@condition frightened} or to end those conditions on itself."
]
},
{
"name": "Stone of Controlling Earth Elementals",
"source": "DMG",
"page": 205,
"srd": true,
"tier": "major",
"rarity": "rare",
"wondrous": true,
"weight": 5,
"entries": [
"If the stone is touching the ground, you can use an action to speak its command word and summon an {@creature earth elemental}, as if you had cast the {@spell conjure elemental} spell. The stone can't be used this way again until the next dawn. The stone weighs 5 pounds."
],
"attachedSpells": [
"conjure elemental"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Stone of Creation",
"source": "AitFR-AVT",
"page": 10,
"otherSources": [
{
"source": "AitFR-DN",
"page": 14
},
{
"source": "AitFR-FCD",
"page": 12
}
],
"rarity": "artifact",
"reqAttune": true,
"wondrous": true,
"entries": [
"Rare legends and lore that speak of the Stone of Creation claim it fell to the Material Plane like a meteor from some distant edge of the Outer Planes or the Far Realm. Similar legends across various worlds, all describing stones that grow buildings and islands from magic, like a house from a seed, suggest the Stone of Creation is not a unique artifact—or that all the various pieces of it are derived from a single source of stone even larger than sages imagine.",
"The raw, black stone appears flaky like slate but is as hard as granite and marbled with veins of gold and platinum. The original, complete Stone of Creation took the form of a blocky slab like a standing stone, but it may be impossible to know the Stone's true size, or if the concept of a \"whole\" Stone of Creation even applies to the artifact. Perhaps other slabs and boulders made from the Stone have been scattered across the multiverse, being cut down and recombed through interplanar movements and the magic of the artifacts themselves.",
{
"type": "entries",
"name": "Random Properties",
"entries": [
"The Stone of Creation has the following random properties:",
{
"type": "list",
"items": [
"2 {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} properties",
"1 {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial} property"
]
}
]
},
{
"type": "entries",
"name": "Stone Smithing",
"entries": [
"While attuned to the Stone of Creation, you are proficient with {@item mason's tools|PHB} and can use them to cut pieces from the Stone's slab, thereby creating lesser artifacts."
]
},
{
"type": "entries",
"name": "Cut into Pieces",
"entries": [
"The Stone of Creation is a source for smaller artifacts that derive their power from it. These pieces allow you to shape and bestow a portion of the slab's power for yourself or others.",
"You can cut pieces in two general sizes: a {@item chip of creation|AitFR-AVT} or a {@item keystone of creation|AitFR-AVT}. These lesser artifacts require their own attunement.",
"Up to eleven chips of creation can be created from the existing slab, plus up to nine keystones. A chip resembles a flake of rock about the size of an adult human's hand. A keystone is at least five times that thickness.",
"When all chips and keystones have been cut away, a portion of the Stone remains but proves impervious to additional attempts to cut or chisel pieces from it. This smallest version of the Stone is the same size as a Tiny creature."
]
},
{
"type": "entries",
"name": "Raze or Reshape Creations",
"entries": [
"Although the Stone of Creation cannot create structures or demiplanes of its own, while you are attuned to it you can revise or renovate any structure or demiplane created by a keystone or chip, provided you are within 1 mile of the Stone and you can see or otherwise perceive the structure or demiplane. For example, you may add doors, seal windows, change the arrangement of rooms, or even dispel a structure created by a keystone. To do so, you must concentrate for 1 minute, as if on a spell, while picturing the changes you wish to make in your mind; over the course of that minute, the changes slowly occur. These changes cannot deal damage or directly harm a creature. A dispelled structure gradually recedes and diminishes to nothing, leaving creatures unharmed."
]
},
{
"type": "entries",
"name": "Destroying the Stone",
"entries": [
"The Stone of Creation is a strange artifact that can be divided into smaller artifacts, yet the Stone is impervious to most damage. Only the smith attuned to the Stone can carve it. Destroying the Stone's lesser, derived artifacts (and their creations) does no damage to the Stone itself.",
"The Stone of Creation is destroyed if it reaches the Elemental Chaos or is struck with a forked metal rod attuned to the Elemental Chaos (as per the {@spell plane shift} spell). The Stone then shatters into harmless, inert shards of rock. Places created by the slab's chips and keystones crumble, decay, or fade away to nothing after the Stone of Creation is destroyed. Whether the Stone of Creation is itself a piece of a larger artifact remains unknown."
]
}
]
},
{
"name": "Stone of Golorr",
"source": "WDH",
"page": 192,
"rarity": "artifact",
"reqAttune": true,
"wondrous": true,
"sentient": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"The Stone of Golorr is a glossy, greenish-gray stone that fits in the palm of your hand. The stone is actually an {@creature aboleth} named Golorr, transformed by magic into an object.",
{
"type": "entries",
"name": "Random Properties",
"entries": [
"The Stone of Golorr has the following properties, determined by rolling on the tables in the \"Artifacts\" section in chapter 7 of the Dungeon Master's Guide:",
{
"type": "list",
"items": [
"1 {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial property}",
"1 {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental property}"
]
}
]
},
{
"type": "entries",
"name": "Legend Lore",
"entries": [
"The Stone of Golorr has 3 charges and regains {@dice 1d3} expended charges daily at dawn. While holding the stone, you can expend 1 of its charges to cast the {@spell legend lore} spell.",
"By using the stone to cast {@spell legend lore}, you communicate directly with the {@creature aboleth}, and it shares its knowledge with you. The aboleth can't lie to you, but the information it provides is often cryptic or vague.",
"The aboleth knows where Lord Neverember's secret vault is located. It also knows that three keys are needed to open the vault and that a gold dragon named Aurinax inhabits the vault and guards its treasures."
]
},
{
"type": "entries",
"name": "Failed Memory",
"entries": [
"When your attunement to the Stone of Golorr ends, you must make a DC 16 Wisdom saving throw. On a failed save, you lose all memory of the stone being in your possession and all knowledge imparted by it. A {@spell remove curse} spell cast on you has a {@chance 20} chance of restoring the lost knowledge and memories, and a {@spell greater restoration} spell does so automatically."
]
},
{
"type": "entries",
"name": "Sentience",
"entries": [
"The Stone of Golorr is a sentient lawful evil magic item with an Intelligence of 18, a Wisdom of 15, and a Charisma of 18. It has hearing and {@sense darkvision} out to a range of 120 feet. It can communicate telepathically with the creature that is attuned to it, as long as that creature understands at least one language. In addition, the {@creature aboleth} learns the greatest desires of any creature that communicates telepathically with the stone.",
"The Stone of Golorr hungers for information and prefers not to remain in the clutches of any creature for too long. Whenever the stone desires a new owner, it demands to be given to another intelligent creature as quickly as possible. If its demands are ignored, it tries to take control of its owner (see \"Sentient Magic Items\" in chapter 7 of the Dungeon Master's Guide)."
]
},
{
"type": "entries",
"name": "Personality",
"entries": [
"The Stone of Golorr has an alien intellect that is both domineering and hungry for knowledge. It thinks of itself as an ageless and immortal god."
]
},
{
"type": "entries",
"name": "Destroying the Stone",
"entries": [
"While in stone form, the {@creature aboleth} isn't a creature and isn't subject to effects that target creatures. The Stone of Golorr is immune to all damage. Casting an {@spell antipathy/sympathy} spell on the stone destroys it if the antipathy effect is selected and the spell is directed to repel aberrations. When the spell is cast in this way, the stone transforms into mucus and is destroyed, and Golorr the aboleth appears in an unoccupied space within 30 feet of the stone's remains. The aboleth is incensed by the stone's destruction, and it attacks all other creatures it can see."
]
}
],
"attachedSpells": [
"legend lore"
],
"hasFluffImages": true
},
{
"name": "Stone of Good Luck",
"source": "DMG",
"page": 205,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"bonusSavingThrow": "+1",
"bonusAbilityCheck": "+1",
"entries": [
"While this polished agate is on your person, you gain a +1 bonus to ability checks and saving throws."
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Stone of Ill Luck",
"source": "TftYP",
"page": 229,
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"curse": true,
"weight": 1,
"bonusSavingThrow": "-2",
"bonusAbilityCheck": "-2",
"entries": [
"This polished agate appears to be a {@item stone of good luck} to anyone who tries to identify it, and it confers that item's property while on your person.",
{
"type": "entries",
"name": "Curse",
"entries": [
"This item is cursed. While it is on your person, you take a -2 penalty to ability checks and saving throws. Until the curse is discovered, the DM secretly applies this penalty, assuming you are adding the item's bonus. You are unwilling to part with the stone until the curse is broken with {@spell remove curse} or similar magic."
]
}
]
},
{
"name": "Stonespeaker Crystal",
"source": "OotA",
"page": 223,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 4}",
"charges": 10,
"entries": [
"Created by the stone giant librarians of Gravenhollow, this nineteen-inch-long shard of quartz grants you advantage on Intelligence ({@skill Investigation}) checks while it is on your person.",
"The crystal has 10 charges. While holding it, you can use an action to expend some of its charges to cast one of the following spells from it: {@spell speak with animals} (2 charges), {@spell speak with dead} (4 charges), or {@spell speak with plants} (3 charges).",
"When you cast a {@filter divination|spells|school=D} spell, you can use the crystal in place of one material component that would normally be consumed by the spell, at a cost of 1 charge per level of the spell. The crystal is not consumed when used in this way.",
"The crystal regains {@dice 1d6 + 4} expended charges daily at dawn. If you expend the crystal's last charge, roll a {@dice d20}. On a 1, the crystal vanishes, lost forever."
],
"attachedSpells": [
"speak with animals",
"speak with dead",
"speak with plants"
],
"hasFluffImages": true
},
{
"name": "Stonky's ring",
"source": "CM",
"page": 132,
"type": "RG",
"rarity": "unknown (magic)",
"reqAttune": true,
"entries": [
"While wearing this ring, you can cast the {@spell telekinesis} spell at will, but you can target only objects that aren't being worn or carried.",
"Any character who attunes to the ring gains control of Stonky's creations, though the {@creature skitterwidget|cm|skitterwidgets} ignore commands spoken more than 30 feet away from them."
],
"attachedSpells": [
"telekinesis"
]
},
{
"name": "Storm Boomerang",
"source": "PotA",
"page": 223,
"type": "R",
"rarity": "uncommon",
"weaponCategory": "simple",
"range": "60/120",
"dmg1": "1d4",
"dmgType": "B",
"entries": [
"This boomerang is a ranged weapon carved from {@creature griffon} bone and etched with the symbol of elemental air. When thrown, it has a range of 60/120 feet, and any creature that is proficient with the javelin is also proficient with this weapon. On a hit, the boomerang deals {@damage 1d4} bludgeoning damage and {@damage 3d4} thunder damage, and the target must succeed on a DC 10 Constitution saving throw or be {@condition stunned} until the end of its next turn. On a miss, the boomerang returns to the thrower's hand.",
"Once the boomerang deals thunder damage to a target, the weapon loses its ability to deal thunder damage and its ability to stun a target. These properties return after the boomerang spends at least 1 hour inside an elemental air node."
]
},
{
"name": "Stormgirdle (Awakened)",
"source": "EGW",
"_copy": {
"name": "Stormgirdle (Dormant)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Awakened",
"entries": [
"While wearing the Stormgirdle in its awakened state, you gain the following benefits:",
{
"type": "list",
"items": [
"Your Strength score becomes 23 if it isn't already 23 or higher.",
"Your Storm Avatar's lightning strike deals {@damage 4d6} lightning damage (instead of {@dice 3d6}).",
"While transformed into a Storm Avatar, you gain a flying speed of 30 feet and can hover."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
}
},
{
"name": "Stormgirdle (Dormant)",
"source": "EGW",
"page": 273,
"resist": [
"lightning",
"thunder"
],
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"ability": {
"static": {
"str": 21
}
},
"entries": [
"A Stormgirdle is a wide belt made of thick leather branded with the symbol of Kord. The girdle's clasps are made from dragon ivory.",
{
"type": "entries",
"name": "Dormant",
"entries": [
"While wearing the Stormgirdle in its dormant state, you have resistance to lightning damage and thunder damage, and your Strength score becomes 21 if it isn't already 21 or higher. In addition, you can use an action to become a Storm Avatar for 1 minute, gaining the following benefits for the duration:",
{
"type": "list",
"items": [
"You have immunity to lightning damage and thunder damage.",
"When you hit with a weapon attack that normally deals bludgeoning damage, it deals thunder damage instead. When you hit with a weapon attack that normally deals piercing or slashing damage, it deals lightning damage instead.",
"As a bonus action, you can choose one creature you can see within 30 feet of you to be struck by lightning. The target must make a DC 15 Dexterity saving throw, taking {@damage 3d6} lightning damage on a failed save, or half as much damage on a successful one."
]
},
"Once you use the girdle's Storm Avatar property, that property can't be used again until the next dawn."
]
}
]
},
{
"name": "Stormgirdle (Exalted)",
"source": "EGW",
"_copy": {
"name": "Stormgirdle (Awakened)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Exalted",
"entries": [
"While wearing the Stormgirdle in its exalted state, you gain the following benefits:",
{
"type": "list",
"items": [
"Your Strength score becomes 25 if it isn't already 25 or higher.",
"Your Storm Avatar's lightning strike deals {@damage 5d6} lightning damage (instead of {@dice 3d6}).",
"You can cast the {@spell control weather} spell from the girdle. This property can't be used again until the next dawn."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"attachedSpells": [
"control weather"
]
},
{
"name": "Strixhaven Pennant",
"source": "SCC",
"page": 39,
"rarity": "common",
"wondrous": true,
"entries": [
"This magic pennant bears the symbol of Strixhaven or one of its colleges: Lorehold, Prismari, Quandrix, Silverquill, or Witherbloom. While you wave the pennant, the symbol on it glitters, and the pennant sheds bright light in a 10-foot-radius and dim light for an additional 10 feet."
]
},
{
"name": "Sun",
"source": "WDH",
"page": 169,
"type": "$",
"rarity": "none",
"value": 1000,
"entries": [
"Platinum coin, twice as large as a {@item nib|wdh} (1 sun = 1,000 {@item nib|wdh|nibs})"
]
},
{
"name": "Sun amulet on a beaded chain (Sun Empire)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 2500
},
{
"name": "Sun Blade",
"source": "DMG",
"page": 205,
"srd": true,
"baseItem": "longsword|phb",
"type": "M",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"weight": 3,
"weaponCategory": "martial",
"property": [
"F",
"V"
],
"dmg1": "1d8",
"dmgType": "R",
"dmg2": "1d10",
"bonusWeapon": "+2",
"entries": [
"This item appears to be a longsword hilt. While grasping the hilt, you can use a bonus action to cause a blade of pure radiance to spring into existence, or make the blade disappear. While the blade exists, this magic longsword has the finesse property. If you are proficient with {@item shortsword|phb|shortswords} or {@item longsword|phb|longswords}, you are proficient with the sun blade.",
"You gain a +2 bonus to attack and damage rolls made with this weapon, which deals radiant damage instead of slashing damage. When you hit an undead with it, that target takes an extra {@damage 1d8} radiant damage.",
"The sword's luminous blade emits bright light in a 15-foot radius and dim light for an additional 15 feet. The light is sunlight. While the blade persists, you can use an action to expand or reduce its radius of bright and dim light by 5 feet each, to a maximum of 30 feet each or a minimum of 10 feet each."
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Sunforger",
"source": "GGR",
"page": 181,
"baseItem": "warhammer|phb",
"type": "M",
"rarity": "rare",
"reqAttune": true,
"weight": 2,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "B",
"dmg2": "1d10",
"bonusWeapon": "+2",
"entries": [
"You gain a +2 bonus to attack and damage rolls made with this magic weapon.",
"As an action, you can hurl the weapon up to 120 feet to a point you can see. When it reaches that point, the weapon vanishes in an explosion, and each creature in a 20-foot-radius sphere centered on that point must make a DC 15 Dexterity saving throw, taking {@damage 6d6} fire damage on a failed save, or half as much damage on a successful one. Afterward, you can use an action to cause the weapon to reappear in your empty hand. You can't cause it to explode again until you finish a short or long rest.",
"If you don't call the weapon back to your hand, it reappears at the point where it exploded when you are no longer attuned to it or when 24 hours have passed."
],
"hasFluffImages": true
},
{
"name": "Sunsword",
"source": "CoS",
"page": 223,
"baseItem": "longsword|phb",
"type": "M",
"rarity": "legendary",
"reqAttune": true,
"sentient": true,
"weight": 3,
"weaponCategory": "martial",
"property": [
"F",
"V"
],
"dmg1": "1d8",
"dmgType": "R",
"dmg2": "1d10",
"bonusWeapon": "+2",
"entries": [
"The Sunsword is a unique blade once possessed by Strahd's brother, Sergei von Zarovich. In its original form, it had a platinum hilt and guard, and a thin crystal blade as strong as steel.",
"Strahd employed a powerful wizard named Khazan to destroy the weapon after Sergei's death. The first part of the process required the hilt and the blade to be separated, which Khazan accomplished. While Khazan was busying himself destroying the blade, his apprentice stole the hilt and fled. Khazan later located his apprentice's mutilated corpse in the Svalich Woods, but the hilt was nowhere to be found. To avoid the vampire's wrath, Khazan told Strahd that the entire weapon had been destroyed.",
"The hilt, which is sentient, knows that it can never be reunited with its original crystal blade. It has, however, gained the properties of a {@item sun blade}:",
{
"type": "list",
"items": [
"You gain a +2 bonus to attack and damage rolls made with this weapon, which deals radiant damage instead of slashing damage. When you hit an undead with it, that target takes an extra {@damage 1d8} radiant damage.",
"The sword's luminous blade emits bright light in a 15-foot radius and dim light for an additional 15 feet. The light is sunlight. While the blade persists, you can use an action to expand or reduce its radius of bright and dim light by 5 feet each, to a maximum of 30 feet each or a minimum of 10 feet each."
]
},
{
"name": "Sentience",
"type": "entries",
"entries": [
"The Sunsword is a sentient chaotic good weapon with an Intelligence of 11, a Wisdom of 17, and a Charisma of 16. It has hearing and normal vision out to a range of 60 feet. The weapon communicates by transmitting emotions to the creature carrying it or wielding it."
]
},
{
"name": "Personality",
"type": "entries",
"entries": [
"The Sunsword's special purpose is to destroy Strahd, not so much because it wants to free the land of Barovia from evil but because it wants revenge for the loss of its crystal blade. The weapon secretly fears its own destruction."
]
}
],
"hasFluffImages": true
},
{
"name": "Survival Mantle",
"source": "VGM",
"page": 81,
"type": "MA",
"rarity": "unknown",
"weight": 40,
"ac": 15,
"stealth": true,
"entries": [
"This carapace-like augmentation encases portions of the wearer's shoulders, neck, and chest. A survival mantle is equivalent to a suit of nonmagical half plate armor and takes just as long to don or doff. It can't be worn with other kinds of armor.",
"A creature wearing a survival mantle can breathe normally in any environment (including a vacuum) and has advantage on saving throws against harmful gases (such as those created by a {@spell cloudkill} spell, a {@spell stinking cloud} spell, inhaled poisons, and the breath weapons of some dragons)."
]
},
{
"name": "Sword of Answering (Answerer)",
"source": "DMG",
"page": 206,
"baseItem": "longsword|phb",
"type": "M",
"tier": "major",
"rarity": "legendary",
"reqAttune": "by a chaotic good creature",
"reqAttuneTags": [
{
"alignment": [
"C",
"G"
]
}
],
"weight": 3,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"bonusWeapon": "+3",
"entries": [
"In the world of Greyhawk, only nine of these blades are known to exist. Each is patterned after the legendary sword Fragarach, which is variously translated as \"Final Word.\" Each of the nine swords has its own name and alignment, and each bears a different gem in its pommel.",
"Answerer, the Chaotic Good sword, has an emerald set in its pommel.",
"You gain a +3 bonus to attack and damage rolls made with this sword. In addition, while you hold the sword, you can use your reaction to make one melee attack with it against any creature in your reach that deals damage to you. You have advantage on the attack roll, and any damage dealt with this special attack ignores any damage immunity or resistance the target has."
]
},
{
"name": "Sword of Answering (Back Talker)",
"source": "DMG",
"page": 206,
"baseItem": "longsword|phb",
"type": "M",
"tier": "major",
"rarity": "legendary",
"reqAttune": "by a chaotic evil creature",
"reqAttuneTags": [
{
"alignment": [
"C",
"E"
]
}
],
"weight": 3,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"bonusWeapon": "+3",
"entries": [
"In the world of Greyhawk, only nine of these blades are known to exist. Each is patterned after the legendary sword Fragarach, which is variously translated as \"Final Word.\" Each of the nine swords has its own name and alignment, and each bears a different gem in its pommel.",
"Back Talker, the Chaotic Evil sword, has jet set in its pommel.",
"You gain a +3 bonus to attack and damage rolls made with this sword. In addition, while you hold the sword, you can use your reaction to make one melee attack with it against any creature in your reach that deals damage to you. You have advantage on the attack roll, and any damage dealt with this special attack ignores any damage immunity or resistance the target has."
]
},
{
"name": "Sword of Answering (Concluder)",
"source": "DMG",
"page": 206,
"baseItem": "longsword|phb",
"type": "M",
"tier": "major",
"rarity": "legendary",
"reqAttune": "by a lawful neutral creature",
"reqAttuneTags": [
{
"alignment": [
"L",
"N"
]
}
],
"weight": 3,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"bonusWeapon": "+3",
"entries": [
"In the world of Greyhawk, only nine of these blades are known to exist. Each is patterned after the legendary sword Fragarach, which is variously translated as \"Final Word.\" Each of the nine swords has its own name and alignment, and each bears a different gem in its pommel.",
"Concluder, the Lawful Neutral sword, has an amethyst set in its pommel.",
"You gain a +3 bonus to attack and damage rolls made with this sword. In addition, while you hold the sword, you can use your reaction to make one melee attack with it against any creature in your reach that deals damage to you. You have advantage on the attack roll, and any damage dealt with this special attack ignores any damage immunity or resistance the target has."
]
},
{
"name": "Sword of Answering (Last Quip)",
"source": "DMG",
"page": 206,
"baseItem": "longsword|phb",
"type": "M",
"tier": "major",
"rarity": "legendary",
"reqAttune": "by a chaotic neutral creature",
"reqAttuneTags": [
{
"alignment": [
"C",
"N"
]
}
],
"weight": 3,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"bonusWeapon": "+3",
"entries": [
"In the world of Greyhawk, only nine of these blades are known to exist. Each is patterned after the legendary sword Fragarach, which is variously translated as \"Final Word.\" Each of the nine swords has its own name and alignment, and each bears a different gem in its pommel.",
"Last Quip, the Chaotic Neutral sword, has a tourmaline set in its pommel.",
"You gain a +3 bonus to attack and damage rolls made with this sword. In addition, while you hold the sword, you can use your reaction to make one melee attack with it against any creature in your reach that deals damage to you. You have advantage on the attack roll, and any damage dealt with this special attack ignores any damage immunity or resistance the target has."
]
},
{
"name": "Sword of Answering (Rebutter)",
"source": "DMG",
"page": 206,
"baseItem": "longsword|phb",
"type": "M",
"tier": "major",
"rarity": "legendary",
"reqAttune": "by a neutral good creature",
"reqAttuneTags": [
{
"alignment": [
"N",
"G"
]
}
],
"weight": 3,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"bonusWeapon": "+3",
"entries": [
"In the world of Greyhawk, only nine of these blades are known to exist. Each is patterned after the legendary sword Fragarach, which is variously translated as \"Final Word.\" Each of the nine swords has its own name and alignment, and each bears a different gem in its pommel.",
"Rebutter, the Neutral Good sword, has a topaz set in its pommel.",
"You gain a +3 bonus to attack and damage rolls made with this sword. In addition, while you hold the sword, you can use your reaction to make one melee attack with it against any creature in your reach that deals damage to you. You have advantage on the attack roll, and any damage dealt with this special attack ignores any damage immunity or resistance the target has."
]
},
{
"name": "Sword of Answering (Replier)",
"source": "DMG",
"page": 206,
"baseItem": "longsword|phb",
"type": "M",
"tier": "major",
"rarity": "legendary",
"reqAttune": "by a neutral creature",
"reqAttuneTags": [
{
"alignment": [
"N"
]
}
],
"weight": 3,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"bonusWeapon": "+3",
"entries": [
"In the world of Greyhawk, only nine of these blades are known to exist. Each is patterned after the legendary sword Fragarach, which is variously translated as \"Final Word.\" Each of the nine swords has its own name and alignment, and each bears a different gem in its pommel.",
"Replier, the Neutral sword, has a peridot set in its pommel.",
"You gain a +3 bonus to attack and damage rolls made with this sword. In addition, while you hold the sword, you can use your reaction to make one melee attack with it against any creature in your reach that deals damage to you. You have advantage on the attack roll, and any damage dealt with this special attack ignores any damage immunity or resistance the target has."
]
},
{
"name": "Sword of Answering (Retorter)",
"source": "DMG",
"page": 206,
"baseItem": "longsword|phb",
"type": "M",
"tier": "major",
"rarity": "legendary",
"reqAttune": "by a lawful good creature",
"reqAttuneTags": [
{
"alignment": [
"L",
"G"
]
}
],
"weight": 3,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"bonusWeapon": "+3",
"entries": [
"In the world of Greyhawk, only nine of these blades are known to exist. Each is patterned after the legendary sword Fragarach, which is variously translated as \"Final Word.\" Each of the nine swords has its own name and alignment, and each bears a different gem in its pommel.",
"Retorter, the Lawful Good sword, has an aquamarine set in its pommel.",
"You gain a +3 bonus to attack and damage rolls made with this sword. In addition, while you hold the sword, you can use your reaction to make one melee attack with it against any creature in your reach that deals damage to you. You have advantage on the attack roll, and any damage dealt with this special attack ignores any damage immunity or resistance the target has."
]
},
{
"name": "Sword of Answering (Scather)",
"source": "DMG",
"page": 206,
"baseItem": "longsword|phb",
"type": "M",
"tier": "major",
"rarity": "legendary",
"reqAttune": "by a lawful evil creature",
"reqAttuneTags": [
{
"alignment": [
"L",
"E"
]
}
],
"weight": 3,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"bonusWeapon": "+3",
"entries": [
"In the world of Greyhawk, only nine of these blades are known to exist. Each is patterned after the legendary sword Fragarach, which is variously translated as \"Final Word.\" Each of the nine swords has its own name and alignment, and each bears a different gem in its pommel.",
"Scather, the Lawful Evil sword, has a garnet set in its pommel.",
"You gain a +3 bonus to attack and damage rolls made with this sword. In addition, while you hold the sword, you can use your reaction to make one melee attack with it against any creature in your reach that deals damage to you. You have advantage on the attack roll, and any damage dealt with this special attack ignores any damage immunity or resistance the target has."
]
},
{
"name": "Sword of Answering (Squelcher)",
"source": "DMG",
"page": 206,
"baseItem": "longsword|phb",
"type": "M",
"tier": "major",
"rarity": "legendary",
"reqAttune": "by a neutral evil creature",
"reqAttuneTags": [
{
"alignment": [
"N",
"E"
]
}
],
"weight": 3,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"bonusWeapon": "+3",
"entries": [
"In the world of Greyhawk, only nine of these blades are known to exist. Each is patterned after the legendary sword Fragarach, which is variously translated as \"Final Word.\" Each of the nine swords has its own name and alignment, and each bears a different gem in its pommel.",
"Squelcher, the Neutral Evil sword, has a spinel set in its pommel.",
"You gain a +3 bonus to attack and damage rolls made with this sword. In addition, while you hold the sword, you can use your reaction to make one melee attack with it against any creature in your reach that deals damage to you. You have advantage on the attack roll, and any damage dealt with this special attack ignores any damage immunity or resistance the target has."
]
},
{
"name": "Sword of Kas",
"source": "DMG",
"page": 226,
"baseItem": "longsword|phb",
"type": "M",
"rarity": "artifact",
"reqAttune": true,
"sentient": true,
"weight": 3,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"bonusWeapon": "+3",
"critThreshold": 19,
"entries": [
"When Vecna grew in power, he appointed an evil and ruthless lieutenant, Kas the Bloody Handed, to act as his bodyguard and right hand. This despicable villain served as advisor, warlord, and assassin. His successes earned him Vecna's admiration and a reward: a sword with as dark a pedigree as the man who would wield it.",
"For a long time, Kas faithfully served the {@creature lich} but as Kas's power grew, so did his hubris. His sword urged him to supplant Vecna, so that they could rule the {@creature lich||lich's} empire in Vecna's stead. Legend says Vecna's destruction came at Kas's hand, but Vecna also wrought his rebellious lieutenant's doom, leaving only Kas's sword behind. The world was made brighter thereby.",
"The Sword of Kas is a magic, sentient longsword that grants a +3 bonus to attack and damage rolls made with it. It scores a critical hit on a roll of 19 or 20, and deals an extra {@damage 2d10} slashing damage to undead.",
"If the sword isn't bathed in blood within 1 minute of being drawn from its scabbard, its wielder must make a DC 15 Charisma saving throw. On a successful save, the wielder takes {@damage 3d6} psychic damage. On a failed save, the wielder is dominated by the sword, as if by the {@spell dominate monster} spell, and the sword demands that it be bathed in blood. The spell effect ends when the sword's demand is met.",
{
"name": "Random Properties",
"type": "entries",
"entries": [
"The Sword of Kas has the following random properties:"
]
},
{
"type": "list",
"items": [
"1 {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial property}",
"1 {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial property}",
"1 {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental property}",
"1 {@table Artifact Properties; Major Detrimental Properties|dmg|major detrimental property}"
]
},
{
"name": "Spirit of Kas",
"type": "entries",
"entries": [
"While the sword is on your person, you add a {@dice d10} to your initiative at the start of every combat. In addition, when you use an action to attack with the sword, you can transfer some or all of its attack bonus to your Armor Class instead. The adjusted bonuses remain in effect until the start of your next turn."
]
},
{
"name": "Spells",
"type": "entries",
"entries": [
"While the sword is on your person, you can use an action to cast one of the following spells (save 18) from it: {@spell call lightning}, {@spell divine word}, or {@spell finger of death}. Once you use the sword to cast a spell, you can't cast that spell again from it until the next dawn."
]
},
{
"name": "Sentience",
"type": "entries",
"entries": [
"The Sword of Kas is a sentient chaotic evil weapon with an Intelligence of 15, a Wisdom of 13, and a Charisma of 16. It has hearing and {@sense darkvision} out to a range of 120 feet.",
"The weapon communicates telepathically with its wielder and can speak, read, and understand Common."
]
},
{
"name": "Personality",
"type": "entries",
"entries": [
"The sword's purpose is to bring ruin to Vecna. Killing Vecna's worshipers, destroying the {@creature lich||lich's} works, and foiling his machinations all help to fulfill this goal.",
"The Sword of Kas also seeks to destroy anyone corrupted by the Eye and {@item Hand of Vecna}. The sword's obsession with those artifacts eventually becomes a fixation for its wielder."
]
},
{
"name": "Destroying the Sword",
"type": "entries",
"entries": [
"A creature attuned to both the {@item Eye of Vecna} and the {@item Hand of Vecna} can use the wish property of those combined artifacts to unmake the Sword of Kas. The creature must cast the {@spell wish} spell and make a Charisma check contested by the Charisma check of the sword. The sword must be within 30 feet of the creature, or the spell fails. If the sword wins the contest, nothing happens, and the {@spell wish} spell is wasted. If the sword loses the contest, it is destroyed."
]
}
],
"attachedSpells": [
"call lightning",
"divine word",
"finger of death"
],
"hasFluffImages": true
},
{
"name": "Sword of the Paruns",
"source": "GGR",
"page": 181,
"baseItem": "longsword|phb",
"type": "M",
"rarity": "very rare",
"reqAttune": true,
"weight": 3,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"bonusWeapon": "+1",
"entries": [
"You gain a +1 bonus to attack and damage rolls made with this magic weapon. Additionally, once on each of your turns, you can use one of the following properties if you're holding the sword:",
{
"type": "list",
"items": [
"Immediately after you use the {@action Attack} action to attack with the sword, you can enable one creature within 60 feet of you to use its reaction to make one weapon attack.",
"Immediately after you take the {@action Dash} action, you can enable one creature within 60 feet of you to use its reaction to move up to its speed.",
"Immediately after you take the {@action Dodge} action, you can enable one creature within 60 feet of you to use its reaction to gain the benefits of the {@action Dodge} action."
]
}
],
"hasFluffImages": true
},
{
"name": "Sword of Zariel",
"source": "BGDIA",
"page": 226,
"baseItem": "longsword|phb",
"type": "M",
"resist": [
"necrotic",
"radiant"
],
"rarity": "artifact",
"reqAttune": "by a creature the sword deems worthy",
"sentient": true,
"weight": 3,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"ability": {
"static": {
"cha": 20
}
},
"entries": [
"This longsword belonged to the angel Zariel before her fall from grace. Fashioned from celestial steel, it gives off a faint glow and hum. The weapon chooses who can attune to it and who can't. It desires a wielder who embodies bravery and heroism.",
{
"type": "entries",
"name": "Attunement",
"entries": [
"The sword allows you to attune to it immediately, without having to take a short rest. The first time you attune to the sword, you are transformed into a heavenly, idealized version of yourself, blessed with otherworldly beauty and a touch of heaven in your heart. Neither magic nor divine intervention can reverse this transformation. Your alignment becomes lawful good, and you gain the following traits:",
{
"type": "list",
"items": [
{
"type": "item",
"name": "Angelic Language",
"entry": "You can speak, read, and write Celestial."
},
{
"type": "item",
"name": "Celestial Resistance",
"entry": "You have resistance to necrotic and radiant damage."
},
{
"type": "item",
"name": "Divine Presence",
"entry": "Your Charisma score becomes 20, unless it is already 20 or higher."
},
{
"type": "item",
"name": "Feathered Wings",
"entry": "You sprout a beautiful pair of feathered wings that grant you a flying speed of 90 feet and the ability to hover. If you already have a different kind of wings, these new wings replace the old ones, which fall off."
},
{
"type": "item",
"name": "Truesight",
"entry": "Your eyes become luminous pools of silver. You can see in normal and magical darkness, see {@condition invisible} creatures and objects, automatically detect visual illusions and succeed on saving throws against them, perceive the original form of a shapechanger or a creature that is transformed by magic, and see into the Ethereal Plane, all within a range of 60 feet."
},
{
"type": "item",
"name": "New Personality",
"entries": [
"You gain new personality traits, determined by rolling once on each of the following tables. These traits override any conflicting personality trait, ideal, bond, or flaw.",
{
"type": "table",
"caption": "Personality Traits",
"colLabels": [
"d8",
"Personality Trait"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"1",
"I treat all beings, even enemies, with respect."
],
[
"2",
"I won't tell a lie."
],
[
"3",
"I enjoy sharing my philosophical worldview and experiences with others."
],
[
"4",
"I cut right to the chase in every conversation."
],
[
"5",
"I often quote (or misquote) religious texts."
],
[
"6",
"I anger quickly when I witness cruelty or injustice."
],
[
"7",
"My praise and trust are earned and never given freely."
],
[
"8",
"I like everything clean and organized."
]
]
},
{
"type": "table",
"caption": "Ideals",
"colLabels": [
"d6",
"Ideal"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"1",
"Charity. I always help those in need. (Good)"
],
[
"2",
"Faith. I choose to follow the tenets of a particular lawful good deity to the letter. (Lawful)"
],
[
"3",
"Responsibility. It is the duty of the strong to protect the weak. (Lawful)"
],
[
"4",
"Respect. All people deserve to be treated with dignity. (Good)"
],
[
"5",
"Honor. The way I conduct myself determines my reward in the afterlife. (Lawful)"
],
[
"6",
"Redemption. All creatures are capable of change for the better. (Good)"
]
]
},
{
"type": "table",
"caption": "Bonds",
"colLabels": [
"d6",
"Bond"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"1",
"I have a favorite religious hymn that I constantly hum."
],
[
"2",
"I must keep a written record of my beliefs and the sins that I witness. When finished, this book will be my gift to the multiverse."
],
[
"3",
"I have cherished memories of Idyllglen, though I've only seen this bucolic town in dreams."
],
[
"4",
"I would die for those who fight beside me, regardless of their faults."
],
[
"5",
"I seek to honor the angel Zariel by destroying fiends and other evildoers wherever I find them."
],
[
"6",
"The Sword of Zariel has chosen me. I shall not fail to wield it justly."
]
]
},
{
"type": "table",
"caption": "Flaws",
"colLabels": [
"d6",
"Flaw"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"1",
"I am too quick to judge others."
],
[
"2",
"I offer forgiveness too readily."
],
[
"3",
"I will sacrifice innocent lives for the greater good."
],
[
"4",
"Flaw? What flaw? I am flawless. Utter perfection!"
],
[
"5",
"I allow nothing to stand in the way of my crusade to eradicate evil from the multiverse."
],
[
"6",
"I ignore those who do not support my plans, for my calling is higher than all others."
]
]
}
]
}
]
}
]
},
{
"type": "entries",
"name": "Holy Light",
"entries": [
"The sword sheds bright light in a 5-foot radius and dim light for an additional 5 feet. Fiends find the sword's light disconcerting and painful, even if they can't see it, and have disadvantage on attack rolls made within the weapon's radius of bright light.",
"As a bonus action, you can intensify the sword's light, causing it to shed bright light in a 15-foot radius and dim light for an additional 15 feet, or reduce its glow to its normal intensity."
]
},
{
"type": "entries",
"name": "Random Properties",
"entries": [
"The sword has 2 {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial properties}."
]
},
{
"type": "entries",
"name": "Searing Radiance",
"entries": [
"The sword deals an extra 9 ({@damage 2d8}) radiant damage to any creature it hits, or 16 ({@damage 3d10}) radiant damage if you're wielding the weapon with two hands. An evil creature that takes this radiant damage must succeed on a DC 17 Constitution saving throw or be {@condition blinded} until the end of its next turn."
]
},
{
"type": "entries",
"name": "Sentience",
"entries": [
"The Sword of Zariel is a sentient, lawful good item with an Intelligence of 10, a Wisdom of 20, and a Charisma of 18. It has hearing and normal vision out to a range of 30 feet.",
"The sword communicates by transmitting emotion to the creature carrying or wielding it."
]
},
{
"type": "entries",
"name": "Truth Seer",
"entries": [
"While holding the sword, you gain advantage on all Wisdom ({@skill Insight}) checks."
]
},
{
"type": "entries",
"name": "Destroying the Sword",
"entries": [
"Zariel can destroy the sword simply by grasping it. She couldn't bring herself to do so when she was an angel, but as an archdevil she has no compunction about ridding the multiverse of the weapon. The sword is also destroyed if it's used to shatter the Companion (see \"{@adventure Shattering the Companion|bgdia|8|shattering the companion}\"), unless the blade is wielded by an angel of challenge rating 15 or higher, or a good-aligned cleric or paladin of at least 10th level.",
"If Zariel is killed for good (that is, if she dies in the Nine Hells), the sword becomes no harder to destroy than a normal longsword."
]
}
],
"hasFluffImages": true
},
{
"name": "Talarith",
"source": "BAM",
"page": 47,
"rarity": "legendary",
"reqAttune": "by a reigar who created it",
"wondrous": true,
"entries": [
"The {@creature reigar|BAM} who creates this piece of jewelry chooses its form: bracelet, brooch, diadem, or necklace. While the reigar wears the talarith, any weapon wielded by the {@creature reigar|BAM} deals an extra {@damage 1d6} force damage when it hits.",
"As an action, a reigar can use its talarith to summon a golem that looks just like the reigar. The golem obeys the reigar's commands and uses the {@creature reigar|BAM|reigar's} statistics, except it is a Construct that doesn't have a talarith of its own. The golem vanishes after 1 hour, or when it is reduced to 0 hit points. After summoning a golem, the reigar must finish a short or long rest before this property can be used again."
]
},
{
"name": "Talisman of Pure Good",
"source": "DMG",
"page": 207,
"srd": true,
"tier": "major",
"rarity": "legendary",
"reqAttune": "by a creature of good alignment",
"reqAttuneTags": [
{
"alignment": [
"G"
]
}
],
"wondrous": true,
"weight": 1,
"bonusSpellAttack": "+2",
"charges": 7,
"entries": [
"This talisman is a mighty symbol of goodness. A creature that is neither good nor evil in alignment takes {@damage 6d6} radiant damage upon touching the talisman. An evil creature takes {@damage 8d6} radiant damage upon touching the talisman. Either sort of creature takes the damage again each time it ends its turn holding or carrying the talisman.",
"If you are a good cleric or paladin, you can use the talisman as a holy symbol, and you gain a +2 bonus to spell attack rolls while you wear or hold it.",
"The talisman has 7 charges. If you are wearing or holding it, you can use an action to expend 1 charge from it and choose one creature you can see on the ground within 120 feet of you. If the target is of evil alignment, a flaming fissure opens under it. The target must succeed on a DC 20 Dexterity saving throw or fall into the fissure and be destroyed, leaving no remains. The fissure then closes, leaving no trace of its existence. When you expend the last charge, the talisman disperses into motes of golden light and is destroyed."
],
"lootTables": [
"Magic Item Table I"
]
},
{
"name": "Talisman of the Sphere",
"source": "DMG",
"page": 207,
"srd": true,
"tier": "major",
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"weight": 1,
"entries": [
"When you make an Intelligence ({@skill Arcana}) check to control a {@item sphere of annihilation} while you are holding this talisman, you double your proficiency bonus on the check. In addition, when you start your turn with control over a {@item sphere of annihilation}, you can use an action to levitate it 10 feet plus a number of additional feet equal to 10 × your Intelligence modifier."
],
"lootTables": [
"Magic Item Table I"
],
"hasFluffImages": true
},
{
"name": "Talisman of Ultimate Evil",
"source": "DMG",
"page": 207,
"srd": true,
"tier": "major",
"rarity": "legendary",
"reqAttune": "by a creature of evil alignment",
"reqAttuneTags": [
{
"alignment": [
"E"
]
}
],
"wondrous": true,
"weight": 1,
"bonusSpellAttack": "+2",
"charges": 6,
"entries": [
"This item symbolizes unrepentant evil. A creature that is neither good nor evil in alignment takes {@damage 6d6} necrotic damage upon touching the talisman. A good creature takes {@damage 8d6} necrotic damage upon touching the talisman. Either sort of creature takes the damage again each time it ends its turn holding or carrying the talisman.",
"If you are an evil cleric or paladin, you can use the talisman as a holy symbol, and you gain a +2 bonus to spell attack rolls while you wear or hold it.",
"The talisman has 6 charges. If you are wearing or holding it, you can use an action to expend 1 charge from the talisman and choose one creature you can see on the ground within 120 feet of you. If the target is of good alignment, a flaming fissure opens under it. The target must succeed on a DC 20 Dexterity saving throw or fall into the fissure and be destroyed, leaving no remains. The fissure then closes, leaving no trace of its existence. When you expend the last charge, the talisman dissolves into foul-smelling slime and is destroyed."
],
"lootTables": [
"Magic Item Table I"
],
"hasFluffImages": true
},
{
"name": "Talking Doll",
"source": "XGE",
"page": 139,
"tier": "minor",
"rarity": "common",
"reqAttune": true,
"wondrous": true,
"entries": [
"While this stuffed doll is within 5 feet of you, you can spend a short rest telling it to say up to six phrases, none of which can be more than six words long, and set an observable condition under which the doll speaks each phrase. You can also replace old phrases with new ones. Whatever the condition, it must occur within 5 feet of the doll to make it speak. For example, whenever someone picks up the doll, it might say, \"I want a piece of candy.\" The doll's phrases are lost when your attunement to the doll ends."
],
"hasFluffImages": true
},
{
"name": "Tangler Grenade",
"source": "WDMM",
"page": 244,
"type": "OTH",
"rarity": "none",
"entries": [
"A tangler grenade is an alchemical, nonmagical item with an ovoid resin shell that shatters on impact. An as action, a creature can throw a tangler grenade at a point up to 60 feet away. Each creature within 10 feet of a shattered tangler grenade must succeed on a DC 15 Dexterity saving throw or be {@condition restrained} by sticky white webs. As an action, a creature can try to free itself or another creature within its reach from the webs, doing so with a successful DC 20 Strength ({@skill Athletics}) check. A gallon of alcohol dissolves the webs produced by a single tangler grenade. Otherwise, the webs dissolve on their own after 1 hour, freeing any creatures {@condition restrained} by them."
]
},
{
"name": "Tankard",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 1,
"value": 2,
"entries": [
"A tankard holds 1 pint of liquid."
]
},
{
"name": "Tankard of Plenty",
"source": "HotDQ",
"page": 74,
"otherSources": [
{
"source": "ToD",
"page": 90
}
],
"type": "OTH",
"rarity": "unknown (magic)",
"entries": [
"This golden stein is decorated with dancing dwarves and grain patterns. Speaking the command word (\"Illefarn\") while grasping the handle fills the tankard with three pints of rich dwarven ale. This power can be used up to three times per day."
]
},
{
"name": "Tankard of Sobriety",
"source": "XGE",
"page": 139,
"tier": "minor",
"rarity": "common",
"wondrous": true,
"entries": [
"This tankard has a stern face sculpted into one side. You can drink ale, wine, or any other nonmagical alcoholic beverage poured into it without becoming inebriated. The tankard has no effect on magical liquids or harmful substances such as poison."
]
},
{
"name": "Tantan",
"source": "SCAG",
"page": 124,
"type": "INS",
"rarity": "none",
"entries": [
"A tambourine, a popular instrument with halflings and humans south of the Dalelands."
]
},
{
"name": "Taol",
"source": "WDH",
"page": 169,
"type": "$",
"rarity": "none",
"value": 200,
"entries": [
"Brass coin, about two inches square with a hold large enough for a {@item nib|wdh} to fit in (1 taol = 200 {@item nib|wdh|nibs})"
]
},
{
"name": "Tearulai",
"source": "WDMM",
"page": 70,
"baseItem": "longsword|phb",
"type": "M",
"rarity": "very rare",
"reqAttune": true,
"sentient": true,
"weight": 3,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d4 + 2}",
"charges": 6,
"entries": [
"The longsword, Tearulai, is a sentient, neutral good {@item sword of sharpness} with an emerald-colored blade and precious gemstones embedded in its hilt and pommel. The sword's magical properties are suppressed until it is removed from Valdemar's skull.",
"Evil creatures can't attune to Tearulai; any evil creature that tries to do so takes 20 psychic damage. The weapon's emerald blade can't be damaged or dulled, and the sword can't be teleported anywhere without its wielder while the two are attuned to one another.",
{
"type": "entries",
"name": "Spells",
"entries": [
"The sword has 6 charges and regains {@dice 1d4 + 2} expended charges daily at dawn. A creature attuned to the sword can use an action and expend 1 or more charges to cast one of the following spells from it without material components: {@spell fly} (2 charges), {@spell polymorph} (3 charges), or {@spell transport via plants} (4 charges)."
]
},
{
"type": "entries",
"name": "Sentience",
"entries": [
"The sword has an Intelligence of 17, a Wisdom of 12, and a Charisma of 20. It has hearing and {@sense truesight} out to a range of 120 feet. It communicates telepathically with its attuned wielder and can speak, read, and understand Common, Draconic, Elvish, and Sylvan. In addition, the sword can ascertain the true value of any gemstone brought within 5 feet of it."
]
},
{
"type": "entries",
"name": "Personality",
"entries": [
"Tearulai admires great beauty, music, fine art, and poetry. Vain, the weapon strives to improve its appearance. It craves gemstones and seeks out better ones with which to adorn itself. Most of all, it longs to return to the forests around Myth Drannor, where it was created. If its wielder's goals run counter to its own, Tearulai attempts to take control of its wielder and escape Undermountain, whereupon it can use its {@spell transport via plants} spell to return whence it came."
]
},
"When you attack an object with this magic sword and hit, maximize your weapon damage dice against the target.",
"When you attack a creature with this weapon and roll a 20 on the attack roll, that target takes an extra 14 slashing damage. Then roll another {@dice d20}. If you roll a 20, you lop off one of the target's limbs, with the effect of such loss determined by the DM. If the creature has no limb to sever, you lop off a portion of its body instead.",
"{@note Note: According to the SRD, it is an extra {@damage 4d6} slashing damage, although {@link this is incorrect|https://rpg.stackexchange.com/a/174522/53884}}.",
"In addition, you can speak the sword's command to cause the blade to shed bright light in a 10-foot radius and dim light for an additional 10 feet. Speaking the command word again or sheathing the sword puts out the light."
],
"attachedSpells": [
"fly",
"polymorph",
"transport via plants"
]
},
{
"name": "Teeth of Dahlver-Nar",
"source": "TCE",
"page": 135,
"rarity": "artifact",
"reqAttune": true,
"wondrous": true,
"bonusAc": "+2",
"modifySpeed": {
"static": {
"fly": 30
}
},
"recharge": "dawn",
"rechargeAmount": 8,
"charges": 8,
"entries": [
"The Teeth of Dahlver-Nar are stories given form. They are a collection of teeth, each suggestive of wildly different origins and made from various materials. The collection rests within a leather pouch, stitched with images of heroes and whimsical creatures. Where the teeth fall, they bring legends to life.",
{
"type": "entries",
"name": "Using the Teeth",
"entries": [
"While you are holding the pouch, you can use an action to draw one tooth. Roll on the Teeth of Dahlver-Nar table to determine which tooth you draw, and you can either sow the tooth or implant it (both of which are described later).",
"If you don't sow or implant the tooth, roll a die at the end of your turn. On an even number, the tooth vanishes, and creatures appear as if you sowed the tooth, but they are hostile to you and your allies. On an odd number, the tooth replaces one of your teeth as if you implanted it (potentially replacing another implanted tooth, see below).",
"Each tooth can only be used once. Track which teeth have been used. If a tooth's result is rolled after it's been used, you draw the next lowest unused tooth on the table.",
{
"type": "table",
"caption": "Teeth of Dahlver-Nar",
"colLabels": [
"d20",
"Tale and Tooth",
"Creatures Summoned",
"Implanted Effect"
],
"colStyles": [
"col-2 text-center",
"col-2",
"col-1",
"col-7"
],
"rows": [
[
"1",
"The Staring Cats of Uldun-dar (ivory cat molar)",
"9 {@creature cat||cats}",
"The tooth has 8 charges. As an action, you can expend 1 charge to cast the {@spell revivify} spell from the tooth. If you are dead at the start of your turn, the tooth expends 1 charge and casts {@spell revivify} on you."
],
[
"2",
"Duggle's Surprising Day (human molar)",
"1 {@creature commoner}",
"When you finish a long rest, the tooth casts {@spell sanctuary} (DC 18) on you, and the spell lasts for 24 hours or until you break it."
],
[
"3",
"The Golden Age of Dhakaan (golden goblin bicuspid)",
"10 {@creature goblin||goblins}, 1 {@creature goblin boss}",
"When you are hit by an attack and an ally is within 5 feet of you, you can use your reaction to cause them to be hit instead. You can't use this reaction again until you finish a short or long rest."
],
[
"4",
"The Mill Road Murders (halfling canine)",
"3 {@creature green hag||green hags} in a coven",
"When you damage a target that hasn't taken a turn in this combat, the target takes an extra {@damage 3d10} slashing damage from ghostly blades."
],
[
"5",
"Dooms of the Malpheggi (emerald lizardfolk fang)",
"1 {@creature lizard queen||lizardfolk queen} and 4 {@creature lizardfolk}",
"You gain reptilian scales, granting you a +2 bonus to your AC. Additionally, when you finish a long rest, you must succeed on a DC 15 Constitution saving throw or gain 1 level of {@condition exhaustion}."
],
[
"6",
"The Stable Hand's Secret (sweet-tasting human canine)",
"2 {@creature Incubus||incubi}",
"When you make a Charisma check against a humanoid, you can roll a {@dice d10} and add the number rolled as a bonus to the result. The creature then becomes hostile to you at the next dawn."
],
[
"7",
"The Donkey's Dream (rainbow-colored donkey molar)",
"1 {@creature unicorn}",
"The tooth has 3 charges. As an action, you can expend 1 charge to touch a creature. The target regains {@dice 2d8 + 2} hit points, and all diseases and poisons affecting it are removed. When you use this action, a shimmering image of a unicorn's horn appears until the end of your turn, sprouting from your forehead. The tooth regains all expended charges daily at dawn. You gain the following flaw: \"When I see wickedness in action, I must oppose it.\""
],
[
"8",
"Beyond the Rock of Bral (silver mind flayer tooth)",
"2 {@creature mind flayer||mind flayers}",
"You gain telepathy out to 120 feet as described in the Monster Manual, and you can cast the {@spell detect thoughts} spell at will, requiring no components. You also have disadvantage on Wisdom ({@skill Insight}) and Wisdom ({@skill Perception}) checks from constant whispers of memories and nearby minds."
],
[
"9",
"The Disappearances of Half Hollow (vomerine tooth of a Large toad)",
"4 {@creature giant toad||giant toads}",
"Your long jump is up to 30 feet and your high jump is up to 15 feet, with or without a running start."
],
[
"10",
"Legendry of Phantoms and Ghosts (obsidian human molar)",
"1 {@creature giant octopus}, 1 {@creature mage}, 1 {@creature specter}",
"As an action, you can use the tooth to cast the {@spell Evard's black tentacles} spell (DC 18). Once this property is used, it can't be used again until the next dawn."
],
[
"11",
"The Thousand Deaths of Jander Sunstar (yellowed vampire fang)",
"1 {@creature vampire}",
"You can make a bite attack as an unarmed strike. On a hit, it deals {@damage 1d6} piercing damage plus {@damage 3d6} necrotic damage. You regain a number of hit points equal to the necrotic damage dealt. While you are in sunlight, you can't regain hit points."
],
[
"12",
"Nightmares of Kaggash (twisted beholder tooth)",
"1 {@creature beholder}",
"As an action, you can cast the {@spell eyebite} spell from the tooth. Once you use this action, it can't be used again until the next dawn. Whenever you finish a long rest, roll a {@dice d20}. On a 20, an aberration chosen by the DM appears within 30 feet of you and attacks."
],
[
"13",
"Three Bridges to the Sky (lapis lazuli oni fang)",
"3 {@creature oni}",
"You gain a flying speed of 30 feet, and you can use the tooth to cast the {@spell detect magic} spell at will. While you are attuned to fewer than 3 magic items, you gain 1 level of {@condition exhaustion} that can't be removed until you are attuned to three or more magic items."
],
[
"14",
"The Claws of Dragotha (broken translucent fang)",
"1 {@creature adult red dracolich|tce}",
"You can use the tooth to cast the {@spell create undead} spell. Once this property is used, it can't be used again until the next dawn. Each time you create an undead creature using the tooth, a {@creature skeleton}, {@creature zombie}, or {@creature ghoul} also appears at a random location within 5 miles of you, searching for the living to kill. A humanoid killed by these undead rises as the same type of undead at the next midnight."
],
[
"15",
"Ashes of the Ages and Eternal Fire (jade humanoid bicuspid)",
"1 {@creature dao}, 1 {@creature djinni}, 1 {@creature efreeti}, 1 {@creature marid}",
"You can use the tooth to cast {@spell counterspell} at 9th level. Once you use this property, it can't be used again until the next dawn. Whenever you finish a long rest, if you haven't used the tooth to counter a spell since your last long rest, your hit point maximum is reduced by {@dice 2d10}. If this reduces your hit point maximum to 0, you die."
],
[
"16",
"Daughters of Bel (green steel pit fiend fang)",
"1 {@creature pit fiend}",
"You can use the tooth to cast {@spell dominate monster} (DC 18). Once you use this property, it can't be used again until the next dawn. You smell strongly of burning sulfur."
],
[
"17",
"Why the Sky Screams (blue dragon fang)",
"1 {@creature ancient blue dragon}",
"You gain immunity to lightning damage and vulnerability to thunder damage."
],
[
"18",
"The Last Tarrasque (jagged sliver of tarrasque tooth)",
"1 {@creature tarrasque} (ignores you and your commands appears for {@dice 1d4} rounds then vanishes)",
"You deal double damage to objects and structures. If you take 20 or more damage in one turn, you must succeed on a DC 18 Wisdom saving throw or spend your next turn in a murderous fury. During this rage, you must use your action to make an unarmed strike against a creature that damaged you, or a random creature you can see if you weren't damaged by a creature, moving as close as you can to the target if necessary."
],
[
"19",
"Incendax's Tooth (ruby-veined red dragon fang)",
"1 {@creature ancient red dragon}",
"You gain immunity to fire damage, and as an action, you can exhale fire in a 90-foot cone. Each creature in that area must make a DC 24 Dexterity saving throw, taking {@damage 26d6} fire damage on a failed save, or half as much damage on a successful one. After using the breath weapon, you gain 2 levels of {@condition exhaustion}."
],
[
"20",
"Dahlver-Nar's Tooth (dusty human molar)",
"1 {@creature priest}",
"As an action you can call on a divine force to come to your aid. Describe the assistance you seek, and the DM decides the nature of the intervention (the effect of any cleric spell would be appropriate). Once this property is used, it can't be used again for 7 days."
]
]
}
]
},
{
"type": "entries",
"name": "Sowing Teeth",
"entries": [
"To sow the tooth, you place it on the ground in an unoccupied space within your reach, or you throw it into an unoccupied space within 10 feet of you in a body of water that is at least 50 feet wide and 50 feet long. Upon doing so, the tooth burrows into the ground and vanishes, leaving no hole behind, or it vanishes into the water. The creatures noted in the Creatures Summoned column appear in an unoccupied space as close to where the tooth was sown as possible. The creatures are allies to you, speak all languages you speak, and linger for 10 minutes before disappearing, unless otherwise noted."
]
},
{
"type": "entries",
"name": "Implanting Teeth",
"entries": [
"To implant the tooth, you place it in your mouth, whereupon one of your own teeth falls out, and the drawn tooth takes its place, resizing to fit in your mouth. Once the tooth is implanted, you gain the effect noted in the Implanted Effect column. The tooth can't be removed while you are attuned to the teeth, and you can't voluntarily end your attunement to them. If removed after your death, the tooth vanishes. You can have a maximum number of the teeth implanted at one time equal to 1 + your Constitution modifier (minimum of 2 teeth total). If you try to implant more teeth, the newly implanted tooth replaces one of the previous teeth, determined randomly. The replaced tooth vanishes, and you lose the implanted effect."
]
},
{
"type": "entries",
"name": "Recovering Teeth",
"entries": [
"Once all the teeth have vanished, their pouch also vanishes. The pouch with all the teeth then appears in a random destination, which could be on a different world of the Material Plane."
]
},
{
"type": "entries",
"name": "Destroying the Teeth",
"entries": [
"Each tooth must be destroyed individually by sowing it in the area where the tooth's story originated, with the intention to destroy it. When planted in this way, creatures summoned are not friendly to you and do not vanish. Some of the creatures summoned merely head off in search of home, while others act as their tales dictate. In either case, the tooth is gone forever."
]
}
],
"attachedSpells": [
"sanctuary",
"counterspell",
"dominate monster"
],
"hasFluffImages": true
},
{
"name": "Tej",
"source": "ToA",
"page": 32,
"type": "FD",
"rarity": "none",
"weight": 5,
"entries": [
"Tej is an amber-colored, fermented drink made from honey. It's more common and popular in Chult than beer or ale. A mug of tej costs 4 cp in Port Nyanzaru or 6 cp in Fort Beluarian. A 1-gallon cask costs 2 sp in the city or 3 sp at the fort."
]
},
{
"name": "Teleportation Tablet",
"source": "CRCotN",
"page": 216,
"rarity": "rare",
"wondrous": true,
"entries": [
"This clay tablet is eight inches long, four inches wide, and half an inch thick. Inscribed on it is the sigil sequence for a permanent teleportation circle. A creature that studies the sequence for 10 minutes can make a DC 21 Intelligence ({@skill Arcana}) check, learning the circle's destination on a success.",
"You can use an action to break the tablet in half, turning it to dust. If the tablet is broken while it is on the same plane of existence as the teleportation circle whose sigil sequence was engraved on it, a 10-foot-diameter teleportation circle of glowing blue light appears on the ground in an unoccupied space you choose within 30 feet of you. This teleportation circle has the characteristics of one created using the {@spell teleportation circle} spell, except that it connects to the teleportation circle whose sigil sequence appears on the tablet.",
"The teleportation circle created by the tablet disappears at the end of your next turn."
]
},
{
"name": "Teleporter Ring",
"source": "WDH",
"page": 157,
"type": "RG",
"rarity": "unknown (magic)",
"entries": [
"As an action, a creature wearing a teleporter ring can activate the teleportation circle either in area K22 or area E1, teleporting itself and up to six other willing creatures from one circle to the other."
]
},
{
"name": "Tentacle Rod",
"source": "DMG",
"page": 208,
"type": "RD",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"weight": 2,
"entries": [
"Made by the {@creature drow}, this rod is a magic weapon that ends in three rubbery tentacles. While holding the rod, you can use an action to direct each tentacle to attack a creature you can see within 15 feet of you. Each tentacle makes a melee attack roll with a +9 bonus. On a hit, the tentacle deals {@damage 1d6} bludgeoning damage. If you hit a target with all three tentacles, it must make a DC 15 Constitution saving throw. On a failure, the creature's speed is halved, it has disadvantage on Dexterity saving throws, and it can't use reactions for 1 minute. Moreover, on each of its turns, it can take either an action or a bonus action, but not both. At the end of each of its turns, it can repeat the saving throw, ending the effect on itself on a success."
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "The Bloody End (Awakened)",
"source": "EGW",
"_copy": {
"name": "The Bloody End (Dormant)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Awakened",
"entries": [
"When the morningstar reaches an awakened state, it gains the following properties:",
{
"type": "list",
"items": [
"The weapon's bonus to attack and damage rolls increases to +2.",
"The saving throw DC for spells cast from the morningstar increases to 15.",
"When you reduce a creature to 0 hit points with an attack using The Bloody End, you can cause the following effect: each creature of your choice within 15 feet of you must succeed on a DC 15 Wisdom saving throw or be {@condition frightened} of you until the end of your next turn. This property can't be used again until the next dawn.",
"When a creature hits you with a melee attack, you can use your reaction to deal {@damage 1d6} psychic damage to the attacker."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"bonusWeapon": "+2"
},
{
"name": "The Bloody End (Dormant)",
"source": "EGW",
"page": 278,
"baseItem": "morningstar|PHB",
"type": "M",
"rarity": "artifact",
"reqAttune": true,
"sentient": true,
"weight": 4,
"weaponCategory": "martial",
"dmg1": "1d8",
"dmgType": "P",
"bonusWeapon": "+1",
"weapon": true,
"entries": [
"Carrying the power of Bane, this adamantine morningstar sports foot-long spikes and has a jagged blade on its pommel.",
{
"type": "entries",
"name": "Sentience",
"entries": [
"The Bloody End is a sentient lawful evil weapon with an Intelligence of 22, a Wisdom of 18, and a Charisma of 24. It has hearing and {@sense darkvision} out to a range of 120 feet.",
"The weapon communicates telepathically with its wielder and can speak, read, and understand Common and Infernal."
]
},
{
"type": "entries",
"name": "Personality",
"entries": [
"A tyrannical {@creature pit fiend} named Izeelzee lives within The Bloody End. The weapon boisterously pushes its wielder to subjugate others and to never back down from a challenge. If the wielder doesn't demand fealty from others, the weapon is happy to demand it on their behalf."
]
},
{
"type": "entries",
"name": "Dormant",
"entries": [
"The morningstar grants the following benefits in its dormant state:",
{
"type": "list",
"items": [
"You can speak, read, and write Infernal.",
"You gain a +1 bonus to attack and damage rolls made with this magic weapon.",
"While holding the morningstar, you can use an action to cast one of the following spells from it (save DC 13): {@spell charm person}, {@spell dominate person}, or {@spell fear}. Once a spell has been cast using the morningstar, that spell can't be cast from it again until the next dawn.",
"While holding the morningstar, you have advantage on Charisma ({@skill Intimidation}) checks."
]
}
]
},
{
"type": "inset",
"name": "Betrayer Artifact Properties",
"entries": [
"The Arms of the Betrayers advance in power in the same manner as the Vestiges of Divergence. In its dormant state, each of these artifacts has one {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} property and one {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental} property. When the artifact attains an awakened state, it gains an additional {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} property and an additional {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental} property. When the item reaches its exalted state, it gains a {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial} property. See \"Artifact Properties\" in chapter 7 of the Dungeon Master's Guide for more information."
]
}
],
"attachedSpells": [
"charm person",
"dominate person",
"fear"
]
},
{
"name": "The Bloody End (Exalted)",
"source": "EGW",
"_copy": {
"name": "The Bloody End (Awakened)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Exalted",
"entries": [
"When the morningstar reaches an exalted state, it gains the following properties:",
{
"type": "list",
"items": [
"The weapon's bonus to attack and damage rolls increases to +3.",
"Add {@spell dominate monster} to the list of spells that can be cast from the morningstar.",
"The saving throw DC for spells cast from the morningstar, as well as for creatures to avoid being {@condition frightened} by it, increases to 17.",
"When you use your reaction to deal psychic damage to a creature that hits you with a melee attack, the amount of psychic damage increases to {@dice 2d6}."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"bonusWeapon": "+3"
},
{
"name": "The Codicil of White",
"source": "IDRotF",
"page": 317,
"rarity": "unknown (magic)",
"wondrous": true,
"entries": [
"The Codicil of White is a tall, thin volume bound in white ermine fur over seasoned boards of white pine and sealed with a clasp and lock of tarnished silver. The book is cold to the touch, and the fur is worn about the edges from use. The twenty-seven pages within are of vellum painted with silver gilt on the outer edges. The whole is sewn to a leather binding with strips of sinews, making it quite durable.",
"A creature with the codicil in its possession has resistance to cold damage.",
"The codicil was written by followers of Auril as a primer on her worship. The first page is a title page with the snowflake symbol of Auril on it. The remaining pages describe various priestly rituals and ceremonies in chilling detail. Nestled among these descriptions is a spell that wizards can learn ({@spell frost fingers|IDRotF}), and a poem called \"Rime of the Frostmaiden\" (see appendix E). The poem is an incantation, the power of which can be used to split a glacier (see chapter 6). The poem might have other capabilities, at your discretion"
],
"hasFluffImages": true
},
{
"name": "The Incantations of Iriolarthas",
"source": "IDRotF",
"page": 317,
"type": "G",
"rarity": "unknown",
"weight": 3,
"entries": [
"The Incantations of Iriolarthas is a weighty spellbook. Its black leather covers have dead, toothy worms glued to them, sheathed in glossy varnish. Set into this morbid display on the front cover is a gold rune that resembles a stylized eye with a pupil shaped like a candle flame—the sigil of Iriolarthas, a Netherese lich.",
"The book contains sixty pages of brittle yellow vellum. Written on these pages are the following wizard spells:",
{
"type": "list",
"style": "list-hang-notitle",
"items": [
"1st level: {@spell alarm}, {@spell detect magic}, {@spell identify}, {@spell magic missile}, {@spell shield}, {@spell Tasha's hideous laughter}, {@spell thunderwave}",
"2nd level: {@spell arcane lock}, {@spell continual flame}, {@spell invisibility}, {@spell knock}, {@spell levitate}, {@spell Melf's acid arrow}, {@spell mirror image}",
"3rd level: {@spell animate dead}, {@spell bestow curse}, {@spell clairvoyance}, {@spell counterspell}, {@spell dispel magic}, {@spell fireball}, {@spell glyph of warding}",
"4th level: {@spell arcane eye}, {@spell banishment}, {@spell blight}, {@spell dimension door}, {@spell Evard's black tentacles}, {@spell phantasmal killer}",
"5th level: {@spell Bigby's hand}, {@spell cloudkill}, {@spell dominate person}, {@spell planar binding}, {@spell scrying}, {@spell telekinesis}",
"6th level: {@spell create undead}, {@spell disintegrate}, {@spell globe of invulnerability}, {@spell move earth}, {@spell Otto's irresistible dance}",
"7th level: {@spell create magen|IDRotF}, {@spell finger of death}, {@spell prismatic spray}, {@spell teleport}",
"8th level: {@spell demiplane}, {@spell dominate monster}, {@spell mind blank}, {@spell power word stun}",
"9th level: {@spell blade of disaster|TCE}, {@spell power word kill}"
]
}
],
"hasFluffImages": true
},
{
"name": "The Infernal Machine of Lum the Mad",
"source": "IMR",
"page": 96,
"rarity": "artifact",
"reqAttune": true,
"wondrous": true,
"bonusWeaponAttack": "+1",
"modifySpeed": {
"static": {
"fly": 150
}
},
"entries": [
"This strange device was once thought to have been built by gods long forgotten and to have survived the eons since their passing, for it is incredibly ancient and crafted by means unlike anything known today. However, its true origins derive from a planar craft that crashed in the Barrier Peaks, for the Infernal Machine once functioned as this craft's central command console.",
"Explorers who discovered the crash site removed the command console and brought it back to civilization, not understanding its true purpose or powers. In later years, the console came into the possession of Baron Lum. By experimenting with its many controls, he was able to achieve astounding and destructive magical effects in the building of his empire, and so the artifact came to be known as the Infernal Machine of Lum the Mad. Yet with Lum's eventual defeat, his great machine was destroyed.",
"The Infernal Machine is a delicate, intricate, bulky, and heavy device, weighing some 5,500 pounds. It can be operated using a control panel containing sixty levers, forty dials, twenty switches, and a number of jeweled components. These controls generate all kinds of powers and effects, as noted below.",
{
"type": "entries",
"name": "Silver Wire",
"entries": [
"The Infernal Machine's great size makes it largely immobile. To make ongoing use of the machine (such as while adventuring), it can be connected to its attuned user by a silver wire, a supply of which can always be produced from the machine's inner workings. This silver wire shares the same general nature as the silvery cord of an {@spell astral projection} spell, connecting to the body of the user and trailing behind them. When so attached, the wire becomes {@condition invisible}, astral, and extends to virtually infinite length.",
"As long as the wire remains intact, the attuned user can make full use of the Infernal Machine's powers, with their effects centered around the user. If the wire is cut—something that can happen only when an effect specifically states that it can cut an {@spell astral projection}'s silvery cord—the user suffers a sudden burst of feedback from the machine that kills them instantly.",
"Any effect of the Infernal Machine that requires concentration can be concentrated on by a remote user. The Infernal Machine's effects have a spell save DC of 14 or the attuned user's spell save DC, whichever is higher."
]
},
{
"type": "entries",
"name": "Summon Monster",
"entries": [
"When first used by Baron Lum, the Infernal Machine was known for its ability to unleash terrible monsters within the world. Specific combinations of its controls can still recreate the effects of various spells of the conjuration school, as follows:",
{
"type": "list",
"style": "list-hang-notitle",
"items": [
"1/day: {@spell conjure animals}, {@spell summon lesser demons|XGE}",
"1/7 days: {@spell conjure minor elementals}, {@spell conjure woodland beings}, {@spell summon greater demon|XGE}",
"1/month: {@spell conjure elemental}, {@spell conjure fey}"
]
}
]
},
{
"type": "entries",
"name": "Wish",
"entries": [
"The Infernal Machine has the ability to bend the nature of reality, with the proper combination of controls recreating the effect of a {@spell wish} spell (including all side effects). Due to the complex nature of its programming, this feature can be used only once per month. Each time this effect is used, there is a cumulative {@chance 10|10 percent} chance of the Infernal Machine malfunctioning, necessitating seven days' worth of repairs and reprogramming, as well as the expenditure of 500 gp in parts, before it can be used again. When these repairs are done, the cumulative chance of malfunction returns to 10 percent."
]
},
{
"type": "entries",
"name": "Random Properties",
"entries": [
"There are far more possible combinations for the Infernal Machine's controls than can ever be known or matrixed—especially as the controls shift position and reset themselves over time. At the end of each long rest of the user attuned to it, the Infernal Machine generates {@dice 1d4 + 1} random beneficial properties and {@dice 1d4 + 1} detrimental properties. Roll for each of these properties on the Infernal Machine Properties table. To keep things interesting, the DM might roll secretly for detrimental properties, revealing those properties only during the course of play.",
"Once set, these properties last until the end of the attuned user's next long rest.",
"(Our thanks to the D&D community for their suggestions for the properties of the Infernal Machine of Lum the Mad!)"
]
},
{
"type": "entries",
"name": "Destroying the Machine",
"entries": [
"The Infernal Machine of Lum the Mad is self-repairing of anything short of catastrophic damage, and how it was disabled in Lum's time remains a mystery. It is rumored that if one specific combination of controls is set, the Infernal Machine will enter a repair mode, allowing unfettered access to its inner workings—and preventing it from restoring itself if it is attacked. Alternatively, other combinations of controls might cause the Infernal Machine to teleport its most critical components to some hiding place deep in space and time, rendering it inoperable until those components are found again."
]
},
{
"type": "table",
"caption": "Infernal Machine Properties",
"colLabels": [
"d100",
"Beneficial Property",
"Detrimental Property"
],
"colStyles": [
"col-2 text-center",
"col-5",
"col-5"
],
"rows": [
[
"01",
"You have a flying speed of 150 feet but lose all other movement.",
"You are {@condition blinded}."
],
[
"02",
"You can cast the {@spell speak with animals} spell at will, but can use it to speak with only one animal of your choice.",
"All animals despise you. You have disadvantage on Wisdom ({@skill Animal Handling}) checks."
],
[
"03",
"One weapon or item you carry of your choice gains a walking speed of 30 feet and goes where you tell it.",
"Your armor and clothing feels unnaturally tight. Unless you go naked, roll a {@dice d4} each time you are attacked and subtract the number rolled from your AC."
],
[
"04",
"You can cast the {@spell legend lore} spell three times.",
"Whenever you make an Intelligence check or use divination magic to learn something about a creature, that knowledge comes with added trivia of the DM's devising."
],
[
"05",
"You emanate a weak magnetic aura. The first time during a combat that a creature attacks you with a metal weapon, that creature has disadvantage on the attack roll.",
"Metal rusts in response to your touch—including metal armor you wear and metal weapons you hold—reproducing the effect of the rust monster's Rust Metal trait."
],
[
"06",
"Your fingers sprout tiny clockwork picks, granting you advantage on ability checks made using {@item thieves' tools|PHB} or {@item tinker's tools|PHB}.",
"Any metal object you wield or wear is covered in a thin layer of oil that drips occasionally and leaves your fingerprints everywhere."
],
[
"07",
"You can cast the {@spell speak with animals} spell at will, but can use it to communicate only with insects.",
"Disturbing whispers on the edge of hearing haunt all creatures within 30 feet of you, causing those creatures to recall their saddest memories."
],
[
"08",
"You have a +1 bonus to Wisdom ({@skill Insight} checks) involving creatures you can see.",
"Your body is ghostly and translucent except for your bones. You have vulnerability to radiant damage, and you have disadvantage on attack rolls while in direct sunlight."
],
[
"09",
"You summon a {@creature unicorn} that obeys your commands. If you give a command that violates the unicorn's allignment, the unicorn immediately vanishes.",
"You grow a unicorn horn."
],
[
"10",
"A specialized clockwork compass floats in front of you. You can name one character, place, or object on the same plane as you and have the compass point constantly in the direction of the named target. You gain no knowledge of the distance to the target.",
"A specialized clockwork compass floats in front of you, and points toward you. The DM determines whether a creature currently looking for you is drawn to your location. Additionally, when a creature attacks you for the first time during an encounter, it has advantage on the attack roll."
],
[
"11",
"Your clothes are wrapped in illusion that makes them appear to be the very latest style, drawing praise and attention. You have a +1 bonus to Charisma checks.",
"Any food and drink you eat spoils as you swallow it. Each time you eat or drink, you are {@condition poisoned} for 1 hour."
],
[
"12",
"You have a +1 bonus to attack rolls with weapons or spells (your choice).",
"The {@spell cure wounds} spell restores no hit points for you."
],
[
"13",
"You have an innate sense of direction. You automatically succeed on ability checks made to avoid becoming lost.",
"Whenever you move, a magical voice calls out your direction and how far you've gone. The voice cannot be silenced or stopped."
],
[
"14",
"You constantly see afterimages of all events that occurred within 30 feet of you over the previous hour.",
"You are distracted by voices and visions, forcing you to succeed on a Wisdom saving throw every 4 hours or gain one level of {@condition exhaustion}."
],
[
"15",
"You understand all languages, as if under the effect of a {@spell comprehend languages} spell.",
"Whenever you speak, you speak in a random language (possibly including languages you don't normally know)."
],
[
"16",
"You have +1 bonus to initiative rolls.",
"You are unable to hear any speech louder than a whisper."
],
[
"17",
"An exact duplicate of you appears next to you, dressed as you but lacking any gear. The duplicate functions as a familiar and has the statistics of a {@creature cat}.",
"An exact duplicate of you appears next to you, dressed as you but lacking any gear. It loudly tries to convince all other creatures that it is you, and you are its familiar. If destroyed, it reappears in {@dice 1d4} hours."
],
[
"18",
"While you are afflicted by a disease or suffering the {@condition poisoned} or {@condition frightened} condition, whenever you hit a humanoid with a melee attack, the target must succeed on a DC 15 Constitution saving throw. On a failed save, you transfer the disease or the condition to the target, and the disease or condition ends for you.",
"Water becomes intensely acidic to you. If you are submerged in water, you take {@damage 1d6} acid damage at the start of each of your turns, and drinking pure water causes you to be {@condition poisoned} for 1 hour."
],
[
"19",
"A random Huge beast appears next to you (chosen by the DM) as if with the {@spell conjure animals} spell. You have a telepathic link to this beast, such that you can give it commands even if it can't hear you.",
"A random Huge beast appears next to you (chosen by the DM) and attacks at once."
],
[
"20",
"You have 1 luck point, which you can use as if you had taken the Lucky feat. If you have taken that feat, you have 1 extra luck point.",
"Those around you perceive you as dishonest, even when you are completely truthful. You automatically fail Charisma checks."
],
[
"21",
"If you drop to 0 hit points, a phantom guardian appears next to you, functioning as if you had cast the {@spell Mordenkainen's faithful hound} spell. The guardian disappears if you die, regain hit points, or regain consciousness.",
"No one takes you seriously, imposing disadvantage on Charisma ({@skill Deception} and {@skill Persuasion}) checks, and causing you to automatically fail Charisma ({@skill Intimidation}) checks."
],
[
"22",
"You can cast the {@spell gaseous form} spell on yourself three times. When you do, instead of appearing as mist, you appear as a swirling puff of feathers.",
"Your body is covered in feathers."
],
[
"23",
"You have advantage on death saving throws.",
"You have disadvantage on all saving throws except death saving throws."
],
[
"24",
"You can cast {@spell speak with animals} at will, but can use it to communicate only with dogs.",
"All cats are automatically hostile toward you."
],
[
"25",
"You can cast the {@spell raise dead} spell once, requiring no material components.",
"If you drop to 0 hit points, you automatically suffer 1 failed death saving throw."
],
[
"26",
"Each time you finish a short rest, up to 100 silver pieces already in your possession become gold pieces, and up to 100 copper pieces become silver pieces.",
"All coins you touch become worthless tin."
],
[
"27",
"Anything poisonous within 50 feet of you is automatically detected and marked by the image of a small frog that is visible only to you.",
"A dozen small frogs constantly follow you around, chirping loudly."
],
[
"28",
"You gain the benefit of a {@spell nondetection} spell.",
"Your appearance changes randomly as determined by the DM, as if you were under the effect of a {@spell disguise self} spell."
],
[
"29",
"You can cast the {@spell mage hand} cantrip at will.",
"A disembodied spirit follows you around, stealing and scattering coins from you, crumbling your food, and playing with your weapons and gear."
],
[
"30",
"You are able to decipher any text.",
"You lose the ability to speak any language. This has no effect on casting spells with verbal components."
],
[
"31",
"You can cast the {@spell speak with plants} spell three times.",
"Your skin turns green, and you must drink a half gallon of fresh water every 4 hours or take {@damage 1d4} necrotic damage."
],
[
"32",
"You know whether creatures within 15 feet of you are lying, as if you were the center of a {@spell zone of truth} spell.",
"Whenever you would speak falsely, you cannot speak at all."
],
[
"33",
"You gain a sense of destiny that directs you toward an important goal, as determined by the DM.",
"Your head is filled with an unpleasant buzzing that imposes disadvantage on Intelligence checks and saving throws."
],
[
"34",
"All creatures you speak with automatically believe your words, as if you have succeeded on a Charisma ({@skill Deception} or {@skill Persuasion}) check against them. Any sign of threatening or duplicitous action on your part negates this automatic success.",
"Your personal tastes reverse, such that your favorite foods, hobbies, music, and so forth become unbearable to you."
],
[
"35",
"Your shadow becomes an undead {@creature shadow} that shares your alignment and is under your control.",
"Your shadow behaves disapprovingly whenever you're not looking—stealing things, mocking you behind your back, taunting opponents or friends, and so on."
],
[
"36",
"Your skin is covered by slime. You automatically succeed on ability checks and saving throws made to escape a grapple.",
"Your skin is covered by slime. You must succeed on a DC 10 Dexterity saving throw to sit down, open doors, and perform other mundane tasks of the DM's determination."
],
[
"37",
"Flowers turn to face you as you pass them.",
"Mundane animals can't look at you."
],
[
"38",
"Each time you finish a short rest, you suddenly sprout a covering of fragrant leaves. The leaves fall off after {@dice 1d10} minutes, revealing {@dice 1d6} magic fruits that can be plucked from your head. Each fruit functions as a berry created with the {@spell goodberry} spell.",
"Whenever you stop moving for 10 minutes or more, you discover that your boots or shoes have set down roots that must be pulled free with a successful DC 20 Strength check."
],
[
"39",
"Choose a creature you can see. That creature must succeed on a DC 15 Dexterity saving throw or drop one item they are holding (DM's choice). You can use this property three times.",
"Each time you roll a 1 on a Dexterity check, you drop one object you are holding (DM's choice)."
],
[
"40",
"A group of small forest animals appears and follows you everywhere, singing and dancing. These magical creatures can perform any task you desire that could be performed by a {@spell prestidigitation} spell.",
"You break out into song whenever you roll a 1 on any die."
],
[
"41",
"Your possessions change their state or appearance at the DM's determination, giving you hints of potential future events. For example, your sword might become suddenly bloodstained to warn of an upcoming combat encounter, your clothes might become damp to warn of a water-filled pit trap ahead, and so on.",
"You take a −5 penalty to initiative rolls."
],
[
"42",
"Whenever you roll a 1 on a {@dice d20}, you have advantage on your next {@dice d20} roll.",
"Whenever you roll a 1 on a {@dice d20}, you have disadvantage on your next {@dice d20} roll."
],
[
"43",
"Grass, moss, and flowers instantly grow in your footsteps. You are also unaffected by {@quickref difficult terrain||3} created by plants.",
"Any harm that befalls a plant you can see (a woodcutter sawing down a tree, a gardener pulling weeds, and so forth) fills you with a sadness that imposes disadvantage on ability checks for 1 minute."
],
[
"44",
"You automatically know the direction to the greatest danger within 500 feet of you.",
"You leave a trail of black footprints that allow anyone to automatically follow you."
],
[
"45",
"You can cast the {@spell detect thoughts} spell three times.",
"All creatures within 30 feet of you automatically know your surface thoughts, as if having cast {@spell detect thoughts} against you."
],
[
"46",
"You can cast the {@spell misty step} spell three times. When you do, you must begin and end your teleportation in an area of shadow.",
"While in direct sunlight, you have disadvantage on attack rolls as well as on Wisdom ({@skill Perception}) checks that rely on sight."
],
[
"47",
"Creatures cannot gain advantage on attacks made against you as a result of another creature using the {@action Help} action.",
"You have disadvantage on Dexterity ({@skill Acrobatics}) checks."
],
[
"48",
"You have increased lung capacity, increasing your walking speed by 10 feet and letting you hold your breath for twice as long as normal.",
"Whenever you exert yourself by fighting, running, and so forth, you breathe out intermittent gouts of flame that deal {@damage 1d4} fire damage to you."
],
[
"49",
"You can cast the {@spell daylight} spell three times.",
"An evil undead creature that comes within 30 feet of you for the first time is {@condition charmed} by you until the end of your next turn."
],
[
"50",
"When you touch a copper piece, it transmutes to become two copper pieces. You can duplicate a maximum of 1,000 coins this way.",
"Food doesn't satiate you, and you must eat double the amount of food you normally eat to avoid feeling hungry."
],
[
"51",
"You shed light like a torch, as if you were the target of a {@spell light} cantrip.",
"No one else can take a short rest within 40 feet of you, as everything you say or do seems distracting to them."
],
[
"52",
"You can cast the {@spell divination} spell once.",
"Whenever you make an Intelligence check or saving throw, you receive a grim vision of the DM's determination, imposing disadvantage on your next attack roll, ability check, or saving throw."
],
[
"53",
"As an action, you can become incorporeal for 10 minutes, allowing you to move through other creatures and objects as if they were {@quickref difficult terrain||3}. You take 5 ({@damage 1d10}) force damage if you end your turn inside an object. You can use this property once.",
"Your senses diminish as if the world were faded around you, imposing disadvantage on Wisdom ({@skill Perception}) checks."
],
[
"54",
"While you take a short rest, the area within 50 feet of you takes on the illusory appearance of a beautiful forest glade.",
"The first time an ally you can see takes damage as a result of failing a saving throw, you take the damage instead of them."
],
[
"55",
"As a bonus action, you can choose one creature within 10 feet of you and know one secret about it, as determined by the DM.",
"The {@spell remove curse}, {@spell lesser restoration}, and {@spell greater restoration} spells have no effect on you."
],
[
"56",
"You can predict the weather conditions for the next 24 hours with perfect accuracy.",
"A rain cloud follows you around, drizzling on you constantly even while indoors."
],
[
"57",
"You have telepathy out to a range of 120 feet.",
"You hear the voices of creatures you can see in your head from time to time (as detailed by the DM), but you have no idea whether what you're hearing is the truth or not."
],
[
"58",
"You can cast {@spell speak with animals} at will, but you can use it to communicate only with goats. Any goat you speak with becomes {@condition charmed} by you for 1 hour.",
"You become distraught unless your clothing is all the same color, imposing disadvantage on Intelligence, Wisdom, and Charisma checks and saving throws."
],
[
"59",
"You have resistance to fire damage.",
"You gain no benefit from any potion."
],
[
"60",
"You have immunity to the {@condition charmed} and {@condition frightened} conditions.",
"You can speak only in a monotone voice, imposing disadvantage on Charisma checks."
],
[
"61",
"You and each creature within 10 feet of you have resistance to necrotic damage.",
"Any Wisdom ({@skill Medicine}) check you make or that is made to help you is made with disadvantage."
],
[
"62",
"The first creature you hit with a weapon attack on each of your turns takes {@damage 1d4} fire damage at the start of its next turn.",
"You are {@condition blinded} while in bright light, and your shadow takes on a monstrous appearance."
],
[
"63",
"No creature can learn your alignment by magical means, and you have advantage on Charisma checks involving creatures that also share your alignment.",
"You have disadvantage on Charisma checks involving creatures that do not share your alignment."
],
[
"64",
"As a bonus action, you cause yourself and a willing ally you can see to swap positions by teleportation.",
"Whenever you roll a 1 on a {@dice d20} roll, you and an ally you can see swap positions by teleportation."
],
[
"65",
"As an action, you cause {@quickref difficult terrain||3} within 50 feet of you to become normal terrain. You can use this property three times.",
"Each time you move through {@quickref difficult terrain||3}, your speed is reduced by 10 feet until the end of your next turn."
],
[
"66",
"You increase one ability score of your choice by 2. You can increase an ability score above 20 using this property, but the increase is lost when this property ends.",
"Whenever you roll a 1 on an attack roll, ability check, or saving throw using your highest ability score, you take {@damage 1d4} psychic damage."
],
[
"67",
"As an action, you learn the direction and distance to the nearest magic item not in the possession of you or any of your allies.",
"Each creature within 30 feet of you automatically knows which magic items you carry."
],
[
"68",
"As an action while you are in dim light or darkness, you can become {@condition invisible}, as if targeted by the {@spell invisibility} spell. In addition to becoming visible if you attack or cast a spell, you become visible if you move into bright light. You can use this property three times.",
"While in bright light, you have disadvantage on initiative rolls."
],
[
"69",
"You can read and understand all the languages commonly spoken within 1 mile of you, as determined by the DM.",
"While speaking, you occasionally use nonsense words, imposing disadvantage on Charisma checks."
],
[
"70",
"You know one cantrip of the DM's choice, and can cast one 1st-level spell of the DM's choice at will.",
"Whenever you cast a spell or use a magic item property that requires an action or a bonus action to activate, roll on the {@table Wild Magic Surge|PHB} table (see the sorcerer section in chapter 3 of the Player's Handbook) to see what happens."
],
[
"71",
"Whenever you roll a 20 on a {@dice d20}, you can immediately end one effect on you.",
"Whenever you roll a 1 on a {@dice d20}, you are {@condition blinded} until the end of your next turn."
],
[
"72",
"Any clothing you wear is covered with a magical map image that shows all the local area you are aware of. This map doesn't reveal anything you don't know, but grants advantage on ability checks to find secret doors and to navigate back to where you've been.",
"Any rope you touch transforms into a {@creature poisonous snake} that attacks you."
],
[
"73",
"You summon a {@creature slithering tracker|VGM} that obeys your commands.",
"You take on the appearance of having translucent skin, with your bones and organs slightly visible within."
],
[
"74",
"You have a fresh-scrubbed appearance, and a sweet perfume surrounds you. You have advantage on Charisma ({@skill Persuasion}) checks.",
"Whenever you fail a Charisma ({@skill Persuasion}) check, the subject of the check becomes irrationally resentful of you, seeking to undermine you in ways determined by the DM."
],
[
"75",
"You can cast the {@spell control weather} spell.",
"Each time you deal or take cold damage or fire damage, you must succeed on a DC 10 Charisma saving throw or accidentally summon a hostile {@creature air elemental} or {@creature fire elemental} (depending on the damage dealt)."
],
[
"76",
"A tiny construct resembling a {@creature gynosphinx} follows you around. It can truthfully answer up to ten yes-or-no questions regarding the campaign, at the DM's discretion.",
"A tiny construct resembling a {@creature gynosphinx} follows you around. It constantly asks unsolvable riddles of other creatures, and insults those creatures when they fail to answer correctly."
],
[
"77",
"A creature appears next to you that is a hybrid of any two nonmagical beasts (akin to the {@creature owlbear}). The creature serves you as if created by a {@spell conjure animals} spell. The DM determines the creature's statistics.",
"A creature appears next to you that is a hybrid of any two nonmagical beasts (akin to the {@creature owlbear}). Unless you succeed on a DC 12 Wisdom ({@skill Animal Handling}) check, the creature attacks you before wandering off. The DM determines the creature's statistics."
],
[
"78",
"You can cast the {@spell teleport} spell, always arriving on target when you do. You can use this property once.",
"Each time you walk through a doorway or similar portal, you must succeed on a DC 10 Intelligence check or step into a skewed timeline. On a failure, you and all allies within 10 feet of you skip ahead {@dice 1d4} minutes. To other creatures, it appears that you and other affected creatures disappear for that length of time, then appear again in the doorway."
],
[
"79",
"You can cast the {@spell haste} spell on yourself. You can use this property three times.",
"Each time you are reduced to 0 hit points then stabilized, you suffer the effect of a {@spell slow} spell."
],
[
"80",
"You do not need to breathe and cease to do so, making you immune to inhaled poisons and suffocation.",
"Whenever you speak (including casting a spell with a verbal component), you must first use a bonus action to breathe."
],
[
"81",
"Your blood is venomous, granting you immunity to poison and to the {@condition poisoned} condition. You can cast the {@spell speak with animals} spell at will, but you can use it to communicate only with snakes.",
"Your tongue is long and forked, causing you to speak in a hiss. You have disadvantage on Charisma checks involving speech, and you must succeed on a DC 15 Constitution check to cast a spell with a verbal component."
],
[
"82",
"You grow gills and have the ability to breathe air and water. You also sprout a pair of tentacles from your shoulders that have a reach of 10 feet and can be used to perform any action you could perform with your hands. The tentacles cannot attack or grapple.",
"You are constantly followed by {@dice 1d4} {@creature octopus||octopuses} that can breathe air and water, and which have a walking speed of 30 feet. Each time combat breaks out, you must succeed on a DC 13 Wisdom ({@skill Animal Handling}) check or have the octopuses panic and attack you."
],
[
"83",
"You can cast the {@spell misty step} spell three times. When you do, you must begin and end your teleportation in an area of fire. Any fire damage you take during the same turn in which you use {@spell misty step} is restored automatically at the end of your turn.",
"Whenever you start your turn within 30 feet of an ongoing fire effect, you must succeed on a DC 13 Wisdom saving throw or move toward the fire and into it if possible."
],
[
"84",
"When you look at the sky, you see it overlaid with navigational symbols. You have advantage on ability checks made to avoid becoming lost while outdoors.",
"You have disadvantage on Wisdom ({@skill Perception}) checks while indoors."
],
[
"85",
"You can cast the {@spell healing word} spell three times.",
"Undead are drawn to you, making it impossible for you to hide from them. Each undead creature you face in combat has advantage on its first attack roll against you."
],
[
"86",
"Any food you touch is transformed into a culinary delight. You have advantage on Charisma checks while interacting with any creatures that have eaten of this food.",
"You constantly hear distracting music that throws off your timing, imposing disadvantage on initiative rolls and Dexterity checks."
],
[
"87",
"Your mind is filled with lost facts and lore, granting advantage on Intelligence checks.",
"You forget your name, and must be constantly reminded of it by your allies."
],
[
"88",
"Your touch can change small nonmagical objects into chocolate.",
"Anything you eat or drink makes you feel overly sated and full, imposing disadvantage on Strength checks for 1 hour."
],
[
"89",
"Each time you finish a short rest, you regain {@dice 1d8} hit points. Each creature that took a short rest with you and remained within 30 feet of you for the duration also gains this benefit.",
"Each time you finish a short rest, you take {@damage 3d8} psychic damage. At your determination, you can transfer some or all of this damage to any creatures that took a short rest with you and remained within 30 feet of you for the duration."
],
[
"90",
"Each time you finish a short rest, you find {@dice 1d10} small gems worth 10 gp each in your possession.",
"Whenever you spend coins or gems, any character you give them to becomes convinced that the wealth was stolen from them."
],
[
"91",
"When you hit a creature for the first time during an encounter, its Armor Class is reduced by 1.",
"Whenever you roll a 1 on a weapon attack roll, the weapon shatters like glass. The weapon magically reforms in {@dice 1d4} rounds."
],
[
"92",
"Each time you enter a settlement, you are treated as though you are known there and a friend, even if you are neither.",
"Each time you roll a 1 on an attack roll, ability check, or saving throw, you grow one inch taller. Your clothing and armor does not grow with you."
],
[
"93",
"While standing in a doorway, you can cast the {@spell teleport} spell. Your destination must be another doorway you know. You can use this property once.",
"Each time you finish a short rest, you forget one of your allies (of the DM's choice) and no longer trust that character. You lose this distrust at the end of your next short rest."
],
[
"94",
"As an action, you cause a swirling storm of rocks and dust to surround you in a 10-foot radius for 1 minute. Each creature hostile to you that enters the area must succeed on a DC 12 Constitution saving throw or take {@damage 1d6} bludgeoning damage and be {@condition blinded} for 1 round. You can use this property three times.",
"{@creature fire elemental||Fire elementals}, {@creature water elemental||water elementals}, and other creatures of fire and water (as determined by the DM) regard you as their enemy, and focus their attacks against you to the exclusion of all else."
],
[
"95",
"As an action, you learn the names of every humanoid creature within 30 feet of you.",
"Whenever you roll a 1 on a Strength or Dexterity check that incorporates touching an object, you stick to that object for 1 minute. It takes a successful DC 20 Strength check to free yourself."
],
[
"96",
"You can cast the {@spell modify memory} spell once.",
"Whenever you make an Intelligence check or saving throw, you must succeed on a DC 13 Charisma saving throw or lose all your memories of the past hour."
],
[
"97",
"You can ignore the verbal component of any spell you cast.",
"You must shout the verbal component of any spell you cast."
],
[
"98",
"As an action, you conjure a mindless, shapeless force that is your exact duplicate. You control this force as if it were the product of an {@spell unseen servant} spell, except that it is visible.",
"Each time you see your own reflection, you must succeed on a DC 12 Charisma saving throw. On a failure, you treat your allies as enemies and vice versa for 1 minute, attacking the wrong targets, betraying your companions in social situations, and so forth. You can repeat the saving throw at the end of each of your turns, ending the effect on a success."
],
[
"99",
"As an action, you can replace one of your hands with any nonmagical weapon, tool, or piece of adventuring equipment of reasonable size (as determined by the DM).",
"Your hands feel unnaturally large, imposing disadvantage on Dexterity ({@skill Sleight of Hand}) checks and Dexterity checks using {@item thieves' tools|PHB} or {@item artisan's tools|PHB}. The first weapon attack roll you make in any encounter is made with disadvantage."
],
[
"00",
"All creatures of a particular type other than humanoid (as chosen by the DM) are incapable of acting hostile toward you as long as you do not attack, threaten, or provoke them.",
"Whenever you meet a creature of a particular type other than humanoid (as chosen by the DM), there is a 1 percent cumulative chance that the {@creature tarrasque} is magically summoned within 1 mile of you. The tarrasque has no knowledge of your existence. It runs amok for {@dice 1d6 × 10} minutes, then magically returns to its previous location."
]
]
}
],
"attachedSpells": [
"conjure animals",
"summon lesser demons|XGE",
"conjure minor elementals",
"conjure woodland beings",
"summon greater demon|XGE",
"conjure elemental",
"conjure fey",
"wish",
"speak with animals",
"legend lore",
"Mordenkainen's faithful hound",
"gaseous form",
"raise dead",
"nondetection",
"mage hand",
"speak with plants",
"goodberry",
"prestidigitation",
"detect thoughts",
"misty step",
"daylight",
"light",
"divination",
"remove curse",
"lesser restoration",
"greater restoration",
"invisibility",
"teleport",
"haste",
"slow",
"healing word",
"modify memory",
"unseen servant",
"comprehend languages",
"zone of truth"
]
},
{
"name": "Theki Root",
"source": "EGW",
"page": 70,
"type": "G",
"rarity": "none",
"value": 300,
"entries": [
"This thick marsh root tastes bitter but is thought to aid digestive health. When you use an action to consume a dose, you gain advantage on saving throws against the effects of poisonous or toxic substances for 8 hours."
]
},
{
"name": "Thelarr",
"source": "SCAG",
"page": 124,
"type": "INS",
"rarity": "none",
"entries": [
"Also known as a whistlecane, a simple and easy-to-make wind instrument cut from a reed. They are so simple, in fact, that skilled bards frequently make and give them away to children—to the parents' delight or regret."
]
},
{
"name": "Thermal Cube",
"source": "IDRotF",
"page": 316,
"rarity": "common",
"wondrous": true,
"entries": [
"This 3-inch cube of solid brimstone generates enough dry heat to keep the temperature within 15 feet of it at 95 degrees Fahrenheit (35 degrees Celsius)."
]
},
{
"name": "Thessaltoxin",
"source": "IMR",
"page": 93,
"rarity": "none",
"poison": true,
"entries": [
"This poison was first created using blood harvested from the artificer Thessalar. A creature subjected to the poison must succeed on a DC 15 Constitution saving throw or take on a new form as if affected by the {@spell polymorph} spell. The creature's new form is a random beast or a creature it has seen within the last 24 hours (as chosen by the DM). This transformation lasts until the end of the target's next long rest. This effect is not subject to {@spell dispel magic} or {@spell remove curse}, but a {@spell greater restoration} spell restores the creature to its original form."
],
"poisonTypes": [
"ingested",
"injury"
],
"attachedSpells": [
"polymorph"
]
},
{
"name": "Thessaltoxin Antidote",
"source": "IMR",
"page": 95,
"type": "P",
"rarity": "uncommon",
"entries": [
"This potion appears to change color and texture each time you look at it. When you drink the potion, it ends the {@spell polymorph} effect imposed by thessaltoxin."
]
},
{
"name": "Thieves' Tools",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"additionalSources": [
{
"source": "XGE",
"page": 84
}
],
"type": "T",
"rarity": "none",
"weight": 1,
"value": 2500,
"entries": [
"This set of tools includes a small file, a set of lock picks, a small mirror mounted on a metal handle, a set of narrow-bladed scissors, and a pair of pliers. Proficiency with these tools lets you add your proficiency bonus to any ability checks you make to disarm traps or open locks."
],
"additionalEntries": [
"Perhaps the most common tools used by adventurers, thieves' tools are designed for picking locks and foiling traps. Proficiency with the tools also grants you a general knowledge of traps and locks.",
{
"type": "entries",
"name": "Components",
"entries": [
"Thieves' tools include a small file, a set of lock picks, a small mirror mounted on a metal handle, a set of narrow-bladed scissors, and a pair of pliers."
]
},
{
"type": "entries",
"name": "History",
"entries": [
"Your knowledge of traps grants you insight when answering questions about locations that are renowned for their traps."
]
},
{
"type": "entries",
"name": "Investigation and Perception",
"entries": [
"You gain additional insight when looking for traps, because you have learned a variety of common signs that betray their presence."
]
},
{
"type": "entries",
"name": "Set a Trap",
"entries": [
"Just as you can disable traps, you can also set them. As part of a short rest, you can create a trap using items you have on hand. The total of your check becomes the DC for someone else's attempt to discover or disable the trap. The trap deals damage appropriate to the materials used in crafting it (such as poison or a weapon) or damage equal to half the total of your check, whichever the DM deems appropriate."
]
},
{
"type": "table",
"caption": "Thieves' Tools",
"colLabels": [
"Activity",
"DC"
],
"colStyles": [
"col-10",
"col-2 text-center"
],
"rows": [
[
"Pick a lock",
"Varies"
],
[
"Disable a trap",
"Varies"
]
]
}
],
"hasFluffImages": true
},
{
"name": "Three-Dragon Ante Set",
"source": "PHB",
"page": 154,
"basicRules": true,
"type": "GS",
"rarity": "none",
"value": 100
},
{
"name": "Thunder Absorbing Tattoo",
"source": "TCE",
"page": 119,
"resist": [
"thunder"
],
"detail1": "orange",
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"hasRefs": true,
"entries": [
"{#itemEntry Absorbing Tattoo|TCE}"
]
},
{
"name": "Tiger Eye",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 1000,
"entries": [
"A translucent brown with golden center gemstone."
]
},
{
"name": "Timepiece of Travel",
"source": "AI",
"page": 221,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"bonusSavingThrow": "+1",
"entries": [
"While attuned to this device, you have a +1 bonus to Dexterity saving throws and you always know which way is north. Additionally, you can cast the {@spell longstrider} spell on yourself at will.",
{
"type": "entries",
"name": "Turn-by-Turn Directions",
"entries": [
"You can name any location within 10 miles of your current location that is known to at least one creature also within 10 miles of that location (including you). Once you name the location, a disembodied voice heard only by you provides directions to the location by the safest, most direct route. The voice knows to avoid natural hazards, so that it does not send you through trackless wilderness or into a lake. But it has no knowledge of or ability to help you avoid monsters, enemies, traps, locked doors, and the like.",
"The timepiece is of no use for reaching locations that are wholly secret or not known to any creatures within range. Once you use this feature of the timepiece of travel, it cannot be used again until the next dawn."
]
},
{
"type": "entries",
"name": "Get Away From It All",
"entries": [
"As an action, you can cast the {@spell teleport} spell. Once you use this feature of the timepiece of travel, it cannot be used again until the next dawn."
]
},
{
"type": "entries",
"name": "Part of a Whole",
"entries": [
"While this component is not installed in the {@item Orrery of the Wanderer|AI}, its magic might function sporadically or with unpredictable side effects, as determined by the DM."
]
}
],
"attachedSpells": [
"longstrider",
"teleport"
]
},
{
"name": "Tinderbox",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 1,
"value": 50,
"entries": [
"This small container holds flint, fire steel, and tinder (usually dry cloth soaked in light oil) used to kindle a fire. Using it to light a torch—or anything else with abundant, exposed fuel—takes an action. Lighting any other fire takes 1 minute."
]
},
{
"name": "Tinderstrike",
"source": "PotA",
"page": 224,
"baseItem": "dagger|phb",
"type": "M",
"resist": [
"fire"
],
"rarity": "legendary",
"reqAttune": true,
"weight": 1,
"weaponCategory": "simple",
"property": [
"F",
"L",
"T"
],
"range": "20/60",
"dmg1": "1d4",
"dmgType": "P",
"grantsProficiency": true,
"bonusWeapon": "+2",
"entries": [
"A flint dagger, Tinderstrike is uncommonly sharp, and sparks cascade off its edge whenever it strikes something solid. Its handle is always warm to the touch, and the blade smolders for {@dice 1d4} minutes after it is used to deal damage. It contains a spark of {@creature Imix|PotA}, Prince of Evil Fire.",
"You gain a +2 bonus to attack and damage rolls made with this magic weapon. When you hit with it, the target takes an extra {@damage 2d6} fire damage.",
{
"name": "Fire Mastery",
"type": "entries",
"entries": [
"You gain the following benefits while you hold Tinderstrike:"
]
},
{
"type": "list",
"items": [
"You can speak Ignan fluently.",
"You have resistance to fire damage.",
"You can cast {@spell dominate monster} (save DC 17) on a {@creature fire elemental}. Once you have done so, Tinderstrike can't be used this way again until the next dawn."
]
},
{
"type": "entries",
"name": "Dance of the All-Consuming Fire",
"entries": [
"While inside a fire node, you can perform a ritual called the Dance of the All-Consuming Fire, using Tinderstrike to create a {@item devastation orb of fire|PotA}. Once you perform the ritual, Tinderstrike can't be used to perform the ritual again until the next dawn."
]
},
{
"name": "Flaw",
"type": "entries",
"entries": [
"Tinderstrike makes its wielder impatient and rash. While attuned to the weapon, you gain the following flaw: \"I act without thinking and take risks without weighing the consequences.\""
]
}
],
"attachedSpells": [
"dominate monster"
],
"hasFluffImages": true
},
{
"name": "Tinker's Tools",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"additionalSources": [
{
"source": "XGE",
"page": 84
}
],
"type": "AT",
"rarity": "none",
"weight": 10,
"value": 5000,
"additionalEntries": [
"A set of tinker's tools is designed to enable you to repair many mundane objects. Though you can't manufacture much with tinker's tools, you can mend torn clothes, sharpen a worn sword, and patch a tattered suit of chain mail.",
{
"type": "entries",
"name": "Components",
"entries": [
"Tinker's tools include a variety of hand tools, thread, needles, a whetstone, scraps of cloth and leather, and a small pot of glue."
]
},
{
"type": "entries",
"name": "History",
"entries": [
"You can determine the age and origin of objects, even if you have only a few pieces remaining from the original."
]
},
{
"type": "entries",
"name": "Investigation",
"entries": [
"When you inspect a damaged object, you gain knowledge of how it was damaged and how long ago."
]
},
{
"type": "entries",
"name": "Repair",
"entries": [
"You can restore 10 hit points to a damaged object for each hour of work. For any object, you need access to the raw materials required to repair it. For metal objects, you need access to an open flame hot enough to make the metal pliable."
]
},
{
"type": "table",
"caption": "Tinker's Tools",
"colLabels": [
"Activity",
"DC"
],
"colStyles": [
"col-10",
"col-2 text-center"
],
"rows": [
[
"Temporarily repair a disabled device",
"10"
],
[
"Repair an item in half the time",
"15"
],
[
"Improvise a temporary item using scraps",
"20"
]
]
}
]
},
{
"name": "Tiny jade figurine (River Heralds)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 2500
},
{
"name": "Tocken",
"source": "SCAG",
"page": 124,
"type": "INS",
"rarity": "none",
"entries": [
"A hanging set of carved oval bells, usually played with a pair of light wooden hammers (or open handed). They are most common in underground cultures, where the resonant tones can carry."
]
},
{
"name": "Tome of Clear Thought",
"source": "DMG",
"page": 208,
"srd": true,
"tier": "major",
"rarity": "very rare",
"wondrous": true,
"weight": 5,
"ability": {
"int": 2
},
"entries": [
"This book contains memory and logic exercises, and its words are charged with magic. If you spend 48 hours over a period of 6 days or fewer studying the book's contents and practicing its guidelines, your Intelligence score increases by 2, as does your maximum for that score. The manual then loses its magic, but regains it in a century."
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Tome of Leadership and Influence",
"source": "DMG",
"page": 208,
"srd": true,
"tier": "major",
"rarity": "very rare",
"wondrous": true,
"weight": 5,
"ability": {
"cha": 2
},
"entries": [
"This book contains guidelines for influencing and charming others, and its words are charged with magic. If you spend 48 hours over a period of 6 days or fewer studying the book's contents and practicing its guidelines, your Charisma score increases by 2, as does your maximum for that score. The manual then loses its magic, but regains it in a century."
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Tome of Strahd",
"source": "CoS",
"page": 221,
"type": "G",
"rarity": "none",
"weight": 5,
"entries": [
"The Tome of Strahd is an ancient work penned by Strahd, a tragic tale of how he came to his fallen state. The book is bound in a thick leather cover with steel hinges and fastenings. The pages are of parchment and very brittle. Most of the book is written in the curious shorthand that only Strahd employs. Stains and age have made most of the work illegible, but several paragraphs remain intact."
],
"hasFluffImages": true
},
{
"name": "Tome of the Stilled Tongue",
"source": "DMG",
"page": 208,
"tier": "major",
"rarity": "legendary",
"reqAttune": "by a wizard",
"reqAttuneTags": [
{
"class": "wizard"
}
],
"wondrous": true,
"weight": 5,
"focus": [
"Wizard"
],
"entries": [
"This thick leather-bound volume has a desiccated tongue pinned to the front cover. Five of these tomes exist, and it's unknown which one is the original. The grisly cover decoration on the first tome of the stilled tongue once belonged to a treacherous former servant of the lich-god Vecna, keeper of secrets. The tongues pinned to the covers of the four copies came from other spellcasters who crossed Vecna. The first few pages of each tome are filled with indecipherable scrawls. The remaining pages are blank and pristine.",
"If you can attune to this item, you can use it as a spellbook and an arcane focus. In addition, while holding the tome, you can use a bonus action to cast a spell you have written in this tome, without expending a spell slot or using any verbal or somatic component. Once used, this property of the tome can't be used again until the next dawn.",
"While attuned to the book, you can remove the tongue from the book's cover. If you do so, all spells written in the book are permanently erased.",
"Vecna watches anyone using this tome. He can also write cryptic messages in the book. These messages appear at midnight and fade away after they are read."
],
"lootTables": [
"Magic Item Table I"
]
},
{
"name": "Tome of Understanding",
"source": "DMG",
"page": 209,
"srd": true,
"tier": "major",
"rarity": "very rare",
"wondrous": true,
"weight": 5,
"ability": {
"wis": 2
},
"entries": [
"This book contains intuition and insight exercises, and its words are charged with magic. If you spend 48 hours over a period of 6 days or fewer studying the book's contents and practicing its guidelines, your Wisdom score increases by 2, as does your maximum for that score. The manual then loses its magic, but regains it in a century."
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Topaz",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 50000,
"entries": [
"A transparent golden yellow gemstone."
]
},
{
"name": "Topaz Annihilator",
"source": "FTD",
"page": 24,
"type": "R",
"rarity": "legendary",
"reqAttune": true,
"property": [
"2H"
],
"range": "100/300",
"dmg1": "2d6",
"dmgType": "N",
"firearm": true,
"weapon": true,
"entries": [
"This magic ranged weapon resembles a {@item musket|DMG}, but in lieu of any ammunition, it holds a glowing yellow scale from a topaz dragon in its heart.",
"The weapon has a normal range of 100 feet and a long range of 300 feet, and it has the two-handed property. It deals {@damage 2d6} necrotic damage on a hit. If this damage reduces a creature or object to 0 hit points, the target is reduced to dust. A creature reduced to dust can be restored to life only by a {@spell true resurrection} or {@spell wish} spell.",
"While the weapon is on your person, you can use an action to cast the {@spell disintegrate} spell (save DC 18). Once this property is used, it can't be used again until the next dawn."
],
"attachedSpells": [
"disintegrate"
]
},
{
"name": "Torch",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 1,
"value": 1,
"entries": [
"A torch burns for 1 hour, providing bright light in a 20-foot radius and dim light for an additional 20 feet. If you make a melee attack with a burning torch and hit, it deals 1 fire damage."
]
},
{
"name": "Torpor",
"source": "DMG",
"page": 258,
"srd": true,
"type": "G",
"rarity": "none",
"value": 60000,
"poison": true,
"entries": [
"A creature subjected to this poison must succeed on a DC 15 Constitution saving throw or become {@condition poisoned} for {@dice 4d6} hours. The {@condition poisoned} creature is {@condition incapacitated}."
],
"poisonTypes": [
"ingested"
]
},
{
"name": "Totem",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "SCF",
"scfType": "druid",
"rarity": "none",
"value": 100
},
{
"name": "Tourmaline",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 10000,
"entries": [
"A transparent pale green, blue, brown, or red gemstone."
]
},
{
"name": "Travel Alchemical Kit",
"source": "AI",
"page": 30,
"rarity": "uncommon",
"wondrous": true,
"grantsProficiency": true,
"entries": [
"Also at rank 3, Head Office provides you with a travel alchemical kit—an uncommon magic item containing miniaturized versions of both {@item alchemist's supplies|phb} and a {@item poisoner's kit|phb} (glass vials, a mortar and pestle, chemicals, and a glass stirring rod). You gain proficiency with a {@item poisoner's kit|phb} as part of this upgrade.",
"You can use this magical kit as long as it is on your person, with no need to draw or stow it. If you are ever searched, finding your travel alchemical kit requires a successful DC 20 Intelligence ({@skill Investigation}) or Wisdom ({@skill Insight}) check."
],
"hasFluffImages": true
},
{
"name": "Traveler's Clothes",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 4,
"value": 200
},
{
"name": "Treasure chest crafted of exotic wood with gold fittings and opals (Brazen Coalition)",
"source": "PSX",
"page": 25,
"type": "$",
"rarity": "none",
"value": 750000
},
{
"name": "Treebane",
"source": "CoS",
"page": 198,
"baseItem": "battleaxe|phb",
"type": "M",
"rarity": "unknown (magic)",
"weight": 2,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"entries": [
"The axe's handle is carved with leaves and vines, and it weighs half as much as a normal battleaxe. When the axe hits a plant, whether an ordinary plant or a plant creature, the target takes an extra {@damage 1d8} slashing damage. When a creature of non-good alignment wields the axe, it sprouts thorns whenever its wielder makes an attack with it. These thorns prick the wielder for 1 piercing damage after the attack is made, and this damage is considered magical."
]
},
{
"name": "Trident of Fish Command",
"source": "DMG",
"page": 209,
"srd": true,
"baseItem": "trident|phb",
"type": "M",
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"weight": 4,
"weaponCategory": "martial",
"property": [
"T",
"V"
],
"range": "20/60",
"dmg1": "1d6",
"dmgType": "P",
"dmg2": "1d8",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"This trident is a magic weapon. It has 3 charges. While you carry it, you can use an action and expend 1 charge to cast {@spell dominate beast} (save DC 15) from it on a beast that has an innate swimming speed. The trident regains {@dice 1d3} expended charges daily at dawn."
],
"attachedSpells": [
"dominate beast"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Trinket",
"source": "AI",
"page": 222,
"type": "G",
"rarity": "none",
"entries": [
"Worried that you're going to pull out your dead sprite inside a clear glass bottle at a company function only to discover that three other people in the franchise have one as well? Then consider rolling on the Acquisitions Incorporated Trinkets table instead of the {@item Trinket|phb|table in chapter 5 of the Player's Handbook}, to gain a unique trinket at character creation with a bit of Acq Inc flair.",
{
"type": "table",
"caption": "Acquisitions Incorporated Trinkets",
"colLabels": [
"d100",
"Trinket"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"1",
"A perfect skipping stone"
],
[
"2",
"Three weighted dice that always roll low"
],
[
"3",
"A locket with a picture of Jim Darkmagic"
],
[
"4",
"A tiny anvil and smith's hammer"
],
[
"5",
"A nonfunctional immovable rod"
],
[
"6",
"A DIY acupuncture kit"
],
[
"7",
"A stuffed owlbear toy"
],
[
"8",
"A diagram for a new war lute"
],
[
"9",
"A declaration of war against a nation no one has ever heard of"
],
[
"10",
"A pipe that emits green smoke"
],
[
"11",
"A glass eye"
],
[
"12",
"The centerpiece of a priceless chandelier"
],
[
"13",
"A fine cane with a secret compartment"
],
[
"14",
"A letter written by Rosie Beestinger"
],
[
"15",
"A coin that always lands on its edge"
],
[
"16",
"The deed and title to an abandoned windmill and three acres of tillable land that you've never been able to find"
],
[
"17",
"A menu from Big Daddy Donaar's Yum Yum Hut"
],
[
"18",
"A small purse that screams loudly when opened"
],
[
"19",
"A deep-crow-feather quill"
],
[
"20",
"A pair of breeches that always smells faintly of honey"
],
[
"21",
"A one-armed Viari doll (Apocalypse Dagger accessory missing)"
],
[
"22",
"A leather pouch filled with various finger bones of unknown provenance"
],
[
"23",
"The collar of your childhood pet, Nutmeg"
],
[
"24",
"A seashell that, when pressed to the ear, speaks in flowing rhymes"
],
[
"25",
"A recipe book for cooking with mushrooms"
],
[
"26",
"One expired coupon for \"A Free Cornerstone\""
],
[
"27",
"A portable beehive"
],
[
"28",
"Goggles that literally tint everything rose colored"
],
[
"29",
"A wand of wonder that allows you to cast only mending"
],
[
"30",
"A bracelet woven from mistletoe"
],
[
"31",
"A curved claw from an unknown beast that small children are always frightened of"
],
[
"32",
"A longsword that can be folded down in 1 minute and hidden in your pocket"
],
[
"33",
"A living graft of what you believe to be the World Tree"
],
[
"34",
"A tattered scarf with the Acquisitions Incorporated logo"
],
[
"35",
"A box containing a torn-up letter"
],
[
"36",
"An old contract marked \"Void\""
],
[
"37",
"A small pigeon in a cage"
],
[
"38",
"An Acquisitions Incorporated \"Green Flame\" foam finger"
],
[
"39",
"A \"C\" Team lunchbox"
],
[
"40",
"A potted plant that grows different fruit on every branch"
],
[
"41",
"A book of adventures for children"
],
[
"42",
"An Omin action figure with detachable maul and holy symbol"
],
[
"43",
"A pint glass engraved with a picture of a keg robot"
],
[
"44",
"A giant hockey puck"
],
[
"45",
"A miniature cannon that actually fires"
],
[
"46",
"A very fancy red scarf and matching handkerchief"
],
[
"47",
"An arrow once shot by Môrgæn"
],
[
"48",
"A purple worm toy"
],
[
"49",
"A burned doll whose eyes follow you around the room"
],
[
"50",
"An infinite inkwell"
],
[
"51",
"A band embroidered with the symbol of the Six"
],
[
"52",
"A squishy cactus"
],
[
"53",
"A velvet blindfold"
],
[
"54",
"A dirty figurine of a triceratops that can't be cleaned"
],
[
"55",
"A used (and thus nonmagical) noble knife from the Noble Knife"
],
[
"56",
"A quill that rotates through all the colors of the rainbow"
],
[
"57",
"A twenty-sided die that only rolls the number 4"
],
[
"58",
"A pointed hat that glows in the dark"
],
[
"59",
"A cup that hums when filled with water"
],
[
"60",
"A mug fashioned from the skull of someone else's enemy"
],
[
"61",
"A small glass jar containing an immortal firefly"
],
[
"62",
"A fake mustache made from gnome facial hair"
],
[
"63",
"A petrified troll finger"
],
[
"64",
"Half of a map"
],
[
"65",
"The other half of a map"
],
[
"66",
"An unreasonable amount of pocket lint"
],
[
"67",
"A dozen flyers for a local gnome food place"
],
[
"68",
"Omin Dran's business card"
],
[
"69",
"A lock of hair from a changeling"
],
[
"70",
"A marble that rolls uphill"
],
[
"71",
"A piece of parchment listing a command word for a powerful magic item"
],
[
"72",
"A book titled Conversational Giant"
],
[
"73",
"A user's manual for an apparatus of Kwalish"
],
[
"74",
"A signed headshot of famous bard Spice Caraway"
],
[
"75",
"The eye of a basilisk in a crystal box"
],
[
"76",
"A postcard from Ravnica"
],
[
"77",
"A stamp collection"
],
[
"78",
"A small piece of solidified smoke"
],
[
"79",
"A six-sided die that sometimes rolls a seven"
],
[
"80",
"A left-hand gauntlet"
],
[
"81",
"A music box that plays nursery rhymes"
],
[
"82",
"A locket that's bigger on the outside than the inside"
],
[
"83",
"A potted plant that grows hair instead of leaves"
],
[
"84",
"A collection of teeth"
],
[
"85",
"A coin whose minting date always shows three years in the future"
],
[
"86",
"A green drinking horn taken from a very large bull"
],
[
"87",
"A small book containing pressed and dried botanical samples"
],
[
"88",
"A hatched chimera egg"
],
[
"89",
"A slightly used red bandit mask"
],
[
"90",
"A tiny stirge encased in amber"
],
[
"91",
"A large bottle of Red Larch Ale that can't be opened"
],
[
"92",
"A cane topped with a stylized golden bulldog"
],
[
"93",
"A note in your own hand that you don't remember writing"
],
[
"94",
"A sprig of herbs from your family's garden"
],
[
"95",
"A tankard stolen from the Dran & Courtier"
],
[
"96",
"A small, severed tentacle preserved in alcohol"
],
[
"97",
"A walnut with a face drawn on it"
],
[
"98",
"A small flask of liquid from the Lake of Radiant Mists"
],
[
"99",
"An ice cube that never melts"
],
[
"00",
"An expertly carved sword hilt with the blade snapped cleanly off"
]
]
}
],
"hasFluffImages": true
},
{
"name": "Trinket",
"source": "CoS",
"page": 2,
"type": "G",
"rarity": "none",
"entries": [
"When you make your character, you can roll once on the Trinkets table to gain a trinket, a simple item lightly touched by mystery. The DM might also use this table. It can help stock a room in a dungeon or fill a creatures pockets.",
{
"type": "table",
"caption": "Curse of Strahd. Character Options, Gothic Trinket Table",
"colLabels": [
"{@dice d100}",
"Trinket"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"01-02",
"A picture you drew as a child of your imaginary friend"
],
[
"03-04",
"A lock that opens when blood is dripped in its keyhole"
],
[
"05-06",
"Clothes stolen from a {@creature scarecrow}"
],
[
"07-08",
"A spinning top carved with four faces: happy, sad, wrathful, and dead"
],
[
"09-10",
"The necklace of a sibling who died on the day you were born"
],
[
"11-12",
"A wig from someone executed by beheading"
],
[
"13-14",
"The unopened letter to you from your dying father"
],
[
"15-16",
"A pocket watch that runs backward for an hour every midnight"
],
[
"17-18",
"A winter coat stolen from a dying soldier"
],
[
"19-20",
"A bottle of invisible ink that can only be read at sunset"
],
[
"21-22",
"A wineskin that refills when interred with a dead person for a night"
],
[
"23-24",
"A set of silverware used by a king for his last meal"
],
[
"25-26",
"A spyglass that always shows the world suffering a terrible storm"
],
[
"27-28",
"A cameo with the profile's face scratched away"
],
[
"29-30",
"A lantern with a black candle that never runs out and that burns with green flame"
],
[
"31-32",
"A teacup from a child's tea set, stained with blood"
],
[
"33-34",
"A little black book that records your dreams, and yours alone, when you sleep"
],
[
"35-36",
"A necklace formed of the interlinked holy symbols of a dozen deities"
],
[
"37-38",
"A hangman's noose that feels heavier than it should"
],
[
"39-40",
"A birdcage into which small birds fly but once inside never eat or leave"
],
[
"41-42",
"A lepidopterist's box filled dead moths with skulllike patterns on their wings"
],
[
"43-44",
"A jar of pickled ghouls' tongues"
],
[
"45-46",
"The wooden hand of a notorious pirate"
],
[
"47-48",
"An urn with the ashes of a dead relative"
],
[
"49-50",
"A hand mirror backed with a bronze depiction of a {@creature medusa}"
],
[
"51-52",
"Pallid leather gloves crafted with ivory fingernails"
],
[
"53-54",
"Dice made from the knuckles of a notorious charlatan"
],
[
"55-56",
"A ring of keys for forgotten locks"
],
[
"57-58",
"Nails from the coffin of a murderer"
],
[
"59-60",
"A key to the family crypt"
],
[
"61-62",
"A bouquet of funerary flowers that always looks and smells fresh"
],
[
"63-64",
"A switch used to discipline you as a child"
],
[
"65-66",
"A music box that plays by itself whenever someone holding it dances"
],
[
"67-68",
"A walking cane with an iron ferrule that strikes sparks on stone"
],
[
"69-70",
"A flag from a ship lost at sea"
],
[
"71-72",
"Porcelain doll's head that always seems to be looking at you"
],
[
"73-74",
"A wolf's head wrought in silver that is also a whistle"
],
[
"75-76",
"A small mirror that shows a much older version of the viewer"
],
[
"77-78",
"Small, worn book of children's nursery rhymes"
],
[
"79-80",
"A mummified raven claw"
],
[
"81-82",
"A broken pendent of a silver dragon that's always cold to the touch"
],
[
"83-84",
"A small locked box that quietly hums a lovely melody at night but you always forget it in the morning"
],
[
"85-86",
"An inkwell that makes one a little nauseous when staring at it"
],
[
"87-88",
"An old little doll made from a dark, dense wood and missing a hand and a foot"
],
[
"89-90",
"A black executioner's hood"
],
[
"91-92",
"A pouch made of flesh, with a sinew drawstring"
],
[
"93-94",
"A tiny spool of black thread that never runs out"
],
[
"95-96",
"A tiny clockwork figurine of a dancer that's missing a gear and doesn't work"
],
[
"97-98",
"A black wooden pipe that creates puffs of smoke that look like skulls"
],
[
"99-00",
"A vial of perfume, the scent of which only certain creatures can detect"
]
]
}
]
},
{
"name": "Trinket",
"source": "EET",
"page": 1,
"type": "G",
"rarity": "none",
"entries": [
"When you make your character, you can roll once on the Trinkets table to gain a trinket, a simple item lightly touched by mystery. The DM might also use this table. It can help stock a room in a dungeon or fill a creatures pockets.",
{
"type": "table",
"caption": "Elemental Evil Trinket Table",
"colLabels": [
"{@dice d100}",
"Trinket"
],
"colStyles": [
"col-1 text-center",
"col-11"
],
"rows": [
[
"01",
"A compass that always points to Mulmaster"
],
[
"02",
"A paper fan that won't produce a breeze no matter how hard it's waved"
],
[
"03",
"A petrified potato that resembles someone important to you"
],
[
"04",
"A glass cup that can only be filled half way no matter how much liquid is poured into it"
],
[
"05",
"A mirror that only shows the back of your head"
],
[
"06",
"A small glass bird that when set down near water dips its head in as if to get a drink"
],
[
"07",
"A lady's coin purse containing two sharp fangs"
],
[
"08",
"A small sea conch with the words \"From the beginning\" painted on the lip"
],
[
"09",
"A frost-covered silver locket that's frozen shut"
],
[
"10",
"A seal which imprints a mysterious, unknown coat of arms into hard rock"
],
[
"11",
"A small wooden doll that when held brings back fond memories"
],
[
"12",
"A small handmirror which only reflects inanimate objects"
],
[
"13",
"A glass eyeball that looks about of its own accordance, and can roll around"
],
[
"14",
"A glass orb that replicates yesterday's weather inside itself"
],
[
"15",
"A drinking cup, that randomly fills with fresh or salt water. Refilling once emptied"
],
[
"16",
"A deep blue piece of flint, that when struck with steel produces not a spark but a drop of water"
],
[
"17",
"A conch shell which is always damp and constantly drips saltwater"
],
[
"18",
"A charred, half-melted pewter clasp that glows as if smoldering but releases no heat"
],
[
"19",
"A clockwork finch that flaps its wings in the presence of a breeze"
],
[
"20",
"An unbreakable sealed jar of glowing water that hums when shaken"
],
[
"21",
"A small, finely polished geode whose crystals slowly fade between every color of the spectrum"
],
[
"22",
"A rough stone eye pulled from a petrified creature"
],
[
"23",
"A stone smoking pipe that never needs lighting"
],
[
"24",
"A small whistle, that when blown, whispers a name of a person or place unknown to you, instead of the whistle sound"
],
[
"25",
"A fist sized rock that \"beats\" like a heart"
],
[
"26",
"A pair of bronze scissors in the shape of a pair of leaping dolphins"
],
[
"27",
"A bronze oil lamp which is rumored to have once held a genie"
],
[
"28",
"A single gauntlet inscribed with a fire motif and an unfamiliar name in Primordial"
],
[
"29",
"A one-eyed little fish inside a spherical vial, much bigger than the vial's neck, He has a cunning look"
],
[
"30",
"The tiny skull of a rabbit that whispers scathing insults when nobody is looking"
],
[
"31",
"A rag doll in the likeness of an {@creature owlbear}"
],
[
"32",
"The desiccated body of a small eight-legged black lizard"
],
[
"33",
"A small toy boat made with a walnut shell, toothpick, and piece of cloth"
],
[
"34",
"A small pocket mirror that slowly fogs over while held"
],
[
"35",
"Wind chimes that glow when the wind blows"
],
[
"36",
"A small, clay square with an unknown rune etched into one side"
],
[
"37",
"A tea kettle that heats itself when filled with water"
],
[
"38",
"An old scratched monocle which shows an underwater landscape whenever someone looks through it"
],
[
"39",
"A rose carved from coral"
],
[
"40",
"A set of dice with elemental symbols and primordial runes instead of pips or numbers"
],
[
"41",
"An amulet filled with liquid that churns, freezes, or boils to match it's wearer's mood"
],
[
"42",
"A small silver bell that makes a sound like quiet, distant thunder when it's struck"
],
[
"43",
"A small vial of black sand that glows slightly in the moonlight"
],
[
"44",
"A small whale tooth with etched with an image of waves crashing upon a beach"
],
[
"45",
"An hourglass in which the sands pour upward instead of downward"
],
[
"46",
"A glass pendant with a hole in the center that a mild breeze always blows out of"
],
[
"47",
"A soft feather that falls like a stone when dropped"
],
[
"48",
"A large transparent gem that, when gripped tightly, whispers in Terran"
],
[
"49",
"A small crystal snowglobe that, when shaken, seems to form silhouettes of dancing forms"
],
[
"50",
"Half of a palm-sized geode that pulses dimly with purple light"
],
[
"51",
"A book filled with writing that only appears when the book is held underwater"
],
[
"52",
"A sealed envelope made of red leather that you haven't been able to open. It smells of campfire"
],
[
"53",
"A locket of hair that is rumored to have come from a famed fire genasi"
],
[
"54",
"Flint and steel that, when used to start a fire, creates a random colored flame"
],
[
"55",
"A blank piece of wet parchment that never seems to dry"
],
[
"56",
"A small puzzle box made of brass, that is slightly warm to the touch"
],
[
"57",
"A cloudy chunk of glass that is said to hold a spark of breath from a blue dragon"
],
[
"58",
"A crude chalice made of coal"
],
[
"59",
"A miniature brass horn, silent when played, but fills the air with the scent of warm and exotic spices"
],
[
"60",
"An eye-sized blue pearl that floats in salt water"
],
[
"61",
"A tuning fork made from a dark metal which glows with a pale, white light during thunderstorms"
],
[
"62",
"A small vial that is always filled with the smell of autumn wind"
],
[
"63",
"A clear marble that slowly rolls toward the nearest source of running water"
],
[
"64",
"A small collapsible silver cup that perspires constantly when opened"
],
[
"65",
"An hourglass that tells time with falling mist instead of sand"
],
[
"66",
"An ornate razor, which only cuts in freezing cold temperature"
],
[
"67",
"A shark tooth covered in tiny etched words from a lost language"
],
[
"68",
"A large brass coin with no markings or images on it"
],
[
"69",
"A small wooden box filled with a strange red clay"
],
[
"70",
"A necklace with a small, rusted iron anchor"
],
[
"71",
"A small brass flute adorned with silver wire that is always faintly sounding"
],
[
"72",
"A red and black Aarakocra feather"
],
[
"73",
"A palm-sized stone with a hole in it, through which can be heard a constantly whispering wind"
],
[
"74",
"A small conch shell covered in black crystal"
],
[
"75",
"A small music box made of brass. It features a pair of tiny automatons that resemble {@creature Azer} working at a forge"
],
[
"76",
"A glass jar containing the preserved corpse of an unfamiliar aquatic creature"
],
[
"77",
"A piece of petrified wood carved into the shape of a seashell"
],
[
"78",
"A wooden puzzle cube covered in elemental symbols"
],
[
"79",
"A small stone cube that acts as a magnet when placed against another stone"
],
[
"80",
"A ring made of a white metal. On the inside is a name etched in Auran"
],
[
"81",
"A bracelet made of silvered fish hooks"
],
[
"82",
"A journal filled with poetry hand-written in Primordial"
],
[
"83",
"A yellow gemstone that glows dimly when a storm is nearby"
],
[
"84",
"A charred chisel with an unfamiliar symbol stamped into its base"
],
[
"85",
"A canteen filled with a foul smelling orange mud"
],
[
"86",
"A faceless doll made of driftwood"
],
[
"87",
"A heavy iron key bearing the name of a ship long lost to the sea"
],
[
"88",
"A small jewelry box made from the shell of a turtle"
],
[
"89",
"A chess piece fashioned to look like fire myrmidon"
],
[
"90",
"A spinning top with an image of one of the four elements on each side"
],
[
"91",
"A single hoop earring made of a porous red stone"
],
[
"92",
"An arrowhead carved from seasalt"
],
[
"93",
"A small comb made of blue coral"
],
[
"94",
"Seven small beads of sandstone on a string, all different colors"
],
[
"95",
"A romance chapbook written in Undercommon titled \"Just one Layer of Grey\""
],
[
"96",
"A tiny, broken clockwork Harpy"
],
[
"97",
"An ivory whale statuette"
],
[
"98",
"A fist-sized cog, covered in barnacles"
],
[
"99",
"An eyepatch made of obsidian and a black leather cord"
],
[
"100",
"A glass bottle with a tiny ship of unfamiliar design inside"
]
]
}
]
},
{
"name": "Trinket",
"source": "PHB",
"page": 159,
"basicRules": true,
"type": "G",
"rarity": "none",
"entries": [
"When you make your character, you can roll once on the Trinkets table to gain a trinket, a simple item lightly touched by mystery. The DM might also use this table. It can help stock a room in a dungeon or fill a creatures pockets.",
{
"type": "table",
"caption": "Player's Handbook Trinket Table",
"colLabels": [
"{@dice d100}",
"Trinket"
],
"colStyles": [
"col-1 text-center",
"col-11"
],
"rows": [
[
"01",
"A mummified goblin hand"
],
[
"02",
"A piece of crystal that faintly glows in the moonlight"
],
[
"03",
"A gold coin minted in an unknown land"
],
[
"04",
"A diary written in a language you don't know"
],
[
"05",
"A brass ring that never tarnishes"
],
[
"06",
"An old chess piece made from glass"
],
[
"07",
"A pair of knucklebone dice, each with a skull symbol on the side that would normally show six pips"
],
[
"08",
"A small idol depicting a nightmarish creature that gives you unsettling dreams when you sleep near it"
],
[
"09",
"A rope necklace from which dangles four mummified elf fingers"
],
[
"10",
"The deed for a parcel of land in a realm unknown to you"
],
[
"11",
"A 1-ounce block made from an unknown material"
],
[
"12",
"A small cloth doll skewered with needles"
],
[
"13",
"A tooth from an unknown beast"
],
[
"14",
"An enormous scale, perhaps from a dragon"
],
[
"15",
"A bright green feather"
],
[
"16",
"An old divination card bearing your likeness"
],
[
"17",
"A glass orb filled with moving smoke"
],
[
"18",
"A 1-pound egg with a bright red shell"
],
[
"19",
"A pipe that blows bubbles"
],
[
"20",
"A glass jar containing a weird bit of flesh floating in pickling fluid"
],
[
"21",
"A tiny gnome-crafted music box that plays a song you dimly remember from your childhood"
],
[
"22",
"A small wooden statuette of a smug halfling"
],
[
"23",
"A brass orb etched with strange runes"
],
[
"24",
"A multicolored stone disk"
],
[
"25",
"A tiny silver icon of a raven"
],
[
"26",
"A bag containing forty-seven humanoid teeth, one of which is rotten"
],
[
"27",
"A shard of obsidian that always feels warm to the touch"
],
[
"28",
"A dragon's bony talon hanging from a plain leather necklace"
],
[
"29",
"A pair of old socks"
],
[
"30",
"A blank book whose pages refuse to hold ink, chalk, graphite, or any other substance or marking"
],
[
"31",
"A silver badge in the shape of a five-pointed star"
],
[
"32",
"A knife that belonged to a relative"
],
[
"33",
"A glass vial filled with nail clippings"
],
[
"34",
"A rectangular metal device with two tiny metal cups on one end that throws sparks when wet"
],
[
"35",
"A white, sequined glove sized for a human"
],
[
"36",
"A vest with one hundred tiny pockets"
],
[
"37",
"A small, weightless stone block"
],
[
"38",
"A tiny sketch portrait of a goblin"
],
[
"39",
"An empty glass vial that smells of perfume when opened"
],
[
"40",
"A gemstone that looks like a lump of coal when examined by anyone but you"
],
[
"41",
"A scrap of cloth from an old banner"
],
[
"42",
"A rank insignia from a lost legionnaire"
],
[
"43",
"A tiny silver bell without a clapper"
],
[
"44",
"A mechanical canary inside a gnomish lamp"
],
[
"45",
"A tiny chest carved to look like it has numerous feet on the bottom"
],
[
"46",
"A dead {@creature sprite} inside a clear glass bottle"
],
[
"47",
"A metal can that has no opening but sounds as if it is filled with liquid, sand, spiders, or broken glass (your choice)"
],
[
"48",
"A glass orb filled with water, in which swims a clockwork goldfish"
],
[
"49",
"A silver spoon with an 'M' engraved on the handle"
],
[
"50",
"A whistle made from gold-colored wood"
],
[
"51",
"A dead scarab beetle the size of your hand"
],
[
"52",
"Two toy soldiers, one with a missing head "
],
[
"53",
"A small box filled with different-sized buttons"
],
[
"54",
"A candle that can't be lit"
],
[
"55",
"A tiny cage with no door"
],
[
"56",
"An old key"
],
[
"57",
"An indecipherable treasure map"
],
[
"58",
"A hilt from a broken sword"
],
[
"59",
"A rabbit's foot"
],
[
"60",
"A glass eye"
],
[
"61",
"A cameo carved in the likeness of a hideous person"
],
[
"62",
"A silver skull the size of a coin"
],
[
"63",
"An alabaster mask"
],
[
"64",
"A pyramid of sticky black incense that smells very bad"
],
[
"65",
"A nightcap that, when worn, gives you pleasant dreams"
],
[
"66",
"A single caltrop made from bone"
],
[
"67",
"A gold monocle frame without the lens"
],
[
"68",
"A 1-inch cube, each side painted a different color"
],
[
"69",
"A crystal knob from a door"
],
[
"70",
"A small packet filled with pink dust"
],
[
"71",
"A fragment of a beautiful song, written as musical notes on two pieces of parchment"
],
[
"72",
"A silver teardrop earring made from a real teardrop"
],
[
"73",
"The shell of an egg painted with scenes of human misery in disturbing detail"
],
[
"74",
"A fan that, when unfolded, shows a sleeping cat"
],
[
"75",
"A set of bone pipes"
],
[
"76",
"A four-leaf clover pressed inside a book discussing manners and etiquette"
],
[
"77",
"A sheet of parchment upon which is drawn a complex mechanical contraption"
],
[
"78",
"An ornate scabbard that fits no blade you have found so far"
],
[
"79",
"An invitation to a party where a murder happened"
],
[
"80",
"A bronze pentacle with an etching of a rat's head in its center"
],
[
"81",
"A purple handkerchief embroidered with the name of a powerful {@creature archmage}"
],
[
"82",
"Half of a floorplan for a temple, castle, or some other structure"
],
[
"83",
"A bit of folded cloth that, when unfolded, turns into a stylish cap"
],
[
"84",
"A receipt of deposit at a bank in a far-flung city"
],
[
"85",
"A diary with seven missing pages"
],
[
"86",
"An empty silver snuffbox bearing an inscription on the surface that says \"dreams\""
],
[
"87",
"An iron holy symbol devoted to an unknown god"
],
[
"88",
"A book that tells the story of a legendary hero's rise and fall, with the last chapter missing"
],
[
"89",
"A vial of dragon blood"
],
[
"90",
"An ancient arrow of elven design"
],
[
"91",
"A needle that never bends"
],
[
"92",
"An ornate brooch of dwarven design"
],
[
"93",
"An empty wine bottle bearing a pretty label that says, \"The Wizard of Wines Winery, Red Dragon Crush, 331422-W\""
],
[
"94",
"A mosaic tile with a multicolored, glazed surface"
],
[
"95",
"A {@condition petrified} mouse"
],
[
"96",
"A black pirate flag adorned with a dragon's skull and crossbones"
],
[
"97",
"A tiny mechanical crab or spider that moves about when it's not being observed"
],
[
"98",
"A glass jar containing lard with a label that reads, \"Griffon Grease\""
],
[
"99",
"A wooden box with a ceramic bottom that holds a living worm with a head on each end of its body"
],
[
"100",
"A metal urn containing the ashes of a hero"
]
]
}
],
"hasFluffImages": true
},
{
"name": "Truth Serum",
"source": "DMG",
"page": 258,
"srd": true,
"type": "G",
"rarity": "none",
"value": 15000,
"poison": true,
"entries": [
"A creature subjected to this poison must succeed on a DC 11 Constitution saving throw or become {@condition poisoned} for 1 hour. The {@condition poisoned} creature can't knowingly speak a lie, as if under the effect of a {@spell zone of truth} spell."
],
"poisonTypes": [
"ingested"
],
"attachedSpells": [
"zone of truth"
]
},
{
"name": "Turquoise",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 1000,
"entries": [
"An opaque light blue-green gemstone."
]
},
{
"name": "Turtle Ship",
"source": "AAG",
"page": 0,
"type": "SPC",
"rarity": "none",
"value": 4000000,
"crew": 16,
"vehAc": 19,
"vehHp": 300,
"vehDmgThresh": 15,
"vehSpeed": 3,
"capCargo": 30,
"entries": [
"A turtle ship is encased in a protective metal shell, as befits its name, and bristles with weapons. In addition to its roomy quarters, it can carry a decent- sized payload, making it popular among traders and others who need a lot of cargo space. The ship can land on the ground, and it floats on water. Sliding panels can be closed to make the hull airtight, enabling the ship to travel underwater safely, even to great depths. If the ship takes damage while on or under the water, interior hatches can be sealed to prevent the ship from flooding completely.",
"Standard weaponry on a turtle ship includes three forward-mounted ballistae and a rear-facing mangonel. These weapons can't be used while the ship is underwater."
],
"seeAlsoVehicle": [
"Turtle Ship|AAG"
]
},
{
"name": "Two-Birds Sling",
"source": "MOT",
"page": 198,
"baseItem": "sling|PHB",
"type": "R",
"rarity": "rare",
"weaponCategory": "simple",
"property": [
"A"
],
"range": "30/120",
"dmg1": "1d4",
"dmgType": "B",
"bonusWeapon": "+1",
"weapon": true,
"entries": [
"You have a +1 bonus to attack and damage rolls made with this weapon.",
"When you make a ranged attack with this sling and hit a target, you can cause the ammunition to ricochet toward a second target within 10 feet of the first, and then make a ranged attack against the second target."
],
"ammoType": "sling bullet|phb"
},
{
"name": "Two-Person Tent",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 20,
"value": 200,
"entries": [
"A simple and portable canvas shelter, a tent sleeps two."
]
},
{
"name": "Tyrant Ship",
"source": "AAG",
"page": 0,
"type": "SPC",
"rarity": "none",
"crew": 10,
"vehAc": 17,
"vehHp": 300,
"vehDmgThresh": 20,
"vehSpeed": 4.5,
"capCargo": 20,
"entries": [
"Beholders carve tyrant ships out of stone using their disintegration rays and use these ships to wander the Astral Plane, looking for worlds to conquer and rival beholders to destroy. No two tyrant ships look alike, but they all have one or more features reminiscent of their creators, such as a bulbous shape and eyestalks.",
"A spherical chamber on the ship's command deck functions as a spelljamming helm that only beholders can attune to. Any beholder that occupies this chamber can attune to it. Reducing a tyrant ship to 0 hit points not only destroys it but also destroys the ship's spelljamming helm and eyestalk cannons.",
"A tyrant ship can't float on water, but it can safely land on the ground."
],
"seeAlsoVehicle": [
"Tyrant Ship|AAG"
]
},
{
"name": "Unbreakable Arrow",
"source": "XGE",
"page": 139,
"type": "A",
"tier": "minor",
"rarity": "common",
"entries": [
"This arrow can't be broken, except when it is within an {@spell antimagic field}."
],
"hasFluffImages": true
},
{
"name": "Uncommon Glamerweave",
"source": "ERLW",
"page": 277,
"rarity": "uncommon",
"wondrous": true,
"entries": [
"Glamerweave is clothing imbued with harmless illusory magic. While wearing these clothes, you can use a bonus action to create a moving illusory pattern within the cloth. Alternatively, you can have the pattern rise from the cloth. For example, a glamerweave gown might be wreathed in harmless, illusory flames, while a glamerweave hat might have illusory butterflies fluttering around it.",
"When you make a Charisma ({@skill Performance}) or Charisma ({@skill Persuasion}) check while wearing the glamerweave, you can roll a {@dice d4} and add the number rolled to the check. Once you use this property, it can't be used again until the next dawn."
]
},
{
"name": "Universal Solvent",
"source": "DMG",
"page": 209,
"srd": true,
"tier": "minor",
"rarity": "legendary",
"wondrous": true,
"entries": [
"This tube holds milky liquid with a strong alcohol smell. You can use an action to pour the contents of the tube onto a surface within reach. The liquid instantly dissolves up to 1 square foot of adhesive it touches, including {@item sovereign glue}."
],
"lootTables": [
"Magic Item Table E"
],
"hasFluffImages": true
},
{
"name": "Vanquisher's Banner",
"source": "XMtS",
"page": 19,
"rarity": "unknown (magic)",
"entries": [
"This battle-worn but unbroken standard bears the insignia of one of the forces of the Legion of Dusk. A creature that holds the vanquisher's banner can use a bonus action to grant an ally advantage on the ally's next attack roll, saving throw, or ability check."
]
},
{
"name": "Vanrak's Mithral Shirt",
"source": "WDMM",
"page": 237,
"baseItem": "chain shirt|phb",
"type": "MA",
"rarity": "uncommon",
"weight": 20,
"ac": 13,
"strength": null,
"stealth": false,
"entries": [
"Mithral is a light, flexible metal. A mithral chain shirt or breastplate can be worn under normal clothes. If the armor normally imposes disadvantage on Dexterity ({@skill Stealth}) checks or has a Strength requirement, the mithral version of the armor doesn't.",
"While you wear this armor, you gain {@sense darkvision} out to a range of 60 feet."
]
},
{
"name": "Velvet doublet with gold buttons (Legion of Dusk)",
"source": "PSX",
"page": 24,
"type": "$",
"rarity": "none",
"value": 2500
},
{
"name": "Ventilating Lungs",
"source": "ERLW",
"page": 279,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"recharge": "dawn",
"entries": [
"These metallic nodules were created in response to the poisonous gases used on the battlefields of the Last War. When you attune to these lungs, they replace the lungs in your chest, which disappear. The lungs allow you to breathe normally, even in an antimagic field, and their breathing function can't be suppressed by magic.",
"Outside an antimagic field or any other effect that suppresses magic, these lungs allow you to breathe normally in any environment (including a vacuum), and you have advantage on saving throws against harmful gases such as those created by a {@spell cloudkill} spell, a {@spell stinking cloud} spell, inhaled poisons, and gaseous breath weapons.",
"As an action, you can use these lungs to exhale a gust of wind, as if you had cast the {@spell gust of wind} spell (spell save DC 15) with no components. This property of the lungs can't be used again until the next dawn.",
"If your attunement to the lungs ends, your original lungs reappear."
],
"optionalfeatures": [
"replicate magic item|tce"
],
"attachedSpells": [
"gust of wind"
]
},
{
"name": "Verminshroud (Awakened)",
"source": "EGW",
"_copy": {
"name": "Verminshroud (Dormant)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Awakened",
"entries": [
"While wearing the Verminshroud in its awakened state, you gain the following benefits:",
{
"type": "list",
"items": [
"You have resistance to poison damage.",
"You can use an action to cast the {@spell insect plague} spell (save DC 15) from the Verminshroud, requiring no material components. This property can't be used again until the next dawn.",
"When you cast the {@spell polymorph} spell using the Verminshroud, you can transform into a {@creature giant wasp}."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"resist": [
"poison"
],
"attachedSpells": [
"polymorph",
"insect plague"
]
},
{
"name": "Verminshroud (Dormant)",
"source": "EGW",
"page": 273,
"conditionImmune": [
"disease"
],
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"entries": [
"This patchy cloak was pieced together from the pelts of rats found feasting on the dead in Blightshore and is dotted with the bloated corpses of magically preserved insects along its seams.",
{
"type": "entries",
"name": "Dormant",
"entries": [
"While wearing the Verminshroud in its dormant state, you gain the following benefits:",
{
"type": "list",
"items": [
"You have advantage on Wisdom ({@skill Perception}) checks that rely on smell, you are immune to disease, and you have {@sense darkvision} out to a range of 60 feet. If you already have {@sense darkvision}, wearing the cloak increases the range of your {@sense darkvision} by 60 feet.",
"As an action, you can use the Verminshroud to cast {@spell polymorph} on yourself, transforming into a {@creature giant rat} or {@creature rat} while retaining your Intelligence, Wisdom, and Charisma scores, as well as the properties of the cloak. This property can't be used again until the next dawn."
]
}
]
}
],
"attachedSpells": [
"polymorph"
]
},
{
"name": "Verminshroud (Exalted)",
"source": "EGW",
"_copy": {
"name": "Verminshroud (Awakened)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Exalted",
"entries": [
"While wearing the Verminshroud in its exalted state, you gain the following benefits:",
{
"type": "list",
"items": [
"You gain a climbing speed equal to your walking speed.",
"Your teeth become razor-sharp natural weapons, which you can use to make unarmed strikes. If you hit with them, you deal piercing damage equal to {@dice 1d6} + your Strength modifier, instead of the bludgeoning damage normal for an unarmed strike. You can make this attack as a bonus action. When you bite a creature and deal damage to it, the creature must succeed on a DC 17 Constitution saving throw or be {@condition poisoned} for 1 minute. The target can repeat the saving throw at the end of each of its turns, ending the condition on itself on a success.",
"When you cast the {@spell polymorph} spell using the Verminshroud, you can transform into a {@creature giant scorpion}."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"modifySpeed": {
"equal": {
"climb": "walk"
}
},
"attachedSpells": [
"polymorph",
"insect plague"
]
},
{
"name": "Veteran's Cane",
"source": "XGE",
"page": 139,
"tier": "minor",
"rarity": "common",
"wondrous": true,
"entries": [
"When you grasp this walking cane and use a bonus action to speak the command word, it transforms into an ordinary longsword and ceases to be magical."
]
},
{
"name": "Vial",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"value": 100,
"entries": [
"A vial can hold up to 4 ounces of liquid."
]
},
{
"name": "Vial of Stardust",
"source": "WDMM",
"page": 251,
"type": "OTH",
"rarity": "unknown",
"entries": [
"Any creature that sprinkles the contents of a Vial of Stardust over itself gains the ability to cast the {@spell dream} spell once as an action (spell save DC 15), requiring no components."
],
"attachedSpells": [
"dream"
]
},
{
"name": "Vial of Thought Capture",
"source": "AZfyT",
"page": 4,
"rarity": "unknown (magic)",
"entries": [
"This dark blue vial allows you to read another's surface thoughts and capture them as thought strands, storing the strands within the vial. As an action, you can activate the vial to target a creature you can see within 10 feet of you to learn its surface thoughts and pull the thoughts learned out of the creature's head as a tangible glowing string of energy known as a thought strand. The strands can be stored in the vial for up to 24 hours or until the vial reaches capacity, in which case the oldest strands dissipate. A creature affected by the vial knows that its thoughts are being probed.",
"The vial can also store thought strands created by the {@spell encode thoughts|ggr} cantrip. It does not extend the duration of those strands beyond 8 hours, but stored strands do not disappear if you cast {@spell encode thoughts|ggr} again during the duration of the cantrip.",
"The vial has 3 charges and can store up to 3 thought strands. It regains all its charges daily at dusk."
]
},
{
"name": "Viol",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"type": "INS",
"rarity": "none",
"weight": 1,
"value": 3000
},
{
"name": "Voting Kit",
"source": "AI",
"page": 22,
"rarity": "common",
"wondrous": true,
"entries": [
"At rank 4, your voting kit becomes a common magic item that conceals an extradimensional space. As a bonus action, you can place one tool kit that you are proficient with into the voting kit, or can remove it. No other type of object can be placed into the extradimensional space. A creature searching the voting kit finds and extracts the tool kit with a successful DC 20 Intelligence ({@skill Investigation}) or Wisdom ({@skill Perception}) check.",
"Additionally, you can use the voting kit to cast {@spell charm person} (save DC 15). This property of the kit can't be used again until the next dawn."
],
"attachedSpells": [
"charm person"
]
},
{
"name": "Vox Seeker",
"source": "EGW",
"page": 270,
"rarity": "common",
"wondrous": true,
"entries": [
"This clockwork device resembles a metal crab the size of a dinner plate. Every action used to wind up the device allows it to operate for 1 minute, to a maximum of 10 minutes. While operational, the item uses the accompanying {@creature Vox Seeker|EGW} stat block. This automaton is under the DM's control. A vox seeker reduced to 0 hit points is destroyed."
]
},
{
"name": "Voyager Staff",
"source": "GGR",
"page": 181,
"rarity": "very rare",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"weight": 4,
"weaponCategory": "simple",
"property": [
"V"
],
"dmg1": "1d6",
"dmgType": "B",
"dmg2": "1d8",
"bonusWeapon": "+1",
"bonusSpellAttack": "+1",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 4}",
"charges": 10,
"staff": true,
"entries": [
"You gain a +1 bonus to attack and damage rolls made with this magic quarterstaff. While you hold it, you gain a +1 bonus to spell attack rolls.",
"This staff has 10 charges. While holding it, you can use an action to expend 1 or more of the staff's charges to cast one of the following spells from it, using your spell save DC: {@spell banishment} (4 charges), {@spell blink} (3 charges), {@spell misty step} (2 charges), {@spell passwall} (5 charges), or {@spell teleport} (7 charges).",
"The staff regains {@dice 1d6 + 4} expended charges daily at dawn. If you expend the last charge, roll a {@dice d20}. On a 1, the staff vanishes forever."
],
"attachedSpells": [
"banishment",
"blink",
"misty step",
"passwall",
"teleport"
]
},
{
"name": "Wagon",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "VEH",
"rarity": "none",
"weight": 400,
"value": 3500
},
{
"name": "Wakened Dragon Vessel",
"source": "FTD",
"_copy": {
"name": "Stirring Dragon Vessel",
"source": "FTD",
"_preserve": {
"page": true
}
},
"rarity": "very rare",
"entries": [
"This vessel can be a potion bottle, drinking horn, or other container meant to hold a liquid.",
"As a bonus action, if the vessel is empty, you can speak the command word to fill the vessel with one of the following (your choice): ale, olive oil, mead, wine, a {@item potion of healing}, a {@item potion of greater healing}, a {@item potion of superior healing}, a {@item potion of climbing}, a {@item potion of fire breath}, or a {@item potion of flying}. Once this property is used, it can't be used until the next dawn. A potion you create in this way loses its magical properties if it isn't imbibed within 24 hours."
]
},
{
"name": "Wakened Dragon-Touched Focus",
"source": "FTD",
"_copy": {
"name": "Stirring Dragon-Touched Focus",
"source": "FTD",
"_preserve": {
"page": true
}
},
"rarity": "very rare",
"entries": [
"This wondrous item can be a scepter, an orb, an amulet, a crystal, or another finely crafted object. It typically incorporates imagery of dragons' wings, claws, teeth, or scales.",
"You have advantage on initiative rolls. While you are holding the focus, it can function as a spellcasting focus for all your spells.",
"The focus gains an additional property determined by the family of the dragon in whose hoard it became Stirring:",
{
"type": "list",
"style": "list-hang-notitle",
"items": [
{
"type": "item",
"name": "Chromatic",
"entries": [
"Whenever you use a spell slot to cast a spell that deals acid, cold, fire, lightning, or poison damage, roll a {@dice d6}, and you gain a bonus equal to the number rolled to one of the spell's damage rolls."
]
},
{
"type": "item",
"name": "Gem",
"entries": [
"Whenever you use a spell slot to cast a spell, you can immediately teleport to an unoccupied space you can see within 15 feet of you."
]
},
{
"type": "item",
"name": "Metallic",
"entries": [
"When a creature you can see within 30 feet of you makes a saving throw, you can use your reaction to give that creature advantage on the saving throw."
]
}
]
},
"While you are holding a Wakened focus, you can use it to cast certain spells. Once the item is used to cast a given spell, it can't be used to cast that spell again until the next dawn. The spells are determined by the family of the dragon in whose hoard it became Wakened.",
{
"type": "list",
"style": "list-hang-notitle",
"items": [
{
"type": "item",
"name": "Chromatic",
"entries": [
"{@spell Hold monster}, {@spell Rime's binding ice|FTD}"
]
},
{
"type": "item",
"name": "Gem",
"entries": [
"{@spell Rary's telepathic bond}, {@spell Raulothim's psychic lance|FTD}"
]
},
{
"type": "item",
"name": "Metallic",
"entries": [
"{@spell Fizban's platinum shield|FTD}, {@spell legend lore}"
]
}
]
}
],
"attachedSpells": [
"hold monster",
"rime's binding ice|ftd",
"rary's telepathic bond",
"raulothim's psychic lance|ftd",
"fizban's platinum shield|ftd",
"legend lore"
]
},
{
"name": "Wakened Scaled Ornament",
"source": "FTD",
"_copy": {
"name": "Stirring Scaled Ornament",
"source": "FTD",
"_preserve": {
"page": true
}
},
"rarity": "very rare",
"entries": [
"This ornament can be jewelry, a cloak, or another wearable accessory. It appears to be fashioned from a dragon's scale, tooth, or claw, or it incorporates images in those shapes.",
"You gain a +1 bonus to AC, and you can't be {@condition charmed} or {@condition frightened}. Moreover, each creature of your choice within 30 feet of you has advantage on saving throws it makes to avoid being {@condition charmed} or {@condition frightened} or to end those conditions on itself.",
"When you would take damage of the type dealt by the breath of the dragon in whose hoard the ornament became Wakened, you can use your reaction to take no damage instead, and you regain hit points equal to the damage you would have taken. Once this property is used, it can't be used again until the next dawn."
]
},
{
"name": "Wand of Binding",
"source": "DMG",
"page": 209,
"srd": true,
"type": "WD",
"tier": "major",
"rarity": "rare",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"weight": 1,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 1}",
"charges": 7,
"entries": [
"This wand has 7 charges for the following properties. It regains {@dice 1d6 + 1} expended charges daily at dawn. If you expend the wand's last charge, roll a {@dice d20}. On a 1, the wand crumbles into ashes and is destroyed.",
{
"name": "Spells",
"type": "entries",
"entries": [
"While holding the wand, you can use an action to expend some of its charges to cast one of the following spells (save DC 17): {@spell hold monster} (5 charges) or {@spell hold person} (2 charges)."
]
},
{
"name": "Assisted Escape",
"type": "entries",
"entries": [
"While holding the wand, you can use your reaction to expend 1 charge and gain advantage on a saving throw you make to avoid being {@condition paralyzed} or {@condition restrained}, or you can expend 1 charge and gain advantage on any check you make to escape a grapple."
]
}
],
"attachedSpells": [
"hold monster",
"hold person"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Wand of Conducting",
"source": "XGE",
"page": 140,
"type": "WD",
"tier": "minor",
"rarity": "common",
"weight": 1,
"recharge": "dawn",
"rechargeAmount": 3,
"charges": 3,
"entries": [
"This wand has 3 charges. While holding it, you can use an action to expend 1 of its charges and create orchestral music by waving it around. The music can be heard out to a range of 60 feet and ends when you stop waving the wand.",
"The wand regains all expended charges daily at dawn. If you expend the wand's last charge, roll a {@dice d20}. On a 1, a sad tuba sound plays as the wand crumbles to dust and is destroyed."
]
},
{
"name": "Wand of Enemy Detection",
"source": "DMG",
"page": 210,
"srd": true,
"type": "WD",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"weight": 1,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 1}",
"charges": 7,
"entries": [
"This wand has 7 charges. While holding it, you can use an action and expend 1 charge to speak its command word. For the next minute, you know the direction of the nearest creature hostile to you within 60 feet, but not its distance from you. The wand can sense the presence of hostile creatures that are ethereal, {@condition invisible}, disguised, or hidden, as well as those in plain sight. The effect ends if you stop holding the wand.",
"The wand regains {@dice 1d6 + 1} expended charges daily at dawn. If you expend the wand's last charge, roll a {@dice d20}. On a 1, the wand crumbles into ashes and is destroyed."
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Wand of Entangle",
"source": "TftYP",
"page": 229,
"type": "WD",
"rarity": "uncommon",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"weight": 1,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 1}",
"charges": 7,
"entries": [
"This wand has 7 charges. While holding it, you can use an action to expend 1 of its charges to cast the {@spell entangle} spell (save DC 13) from it.",
"The wand regains {@dice 1d6 + 1} expended charges daily at dawn. If you expend the wand's last charge, roll a {@dice d20}. On a 1, the wand crumbles into ashes and is destroyed."
],
"attachedSpells": [
"entangle"
]
},
{
"name": "Wand of Fear",
"source": "DMG",
"page": 210,
"srd": true,
"type": "WD",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"weight": 1,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 1}",
"charges": 7,
"entries": [
"This wand has 7 charges for the following properties. It regains {@dice 1d6 + 1} expended charges daily at dawn. If you expend the wand's last charge, roll a {@dice d20}. On a 1, the wand crumbles into ashes and is destroyed.",
{
"name": "Command",
"type": "entries",
"entries": [
"While holding the wand, you can use an action to expend 1 charge and command another creature to flee or grovel, as with the {@spell command} spell (save DC 15)."
]
},
{
"name": "Cone of Fear",
"type": "entries",
"entries": [
"While holding the wand, you can use an action to expend 2 charges, causing the wand's tip to emit a 60-foot cone of amber light. Each creature in the cone must succeed on a DC 15 Wisdom saving throw or become {@condition frightened} of you for 1 minute. While it is {@condition frightened} in this way, a creature must spend its turns trying to move as far away from you as it can, and it can't willingly move to a space within 30 feet of you. It also can't take reactions. For its action, it can use only the {@action Dash} action or try to escape from an effect that prevent it from moving. If it has nowhere it can move, the creature can use the {@action Dodge} action. At the end of each of its turns, a creature can repeat the saving throw, ending the effect on itself on a success."
]
}
],
"attachedSpells": [
"command"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Wand of Fireballs",
"source": "DMG",
"page": 210,
"srd": true,
"type": "WD",
"tier": "major",
"rarity": "rare",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"weight": 1,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 1}",
"charges": 7,
"entries": [
"This wand has 7 charges. While holding it, you can use an action to expend 1 or more of its charges to cast the {@spell fireball} spell (save DC 15) from it. For 1 charge, you cast the 3rd-level version of the spell. You can increase the spell slot level by one for each additional charge you expend.",
"The wand regains {@dice 1d6 + 1} expended charges daily at dawn. If you expend the wand's last charge, roll a {@dice d20}. On a 1, the wand crumbles into ashes and is destroyed."
],
"attachedSpells": [
"fireball"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Wand of Lightning Bolts",
"source": "DMG",
"page": 211,
"srd": true,
"type": "WD",
"tier": "major",
"rarity": "rare",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"weight": 1,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 1}",
"charges": 7,
"entries": [
"This wand has 7 charges. While holding it, you can use an action to expend 1 or more of its charges to cast the {@spell lightning bolt} spell (save DC 15) from it. For 1 charge, you cast the 3rd-level version of the spell. You can increase the spell slot level by one for each additional charge you expend.",
"The wand regains {@dice 1d6 + 1} expended charges daily at dawn. If you expend the wand's last charge, roll a {@dice d20}. On a 1, the wand crumbles into ashes and is destroyed."
],
"attachedSpells": [
"lightning bolt"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Wand of Magic Detection",
"source": "DMG",
"page": 211,
"srd": true,
"basicRules": true,
"type": "WD",
"tier": "minor",
"rarity": "uncommon",
"weight": 1,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"This wand has 3 charges. While holding it, you can expend 1 charge as an action to cast the {@spell detect magic} spell from it. The wand regains {@dice 1d3} expended charges daily at dawn."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"attachedSpells": [
"detect magic"
],
"lootTables": [
"Magic Item Table B"
],
"hasFluffImages": true
},
{
"name": "Wand of Magic Missiles",
"source": "DMG",
"page": 211,
"srd": true,
"basicRules": true,
"type": "WD",
"tier": "major",
"rarity": "uncommon",
"weight": 1,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 1}",
"charges": 7,
"entries": [
"This wand has 7 charges. While holding it, you can use an action to expend 1 or more of its charges to cast the {@spell magic missile} spell from it. For 1 charge, you cast the 1st-level version of the spell. You can increase the spell slot level by one for each additional charge you expend.",
"The wand regains {@dice 1d6 + 1} expended charges daily at dawn. If you expend the wand's last charge, roll a {@dice d20}. On a 1, the wand crumbles into ashes and is destroyed."
],
"attachedSpells": [
"magic missile"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Wand of Orcus",
"source": "DMG",
"page": 227,
"baseItem": "mace|phb",
"type": "WD",
"typeAlt": "M",
"rarity": "artifact",
"reqAttune": true,
"sentient": true,
"weight": 4,
"weaponCategory": "simple",
"dmg1": "1d6",
"dmgType": "B",
"bonusWeapon": "+3",
"bonusAc": "+3",
"recharge": "dawn",
"rechargeAmount": "{@dice 1d4 + 3}",
"charges": 7,
"entries": [
"The ghastly Wand of Orcus rarely leaves {@creature Orcus|MTF|Orcus's} side. The device, as evil as its creator, shares the demon lord's aims to snuff out the lives of all living things and bind the Material Plane in the stasis of undeath. {@creature Orcus|MTF} allows the wand to slip from his grasp from time to time. When it does, it magically appears wherever its master senses an opportunity to achieve some fell goal.",
"Made from bones as hard as iron, the wand is topped with a magically enlarged skull that once belonged to a human hero slain by {@creature Orcus|MTF}. The wand can magically change in size to better conform to the grip of its user. Plants wither, drinks spoil, flesh rots, and vermin thrive in the wand's presence.",
"Any creature besides {@creature Orcus|MTF} that tries to attune to the wand must make a DC 17 Constitution saving throw. On a successful save, the creature takes {@damage 10d6} necrotic damage. On a failed save, the creature dies and rises as a {@creature zombie}.",
"In the hands of one who is attuned to it, the wand can be wielded as a magic {@item mace|phb} that grants a +3 bonus to attack and damage rolls made with it. The wand deals an extra {@damage 2d12} necrotic damage on a hit.",
{
"name": "Random Properties",
"type": "entries",
"entries": [
"The Wand of Orcus has the following random properties:"
]
},
{
"type": "list",
"items": [
"2 {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial properties}",
"1 {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial property}",
"2 {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental properties}",
"1 {@table Artifact Properties; Major Detrimental Properties|dmg|major detrimental property}"
]
},
"The detrimental properties of the Wand of Orcus are suppressed while the wand is attuned to {@creature Orcus|MTF} himself.",
{
"name": "Protection",
"type": "entries",
"entries": [
"You gain a +3 bonus to Armor Class while holding the wand."
]
},
{
"name": "Spells",
"type": "entries",
"entries": [
"The wand has 7 charges. While holding it, you can use an action and expend 1 or more of its charges to cast one of the following spells (save DC 18) from it: {@spell animate dead} (1 charge), {@spell blight} (2 charges), {@spell circle of death} (3 charges), {@spell finger of death} (3 charges), {@spell power word kill} (4 charges), or {@spell speak with dead} (1 charge). The wand regains {@dice 1d4 + 3} expended charges daily at dawn.",
"While attuned to the wand, {@creature Orcus|MTF} or a follower blessed by him can cast each of the wand's spells using 2 fewer charges (minimum of 0)."
]
},
{
"name": "Call Undead",
"type": "entries",
"entries": [
"While you are holding the wand, you can use an action to conjure {@creature skeleton||skeletons} and {@creature zombie||zombies}, calling forth as many of them as you can divide 500 hit points among, each undead having average hit points. The undead magically rise up from the ground or otherwise form in unoccupied spaces within 300 feet of you and obey your commands until they are destroyed or until dawn of the next day, when they collapse into inanimate piles of bones and rotting corpses. Once you use this property of the wand, you can't use it again until the next dawn.",
"While attuned to the wand, {@creature Orcus|MTF} can summon any kind of undead, not just skeletons and zombies. The undead don't perish or disappear at dawn the following day, remaining until {@creature Orcus|MTF} dismisses them."
]
},
{
"name": "Sentience",
"type": "entries",
"entries": [
"The Wand of Orcus is a sentient, chaotic evil item with an Intelligence of 16, a Wisdom of 12, and a Charisma of 16. It has hearing and {@sense darkvision} out to a range of 120 feet.",
"The wand communicates telepathically with its wielder and can speak, read, and understand Abyssal and Common."
]
},
{
"name": "Personality",
"type": "entries",
"entries": [
"The wand's purpose is to help satisfy {@creature Orcus|MTF|Orcus's} desire to slay everything in the multiverse. The wand is cold, cruel, nihilistic, and bereft of humor. In order to further its master's goals, the wand feigns devotion to its current user and makes grandiose promises that it has no intention of fulfilling, such as vowing to help its user overthrow {@creature Orcus|MTF}."
]
},
{
"name": "Destroying the Wand",
"type": "entries",
"entries": [
"Destroying the Wand of Orcus requires that it be taken to the Positive Energy Plane by the ancient hero whose skull surmounts it. For this to happen, the long-lost hero must first be restored to life—no easy task, given the fact that {@creature Orcus|MTF} has imprisoned the hero's soul and keeps it hidden and well guarded.",
"Bathing the wand in positive energy causes it to crack and explode, but unless the above conditions are met, the wand instantly reforms on {@creature Orcus|MTF|Orcus's} layer of the Abyss."
]
}
],
"attachedSpells": [
"animate dead",
"blight",
"circle of death",
"finger of death",
"power word kill",
"speak with dead"
],
"hasFluffImages": true
},
{
"name": "Wand of Paralysis",
"source": "DMG",
"page": 211,
"srd": true,
"type": "WD",
"tier": "major",
"rarity": "rare",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"weight": 1,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 1}",
"charges": 7,
"entries": [
"This wand has 7 charges. While holding it, you can use an action to expend 1 of its charges to cause a thin blue ray to streak from the tip toward a creature you can see within 60 feet of you. The target must succeed on a DC 15 Constitution saving throw or be {@condition paralyzed} for 1 minute. At the end of each of the target's turns, it can repeat the saving throw, ending the effect on itself on a success.",
"The wand regains {@dice 1d6 + 1} expended charges daily at dawn. If you expend the wand's last charge, roll a {@dice d20}. On a 1, the wand crumbles into ashes and is destroyed."
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Wand of Polymorph",
"source": "DMG",
"page": 211,
"srd": true,
"type": "WD",
"tier": "major",
"rarity": "very rare",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"weight": 1,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 1}",
"charges": 7,
"entries": [
"This wand has 7 charges. While holding it, you can use an action to expend 1 of its charges to cast the {@spell polymorph} spell (save DC 15) from it.",
"The wand regains {@dice 1d6 + 1} expended charges daily at dawn. If you expend the wand's last charge, roll a {@dice d20}. On a 1, the wand crumbles into ashes and is destroyed."
],
"attachedSpells": [
"polymorph"
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Wand of Pyrotechnics",
"source": "XGE",
"page": 140,
"type": "WD",
"tier": "minor",
"rarity": "common",
"weight": 1,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 1}",
"charges": 7,
"entries": [
"This wand has 7 charges. While holding it, you can use an action to expend 1 of its charges and create a harmless burst of multicolored light at a point you can see up to 60 feet away. The burst of light is accompanied by a crackling noise that can be heard up to 300 feet away. The light is as bright as a torch flame but lasts only a second.",
"The wand regains {@dice 1d6 + 1} expended charges daily at dawn. If you expend the wand's last charge, roll a {@dice d20}. On a 1, the wand erupts in a harmless pyrotechnic display and is destroyed."
]
},
{
"name": "Wand of Scowls",
"source": "XGE",
"page": 140,
"type": "WD",
"tier": "minor",
"rarity": "common",
"weight": 1,
"recharge": "dawn",
"rechargeAmount": 3,
"charges": 3,
"entries": [
"This wand has 3 charges. While holding it, you can use an action to expend 1 of its charges and target a humanoid you can see within 30 feet of you. The target must succeed on a DC 10 Charisma saving throw or be forced to scowl for 1 minute.",
"The wand regains all expended charges daily at dawn. If you expend the wand's last charge, roll a {@dice d20}. On a 1, the wand transforms into a {@item wand of smiles|xge}."
]
},
{
"name": "Wand of Secrets",
"source": "DMG",
"page": 211,
"srd": true,
"type": "WD",
"tier": "minor",
"rarity": "uncommon",
"weight": 1,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"The wand has 3 charges. While holding it, you can use an action to expend 1 of its charges, and if a secret door or trap is within 30 feet of you, the wand pulses and points at the one nearest to you. The wand regains {@dice 1d3} expended charges daily at dawn."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table B"
],
"hasFluffImages": true
},
{
"name": "Wand of Smiles",
"source": "XGE",
"page": 140,
"type": "WD",
"tier": "minor",
"rarity": "common",
"weight": 1,
"recharge": "dawn",
"rechargeAmount": 3,
"charges": 3,
"entries": [
"This wand has 3 charges. While holding it, you can use an action to expend 1 of its charges and target a humanoid you can see within 30 feet of you. The target must succeed on a DC 10 Charisma saving throw or be forced to smile for 1 minute.",
"The wand regains all expended charges daily at dawn. If you expend the wand's last charge, roll a {@dice d20}. On a 1, the wand transforms into a {@item wand of scowls|xge}."
]
},
{
"name": "Wand of Viscid Globs",
"source": "OotA",
"page": 223,
"type": "WD",
"rarity": "rare",
"reqAttune": true,
"weight": 1,
"recharge": "midnight",
"rechargeAmount": "{@dice 1d6 + 1}",
"charges": 7,
"entries": [
"Crafted by the {@creature drow}, this slim black wand has 7 charges. While holding it, you can use an action to expend 1 of its charges to cause a small glob of viscous material to launch from the tip at one creature within 60 feet of you. Make a ranged attack roll against the target, with a bonus equal to your spellcasting modifier (or your Intelligence modifier, if you don't have a spellcasting modifier) plus your proficiency bonus. On a hit, the glob expands and dries on the target, which is {@condition restrained} for 1 hour. After that time, the viscous material cracks and falls away.",
"Applying a pint or more of alcohol to the {@condition restrained} creature dissolves the glob instantly, as does the application of {@item oil of etherealness} or {@item universal solvent}. The glob also dissolves instantly if exposed to sunlight. No other nonmagical process can remove the viscous material until it deteriorates on its own.",
"The wand regains {@dice 1d6 + 1} expended charges daily at midnight. If you expend the wands last charge, roll a {@dice d20}. On a 1, the wand melts into harmless slime and is destroyed.",
"A wand of viscous globs is destroyed if exposed to sunlight for 1 hour without interruption."
]
},
{
"name": "Wand of Web",
"source": "DMG",
"page": 212,
"srd": true,
"type": "WD",
"tier": "major",
"rarity": "uncommon",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"weight": 1,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 1}",
"charges": 7,
"entries": [
"This wand has 7 charges. While holding it, you can use an action to expend 1 of its charges to cast the {@spell web} spell (save DC 15) from it.",
"The wand regains {@dice 1d6 + 1} expended charges daily at dawn. If you expend the wand's last charge, roll a {@dice d20}. On a 1, the wand crumbles into ashes and is destroyed."
],
"attachedSpells": [
"web"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Wand of Winter",
"source": "HotDQ",
"page": 94,
"otherSources": [
{
"source": "ToD",
"page": 178
}
],
"type": "WD",
"rarity": "rare",
"reqAttune": true,
"weight": 1,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 1}",
"charges": 7,
"entries": [
"This wand looks and feels like an icicle. You must be attuned to the wand to use it.",
"The wand has 7 charges, which are used to fuel the spells within it. With the wand in hand, you can use your action to cast one of the following spells from the wand, even if you are incapable of casting spells: {@spell ray of frost} (no charges, or 1 charge to cast at 5th level; +5 to hit with ranged spell attack), {@spell sleet storm} (3 charges; spell save DC 15), or {@spell ice storm} (4 charges; spell save DC 15). No components are required. The wand regains {@dice 1d6 + 1} expended charges each day at dawn. If you expend the wand's last charge, roll a {@dice d20}. On a 20, the wand melts away, forever destroyed."
],
"attachedSpells": [
"ray of frost",
"sleet storm",
"ice storm"
],
"hasFluffImages": true
},
{
"name": "Wand of Wonder",
"source": "DMG",
"page": 212,
"srd": true,
"type": "WD",
"tier": "major",
"rarity": "rare",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"weight": 1,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d6 + 1}",
"charges": 7,
"entries": [
"This wand has 7 charges. While holding it, you can use an action to expend 1 of its charges and choose a target within 120 feet of you. The target can be a creature, an object, or a point in space. Roll {@dice d100} and consult the following table to discover what happens.",
"If the effect causes you to cast a spell from the wand, the spell's save DC is 15. If the spell normally has a range expressed in feet, its range becomes 120 feet if it isn't already.",
"If an effect covers an area, you must center the spell on and include the target. If an effect has multiple possible subjects, the DM randomly determines which ones are affected.",
"The wand regains {@dice 1d6 + 1} expended charges daily at dawn. If you expend the wand's last charge, roll a {@dice d20}. On a 1, the wand crumbles into dust and is destroyed.",
{
"type": "table",
"colLabels": [
"{@dice d100}",
"Effect"
],
"colStyles": [
"col-1 text-center",
"col-11"
],
"rows": [
[
"01-05",
"You cast {@spell slow}."
],
[
"06-10",
"You cast {@spell faerie fire}."
],
[
"11-15",
"You are {@condition stunned} until the start of your next turn, believing something awesome just happened."
],
[
"16-20",
"You cast {@spell gust of wind}."
],
[
"21-25",
"You cast {@spell detect thoughts} on the target you chose. If you didn't target a creature, you instead take {@damage 1d6} psychic damage."
],
[
"26-30",
"You cast {@spell stinking cloud}."
],
[
"31-33",
"Heavy rain falls in a 60-foot radius centered on the target. The area becomes lightly obscured. The rain falls until the start of your next turn."
],
[
"34-36",
"An animal appears in the unoccupied space nearest the target. The animal isn't under your control and acts as it normally would. Roll a {@dice d100} to determine which animal appears. On a 01-25, a {@creature rhinoceros} appears; on a 26-50, an {@creature elephant} appears; and on a 51-100, a {@creature rat} appears."
],
[
"37-46",
"You cast {@spell lightning bolt}."
],
[
"47-49",
"A cloud of 600 oversized butterflies fills a 30-foot radius centered on the target. The area becomes heavily obscured. The butterflies remain for 10 minutes."
],
[
"50-53",
"You enlarge the target as if you had cast {@spell enlarge/reduce}. If the target can't be affected by that spell or if you didn't target a creature, you become the target."
],
[
"54-58",
"You cast {@spell darkness}."
],
[
"59-62",
"Grass grows on the ground in a 60-foot radius centered on the target. If grass is already there, it grows to ten times its normal size and remains overgrown for 1 minute."
],
[
"63-65",
"An object of the DM's choice disappears into the Ethereal Plane. The object must be neither worn nor carried, within 120 feet of the target, and no larger than 10 feet in any dimension."
],
[
"66-69",
"You shrink yourself as if you had cast {@spell enlarge/reduce} on yourself."
],
[
"70-79",
"You cast {@spell fireball}."
],
[
"80-84",
"You cast {@spell invisibility} on yourself."
],
[
"85-87",
"Leaves grow from the target. If you chose a point in space as the target, leaves sprout from the creature nearest to that point. Unless they are picked off, the leaves turn brown and fall off after 24 hours."
],
[
"88-90",
"A stream of {@dice 1d4 × 10} gems, each worth 1 gp, shoots from the wand's tip in a line 30 feet long and 5 feet wide. Each gem deals 1 bludgeoning damage, and the total damage of the gems is divided equally among all creatures in the line."
],
[
"91-95",
"A burst of colorful shimmering light extends from you in a 30-foot radius. You and each creature in the area that can see must succeed on a DC 15 Constitution saving throw or become {@condition blinded} for 1 minute. A creature can repeat the saving throw at the end of each of its turns, ending the effect on itself on a success."
],
[
"96-97",
"The target's skin turns bright blue for {@dice 1d10} days. If you chose a point in space, the creature nearest to that point is affected."
],
[
"98-00",
"If you targeted a creature, it must make a DC 15 Constitution saving throw. If you didn't target a creature, you become the target and must make the saving throw. If the saving throw fails by 5 or more, the target is instantly {@condition petrified}. On any other failed save, the target is {@condition restrained} and begins to turn to stone. While {@condition restrained} in this way, the target must repeat the saving throw at the end of its next turn, becoming {@condition petrified} on a failure or ending the effect on a success. The petrification lasts until the target is freed by the {@spell greater restoration} spell or similar magic."
]
]
}
],
"attachedSpells": [
"slow",
"faerie fire",
"gust of wind",
"detect thoughts",
"stinking cloud",
"lightning bolt",
"enlarge/reduce",
"darkness",
"fireball",
"invisibility"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Wand Sheath",
"source": "ERLW",
"page": 279,
"otherSources": [
{
"source": "UAWGE",
"page": 115
}
],
"rarity": "common",
"reqAttune": "by a warforged",
"reqAttuneTags": [
{
"race": "warforged|erlw"
}
],
"wondrous": true,
"entries": [
"A wand sheath clamps onto your arm and imparts the following benefits:",
{
"type": "list",
"items": [
"The wand sheath can't be removed from you while you're attuned to it.",
"You can insert a wand into the sheath as an action. The sheath can hold only one wand at a time.",
"You can retract or extend a wand from the sheath as a bonus action. While the wand is extended, you can use it as if you were holding it, but your hand remains free."
]
},
"If a sheathed wand requires attunement, you must attune to the wand before you can use it. However, the wand sheath and the attached wand count as a single magic item with regard to the number of magic items you can attune to. If you remove the wand from the sheath, your attunement to the wand ends."
]
},
{
"name": "Wargong",
"source": "SCAG",
"page": 124,
"type": "INS",
"rarity": "none",
"entries": [
"A metal gong, traditionally made from a shield, particularly the shield of an enemy. Both goblins and dwarves make and play wargongs, their sound echoing through tunnels in the Underdark."
]
},
{
"name": "Warhorse",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "MNT",
"rarity": "none",
"value": 40000,
"carryingCapacity": 540,
"speed": 60
},
{
"name": "Warship",
"source": "DMG",
"page": 119,
"srd": true,
"basicRules": true,
"type": "SHP",
"rarity": "none",
"value": 2500000,
"crew": 60,
"vehAc": 15,
"vehHp": 500,
"vehDmgThresh": 20,
"vehSpeed": 2.5,
"capPassenger": 60,
"capCargo": 200,
"seeAlsoVehicle": [
"Warship"
]
},
{
"name": "Wasp Ship",
"source": "AAG",
"page": 0,
"type": "SPC",
"rarity": "none",
"value": 2000000,
"crew": 5,
"vehAc": 15,
"vehHp": 250,
"vehDmgThresh": 15,
"vehSpeed": 5.5,
"capCargo": 10,
"entries": [
"Wasp ships are lightweight wooden vessels capable of landing safely on the ground, but not on water. They appeal to adventurers because they're affordable and don't require a large crew. Pirates like them because they're swift. Aristocrats are fond of using them as flying yachts because the ship's cargo hold can be easily converted into posh living quarters.",
"The ship's raised back provides high ground for a weapon emplacement, which is typically equipped with a ballista."
],
"seeAlsoVehicle": [
"Wasp Ship|AAG"
]
},
{
"name": "Watchful Helm",
"source": "CM",
"page": 183,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"bonusAc": "+1",
"entries": [
"While you wear this helm, you gain a +1 bonus to AC and remain aware of your surroundings even while you're asleep, and you have advantage on Wisdom ({@skill Perception}) checks that rely on sight.",
"As a bonus action, you can cast the {@spell see invisibility} spell from the helm. Once this property of the helm is used, it can't be used again until the next dawn."
],
"attachedSpells": [
"see invisibility"
]
},
{
"name": "Waterskin",
"source": "PHB",
"page": 153,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 5,
"weightNote": "(full)",
"value": 20,
"entries": [
"A waterskin can hold up to 4 pints of liquid."
]
},
{
"name": "Wave",
"source": "DMG",
"page": 218,
"baseItem": "trident|phb",
"type": "M",
"rarity": "legendary",
"reqAttune": "by a creature that worships a god of the sea",
"sentient": true,
"weight": 4,
"weaponCategory": "martial",
"property": [
"T",
"V"
],
"range": "20/60",
"dmg1": "1d6",
"dmgType": "P",
"dmg2": "1d8",
"bonusWeapon": "+3",
"entries": [
"Held in the dungeon of White Plume Mountain, this trident is an exquisite weapon engraved with images of waves, shells, and sea creatures. Although you must worship a god of the sea to attune to this weapon, Wave happily accepts new converts.",
"You gain a +3 bonus to attack and damage rolls made with this magic weapon. If you score a critical hit with it, the target takes extra necrotic damage equal to half its hit point maximum.",
"The weapon also functions as a {@item trident of fish command} and a {@item weapon of warning}. It can confer the benefit of a {@item cap of water breathing} while you hold it, and you can use it as a {@item cube of force} by choosing the effect, instead of pressing cube sides to select it.",
{
"name": "Sentience",
"type": "entries",
"entries": [
"Wave is a sentient weapon of neutral alignment, with an Intelligence of 14, a Wisdom of 10, and a Charisma of 18. It has hearing and {@sense darkvision} out to a range of 120 feet.",
"The weapon communicates telepathically with its wielder and can speak, read, and understand Aquan. It can also speak with aquatic animals as if using a {@spell speak with animals} spell, using telepathy to involve its wielder in the conversation."
]
},
{
"name": "Personality",
"type": "entries",
"entries": [
"When it grows restless, Wave has a habit of humming tunes that vary from sea chanteys to sacred hymns of the sea gods.",
"Wave zealously desires to convert mortals to the worship of one or more sea gods, or else to consign the faithless to death. Conflict arises if the wielder fails to further the weapon's objectives in the world. The trident has a nostalgic attachment to the place where it was forged, a desolate island called Thunderforge. A sea god imprisoned a family of storm giants there, and the giants forged Wave in an act of devotion to—or rebellion against—that god.",
"Wave harbors a secret doubt about its own nature and purpose. For all its devotion to the sea gods, Wave fears that it was intended to bring about a particular sea god's demise. This destiny is something Wave might not be able to avert."
]
}
],
"attachedSpells": [
"speak with animals"
]
},
{
"name": "Waythe",
"source": "TftYP",
"page": 229,
"baseItem": "greatsword|phb",
"type": "M",
"rarity": "legendary",
"reqAttune": true,
"sentient": true,
"weight": 6,
"weaponCategory": "martial",
"property": [
"H",
"2H"
],
"dmg1": "2d6",
"dmgType": "S",
"bonusWeapon": "+1",
"entries": [
"Waythe is a unique greatsword most recently in the possession of a high-ranking {@creature cloud giant} ambassador.",
"You gain a +1 bonus to attack and damage rolls made with this magic weapon. When you hit a creature of the giant type with it, the giant takes an extra {@damage 2d6} slashing damage, and it must succeed on a DC 15 Strength saving throw or fall {@condition prone}.",
"The sword also functions as a {@item wand of enemy detection}. It regains all of its expended charges at dawn and isn't at risk of crumbling if its last charge is used.",
{
"name": "Sentience",
"type": "entries",
"entries": [
"Waythe is a sentient weapon of neutral good alignment, with an Intelligence of 12, a Wisdom of 2, and a Charisma of 14. It has hearing and {@sense darkvision} out to a range of 120 feet.",
"The weapon can speak and understand Giant and Common, and it can communicate telepathically with its wielder."
]
},
{
"name": "Personality",
"type": "entries",
"entries": [
"This sword believes in freedom and allowing others to live as they see fit. It is protective of its friends, and wants to be friends with a like-minded wielder. (It takes only 1 minute for a good-aligned character to gain attunement with the sword.) Waythe is courageous to the point of foolhardiness, however, and vocally urges bold action. It is likely to come into conflict with an evil or a timid wielder."
]
}
]
},
{
"name": "Weaver's Tools",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"additionalSources": [
{
"source": "XGE",
"page": 85
}
],
"type": "AT",
"rarity": "none",
"weight": 5,
"value": 100,
"additionalEntries": [
"Weaver's tools allow you to create cloth and tailor it into articles of clothing.",
{
"type": "entries",
"name": "Components",
"entries": [
"Weaver's tools include thread, needles, and scraps of cloth. You know how to work a loom, but such equipment is too large to transport."
]
},
{
"type": "entries",
"name": "Arcana, History",
"entries": [
"Your expertise lends you additional insight when examining cloth objects, including cloaks and robes."
]
},
{
"type": "entries",
"name": "Investigation",
"entries": [
"Using your knowledge of the process of creating cloth objects, you can spot clues and make deductions that others would overlook when you examine tapestries, upholstery, clothing, and other woven items."
]
},
{
"type": "entries",
"name": "Repair",
"entries": [
"As part of a short rest, you can repair a single damaged cloth object."
]
},
{
"type": "entries",
"name": "Craft Clothing",
"entries": [
"Assuming you have access to sufficient cloth and thread, you can create an outfit for a creature as part of a long rest."
]
},
{
"type": "table",
"caption": "Weaver's Tools",
"colLabels": [
"Activity",
"DC"
],
"colStyles": [
"col-10",
"col-2 text-center"
],
"rows": [
[
"Repurpose cloth",
"10"
],
[
"Mend a hole in a piece of cloth",
"10"
],
[
"Tailor an outfit",
"15"
]
]
}
]
},
{
"name": "Weird Tank",
"source": "PotA",
"page": 223,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"entries": [
"A weird tank is a ten-gallon tank of blown glass and sculpted bronze with a backpack-like carrying harness fashioned from tough leather. A {@creature water weird} is contained within the tank. While wearing the tank, you can use an action to open it, allowing the {@creature water weird} to emerge. The {@creature water weird} acts immediately after you in the initiative order, and it is bound to the tank.",
"You can command the {@creature water weird} telepathically (no action required) while you wear the tank. You can close the tank as an action only if you have first commanded the {@creature water weird} to retract into it or if the {@creature water weird} is dead.",
"If the {@creature water weird} is killed, the tank loses its magical containment property until it spends at least 24 hours inside an elemental water node. When the tank is recharged, a new {@creature water weird} forms inside it.",
"The tank has AC 15, 50 hit points, vulnerability to bludgeoning damage, and immunity to poison and psychic damage. Reducing the tank to 0 hit points destroys it and the {@creature water weird} contained within it."
],
"hasFluffImages": true
},
{
"name": "Well of Many Worlds",
"source": "DMG",
"page": 213,
"srd": true,
"tier": "major",
"rarity": "legendary",
"wondrous": true,
"entries": [
"This fine black cloth, soft as silk, is folded up to the dimensions of a handkerchief. It unfolds into a circular sheet 6 feet in diameter.",
"You can use an action to unfold and place the well of many worlds on a solid surface, whereupon it creates a two-way portal to another world or plane of existence. Each time the item opens a portal, the DM decides where it leads. You can use an action to close an open portal by taking hold of the edges of the cloth and folding it up. Once well of many worlds has opened a portal, it can't do so again for {@dice 1d8} hours."
],
"lootTables": [
"Magic Item Table I"
]
},
{
"name": "Wheat",
"source": "PHB",
"page": 157,
"srd": true,
"basicRules": true,
"type": "TG",
"rarity": "none",
"weight": 1,
"value": 1
},
{
"name": "Wheel of Stars",
"source": "AI",
"page": 222,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"bonusSavingThrow": "+1",
"entries": [
"While attuned to this device, you have a +1 bonus to Wisdom saving throws, and you can use an action to cast the {@spell dancing lights}, {@spell guidance}, or {@spell message} cantrips.",
{
"type": "entries",
"name": "Portent of the Stars",
"entries": [
"As a reaction when a creature you can see within 60 feet of you makes an attack roll, saving throw, or ability check, you make that creature roll a {@dice d10} and add or subtract the number rolled (your choice) from the roll. Once you use this feature of the wheel of stars, it cannot be used again until the next dawn."
]
},
{
"type": "entries",
"name": "Alter Gravity",
"entries": [
"As an action, you can cast {@spell fly} on yourself or you can cast {@spell levitate} (save DC 15). Once you use this feature of the wheel of stars, it cannot be used again until the next dawn."
]
},
{
"type": "entries",
"name": "Part of a Whole",
"entries": [
"While this component is not installed in the {@item Orrery of the Wanderer|AI}, its magic might function sporadically or with unpredictable side effects, as determined by the DM."
]
}
],
"attachedSpells": [
"dancing lights",
"guidance",
"message",
"fly",
"levitate"
]
},
{
"name": "Wheel of Wind and Water",
"source": "ERLW",
"page": 280,
"otherSources": [
{
"source": "UAWGE",
"page": 115
}
],
"rarity": "uncommon",
"wondrous": true,
"entries": [
"When mounted at the helm of an elemental galleon or airship, this wheel allows a creature that possesses the Mark of Storm to telepathically control the elemental bound inside the vessel.",
"If a wheel of wind and water is mounted on a mundane sailing ship, a creature with the Mark of Storm who is using the wheel can create an area of ideal conditions around the vessel, increasing its speed by 5 miles per hour."
]
},
{
"name": "Whelm",
"source": "DMG",
"page": 218,
"baseItem": "warhammer|phb",
"type": "M",
"rarity": "legendary",
"reqAttune": "by a dwarf",
"reqAttuneTags": [
{
"race": "dwarf"
}
],
"sentient": true,
"weight": 2,
"weaponCategory": "martial",
"property": [
"T",
"V"
],
"range": "20/60",
"dmg1": "1d8",
"dmgType": "B",
"dmg2": "1d10",
"bonusWeapon": "+3",
"entries": [
"Whelm is a powerful warhammer forged by dwarves and lost in the dungeon of White Plume Mountain.",
"You gain a +3 bonus to attack and damage rolls made with this magic weapon. At dawn the day after you first make an attack roll with Whelm, you develop a fear of being outdoors that persists as long as you remain attuned to the weapon. This causes you to have disadvantage on attack rolls, saving throws, and ability checks while you can see the daytime sky.",
{
"name": "Thrown Weapon",
"type": "entries",
"entries": [
"Whelm has the thrown property, with a normal range of 20 feet and a long range of 60 feet. When you hit with a ranged weapon attack using it, the target takes an extra {@damage 1d8} bludgeoning damage, or an extra {@damage 2d8} bludgeoning damage if the target is a giant. Each time you throw the weapon, it flies back to your hand after the attack. If you don't have a hand free, the weapon lands at your feet."
]
},
{
"name": "Shock Wave",
"type": "entries",
"entries": [
"You can use an action to strike the ground with Whelm and send a shock wave out from the point of impact. Each creature of your choice on the ground within 60 feet of that point must succeed on a DC 15 Constitution saving throw or become {@condition stunned} for 1 minute. A creature can repeat the saving throw at the end of each of its turns, ending the effect on itself on a success. Once used, this property can't be used again until the next dawn."
]
},
{
"name": "Supernatural Awareness",
"type": "entries",
"entries": [
"While you are holding the weapon, it alerts you to the location of any secret or concealed doors within 30 feet of you. In addition, you can use an action to cast {@spell detect evil and good} or {@spell locate object} from the weapon. Once you cast either spell, you can't cast it from the weapon again until the next dawn."
]
},
{
"name": "Sentience",
"type": "entries",
"entries": [
"Whelm is a sentient lawful neutral weapon with an Intelligence of 15, a Wisdom of 12, and a Charisma of 15. It has hearing and {@sense darkvision} out to a range of 120 feet.",
"The weapon communicates telepathically with its wielder and can speak, read, and understand Dwarvish, Giant, and Goblin. It shouts battle cries in Dwarvish when used in combat."
]
},
{
"name": "Personality",
"type": "entries",
"entries": [
"Whelm's purpose is to slaughter giants and goblinoids. It also seeks to protect dwarves against all enemies. Conflict arises if the wielder fails to destroy goblins and giants or to protect dwarves. Whelm has ties to the dwarf clan that created it, variously called the Dankil or the Mightyhammer clan. It longs to be returned to that clan. It would do anything to protect those dwarves from harm. The hammer also carries a secret shame. Centuries ago, a dwarf named Ctenmiir wielded it valiantly for a time. But Ctenmiir was turned into a {@creature vampire}. His will was strong enough that he bent Whelm to his evil purposes, even killing members of his own clan."
]
}
],
"attachedSpells": [
"detect evil and good",
"locate object"
]
},
{
"name": "Whetstone",
"source": "PHB",
"page": 150,
"srd": true,
"basicRules": true,
"type": "G",
"rarity": "none",
"weight": 1,
"value": 1
},
{
"name": "Whisper Jar",
"source": "AI",
"page": 27,
"rarity": "varies",
"reqAttune": true,
"wondrous": true,
"entries": [
"Also at rank 1, you are the beneficiary of a whisper jar—a common magic item resembling an ether-filled jar with a long tap hose. The jar is used to record others' stories and your own observations, like a verbal notebook with unlimited space. The whisper jar records as fast as a creature can speak into it, and whispers back words at the same pace they were recorded. Activating the playback of a particular recording is an action.",
{
"type": "table",
"caption": "Things Recorded in Your Whisper Jar",
"colLabels": [
"d8",
"Recording"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"1",
"A live recording of the only performance of the mostly forgotten stage play Tabaxi on a Hot Tin Roof"
],
[
"2",
"The life's work of the loremonger who owned the jar before you"
],
[
"3",
"Every courageous thought you've ever had, to cover for how scared you are most of the time"
],
[
"4",
"Everything, because everyone else around you will eat their words one day!"
],
[
"5",
"The top seven places to eat fried rat skewers in every settlement you've ever visited"
],
[
"6",
"The entire family tree of every royal line, extant or lost, in Faerûn"
],
[
"7",
"A moment-by-moment review of your favorite Jim Darkmagic stage performance"
],
[
"8",
"The sound of raucous applause, which you play back liberally"
]
]
},
{
"type": "entries",
"name": "Whispered Encyclopedia",
"entries": [
"At rank 2, your whisper jar becomes an uncommon magic item that can tap into the broader lore of Acquisitions Incorporated. As a bonus action, you ask the jar for information on a specific subject and make a DC 15 Intelligence ({@skill History}) check. On a success, the jar plays back a recording on that subject, made somewhere, sometime by another Acq Inc loremonger. This property of the whisper jar can't be used again until the next dawn."
]
},
{
"type": "entries",
"name": "Whispered Warnings",
"entries": [
"Also at rank 3, your whisper jar gains additional power and becomes a rare magic item. As an action, you can cause the jar to function as a {@item wand of enemy detection}, whereupon it whispers the direction to foes within range. This property of the whisper jar can't be used again until the next dawn."
]
},
{
"type": "entries",
"name": "Whispered Arcana",
"entries": [
"Also at rank 4, your whisper jar gains access to even more magic. By whispering the name of one of the following spells into the jar as an action, you can cast that spell from the jar: {@spell detect evil and good}, {@spell detect magic}, {@spell detect poison and disease}, {@spell find traps}, {@spell identify}, or {@spell locate animals or plants}. This property of the whisper jar can't be used again until the next dawn."
]
}
],
"attachedSpells": [
"detect evil and good",
"detect magic",
"detect poison and disease",
"find traps",
"identify",
"locate animals or plants"
],
"hasFluffImages": true
},
{
"name": "Whistle-Stick",
"source": "OGA",
"page": 4,
"type": "INS",
"rarity": "unknown",
"entries": [
"The {@race grung|OGA} of {@book One Grung Above|OGA} are trained to use this new musical instrument. This is a hollow tube with holes cut throughout, much like a flute. You can play music with it for entertainment, but the grung also swing it about by a sturdy cord (attached) to create a sound recognizable by other grung, so they know each other's approximate location. Additionally, grung that know {@language Thieves' Cant} can use a whistle stick in this manner to communicate over distance."
]
},
{
"name": "White Chromatic Rose",
"source": "WBtW",
"page": 208,
"resist": [
"cold"
],
"detail1": "covered in frost",
"rarity": "rare",
"wondrous": true,
"hasRefs": true,
"entries": [
"{#itemEntry Chromatic Rose|WBtW}"
]
},
{
"name": "White Dragon Cape",
"source": "TftYP",
"page": 193,
"type": "OTH",
"resist": [
"cold"
],
"rarity": "unknown (magic)",
"entries": [
"You have resistance to cold damage while wearing this cape."
]
},
{
"name": "White Dragon Mask",
"source": "RoTOS",
"page": 4,
"resist": [
"cold"
],
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"grantsProficiency": true,
"entries": [
"This gleaming mask is white with highlights of pale blue and is topped by a spined crest. The mask reshapes to fit a wearer attuned to it. While you are wearing the mask and attuned to it, you can access the following properties.",
{
"type": "entries",
"name": "Damage Absorption",
"entries": [
"You have resistance against cold damage. If you already have resistance to cold damage from another source, you instead have immunity to cold damage. If you already have immunity to cold damage from another source, whenever you are subjected to cold damage, you take none of that damage and regain a number of hit points equal to half the damage dealt of that type."
]
},
{
"type": "entries",
"name": "Draconic Majesty",
"entries": [
"While you are wearing no armor, you can add your Charisma bonus to your Armor Class."
]
},
{
"type": "entries",
"name": "Dragon Breath",
"entries": [
"If you have a breath weapon that requires rest to recharge, it gains a recharge of 6."
]
},
{
"type": "entries",
"name": "Dragon Sight",
"entries": [
"You gain {@sense darkvision} out to 60 feet, or to an additional 60 feet if you already have that sense. Once per day, you can gain {@sense blindsight} out to 30 feet for 5 minutes."
]
},
{
"type": "entries",
"name": "Dragon Tongue",
"entries": [
"You can speak and understand Draconic. You also have advantage on any Charisma check you make against White Dragons."
]
},
{
"type": "entries",
"name": "Legendary Resistance",
"entries": [
"(1/Day) If you fail a saving throw, you can choose to succeed instead."
]
},
{
"type": "entries",
"name": "Winter's Fury",
"entries": [
"While your current hit points are equal to or less than half your hit point maximum, you deal an extra {@damage 1d8} cold damage with your melee attacks."
]
}
]
},
{
"name": "White Dragon Scale Mail",
"source": "DMG",
"page": 165,
"srd": true,
"type": "MA",
"resist": [
"cold"
],
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"weight": 45,
"ac": 14,
"bonusAc": "+1",
"bonusSavingThrow": "+1",
"stealth": true,
"entries": [
"Dragon scale mail is made of the scales of one kind of dragon. Sometimes dragons collect their cast-off scales and gift them to humanoids. Other times, hunters carefully skin and preserve the hide of a dead dragon. In either case, dragon scale mail is highly valued. While wearing this armor, you gain a +1 bonus to AC, you have advantage on saving throws against the Frightful Presence and breath weapons of dragons, and you have resistance to cold damage.",
"Additionally, you can focus your senses as an action to magically discern the distance and direction to the closest white dragon within 30 miles of you. This special action can't be used again until the next dawn."
]
},
{
"name": "White Ghost Orchid Seed",
"source": "JttRC",
"page": 0,
"type": "G",
"rarity": "none",
"entries": [
"More rarely, the orchids produce a smaller pod holding a single white seed. Among its various magical properties, if a white seed is ground and scattered over a dead body, the body is affected by the {@spell resurrection} spell. A white ghost orchid seed has no effect if consumed."
]
},
{
"name": "Wildroot",
"source": "ToA",
"page": 205,
"type": "G",
"rarity": "unknown",
"value": 2500,
"entries": [
"Introducing the juice of a wildroot into a {@condition poisoned} creature's bloodstream (for example, by rubbing it on an open wound) rids the creature of the {@condition poisoned} condition. Once used in this way, a wildroot loses this property."
]
},
{
"name": "Wildspace Orrery",
"source": "AAG",
"page": 23,
"rarity": "uncommon",
"wondrous": true,
"entries": [
"Inside a Wildspace system, this portable arcane device automatically tracks the positions and movements of all suns, planets, moons, and comets within that system, projecting a display of all these bodies in the space above its current location. In that display, a white, pulsating pinprick of light marks the orrery's location."
]
},
{
"name": "Will of the Talon (Awakened)",
"source": "EGW",
"_copy": {
"name": "Will of the Talon (Dormant)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Awakened",
"entries": [
"When the war pick reaches an awakened state, it gains the following properties:",
{
"type": "list",
"items": [
"The weapon's bonus to attack and damage rolls increases to +2.",
"The saving throw DC for the war pick's Breath Weapon property increases to 15, and that property deals {@damage 4d6} damage on a failed save, or half as much damage on a successful one.",
"The saving throw DC for the war pick's Frightful Presence property increases to 15.",
"While carrying the weapon, you have resistance to acid, cold, fire, lightning, and poison damage."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"resist": [
"acid",
"cold",
"fire",
"lightning",
"poison"
],
"bonusWeapon": "+2"
},
{
"name": "Will of the Talon (Dormant)",
"source": "EGW",
"page": 279,
"baseItem": "war pick|PHB",
"type": "M",
"rarity": "artifact",
"reqAttune": true,
"sentient": true,
"weight": 2,
"weaponCategory": "martial",
"dmg1": "1d8",
"dmgType": "P",
"bonusWeapon": "+1",
"weapon": true,
"entries": [
"Black, blue, green, red, and white gems carved in the form of dragon heads cover this ostentatious gold war pick, marking it as a weapon channeling the power of Tiamat.",
{
"type": "entries",
"name": "Sentience",
"entries": [
"Will of the Talon is a sentient lawful evil weapon with an Intelligence of 14, a Wisdom of 15, and a Charisma of 19. It has hearing and {@sense darkvision} out to a range of 120 feet.",
"The weapon communicates telepathically with its wielder and can speak, read, and understand Common, Draconic, and Infernal."
]
},
{
"type": "entries",
"name": "Personality",
"entries": [
"A short-tempered {@creature bone devil} named Ashtyrlon lives within Will of the Talon. The weapon is greedy and values strong leadership. It demands that its wielder take decisive action to keep order in high-pressure situations—and to always take a fair share of treasure in return."
]
},
{
"type": "entries",
"name": "Dormant",
"entries": [
"The war pick grants the following benefits in its dormant state:",
{
"type": "list",
"items": [
"You can speak, read, and write Draconic and Infernal.",
"You gain a +1 bonus to attack and damage rolls made with this magic weapon.",
"As a bonus action while holding the war pick, you can cause the following effect: each creature of your choice that is within 30 feet of you and is aware of you must succeed on a DC 13 Wisdom saving throw or become {@condition frightened} of you for 1 minute. A creature can repeat the saving throw at the end of each of its turns, ending the effect on itself on a success. If a creature's saving throw is successful or the effect ends for it, the creature is immune to your Frightful Presence for the next 24 hours. This property can't be used again until the next dawn.",
"While holding the war pick, you can use your action to exhale destructive energy. Pick a damage type from the Will of the Talon Breath Weapons table. Each creature in the area of the exhalation must make a DC 13 saving throw, the type of which is specified in the table. A creature takes {@damage 3d6} damage on a failed save, or half as much damage on a successful one."
]
},
"When you use the war pick to unleash a breath weapon of a specific damage type, you can't choose that same damage type again until the next dawn."
]
},
{
"type": "table",
"caption": "Will of the Talon Breath Weapons",
"colStyles": [
"col-3",
"col-7",
"col-3 text-center"
],
"colLabels": [
"Damage Type",
"Area",
"Saving Throw"
],
"rows": [
[
"Acid",
"5 ft. wide, 30 ft. long line",
"Dexterity"
],
[
"Cold",
"15 ft. cone",
"Constitution"
],
[
"Fire",
"15 ft. cone",
"Dexterity"
],
[
"Lightning",
"5 ft. wide, 30 ft. long line",
"Dexterity"
],
[
"Poison",
"15 ft. cone",
"Constitution"
]
]
},
{
"type": "inset",
"name": "Betrayer Artifact Properties",
"entries": [
"The Arms of the Betrayers advance in power in the same manner as the Vestiges of Divergence. In its dormant state, each of these artifacts has one {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} property and one {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental} property. When the artifact attains an awakened state, it gains an additional {@table Artifact Properties; Minor Beneficial Properties|dmg|minor beneficial} property and an additional {@table Artifact Properties; Minor Detrimental Properties|dmg|minor detrimental} property. When the item reaches its exalted state, it gains a {@table Artifact Properties; Major Beneficial Properties|dmg|major beneficial} property. See \"Artifact Properties\" in chapter 7 of the Dungeon Master's Guide for more information."
]
}
]
},
{
"name": "Will of the Talon (Exalted)",
"source": "EGW",
"_copy": {
"name": "Will of the Talon (Awakened)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Exalted",
"entries": [
"When the war pick reaches an exalted state, it gains the following properties:",
{
"type": "list",
"items": [
"The weapon's bonus to attack and damage rolls increases to +3.",
"The saving throw DC for the war pick's Breath Weapon property increases to 17, and that property deals {@damage 5d6} damage on a failed save, or half as much damage on a successful one.",
"The saving throw DC for the war pick's Frightful Presence property increases to 17."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"bonusWeapon": "+3"
},
{
"name": "Willowshade Oil",
"source": "EGW",
"page": 70,
"type": "G",
"rarity": "none",
"value": 3000,
"entries": [
"A dark blue oil can be extracted from the rare fruit of the willowshade plant. A creature can use its action to apply the oil to another creature that has been {@condition petrified} for less than 1 minute, causing the {@condition petrified} condition on that creature to end at the start of what would be that creature's next turn."
]
},
{
"name": "Wind Fan",
"source": "DMG",
"page": 213,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"wondrous": true,
"entries": [
"While holding this fan, you can use an action to cast the {@spell gust of wind} spell (save DC 13) from it. Once used, the fan shouldn't be used again until the next dawn. Each time it is used again before then, it has a cumulative {@chance 20} chance of not working and tearing into useless, nonmagical tatters."
],
"attachedSpells": [
"gust of wind"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Windvane",
"source": "PotA",
"page": 224,
"baseItem": "spear|phb",
"type": "M",
"resist": [
"lightning"
],
"rarity": "legendary",
"reqAttune": true,
"weight": 3,
"weaponCategory": "simple",
"property": [
"T",
"V",
"F"
],
"range": "20/60",
"dmg1": "1d6",
"dmgType": "P",
"dmg2": "1d8",
"grantsProficiency": true,
"bonusWeapon": "+2",
"entries": [
"A silver spear, Windvane has dark sapphires on the filigreed surface of its polished head. Held by its shining haft, the weapon feels insubstantial, as if clutching a cool, gently flowing breeze. The spear contains a spark of {@creature Yan-C-Bin|PotA}, the Prince of Evil Air.",
"You have a +2 bonus to attack and damage rolls made with this magic weapon, which has the finesse weapon property. When you hit with it, the target takes an extra {@damage 1d6} lightning damage.",
{
"name": "Air Mastery",
"type": "entries",
"entries": [
"You gain the following benefits while you hold Windvane:"
]
},
{
"type": "list",
"items": [
"You can speak Auran fluently.",
"You have resistance to lightning damage.",
"You can cast {@spell dominate monster} (save DC 17) on an {@creature air elemental}. Once you have done so, Windvane can't be used this way again until the next dawn."
]
},
{
"name": "Song of the Four Winds",
"type": "entries",
"entries": [
"While inside an air node, you can perform a ritual called the Song of the Four Winds, using Windvane to create a {@item devastation orb of air|PotA}. Once you perform the ritual, Windvane can't be used to perform the ritual again until the next dawn."
]
},
{
"name": "Flaw",
"type": "entries",
"entries": [
"Windvane makes its wielder mercurial and unreliable. While attuned to the weapon, you gain the following flaw: \"I break my vows and plans. Duty and honor mean nothing to me.\""
]
}
],
"attachedSpells": [
"dominate monster"
],
"hasFluffImages": true
},
{
"name": "Winged Boots",
"source": "DMG",
"page": 214,
"srd": true,
"tier": "major",
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"modifySpeed": {
"equal": {
"fly": "walk"
}
},
"entries": [
"While you wear these boots, you have a flying speed equal to your walking speed. You can use the boots to fly for up to 4 hours, all at once or in several shorter flights, each one using a minimum of 1 minute from the duration. If you are flying when the duration expires, you descend at a rate of 30 feet per round until you land.",
"The boots regain 2 hours of flying capability for every 12 hours they aren't in use."
],
"optionalfeatures": [
"replicate magic item|tce",
"replicate magic item|uaartificerrevisited"
],
"lootTables": [
"Magic Item Table F"
],
"hasFluffImages": true
},
{
"name": "Wings of Flying",
"source": "DMG",
"page": 214,
"srd": true,
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"modifySpeed": {
"static": {
"fly": 60
}
},
"entries": [
"While wearing this cloak, you can use an action to speak its command word. This turns the cloak into a pair of bat wings or bird wings on your back for 1 hour or until you repeat the command word as an action. The wings give you a flying speed of 60 feet. When they disappear, you can't use them again for {@dice 1d12} hours."
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Wingwear",
"source": "PotA",
"page": 223,
"rarity": "uncommon",
"reqAttune": true,
"wondrous": true,
"modifySpeed": {
"static": {
"fly": 30
}
},
"charges": 3,
"entries": [
"This snug uniform has symbols of air stitched into it and leathery flaps that stretch along the arms, waist, and legs to create wings for gliding. A suit of wingwear has 3 charges. While you wear the suit, you can use a bonus action and expend 1 charge to gain a flying speed of 30 feet until you land. At the end of each of your turns, your altitude drops by 5 feet. Your altitude drops instantly to 0 feet at the end of your turn if you didn't fly at least 30 feet horizontally on that turn. When your altitude drops to 0 feet, you land (or fall), and you must expend another charge to use the suit again.",
"The suit regains all of its expended charges after spending at least 1 hour in an elemental air node."
]
},
{
"name": "Winter's Dark Bite",
"source": "HftT",
"page": 4,
"baseItem": "greatsword|phb",
"type": "M",
"rarity": "uncommon",
"weight": 6,
"weaponCategory": "martial",
"property": [
"H",
"2H"
],
"dmg1": "2d6",
"dmgType": "S",
"bonusWeapon": "+1",
"entries": [
"You have a +1 bonus to attack and damage rolls made with this magic weapon.",
"This greatsword is made of an unknown black metal. In most cases, it works as a {@item +1 greatsword}. But when used against a {@creature thessalhydra|hftt}, it works as a {@item +3 greatsword}. While in the Upside Down, it works as a +4 greatsword."
]
},
{
"name": "Witchlight Vane",
"source": "WBtW",
"page": 27,
"rarity": "legendary",
"reqAttune": "by a mister light or his handpicked successor",
"sentient": true,
"weight": 3,
"bonusWeapon": "+3",
"entries": [
"This ornate rod is topped by a pair of butterfly wings and incorporates bits of red glass into its length. It weighs 3 pounds.",
{
"type": "entries",
"name": "Magic Weapon",
"entries": [
"In the hands of one who is attuned to it, the vane can be wielded as a magic mace that grants a +3 bonus to attack and damage rolls made with it. In addition, the vane deals an extra {@damage 1d8} radiant damage on a hit."
]
},
{
"type": "entries",
"name": "Sense Mood",
"entries": [
"While the vane is inside the perimeter of the Witchlight Carnival, it can sense the mood of every creature in the carnival that has an Intelligence of 4 or higher. As an action, a creature attuned to the vane can use it to pinpoint the location of the happiest creature in the carnival.",
"The butterfly shape at the top of the vane spins slowly clockwise when spirits in the carnival are generally high; if the general mood in the carnival is dour, the top of the vane spins slowly counterclockwise."
]
},
{
"type": "entries",
"name": "Sentience",
"entries": [
"The vane is a sentient, chaotic good wondrous item with an Intelligence of 11, a Wisdom of 14, and a Charisma of 14. It has hearing and normal vision out to a range of 30 feet, and it communicates by transmitting emotion to the creature attuned to it. Its purpose is to make sure everyone in the Witchlight Carnival is having a good time."
]
},
{
"type": "entries",
"name": "Additional Properties",
"entries": [
"The vane has the following additional properties:",
{
"type": "list",
"items": [
"The creature holding the vane has vulnerability to lightning damage.",
"The creature attuned to the vane can't be {@condition blinded}, {@condition deafened}, {@condition petrified}, or {@condition stunned}.",
"While carrying the vane, the creature attuned to it can cast the {@spell dancing lights}, {@spell polymorph}, or {@spell ray of frost} spell as an action, requiring no spell components and using Charisma as the spellcasting ability. After the vane's {@spell polymorph} spell is cast, roll a {@dice d8}; on a roll of 3 or 8, the vane can't be used to cast this spell again until the next dawn."
]
}
]
}
],
"attachedSpells": [
"dancing lights"
],
"hasFluffImages": true
},
{
"name": "Witchlight Watch",
"source": "WBtW",
"page": 25,
"rarity": "legendary",
"reqAttune": "by a mister witch or his handpicked successor",
"wondrous": true,
"entries": [
"This ornate pocket watch is fastened to the end of a gold chain. It glows with a faint golden light when opened, and it makes a soft ticking noise that can be heard only by the creature holding it. The face of the watch shows a miniature painting of the Witchlight Carnival ringed by a tiny henge, orbited at night by a mote of light small enough to slip through the eye of a needle. This light causes the henge to cast shadows, and these shadows allow the watch's owner to track the passage of time.",
{
"type": "entries",
"name": "Carnival Setup and Takedown",
"entries": [
"The creature attuned to the watch can use an action to initiate the packing up or the unpacking of the Witchlight Carnival, provided the creature and the carnival are on the same plane of existence. In the span of 1 hour, all objects that are elements of the carnival are magically whisked about until everything is packed up and ready for travel, or unpacked and assembled. The watch has no effect on creatures, which can move about freely and safely while the carnival is being set up or taken down. Once the process of packing up or unpacking the carnival begins, it can't be stopped until the task is complete. When the watch is used to pack up or unpack the carnival, this property cannot be used again until 8 hours have elapsed."
]
},
{
"type": "entries",
"name": "Additional Properties",
"entries": [
"The pocket watch has the following additional properties:",
{
"type": "list",
"items": [
"While carrying the watch, the creature attuned to it can cast the {@spell fire bolt}, {@spell invisibility}, or {@spell message} spell as an action, requiring no spell components and using Intelligence as the spellcasting ability. After the watch's {@spell invisibility} spell is cast, roll a {@dice d8}; on a roll of 3 or 8, the watch can't be used to cast this spell again until the next dawn.",
"The creature attuned to the watch gains 30 pounds. This extra weight vanishes when the attunement ends.",
"The creature attuned to the watch must eat and drink eight times the normal amount each day."
]
}
]
}
],
"attachedSpells": [
"fire bolt"
]
},
{
"name": "Witherbloom Primer",
"source": "SCC",
"page": 39,
"rarity": "uncommon",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": "{@dice 1d3}",
"charges": 3,
"entries": [
"The Witherbloom Primer is a magic textbook created at Strixhaven's Witherbloom College. The primer has 3 charges, and it regains {@dice 1d3} expended charges daily at dawn. If you make an Intelligence ({@skill Nature}) or Wisdom ({@skill Survival}) check while holding the primer, you can expend 1 charge to give yourself a {@dice 1d4} bonus to the check, immediately after you roll the {@dice d20}.",
"In addition, if you study the primer at the end of a long rest, you can choose one 1st-level spell from the druid or wizard spell list. Before you finish your next long rest, you can cast the chosen spell once without a spell slot if you are holding the primer. Your spellcasting ability for this spell is your choice of Intelligence, Wisdom, or Charisma."
]
},
{
"name": "Woodcarver's Tools",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"additionalSources": [
{
"source": "XGE",
"page": 85
}
],
"type": "AT",
"rarity": "none",
"weight": 5,
"value": 100,
"additionalEntries": [
"Woodcarver's tools allow you to craft intricate objects from wood, such as wooden tokens or arrows.",
{
"type": "entries",
"name": "Components",
"entries": [
"Woodcarver's tools consist of a knife, a gouge, and a small saw."
]
},
{
"type": "entries",
"name": "Arcana, History",
"entries": [
"Your expertise lends you additional insight when you examine wooden objects, such as figurines or arrows."
]
},
{
"type": "entries",
"name": "Nature",
"entries": [
"Your knowledge of wooden objects gives you some added insight when you examine trees."
]
},
{
"type": "entries",
"name": "Repair",
"entries": [
"As part of a short rest, you can repair a single damaged wooden object."
]
},
{
"type": "entries",
"name": "Craft Arrows",
"entries": [
"As part of a short rest, you can craft up to five arrows. As part of a long rest, you can craft up to twenty. You must have enough wood on hand to produce them."
]
},
{
"type": "table",
"caption": "Woodcarver's Tools",
"colLabels": [
"Activity",
"DC"
],
"colStyles": [
"col-10",
"col-2 text-center"
],
"rows": [
[
"Craft a small wooden figurine",
"10"
],
[
"Carve an intricate pattern in wood",
"15"
]
]
}
]
},
{
"name": "Woodcutter's Axe",
"source": "WBtW",
"page": 214,
"baseItem": "greataxe|PHB",
"type": "M",
"rarity": "rare",
"weight": 7,
"weaponCategory": "martial",
"property": [
"H",
"2H"
],
"dmg1": "1d12",
"dmgType": "S",
"bonusWeapon": "+1",
"axe": true,
"weapon": true,
"entries": [
"You have a +1 bonus to attack and damage rolls made with this magic weapon.",
"When you use this axe to make an attack against a plant (an ordinary plant or a creature with the Plant type) or a wooden object that isn't being worn or carried, the attack deals an extra {@damage 2d6} slashing damage on a hit."
]
},
{
"name": "Wreath of the Prism (Awakened)",
"source": "EGW",
"_copy": {
"name": "Wreath of the Prism (Dormant)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Awakened",
"entries": [
"Once the Wreath of the Prism reaches an awakened state, it gains the following benefits:",
{
"type": "list",
"items": [
"You can affect creatures of challenge rating 10 or lower with the wreath.",
"The save DC of the wreath's spell increases to 15."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"hasFluffImages": true
},
{
"name": "Wreath of the Prism (Dormant)",
"source": "EGW",
"page": 274,
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"entries": [
"This loop of golden thorns is inset with dozens of gems representing the five colors of Tiamat.",
{
"type": "entries",
"name": "Dormant",
"entries": [
"While wearing the wreath in its dormant state, you have {@sense darkvision} out to a range of 60 feet. If you already have {@sense darkvision}, wearing the wreath increases the range of your {@sense darkvision} by 60 feet.",
"When you hit a beast, dragon, or monstrosity of challenge rating 5 or lower with an attack, or when you grapple it, you can use the wreath to cast {@spell dominate monster} on the creature (save DC 13). On a successful save, the target is immune to the power of the wreath for 24 hours. On a failure, a shimmering, golden image of the wreath appears as a collar around the target's neck or as a crown on its head (your choice) until it is no longer {@condition charmed} by the spell. If you use the wreath to charm a second creature, the first spell immediately ends. When the spell ends, the target knows it was {@condition charmed} by you."
]
}
],
"attachedSpells": [
"dominate monster"
],
"hasFluffImages": true
},
{
"name": "Wreath of the Prism (Exalted)",
"source": "EGW",
"_copy": {
"name": "Wreath of the Prism (Awakened)",
"source": "EGW",
"_mod": {
"entries": {
"mode": "insertArr",
"index": -1,
"items": {
"type": "entries",
"name": "Exalted",
"entries": [
"Once the Wreath of the Prism reaches an exalted state, it gains the following benefits:",
{
"type": "list",
"items": [
"You can affect creatures of challenge rating 15 or lower with the wreath.",
"The save DC of the wreath's spell increases to 17."
]
}
]
}
}
},
"_preserve": {
"page": true,
"hasFluffImages": true
}
},
"hasFluffImages": true
},
{
"name": "Wukka Nut",
"source": "ToA",
"page": 205,
"type": "G",
"rarity": "unknown",
"value": 100,
"entries": [
"These fist-sized nuts grow on wukka trees, which are popular haunts for {@creature jaculi|ToA}, {@creature su-monster|ToA|su-monsters}, and {@creature zorbo|ToA|zorbos}. A wukka nut rattles when shaken, causing its shell to shed bright light in a 10-foot radius and dim light for an additional 10 feet. This magical light fades after 1 minute, but shaking the nut again causes the light to reappear. If the shell of the nut is cracked open, it loses its magic."
]
},
{
"name": "Wyllow's Staff of Flowers",
"source": "WDMM",
"page": 75,
"_copy": {
"name": "Staff of Flowers",
"source": "XGE",
"_mod": {
"entries": {
"mode": "appendArr",
"items": "Wyllow's staff is peculiar in that it can't create roses, which the archdruid dislikes. If a rose is chosen, a daisy grows instead."
}
}
},
"reqAttune": true
},
{
"name": "Wyrmskull Throne",
"source": "SKT",
"page": 237,
"rarity": "artifact",
"wondrous": true,
"recharge": "dawn",
"rechargeAmount": 9,
"charges": 9,
"entries": [
"Built by dwarven gods and entrusted to the rulers of Shanatar, an ancient dwarven empire. The Wyrmskull Throne was a symbol of dwarven power and pride for ages untold. The throne hovers a foot off the ground and is a massive thing made of polished obsidian with oversized feet—the impaled skulls of four ancient blue dragons. Runes glisten in the carved obsidian winking to life with blue energy when the throne's powers are activated.",
"After the fall of Shanatar, the Wyrmskull Throne fell into the clutches of less honorable creatures. A band of adventurers wrested the throne from the aquatic elf tyrant Gantar Kraok and sold it to the {@creature storm giant} Neri for a considerable fortune. Neri had the throne magically enlarged and gave it to her husband, King Hekaton, as a gift, along with one of the Ruling Scepters of Shanatar, which she had found in a wreck at the bottom of the Trackless Sea. Only a creature attuned to a Ruling Scepter and in possession of it can harness the powers of the Wyrmskull Throne, which has become the centerpiece of King Hekaton's throne room in the undersea citadel of Maelstrom. Fear of the throne's power has helped prevent evil giants from challenging or threatening Hekaton's leadership.",
"Any creature not attuned to a Ruling Scepter who sits on the throne is {@condition paralyzed} and encased in a magical force field. While encased, the creature can't be touched or moved from the throne. Touching a Ruling Scepter to the force field dispels the field, though the creature remains {@condition paralyzed} until it is separated from the throne.",
"Any creature seated on the throne can hear faint Whispers in Draconic—the whisperings of four blue dragons whose skulls adorn the throne. Although powerless, these spirits try to influence the decisions of the throne's master.",
{
"name": "Properties of the Throne",
"type": "entries",
"entries": [
"The throne has 9 charges and regains all expended charges daily at dawn. A creature that sits on the throne while attuned to a Ruling Sceptor in its possession can harness the throne's properties, which are as follows:",
"The throne gains a flying speed of 30 feet and can hover and flies where the creature wills. This property doesn't expend any charges.",
"Both the throne and the creature sitting on it can move through earth and stone without disturbing the material they move through. This property doesn't expend any charges.",
"As an action, the creature can expend 1 charge to cast {@spell lightning bolt} (spell save DC 19) from the throne. The spell is cast as though using a 9th-level spell slot and deals 49 ({@damage 14d6}) lightning damage. The bolt discharges from the mouth of one of the throne's blue dragon skulls.",
"As an action, the creature can expend 2 charges to cast the {@spell globe of invulnerability} spell from the throne. The globe encloses both the creature and the throne.",
"As an action, the creature can expend 3 charges to create a spectral image of an {@creature ancient blue dragon} that surrounds both it and the throne. The spectral dragon lasts for 1 minute. At the end of each of the creature's turns, the spectral dragon makes one bite attack and two claw attacks against targets of the creature's choice. These attacks have the same attack bonus, reach, and damage as an {@creature ancient blue dragon||ancient blue dragon's} bite and claw attacks."
]
},
{
"name": "Destroying the Throne",
"type": "entries",
"entries": [
"The Wyrmskull Throne can be destroyed by breaking at least five Ruling Scepters of Shanatar simultaneously on it. This fact has never been recorded or sung of among the dwarves or any bards or storytellers, and it can't be discovered with an ability check. Characters who want to destroy the throne must go on a quest to learn the method for doing so. The throne's destruction triggers an explosion, as shards of obsidian fly out in all directions. Each creature and object within a 30-foot-radius sphere centered on the throne must succeed on a DC 21 Dexterity saving throw, taking 70 ({@damage 20d6}) slashing damage on a failed save, or half as much damage on a successful one."
]
}
],
"attachedSpells": [
"lightning bolt",
"globe of invulnerability"
],
"hasFluffImages": true
},
{
"name": "Wyvern Poison",
"source": "DMG",
"page": 258,
"srd": true,
"type": "G",
"rarity": "none",
"value": 120000,
"poison": true,
"entries": [
"This poison must be harvested from a dead or {@condition incapacitated} {@creature wyvern}. A creature subjected to this poison must make a DC 15 Constitution saving throw, taking 24 ({@damage 7d6}) poison damage on a failed save, or half as much damage on a successful one."
],
"poisonTypes": [
"injury"
]
},
{
"name": "Yahcha",
"source": "ToA",
"page": 205,
"type": "G",
"rarity": "none",
"value": 100,
"entries": [
"A yahcha (pronounced YAH-chah) is a harmless, meaty beetle about the size of a human hand, which feeds on worms and maggots. It moves slowly (walking speed 10 feet) and is easy to catch. A creature with blue mist fever that eats a raw or cooked yahcha can immediately make a saving throw with advantage against that disease (see \"Diseases,\" page 40),"
]
},
{
"name": "Yarting",
"source": "SCAG",
"page": 124,
"type": "INS",
"rarity": "none",
"entries": [
"A southern instrument from Amn and Calimshan that is a Faerûnian analog to the guitar. Numerous variations have spread across the continent."
]
},
{
"name": "Yellow Sapphire",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 100000,
"entries": [
"A transparent fiery yellow or yellow-green gemstone."
]
},
{
"name": "Yew Wand",
"source": "PHB",
"page": 151,
"srd": true,
"basicRules": true,
"type": "SCF",
"scfType": "druid",
"rarity": "none",
"weight": 1,
"value": 1000
},
{
"name": "Ythryn Mythallar",
"source": "IDRotF",
"page": 316,
"rarity": "legendary",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"wondrous": true,
"entries": [
"A mythallar looks like an enormous crystal ball held in an ornate cradle. The globe sheds bright light in a 300-foot radius and dim light for an additional 300 feet. The globe draws magic from the Weave that can be harnessed for various purposes. For example, Netherese mages used mythallars to keep their cities aloft and empower their magic items. The bigger the mythallar, the more magic it can hold. The largest mythallars are 150 feet in diameter.",
"The Ythryn mythallar is a relatively small device—a mere 50 feet in diameter. To attune to this mythallar, a creature must finish a short rest within 30 feet of it, meditating on the mythallar. Up to eight creatures can be attuned to it at one time; otherwise, the Ythryn mythallar follows the attunement rules in the Dungeon Master's Guide. If a ninth creature tries to attune to the mythallar, nothing happens.",
"All creatures attuned to the Ythryn mythallar can sense when the device is being used. A creature attuned to the device can use any of its properties, but only if all other creatures attuned to the device agree to allow it. The Ythryn mythallar's properties are as follows:",
{
"type": "list",
"items": [
"While you're on the same plane of existence as the Ythryn mythallar, you can use an action to cause it to fly in any direction you choose at a speed of 30 feet. All matter within 500 feet of the device moves with it. The Ythryn mythallar and all structures held aloft by it hover in place when not in motion.",
"As an action, you can cause one magic item you are holding within 30 feet of the Ythryn mythallar to immediately regain all its expended charges or uses. A magic item recharged in this manner can't be recharged by the Ythryn mythallar again until after the item regains expended charges or uses on its own.",
"You can use the Ythryn mythallar to cast the {@spell control weather} spell without requiring any components and without the need for you to be outdoors. This casting of the spell has a 50-mile radius. For the duration of the spell's casting time, you must be within 30 feet of the Ythryn mythallar or the spell fails."
]
},
{
"type": "entries",
"name": "Touching the Mythallar",
"entries": [
"Any creature that touches the globe of the mythallar must make a DC 22 Constitution saving throw, taking 180 ({@damage 20d10 + 70}) radiant damage on a failed save, or half as much damage on a successful one. Undead have disadvantage on this saving throw. Any object that touches the globe, other than an artifact or the mythallar's cradle, is disintegrated instantly (no save)."
]
}
],
"attachedSpells": [
"control weather"
]
},
{
"name": "Zabou",
"source": "ToA",
"page": 205,
"type": "G",
"rarity": "none",
"value": 1000,
"entries": [
"Zabou mushrooms feed on offal and the rotting wood of dead trees. If handled carefully, a zabou can be picked or uprooted without causing it to release its spores. If crushed or struck, a zabou releases its spores in a 10-foot-radius sphere. A zabou can also be hurled up to 30 feet away or dropped like a grenade, releasing its cloud of spores on impact. Any creature in that area must succeed on a DC 10 Constitution saving throw or be {@condition poisoned} for 1 minute. The {@condition poisoned} creature's skin itches for the duration. The creature can repeat the saving throw at the end of each of its turns, ending the effect on itself on a success."
]
},
{
"name": "Zircon",
"source": "DMG",
"page": 134,
"type": "$",
"rarity": "none",
"value": 5000,
"entries": [
"A transparent pale blue-green gemstone."
]
},
{
"name": "Zulkoon",
"source": "SCAG",
"page": 124,
"type": "INS",
"rarity": "none",
"entries": [
"A complex pump organ that originated with the zulkirs of Thay, who use it in the casting of their spells. It is considered to have a dramatic, but sinister, sound."
]
}
],
"itemGroup": [
{
"name": "Absorbing Tattoo",
"source": "TCE",
"page": 119,
"rarity": "very rare",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"entries": [
"Produced by a special needle, this magic tattoo features designs that emphasize one color.",
{
"type": "entries",
"name": "Tattoo Attunement",
"entries": [
"To attune to this item, you hold the needle to your skin where you want the tattoo to appear, pressing the needle there throughout the attunement process. When the attunement is complete, the needle turns into the ink that becomes the tattoo, which appears on the skin.",
"If your attunement to the tattoo ends, the tattoo vanishes, and the needle reappears in your space."
]
},
{
"type": "entries",
"name": "Damage Resistance",
"entries": [
"While the tattoo is on your skin, you have resistance to a type of damage associated with that color, as shown on the table below. The DM chooses the color or determines it randomly.",
{
"type": "table",
"caption": "Absorbing Tattoo",
"colLabels": [
"d10",
"Color",
"Tattoo"
],
"colStyles": [
"col-2 text-center",
"col-2 text-center",
"col-8 text-center"
],
"rows": [
[
"1",
"Green",
"{@item Acid Absorbing Tattoo|TCE}"
],
[
"2",
"Blue",
"{@item Cold Absorbing Tattoo|TCE}"
],
[
"3",
"Red",
"{@item Fire Absorbing Tattoo|TCE}"
],
[
"4",
"White",
"{@item Force Absorbing Tattoo|TCE}"
],
[
"5",
"Yellow",
"{@item Lightning Absorbing Tattoo|TCE}"
],
[
"6",
"Black",
"{@item Necrotic Absorbing Tattoo|TCE}"
],
[
"7",
"Violet",
"{@item Poison Absorbing Tattoo|TCE}"
],
[
"8",
"Silver",
"{@item Psychic Absorbing Tattoo|TCE}"
],
[
"9",
"Gold",
"{@item Radiant Absorbing Tattoo|TCE}"
],
[
"10",
"Orange",
"{@item Thunder Absorbing Tattoo|TCE}"
]
]
}
]
},
{
"type": "entries",
"name": "Damage Absorption",
"entries": [
"When you take damage of the chosen type, you can use your reaction to gain immunity against that instance of the damage, and you regain a number of hit points equal to half the damage you would have taken. Once this reaction is used, it can't be used again until the next dawn."
]
}
],
"items": [
"Acid Absorbing Tattoo|TCE",
"Cold Absorbing Tattoo|TCE",
"Fire Absorbing Tattoo|TCE",
"Force Absorbing Tattoo|TCE",
"Lightning Absorbing Tattoo|TCE",
"Necrotic Absorbing Tattoo|TCE",
"Poison Absorbing Tattoo|TCE",
"Psychic Absorbing Tattoo|TCE",
"Radiant Absorbing Tattoo|TCE",
"Thunder Absorbing Tattoo|TCE"
]
},
{
"name": "Arcane Focus",
"source": "PHB",
"page": 151,
"type": "SCF",
"scfType": "arcane",
"rarity": "none",
"focus": [
"Sorcerer",
"Warlock",
"Wizard"
],
"items": [
"Crystal|phb",
"Orb|phb",
"Rod|phb",
"Staff|phb",
"Wand|phb"
]
},
{
"name": "Armor of Resistance",
"source": "DMG",
"page": 162,
"type": "GV",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"entries": [
"You have resistance to one type of damage while you wear this armor. The DM chooses the type or determines it randomly from the options below.",
{
"type": "table",
"colStyles": [
"col-2 text-center",
"col-10"
],
"colLabels": [
"d10",
"Damage Type"
],
"rows": [
[
"1",
"{@item armor of acid resistance||Acid}"
],
[
"2",
"{@item armor of cold resistance||Cold}"
],
[
"3",
"{@item armor of fire resistance||Fire}"
],
[
"4",
"{@item armor of force resistance||Force}"
],
[
"5",
"{@item armor of lightning resistance||Lightning}"
],
[
"6",
"{@item armor of necrotic resistance||Necrotic}"
],
[
"7",
"{@item armor of poison resistance||Poison}"
],
[
"8",
"{@item armor of psychic resistance||Psychic}"
],
[
"9",
"{@item armor of radiant resistance||Radiant}"
],
[
"10",
"{@item armor of thunder resistance||Thunder}"
]
]
}
],
"items": [
"Armor of Acid Resistance",
"Armor of Cold Resistance",
"Armor of Fire Resistance",
"Armor of Force Resistance",
"Armor of Lightning Resistance",
"Armor of Necrotic Resistance",
"Armor of Poison Resistance",
"Armor of Psychic Resistance",
"Armor of Radiant Resistance",
"Armor of Thunder Resistance"
],
"hasFluffImages": true
},
{
"name": "Armor of Vulnerability",
"source": "DMG",
"page": 152,
"baseItem": "plate armor|phb",
"type": "HA",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"curse": true,
"weight": 65,
"ac": 18,
"strength": "15",
"stealth": true,
"items": [
"Armor of Vulnerability (Bludgeoning)",
"Armor of Vulnerability (Piercing)",
"Armor of Vulnerability (Slashing)"
],
"lootTables": [
"Magic Item Table G"
]
},
{
"name": "Artisan's Tools",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"type": "AT",
"rarity": "none",
"items": [
"Alchemist's Supplies|PHB",
"Brewer's Supplies|PHB",
"Calligrapher's Supplies|PHB",
"Carpenter's Tools|PHB",
"Cartographer's Tools|PHB",
"Cobbler's Tools|PHB",
"Cook's Utensils|PHB",
"Glassblower's Tools|PHB",
"Jeweler's Tools|PHB",
"Leatherworker's Tools|PHB",
"Mason's Tools|PHB",
"Painter's Supplies|PHB",
"Potter's Tools|PHB",
"Smith's Tools|PHB",
"Tinker's Tools|PHB",
"Weaver's Tools|PHB",
"Woodcarver's Tools|PHB"
]
},
{
"name": "Bag of Tricks",
"source": "DMG",
"page": 154,
"tier": "major",
"rarity": "uncommon",
"wondrous": true,
"weight": 0.5,
"items": [
"Bag of Tricks, Gray",
"Bag of Tricks, Rust",
"Bag of Tricks, Tan"
],
"hasFluffImages": true
},
{
"name": "Barrier Tattoo",
"source": "UA2020SpellsAndMagicTattoos",
"page": 9,
"rarity": "varies",
"reqAttune": true,
"wondrous": true,
"tattoo": true,
"items": [
"Barrier Tattoo (Uncommon)|UA2020SpellsAndMagicTattoos",
"Barrier Tattoo (Rare)|UA2020SpellsAndMagicTattoos",
"Barrier Tattoo (Very Rare)|UA2020SpellsAndMagicTattoos"
]
},
{
"name": "Belt of Giant Strength",
"source": "DMG",
"page": 155,
"rarity": "varies",
"reqAttune": true,
"wondrous": true,
"items": [
"Belt of Cloud Giant Strength",
"Belt of Fire Giant Strength",
"Belt of Frost Giant Strength",
"Belt of Hill Giant Strength",
"Belt of Stone Giant Strength",
"Belt of Storm Giant Strength"
],
"hasFluffImages": true
},
{
"name": "Blade of Broken Mirrors",
"source": "EGW",
"page": 275,
"baseItem": "dagger|phb",
"type": "M",
"rarity": "artifact",
"reqAttune": "by a humanoid",
"reqAttuneTags": [
{
"creatureType": "humanoid"
}
],
"sentient": true,
"weight": 1,
"weaponCategory": "simple",
"property": [
"F",
"L",
"T"
],
"range": "20/60",
"dmg1": "1d4",
"dmgType": "P",
"weapon": true,
"entries": [
"A weapon of Tharizdun, this dagger is a piece of jagged stone whose blade is scribed with a maze-like pattern with no beginning or end."
],
"items": [
"Blade of Broken Mirrors (Dormant)|EGW",
"Blade of Broken Mirrors (Awakened)|EGW",
"Blade of Broken Mirrors (Exalted)|EGW"
]
},
{
"name": "Carpet of Flying",
"source": "DMG",
"page": 157,
"tier": "major",
"rarity": "very rare",
"wondrous": true,
"items": [
"Carpet of Flying, 3 ft. × 5 ft.",
"Carpet of Flying, 4 ft. × 6 ft.",
"Carpet of Flying, 5 ft. × 7 ft.",
"Carpet of Flying, 6 ft. × 9 ft."
],
"lootTables": [
"Magic Item Table H"
]
},
{
"name": "Chromatic Rose",
"source": "WBtW",
"page": 208,
"rarity": "rare",
"wondrous": true,
"entries": [
"This magic rose comes in one of five colors, as noted in the Chromatic Roses table. While a rose is held, it gains a harmless visual effect as indicated on the table.",
"While holding the rose by its stem, you gain resistance to damage of the type associated with the rose's color. If you would take more than 10 damage of this type from a single source (after applying the resistance), the rose disintegrates, and you take no damage instead.",
"As an action, you can blow the petals from the rose to produce a 20-foot cone of acid, lightning, poisonous gas, fire, or cold, as dictated by the rose's damage type. Each creature in the cone must make a DC 15 Constitution saving throw, taking {@damage 3d10} damage of the appropriate type on a failed save, or half as much damage on a successful one. Using this property destroys the rose.",
{
"type": "table",
"caption": "Chromatic Roses",
"colStyles": [
"col-2 text-center",
"col-7 text-center",
"col-3 text-center"
],
"colLabels": [
"Color",
"Visual Effect",
"Damage Type"
],
"rows": [
[
"{@item Black Chromatic Rose|WBtW|Black}",
"Drips acid",
"Acid"
],
[
"{@item Blue Chromatic Rose|WBtW|Blue}",
"Crackles with lightning",
"Lightning"
],
[
"{@item Green Chromatic Rose|WBtW|Green}",
"Issues green gas",
"Poison"
],
[
"{@item Red Chromatic Rose|WBtW|Red}",
"Wreathed in fire",
"Fire"
],
[
"{@item White Chromatic Rose|WBtW|White}",
"Covered in frost",
"Cold"
]
]
}
],
"items": [
"Black Chromatic Rose|WBtW",
"Blue Chromatic Rose|WBtW",
"Green Chromatic Rose|WBtW",
"Red Chromatic Rose|WBtW",
"White Chromatic Rose|WBtW"
]
},
{
"name": "Crystal Ball (Legendary Version)",
"source": "DMG",
"page": 159,
"tier": "major",
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"weight": 3,
"items": [
"Crystal Ball of Mind Reading",
"Crystal Ball of Telepathy",
"Crystal Ball of True Seeing"
],
"attachedSpells": [
"scrying"
],
"lootTables": [
"Magic Item Table I"
]
},
{
"name": "Danoth's Visor",
"source": "EGW",
"page": 270,
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"entries": [
"These mithral-frame goggles with clear diamond lenses were used by the evoker Danoth Oro to spot {@condition invisible} enemies and scout areas from afar."
],
"items": [
"Danoth's Visor (Dormant)|EGW",
"Danoth's Visor (Awakened)|EGW",
"Danoth's Visor (Exalted)|EGW"
],
"hasFluffImages": true
},
{
"name": "Devastation Orb",
"source": "PotA",
"page": 222,
"rarity": "very rare",
"wondrous": true,
"entries": [
"A devastation orb is an elemental bomb that can be created at the site of an elemental node by performing a ritual with an elemental weapon. The type of orb created depends on the node used. For example, an air node creates a {@item devastation orb of air|PotA}. The ritual takes 1 hour to complete and requires 2,000 gp worth of special components, which are consumed.",
"A devastation orb measures 12 inches in diameter, weighs 10 pounds, and has a solid outer shell. The orb detonates {@dice 1d100} hours after its creation, releasing the elemental energy it contains. The orb gives no outward sign of how much time remains before it will detonate. Spells such as {@spell identify} and {@spell divination} can be used to ascertain when the orb will explode. An orb has AC 10, 15 hit points, and immunity to poison and psychic damage. Reducing it to 0 hit points causes it to explode instantly.",
"A special container can be crafted to contain a devastation orb and prevent it from detonating. The container must be inscribed with symbols of the orb's opposing element. For example, a case inscribed with earth symbols can be used to contain a {@item devastation orb of air|PotA} and keep it from detonating. While in the container, the orb thrums. If it is removed from the container after the time when it was supposed to detonate, it explodes {@dice 1d6} rounds later, unless it is returned to the container.",
"Regardless of the type of orb, its effect is contained within a sphere with a 1 mile radius. The orb is the sphere's point of origin. The orb is destroyed after one use."
],
"items": [
"Devastation Orb of Air|PotA",
"Devastation Orb of Earth|PotA",
"Devastation Orb of Fire|PotA",
"Devastation Orb of Water|PotA"
]
},
{
"name": "Dragon Scale Mail",
"source": "DMG",
"page": 165,
"type": "MA",
"tier": "major",
"rarity": "very rare",
"reqAttune": true,
"weight": 45,
"ac": 14,
"stealth": true,
"items": [
"Black Dragon Scale Mail",
"Blue Dragon Scale Mail",
"Brass Dragon Scale Mail",
"Bronze Dragon Scale Mail",
"Copper Dragon Scale Mail",
"Gold Dragon Scale Mail",
"Green Dragon Scale Mail",
"Red Dragon Scale Mail",
"Silver Dragon Scale Mail",
"White Dragon Scale Mail"
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Dragon Vessel",
"source": "FTD",
"page": 27,
"rarity": "varies",
"reqAttune": true,
"wondrous": true,
"entries": [
"This vessel can be a potion bottle, drinking horn, or other container meant to hold a liquid."
],
"items": [
"Slumbering Dragon Vessel|FTD",
"Stirring Dragon Vessel|FTD",
"Wakened Dragon Vessel|FTD",
"Ascendant Dragon Vessel|FTD"
]
},
{
"name": "Dragon's Wrath Weapon",
"source": "FTD",
"page": 25,
"type": "GV",
"rarity": "varies",
"reqAttune": true,
"entries": [
"This weapon is decorated with dragon heads, claws, wings, scales, or Draconic letters. When it steeps in a dragon's hoard, it absorbs the energy of the dragon's breath weapon and deals damage of that type with its special properties."
],
"items": [
"Slumbering Dragon's Wrath Weapon|FTD",
"Stirring Dragon's Wrath Weapon|FTD",
"Wakened Dragon's Wrath Weapon|FTD",
"Ascendant Dragon's Wrath Weapon|FTD"
]
},
{
"name": "Dragon-Touched Focus",
"source": "FTD",
"page": 26,
"rarity": "varies",
"reqAttune": "by a spellcaster",
"reqAttuneTags": [
{
"spellcasting": true
}
],
"wondrous": true,
"focus": [
"Artificer",
"Bard",
"Cleric",
"Druid",
"Paladin",
"Ranger",
"Sorcerer",
"Warlock",
"Wizard"
],
"entries": [
"This wondrous item can be a scepter, an orb, an amulet, a crystal, or another finely crafted object. It typically incorporates imagery of dragons' wings, claws, teeth, or scales."
],
"items": [
"Slumbering Dragon-Touched Focus|FTD",
"Stirring Dragon-Touched Focus|FTD",
"Wakened Dragon-Touched Focus|FTD",
"Ascendant Dragon-Touched Focus|FTD"
]
},
{
"name": "Druidic Focus",
"source": "PHB",
"page": 151,
"type": "SCF",
"scfType": "druid",
"rarity": "none",
"focus": [
"Druid"
],
"items": [
"Sprig of mistletoe|phb",
"Totem|phb",
"Wooden staff|phb",
"Yew wand|phb"
]
},
{
"name": "Elemental Gem",
"source": "DMG",
"page": 167,
"tier": "minor",
"rarity": "uncommon",
"wondrous": true,
"items": [
"Elemental Gem, Blue Sapphire",
"Elemental Gem, Emerald",
"Elemental Gem, Red Corundum",
"Elemental Gem, Yellow Diamond"
],
"attachedSpells": [
"conjure elemental"
],
"lootTables": [
"Magic Item Table B"
],
"hasFluffImages": true
},
{
"name": "Figurine of Wondrous Power",
"source": "DMG",
"page": 169,
"rarity": "varies",
"wondrous": true,
"items": [
"Figurine of Wondrous Power, Bronze Griffon",
"Figurine of Wondrous Power, Ebony Fly",
"Figurine of Wondrous Power, Golden Lions",
"Figurine of Wondrous Power, Ivory Goats",
"Figurine of Wondrous Power, Marble Elephant",
"Figurine of Wondrous Power, Obsidian Steed",
"Figurine of Wondrous Power, Onyx Dog",
"Figurine of Wondrous Power, Serpentine Owl",
"Figurine of Wondrous Power, Silver Raven"
],
"hasFluffImages": true
},
{
"name": "Flensing Claws",
"source": "VGM",
"page": 81,
"type": "OTH",
"rarity": "unknown",
"dmgType": "S",
"entries": [
"{@creature mind flayer||Illithids} don't always provide their thralls with normal weapons, such as swords and axes. Sometimes they improve the natural capabilities of thralls by giving them new anatomy. Flensing claws take the form of articulated digits that extend into long metal blades. The claws are knitted into the flesh and bones of a creature's arms and can't be removed without surgical amputation.",
"Each set of flensing claws is designed for a specific creature and can't be used by anyone else. A creature equipped with flensing claws can use its action to make one melee weapon attack with the claws. The claws deal slashing damage based on the creature's size. The creature adds its proficiency bonus and Strength modifier to any attack roll made with the claws, and its Strength modifier to its damage roll when it hits a target with the claws. Tiny and Gargantuan creatures can't be fitted with flensing claws."
],
"items": [
"Flensing Claws (Small)|VGM",
"Flensing Claws (Medium)|VGM",
"Flensing Claws (Large)|VGM",
"Flensing Claws (Huge)|VGM"
]
},
{
"name": "Gaming Set",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"type": "GS",
"rarity": "none",
"items": [
"Dice Set|PHB",
"Dragonchess Set|PHB",
"Playing Card Set|PHB",
"Three-Dragon Ante Set|PHB"
]
},
{
"name": "Glamerweave",
"source": "ERLW",
"page": 277,
"rarity": "varies",
"wondrous": true,
"items": [
"Common Glamerweave|ERLW",
"Uncommon Glamerweave|ERLW"
]
},
{
"name": "Grimoire Infinitus",
"source": "EGW",
"page": 271,
"rarity": "legendary",
"reqAttune": "by a wizard",
"reqAttuneTags": [
{
"class": "wizard"
}
],
"wondrous": true,
"weight": 3,
"entries": [
"Several of these spellbooks with gilded pages and silver-plated covers were created during the Age of Arcanum, but only one has been found since the Calamity ended. The book has an infinite number of pages, is three inches thick, eight inches wide, twelve inches long, and weighs three pounds."
],
"items": [
"Grimoire Infinitus (Dormant)|EGW",
"Grimoire Infinitus (Awakened)|EGW",
"Grimoire Infinitus (Exalted)|EGW"
],
"hasFluffImages": true
},
{
"name": "Grovelthrash",
"source": "EGW",
"page": 275,
"baseItem": "warhammer|PHB",
"type": "M",
"rarity": "artifact",
"reqAttune": true,
"sentient": true,
"weight": 2,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "B",
"dmg2": "1d10",
"weapon": true,
"entries": [
"Crafted from a single piece of obsidian, this warhammer of Torog hums with magical energy and is carved with the images of faces screaming in pain."
],
"items": [
"Grovelthrash (Dormant)|EGW",
"Grovelthrash (Awakened)|EGW",
"Grovelthrash (Exalted)|EGW"
],
"hasFluffImages": true
},
{
"name": "Guild Keyrune",
"source": "GGR",
"page": 177,
"rarity": "varies",
"reqAttune": "by a member of the relevant guild",
"wondrous": true,
"entries": [
"Associated with a particular guild, a guild keyrune is a ceremonial, stylized key, about 1 foot long, made from carved stone. Not a literal key, the item is a badge of authority that gives its bearer access to privileged places in its guild's headquarters and outposts. At the DM's discretion, a character might be given a keyrune upon attaining a renown score of 25 in their guild."
],
"items": [
"Azorius Keyrune|GGR",
"Boros Keyrune|GGR",
"Dimir Keyrune|GGR",
"Golgari Keyrune|GGR",
"Gruul Keyrune|GGR",
"Izzet Keyrune|GGR",
"Orzhov Keyrune|GGR",
"Rakdos Keyrune|GGR",
"Selesnya Keyrune|GGR",
"Simic Keyrune|GGR"
]
},
{
"name": "Guild Signet",
"source": "GGR",
"page": 178,
"type": "RG",
"rarity": "uncommon",
"reqAttune": true,
"charges": 3,
"entries": [
"A guild signet is sometimes awarded to a guild member whose renown score in that guild is 5 or higher, as a reward for performing special services for the guild. Aside from its magical properties, the ring is also an indicator of the guild's recognition and favor."
],
"items": [
"Azorius Guild Signet|GGR",
"Boros Guild Signet|GGR",
"Dimir Guild Signet|GGR",
"Golgari Guild Signet|GGR",
"Gruul Guild Signet|GGR",
"Izzet Guild Signet|GGR",
"Orzhov Guild Signet|GGR",
"Rakdos Guild Signet|GGR",
"Selesnya Guild Signet|GGR",
"Simic Guild Signet|GGR"
]
},
{
"name": "Hide of the Feral Guardian",
"source": "EGW",
"page": 271,
"baseItem": "studded leather armor|PHB",
"type": "LA",
"rarity": "legendary",
"reqAttune": true,
"weight": 13,
"ac": 12,
"entries": [
"It is believed that this polished and beautifully detailed leather armor was a gift from Melora, bestowed on a long-forgotten archdruid and champion of the natural world before the terrors of the Calamity."
],
"items": [
"Hide of the Feral Guardian (Dormant)|EGW",
"Hide of the Feral Guardian (Awakened)|EGW",
"Hide of the Feral Guardian (Exalted)|EGW"
],
"attachedSpells": [
"polymorph"
]
},
{
"name": "Holy Symbol",
"source": "PHB",
"page": 151,
"type": "SCF",
"scfType": "holy",
"rarity": "none",
"focus": [
"Cleric",
"Paladin"
],
"items": [
"Amulet|phb",
"Emblem|phb",
"Reliquary|phb"
]
},
{
"name": "Horn of Valhalla",
"source": "DMG",
"page": 175,
"rarity": "varies",
"wondrous": true,
"items": [
"Horn of Valhalla, Brass",
"Horn of Valhalla, Bronze",
"Horn of Valhalla, Iron",
"Horn of Valhalla, Silver"
],
"hasFluffImages": true
},
{
"name": "Imbued Wood Focus",
"source": "ERLW",
"page": 277,
"type": "SCF",
"scfType": "arcane",
"rarity": "common",
"reqAttune": true,
"wondrous": true,
"entries": [
"An imbued wood focus is a rod, staff, or wand cut from a tree infused with extraplanar energy. If you're a spellcaster, you can use this as a spellcasting focus.",
"When you cast a damage-dealing spell using this item as your spellcasting focus, you gain a +1 bonus to one damage roll of the spell, provided the damage is of the type associated with the item's wood. The types of wood and their associated damage types are listed in the Imbued Wood Focus table.",
{
"type": "table",
"caption": "Imbued Wood Focus",
"colLabels": [
"Wood",
"Damage Type"
],
"colStyles": [
"col-6",
"col-6"
],
"rows": [
[
"{@item Imbued Wood (Fernian Ash)|ERLW|Fernian Ash}",
"Fire"
],
[
"{@item Imbued Wood (Irian Rosewood)|ERLW|Irian Rosewood}",
"Radiant"
],
[
"{@item Imbued Wood (Kythrian Manchineel)|ERLW|Kythrian Manchineel}",
"Acid or poison"
],
[
"{@item Imbued Wood (Lamannian Oak)|ERLW|Lamannian Oak}",
"Lightning or thunder"
],
[
"{@item Imbued Wood (Mabaran Ebony)|ERLW|Mabaran Ebony}",
"Necrotic"
],
[
"{@item Imbued Wood (Risian Pine)|ERLW|Risian Pine}",
"Cold"
],
[
"{@item Imbued Wood (Shavarran Birch)|ERLW|Shavarran Birch}",
"Force"
],
[
"{@item Imbued Wood (Xorian Wenge)|ERLW|Xorian Wenge}",
"Psychic"
]
]
}
],
"items": [
"Imbued Wood (Fernian Ash)|ERLW",
"Imbued Wood (Irian Rosewood)|ERLW",
"Imbued Wood (Kythrian Manchineel)|ERLW",
"Imbued Wood (Lamannian Oak)|ERLW",
"Imbued Wood (Mabaran Ebony)|ERLW",
"Imbued Wood (Risian Pine)|ERLW",
"Imbued Wood (Shavarran Birch)|ERLW",
"Imbued Wood (Xorian Wenge)|ERLW"
]
},
{
"name": "Infiltrator's Key",
"source": "EGW",
"page": 272,
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"grantsProficiency": true,
"entries": [
"This mithral skeleton key was forged using the blood of twelve master thieves executed for trying to steal magic items during the Age of Arcanum."
],
"items": [
"Infiltrator's Key (Dormant)|EGW",
"Infiltrator's Key (Awakened)|EGW",
"Infiltrator's Key (Exalted)|EGW"
],
"hasFluffImages": true
},
{
"name": "Instrument of the Bards",
"source": "DMG",
"page": 176,
"type": "INS",
"rarity": "varies",
"reqAttune": "by a bard",
"reqAttuneTags": [
{
"class": "bard"
}
],
"wondrous": true,
"entries": [
"An instrument of the bards is an exquisite example of its kind, superior to an ordinary instrument in every way. Seven types of these instruments exist, each named after a legendary bard college. A creature that attempts to play the instrument without being attuned to it must succeed on a DC 15 Wisdom saving throw or take {@damage 2d4} psychic damage.",
"You can use an action to play the instrument and cast one of its spells. Once the instrument has been used to cast a spell, it can't be used to cast that spell again until the next dawn. The spells use your spellcasting ability and spell save DC.",
"You can play the instrument while casting a spell that causes any of its targets to be {@condition charmed} on a failed saving throw, thereby imposing disadvantage on the save. This effect applies only if the spell has a somatic or a material component.",
"All instruments of the bards can be used to cast the following spells: {@spell fly}, {@spell invisibility}, {@spell levitate}, and {@spell protection from evil and good}."
],
"items": [
"Instrument of the Bards, Anstruth Harp",
"Instrument of the Bards, Canaith Mandolin",
"Instrument of the Bards, Cli Lyre",
"Instrument of the Bards, Doss Lute",
"Instrument of the Bards, Fochlucan Bandore",
"Instrument of the Bards, Mac-Fuirmidh Cittern",
"Instrument of the Bards, Ollamh Harp"
],
"attachedSpells": [
"fly",
"invisibility",
"levitate",
"protection from evil and good"
]
},
{
"name": "Ioun Stone",
"source": "DMG",
"page": 176,
"rarity": "varies",
"reqAttune": true,
"wondrous": true,
"items": [
"Ioun Stone, Absorption",
"Ioun Stone, Agility",
"Ioun Stone, Awareness",
"Ioun Stone, Fortitude",
"Ioun Stone, Greater Absorption",
"Ioun Stone, Insight",
"Ioun Stone, Intellect",
"Ioun Stone, Leadership",
"Ioun Stone, Mastery",
"Ioun Stone, Protection",
"Ioun Stone, Regeneration",
"Ioun Stone, Reserve",
"Ioun Stone, Strength",
"Ioun Stone, Sustenance"
]
},
{
"name": "Ioun Stone",
"source": "LLK",
"page": 55,
"rarity": "rare",
"reqAttune": true,
"wondrous": true,
"items": [
"Ioun Stone, Supreme Intellect|LLK",
"Ioun Stone, Historical Knowledge|LLK",
"Ioun Stone, Natural Knowledge|LLK",
"Ioun Stone, Religious Knowledge|LLK",
"Ioun Stone, Language Knowledge|LLK",
"Ioun Stone, Self-Preservation|LLK"
]
},
{
"name": "Jewel of Three Prayers",
"source": "CRCotN",
"page": 213,
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"recharge": "dawn",
"items": [
"Jewel of Three Prayers (Dormant)|CRCotN",
"Jewel of Three Prayers (Awakened)|CRCotN",
"Jewel of Three Prayers (Exalted)|CRCotN"
],
"attachedSpells": [
"invisibility"
],
"hasFluffImages": true
},
{
"name": "Lash of Shadows",
"source": "EGW",
"page": 276,
"baseItem": "whip|PHB",
"type": "M",
"rarity": "artifact",
"reqAttune": true,
"sentient": true,
"weight": 3,
"weaponCategory": "martial",
"property": [
"F",
"R"
],
"dmg1": "1d4",
"dmgType": "S",
"weapon": true,
"entries": [
"This snakeskin whip is touched by the power of Zehir and ends in five animated serpent heads."
],
"items": [
"Lash of Shadows (Dormant)|EGW",
"Lash of Shadows (Awakened)|EGW",
"Lash of Shadows (Exalted)|EGW"
],
"hasFluffImages": true
},
{
"name": "Mace of the Black Crown",
"source": "EGW",
"page": 276,
"baseItem": "mace|PHB",
"type": "M",
"resist": [
"fire",
"poison"
],
"rarity": "artifact",
"reqAttune": true,
"sentient": true,
"weight": 4,
"weaponCategory": "simple",
"dmg1": "1d6",
"dmgType": "B",
"weapon": true,
"entries": [
"This mace has a haft of black iron and a ruby head with a fiendish countenance. Carrying the boon of Asmodeus, it is fit for the most powerful servants of the Nine Hells."
],
"items": [
"Mace of the Black Crown (Dormant)|EGW",
"Mace of the Black Crown (Awakened)|EGW",
"Mace of the Black Crown (Exalted)|EGW"
],
"hasFluffImages": true
},
{
"name": "Manual of Golems",
"source": "DMG",
"page": 180,
"tier": "major",
"rarity": "very rare",
"wondrous": true,
"weight": 5,
"items": [
"Manual of Clay Golems",
"Manual of Flesh Golems",
"Manual of Iron Golems",
"Manual of Stone Golems"
],
"lootTables": [
"Magic Item Table H"
],
"hasFluffImages": true
},
{
"name": "Musical Instrument",
"source": "PHB",
"page": 154,
"srd": true,
"basicRules": true,
"type": "INS",
"rarity": "none",
"items": [
"Bagpipes|PHB",
"Drum|PHB",
"Dulcimer|PHB",
"Flute|PHB",
"Horn|PHB",
"Lute|PHB",
"Lyre|PHB",
"Pan Flute|PHB",
"Shawm|PHB",
"Viol|PHB"
]
},
{
"name": "Musical Instrument",
"source": "SCAG",
"page": 124,
"type": "INS",
"rarity": "none",
"items": [
"Birdpipes|SCAG",
"Glaur|SCAG",
"Hand Drum|SCAG",
"Longhorn|SCAG",
"Shawm|SCAG",
"Songhorn|SCAG",
"Tantan|SCAG",
"Thelarr|SCAG",
"Tocken|SCAG",
"Wargong|SCAG",
"Yarting|SCAG",
"Zulkoon|SCAG"
]
},
{
"name": "Orb of Shielding",
"source": "ERLW",
"page": 278,
"type": "SCF",
"scfType": "arcane",
"rarity": "common",
"reqAttune": true,
"wondrous": true,
"weight": 3,
"entries": [
"An orb of shielding is a polished, spherical chunk of crystal or stone aligned to one of the planes of existence. If you're a spellcaster, you can use this orb as a spellcasting focus.",
"If you're holding the orb when you take damage of the type associated with the orb's material, you can use your reaction to reduce the damage by {@dice 1d4} (to a minimum of 0). The materials and their associated damage types are listed in the Orb of Shielding table.",
{
"type": "table",
"caption": "Orb of Shielding",
"colLabels": [
"Planar Material",
"Damage Type"
],
"colStyles": [
"col-6",
"col-6"
],
"rows": [
[
"{@item Orb of Shielding (Fernian Basalt)|ERLW|Fernian basalt}",
"Fire"
],
[
"{@item Orb of Shielding (Irian Quartz)|ERLW|Irian quartz}",
"Radiant"
],
[
"{@item Orb of Shielding (Kythrian Skarn)|ERLW|Kythrian skarn}",
"Acid and poison"
],
[
"{@item Orb of Shielding (Lamannian Flint)|ERLW|Lamannian flint}",
"Lightning and thunder"
],
[
"{@item Orb of Shielding (Mabaran Obsidian)|ERLW|Mabaran obsidian}",
"Necrotic"
],
[
"{@item Orb of Shielding (Risian Shale)|ERLW|Risian shale}",
"Cold"
],
[
"{@item Orb of Shielding (Shavarran Chert)|ERLW|Shavarran chert}",
"Force"
],
[
"{@item Orb of Shielding (Xorian Marble)|ERLW|Xorian marble}",
"Psychic"
]
]
}
],
"items": [
"Orb of Shielding (Fernian Basalt)|ERLW",
"Orb of Shielding (Irian Quartz)|ERLW",
"Orb of Shielding (Kythrian Skarn)|ERLW",
"Orb of Shielding (Lamannian Flint)|ERLW",
"Orb of Shielding (Mabaran Obsidian)|ERLW",
"Orb of Shielding (Risian Shale)|ERLW",
"Orb of Shielding (Shavarran Chert)|ERLW",
"Orb of Shielding (Xorian Marble)|ERLW"
]
},
{
"name": "Potion of Giant Strength",
"source": "DMG",
"page": 187,
"type": "P",
"rarity": "varies",
"items": [
"Potion of Cloud Giant Strength",
"Potion of Fire Giant Strength",
"Potion of Frost Giant Strength",
"Potion of Hill Giant Strength",
"Potion of Stone Giant Strength",
"Potion of Storm Giant Strength"
]
},
{
"name": "Potion of Healing (*)",
"source": "DMG",
"page": 187,
"srd": true,
"type": "P",
"rarity": "varies",
"entries": [
"{@note * This item group has the same name and source as the item {@item potion of healing}}."
],
"items": [
"Potion of Healing",
"Potion of Greater Healing",
"Potion of Superior Healing",
"Potion of Supreme Healing"
]
},
{
"name": "Potion of Mind Control",
"source": "TftYP",
"page": 229,
"type": "P",
"rarity": "varies",
"items": [
"Potion of Mind Control (beast)|TftYP",
"Potion of Mind Control (humanoid)|TftYP",
"Potion of Mind Control (monster)|TftYP"
]
},
{
"name": "Potion of Resistance",
"source": "DMG",
"page": 188,
"type": "P",
"tier": "minor",
"rarity": "uncommon",
"items": [
"Potion of Acid Resistance",
"Potion of Cold Resistance",
"Potion of Fire Resistance",
"Potion of Force Resistance",
"Potion of Lightning Resistance",
"Potion of Necrotic Resistance",
"Potion of Poison Resistance",
"Potion of Psychic Resistance",
"Potion of Radiant Resistance",
"Potion of Thunder Resistance"
],
"lootTables": [
"Magic Item Table B"
]
},
{
"name": "Quaal's Feather Token",
"source": "DMG",
"page": 188,
"rarity": "rare",
"wondrous": true,
"entries": [
"This tiny object looks like a feather. Different types of feather tokens exist, each with a different single-use effect. The DM chooses the kind of token or determines it randomly.",
{
"type": "table",
"colLabels": [
"d100",
"Feather Token"
],
"colStyles": [
"col-2 text-center",
"col-10"
],
"rows": [
[
"01-20",
"{@item Quaal's Feather Token, Anchor||Anchor}"
],
[
"21-35",
"{@item Quaal's Feather Token, Bird||Bird}"
],
[
"36-50",
"{@item Quaal's Feather Token, Fan||Fan}"
],
[
"51-65",
"{@item Quaal's Feather Token, Swan Boat||Boat}"
],
[
"66-90",
"{@item Quaal's Feather Token, Tree||Tree}"
],
[
"91-100",
"{@item Quaal's Feather Token, Whip||Whip}"
]
]
}
],
"items": [
"Quaal's Feather Token, Anchor",
"Quaal's Feather Token, Bird",
"Quaal's Feather Token, Fan",
"Quaal's Feather Token, Swan Boat",
"Quaal's Feather Token, Tree",
"Quaal's Feather Token, Whip"
],
"lootTables": [
"Magic Item Table C"
],
"hasFluffImages": true
},
{
"name": "Ring of Elemental Command",
"source": "DMG",
"page": 190,
"type": "RG",
"tier": "major",
"rarity": "legendary",
"reqAttune": true,
"charges": 5,
"items": [
"Ring of Air Elemental Command",
"Ring of Earth Elemental Command",
"Ring of Fire Elemental Command",
"Ring of Water Elemental Command"
]
},
{
"name": "Ring of Resistance",
"source": "DMG",
"page": 192,
"type": "RG",
"tier": "major",
"rarity": "rare",
"reqAttune": true,
"entries": [
"You have resistance to one damage type while wearing this ring. The gem in the ring indicates the type, which the DM chooses or determines randomly. ",
{
"type": "table",
"colStyles": [
"col-2 text-center",
"col-5",
"col-5"
],
"colLabels": [
"d10",
"Damage Type",
"Gem"
],
"rows": [
[
"1",
"{@item ring of acid resistance||Acid}",
"Pearl"
],
[
"2",
"{@item ring of cold resistance||Cold}",
"Tourmaline"
],
[
"3",
"{@item ring of fire resistance||Fire}",
"Garnet"
],
[
"4",
"{@item ring of force resistance||Force}",
"Sapphire"
],
[
"5",
"{@item ring of lightning resistance||Lightning}",
"Citrine"
],
[
"6",
"{@item ring of necrotic resistance||Necrotic}",
"Jet"
],
[
"7",
"{@item ring of poison resistance||Poison}",
"Amethyst"
],
[
"8",
"{@item ring of psychic resistance||Psychic}",
"Jade"
],
[
"9",
"{@item ring of radiant resistance||Radiant}",
"Topaz"
],
[
"10",
"{@item ring of thunder resistance||Thunder}",
"Spinel"
]
]
}
],
"items": [
"Ring of Acid Resistance",
"Ring of Cold Resistance",
"Ring of Fire Resistance",
"Ring of Force Resistance",
"Ring of Lightning Resistance",
"Ring of Necrotic Resistance",
"Ring of Poison Resistance",
"Ring of Psychic Resistance",
"Ring of Radiant Resistance",
"Ring of Thunder Resistance"
],
"lootTables": [
"Magic Item Table G"
],
"hasFluffImages": true
},
{
"name": "Ruin's Wake",
"source": "EGW",
"page": 277,
"baseItem": "spear|PHB",
"type": "M",
"rarity": "artifact",
"reqAttune": true,
"sentient": true,
"weight": 3,
"weaponCategory": "simple",
"property": [
"T",
"V"
],
"range": "20/60",
"dmg1": "1d6",
"dmgType": "P",
"dmg2": "1d8",
"weapon": true,
"entries": [
"This spear is made from the ivory bone of an ancient gold dragon and carved with an Orc hymn to Gruumsh."
],
"items": [
"Ruin's Wake (Dormant)|EGW",
"Ruin's Wake (Awakened)|EGW",
"Ruin's Wake (Exalted)|EGW"
],
"hasFluffImages": true
},
{
"name": "Scaled Ornament",
"source": "FTD",
"page": 27,
"rarity": "varies",
"reqAttune": true,
"wondrous": true,
"bonusAc": "+1",
"modifySpeed": {
"equal": {
"fly": "walk"
}
},
"entries": [
"This ornament can be jewelry, a cloak, or another wearable accessory. It appears to be fashioned from a dragon's scale, tooth, or claw, or it incorporates images in those shapes."
],
"items": [
"Slumbering Scaled Ornament|FTD",
"Stirring Scaled Ornament|FTD",
"Wakened Scaled Ornament|FTD",
"Ascendant Scaled Ornament|FTD"
]
},
{
"name": "Scroll of Protection",
"source": "DMG",
"page": 199,
"type": "SC",
"tier": "minor",
"rarity": "rare",
"items": [
"Scroll of Protection from Aberrations",
"Scroll of Protection from Beasts",
"Scroll of Protection from Celestials",
"Scroll of Protection from Elementals",
"Scroll of Protection from Fey",
"Scroll of Protection from Fiends",
"Scroll of Protection from Plants",
"Scroll of Protection from Undead"
],
"lootTables": [
"Magic Item Table C"
]
},
{
"name": "Silken Spite",
"source": "EGW",
"page": 277,
"baseItem": "rapier|PHB",
"type": "M",
"rarity": "artifact",
"reqAttune": true,
"sentient": true,
"weight": 2,
"weaponCategory": "martial",
"property": [
"F"
],
"dmg1": "1d8",
"dmgType": "P",
"modifySpeed": {
"equal": {
"climb": "walk"
}
},
"sword": true,
"weapon": true,
"entries": [
"The blade of this rapier is made of spider silk. Its onyx pommel is tipped with a ruby cut in the shape of a spider, and the blade carries the blessing of Lolth."
],
"items": [
"Silken Spite (Dormant)|EGW",
"Silken Spite (Awakened)|EGW",
"Silken Spite (Exalted)|EGW"
]
},
{
"name": "Spell Gem",
"source": "OotA",
"page": 223,
"otherSources": [
{
"source": "IMR",
"page": 95
}
],
"rarity": "varies",
"reqAttune": "optional",
"wondrous": true,
"items": [
"Spell Gem (Amber)|OotA",
"Spell Gem (Bloodstone)|OotA",
"Spell Gem (Diamond)|OotA",
"Spell Gem (Jade)|OotA",
"Spell Gem (Lapis lazuli)|OotA",
"Spell Gem (Obsidian)|OotA",
"Spell Gem (Quartz)|OotA",
"Spell Gem (Ruby)|OotA",
"Spell Gem (Star ruby)|OotA",
"Spell Gem (Topaz)|OotA"
]
},
{
"name": "Spell Scroll",
"source": "DMG",
"page": 200,
"type": "SC",
"rarity": "varies",
"items": [
"Spell Scroll (Cantrip)",
"Spell Scroll (1st Level)",
"Spell Scroll (2nd Level)",
"Spell Scroll (3rd Level)",
"Spell Scroll (4th Level)",
"Spell Scroll (5th Level)",
"Spell Scroll (6th Level)",
"Spell Scroll (7th Level)",
"Spell Scroll (8th Level)",
"Spell Scroll (9th Level)"
]
},
{
"name": "Spellwrought Tattoo",
"source": "TCE",
"page": 135,
"rarity": "varies",
"wondrous": true,
"tattoo": true,
"items": [
"Spellwrought Tattoo (Cantrip)|TCE",
"Spellwrought Tattoo (1st Level)|TCE",
"Spellwrought Tattoo (2nd Level)|TCE",
"Spellwrought Tattoo (3rd Level)|TCE",
"Spellwrought Tattoo (4th Level)|TCE",
"Spellwrought Tattoo (5th Level)|TCE"
]
},
{
"name": "Spellwrought Tattoo",
"source": "UA2020SpellsAndMagicTattoos",
"page": 11,
"rarity": "varies",
"wondrous": true,
"tattoo": true,
"items": [
"Spellwrought Tattoo (Cantrip)|UA2020SpellsAndMagicTattoos",
"Spellwrought Tattoo (1st Level)|UA2020SpellsAndMagicTattoos",
"Spellwrought Tattoo (2nd Level)|UA2020SpellsAndMagicTattoos",
"Spellwrought Tattoo (3rd Level)|UA2020SpellsAndMagicTattoos",
"Spellwrought Tattoo (4th Level)|UA2020SpellsAndMagicTattoos",
"Spellwrought Tattoo (5th Level)|UA2020SpellsAndMagicTattoos"
]
},
{
"name": "Stormgirdle",
"source": "EGW",
"page": 273,
"resist": [
"lightning",
"thunder"
],
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"ability": {
"static": {
"str": 21
}
},
"entries": [
"A Stormgirdle is a wide belt made of thick leather branded with the symbol of Kord. The girdle's clasps are made from dragon ivory."
],
"items": [
"Stormgirdle (Dormant)|EGW",
"Stormgirdle (Awakened)|EGW",
"Stormgirdle (Exalted)|EGW"
]
},
{
"name": "Sword of Answering",
"source": "DMG",
"page": 206,
"baseItem": "longsword|phb",
"type": "M",
"tier": "major",
"rarity": "legendary",
"reqAttune": true,
"weight": 3,
"weaponCategory": "martial",
"property": [
"V"
],
"dmg1": "1d8",
"dmgType": "S",
"dmg2": "1d10",
"items": [
"Sword of Answering (Answerer)",
"Sword of Answering (Back Talker)",
"Sword of Answering (Concluder)",
"Sword of Answering (Last Quip)",
"Sword of Answering (Rebutter)",
"Sword of Answering (Replier)",
"Sword of Answering (Retorter)",
"Sword of Answering (Scather)",
"Sword of Answering (Squelcher)"
],
"lootTables": [
"Magic Item Table I"
]
},
{
"name": "The Bloody End",
"source": "EGW",
"page": 278,
"baseItem": "morningstar|PHB",
"type": "M",
"rarity": "artifact",
"reqAttune": true,
"sentient": true,
"weight": 4,
"weaponCategory": "martial",
"dmg1": "1d8",
"dmgType": "P",
"weapon": true,
"entries": [
"Carrying the power of Bane, this adamantine morningstar sports foot-long spikes and has a jagged blade on its pommel."
],
"items": [
"The Bloody End (Dormant)|EGW",
"The Bloody End (Awakened)|EGW",
"The Bloody End (Exalted)|EGW"
],
"attachedSpells": [
"charm person",
"dominate person",
"fear"
]
},
{
"name": "Verminshroud",
"source": "EGW",
"page": 273,
"conditionImmune": [
"disease"
],
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"entries": [
"This patchy cloak was pieced together from the pelts of rats found feasting on the dead in Blightshore and is dotted with the bloated corpses of magically preserved insects along its seams."
],
"items": [
"Verminshroud (Dormant)|EGW",
"Verminshroud (Awakened)|EGW",
"Verminshroud (Exalted)|EGW"
],
"attachedSpells": [
"polymorph"
]
},
{
"name": "Will of the Talon",
"source": "EGW",
"page": 279,
"baseItem": "war pick|PHB",
"type": "M",
"rarity": "artifact",
"reqAttune": true,
"sentient": true,
"weight": 2,
"weaponCategory": "martial",
"dmg1": "1d8",
"dmgType": "P",
"weapon": true,
"entries": [
"Black, blue, green, red, and white gems carved in the form of dragon heads cover this ostentatious gold war pick, marking it as a weapon channeling the power of Tiamat."
],
"items": [
"Will of the Talon (Dormant)|EGW",
"Will of the Talon (Awakened)|EGW",
"Will of the Talon (Exalted)|EGW"
]
},
{
"name": "Wreath of the Prism",
"source": "EGW",
"page": 274,
"rarity": "legendary",
"reqAttune": true,
"wondrous": true,
"entries": [
"This loop of golden thorns is inset with dozens of gems representing the five colors of Tiamat."
],
"items": [
"Wreath of the Prism (Dormant)|EGW",
"Wreath of the Prism (Awakened)|EGW",
"Wreath of the Prism (Exalted)|EGW"
],
"attachedSpells": [
"dominate monster"
],
"hasFluffImages": true
}
]
}

export default itemsData;
