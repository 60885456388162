const faactionsData = {
  encountered: {
    gauntlet: {
      name: "Order of the Gauntlet",
      type: "Coalition of Holy Warriors",
      alignments: "Lawful Good, but with Neutral Good and the rare Chaotic Good",
      enabled: true,
      tagLine: "Lawful paladins, clerics, or other members of faith dedicated to fighting evil without hesitation. Evil is in deeds, not thoughts though. Law and Faith is power. Teamwork!",
      src: "https://media-waterdeep.cursecdn.com/attachments/thumbnails/5/928/290/504/br-orderofthegauntlet.png",
      beliefs: ["Faith is the greatest weapon against evil—faith in one’s god, one’s friends, and one’s self.", "Battling evil is an extraordinary task that requires extraordinary strength and bravery.", "Punishing an evil act is just. Punishing an evil thought is not."],
      members: ["Daran Edermath"],
      goals: "To be armed, vigilant, and ready to smite evil, enforce justice, and enact retribution. This means identifying evil threats such as secretive power groups and inherently evil creatures, watching over them, and being ready to attack the moment they misbehave. (These are always retributive strikes, never preemptive.)",
      blurb: `
        The Order of the Gauntlet is a relatively new organization dedicated to smiting evil wherever it lurks and without hesitation. The Order understands that evil wears many guises, playing games and tricking others in order to spread. That is why its members act on their own authority, identifying threats and smashing them before they can grow. Because the seeds of evil are nourished in the shadows, the Order of the Gauntlet rides out to the most dangerous dungeons, the darkest caverns, and the foulest pits to weed out wrongdoers.
        <br/><br/>
        Originally it comprised of paladins and clerics, but soon monks joined. Some members worried opening to non-paladins and clerics would weaken their bond, but others argued against this, saying that faithful can serve the light in nearly any path. Recently they have accepted dedicated Barbarians, Fighters, Rangers, and spellscasters, though the majoirty are still paladins and clerics.
        <br/><br/>
        But the Order is keenly aware that the shadow of evil lies within everyone, waiting for a moment when it can gain a foothold on their souls. Thus its members spend long hours deep in prayer to keep their inner eye vigilant and focused on their own thoughts and emotions. In this way they purify themselves from within before taking up their swords to cleanse the world.
        <br/><br/>
        The Order of the Gauntlet believes that all sentient beings must come to the light of reason and goodness of their own volition. That is why it is not interested in controlling minds: it focuses only on deeds, setting an example to the world in hopes of inspiring and enlightening others. The Order holds that faith in one’s god, one’s friends, and one’s self are the greatest weapons in quelling the hordes of malice. With such devout conviction, the Order’s members can be depended on as a source of strength to themselves and others, a bright light against the darkness.
        <br/><br/>
        They are not preemptive bullies, though. A strict code of honor allows them to strike only when evil deeds are being committed. Thus, the Order of the Gauntlet is hypervigilant, using every resource at their disposal—both divine and mundane—to know where and when dark deeds will occur.`
        ,
      recentEvents: "<h4>Session 3</h4> Sass meets Daran Edermath and notices a Gauntlet symbol in his home."
    },

    alliance: {
      name: "Lord's Aliance",
      type: "Cooperative Allinace of Rulers",
      alignments: "Lawful Good and Lawful Neutral, with some Neutral Good and the rare Chaotic Good",
      enabled: true,
      tagLine: "A confederation of governments trying to keep general peace. Not selflessly allied, but lead by the powerful to keep status-quo but with peace. Stability will Bring Peace, and Peace is Power!",
      beliefs: [
        "If civilization is to survive, all must unite against the dark forces that threaten it.",
        "Glory comes from protecting one’s home and honoring its leaders.",
        "The best defense is a strong offense."
      ],
      members: ["Sildar Hallwinter", "Iarno \"Glasstaff\" Albrek (Former)"],
      goals: "To ensure the safety and prosperity of the cities and other settlements of Faerûn by forming a strong coalition against the forces that threaten all, eliminate such threats by any means necessary whenever and wherever they arise, and be champions of the people. Bring glory to one's homeland and leader.",
      src: "https://media-waterdeep.cursecdn.com/attachments/thumbnails/5/926/290/504/br-lordsalliance.png",
      blurb: `
        The Lords’ Alliance is an association of rulers from cities and towns across Faerûn (primarily in the North), who believe that solidarity is needed to keep evil at bay. The rulers of Waterdeep, Silverymoon, Neverwinter, and other free cities dominate the coalition, and all lords in the Alliance work primarily for the fate and fortune of their individual settlements.
        <br/><br/>
        Alliance agents include sophisticated bards, zealous paladins, talented mages, and grizzled warriors. They are chosen primarily for their loyalty and are experts in observation, stealth, innuendo, and combat. Backed by the wealthy and the privileged, they carry fine equipment (often disguised to appear common), and spellcasters tend to have a large number of scrolls with communication spells.
        <br/><br/>
        Agents of the Lords’ Alliance ensure the safety and prosperity of civilized Faerûn by standing united against the forces that threaten civilization. They proactively eliminate such threats by any means, fighting with pride for the glory and security of their people, and for the lords who rule over them. By  providing order, stability, and peace, these agents believe that the common people can flourish.
        <br/><br/>
        The leaders of the Alliance know that the order will survive only if its members support each other, requiring a balance between pride and diplomacy. Rogue agents within the Lords’ Alliance are rare, but defections have been known to occur.
        `,
      recentEvents: `
        <h4>Session 3</h4> Sildar Hallwinter reveals to Sparka and Cecil that he is an agent of the Lord's Alliance, sent to bring stability to Phandalin and get the mine open, stability will bring peace after all. He also needs to discover what happened to the previous agent, Iarno Albrek, who dissappeared while doing this task.
        <h4>Session 6</h4> Iarno Albrek is revealed to be Glasstaff. He went turncoat, after making a "police force" out of the Redbrands, and just used them to oppress the town. Sildar Hallwinter is distressed at this, vows to fix the failures of Iarno "Glasstaff" Albrek, but says that the heroes should search for Gundren Rockseeker.
      `
    },
    harpers: {
      name: "Harpers",
      type: "Secret Network Against Tyranny",
      alignments: "Chaotic Good with Neutral Good, and the rare Lawful Good",
      enabled: true,
      tagLine: "Clandestine Group seeking to tip balance of power towards the commoners. Knowledge is power, and prevent evil doers from getting magical items. Self-reliant!",
      src: "https://media-waterdeep.cursecdn.com/attachments/thumbnails/5/924/290/504/br-harpers.png",
      beliefs: ["One can never have too much information.", "Too much power leads to corruption.", "No one should be powerless."],
      members: ["Sister Garaele"],
      goals: `
        This clandestine network of spellcasters and spies seeks to tip the scales in favor of the innocent, the weak, and the poor within the Realms. Harper agents pride themselves on being incorruptible defenders of good, and they never hesitate to aid the oppressed. Because they prefer to work behind the scenes, they are rarely noticed as they thwart tyrants, depose rulers, and head off any growing force that is rumored to have evil intent. The Harpers has its finger on the pulse of power in the Realms and works tirelessly to even the odds for the downtrodden.
        <br/><br/>
        The organization is always on the lookout for powerful items, expressly to keep them out of the hands of evildoers. To this end its agents use various guises and identities to gain access to carefully guarded secrets such as ancestral maps, buried cities, and mages’ keeps.
      `,
      blurb: `
        The Harpers is an old organization that has risen, been shattered, and risen again several times. Its longevity and resilience are largely due to its decentralized, grassroots, secretive nature, and the near-autonomy of many of its members. The Harpers have “cells” and lone operatives throughout Faerûn, although they interact and share information with one another from time to time as needs warrant. The Harpers' ideology is noble, and its members pride themselves on their integrity and incorruptibility. Harpers do not seek power or glory, only fair and equal treatment for all.
        <br/><br/>
        Harper agents are trained to act alone and depend on their own resources. When they get into scrapes, they don’t count on their fellow Harpers to rescue them. Nevertheless, Harpers are dedicated to helping one another in times of need, and friendships between Harpers are nigh unbreakable. Masterful spies and infiltrators, they use various guises and secret identities to form relationships, cultivate their information networks, and manipulate others into doing what needs to be done. All Harpers relying on their wits and extensive information networks to gain an advantage over their enemies. They know that knowledge is power, so gathering intelligence beforehand is paramount to their success. <br/><br/> Although most Harpers prefer to operate in the shadows, there are exceptions.
        `,
      recentEvents: `
        <h4>Session 6</h4> Sister Garaele asks Corpo's help in finding forgotten knowledge to prevent tyrants from obtaining powerful magic aritifacts. Says that Harper agents can be loaned magic items of course. Says to talk or not talk to his party, but she will talk with him shortly
      `
    },

    nomads: {
      name: "Nomadic Keepers of the Planes",
      enabled: true,
      type: "???",
      alignments: "???",
      tagLine: "Group Corpo met in the Astral Plane",
      members: ["Unnamed Water Genasi Nomad", "Unnamed Halfling Nomad"],
      goals: "???",
      beliefs: ["Dedicated enough to die for their cause", "???"],
      src: "https://i.imgur.com/UwaDuIa.png",
      blurb: `
        When they noticed Corpo, a Water Genasi read him the following in a Miranda-Rights-tone "You have been in acquired in accordance with the provisions set out by The Codex and Directives for the Nomadic Keepers of the Planes by the Final Council of Grand Vaati Empire. You will have questions, I will not answer all of them.  Your cooperation is requested and expected. Unless you present difficulty, you will be given the choice to return to your body or transit to another Plane within reason."
        <br/><br/>
        The seen members were of many races and seemed to get alone fine, though Corpo had an issue with the Halfling because of Wildon Fiddlestump.
      `,
      recentEvents: "<h4>Session 4</h4> Corpo met them on their flying ship in the Astral Plane, where he noticed they also were astral projecting. They did not appear to know what or who he fully was. They were attacked by Dread Flame pirate mercenaries who overtook the ship. The Dread Flame leader asked the crew who was just picked up (Corpo) but they refused to answer, to the point where the Dread Flame leader started executing them. Corpo uses his enigmatic thunder struck power to save them."
    },
    enclave: {
      name: "Emerald Enclave",
      type: "Guardians of Natural Order",
      alignments: "Neutral, but many of all non-evil alignments",
      enabled: true,
      tagLine: "Nature loving, but also concerned with balance of civ and wild. Protect a forest from being cut down, protect a village from ravaging wolves. Balance with Nature is power!",
      beliefs: ["The natural order must be respected and preserved.", "Forces that seek to upset the natural balance must be destroyed.", "The wilderness can be harsh. Not everyone can survive in it without assistance."],
      members: [],
      goals: "To restore and preserve the natural order, keep the elemental forces of the world in check, keep civilization and the wilderness from destroying one another, and help others survive the perils of the wilderness.",
      src: "https://media-waterdeep.cursecdn.com/attachments/thumbnails/5/925/290/504/br-emeraldenclave.png",
      blurb: `
        The Emerald Enclave is a far-ranging group that opposes threats to the natural world and helps others survive in the wilderness. Branches of the organization are scattered throughout Faerûn and often operate in isolation from the others. This existence teaches the Enclave’s members a fierce self-reliance and mastery of certain fighting and survival skills.
        <br/><br/>
        A ranger of the Enclave might be hired to lead a caravan through a treacherous mountain pass or the frozen tundra of Icewind Dale. A druid might volunteer to help a village prepare for a long, brutal winter. Barbarians and druids who live as hermits might appear from nowhere to help defend a town against marauding orcs.
        <br/><br/>
        Members of the Emerald Enclave know how to survive and, more importantly, to help others do the same. They are not opposed to civilization or progress, but they strive to keep it in balance with the wild. They restore and preserve the natural order, even as they root out and destroy all that is unnatural. They keep the elemental forces of the world in check and keep civilization and the wilderness from destroying one another.

      `,
      recentEvents: `
      `
    },
    zhentarim: {
      name: "Zhentarim",
      type: "Secret Society Growing Power",
      alignments: "All Evil alignments and Neutral alignments, with a rare good.",
      enabled: true,
      tagLine: "The Illuminati/Mafia. Wealth is Power. Ambition is good, results are all that matter.",
      members: ['Halia Thornton'],
      goals: "Amass wealth, power, and influence. Look for opportunities to seize power. Gain influence over important people and organizations. Leverage good groups to prevent others from conquering the world. Dominate Faerûn. Become untouchable. Everyone should fear to cross them.",
      beliefs: [
        "The Zhentarim is your family. You watch out for it, and it watches out for you.",
        "You are the master of your own destiny. Never be less than what you deserve to be.",
        "Everything—and everyone—has a price."
      ],
      src: "https://media-waterdeep.cursecdn.com/attachments/thumbnails/5/927/290/504/br-zhentarim.png",
      blurb: `
        The Zhentarim seeks to become omnipresent and inescapable, more wealthy and powerful, and most importantly, untouchable. The public face of the organization appears much more benign, offering the best mercenaries money can buy. When a merchant needs an escort for his caravan, when a noble needs bodyguards to protect her holdings, or when a city needs trained soldiers to defend its honor, the Zhentarim provides the best-trained fighting men and women money can buy. However, the cost of doing business with the Black Network can be high.
        <br/><br/>
        There is a hierarchy, but success is what matters. Those who succeed are followed and promoted, allowing the Black Network to radically change in just a few years.
        <br/><br/>
        The Black Network wants to make it necessary—and preferable, even—to deal with its members. It wants to secure, over time, an iron-fisted monopoly. Members must be the best—the cheapest, the fastest, and the most secure—at providing services and goods both legal and illicit, willing to lose coin if it means destroying a competitor, and securing profits from everyone they deal with, except when to do so would work against the Black Network's ultimate goal: to make everyone dependent on it.
        <br/><br/>
        A member of the Zhentarim thinks of himself or herself as a member of a very large family and relies on the Black Network for resources and security. However, members are granted enough autonomy to pursue their own interests and gain some measure of personal power or influence.
        <br/><br/>
        The Black Network does not just do evil for the sake of evil, it does whatever for power. For example, it avoids slavery in the Sword Coast not just due to morals, but it is considered immoral and would turn people against them and thus hurt their cause. Behind closed doors however, morals are more flexible.
        <br/><br/>
        The Black Network cooperates with good factions in opposition to various other evil organizations, as the Zhentarim cannot rule the world if other groups conquer it first.
      `,
      recentEvents: ""
    },
     blackSpider: {
      name: "Zealous of the Black Spider",
      enabled: true,
      type: "Elite Agents of Drow",
      alignments: "Evil",
      tagLine: "Clandestine Elite Drow agents tasked with sowing chaos above ground",
      members: [],
      goals: "Harm, Destruction, Chaos to the Surface World",
      beliefs: ["Praise to Lloth, the Spider Queen", "The Drow are the superior race and culture.", "The surface will suffer."],
      src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpcMC0LAOq9cKWedEFBMHolIcbdNnrnjfneg&usqp=CAU",
      blurb: "A radical group of Drow, trained by powerful matrons to orchestrate chaos. They are masters of espionage, subterfuge, assassination, and manipulation.",
      recentEvents: "They were behind the War of the Silver Marches"
    },

  },

  notMet: {

    gilded: {
      name: "Order of the Gilded Eye",
      enabled: true,
      type: "Anti-Evil Zealots and Inquisitors",
      alignments: "",
      tagLine: "Paladins, clerics, or other members of faith who know one cannot wait evil to act. Extra-planar evils must be eradicated before they can do harm. No Heisitation! No Mercy!",
      members: [],
      goals: "Eradicate Extra-planar evil at all costs. By waiting for evil acts to strike, the Order of the Gauntlet shares guilt in the evil acts. Destroying, killing, purging evildoers is but a first step.",
      beliefs: [
        "Faith is the greatest weapon against evil—faith in one’s god, one’s friends, and one’s self.",
        "Battling evil is an extraordinary task that requires victory by any means necessary.",
        "If one plots to do evil, it must die."
      ],

      src: "https://static.wikia.nocookie.net/forgottenrealms/images/1/1b/GildedEyeBanner.jpg",
      blurb: `
        The Order of the Gilded Eye is an over-zealous military organization dedicated to the eradication of extra-planar evil throughout the Realms. They were relentless in their efforts, actively seeking out those they perceived as malevolent and harmful and launching preemptive assaults without provocation. The Order of the Gilded Eye was formed by Javen Tamikos after he was infuriated by the Order of the Gauntlet's weakness. After the siege on Helm's Hold to retake it, Javen created the Order of the Gilded Eye, which refused to follow the Order of the Gauntlet's ethos of avoiding pre-emptive strikes, instead actively searching for hidden evil and destroying it before it threatened anybody. The Order of the Gilded Eye came to control Helm's Hold due to the shared frustration of many other clerics and paladins within the city, which led to mass recruitment and numbers, leading the Order to become the most influential and powerful organization in the city.
      `,
      recentEvents: ""
    },

    moonstars: {
      name: "Moonstars",
      enabled: true,
      type: "Secret Society Aimed to Unify Good Against Evil",
      alignments: "Neutral Good with Lawful Good  and Chaotic Good",
      tagLine: "Offshoot of the Harpers, seeking to unite all good people, across all realms, backgrounds, and power levels to face evil. Practical and Dedicated!",
      members: [],
      goals: "Unify good people against Evil, be it Bandits, Demons, Tyrants, or Monsters",
      beliefs: ["Evil Triumphs unless Good Unites.", "One can never have too much information.", "There is no one Evil."],
      src: "https://static.wikia.nocookie.net/forgottenrealms/images/9/92/MoonstarsSymbol.jpg",
      blurb: "The Moonstars are a smaller offshoot of the Harpers based out of Waterdeep. They promote and work for cooperation against evil by a united front of good. They are more centralized then the scattered Harpers, considering themselves a group of loyal friends and comrades. They are sort of a middle ground between the Harpers and Lord's alliance, being secretive and distrustful of powerful leaders, but recognized the ncesessity of working with good leaders to save the common people.",
      recentEvents: ""
    },
  },
}

export default faactionsData
