const articleData = {
  "Toril": {
  },
  "First World": {
    orcs: {
      name: "Orcs",
      blurb: `
        While similar to Orcs across the Planes, Orcs in the First World fall into a few categories.

        <h3> Orcs of Ruin </h3>
        Worshippers of Gruumsh the Ruiner and the associated Pantheon. Also called Orcs of the Old ways or Old gods. These are the stereotypical Orcs many fear. They focus on destruction, conquest, and death. Ravaging near and far in the name of the Ruiner. War bands are lead by the strongest male who takes the largest number of women as wives/concubines (the translation is not clear.)
        <br/><br/>
        The shorter life span of Orcs, something like 3/4th of a human lifespan, and constant death from violence, results in large numbers of offspring, the paternity of which can be questionable. It is not uncommon to see Half-Orcs of various stripes in Ruiner War Bands, as they are not choosy when it comes to breeding, and anyone strong enough to survive amongst Orcs does earn their respect regardless of ancestry.
        <br/><br/>
        Leadership is decided by violence and warring. Mating with multiple women means that the sons of chieftains are numerous. As they age, many attempt to form their own bands of followers to one day challenge their father or rival brothers. Challenges for leadership are common, as well as waiting for an all out war between sons when a father dies.
        <br/><br/>
        Other gods of the Old Orc Pantheon include:
        <ul>
          <li>
            Ilneval, "the War Maker"
            <ul>
              The right hand of Gruumsh known as a strategist. Orcs who see strategy and nuances of warfare are marked as Blade of Ilneval, and serve as loyal captains and orchestrate strategic manuevers to turn a wild pack of Orcs into a pack of wolves
            </ul>
          </li>
          <li>
            Bahgtru, "the Leg Breaker"
            <ul>
              The patron of wild Orcs, those who seek cruel sport and constant savage brutality. Sometimes ride aurochs into combat as a sign of strength and superiority.
            </ul>
          </li>
          <li>
            Luthic, "the Cave Mother"
            <ul>
              Wife of Gruumsh who with her followers keep Orcs together. Her priestess with their pet Cave Bears (symbols of Luthic) raise the next generation of Orcs deep in cave lairs. They instill them with the skills, rage, and cruelty to survive amongst Ruiner Orcs. They are known to protect youths from jealous war chiefs if a particular strong Orc is born, like an Orog.
            </ul>
          </li>
          <li>
            Yurtrus, "the White Hand"
            <ul>
              The Orc deity of Death. His White Hand clerics tend to fallen, serving heads, boiling away flesh, and removing an eye to make them like Gruumsh. White Hand Orcs are feared by others for their line between death and life.
            </ul>
          </li>
          <li>
            Shargaas, "the Night Lord"
            <ul>
              Even more outcast than White Hand Orcs...followers of Shargaas cull the weak. Known to drag off weakling Orcs from children to ineffective leaders deep into their caves and ritualistically slaughter them. It is taboo to speak of them, though they lurk in the dark of all Ruiner Orc groups.
            </ul>
          </li>
        </ul>
        Spreading from Primavera onto the Interior Plains are the main groupings of Ruiner Orcs.
        The hordes formed a single horde united by the mighty Tzakrakh that spread from the far plains some 400 years ago as Chromatic Rule weakend. They last united before The Chaos, when the success of raiding in all directions divied them.
        Today there are roughly three Northern Hordes, though the levels of unity and control vary. Half-brothers fighting for control of their father's band/clan/tribe is as common as raids into neighboring territories.
        <br/><br/>
        These are the current hordes of Gruumsh:
        <ul>
          <li>
            Buramog Orc Horde
          </li>
          <li>
            Prikdarok Orc Horde
          </li>
          <li>
            Aşağılık Orc Horde
          </li>
        </ul>

        <h3> Storm Orcs </h3>
        Some Orcs, namely the Urzoth Orcs to the south converted from the Ruiner's ways to that of Kord. On the surface many things remain the same, but the level of savagry and destruction is not the same. Kord Orcs (Korcs) follow Kord's call for competition and self-improvement in ways that does not always require constant expansion and war. Their camps slowly turned into temporary towns as they retain a war-like culture, but one of co-existience with others.

        <br/><br/>

        While Kord is entirely the main deity, Storm Orcs do honor the Raven Queen for their dead and Milritha for family concerns.
        Recently the cult of Avandra has grown as young Storm Orcs travel into the world, leaving their home to take their culture's traditional chaotic bent towards opposing tyranny anywhere.

        <br/><br/>

        Unlike Ruiner Orcs with their lack of defined relationships, Storm Orcs have actual "marriages" though not as formal as most humanoid relationships. Chieftains often have multiple concubines, but children are actually raised by their own parents, though the burden is shared.

        <br/><br/>

        Without the cult of Luthic obsessed with breeding and mind washing children, female orcs have wider roles in their community. Female Storm Orcs are trained warriors, many join in on raids, but all serve as defenders especially when war bands are out.

        <br/><br/>

        Non-Orcs are welcomed if they follow the path of Kord and fit into their rowdy culture.
      `
    },
    // },
    underdark: {
      name: "The Under Dark",
      blurb: `
        Compared to the surface world...the Underdark is more spread out and sparse. The 3 dimensional, rats nest of tunnels make mapping it extremely difficult. In between controlled outposts are passages filled with mystery and danger.
        <br/>
        Umbradiance, a form of magical energy, raw wild magic exists in many places in the underdark. The Drow call it "Faerzress" and it permeates like a mixure of radiation or fog in certain areas. It glows dimly and mushrooms can absorb it for energy, helping explain the large mushroom forests able to grow down in the Underdark.
        <br/>
        Curiously however, Umbradiance impacts spell craft. Divination spells on targets/locations in concetrations of Umbradiance are unpredictable at best, most likely impossible. Teleportation into the Underdark itself is dangerous as the shifting Umbradiance is not predictable. That is not to say impossible, and many established Underdark powers have Umbradiance-protected chambers for this exact reason. However, when any spell over level 6 is cast in the Underdark, it creates a wild magic surge. In light concetrations of Umbradiance, it drops to level 5, it mild concetrations level 3, and in desne areas level 3. In the most dense of all areas (most likely artificially dense) even cantrips can create a surge
        <br/>
        <br/>
        <h3>Transnational Alliances</h3>
        <h4>Free Alliance</h4>
        <ul>
          <li>Centered under the Empire near Thalas, essentially non-evil groups here were the first supporters of the Progeny and the proto-empire as they hid in the underdark</li>
          <li>Now the Empire returns the favor with support from the surface. </li>
          <li>They are not part of the empire, but are extremely intertwined.</li>
          <li>The Empire directly controls the Underdark around Thalas, but the others have build their homes nearby</li>
        </ul>
        <h3>Realms/Races/Regions</h3>
        <h4>Druegar</h4>
        <ul>
          <li>Mithral Hold</li>
          <ul>
            <li>Not wholly mad Druegar but still paranoid and mistrusting of outsiders</li>
            <li>Mostly keep to themselves despite pushes to help them fight the Orcs above them</li>
          </ul>
          <li>Brutewald (FA)</li>
          <ul>
            <li>Massive mining city-state kingdom under the eastern mountains</li>
            <li>Mostly friendly with the empire and confederation dwarves</li>
          </ul>
          <li> Emberdeep hold</li>
          <ul>
            <li>Verano based kingdom who mine and attempt to control Lake T’kial with channels and locks and canals. </li>
            <li>Fairly mad but cooperate with others due to fear of the ghouls </li>
          </ul>
        </ul>
        <h4>Fomorians</h4>
        <ul>
          <li>They rule with cruelty under Giant territories</li>
          <li>Under Riksar's Hall they submit slightly to the above ground but it is more a tacit alliance as long as the Fomorians stay in their lane it is fine and the Giants get some stability down there</li>
          <li>Near Lake Nsat'ial </li>
        </ul>
        <h4>Darakhul</h4>
        <ul>
          <li>The Empire of the Ghouls has their capital centered in Verano, north of Lake Nsat'ial</li>
          <li>During the Chaos....a group of Free Drow wizards created undead soldiers to fight their evil Lloth loving kin</li>
          <li>It worked...too well. The Darakhul's rebelled under the Ghoul Emperor and traveled to the north side of Lake Nsat'ial</li>
          <li>Ignored by the various Underdark realms as the dead, they slowly built their power while avoiding others</li>
          <li>Now their legions of undead are pressing against all sides as their domain is the largest in the Underdark</li>
          <li>Few things can get Underdark denizens to unite like the Darakhul Empire</li>
        </ul>
        <h4>Grey Orcs</h4>
        <ul>
          <li>Grey Camp</li>
          <ul>
            <li>A semi-nomadic group of Grey Orcs that move about to avoid Hobgoblin hunting them from above</li>
            <li>They are Gruumsh worshippers, but others see them as a check to the Hobgobs above</li>
          </ul>
          <li>Deep Ruiners</li>
          <ul>
            <li>Gruumsh's Deep Faithful who were driven underground generations back by the Sun Kingdoms and their non-evil kin.</li>
            <li>They move and raid the regions usually south of Kriazel and Emberhold, and that is where their shrines and some permanent bases are protected.</li>
            <li>Less concerned with Darakhuls for now...since in general lawful orderly things are just more for them to raid and ruin</li>
          </ul>
        </ul>
        <h4>Deep Dragons</h4>
        <ul>
          <li>Under the Pentarchy, many Deep Dragons exert their influence</li>
        </ul>
        <h4>Sapphire Dragons</h4>
        <ul>
          <li>These Militarist Abberation hunters have their lair mostly under Imperial and Platinum Republic lands...but are known the branch out and expand their hunt the world over</li>
        </ul>
        <h4>Lloth Drow</h4>
        <ul>
          <li>Vrammion'lyr</li>
          <ul>
            <li>A realm to the North of the Platinum Republic</li>
            <li>Led by Drow Spider Matriarch Myrymrith Vrammion'lyr</li>
            <li>Enemy of Loxor the Sapphire Dragon</li>
          </ul>
          <li>Silken Zhaunil</li>
          <ul>
            <li>Major Center of Lloth Drow activity in Verano. Other small enclaves to the South and North all connect here where major houses are</li>
            <li>The Houses are stereotypical CE Drow</li>
            <li>They publicly vow to crush the Kriazel heretics...but the war with the rising Draakhul have them too busy at the moment</li>
          </ul>
        </ul>
        <h4>Anti-Lloth Drow</h4>
        <ul>
          <li>Nerianna Matriarchy (FA)</li>
          <ul>
            <li>A group of Eliastree worshipping Drow who border Shalar's Sea to the south, under northern Aetherese and Southern Oxkadi</li>
            <li>They have had good ties with the Oxkadi for generations, aiding in the covert behaviors</li>
            <li>Matron Mizzrym of House Rilrae is just over 500 years old and a loyal friend to the Broken Orders. Personal friend of Nox and  Uslon</li>
            <li>Matron Chandtha of House Faeryl is suspicious of the Empire pushing into her realm or trying to change Drow culture and traditions, though she does not openly oppose them</li>
          </ul>
          <li>Kriazel Matriarchy (FA)</li>
          <ul>
            <li>Anti-Lloth Drow in Verano near the Lakes of Nsat'ial</li>
            <li>House Kriazel is the dominant House as it led the exile from Silken Zhaunil centuries ago</li>
            <li>High Matron Gilastha Kriazel is paranoid about her enclave being destroyed, though probably rightfully so.</li>
          </ul>
        </ul>
        <h4>Grimlocks</h4>
        <ul>
          <li>Tribe Bonedust</li>
          <ul>
            <li>Operates in cave networks to the east of Kriazel in Verano</li>
          </ul>
          <li>Tribe Toothpowder</li>
          <ul>
            <li>Live north of Shalar's Sea and west of Mithral Bastion</li>
            <li>Fight often with the Grey Orcs and Goblinoids under the Legions</li>
            <li>The Druegar do not tolerate their raids they learned long ago</li>
          </ul>
        </ul>
        <h4>Indepedent</h4>
        <ul>
          <li>Darkwater Haven</li>
          <ul>
            <li>Trading Hub on a spur of land far out into the Lakes of Nsat'ial....Tons of piers and ports</li>
            <li>Run by an Jerro Jian and Varotha "the Exiled Blade" Jian </li>
            <ul>
              <li>The Half Drow and Hobgoblin are a married couple of former adventurers, who striking it rich and having no where to go after their adventures, revamped the tiny trading port...keeping it in order to make ends meet and keeping tabs on the Underdark</li>
              <li>Jerro Jian: Male Half Drow Shepherd Druid who tends to the wildlife in the area and has drawn a crowd of critter who help keep it safe.</li>
              <li>Varotha: Fem Hobgob. Battle Smith Artificer who has her constructs working around town and helping as enforcers. She has a sort of haven for constructs who fled their old masters. Also runs a shop down here</li>
            </ul>
            <li>Gorpata</li>
            <ul>
              <li>Fem Bugbear Horizon Watcher Ranger</li>
              <li>Former party member with Jerro and Varotha</li>
              <li>Down in the Underdark to look out for Abberations</li>
            </ul>
            <li>'Azraq</li>
            <ul>
              <li>Male Air Genasi Gloom Stalker Ranger with a heavy crossbow</li>
              <li>Former party member with Jerro and Varotha</li>
              <li>Expert ambusher who clears out the area from dangerous monsters on most days but occasionally heads off for more missions</li>
            </ul>
            <li>Lotkar Llothbane is known to visit here and meet with Gorpata, Azraq, and various other allies3</li>
            <li>The Umbral Court</li>
            <ul>
              <li>A secretive lair of elite Drow and other assassins located in Darkwater Haven</li>
              <li>Looking for an assassin? Just information? This is the stop for you</li>
              <li>A sort of arrangement with the Jians where they don't want to run the town and don't bother the Jians</li>
              <li>Their presence and reputation helps keep Darkwater off the radar...though the Ghouls have no such ideals</li>
            </ul>
          </ul>
        </ul>
      `
    },
    imperial_regions: {
      name: "Regions of the Empire",
      blurb: `
        <ul>
          <li>
            Aetherese Riviera and Coasts
          </li>
          <li>
            Oxkadi Basin
          </li>
          <li>
            Enshullese Shores
          </li>
          <li>
            The Surteppe Plains
          </li>
          <li>
            The Inland Sea
          </li>
          <li>
            High Hinterlands
          </li>
        </ul>

      `
    },
    enshullese_shores: {
      name: "Enshullese Shores",
      blurb: `
        The Enshullese are the high culture of the Empire. As the center of the Imperial waterways (and the edge of the Peninsula frontlines) they are the center of trade and logistics. This results in them being fairly affluent from the gold due to the commerce coming through.
        <br/>
        They are formal yet warm and welcoming, duty-bound but hopeless romantics.
        <br/>
        Historically they were the first rival to the Aetherese in their ancient days, the Enshullese Confederation controlled the Enshullese Gulf. They were defeated but slowly regained status as a part of the Aetherese Domains.
        <br/>
        With the Fall of the Aetherese they briefly ruled themselves until the Draconic Tyranny began.
        <br/>
        With Thalas nearby, they were among the first coastal lands taken by the Progeny.
        <br/>
        During the wars they were behind the Kinaphalic Kngdom but often raided and invaded. During the Chaos they bore the brunt of filling the gap left by the Kinaphalic dissappearance.
        <br/>
        Today they are the cultural core of the Empire outside of the capital.
        <ul>
          <li>
            Enshullese Culture
          </li>
          <li>
            Enshullese Language
          </li>
          <li>
            Enshullese Magic
          </li>
          <li>
            Enshullese Military and Civil Service
          </li>
        </ul>
      `,
    },
    enshullese_culture: {
      name: "Enshullese Culture",
      blurb: `
        The culture of courtly love, welcoming to all yet formal when needed. The Enshullese are the foremost sailors, merchants, hosts, and cooks in the Empire.
        <br/>
        They are among most wealthiest areas of the Empire due to commerical crossroads. The Enshullese have wonderful food, fashion, literature, theater, arts, etc due to their fiscal and cultural capital.
        <br/>
        They use their wealth and influence to purchase/create the best items which they see as practical. For example they like the more expensive crossbows instead of longbows which take far more training.
        <br/>
        There is a focus on Strength and Charisma. Strength from beng at the frontlines and for hardwork, while Charisma from their natural warmth and focus on caring hospitality.
        <br/>
        They strike a balance between personal ambition and service to the many. Glory is no achieved if you have not ALSO served the whole, but then Glory is heaivly lauded.
        <br/>
        The most popular gods here are Bahamut, Pelor, Moradin, Erathis, Mildritha, and Waukeen. They tend towards Lawful Good/Neutral Good, and much of their arts focuses on the conflicts between duty and love, two different right decisions, lovers that cannot be, etc.
        <br/>
        Tension betweens:
        <ul>
          <li>
            Duty and Love
          </li>
          <li>
            Tension between Duty and Glory
          </li>
          <li>
            Tension between Erathis and Pelor
          </li>
        </ul>
      `,
    },
    enshullese_magic: {
      name: "Enshullese Magic",
      blurb: `
        Compared to the rest of the Empire, the Enshullese have higher numbers of Paladins due to their romantic idealized passions.
        <br/>
        The courtly love enthusiasts help produce Clerics of Bahamut, Pelor, Mildritha
        <br/>
        One finds many Artificers as well, as their affluence and experiences on the frontlines lead them to embrace equipment like heavy armor, heavy crossbow that helps counteract less training for more power. Their Artificers are more known for practicality than the pushing-the-limits to greatness of Aetherese Artificers
        <br/>
      `,
    },
    enshullese_military_and_civil_service: {
      name: "Enshullese Military and Civil Service",
      blurb: `
        Often it is said that the Enshullese have the mindset of a soldier. Their naval experiences, cultural outlook, and their situation near the frontlines all push for a cooperative mindset in order to also live lives of peace and freedom.
        <br/>
        A lot of Imperial Naval Forces are Enshullese, especially the larger crafts.
        <br/>
        In the Army they are more known for heavily armored soliders leading the vanguard and in heavy calvarly.
        <br/>
        As mentioned in the article on Enshullese Magic, they are known for Paladins and Clerics that get right in the mix, not hanging back in the rear. Many Imperial soldiers meet their first Enshullese as a Cleric in army training.
        <br/>
        Civil Service often fills rolls in maritime, commerce, and logistics, with a reputation for being practical and simple in ideas, as contrasted to the ambitious Aetherese who Enshullese accuse of overcomplicating things and glory seeking.
        <br/>
        The Enshullese are sought after as social planners as well, leading civil service roles that involve speaking and organizing social events to be popular destinations as well.
      `,
    },
    enshullese_language: {
      name: "Enshullese Language",
      blurb: `
        Unlike other languages, the Enshullese had no reason not to speak their tongue for many generations. As the center of trade under the Ancient Aetherese Empire, the Draconic Tyrany, and the modern Imperium, most other groups would learn Enshullese with it becoming a bit of a Lingua Franca. Traders from all directions needed to go there, so why not all know their tongue. Only the recent rise of Progeny's Common Imperial has pushed it from the top spot, and even then Common Imperial borrows a lot of vocabulary from Enshullese to the point that Enshullese learn it the easiest.
        <br/>
        The language is known to be pleasing on the ear if it wants to be, or harsh and staccato-filled if desired.
        <br/>
        Nautical terms are often in Enshullese, along with cullinary terms, owing to their naval and cullinary domination over the centuries.
        <br/>
      `,
    },

    high_hinterlands: {
      name: "High Hinterlands",
      blurb: `
        When Imperials discuss High Hinterlands, they mean the north-eastern forests regions up into their mountains. While lumped together, there are numerous cultures that have slowly connected more and more with each generation after the Imperial defeat of the Southern Giants.
        <br/>
        <br/>
        <ul>
          <li>
            Hinterlander Culture
          </li>
          <li>
            Hinterlander Magic
          </li>
          <li>
            Hinterlander Military and Civil Service
          </li>
        </ul>
      `,
    },
    hinterlander_culture: {
      name: "Hinterlander Culture",
      blurb: `
          Hinterlanders are varied, with towns on neighboring sides of a mountainous valley having clashing cultures. This arises due to the forced isolation inherent in some of the terrain, as well as historical divisions. Non-Human groups such as Halflings, Goliaths, Firbolgs, and several others form villages here, where most of the Imperium is more integrated at this point.
        <br/>
        <br/>
        However, we can see some similarities.
        <br/>
        <br/>
        A theme of cooperation in the face of the elements runs throughout, leading to a group-focused society. This manifests also a resistance to materialism, especially in smaller groups where communities share vital tools to survive. Selfless actions are required as well, resulting in them being about the most charitable group in the Empire...possibly to a fault where traveling Hinterlanders are often marked by con artists.
        <br/>
        <br/>
        Their cooperative and generally selfless side inspires Pelor and Bahamut (proximity to the Platinum Republic doesn't hurt)
        <br/>
        <br/>
        The focus on natrual elements leads many to Melora as well, but also a more spiritual connection to the elements.
        <br/>
        <br/>
        One point of contention is with their neighboring Dwarves, who can be puzzled at Hinterlander lower emphasis on material possessions and assumptions of sharing.
        <br/>
        <br/>
      `,
    },
    hinterlander_magic: {
      name: "Hinterlander Magic",
      blurb: `
        Hinterlander is often connected to nature and the elements. Elementalists are stereotypically arise from these people, along with rangers and druids.
        <br/>
        <br/>
        Regions previously ruled by Giants can find Rune Carvers and Rune Knights. Some humans have trace Goliath or Firgolb ancestry which results in Hinterlanders being generally taller and having some giant magic in their blood. Giant Path Barbarians is a path taken by many of these giant-blooded.
        <br/>
        <br/>
        Remote mountains are perfect for medtiation, and monks have sought the area to train. Locals have adopted their teachings and nearly every flavor of monk can be found, with various communities often having a choice disciplie, often a Tradition of Monks, Discipline of Elementalists, or Runic Magic. Another sign of community building.
      `,
    },
    hinterlander_military: {
      name: "Hinterlander Military and Civil Service",
      blurb: `
        The Hinterlander focus on solidarity and familiarity with harsh environments makes them sought after rank and file soldiers, especially for mountain forces. Those with Goliath heritage use their size as front line bruisers, the most fierce as Rune Knight and Giant Path Babarian.
        <br/>
        <br/>
        They make skilled naturalists, Hinterlander rangers serve as scouts in the military and in many Broken Orders as they watch the wild frontiers and interiors of the Imperium.
        <br/>
        <br/>
        It is not uncommon for Paladins who take the Oath of the Ancients to be Hinterlanders as well. While there are Hinterlander Clerics, the people are generally spiritual as a whole, with fewer clerics traveling between various Hinterlander settlements.
        <br/>
        <br/>
        While Water Elementalists find a home in the Naval Forces, and Air Elementalists in Flying Forces and the sailing Navy...skilled Elementalists are spread across various military services, similar to how clerics and wizards are dispersed. Some are utilized for attacking while others fill a more defensive and support role.
        <br/>
        <br/>
        Elementalists find homes also in Engineering and other Civil Services where elemental manipulation is helpful.
        <br/>
        <br/>

      `,
    },
    aetherese: {
      name: "Aetherese Reach and Coasts",
      blurb: `
        The Aetherese Reach and Coasts cover the lands between the Oxkadi Marches and the rest of the Empire to the South. The people from upper riverlands to the offshore island share a culture and historical birthright, with some regional differences of course.
        <br/>
        <br/>
        Aetherese History is ancient yet eternal, storied yet tragic, bitter yet hopeful. From humble beginings in Aetheria, now just the Aetheria Ruins, they were an insignificant town of sheep herders upland from the Alipyrgos River. But some small town farmers developed talents for arcane magic. Breakthrough after breakthrough propelled them to expand at first peacefully to their neighbors into the Alipyrgos Consortium over 3.8K years ago.
        <br/>
        <br/>
        The Consortium expanded upriver, to the islands, and across the coasts, but their activities angered the Enshullese Empire to the South. The veteran Enshullese fleet smashes the Aetherese in the Battle of the False Tide in the year -3787.
        <br/>
        <br/>
        The Consortium, embarassed by the defeat and the prestige of the Enshulese "Empire", gathered their leading figured in Aetheria in conclave to plan the future. The leading elders declared the Consortium simply the founding member of the new Aetherese Empire, electing Isalcis Yzebelis the first Aetherese Empress as a leadng wizard with a plan to reorganize their society. Isalcis creates the Declaration of Universal Constorium, establishing a new magocratic government of wizards where positions and votes were based on magical abilities. She created a massive investment in arcnae education for the Aetherese nobility...while facing/conquering/allying with interior tribes outside the Reach to gain warriors.
        <br/>
        <br/>
        Their hard work paid off, as arcane researches found secrets of Quintessential Elements, and Dunamancy. Isalcis's great-grandson Baclris I began creating gravitational weapons, flying fortresses, and advanced technology to overwhelm their foes.
        <br/>
        <br/>
        The discovery and forging of Aetherese Steel began in these early days, though it would take generations until it could be produced in volume to make anything beyond small objects.
        <br/>
        <br/>
        THE WARS...TO BE CONTINUED
        <br/>
        <br/>
        <ul>
          <li>
            Aetherese Culture
          </li>
          <li>
            Aetherese Language
          </li>
          <li>
            Aetherese Military and Civil Service
          </li>
          <li>
            Aetherese Magic
          </li>
        </ul>

      `
    },
    aetherese_culture: {
      name: "Aetherese Culture",
      blurb: `
        Aetherese culture in its most well known form is one that values ambition, intelligence, and hard work.
        <br/>
        <br/>
        To outsiders, Aetherese might seem emotionally colder, behaving in more logical ways. However, this cultural tendency to be more reserved means affection and joy are shown in different ways.
        <br/>
        <br/>
        The Aetherese are expected to do great things, whatever path they chose. Aetherese parents are known to have their children try many different fields, crafts, schools, until one path appears to be the favorite for their young adult. At this point the parents wholeheartedly...though maintaing a reserved outer demeanor...support this new choice. Many outsiders think that Aetherese parents constantly brag and compare the achievments of their children, but it is a mark of great honor and friendship to the Aetherse to allow someone to boast of their descendants successes.
        <br/>
        <br/>
        Wizards, Artificers, and Eldritch Knights are well honored here, as are Rune Knights, Battle Master Fights, Arcane Archers Cobalt Soul Monks, really any path that requires rigorous study.
        <br/>
        <br/>
        Religiously the locals honor Ioun as goddess of knowledge and Milritha as the goddess of family, with their shrines being the most common. Pelor has a presence amongst those who take the ambition to helping others. Other imperial deities are represented, with Moradin drawing support from the crafters, Corellon drawing support for his magic, and Erathis/Bahamut for their general support of civilization.
        <br/>
        <br/>
        In terms of cuisine, Aetherse meals tend to be rather efficient affairs. Most dishes are served in single pots (for ease of cleaning) and filled with high protein mixes. Their stews are famous outside even of their own territories.
        <br/>
        <br/>
        Aetherese of the Riviera tend to be more vigilant due to proximity to the Pentarchy and place a higher focus on boating, fishing, and sea imagery in general. They add Melora, in her role as goddess of the seas, as well as other lesser water deities to their regular catalog of worship. While still into studying, they tend to favor more practical applications, such as wizards helping to guide ships and war focused studies.
        <br/>
        <br/>
      `
    },
    aetherese_langauge: {
      name: "Aetherese Language",
      language: true,
      blurb: `
        The Aetherse language is one of the most changed languages in Primavera over the course of the last 4 thousand years. The Aetherse language by this point is one so foundational to magical jargon it is hard to read ancient non-magical texts.
        <br/>
        <br/>
         Originally the dialect of Aetheria, common words like "chair" or "bird" were assigned to magical concepts and so associated with them that modern day Aetherese took loan words for the original concepts to avoid confusion.
        <br/>
        <br/>
        It is generally a harmonious language, making it a favorite of even non-Aetherese to use in magic rituals. However, by the present day most Aetherese speak at least enough Imperial Common to get by, with artisan and noble classes being fluent.
        <br/>
        <br/>
        Locals mostly speak Imperial Common, as it is logical for communication outside of their homeland. However, children are raised to know and speak Aetherese, but mostly as it will aide future scholars to know this tongue of aracne scholars. Also a professor decades ago claimed that learning Aetherese exposed children to difficult concepts, which will lead them to better be able to grasp other languages as they grow. This makes the pride in their languages less about the identity of it being their language, and more in its use to help grow in learning.
      `
    },
    aetherese_magic: {
      name: "Aetherese Magic",
      blurb: `
        Arcane magic, with Wizards and Eldritch Knights specifically, is given the highest honor here, moreso than most regions of the empire. It is not uncommon for half of wizards in imperial schools to be Aetherese.
        <br/>
        <br/>
        Other intelligence based crafts..., Arcane Archers, Rune Carving, Cobalt Soul Monks are worthy paths and much lauded.
        <br/>
        <br/>
        Artificers have historical relevance as well, as their ancestors created the mysterious Aetherese Steel. They fill military and civilian Artificer roles, and Aetherese goods are highly prized.
        <br/>
        <br/>
        Most notable Clerics tend to be Knowledge ones, even if not for Ioun, while others are present as well.
        <br/>
        <br/>
        Lore Bards are the most common type of performer, weaving bits of odd lore, facts, trivia, and subtle references that only the educated would understand into their performances.
        <br/>
        <br/>
        Blood Hunters are becoming more common, especially as the study of alchemy becomes increasingly popular up river.
        <br/>
        <br/>
        Outside of standard DND classes, other fields of learning are prestigious as well and often include some sort of magic to help them, most forms of engineering, shipwrights, historians, foresters, etc.
      `
    },
    aetherese_military: {
      name: "Aetherese Military and Civil Service",
      blurb: `
        The majority of Aetherese military tradition is steeped in magic, as shown in the article Aetherese Magic. Coastal Aetherese do join the navy in high numbers, where they have a reputation for making excellent navigators and shipwrights.
        <br/>
        <br/>
        While most Imperial wizards are Aetherese, they also represent a high number of tacticians, intelligence officers, analysts, and they dominate the logistics wings of all corners of the Imperium.
        <br/>
        <br/>
        Aetherese Artificers serve as armorers across the Imperial military, to the point that Imperial Armorers often speak in a slang form of Aetherese amongst themselves, even if they are not originally Aetherese.
        <br/>
        <br/>
        Imperial War Wizards are a majority Aetherese, with elite War Weaver units at some points historically being over 90% Aetherese.
        <br/>
        <br/>
        In the Silver Eye, the union of Ioun affiliated orders inside the Leviathan League, Aetherese scholars are present in every portion, be it as librarians, researchers, clerics, archivists, and even the vaulted Blood Heralds.
      `
    },
    oxkad: {
      name: "Oxkad Basin",
      blurb: `
        Riverlands to the North of the Empire, a sprawling river that flows from the far internal plains to the east in the Platinum Republic and beyond.
        <br/>
        <br/>
        For thousands of years they had contact with nomads from eastern steppes, through conquest and migration, but they assimilated the newcomers into their culture, not the other way around.
        <br/>
        <br/>
        Over 3 thousand years ago they were united under Cappadán the Great in response to a mad Aetherese mage opening demonic portals in their lands. He lead them to form a powerhouse along the massive river basin of cities and irrigation systems.
        <br/>
        <br/>
        After the initial conflict with the Aetherese, they eventually allied with them for magic help with their engineering projects. As the Aetherese grew more domineering and tyrannical, the Basin increasingly became a vassal state.
        <br/>
        <br/>
        During the Aetherese Wars they were drafted into Aetherese armies, and engineers helped them create their flying cities and redirect the water around Thalas. Despite the loses in the war, at the end they were freed from the Aetherese. They briefly rebuilt their realms and assimilated with new groups Steppe Nomads.
        <br/>
        <br/>
        The Oxkadi helped fight against Chromatics, but the Basin gradually fell to Chromatics during their thousands of years rise to power. Locals worked hard as they could to keep their canals and cities engineered and upkept, while growing their skils at subterfugue.
        <br/>
        <br/>
        The ruler Cappadán the Wolf lead an early uprising agains the Blue Chromatics who conquered them. While it was short lived, he spent the years of freedom preparing a resistance network with tunnels, supplies, and plans.
        <br/>
        <br/>
        Once the uprisings began, the Riverlands constantly were taken by Imperials, Hobgoblins, Orcs, Timother Dragonborn, and both types Dragons. It remained a major battleground for generations, as the thin long lands wre hard to hold with their open plains around them. The non-Chromatics reached a concord before and after the Chaos that left the Basin divided between many powers, but no more Chromatics.
        <br/>
        <br/>
        They cooperate with overlords, though their loyalty to a united Oxkad and underhanded skills mean all powers do not fully trust them. They keep an independent streak and desire for unification.
        <br/>
        <br/>
        <ul>
          <li>
            Oxkadi Culture
          </li>
          <li>
            Oxkadi Language
          </li>
          <li>
            Oxkadi Military
          </li>
        </ul>

      `
    },
    oxkad_culture: {
      name: "Oxkadi Culture",
      blurb: `
        Family bonds are extremely important, but mostly family within living memory of relation. Few Oxkadi groups form clans, with the flow of identity going from Oxkadi to dialectical region, to family, leading to each region being fairly unified without rival clans. The divisions by overlords and their family focus results in a decentralized social structure, but after family, most consider themselves Oxkadi.
        <br/>
        <br/>
        Traditional Oxkadi ways, the "Old Ways", involve ancestor honor/worship, tattoos to honor family and own deeds, and hospitality. Owing from a history of resistance to conquerors, Oxkadi do not consider subterfuge dishonorable.
        <br/>
        <br/>
        They have affinity for the Element of Water as the river is what defines them. Most Oxkadi learn to pilot boats from a young age, and most all can swim. Swimming and water sports are their main recreational activities. They have a history of using Geomancy to carve canals to irrigate their farms, leading to the element of Earth being honored as well. Thus Water and Earth Genasi Progeny are well honored, resulting in the Imperials assigning them as governors.
        <br/>
        <br/>
        Religiously the overlords influence the major deities honored, however, the subterfugue aspect allows for non-approve deities to remain. The family and ancestor focus of their culture honors Milritha and The Raven Queen, while their more independent tricky nature honors Avandra, Corellon, and Sehanine as well.
        <br/>
        <br/>
        In terms of cuisine, the Oxkadi meals tend to wraps of fish/meat with spicy peppers, while larger meals have many small dishes including hummus spreads, flatbread, seafood (well from the river), peppers, tomatoes, and rice.
      `
    },
    oxkad_langauge: {
      name: "Oxkadi Language",
      language: true,
      blurb: `
        Originally the language of the pastoralists of the basin from thousands of years ago. The ancient tongue greatly evolved with new words and influences from various steppe invaders, the Aetherese, Dragons, and now the Empire/Hobgoblin/Orcish.
        <br/>
        <br/>
        Locals (especially elites) often know Imperial/Goblinoid/Orcish/Draconic depending on their overlords. This has resulted in a dialectical shift between the areas of the basin as political realities shape vocabulary, especially words related to government and new items without previous words for them.
        <br/>
        <br/>
        However, the language spoken in homes is Oxkadi despite learning standard other languages. Dialectical differences do not prevent communication, as Oxkadi contains a wide and colorful vocabulary that any two Oxkadi should have enough overlap after a few attempts to get their points across.
      `
    },
    oxkad_magic: {
      name: "Oxkadi Magic",
      blurb: `
        The engineering focus of the Oxkadi has fostered both learning of Arcane Magic, and channeling Divine and Primal magic as well. The need for the canals to function and resistance
        <br/>
        <br/>
        The honoring of ancestors, and the focus on the dead, leads to a tradition of spirit powers. Ancestral Guardian Barbarians for example emerge and mighty Oxkadi ancestors choose not to move on, but to sleep until a worthy descendant emerges. Phantom Rogues and Spirit Bards were well known among the ranks of Oxkadi resistance fighters, a pattern that remains in Oxkadi skirmishers to this day.
        <br/>
        <br/>
        Due to a certain steppe tribe in their ancestry, Oxkadi blood has traces of lycanthropy, though mostly it manifests as Shifters. This makes Oxkadi good candidates for the Blood Maledict ritual that creates Blood Hunters. They die far less during the process due to their blood already being partially tainted.
      `
    },
    oxkad_military: {
      name: "Oxkadi Military",
      blurb: `
        Oxkadi armory traditions go back to the ancient Oxkad basin. Traditionally, weapons have an engraving of the Oxkad River with an X where it was forged.
        <br/>
        <br/>
        The oldest weapons are Sickle Shaped Khopesh, and while only made as curiousities today, magical Oxkadi Khopesh remain potent tools of war, as most magical weapons are far from young. An acient Khopesh is valued by Oxkadi to the point of desperation.
        The Khopesh was slowly replaced by the steppe styles of sabers and weapons designed for use on horseback. The weapon most associated with Oxkadis today is the Shaska, a guardless finese slashing sword longer than a scimitar. Too long to usualy be dual wielded like a scimitar, which the nomads accepted as a rider needed a free hand on the reins.
        <br/>
        <br/>
        Early shortbows adopted from steppe nomads evolved to longbows as the intelligent planted groves of Osage and Yew trees, creating the ultimate bow trees within a few hundred years.
        <br/>
        <br/>
        Oxkadi skirmishers are famous for their shaskas, light armor, skill with bows, stealth, and engineering. Whatever overlord they serve, they tend to be excellent skirmishers or battlefield engineers.
        The combination of steppe, underhanded, and skirmisher influences on the area results in a higher percentage of finese weapons produced today, especially their famous Shaska.
      `
    },
    whispervaled: {
      name: "Whispervale Enclave",
      blurb: `
        YO DAWG
        <Collapsible
          toggle={this.collapseToggle.bind(this)}
          kind='test'
          title="TEST"
          show={this.state.show}
        >
          <div>TEST</div>
        </Collapsible>
      `
    }
  }
}

export default articleData;
