import React from "react";

import Tableau   from "./Tableau";
import TotalRow  from "./TotalRow";

class TabulaCritica extends React.Component {
  constructor(props) {
    super(props);
    this.characters = [
      "Sparka",
      "Corpo",
      "Cecil",
      "Sass",
      "Lars",
      "The Duchess",
      "Raven Friend",
      "GDE"
    ]
  }

  render() {
    return (
      <div>
        <h3>
          Totals
        </h3>

        <table className="regular">
          <tbody>
            <tr>
              <td></td>

              {
                this.characters.map((character, indy) => {
                  return (
                    <td key={indy}>
                      {character}
                    </td>
                  )
                })
              }

              <td>
                Total
              </td>
            </tr>

            <TotalRow characters={this.characters} kind="20s"/>

            <TotalRow characters={this.characters} kind="1s"/>
          </tbody>
        </table>

        <Tableau kind="1s"/>

        <Tableau kind="20s"/>
      </div>
    )
  }
}

export default TabulaCritica;
