class Randomizer {

  generate() {
    return {
      race: this.random(Object.keys(this.races)),
      klass: this.random(this.classes),
      background: this.random(this.backgrounds),
      families: this.weightedRandom(this.families),
      alignment: this.random(this.alignment.slice(0,12))
    }
  }

  genRelationship() {
    return this.weightedRandom(this.relationship);
  }

  random(collection) {
    return collection[Math.floor(Math.random()*collection.length)]
  }

  weightedRandom(spec) {
    var i, sum=0, r=Math.random();
    for (i in spec) {
      sum += spec[i];
      if (r <= sum) return i;
    }
  }

  backgrounds = [
    "Acolyte",
    "Anthropologist",
    "Archaeologist",
    "Athlete",
    "Charlatan",
    "City Watch",
    "Clan Crafter",
    "Cloistered Scholar",
    "Courtier",
    "Criminal",
    "Entertainer",
    "Faceless",
    "Faction Agent (Custom)",
    "Faction Agent (Order of the Gauntlet)",
    "Faction Agent (Harpers)",
    "Faction Agent (Emerald Enclave)",
    "Faction Agent (Lord's Alliance)",
    "Faction Agent (Zhentarim)",
    "Far Traveler",
    "Fisher",
    "Folk Hero",
    "Gladiator",
    "Guild Artisan",
    "Guild Merchant",
    "Haunted One",
    "Hermit",
    "House Agent",
    "Inheritor",
    "Investigator",
    "Knight",
    "Knight of the Order",
    "Marine",
    "Mercenary Veteran",
    "Noble",
    "Outlander",
    "Pirate",
    "Sage",
    "Sailor",
    "Shipwright",
    "Smuggler",
    "Soldier",
    "Spy",
    "Urban Bounty Hunter",
    "Urchin",
    "Uthgardt Tribe Member",
    "Waterdhavian Noble"
  ]

  // races = [
  //   "Dragonborn",
  //   "Dwarf",
  //   "Elf",
  //   "Gnome",
  //   "Half-Elf",
  //   "Halfling",
  //   "Half-Orc",
  //   "Human",
  //   "Tiefing",
  //   "Customized Origin"
  // ]

  races = {
    "Dragonborn": {
      stats: [
        [2, "strength"],
        [1, "charisma"],
      ],
      traits: [
        "Draconic Ancestry",
        "Breath Weapon",
        "Damage Resistance"
      ],
    },
    "Dwarf": {
      stats: [
        [2, "constitution"],
      ],
      traits: [
        "Darkvision",
        "Dwarven Resilience",
        "Dwarven Combat Training",
        "Stonecunning"
      ],
    },
    "Elf": {
      stats: [
        [2, "dexterity"],
      ],
      traits: [
        "Darkvision",
        "Keen Senses",
        "Fey Ancestry",
        "Trance"
      ],
    },
    "Gnome": {
      stats: [
        [2, "intelligence"],
      ],
      traits: [
        "Darkvision",
        "Gnome Cunning"
      ],
    },
    "Half-Elf": {
      stats: [
        [2, "charisma"],
        [1, "any"],
        [1, "any"]
      ],
      traits: [
        "Darkvision",
        "Fey Ancestry",
        "Skill Versatility"
      ],
    },
    "Halfling": {
      stats: [
        [2, "dex"],
      ],
      traits: [
        "Lucky",
        "Brave",
        "Halfling Nimbleness"
      ],
    },
    "Half-Orc": {
      stats: [
        [2, "strength"],
        [1, "constitution"],
      ],
      traits: [
        "Darkvision",
        "Menacing",
        "Relentless Endurance",
        "Savage Attacks"
      ],
    },
    "Human Variant": {
      stats: [
        [1, "any"],
        [1, "any"]
      ],
      traits: [
        "Darkvision",
        "Menacing",
        "Relentless Endurance",
        "Savage Attacks"
      ],
    },
    "Tiefling": {
      stats: [
        [2, "charisma"],
        [1, "intelligence"],
      ],
      traits: [
        "Darkvision",
        "Hellish Resistance",
        "Infernal Legacy"
      ],
    },
    "Customized Origin": {
      stats: [
        [2, "any"],
      ],
      traits: [
        "One Feat",
        "Darkvision or Skill Proficiency",
        "Extra Language"
      ],
    },
  }

  classes = [
    "Barbarian",
    "Bard",
    "Cleric",
    "Druid",
    "Fighter",
    "Monk",
    "Paladin",
    "Ranger",
    "Rogue",
    "Sorcerer",
    "Warlock",
    "Wizard",
    "Artificer"
  ]


  alignment = [
    ["Lawful Good", "There is No Good Without Justice"],
    ["Lawful Good", "Honor and Tradition are Paramount to Good Society"],

    ["Neutral Good", "Laws are not inherently good"],
    ["Neutral Good", "No Philosophy is perfect, they are guides"],

    ["Chaotic Good", "Laws and Traditions are tools of Tyrants"],
    ["Chaotic Good", "There is No Good Without Freedom and Individuality"],

    ["Lawful Neutral", "Law and Justice bring Order, the only true aim"],
    ["Lawful Neutral", "The Ends Justify The Means"],

    ["True Neutral", "Loyalty and Honor to my side only"],
    ["True Neutral", "Nautre is balance, not Philosophy or Morality"],

    ["Chaotic Neutral", "Laws and Rules impede freedom"],
    ["Chaotic Neutral", "Life is to be enjoyed by my standards"],

    ["Lawful Evil", "The law is my framework to absolute power"],
    ["Lawful Evil", "Order favors me, so I favor order"],

    ["Neutral Evil", "Kill anyone for to help me"],
    ["Neutral Evil", "Nothing matters besides my desires"],

    ["Chaotic Evil", "I just want to watch the world burn"],
    ["Chaotic Evil", "My Freedom and Desires are important, but only me"],
  ]

  families = {
    "None": 0.02,
    "Institution, such as an asylum": 0.03,
    "Temple": 0.03,
    "Orphanage": 0.06,
    "Guardian": 0.04,
    "Paternal or maternal aunt, uncle, or both": 0.08,
    "Paternal or maternal grandparent(s)": 0.1,
    "Adoptive family (same or different race)": 0.1,
    "Single father or stepfather": 0.17,
    "Single mother or stepmother": 0.17,
    "Mother and father": 0.20
  }

  relationship = {
    "Commoner": 0.05,
    "Acolyte": 0.05,
    "Bandit": 0.05,
    "Barbarian": 0.05,
    "Cult fanatic": 0.05,
    "Druid": 0.05,
    "Gladiator": 0.05,
    "Guard": 0.05,
    "Knight": 0.05,
    "Priest": 0.05,
    "Scout": 0.05,
    "Spy": 0.05,
    "Tribal warrior": 0.05,
    "Veteran": 0.05,
    "Mage": 0.04,
    "Noble": 0.04,
    "Assassin": 0.04,
    "Faction Member (DM's choice)": 0.07,
    "Good or neutral werebear or weretiger (DM's choice)": 0.02,
    "Evil wereboar, wererat, or werewolf (DM's choice)": 0.02,
    "Archmage": 0.01,
    "Dragon (DM's choice)": 0.01,
  }

}

export default Randomizer;
