import React                 from "react";
import { Link }              from "react-router-dom";

class CharacterList extends React.Component {
  allowedChars(chars) {
    return Object.keys(chars).filter((characterKey) => {
      return chars[characterKey].enabled;
    }).sort();
  }

  render() {
    return (
      <div className="divisor">
        {
          this.allowedChars(this.props.characters).map((characterKey, indy) => {
            return (
              <div
                key={indy}
                className="index-listing"
              >
                <Link to={`/pages/${characterKey}`}>
                  <img
                    src={this.props.characters[characterKey].src || "https://www.pngfind.com/pngs/m/5-53216_male-avatar-silhouette-man-hd-png-download.png"}
                    alt="character-thumb"
                  />

                  <div className="info-panel">
                    <h3>
                      {this.props.characters[characterKey].name}
                    </h3>

                    <span>
                      {this.props.characters[characterKey].tagLine}
                    </span>
                  </div>
                </Link>
              </div>
            )
          })
        }
      </div>
    )
  }
}

export default CharacterList;
