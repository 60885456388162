import { BrowserRouter, Route, Switch } from "react-router-dom";

import './App.css';

import Home              from "./components/Home";
import Articles          from "./components/articles/Articles";
import Authenticate      from "./components/Authenticate";
import Characters        from "./components/characters/Characters";
import Creation          from "./components/creation/Creation";
import Details           from "./components/Details";
import Divines           from "./components/divines/Divines";
import EditCharacter     from "./components/characters/EditCharacter";
import Events            from "./components/events/Events";
import Timeline          from "./components/events/Timeline";
import Factions          from "./components/factions/Factions";
import InfoPage          from "./components/info/InfoPage";
import Locations         from "./components/locations/Locations";
import Map               from "./components/maps/Map";
import Maps              from "./components/maps/Maps";
import NewShop           from "./components/locations/NewShop";
import Planes            from "./components/divines/Planes";
import PrimaveraCreation from "./components/creation/PrimaveraCreation";
import LanguageSandbox   from "./components/languages/LanguageSandbox";


function App() {
  if (window.location.search.match(/fullscreen=true/)) {
    return (
      <BrowserRouter>
        <Authenticate>
          <Route exact path="/" component={Home}/>

          <Route exact path="/articles/" component={Articles}/>

          <Route exact path="/maps/" component={Maps}/>
          <Route
            path="/maps/:map"
            render={(props) => (
              <Map {...props} map={props.match.params.map} />
            )}
          />

          <Route exact path="/creation/" component={Creation}/>

          <Route exact path="/prima/" component={PrimaveraCreation}/>
          <Route exact path="/stiernan/" component={LanguageSandbox}/>

          <Switch>
            <Route exact path="/characters/" component={Characters}/>
            <Route exact path="/characters/edit" component={EditCharacter}/>
            <Route
              path="/characters/:character"
              render={(props) => (
                <InfoPage {...props} keyed={props.match.params.character} />
              )}
            />
          </Switch>

          <Route exact path="/locations/" component={Locations}/>
          <Route
            path="/locations/:location"
            render={(props) => (
              <InfoPage {...props} keyed={props.match.params.location} />
            )}
          />

          <Route exact path="/events/" component={Events}/>
          <Route
            path="/events/:event"
            render={(props) => (
              <InfoPage {...props} keyed={props.match.params.event} />
            )}
          />

          <Route exact path="/timeline/" component={Timeline}/>

          <Route exact path="/factions/" component={Factions}/>
          <Route
            path="/factions/:faction"
            render={(props) => (
              <InfoPage {...props} keyed={props.match.params.faction} />
            )}
          />
        </Authenticate>
      </BrowserRouter>
    )
  }
  else {
    return (
      <div className="App">
        <div className="nav">
          <div className="campaign-title">
            <a className='standard-header' href="/">
              <h2>
                The Return of the Queen
              </h2>
            </a>

            <h2 className="mobile-header">
              The Return of the Queen
            </h2>
          </div>
          <div className="links">
            <a className="mobile-home-link" href="/">
              Home
            </a>
            <a href="/creation/">
              Creation Guide
            </a>
            <a href="/characters/">
              Characters
            </a>
            <a href="/details/">
              Background Details
            </a>
            <a href="/maps/">
              Maps
            </a>
            <a href="/signout">
              Signout
            </a>
          </div>
        </div>
        <div className={`reading-portion ${window.location.pathname.match(/divines/) ? 'overflown' : ''}`}>
          <header className="App-header">
          </header>

          <BrowserRouter>
            <Authenticate>
              <Route exact path="/" component={Home}/>

              <Route exact path="/articles/" component={Articles}/>

              <Route exact path="/maps/" component={Maps}/>
              <Route
                path="/maps/:map"
                render={(props) => (
                  <Map {...props} map={props.match.params.map} />
                )}
              />

              <Route exact path="/creation/" component={Creation}/>

              <Route exact path="/prima/" component={PrimaveraCreation}/>
              <Route exact path="/stiernan/" component={LanguageSandbox}/>

              <Switch>
                <Route exact path="/characters/" component={Characters}/>
                <Route exact path="/characters/edit" component={EditCharacter}/>
                <Route
                  path="/characters/:character"
                  render={(props) => (
                    <InfoPage {...props} keyed={props.match.params.character} />
                  )}
                />
              </Switch>

              <Route exact path="/locations/" component={Locations}/>
              <Route
                path="/locations/:location"
                render={(props) => (
                  <InfoPage {...props} keyed={props.match.params.location} />
                )}
              />

              <Route exact path="/events/" component={Events}/>
              <Route
                path="/events/:event"
                render={(props) => (
                  <InfoPage {...props} keyed={props.match.params.event} />
                )}
              />

              <Route exact path="/timeline/" component={Timeline}/>

              <Route exact path="/details/" component={Details}/>

              <Route exact path="/factions/" component={Factions}/>
              <Route
                path="/factions/:faction"
                render={(props) => (
                  <InfoPage {...props} keyed={props.match.params.faction} />
                )}
              />

              <Route exact path="/new-shop/" component={NewShop}/>

              <Route
                path="/pages/:keyed"
                render={(props) => (
                  <InfoPage {...props} keyed={props.match.params.keyed} />
                )}
              />

              <Route exact path="/divines/" component={Divines}/>
              <Route exact path="/planes/" component={Planes}/>
            </Authenticate>
          </BrowserRouter>
        </div>
      </div>
    );
  }
}


export default App;
