import React from "react";

class KillTabuleau extends React.Component {
  applicableKills(list) {
    return list.filter((kill) => {
      if(this.props.kind === "dragonKills") {
        return kill.match("DRAGON SLAYER");
      }
      else if(this.props.kind === "giantKills") {
        return kill.match("GIANT SLAYER");
      }
      else {
        return true;
      }
    })
  }

  render() {
    return (
      <div>
        <h3>{this.props.kind}</h3>

        <table>
          <thead>
            <tr>
              <th></th>
              <th>
                Character
              </th>
              <th>
                Session
              </th>
              <th>
                Description
              </th>
            </tr>
          </thead>
          <tbody>
            {
              Object.keys(this.props.data).map((session) => {
                return (
                  <React.Fragment key={session}>
                    {
                      Object.keys(this.props.data[session]).map((character, indy) => {
                        return (
                          <React.Fragment key={indy}>
                            {
                              this.applicableKills(this.props.data[session][character]).map((kill, killIndy) => {
                                return (
                                  <tr key={killIndy}>
                                    <td></td>
                                    <td>
                                      {character}
                                    </td>
                                    <td>
                                      {session}
                                    </td>
                                    <td>
                                      {kill}
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </React.Fragment>
                        )
                      })
                    }
                  </React.Fragment>
                )
              })
            }
          </tbody>
        </table>
      </div>
    )
  }
}

export default KillTabuleau;
