import React from "react";

import KillTotal from "./KillTotal"

class KillTotalRow extends React.Component {
  render() {
    return (
      <tr>
        <td>
          {this.props.title}
        </td>

        {
          this.props.characters.map((character, indy) => {
            return (
              <td key={indy}>
                <KillTotal data={this.props.data} character={character} kind={this.props.kind}/>
              </td>
            )
          })
        }

        <td>
          <KillTotal data={this.props.data} character={"all"} kind={this.props.kind}/>
        </td>
      </tr>
    )
  }
}

export default KillTotalRow;
