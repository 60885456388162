const smoochesData = {
  6: {
    Corpo: [
      "Wildon Fiddlestump, pretending to give mouth to mouth, rolled for tongue",
      "Sister Garaele, Kiss on the cheek as he left"
    ],
  },
  7: {
    Lars: [
      "....Qelline Alderleaf....."
    ],
  },
  8: {
    Lars: [
      "....Qelline Alderleaf.....round 2"
    ],
  },
  11: {
    Corpo: [
      "Lady Carnival after impressing her with his wine pouring"
    ],
  },
  23: {
    Corpo: [
      "A special massage in Ectasy and Fire"
    ],
    Lars: [
      "Had a lovely evening with Thistle Brathen"
    ]
  },
  24: {
    Lars: [
      "Another round with Thistle Brathen after she stole his flask and he stole her underwear"
    ]
  },
  30: {
    Sass: [
      "He Pretty woman's a Ho (Razorfang) and then the next day returns to see what her dress he paid for looks like off of her scaly body"
    ]
  },
  31: {
    Sass: [
      "Makes love to the Fang gently like Tenacious D"
    ]
  },
  37: {
    Sass: [
      "Fanging with the Fang after some emotional vulnerability admitting his insecurities and fears about her cheating on him",
      "Morning Fanging while others summoned a Succubus",
    ],
    Lars: [
      "Fanging with Thisle aftering showing her the Dragon Scale addition",
      "Morning Fanging while others summoned a Succubus"
    ]
  }
}

export default smoochesData
