import React from "react";

import critData from "../../data/crits";

class CritTotal extends React.Component {
  totalsSearch() {
    let total = 0;

    Object.keys(critData[this.props.kind]).forEach((session) => {
      critData[this.props.kind][session].forEach((record) => {
        if(record.character === this.props.character || this.props.character === "all") {
          total ++;
        }
      });
    });

    return total;
  }

  render() {
    return (
      <>
        {this.totalsSearch()}
      </>
    )
  }
}

export default CritTotal;
