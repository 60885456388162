import React               from 'react';
import PrimaveraRandomizer from '../../lib/PrimaveraRandomizer';

class PrimaveraCreation extends React.Component {
  constructor(props) {
    super(props);
    this.randomizer = new PrimaveraRandomizer();

    this.state = {randos: []}
  }


  render() {
    return (
      <div className="standard-panel">
        <h1>
          Primavera Generator
        </h1>

        <select
          onChange={(e) => {this.setState({selectedRace: e.target.value})}}
        >
          <option placeholder>
            Selected Race
          </option>
          {
            Object.keys(this.randomizer.races || []).map((key, indy) => {
              return (
                <option
                  key={indy}
                  value={key}
                >
                  {key}
                </option>
              )
            })
          }
        </select>

        <button onClick={() => {
          this.setState({
            randos: this.randomizer.generateList(10, {race: this.state.selectedRace})
          })}
        }>
          HIT
        </button>

        <table>
          <thead>
            <tr>
              <th>
                Race
              </th>
              <th>
                Sex
              </th>
              <th>
                Name
              </th>
              <th>
                Class
              </th>
              <th>
                Background
              </th>
              <th>
                Families
              </th>
              <th>
                Alignment
              </th>
            </tr>
          </thead>

          <tbody>
            {
              this.state.randos.map((rando, indy) => {
                return (
                  <tr key={indy}>
                    <td>
                      {rando.race}
                    </td>
                    <td>
                      {rando.sex}
                    </td>
                    <td>
                      {rando.name.first}
                      {
                        rando.name.middle &&
                        <div>
                          {rando.name.middle}
                        </div>
                      }

                      <div>
                        {rando.name.last}
                      </div>
                    </td>
                    <td>
                      {rando.klass}
                    </td>
                    <td>
                      {rando.background}
                    </td>
                    <td>
                      {rando.families}
                    </td>
                    <td>
                      {rando.alignment[0]}
                      <br/>
                      {rando.alignment[1]}
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    )
  }
}

export default PrimaveraCreation;
