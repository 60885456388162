const measures = {
  Fireball2: {
    thunda: {

    },
    default: {
      width: 700,
      display: "block",
      height: 700,
      backgroundColor: "#F009",
      borderRadius: "800px",
      position: "absolute",
    }
  },
  Fireball: {
    thunda: {
      height: 1604, //1.802247191
      width: 1604,
    },
    neutralparty: {
      height: 885,
      width: 885,
    },
    cragmaw: {
      height: 1145,
      width: 1145,
    },
    default: {
      width: 890,
      display: "block",
      height: 890,
      backgroundColor: "#F009",
      borderRadius: "800px",
      position: "absolute",
    }
  },
  smallerCircle: {
    thunda: {
    },
    default: {
      width: 300,
      display: "block",
      height: 300,
      backgroundColor: "#00F9",
      borderRadius: "800px",
      position: "absolute",
    }
  },
  Dragonborn: {
    neutralparty: {
      borderLeft: "209px solid transparent",
      borderRight: "209px solid transparent",
      borderTop: "337px solid #96CD",
    },
    cragmaw: {
      borderLeft: "269px solid transparent",
      borderRight: "269px solid transparent",
      borderTop: "435px solid #96CD",
    },
    thunda: {
      borderLeft: "374px solid transparent",
      borderRight: "374px solid transparent",
      borderTop: "600px solid #96CD",
    },
    default: {
      display: "block",
      position: "absolute",

      //triangle
      // width: 700,
      // height: 700,
      // borderLeft: "350px solid transparent",
      // borderRight: "350px solid transparent",
      // borderTop: "700px solid red",,


      //cone
      width: 0,
      height: 0,
      borderLeft: "208px solid transparent",
      borderRight: "208px solid transparent",
      borderTop: "333px solid #96CD",
      borderRadius: "50%",
    }
  },
  MonkDragon: {
    neutralparty: {
      borderLeft: "278px solid transparent",
      borderRight: "278px solid transparent",
      borderTop: "450px solid #0AFD",
    },
    cragmaw: {
      borderLeft: "535px solid transparent",
      borderRight: "535px solid transparent",
      borderTop: "863px solid #0AFD",
    },
    thunda: {
    },
    default: {
      display: "block",
      position: "absolute",
      width: 0,
      height: 0,
      borderLeft: "500px solid transparent",
      borderRight: "500px solid transparent",
      borderTop: "808px solid #0AFD",
      borderRadius: "50%",
    },

  },
  Venomfang: {
    neutralparty: {
      borderLeft: "483px solid transparent",
      borderRight: "483px solid transparent",
      borderTop: "782px solid #0F0D",
    },
    thunda: {
    },
    default: {
      display: "block",
      position: "absolute",
      width: 0,
      height: 0,
      borderLeft: "741px solid transparent",
      borderRight: "741px solid transparent",
      borderTop: "1208px solid #0F0D",
      borderRadius: "50%",
    },

  },
  MonkLine: {
    neutralparty: {
      width: 112,
      height: 668,
    },
    cragmaw: {
      width: 149,
      height: 860,
    },
    thunda: {
      width: 200,
      height: 1200,
    },
    default: {
      display: "block",
      position: "absolute",
      width: 112,
      height: 525,
      backgroundColor: "#0AFD"
    }
  }
}


//https://css-tricks.com/the-shapes-of-css/

export default measures;
