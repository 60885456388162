const namesData = {
  "Elf": {
    first: {
      "♂": [
        "Revalor",
        "Ryul",
        "Reluraun",
        "Nesterin",
        "Aias",
        "Sontar",
        "Thuridan",
        "Traeliorn",
        "Otaehryn",
        "Elmar",
        "Sundamar",
        "Alabyran",
        "Ailmar",
        "Aired",
        "Elmon",
        "Erolith",
        "Taranath",
        "Halafarin",
        "Merith",
        "Khidell",
        "Waeslen",
        "Naehice",
        "Pagolor",
        "Hermaer",
        "Aelamin",
        "Pettumal",
        "Ralofir",
        "Umesandoral",
        "Mirahorn",
        "Carnelis",
        "Elcan",
        "Qincan",
        "Adfir",
        "Leozumin",
        "Daeceran",
        "Rokian",
        "Glynquinal",
        "Naegolor",
        "Craric",
        "Glynhorn",
        "Leoven",
        "Ilibalar",
        "Zinmaris",
        "Balvalur",
        "Adfir",
        "Urinorin",
        "Yingolor",
        "Waessalor",
        "Rovalur",
        "Syldithas",
        "Lulamin",
        "Qinric",
        "Fenkas",
        "Leokas",
        "Thesalor",
        "Daeydark",
        "Ilinan",
        "Balmyar",
        "Raloro",
        "Lutumal",
        "Qinan",
        "Carmaris",
        "Cramaer",
        "Wranbalar",
        "Beimaer",
        "Zumwraek",
        "Paran",
        "Keazumin",
        "Ronorin",
        "Luqen",
        "Ralomyar",
        "Qinmaris",
        "Petven",
        "Omaydark",
        "Beiven",
        "Elajor",
        "Genbalar",
        "Naehorn",
        "Zingolor",
        "Vafir",
        "Olonorin",
        "Yelbalar",
        "Waescan",
        "Adquinal",
        "Wranris",
        "Craqen",
        "Herfaren",
        "Zumfir",
        "Pertumal",
        "Omaro",
        "Daeberos",
        "Genmyar",
        "Luro",
        "Umelamin",
        "Petquinal",
        "Leokas",
        "Uriro",
        "Heiwarin",
        "Beigeiros",
        "Eladan",
        "Thekas",
        "Omaven",
        "Ililar",
        "Carmaer",
        "Ralojor",
        "Leobalar",
        "Norlar",
        "Keanan",
        "Luwraek",
        "Paven",
        "Glyntumal",
        "Morlamin",
        "Farjeon",
        "Paric",
        "Ilixidor",
        "Kelbalar",
        "Farkas",
        "Dormaris",
        "Farzumin",
        "Nortoris",
        "Keazumin",
        "Tradan",
        "Ianmyar",
        "Erneiros",
        "Beinorin",
        "Herven",
        "Aeberos",
        "Petydark",
        "Waeslen",
        "Umero",
        "Ernelis",
        "Iligolor",
        "Ralonelis",
        "Waesqen",
        "Thewraek",
        "Miraric",
        "Trahorn",
        "Virmaer",
        "Genpetor",
        "Zumtumal",
        "Elquinal",
        "Elkian",
        "Omanan",
        "Qinlen",
        "Kelgeiros",
        "Aegeiros",
        "Elazumin",
        "Morpeiros",
        "Aepetor",
        "Glynkian",
        "Keatoris",
        "Glynfir",
        "Luwraek",
        "Olomyar",
        "Hermaer",
        "Zumfaren",
        "Keldan",
        "Trazumin",
        "Themenor",
      ],
      neutral: [
        "Chaenath",
        "Arel",
        "Ciliren",
        "Azariah",
        "Khidell",
        "Taenaran",
        "Aithlin",
        "Irhaal",
        "Elauthin",
        "Nueleth",
        "Ivasaar",
        "Aithlin",
        "Ciradyl",
        "Ryllae",
        "Nieven",
        "Cohnal",
        "Jorildyn",
        "Haldir",
      ],
      "♀": [
        "Keryth",
        "Leilatha",
        "Elenaril",
        "Mhoryga",
        "Kenia",
        "Tanulia",
        "Almedha",
        "Deulara",
        "Solana",
        "Cremia",
        "Sionia",
        "Sionia",
        "Sionia",
        "Cremia",
        "Solana",
        "Deulara",
        "Almedha",
        "Tanulia",
        "Kenia",
        "Mhoryga",
        "Elenaril",
        "Leilatha",
        "Araxina",
        "Olamys",
        "Zylrora",
        "Olahana",
        "Trisynore",
        "Yllawynn",
        "Daelynn",
        "Dasys",
        "Ravakalyn",
        "Magstina",
        "Yesrie",
        "Torzorwyn",
        "Gilra",
        "Xyrstina",
        "Xilhana",
        "Zylharice",
        "Bilynn",
        "Zyldi",
        "Wynra",
        "Greleth",
        "Olagella",
        "Holalynn",
        "Jora",
        "Maggella",
        "Iarbella",
        "Favyre",
        "Valcaryn",
        "Yeslee",
        "Torthana",
        "Wysaynore",
        "Kristris",
        "Valroris",
        "Holazorwyn",
        "Magkrana",
        "Chaegella",
        "Torstina",
        "Faegwyn",
        "Farie",
        "Oribella",
        "Oririeth",
        "Farora",
        "Torkrana",
        "Joyra",
        "Jothana",
        "Bryphyra",
        "Reyroris",
        "Entris",
        "Preswynn",
        "Keylana",
        "Inaynore",
        "Kristris",
        "Qiphine",
        "Presmys",
        "Faenala",
        "Phiphine",
        "Trisqirelle",
        "Trissatra",
        "Fayra",
        "Preslynn",
        "Inazorwyn",
        "Zylynore",
        "Lorayra",
        "Phibanise",
        "Yllayra",
        "Wynthana",
        "Ravacyne",
        "Phiroris",
        "Birel",
        "Bira",
        "Dajyre",
        "Daeroris",
        "Faerieth",
        "Daerieth",
        "Quigwyn",
        "Qigwyn",
        "Torkalyn",
        "Yeslynn",
        "Torharice",
        "Olanala",
        "Reyrona",
        "Sylralei",
        "Faecyne",
        "Olabanise",
        "Chaesatra",
        "Trisynore",
        "Yllaynore",
        "Yllasys",
        "Xyrcaryn",
        "Grezorwyn",
        "Sylbella",
        "Yeswynn",
        "Yllagella",
        "Oritris",
        "Reygwyn",
        "Aracaryn",
        "Enharice",
        "Brydove",
        "Xillana",
        "Inalee",
        "Holazorwyn",
        "Torlee",
        "Faqirelle",
        "Wynxisys",
        "Xillee",
        "Enrora",
        "Eilcyne",
        "Torqirelle",
        "Oriroris",
        "Chaeqirelle",
        "Shavaris",
        "Miavaris",
        "Trisbanise",
        "Yllaharice",
        "Magphyra",
        "Wysalynn",
        "Zincyne",
        "Vengwyn",
        "Torharice",
        "Jomoira",
        "Trisphyra",
        "Orizorwyn",
        "Xyrrie",
        "Iarcaryn",
        "Faesys",
        "Valkrana",
        "Facaryn",
        "Torlana",
        "Arawynn",
        "Pressatra",
        "Iarharice",
        "Ravazorwyn",
        "Triszana",
        "Yllasatra",
        "Ularora",
        "Orikrana",
        "Quirora",
        "Miabella",
        "Qiphyra",
        "Iargwyn",
        "Nerirora",
        "Loracyne",
        "Loradi",
        "Chaewynn",
        "Qidove",
        "Urina",
        "Zinrel",
        "Ulakrana",
        "Sylharice",
        "Nerilynn",
        "Neriwenys",
        "Valstina",
        "Dakrana",
        "Dajyre",
        "Grephine",
        "Enlee",
        "Krisxisys",
        "Xilwynn",
        "Wynzorwyn",
        "Zinrona",
        "Yllalynn",
        "Eilphyra",
        "Oriphine",
        "Torleth",
        "Sylthana",
        "Iaryra",
        "Valthyra",
        "Venwynn",
        "Eilxina",
        "Holabanise",
        "Zylthyra",
        "Zummaris",
        "Yingeiros",
        "Heisalor",
        "Erydark",
        "Aewraek",
        "Theris",
        "Qisandoral",
        "Virceran",
        "Morzeiros",
        "Aemaris",
      ]
    },
    last: [
      "Holagolor",
      "Facaryn",
      "Elaharice",
      "Beiphine",
      "Fenkrana",
      "Perkian",
      "Krisrie",
      "Ralojyre",
      "Gredi",
      "Elajeon",
      "Leofiel",
      "Olanan",
      "Lorahorn",
      "Roxidor",
      "Shafaren",
      "Phirieth",
      "Carnala",
      "Presceran",
      "Naephine",
      "Ianna",
      "Ravaris",
      "Ravawynn",
      "Kelthana",
      "Miratumal",
      "Zumrona",
      "Virstina",
      "Origella",
      "Olacyne",
      "Krisro",
      "Trazorwyn",
      "Liaxisys",
      "Farbalar",
      "Orihorn",
      "Jobella",
      "Vanelis",
      "Umeric",
      "Venlen",
      "Phidove",
      "Valquinal",
      "Iandithas",
      "Ralorie",
      "Persatra",
      "Reyyarus",
      "Eilkian",
      "Royra",
      "Wynstina",
      "Umemoira",
      "Wranwarin",
      "Petzorwyn",
      "Petzorwyn",
      "Petzorwyn",
      "Wranwarin",
      "Umemoira",
      "Wynstina",
      "Royra",
      "Eilkian",
      "Reyyarus",
      "Persatra",
      "Ralorie",
      "Iandithas",
      "Fogheel",
      "Birchbeam",
      "Raingaze",
      "Summershard",
      "Dastrenthrel",
      "Fierar",
      "Drarasties",
      "Fiesos",
      "Musto",
      "Dawnshine",
      "Boldgift",
      "Oakenblossom",
      "Windmind",
      "Diamondbreath",
      "Lucontaxonn",
      "Ardash",
      "Henont",
      "Bastroninthri",
      "Wiarohosh",
      "Silentdream",
      "Sagebell",
      "Bluestar",
      "Wildmind",
      "Beechbirth",
      "Dresesephash",
      "Aldinna",
      "Tilorostrenn",
      "Buntocont",
      "Ema",
      "Dewbrook",
      "Firfate",
      "Sageshine",
      "Springdew",
      "Eldermoon",
      "Miardoth",
      "Bollual",
      "Fledrendre",
      "Iernir",
      "Thiephaxirriith",
      "Winterbirth",
      "Nightspirit",
      "Fogsinger",
      "Silentsinger",
      "Silverlight",
      "Kannon",
      "Voldann",
      "Ethas",
      "Reymir",
      "Maledrices",
      "Dewsinger",
      "Willowfond",
      "Silverstar",
      "Lightflight",
      "Gemkind",
      "Yenes",
      "Xesilkaeann",
      "Vophostril",
      "Gamies",
      "Quallont",
      "Mistsinger",
      "Sagegazer",
      "Shadowriver",
      "Summerdreamer",
      "Sunsong",
      "Nathi",
      "Xianasath",
      "Duholdennir",
      "Quindrasith",
      "Yovahis",
      "Spiritgift",
      "Amberfond",
      "Boldflower",
      "Sparkhold",
      "Winterbeam",
      "Cunthrivoha",
      "Astron",
      "Berastanniann",
      "Vexeltana",
      "Kethon",
      "Azureflight",
      "Spiritfond",
      "Flowerdew",
      "Silvermoon",
      "Ravenhold",
      "Culdrelkar",
      "Curil",
      "Gumesedrer",
      "Kimorrir",
      "Sessarondra",
      "Rainstar",
      "Emberwatcher",
      "Moonmight",
      "Oakseeker",
      "Alderheart",
      "Bavecint",
      "Gomevon",
      "Otholann",
      "Olphannis",
      "Xeythri",
      "Emberstar",
      "Dawnkind",
      "Greencrown",
      "Forestdreamer",
      "Hazelstar",
      "Dunne",
      "Veviphe",
      "Shulthendra",
      "Kethrol",
      "Bolesh",
      "Poplarshadow",
      "Nightbreath",
      "Sageshard",
      "Woodguard",
      "Azureshard",
      "Fostixen",
      "Cunnastohash",
      "Kirith",
      "Beylivindroth",
      "Wason",
      "Ashpetal",
      "Spiritmight",
      "Silvermane",
      "Woodguard",
      "Windgrove",
      "Xuronnescaear",
      "Serdostan",
      "Femie",
      "Druldre",
      "Geviro",
      "Farpetal",
      "Firshard",
      "Goldsmile",
      "Willowlight",
      "Birchrest",
      "Ordennent",
      "Kacovanthre",
      "Leyri",
      "Deynteco",
      "Movenn",
      "Rapidcrown",
      "Evenheart",
      "Lunarspirit",
      "Stonespell",
      "Duskfall",
      "Loler",
      "Galistres",
      "Mieldrimosil",
      "Sholla",
      "Sostrath",
      "Stonesense",
      "Sparkmind",
      "Evenmoon",
      "Diamondheel",
      "Oakgift",
      "Rilithrosel",
      "Unti",
      "Rondrosto",
      "Vaedralo",
      "Thillostaea",
      "Bronzeflower",
      "Oakencloud",
      "Amberbirth",
      "Oakengleam",
      "Eagerbreeze",
      "Xashar",
      "Wutho",
      "Huphindro",
      "Caront",
      "Thostendricas",
      "Woodhold",
      "Swiftwatcher",
      "Hazelfate",
      "Nightdream",
      "Mossbreeze",
      "Yielath",
      "Nelphomirro",
      "Goldessemo",
      "Quithent",
      "Thielthiath",
      "Fallflight",
      "Beechwalker",
      "Alderpetal",
      "Bolddream",
      "Sparksong",
      "Geldrostemash",
      "Shelisuant",
      "Shieltothre",
      "Fiani",
      "Thameroxesh",
      "Ashcrown",
      "Sagebirth",
      "Silvercrown",
      "Silversense",
      "Swiftbell",
      "Lorent",
      "Lovo",
      "Misoscos",
      "Dastele",
      "Custroro",
      "Forestwish",
      "Elmflower",
      "Sageheart",
      "Cedarspell",
      "Fogseeker",
      "Vennalke",
      "Selis",
      "Kehothronthriil",
      "Nialdroxo",
      "Gehondrindra",
      "Starfate",
      "Swiftguard",
      "Springmind",
      "Beechspirit",
      "Oceansinger",
      "Gorneler",
      "Fevastomo",
      "Lira",
      "Lurennan",
      "Bavathaxesh",
      "Cedarwhisper",
      "Elderwhisper",
      "Windgift",
      "Evenseeker",
      "Duskgrove",
      "Feltastessinn",
      "Flastothame",
      "Giphil",
      "Keynnentanni",
      "Banodrivos",
      "Ambersmile",
      "Aldersinger",
      "Mosspetal",
      "Crimsonbrook",
      "Stonegrove",
      "Usimessil",
      "Yaldroma",
      "Girerroscan",
      "Drephilphascor",
      "Cithren",
      "Eagerhold",
      "Crimsondreamer",
      "Beechwhisper",
      "Silvershine",
      "Feathersmile",
      "Innolii",
      "Tistro",
      "Ceholeno",
      "Nonixaphiil",
      "Imia",
      "Sunspark",
      "Moonheart",
      "Spiritsong",
      "Autumngleam",
      "Spiritflow",
      "Xuldoscil",
      "Oldesti",
      "Raltisal",
      "Ellath",
      "Hustassa",
      "Azureflower",
      "Sungleam",
      "Sagesense",
      "Amberblossom",
      "Elderpride",
      "Aphant",
      "Lemi",
      "Lanilkish",
      "Eldish",
      "Thostroscal",
      "Blueseeker",
      "Willowkind",
      "Gemhold",
      "Rapidmoon",
      "Emberbreeze",
      "Melphindrish",
      "Adron",
      "Aestastovont",
      "Thiacirnace",
      "Waltinexas",
      "Emberbirth",
      "Flowerspark",
      "Silentmoon",
      "Fogshadow",
      "Azureshine",
      "Wodrir",
      "Nore",
      "Rornescar",
      "Ciathish",
      "Quothromin",
      "Dewspark",
      "Poplarshadow",
      "Oceanshine",
      "Silverbirth",
      "Autumnshine",
      "Flonti",
      "Toshorar",
      "Xaelasciis",
      "Queirnestras",
      "Yapholont",
      "Ravendreamer",
      "Bluegift",
      "Shadowpath",
      "Amberbeam",
      "Crimsongaze",
      "Bistaxo",
      "Huldrann",
      "Lami",
      "Lultilinthran",
      "Faeririnn",
      "Wildwhisper",
      "Shadowmind",
      "Starlight",
      "Windbirth",
      "Mossbirth",
      "Ise",
      "Hele",
      "Thelonnoho",
      "Shorihacosh",
      "Ontama",
    ]
  },
  "Shadar-Kai": {
    first: {
      "♂": [
        "Trunnil",
        "Utes",
        "Skanal",
        "Limes",
        "Monno",
        "Triwa",
        "Nivis",
        "Lannen",
        "Ronos",
        "Rannol",
        "Lanavel",
        "Rothon",
        "Monnik",
        "Surris",
        "Muteyon",
        "Sunnin",
        "Vuval",
        "Thinnis",
        "Thuven",
        "Thaza",
        "Ugok",
        "Zorol",
        "Naedon",
        "Sizas",
        "Eevan",
        "Trinnach",
        "Sauwen",
        "Thuval",
        "Thugol",
        "Thatoyil",
        "Lare",
        "Liga",
        "Thanis",
        "Vetara",
        "Thirrel",
        "Tratoch",
        "Skimek",
        "Cetiyos",
        "Zirol",
        "Skevil",
        "Onon",
        "Cruti",
        "Linni",
        "Zivis",
        "Skurren",
        "Horas",
        "Rewan",
        "Trevok",
        "Roreras",
        "Limoch",
        "Thazon",
        "Currel",
        "Cozach",
        "Rewal",
        "Seegil",
        "Sodos",
        "Traugik",
        "Trital",
        "Roshos",
        "Vares",
        "Zoshalal",
        "Inaril",
        "Mezol",
        "Skotha",
        "Conin",
        "Edos",
        "Trigalel",
        "Sunnin",
        "Skeman",
        "Zoze",
        "Lothal",
        "Mutol",
        "Trethan",
        "Sitalol",
        "Troshores",
        "Naegol",
        "Cauza",
        "Siraras",
        "Suwo",
        "Caeva",
        "Zemen",
        "Thode",
        "Thewol",
        "Zirras",
        "Skutis",
        "Numik",
        "Trumes",
        "Zuwas",
        "Annich",
        "Rinne",
        "Nirol",
        "Trushilos",
        "Skotol",
        "Urrech",
        "Truvil",
        "Hovas",
        "Hithen",
        "Skanal",
        "Raune",
        "Rozan",
        "Zoril",
        "Ennon",
        "Critil",
        "Hodan",
        "Skeezorak",
        "Cidas",
        "Radethan",
        "Verris",
        "Thudas",
        "Cradoch",
        "Lishis",
        "Thozol",
        "Nushes",
        "Triegoyan",
        "Nizen",
        "Hozi",
        "Cito",
        "Memethel",
        "Nothon",
        "Codil",
      ],
      "♀": [
        "Thidanill",
        "Amti",
        "Nalki",
        "Elki",
        "Thentinn",
        "Intis",
        "Sivlanthe",
        "Hirlith",
        "Agvalrall",
        "Irka",
        "Evralna",
        "Ami",
        "Igvini",
        "Hemninn",
        "Ankeni",
        "Ine",
        "Helka",
        "Sandaltha",
        "Thinta",
        "Agrall",
        "Yagnedi",
        "Entethre",
        "Hignadra",
        "Nagnadi",
        "Zintenn",
        "Agrenthann",
        "Igrele",
        "Cirkava",
        "Zamal",
        "Elnali",
        "Yinnelli",
        "Hegvedi",
        "Cegnala",
        "Imnirass",
        "Rigva",
        "Yandentha",
        "Vandadrill",
        "Alkanthi",
        "Senall",
        "Rilni",
        "Imnarthil",
        "Rernelthi",
        "Irredrin",
        "Emni",
        "Alna",
        "Idredass",
        "Aldirtha",
        "Relda",
        "Illi",
        "Cigvavi",
        "Lelkanas",
        "Ilneri",
        "Imnathi",
        "Samith",
        "Imill",
        "Rervalna",
        "Nenne",
        "Inavass",
        "Vedalni",
        "Ilke",
        "Irli",
        "Annadra",
        "Arva",
        "Cilvi",
        "Sene",
        "Arvarna",
        "Amta",
        "Ilvithrall",
        "Lannath",
        "Elnallith",
        "Sernelni",
        "Nannis",
        "Levlenith",
        "Arli",
        "Arli",
        "Emass",
        "Vina",
        "Thinallenn",
        "Lidali",
        "Ennetha",
        "Thamtinn",
        "Ranki",
        "Relke",
        "Narredi",
        "Ilki",
        "Thagni",
        "Elkethi",
        "Evralthi",
        "Verkal",
        "Ragna",
        "Enkeva",
        "Imnane",
        "Cavlerthi",
        "Thagledre",
        "Yidath",
        "Anmavi",
        "Ragrith",
        "Lenmanta",
        "Nillartha",
        "Hamnenn",
        "Sagnalthe",
        "Venka",
        "Egrili",
        "Erkivi",
        "Alvadrall",
        "Cendenthith",
        "Sarlanta",
        "Idri",
        "Igli",
        "Agvani",
        "Yarreri",
        "Nellathi",
        "Nenilla",
        "Herliri",
        "Thigveli",
        "Yellale",
        "Imernas",
        "Irra",
        "Ilki",
        "Irkerthes",
        "Enass",
        "Agnelle",
        "Emna",
        "Calvilri",
        "Vankilinn",
        "Sagviva",
        "Sededra",
        "Ava",
        "Vilnethras",
        "Emtertha",
        "Sendi",
        "Ilva",
        "Avlere",
        "Alki",
        "Zalnan",
        "Egneva",
        "Zinnalri",
        "Inavis",
        "Eninthe",
        "Avrallil",
        "Ilridel",
        "Innas",
        "Zalkall",
        "Indirtha",
        "Hadrerthenn",
        "Silka",
        "Evlari",
        "Vagli",
        "Alenthal",
        "Avlethi",
        "Nillethi",
        "Amnil",
        "Thenkadi",
        "Allethri",
        "Ellalni",
        "Emnan",
        "Antirnis",
        "Lanelri",
        "Lillare",
        "Ivrena",
        "Canentha",
        "Vavedrall",
        "Inkalna",
        "Selda",
        "Zalkalni",
        "Vagreli",
        "Enadran",
        "Serni",
        "Caglinn",
        "Alle",
        "Ivlathra",
        "Larva",
        "Hagnevin",
        "Yenkethra",
        "Sigli",
        "Arla",
        "Siminn",
        "Heneval",
        "Imtilli",
        "Nendinn",
      ]
    }
  },
  "Eladrin": {
    first: {
      "♂": [
        "Galtihr",
        "Quadan",
        "Xancorin",
        "Haurian",
        "Uririan",
        "Arasirak",
        "Galliss",
        "Trindon",
        "Yoruvir",
        "Relfros",
        "Galxeral",
        "Jorkul",
        "Vethuvir",
        "Jorpeiros",
        "Corcorin",
        "Iraiver",
        "Tavurian",
        "Erevan",
        "Galinheros",
        "Mincorin",
        "Lanrion",
        "Narkbin",
        "Berprath",
        "Yorratra",
        "Daokas",
        "Vethwirn",
        "Quovis",
        "Wruglias",
        "Nalcorin",
        "Daheros",
        "Mardartis",
        "Farworn",
        "Ereiver",
        "Iravan",
        "Ereiver",
        "Wrugdarai",
        "Haikul",
        "Mincorin",
        "Berkul",
        "Elroratra",
        "Minurian",
        "Braniver",
        "Paemil",
        "Arapeiros",
        "Wruglias",
        "Galdartis",
        "Brantorin",
        "Irasirak",
        "Horuvir",
        "Zorxeral",
        "Otirfis",
        "Krureth",
        "Otirheros",
        "Dayecard",
        "Vethwirn",
        "Trinvan",
        "Fylaiver",
        "Waluvir",
        "Lanzion",
        "Branzeiros",
        "Sarntihr",
        "Vicxeral",
        "Farykos",
        "Narkiver",
        "Zorkul",
        "Xanrion",
        "Xanemin",
        "Eregeon",
        "Crumendi",
        "Uriheros",
        "Minbin",
        "Yornis",
        "Waldartis",
        "Hatihr",
        "Yanratra",
        "Aranfis",
        "Jordon",
        "Harian",
        "Narkzion",
        "Maraster",
        "Sarnneiros",
        "Jormorn",
        "Sarngeon",
        "Lanaster",
        "Yanster",
        "Brankris",
        "Arancorin",
        "Panreth",
        "Sarnkul",
        "Marmil",
        "Zendartis",
        "Yorratra",
        "Branurian",
        "Hortihr",
        "Kuofros",
        "Jamendi",
        "Arantorin",
        "Uritihr",
        "Fylalias",
        "Zorvan",
        "Relwirn",
        "Pankul",
        "Reliver",
        "Riartihr",
        "Uridan",
        "Sovebaver",
        "Vicurian",
        "Imratra",
        "Otirneiros",
        "Brangeon",
        "Licemin",
        "Lanmorn",
        "Farmendi",
        "Galinoros",
        "Fyladartis",
        "Corneiros",
        "Galinxis",
        "Farxis",
        "Xanbaver",
        "Dakul",
        "Zendon",
        "Pandartis",
        "Tavikul",
        "Minoros",
        "Vicrion",
        "Licerta",
        "Olaikul",
        "Zorneiros",
        "Yoriver",
        "Relcard",
        "Haerta",
        "Nalokas",
        "Berheros",
        "Walprath",
        "Galurian",
        "Arafros",
        "Pankris",
        "Licokas",
        "Iraliss",
        "Arabin",
        "Erefis",
        "Reldan",
        "Erefis",
        "Arafros",
        "Mindon",
        "Galindon",
        "Farvis",
        "Panxeral",
        "Urivan",
        "Riarratra",
      ],
      "♀": [
        "Enamynis",
        "Jarcelle",
        "Jelensys",
        "Ulprana",
        "Prysviryn",
        "Mezora",
        "Oriparys",
        "Caethaea",
        "Zensys",
        "Trixana",
        "Anrastra",
        "Fanprana",
        "Anaswyse",
        "Durcelle",
        "Nerivara",
        "Ylladys",
        "Prysviryn",
        "Quegrys",
        "Anasxana",
        "Lyvxis",
        "Durmiris",
        "Magkyrath",
        "Durtora",
        "Hylshana",
        "Gylviryn",
        "Orihanna",
        "Illaxana",
        "Riparys",
        "Pryszora",
        "Urarastra",
        "Eilmeiv",
        "Henqis",
        "Sharaste",
        "Hyltrianna",
        "Lyvfaera",
        "Anasmeiv",
        "Olshana",
        "Velayeira",
        "Eildys",
        "Kaithaea",
        "Wrecena",
        "Illahanna",
        "Lesbynn",
        "Lyvzora",
        "Lesdiel",
        "Hylkyrath",
        "Kaixana",
        "Olzane",
        "Henvara",
        "Uracena",
        "Gylyana",
        "Prysvara",
        "Henyana",
        "Quedys",
        "Befyra",
        "Hylmiris",
        "Zenraste",
        "Roldys",
        "Eilgrys",
        "Trikyrath",
        "Prysparys",
        "Theilynna",
        "Gylsanna",
        "Wrevara",
        "Shaqirith",
        "Wreprana",
        "Vaqis",
        "Yllawyse",
        "Magdiel",
        "Uramiris",
        "Shafaera",
        "Qilenna",
        "Olfaera",
        "Trinairra",
        "Cylmynis",
        "Cylmiris",
        "Irecahne",
        "Tridiel",
        "Shaqirith",
        "Cylcelle",
        "Orimeiv",
        "Anzane",
        "Theirynna",
        "Jarmeiv",
        "Gylfaera",
        "Naitora",
        "Eillenae",
        "Quelynna",
        "Metrianna",
        "Henglyn",
        "Kaicaryn",
        "Jarparys",
        "Triwena",
        "Pisanna",
        "Uraxis",
        "Besanna",
        "Hylvyre",
        "Yllacaryn",
        "Lesgrys",
        "Faekiries",
        "Theikyrath",
        "Lyvlynna",
        "Neritrianna",
        "Driskyrath",
        "Qivyre",
        "Britrianna",
        "Uralyn",
        "Durcahne",
        "Duryana",
        "Bebis",
        "Fanparys",
        "Sacaryn",
        "Wessanna",
        "Fangrys",
        "Alwena",
        "Rolqirith",
        "Neriqis",
        "Vamynis",
        "Durneth",
        "Naitrianna",
        "Bebis",
        "Brilenna",
        "Hencena",
        "Zenqis",
        "Quevyre",
        "Naizora",
        "Iremiris",
        "Cylglyn",
        "Ollenae",
        "Ollynna",
        "Pryskiries",
        "Kaidys",
        "Wrelynna",
        "Magrastra",
        "Durbis",
        "Hylneth",
        "Gylqirith",
        "Gruneth",
        "Henfaera",
        "Ireglyn",
        "Henzane",
        "Gylcena",
        "Almeiv",
        "Jarzora",
        "Cyllyn",
        "Wrevara",
        "Jarcelle",
        "Fanwena",
        "Zenlynna",
        "Alyeira",
        "Maglyn",
        "Pryssanna",
        "Fanneth",
        "Britora",
        "Vaneth",
        "Vaxis",
        "Yllaprana",
        "Wessys",
        "Cylrastra",
        "Magrastra",
        "Jarparys",
        "Wreqirith",
        "Belyn",
        "Memiris",
        "Illaglyn",
        "Leshanna",
        "Trifyra",
        "Enasys",
        "Anastora",
        "Eilnairra",
        "Gylnairra",
        "Vanairra",
        "Triprana",
        "Ricena",
        "Shaparys",
        "Faeqis",
        "Theiparys",
        "Fancahne",
        "Ireraste",
        "Bridys",
      ]
    }
  },
  "Drow": {
    first: {
      "♂": [
        "Szinton",
        "Wehlgloth",
        "Zekdriirn",
        "Sornhrae",
        "Jivthel",
        "Vagred",
        "Minogred",
        "Minoryn",
        "Ghaundar",
        "Dhuunyl",
        "Nilafein",
        "Duagtrin",
        "Duagtar",
        "Chaszas",
        "Jhaammeth",
        "Jeglil",
        "Jaezsorin",
        "Yazrini'th",
        "Molvayas",
        "Dantrag",
        "Baraonar",
        "Uhlsyln",
        "Gelirahc",
        "Goyrd",
        "Torrelkos",
        "Seldnaste",
        "Ornaruel",
        "Belros",
        "Kluthruel",
        "Micarlin",
        "Tebzen",
        "Wehleth",
        "Nadimar",
        "Tathdax",
        "Danverin",
        "Yazlan",
        "Seldshin",
        "Dikoph",
        "Imbros",
        "Chakos",
        "Rhylinyon",
        "Lesroos",
        "Solgloth",
        "Zekantar",
        "Lesaogred",
        "Xundath",
        "Jaezvir",
        "Quennor",
        "Welverin",
        "Belaern",
        "Szinerd",
        "Drizdar",
        "Antas",
        "Szind",
        "Quevlas",
        "Kronlyss",
        "Zaknamyn",
        "Lesaoverin",
        "Nilonim",
        "Zyn",
        "Orgollor",
        "Tluthantar",
        "Wodzar",
        "Elaugal",
        "Vlonrann",
        "Yuimnor",
        "Xarius",
        "Vanyl",
        "Krondorl",
        "Vhurdaer",
      ],
      "♀": [
        "Zebeyice",
        "Vasril",
        "Eclavriia",
        "Molstra",
        "Guva",
        "Brorle",
        "Luania",
        "Charirala",
        "Bautha",
        "Qilue",
        "Sinayne",
        "Jhaelvayas",
        "Auncice",
        "Moleyl",
        "Saslyth",
        "Jhutha",
        "Shurtlara",
        "Pellaniss",
        "Greyanna",
        "Pellanistra",
        "Dirzvyrae",
        "Zarcice",
        "Shi'ntana",
        "Illiamiara",
        "Mez'Bardriira",
        "Xullzina",
        "Sastana",
        "Taliira",
        "Schezalle",
        "Menzoberra",
        "Talfryn",
        "Yasva",
        "Nhilrae",
        "Inaer",
        "Shurnel",
        "Charifaere",
        "Shulvallka",
        "Faepassa",
        "Ahlysaaria",
        "Saradreza",
        "Ilphnitra",
        "Zesbaste",
        "Mayaste",
        "Trielnitra",
        "Mazira",
        "Talalue",
        "Z'resryl",
        "Besbreena",
        "T'risstree",
        "Jhaelryna",
        "Quavshalee",
        "Etheisstra",
        "Chessstra",
        "Qilna",
        "Besva",
        "Shulvallzara",
        "Burstree",
        "Charirene",
        "Xullrae",
        "Angaste",
        "Ethewae",
        "Greyuque",
        "Janshalee",
        "Umraeymma",
        "Mez'Barma",
        "Ilmdra",
        "Brigansintra",
        "Shiniss",
        "Qilue",
        "Zilvra",
      ]
    },
    last: [
      "Everhana",
      "Kenduis",
      "Teken'neld",
      "Kenndar",
      "Olabar",
      "Keteeryl",
      "Blundoavae",
      "Hyluan",
      "Dyrr",
      "Dhunnyl",
      "Eilsafin",
      "Hlat'tar",
      "Melafin",
      "Oussviir",
      "Tlin'ath",
      "Tanor'Thossz",
      "Cormryrr",
      "Abenna",
      "Khalazza",
      "Waeglossz",
      "Desek",
      "Derret",
      "Oussrret",
      "Kenund",
      "Taulur",
      "Mizzronrae",
      "Olagh",
      "Olune",
      "Elpragh",
      "Elpragh",
      "Claddrahel",
      "Maeviir",
      "Hlaervs",
      "Kilneld",
      "Lhalion'lyr",
      "Nael",
      "Zolaeir",
      "Omraryd",
      "Dhunnyl",
      "T'sarran",
      "Godetyl",
      "Auvryndar",
      "Fret'tar",
      "Myar",
      "Norzza",
      "Dinond",
      "Haerth",
      "Filistyn",
      "Diliriy",
      "Rrostarr",
      "Godeval",
      "Freval",
      "Aleanund",
      "Claddrret",
      "Filabar",
      "Tarr",
      "Vrammin",
      "Tlintossz",
      "Illykur",
      "Omriwin",
      "Kilt'tar",
      "Fret'tar",
      "Myrret",
      "Eilst'tar",
      "Telass",
      "Vririy",
      "Chaulssion'lyr",
      "Dhunneztice",
      "Abbylan",
      "T'orgh",
      "Arkenrret",
      "Hlaund",
      "Auvryghym",
      "Teken'arn",
      "Abbar",
      "Ilalach",
      "Cobaen",
      "Blaeriir",
      "Yauthlo",
      "Dalael",
      "Alemtor",
      "Aleanlyl",
      "Maeneld",
      "Orlyett",
      "Mlezzis",
      "Nirarran",
      "Vraerth",
      "Rhomdiiryn",
      "Glannath",
      "Mlezziir",
      "Arkenneld",
      "Alearn",
      "Teken'duis",
      "Rilynndar",
      "Rhomdorgh",
      "Rhomdoavae",
      "Tanor'Thagh",
      "Ichach",
      "Daevion'lyr",
      "Vrammyr",
      "Hlaana",
      "Aleanval",
      "Melrret",
      "Myzynge",
      "Duskrabban",
      "T'siiryn",
      "Tarn",
      "Waeglykur",
      "Lueltar",
      "Zauviir",
      "Teken'rahel",
      "Hlaghym",
      "Rilynani",
      "Teken'lyl",
      "Tlintagh",
      "Quavistyn",
      "Lueltass",
      "Hylala",
      "Omriwin",
      "Cormrael",
      "Hun'ar",
      "Alesek",
      "Everhund",
      "Claddund",
      "Lueltaerth",
      "Lueltein",
      "Ichiirn",
      "Kront'tyr",
      "Tlintarn",
      "Yril'Lysaen",
      "Aleghym",
      "Torund",
      "Derret",
      "Godeghym",
      "Rrostis",
      "Haeir",
      "Olear",
      "Mizzrylan",
      "Jhalavar",
      "Zauviir",

    ]
  },
  "Half Elf": {
    first: {
      "♂": [
        "Vanparin",
        "Xavenas",
        "Xanben",
        "Sylvoril",
        "Norelor",
        "Ianfaelor",
        "Craynor",
        "Leoavor",
        "Yorgotin",
        "Yenfinas",
        "Jamkoris",
        "Falfinas",
        "Osparin",
        "Walyeras",
        "Davenas",
        "Zylsariph",
        "Sylmorn",
        "Graben",
        "Elneiros",
        "Panynor",
        "Xavphanis",
        "Iloneiros",
        "Barzaphir",
        "Davelor",
        "Grareak",
        "Corcoril",
        "Rafenas",
        "Barwarith",
        "Ilostaer",
        "Lortumil",
        "Sylborin",
        "Uanfaelor",
        "Vicenas",
        "Vicminar",
        "Falros",
        "Jamxian",
        "Kevdithas",
        "Wilvalor",
        "Ostorin",
        "Riparin",
        "Mardithas",
        "Xanfinas",
        "Eldeyr",
        "Meiphanis",
        "Vanben",
        "Marneak",
        "Horreak",
        "Quoxiron",
        "Orifaelor",
        "Graqarim",
        "Yenros",
        "Yorfinas",
        "Rafreak",
        "Jamcraes",
        "Tyrlanann",
        "Kevneiros",
        "Gramorn",
        "Ulborin",
        "Bargotin",
        "Craneak",
        "Rixiron",
        "Syllanann",
        "Uanxian",
        "Eirros",
        "Xavcraes",
        "Coravor",
        "Ianfaerd",
        "Vannan",
        "Alneiros",
        "Rafovar",
        "Zanreak",
        "Ilohomin",
        "Leoril",
        "Sarkas",
        "Gratorin",
        "Leokas",
        "Belros",
        "Traxian",
        "Falenas",
        "Osvalor",
        "Iangretor",
        "Xanvalor",
        "Norelor",
        "Meihomin",
        "Horcoril",
        "Lortumil",
        "Xannan",
        "Vanzaphir",
        "Eirphanis",
        "Tyrneak",
        "Alvalor",
        "Falorin",
        "Zylril",
        "Corenas",
        "Vicelor",
        "Xavdeyr",
        "Leoxian",
        "Zyltorin",
        "Yenfaerd",
        "Ianavor",
        "Riminar",
        "Nororin",
        "Ianben",
        "Zylynor",
        "Petelor",
        "Vanhorn",
        "Elqarim",
        "Graneiros",
        "Trafyr",
        "Quoparin",
        "Horfaerd",
        "Osgotin",
        "Kevovar",
        "Zanril",
        "Wilyeras",
        "Jamenas",
        "Alstaer",
        "Traros",
        "Osword",
        "Ellanann",
        "Friltorin",
        "Rafgretor",
        "Xanfaerd",
        "Raftumil",
        "Quotorin",
        "Vicphanis",
        "Frilynor",
        "Zylqinor",
        "Petreak",
        "Hordeyr",
        "Xavwarith",
        "Vicgretor",
        "Oscraes",
        "Ritumil",
        "Quoynor",
        "Zanmorn",
        "Leofaerd",
        "Dorneak",
        "Ulborin",
        "Quoorin",
        "Jamtumil",
        "Ilogotin",
        "Frilgretor",
        "Kevreak",
        "Iloelor",
        "Xanxiron",
        "Uanfinas",
        "Frilkas",
        "Barqinor",
        "Wallumin",
        "Ulros",
        "Yorovar",
        "Zylorin",
        "Walavor",
        "Dorgotin",
        "Norkoris",
        "Kevfaerd",
        "Ilocraes",
        "Yorril",
        "Baravor",
        "Arokoris",
        "Petneiros",
        "Niltorin",
        "Yenreak",
        "Uanxian",
        "Zylelor",
        "Frilvalor",
        "Marynor",
        "Nillanann",
        "Vansariph",
        "Lorqinor",
        "Sylros",
        "Oslamir",
        "Wilsariph",
        "Petben",
        "Alynor",
        "Zandithas",
        "Tyryeras",
        "Davtumil",
        "Panqarim",
        "Ilozaphir",
        "Petqarim",
        "Riyeras",
        "Ianfinas",
        "Sarneak",
        "Quohorn",
        "Loravor",
        "Eirlanann",
        "Yorxian",
        "Jamgretor",
        "Sarfyr",
        "Falros",
        "Petovar",
        "Yenfinas",
        "Nilkas",
        "Algretor",
        "Zanfaelor",
        "Arocoril",
        "Uannan",
        "Horlumin",
      ],
      "♀": [
        "Alyphira",
        "Saffaen",
        "Cozira",
        "Ophifine",
        "Vylmalis",
        "Mahophe",
        "Mafine",
        "Prithana",
        "Elwalyn",
        "Delmythe",
        "Hofaen",
        "Zelyra",
        "Marzira",
        "Jenlynn",
        "Kriaerys",
        "Thersys",
        "Xilrila",
        "Delbwynn",
        "Tylmalis",
        "Halyqarin",
        "Safphira",
        "Neriwaris",
        "Zellahne",
        "Winsys",
        "Mastine",
        "Lesseris",
        "Yllkilia",
        "Resgwynn",
        "Vencharis",
        "Reltihne",
        "Brendove",
        "Xyrcharis",
        "Quexipha",
        "Mardiane",
        "Olbellis",
        "Elimythe",
        "Kysfaen",
        "Maviel",
        "Lorafine",
        "Alythana",
        "Elyra",
        "Cofaen",
        "Darnoa",
        "Winstine",
        "Tylrora",
        "Ophimalis",
        "Cargalyn",
        "Jenmae",
        "Zelelor",
        "Xyrnalore",
        "Galwaris",
        "Sylnys",
        "Delkaen",
        "Prithana",
        "Loracerys",
        "Delstine",
        "Aluzira",
        "Xilnoa",
        "Brenxipha",
        "Rellahne",
        "Ileanys",
        "Prisys",
        "Relqwyn",
        "Maremythe",
        "Ollahne",
        "Lesgalyn",
        "Kysxaris",
        "Thertrana",
        "Gifnalore",
        "Jenkaen",
        "Mamae",
        "Qiviel",
        "Urihana",
        "Conoa",
        "Dartrana",
        "Delenyphe",
        "Fhabellis",
        "Tylwaris",
        "Unatrana",
        "Zintrana",
        "Cokaen",
        "Wincharis",
        "Halystine",
        "Saelbwynn",
        "Qithana",
        "Jilkaen",
        "Mareqwyn",
        "Hoaerys",
        "Magwynn",
        "Loragwynn",
        "Gallynn",
        "Bynnalore",
        "Sylxipha",
        "Elcharis",
        "Carona",
        "Ophiphira",
        "Phayebellis",
        "Mareaerys",
        "Kyshana",
        "Irokaen",
        "Unadiane",
        "Tylkaen",
        "Loratrana",
        "Iroxaris",
        "Vylbwynn",
        "Phayeseris",
        "Ileyra",
        "Safqarin",
        "Lesgalyn",
        "Safseris",
        "Ilefaen",
        "Brennoa",
        "Caryaries",
        "Kriqwyn",
        "Urimae",
        "Thergalyn",
        "Cartrana",
        "Faetrana",
        "Costine",
        "Fhaphira",
        "Zinvyre",
        "Sylviel",
        "Unastine",
        "Winfaen",
        "Lestihne",
        "Elphira",
        "Hoanys",
        "Unaona",
        "Sylqwyn",
        "Eliseris",
        "Ophinoa",
        "Halystine",
        "Lesmalis",
        "Saelnys",
        "Queenyphe",
        "Codove",
        "Venphira",
        "Bynqwyn",
        "Safpisys",
        "Halywaris",
        "Therqarin",
        "Hophira",
        "Zelgalyn",
        "Carnys",
        "Iroyaries",
        "Eliviel",
        "Arnalore",
        "Elviel",
        "Jilhophe",
        "Therpisys",
        "Lorakilia",
        "Faefine",
        "Bynqarin",
        "Irokaen",
        "Neristine",
        "Galkilia",
        "Bynnoa",
        "Marenoa",
        "Aluphira",
        "Jenmythe",
        "Qigalyn",
        "Nerihana",
        "Theranys",
        "Zelenyphe",
        "Cosys",
        "Kysphira",
        "Mayaries",
        "Delqarin",
        "Faeelor",
        "Fhacharis",
        "Zinthana",
        "Irodove",
        "Reslahne",
        "Saelenyphe",
        "Ironalore",
        "Lesnoa",
        "Unalahne",
        "Dartheris",
        "Relvyre",
        "Carzira",
        "Carmae",
        "Darzenya",
        "Jilqwyn",
        "Darpisys",
        "Lesvyre",
        "Eldiane",
        "Vylzenya",
        "Tylthana",
        "Tyldove",
        "Prienyphe",
        "Sylxaris",
        "Bynmythe",
        "Quefaen",
        "Vennoa",
        "Magalyn",
        "Lesnys",
        "Dargwynn",
        "Tylvyre",
        "Marbwynn",
        "Syltheris",
        "Alygwynn",
        "Marvyre",
        "Sylyaries",
        "Darfine",
        "Xyrcerys",
        "Prifaen",
        "Aluxaris",
        "Kridove",
        "Halygalyn",
        "Phayewaris",
      ]
    }
  },
  "Half Orc": {
    first: {
      "♂": [
        "Okeglar",
        "Nagak",
        "Makamar",
        "Ukad",
        "Kraurk",
        "Thurabark",
        "Ronaarash",
        "Ronaark",
        "Arnark",
        "Agugur",
        "Ogguubak",
        "Mogodall",
        "Garumar",
        "Ullabur",
        "Zusurth",
        "Hurerall",
        "Mahlunars",
        "Ukridar",
        "Thrark",
        "Trukugash",
        "Hurogul",
        "Mahlad",
        "Zurogash",
        "Kamugak",
        "Ugabar",
        "Dugur",
        "Tharonar",
        "Hogomir",
        "Makurash",
        "Agobar",
        "Mahlan",
        "Drudash",
        "Morosk",
        "Zugall",
        "Kiladim",
        "Zasomarsh",
        "Ukurth",
        "Zevurth",
        "Cursh",
        "Zaeburk",
        "Trukudar",
        "Grimorim",
        "Urthudur",
        "Muraogark",
        "Urthurall",
        "Grumutar",
        "Harimar",
        "Ogueg",
        "Hururth",
        "Arobash",
        "Brakirth",
        "Duremak",
        "Baremur",
        "Makanur",
        "Marirall",
        "Mugadar",
        "Rekumash",
        "Crarsh",
        "Okomak",
        "Gragul",
        "Uriburk",
        "Cruzur",
        "Thramarsh",
        "Drezur",
        "Lumoglar",
        "Atrag",
        "Ukrarak",
        "Ureg",
        "Tharegall",
        "Aggaz",
        "Mukigar",
        "Kurabur",
        "Korunur",
        "Grumodur",
        "Muraibak",
        "Haregall",
        "Arnubar",
        "Garag",
        "Kraigak",
        "Urtrabash",
        "Korerimm",
        "Urthurak",
        "Kraag",
        "Kruadall",
        "Brugar",
        "Zoran",
        "Tharegur",
        "Honomarsh",
        "Gulibark",
        "Kulamash",
        "Themash",
        "Okirk",
        "Guloburk",
        "Trakez",
        "Boregark",
        "Gorunar",
        "Aggugash",
        "Ullanur",
        "Muraerimm",
        "Zuradar",
      ],
      "♀":[
        "Roda",
        "Shugu",
        "Kitar",
        "Gajorook",
        "Samida",
        "Sunenir",
        "Mane",
        "Semorel",
        "Kuragh",
        "Sametir",
        "Lagutar",
        "Merashi",
        "Ekizira",
        "Katugar",
        "Umodur",
        "Satar",
        "Garagum",
        "Semedar",
        "Gijigur",
        "Rashadur",
        "Puyizi",
        "Umewar",
        "Namur",
        "Gryad",
        "Ekogar",
        "Zonogh",
        "Morutah",
        "Samizi",
        "Rasugur",
        "Kutazar",
        "Shonar",
        "Tumusha",
        "Lagotah",
        "Tumaki",
        "Sogri",
        "Olotur",
        "Garotur",
        "Kirash",
        "Aremi",
        "Vanida",
        "Sinemur",
        "Ketanur",
        "Zunash",
        "Vanidur",
        "Samoz",
        "Temime",
        "Puyomur",
        "Felirel",
        "Rohkonar",
        "Kiromi",
        "Morizara",
        "Ekush",
        "Falaz",
        "Gijati",
        "Manar",
        "Tamitah",
        "Vanotir",
        "Olegum",
        "Tamigume",
        "Sanatah",
        "Gyniner",
        "Folatar",
        "Tumarel",
        "Kutazur",
        "Ekiki",
        "Broz",
        "Keruda",
        "Uriral",
        "Sanoti",
        "Gorati",
      ],
    }
  },
  "Tiefling": {
    first: {
      "♂": [
        "Morcius",
        "Urixire",
        "Roldos",
        "Kaiichar",
        "Garmong",
        "Mavdos",
        "Rolus",
        "Malexik",
        "Sirlius",
        "Dammeros",
        "Kaakos",
        "Thyemon",
        "Sharakas",
        "Casadius",
        "Morakas",
        "Garxius",
        "Thyneron",
        "Dharxik",
        "Zerrus",
        "Carlyre",
        "Zherthus",
        "Damrakir",
        "Iaxus",
        "Zhermong",
        "Baril",
        "Karrias",
        "Sirros",
        "Karxire",
        "Zhervir",
        "Ozxes",
        "Garchar",
        "Barxus",
        "Kyrakas",
        "Ialyre",
        "Nephrus",
        "Reilius",
        "Amris",
        "Shareus",
        "Shamir",
        "Thevir",
        "Kaixius",
        "Ebvenom",
        "Casmeros",
        "Kyxire",
        "Carxus",
        "Xarxikas",
        "Arxius",
        "Morichar",
        "Horrias",
        "Mavxus",
        "Urxikas",
        "Damira",
        "Zerxire",
        "Reira",
        "Sirilius",
        "Sharon",
        "Iaakas",
        "Ralrakas",
        "Garxius",
        "Amvius",
        "Morrakas",
        "Barthor",
        "Zerlech",
        "Amcius",
        "Salrai",
        "Arkcius",
        "Carlius",
        "Zherreus",
        "Kyrai",
        "Urinon",
        "Malecis",
        "Shaxus",
        "Ozmenos",
        "Barrius",
        "Kavir",
        "Kareus",
        "Thelech",
        "Lokechar",
        "Zarris",
        "Kyemon",
        "Kosthos",
        "Zorrakir",
        "Zorxius",
        "Mavxius",
        "Dammeros",
        "Sirnon",
        "Casmir",
        "Xaril",
        "Valris",
        "Ekvius",
      ],
      "♀": [
        "Levza",
        "Yuxori",
        "Agnehiri",
        "Zeyola",
        "Zaloth",
        "Misspira",
        "Mislith",
        "Zaigrea",
        "Nethwure",
        "Zaigoria",
        "Brigrea",
        "Lilcria",
        "Royis",
        "Rolypsis",
        "Dorrissa",
        "Anilista",
        "Lehala",
        "Natlith",
        "Aradoris",
        "Inimine",
        "Phelies",
        "Yayis",
        "Sharissa",
        "Afseis",
        "Frisolis",
        "Maqine",
        "Dakaria",
        "Crefaris",
        "Inixibis",
        "Marspira",
        "Pesxibis",
        "Zaiwala",
        "Sarhala",
        "Mithyis",
        "Nawala",
        "Levvine",
        "Seiribis",
        "Arialypsis",
        "Affirith",
        "Frixibis",
        "Pesmine",
        "Ridani",
        "Dorspira",
        "Hisnise",
        "Inicria",
        "Zetish",
        "Quuphis",
        "Nethyola",
        "Inimine",
        "Innirith",
        "Arilia",
        "Kalvari",
        "Ariyis",
        "Afpione",
        "Levhiri",
        "Hisrissa",
        "Levmine",
        "Ealista",
        "Rilies",
        "Valyis",
        "Afborys",
        "Qucyra",
        "Mithcria",
        "Yacria",
        "Misfirith",
        "Natmeia",
        "Fribis",
        "Miszes",
        "Mapunith",
        "Velnise",
      ]
    }
  },
  "Dwarf": {
    first: {
      "♂": [
        "Thomyl",
        "Ragnus",
        "Dalmur",
        "Brumnir",
        "Morryl",
        "Tharnom",
        "Garthran",
        "Barmond",
        "Bhalmur",
        "Bandir",
        "Dulgus",
        "Harryl",
        "Urmmek",
        "Hurgus",
        "Hjulman",
        "Gimmin",
        "Ranadin",
        "Thorrak",
        "Gulmand",
        "Grantharm",
        "Thulgrom",
        "Dulgrum",
        "Gralrom",
        "Umkuhm",
        "Ebdain",
        "Brumrigg",
        "Kromdren",
        "Bheldan",
        "Grenrig",
        "Hjaldrum",
        "Malnus",
        "Gargran",
        "Admus",
        "Vondar",
        "Germyl",
        "Maldek",
        "Brommin",
        "Galron",
        "Muirrum",
        "Thodram",
        "Farkohm",
        "Malthrum",
        "Bhalnum",
        "Ranmin",
        "Farmin",
        "Farron",
        "Grydrom",
        "Bunmir",
        "Kargrum",
        "Mordur",
        "Thulrus",
        "Hjalgurn",
        "Granmund",
        "Thuldrak",
        "Bundrak",
        "Galdohr",
        "Thordar",
        "Gralkohm",
        "Armrus",
        "Vondohr",
        "Horadin",
        "Tyryl",
        "Regdrak",
        "Graldain",
        "Gimrak",
        "Gramnam",
        "Grymor",
        "Ranmir",
        "Krumadin",
        "Daldan",
        "Malron",
        "Dargus",
        "Harnyl",
        "Grildir",
        "Hjulnus",
        "Bergurn",
        "Thargurn",
        "Guldrak",
        "Thordal",
        "Dulgrun",
        "Thelnur",
        "Germyr",
        "Grildrak",
        "Thurnar",
        "Grenmek",
        "Muirron",
        "Daergran",
        "Ambrek",
        "Murren",
        "Gramdrus",
        "Brommund",
        "Armryl",
        "Murgrom",
        "Hurnir",
        "Ragram",
        "Kramryl",
        "Armrak",
        "Gardain",
        "Adkum",
        "Urmdus",
        "Thyrom",
        "Grydrak",
        "Meldram",
        "Addrak",
        "Bartharn",
        "Baernam",
        "Thermar",
        "Melrus",
        "Grennur",
        "Karkuhm",
      ],
      "♀": [
        "Tishbelle",
        "Bylleris",
        "Ingnar",
        "Braenvan",
        "Lessglian",
        "Lysslinn",
        "Gymrielle",
        "Jinrin",
        "Brenniss",
        "Naermora",
        "Brallelin",
        "Brenglia",
        "Brallelinn",
        "Leslyn",
        "Einnar",
        "Karva",
        "Lysselle",
        "Jenmura",
        "Inglyn",
        "Gwynnyss",
        "Jynnas",
        "Ranlin",
        "Argwyn",
        "Ryndeth",
        "Reynnora",
        "Tyshras",
        "Anla",
        "Dimnura",
        "Bryldeth",
        "Misgiel",
        "Kaitnas",
        "Dearva",
        "Maernip",
        "Misthel",
        "Bonsora",
        "Karras",
        "Naerlin",
        "Gwynres",
        "Myrmyla",
        "Gymdille",
        "Lasla",
        "Bondora",
        "Bralleil",
        "Dearrielle",
        "Brillyl",
        "Brolselle",
        "Lyssris",
        "Elnura",
        "Ansyl",
        "Lyslen",
        "Bralla",
        "Bylledeth",
        "Brylras",
        "Solvan",
        "Brytria",
        "Leslinn",
        "Brylledielle",
        "Katnis",
        "Edva",
        "Kathdielle",
        "Sollinn",
        "Dimla",
        "Tyshras",
        "Eddryn",
        "Brallesora",
        "Baermyl",
        "Mistglia",
        "Brendelle",
        "Raenmyl",
        "Sarniss",
        "Lassmera",
        "Brulnyl",
        "Katmora",
        "Ranlin",
        "Ranmora",
        "Lyssres",
        "Lysnera",
        "Tizmyl",
        "Arwynn",
        "Byldielle",
        "Jinlinn",
        "Barryn",
        "Nesnera",
        "Mysdielle",
        "Lyesris",
        "Gwyntin",
        "Katbelle",
        "Gwanvian",
        "Tizthel",
        "Germera",
        "Brynmyl",
        "Maevmyla",
        "Brillerielle",
        "Dimdelle",
        "Nysnyl",
        "Belniss",
        "Barmyl",
        "Reynlin",
        "Karnyss",
        "Bralledielle",
      ]
    },
    last: [
      "Darkblade",
      "Kindgrace",
      "Drunksong",
      "Smugforce",
      "Broodhand",
      "Brumnirr",
      "Brudahr",
      "Bradonok",
      "Gorthevaln",
      "Dolbrirt",
      "Lastdelver",
      "Moltenfists",
      "Bloodback",
      "Goblinbeard",
      "Blackhelm",
      "Braezzuvak",
      "Turigark",
      "Cakerk",
      "Bongegak",
      "Brokan",
      "Steeltale",
      "Everpast",
      "Starkflight",
      "Frostbleeder",
      "Madfist",
      "Brelbrivirr",
      "Fobregihk",
      "Strongurr",
      "Glardavarr",
      "Bedunar",
      "Frostrock",
      "Bloodbelch",
      "Lastkith",
      "Thundereyes",
      "Goldenfall",
      "Lullahr",
      "Famnoln",
      "Muzzar",
      "Strotganem",
      "Tardan",
      "Stronghandle",
      "Burrowreach",
      "Drunkreach",
      "Goblinheart",
      "Firstkind",
      "Fungehk",
      "Cekevam",
      "Broldihark",
      "Throlbruhork",
      "Rarthiheg",
      "Truepass",
      "Boldhammer",
      "Keenbelch",
      "Brightmask",
      "Brawnbeard",
      "Gafdart",
      "Gunguvun",
      "Hurahk",
      "Mekurt",
      "Thronhakonn",
      "Trollfront",
      "Mightgrace",
      "Goldkin",
      "Truehandle",
      "Blackeye",
      "Funguherk",
      "Latgikuck",
      "Tardenn",
      "Merdahort",
      "Thruzzogur",
      "Coldbraid",
      "Boulderforce",
      "Bouldertankard",
      "Trueflight",
      "Battlehead",
      "Drangug",
      "Tuthgenurr",
      "Grerdevag",
      "Grureck",
      "Brazzem",
      "Goldstrike",
      "Frostward",
      "Truefury",
      "Deeppast",
      "Boulderaxe",
      "Guhirinn",
      "Burdahr",
      "Caekark",
      "Defden",
      "Horukick",
      "Mountainpride",
      "Flinttankard",
      "Truekin",
      "Evenboots",
      "Goldenkind",
      "Fatgikom",
      "Braekohom",
      "Faengirt",
      "Rohugan",
      "Daenkart",
      "Drunkgrip",
      "Smugroar",
      "Goldshaper",
      "Wildboot",
      "Darkblood",
      "Mozzahr",
      "Hatgarehk",
      "Cankakort",
      "Gaengurk",
      "Drezzir",
      "Firstbreaker",
      "Frozendelver",
      "Firsteye",
      "Mightback",
      "Starkgrip",
      "Dobravahr",
      "Grallorahr",
      "Grukanag",
      "Brurthirr",
      "Glomnevaln",
      "Fireforge",
      "Silverdelver",
      "Goldenforce",
      "Loudboots",
      "Coldbreath",
      "Drengenn",
      "Glurthark",
      "Lalbrur",
      "Dallinert",
      "Drehivon",
      "Hardkin",
      "Boulderrest",
      "Hammertale",
      "Drunktankard",
      "Burrowroar",
      "Dokaln",
      "Hezzarr",
      "Tengokir",
      "Lehahr",
      "Cebrirt",
      "Halfback",
      "Steeleye",
      "Trollbottom",
      "Mightdelver",
      "Smugstand",
      "Faelbrekerr",
      "Glakihark",
      "Grabrigak",
      "Haerthaln",
      "Throrder",
      "Hardward",
      "Coldtankard",
      "Mightbrow",
      "Stormrest",
      "Grayback",
      "Moreck",
      "Strordar",
      "Stranhirek",
      "Godok",
      "Maerdeln",
      "Evermight",
      "Brightpride",
      "Goldblade",
      "Moltenbreaker",
      "Drunkaxe",
      "Bruldann",
      "Gullok",
      "Dorunim",
      "Motgogack",
      "Cuderark",
      "Battlehelm",
      "Hardpride",
      "Greatshaper",
      "Strongstrike",
      "Trollreach",
      "Huldekeck",
      "Deldir",
      "Denhehurr",
      "Glefdon",
      "Bodohk",
      "Hardhammer",
      "Boldbreath",
      "Broadroar",
      "Strongfall",
      "Broodbleeder",
      "Camnegen",
      "Bumnann",
      "Menkack",
      "Thraellun",
      "Dehanonn",
      "Truemane",
      "Mountainforge",
      "Lasttankard",
      "Fireeyes",
      "Hammeraxe",
      "Cerdohurk",
      "Glongovem",
      "Raldinaln",
      "Stretgur",
      "Madinn",
      "Hardforce",
      "Greatstorm",
      "Stronghold",
      "Broadfall",
      "Wildbraid",
      "Daefduvem",
      "Straethguvaln",
      "Fokugir",
      "Bokeg",
      "Lefdihun",
    ]
  },
  "Halfling": {
    first: {
      "♂": [
        "Norster",
        "Halzu",
        "Ostran",
        "Peramin",
        "Quopos",
        "Corder",
        "Wendon",
        "Xomin",
        "Panyas",
        "Govias",
        "Idopher",
        "Confer",
        "Riric",
        "Idover",
        "Zaleon",
        "Pandal",
        "Vinpos",
        "Ulkas",
        "Idolos",
        "Yenzu",
        "Orilos",
        "Iraret",
        "Yarmo",
        "Quozin",
        "Garnan",
        "Orimin",
        "Halyver",
        "Sanos",
        "Zalvon",
        "Uriamin",
        "Ulwan",
        "Tarry",
        "Merlan",
        "Davdak",
        "Lalos",
        "Valric",
        "Xantran",
        "Gosire",
        "Ricpher",
        "Ulver",
        "Barver",
        "Belzor",
        "Osdak",
        "Jorich",
        "Gofer",
        "Elton",
        "Arser",
        "Ulyver",
        "Yarfire",
        "Wilrich",
        "Hormin",
        "Tarmo",
        "Kasdal",
        "Sharyver",
        "Horry",
        "Wilbin",
        "Marzor",
        "Yargin",
        "Barhace",
        "Garner",
        "Korzu",
        "Conner",
        "Zalnan",
        "Fingin",
        "Zalwrick",
        "Tenad",
        "Nezor",
        "Pimhace",
        "Mardon",
        "Valpos",
      ],
      "♀": [
        "Kelkis",
        "Xiphina",
        "Hilleigh",
        "Syllyse",
        "Yesgwen",
        "Fayula",
        "Uvisica",
        "Lelile",
        "Jayzira",
        "Unalyse",
        "Vertina",
        "Xanmita",
        "Rosmita",
        "Shaeda",
        "Ariara",
        "Rigwen",
        "Faykath",
        "Aridove",
        "Jilna",
        "Jaynys",
        "Welne",
        "Welelle",
        "Elilie",
        "Belprys",
        "Eralienne",
        "Unaree",
        "Prudove",
        "Prune",
        "Graree",
        "Therora",
        "Malyra",
        "Eraprys",
        "Nedfice",
        "Froni",
        "Grane",
        "Chenmita",
        "Qieni",
        "Salienne",
        "Tryngrace",
        "Maeleigh",
        "Odirana",
        "Isaleigh",
        "Xigrace",
        "Lehaly",
        "Uvidove",
        "Verula",
        "Vervira",
        "Odigwen",
        "Diawyn",
        "Yophina",
        "Maezana",
        "Rifice",
        "Hani",
        "Callile",
        "Jilfira",
        "Yesyola",
        "Malwyse",
        "Kithwyn",
        "Jilmia",
        "Lidfice",
        "Kelelle",
        "Wiris",
        "Qialyn",
        "Shaemia",
        "Paecaryn",
        "Rimita",
        "Zefvyre",
        "Wiphina",
        "Winys",
        "Zefkath",
        "Ariri",
        "Yoyola",
        "Verleigh",
        "Hahaly",
        "Fenwyn",
        "Gramia",
        "Unayola",
        "Rostrix",
        "Faylie",
        "Nedcey",
      ]
    },
    last: [
      "Riversurge",
      "Marbleearth",
      "Shadowspell",
      "Glowbrace",
      "Softspirit",
      "Clearfellow",
      "Bronzeleaf",
      "Barleycloak",
      "Cloudbluff",
      "Stoutgather",
      "Laughingfeet",
      "Twilightcheeks",
      "Flintwhistle",
      "Dustcobble",
      "Talleye",
      "Cindermeadow",
      "Bramblefinger",
      "Nimbleberry",
      "Copperstep",
      "Smoothheart",
      "Mossfound",
      "Freefellow",
      "Hogbeam",
      "Grandmantle",
      "Greenshadow",
      "Summervale",
      "Lonefeet",
      "Sungrove",
      "Fernbraid",
      "Stonewhistle",
      "Rosegather",
      "Tentop",
      "Fatfellow",
      "Deepgrove",
      "Hogcloak",
      "Bronzeman",
      "Stoneheart",
      "Lightpot",
      "Warmeye",
      "Lunarbrace",
      "Stoutwhistle",
      "Learabbit",
      "Ravenbluff",
      "Underbranch",
      "Bramblewood",
      "Silverfellow",
      "Tossstride",
      "Shadowhill",
      "Amberbloom",
      "Boulderdancer",
      "Mosstop",
      "Hogcobble",
      "Fogfingers",
      "Goodwhistle",
      "Bigflower",
      "Reedbottle",
      "Sunspell",
      "Lunareyes",
      "Brushace",
      "Leabloom",
      "Softwillow",
      "Lightearth",
      "Greenbrand",
      "Tendew",
      "Leaflade",
      "Bronzebottle",
      "Lonewater",
      "Dustbranch",
      "Hillsky",
      "Rumblespell",
      "Grassbranch",
      "Rosecrest",
      "Eldermouse",
      "Rumblebrand",
      "Goodsong",
      "Wildfingers",
      "Greatfeet",
      "Silentrabbit",
      "Longfoot",
      "Greenhollow",
      "Dustberry",
      "Bronzetop",
      "Nightgather",
      "Glowhare",
      "Autumneye",
      "Springwoods",
      "Bouldercrest",
      "Stonewood",
      "Keenmouse",
      "Lightspell",
      "Greenshine",
      "Eartheyes",
      "Smoothmane",
      "Whisperdream",
      "Commonstep",
      "Wisekettle",
      "Havengrove",
      "Cherrycreek",
      "Lunarfinger",
      "Goldbrand",
      "Clearman",
      "Greenwoods",
      "Grassmouse",
      "Leabough",
      "Mossbraid",
      "Autumnbrace",
      "Dustgrove",
      "Bigcobble",
      "Rumblemantle",
      "Barleycreek",
      "Shadowhands",
      "Stonebough",
      "Foremantle",
      "Brightdream",
      "Highsky",
      "Freebeam",
      "Barleyfingers",
      "Cloudtop",
      "Wisedew",
      "Grasscheeks",
      "Leafkettle",
      "Havensurge",
      "Greensun",
      "Silentheart",
      "Lightstep",
      "Laughingmeadow",
      "Mildace",
      "Leawoods",
      "Nightmantle",
      "Wildfeet",
      "Proudwoods",
      "Clearwater",
      "Keengather",
      "Lunarmantle",
      "Fastbelly",
      "Riverfinger",
      "Wisefound",
      "Springdancer",
      "Granddance",
      "Dustbridge",
      "Cherryeye",
      "Wildflow",
      "Autumndew",
      "Boulderhands",
      "Greentop",
      "Fastfeet",
      "Barleybough",
      "Mosskettle",
      "Heartbelly",
      "Truesky",
    ]
  },
  "Fire Genasi": {
    first: {
      neutral: [
        "Fervor",
        "Sizzle",
        "Ardor",
        "Gleam",
        "Flicker",
        "Combust",
        "Smoke",
        "Singe",
        "Fuego",
        "Kiln",
        "Wildfire",
        "Conflagration",
        "Light",
        "Sultry",
        "Magma",
        "Tinder",
        "Thermal",
        "Sun",
        "Frizzle",
        "Char",
        "Scald",
        "Blaze",
        "Glint",
        "Ablaze",
        "Piping",
        "Smolder",
        "Frizzle",
        "Tinder",
        "Inferno",
        "Smoke",
        "Smoke",
        "Explosion",
        "Sear",
        "Spark",
        "Glare",
        "Infernal",
        "Flare",
        "Smoke",
        "Lantern",
        "Bonfire",
        "Thermo",
        "Fry",
        "Sizzle",
        "Inferno",
        "Tinder",
        "Fume",
        "Fervor",
        "Seethe",
        "Fume",
        "Beacon",
        "Dantean",
        "Lantern",
        "Warmth",
        "Sear",
        "Glow",
        "Scorch",
        "Ablaze",
        "Tinder",
        "Brand",
        "Seethe"
      ],
    },
  },
  "Water Genasi": {
    first: {
      neutral: [
        "Drain",
        "Runnel",
        "Spout",
        "Course",
        "Douse",
        "Ocean",
        "Azure",
        "Tear",
        "Basin",
        "Influx",
        "Neptune",
        "Flood",
        "Course",
        "Rain",
        "Gush",
        "Gush",
        "Dabble",
        "Sprinkle",
        "Wave",
        "Lake",
        "Jet",
        "Lagoon",
        "Bore",
        "Torrent",
        "Influx",
        "Seiche",
        "Current",
        "Agua",
        "Rill",
        "Drown",
        "Wet",
        "Cleanse",
        "Creek",
        "Damp",
        "Rivulet",
        "Dabble",
        "Tempest",
        "Douse",
        "Wet",
        "Soak",
        "Swish",
        "Cleanse",
        "Flux",
        "Surge",
        "Dewdrop",
        "Neptune",
        "Monsoon",
        "Sea",
        "Ripple",
        "Streamlet",
        "Pool",
        "Tidal",
        "Beck",
        "Pool",
        "Aqua",
        "Shower",
        "Ripple",
        "Wash",
        "Plash",
        "Surge"
      ]
    },
  },
  "Air Genasi": {
    first: {
      neutral: [
        "Aerate",
        "Breeze",
        "Murmur",
        "Celestial",
        "Rise",
        "Vent",
        "Gasp",
        "Storm",
        "Eddy",
        "Sail",
        "Zephyr",
        "Puff",
        "Troposphere",
        "Sky",
        "Surge",
        "Air",
        "Hover",
        "Aviate",
        "Hover",
        "Vent",
        "Aura",
        "Pipe",
        "Twister",
        "Gust",
        "Lift",
        "Turbulence",
        "Blast",
        "Azure",
        "Tornado",
        "Flute",
        "Flutter",
        "Turbine",
        "Stratosphere",
        "Mistral",
        "Draft",
        "Sail",
        "Aura",
        "Cruise",
        "Zephyr",
        "Pipe",
        "Mistral",
        "Air",
        "Aerial",
        "Hiss",
        "Hover",
        "Wing",
        "Wheeze",
        "Storm",
        "Cruise",
        "Air",
        "Tumult",
        "Tumult",
        "Chinook",
        "Hiss",
        "Hiss",
        "Shriek",
        "Aerate",
        "Float",
        "Gasp",
        "Shriek"
      ]
    }
  },
  "Earth Genasi": {
    first: {
      neutral: [
        "Mountain",
        "Moonstone",
        "Alabaster",
        "Marmoreal",
        "Lump",
        "Bedrock",
        "Slab",
        "Nugget",
        "Stone",
        "Slab",
        "Crystal",
        "Zircon",
        "Precious",
        "Slate",
        "Monolith",
        "Sediment",
        "Sandstone",
        "Lapis",
        "Sand",
        "Citrine",
        "Jewel",
        "Marble",
        "Wedge",
        "Alabaster",
        "Flint",
        "Bedrock",
        "Crystal",
        "Hunk",
        "Quartz",
        "Cliff",
        "Rough",
        "Gravel",
        "Mountain",
        "Obsidian",
        "Sapphire",
        "Gem",
        "Wedge",
        "Mineral",
        "Crag",
        "Slate",
        "Spinel",
        "Soapstone",
        "Callous",
        "Ruby",
        "Lapis",
        "Adamant",
        "Zircon",
        "Monolith",
        "Pellet",
        "Callous",
        "Solid",
        "Fossilstone",
        "Mineral",
        "Sediment",
        "Slate",
        "Solid",
        "Clay",
        "Quarry",
        "Brick",
        "Pebble"
      ]
    }
  },
  "Dragonborn": {
    first: {
      "♂": [
        "Niracnak",
        "Verjek",
        "Vaccukec",
        "Dealmish",
        "Neltishkmak",
        "Nelthinkorges",
        "Criccatil",
        "Uummith",
        "Copukac",
        "Vephastallith",
        "Mildruniruac",
        "Lerdicmarres",
        "Daltid",
        "Lelthead",
        "Myustin",
        "Thilritol",
        "Epanalaar",
        "Istaadaarrun",
        "Dincuc",
        "Vemphishtul",
        "Drithtendrejid",
        "Nerul",
        "Shaltuuth",
        "Achenel",
        "Nyuumtaashkmek",
        "Emrecmergiad",
        "Arrhacellin",
        "Lasteshtil",
        "Machil",
        "Tultenthash",
        "Cemmean",
        "Ucaduuk",
        "Clualkondruus",
        "Nyirdush",
        "Krichonshtar",
        "Vichistanid",
        "Tincetek",
        "Teccil",
        "Mimmukajen",
        "Myalkesh",
        "Monxecel",
        "Ealricnarol",
        "Shemtison",
        "Kankastud",
        "Creldad",
        "Tiapindrimir",
        "Krilruanshted",
        "Clarjir",
        "Krammir",
        "Drilkeasillaash",
        "Gilthuundrin",
        "Laphath",
        "Draathtelaajean",
        "Krankathek",
        "Fulmindis",
        "Firjel",
        "Klithtas",
        "Miastekmal",
        "Kremponir",
        "Naccath",
        "Althin",
        "Estotiad",
        "Naxilush",
        "Kaachekardath",
        "Cluaphear",
        "Vaaldrak",
        "Klomridendek",
        "Tathtec",
        "Prumpenthindol",
        "Vepuath",
        "Aamronirrod",
        "Castash",
        "Krulxus",
        "Krialminkir",
        "Kuxicnajad",
        "Koldad",
        "Clearjasan",
        "Miastethaan",
        "Lophekmender",
        "Thealdirak",
        "Thethtendrer",
        "Cluumphicmeth",
        "Clichuunkeadic",
        "Krimbatundec",
        "Enkos",
        "Klinkeshtundaath",
        "Cruchires",
        "Myirnic",
        "Umbeac",
        "Clornon",
        "Niancis",
        "Mearrhuucnuurdeth",
        "Shembutidor",
        "Deruak",
        "Anxenshtorrel",
        "Thilranuamad",
        "Olthaacmed",
        "Felthac",
        "Thornashted",
        "Drulthucnith",
        "Kucces",
        "Tertheshtelic",
        "Drerthajirdac",
        "Gaalrul",
        "Uncicerran",
        "Ulmic",
        "Tuulmesh",
        "Daamtec",
        "Celtis",
        "Iachashtan",
      ],
      "♀": [
        "Yonxoshkmaath",
        "Uarthikmumic",
        "Vaccastir",
        "Klampondris",
        "Lelmal",
        "Echekan",
        "Yalkeankaarrok",
        "Drealmus",
        "Virjish",
        "Nyuchundreak",
        "Yonxoshkmaath",
        "Uarthikmumic",
        "Vaccastir",
        "Klampondris",
        "Lelmal",
        "Echekan",
        "Yalkeankaarrok",
        "Drealmus",
        "Virjish",
        "Nyuchundreak",
        "Nochitharash",
        "Nyanxeanked",
        "Prilkuacar",
        "Shenxonkan",
        "Dincukmilis",
        "Shaldral",
        "Kemmeth",
        "Firnedar",
        "Clumres",
        "Krulthur",
        "Shicces",
        "Mixoth",
        "Krelmucmel",
        "Klaapor",
        "Crelduar",
        "Princash",
        "Claphoxinud",
        "Cremtuanthuumuush",
        "Karjas",
        "Dirrhac",
        "Irthoth",
        "Delkas",
        "Cleldathak",
        "Erjaxidic",
        "Ecen",
        "Nirrhuur",
        "Myimphik",
        "Crichashtunor",
        "Croltish",
        "Nachad",
        "Dultathar",
        "Nyosticmandial",
        "Shistindreargir",
        "Drimbaan",
        "Shirthoxarrik",
        "Prurthashtiruk",
        "Cuulrenas",
        "Olmaadac",
        "Nuumpareardash",
        "Drirden",
        "Accas",
        "Vankalak",
        "Sholdririd",
        "Vethtiajil",
        "Ilruarak",
        "Crildretak",
        "Noldiacmarrath",
        "Drolkuun",
        "Drelthualos",
        "Thaphejun",
        "Accas",
        "Vankalak",
        "Sholdririd",
        "Vethtiajil",
        "Ilruarak",
        "Crildretak",
        "Noldiacmarrath",
        "Drolkuun",
        "Drelthualos",
        "Thaphejun",
        "Daalkatun",
        "Kleccindrulath",
        "Imtuushkmirdas",
        "Prirrhasirreac",
        "Klolthaxamok",
        "Velthad",
        "Crenkisec",
        "Drarthaner",
        "Vaphir",
        "Firjan",
        "Prirjol",
        "Dracakmish",
        "Krunkondruus",
        "Prialtusuth",
        "Genxetuandok",
        "Crilkacnash",
        "Myencin",
        "Kixed",
        "Vopeshtorriar",
        "Urnal",
        "Krualthal",
        "Gimmec",
        "Yuummestudul",
        "Uachandolen",
        "Nyerjer",
        "Ginxal",
        "Pruumrek",
        "Lerjash",
        "Mirjuxish",
        "Kruucherid",
        "Dreltendrirash",
        "Achistinduuc",
        "Mulrenkaarges",
        "Eamphustid",
        "Thaanxixad",
        "Cichic",
        "Neldanshtirel",
        "Emtuk",
        "Malkestac",
        "Vimresel",
        "Fethtadir",
        "Draltudac",
        "Apeth",
        "Crexer",
        "Thapud",
        "Almendiak",
        "Yimbonak",
        "Nacucnuallad",
        "Celdos",
        "Carthotak",

      ]
    },
    last: [
      "Qelciar",
      "Zorkax",
      "Jinhazar",
      "Faerkris",
      "Xarythas",
      "Froghull",
      "Mornaar",
      "Iorseth",
      "Shanaar",
      "Raslasar",
      "Alizire",
      "Dosashi",
      "Zraghull",
      "Jinxan",
      "Uroythas",
      "Otivull",
      "Belvroth",
      "Worzavur",
      "Sulwarum",
      "Nardaar",
      "Durseth",
      "Caluzavur",
      "Gheseth",
      "Xaryax",
      "Qelprax",
      "Qelciar",
      "Zorhadur",
      "Wumash",
      "Tazrinn",
      "Kilkris",
      "Orlawunax",
      "Jinfras",
      "Rhoprax",
      "Rasfarn",
      "Wulvull",
      "Vrakfarn",
      "Lordhall",
      "Narbarum",
      "Pazavur",
      "Krivqull",
      "Kilseth",
      "Naythas",
      "Wultrin",
      "Tazgar",
      "Lorrakas",
      "Hibor",
      "Drakul",
      "Yorlasar",
      "Drahazar",
      "Lorbarum",
      "Aryax",
      "Iorcrath",
      "Xarfras",
      "Dragrax",
      "Wulvull",
      "Otirinn",
      "Otibroth",
      "Sharash",
      "Balziros",
      "Draciar",
      "Hilasar",
      "Doprax",
      "Wulnaar",
      "Vrakmash",
      "Yordhall",
      "Zorxan",
      "Jarvarax",
      "Xarturim",
      "Qelqull",
      "Orlabroth",
      "Trouwarum",
      "Sulbroth",
      "Troulin",
      "Saqrin",
      "Raskax",
      "Wulgar",
      "Ravobarum",
      "Orlaroth",
      "Grejhan",
      "Jinturim",
      "Worrinn",
      "Mormorn",
      "Goraxiros",
      "Gheyax",
      "Greqrin",
      "Greziros",
      "Otinaar",
      "Xarwarum",
      "Dralin",
      "Frociar",
      "Caerlasar",
      "Pabarum",
      "Xardaar",
      "Zradaar",
      "Trouseth",
      "Belrash",
      "Orlagar",
      "Durdhall",
      "Priroth",
      "Nazire",
      "Xarwarum",
      "Neskul",
      "Orlaturim",
      "Goramash",
      "Rhoturim",
      "Jarzavur",
      "Zrazavur",
      "Frodaar",
      "Pritrin",
      "Wrakax",
      "Uridalynn",
      "Yrrann",
      "Vyrabis",
      "Qiwophyl",
      "Wrazys",
      "Yasira",
      "Erlinys",
      "Xydrish",
      "Hacys",
      "Crisfaeth",
      "Uridalynn",
      "Yrrann",
      "Vyrabis",
      "Qiwophyl",
      "Wrazys",
      "Yasira",
      "Erlinys",
      "Xydrish",
      "Hacys",
      "Crisfaeth",
      "Binorae",
      "Welsirish",
      "Narinn",
      "Drysshann",
      "Jeszys",
      "Nabis",
      "Neslarys",
      "Jomyse",
      "Nysshann",
      "Bipatys",
      "Malrith",
      "Nessira",
      "Jowophyl",
      "Mizys",
      "Thabis",
      "Ushirina",
      "Grigil",
      "Faecys",
      "Hinyries",
      "Malgwen",
      "Welsifyire",
      "Zofrann",
      "Uribith",
      "Gurnorae",
      "Yrsaadi",
      "Aqwen",
      "Yagwen",
      "Nespatys",
      "Grinorae",
      "Gurvyre",
      "Thercys",
      "Omyse",
      "Caliann",
      "Oriel",
      "Vallarys",
      "Ushibith",
      "Bivyre",
      "Rashimyse",
      "Xywyn",
      "Ophiriel",
      "Orith",
      "Sovyre",
      "Uripora",
      "Welsigissa",
      "Ophiriel",
      "Jescys",
      "Joxora",
      "Dafyire",
      "Phidalynn",
      "Thabis",
      "Orith",
      "Sovyre",
      "Uripora",
      "Welsigissa",
      "Ophiriel",
      "Jescys",
      "Joxora",
      "Dafyire",
      "Phidalynn",
      "Thabis",
      "Oricys",
      "Nesnorae",
      "Yrlyassa",
      "Xysira",
      "Irlydrish",
      "Oyries",
      "Valwophyl",
      "Ushirinn",
      "Nysrina",
      "Lilopatys",
      "Quilgwen",
      "Zofliann",
      "Zofriel",
      "Tharith",
      "Loranys",
      "Raivys",
      "Eshbith",
      "Sobirith",
      "Yafaeth",
      "Gurrish",
      "Kelzita",
      "Xisvayla",
      "Ophithyra",
      "Kofaeth",
      "Sohymm",
      "Grilarys",
      "Aripora",
      "Irlywyn",
      "Drysdrish",
      "Belkaryn",
      "Darinn",
      "Jovayla",
      "Rashigissa",
      "Gurgissa",
      "Criskaryn",
      "Irlypatys",
      "Navyre",
      "Thagwen",
      "Grimeila",
      "Nysgwen",
      "Nagwen",
      "Ophicys",
      "Crishymm",
      "Iriehymm",
      "Dryscys",
      "Davayla",
      "Therrinn",
      "Gurrann",
      "Xiszys",
      "Iriebis",
    ]
  },
  "Aasimar": {
    first: {
      "♂": [
        "Creirwan",
        "Heirail",
        "Maunont",
        "Aigront",
        "Caudrem",
        "Zeidrulan",
        "Brulaman",
        "Wanliven",
        "Eklodant",
        "Ukreel",
        "Reggal",
        "Irnant",
        "Vunlant",
        "Cralent",
        "Browiant",
        "Crilwidant",
        "Eiggivil",
        "Ernatent",
        "Uniler",
        "Eaklal",
        "Ylir",
        "Urral",
        "Lildil",
        "Brynlim",
        "Vaugwint",
        "Einlaman",
        "Rodralan",
        "Cunrimial",
        "Zynweetam",
        "Heaggil",
        "Orian",
        "Naggant",
        "Inol",
        "Irem",
        "Maulran",
        "Vegravim",
        "Uvridil",
        "Hyvrivail",
        "Einledant",
        "Rakol",
        "Aulwair",
        "Pylgim",
        "Aivriar",
        "Nelwam",
        "Evrur",
        "Ikleinaint",
        "Nelwideel",
        "Lilrivian",
        "Urlatim",
        "Novrein",
        "Maivril",
        "Heakint",
        "Poldil",
        "Hoklen",
        "Egol",
        "Irleelan",
        "Zailralun",
        "Zornavil",
        "Velwedil",
        "Irler",
        "Vuggul",
        "Vealtir",
        "Ruklint",
        "Ivreint",
        "Elir",
        "Vaklatar",
        "Molrinar",
        "Rorrinint",
        "Veilgidir",
        "Cryvrin",
        "Leawain",
        "Zegrant",
        "Altil",
        "Beivrant",
        "Yrliar",
        "Bregolem",
        "Bonevam",
        "Pirradan",
        "Yldanim",
        "Ylreer",
        "Lurwil",
        "Calril",
        "Tulrunt",
        "Cylgan",
        "Yldim",
        "Wagratant",
        "Wikeitan",
        "Zunrideel",
        "Crinudon",
        "Lowial",
        "Ydriam",
        "Ukrar",
        "Ireen",
        "Veilrin",
        "Braurliar",
        "Hyrwuniel",
        "Haduvint",
        "Weilrilar",
        "Meikramint",
        "Hynwent",
        "Algel",
        "Zenlaim",
        "Unim",
        "Awem",
        "Tinwant",
        "Halriemunt",
        "Borinant",
        "Cairwalien",
        "Neivronim",
        "Odar",
        "Manlam",
        "Irweem",
        "Naulrar",
        "Avrain",
        "Baivrel",
        "Bodritint",
        "Vokralem",
        "Cidriemint",
        "Cykladar",
        "Ogwant",
        "Meagrint",
        "Lunlol",
        "Zogwin",
        "Munrul",
        "Creaggun",
        "Honlaveint",
        "Wilgaivint",
        "Brolgamant",
        "Vaureenint",
        "Mearant",
        "Hugger",
        "Craudrint",
        "Heanral",
        "Rarrin",
        "Werlin",
        "Puditil",
        "Lulgidant",
        "Menleilint",
        "Wovamen",
        "Brukem",
        "Akleil",
        "Zuver",
        "Iklair",
        "Agal",
        "Zikil",
        "Wegreitir",
        "Ogwevar",
        "Wivrolam",
        "Ygovan",
        "Breawen",
        "Onal",
        "Vaidir",
        "Naurwul",
        "Palen",
        "Ranlan",
        "Brovranul",
        "Tekiman",
        "Cegavem",
        "Ynlavir",
        "Cunir",
        "Byrlunt",
        "Lilgom",
        "Ruvan",
        "Ailtun",
        "Nurren",
        "Ulwatam",
        "Cridridant",
        "Neivialan",
        "Ynlanint",
        "Arlaint",
        "Vaunlol",
        "Hirnal",
        "Nigrar",
        "Anul",
        "Cugweil",
        "Vuneidil",
        "Mirramal",
        "Coviner",
        "Aurleelan",
        "Ryrnien",
        "Vailgol",
        "Ydrur",
        "Idont",
        "Peagwun",
        "Evriant",
        "Aidritar",
        "Ylwiateint",
        "Panritil",
        "Bregedin",
      ],
      "♀": [
        "Hanroh",
        "Ildie",
        "Vosra",
        "Heanvi",
        "Brioldrer",
        "Rirka",
        "Drimasa",
        "Vilvesi",
        "Vonviza",
        "Theonele",
        "Zeadi",
        "Dianviah",
        "Nisre",
        "Zindre",
        "Nadir",
        "Zavih",
        "Rhiondriza",
        "Wenlemoe",
        "Odeli",
        "Bromisi",
        "Leorso",
        "Andrih",
        "Riommi",
        "Zinma",
        "Wordo",
        "Dordea",
        "Disaze",
        "Wiasleasoem",
        "Driorkini",
        "Anmemou",
        "Phearsa",
        "Drorsel",
        "Brorsea",
        "Brimmo",
        "Osra",
        "Rhajie",
        "Thindrare",
        "Mosliasa",
        "Wosrili",
        "Devise",
        "Irda",
        "Aro",
        "Rhinliar",
        "Phialvi",
        "Zanron",
        "Phosi",
        "Wollore",
        "Pharkoza",
        "Malamal",
        "Tharone",
        "Rhirdea",
        "Omen",
        "Werdan",
        "Osia",
        "Doso",
        "Ranri",
        "Manmise",
        "Thaldriril",
        "Brasrieme",
        "Phanmazau",
        "Phirdo",
        "Hardi",
        "Walle",
        "Phillal",
        "Rheral",
        "Thinla",
        "Eorsesa",
        "Wiltasin",
        "Mirolim",
        "Rhevesil",
        "Onmea",
        "Driri",
        "Nonra",
        "Allieh",
        "Lasril",
        "Ove",
        "Ondrone",
        "Zamosa",
        "Warsari",
        "Momiri",
        "Malve",
        "Meosre",
        "Zomni",
        "Drorie",
        "Invo",
        "Wola",
        "Mildomer",
        "Isrosou",
        "Brinlose",
        "Theammele",
        "Darse",
        "Vemia",
        "Zilva",
        "Ola",
        "Rhamne",
        "Pheva",
        "Zolvisar",
        "Vealvielim",
        "Alvala",
        "Irdira",
        "Broli",
        "Dova",
        "Oje",
        "Hilve",
        "Ralti",
        "Thava",
        "Eollozen",
        "Liorderi",
        "Zianvosih",
        "Lorozam",
        "Thimno",
        "Zadih",
        "Thari",
        "Imno",
        "Deller",
        "Manel",
        "Avasi",
        "Momasi",
        "Wanmale",
        "Phonmaner",
        "Phinvia",
        "Thinmi",
        "Illom",
        "Wirde",
        "Phinveh",
        "Tholdal",
        "Viarsima",
        "Brilteanal",
        "Inasil",
        "Woldrel",
      ]
    }
  },
  "Aarakocra": {
    first: {
      neutral: [
        "Cred",
        "Ud",
        "Guss",
        "Crig",
        "Sack",
        "Krerirk",
        "Qhuarrerr",
        "Aqar",
        "Rhakkass",
        "Crikark",
        "Eg",
        "Ick",
        "Qe",
        "Clif",
        "Rhas",
        "Icceck",
        "Qluqic",
        "Ukek",
        "Qrualiag",
        "Qhaqea",
        "Yaef",
        "Zif",
        "Klic",
        "Qu",
        "Duc",
        "Sarrek",
        "Sakis",
        "Kherreek",
        "Ellahk",
        "Urrek",
        "Qrirk",
        "Arc",
        "Aiahk",
        "Kaes",
        "Cleck",
        "Criri",
        "Zurra",
        "Yakked",
        "Uaqiack",
        "Qheree",
        "Arr",
        "Zirrk",
        "Gec",
        "Qurc",
        "Qluieck",
        "Uciss",
        "Eriak",
        "Allir",
        "Galieck",
        "Sekif",
        "Hehk",
        "Yarc",
        "Uck",
        "Us",
        "Gral",
        "Qreqee",
        "Gruccef",
        "Kleca",
        "Iqehk",
        "Qallirc",
        "Cruarrk",
        "Heck",
        "Di",
        "Kraerr",
        "Uhk",
        "Qlarreaf",
        "Kreerrirrk",
        "Klakarrk",
        "Koucciaf",
        "Qirri",
        "Koul",
        "Rid",
        "Kherr",
        "Yi",
        "Il",
        "Khallic",
        "Aeqa",
        "Qraellis",
        "Krirerr",
        "Cilarrk",
        "Rhae",
        "Cuk",
        "Qrel",
        "Qrek",
        "Cris",
        "Craiakkeeck",
        "Grakkarr",
        "Diqack",
        "Qeeca",
        "Krillis",
        "Rerc",
        "Qra",
        "Ec",
        "Es",
        "Rhud",
        "Khurreark",
        "Rarark",
        "Serress",
        "Cluakerk",
        "Hekkik",
        "Gras",
        "Kriss",
        "Ku",
        "Rhuc",
        "Ras",
        "Qalled",
        "Yuiera",
        "Durrehk",
        "Yaqil",
        "Qirir",
        "Qhuhk",
        "Ri",
        "Qarrk",
        "Rharc",
        "Ya",
        "Khulierr",
        "Gicas",
        "Curre",
        "Zerref",
        "Gracarc",
        "Qu",
        "Ik",
        "Irr",
        "Qrul",
        "Zuies",
        "Gurreec",
        "Uielag",
        "Yukkic",
        "Kheccic",
        "Crukac",
        "Klu",
        "Kuierk",
        "Suhk",
        "As",
        "Uck",
        "Kaiacief",
        "Qlukerr",
        "Hirriad",
        "Clureg",
        "Qrirrass",
      ]
    }
  },
  "Gnome":{
    first: {
      "♂": [
        "Ertor",
        "Toji",
        "Graybar",
        "Davfiz",
        "Salhik",
        "Davrick",
        "Dorser",
        "Warzu",
        "Uriybar",
        "Rondri",
        "Calcorin",
        "Toyur",
        "Felmop",
        "Nestix",
        "Vorzu",
        "Erdon",
        "Warnan",
        "Jorros",
        "Iantix",
        "Mancorin",
        "Corwin",
        "Zanziver",
        "Tanxif",
        "Sinrick",
        "Tranan",
        "Brimorn",
        "Salvyn",
        "Rasvyn",
        "Xalpip",
        "Jorni",
        "Kasros",
        "Lanston",
        "Trakas",
        "Lanzu",
        "Ronziver",
        "Quowor",
        "Sapip",
        "Graji",
        "Brimop",
        "Zilxif",
        "Valrick",
        "Toben",
        "Uritix",
        "Kashik",
        "Vorgrim",
        "Xotor",
        "Panacorin",
        "Ianhim",
        "Salrug",
        "Powin",
        "Kelmin",
        "Rasmorn",
        "Ercryn",
        "Zanston",
        "Yebar",
        "Felfiz",
        "Feldon",
        "Dormin",
        "Quoyur",
        "Almorn",
        "Niybar",
        "Rongrim",
        "Sacryn",
        "Orulin",
        "Quaston",
        "Jorfiz",
        "Gayur",
        "Wrefiz",
        "Bilhik",
        "Trapip",
        "Ipapip",
        "Ariston",
        "Xalmop",
        "Jorgim",
        "Nesner",
        "Merben",
        "Salbar",
        "Yedri",
        "Grabar",
        "Manros",
        "Wrexim",
        "Enirick",
        "Kasyur",
        "Lanbis",
        "Corxim",
        "Manfan",
        "Corcorin",
        "Tohim",
        "Lanner",
        "Briwin",
        "Rasfan",
        "Grakur",
        "Sinros",
        "Enidri",
        "Orufiz",
        "Grafan",
        "Lanrug",
        "Mandon",
        "Xalryn",
        "Farxim",
        "Jordri",
        "Rasrug",
        "Zantix",
        "Loyur",
        "Toybar",
        "Caljin",
        "Niyur",
        "Tovyn",
        "Bilmorn",
        "Ipakas",
        "Vorver",
        "Yoshik",
        "Tandri",
        "Traston",
        "Orston",
        "Quajin",
        "Ertix",
        "Sabar",
        "Tanpos",
        "Valfan",
        "Ronser",
        "Bilpip",
        "Panamop",
        "Umbis",
        "Manmop",
        "Corkas",
        "Ercryn",
        "Ipavyn",
        "Oruhik",
        "Tracorin",
        "Gajin",
        "Feldri",
        "Ronlin",
        "Toner",
        "Wrever",
        "Kasziver",
        "Ipaben",
        "Tanmin",
        "Tanros",
        "Orpip",
      ],
      "♀": [
        "Jotra",
        "Hesxis",
        "Carlys",
        "Venniana",
        "Eilimyn",
        "Welfyx",
        "Odaza",
        "Seldira",
        "Krigyra",
        "Dosys",
        "Eilina",
        "Yloyaris",
        "Zingyra",
        "Tripine",
        "Hesza",
        "Odalin",
        "Ufemila",
        "Galkini",
        "Nykini",
        "Galyaris",
        "Rosikini",
        "Xayaris",
        "Jelkini",
        "Mindysa",
        "Folhani",
        "Alunoa",
        "Isoza",
        "Zingani",
        "Eilihani",
        "Doxi",
        "Isoxis",
        "Refyx",
        "Myxi",
        "Nikini",
        "Cella",
        "Isoli",
        "Queli",
        "Galmila",
        "Loriqys",
        "Joniana",
        "Nyyore",
        "Dofyx",
        "Joqaryn",
        "Queqaryn",
        "Ylohani",
        "Tahana",
        "Ufedira",
        "Lilmila",
        "Minlin",
        "Jelmiphi",
        "Alutina",
        "Yoci",
        "Volys",
        "Odaqaryn",
        "Ormiphi",
        "Zinyore",
        "Orla",
        "Lilnoa",
        "Zinpine",
        "Repine",
        "Phila",
        "Yoci",
        "Celkasys",
        "Carza",
        "Alunoa",
        "Odawyse",
        "Tifaxis",
        "Venlin",
        "Jelnove",
        "Welqys",
        "Banphina",
        "Celhana",
        "Regyra",
        "Tifamila",
        "Aluxis",
        "Xadira",
        "Wroxi",
        "Volys",
        "Minhana",
        "Qissa",
        "Yomyra",
        "Ufemyn",
        "Welli",
        "Yloniana",
        "Welxis",
        "Kriphina",
        "Ariyore",
        "Minbys",
        "Yloli",
        "Orzyre",
        "Rosiqaryn",
        "Daphimyn",
        "Nyna",
        "Isomiphi",
        "Jomila",
        "Ufeli",
        "Niwyse",
        "Yobys",
        "Ornoa",
        "Taceli",
        "Xyrowyn",
        "Minssa",
        "Elyaris",
        "Eiliphina",
        "Rezyre",
        "Lilyaris",
        "Breeqaryn",
        "Isoniana",
        "Prina",
        "Daphimiphi",
        "Grensany",
        "Krili",
        "Ylolys",
        "Takasys",
        "Orqys",
        "Cellin",
        "Hesnoa",
        "Xatra",
        "Yosany",
        "Hesssa",
        "Zanissa",
        "Nyroe",
        "Selfi",
        "Ylohani",
        "Rosiqys",
        "Venli",
        "Fendysa",
        "Fensany",
        "Alumyn",
        "Kloli",
        "Faebys",
        "Qilin",
        "Urixi",
        "Klobi",
        "Xana",
        "Odanoa",
        "Galwyn",
        "Krigani",
        "Alufyx",
        "Galbi",
      ]
    },
    last: [
      "Bellybadge",
      "Fizzledwadle",
      "Twinblock",
      "Flickerdiggles",
      "Wildflight",
      "Zombletamble",
      "Popleter",
      "Mirnenesar",
      "Sukkidon",
      "Paglenogle",
      "Babblestone",
      "Ironspell",
      "Darkfield",
      "Goldgem",
      "Goldmane",
      "Efen",
      "Tuffurodend",
      "Mempledimple",
      "Rannabel",
      "Turganarors",
      "Fizzlebelt",
      "Kinddiggles",
      "Whistlepocket",
      "Fapplereach",
      "Cobblestamp",
      "Zaenggebeneg",
      "Peebblur",
      "Feblebeble",
      "Fanpenes",
      "Bimblememble",
      "Ironfront",
      "Singlehelm",
      "Squigglebraid",
      "Fizzlefeast",
      "Boldgrace",
      "Zoglezegle",
      "Umplatese",
      "Dagglegeggle",
      "Bongisonars",
      "Folkuban",
      "Broadgem",
      "Luckystone",
      "Quickbelch",
      "Brassblock",
      "Boldfeast",
      "Geelbibaton",
      "Muppisaban",
      "Hacklewickle",
      "Bicklaseti",
      "Zepplemepple",
      "Tinkermantle",
      "Bellybit",
      "Silverstitch",
      "Berrydust",
      "Wildbranch",
      "Zarnenerirs",
      "Puggloten",
      "Leddleliddle",
      "Raernoborin",
      "Enges",
      "Lightstand",
      "Swiftguard",
      "Thundercrag",
      "Singlebranch",
      "Shadowmane",
      "Pampleromple",
      "Gugglineres",
      "Folnen",
      "Ammlesoten",
      "Irrusedack",
      "Tinkerfen",
      "Sparklewander",
      "Tosslebit",
      "Squigglethread",
      "Copperfeast",
      "Halnanadag",
      "Pulrideg",
      "Nasgranorir",
      "Lelburesill",
      "Rambunesar",
      "Truelob",
      "Finebelch",
      "Broadstamp",
      "Wigglefront",
      "Palefern",
      "Tiplegiple",
      "Hammonedeck",
      "Mebleneble",
      "Welrotol",
      "Pungon",
      "Wildbit",
      "Wobblecollar",
      "Waggleblock",
      "Stoutreach",
      "Palebrand",
      "Maambarenig",
      "Gurkedisirs",
      "Repenaters",
      "Omlal",
      "Heglehegle",
      "Kindmantle",
      "Tinkerstand",
      "Bellyspan",
      "Fapplefall",
      "Glittergrace",
      "Furesanan",
      "Nuffibiso",
      "Hadleledle",
      "Rabblenabble",
      "Larkederand",
      "Stronglob",
      "Quillgift",
      "Quickfall",
      "Puddleflow",
      "Tinkerhold",
      "Dernosiron",
      "Rircuremp",
      "Lunggas",
      "Lorran",
      "Haalnotos",
      "Babblebelt",
      "Tinkerhelm",
      "Loudthread",
      "Starkpeak",
      "Palebelch",
      "Pepasadol",
      "Miblegoble",
      "Zennig",
      "Narnobirs",
      "Dosgend",
      "Pebblebell",
      "Whistlebit",
      "Bronzerock",
      "Bafflediggles",
      "Broadfront",
      "Hinnetabar",
      "Nirnabadeg",
      "Bopebener",
      "Semplezample",
      "Fiserebe",
      "Tosslebrand",
      "Fiddlefall",
      "Fizzlefront",
      "Quickguard",
      "Lightguard",
      "Weffinol",
      "Surcibase",
      "Nirnoris",
      "Gamlatars",
      "Zemplepomple",
      "Luckygrace",
      "Puddlepeak",
      "Dazzlebonk",
      "Luckyhelm",
      "Shadowcase",
      "Repplelopple",
      "Tolweteda",
      "Aerkabisel",
      "Zeesgrisel",
      "Seplesenan",
      "Wobbledrop",
      "Finefen",
      "Puddlestand",
      "Twinmane",
      "Goldbelt",
      "Wurrerin",
      "Hirraneg",
      "Emmladirar",
      "Zemladerer",
      "Zelband",
      "Broadshield",
      "Deepgrace",
      "Tinkerpocket",
      "Truediggles",
      "Glitterdrop",
      "Poosenorers",
      "Renggerebe",
      "Dembesadal",
      "Nisgoseck",
      "Hogglabirel",
      "Bellycheek",
      "Briskmantle",
      "Berrybadge",
      "Brasshide",
      "Stronglob",
      "Wemblelamble",
      "Winggabonomp",
      "Hilwatinol",
      "Lekkol",
      "Nemmli",
      "Berryhold",
      "Whistlefeast",
      "Boonfen",
      "Wigglekind",
      "Silvercord",
      "Hilnel",
      "Logglenaggle",
      "Paembedasor",
      "Widdleniddle",
      "Wegglepeggle",
      "Thistleshield",
      "Briskpocket",
      "Togglewander",
      "Boldspell",
      "Palestitch",
      "Lipal",
      "Zelkanedo",
      "Foongaron",
      "Homplefample",
      "Zimblerimble",
      "Twinrest",
      "Wildtrick",
      "Darkthread",
      "Truepitch",
      "Flickerdiggles",
      "Riblerable",
      "Worces",
      "Wemparatin",
      "Wosidiner",
      "Zaannidabon",
      "Copperblock",
      "Togglebadge",
      "Fappleflow",
      "Billowligt",
      "Deepback",
      "Hurken",
      "Deplenes",
      "Raapadotos",
      "Rannack",
      "Pofobedag",
      "Thistlefield",
      "Gobblespark",
      "Lastrest",
      "Thistletrick",
      "Berrybelch",
      "Teckluck",
      "Leggleheggle",
      "Miseda",
      "Nackledeckle",
      "Hoglegegle",
      "Flickerspell",
      "Dapplemane",
      "Glittercloak",
      "Springtop",
      "Puddlebranch",
      "Tadlemedle",
      "Gegglediggle",
      "Rebbleribble",
      "Backledeckle",
      "Tunporatemp",
      "Palesong",
      "Glitterbonk",
      "Loudreach",
      "Stoutpocket",
      "Luckymask",
      "Bisinal",
      "Gilwodanen",
      "Horobas",
      "Lenpos",
      "Noresatel",
      "Bellowfen",
      "Dappleheart",
      "Waggleboots",
      "Squigglepocket",
      "Deeppatch",
      "Tombel",
      "Ulninaback",
      "Faasebel",
      "Hambar",
      "Tackleseckle",
      "Coppercrag",
      "Copperfall",
      "Copperpatch",
      "Strongmantle",
      "Shadowhold",
      "Turaners",
      "Depleber",
      "Negleligle",
      "Nulraribes",
      "Ammebaders",
    ]
  },
  "Deep Gnome": {
    first: {
      "♂": [
        "Taaklil",
        "Tirthmed",
        "Witlu",
        "Shathre",
        "Takter",
        "Schnudmend",
        "Dithal",
        "Bottlig",
        "Schnittlit",
        "Schenthuc",
        "Bragglig",
        "Brodmu",
        "Gricktin",
        "Sniltre",
        "Drecktenk",
        "Drultrull",
        "Drektars",
        "Gilkra",
        "Zhignin",
        "Tartenk",
        "Gildel",
        "Theelthe",
        "Thocktat",
        "Tetli",
        "Groktell",
        "Frondrall",
        "Brithreld",
        "Shentut",
        "Dratren",
        "Geignull",
        "Wantri",
        "Scheidlat",
        "Ghilthell",
        "Dilwet",
        "Schecktig",
        "Braackic",
        "Drunthild",
        "Scheltrir",
        "Schnonden",
        "Driknell",
        "Khelthu",
        "Deggi",
        "Wuthi",
        "Draaddlad",
        "Fraggil",
        "Tokle",
        "Ghignars",
        "Grenthig",
        "Fruttu",
        "Frindul",
        "Ghaggurs",
        "Krelthig",
        "Kriegel",
        "Waggla",
        "Woldru",
        "Teknull",
        "Doldeg",
        "Dildic",
        "Kreldan",
        "Zottlall",
        "Kruddle",
        "Khieddle",
        "Sniggle",
        "Schneckhan",
        "Tolschi",
        "Ghondi",
        "Ghidmer",
        "Khaaktic",
        "Krukla",
        "Bettluc",
        "Shockhu",
        "Bettlu",
        "Schithurs",
        "Zalwac",
        "Snarthmuld",
        "Schnaguck",
        "Braalwi",
        "Willer",
        "Schniglac",
        "Kignind",
        "Gulkrun",
        "Khital",
        "Dendirs",
        "Khettlall",
        "Sheigglit",
        "Ghudli",
        "Dirthmeld",
        "Brerdrel",
        "Zaicktars",
        "Thackhick",
        "Fralwi",
        "Grocneld",
        "Zeedmur",
        "Dridman",
        "Schocktec",
        "Brickal",
        "Baknun",
        "Ghalwull",
        "Schutli",
        "Zecktend",
        "Fralwi",
        "Grocneld",
        "Zeedmur",
        "Dridman",
        "Schocktec",
        "Brickal",
        "Baknun",
        "Ghalwull",
        "Schutli",
        "Zecktend",
      ],
      "♀": [
        "Telvil",
        "Snaslie",
        "Kragga",
        "Grivi",
        "Hisge",
        "Fraseirre",
        "Nitrali",
        "Usheschi",
        "Grenyeeryi",
        "Thavyaldi",
        "Emkid",
        "Daslaill",
        "Frelga",
        "Kranteis",
        "Snackness",
        "Entadi",
        "Schnanaila",
        "Usneirri",
        "Tarleilna",
        "Ghusgida",
        "Nalshidd",
        "Zilgein",
        "Tavyee",
        "Imkieh",
        "Frilgill",
        "Snangeshi",
        "Iskeldi",
        "Alvenne",
        "Nurgailla",
        "Mankerra",
        "Schnerge",
        "Sata",
        "Grutrah",
        "Bushiedd",
        "Esges",
        "Ghagneilka",
        "Bulgine",
        "Uthirte",
        "Lervierre",
        "Shesnada",
        "Schnigdiss",
        "Snilgee",
        "Time",
        "Nasges",
        "Kerlee",
        "Schnantialda",
        "Ulschera",
        "Itailda",
        "Darseildi",
        "Samkaddi",
        "Yugas",
        "Sutiel",
        "Walseedd",
        "Seski",
        "Schnursis",
        "Nervalni",
        "Itildi",
        "Umgedi",
        "Bertalni",
        "Kresnilna",
        "Grullas",
        "Krunyan",
        "Sethre",
        "Wirshess",
        "Birsidd",
        "Yighischa",
        "Schnunyiera",
        "Fraseni",
        "Lirthalni",
        "Lirtilna",
        "Scharsaill",
        "Igdeill",
        "Higda",
        "Khudle",
        "Henya",
        "Snegdaldi",
        "Telmeelka",
        "Numkaddi",
        "Esidda",
        "Kranyidda",
        "Scharsaill",
        "Igdeill",
        "Higda",
        "Khudle",
        "Henya",
        "Snegdaldi",
        "Telmeelka",
        "Numkaddi",
        "Esidda",
        "Kranyidda",
      ],
    },
    last: [
      "Copperbiter",
      "Crystalmender",
      "Emeraldmug",
      "Zirconhewer",
      "Fossileyes",
      "Topazsnatcher",
      "Sandpresser",
      "Groundmask",
      "Depositgetter",
      "Bouldersmelter",
      "Wedgemoulder",
      "Cobblepacker",
      "Wedgefoot",
      "Scrapmarker",
      "Grimebasher",
      "Jewelsniffer",
      "Moldbones",
      "Wedgesaver",
      "Rubbleeyes",
      "Tileknuckle",
      "Granitelifter",
      "Dirtloader",
      "Marblebone",
      "Tinvein",
      "Adamantsenser",
      "Wedgebender",
      "Steelsearcher",
      "Jointfingers",
      "Topazcarver",
      "Nickelskin",
      "Quartzneck",
      "Rockbones",
      "Groundcrusher",
      "Jadechecker",
      "Dustneck",
      "Rubblehewer",
      "Soilcarver",
      "Garnetchin",
      "Pitbrusher",
      "Terrapresser",
      "Sturdylegs",
      "Gemknuckle",
      "Amethystsealer",
      "Basaltbreaker",
      "Turfrinser",
      "Lapiswatcher",
      "Silverbrander",
      "Depositsenser",
      "Oreleg",
      "Lazuliteeth",
      "Claychest",
      "Wiresplitter",
      "Depositmolder",
      "Nuggetsorter",
      "Diamondbleacher",
      "Orecutter",
      "Scrapface",
      "Boulderdigger",
      "Moldgazer",
      "Cobblescanner",
      "Peridothead",
      "Seamdigger",
      "Lodgevein",
      "Rubydesigner",
      "Pitcatcher",
      "Tilewarper",
      "Nickelmaker",
      "Bedrockcleaner",
      "Lumpscanner",
      "Sapphirechin",
      "Moldneck",
      "Adamantbiter",
      "Lodgecarver",
      "Jadeneck",
      "Rubysearcher",
      "Stonycrusher",
      "Bouldergrasper",
      "Millvein",
      "Granitegrasper",
      "Sturdycleaner",
      "Agategazer",
      "Steelcollector",
      "Sapphireswitcher",
      "Obsidiancrusher",
      "Rockgazer",
      "Topazbasher",
      "Cobaltstasher",
      "Limescratcher",
      "Metalrinser",
      "Silversurveyor",
      "Spinelshoveler",
      "Zincpresser",
      "Cobaltleg",
      "Bronzegatherer",
      "Orecarver",
      "Blockstamper",
      "Shelffingers",
      "Marblecarver",
      "Goldchest",
      "Steelheart",
      "Spinelshoveler",
      "Zincpresser",
      "Cobaltleg",
      "Bronzegatherer",
      "Orecarver",
      "Blockstamper",
      "Shelffingers",
      "Marblecarver",
      "Goldchest",
      "Steelheart",
      "Leadchest",
      "Scrapheart",
      "Nickelteeth",
      "Alabasterpacker",
      "Amethystvein",
      "Depositmaker",
      "Ingotstasher",
      "Amethystmoulder",
      "Geodetemperer",
      "Moldsweeper",
      "Lazulipresser",
      "Lazulibasher",
      "Tinfist",
      "Turfbleacher",
      "Onyxseizer",
      "Alabasterbleacher",
      "Lazulibrander",
      "Soilcarver",
      "Wirepusher",
      "Basaltleg",
      "Alabastermelter",
      "Goldcollector",
      "Zirconfeet",
      "Graveleyes",
      "Smeltfist",
      "Millsenser",
      "Jewelsealer",
      "Moldchest",
      "Rockmaker",
      "Slatescanner",
      "Rubblecutter",
      "Smeltpusher",
      "Steeltwirler",
      "Nuggetshaper",
      "Metalswitcher",
      "Turfsmelter",
      "Slabneck",
      "Slatesurveyor",
      "Terrabones",
      "Depositloader",
      "Smelttemperer",
      "Alabasterscanner",
      "Claygetter",
      "Coppervein",
      "Boulderstocker",
      "Zirconvein",
      "Lodgewarper",
      "Cobaltcarver",
      "Basaltbones",
      "Jointpacker",
      "Ingotneck",
      "Clayskin",
      "Adamantrecorder",
      "Ironstocker",
      "Marblesenser",
      "Obsidianbringer",
      "Amethystlifter",
      "Silvercounter",
      "Pebblegazer",
      "Lazulirecorder",
      "Wirerater",
      "Silvervein",
      "Zincmerger",
      "Lodgemarker",
      "Sapphirepacker",
      "Quartzchecker",
      "Agateshoveler",
      "Bronzefinder",
      "Turfrecorder",
      "Soilhewer",
      "Rocksearcher",
      "Basaltear",
      "Turfbleacher",
      "Fossilmender",
      "Metalcleanser",
      "Terramerger",
      "Alabastermoulder",
      "Peridotscanner",
      "Roughseizer",
      "Jointrinser",
      "Rocksearcher",
      "Basaltear",
      "Turfbleacher",
      "Fossilmender",
      "Metalcleanser",
      "Terramerger",
      "Alabastermoulder",
      "Peridotscanner",
      "Roughseizer",
      "Jointrinser",
    ]
  },
  "Tabaxi": {
    first: {
      neutral: [
        "Feather in the Wind (Feather)",
        "Garden of Flowers (Flower)",
        "Call to Action (Action)",
        "End of Winter (Winter)",
        "Rain in Summer (Rain)",
        "Kind Link (Kind)",
        "Ancient Robin (Robin)",
        "Three Beast (Beast)",
        "Humble Lock (Lock)",
        "Fragrant Candle (Candle)",
        "Rays of the Sun (Ray)",
        "Bite Marks (Bite)",
        "Hot Flame (Flame)",
        "Grass of Spring (Grass)",
        "Scarf in Summer (Scarf)",
        "Odd Edge (Odd)",
        "Quick Cannon (Cannon)",
        "Ethereal Cart (Cart)",
        "Bizarre Block (Block)",
        "Light Peak (Peak)",
        "Shadow of a Star (Shadow)",
        "Fire in the Distance (Fire)",
        "Taste of Fruit (Taste)",
        "Sunshine at Night (Sunshine)",
        "Afternoon Nap (Nap)",
        "Four Tome (Four)",
        "Tranquil Stitch (Tranquil)",
        "Five Shore (Shore)",
        "Faint Riddle (Riddle)",
        "Clever Block (Block)",
        "Aurora of Winter (Aurora)",
        "Blossoms in Summer (Blossom)",
        "Busy Bee (Bee)",
        "Print of an Animal (Animal)",
        "Guest at Home (Guest)",
        "Silent Chance (Silent)",
        "Magic Boot (Magic)",
        "Lost Tale (Tale)",
        "Hearty Light (Light)",
        "Seven Grass (Seven)",
        "Fall of Water (Water)",
        "Sky Full of Stars (Sky)",
        "Song of Paradise (Song)",
        "Glow of the Sun (Sun)",
        "Flower of Ivory (Ivory)",
        "Pure Light (Pure)",
        "Little Straw (Straw)",
        "Mystery Guide (Mystery)",
        "Nimble Thing (Nimble)",
        "Brash Sparkle (Brash)",
        "Peak of Mountains (Peak)",
        "Dust on the Road (Dust)",
        "Rhythm of Drums (Rhythm)",
        "Looping Coil (Coil)",
        "Cannon on Deck (Cannon)",
        "Two Owl (Two)",
        "Magic Riddle (Riddle)",
        "Seven Wave (Wave)",
        "Elite Mirror (Mirror)",
        "Hearty Spark (Hearty)",
        "End of Winter (Winter)",
        "Call of a Bird (Bird)",
        "Kite in the Wind (Kite)",
        "Daydream at Night (Dream)",
        "Dangling Button (Button)",
        "Violet Gift (Violet)",
        "Hearty Fire (Hearty)",
        "Brave Thunder (Brave)",
        "Wild Wonder (Wonder)",
        "Four Canvas (Canvas)",
        "Screech of Bats (Bat)",
        "Breath of Fresh Air (Breath)",
        "Flickering Flame (Flame)",
        "Candle in the Dark (Candle)",
        "Night of Dreams (Night)",
        "Gentle Lock (Lock)",
        "Hushed Frog (Hushed)",
        "Free Song (Free)",
        "Light Brain (Light)",
        "Lone Chance (Chance)",
        "Drifting Snowflake (Snowflake)",
        "Aurora of Winter (Aurora)",
        "Rain of Fall (Rain)",
        "Steady Rock (Rock)",
        "Smooth as Silk (Silk)",
        "Proud Rain (Proud)",
        "Opal Scarf (Scarf)",
        "Gold Mask (Gold)",
        "Eager Peak (Peak)",
        "Three Smoke (Smoke)",
        "Lock on an Open Door (Lock)",
        "Game of Chance (Game)",
        "Carriage on the Road (Road)",
        "Luck of the Draw (Luck)",
        "Paint on a Canvas (Paint)",
        "Active Candy (Active)",
        "Bold Bush (Bold)",
        "Odd Jewel (Jewel)",
        "Icy Snowflake (Icy)",
        "Lucky Link (Link)",
        "Blazing Fire (Blaze)",
        "Scratch on Wood (Scratch)",
        "Wonder of the World (Wonder)",
        "Taste of Fruit (Taste)",
        "Honey of Bees (Honey)",
        "Radiant Song (Radiant)",
        "Merry Clover (Clover)",
        "Amused Clock (Clock)",
        "Silent Wonder (Silent)",
        "Mystery Boat (Boat)",
        "Lock on an Open Door (Lock)",
        "Looping Coil (Coil)",
        "Winter Breath (Winter)",
        "Cannon on Deck (Cannon)",
        "Berry Bush (Bush)",
        "Swift Hide (Hide)",
        "Hushed Snow (Hushed)",
        "Austere Chance (Austere)",
        "Icy Feather (Feather)",
        "Grand Candle (Candle)",
        "Little Flower (Little)",
        "Afternoon Nap (Nap)",
        "Honey of Bees (Honey)",
        "Autumn Harvest (Autumn)",
        "Wish Upon a Star (Wish)",
        "Elegant Veil (Elegant)",
        "Secret Shadow (Secret)",
        "Ruby Drop (Ruby)",
        "Opal Song (Opal)",
        "Lone Stone (Lone)",
        "Thrill of Life (Thrill)",
        "Branch of a River (River)",
        "Ticking Clock (Clock)",
        "Sparkle of Light (Sparkle)",
        "Sound of the Drum (Drum)",
        "Bold Storm (Storm)",
        "Elegant Candle (Candle)",
        "True Silk (Silk)",
        "Angelic Frog (Angelic)",
        "Smooth Board (Board)",
        "Breath of Fresh Air (Breath)",
        "Wave on the Shore (Wave)",
        "Bush in the Forest (Forest)",
        "Fragrance of Spring (Spring)",
        "Summer Afternoon (Summer)",
        "Half Tale (Half)",
        "Bright Clover (Clover)",
        "Vibrant Sparkle (Vibrant)",
        "Ancient Grass (Grass)",
        "Odd Dust (Odd)",
        "Serpent Scale (Scale)",
        "Burden of Chains (Chains)",
        "Belly of a Beast (Beast)",
        "Taste of Fruit (Taste)",
        "Hidden Depths (Depth)",
        "Cheeky Robin (Robin)",
        "Fine Card (Card)",
        "Arctic Marble (Arctic)",
        "True Riddle (Riddle)",
        "Ancient Needle (Ancient)",
        "Lightning After Thunder (Lightning)",
        "Summer Afternoon (Summer)",
        "Remnants of History (Remnant)",
        "Roar of a Bear (Roar)",
        "Fish in the River (River)",
        "Two Card (Card)",
        "Pure Garden (Pure)",
        "Six Deer (Deer)",
        "Light Chains (Light)",
        "Sweet Carriage (Sweet)",
        "Tree in the Woods (Tree)",
        "Melting of Snow (Snow)",
        "Tale of Wonder (Tale)",
        "Remnants of History (Remnant)",
        "Hawk Feather (Hawk)",
        "Elegant Cake (Elegant)",
        "Misty Bush (Bush)",
        "Ancient River (Ancient)",
        "Bronze Guest (Guest)",
        "Rare Quill (Quill)",
        "Tale of Wonder (Tale)",
        "Afternoon Nap (Nap)",
        "Fish in the River (River)",
        "Piece of the Puzzle (Piece)",
        "Scarf in Summer (Scarf)",
        "Misty Bush (Misty)",
        "Elegant Rain (Elegant)",
        "Quirky Tome (Quirky)",
        "Gold Stripe (Stripe)",
        "Sapphire Flame (Sapphire)",
        "Answered Riddle (Riddle)",
        "Aurora of Winter (Aurora)",
        "Burning Desire (Desire)",
        "Owl in the Morning (Owl)",
        "Veil of a Mask (Veil)",
        "Cheeky Scarf (Scarf)",
        "Half Stream (Half)",
        "Amused Chalk (Amused)",
        "Bold Drop (Drop)",
        "Prime Bite (Prime)",
      ]
    },
    last: [
      "The Dual Crag Clan",
      "The Twisting Wilds Clan",
      "The Abandoned Barrens Clan",
      "The Fragrant Torrent Clan",
      "The Protecting Crag Clan",
      "The Bleak Isles Clan",
      "The Mellow Mountain Clan",
      "The Everlasting Lagoons Clan",
      "The Radiant Coasts Clan",
      "The Anchored Hail Clan",
      "The Awoken Morass Clan",
      "The Growing Dune Clan",
      "The Dancing Marsh Clan",
      "The Meager Deluge Clan",
      "The Sleepy Marsh Clan",
      "The Tired Grotto Clan",
      "The Reflecting Wild Clan",
      "The Tired Deserts Clan",
      "The Blank Shores Clan",
      "The Awoken Grotto Clan",
      "The Velvet Crags Clan",
      "The Pure Mountain Clan",
      "The Everlasting Bluffs Clan",
      "The Bustling Lagoons Clan",
      "The Twirling Coasts Clan",
      "The Wailing River Clan",
      "The Expanding Glades Clan",
      "The Roaring Swamps Clan",
      "The Mild Barrens Clan",
      "The Enchanted Marsh Clan",
      "The Tranquil Rain Clan",
      "The Grieving Wild Clan",
      "The Ascending Isles Clan",
      "The Faraway Cliff Clan",
      "The Unraveling Islands Clan",
      "The Twirling Grotto Clan",
      "The Precious Grotto Clan",
      "The Bright Monsoon Clan",
      "The Silent Rainforest Clan",
      "The Roaming Woodland Clan",
      "The Awakening Jungle Clan",
      "The Unraveling River Clan",
      "The Misty Isles Clan",
      "The Snoring Rain Clan",
      "The Animated Desert Clan",
      "The Whispering Estuary Clan",
      "The Mellow Oasis Clan",
      "The Surging Forest Clan",
      "The Longing Wilds Clan",
      "The Basking Wilds Clan",
      "The Rumbling Islands Clan",
      "The Shimmering Islands Clan",
      "The Sleepy Forests Clan",
      "The Infinite Crag Clan",
      "The Scented Crags Clan",
      "The Hushed Coast Clan",
      "The Blossoming Isle Clan",
      "The Noiseless Glade Clan",
      "The Bustling Den Clan",
      "The Soothing Grotto Clan",
      "The Barren Islands Clan",
      "The Dancing Cavern Clan",
      "The Prowling Shores Clan",
      "The Dreary Mist Clan",
      "The Murky Wilderness Clan",
      "The Humble Sierra Clan",
      "The Rumbling Lakes Clan",
      "The Healing Mountain Clan",
      "The Gentle Caves Clan",
      "The Shimmering Monsoon Clan",
      "The Bellowing Fjords Clan",
      "The Fragile Woods Clan",
      "The Elder Lakes Clan",
      "The Faraway Bog Clan",
      "The Rumbling Oasis Clan",
      "The Light Isle Clan",
      "The Tumbling Mountains Clan",
      "The Whistling Oasis Clan",
      "The Cherished Shower Clan",
      "The Mountain Bayou Clan",
      "The Great Deserts Clan",
      "The Far Coasts Clan",
      "The Faraway Cliffs Clan",
      "The Mild Oasis Clan",
      "The Basking Mesa Clan",
      "The Roaming Slopes Clan",
      "The Rising Islands Clan",
      "The Pure Cliffs Clan",
      "The Cherished Crags Clan",
      "The Passing Rivers Clan",
    ]
  },
  "Triton": {
    first: {
      "♂": [
        "Mavas",
        "Dhalgas",
        "Nonvos",
        "Khulzus",
        "Khonvos",
        "Veles",
        "Veddes",
        "Jelmes",
        "Reles",
        "Khunnus",
        "Dhuvnus",
        "Domnos",
        "Dhemes",
        "Jevnes",
        "Culnus",
        "Varvas",
        "Kildis",
        "Zulus",
        "Dhuvnus",
        "Dohlos",
        "Cuvnus",
        "Junvus",
        "Jeddes",
        "Zahnas",
        "Zamas",
        "Kurzus",
        "Konos",
        "Kedes",
        "Runrus",
        "Kezes",
        "Manras",
        "Calas",
        "Darlas",
        "Vilgis",
        "Khezes",
        "Zaglas",
        "Doros",
        "Cihnis",
        "Zendes",
        "Zehres",
        "Moldos",
        "Monlos",
        "Jharzas",
        "Rarvas",
        "Morlos",
        "Vagas",
        "Jhugus",
        "Khonlos",
        "Jhiglis",
        "Comnos",
        "Jhivis",
        "Noros",
        "Joddos",
        "Dildis",
        "Zurlus",
        "Zerzes",
        "Janas",
        "Dhulgus",
        "Kelges",
        "Dhulmus",
        "Kovnos",
        "Vinis",
        "Johros",
        "Cehles",
        "Rudus",
        "Jelmes",
        "Nuddus",
        "Duhlus",
        "Dhiglis",
        "Dehles",
        "Jagas",
        "Dholzos",
        "Dolnos",
        "Memres",
        "Rohlos",
        "Jhudus",
        "Ralmas",
        "Dhunus",
        "Ronlos",
        "Rulus",
        "Derves",
        "Jigis",
        "Dhurlus",
        "Dhuzus",
        "Dendes",
        "Zolmos",
        "Comros",
        "Mavnas",
        "Nedes",
        "Karzas",
        "Vohlos",
        "Nilis",
        "Dhihnis",
        "Dirlis",
        "Dhindis",
        "Zinis",
        "Zivnis",
        "Khunlus",
        "Nolgos",
        "Kurlus",
        "Vilgis",
        "Dhimis",
        "Zenres",
        "Mazas",
        "Zurvus",
        "Karzas",
        "Zamas",
        "Nuldus",
        "Menres",
        "Nurus",
      ],
      "♀": [
        "Sadryn",
        "Vlurnyn",
        "Haten",
        "Shunlyn",
        "Wharyn",
        "Esnomen",
        "Multhihyn",
        "Itaryn",
        "Fonlolyn",
        "Esharyn",
        "Vlilyn",
        "Vlasyn",
        "Yugryn",
        "Egren",
        "Flalthyn",
        "Etlunyn",
        "Vlogledyn",
        "Shelruden",
        "Ushuryn",
        "Fithraren",
        "Dosnyn",
        "Dhelyn",
        "Bholyn",
        "Flaltyn",
        "Meshyn",
        "Mithladyn",
        "Adrenyn",
        "Dathromyn",
        "Shotronyn",
        "Fluhlehyn",
        "Emlyn",
        "Ethlyn",
        "Ulyn",
        "Vlotryn",
        "Iltyn",
        "Hosonyn",
        "Setunyn",
        "Bhehledyn",
        "Astaryn",
        "Olthoryn",
        "Homlen",
        "Udyn",
        "Itlen",
        "Oryn",
        "Dhorryn",
        "Flusohyn",
        "Yigrodyn",
        "Yerlonyn",
        "Minthohyn",
        "Olthunyn",
        "Ehnyn",
        "Oslyn",
        "Ohnyn",
        "Omlyn",
        "Vlosnyn",
        "Oddonyn",
        "Etluden",
        "Mutinyn",
        "Bhuhnulyn",
        "Mornenyn",
        "Ohlyn",
        "Heslyn",
        "Nuddyn",
        "Otren",
        "Lathyn",
        "Whusenyn",
        "Nilronyn",
        "Helthenen",
        "Urehyn",
        "Hugrumen",
        "Larlyn",
        "Onren",
        "Bheslyn",
        "Flostyn",
        "Yutryn",
        "Sotlemen",
        "Desturyn",
        "Emlulyn",
        "Altomyn",
        "Inrohyn",
        "Finthyn",
        "Burlen",
        "Feslyn",
        "Eden",
        "Yityn",
        "Uhlanyn",
        "Delunyn",
        "Midrahyn",
        "Ugrohyn",
        "Yetralyn",
        "Wenryn",
        "Wenlen",
        "Iglyn",
        "Noglyn",
        "Omlyn",
        "Lurnenyn",
        "Ladremyn",
        "Bhatlelyn",
        "Islomyn",
        "Fedreryn",
        "Shunlyn",
        "Anlyn",
        "Luthyn",
        "Hirnyn",
        "Wustyn",
        "Udrelyn",
        "Ilthahyn",
        "Shunlanyn",
        "Vlultheren",
        "Yethredyn",
        "Flornyn",
        "Shunryn",
        "Ilyn",
        "Vlilthyn",
        "Warlyn",
        "Yesanen",
        "Othroryn",
        "Othrodyn",
        "Benthuhyn",
        "Sotuhen",
        "Wholryn",
        "Mulryn",
        "Dholtyn",
        "Shonren",
        "Shelthyn",
        "Whodinyn",
        "Monlaryn",
        "Orlahyn",
        "Dinrihyn",
        "Nomlamyn",
        "Otren",
        "Noden",
        "Vlodryn",
        "Ustyn",
        "Ohnyn",
        "Floddulyn",
        "Etlomyn",
        "Wushimyn",
        "Shanrodyn",
        "Bethludyn",

      ]
    },
    last: [
      "Lomodath",
      "Vugalvath",
      "Vaghuzath",
      "Jadolmath",
      "Durozath",
      "Pumuxath",
      "Puhralmath",
      "Lahmosnath",
      "Aloxath",
      "Ubogath",
      "Gahrellath",
      "Doghanath",
      "Uvalath",
      "Gehresnath",
      "Mobelnath",
      "Degamnath",
      "Ragalnath",
      "Ahmursath",
      "Rohnunzath",
      "Ademnath",
      "Dahlolnath",
      "Amusath",
      "Ahnogath",
      "Namunath",
      "Zuhnalath",
      "Lahlonzath",
      "Vagolvath",
      "Munagath",
      "Pulasath",
      "Gunoxath",
      "Dhurasnath",
      "Suhrosath",
      "Lughuxath",
      "Zehralvath",
      "Dharuxath",
      "Vulorsath",
      "Munedath",
      "Pehnarath",
      "Zuralmath",
      "Vonerath",
      "Vahnursath",
      "Bomaxath",
      "Gabonzath",
      "Vamusnath",
      "Luhlasnath",
      "Uvarsath",
      "Buvollath",
      "Dhuhmoxath",
      "Borolvath",
      "Vuhlurath",
      "Zoghenzath",
      "Vamolnath",
      "Gughusath",
      "Abelnath",
      "Jamagath",
      "Ahranath",
      "Udonzath",
      "Muhlenzath",
      "Dhuhlonsath",
      "Mudolvath",
      "Zohmomnath",
      "Ovollath",
      "Lahnadath",
      "Ohronsath",
      "Uhmerath",
      "Pudomath",
      "Ehmagath",
      "Lumolvath",
      "Odalnath",
      "Bhahnelnath",
      "Zadamath",
      "Dehlanzath",
      "Maroxath",
      "Dhavuxath",
      "Jaroxath",
      "Radaxath",
      "Gunolmath",
      "Bhugoxath",
      "Zuganath",
      "Bhonulath",
      "Luresnath",
      "Zohmolmath",
      "Dahnedath",
      "Baghonsath",
      "Ugasath",
      "Vahremath",
      "Bhebersath",
      "Luramath",
      "Zulorath",
      "Zulodath",
      "Zadersath",
      "Dhurusnath",
      "Ragollath",
      "Jolagath",
      "Dhalarath",
      "Bhuvolath",
      "Zuhlagath",
      "Dohnozath",
      "Zalalnath",
      "Samexath",
      "Banelath",
      "Bamuzath",
      "Bhaghemath",
      "Ragunzath",
      "Abalath",
      "Pemegath",
      "Dhabenath",
      "Zehlazath",
      "Sulomath",
      "Burexath",
      "Ughadath",
      "Vabaxath",
      "Lenodath",
      "Mughaxath",
      "Jugomnath",
      "Palalnath",
      "Sahnanath",
      "Uhnoxath",
      "Puhlallath",
      "Golumnath",
      "Peghursath",
      "Juberath",
      "Ubodath",
      "Bhulesath",
      "Avolvath",
      "Rudorsath",
      "Daghaxath",
      "Donalnath",
      "Vuhnazath",
      "Bhehrurath",
      "Vobolmath",
      "Duhmullath",
      "Gumaxath",
      "Zuhmozath",
      "Avenath",
      "Unalath",
      "Dhughonzath",
      "Zulamath",
      "Zahnosnath",
      "Puhlosnath",
      "Muborath",
      "Bhuhromath",
      "Suranath",
      "Savolnath",
      "Mughosnath",
      "Zuganath",
      "Gubalvath",
      "Ubuxath",
      "Bhahnagath",
      "Vudonsath",
      "Bhugasnath",
      "Bhomalvath",
      "Bhahlasnath",
      "Rovasnath",
      "Suraxath",
      "Davelnath",
      "Gahnasath",
      "Dhulanath",
      "Pedomath",
      "Dhuhlolvath",
      "Dhuvarath",
      "Gumuxath",
      "Unomnath",
      "Modasnath",
      "Vugharath",
      "Bhuhlozath",
      "Rahlosath",
      "Zahlulnath",
      "Dahlorsath",
      "Lodalnath",
      "Ahlenath",
      "Sahlellath",
      "Bhuboxath",
      "Navorath",
      "Mohmulvath",
      "Mobesath",
      "Lerelnath",
      "Runesnath",
      "Juhnulvath",
      "Rohlelvath",
      "Lanelvath",
      "Mebalmath",
      "Lughalnath",
      "Daranzath",
      "Nebuxath",
      "Mudagath",
      "Vehlamnath",
      "Bavalmath",
      "Ahnoxath",
      "Vohlagath",
      "Ugholnath",
      "Dhogudath",
      "Zanalnath",
      "Numalvath",
      "Vunemath",
      "Sugenzath",
      "Neghonsath",
      "Vulonzath",
      "Dhamelmath",
      "Amaxath",
      "Nagholath",
      "Davadath",
      "Uhlolath",
      "Pulanzath",
      "Bhagalnath",
      "Abalmath",
      "Luhlogath",
      "Mudonsath",
      "Rogonsath",
      "Uhmasath",
      "Pughonsath",
      "Ubolnath",
      "Begalvath",
      "Mudomnath",
      "Amodath",
      "Abudath",
      "Bahnexath",
      "Raghanzath",
      "Vurasnath",
      "Dhagharsath",
      "Zubaxath",
      "Rahlorsath",
      "Ragadath",
      "Nahnenzath",
      "Garolmath",
      "Ehmolath",
      "Numaxath",
      "Lovemnath",
      "Bhemalvath",
      "Burenath",
      "Mahrosnath",
      "Duhnelath",
      "Ahromath",
      "Ledaxath",
      "Vuborath",
      "Punenzath",
      "Vunolmath",
      "Ugosath",
      "Ahragath",
      "Sadallath",
      "Oramath",
      "Guhnosnath",
      "Ramalath",
      "Luhmonsath",
      "Zabalvath",
      "Ehrolath",
      "Larezath",
      "Ohnollath",
      "Bahmurath",
      "Suhrasnath",
    ]
  },
  "Warforged": {
    first: {
      neutral: [
        "Creese",
        "Needle",
        "Metal",
        "Crusher",
        "Student",
        "Actor",
        "Gasher",
        "Shaper",
        "Slasher",
        "Armament",
        "Image",
        "Adviser",
        "Dozer",
        "Cutlass",
        "Expert",
        "Stick",
        "Case",
        "Animal",
        "Reader",
        "Buffet",
        "Shield",
        "Five",
        "Diagnoser",
        "Data",
        "Servant",
        "Observer",
        "Tester",
        "Pretender",
        "Salvager",
        "Sword",
        "Creature",
        "Spark",
        "Lurker",
        "Commander",
        "Snooper",
        "Tester",
        "Eraser",
        "Decipherer",
        "Creator",
        "Definer",
        "Tutor",
        "Probe",
        "Squasher",
        "Grunter",
        "Unmaker",
        "Chaser",
        "Responder",
        "Curator",
        "Tester",
        "Patient",
        "Scimitar",
        "Custodian",
        "Zero",
        "Sparkle",
        "Drone",
        "Whisperer",
        "Thunder",
        "Victor",
        "Adapter",
        "Relic",
        "Sprite",
        "Patient",
        "Expert",
        "Seeker",
        "Blade",
        "Giant",
        "Familiar",
        "Bruiser",
        "Seeker",
        "Twister",
        "Machine",
        "Sentry",
        "Beetle",
        "Novice",
        "Titan",
        "Killer",
        "Senser",
        "Apparatus",
        "Horn",
        "Kris",
        "Doctor",
        "Sprinter",
        "Cook",
        "Chaser",
        "Curator",
        "Stick",
        "Friend",
        "Coil",
        "Artist",
        "Curtana",
        "Ogler",
        "Carver",
        "Winner",
        "Bouncer",
        "Epee",
        "Horn",
        "Guide",
        "Nameless",
        "Guardian",
        "Help",
        "Claymore",
        "Bruiser",
        "Ghost",
        "Teacher",
        "Fighter",
        "Query",
        "Destroyer",
        "Slicer",
        "Mentor",
        "Expert",
        "Beetle",
        "Eraser",
        "Thief",
        "Nurse",
        "Heart",
        "Basher",
        "Safeguard",
        "Definer",
        "Beetle",
        "Charger",
        "Salvager",
        "Help",
        "Drone",
        "Fumbler",
        "Winner",
        "Commander",
        "Data",
        "Steeple",
        "Figure",
        "Teaser",
        "Cleaver",
        "Dozer",
        "Nemo",
        "Carrier",
        "Caster",
        "Tinkerer",
        "Echo",
        "Diagnoser",
        "Caster",
        "Menace",
        "Book",
        "Carer",
        "Help",
        "Fluke",
        "Passenger",
        "Horn",
        "Sentinel",
        "Steeple",
        "Masker",
        "Fumbler",
        "Delver",
        "Giant",
        "Dreamer",
        "Etcher",
        "Senior",
        "Smasher",
        "Pierce",
        "Grunter",
        "Thinker",
        "Killer",
        "Sword",
        "Prize",
        "Protector",
        "Bug",
        "Five",
        "Knife",
        "Special",
        "Adapter",
        "Bug",
        "Winker",
      ]
    }
  },
  "Goliath": {
    first: {
      "♂": [
        "Nagun",
        "Keoman",
        "Gamahg",
        "Thothag",
        "Egrhan",
        "Aurkhal",
        "Thonak",
        "Morein",
        "Iragith",
        "Geakein",
        "Mauzak",
        "Lakin",
        "Khuman",
        "Paroth",
        "Korazak",
        "Kanarad",
        "Gaulath",
        "Aurglath",
        "Ghalok",
        "Nautham",
        "Apadhan",
        "Viziath",
        "Kazavek",
        "Ergrok",
        "Thophak",
        "Lagith",
        "Vozak",
        "Lazavith",
        "Keovhik",
        "Graghan",
        "Aparad",
        "Neovhik",
        "Nakan",
        "Maralok",
        "Egkhal",
        "Meathi",
        "Zaugak",
        "Vaurathag",
        "Aurglath",
        "Pulok",
        "Lorovek",
        "Taranak",
        "Ladhan",
        "Pamahg",
        "Lazavhal",
        "Zanoth",
        "Apadak",
        "Laugath",
        "Pudath",
        "Vaurarein",
        "Eglok",
        "Thoglath",
        "Varaziath",
        "Iliphak",
        "Eggath",
        "Vaveith",
        "Thagal",
        "Ilakhal",
        "Kradath",
        "Kavaveith",
        "Zariak",
        "Mopath",
        "Ilazak",
        "Tauvhal",
        "Lakein",
        "Augnath",
        "Egtham",
        "Pagith",
        "Putham",
        "Ilarad",
        "Taralath",
        "Palig",
        "Vovek",
        "Gaunath",
        "Iligan",
        "Erggal",
        "Vaunoth",
        "Vaurarian",
        "Vipath",
        "Vauralok",
        "Thavalok",
        "Maramahl",
        "Lazalig",
        "Vegarok",
        "Armith",
        "Ghakin",
        "Irapath",
        "Aukan",
        "Vaurath",
        "Gakon",
        "Gramith",
        "Loroman",
        "Vaudhan",
        "Maukon",
        "Lalig",
        "Kavaveith",
        "Vauragan",
        "Narhan",
        "Vegatham",
        "Gaukin",
        "Aumak",
        "Vegagal",
        "Vaunath",
        "Kanarok",
        "Gauvhik",
        "Zaurian",
        "Eaggan",
        "Vaurakhal",
        "Vigal",
        "Vauranihl",
        "Thorhak",
        "Kavakon",
        "Vegakan",
        "Gradhan",
        "Neodath",
        "Augrad",
        "Zaurad",
        "Thavamahk",
        "Arrath",
        "Thomahk",
        "Neogath",
        "Maumith",
        "Egkan",
        "Varapath",
        "Arvoi",
        "Vauragal",
        "Ergkein",
        "Maulig",
        "Zamahg",
        "Irathi",
        "Eggal",
        "Thanak",
        "Lazakein",
        "Keorian",
        "Neovith",
        "Apagak",
        "Ilamahl",
        "Meamahk",
        "Vaughan",
        "Naziath",
        "Thavith",
        "Ilidak",
        "Aparad",
        "Aurglath",
        "Parok",
        "Aurian",
        "Vigan",
        "Kanagun",
        "Lazakhal",
      ],
      "♀": [
        "None",
        "Paania",
        "Lakia",
        "Naneo",
        "Pauma",
        "Pathu",
        "Thelea",
        "Vola",
        "Vovea",
        "Penu",
        "Thaukia",
        "Gaukha",
        "Lomi",
        "Paakio",
        "Gelneo",
        "Thukko",
        "Zaukko",
        "Diki",
        "Papeu",
        "Nilo",
        "Zoki",
        "Dinnio",
        "Zakko",
        "Peri",
        "Pania",
        "Gelkea",
        "Thaukeo",
        "Aregeo",
        "Lageo",
        "Zakha",
        "Kuori",
        "Zaania",
        "Dinnio",
        "Naurea",
        "Thalo",
        "Pauni",
        "Daari",
        "Daathe",
        "Anekeo",
        "Daagu",
        "Kauria",
        "Mepu",
        "Onegu",
        "Vaakha",
        "Vaula",
        "Vauri",
        "Nalane",
        "Daani",
        "Onekha",
        "Orima",
        "Gelnu",
        "Nane",
        "Daavi",
        "Orinea",
        "Zaulane",
        "Maulai",
        "Veki",
        "Zarea",
        "Panna",
        "Arethe",
        "Velu",
        "Kaumi",
        "Vema",
        "Vokko",
        "Pelo",
        "Inanna",
        "Mankio",
        "Zala",
        "Vuthi",
        "Thegu",
        "Pemi",
        "Pami",
        "Nokea",
        "Mannu",
        "Nalkko",
        "Nagia",
        "Navi",
        "Mankko",
        "Pekea",
        "Ilakia",
        "Vaapeu",
        "Vuggeo",
        "Gelrheo",
        "Merrea",
        "Orelane",
        "Vauki",
        "Vaurra",
        "Arennio",
        "Laugea",
        "Vethi",
        "Kuopu",
        "Vaugu",
        "Logeo",
        "Gekeo",
        "Manni",
        "Areggeo",
        "Ilakea",
        "Orelane",
        "Nalkko",
        "Paathia",
        "Theleo",
        "Kegeo",
        "Thevea",
        "Vaupeu",
        "Gemia",
        "Leni",
        "Zalane",
        "Zaukio",
        "Kuonna",
        "Kirea",
        "Maathi",
        "Kuolane",
        "Vukia",
        "Galni",
        "Maunea",
        "Kevea",
        "Orivia",
        "Thaulane",
        "Oripeo",
        "Zauvu",
      ]
    },
    middle: [
      "Frightkiller",
      "Stormdream",
      "Lumberheart",
      "Skyleader",
      "Brightvigor",
      "Rockweaver",
      "Stonetanner",
      "Silentcarver",
      "Highbearer",
      "Treevigor",
      "Smartlogger",
      "Tribetwister",
      "Tribespeaker",
      "Rootfist",
      "Treejumper",
      "Lowcaller",
      "Lowmender",
      "Mindheart",
      "Keenlander",
      "Treewatcher",
      "Swiftwanderer",
      "Wiseeye",
      "Mastersmasher",
      "Mountaincaller",
      "Tribeclimber",
      "Harddrifter",
      "Loneshot",
      "Lumberwatcher",
      "Highleaper",
      "Frightclimber",
      "Dawnworker",
      "Wisecarver",
      "Slyweaver",
      "Keenleaper",
      "Stonetwister",
      "Bravemaker",
      "Masterwalker",
      "Longspeaker",
      "Frightwarrior",
      "Dreamcaller",
      "Honestaid",
      "Brightguard",
      "Nightwatcher",
      "Frightleader",
      "Threadmaker",
      "Truthtwister",
      "Stormsmasher",
      "Lowjumper",
      "Brightleaper",
      "Fearlessshot",
      "Nightfrightener",
      "Threadworker",
      "Braverunner",
      "Goatlogger",
      "Wisefist",
      "Frightbreaker",
      "Hardworker",
      "Treeherder",
      "Silentlander",
      "Hidehunter",
      "Swiftsmasher",
      "Lumberfist",
      "Keenshot",
      "Wisepicker",
      "Wiselander",
      "Frightworker",
      "Loneweaver",
      "Swiftwalker",
      "Flowercarver",
      "Truewanderer",
      "Honestdrifter",
      "Hardmaker",
      "Swiftlander",
      "Woundstalker",
      "Truthleaper",
      "Wildkiller",
      "Stonecarver",
      "Mindwanderer",
      "Brightweaver",
      "Honestfrightener",
      "Lowherder",
      "Nighthunter",
      "Nightspeaker",
      "Deerhunter",
      "Tribestalker",
      "Frightmaker",
      "Truthfrightener",
      "Bearweaver",
      "Mountainherder",
      "Steadytwister",
      "Swiftsmasher",
      "Highhauler",
      "Treeleaper",
      "Hidehunter",
      "Bravestriker",
      "Dawnwalker",
      "Wildbearer",
      "Lonebearer",
      "Wanderchaser",
      "Lowbreaker",
      "Woundguard",
      "Wildmaker",
      "Wanderhunter",
      "Hardworker",
      "Rockwalker",
      "Mindcook",
      "Masterwalker",
      "Brightfriend",
      "Woundhand",
      "Longchaser",
      "Flintmaker",
      "Flintherder",
      "Silentlogger",
      "Stormguard",
      "Lumberbearer",
      "Fearlesswanderer",
      "Bravewarrior",
      "Masterfist",
      "Swiftaid",
      "Hideaid",
      "Bearguard",
      "Strongworker",
      "Foodhand",
      "Treesmasher",
      "Stormpicker",
      "Triberunner",
      "Mountainhauler",
      "Wanderfriend",
      "Deerwalker",
      "Dreamwalker",
      "Flintvigor",
      "Dawnwalker",
      "Bearmaker",
      "Foodstriker",
      "Bravewatcher",
      "Flintchaser",
      "Riverstalker",
      "Dreamwarrior",
      "Mountainguard",
      "Woundcaller",
      "Treecaller",
      "Wildbreaker",
      "Wanderheart",
      "Mastereye",
      "Mountainclimber",
      "Flintkiller",
      "Roothauler",
      "Silentstalker",
      "Fearlessbreaker",
      "Adeptfinder",
      "Smartwanderer",
      "Deerwatcher",
      "Flintaid",
      "Truthherder",
      "Honestdream",
      "Highguard",
      "Rockcarver",
      "Swiftdrifter",
      "Swiftwalker",
      "Hornstriker",
      "Lowbearer",
      "Trueherder",
      "Hidewarrior",
      "Hidefinder",
      "Dayfrightener",
      "Hornwarrior",
      "Stormmender",
      "Riverwalker",
      "Strongvigor",
      "Truthvigor",
      "Smartmaker",
      "Brighttanner",
      "Fearlessfist",
      "Honestshot",
      "Steadyeye",
      "Honestherder",
      "Dawntanner",
      "Flintstriker",
      "Wildpicker",
      "Slyfist",
      "Hornleader",
      "Flowermender",
      "Hornstriker",
      "Flintfist",
      "Rockclimber",
      "Riverwarrior",
      "Longcarver",
      "Foodchaser",
      "Wiseworker",
      "Steadytanner",
      "Flinthauler",
      "Riverhunter",
      "Mindcarver",
      "Hidevigor",
      "Rockweaver",
      "Wildspeaker",
      "Skyworker",
      "Lumbervigor",
      "Silentheart",
      "Swiftdrifter",
      "Dawnjumper",
      "Goataid",
      "Goatfinder",
      "Lonewalker",
      "Deerchaser",
      "Silentstriker",
      "Nightlogger",
      "Rootshot",
      "Woundvigor",
      "Strongeye",
      "Rockjumper",
      "Tribesmasher",
      "Truthcaller",
      "Stonewalker",
      "Strongmaker",
      "Adeptfist",
      "Wandercarver",
      "Truthherder",
      "Dawncook",
      "Stormdrifter",
      "Flowerstriker",
      "Mindpicker",
      "Smartmender",
      "Flinthand",
      "Highcarver",
      "Hornbearer",
      "Lowaid",
      "Longworker",
      "Rootleaper",
      "Stoneweaver",
      "Longhunter",
      "Riverleader",
      "Steadywarrior",
      "Lonemender",
      "Trueguard",
      "Treefinder",
      "Goatbearer",
      "Wandershot",
      "Wiseshot",
      "Dayfinder",
      "Steadymaker",
      "Rootpicker",
      "Woundwarrior",
      "Frightbearer",
      "Nightaid",
      "Rocktanner",
      "Swiftmaker",
      "Hidepicker",
      "Dawnfist",
      "Rootfist",
      "Smartmender",
      "Rootlogger",
      "Bravecaller",
      "Frightcaller",
      "Threadspeaker",
      "Hardmaker",
      "Silentheart",
      "Deerweaver",
      "Frightwarrior",
      "Highkiller",
      "Treeheart",
      "Treestriker",
      "Hornwanderer",
      "Masterweaver",
      "Slylogger",
      "Stoneherder",
      "Flintkiller",
      "Stormfriend",
      "Lumberrunner",
    ],
    last: [
      "Nugalakanu",
      "Munakupine",
      "Lakumithino",
      "Thenalupine",
      "Kalagiaga",
      "Muthalekali",
      "Thuligano",
      "Nalakukena",
      "Gathakakane",
      "Ganu-Mukena",
      "Ogolavea",
      "Kulumamino",
      "Vunakigane",
      "Egena-Vukate",
      "Agu-Vugate",
      "Thuluthea",
      "Ganu-Miaga",
      "Nola-Kugate",
      "Kalukukena",
      "Gathakakanu",
      "Ganu-Miaga",
      "Malukigo",
      "Thunukalathi",
      "Nulakiala",
      "Katho-Oliala",
      "Lakumutha",
      "Kulanatake",
      "Ugunigone",
      "Uthenu-Kupine",
      "Nalakigo",
      "Nulakigo",
      "Valu-Nukane",
      "Kolakalathi",
      "Gathakigone",
      "Kalagiano",
      "Malukakanu",
      "Vunakukate",
      "Thunukupine",
      "Athunatho",
      "Vuma-Thathala",
      "Athuniano",
      "Kulumulane",
      "Thunukiago",
      "Kalagatake",
      "Vaimei-Lileana",
      "Kulanukane",
      "Vunakupine",
      "Gathakigane",
      "Inulileana",
      "Kalukakume",
      "Ovethukate",
      "Vathunigala",
      "Apuna-Migano",
      "Kolae-Gekali",
      "Malukileana",
      "Inulolake",
      "Kalukekali",
      "Nola-Kiaga",
      "Ovethavi",
      "Ogolakume",
      "Thuliago",
      "Kalagugoni",
      "Egumigone",
      "Nulakatho",
      "Kolae-Gigone",
      "Thulalathi",
      "Apuna-Mekali",
      "Agu-Vulane",
      "Nugalulane",
      "Agu-Ulukate",
      "Katho-Oligane",
      "Uthenu-Kavi",
      "Elanamino",
      "Geanigone",
      "Egumelo",
      "Agu-Ulanathi",
      "Geanutha",
      "Anakalathai",
      "Elaniano",
      "Vuma-Thulane",
      "Kulanalathi",
      "Geanigala",
      "Thenaluthea",
      "Thunukukena",
      "Nola-Kileana",
      "Nulakigo",
      "Thuligano",
      "Gathakelo",
      "Vaimei-Lulane",
      "Vathuneaku",
      "Kolakiago",
      "Nugalathai",
      "Ugunithino",
      "Nola-Kigone",
      "Munakulane",
      "Thunukamune",
      "Vunakatho",
      "Ganu-Mulane",
      "Nulakavone",
      "Vuma-Thatake",
      "Vathuniago",
      "Athunaga",
      "Anakalugoni",
      "Muthalathala",
      "Egumigo",
      "Nalakiala",
      "Inulavi",
      "Uthenu-Kakane",
      "Veomalathi",
      "Ovethageane",
      "Kolakigo",
      "Apuna-Mathai",
      "Nola-Kigone",
      "Nalakatake",
      "Apuna-Mavea",
      "Kulumavone",
      "Agu-Vugate",
      "Vaimei-Liano",
      "Kulanakanu",
      "Munakiala",
      "Geanigala",
      "Valu-Nuthea",
      "Kolakamune",
      "Egumithino",
      "Ogolupine",
      "Vuma-Thakane",
      "Ganu-Migano",
      "Muthalageane",
      "Vathunekali",
      "Vathunukena",
      "Nulakavi",
      "Agu-Ulukane",
      "Thenalavea",
      "Egumulane",
      "Thenalukena",
      "Kulanavone",
      "Inulaga",
      "Ugunaga",
      "Nola-Kigano",
      "Veomageane",
      "Katho-Olatho",
      "Kulumukate",
      "Inulileana",
      "Inulukane",
      "Uthenu-Kiala",
      "Egumakanu",
      "Ogolavea",
      "Egumakane",
      "Thenalatho",
      "Kulanavone",
      "Kolae-Gigano",
      "Kulumatho",
      "Nalakolake",
      "Veomathai",
      "Nulakamune",
      "Agu-Ulanathi",
      "Kolae-Gatho",
      "Vunakigano",
      "Egumukate",
      "Ugunekali",
      "Valu-Nugoni",
      "Anakaligane",
      "Vaimei-Lolake",
      "Munakupine",
      "Athunutha",
      "Apuna-Mugoni",
      "Athuniala",
      "Vaimei-Lakanu",
      "Malukatho",
      "Ovethakane",
      "Kolae-Githino",
      "Katho-Olileana",
      "Ugunigo",
      "Anakalavea",
      "Thulugate",
      "Vaimei-Lukane",
      "Kalukukate",
      "Agu-Ulanathi",
      "Vathunavea",
      "Inulathala",
      "Inulavone",
      "Kolae-Ganathi",
      "Lakumigo",
      "Kalukiaga",
      "Ovethalathi",
      "Geaniala",
      "Kalukeaku",
      "Malukakanu",
      "Uthenu-Kuthea",
      "Vaimei-Lanathi",
      "Gathakalathi",
      "Vuma-Thigo",
      "Muthalakane",
      "Inuligala",
      "Anakalugoni",
      "Kolae-Gakane",
      "Ogolelo",
      "Kalagiano",
      "Ovethiano",
      "Ovetheaku",
      "Kalukolake",
      "Katho-Olugoni",
      "Vathunigane",
      "Inulavea",
      "Kulanulane",
      "Katho-Oliala",
      "Elanulane",
      "Katho-Oliago",
      "Vuma-Thalathi",
      "Ugunulane",
      "Gathakiala",
      "Kalukekali",
      "Agu-Ulatake",
      "Vunakiago",
      "Vathunanathi",
      "Vathunukate",
      "Apuna-Mavea",
      "Valu-Nupine",
      "Elanaga",
      "Kolakiago",
      "Athunugate",
      "Malukelo",
      "Ugunukane",
      "Katho-Olukane",
      "Kulanekali",
      "Egena-Vamune",
      "Ovethakanu",
      "Vaimei-Lukate",
      "Ogoligane",
      "Malukigane",
      "Thenalugate",
      "Nola-Kathai",
      "Nola-Kekali",
      "Malukithino",
      "Athuneaku",
      "Katho-Olukena",
      "Athunukena",
      "Elanavea",
      "Kulumageane",
      "Uthenu-Kakanu",
      "Egumavea",
      "Ogolelo",
      "Thulanathi",
      "Vunakigo",
      "Valu-Niala",
      "Egena-Vileana",
      "Vunakolake",
      "Apuna-Migane",
      "Anakalugoni",
      "Ugunigane",
      "Katho-Olugoni",
      "Ugunukane",
      "Thenalekali",
      "Vunakupine",
      "Ogolukena",
      "Agu-Vugoni",
      "Ovetheaku",
      "Inulathala",
      "Kalukeaku",
      "Veomekali",
      "Ugunakane",
      "Ganu-Mekali",
      "Geaniago",
      "Vathunuthea",
      "Ganu-Melo",
      "Agu-Vatho",
      "Nalakanathi",
      "Uthenu-Kageane",
      "Egumigala",
    ]
  },
  "Firbolg": {
    first: {
      "♂": [
        "Roceran",
        "Urimenor",
        "Carro",
        "Vanorin",
        "Umexalim",
        "Farpetor",
        "Yelquinal",
        "Lutumal",
        "Romenor",
        "Zinnelis",
        "Waeswraek",
        "Morfaren",
        "Wrandithas",
        "Umedithas",
        "Rokas",
        "Carkian",
        "Genpeiros",
        "Zumpeiros",
        "Ermenor",
        "Yinnorin",
        "Pabalar",
        "Paven",
        "Elfir",
        "Iliquinal",
        "Pernan",
        "Yinlen",
        "Perxidor",
        "Norhorn",
        "Leoceran",
        "Ilikas",
        "Leonelis",
        "Tralamin",
        "Raloneiros",
        "Hernorin",
        "Ilitoris",
        "Urisalor",
        "Yelyarus",
        "Yelyarus",
        "Glynsandoral",
        "Heisalor",
        "Virmaer",
        "Fenwarin",
        "Yinhorn",
        "Naemaer",
        "Waesydark",
        "Virquinal",
        "Fenfir",
        "Hernelis",
        "Qilen",
        "Craxalim",
        "Umegeiros",
        "Omawraek",
        "Valamin",
        "Morjor",
        "Dordithas",
        "Adjeon",
        "Keafir",
        "Beitumal",
        "Kearis",
        "Trawarin",
        "Morpetor",
        "Ralozumin",
        "Trajor",
        "Zinpeiros",
        "Elneiros",
        "Pawraek",
        "Waestoris",
        "Wranfaren",
        "Wranjor",
        "Genvalur",
        "Heiyarus",
        "Miranan",
        "Sylmaer",
        "Qimaer",
        "Oloberos",
        "Wranwraek",
        "Keaberos",
        "Sarro",
        "Beiro",
        "Sylneiros",
        "Ercan",
        "Pajeon",
        "Petric",
        "Sylsandoral",
        "Vagolor",
        "Perqen",
        "Zinneiros",
        "Olodan",
        "Petzumin",
        "Perydark",
        "Naebalar",
        "Crahice",
        "Elquinal",
        "Sylbalar",
        "Elaquinal",
        "Daenan",
        "Zumzumin",
        "Heihorn",
        "Farhorn",
        "Naeqen",
        "Lufir",
        "Zumkas",
        "Vanorin",
        "Naebalar",
        "Dorvalur",
        "Qinmaer",
        "Yinydark",
        "Elamenor",
        "Virzumin",
        "Sarjor",
        "Zumxidor",
        "Herquinal",
        "Keatumal",
        "Norhorn",
        "Herberos",
        "Glynbalar",
        "Naexalim",
        "Naeyarus",
        "Heigolor",
        "Aehice",
        "Fenceran",
        "Trasalor",
        "Romyar",
        "Norkian",
        "Norfir",
        "Naefaren",
        "Ilidithas",
        "Urigolor",
        "Trasandoral",
        "Thefir",
        "Wranris",
        "Vasandoral",
        "Naegeiros",
        "Thenorin",
        "Farxalim",
        "Traro",
        "Carbalar",
        "Petven",
        "Balmaer",
        "Zumxidor",
      ],
      "♀": [
        "Ulacaryn",
        "Enrieth",
        "Caivaris",
        "Presvyre",
        "Daphine",
        "Orijyre",
        "Adtris",
        "Eilkrana",
        "Jozorwyn",
        "Syllynn",
        "Quileth",
        "Inagwyn",
        "Bicaryn",
        "Yesthana",
        "Torrie",
        "Dawenys",
        "Addove",
        "Magrel",
        "Chaemoira",
        "Sylgwyn",
        "Faemoira",
        "Daeroris",
        "Miaynore",
        "Nericyne",
        "Valvaris",
        "Shathana",
        "Lorara",
        "Iargwyn",
        "Qiharice",
        "Valynore",
        "Xilfiel",
        "Eilrora",
        "Qira",
        "Wysafina",
        "Inawynn",
        "Caisatra",
        "Valrona",
        "Sylralei",
        "Iarthyra",
        "Xilgella",
        "Holaxisys",
        "Inanala",
        "Krisrie",
        "Bryqirelle",
        "Quisys",
        "Holana",
        "Liafina",
        "Bifina",
        "Xyrfina",
        "Orikrana",
        "Bira",
        "Wysathyra",
        "Inazorwyn",
        "Valthyra",
        "Faphyra",
        "Quiroris",
        "Urigella",
        "Shasatra",
        "Nerirora",
        "Ravahana",
        "Greqirelle",
        "Presgwyn",
        "Reydi",
        "Helephine",
        "Zinstina",
        "Brysys",
        "Triscaryn",
        "Yesqirelle",
        "Dalana",
        "Liaxina",
        "Liahana",
        "Grevaris",
        "Oriphine",
        "Caiqirelle",
        "Sylqirelle",
        "Cailee",
        "Reyrel",
        "Holazorwyn",
        "Trissatra",
        "Presra",
        "Faevaris",
        "Quixina",
        "Grezana",
        "Faqirelle",
        "Quitris",
        "Venxina",
        "Iarbella",
        "Faedove",
        "Inara",
        "Chaemys",
        "Chaekalyn",
        "Kriscyne",
        "Uristina",
        "Tormys",
        "Helethyra",
        "Wysawynn",
        "Wynvaris",
        "Shajyre",
        "Magmoira",
        "Venrieth",
        "Keyxisys",
        "Zinvaris",
        "Gilynore",
        "Xyrzorwyn",
        "Yllazorwyn",
        "Magfina",
        "Arakalyn",
        "Chaenala",
        "Trisfiel",
        "Encaryn",
        "Zylxina",
        "Wysarona",
        "Enlynn",
        "Loramys",
        "Gillana",
        "Ulacaryn",
        "Phimys",
        "Xyrphine",
        "Olaharice",
        "Magleth",
        "Wysanala",
        "Quicaryn",
        "Zinwynn",
        "Quitris",
        "Magbella",
        "Valbella",
        "Ravalana",
        "Gilfina",
        "Qistina",
        "Grefiel",
        "Sylwynn",
        "Zylsys",
        "Eilfina",
        "Bryfina",
        "Miaralei",
        "Ulaleth",
        "Reynala",
        "Liarie",
        "Venzana",
        "Xillynn",
        "Iarsatra",
        "Nerihana",
        "Iarmoira",
        "Quithana",
        "Caizana",
        "Wysazorwyn",
        "Inathyra",
        "Wyncyne",
        "Iarlynn",
        "Uriroris",
        "Wynharice",
        "Qira",
        "Arafina",
        "Xyrxina",
        "Urizorwyn",
        "Iarwynn",
        "Daexina",
        "Darona",
        "Holabella",
        "Phiwynn",
        "Qihana",
        "Holarie",
        "Heleleth",
        "Trisnala",
        "Wysakalyn",
        "Heleleth",
        "Phirora",
        "Zinjyre",
        "Presphine",
        "Xilkrana",
        "Wysabanise",
        "Yesphine",
        "Torcyne",
        "Zinmoira",
        "Ravasys",
        "Urixina",
        "Zinna",
        "Valgella",
        "Faedi",
        "Magna",
        "Inaphine",
        "Adzana",
        "Gilbella",
        "Trislana",
        "Uriphyra",
        "Quiralei",
        "Caiqirelle",
        "Yllacyne",
        "Zinnala",
        "Philynn",
        "Inaphyra",
        "Zinxisys",
        "Kriszana",
        "Urigella",
        "Faefiel",
        "Trisralei",
        "Shabanise",
        "Valrieth",
        "Magsatra",
        "Trisvaris",
      ]
    },
    last: [
      "Willowvale",
      "Diamondheel",
      "Mossbrook",
      "Evenpath",
      "Mistflower",
      "Uldithrie",
      "Movenont",
      "Drevadrann",
      "Kaldrasos",
      "Quarinthra",
      "Mistbrook",
      "Rapidriver",
      "Birchbirth",
      "Winterflight",
      "Feathermind",
      "Yeilolascint",
      "Sheithith",
      "Surdonnostor",
      "Vulaeant",
      "Daeront",
      "Evenspell",
      "Bronzemind",
      "Ambergleam",
      "Firmoon",
      "Moondreamer",
      "Hastrasissis",
      "Hemohith",
      "Tuphinthravo",
      "Fuldrelkas",
      "Xeldri",
      "Goldwatcher",
      "Aspenheel",
      "Firrest",
      "Lunarblossom",
      "Fogriver",
      "Silphaxo",
      "Wollorin",
      "Rindrithra",
      "Lirda",
      "Aendral",
      "Greenguard",
      "Winterwhisper",
      "Azuregaze",
      "Woodsinger",
      "Dawnsmile",
      "Muredrann",
      "Quonesaeant",
      "Runtesar",
      "Tustorrostrient",
      "Yalelkaeann",
      "Gemspell",
      "Elmriver",
      "Aldersinger",
      "Stonegazer",
      "Birchshadow",
      "Yalirasso",
      "Vodronthrann",
      "Kisie",
      "Astria",
      "Rastronont",
      "Ashpath",
      "Birchhold",
      "Ashmane",
      "Wooddew",
      "Elmmoon",
      "Colthas",
      "Xoldrepha",
      "Shestraldossinn",
      "Murdonnosca",
      "Yallaea",
      "Oakenpride",
      "Cedarheart",
      "Azurespirit",
      "Fogshadow",
      "Starbreath",
      "Welphomodre",
      "Rastrime",
      "Fothrihe",
      "Misen",
      "Sondrephace",
      "Goldpride",
      "Amberhold",
      "Wintercrown",
      "Ravenfall",
      "Blueflight",
      "Yolphenolol",
      "Rororinn",
      "Dindran",
      "Culomase",
      "Shulle",
      "Sageheart",
      "Sunwalker",
      "Duskflower",
      "Rapidcloud",
      "Feathermane",
      "Landrori",
      "Ildosh",
      "Ullesh",
      "Drorish",
      "Unnoca",
      "Stonepath",
      "Aspenseeker",
      "Feathermane",
      "Birchgazer",
      "Evenbell",
      "Mevestoher",
      "Buxatholi",
      "Oldrarint",
      "Lephent",
      "Sorico",
      "Sungazer",
      "Emberhold",
      "Rapidgleam",
      "Duskheart",
      "Duskfond",
      "Tholpho",
      "Mentiphonos",
      "Lulihelesh",
      "Corimaeash",
      "Bephistenas",
      "Silverfond",
      "Aspensong",
      "Duskspark",
      "Swiftvale",
      "Azuresmile",
      "Billolka",
      "Yixaldrini",
      "Gaenniphen",
      "Thelarrann",
      "Suvovorra",
      "Sagelight",
      "Bronzebeam",
      "Ravenfall",
      "Azurefall",
      "Stargazer",
      "Eyxelandrenn",
      "Flolkentava",
      "Basherris",
      "Driltarra",
      "Silverbrook",
      "Diamondflow",
      "Woodmight",
      "Azureshadow",
      "Alderspell",
      "Siren",
      "Cedrophilkant",
      "Lialtistixon",
      "Verirrinan",
      "Beshienn",
      "Beechgift",
      "Amberpath",
      "Ashshadow",
      "Mistgazer",
      "Foggazer",
      "Hephalki",
      "Gethramath",
      "Xithahan",
      "Thucaldrarri",
      "Yeystiste",
      "Spiritspell",
      "Bluemane",
      "Mossshine",
      "Starpride",
      "Crimsongaze",
      "Thondrildessint",
      "Viva",
      "Erdesh",
      "Husiri",
      "Quisho",
      "Fallbirth",
      "Poplarflower",
      "Azureguard",
      "Evenfate",
      "Cedarshard",
      "Dresiint",
      "Seroli",
      "Vollanthre",
      "Ruviel",
      "Hundros",
      "Evenspirit",
      "Sparkriver",
      "Falldew",
      "Summerriver",
      "Rapidsong",
      "Veythia",
      "Altendrith",
      "Vintan",
      "Vononn",
      "Daphath",
      "Birchflower",
      "Oakengrove",
      "Aspenbrook",
      "Ambercloud",
      "Amberrest",
      "Alphadrii",
      "Ilo",
      "Iamen",
      "Shise",
      "Ethrisixith",
      "Stillspell",
      "Elderbrook",
      "Autumnfate",
      "Wildgrove",
      "Swiftwish",
      "Kuvol",
      "Oshanthro",
      "Ildal",
      "Xullen",
      "Sondresavann",
      "Starspell",
      "Featherbell",
      "Fogstar",
      "Forestflight",
      "Sparkgift",
      "Irnanthrua",
      "Wonedrime",
      "Ballon",
      "Ura",
      "Shaldrestri",
      "Gembirth",
      "Eagerlight",
      "Dawnrest",
      "Sunspirit",
      "Silentfate",
      "Mehistralki",
      "Curamor",
      "Yostronen",
      "Drure",
      "Vocandrethrir",
      "Evengazer",
      "Autumngrove",
      "Stillfate",
      "Greencrown",
      "Aldergazer",
      "Teivilthovi",
      "Riri",
      "Fasenanthren",
      "Thunolkith",
      "Gosherro",
      "Fallcloud",
      "Stillblossom",
      "Spiritpath",
      "Hazelpetal",
      "Rainflight",
      "Valose",
      "Osiselko",
      "Illoli",
      "Guvamestra",
      "Cisan",
      "Oceanbreath",
      "Stonesong",
      "Mistlight",
      "Aspenpetal",
      "Wildspark",
      "Fliesolkirra",
      "Gasciphonnis",
      "Fleivedrol",
      "Delthas",
      "Ivoce",
      "Poplarflow",
      "Featherguard",
      "Silverspirit",
      "Rainflower",
      "Beechvale",
      "Xoltho",
      "Relphescer",
      "Iessellalki",
      "Viroxexo",
      "Nondrasa",
      "Nightbreeze",
      "Emberfate",
      "Ravenwish",
      "Evenfate",
      "Wintergift",
      "Shorne",
      "Dolthoxor",
      "Shomiphindre",
      "Rononna",
      "Thoshisondrar",
      "Rapidspell",
      "Fallfate",
      "Bronzespirit",
      "Bronzestar",
      "Flowerwhisper",
      "Kassovendrosh",
      "Gulthash",
      "Kecalthostris",
      "Seman",
      "Lultholas",
      "Moonshadow",
      "Elmsense",
      "Goldfall",
      "Swiftpetal",
      "Ambershine",
      "Daldrorror",
      "Kuphiir",
      "Gustrophira",
      "Gurodranthrish",
      "Honnicor",
      "Lunarflower",
      "Aspenvale",
      "Elmshine",
      "Fallflower",
      "Silentbreeze",
      "Thiadriphi",
      "Thovendria",
      "Athoho",
      "Laeso",
      "Rielassann",
      "Azuremoon",
      "Cedardream",
      "Dewmight",
      "Oceanpetal",
      "Eagerbreath",
      "Flihiphemash",
      "Verascath",
      "Drithash",
      "Ihilthalis",
      "Histroman",
      "Bronzeflower",
      "Falldreamer",
      "Starshadow",
      "Windgleam",
      "Spiritseeker",
      "Quarophaphoth",
      "Yalthathro",
      "Eltonnath",
      "Inna",
      "Thathresisiir",
      "Windgleam",
      "Flowerpetal",
      "Fardream",
      "Crimsonlight",
      "Oceanmight",
      "Druldrastrinn",
      "Heistre",
      "Konnastres",
      "Thovilkaeas",
      "Thialthelorrann",
      "Mumer",
    ]
  },
  "Lizardfolk": {
    first: {
      neutral: [
        "Thorth",
        "Ki",
        "Lalle",
        "Mitrok",
        "Jhesheth",
        "Ilthot",
        "Islo",
        "Thulristru",
        "Itaacesj",
        "Thatothiank",
        "Thorth",
        "Ki",
        "Lalle",
        "Mitrok",
        "Jhesheth",
        "Ilthot",
        "Islo",
        "Thulristru",
        "Itaacesj",
        "Thatothiank",
        "Mu",
        "Sent",
        "Ethyn",
        "Vishra",
        "Rolrer",
        "Ithysk",
        "Thethru",
        "Thradhegert",
        "Shupognenk",
        "Trupocaorth",
        "Ke",
        "Rau",
        "Nolthaar",
        "Taorert",
        "Aepont",
        "Vashro",
        "Kodhi",
        "Rugroste",
        "Uslisse",
        "Rirgukhuss",
        "And",
        "Ent",
        "Thopin",
        "Jhorgach",
        "Lushrurt",
        "Shesly",
        "Gero",
        "Shorgosrov",
        "Teggikrush",
        "Rudhitok",
        "Lux",
        "Thex",
        "Isho",
        "Idrut",
        "Vedhe",
        "Trurthev",
        "Mugos",
        "Achegri",
        "Althossorth",
        "Ritaakhech",
        "Tosj",
        "Aek",
        "Jhoport",
        "Iltut",
        "Sichath",
        "Thaotrysk",
        "Birkord",
        "Trushekenk",
        "Kellutis",
        "Vorthetaak",
        "Suk",
        "Mauk",
        "Jhagart",
        "Trighot",
        "Rathrord",
        "Dilthot",
        "Ergysk",
        "Voghitrav",
        "Nulrukho",
        "Liggakryth",
        "Ek",
        "Rer",
        "Aeggurth",
        "Oggu",
        "Dughu",
        "Uslynt",
        "Ketirk",
        "Opekraan",
        "Naarhtusri",
        "Lergekaach",
        "Gank",
        "Trart",
        "Bathrart",
        "Nertink",
        "Delrurk",
        "Methrit",
        "Throshynd",
        "Ogicu",
        "Orditro",
        "Ithissae",
        "Verd",
        "Nirth",
        "Saesluart",
        "Vashrath",
        "Kiti",
        "Ege",
        "Olther",
        "Ruchestre",
        "Urthireth",
        "Jhegrosess",
        "Liss",
        "It",
        "Noghech",
        "Uthisj",
        "Oryrth",
        "Viggy",
        "Oshro",
        "Kodrarrosj",
        "Kiggasri",
        "Tharthocunt",
        "Irt",
        "Tros",
        "Gutusk",
        "Erthy",
        "Sepuath",
        "Utrek",
        "Ditrok",
        "Regrissonk",
        "Althausset",
        "Thretastort",
        "Ur",
        "Iv",
        "Athrunk",
        "Bushy",
        "Shashronk",
        "Karird",
        "Keluss",
        "Lighasti",
        "Jhiskaatris",
        "Trartegnirk",
      ]
    },

    middle: [
      "Home",
      "Hate",
      "Split",
      "Journey",
      "Mountain",
      "Animal",
      "Axe",
      "Bowl",
      "Misery",
      "Black",
      "Nobody",
      "Tolerance",
      "Mist",
      "Double",
      "Chalk",
      "Reserve",
      "Bitter",
      "Patience",
      "Cub",
      "Beast",
      "Flight",
      "Wish",
      "Beach",
      "Pitch",
      "Trick",
      "Chain",
      "Respect",
      "Silver",
      "Chalk",
      "Alarm",
      "Bite",
      "Horn",
      "Patient",
      "Scent",
      "Liberty",
      "Trust",
      "Promise",
      "Stick",
      "Danger",
      "Spirit",
      "Spider",
      "Steel",
      "Night",
      "Brass",
      "Smile",
      "Pitch",
      "Signal",
      "Ghost",
      "Spider",
      "Steel",
      "Voyage",
      "Eternity",
      "Muscle",
      "Paste",
      "Fear",
      "Desire",
      "Freedom",
      "Shell",
      "Respect",
      "Steel",
      "Desire",
      "Locket",
      "Friend",
      "Bother",
      "Effect",
      "Nothing",
      "Guide",
      "Beast",
      "Slide",
      "Tool",
      "Theory",
      "Bite",
      "Board",
      "Bother",
      "Riddle",
      "Spell",
      "Cash",
      "Clue",
      "Edge",
      "Choice",
      "Battle",
      "Cell",
      "Gold",
      "Chain",
      "Dream",
      "Fear",
      "Emphasis",
      "Bean",
      "Fear",
      "Patience",
      "Gold",
      "Happy",
      "Fall",
      "Secret",
      "Love",
      "Habit",
      "Range",
      "Mist",
      "Iron",
      "Anger",
      "Will",
      "Habit",
      "Struggle",
      "Many",
      "Adventure",
      "Harmony",
      "Trick",
      "Prompt",
      "Sleep",
      "Border",
      "Spirit",
      "Trust",
      "Rose",
      "Tool",
      "Mountain",
      "Pleasure",
      "Song",
      "Orange",
      "Force",
      "Chest",
      "Thicket",
      "Dream",
      "Courage",
      "Chaos",
      "Light",
      "Wrath",
      "Crown",
      "Rainstorm",
      "Hearth",
      "Final",
      "Iron",
      "Double",
      "Rose",
      "Bother",
      "Bird",
      "Imagination",
      "Beast",
    ]
  },
  "Yuan-Ti": {
    first: {
      neutral: [
        "Uilsie",
        "Mosha",
        "Aztlal",
        "Huitu",
        "Iltli",
        "Yetsei",
        "Oaksoatih",
        "Zhutshollish",
        "Muklatu",
        "Sshohshalu",
        "Ezsee",
        "Zutszeell",
        "Thiska",
        "Zalki",
        "Eltli",
        "Sshoaltli",
        "Haltlehsiesh",
        "Estloasuh",
        "Hiksossieh",
        "Sholtluitash",
        "Imuss",
        "Zsustlish",
        "Astih",
        "Ihius",
        "Etsass",
        "Shuhas",
        "Zsuszoathi",
        "Thassasull",
        "Eltsussi",
        "Ultluizash",
        "Saska",
        "Iztlei",
        "Ssiliss",
        "Shohshu",
        "Sshultsa",
        "Taltli",
        "Missethi",
        "Muiktlothil",
        "Huklateell",
        "Thutstlosul",
        "Neszu",
        "Uhus",
        "Utszu",
        "Zatshull",
        "Thuihshi",
        "Nohtlih",
        "Olkalluil",
        "Szahloshlu",
        "Hazsashluss",
        "Sshetlethiu",
        "Uinu",
        "Ohis",
        "Shassih",
        "Zihshei",
        "Ata",
        "Uizsa",
        "Ssheszeha",
        "Hilshasih",
        "Zsihsotha",
        "Zhisizha",
        "Etla",
        "Usta",
        "Sikla",
        "Tuhuiss",
        "Ustil",
        "Hakui",
        "Thekihsah",
        "Semusi",
        "Essohu",
        "Sshohtloti",
        "Oktlui",
        "Toktleell",
        "Zhizsheess",
        "Yuiztla",
        "Seshu",
        "Humeesh",
        "Ssotstluilla",
        "Shalahi",
        "Szoahshoati",
        "Nultsazhil",
        "Nutszuss",
        "Sistliu",
        "Zsitza",
        "Zsolass",
        "Ssotziu",
        "Yeksa",
        "Moalkilliu",
        "Soltsuthi",
        "Maltlatias",
        "Hekhaluih",
        "Ssezsie",
        "Zhikhush",
        "Oakluh",
        "Thatsi",
        "Zsihshu",
        "Zsoshuh",
        "Zutshushih",
        "Toszuhsuh",
        "Zuzshasa",
        "Ssissuzhash",
        "Zaksi",
        "Yiltlill",
        "Zetlih",
        "Zihshu",
        "Utszuh",
        "Thuztleih",
        "Zhahuillu",
        "Zsolsihsuh",
        "Etszohsu",
        "Akheshi",
        "Shusei",
        "Yuzhiss",
        "Shemush",
        "Alsu",
        "Szultli",
        "Zhohtlush",
        "Selulla",
        "Yizhuheill",
        "Ekhehlui",
        "Sisseyi",
        "Etshu",
        "Astlie",
        "Tolsuih",
        "Azsee",
        "Oltlill",
        "Natlush",
        "Ssehloaziell",
        "Yoaltsohush",
        "Olkithuiss",
      ]
    }
  },
  "Githyanki": {
    first: {
      "♂": [
        "Jac",
        "V'a's",
        "Vrir'a'n",
        "Vrik'a'andoth",
        "Qolis",
        "Trylirdoc",
        "Vremas",
        "Gedis",
        "Darin",
        "G'a'anos",
        "Kyn",
        "Ran",
        "Ymon",
        "Vr'a'anas",
        "Gis'a'n",
        "Disan",
        "Rekon",
        "Rolath",
        "Gadan",
        "Ren",
        "R'a'adith",
        "Oric",
        "Lirildus",
        "Vanic",
        "Xith",
        "Viris",
        "Qoth",
        "Qin",
        "Da'arath",
        "Xapis",
        "Tram'i's",
        "Apan",
        "Trinilros",
        "Lodoc",
        "Rapis",
        "Gim'a'andus",
        "K'a'loth",
        "Velirus",
        "Ykis",
        "Qom'a'ardun",
        "Ek'a'adan",
        "Rados",
        "Kod'a'lron",
        "Vr'a'lath",
        "Ydanac",
        "Tryth",
        "Qaroth",
        "Jyl'a'n",
        "Vrik'a'n",
        "Qidondan",
        "Xelis",
        "Da'an",
        "Qymanas",
        "Rak'a'ardan",
        "Gadirath",
        "'A'pos",
        "Doras",
        "X'a'aric",
        "Leroth",
        "Lanos",
        "'A'rac",
        "A'aran",
        "K'a'sath",
        "Gek'a'an",
        "Lipoc",
        "Vros'a'adon",
        "Tropan",
        "Jamoth",
        "Vopin",
        "X'a'apas",
        "Vrop'i'ldan",
        "R'a'n",
        "Edic",
        "Vryn",
        "Akith",
        "L'a'apath",
        "Danorduc",
        "Trik'a'as",
        "Ak'a'th",
        "K'a'ath",
        "Xasath",
        "Ka'amondac",
        "Vap'a'rath",
        "Ledic",
        "Vrath",
        "Ryn",
        "Tron",
        "'A'apanath",
        "Dok'a'adus",
        "Lanodas",
        "Trakic",
        "As'a'ath",
        "Jydis",
        "Trynath",
        "Ed'i'th",
        "Jim'a'th",
        "Jos",
        "Xyd'i'n",
        "Koth",
        "Ekis",
        "Damac",
        "J'a'n",
        "Kamoc",
        "Odic",
        "Vris",
        "Keson",
        "Ros'a'c",
        "Rirolraith",
        "Tryrardan",
        "Rymath",
        "Verimus",
        "Xyron",
        "Gonoth",
        "Relon",
        "Qan",
        "Vresinath",
        "Vis'i'th",
        "Vram'a'lras",
        "Tr'a'roth",
        "Onath",
        "Xen",
        "Xim'i'th",
        "Ekith",
        "Adath",
        "Q'a'kan",
        "Gip'a's",
        "Rapos",
        "Girin",
        "Trid'i'th",
        "Qakath",
        "Gidos",
        "Joc",
        "Ikin",
        "Lip'i'c",
        "Dyth",
        "Trapoldon",
        "Kedas",
        "Dekan",
        "Yp'i'n",
        "Gyn",
        "Dilas",
        "Qon'a'ath",
        "Opoth",
        "Jas",
        "Gik'a'c",
        "Lemath",
        "Akith",
        "L'a'amos",
        "Eran",
        "Qa'asinduth",
        "Vr'a'alos",
        "Jec",
        "K'a'n",
        "Xirandac",
        "Qa'adonoc",
        "Lisic",
        "D'a's",
        "Ar'i'th",
        "Xiroth",
        "Xop'a'ath",
        "Vrosanuth",
        "Vronoth",
        "X'a'ath",
        "Volac",
        "Tren",
        "Ys'a'ac",
        "Ir'a'c",
        "Rypac",
        "Vremis",
        "Jydis",
        "Derith",
        "Vin",
        "Jod'a'c",
        "Xykith",
        "Qyk'i's",
        "Koron",
        "Lic",
        "Din'i's",
        "Op'a'th",
        "Jak'i's",
        "X'a'adoth",
        "Roc",
        "Jip'a'th",
        "Trilis",
        "Lenas",
        "Qyc",
        "Varas",
        "Ker'a'ac",
        "Vrem'a'n",
        "Kon",
        "Vopon",
        "Am'a's",
        "Disith",
        "Dik'a'aran",
        "Gam'i's",
        "Olon",
        "Elis",
        "Dynos",
        "Rosoloth",
        "Qopon",
        "Vik'a'ath",
        "Akon",
        "Ris",
        "Xyp'a'ac",
        "Vr'a'amolos",
        "Trolon",
        "Gisith",
        "Ra'apoth",
        "Ad'i'n",
        "Xonos",
      ],
      "♀": [
        "V'netrel",
        "Qetrarli'ith",
        "Qelra",
        "Ainduth",
        "Qistruth",
        "Zestruru",
        "Jillath",
        "Festrer",
        "Pirra",
        "Verra",
        "Yir'reth",
        "Yelre",
        "Qistru",
        "Jerste",
        "Jeh'zusyg",
        "Vir'rel",
        "Ellar",
        "B'nailezi",
        "Igreg",
        "Zan'lur",
        "Yimre",
        "Firelyth",
        "Zin'le",
        "Jarstar",
        "Jileth",
        "Qinul",
        "Qamrug",
        "B'nassu",
        "Zen'lath",
        "Yirsta",
        "Qaneg",
        "Yaatrul",
        "Yaarrug",
        "Qigrug",
        "Sairstasrel",
        "Finda",
        "Zairsta",
        "Salla",
        "Zen'lu",
        "Parradrar",
        "Yaineg",
        "Yetrel",
        "Sanar",
        "Paimrul",
        "Vailleg",
        "Sigra",
        "Qedrar",
        "Qih'zer",
        "Fatrel",
        "Zalag",
        "Qistru",
        "Zaandath",
        "Sadre",
        "Yaah'zesral",
        "Yadrer",
        "V'negrul",
        "Pelleg",
        "S'ratra",
        "Qirag",
        "B'nerrar",
        "Qedra",
        "Passusrur",
        "V'nirrul",
        "Istrug",
        "Zamrag",
        "Yimra",
        "Jah'zer",
        "Saarreg",
        "Segru",
        "S'ranar",
        "Zasse",
        "Assel",
        "B'lastredrer",
        "Jaagre",
        "Pih'zuth",
        "Vainu",
        "Versta",
        "Qinde",
        "Firral",
        "Zailleth",
        "Fagru",
        "V'nisse",
        "S'raileg",
        "Selre",
        "Qimrag",
        "B'laadra",
        "Zagru",
        "Qilrel",
        "Jala",
        "Verruth",
        "Sainda",
        "Jaar'rug",
        "Yaan'lar",
        "Zagrag",
        "Sissunath",
        "Falrudre",
        "Yaan'lel",
        "Varstag",
        "Sindezyr",
        "Zidrar",
        "Esse",
        "Qenerzug",
        "V'nemruth",
        "S'ressa",
        "Yilrulir",
        "Sagrer",
        "Qegreli",
        "Sellal",
        "Vilra",
        "Aallul",
        "Farrug",
        "Zih'zer",
        "Fetreluth",
        "Zamrer",
        "Ar'rer",
        "Pissulzy",
        "Samral",
        "Irste",
        "S'relra",
        "Yare",
        "Zilrug",
        "Vistrerze",
        "Jenu",
        "Fin'ler",
        "Zitrar",
        "Varrur",
        "Zaih'zur",
        "Elrul",
        "Paandag",
        "Zelrag",
      ]
    }
  },
  "Githzerai": {
    first: {
      "♂": [
        "Urmiak",
        "Amdran",
        "Xhamag",
        "Dalag",
        "Shramir",
        "Grakk",
        "Mallak",
        "Farmak",
        "Zrad",
        "Hulla",
        "Unkk",
        "Urarg",
        "Drarg",
        "Xhakh",
        "Und",
        "Grukk",
        "Hrazeg",
        "Muumak",
        "Drod",
        "Granrm",
        "Muuzar",
        "Amrad",
        "Nalla",
        "Azazar",
        "Drorm",
        "Azazag",
        "Brakk",
        "Kardar",
        "Zarlis",
        "Duudh",
        "Shragahr",
        "Darmak",
        "Amth",
        "Ferkk",
        "Arkiak",
        "Brarth",
        "Granruk",
        "Gromir",
        "Shrazar",
        "Ralag",
        "Orokk",
        "Mumak",
        "Xoth",
        "Bruth",
        "Duurag",
        "Branth",
        "Ardh",
        "Droram",
        "Khaniar",
        "Grarth",
        "Unak",
        "Furzak",
        "Rarek",
        "Srad",
        "Urgh",
        "Xodran",
        "Ranrm",
        "Xharm",
        "Zrallak",
        "Bardar",
        "Karkar",
        "Bardh",
        "Grerm",
        "Orad",
        "Brarth",
        "Darmiak",
        "Karath",
        "Brukar",
        "Groth",
        "Karkar",
        "Karnak",
        "Amzar",
        "Xogor",
        "Kazag",
        "Arazar",
        "Xorzth",
        "Ord",
        "Rutar",
        "Unakk",
        "Urth",
        "Ororath",
        "Razak",
        "Sarm",
        "Drorath",
        "Furk",
        "Ondh",
        "Brumiak",
        "Shagh",
        "Duugh",
        "Nuzag",
        "Orokk",
        "Hramiak",
        "Farth",
        "Onkk",
        "Rulahr",
        "Grankk",
        "Marmiak",
        "Fardh",
        "Drarth",
        "Furkk",
        "Nardh",
        "Oraniar",
        "Fadran",
        "Bralahr",
        "Ferdh",
        "Xath",
        "Muutar",
        "Shazar",
        "Azakran",
        "Brarg",
      ],
      "♀": [
        "Shundaya",
        "Helnines",
        "Arwnel",
        "Dolmanya",
        "Eszerath",
        "Kanira",
        "Meirilzin",
        "Khaziren",
        "Nalmaya",
        "Ilnela",
        "Eszilzin",
        "Adarin",
        "Lazekus",
        "Helneth",
        "Uralin",
        "Urerra",
        "Immilelzal",
        "Shamane",
        "Mirira",
        "Mirla",
        "Urin",
        "Lhasherah",
        "Ilnane",
        "Adena",
        "Dolmeya",
        "Meiryara",
        "Janeya",
        "Izalin",
        "Nahel",
        "Nahane",
        "Grelzin",
        "Aderra",
        "Umiris",
        "Ihreth",
        "Immileah",
        "Niarmina",
        "Marmera",
        "Ihrenah",
        "Izane",
        "Nagrah",
        "Shundmina",
        "Mirarah",
        "Almaka",
        "Nalmerah",
        "Almnera",
        "Ural",
        "Nalmeka",
        "Uwina",
        "Sharmalla",
        "Izarah",
        "Almane",
        "Shamel",
        "Dhareah",
        "Genryara",
        "Lazmira",
        "Almiza",
        "Lirla",
        "Meirilias",
        "Umenah",
        "Shamnera",
        "Ohnith",
        "Liraka",
        "Janenah",
        "Ezheya",
        "Kanalin",
        "Griniris",
        "Kanaya",
        "Loriren",
        "Niarina",
        "Vithnara",
        "Arwane",
        "Kanila",
        "Ihrarin",
        "Grinerra",
        "Ezheah",
        "Halmanya",
        "Miranya",
        "Vithilias",
        "Ihrerah",
        "Adarin",
        "Niarin",
        "Izerra",
        "Harnmina",
        "Kananith",
        "Janalin",
        "Krezalin",
        "Grinmina",
        "Liranya",
        "Marmeth",
        "Greleth",
        "Sharmzin",
        "Halmira",
        "Arwarin",
        "Helnerra",
        "Sharmelna",
        "Halmelzal",
        "Loranya",
        "Shamya",
        "Krezith",
        "Asha",
        "Adenah",
        "Nashira",
        "Grelilzin",
        "Draniza",
        "Halmekus",
        "Orenah",
        "Grelarin",
        "Ohnilzin",
        "Areah",
        "Janina",
        "Dolmal",
        "Nahina",
        "Helnelzal",
        "Erzhines",
        "Dranmina",
        "Eleah",
        "Arwerath",
        "Grinra",
        "Almra",
        "Shundalla",
        "Shamra",
        "Siliza",
        "Vithines",
        "Jananith",
        "Immiliren",
        "Dhariris",
        "Ihrayah",
        "Loral",
        "Naherath",
        "Halmith",
        "Dolmith",
        "Izra",
        "Shundanya",
        "Shamelzal",
        "Immayah",
        "Genrarah",
        "Lirzin",
        "Lhashanith",
        "Izayah",
        "Umaya",
        "Halmerath",
        "Immanith",
        "Dranilzin",
        "Ilneya",
        "Arniya",
        "Almnera",
        "Umines",
        "Nagrilias",
        "Khazela",
        "Grelaka",
      ]
    }
  },
  "Hobgoblin": {
    first: {
      neutral: [
        "Moldrurg",
        "Kundarz",
        "Androl",
        "Zrundag",
        "Zroldreluc",
        "Vulvar Saw",
        "Rholdrek The Red",
        "Dac Crush",
        "Rogerg The Insane",
        "Oknozer Stunt",
        "Kovrok",
        "Vokkorz",
        "Lozrar",
        "Radruvog",
        "Rag",
        "Noldrurg Whack",
        "Nondron The Dagger",
        "Rerg The Thief",
        "Ulvrurac The Corrupt",
        "Rakkak Saw",
        "Drankod",
        "Lorg",
        "Vrokrok",
        "Koldralorg",
        "Gazeneg",
        "Kuk Lash",
        "Garuc Bash",
        "Ondrerg Flare",
        "Vokkezor The Oaf",
        "Azerz Bellow",
        "Olvrezur",
        "Gradrag",
        "Ralvrezorz",
        "Arbarz",
        "Dukver",
        "Urdan Thunder",
        "Lol Froth",
        "Kreldrerz Stunt",
        "Ladrarg Blow",
        "Gruk The Shallow",
        "Rhakkuk",
        "Nenkurg",
        "Kondrec",
        "Vrakovol",
        "Lon",
        "Shukrek The Boar",
        "Rec Rebuke",
        "Shagrorz Sting",
        "Gorg Lurch",
        "Krekker Buckle",
        "Muk",
        "Shuzrol",
        "Sharz",
        "Krevlal",
        "Darg",
        "Drozovon The Brute",
        "Karag The Grim",
        "Arbarz Chomp",
        "Khec The Warlord",
        "Ardok The Red",
        "Lekvedec",
        "Kruzridec",
        "Kragrug",
        "Zrarz",
        "Khurz",
        "Dovlor Kill",
        "Shokned Dodge",
        "Dod Flare",
        "Nokevorz Grimace",
        "Kholzanog Lurch",
        "Gavlenurg",
        "Glezrurz",
        "Ererz",
        "Nardec",
        "Durdec",
        "Aguk Grin",
        "Klandralok Thrash",
        "Nedurz Scorch",
        "Grokravar The Harsh",
        "Rac The Harsh",
        "Uvan",
        "Druder",
        "Zrorerz",
        "Grolzer",
        "Rakkoc",
        "Gar Scrub",
        "Khulvruzog Butcher",
        "Glegek The Hook",
        "Erkunoc Crash",
        "Draral Jolt",
        "Azranuk",
        "Khurz",
        "Modrurz",
        "Drelzirud",
        "Rhokror",
        "Kroknarg Forge",
        "Kradrag Flail",
        "Nukan Grin",
        "Lundrul Froth",
        "Zrerkizad The Grand",
      ]
    }
  },
  "Bugbear": {
    first: {
      neutral: [
        "Tutorr",
        "Ghimkk",
        "Nurk",
        "Vorgurk",
        "Nuk",
        "Tath",
        "Ghurgak",
        "Khergir",
        "Zhovamkk",
        "Thorruth",
        "Nattath",
        "Tivulk",
        "Thigrark",
        "Zaddamkk",
        "Zhan",
        "Stok",
        "Notann",
        "Stigunk",
        "Raddur",
        "Khoghamkk",
        "Vugroth",
        "Vik",
        "Diggirr",
        "Vidduk",
        "Stunn",
        "Ravrork",
        "Noturk",
        "Tonk",
        "Rulk",
        "Rolk",
        "Guvronn",
        "Zodilk",
        "Gaghark",
        "Toghir",
        "Zudrarr",
        "Davrork",
        "Hrazath",
        "Dotronn",
        "Hrotunk",
        "Khazith",
        "Zhigoth",
        "Tidrarr",
        "Tumkk",
        "Duzzimkk",
        "Vivuk",
        "Khogoth",
        "Hrutoth",
        "Dan",
        "Bargomkk",
        "Thugrumkk",
        "Rizzank",
        "Chraddink",
        "Ginn",
        "Vitink",
        "Stegorr",
        "Duvrilk",
        "Nutronk",
        "Ghurdinn",
        "Vezzuk",
        "Virr",
        "Daddomkk",
        "Hronk",
        "Toth",
        "Ghogralk",
        "Chruvonk",
        "Viggor",
        "Broggirr",
        "Stetamkk",
        "Zhozzalk",
        "Stir",
        "Stughok",
        "Zhuk",
        "Chrir",
        "Gink",
        "Zonn",
        "Khork",
        "Khath",
        "Chrir",
        "Raghilk",
        "Dighalk",
        "Dodrilk",
        "Brurgonk",
        "Starnilk",
        "Ghank",
        "Tetinn",
        "Khork",
        "Gik",
        "Chrughinn",
        "Chretunk",
        "Brudrak",
        "Gar",
        "Chrordomkk",
        "Nugank",
        "Ghark",
        "Thuk",
        "Chrar",
        "Vugurr",
        "Girr",
        "Thok",
        "Zhuth",
      ]
    }
  },
  "Goblin": {
    first: {
      "♂": [
        "Crullak",
        "Credgos",
        "Zugdes",
        "Crogg",
        "Najig",
        "Snack",
        "Sleaze",
        "Mud",
        "Bait",
        "Limpcheeks",
        "Stuchirk",
        "Rigg",
        "Crug",
        "Terreb",
        "Vrit",
        "Halfmug",
        "Shrillbrain",
        "Candlestick",
        "Wideflank",
        "Oaftooth",
        "Dudguk",
        "Neglarg",
        "Godgerk",
        "Peg",
        "Jerg",
        "Malformed",
        "Maggotbrain",
        "Shrilleye",
        "Dirtnose",
        "Frogwart",
        "Zrerrurg",
        "Illark",
        "Get",
        "Edrorg",
        "Jurralk",
        "Brokenteeth",
        "Mudtooth",
        "Wartface",
        "Wartgob",
        "Snailwill",
        "Zrub",
        "Zrigrub",
        "Got",
        "Cis",
        "Gurdus",
        "Murkguts",
        "Deviant",
        "Ichornose",
        "Snailflab",
        "No-Eyes",
        "Zrudgelk",
        "Jaggalk",
        "Vragrurk",
        "Huglog",
        "Pok",
        "Flatchin",
        "Larva",
        "Goutguts",
        "Slimecheeks",
        "Oafteeth",
        "Vrag",
        "Sok",
        "Tardet",
        "Vudgeg",
        "Nork",
        "Sluglegs",
        "Grimelegs",
        "Shrillteeth",
        "Strangemaw",
        "Limpmug",
        "Vrurg",
        "Hullat",
        "Jiglot",
        "Nigg",
        "Cab",
        "Deviant",
        "Mudface",
        "Stubflab",
        "Mitemouth",
        "Shrillhead",
        "Ragg",
        "Stiga",
        "Turg",
        "Halk",
        "Zrajug",
        "Dirtmaw",
        "Leechhead",
        "Fatgob",
        "Insect",
        "Uglybones",
      ],
      "♀": [
        "Orgas",
        "Ildrus",
        "Gonvivo",
        "Pun",
        "Rilgru",
        "Mule",
        "Wideflank",
        "Uglyflank",
        "Deviant",
        "Wartflab",
        "Qagnar",
        "Rodras",
        "Irdruddis",
        "Belzan",
        "Arzinuk",
        "Madbrain",
        "Louse",
        "Maggotbrain",
        "Numbcheek",
        "Pighead",
        "Zubraldit",
        "Nalgrol",
        "Virdrur",
        "Gubruk",
        "Qerdrar",
        "Sleaze",
        "Strangemaw",
        "Bearbite",
        "Globarm",
        "Weasel",
        "Pilzit",
        "Qudra",
        "Venden",
        "Zurgen",
        "Delda",
        "Oafleg",
        "Wormtooth",
        "Moldguts",
        "Snotgob",
        "Uglycheek",
        "Dagnidis",
        "Dis",
        "Mer",
        "Genre",
        "Udrot",
        "Bentbrain",
        "Mitemouth",
        "Toadbrain",
        "Snailnose",
        "Frailwill",
        "Vundan",
        "Rurtak",
        "Zardra",
        "Pinvu",
        "Bolvat",
        "Snailbrain",
        "Ant",
        "Globeye",
        "Toadwart",
        "Greasemaw",
        "Jurtedal",
        "Qarzi",
        "Qot",
        "Elvu",
        "Qol",
        "No-Ears",
        "Mealworm",
        "Mule",
        "Limpcheeks",
        "Dirtface",
        "Golvalir",
        "Polzus",
        "Rindir",
        "Rolgit",
        "Punvik",
        "Greasecheek",
        "Breadstick",
        "Beardung",
        "Breadstick",
        "Chowder",
        "Durget",
        "Gunri",
        "Mirdrit",
        "Idinal",
        "Regru",
        "Bentbones",
        "Mealworm",
        "Dullleg",
        "Stinkknuckles",
        "Slimearms",
        "Jut",
        "Zinri",
        "Zok",
        "Invok",
        "Mudel",
        "Shrillleg",
        "Snotknuckles",
        "Froghead",
        "Brokeneye",
        "Spiderbait",
        "Not",
        "Bugol",
        "Vuldus",
        "Das",
        "Qertil",
        "Frailbrain",
        "Smugwill",
        "Pigmug",
        "Grubgrub",
        "Numbchin",
        "Orgas",
        "Ildrus",
        "Gonvivo",
        "Pun",
        "Rilgru",
        "Mule",
        "Wideflank",
        "Uglyflank",
        "Deviant",
        "Wartflab",
      ]
    }
  },
  "Tortle": {
    first: {
      neutral: [
        "Kon",
        "Krortlut",
        "Jibir",
        "Nualkur",
        "Dilden",
        "Alditt",
        "Iat",
        "Wug",
        "Akyc",
        "Giartok",
        "Qidi",
        "Kue",
        "Gelbyd",
        "Konun",
        "Ku",
        "Ye",
        "Kuc",
        "Nizlak",
        "Liani",
        "Xin",
        "Eppu",
        "Kronqill",
        "Siqwi",
        "In",
        "Xartlott",
        "Duertlim",
        "Du",
        "Ku",
        "Inutt",
        "Senlum",
        "Ukec",
        "To",
        "Lu",
        "Ematt",
        "Wanqwo",
        "Yo",
        "Jeldud",
        "Plozlin",
        "Wok",
        "Qanlad",
        "Bat",
        "Nebull",
        "Dibid",
        "Ueqwul",
        "Tunlell",
        "Go",
        "Jizy",
        "Jirtlac",
        "Plualdo",
        "Xortlun",
        "Yabug",
        "Winloc",
        "Xolbol",
        "Boppod",
        "Lue",
        "Jak",
        "Qerat",
        "Yak",
        "Uec",
        "Xoppam",
        "Boro",
        "Gizag",
        "Qud",
        "Golbum",
        "Wig",
        "Krilbutt",
        "Alell",
        "Krennom",
        "Nanqet",
        "Xardlid",
        "Quabyg",
        "Gulde",
        "Ibe",
        "Get",
        "Xuanlog",
        "Nuertla",
        "Ximuk",
        "Tuonqwag",
        "La",
        "Unqeg",
        "Krardlu",
        "Aldu",
        "Jortad",
        "Xunqitt",
        "Tuemo",
        "Kruk",
        "Sialok",
        "Denqa",
        "Xanlet",
        "Onir",
        "Guappem",
        "Seki",
        "Tik",
        "Derut",
        "Je",
        "Ol",
        "Idyk",
        "Krimi",
        "Bonle",
        "Sirud",
        "Juqwu",
        "Gac",
        "An",
        "Gilbom",
        "Damut",
        "Xet",
        "Koqwe",
        "Ill",
        "Kor",
        "Xezlon",
        "Qoqwatt",
        "Qinlad",
        "Tonuk",
        "Krunli",
        "Planlo",
        "Konqwa",
        "Buec",
        "Xalko",
        "Iled",
        "Yi",
        "Yidym",
        "Elel",
        "Enqwo",
        "Gulil",
        "Suri",
        "Lezig",
        "Wiled",
        "Jubo",
        "Xutt",
        "Nod",
      ]
    }
  },
  "Orc": {
    first: {
      "♂": [
        "Nall",
        "Run",
        "Dug",
        "Bhak",
        "Rhumzukk",
        "Ludakk",
        "Ukzukk",
        "Ghazzakk",
        "Zagvad",
        "Nongok",
        "Grull",
        "Drod",
        "Dron",
        "Dron",
        "Dhurlokk",
        "Magror",
        "Zhobzul",
        "Zhordoll",
        "Dongall",
        "Ogakk",
        "Zhob",
        "Gran",
        "Rok",
        "Dhuk",
        "Runzur",
        "Abrag",
        "Bhotud",
        "Ugol",
        "Modob",
        "Onal",
        "Lag",
        "Dol",
        "Jad",
        "Shuk",
        "Ghurzakk",
        "Amvag",
        "Umzukk",
        "Dhorlokk",
        "Lurzoll",
        "Ghuvall",
        "Lull",
        "Zhall",
        "Bhod",
        "Lob",
        "Duhrub",
        "Lokrag",
        "Ragval",
        "Jadzab",
        "Martok",
        "Lugvok",
        "Drok",
        "Dukk",
        "Mur",
        "Bog",
        "Ogvok",
        "Lomzar",
        "Manzokk",
        "Rhongog",
        "Rabrol",
        "Shovull",
        "Bok",
        "Lur",
        "Zakk",
        "Rug",
        "Rudgab",
        "Mozzar",
        "Shahrakk",
        "Rhabar",
        "Urtol",
        "Davoll",
        "Ghuk",
        "Bhol",
        "Lor",
        "Bol",
        "Raggub",
        "Jarukk",
        "Dhugul",
        "Drokrukk",
        "Jortokk",
        "Bharzokk",
        "Rab",
        "Jul",
        "Bral",
        "Ghab",
        "Nugvak",
        "Bokzol",
        "Dortun",
        "Adal",
        "Ubzokk",
        "Mundug",
        "Grak",
        "Rhall",
        "Buk",
        "Bhokk",
        "Arlol",
        "Nangakk",
        "Gukzukk",
        "Gudul",
        "Bhumak",
        "Amur",
        "Rhok",
        "Rug",
        "Gal",
        "Drul",
        "Ugzog",
        "Johrokk",
        "Gravug",
        "Ozukk",
        "Rhahrall",
        "Grovrur",
        "Jab",
        "Zokk",
        "Rag",
        "Joll",
        "Zunzull",
        "Ukron",
        "Drojor",
        "Grandor",
        "Drabrak",
        "Rartol",
        "Dhal",
        "Lob",
        "Grokk",
        "Mol",
        "Labzag",
        "Goddab",
        "Gukror",
        "Nomvug",
        "Dhonzor",
        "Agzull",
        "Rhur",
        "Bhur",
        "Bon",
        "Lub",
        "Jagub",
        "Ghojub",
        "Grungok",
        "Uzun",
        "Mudgog",
        "Dotokk",
        "Bholl",
        "Brukk",
        "Mukk",
        "Gull",
        "Zobrar",
        "Ghamvud",
        "Grukzur",
        "Gohzuk",
        "Drubral",
        "Monduk",
        "Zhokk",
        "Rhob",
        "Lug",
        "Zhur",
        "Junguk",
        "Rhujug",
        "Shunzakk",
        "Dograb",
        "Datok",
        "Dragzod",
        "Brak",
        "Dan",
        "Dhud",
        "Zokk",
        "Brudzor",
        "Zoddul",
        "Dazzor",
        "Burtull",
        "Zobzok",
        "Dojul",
        "Rob",
        "Juk",
        "Brall",
        "Ghog",
        "Arak",
        "Zotukk",
        "Ghunzan",
        "Zobok",
        "Shobruk",
        "Zhotod",
      ],
      "♀": [
        "Dhof",
        "Shul",
        "Kouf",
        "Rak",
        "Emvah",
        "Kognong",
        "Evda",
        "Aavuk",
        "Ivnif",
        "Zowgaa",
        "Zang",
        "Zam",
        "Gun",
        "Veh",
        "Nouvnu",
        "Bhigvif",
        "Ivak",
        "Gaatua",
        "Imduv",
        "Dhumvouk",
        "Gof",
        "Niz",
        "Gen",
        "Mah",
        "Angouk",
        "Ghivref",
        "Ghothul",
        "Yetham",
        "Kingi",
        "Hudkeng",
        "Ghing",
        "Mif",
        "Riel",
        "Zav",
        "Ivdum",
        "Kadgom",
        "Seedkaan",
        "Bhithi",
        "Iggaun",
        "Bhodve",
        "Deek",
        "Ghiek",
        "Dhaan",
        "Ring",
        "Bhanke",
        "Athro",
        "Eengak",
        "Relvong",
        "Vegnam",
        "Aadving",
        "Hiv",
        "Suh",
        "Dhez",
        "Vuf",
        "Bhovnang",
        "Soven",
        "Sonzih",
        "Awgih",
        "Venzi",
        "Dounzieh",
        "Bhal",
        "Maang",
        "Nang",
        "Sam",
        "Daagzam",
        "Kunseng",
        "Nankez",
        "Dhovde",
        "Bhamdah",
        "Ulvou",
        "Bhov",
        "Guk",
        "Dieng",
        "Rhum",
        "Vuwnku",
        "Kadgi",
        "Bhavrez",
        "Anzi",
        "Eellik",
        "Zolling",
        "Duam",
        "Nam",
        "Ghouv",
        "Mil",
        "Bemee",
        "Buathrie",
        "Ielli",
        "Ghigviz",
        "Ghaugvaah",
        "Vansem",
        "Niv",
        "Rhef",
        "Buz",
        "Duf",
        "Hinguv",
        "Muaddong",
        "Dhuvgiz",
        "Kiwnah",
        "Bevniv",
        "Omzouv",
        "Vol",
        "Ghaun",
        "Rez",
        "Vum",
        "Bhuaggiz",
        "Rhodka",
        "Magnyel",
        "Dillo",
        "Rhodkaf",
        "Rhotol",
        "Gom",
        "Vek",
        "Gah",
        "Bhel",
        "Bonke",
        "Dhodo",
        "Govri",
        "Keviz",
        "Vulzuz",
        "Zegzof",
      ]
    },
    middle: [
      "Chaos Slayer",
      "The Dark",
      "Gnome Slicer",
      "The Violent",
      "The Hollow",
      "The Arrogant",
      "Rib Destroyer",
      "The Deranged",
      "Joint Queller",
      "Joint Sunderer",
      "Black Hacker",
      "The Wrathful",
      "The Miscreant",
      "The Wretched",
      "Rib Squisher",
      "The Prime",
      "The Volatile",
      "Cold Cruncher",
      "The Crooked",
      "Head Quasher",
      "Flesh Cruncher",
      "The Wrathful",
      "Rib Brand",
      "Vermin Strangler",
      "The Broad",
      "The Loyal",
      "The Fierce",
      "The Barbarian",
      "The Violent",
      "The Broad",
      "The Repulsive",
      "Muscle Skinner",
      "The Vulgar",
      "Vein Queller",
      "Talon Burster",
      "The Merciless",
      "The Prime",
      "The Merciless",
      "Beast Squasher",
      "Giant Scalper",
      "The Filthy",
      "Toe Destroyer",
      "The Bloody",
      "The Mad",
      "The Lost",
      "The Smug",
      "The Defiant",
      "Blood Conquerer",
      "The Gross",
      "Fang Dagger",
      "The Filthy",
      "The Ancient",
      "Storm Conquerer",
      "The Grand",
      "The Wrathful",
      "The Berserk",
      "The Prime",
      "Power Defacer",
      "Smoke Sunderer",
      "Brain Sword",
      "The Forsaken",
      "Gore Squisher",
      "The Fierce",
      "The Ugly",
      "The Infernal",
      "The Powerful",
      "The Repulsive",
      "Throat Queller",
      "The Coarse",
      "Battle Smasher",
      "Cold Marauder",
      "The Deranged",
      "The Volatile",
      "The Maniac",
      "The Worthless",
      "The Miscreant",
      "Gnome Splitter",
      "The Wretched",
      "Cold Flayer",
      "Finger Gouger",
      "The Crazy",
      "The Frantic",
      "The Filthy",
      "Doom Scalper",
      "The Enraged",
      "The Worthless",
      "The Giant",
      "Slave Cracker",
      "Eye Dissector",
      "Talon Chopper",
      "Hate Chopper",
      "The Feisty",
      "The Fearless",
      "Tooth Snapper",
      "Finger Mutilator",
      "Foot Vanquisher",
      "The Deranged",
      "Heart Clobberer",
      "Power Burster",
      "The Miscreant",
      "The Ancient",
      "The Grand",
      "The Maniac",
      "Sorrow Destroyer",
      "The Angry",
      "The Broken",
      "The Putrid",
      "The Swift",
      "The Glorious",
      "Knee Hammer",
      "Talon Flayer",
      "Chin Slicer",
      "Giant Scalper",
      "The Crazy",
      "Storm Bruiser",
      "The Angry",
      "Head Sunderer",
      "Dream Shatterer",
      "Heart Despoiler",
      "The Crazy",
      "Bitter Snapper",
      "Doom Saber",
      "The Noxious",
      "Nose Razer",
      "Gore Gouger",
      "The Lethal",
      "The Swift",
      "Sorrow Bruiser",
      "The Infernal",
      "The Wretched",
      "Fiend Breaker",
      "Ghost Sword",
      "Brain Dissector",
      "Hell Dagger",
      "Flesh Dagger",
      "Horror Spear",
      "The Volatile",
      "The Glorious",
      "Feet Ripper",
      "Brass Queller",
      "The Brute",
      "Ghost Pummel",
      "Bone Snapper",
      "The Berserk",
      "Knee Glaive",
      "The Bloody",
      "Fiend Lance",
      "Vermin Razer",
      "Power Pummel",
      "Slave Crusher",
      "Death Squisher",
      "The Coarse",
      "Battle Gasher",
      "Dark Cleaver",
      "The Filthy",
      "The Maniac",
      "The Colossal",
      "The Brutal",
      "The Loyal",
      "The Noxious",
      "The Broad",
      "The Brute",
      "The Fearless",
      "The Butcher",
      "Spite Razer",
      "Steel Blade",
      "Doom Piercer",
      "Talon Slicer",
      "The Smug",
      "The Miscreant",
      "Giant Carver",
      "Dirt Sword",
      "The Cruel",
      "The Barren",
      "The Broken",
      "Muscle Cutter",
      "The Unsightly",
      "The Vulgar",
      "Pest Dagger",
      "The Coarse",
      "Rib Clobberer",
      "Fiend Hacker",
      "The Butcher",
      "Bone Splitter",
      "The Vulgar",
      "The Ancient",
      "The Disfigured",
      "The Smug",
      "Hell Slicer",
      "The Vulgar",
      "Thunder Glaive",
      "The Wicked",
      "Storm Bruiser",
      "The Proud",
      "Spine Saber",
      "Chaos Destroyer",
      "Teeth Saber",
      "The Vengeful",
      "Brass Clobberer",
      "The Venomous",
      "Storm Mutilator",
      "Heart Mutilator",
      "Bitter Squasher",
      "The Grand",
      "Iron Shatterer",
      "Gore Dissector",
      "The Unsightly",
      "The Shady",
      "The Fearless",
      "Finger Marauder",
      "Death Shatterer",
      "Joint Sunderer",
      "The Feisty",
      "Vein Flayer",
      "The Brutal",
      "The Wicked",
      "The Warped",
      "Knee Mutilator",
      "The Macabre",
      "Bitter Gasher",
      "Ghost Saber",
      "Brain Masher",
      "Gnome Razer",
      "The Simple",
      "Steel Sword",
      "The Feisty",
      "Ghost Spear",
      "The Gruesome",
      "Breath Hammer",
      "Heart Sword",
      "The Vulgar",
      "Doom Razer",
      "The Defiant",
      "Skin Gasher",
      "The Miscreant",
      "The Feisty",
      "The Deranged",
      "Nose Cruncher",
      "The Broken",
      "Knee Mutilator",
      "The Wrathful",
      "The Worthless",
      "Heel Cutter",
      "Toe Shatterer",
      "Spite Killer",
      "Steel Dissector",
      "The Swift",
      "Gnome Breaker",
      "Eye Quasher",
      "The Violent",
      "The Outlandish",
      "Beast Despoiler",
      "The Volatile",
      "Doom Brand",
      "Breath Mutilator",
      "The Worthless",
      "The Giant",
      "Vein Sabre",
      "The Infernal",
      "The Fearless",
      "The Hollow",
      "Flame Gasher",
      "Ghost Slicer",
      "The Unsightly",
      "The Radical",
      "Foot Sabre",
      "Nose Sunderer",
      "Venom Gouger",
      "Finger Render",
      "The Berserk",
      "Hell Snapper",
      "The Dark",
      "Skull Gasher",
      "The Vengeful",
      "Pride Chopper",
      "Hell Slayer",
      "The Forsaken",
      "Tooth Razer",
      "The Simple",
      "The Broken",
      "The Simple",
      "The Barren",
      "The Barbaric",
      "Black Breaker",
      "Brain Shatterer",
      "Brain Trampler",
      "The Gargantuan",
      "The Vicious",
      "The Wicked",
      "Pride Breaker",
      "The Outlandish",
      "Chin Scalper",
      "The Cold",
      "Dirt Cracker",
      "Chaos Ender",
      "Spite Vanquisher",
      "The Maniac",
      "Skin Crusher",
      "Hell Gasher",
      "Nose Clobberer",
    ]
  },
  "Duergar": {
    first: {
      "♂": [
        "Galdahr",
        "Gulren",
        "Maggarn",
        "Therbek",
        "Umthran",
        "Gralmyl",
        "Mordohr",
        "Thorgurn",
        "Muirgarn",
        "Amdrak",
        "Hjolmur",
        "Bromgrun",
        "Thergrun",
        "Thulgram",
        "Raggus",
        "Beldor",
        "Thurnyl",
        "Huldren",
        "Thomus",
        "Bunnur",
        "Dargron",
        "Gulkuhm",
        "Ammyr",
        "Hjulkohm",
        "Kharmor",
        "Gartharn",
        "Thymin",
        "Bheldren",
        "Hordek",
        "Emdir",
        "Hjulbek",
        "Kramkom",
        "Hargrom",
        "Therrig",
        "Thergron",
        "Torkuhm",
        "Farkom",
        "Umadin",
        "Hjolmur",
        "Hardrak",
        "Hjalrum",
        "Krumdrak",
        "Hultharn",
        "Thorgran",
        "Thurkahm",
        "Bharthrun",
        "Dalnik",
        "Grilnur",
        "Grymur",
        "Ebdain",
        "Ranmand",
        "Ragmiir",
        "Gramdor",
        "Brumdrom",
        "Raggran",
        "Melmiir",
        "Hulnom",
        "Armadin",
        "Urmdram",
        "Emdar",
        "Duldus",
        "Rangurn",
        "Mordor",
        "Thalnom",
        "Kharmiir",
        "Kromdrum",
        "Grandal",
        "Krommar",
        "Thartharm",
        "Darmand",
        "Umthrun",
        "Huliggs",
        "Thulmus",
        "Muirdor",
        "Thelgus",
        "Farrak",
        "Bhalmir",
        "Adnyl",
        "Horryl",
        "Melrigg",
        "Vonryl",
        "Germyl",
        "Thelrigg",
        "Tyadin",
        "Belkohm",
        "Tyrum",
        "Adrigg",
        "Magnir",
        "Morgurn",
        "Gramdan",
        "Grendar",
        "Hjulgarn",
        "Darnom",
        "Hultharm",
        "Torthrun",
        "Ragmir",
        "Grenkuhm",
        "Bhalrak",
        "Rotmun",
        "Ebrak",
        "Armdrum",
        "Dargron",
        "Bramkom",
        "Rotdahr",
        "Kromnik",
        "Gralmund",
        "Baldal",
        "Ragdrom",
        "Bromrik",
        "Daerdir",
        "Brambrek",
        "Tymun",
        "Adgurn",
        "Umkohm",
        "Hardohr",
        "Muirmun",
        "Ragnik",
        "Ebtharm",
        "Hurmek",
        "Ragdan",
        "Thormund",
        "Ermdal",
        "Belryl",
        "Bundir",
        "Gimrus",
        "Thurrak",
        "Maggarn",
        "Galmar",
        "Thalkuhm",
        "Randus",
        "Doldar",
        "Hortharn",
        "Regmin",
        "Granrus",
        "Granrak",
        "Ermdrum",
        "Berdrus",
        "Garmun",
        "Gulrus",
        "Armdor",
        "Hjalrik",
        "Bhalnom",
        "Amdur",
        "Gramkum",
        "Dardek",
        "Magmar",
        "Tharkohm",
        "Galkom",
        "Magrig",
        "Vonmin",
        "Grennus",
        "Hurdrak",
        "Baertharn",
        "Bhelnus",
        "Gralgus",
        "Hurdahr",
        "Bromnam",
        "Daerkyl",
        "Grydor",
        "Addal",
        "Ermnam",
        "Thulbrek",
        "Garmin",
        "Thelgrun",
        "Hordrak",
        "Dulkuhm",
        "Thalnur",
        "Gremmar",
        "Emnar",
        "Ranthran",
      ],
      "♀": [
        "Gwanlin",
        "Barrin",
        "Myslyn",
        "Bellemyl",
        "Myrvan",
        "Gwennyl",
        "Anria",
        "Bretnura",
        "Tyshnora",
        "Bellen",
        "Eddielle",
        "Ranglian",
        "Baernan",
        "Tasgiel",
        "Bretmora",
        "Kaitwyn",
        "Nasvian",
        "Runryn",
        "Bralnas",
        "Mistdryn",
        "Marleil",
        "Naervia",
        "Gwynnas",
        "Bralleri",
        "Bonnva",
        "Margwyn",
        "Bonngiel",
        "Nissora",
        "Belwynn",
        "Bonnnera",
        "Marnora",
        "Belmyla",
        "Brallenys",
        "Tyshnar",
        "Lysvan",
        "Lyssbelle",
        "Daerdish",
        "Nasrin",
        "Bryllelyn",
        "Bralselle",
        "Dearma",
        "Gwenleil",
        "Runria",
        "Naldeth",
        "Gwanra",
        "Nistin",
        "Lessglia",
        "Neslynn",
        "Rannip",
        "Lesryn",
        "Dimtin",
        "Karlynn",
        "Brallenora",
        "Lessora",
        "Brytwin",
        "Lessdelle",
        "Ingdish",
        "Belmora",
        "Rannura",
        "Jinnyl",
        "Marleen",
        "Nalselle",
        "Myrtyn",
        "Brulbelle",
        "Edlynn",
        "Kaittin",
        "Braenmura",
        "Bretlyl",
        "Einlen",
        "Bonnleil",
        "Raenros",
        "Daerdish",
        "Brilniss",
        "Marselle",
        "Daerrin",
        "Runthel",
        "Lysswyn",
        "Bonwaen",
        "Nallinn",
        "Gerrin",
        "Daerdryn",
        "Torris",
        "Brallenura",
        "Lyssmora",
        "Gemsael",
        "Bretgiel",
        "Sarmyl",
        "Nyslin",
        "Tisdielle",
        "Gymryl",
        "Myrlynn",
        "Mardielle",
        "Bonnnyl",
        "Elryn",
        "Bretra",
        "Runnas",
        "Tormura",
        "Barnas",
        "Tishlyn",
        "Maevvia",
        "Maevwynn",
        "Nysryn",
        "Tishbelle",
        "Torgwyn",
        "Brondelle",
        "Elwyn",
        "Gymris",
        "Brynros",
        "Nassdille",
        "Brynsael",
        "Tazdyl",
        "Anthiel",
        "Brynleil",
        "Tishdora",
        "Belleglian",
        "Brytri",
        "Karma",
        "Byltyn",
        "Soltin",
        "Mistmyla",
        "Gwenbera",
        "Brytdryn",
        "Anri",
        "Reynnys",
        "Branvan",
        "Mystbelle",
        "Brynnys",
        "Andish",
        "Nasstin",
        "Lesglia",
        "Bretnera",
        "Nesselle",
        "Misnera",
        "Bryllynn",
        "Tizbera",
        "Ednas",
        "Gemglia",
        "Brylvia",
        "Nisnan",
        "Lasres",
      ]
    },
    last: [
      "Gianthorn",
      "Orebreath",
      "Blasthonor",
      "Wrathbeard",
      "Reddrum",
      "Keendust",
      "Goreguard",
      "Goreforce",
      "Burnhorn",
      "Shadowbraid",
      "Hellsnapper",
      "Blackripper",
      "Doommaster",
      "Bronzebelt",
      "Onyxeye",
      "Stoneboots",
      "Wildmantle",
      "Fireripper",
      "Cravenfavor",
      "Keenbelch",
      "Steeltale",
      "Trollsnapper",
      "Wickedmarch",
      "Wrathforge",
      "Cravenforge",
      "Blackstrike",
      "Gorebreath",
      "Onyxbringer",
      "Battleripper",
      "Steelhunt",
      "Warbelt",
      "Ashmaster",
      "Neckbasher",
      "Gorefavor",
      "Hollowhead",
      "Bronzestand",
      "Blackmaster",
      "Blastcrag",
      "Mindcleaver",
      "Forgechampion",
      "Blastbelcher",
      "Darkfury",
      "Boldmantle",
      "Runehelm",
      "Bloodbrand",
      "Onyxhelm",
      "Underforge",
      "Rustfall",
      "Silentbrow",
      "Barrenflow",
      "Hellfight",
      "Mindstand",
      "Burnheart",
      "Stoutbreaker",
      "Burnminder",
      "Broadbleeder",
      "Viceslice",
      "Mindhead",
      "Brightforce",
      "Hollowchain",
      "Doomchain",
      "Stormcrusher",
      "Hellpast",
      "Foremight",
      "Underbuster",
      "Vicehorn",
      "Knifefight",
      "Steelhelm",
      "Blindtale",
      "Barrenhead",
      "Ironboots",
      "Bonegore",
      "Earthbattle",
      "Vicepast",
      "Trollrunner",
      "Goblinhonor",
      "Ironbringer",
      "Brightearth",
      "Bloodgift",
      "Goblinrock",
      "Grimreach",
      "Battleflayer",
      "Orebattle",
      "Broadchains",
      "Darkward",
      "Giantstone",
      "Viceearth",
      "Broadtale",
      "Firegift",
      "Rockchewer",
      "Boldheart",
      "Trollhorn",
      "Flamepast",
      "Keenbreaker",
      "Earthbuster",
      "Flamemarch",
      "Steelbeard",
      "Giantfall",
      "Doommantle",
      "Flamestorm",
      "Neckbrand",
      "Wickedfavor",
      "Fireminder",
      "Mindspite",
      "Ironmask",
      "Skullhunter",
      "Vicetale",
      "Steelguard",
      "Thunderhunter",
      "Battleeye",
      "Flameward",
      "Mindfight",
      "Battleheart",
      "Stormrock",
      "Bonesorrow",
      "Starklord",
      "Bronzehonor",
      "Bronzedrum",
      "Earthhand",
      "Boldrock",
      "Keenaxe",
      "Wildhandle",
      "Onyxmaster",
      "Blackchampion",
      "Burnbleeder",
      "Brokenbrand",
      "Battlemight",
      "Blindmaster",
      "Ironhammer",
      "Rustchain",
      "Redmaster",
      "Rockspite",
      "Ashrest",
      "Wickedfight",
      "Goblinbreaker",
      "Hellbelcher",
      "Rockbasher",
      "Burnhunter",
      "Goblinbattle",
      "Boldboot",
      "Hollowstone",
      "Brokenmarch",
      "Forgeguard",
      "Underhonor",
      "Madchampion",
      "Forgehunter",
      "Runepast",
      "Blastearth",
      "Starkguard",
      "Knifemarch",
      "Starkcrusher",
      "Keenhammer",
      "Flintbelch",
      "Broaddust",
      "Wrathfist",
      "Trollflayer",
      "Vengeslayer",
      "Bronzeboot",
      "Wickedbelcher",
      "Hellmask",
      "Underbrand",
      "Blindhelm",
      "Firesorrow",
      "Orebeard",
      "Ashforce",
      "Goblinslayer",
      "Giantward",
      "Hellmask",
      "Bronzebeard",
      "Neckcleaver",
      "Warbraid",
      "Vengehorn",
      "Neckbrand",
      "Thunderflow",
      "Darkchain",
      "Flintmight",
      "Trollcrusher",
      "Silentbuster",
      "Hollowbrand",
      "Bonebrand",
      "Steelfury",
      "Onyxstriker",
      "Knifehorn",
      "Burnpride",
      "Wrathslice",
      "Starkbleeder",
      "Flamesnapper",
      "Darksorrow",
      "Wrathedge",
      "Flintcleaver",
      "Redforge",
      "Battlebeard",
      "Wildbreaker",
      "Blackfist",
      "Firestrike",
      "Silentbleeder",
      "Broadfeast",
      "Thunderstone",
      "Battleheart",
      "Keenearth",
      "Wrathguard",
      "Warbelt",
      "Giantstorm",
      "Goblinsnapper",
      "Broadcleaver",
      "Brokenbuster",
      "Brightbrow",
      "Bloodearth",
      "Runebuster",
      "Rageward",
      "Underforge",
      "Boneforge",
      "Bonestand",
      "Forgepast",
      "Steelrock",
      "Ashstrike",
      "Thunderchain",
      "Keenstand",
      "Earthspite",
      "Forehorn",
      "Bonebasher",
      "Wrathbelt",
      "Skullheart",
      "Broadmight",
      "Ironcleaver",
      "Flintforce",
      "Thunderhand",
      "Shadowfall",
      "Broadbringer",
      "Neckripper",
      "Gravespite",
      "Flintfall",
      "Grimreach",
      "Rustaxe",
      "Vicechampion",
      "Brokenmight",
      "Rustbasher",
      "Ironbreath",
      "Moltenblade",
      "Brightsorrow",
      "Grimpast",
      "Thundercrag",
      "Flinthunt",
      "Cravenflayer",
      "Madfist",
      "Vicehand",
      "Goblindust",
      "Flintbrand",
      "Rockhandle",
      "Steeldrum",
      "Gravetale",
      "Shadowchampion",
      "Burnreach",
      "Firebreaker",
      "Knifesorrow",
      "Forgeguard",
      "Bronzedust",
      "Mindeater",
      "Stoutpass",
      "Warpast",
      "Cravenminder",
      "Hollowrunner",
      "Graveeater",
      "Madeater",
      "Starkforge",
      "Oreearth",
      "Boldboot",
      "Darkchewer",
      "Warhold",
      "Stonedrum",
      "Madchain",
      "Brokenbreath",
      "Warripper",
      "Rockripper",
      "Broadmask",
      "Ashfight",
      "Wrathblade",
      "Knifebleeder",
      "Madflayer",
      "Boneforge",
      "Cravenbuster",
      "Neckstriker",
      "Gorerunner",
      "Stonemarch",
      "Warmaster",
      "Hollowheart",
      "Runebattle",
      "Oreward",
      "Vengemantle",
      "Blasthand",
      "Ashbreath",
      "Darkhead",
      "Wildcleaver",
      "Bonestone",
      "Underfall",
      "Burnfavor",
      "Goreforge",
      "Maddust",
      "Moltenripper",
      "Flamefeast",
      "Stonestand",
      "Bronzeslayer",
      "Starkbattle",
      "Burnhandle",
      "Doomslice",
      "Shadowstriker",
      "Fireflow",
      "Stoutslice",
      "Neckbreaker",
      "Cravenmantle"
    ]
  },
  "Human": {
    first: {
      "♂": [
        //Medievaly
        "Hanry",
        "Azur",
        "Godelot",
        "Perez",
        "Brice",
        "Radolf",
        "Richie",
        "Raynerus",
        "Frideric",
        "Reimfred",
        "Berengerius",
        "Degore",
        "Adenot",
        "Richarde",
        "Bartelmeu",
        "Janshai",
        "Hugo",
        "Wymarc",
        "Christofur",
        "Dodd",
        "Gilow",
        "Brianus",
        "Aleyn",
        "Thim",
        "Jacke",
        "Lewis",
        "Drugo",
        "Hamlyn",
        "Daniel",
        "Milo",
        "Odibrand",
        "Hobard",
        "Mosse",
        "Gervis",
        "Gualterius",
        "Hamond",
        "Jessop",
        "Bartelemi",
        "Huffie",
        "Wilkie",
        "Hugline",
        "Nicolin",
        "Gawter",
        "Hendry",
        "Sanse",
        "Henry",
        "Christie",
        "Anfroy",
        "Joscelyn",
        "Hervi",
        "Mousse",
        "Jeremy",
        "Malcolm",
        "Garin",
        "Arnald",
        "Rolant",
        "Raaf",
        "Wat",
        "Aalart",
        "Hud",
        "Athelardus",
        "Hann",
        "Nigel",
        "Oddo",
        "Aalart",
        "Lambekin",
        "Josclyn",
        "Christofre",
        "Galien",
        "Audri",
        "Giraldus",
        "Reynald",
        "Gaiallard",
        "Curtis",
        "Gaylord",
        "Oger",
        "Yvain",
        "Willie",
        "Elias",
        "Jehan",
        "Christy",
        "Parsefal",
        "Rannulf",
        "Gawyn",
        "Owini",
        "Remi",
        "Huon",
        "Water",
        "Selle",
        "Rogerin",
        "Huguard",
        "Melcher",
        "Hervy",
        "Tam",
        "Jacomus",
        "Wimark",
        "Edun",
        "Adeite",
        "Alcock",
        "Guischard",
        "Guntram",
        "Wilburg",
        "Wighard",
        "Falko",
        "Astolfo",
        "Sachso",
        "Henry",
        "Bernfried",
        "Karl",
        "Hademar",
        "Wilmar",
        "Waldemar",
        "Gerolf",
        "Arwin",
        "Willibald",
        "Gonzalo",
        "Ingo",
        "Bodobert",
        "Eckbert",
        "Eike",
        "Roderick",
        "Ingolf",
        "Frank",
        "Ludger",
        "Emmerich",
        "Mombert",
        "Bodobert",
        "Bernfried",
        "Ortwin",
        // Magic Names
        "Necros",
        "Spellbound",
        "Prophesied",
        "Augurs",
        "Materia",
        "Abs",
        "Alchemight",
        "Hocuspo",
        "Charismas",
        "Eclipsed",
        "Necros",
        "Spellbound",
        "Prophesied",
        "Augurs",
        "Materia",
        "Abs",
        "Alchemight",
        "Hocuspo",
        "Charismas",
        "Eclipsed",
        "Practicioners",
        "Itzam",
        "Eclipsed",
        "Esoteriques",
        "Mythics",
        "Beyonders",
        "Augurs",
        "Spirited",
        "Kerei",
        "Obi",
        "Kerei",
        "Auroras",
        "Obeah",
        "Anima",
        "Abs",
        "Eclipsed",
        "Spellbound",
        "Hocuspo",
        "Conji",
        "Auras",
        "Kerei",
        "Orphic",
        "Diaboli",
        "Occult",
        "Abnormals",
        "Enlightened",
        "Peculiars",
        "Voyants",
        "Necromi",
        "Acroamatics",
        "Mystiques",
        "Arcandor",
        "Etheri",
        "Runi",
        "Kerei",
        "Seers",
        "Alchemight",
        "Mythica",
        "Charmed",
        "Diaboli",
        // Latin
        "Marcus",
        "Norbanus ",
        "Julianus",
        "Viridius",
        "Mellitus",
        "Proculus",
        "Caristanius",
        "Livianus",
        "Augustus",
        "Pinarius",
        "Decmus",
        "Camillus",
        "Aebutius",
        "Fortunatus",
        "Manius",
        "Macrinius",
        "Elerius",
        "Tertius",
        "Antistius",
        "Maximian",
        "Tullus",
        "Oranius",
        "Civilis",
        "Amulius",
        "Ceionius",
        "Nertomarus",
        "Lucius",
        "Sertorius",
        "Agricola",
        "Paulus",
        "Marcius",
        "Falco",
        "Cnaeus",
        "Icilius",
        "Tarquinius",
        "Tiberius",
        "Considius",
        "Ausonius",
        "Servius",
        "Sextius",
        "Hilaris",
        "Decius",
        "Tuccius",
        "Plautus",
        "Mettius",
        "Petellius",
        "Silanus",
        "Publius",
        "Betucius",
        "Iuvenlis",
        "Tertius",
        "Sidonius",
        "Horatius",
        "Secundus",
        "Sabucius",
        "Geta",
        "Publius",
        "Otacilius",
        "Camerius",
        "Decimus",
        "Publicius",
        "Faustinius",
        "Numerius",
        "Curtius",
        "Flavianus",
        "Vopiscus",
        "Caesius",
        "Catullus",
        "Sextus",
        "Sidonius",
        "Eumenius",
        "Tullus",
        "Cilnius",
        "Felissimus",
        "Decius",
        "Mamilius",
        "Medullinus",
        "Agrippa",
        "Loreius",
        "Telesinus",
        "Lar",
        "Verecundius",
        "Traianus",
        "Titus",
        "Vesuvius",
        "Fredisius",
        "Quintis",
        "Gavius",
        "Acacius",
        "Manius",
        "Arsinius",
        "Voteporix",
        "Nonus",
        "Accoleius",
        "Sylvius",
        "Gaius",
        "Canius",
        "Campanus",
        "Caius",
        "Caprenius",
        "Quintillius",
        "Mettius",
        "Fadius",
        "Quiriac",
        "Decimus",
        "Cispius",
        "Tyranus",
        "Paullus",
        "Vatinius",
        "Maltinus",
        "Opiter",
        "Cilnius",
        "Major",
        "Julianus",
        "Artorius",
        "Caerellius",
        "Caelus",
        "Antistius",
        "Augendus",
        "Gnaeus",
        "Cassius",
        "Telesinus",
        "Aulus",
        "Cordius",
        "Rufinianus",
        "Sisenna",
        "Bruttius",
        "Vibius",
        "Proculus",
        "Caesonius",
        "Falco",
        "Hostus",
        "Mallius",
        "Camillus",
        "Vel",
        "Icilius",
        "Naevius",
        "Tiberius",
        "Arsinius",
        "Eugenus",
        "Opiter",
        "Maelius",
        "Natalis",
        "Septimus",
        "Calavius",
        "Paterculus",
        "Arruns",
        "Cordius",
        //Pict
        "Oengus",
        "Cailtram",
        "Gest",
        "Drust",
        "Dúngal",
        "Caustantin",
        "Uvan",
        "Choinnich",
        "Uuroid",
        "Talore",
        "Uuredach",
        "Uoret",
        "Deoord",
        "Murtolic",
        "Mordeleg",
        "Erp",
        "Dhunghail",
        "Irb",
        "Lutrin",
        "Garnaith",
        "Galan",
        "Elphin",
        "Breth",
        "Caltram",
        "Dompneth",
        "Castantin",
        "Drust",
        "Breidei",
        "Ciniath",
        "Pidarnoin",
        "Mailcon",
        "Lutrin",
        "Gigurum",
        "Uist",
        "Cináeda",
        "Gartnaich",
        "Breth",
        "Cano",
        "Talorc",
        "Deocilunon",
        "Uuid",
        "Uven",
        "Galam",
        "Bili",
        "Brude",
        "Drest",
        "Fergus",
        "Wid",
        "Uudrost",
        "Castantin",
        "Constantin",
        "Dompneth",
        "Melcon",
        "Kinet",
        "Aenbecan",
        "Alpín",
        "Echach",
        "Deocillimon",
        "Erip",
        "Gailtram",
        "Angus",
        "Uuid",
        "Giric",
        "Vipoig",
        "Irb",
        "Gailtram",
        "Breidei",
        "Bridei",
        "Deocillimon",
        "Forcus",
        "Oswiu",
        "Galan",
        "Causantín",
        "Fochle",
        "Nehhton",
        "Uurad",
        "Gest",
        "Maelchon",
        "Elpin",
        "Munait",
        // Barb
        "Modr",
        "Jal",
        "Tis",
        "Rhamkem",
        "Namlell",
        "Igles",
        "Raestaelk",
        "Maebraeg",
        "Arnvavolf",
        "Khinwimkil",
        "Sgeith",
        "Skurr",
        "Fris",
        "Ghuldauf",
        "Ridraur",
        "Drirstem",
        "Meirstann",
        "Llanbend",
        "Rulgrettad",
        "Llungaelmelk",
        "Ith",
        "Sit",
        "Tyum",
        "Nethvoth",
        "Bhisskeld",
        "Snottfuth",
        "Jauyvelk",
        "Sgomlum",
        "Skismaemdund",
        "Snilskufkaelr",
        "Pald",
        "Lum",
        "Rard",
        "Khevull",
        "Khaulsven",
        "Vaethnund",
        "Virnokr",
        "Tugdald",
        "Prusreilgild",
        "Udrogsset",
      ],
      "♀": [
        //Medievaly
        "Johana",
        "Marione",
        "Osanna",
        "Maaline",
        "Matilde",
        "Adelaide",
        "Theophania",
        "Bela",
        "Marioth",
        "Ella",
        "Tilda",
        "Belet",
        "Emelot",
        "Tiphina",
        "Evelune",
        "Nibb",
        "Jocea",
        "Esclamonde",
        "Essylt",
        "Notekyn",
        "Johnnett",
        "Avilina",
        "Nanss",
        "Winefred",
        "Grissall",
        "Peronell",
        "Alson",
        "Moude",
        "Aubourc",
        "Tilda",
        "Alianora",
        "Milisent",
        "Cristiana",
        "Adete",
        "Mariorie",
        "Teffany",
        "Griselda",
        "Tomson",
        "Grissel",
        "Enmeline",
        "Emmeline",
        "Emelisse",
        "Ariana",
        "Mary",
        "Adelena",
        "Mathe",
        "Elizabethe",
        "Dimia",
        "Eluned",
        "Belet",
        "Branwyne",
        "Crislye",
        "Jean",
        "Swetyene",
        "Jehannete",
        "Ancreta",
        "Mirield",
        "Herleva",
        "Theffania",
        "Wilmot",
        "Elianora",
        "Till",
        "Winnifred",
        "Wannour",
        "Duraina",
        "Ysabelle",
        "Albray",
        "Maghenyld",
        "Hawis",
        "Izot",
        "Maynild",
        "Alia",
        "Grissell",
        "Hele",
        "Bayle",
        "Joanette",
        "Tibb",
        "Emelenine",
        "Roheisia",
        "Iohane",
        "Emelisse",
        "Alys",
        "Seluue",
        "Tilda",
        "Hermessent",
        "Isemeine",
        "Moll",
        "Tamsin",
        "Lynette",
        "Grissall",
        "Athelesia",
        "Hemin",
        "Sueteluue",
        "Malt",
        "Helouys",
        "Elizabet",
        "Ysmena",
        "Elwisia",
        "Aales",
        "Ahelis",
        "Murie",
        "Jismond",
        "Xristiana",
        "Tille",
        "Richolda",
        "Elizibetht",
        "Sulley",
        "Elizabet",
        "Tiffania",
        "Teffania",
        "Johnnett",
        "Gabriel",
        "Ankharet",
        "Alise",
        "Ewe",
        "Ysemay",
        "Emony",
        "Tilla",
        "Melisant",
        "Tille",
        "Tillie",
        "Cristy",
        "Alyon",
        "Matty",
        "Bibel",
        "Griseldis",
        "Tibby",
        "Malyn",
        "Nanny",
        "Helewis",
        "Joyce",
        "Bethia",
        "Malyn",
        "Susan",
        "Johana",
        "Maysaunt",
        "Libby",
        "Hermessent",
        "Rosemunda",
        "Ybot",
        "Adelin",
        "Alicia",
        "Albree",
        "Lilias",
        "Ingaretta",
        "Miriel",
        "Madallaine",
        "Athelesia",
        "Gennat",
        "Johna",
        "Bibele",
        "Imayne",
        "Ismenia",
        "Rikild",
        "Masse",
        "Elyscia",
        "Ysolt",
        "Elise",
        "Angmar",
        "Yvonne",
        "Heloys",
        "Ozanne",
        "Shusanna",
        "Jeanna",
        "Bette",
        "Roysia",
        "Ibbet",
        "Esobel",
        "Auelina",
        "Asceline",
        //Barb
        "Rheif",
        "Khaf",
        "Wath",
        "Thifno",
        "Atke",
        "Ghasguh",
        "Mulmoy",
        "Vasgan",
        "Halgrene",
        "Treangalgru",
        "Rhu",
        "Grei",
        "Jin",
        "Bhocunn",
        "Jastna",
        "Fungaf",
        "Umienn",
        "Dhugnhog",
        "Grislidli",
        "Rilgretthae",
        "Seh",
        "Dhes",
        "Wil",
        "Dushioth",
        "Svedfa",
        "Breslul",
        "Traeminn",
        "Bhottha",
        "Jageffe",
        "Varthiolmu",
        "Gol",
        "Dul",
        "Bhi",
        "Khormuh",
        "Kirnesh",
        "Shennhug",
        "Hoflef",
        "Starron",
        "Freshutti",
        "Bheignhustnu",
        "Khol",
        "Fuh",
        "Trel",
        "Fefeth",
        "Brildun",
        "Undrinn",
        "Frarrag",
        "Frionhis",
        "Viedathre",
        "Glundrukie",
        //Pictish
        "Oenga",
        "Cailtra",
        "Gesa",
        "Drusta",
        "Dúna",
        "Caustantina",
        "Uvana",
        "Choinnicha",
        "Uuroia",
        "Talora",
        "Uuredacha",
        "Uoretta",
        "Deoorda",
        "Murtolicta",
        "Mordeleg",
        "Erp",
        "Dhunghail",
        "Irb",
        "Lutrin",
        "Garnaith",
        "Galan",
        "Elphin",
        "Breth",
        "Caltram",
        "Dompneth",
        "Castantin",
        "Drust",
        "Breidei",
        "Ciniath",
        "Pidarnoin",
        "Mailcon",
        "Lutrin",
        "Gigurum",
        "Uist",
        "Cináeda",
        "Gartnaich",
        "Breth",
        "Cano",
        "Talorca",
        "Deocilunona",
        "Uvena",
        "Galama",
        "Bilia",
        "Brudea",
        "Dresa",
        "Ferga",
        "Wida",
        "Uudrosta",
        "Castantina",
        "Dompnetha",
        "Melcoa",
        "Kineta",
        "Aenbecana",
        "Alpín",
        "Echacha",
        "Deocillima",
        "Eripa",
        "Gailtrama",
        "Anga",
        "Uuida",
        "Girica",
        "Vipoiga",
        "Irba",
        "Gailtrama",
        "Brideia",
        "Deocilla",
        "Forcusa",
        "Oswia",
        "Gala",
        "Causantína",
        "Fochla",
        "Nehhtona",
        "Uura",
        "Maelcha",
        "Elpina",
        "Muna",
        "Alpia",
        "Brigid",
        "Caintigern",
        "Coblaith",
        "Conchenn",
        "Darlugdach",
        "Derelei",
        "Derile",
        "Domelcha",
        "Drusticca",
        "Eithne",
        "Eithni",
        "Enfleda",
        "Ethne",
        "Eurgein",
        "Fína",
        "Feidelea",
        "Fiala",
        "Findchoem",
        "Fotla",
        "Kentigerna",
        "Ligacha",
        "Lonca",
        "Maithgemma",
        "Modwenna",
        "Monenna",
        "Mongfina",
        "Nadbroicc",
        "Nectudad",
        // Latin
        "Volumnia",
        "Hesychia",
        "Herminia",
        "Tibulla",
        "Plotia",
        "Sancta",
        "Claudia",
        "Mutia",
        "Messiena",
        "Pictor",
        "Labiena",
        "Calera",
        "Pedia",
        "Terenteiana",
        "Milonia",
        "Quarto",
        "Didia",
        "Ecdicia",
        "Petronia",
        "Augurna",
        "Tettiena",
        "Hermina",
        "Atria",
        "Sosia",
        "Petellia",
        "Sylvia",
        "Papia",
        "Facilis",
        "Caesetia",
        "Aloysia",
        "Messiena",
        "Genialis",
        "Salonia",
        "Cerularia",
        "Longinia",
        "Claudiana",
        "Metilia",
        "Aeaca",
        "Caelia",
        "Mela",
        "Horatia",
        "Asellio",
        "Antonia",
        "Amantia",
        "Loreia",
        "Servana",
        "Flavonia",
        "Victorina",
        "Sidonia",
        "Vita",
        "Laetoria",
        "Gratidiana",
        "Coruncania",
        "Libania",
        "Secundia",
        "Eutheria",
        "Petilia",
        "Volusiana",
        "Secundinia",
        "Frumentia",
        "Herennia",
        "Faustiniana",
        "Vibia",
        "Mactator",
        "Pontidia",
        "Camilla",
        "Baebia",
        "Frigidian",
        "Gratidia",
        "Lentula",
        "Calavia",
        "Severa",
        "Menenia",
        "Primana",
        "Piscia",
        "Auxentia",
        "Curtia",
        "Iovina",
        "Scribonia",
        "Foriana",
        "Veturia",
        "Bonifatia",
        "Lutatia",
        "Thurina",
        "Cominia",
        "Trupo",
        "Treblana",
        "Calvina",
        "Artoria",
        "Maximian",
        "Maximia",
        "Barbata",
        "Portia",
        "Pollio",
        "Dillia",
        "Vedrix",
        "Aemilia",
        "Fimbria",
        "Pollia",
        "Lentula",
        "Visellia",
        "Cosmas",
        "Menenia",
        "Calpurniana",
        "Treblana",
        "Quirinalis",
        "Lollia",
        "Cicero",
        "Sempronia",
        "Telesina",
        "Modia",
        "Macro",
        "Peltrasia",
        "Ferreolia",
        "Insteia",
        "Similis",
        "Popidia",
        "Patricia",
        "Caesia",
        "Cerviana",
        "Potitia",
        "Torquata",
        "Umbria",
        "Pola",
        "Rabiria",
        "Iuvenlis",
        "Lampronia",
        "Vibullia",
        "Bantia",
        "Ennodia",
        "Numeria",
        "Macro",
        "Petillia",
        "Processa",
        "Viducia",
        "Dama",
        "Munatia",
        "Sulla",
        "Aburia",
        "Orientalis",
        "Silia",
        "Servana",
        "Nasennia",
        "Hortensis",
        "Salvia",
        "Evodia",
        "Statia",
        "Naevia",
        "Lollia",
        "Maursa",
        "Rabiria",
        "Luctaca",
        "Dexsia",
        "Montaa",
        "Gavia",
        "Loverniana",
        "Caelia",
        "Elvorix",
        "Metilia",
        "Gordio",
        "Equitia",
        "Firma",
        "Axia",
        "Calera",
        "Gratidia",
        "Colias",
        "Gellia",
        "Septimiana",
        "Varia",
        "Verulla",
        "Sentia",
        "Aurelia",
        "Piscia",
        "Deciana",
        "Blossia",
        "Rufina",
        "Munia",
        "Caleta",
        "Maximia",
        "Melita",
        "Caeparia",
        "Tarsicia",
        "Allectia",
        "Vedrix",
        "Albania",
        "Dacien",
        "Herminia",
        "Lepida",
        "Egnatia",
        "Opilio",
        "Paesentia",
        "Lovernia",
        "Aedinia",
        "Bambalio",
        "Papinia",
        "Campana",
        "Valeria",
        "Herma",
        "Tullia",
        "Angela",
        "Axia",
        "Petasia",
        "Didia",
        "Calva",
        "Aebutia",
        "Aponia",
        "Valeria",
        "Leptis",
        "Bruttia",
        "Iustiniana",
        "Tettia",
        "Velia",
        "Secundia",
        "Canisia",
        "Aedinia",
        "Similis",
        "Gavia",
        "Frontalis",
        "Curia",
        "Frugia",
        "Uulia",
        "Eona",
        "Clovia",
        "Iulian",
        "Maecilia",
        "Pola",
        "Didia",
        "Tiberina",
        "Vesnia",
        "Marsica",
        "Galeria",
        "Sorex",
        "Talmudia",
        "Naucratia",
        "Victricia",
        "Banqueria",
        "Betiliena",
        "Duviana",
        "Favonia",
        "Gallio",
        "Messiena",
        "Antia",
        "Fundana",
        "Petra",
        "Vatinia",
        "Dorothea",
        "Lampronia",
        "Aluredes",
        "Atilia",
        "Adjutor",
        "Memmia",
        "Galena",
        "Caprenia",
        "Hirpinia",
        "Suedia",
        "Pictor",
        "Viridia",
        "Floriana",
        "Novia",
        "Trebia",
        "Olcinia",
        "Luciana",
        "Arsinia",
        "Carina",
        "Laberia",
        "Paterna",
        "Calidia",
        "Decmitia",
        "Aemilia",
        "Flavina",
        "Aburia",
        "Mutila",
        "Scribonia",
        "Plautia",
        "Peltrasia",
        "Herena",
        "Baebia",
        "Leontia",
        "Calavia",
        "Respecta",
        "Maximia",
        "Priscian",
        "Pupia",
        "Sulla",
        "Pompilia",
        "Ursina",
        "Umbria",
        "Secundas",
        "Pupia",
        "Germana",
        "Vagionia",
        "Quirinalis",
        "Caepasia",
        "Ferentina",
        "Volusia",
        "Sancta",
        "Sittia",
        "Rema",
        "Auria",
        "Ioviniana",
        "Sepurcia",
        "Priscillian",
        "Vipstana",
        "Ligur",
        "Caesetia",
        "Mutila",
        "Pompilia",
        "Processa",
        "Quinctia",
        "Arvina",
        "Duronia",
        "Laenas",
        "Artoria",
        "Severlina",
        "Camilia",
        "GordianGordiana",
        "Caedicia",
        "Gaura",
        "Modia",
        "Iocunda",
        "Quirinia",
        "Martyria",
        "Aburia",
        "Turpilina",
        "Gegania",
        "Triaria",
        "Helvetia",
        "Aluredes",
        "Sepurcia",
        "Catiana",
        "Rufia",
        "Christiana",
        "Caecia",
        "Casta",
        "Blossia",
        "Bradua",
        "Faleria",
        "Aquila",
        "Veturia",
        "Caligula",
        "Ateia",
        "Quentin",
        "Attia",
        "Cicero",
        "Novia",
        "Opilio",
        "Matia",
        "Lentula",
        "Hirtia",
        "Patricia",
        "Atria",
        "Verania",
        "Canutia",
        "Sisenna",
        "Milonia",
        "Sanga",
        "Caedicia",
        "Erasina",
        "Uulia",
        "Magna",
        "Veturia",
        "Titiana",
        "Amatia",
        "Bato",
        "Blandia",
        "Victricia",
        "Atilia",
        "Nasica",
        "Duccia",
        "Salvian",
        "Vergilia",
        "Cerialis",
        "Sextia",
        "Sicula",
        "Calidia",
        "Vitalina",
        "Seia",
        "Turibia",
        "Cosconia",
        "Foriana",
        "Aemilia",
        "Flavilla",
        "Cluilia",
        "Malla",
        "Cilnia",
        "Tullia",
        "Nigilia",
        "Marcallas",
        "Lafrenia",
        "Sticha",
        "Cicereia",
        "Quiriac",
        "Ulpia",
        "Marcella",
        "Sabucia",
        "Libo",
        "Vergilia",
        "Apelles",
        "Calavia",
        "Constantia",
        "Lucilia",
        "Diserta",
        "Rufia",
        "Figula",
        "Volumnia",
        "Commida",
        "Pompilia",
        "Fimbria",
        "Pompeia",
        "Faga",
        "Tullia",
        "Pertinax",
        "Statilia",
        "Prota",
        "Maria",
        "Decma",
        "Menenia",
        "Gluvias",
        "Tanicia",
        "Aebuta",
        "Potitia",
        "Sicula",
        "Axia",
        "Tullas",
        "Cantia",
        "Valerian",
        "Norbana",
        "Geta",
        "Didia",
        "Papa",
        "Floridia",
        "Pulcheria",
        "Caria",
        "Ceciliana",
        "Tuccia",
        "Iavolena",
        "Menenia",
        "Pictor",
        "Victricia",
        "Cyprias",
        //Germanic
                "Ostara",
        "Frauke",
        "Louise",
        "Wilfriede",
        "Irmgard",
        "Abelarda",
        "Lioba",
        "Sieglinde",
        "Alda",
        "Yvonne",
        "Wendelina",
        "Roswitha",
        "Ottilia",
        "Brunhilde",
        "Almut",
        "Wilhelma",
        "Livina",
        "Kunigunde",
        "Wiltrud",
        "Cressida",
        "Margund",
        "Cressida",
        "Wendelina",
        "Raina",
        "Lioba",
        "Adelheid",
        "Gunda",
        "Wilhelma",
        "Ostara",
        "Amalberga",
        "Hedwig",
        "Saskia",
        "Elfrun",
        "Carla",
        "Sieghilde",
        "Ida",
        "Engla",
        "Inge",
        "Linde",
        "Gunda",
        "Kunigunde",
        "Kriemhild",
        "Aubrey",
        "Charlotte",
        "Sigune",
        "Gertrud",
        "Runhild",
        "Inge",
        "Wilhelma",
        "Bertille",
        "Irmgard",
        "Linde",
        "Hildburg",
        "Hedwig",
        "Geraldine",
        "Schwanhilde",
        "Griselda",
        "Gerwine",
        "Reinhilde",
        "Filiberta",
        "Huberta",
        "Adelinde",
        "Heilgard",
        "Arnolda",
        "Heilwig",
        "Helmtraud",
        "Hildrun",
        "Rotraud",
        "Mathilde",
        "Ulrike",
        "Richmute",
        "Hermine",
        "Norgard",
        "Alrun",
        "Kai",
        "Brunhilde",
        "Irmhild",
        "Liebtrud",
        "Kaja",
        "Almut",
        "Edelgard",
        "Mathilde",
        "Frauke",
        "Bruna",
        "Irmlinde",
        "Guerina",
        "Irmgard",
        "Norgard",
        "Reinhild",
        "Gilberta",
        "Emery",
        "Gunthilde",
        "Huguette",
        "Hulda",
        "Sunna",
        "Landriche",
        "Reinhilde",
        "Kunigunde",
        "Charlotte",
        "Gisela",
        "Aubrey",
        "Ida",
        "Everarda",
        "Riccarda",
        "Merlind",
        "Veerle",
        "Aldona",
        "Ortrun",
        "Engla",
        "Romilda",
        "Arda",
        "Bernharde",
        "Herlinde",
        "Irmlinde",
        "Rodina",
        "Ulrike",
        "Miltrud",
        "Friedegund",
        "Klothilde",
        "Schwanhilde",
      ]
    },
    last: [
      "Stonemourn",
      "Doomsorrow",
      "Ambersurge",
      "Phoenixwalker",
      "Strongbash",
      "Pyreflame",
      "Bearsteel",
      "Ambersnout",
      "Frozenfeather",
      "Bearflare",
      "Grizzlyhell",
      "Clearforest",
      "Lightningshout",
      "Deathshot",
      "Tarrenfist",
      "Mildhide",
      "Distantmark",
      "Wisetail",
      "Silentorb",
      "Lonehammer",
      "Suncloud",
      "Roughforce",
      "Axelash",
      "Summerstrider",
      "Stagwhirl",
      "Flameforge",
      "Horsemaul",
      "Marshstalker",
      "Ambersplitter",
      "Autumnarrow",
      "Moonreaver",
      "Deathslayer",
      "Starweaver",
      "Fireglory",
      "Paleclaw",
      "Wintermark",
      "Mirthwind",
      "Greatkiller",
      "Amberthorne",
      "Pinebringer",
      "Earthsky",
      "Glowblood",
      "Sagestride",
      "Dawnbeard",
      "Truebreaker",
      "Elfdrifter",
      "Greenshadow",
      "Goldspear",
      "Serpentmaul",
      "Shadowhammer",
      "Barleyriver",
      "Foredraft",
      "Firesorrow",
      "Masterbloom",
      "Flintgrip",
      "Mournflow",
      "Hammerwinds",
      "Richcrusher",
      "Wisemane",
      "Alpenbranch",
      "Barleygrove",
      "Gorerun",
      "Sternstride",
      "Sterndown",
      "Ragemight",
      "Bonehand",
      "Skystone",
      "Skulleye",
      "Axebinder",
      "Ironbender",
      "Dewguard",
      "Flamereaver",
      "Phoenixstrider",
      "Slatedown",
      "Lunabeard",
      "Crystalvalor",
      "Grassmark",
      "Crowwind",
      "Laughingshine",
      "Fistmark",
      "Bronzebough",
      "Summerwhisper",
      "Lightningrider",
      "Mountainspell",
      "Ravenless",
      "Dawnflare",
      "Rumblefollower",
      "Bladethorne",
      "Battlebough",
      "Netherblossom",
      "Cloudroot",
      "Morningsky",
      "Dreamhammer",
      "Moltenclaw",
      "Silentblood",
      "Flameeye",
      "Twilightflower",
      "Bluepike",
      "Cinderbeard",
      "Silentgem",
      "Shieldfist",
      "Honorbash",
      "Cliffmourn",
      "Mildbrace",
      "Horsestone",
      "Ragewinds",
      "Frozenguard",
      "Dreamvigor",
      "Dragonheart",
      "Bladeslayer",
      "Rarel",
      "Nerelet",
      "Ginelot",
      "Duragnac",
      "Chauvempes",
      "Boisgellot",
      "Beleze",
      "Montallon",
      "Aguembert",
      "Albetillon",
      "Caffariou",
      "Claristel",
      "Alilon",
      "Sublinet",
      "Chaugre",
      "Écheron",
      "Mairel",
      "Ravidieu",
      "Sustel",
      "Bizenne",
      "Chabaze",
      "Kerganteau",
      "Crelart",
      "Nerere",
      "Béchardieu",
      "Verniges",
      "Alinteau",
      "Pelletillon",
      "Ginegné",
      "Maissier",
      "Bougairon",
      "Chaulet",
      "Laurechade",
      "Abire",
      "Castedieu",
      "Polalieu",
      "Dulles",
      "Sublinnes",
      "Astares",
      "Pellerdieu",
      "Gaille",
      "Astannes",
      "Castessard",
      "Larmarral",
      "Roquerelli",
      "Abossard",
      "Chavanas",
      "Belellac",
      "Guimeur",
      "Bechanteau",
      "Andivau",
      "Échebannes",
      "Vemeur",
      "Cardaimpes",
      "Cardairelli",
      "Chabalart",
      "Sullet",
      "Albefort",
      "Aborac",
      "Pellellevé",
      "Cardaissac",
      "Pedieu",
      "Crezin",
      "Échegnory",
      "Sautelet",
      "Brognac",
      "Gainie",
      "Abossac",
      "Rocheveron",
      "Boisgelli",
      "Albefelon",
      "Montazac",
      "Larmannes",
      "Sarragnory",
      "Chaurel",
      "Baralle",
      "Échenas",
      "Vassegner",
      "Laureres",
      "Beledieu",
      "Échenteau",
      "Saintilieu",
      "Sulon",
      "Polilli",
      "Castegnes",
      "Pouinne",
      "Ababannes",
      "Aguennes",
      "Gaire",
      "Roquellet",
      "Montampes",
      "Bougairon",
      "Sugnes",
      "Caffazin",
      "Belellet",
      "Vemond",
      "Ginegre",
      "Lomabannes",
      "Bizestel",
      "Astatré",
      "Mévouinton",
      "Chamirelli",
      "Charral",
      "Jouverisey",
      "Polivilliers",
      "Perisey",
      "Castellon",
      "Bougaimond",
      "Charral",
      "Vassenet",
      "Bonnere",
      "Chamiveron",
      "Saintinac",
      "Albilieu",
      "Aguegny",
      "Verninie",
      "Polassac",
      "Polimont",
      "Sauvès",
      "Croivès",
      "Angerelli",
      "Chamignory",
      "Auberet",
      "Chamichanteau",
      "Béchallac",
      "Pellegnes",
      "Bizellot",
      "Maimond",
      "Choignes",
      "Machessec",
      "Casteffet",
      "Sublimières",
      "Massoumpes",
      "Roqueres",
      "Lomatré",
      "Sublivau",
      "Duranac",
      "Caffazin",
      "Aguerdieu",
      "Croilli",
      "Caffariou",
      "Claristel",
      "Alilon",
      "Sublinet",
      "Chaugre",
      "Écheron",
      "Mairel",
      "Ravidieu",
      "Sustel",
      "Bizenne",
      "Chabaze",
      "Kerganteau",
      "Crelart",
      "Nerere",
      "Béchardieu",
      "Verniges",
      "Alinteau",
      "Pelletillon",
      "Ginegné",
      "Maissier",
      "Bougairon",
      "Chaulet",
      "Laurechade",
      "Abire",
      "Castedieu",
      "Polalieu",
      "Dulles",
      "Sublinnes",
      "Astares",
      "Pellerdieu",
      "Gaille",
      "Astannes",
      "Castessard",
      "Larmarral",
      "Roquerelli",
      "Abossard",
      "Chavanas",
      "Belellac",
      "Guimeur",
      "Bechanteau",
      "Andivau",
      "Échebannes",
      "Vemeur",
      "Cardaimpes",
      "Cardairelli",
      "Chabalart",
      "Sullet",
      "Albefort",
      "Aborac",
      "Pellellevé",
      "Cardaissac",
      "Pedieu",
      "Crezin",
      "Échegnory",
      "Sautelet",
      "Brognac",
      "Gainie",
      "Abossac",
      "Rocheveron",
      "Boisgelli",
      "Albefelon",
      "Montazac",
      "Larmannes",
      "Sarragnory",
      "Chaurel",
      "Baralle",
      "Échenas",
      "Vassegner",
      "Laureres",
      "Beledieu",
      "Échenteau",
      "Saintilieu",
      "Sulon",
      "Polilli",
      "Castegnes",
      "Pouinne",
      "Ababannes",
      "Aguennes",
      "Gaire",
      "Roquellet",
      "Montampes",
      "Bougairon",
      "Sugnes",
      "Caffazin",
      "Belellet",
      "Vemond",
      "Ginegre",
      "Lomabannes",
      "Bizestel",
      "Astatré",
      "Mévouinton",
      "Chamirelli",
      "Charral",
      "Jouverisey",
      "Polivilliers",
      "Perisey",
      "Castellon",
      "Bougaimond",
      "Charral",
      "Vassenet",
      "Bonnere",
      "Chamiveron",
      "Saintinac",
      "Albilieu",
      "Aguegny",
      "Verninie",
      "Polassac",
      "Polimont",
      "Sauvès",
      "Croivès",
      "Angerelli",
      "Chamignory",
      "Auberet",
      "Chamichanteau",
      "Béchallac",
      "Pellegnes",
      "Bizellot",
      "Maimond",
      "Choignes",
      "Machessec",
      "Casteffet",
      "Sublimières",
      "Massoumpes",
      "Roqueres",
      "Lomatré",
      "Sublivau",
      "Duranac",
      "Caffazin",
      "Aguerdieu",
      "Croilli",
      "Roqueseul",
      "Chassier",
      "Crevès",
      "Cardairdieu",
      "Pouilli",
      "Aguemond",
      "Montanac",
      "Jouvezac",
      "Roquerisey",
      "Angenne",
      "Choimpes",
      "Chauvetré",
      "Cardairisey",
      "Clarizin",
      "Rochetelet",
      "Raviges",
      "Chavazin",
      "Ragre",
      "Limollot",
      "Chaze",
    ]
  },
  "Shifter": {
    first: {
      "♂": [
        "Drift",
        "Mercury",
        "Timber",
        "Ash",
        "Bog",
        "Badger",
        "Glare",
        "Astro",
        "Storm",
        "Newt",
        "Magma",
        "Glare",
        "Onyx",
        "Grime",
        "Ash",
        "Basil",
        "Rubble",
        "Tide",
        "Flare",
        "Ridge",
        "Tide",
        "Drift",
        "Gorge",
        "Shrub",
        "Nimbus",
        "Chasm",
        "Thorn",
        "Midnight",
        "Silver",
        "Talon",
        "Moss",
        "Claw",
        "Comet",
        "Briar",
        "Lumber",
        "Chasm",
        "Canyon",
        "Tide",
        "Mercury",
        "Nova",
        "Brook",
        "Blaze",
        "Rift",
        "Fox",
        "Fury",
        "Brock",
        "Breach",
        "Mountain",
        "Basil",
        "Basil",
        "Barb",
        "Timber",
        "Boulder",
        "Fume",
        "Wolf",
        "Gulch",
        "Char",
        "Pitch",
        "Rift",
        "Glare",
        "Fury",
        "Soot",
        "Flax",
      ],
      "♀": [
        "Chestnut",
        "Aurora",
        "Ruby",
        "Galaxy",
        "Sunrise",
        "Nutmeg",
        "Haze",
        "Autumn",
        "Violet",
        "Petal",
        "Opal",
        "Sunset",
        "Anemone",
        "Flora",
        "Dawn",
        "Isle",
        "Galaxy",
        "Floe",
        "Evening",
        "Twilight",
        "Rill",
        "Luna",
        "Scarlet",
        "Snowflake",
        "Saffron",
        "Emerald",
        "Indigo",
        "Almond",
        "Orchid",
        "Almond",
        "Snowflake",
        "Pine",
        "Nutmeg",
        "Aqua",
        "Plume",
        "Swill",
        "Maple",
        "Luna",
        "Birch",
        "Quill",
        "Dawn",
        "Snow",
        "Aqua",
        "Cloud",
        "Floe",
        "Sage",
        "Dawn",
        "Haze",
        "Nutmeg",
        "Petal",
        "Blossom",
        "Ivy",
        "Marigold",
        "Sapphire",
        "Magenta",
        "Ocean",
        "Bloom",
        "Lake",
        "Almond",
        "Cricket",
        "Sugar",
        "Indigo",
        "Dawn",
        "Sky",
        "Wing",
        "Almond",
        "Urchin",
        "Pearl",
        "Summer",
        "Ruby",
        "Jasmine",
        "Ruby",
        "Meadow",
        "Violet",
        "Haze",
        "Olive",
        "Canyon",
        "Dew",
        "Scarlet",
        "Sugar",
        "River",
        "Sugar",
        "Maple",
        "Nutmeg",
        "Sunshine",
        "Rain",
        "Gem",
        "Wing",
        "Tiger",
        "Hail",
        "Galaxy",
        "Plume",
        "Breeze",
        "Birch",
        "Tidal",
        "Raven",
        "Anemone",
        "Nutmeg",
        "Plume",
        "Olive",
        "Marigold",
        "Lake",
        "Rosemary",
        "Raven",
        "Sugar",
        "Sky",
        "Magenta",
        "Twilight",
        "Amber",
        "Pine",
        "Willow",
        "River",
        "Fern",
        "Snow",
        "Shade",
        "Flora",
        "Tidal",
        "Wind",
        "Briar",
        "Tidal",
        "Sage",
        "Snow",
        "Tidal",
        "Maple",
        "Meadow",
        "Pyro",
        "Bloom",
        "Diamond",
        "Dew",
        "Wing",
        "Luna",
        "Fern",
        "Fluff",
        "Thistle",
        "Winter",
        "Midnight",
        "Cricket",
        "Saffron",
        "Tiger",
        "Urchin",
        "Horizon",
        "Crest",
        "Chestnut",
        "Briar",
        "Moon",
        "Twig",
        "Violet",
      ]
    }
  },
  "Uniya": {
    first: {
      borrowOptions: ['Half Elf', 'Half Orc']
    }
  },
  "Mixed Ancestry": {
    first: {
      borrowOptions: ['Half Elf', 'Human', 'Tiefling', 'Aasimar']
    }
  },
  "Imperial Mixed Ancestry": {
    first: {
      borrowOptions: ['Half Elf', 'Human', 'Tiefling']
    },
    last: {
      borrowOptions: ['Human']
    }
  },
  "Kobold": {
    first: {
      neutral: [
        "Kun",
        "Nag",
        "Vom",
        "Snam",
        "Holbo",
        "Uda",
        "Nirko",
        "Kapa",
        "Snerka",
        "Snivla",
        "Rutt",
        "Moss",
        "Dik",
        "Snirn",
        "Zarpa",
        "Arke",
        "Magni",
        "Megri",
        "Itlu",
        "Totle",
        "Gem",
        "Kiss",
        "Vok",
        "Siv",
        "Muvna",
        "Nahle",
        "Nigri",
        "Snelbe",
        "Iku",
        "Zikbo",
        "Nim",
        "Ted",
        "Gon",
        "Mov",
        "Zagge",
        "Kukli",
        "Situ",
        "Zolo",
        "Getru",
        "Uvnu",
        "Hatt",
        "Tev",
        "Hitt",
        "Hoss",
        "Snellu",
        "Sotlu",
        "Galdu",
        "Darke",
        "Zokki",
        "Mava",
        "Vugs",
        "Suss",
        "Riv",
        "Dass",
        "Siklo",
        "Ekdo",
        "Snalpi",
        "Snopu",
        "Oppu",
        "Ogge",
        "Zas",
        "Zir",
        "Hin",
        "Zeg",
        "Snikki",
        "Ada",
        "Uhse",
        "Snerto",
        "Ihru",
        "Moge",
      ]
    }
  }
}

export default namesData;
