import React from "react";

import StiernanLetterTable from "./StiernanLetterTable"
import StiernanRune        from "./StiernanRune"


class LanguageSandbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      word: ""
    }
  }

  render () {
    return (
      <div className="rune-box">
        <div>
          <StiernanLetterTable/>
        </div>

        <input type="text" onChange={ (e) => this.setState({word: e.currentTarget.value}) }/>

        <div className="rune-word">
          {
            this.state.word.split("").map((character, indy) => {
              return (
                <StiernanRune letter={character}/>
              )
            })
          }
        </div>
      </div>
    )
  }
}

export default LanguageSandbox;
