import React                 from "react";
import { Link }              from "react-router-dom";

import Collapsible           from "../shared/Collapsible"

import eventData             from "../../data/events";

class Locations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  allowedRecords(key) {
    return Object.keys(eventData[key]).filter((eventKey) => {
      return eventData[key][eventKey].enabled;
    });
  }

  collapseToggle(kind) {
    this.state.show === kind ? this.setState({show: ""}) : this.setState({show: kind})
  }

  render() {
    return (
      <div className="standard-panel">
        <h1>
          Events
        </h1>

        <Collapsible
          title="Toril"
          toggle={this.collapseToggle.bind(this)}
          kind="toril"
          show={this.state.show}
        >

          <div className="divisor">
            {
              this.allowedRecords("Toril").map((eventKey, indy) => {
                return (
                  <div
                    key={indy}
                    className="index-listing"
                  >
                    <Link to={`/events/${eventKey}`}>
                      <img
                        src={eventData.Toril[eventKey].src || "https://toppng.com/uploads/preview/location-png-icon-location-icon-png-free-11562933803vththezlcl.png"}
                        alt="character-thumb"
                      />

                      <div className="info-panel">
                        <h3>
                          {eventData.Toril[eventKey].name}
                        </h3>

                        <span>
                          {eventData.Toril[eventKey].tagLine}
                        </span>
                      </div>
                    </Link>
                  </div>
                )
              })
            }
          </div>
        </Collapsible>

        <Collapsible
          title="First World"
          toggle={this.collapseToggle.bind(this)}
          kind="first"
          show={this.state.show}
        >
          {
              this.allowedRecords("First World").map((eventKey, indy) => {
                return (
                  <div
                    key={indy}
                    className="index-listing"
                  >
                    <Link to={`/events/${eventKey}`}>
                      <img
                        src={eventData["First World"][eventKey].src || "https://toppng.com/uploads/preview/location-png-icon-location-icon-png-free-11562933803vththezlcl.png"}
                        alt="character-thumb"
                      />

                      <div className="info-panel">
                        <h3>
                          {eventData["First World"][eventKey].name}
                        </h3>

                        <span>
                          {eventData["First World"][eventKey].tagLine}
                        </span>
                      </div>
                    </Link>
                  </div>
                )
              })
            }
        </Collapsible>

      </div>
    )
  }
}

export default Locations;
