import React                 from "react";
// import { Link }              from "react-router-dom";

class Divines extends React.Component {
  constructor(props) {
    super(props);
    this.state = {display: 'inner'}
  }

  render() {

    return (
      <div className="divine-display">
        <h1>
          Great Wheel Cosmology
        </h1>

        <div>
          <button onClick={() => {this.setState({display: 'inner'})}}>Inner Planes</button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button onClick={() => {this.setState({display: 'concordant'})}}>Concodrant Opposition</button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button onClick={() => {this.setState({display: 'swirl'})}}>Drifting</button>
        </div>
        <br/>


        <div className="pieContainer">
          <div className="pieBackground"></div>
          {
            [
              ...Array(16),
            ].map((v, indy) => {
              return (
                <div className={`pieSlice${indy} hold`}>
                  <div className="pie"></div>
                  <div className="fun-pie"></div>
                </div>
              )
            })
          }

          {
            this.state.display === 'inner' &&
            <>
              <div className="inner-innerCircle">
                <h3>Elemental Chaos</h3>
                <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                <h3>Elemental Chaos</h3>
              </div>
              <div className="innerCircle">
                <div className="content">
                  {
                    [
                      ...Array(4),
                    ].map((v, indy) => {
                      return (
                        <div
                          id={`inner-pieSlice${indy}`}
                          className="inner-hold"
                        >
                          <div className="inner-pie"></div>
                        </div>
                      )
                    })
                  }
                </div>
                <h2 className="aired">Plane of Air</h2>
                <h2 className="earthed">Plane <br/> of <br/>Earth</h2>
                <h2 className="wet">Plane <br/> of <br/>Water</h2>
                <h2 className="burned">Plane of Fire</h2>
              </div>
              <div className="inner-inner-innerCircle">
                <h2>Feywild</h2>
                <br/><br/><br/><br/>
                <br/><br/>
                <br/><br/><br/>
                <h2>Shadowfell</h2>
              </div>
              <div className="inner-oval">
                <h3 className="ether1">Ethereal Plane</h3>
                <h3 className="ether2">Ethereal Plane</h3>
                <br/><br/>
                <h2>Material Plane</h2>
              </div>
            </>
          }

          {
            this.state.display === 'concordant' &&
            <>
              <div className="concordantCircle">
                <br/>
                <br/>
                <br/>
                <h2>Concordant Opposition</h2>
              </div>
              <div className="sigil">
                <br/><br/>
                <h2>Sigil</h2>
              <div className="sigil-mountain left"></div>
              <div className="sigil-mountain right"></div>
              </div>

              <div className="gate-towns">
                <div className="gate beastlands"></div>
                <div className="gate arborea"></div>
                <div className="gate ysgard"></div>
                <div className="gate limbo"></div>
                <div className="gate pandemonium"></div>
                <div className="gate abyss"></div>
                <div className="gate carceri"></div>
                <div className="gate hades"></div>
                <div className="gate gehenna"></div>
                <div className="gate hells"></div>
                <div className="gate acheron"></div>
                <div className="gate mechanus"></div>
                <div className="gate arcadia"></div>
                <div className="gate celestia"></div>
                <div className="gate bytopia"></div>
                <div className="gate elysium"></div>
              </div>
            </>

          }


          {
            this.state.display === 'swirl' &&
            <>
              <div className="concordantCircle adriftCircle">
                <br/>
                <br/>
                <br/>
                <h2>Adrift Planes</h2>
              </div>
                <div className="lamp">
                  <div className="glass">
                    <div className="lava">
                      <div className="blob"><br/><br/>Endless Ethereum</div>
                      <div className="blob"><br/>Marketplace Eternal</div>
                      <div className="blob"><br/><br/>Wild Woods</div>
                    </div>
                  </div>
                </div>

                <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                  <defs>
                    <filter id="goo">
                      <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                      <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
                      <feBlend in="SourceGraphic" in2="goo" />
                    </filter>
                  </defs>
                </svg>

            </>

          }












        </div>

        <div className="outer-plane-names">
          <h3 className="beastlands">Beastlands<br/><span> NG-CG </span></h3>
          <h3 className="arborea">Arborea<br/><span> CG </span></h3>
          <h3 className="ysgard">Ysgard<br/><span> CG-CN </span></h3>
          <h3 className="limbo">Limbo<br/><span> CN </span></h3>
          <h3 className="pandemonium">Pandemonium<br/><span> CN-CE </span></h3>
          <h3 className="abyss">The Abyss<br/><span> CE </span></h3>
          <h3 className="carceri">Carceri<br/><span> CE-NE </span></h3>
          <h3 className="hades">Hades<br/><span> NE </span></h3>
          <h3 className="gehenna">Gehenna<br/><span> LE-NE </span></h3>
          <h3 className="hells">The Nine Hells<br/><span> LE </span></h3>
          <h3 className="acheron">Acheron<br/><span> LN-LE </span></h3>
          <h3 className="mechanus">Mechanus<br/><span> LN </span></h3>
          <h3 className="arcadia">Arcadia<br/><span> LG-LN </span></h3>
          <h3 className="celestia">Mt. Celestia<br/><span> LG </span></h3>
          <h3 className="bytopia">Bytopia<br/><span> NG-LN </span></h3>
          <h3 className="elysium">Elysium<br/><span> NG </span></h3>
        </div>



        <br/>
        <br/>
        <br/>
        <br/>
        <div className="astral-labels">
          <h3 className="astal-label-1">Astral Plane</h3>
          <h3 className="astal-label-2">Astral Plane</h3>
        </div>

      </div>
    )
  }
}

export default Divines;
