import React from "react";

import StiernanRune from "./StiernanRune"

class StiernanLetterTableRow extends React.Component {
  render () {
    return (
      <div className="flex-row">
        <div className="letter-col">
          {this.props.translatedLetter || this.props.letter}
        </div>

        <div className="rune-col">
          <StiernanRune letter={this.props.letter.toLowerCase()} />
        </div>

        <div className="meaning-col">
          {this.props.meaning || "meaning unknown"}
        </div>
      </div>
    )
  }
}

export default StiernanLetterTableRow;
