import React from 'react';

class Collapsible extends React.Component {
  render() {
    return (
      <div className="collapseable" id={this.props.kind}>
        <h3 onClick={() => { this.props.toggle(this.props.kind) }}>
          {this.props.title}
        </h3>

        {
          this.props.show === this.props.kind &&
          <div className="hidden-list">
            {this.props.children}
          </div>
        }
      </div>
    )
  }
}

export default Collapsible;
