import React      from 'react';
import Randomizer from '../../lib/Randomizer';

import { Link }   from "react-router-dom";

class Creation extends React.Component {
  constructor(props) {
    super(props);

    this.randomizer = new Randomizer();

    this.state = {
      show: "Random",
      stats: {
        brawn: 0,
        dex: 0,
        constitution: 0,
        intellect: 0,
        wisdom: 0,
        charisma: 0
      },
      raceStats: {},
      raceTrack: {}
    }
  }

  labeled(label, text) {
    return (
      <div className="labeled">
        <strong>
          {label}:&nbsp;
        </strong>
        {text}
      </div>
    )
  }

  randomRandomRandom() {
    const result = this.randomizer.generate();

    this.setState({race: result.race, klass: result.klass, background: result.background, families: result.families, alignment: result.alignment})
  }

  randomRelationship(type) {
    this.setState({[type]: this.randomizer.genRelationship()})
  }

  bonusPresenter(score) {
    return Math.floor((score-10.0) / 2);
  }

  setStat(value, stat) {
    let newState = {...this.state.stats}

    if(newState.brawn === value) {
      newState.brawn = 0;
    }
    else if(newState.dex === value) {
      newState.dex = 0;
    }
    else if(newState.constitution === value) {
      newState.constitution = 0;
    }
    else if(newState.intellect === value) {
      newState.intellect = 0;
    }
    else if(newState.wisdom === value) {
      newState.wisdom = 0;
    }
    else if(newState.charisma === value) {
      newState.charisma = 0;
    }

    newState[stat] = value;

    this.setState({stats: newState})
  }

  shouldBeDisable(stat, value) {
    return this.state.stats[stat] && this.state.stats[stat] !== value;
  }

  raceStatShouldBeDisable(stat, value, indy) {
    return this.state.raceStats[stat]
  }

  modStat(value, stat, indy) {
    let newState = {...this.state.raceStats, [stat]: value}

    if(this.state.raceTrack[indy]) {
      newState[this.state.raceTrack[indy]] = 0
    }

    const newTrackState = {...this.state.raceTrack, [indy]: stat}

    this.setState({raceStats: newState, raceTrack: newTrackState});
  }

  scoreDisplay(stat) {
    return this.state.stats[stat] + (this.state.raceStats[stat] > 0);
  }

  render() {
    return (
      <div className="standard-panel">
        <h1>
          Character Creation
        </h1>

        <div className="internal-panel">
          <br/>

          <a href="https://www.dndbeyond.com/characters/builder#/" target="_blank" rel="noreferrer">
            Guided DnDBeyond Character Creation (Only basic options free, but covers many cases)
          </a>

          <div className='collapseable'>
            <h2 onClick={() => {this.state.show === "Random" ? this.setState({show: ""}) : this.setState({show: "Random"})}}>
              Random
            </h2>

            {
              this.state.show === "Random" &&
              <div className="hidden-list">
                <div className="random-list">
                  <a href={`http://dnd5e.wikidot.com/race:${this.state.race}`} target="_blank" rel="noreferrer">
                    {this.labeled("Race", this.state.race)}
                  </a>

                  <div>
                    <strong>
                      Alignment:
                    </strong>
                    {
                      this.state.alignment &&
                      <div>
                        {this.state.alignment[0]} => {this.state.alignment[1]}
                      </div>
                    }
                    {
                      !this.state.alignment &&
                      <span><br/><br/></span>
                    }
                  </div>

                  <a href={`http://dnd5e.wikidot.com/${this.state.klass}`} target="_blank" rel="noreferrer">
                    {this.labeled("Class", this.state.klass)}
                  </a>

                  <a href={`http://dnd5e.wikidot.com/background:${this.state.background}`} target="_blank" rel="noreferrer">
                    {this.labeled("Background", this.state.background)}
                  </a>

                  {this.labeled("Family Background", this.state.families)}

                  <div className="random-button-container">
                    <button onClick={() => {this.randomRandomRandom()}}>
                      RANDOM
                    </button>
                  </div>

                  <hr/>

                  {this.labeled("Friend", this.state.ally)}

                  <div className="random-button-container">
                    <button onClick={() => {this.randomRelationship("ally")}}>
                      RANDOM
                    </button>
                  </div>

                  <hr/>

                  {this.labeled("Rival/Enemy", this.state.enemy)}

                  <div className="random-button-container">
                    <button onClick={() => {this.randomRelationship("enemy")}}>
                      RANDOM
                    </button>
                  </div>
                </div>
              </div>
            }
          </div>

          <div className='collapseable'>
            <h2 onClick={() => {this.state.show === "Stats" ? this.setState({show: ""}) : this.setState({show: "Stats"})}}>
              Stats
            </h2>

            {
              this.state.show === "Stats" &&
              <div className="hidden-list stat-list">
                <div className="stat-block">
                  <div className="stat">
                    <div className="stat-name">
                      Brawn
                    </div>
                    <div className="big-score">
                      {this.scoreDisplay("brawn")}
                    </div>

                    <div className="stat-bonus">
                      {this.bonusPresenter(this.state.stats.brawn)}
                    </div>
                  </div>

                  <div className="stat">
                    <div className="stat-name">
                      Dexterity
                    </div>
                    <div className="big-score">
                      {this.scoreDisplay("dex")}
                    </div>

                    <div className="stat-bonus">
                      {this.bonusPresenter(this.state.stats.dex)}
                    </div>
                  </div>

                  <div className="stat">
                    <div className="stat-name">
                      Constitution
                    </div>
                    <div className="big-score">
                      {this.scoreDisplay("constitution")}
                    </div>

                    <div className="stat-bonus">
                      {this.bonusPresenter(this.state.stats.constitution)}
                    </div>
                  </div>

                  <div className="stat">
                    <div className="stat-name">
                      Intellect
                    </div>
                    <div className="big-score">
                      {this.scoreDisplay("intellect")}
                    </div>

                    <div className="stat-bonus">
                      {this.bonusPresenter(this.state.stats.intellect)}
                    </div>
                  </div>

                  <div className="stat">
                    <div className="stat-name">
                      Wisdom
                    </div>
                    <div className="big-score">
                      {this.scoreDisplay("wisdom")}
                    </div>

                    <div className="stat-bonus">
                      {this.bonusPresenter(this.state.stats.wisdom)}
                    </div>
                  </div>

                  <div className="stat">
                    <div className="stat-name">
                      Charisma
                    </div>
                    <div className="big-score">
                      {this.scoreDisplay("charisma")}
                    </div>

                    <div className="stat-bonus">
                      {this.bonusPresenter(this.state.stats.charisma)}
                    </div>
                  </div>
                </div>

                <div className="selectors">
                  <div className="array-selects">
                    <div>
                      15:&nbsp;
                      <select onChange={(e) => {this.setStat(15, e.currentTarget.value)}}>
                        <option value="">
                          Select/Clear
                        </option>
                        <option value="brawn" disabled={this.shouldBeDisable("brawn")}>
                          Brawn
                        </option>
                        <option value="dex" disabled={this.shouldBeDisable("dex")}>
                          Dex
                        </option>
                        <option value="constitution" disabled={this.shouldBeDisable("constitution")}>
                          Constitution
                        </option>
                        <option value="intellect" disabled={this.shouldBeDisable("intellect")}>
                          Intellect
                        </option>
                        <option value="wisdom" disabled={this.shouldBeDisable("wisdom")}>
                          Wisdom
                        </option>
                        <option value="charisma" disabled={this.shouldBeDisable("charisma")}>
                          Charisma
                        </option>
                      </select>
                    </div>

                    <div>
                      14:&nbsp;
                      <select onChange={(e) => {this.setStat(14, e.currentTarget.value)}}>
                        <option value="">
                          Select/Clear
                        </option>
                        <option value="brawn" disabled={this.shouldBeDisable("brawn")}>
                          Brawn
                        </option>
                        <option value="dex" disabled={this.shouldBeDisable("dex")}>
                          Dex
                        </option>
                        <option value="constitution" disabled={this.shouldBeDisable("constitution")}>
                          Constitution
                        </option>
                        <option value="intellect" disabled={this.shouldBeDisable("intellect")}>
                          Intellect
                        </option>
                        <option value="wisdom" disabled={this.shouldBeDisable("wisdom")}>
                          Wisdom
                        </option>
                        <option value="charisma" disabled={this.shouldBeDisable("charisma")}>
                          Charisma
                        </option>
                      </select>
                    </div>

                    <div>
                      13:&nbsp;
                      <select onChange={(e) => {this.setStat(13, e.currentTarget.value)}}>
                        <option value="">
                          Select/Clear
                        </option>
                        <option value="brawn" disabled={this.shouldBeDisable("brawn")}>
                          Brawn
                        </option>
                        <option value="dex" disabled={this.shouldBeDisable("dex")}>
                          Dex
                        </option>
                        <option value="constitution" disabled={this.shouldBeDisable("constitution")}>
                          Constitution
                        </option>
                        <option value="intellect" disabled={this.shouldBeDisable("intellect")}>
                          Intellect
                        </option>
                        <option value="wisdom" disabled={this.shouldBeDisable("wisdom")}>
                          Wisdom
                        </option>
                        <option value="charisma" disabled={this.shouldBeDisable("charisma")}>
                          Charisma
                        </option>
                      </select>
                    </div>

                    <div>
                      12:&nbsp;
                      <select onChange={(e) => {this.setStat(12, e.currentTarget.value)}}>
                        <option value="">
                          Select/Clear
                        </option>
                        <option value="brawn" disabled={this.shouldBeDisable("brawn")}>
                          Brawn
                        </option>
                        <option value="dex" disabled={this.shouldBeDisable("dex")}>
                          Dex
                        </option>
                        <option value="constitution" disabled={this.shouldBeDisable("constitution")}>
                          Constitution
                        </option>
                        <option value="intellect" disabled={this.shouldBeDisable("intellect")}>
                          Intellect
                        </option>
                        <option value="wisdom" disabled={this.shouldBeDisable("wisdom")}>
                          Wisdom
                        </option>
                        <option value="charisma" disabled={this.shouldBeDisable("charisma")}>
                          Charisma
                        </option>
                      </select>
                    </div>

                    <div>
                      10:&nbsp;
                      <select onChange={(e) => {this.setStat(10, e.currentTarget.value)}}>
                        <option value="">
                          Select/Clear
                        </option>
                        <option value="brawn" disabled={this.shouldBeDisable("brawn")}>
                          Brawn
                        </option>
                        <option value="dex" disabled={this.shouldBeDisable("dex")}>
                          Dex
                        </option>
                        <option value="constitution" disabled={this.shouldBeDisable("constitution")}>
                          Constitution
                        </option>
                        <option value="intellect" disabled={this.shouldBeDisable("intellect")}>
                          Intellect
                        </option>
                        <option value="wisdom" disabled={this.shouldBeDisable("wisdom")}>
                          Wisdom
                        </option>
                        <option value="charisma" disabled={this.shouldBeDisable("charisma")}>
                          Charisma
                        </option>
                      </select>
                    </div>

                    <div>
                      8&nbsp;&nbsp;:&nbsp;
                      <select onChange={(e) => {this.setStat(8, e.currentTarget.value)}}>
                        <option value="">
                          Select/Clear
                        </option>
                        <option value="brawn" disabled={this.shouldBeDisable("brawn")}>
                          Brawn
                        </option>
                        <option value="dex" disabled={this.shouldBeDisable("dex")}>
                          Dex
                        </option>
                        <option value="constitution" disabled={this.shouldBeDisable("constitution")}>
                          Constitution
                        </option>
                        <option value="intellect" disabled={this.shouldBeDisable("intellect")}>
                          Intellect
                        </option>
                        <option value="wisdom" disabled={this.shouldBeDisable("wisdom")}>
                          Wisdom
                        </option>
                        <option value="charisma" disabled={this.shouldBeDisable("charisma")}>
                          Charisma
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="race-selects">
                    Race : <select
                      onChange={(e) => {this.setState({race: e.currentTarget.value})}}
                      defaultValue={this.state.race}
                    >
                      <option value="">
                        Select/Clear
                      </option>
                      {
                        Object.keys(this.randomizer.races || []).map((race, indy) => {
                          return (
                            <option key={indy} value={race}>
                              {race}
                            </option>
                          )
                        })
                      }
                    </select>

                    {
                      this.state.race && (this.randomizer.races[this.state.race].stats || []).map((stat, indy) => {
                        return (
                          <div>
                            <select
                              key={indy}
                              onChange={(e) => {this.modStat(stat[0], e.currentTarget.value, indy)}}
                            >
                              <option value="">
                                Select/Clear
                              </option>
                              <option value="brawn" disabled={this.raceStatShouldBeDisable("brawn", indy)}>
                                Brawn
                              </option>
                              <option value="dex" disabled={this.raceStatShouldBeDisable("dex", indy)}>
                                Dex
                              </option>
                              <option value="constitution" disabled={this.raceStatShouldBeDisable("constitution", indy)}>
                                Constitution
                              </option>
                              <option value="intellect" disabled={this.raceStatShouldBeDisable("intellect", indy)}>
                                Intellect
                              </option>
                              <option value="wisdom" disabled={this.raceStatShouldBeDisable("wisdom", indy)}>
                                Wisdom
                              </option>
                              <option value="charisma" disabled={this.raceStatShouldBeDisable("charisma", indy)}>
                                Charisma
                              </option>
                            </select>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            }
          </div>

          <div className='collapseable'>
            <h2 onClick={() => {this.state.show === "Overview" ? this.setState({show: ""}) : this.setState({show: "Overview"})}}>
              Overview
            </h2>

            {
              this.state.show === "Overview" &&
              <div className="hidden-list">
                <ol>
                  <li>
                    <u>
                      We will create characters together during Session 1, but try getting an idea to start with. <strong>If below is confusing, just wait.</strong>
                    </u>
                  </li>

                  <li>
                    Bonds are idea starters for character stories that would be hooked into the story.
                  </li>

                  <li>
                    You get Race/Class/Background. Below are all approved no questions asked by GM.
                  </li>

                  <li>
                    If you want to use other Races/Classes/Backgrounds I just have to make sure it can fit into the world and the story.
                  </li>

                  <li>
                    Alignment is mostly a roleplaying guide, but can be used by magic items.
                  </li>

                  <li>
                    Customized origin is allowed, so races can swap ability and skills. For example, Half-Elf can change Charisma score to be Dexterity.
                  </li>
                </ol>
              </div>
            }
          </div>

          <div className='collapseable'>
            <h2 onClick={() => {this.state.show === "Alignment" ? this.setState({show: ""}) : this.setState({show: "Alignment"})}}>
              Alignment
            </h2>

            {
              this.state.show === "Alignment" &&
              <div className="hidden-list alignment-list">
                <div>
                  Alignment helps roleplay your character. Various interpretations exist, I will be flexible if it makes sense. A great way to plan a character arc is to have their alignment move.
                </div>

                <div>
                  <br/>
                  <a href="https://www.wizards.com/default.asp?x=dnd%2Fdnd%2F20001222b" target="_blank" rel="noreferrer"> Official Quiz </a>
                  <br/>
                </div>

                <div className='collapseable'>
                  <h4 className="subcollapse" onClick={() => {this.state.subShow === "ethics" ? this.setState({subShow: ""}) : this.setState({subShow: "ethics"})}}>
                    Ethics/Law/Chaos Axis
                  </h4>

                  {
                    this.state.subShow === "ethics" &&
                    <ol>
                    <li>
                      <strong>
                        Lawful
                      </strong>
                      <br/>
                      A Lawful character follows the rules, respects hierarchy and believes in power given by society. Lawful characters believe in honour, following traditions and being trustworthy. They have faith in societal rules because they are how you create a functioning society where everyone acts in the way you expect.
                      <br/>
                      While they may sound noble, Lawful characters can be inflexible and blindly follow the rules even if they make no sense. They can harshly judge others that don’t follow the same rules and place their loyalty to the system above their loyalty to friends and family.
                    </li>

                    <li>
                      <strong>
                        Neutral
                      </strong>
                      <br/>
                      Sitting in the middle of the ethical axis, Neutral characters do not feel compelled to follow every rule and societal belief, nor do they feel the need to disrupt and rebel against everything. A Neutral character follows the rules that align with their own agenda, that are convenient to them, or they think are necessary.
                      <br/>
                      Neutral characters will usually follow the rules because they generally lead to a better outcome for them. However, they can break the rules if they believe the benefits to them outweigh the risks.
                      <br/>
                      Characters with a Neutral alignment may see themselves as superior to Lawful and Chaotic aligned characters because in not taking sides, they are the only alignment with true freedom to decide.
                    </li>
                    <li>
                      <strong>
                        Chaotic
                      </strong>
                      <br/>
                      Chaotic aligned characters aren’t random in their actions. Instead, they are simply the opposite side of the ethics spectrum to Lawful characters. Chaotic aligned characters live by their own rules instead of the rules society has decreed. They believe that is the only way an individual is truly free and can live up to their full potential.
                      <br/>
                      Chaotic characters dislike and distrust authority, don’t like following orders, and live life on their own terms. They can be flexible and adaptable and do what fits the situation and their own agenda.
                      <br/>
                      Characters with a Chaotic alignment may rebel against rules to try to bring about change or simply to create anarchy. They may take actions with a complete disregard for the consequences.
                    </li>
                    </ol>
                  }
                </div>

                <div className='collapseable'>
                  <h4 className="subcollapse" onClick={() => {this.state.subShow === "morality" ? this.setState({subShow: ""}) : this.setState({subShow: "morality"})}}>
                    Morality/Altruism/Egoism Axis
                  </h4>

                  {
                    this.state.subShow === "morality" &&
                    <ol>
                    <li>
                      <strong>
                        Good
                      </strong>
                      <br/>
                      Good characters care about others and act in ways that help and benefit them. They protect the innocent, defend the weak, and go out of their way to help other people.
                      <br/>
                      While they may sound noble, Lawful characters can be inflexible and blindly follow the rules even if they make no sense. They can harshly judge others that don’t follow the same rules and place their loyalty to the system above their loyalty to friends and family.
                    </li>

                    <li>
                      <strong>
                        Neutral
                      </strong>
                      <br/>
                      Neutral characters are very much in the middle ground. They do not go to the extremes of Evil characters and harm the innocent, and likewise, they will not go to the extremes of Good and take actions that harm themselves to help others.
                      <br/>
                      The actions of Neutral characters are driven by their loyalty to others and themselves. If an action is in their best interest or in the best interest of those to whom they are most loyal, they will take it.
                      <br/>
                      While all characters may naturally change alignments through character development, it is especially common for Neutral characters to move to a Good or Evil alignment if the majority of their actions favour one moral alignment over the other.
                    </li>
                    <li>
                      <strong>
                        Evil
                      </strong>
                      <br/>
                      Evil characters care about themselves and their needs/desires over all others. They lack compassion, caring not if they harm others, valuing their own desires over others needs.
                    </li>
                    </ol>
                  }
                </div>

                <h4>
                  The Alignments
                </h4>

                <table className="alignment-table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                        Law
                      </th>
                      <th>
                        Neutral
                      </th>
                      <th>
                        Chaos
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Good
                      </td>
                      <td>
                        Lawful Good
                      </td>
                      <td>
                        Neutral Good
                      </td>
                      <td>
                        Chaotic Good
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Neutral
                      </td>
                      <td>
                        Lawful Neutral
                      </td>
                      <td>
                        True Neutral
                      </td>
                      <td>
                        Chaotic Neutral
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Evil
                      </td>
                      <td>
                        Lawful Evil
                      </td>
                      <td>
                        Neutral Evil
                      </td>
                      <td>
                        Chaotic Evil
                      </td>
                    </tr>
                  </tbody>
                </table>


                <div>
                  <em>
                    For for first time PCs, please stick to non-evil, Chaotic Neutral can be problematic for other PCs.
                  </em>
                </div>

                <div>
                  <ol>
                    <li>
                      {this.labeled("Lawful Good", "Crusaders upholding the law, tradition, honor to help people and making a better world. Internal conflict over unjust laws.")}
                      {this.labeled("Examples", "Superman, Aragorn, Dalinar, Early Captain America")}
                      <strong> Sample Types: </strong>
                      <ul>
                        <li>
                          Adhering to Law, Order, and Tradition is Good
                        </li>

                        <li>
                          There is No Good Without Justice
                        </li>

                        <li>
                          Honor and Tradition are Paramount to Good Society
                        </li>
                      </ul>
                    </li>

                    <li>
                      {this.labeled("Neutral Good", "Works to help people, generaly following law and tradition until they are unjust.")}
                      {this.labeled("Examples", "Gandalf, Spiderman, Kaladin, Windrunners End Game Captain America/Iron Man")}
                      <strong> Sample Types: </strong>
                      <ul>
                        <li>
                          Laws are not inherently good
                        </li>

                        <li>
                          No Philosophies are perfect, simply guides to goodness
                        </li>

                        <li>
                          Traditions get the benefit of the doubt, but are not sacrosanct
                        </li>
                      </ul>
                    </li>

                    <li>
                      {this.labeled("Chaotic Good", "Follow their consicences to help people no matter what, often in the face of societal norms, traditions, and laws. Conflict when following traditions is the good thing to do.")}
                      {this.labeled("Examples", "Lift, Star Lord, Early Iron Man, Qui Gon Jinn, Ep. VI Han Solo")}
                      <strong> Sample Types: </strong>
                      <ul>
                        <li>
                          Laws and Traditions are tools of Tyrants
                        </li>

                        <li>
                          There is No Good Without Freedom and Individuality
                        </li>

                        <li>
                          Freedom to do anything but harm
                        </li>
                      </ul>
                    </li>

                    <li>
                      {this.labeled("Lawful Neutral", "Upholds the law because it is the law. Without law, tradition, order, all is lost, so ends justify the means.")}
                      {this.labeled("Examples", "James Bond, Nahle, Szeth, Skybreakers, Black Widow")}
                      <strong> Sample Types: </strong>
                      <ul>
                        <li>
                          The Ends Justify The Means
                        </li>

                        <li>
                          Law and Justice bring Order, the only true aim
                        </li>

                        <li>
                          Emotions and Minds are fragile, we must adhere to a code
                        </li>
                      </ul>
                    </li>

                    <li>
                      {this.labeled("True Neutral", "Don’t take sides, mostly pragmatic rather than emotional in their actions, choosing the response which makes the most sense for them in each situation. However, they have lines they do not cross.")}
                      {this.labeled("Examples", "Lara Croft, Treebeard, Drax the Destoyer")}
                      <strong> Sample Types: </strong>
                      <ul>
                        <li>
                          Loyalty and Honor to my side only
                        </li>

                        <li>
                          Nautre is balance, not Philosophy or Morality
                        </li>

                         <li>
                          One must not interfere, not break the balance
                        </li>
                      </ul>
                    </li>

                    <li>
                      {this.labeled("Chaotic Neutral", "Need to be free and have no one tell them what to do. While they may take delight in unwraveling the establishment, they draw some lines, like not enjoying hurting people.")}
                      {this.labeled("Examples", "Captain Jack Sparrow, Hulk, Gollum, Ep. IV Han Solo")}
                      <strong> Sample Types: </strong>
                      <ul>
                        <li>
                          Live free, don't join
                        </li>

                        <li>
                          Laws and Rules impede freedom
                        </li>

                        <li>
                          Life is to be enjoyed by my standards
                        </li>
                      </ul>
                    </li>

                    <li>
                      {this.labeled("Lawful Evil", "Prioritize law, order, and tradition, but will do so to advance their own ambitions. Lawful Evil characters feel no guilt or remorse for causing harm to in the way of their ambitions if the law allows it. ")}
                      {this.labeled("Examples", "Darth Vader, Tarkin, Umbridge")}
                      <strong> Sample Types: </strong>
                      <ul>
                        <li>
                          I'm not bad if I follow the law
                        </li>

                        <li>
                          The law is my framework to absolute power
                        </li>

                        <li>
                          Order favors me, so I favor order
                        </li>
                      </ul>
                    </li>

                    <li>
                      {this.labeled("Neutral Evil", "Is wholly selfish. They follow government, tradition and laws if it helps them, with no moral or ethical boundaries to their desries.")}
                      {this.labeled("Examples", "Palpatine, Thanos, Jabba the Hutt, Frank Underwood")}
                      <strong> Sample Types: </strong>
                      <ul>
                        <li>
                          Nothing matters besides my desires
                        </li>

                        <li>
                          The law is paramount, for others
                        </li>

                        <li>
                          Greed is Good
                        </li>
                      </ul>
                    </li>

                    <li>
                      {this.labeled("Chaotic Evil", "Selfish with complete disregard for law, order, and freedom of others. Wants to watch the world burn, and wants to laugh as it happens.")}
                      {this.labeled("Examples", "The Joker, Bellatrix")}
                      <strong> Sample Types: </strong>
                      <ul>
                        <li>
                          Just want to watch the world burn
                        </li>

                        <li>
                           Morals and codes are bad jokes. When the chips are down they'll eat each other. I'm not a monster, just ahead of the curve.
                        </li>

                        <li>
                          Freedom is paramount, but only for me
                        </li>
                      </ul>
                    </li>
                  </ol>
                </div>

                <div>
                  <h4>
                    Character Arc and Alignment Changes
                  </h4>

                  A simple character arc plan is your Character moving alignments as they adventure and see things.
                  <ul>
                    <li>
                      Captain America moving Lawful Good to Neutral Good as he finds SHIELD and the government not worthy of his trust.
                    </li>

                    <li>
                      Ironman moving Chaotic Good to Neutral Good as he finds teamwork and cooperation essential to deal with major threats, including ones he made.
                    </li>

                    <li>
                      Han Solo moving Chaotic Neutral to Chaotic Good as he invests in the Rebellion, Luke, and Leia.
                    </li>

                    <li>
                      Luke Skywalker Neutral Good to True Neutral to Neutral Good
                    </li>
                  </ul>
                </div>

                <div>
                  <a href="http://easydamus.com/alignment.html" target="_blank" rel="noreferrer"> A Detailed Deep Dive </a>
                </div>
              </div>
            }
          </div>

          <div className='collapseable'>
            <h2 onClick={() => {this.state.show === "Bonds" ? this.setState({show: ""}) : this.setState({show: "Bonds"})}}>
              Bonds
            </h2>

            {
              this.state.show === "Bonds" &&
              <div className="hidden-list">
                <br/>
                <div>
                  <u>
                    <strong>
                      &nbsp;&nbsp;PCs just be in Neverwinter and accept Gurden's job to escort Wildon to Phandalin. Here some ideas to get you going:
                    </strong>
                  </u>
                </div>

                <ol>
                  <li>
                    You are in Neverwinter and really need work. This Rockseeker dwarf seems to have a simple job.
                  </li>

                  <li>
                    Gundren Rockseeker has helped you in the past, he is excited and says he really needs your help to escort some supplies to Phandalin.
                  </li>

                  <li>
                    You fought in the War of the Silver Marches. It officially ended 4 years ago, but not for you. Memories of the orcs, drow, giants, and dragons stay with you. Maybe you found glory, or the war destroyed all you cared for. You are far from home in Neverwinter looking for work because:
                    <ol>
                      <li>
                        Rebels Orcs rejecting the peace are raiding, and you are tracking them. Your leads are cold and pockets are empty.
                      </li>
                      <li>
                        You fled the trauma, adventuring distracts you from the nighmares.
                      </li>
                      <li>
                        Dark Elves started the war, then you heard whispers of a survivor working in the Sword Coast. You are feeding yourself while you search.
                      </li>
                    </ol>

                  </li>

                  <li>
                    Your grandfather on his deathbed confessed to have been part of the Evil Cult of the Dragon. He left that life behind long ago. He recalls that the Cult had been interested in the legendary Mine of Phandalin long ago, but they could never find it. With increased activity in Phandalin, he knows that they will come. He would have gone himself but is unable. He begs you to make restitution for his mistakes, to hope to save his soul. He suggests posing as an adventurer and going to scope it out low-key, so you made your way to Neverwinter.
                  </li>

                  <li>
                    Your parents are unknown/murdered/deadbeats, and you ended up:
                      <ol>
                        <li>
                          Adopted
                        </li>
                        <li>
                          Orphanage
                        </li>
                        <li>
                          The Streets
                        </li>
                        <li>
                          Raised at a monastery
                        </li>
                        <li>
                          Escaped from an Evil Cult
                        </li>
                      </ol>
                  </li>

                  <li>
                    Your parents seem normal but:
                    <ol>
                      <li>
                        Small signs suggest that they are more than they seem.
                      </li>

                      <li>
                        Basic genetics suggest that your parents cannot both be your biological parents
                      </li>
                    </ol>
                  </li>

                  <li>
                    You woke up a few days ago without a single memory. You are finding work to feed yourself and figure out who you are.
                  </li>

                  <li>
                    You have a secret. You were a Gold Dragon, but Bahamut (Lord of good dragons) decided to teach you a lesson by trapping you in the body of a weak/wretched humanoid who recently died. You burst from the graveyard totally disoriented. The body has a past of his own, but currently kin/friends/enemies think they are dead. Your dragon memories, and those from your new body's life are faded and misty, but occasionally pop up. An image flashes in your mind occasionally of Bahamut ordering you to go to Phandalin, as you search for employment to get you there.
                  </li>

                  <li>
                    You are part of a faction
                    <Link to="/factions">
                      (Facation List here)
                    </Link>
                    , friend/lord/family are part of a faction, or you were hired by them. Whatever the reason:
                    <ol>
                      <li>
                        <strong>
                          Harpers: &nbsp;
                        </strong>

                        You are sent by Leosin Erlanthar to check on and support Sister Gaerele, a Harper agent in the town. He says that many forces are moving towards Phandalin, and the more agents on scene the better.
                      </li>

                      <li>
                        <strong>
                          Lord's Alliance: &nbsp;
                        </strong>

                        You are asked by seasoned knight Sildar Hallwinter to be an adventurer and take the job from Rockseeker to escort the supplies. He does not explain fully, just says that Phandalin needs the Alliance.
                      </li>

                      <li>
                        <strong>
                          Order of the Gauntlet: &nbsp;
                        </strong>

                        Ranking paladin Ontharr Frume has messaged you to keep an eye on Gundren Rockseeker, something is happening in Phandalin and he wants to be in the know. He directs you to find retired member Daran Edermath, Frume will write further instructions to him.
                      </li>

                      <li>
                        <strong>
                          Order of the Gilded Eye (Raidcal Gauntlet Faction): &nbsp;
                        </strong>

                        Gilded Eye Leader Javen Tarmikos has ordered you to move towards Phandalin, as signs point to darkness stirring. Find and exterminate.
                      </li>

                      <li>
                        <strong>
                          Emerald Enclave: &nbsp;
                        </strong>

                        You see discrete messages from other members that balance is in danger on the Sword Coast, centered around Phandalin. You find the offer of payment from Gundren Rockseeker perfect chance to go to the town and hunt down the Emerald Enclave member supposedly in the area, Reidoth.
                      </li>

                      <li>
                        <strong>
                          Zhentarim: &nbsp;
                        </strong>

                        You received an anonymous message from a superior member asking for assistance in Phandalin, offering riches and power if you pass the tryout and prove worthy of helping.
                      </li>

                      <li>
                        <strong>
                          Moonstars: &nbsp;
                        </strong>

                        Many forces and factions gather on Phandalin, you are to make sure the good work together and keep the town from falling into the wrong hands.
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
            }
          </div>

          <div className='collapseable'>
            <h2 onClick={() => {this.state.show === "Classes" ? this.setState({show: ""}) : this.setState({show: "Classes"})}}>
              Classes
            </h2>

            {
              this.state.show === "Classes" &&
              <div className="hidden-list">
                <ul>
                  <li>
                    <a href="https://preview.redd.it/8lmn1ejfzy271.png?width=960&crop=smart&auto=webp&s=8579e92a525d11d1f48b5839b62f49b2f330ff35">Handy Comparison Chart</a>
                  </li>
                  <li
                    className="class-li"
                    style={{
                      "backgroundImage": 'url("https://media-waterdeep.cursecdn.com/avatars/thumbnails/4808/187/340/340/636771210176732976.png")'
                    }}
                  >
                    <h4>
                      Barbarian
                    </h4>

                    <div className="blurb">
                      A fierce warrior of primitive background who can enter a battle rage
                    </div>
                    <br/>
                    {this.labeled("Hit Die", "d12")}
                    {this.labeled("Primary Ability", "Strength")}

                    {this.labeled("Saves", "Strength & Constitution")}

                    <strong>Links:</strong>
                    <ul>
                      <li>
                        <a
                          href="https://www.dndbeyond.com/classes/barbarian"
                          target="_blank"
                          rel="noreferrer"
                        >
                          General Info
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://dnd5e.wikidot.com/barbarian"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Details/All-Subclasses/Abilities/Spells
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li
                    className="class-li"
                    style={{
                      "backgroundImage": 'url("https://media-waterdeep.cursecdn.com/avatars/thumbnails/4808/188/340/340/636771210370826107.png")'
                    }}
                  >
                    <h4>
                      Bard
                    </h4>

                    <div className="blurb">
                      An inspiring magician whose power echoes the music of creation
                    </div>

                    {this.labeled("Hit Die", "d8")}

                    {this.labeled("Primary Ability", "Charisma")}

                    {this.labeled("Saves", "Dexterity & Charisma")}

                    <strong>Links:</strong>
                    <ul>
                      <li>
                        <a
                          href="https://www.dndbeyond.com/classes/bard"
                          target="_blank"
                          rel="noreferrer"
                        >
                          General Info
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://dnd5e.wikidot.com/bard"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Details/All-Subclasses/Abilities/Spells
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li
                    className="class-li"
                    style={{
                      "backgroundImage": 'url("https://media-waterdeep.cursecdn.com/avatars/thumbnails/4808/192/340/340/636771210659206541.png")'
                    }}
                  >
                    <h4>
                      Cleric
                    </h4>

                    <div className="blurb">
                      A priestly champion who wields divine magic in service of a higher power
                    </div>

                    {this.labeled("Hit Die", "d8")}

                    {this.labeled("Primary Ability", "Wisdom")}

                    {this.labeled("Saves", "Wisdom & Charisma")}

                    <strong>Links:</strong>
                    <ul>
                      <li>
                        <a
                          href="https://www.dndbeyond.com/classes/cleric"
                          target="_blank"
                          rel="noreferrer"
                        >
                          General Info
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://dnd5e.wikidot.com/cleric"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Details/All-Subclasses/Abilities/Spells
                        </a>
                      </li>
                    </ul>
                  </li>


                  <li
                    className="class-li"
                    style={{
                      "backgroundImage": 'url("https://media-waterdeep.cursecdn.com/avatars/thumbnails/4808/193/340/340/636771210953582280.png")'
                    }}
                  >
                    <h4>
                      Druid
                    </h4>

                    <div className="blurb">
                      A priest of the Old Faith, wielding the powers of nature and adopting animal forms
                    </div>

                    {this.labeled("Hit Die", "d8")}

                    {this.labeled("Primary Ability", "Wisdom")}

                    {this.labeled("Saves", "Intelligence & Wisdom")}

                    <strong>Links:</strong>
                    <ul>
                      <li>
                        <a
                          href="https://www.dndbeyond.com/classes/druid"
                          target="_blank"
                          rel="noreferrer"
                        >
                          General Info
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://dnd5e.wikidot.com/druid"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Details/All-Subclasses/Abilities/Spells
                        </a>
                      </li>
                    </ul>
                  </li>


                  <li
                    className="class-li"
                    style={{
                      "backgroundImage": 'url("https://media-waterdeep.cursecdn.com/avatars/thumbnails/4808/196/340/340/636771211243925649.png")'
                    }}
                  >
                    <h4>
                      Fighter
                    </h4>

                    <div className="blurb">
                      A master of martial combat, skilled with a variety of weapons and armor
                    </div>

                    {this.labeled("Hit Die", "d10")}

                    {this.labeled("Primary Ability", "Strength or Dexterity")}

                    {this.labeled("Saves", "Strength & Constitution")}

                    <strong>Links:</strong>
                    <ul>
                      <li>
                        <a
                          href="https://www.dndbeyond.com/classes/fighter"
                          target="_blank"
                          rel="noreferrer"
                        >
                          General Info
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://dnd5e.wikidot.com/fighter"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Details/All-Subclasses/Abilities/Spells
                        </a>
                      </li>
                    </ul>
                  </li>


                  <li
                    className="class-li"
                    style={{
                      "backgroundImage": 'url("https://media-waterdeep.cursecdn.com/avatars/thumbnails/4808/198/340/340/636771211453159586.png")'
                    }}
                  >
                    <h4>
                      Monk
                    </h4>

                    <div className="blurb">
                      A master of martial arts, harnessing the power of the body in pursuit of physical and spiritual perfection
                    </div>

                    {this.labeled("Hit Die", "d8")}

                    {this.labeled("Primary Ability", "Dexterity & Wisdom")}

                    {this.labeled("Saves", "Strength & Dexterity")}

                    <strong>Links:</strong>
                    <ul>
                      <li>
                        <a
                          href="https://www.dndbeyond.com/classes/monk"
                          target="_blank"
                          rel="noreferrer"
                        >
                          General Info
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://dnd5e.wikidot.com/monk"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Details/All-Subclasses/Abilities/Spells
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li
                    className="class-li"
                    style={{
                      "backgroundImage": 'url("https://media-waterdeep.cursecdn.com/avatars/thumbnails/4808/200/340/340/636771211793932705.png")'
                    }}
                  >
                    <h4>
                      Paladin
                    </h4>

                    <div className="blurb">
                      A holy warrior bound to a sacred oath
                    </div>

                    {this.labeled("Hit Die", "d10")}

                    {this.labeled("Primary Ability", "Strength & Charisma")}

                    {this.labeled("Saves", "Wisdom & Charisma")}

                    <strong>Links:</strong>
                    <ul>
                      <li>
                        <a
                          href="https://www.dndbeyond.com/classes/paladin"
                          target="_blank"
                          rel="noreferrer"
                        >
                          General Info
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://dnd5e.wikidot.com/paladin"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Details/All-Subclasses/Abilities/Spells
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li
                    className="class-li"
                    style={{
                      "backgroundImage": 'url("https://media-waterdeep.cursecdn.com/avatars/thumbnails/4808/202/340/340/636771212023190165.png")'
                    }}
                  >
                    <h4>
                      Ranger
                    </h4>

                    <div className="blurb">
                      A warrior who combats threats on the edges of civilization
                    </div>

                    {this.labeled("Hit Die", "d10")}

                    {this.labeled("Primary Ability", "Dexterity & Wisdom")}

                    {this.labeled("Saves", "Strength & Dexterity")}

                    <strong>Links:</strong>
                    <ul>
                      <li>
                        <a
                          href="https://www.dndbeyond.com/classes/ranger"
                          target="_blank"
                          rel="noreferrer"
                        >
                          General Info
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://dnd5e.wikidot.com/ranger"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Details/All-Subclasses/Abilities/Spells
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li
                    className="class-li"
                    style={{
                      "backgroundImage": 'url("https://media-waterdeep.cursecdn.com/avatars/thumbnails/4808/206/340/340/636771212243378100.png")'
                    }}
                  >
                    <h4>
                      Rogue
                    </h4>

                    <div className="blurb">
                      A scoundrel who uses stealth and trickery to overcome obstacles and enemies
                    </div>

                    {this.labeled("Hit Die", "d8")}

                    {this.labeled("Primary Ability", "Dexterity")}

                    {this.labeled("Saves", "Dexterity & Intelligence")}

                    <strong>Links:</strong>
                    <ul>
                      <li>
                        <a
                          href="https://www.dndbeyond.com/classes/rogue"
                          target="_blank"
                          rel="noreferrer"
                        >
                          General Info
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://dnd5e.wikidot.com/rogue"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Details/All-Subclasses/Abilities/Spells
                        </a>
                      </li>
                    </ul>
                  </li>


                  <li
                    className="class-li"
                    style={{
                      "backgroundImage": 'url("https://media-waterdeep.cursecdn.com/avatars/thumbnails/4808/208/340/340/636771212509661854.png")'
                    }}
                  >
                    <h4>
                      Sorcerer
                    </h4>

                    <div className="blurb">
                      A spellcaster who draws on inherent magic from a gift or bloodline
                    </div>

                    {this.labeled("Hit Die", "d6")}

                    {this.labeled("Primary Ability", "Charisma")}

                    {this.labeled("Saves", "Constitution & Charisma")}

                    <strong>Links:</strong>
                    <ul>
                      <li>
                        <a
                          href="https://www.dndbeyond.com/classes/sorcerer"
                          target="_blank"
                          rel="noreferrer"
                        >
                          General Info
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://dnd5e.wikidot.com/sorcerer"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Details/All-Subclasses/Abilities/Spells
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li
                    className="class-li"
                    style={{
                      "backgroundImage": 'url("https://media-waterdeep.cursecdn.com/avatars/thumbnails/4808/209/340/340/636771212694225349.png")'
                    }}
                  >
                    <h4>
                      Warlock
                    </h4>

                    <div className="blurb">
                      A wielder of magic that is derived from a bargain with an extraplanar entity
                    </div>

                    {this.labeled("Hit Die", "d8")}

                    {this.labeled("Primary Ability", "Charisma")}

                    {this.labeled("Saves", "Wisdom & Charisma")}

                    <strong>Links:</strong>
                    <ul>
                      <li>
                        <a
                          href="https://www.dndbeyond.com/classes/warlock"
                          target="_blank"
                          rel="noreferrer"
                        >
                          General Info
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://dnd5e.wikidot.com/warlock"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Details/All-Subclasses/Abilities/Spells
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li
                    className="class-li"
                    style={{
                      "backgroundImage": 'url("https://media-waterdeep.cursecdn.com/avatars/thumbnails/4808/213/340/340/636771212938995926.png")'
                    }}
                  >
                    <h4>
                      Wizard
                    </h4>

                    <div className="blurb">
                      A scholarly magic-user capable of manipulating the structures of reality
                    </div>
                    {this.labeled("Hit Die", "d6")}
                    {this.labeled("Primary Ability", "Intelligence")}

                    {this.labeled("Saves", "Intelligence & Wisdom")}

                    <strong>Links:</strong>
                    <ul>
                      <li>
                        <a
                          href="https://www.dndbeyond.com/classes/wizard"
                          target="_blank"
                          rel="noreferrer"
                        >
                          General Info
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://dnd5e.wikidot.com/wizard"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Details/All-Subclasses/Abilities/Spells
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li
                    className="class-li"
                    style={{
                      "backgroundImage": 'url("https://media-waterdeep.cursecdn.com/avatars/thumbnails/7116/589/340/340/637037885363790565.png")'
                    }}
                  >
                    <h4>
                      Artificer
                    </h4>

                    <div className="blurb">
                      Masters of invention, artificers use ingenuity and magic to unlock extraordinary capabilities in objects.
                    </div>

                    {this.labeled("Hit Die", "d8")}

                    {this.labeled("Primary Ability", "Intelligence")}

                    {this.labeled("Saves", "Constitution & Intelligence")}

                    <strong>Links:</strong>
                    <ul>
                      <li>
                        <a
                          href="https://www.dndbeyond.com/classes/artificer"
                          target="_blank"
                          rel="noreferrer"
                        >
                          General Info
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://dnd5e.wikidot.com/artificer"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Details/All-Subclasses/Abilities/Spells
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>

              </div>
            }
          </div>

          <div className='collapseable'>
            <h2 onClick={() => {this.state.show === "Races" ? this.setState({show: ""}) : this.setState({show: "Races"})}}>
              Races
            </h2>

            {
              this.state.show === "Races" &&
              <div className="hidden-list">
                <ul>
                  <li
                    className="class-li"
                    style={{
                      "backgroundImage": 'url("https://media-waterdeep.cursecdn.com/avatars/thumbnails/4808/221/340/340/636771214201929248.png")'
                    }}
                  >
                    <h4>
                      Dragonborn
                    </h4>

                    <div className="blurb">
                      Dragonborn look very much like dragons standing erect in humanoid form, though they lack wings or a tail.
                    </div>

                    {this.labeled("Racial Traits", "+2 Strength, +1 Charisma, Draconic Ancestry, Breath Weapon, Damage Resistance")}

                    <strong>Links:</strong>
                    <ul>
                      <li>
                        <a
                          href="https://www.dndbeyond.com/races/Dragonborn"
                          target="_blank"
                          rel="noreferrer"
                        >
                          General Info
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://dnd5e.wikidot.com/dragonborn"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Details/All-Subraces/Alternate Selections/etc
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li
                    className="class-li"
                    style={{
                      "backgroundImage": 'url("https://media-waterdeep.cursecdn.com/avatars/thumbnails/4808/229/340/340/636771215011009840.png")'
                    }}
                  >
                    <h4>
                      Dwarf
                    </h4>

                    <div className="blurb">
                      Bold and hardy, dwarves are known as skilled warriors, miners, and workers of stone and metal.
                    </div>

                    {this.labeled("Racial Traits", "+2 Constitution, Darkvision, Dwarven Resilience, Dwarven Combat Training, Stonecunning")}

                    <strong>Links:</strong>
                    <ul>
                      <li>
                        <a
                          href="https://www.dndbeyond.com/races/Dwarf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          General Info
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://dnd5e.wikidot.com/dwarf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Details/All-Subraces/Alternate Selections/etc
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li
                    className="class-li"
                    style={{
                      "backgroundImage": 'url("https://media-waterdeep.cursecdn.com/avatars/thumbnails/4808/233/340/340/636771215280662397.png")'
                    }}
                  >
                    <h4>
                      Elf
                    </h4>

                    <div className="blurb">
                      Elves are a magical people of otherworldly grace, living in the world but not entirely part of it.
                    </div>

                    {this.labeled("Racial Traits", "+2 Dexterity, Darkvision, Keen Senses, Fey Ancestry, Trance")}

                    <strong>Links:</strong>
                    <ul>
                      <li>
                        <a
                          href="https://www.dndbeyond.com/races/Elf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          General Info
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://dnd5e.wikidot.com/elf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Details/All-Subraces/Alternate Selections/etc
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li
                    className="class-li"
                    style={{
                      "backgroundImage": 'url("https://media-waterdeep.cursecdn.com/avatars/thumbnails/4808/238/340/340/636771215623971660.png")'
                    }}
                  >
                    <h4>
                      Gnome
                    </h4>

                    <div className="blurb">
                      A gnome’s energy and enthusiasm for living shines through every inch of his or her tiny body.
                    </div>

                    {this.labeled("Racial Traits", "+2 Intelligence, Darkvision, Gnome Cunning")}

                    <strong>Links:</strong>
                    <ul>
                      <li>
                        <a
                          href="https://www.dndbeyond.com/races/Gnome"
                          target="_blank"
                          rel="noreferrer"
                        >
                          General Info
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://dnd5e.wikidot.com/gnome"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Details/All-Subraces/Alternate Selections/etc
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li
                    className="class-li"
                    style={{
                      "backgroundImage": 'url("https://media-waterdeep.cursecdn.com/avatars/thumbnails/4808/241/340/340/636771215841402193.png")'
                    }}
                  >
                    <h4>
                      Half-Elf
                    </h4>

                    <div className="blurb">
                      Half-elves combine what some say are the best qualities of their elf and human parents.
                    </div>

                    {this.labeled("Racial Traits", "+2 Charisma, +1 to Two Other Ability Scores, Darkvision, Fey Ancestry, Skill Versatility")}

                    <strong>Links:</strong>
                    <ul>
                      <li>
                        <a
                          href="https://www.dndbeyond.com/races/Half-Elf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          General Info
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://dnd5e.wikidot.com/Half-elf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Details/All-Subraces/Alternate Selections/etc
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li
                    className="class-li"
                    style={{
                      "backgroundImage": 'url("https://media-waterdeep.cursecdn.com/avatars/thumbnails/4808/242/340/340/636771216077429600.png")'
                    }}
                  >
                    <h4>
                      Halfling
                    </h4>

                    <div className="blurb">
                      The diminutive halflings survive in a world full of larger creatures by avoiding notice or, barring that, avoiding offense.
                    </div>

                    {this.labeled("Racial Traits", "+2 Dexterity, Lucky, Brave, Halfling Nimbleness")}

                    <strong>Links:</strong>
                    <ul>
                      <li>
                        <a
                          href="https://www.dndbeyond.com/races/Halfling"
                          target="_blank"
                          rel="noreferrer"
                        >
                          General Info
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://dnd5e.wikidot.com/halfling"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Details/All-Subraces/Alternate Selections/etc
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li
                    className="class-li"
                    style={{
                      "backgroundImage": 'url("https://media-waterdeep.cursecdn.com/avatars/thumbnails/4808/245/340/340/636771216332458941.png")'
                    }}
                  >
                    <h4>
                      Half-Orc
                    </h4>

                    <div className="blurb">
                      Half-orcs’ grayish pigmentation, sloping foreheads, jutting jaws, prominent teeth, and towering builds make their orcish heritage plain for all to see.
                    </div>

                    {this.labeled("Racial Traits", "+2 Strength, +1 Constitution, Darkvision, Menacing, Relentless Endurance, Savage Attacks")}

                    <strong>Links:</strong>
                    <ul>
                      <li>
                        <a
                          href="https://www.dndbeyond.com/races/Half-Orc"
                          target="_blank"
                          rel="noreferrer"
                        >
                          General Info
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://dnd5e.wikidot.com/Half-orc"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Details/All-Subraces/Alternate Selections/etc
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li
                    className="class-li"
                    style={{
                      "backgroundImage": 'url("https://media-waterdeep.cursecdn.com/avatars/thumbnails/4808/248/340/340/636771216546324720.png")'
                    }}
                  >
                    <h4>
                      Human
                    </h4>

                    <div className="blurb">
                      Humans are the most adaptable and ambitious people among the common races. Whatever drives them, humans are the innovators, the achievers, and the pioneers of the worlds.
                    </div>

                    {this.labeled("Racial Traits Default Option", "+1 to All Ability Scores, Extra Language")}

                    {this.labeled("Racial Traits Better Option", "+1 to Two Ability Scores, One Skill Proficiency, One Feat, Extra Language")}

                    <strong>Links:</strong>
                    <ul>
                      <li>
                        <a
                          href="https://www.dndbeyond.com/races/Human"
                          target="_blank"
                          rel="noreferrer"
                        >
                          General Info
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://dnd5e.wikidot.com/human"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Details/All-Subraces/Alternate Selections/etc
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li
                    className="class-li"
                    style={{
                      "backgroundImage": 'url("https://media-waterdeep.cursecdn.com/avatars/thumbnails/4808/252/340/340/636771216810902999.png")'
                    }}
                  >
                    <h4>
                      Tiefling
                    </h4>

                    <div className="blurb">
                      To be greeted with stares and whispers, to suffer violence and insult on the street, to see mistrust and fear in every eye: this is the lot of the tiefling.
                    </div>

                    {this.labeled("Racial Traits", "+2 Charisma, +1 Intelligence, Darkvision, Hellish Resistance, Infernal Legacy")}

                    <strong>Links:</strong>
                    <ul>
                      <li>
                        <a
                          href="https://www.dndbeyond.com/races/Tiefling"
                          target="_blank"
                          rel="noreferrer"
                        >
                          General Info
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://dnd5e.wikidot.com/tiefling"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Details/All-Subraces/Alternate Selections/etc
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li
                    className="class-li"
                  >
                    <h4>
                      Customized Origin
                    </h4>

                    <div className="blurb">
                      You are of mixed ancestry or appear just as most members of your race, but are counter to standard stereotypes.
                    </div>

                    {this.labeled("Racial Traits", "+2 One Ability, One Feat, Darkvision or Skill Proficiency, Extra Language")}

                    <strong>Links:</strong>
                    <ul>
                      <li>
                        <a
                          href="http://dnd5e.wikidot.com/custom-origin"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Details/All-Subraces/Alternate Selections/etc
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <h4>
                      Others
                    </h4>
                    There are many other races, some are and some are not for the Forgotten Realms setting, but you can always talk with the DM:
                    <br/>
                    <a href="https://www.dndbeyond.com/races">
                      All Races
                    </a>
                  </li>
                </ul>
              </div>
            }
          </div>
          <div className='collapseable'>
            <h2 onClick={() => {this.state.show === "Backgrounds" ? this.setState({show: ""}) : this.setState({show: "Backgrounds"})}}>
              Backgrounds
            </h2>

            {
              this.state.show === "Backgrounds" &&
              <div className="hidden-list">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:acolyte"
                      rel="noreferrer"
                    >
                      Acolyte
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:anthropologist"
                      rel="noreferrer"
                    >
                      Anthropologist
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:archaeologist"
                      rel="noreferrer"
                    >
                      Archaeologist
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:athlete"
                      rel="noreferrer"
                    >
                      Athlete
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:charlatan"
                      rel="noreferrer"
                    >
                      Charlatan
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:city-watch"
                      rel="noreferrer"
                    >
                      City Watch
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:clan-crafter"
                      rel="noreferrer"
                    >
                      Clan Crafter
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:cloistered scholar"
                      rel="noreferrer"
                    >
                      Cloistered Scholar
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:courtier"
                      rel="noreferrer"
                    >
                      Courtier
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:criminal"
                      rel="noreferrer"
                    >
                      Criminal
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:entertainer"
                      rel="noreferrer"
                    >
                      Entertainer
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:faceless"
                      rel="noreferrer"
                    >
                      Faceless
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:faction agent"
                      rel="noreferrer"
                    >
                      Faction Agent (Custom)
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:faction agent"
                      rel="noreferrer"
                    >
                      Faction Agent (Order of the Gauntlet)
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:faction agent"
                      rel="noreferrer"
                    >
                      Faction Agent (Harpers)
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:faction agent"
                      rel="noreferrer"
                    >
                      Faction Agent (Emerald Enclave)
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:faction agent"
                      rel="noreferrer"
                    >
                      Faction Agent (Lord's Alliance)
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:faction agent"
                      rel="noreferrer"
                    >
                      Faction Agent (Zhentarim)
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:far traveler"
                      rel="noreferrer"
                    >
                      Far Traveler
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:fisher"
                      rel="noreferrer"
                    >
                      Fisher
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:folk hero"
                      rel="noreferrer"
                    >
                      Folk Hero
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:gladiator"
                      rel="noreferrer"
                    >
                      Gladiator
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:guild artisan"
                      rel="noreferrer"
                    >
                      Guild Artisan
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:guild merchant"
                      rel="noreferrer"
                    >
                      Guild Merchant
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:haunted one"
                      rel="noreferrer"
                    >
                      Haunted One
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:hermit"
                      rel="noreferrer"
                    >
                      Hermit
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:house agent"
                      rel="noreferrer"
                    >
                      House Agent
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:inheritor"
                      rel="noreferrer"
                    >
                      Inheritor
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:investigator"
                      rel="noreferrer"
                    >
                      Investigator
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:knight"
                      rel="noreferrer"
                    >
                      Knight
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:knight of the order"
                      rel="noreferrer"
                    >
                      Knight of the Order
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:marine"
                      rel="noreferrer"
                    >
                      Marine
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:mercenary veteran"
                      rel="noreferrer"
                    >
                      Mercenary Veteran
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:noble"
                      rel="noreferrer"
                    >
                      Noble
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:outlander"
                      rel="noreferrer"
                    >
                      Outlander
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:pirate"
                      rel="noreferrer"
                    >
                      Pirate
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:sage"
                      rel="noreferrer"
                    >
                      Sage
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:sailor"
                      rel="noreferrer"
                    >
                      Sailor
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:shipwright"
                      rel="noreferrer"
                    >
                      Shipwright
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:smuggler"
                      rel="noreferrer"
                    >
                      Smuggler
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:soldier"
                      rel="noreferrer"
                    >
                      Soldier
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:spy"
                      rel="noreferrer"
                    >
                      Spy
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:urban bounty hunter"
                      rel="noreferrer"
                    >
                      Urban Bounty Hunter
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:urchin"
                      rel="noreferrer"
                    >
                      Urchin
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:uthgardt tribe member"
                      rel="noreferrer"
                    >
                      Uthgardt Tribe Member
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/background:waterdhavian noble"
                      rel="noreferrer"
                    >
                      Waterdhavian Noble
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://dnd5e.wikidot.com/#toc13"
                      rel="noreferrer"
                    >
                      ALL BACKGROUNDS
                    </a>
                    &nbsp; Including ones needing some work to fit.
                  </li>

                </ul>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Creation;
