import React from "react";

import { Link }              from "react-router-dom";

class Details extends React.Component {
  render() {
    return (
      <div className="standard-panel">
        <h1>
          Background Details
        </h1>

        <div className="internal-panel">
          <h4>
            Current Date: Mirtul 5
          </h4>

          <a href="https://app.fantasy-calendar.com/calendars/1e1b8152f5462d8e2bf9096e7dca1fb9" target="_blank" rel="noreferrer">
            Full Calendar
          </a>

          <h2>
            <a href="/divines/">
              Divines
            </a>
          </h2>

          <h2>
            <Link to="/events/">
              Events
            </Link>
          </h2>

          <h2>
            <Link to="/factions/">
              Factions
            </Link>
          </h2>

          <h2>
            <Link to="/locations/">
              Locations
            </Link>
          </h2>

          <h2>
            <Link to="/planes/">
              Planes
            </Link>
          </h2>
        </div>
      </div>
    )
  }
}

export default Details;
