const tearsData = {
  3: {
    Cecil: [
      "King Blargh, told him his mother was so ugly that Hruggek did not want her"
    ],
  },
  5: {
    Cecil: [
      "Brad, viciously mocked while waiting in line to die",
      "Bryce, viciously mocked while waiting in line to die"
    ],
  },
  6: {
    Cecil: [
      "Gogt, 'Out the door last cause you worthless'",
      "Qevin, 'Is the name tag for you to remember?'",
      "Devin, 'Was your name reused??'"
    ],
  },
  8: {
    Cecil: [
      "Zombie “you’re not going to be shuffling anymore” but it got back up..",
      "Janky leg zombie, “can’t even do the Cupid shuffle” and killed"
    ]
  },
  9: {
    Cecil: [
      "Zombie “you’re not going to be shuffling anymore” but it got back up..",
      "Janky leg zombie, “can’t even do the Cupid shuffle” and killed",
      "Giant Spider. 'You're not even sure if you are a spider of a horse and what the hell are these mushrooms'",
      "Shadow. 'Why only Jello in intestines you need protein'"
    ]
  },
  12: {
    Cecil: [
      "Strige. 'You suck at everything...except blood'"
    ]
  },
  14: {
    Cecil: [
      "Devastaor, 'How's Chemo going?",
      "Hobgoblin, he is worthless without captain and comrade",
      "Bugbear, DM can't even print anything for you so you are Chewie",
      "Bugbear,you skipped leg day"
    ]
  },
  19: {
    Cecil: [
      "To the first Ogre: You musta lost a bet cause they sent you out"
    ]
  },
  20: {
    Cecil: [
      "Kills Blago saying your head is a cherry tomato"
    ]
  },
  22: {
    Cecil: [
      "Ogre, Get ready to get a train run on you",
      "Ogre, YOU'RE NEXT FUCKER"
    ]
  },
  25: {
    Cecil: [
      "Mavol, I can't wait to fucking kill you",
    ]
  },
  26: {
    Cecil: [
      "Halfling Wizard, I know why your mother never hugged you, it is hard to embrace your mistakes",
    ]
  },
  33: {
    Cecil: [
      "Fire Giant, YOU COULDN'T GET FROSTED TIPS!",
      "Fleeing Salamander, COWARD!"
    ]
  }


}

export default tearsData
