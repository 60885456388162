import namesData         from "../../src/data/names";

class PrimaveraRandomizer {

  generateList(num, opts) {
    this.list = []

    for (var i = num; i > 0; i--) {
      this.generate(opts);
    }

    return this.list;
  }

  generate(opts) {
    const init = {
      race: opts.race || this.random(Object.keys(this.races)),
      sex: opts.sex || this.random(["♂", "♀"])
    }

    this.list.push({
      race: init.race,
      sex: init.sex,
      klass: this.random(this.classes),
      background: this.random(this.backgrounds),
      families: this.weightedRandom(this.families),
      alignment: this.random(this.alignment.slice(0,12)),
      name: this.nameFun(init)
    })
  }

  genRelationship() {
    return this.weightedRandom(this.relationship);
  }

  random(collection) {
    return collection[Math.floor(Math.random()*collection.length)]
  }

  weightedRandom(spec) {
    var i, sum=0, r=Math.random();
    for (i in spec) {
      sum += spec[i];
      if (r <= sum) return i;
    }
  }

  nameFun(opts) {
    let result = {}
    let nameConfig = namesData[opts.race];

    if(!nameConfig) {
      const keyed = Object.keys(namesData).filter((race) => {
        return opts.race.split(" ")[1] === race
      })[0]

      nameConfig = namesData[keyed];
    }

    if(nameConfig) {
      result.first = this.firstNamer(nameConfig, opts.sex);

      const middleNamed = nameConfig.middle

      if(middleNamed) {
        if(middleNamed.borrowOptions) {
          const randoRace = namesData[this.random(middleNamed.borrowOptions)];
          result.middle = this.random(randoRace.middle)
        }
        else {
          result.middle = this.random(middleNamed)
        }
      }

      const lastNamed = nameConfig.last

      if(lastNamed) {
        if(lastNamed.borrowOptions) {
          const randoRace = namesData[this.random(lastNamed.borrowOptions)];
          result.last = this.random(randoRace.last)
        }
        else {
          result.last = this.random(lastNamed)
        }
      }
    }

    return result
  }

  firstNamer(nameConfig, sex) {
    const firstNamed = nameConfig.first

    if(firstNamed[sex]) {
      return this.random(firstNamed[sex])
    }
    else if (firstNamed.neutral) {
      return this.random(firstNamed.neutral)
    }
    else if(firstNamed.borrowOptions) {
      const randoConfig = namesData[this.random(firstNamed.borrowOptions)]
      return this.firstNamer(randoConfig, sex);
    }
  }


  backgrounds = [
    "Acolyte",
    "Anthropologist",
    "Archaeologist",
    "Athlete",
    "Charlatan",
    "City Watch",
    "Clan Crafter",
    "Cloistered Scholar",
    "Courtier",
    "Criminal",
    "Entertainer",
    "Faceless",
    "Faction Agent (Custom)",
    "Faction Agent (Order of the Gauntlet)",
    "Faction Agent (Harpers)",
    "Faction Agent (Emerald Enclave)",
    "Faction Agent (Lord's Alliance)",
    "Faction Agent (Zhentarim)",
    "Far Traveler",
    "Fisher",
    "Folk Hero",
    "Gladiator",
    "Guild Artisan",
    "Guild Merchant",
    "Haunted One",
    "Hermit",
    "House Agent",
    "Inheritor",
    "Investigator",
    "Knight",
    "Knight of the Order",
    "Marine",
    "Mercenary Veteran",
    "Noble",
    "Outlander",
    "Pirate",
    "Sage",
    "Sailor",
    "Shipwright",
    "Smuggler",
    "Soldier",
    "Spy",
    "Urban Bounty Hunter",
    "Urchin",
    "Uthgardt Tribe Member",
    "Waterdhavian Noble"
  ]



  races = {
    "Aarakocra": 0.005,
    "Protector Aasimar": 0.008,
    "Srouge Aasimar": 0.007,
    "Fallen Aasimar": 0.005,
    "Red Dragonborn": 0.005,
    "Blue Dragonborn": 0.005,
    "White Dragonborn": 0.005,
    "Black Dragonborn": 0.005,
    "Green Dragonborn": 0.005,
    "Brass Dragonborn": 0.005,
    "Bronze Dragonborn": 0.005,
    "Gold Dragonborn": 0.005,
    "Silver Dragonborn": 0.005,
    "Copper Dragonborn": 0.005,
    "Amethyst Dragonborn": 0.002,
    "Sapphire Dragonborn": 0.002,
    "Emerald Dragonborn": 0.002,
    "Topaz Dragonborn": 0.002,
    "Crystal Dragonborn": 0.002,
    "Hill Dwarf": 0.0425,
    "Mountain Dwarf": 0.0425,
    "Duergar": 0.02,
    "Loth Drow": 0.0075,
    "Eilistraee Drow": 0.0075,
    "Eladrin": 0.005,
    "High Elf": 0.03,
    "Sea Elf": 0.015,
    "Shadar-Kai": 0.005,
    "Wood Elf": 0.03,
    "Firbolg": 0.015,
    "Fire Genasi": 0.005,
    "Water Genasi": 0.005,
    "Earth Genasi": 0.005,
    "Air Genasi": 0.005,
    "Githyanki": 0.005,
    "Githzerai": 0.005,
    "Goliath": 0.02,
    "Deep Gnome": 0.016,
    "Forest Gnome": 0.028,
    "Rock Gnome": 0.036,
    "Lightfoot Halfling": 0.027,
    "Stout Halfling": 0.027,
    "Ghostwise Halfling": 0.027,
    "Lotusden Halfling": 0.009,
    "Half Elf": 0.05,
    "Half Orc": 0.05,
    "Imperial Mixed Ancestry": 0.055,
    "Mixed Ancestry": 0.025,
    "Uniya": 0.02,
    "Human": 0.14,
    "Kenku": 0.01,
    "Lizardfolk": 0.01,
    "Bugbear": 0.006,
    "Goblin":  0.008,
    "Hobgoblin":  0.006,
    "Kobold": 0.008,
    "Orc":  0.006,
    "Tabaxi": 0.01,
    "Asmodeus Tiefling": 0.008,
    "Baalzebul Tiefling": 0.004,
    "Dispater Tiefling": 0.004,
    "Fierna Tiefling": 0.004,
    "Glasya Tiefling": 0.004,
    "Levistus Tiefling": 0.004,
    "Mammon Tiefling": 0.004,
    "Mephistopheles Tiefling": 0.004,
    "Zariel Tiefling": 0.004,
    "Warforged": 0.02,
    "Triton": 0.005,
    "Tortle": 0.005,
    "Yuan-Ti":  0.006,
  }

  // classes = [
  //   "Barbarian",
  //   "Bard",
  //   "Blood Hunter",
  //   "Cleric",
  //   "Druid",
  //   "Fighter",
  //   "Monk",
  //   "Paladin",
  //   "Ranger",
  //   "Rogue",
  //   "Sorcerer",
  //   "Warlock",
  //   "Wizard",
  //   "Artificer"
  // ]


  alignment = [
    ["Lawful Good", "There is No Good Without Justice"],
    ["Lawful Good", "Honor and Tradition are Paramount to Good Society"],

    ["Neutral Good", "Laws are not inherently good"],
    ["Neutral Good", "No Philosophy is perfect, they are guides"],

    ["Chaotic Good", "Laws and Traditions are tools of Tyrants"],
    ["Chaotic Good", "There is No Good Without Freedom and Individuality"],

    ["Lawful Neutral", "Law and Justice bring Order, the only true aim"],
    ["Lawful Neutral", "The Ends Justify The Means"],

    ["True Neutral", "Loyalty and Honor to my side only"],
    ["True Neutral", "Nautre is balance, not Philosophy or Morality"],

    ["Chaotic Neutral", "Laws and Rules impede freedom"],
    ["Chaotic Neutral", "Lives are to be enjoyed own standards"],

    ["Lawful Evil", "The law is my framework to absolute power"],
    ["Lawful Evil", "Order favors me, so I favor order"],

    ["Neutral Evil", "Kill anyone for to help me"],
    ["Neutral Evil", "Nothing matters besides my desires"],

    ["Chaotic Evil", "I just want to watch the world burn"],
    ["Chaotic Evil", "My Freedom and Desires are important, only mine"],
  ]

  families = {
    "None": 0.02,
    "Institution, such as an asylum": 0.03,
    "Temple": 0.03,
    "Orphanage": 0.06,
    "Guardian": 0.04,
    "Paternal or maternal aunt, uncle, or both": 0.08,
    "Paternal or maternal grandparent(s)": 0.1,
    "Adoptive family (same or different race)": 0.1,
    "Single father or stepfather": 0.17,
    "Single mother or stepmother": 0.17,
    "Mother and father": 0.20
  }

  relationship = {
    "Commoner": 0.05,
    "Acolyte": 0.05,
    "Bandit": 0.05,
    "Barbarian": 0.05,
    "Cult fanatic": 0.05,
    "Druid": 0.05,
    "Gladiator": 0.05,
    "Guard": 0.05,
    "Knight": 0.05,
    "Priest": 0.05,
    "Scout": 0.05,
    "Spy": 0.05,
    "Tribal warrior": 0.05,
    "Veteran": 0.05,
    "Mage": 0.04,
    "Noble": 0.04,
    "Assassin": 0.04,
    "Faction Member (DM's choice)": 0.07,
    "Good or neutral werebear or weretiger (DM's choice)": 0.02,
    "Evil wereboar, wererat, or werewolf (DM's choice)": 0.02,
    "Archmage": 0.01,
    "Dragon (DM's choice)": 0.01,
  }

  classes = [
    "Artificer:Alchemist",
    "Artificer:Armorer",
    "Artificer:Artillerist",
    "Artificer:Battle Smith",
    "Barbarian:Ancestral Guardian",
    "Barbarian:Battlerager",
    "Barbarian:Beast",
    "Barbarian:Berserker",
    "Barbarian:Storm Herald",
    "Barbarian:Totem Warrior",
    "Barbarian:Wild Magic",
    "Barbarian:Zealot",
    "Bard:Creation",
    "Bard:Eloquence",
    "Bard:Glamour",
    "Bard:Lore",
    "Bard:Spirits",
    "Bard:Swords",
    "Bard:Tragedy",
    "Bard:Valor",
    "Bard:Whispers",
    "Cleric:Arcana",
    "Cleric:Blood",
    "Cleric:Death",
    "Cleric:Forge",
    "Cleric:Grave",
    "Cleric:Knowledge",
    "Cleric:Life",
    "Cleric:Light",
    "Cleric:Moon",
    "Cleric:Nature",
    "Cleric:Order",
    "Cleric:Peace",
    "Cleric:Tempest",
    "Cleric:Trickery",
    "Cleric:Twilight",
    "Cleric:War",
    "Druid:Dreams",
    "Druid:Forged",
    "Druid:Land",
    "Druid:Moon",
    "Druid:Shepherd",
    "Druid:Spores",
    "Druid:Stars",
    "Druid:Wildfire",
    "Druid:Blight",
    "Fighter:Arcane Archer",
    "Fighter:Banneret",
    "Fighter:Battle Master",
    "Fighter:Cavalier",
    "Fighter:Champion",
    "Fighter:Echo Knight",
    "Fighter:Eldritch Knight",
    "Fighter:Psi Warrior",
    "Fighter:Rune Knight",
    "Fighter:Samurai",
    "Monk:Astral Self",
    "Monk:Drunken Master",
    "Monk:Four Elements",
    "Monk:Kensei",
    "Monk:Long Death",
    "Monk:Mercy",
    "Monk:Open Hand",
    "Monk:Shadow",
    "Monk:Sun Soul",
    "Monk:Ascendant Dragon",
    "Paladin:Ancients",
    "Paladin:Conquest",
    "Paladin:Crown",
    "Paladin:Devotion",
    "Paladin:Glory",
    "Paladin:Redemption",
    "Paladin:Vengeance",
    "Paladin:Watchers",
    "Paladin:Oathbreaker",
    "Paladin:Open Sea",
    "Ranger:Beast Master",
    "Ranger:Fey Wanderer",
    "Ranger:Gloom Stalker",
    "Ranger:Horizon Walker",
    "Ranger:Hunter",
    "Ranger:Monster Slayer",
    "Ranger:Swarmkeeper",
    "Ranger:Drakewarden",
    "Rogue:Arcane Trickster",
    "Rogue:Assassin",
    "Rogue:Inquisitive",
    "Rogue:Mastermind",
    "Rogue:Phantom",
    "Rogue:Scout",
    "Rogue:Soulknife",
    "Rogue:Swashbuckler",
    "Rogue:Thief",
    "Sorcerer:Aberrant Mind",
    "Sorcerer:Clockwork Soul",
    "Sorcerer:Draconic Bloodline",
    "Sorcerer:Divine Soul",
    "Sorcerer:Shadow Magic",
    "Sorcerer:Storm Sorcery",
    "Sorcerer:Wild Magic",
    "Sorcerer:Runechild",
    "Warlock:Archfey",
    "Warlock:Celestial",
    "Warlock:Fathomless",
    "Warlock:Fiend",
    "Warlock:The Genie",
    "Warlock:Great Old One",
    "Warlock:Hexblade",
    "Warlock:Undead",
    "Warlock:Undying",
    "Wizard:Abjuration",
    "Wizard:Bladesinging",
    "Wizard:Blood",
    "Wizard:Chronurgy",
    "Wizard:Conjuration",
    "Wizard:Divination",
    "Wizard:Enchantment",
    "Wizard:Evocation",
    "Wizard:Graviturgy",
    "Wizard:Illusion",
    "Wizard:Necromancy",
    "Wizard:Order of Scribes",
    "Wizard:Transmutation",
    "Wizard:War Magic",
    "Wizard:Onomancy",
  ]

}

export default PrimaveraRandomizer;
