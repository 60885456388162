import React     from 'react';
import { Link }  from "react-router-dom";
import JsxParser from 'react-jsx-parser'

import Collapsible  from "./Collapsible"

import locationData  from "../../data/locations";
import characterData from "../../data/characters";
import factionData   from "../../data/factions";
import eventData     from "../../data/events";
import articleData from "../../data/articles";

class Linkedable extends React.Component {

  localeCheck(body) {
    Object.keys(eventData).forEach((topKey) => {
      body = (body || "").replace(
        eventData[topKey].name,
        `<Link to={"/pages/${topKey}"}>${eventData[topKey].name}</Link>`
      );
    });

    Object.keys(factionData).forEach((topKey) => {
      Object.keys(factionData[topKey]).forEach((subKey) => {
        body = (body || "").replace(
          factionData[topKey][subKey].name,
          `<Link to={"/pages/${subKey}"}>${factionData[topKey][subKey].name}</Link>`
        );
      });
    });

    Object.keys(locationData).forEach((topKey) => {
      Object.keys(locationData[topKey]).forEach((locationKey) => {
        body = (body || "").replace(
          locationData[topKey][locationKey].name,
          `<Link to={"/pages/${locationKey}"}>${locationData[topKey][locationKey].name}</Link>`
        );
      });
    });

    Object.keys(characterData).forEach((topKey) => {
      Object.keys(characterData[topKey]).forEach((subKey) => {
        Object.keys(characterData[topKey][subKey]).forEach((subSubKey) => {
          body = (body || "").replace(
            characterData[topKey][subKey][subSubKey].name,
            `<Link to={"/pages/${subSubKey}"}>${characterData[topKey][subKey][subSubKey].name}</Link>`
          );
        });
      });
    });

    Object.keys(articleData).forEach((topKey) => {
      Object.keys(articleData[topKey]).forEach((locationKey) => {
        body = (body || "").replace(
          articleData[topKey][locationKey].name,
          `<Link to={"/pages/${locationKey}"}>${articleData[topKey][locationKey].name}</Link>`
        );
      });
    });

    return body;
  }

  render() {
    return(
      <JsxParser components={{ Link, Collapsible }} jsx={ this.localeCheck(this.props.body) } />
    );
  }
}

export default Linkedable;
