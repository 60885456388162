const eventData = {
  Toril: {
    sacking_of_phandalin: {
      name: "Sacking of Phanadlin",
      tagLine: "Loss of the town and Wave Echo Cave",
      enabled: true,
      src: "https://i.pinimg.com/originals/3a/9f/71/3a9f7155e47041160f94ed8c45fbec57.jpg",
      type: "Battle",
      location: "Phanadlin-Area",
      date: "951 DR",
      outcome: "Razing of Phandalin, Loss of Wave Echo Cave",
      blurb: `
        Orcs wandered towards Phanadlin in need of food, sacking settlements along the way. Phandalin was destroyed and the area devastated enough that any survivors did not return.
        <br/><br/>
        Somewhere in the chaos, the Orcs apparently attacked Wave Echo Cave as well. It is rumored that powerful mercenaries must have helped, since the cave itself was quite defended.
      `,
      recentEvents: "Rumors of riches have brought settlers finally back to Phandalin. Gundren Rockseeker and his brothers secretly believe to have found the entrance to Wave Echo Cave."
    },

    spellplague: {
      name: "Spellplague",
      tagLine: "Disaster that transformed Planes",
      enabled: true,
      src: "https://i.pinimg.com/originals/4d/97/2c/4d972c7441628969efa74eb7860f3a26.jpg",
      type: "Cataclysm",
      location: "Toril and Abeir",
      date: "1385–1395 DR",
      cause: "Death of Mystra",
      outcome: "Global turmoil <br/> Temporary destruction of the Weave <br/> Merging of Parts of Abeir and Toril",
      blurb: "The Spellplague, called the Blue Breath of Change by the inhabitants of Abeir, was a disaster that struck Realmspace and even the planes themselves on Tarsakh 29 of the Year of Blue Fire, 1385 DR, and was caused by Mystra's assassination. It continued for a decade, leading to the Wailing Years, during which arcane magic ceased to function and the planet of Toril was transformed. <br/><br/>  For eons, magic had been bound within Mystra's Weave, the universal structure of arcane forces, and her death tore the Weave apart and unleashed the raw magic contained within it, creating the cataclysm that was later known as the Spellplague. The Spellplague manifested in Toril as a storm of blue flames in the Mhair Jungles, west of Halruaa, and quickly grew to a massive size. The blue flames also infected portals and planar gates, spreading even further across Toril. Almost every part of Faerûn was affected by the Spellplague and certain areas were eliminated entirely, while others were created anew. Thousands of spellcasters were either destroyed or went insane due to the collapse of the Weave after Mystra's death. In Cormyr, a third of the War Wizards were either killed or driven mad. Those who survived lost their ability to use arcane magic. It was through the planar gates that the Spellplague traveled across time and space, and reached the world of Abeir. Abeir was destroyed, but parts of Abeir transported to Toril, including the Dragonborn and their capital. <br/><br/> By 1480 DR the spellplauge ended completely, with the rebirth of Mystra. The Second Sundering followed as a last attempt to fix the Spellplauge by Lord Ao."
    },

    secondsunder: {
      name: "Second Sundering",
      tagLine: "Upheaval as the Spellplauge ends",
      enabled: true,
      src: "https://thumbor.forbes.com/thumbor/631x348/http://b-i.forbesimg.com/erikkain/files/2013/08/Sundering.png",
      type: "Cataclysm",
      location: "Toril and Abeir",
      date: "1482–1487 DR",
      outcome: "Separation of Abeir and Toril <br/> Shifts in Divine Rules <br/> Final End of the Spellplague",
      blurb: `
        To most people it was yet another time of tumult and upheaval. Howeer, scholars believe that divine rules were unwritten, leaving gods scrambling to retain their positions. Divine patrons empowered champions with blessings to expand glory and renown, hoping to move up the ladder of divine power. Meanwhile, chaos consumed Toril. The Great Rain flooded the Sea of Fallen Stars, reducing usable land for the year of 1486 DR. The War of the Silver Marches raged, while Netherese flying cities cause havoc and war.
        <br/><br/>
        In the end, Toril and Abeir permanently split. The Dragonborn of Tymanther warred with the returned nation of Unther, but they were on Toril for good. Some gods were weakened, some empowered. Some deads gods returned, some mortals became gods, and some gods died. Non-divine events tapered down as the sundering ended. The Great Rain ended, the Netherese defeated, and the North, though greatly weakened, was at peace.
        <br/><br/>
        Scholars believe that the Second Sundering ended when the divine rules were reset. Scholars, clerics, and even many common faithful noticed that gods suddenly retreated from the world. In 1488 DR gods stopped answering prayers. However, as the year turned to 1489 DR, prayers started getting answered, but not the same as before. The gods were "quieter", far less direct than during the Second Sundering and the centuries prior. In 1489 DR nearly all of them withdrew their blessings from their Chosen, their purpose fullfilled. Some Chosen retained gifts, but for the most part it was an Age of Mortals. Many argue this is a sign that the reset divine rules limit how much gods can interact with mortals.
        <br/><br/>
        Less clear were the Changes in Cosomology. Various models all appeared to fit reality, though Shadowfell, Feywild, and the Elemental Chaos had to be added.
        <br/><br/>
        The aftermath is a new dawn, largely back to how things were before the Spellplague happened, but with some irrevesible changes, like the Dragonborn coming from Toril. It indeed was an Age of Mortals.
      `
    },

    war_of_silver_marches: {
      name: "War of the Silver Marches",
      tagLine: "Latest Orc-Conflict in the North",
      enabled: true,
      src: "https://db4sgowjqfwig.cloudfront.net/campaigns/150146/assets/712680/571136_orig.jpg?1489693542",
      type: "War",
      location: "Silver Marches",
      date: "1484–1485 DR",
      outcome: "Pyrrhic victory for the Silver Marches",
      blurb: "The Northern Orcs of the Many-Arrows Kingdom, the one group of Orcs that had made peace with neighbors, suddenly went to war. They had support from Frost Giants, three White Dragons, including Glazhael the Cloudchaser, and the Zealous of the Black Spider leading groups of Drow. The Orcs destroyed Nesmé and killed nearly the entire population of Sunabar, before laying seige to Dwarven holds of Citadel Adbar, Citadel Felbarr, and Mithril Hall. Later even Silverymoon was besieged. <br/><br/> The legendary Bruenor Battlehammer united the Dwarves and lifted the Seiges on the Dwarven strongholds. His forces went next to Silverymoon where they united with humans and elves, including the Brassblood clan. This alliance fought valiantly to barely defeat the Orc forces. It was in this costly battle that Glazhael the Cloudchaser wiped out all of Lars Rutschignass' unit except him. Drizzt on a Copper Dragon, and Ignatius Brassblood II on a Brass Dragon hunted the fleeing White Dragons, killing the youngest of the White Dragons, but Glazhael the Cloudchaser and Arauthator escaped. <br/><br/> Bruenor restored the true ruler of Many Arrows to the throne, publically claiming that evil Drow magicians of the shadowy Zealous of the Black Spider had schemes to destablize and control the Orcs, pushing them to war. As the war ended, Dwarves claimed that Humans and Elves of Silverymoon had failed to help Sundabar, which lost all of it's population. As a result, the alliance of the Silver Marches ended as the war did. Therefore the North was left devasted and disunited, open to future attacks.",
      recentEvents: "Lars Rutschignass joined the Bloodaxe mercenaries as Lords attempted to bring order, but the Bloodaxes had lost many soldiers and their home, leaving them quite weak. Lars eventually leaves for the Sword Coast."
    },
  },
"First World": {
    // Add Eldritch Obyriths coming from beyond the far realm in another universe and the Seed of Evil
    "100": {
      enabled: true,
      year: "-???",
      blurb: "Overgods/Forebear beings begin creating the Planes, Magic, Divine Sparks",
      tags: ['ancient', 'myth', 'divines']
    },

    "200": {
      enabled: true,
      year: "Many Ages Before Ancient Age",
      blurb: "Elder beings...including Bahamut, Tiamat, Anman, Primodrial Titans exist and assumedly have Divine Sparks along with various other beings. How many cycles? unknown but these are they when we near our time",
      tags: ['ancient', 'myth', 'divines']
    },

    "300": {
      enabled: true,
      year: "Many Ages Before Ancient Age",
      blurb: "Various worlds are populated with beings as life continues to evolve under their guidance. Magic extremely limited (Gandalf-style)",
      tags: ['ancient', 'myth', "divines"]
    },

    "400": {
      enabled: true,
      year: "Many Ages Before Ancient Age",
      blurb: "Anman the All Father of giants a strong elemental connection. He is supposedly part of the creation of more worlds in the material plane as well as forming the giants to rule them. His connection to Primordial Titans is unknown. Primordials dislike this use of their elements, but Anman and other Elder divide the Material Plane far enough from the Elemental Planes to avoid the ire of the Elemental Planes",
      tags: ['ancient', 'myth', 'elemental planes', 'giants', 'anman', "divines"]
    },

    "500": {
      enabled: true,
      year: "Many Ages Before Ancient Age",
      blurb: "Bahamut, Tiamat, Anman combine their powers to create the First World is a source of a lot of magic, more standard DND-style.",
      tags: ['ancient', 'myth', 'giants', 'anman', 'dragons', 'tiamat', 'bahamut', "giant-dragon war", "divines"]
    },

    "600": {
      enabled: true,
      year: "Ages Before Ancient Age",
      blurb: "Local humans on the First world form the ancient beginnings of the Oaken Druidic Civilization which will live in the shadow of Dragons and giants for thousands of years",
      tags: ['ancient', 'myth', 'first world', 'whispervale', 'druidic']
    },

    "700": {
      enabled: true,
      year: "Ages Before Ancient Age",
      blurb: "Elder beings of power Bahamut and Tiamat start creating dragons but they are very few in number",
      tags: ['ancient', 'myth', 'first world', 'dragons', 'tiamat', 'bahamut', "divines"]
    },

    "800": {
      enabled: true,
      year: "Ages Before Ancient Age",
      blurb: "Anman forms giants on this world. They massive empires that last for milenias but...",
      tags: ['ancient', 'myth', 'elemental planes', 'giants', 'anman', "divines"]
    },

    "900": {
      enabled: true,
      year: "Ages Before Ancient Age",
      blurb: "Dragon number slowly increase and start to conflict with Giants slowly interact...then start to fight",
      tags: ['ancient', 'myth', 'giants', 'anman', 'dragons', 'tiamat', 'bahamut', "giant-dragon war", "divines"]
    },

    "1000": {
      enabled: true,
      year: "Ages Before Ancient Age",
      blurb: "Feywild and Shadowfell form as reflections of the Material Plane as life forms. Fey beings and old Shadowfell begin to form out of the echoes of creation and new life in the Material Plane ripple into these planes",
      tags: ['ancient', 'myth', 'feywild', 'shadowfell', "fey", "corellon", "elves" ]
    },


    "1100": {
      enabled: true,
      year: "Ages Before Ancient Age",
      blurb: "Certain Elder Beings start connecting with beings across different worlds...teaching basics of what limited magic can be. One who is taught is Arch Fey Corellon",
      tags: ['ancient', 'myth', 'divines', "corellon", "elves"]
    },

    "1200": {
      enabled: true,
      year: "Ages Before Ancient Age",
      blurb: "Arch Fey Corellon becomes invested in Human of a world (unknown) and starts teaching them magic helping them into through portals.",
      tags: ['ancient', 'myth', 'feywild', "fey", "corellon", "elves", 'divines']
    },

    "1300": {
      enabled: true,
      year: "Ages Before Ancient Age",
      blurb: "Planar travel is rare but groups start probing into Elemental Planes to find their ways to other worlds...draws attention of the Primordial Titans.",
      tags: ['ancient', 'myth', 'divines', "elemental planes", "dawn war", "primordial titans"]
    },

    "1400": {
      enabled: true,
      year: "Ages Before Ancient Age",
      blurb: "Humanoid beings start arriving on The First World, drawn by magic. Corellon is a major driver of multiple worlds. Giants and dragons investigate the new arrivals.",
      tags: ['ancient', 'myth', 'giants', 'anman', 'dragons', 'tiamat', "bahamut", 'corellon', "elves", "giant-dragon war", "divines",  "primordial titans", "dawn war"]
    },

    "1500": {
      enabled: true,
      year: "Ages Before Ancient Age",
      blurb: "Giant-Dragon wars reach a truce as each side is weakened and newcomers have arrived",
      tags: ['ancient', 'myth', 'giants', 'anman', 'dragons', 'tiamat', 'bahamut', "giant-dragon war", "divines",  "primordial titans", "dawn war"]
    },

    "1600": {
      enabled: true,
      year: "Ages Before Ancient Age",
      blurb: "Anman is suspicious of these other beings, so he sides the giants with the Primoridals to stop their travels",
      tags: ['ancient', 'myth', 'giants', 'anman', 'dragons', 'tiamat', "bahamut", 'corellon', "elves", "giant-dragon war", "divines",  "primordial titans", "dawn war"]
    },

    "1700": {
      enabled: true,
      year: "Ages Before Ancient Age",
      blurb: "Gruumsh the Ruiner arrives on the world with his influenced people.",
      tags: ['ancient', 'myth', 'corellon', "elves", "divines", "gruumsh", "orcs"]
    },

    "1800": {
      enabled: true,
      year: "Ages Before Ancient Age",
      blurb: "Corellon's guided people encounter Gruumsh's and they war.",
      tags: ['ancient', 'myth', 'corellon', "elves", "raven queen", "moonweaver", "lloth", "divines", "gruumsh", "orcs"]
    },

    "1900": {
      enabled: true,
      year: "Ages Before Ancient Age",
      blurb: "The whole fight with Corellon and Gruumsh...creation of Elves and Gruumsh comes from this...WHAT HAPPENED?...but either way Elves and Orcs are similar to humans as their creators base them off them.",
      tags: ['ancient', 'myth', 'corellon', "elves", "raven queen", "moonweaver", "lloth", "divines", "gruumsh", "orcs"]
    },

    "2000": {
      enabled: true,
      year: "Ages Before Ancient Age",
      blurb: "Elven Creation from MToF with Corellon, Sehanine, Lloth, and Raven Queen, then Material Plane at The First World as well (Planar exploration continues)",
      tags: ['ancient', 'myth', 'corellon', "elves", "raven queen", "moonweaver", "lloth", "divines", "gruumsh", "orcs"]
    },

    "2100": {
      enabled: true,
      "year": "Ancient (-12K)",
      "blurb": "At first, uneasy exploration...then...Cold War across the First World as Dragons/Giants/Humanoids all fill in territories until they get closer.",
      "tags": ["exploration", "cold war", "first world", "dragons", "giants"]
    },
    "2200": {
      enabled: true,
      "year": "Ancient (-12K)",
      "blurb": "Metallic Dragons want to get along but are still defensive of their territory, Tiamat and the Chromatics start arguing to want to drive them away",
      "tags": ["metallic dragons", "chromatic dragons", "tiamat", "cold war"]
    },
    "2300": {
      enabled: true,
      "year": "Dawn War (-11K)",
      "blurb": "Elementals attack the travelers to the planes, resulting in Material Plane worlds being largely cut off from each other. Invasion of elemental Titans bring devastation.",
      "tags": ["elementals", "planes", "material plane", "isolation", "elemental titans", "devastation", "dawn war"]
    },
    "2400": {
      enabled: true,
      "year": "Dawn War (-11K)",
      "blurb": "The remnants of the Giant Empires quarrel over which side to support. Giants mostly support the Primoridals but their divisions in what to do divide them so greatly it results in the divisions of giant kind. Anman is supposedly begins withdrawing here. Various arguments over why...some that giants were weak...some that they tried to rule unjustly the small folk....etc. Even giant gods are not sure why their father left.",
      "tags": ["giant empires", "quarrel", "primordials", "division", "anman", "giant gods", "dawn war"]
    },
    "2500": {
      enabled: true,
      "year": "Dawn War (-11K)",
      "blurb": "Leaders of the various groups put aside differences and make an alliance to fight the Primordials and their Elemental armies. Gruumsh and Corellon sit on different ends of all tables",
      "tags": ["alliance", "primordials", "elemental armies", "gruumsh", "corellon", "dawn war"]
    },
    "2600": {
      enabled: true,
      "year": "Dawn War (-11K)",
      "blurb": "At the Moot of the Dawn the leaders forge the Dawn Alliance (otherwise no sun would rise again on the material plane)",
      "tags": ["dawn alliance", "moot of the dawn", "material plane", "dawn war"]
    },
    "2700": {
      enabled: true,
      "year": "Dawn War (-11K)",
      "blurb": "They hatch a crazy plot to find Divine Sparks...bands of adventurers/unlikely allies invade the elemental planes to start slaying the Elemental Powers that derived power from them.",
      "tags": ["divine sparks", "adventurers", "allies", "elemental planes", "elemental powers", "dawn war"]
    },

    "2800": {
      enabled: true,
      year: "Dawn War (-11K)",
      blurb: "DAWN TEN are formed, elite from the various groups like the Fellowship",
      tags: ['dawn war', 'dawn ten', 'elite groups', 'fellowship', "dawn war"]
    },

    "2900": {
      enabled: true,
      year: "Dawn War (-11K)",
      blurb: "Elemental Princes are driven back, but not until after they open connection to the Astral and Outer Planes...also Far Realm. Release Tharizdun released from the Abyss",
      tags: ['dawn war', 'elemental princes', 'astral plane', 'outer planes', 'far realm', 'tharizdun', 'abyss', "dawn war"]
    },

    "3000": {
      enabled: true,
      year: "Dawn War (-11K)",
      blurb: "In the Chaos, Tharizdun betrays the Primordials and attacks their capital as THE EIGHT start bickering about how to get in. Tharizdun slew a Primoridal general who had used a Divine Spark to power a weapon in his army....Realizing what would happen if Tharizdun got all the Divine Sparks...they threw aside differences and called in all to attack",
      tags: ['dawn war', 'chaos', 'tharizdun', 'primordials', 'betrayal', 'divine sparks', "dawn war"]
    },

    "3100": {
      enabled: true,
      year: "Dawn War (-11K)",
      blurb: "With Tharizdun ravaging the palace the band fought towards the Divine Spark vault.",
      tags: ['dawn war', 'tharizdun', 'divine spark vault', 'palace siege', "dawn war"]
    },

    "3200": {
      enabled: true,
      year: "Dawn War (-11K)",
      blurb: "The Vault was setup with each spark in a Demi-plane...so the champions each went into one",
      tags: ['dawn war', 'vault', 'demi-plane', 'divine sparks', 'champions', "dawn war"]
    },

    "3300": {
      enabled: true,
      year: "Dawn War (-11K)",
      blurb: "Avandra was wounded and dying...Tel'Amoth shoved her in one instead of himself to save her. He then and another hold off an Elemental Counter attack...being slain in the process. Tel'Amoth ascends to become one of the early Djinn",
      tags: ['dawn war', 'avandra', "tel'amoth", 'djinn', 'sacrifice', 'elemental attack', "dawn war"]
    },

    "3400": {
      enabled: true,
      year: "Dawn War (-11K)",
      blurb: "The ones with Divine Spark became Greater Deities...though the power slowly built in them over a milennia.",
      tags: ['dawn war', 'divine spark', 'greater deities', 'ascension', "dawn war"]
    },

    "3500": {
      enabled: true,
      year: "Dawn War (-11K)",
      blurb: "With the power the good and neutral members scrambled to the Material Plane to fight Tharizdun...",
      tags: ['dawn war', 'material plane', 'tharizdun', 'good and neutral deities', "dawn war"]
    },

    "3600": {
      enabled: true,
      year: "Dawn War (-11K)",
      blurb: "Then Correllon and Gruumsh started to fight again despite the threat since ya know",
      tags: ['dawn war', 'corellon', 'gruumsh', 'deity conflict', "dawn war"]
    },

    "3700": {
      enabled: true,
      year: "Dawn War (-11K)",
      blurb: "Chromatic Dragons lash out at everyone",
      tags: ['dawn war', 'chromatic dragons', 'conflict', "dawn war"]
    },

    "3800": {
      enabled: true,
      year: "Dawn War (-11K)",
      blurb: "Pelor, Ioun, Kord, Erathis, Avandra, Moonweaver focused on Tharizdun...chaining him to existence",
      tags: ['dawn war', 'pelor', 'ioun', 'kord', 'erathis', 'avandra', 'moonweaver', 'tharizdun', "dawn war"]
    },

    "3900": {
      enabled: true,
      year: "Dawn War (-11K)",
      blurb: "Asmodeus wins over The Hells as the newly connected planes of the Abyss start invading to begin the Blood War. He of course did NOT engineer that as a ploy to unite the hells under his leadership....",
      tags: ['dawn war', 'asmodeus', 'the hells', 'abyss', 'blood war', 'invasion', "dawn war"]
    },

    "4000": {
      enabled: true,
      year: "War of the First World (-10.5K)",
      blurb: "The armies of the non-dragons attack back at Chromatic Dragons...Metallic dragons are torn over this war with their kin....but by now Bahamut has decided that a system of good justice should cover not just dragons",
      tags: ['First world war', 'non-dragons', 'chromatic dragons', 'metallic dragons', 'bahamut', 'justice system']
    },

    "4100": {
      enabled: true,
      year: "War of the First World (-10.5K)",
      blurb: "The Chromatic Dragons are defeated...but in the process somehow Saridor is shattered.",
      tags: ['First world war', 'chromatic dragons', 'defeat', 'saridor', 'shattering']
    },

    "4200": {
      enabled: true,
      year: "War of the First World (-10.5K)",
      blurb: "Tiamat attacks a Giant Deity and kills it...but...in the process is somehow sent to the Hells. Rumored to have been tricked by Asmodeus who uses his role a Tiamat's jailer and defender against the Abyss to protect himself legally",
      tags: ['First world war', 'tiamat', 'giant deity', 'hells', 'asmodeus', 'deception']
    },

    "4300": {
      enabled: true,
      year: "War of the First World (-10.5K)",
      blurb: "All worlds of the material plane get bathed in the magic of the First World, Gem dragons form from Saridor's splintered mind.",
      tags: ['First world war', 'material plane', 'magic', 'first world', 'gem dragons', 'saridor']
    },

    "4400": {
      enabled: true,
      year: "(Age of Gods Rising -10K)",
      blurb: "Deities rise in power, claiming the Outer Planes as their own. ",
      tags: ['deity ascension', 'power rise', 'outer planes', 'claim']
    },

    "4500": {
      enabled: true,
      year: "(Age of Gods Rising -10K)",
      blurb: "The Prime deities come into their own power, as humanoids and dragons expand across the worlds of the material plane",
      tags: ['deity power', 'prime deities', 'humanoids', 'dragons', 'expansion', 'material plane']
    },

    "4600": {
      enabled: true,
      year: "(Age of Gods Rising -10K)",
      blurb: "Dragons notices dragon echoes on different worlds...assume it connected to the shattering of Saridor",
      tags: ['dragons', 'echoes', 'different worlds', 'saridor', 'shattering connection']
    },

    "4700": {
      enabled: true,
      year: "(Age of Gods Rising -10K)",
      blurb: "Few dragons are on the First World...unable to figure out why more are not being born or arriving....turns out Giant Wizards created a great barrier to prevent Draconic Echoes from arriving on this planet.",
      tags: ['dragons', 'first world', 'giant wizards', 'barrier', 'draconic echoes']
    },

    "4800": {
      enabled: true,
      year: "-9023 - -5023",
      blurb: "Status: Elven Coronals reign in various areas, human like Roman era civilizations, isolated Dwarves....but most of world is wilds with few dragons",
      tags: ['elven coronals', 'human civilizations', 'roman era', 'isolated dwarves', 'wilds', 'few dragons']
    },

    "4900": {
      enabled: true,
      year: "-----Year and ages of debated counting-----",
      blurb: "Status: Time of Uncertain Chronicles",
      tags: ['uncertain chronicles', 'debated counting']
    },

    "5000": {
      enabled: true,
      year: "-5023",
      blurb: "Status: Elven Coronals reign in various areas, human like Roman era civilizations, isolated Dwarves....but most of world is wilds with few dragons",
      tags: ['elven coronals', 'human civilizations', 'roman era', 'isolated dwarves', 'wilds', 'few dragons']
    },

    "5100": {
      enabled: true,
      year: "-5023",
      blurb: "Human realms dominate the region of Primavera...sea-faring culture like Carthage",
      tags: ['human realms', 'primavera', 'sea-faring culture', 'carthage']
    },

    "5200": {
      enabled: true,
      year: "-5023",
      blurb: "Elven Coronals are inland and their Shimmering Isle",
      tags: ['elven coronals', 'inland territories', 'shimmering isle']
    },

    "5300": {
      enabled: true,
      year: "-5023",
      blurb: "Large Giant empires rule the mountains near Primavera and down south into Verano...but they are not the glorious realms of the past. They are conflicted despite following the Ordning, slow economic times harm them.",
      tags: ['giant empires', 'mountains', 'primavera', 'verano', 'economic decline', 'ordning']
    },

    "5350": {
      enabled: true,
      year: "-3900",
      blurb: "In the rising Oxkadi City States Culture, one king with a name lost to history has the Khopesh Hozjal forged",
      tags: ['oxkadi valley', 'hozjal', 'forging']
    },

    "5400": {
      enabled: true,
      year: "-3824",
      blurb: "Alipyrgos Consortium founded amongst city states at the mouth of the Alipyrgos River...forerunner to Aetherese Empire",
      tags: ['aetherese', 'primavera', 'alipyrgos river', 'aetherese empire']
    },

    "5500": {
      enabled: true,
      year: "-3810",
      blurb: "Aetherese start war with other maritime city states...their navy is crushed in the Battle of the False Tide",
      tags: ['aetherese', 'maritime war', 'city states', 'navy']
    },



    "5600": {
      enabled: true,
      year: "-3805",
      blurb: "The Aetherese Consortium, embarassed by the defeat and the prestige of the Enshulese \"Empire\" declare the Aetherese Empire, electing Isalcis Yzebelis the first Aetherese Empress from among the leading Wizarding Families.",
      tags: ['aetherese', 'arcane research', 'nobility', 'isalcis yzebelis']
    },

    "5650": {
      enabled: true,
      year: "-3805",
      blurb: "Isalcis creates the Declaration of Universal Constorium, establishing a new magocratic government of wizards where positions and votes were based on magical abilities. She created a massive investment in arcnae education for the Aetherese nobility...while facing/conquering/allying with interior tribes outside the Reach to gain warriors",
      tags: ['aetherese', 'arcane research', 'nobility', 'tribes', 'conquest', 'alliance']
    },

    "5700": {
      enabled: true,
      year: "-3796",
      blurb: "After limited successes...One researcher found notes about the Dunamis from worlds across the material plane...possibly with Divine Help",
      tags: ['aetherese', 'research success', 'dunamis', 'material plane', 'divine help']
    },

    "5800": {
      enabled: true,
      year: "-3790",
      blurb: "Isalcis's great-grandson Baclris I began creating gravitational weapons, flying fortresses, and advanced technology to overwhelm their foes.",
      tags: ['aetherese', "isalcis yzebelis", "balcris i", 'dunmancy', 'advanced weapons', 'flying ships']
    },

    "5900": {
      enabled: true,
      year: "-3788",
      blurb: "General Mortarion Ironfist, a mysterious foreigner who rose high in the Aetherese ranks,took an Aetherese Army south to the Enshullese Heartland...there he fights army after army, defeating them all. His brilliant maneuvers are known for generations. In the end his weakened force could not take Enshullese cities, but he limped to the coast where his distraction allowed the Aetherese time to build a colony/port.",
      tags: ['general mortarion ironfist', 'aetherese army', 'enshullese heartland', 'military campaign', 'tactical maneuvers']
    },

    "6000": {
      enabled: true,
      year: "-3785",
      blurb: "Armed with Dunmacy enhanced weapons that phased through defenses...the Aetherese fleet crushed their rivals in the Battle of the Red Shallows",
      tags: ['aetherese', 'dunmancy', 'enhanced weapons', 'fleet', 'battle of the red shallows']
    },

    "6100": {
      enabled: true,
      year: "-3778",
      blurb: "Aetherese become the dominant power in the seas and eastern coast of Primavera",
      tags: ['aetherese', 'dominance', 'primaveran sea', 'power shift']
    },

    "6200": {
      enabled: true,
      year: "-3750 - -3715",
      blurb: "Cappadán the Great, wiedling Hozjal, unites the entire lower Oxkadi valley by a mixture of diplomacy, trickery, and conquest. Many accept this unity to gain his help repel extra planar incursions after an Aetherese dark wizard force opens portals to allow in demons, elementals, and various others into their lands",
      tags: ['cappadán the great', 'oxkadi valley', 'hozjal', 'conquest', 'cultural unification', 'extra planar incursions', 'aetherese dark wizard']
    },

    "6300": {
      enabled: true,
      year: "-3748 - -3735",
      blurb: "The Aetherese Imperial Villa of Balcris is formed high above Librajak out of Aetherese Steel",
      tags: ['aetherese', 'imperial villa', 'balcris', 'librajak', 'aetherese steel']
    },

    "6400": {
      enabled: true,
      year: "-3698 - -3683",
      blurb: "1st Aetherese-Coronal-Alliance War",
      tags: ['1st aetherese-coronal-alliance war', 'conflict', 'alliance', 'war']
    },

    "6500": {
      enabled: true,
      year: "-3698",
      blurb: "Aetherese frontier skirmishers pushed into Verdant Forest lands. They got drunk on stolen Elven wine and tormented the locals. When told to leave, they attacked, killing some Elven adults and abusing their children. Ten young elven version of teenage maidens were kidnapped.",
      tags: ['aetherese', 'frontier skirmishers', 'verdant forest', 'elven conflict', 'kidnapping']
    },

    "6600": {
      enabled: true,
      year: "-3698",
      blurb: "Battle of Bansuri when local Elven force followed them and caught them in a Aetherese frontier fort. The local governor refused to give them up...quickly the light siege drew Verdant Coronal, the Aetherese, then the Marsh Coronal, then more Aetherese forces...until the small fort was surrounded by multiple forces surrounding multiple forces..resulting in epic battle. The Elves break into the fort but find that the 10 kidnapped Elven maidens were snuck out of the fortress.",
      tags: ['battle of bansuri', 'elves', 'aetherese', 'siege', 'kidnapping']
    },

    "6700": {
      enabled: true,
      year: "-3697",
      blurb: "Enraged Elves from across the world, Sun, Moon, Wood, Free Drow, Sea all form the Coronal Conference to wage war against Aetherese. The Emperor was furious and executed local generals, but refused to cave to Elven demands. The War raged",
      tags: ['elven uprising', 'coronal conference', 'war against aethero', 'imperial conflict']
    },

    "6800": {
      enabled: true,
      year: "-3694",
      blurb: "Battle of Mantasus where Drow tunnels lead a Coronal army into an Aetherese city well into the interior of their lands. Elves crush and pillage the city...exacting bloody, brutal revenge for their stolen maidens. The Drow retreat but the city is utterly ruined (never repaired) The Empire digs deeper, claiming atrocities.",
      tags: ['battle of mantasus', 'drow', 'elven revenge', 'aetherese', 'city ruin']
    },

    "6900": {
      enabled: true,
      year: "-3692",
      blurb: "The Burning Massacre, Aetherese ally with an Adult Red Dragon, allowing it transfer into the world, making it the first Dragon in half a millennia to enter the world. It brings a score of other Red dragons...They set it on an unprepared Elven army, massacring it.",
      tags: ['the burning massacre', 'aetherese', 'red dragon', 'elven army', 'massacre']
    },

    "7000": {
      enabled: true,
      year: "-3691",
      blurb: "The Silver Coronal Elves respond by granting passage to Sapphire Dragons who relish the chance to be warriors, and Copper Dragons ready to fight evil tyrants who kidnap maidens. The start of a Dragon Arms race with each side attempting to recruit more and more",
      tags: ['silver coronal elves', 'sapphire dragons', 'copper dragons', 'dragon arms race']
    },

    "7100": {
      enabled: true,
      year: "-3690",
      blurb: "Battle of Zakrastro",
      tags: ['battle of zakrastro']
    },

    "7200": {
      enabled: true,
      year: "-3690",
      blurb: "Lloth Drow make an alliance with the Aetherese to conquer the underdark. The Free Drow get the Coronal Conference to allow more gem dragons in. Gem dragons bring some Dragonborn as well, bringing Dragonborn into the world. Lloth Drow respond with inviting Deep Dragons",
      tags: ['lloth drow', 'aetherese alliance', 'underdark conquest', 'gem dragons', 'dragonborn', 'deep dragons']
    },

    "7300": {
      enabled: true,
      year: "-3688",
      blurb: "A group of Gem Dragonborn offer their service to the Elves. A group of rangers that travel the planes hunting Chromatic Dragons, they claim they can save the maidens. They are called Kinap-halic an odd translation of kidnap. Kinap-halic rangers began to track and rescue the taken maidens. They end up rescuing 10/10 earning praise from the Elves and a promise of a future kingdom. The Lunar Forest Coronal has Vorel-Vivex the dragon slaying short sword forged for the leader of the Gem Dragonborn as a token of their thanks and a sign to promise of their future kingdom.",
      tags: ['gem dragonborn', 'elven aid', 'kinap-halic rangers', 'rescue mission', 'lunar forest coronal', 'vorel-vivex']
    },

    "7400": {
      enabled: true,
      year: "-3688",
      blurb: "Some Elven Coronals allow human concubines to boost their devastated numbers with Half-Elves...this is seen as heresy due to possible death of Elven souls...but they have little choice",
      tags: ['elven coronals', 'half-elves', 'population boost', 'heresy']
    },

    "7500": {
      enabled: true,
      year: "-3687",
      blurb: "An Aetherese mage, Kiela Gorspar, allows passage to an Emerald Dragon in exchange for slaves from other worlds with Dunmancy knowledge. Also a Green Dragon in exchange for knowledgeable slaves. Kiela creates floating ships by gravitational manipulation. Turns out they are not great to manage and keep up until they find more like fort sizes. Mass production begins.",
      tags: ['aetherese mage', 'kiela gorspar', 'emerald dragon', 'green dragon', 'dunmancy', 'floating ships']
    },

    "7600": {
      enabled: true,
      year: "-3687",
      blurb: "Dwarven negotiators attempt to broker peace now that the maidens were freed, but neither side will hear any of it.",
      tags: ['dwarven negotiators', 'peace attempt', 'elven maidens', 'diplomatic failure']
    },

    "7700": {
      enabled: true,
      year: "- 3686",
      blurb: "Storm Giant rulers officially warn both sides that any more allowing of dragons into the world will result in war with them.",
      tags: ['storm giants', 'warning', 'dragon entry', 'war threat']
    },

    "7800": {
      enabled: true,
      year: "-3685",
      blurb: "Aetherese develop flying fortresses and do a rapid invasion of the Marsh Wooden Coronal",
      tags: ['aetherese', 'flying fortresses', 'invasion', 'marsh wooden coronal']
    },

    "7900": {
      enabled: true,
      year: "-3685",
      blurb: "Marsh Coronal is devastated, forests are decimated, citizens slain. Bronze and Sapphire Dragons help the Elves take down the Flying Forts...but the Damage is done",
      tags: ['marsh coronal', 'devastation', 'bronze dragons', 'sapphire dragons', 'elven defense']
    },

    "8000": {
      enabled: true,
      year: "-3684",
      blurb: "With their flying forts gone, and no time available to build more, Aetherese turn defensive as the Elves plot their next move. Both sides are gutted.",
      tags: ['aetherese', 'defensive strategy', 'elven planning', 'military exhaustion']
    },

    "8100": {
      enabled: true,
      year: "-3684",
      blurb: "Giants raid Aetherese lands over the flying cities, a threat to their Cloud Giant castles. Divisions among the giants are laid bare however, as they bicker over how to proceed. Many are punished by the ruling Storm Giants after the raids.",
      tags: ["giants", "aetherese", "flying cities", "cloud giant castles", "storm giants", "raid", "political division"]
    },

    "8200": {
      enabled: true,
      year: "-3684",
      blurb: "Kiela Gorspar makes a pact with Tiamat to allow more Chromatic Dragons to enter the world in exchange for Dunmancy knowledge about Chronurgy. She harvests Reality Crystals, and guides Green dragons to a hidden forest where they slaughter Elven refugees and make it their nest, in exchange for granting her the crystals in the mine.",
      tags: ["kiela gorspar", "tiamat", "chromatic dragons", "dunmancy", "chronurgy", "reality crystals", "green dragons", "elven refugees"]
    },

    "8300": {
      enabled: true,
      year: "-3683",
      blurb: "The Yuan-Ti empire starts sending raiders due to weakened state of both sides...neither can resist much. Dwarves negotiate a truce which holds. The Yuan-Ti withdraw but both sides realize how weak they are and let the truce hold",
      tags: ["yuan-ti empire", "raiders", "dwarven diplomacy", "truce", "political weakness"]
    },

    "8400": {
      enabled: true,
      year: "-3682",
      blurb: "Peace of Makayabas between Coronal Conference and Aetherese Emperor. A delegation of Giants announces they shall stand down as long as the peace holds and no more dragons are allowed in.",
      tags: ["peace of makayabas", "coronal conference", "aetherese emperor", "giants", "peace agreement", "dragon ban"]
    },

    "8500": {
      enabled: true,
      year: "-3682",
      blurb: "Part of the peace is for Aetherese to cede land on the Southern shore of the Sea to be given to the Gem/Metallic Dragons. The Elves retreat from their forts they built out of their lands closer to the Aetherese. No more Dragons are allowed to enter either...and the local population is to be watched closely.",
      tags: ["land cession", "aetherese", "gem dragons", "metallic dragons", "elven retreat", "dragon ban", "surveillance"]
    },

    "8600": {
      enabled: true,
      year: "-3682",
      blurb: "As part of the peace, the Emperor hands over some war criminals that he claims he had no power over. These should include Kiela...but she is saved by her Chromatic allies who quick her away off world...but a green dragon sells her to House Diemondar of Faerun as a slave to harvest her Dunmancy knowledge, Diemondar later allies with the Netherese to attempt to mess with Time",
      tags: ["war criminals", "kiela gorspar", "chromatic dragons", "house diemondar", "faerun", "dunmancy", "netherese", "time manipulation"]
    },

    "8700": {
      enabled: true,
      year: "-3681 - 3650",
      blurb: "A group of Aetherese Schismatics under a pretender to the crown flee before being slaughtered. They ally with similar Yuan-Ti and other races, as they flee south towards the great central sea and the Island of X. They found a civilization there, using high magic to keep themselves isolated.",
      tags: ["aetherese schismatics", "pretender", "yuan-ti", "alliance", "island of x", "civilization", "high magic", "isolation"]
    },

    "8800": {
      enabled: true,
      year: "-3677",
      blurb: "As promised, Elven Coronal Conferences creates the Kinap-halic Realm, appointing an Amethyst Dragonborn as the Lord Kinap-halic. They setup on the Peninsula a the South of the Primaveran Sea. The rest of the lands are granted to Gem and Metallic dragons. The sword Vorel-Vivex is the symbol of the Realm",
      tags: ["elven coronal conferences", "kinap-halic realm", "amethyst dragonborn", "lord kinap-halic", "primaveran sea", "gem dragons", "metallic dragons", "vorel-vivex"]
    },

    "8810": {
      enabled: true,
      "year": "-3672",
      "blurb": "New Aetherese Empress Isherla IV the Grand declares need for better Automatons. Existing ones are more utility, she wants powerful forces to be reckoned with. Dozen present their plans...but Artificer Hachaeus Anacar comes to Thalas and wins her over with his angle. To harness souls if need be to get what she wants. She smiles and gives him near unlimited resources",
      "tags": ["aetherese", "empress isherla iv", "hachaeus anacar", "automatons"]
    },

    "8820": {
      enabled: true,
      "year": "-3665",
      "blurb": "Working relentlessly, Artificer Hachaeus Anacar presents powerful automatons to Empress Isherla, earning a high seat. He oversees more creation of them near Thalas.",
      "tags": ["aetherese", "hachaeus anacar", "automatons", "thalas"]
    },

    "8900": {
      enabled: true,
      year: "-3663",
      blurb: "Recovering from war...Empress Isherla places Hachaeus Anacar in charge of a new project, larger floating fortress to be the size of cities. The idea being that Aetherese could be mobile and transverse the World...Planes...and beyond.",
      tags: ["aetherese empire", "post-war recovery", "cabal", "floating forts", "mobile cities", "planar travel"]
    },

    "9000": {
      enabled: true,
      year: "-3662",
      blurb: "At the site of modern day Thalas massive walls and supports are made to create more flying forts and cities",
      tags: ["thalas", "construction", "flying forts", "urban development"]
    },

    "9100": {
      enabled: true,
      year: "-3645",
      blurb: "Yuan-Ti are weakened by Civil War as Aetherese manipulations divide them ",
      tags: ["yuan-ti", "civil war", "aetherese manipulation", "political instability"]
    },

    "9200": {
      enabled: true,
      "year": "-3644",
      "blurb": "An Aetherese General allies with a Hobgoblin to take a fleet and devastates the Yuan-Ti, driving them deep into the Jungles. The Hobgoblins are allowed to settle in the remnants of the Jungle Empire.",
      "tags": ["aetherese", "hobgoblin", "yuan-ti", "jungle empire", "military campaign"]
    },

    "9250": {
      enabled: true,
      "year": "-3630",
      "blurb": "Hachaeus Anacar launches a new and larger experimental flying city, using an unorthodox power source than the other floating cities and forts. It becamne apparent that the amount of Chrono Crystals needed to power a massive city alone would not possible, so he experimented. He named it Shaal after an ancient Aetherese legendary monster slayer.",
      "tags": ["aetherese", "hachaeus anacar", "shaal", "thalas", "flying cities"]
    },

    "9300": {
      enabled: true,
      "year": "-3622",
      "blurb": "Aetherese Empress Isherla IV the Grand, makes a deal with the Dao to gain the supplies to finish new Floating projects. The Dao in question were actually allied with Deep Dragons who used it as a chance to further enter the world",
      "tags": ["aetherese", "empress isherla iv", "dao", "floating projects", "deep dragons"]
    },

    "9400": {
      enabled: true,
      "year": "-3620 - -3611",
      "blurb": "Giants face war with Dwarves who discover Fire and Frost Giants keeping dwarves as slaves. Storm giants are too withdrawn to notice, focusing mainly on fears of the dragons and others. Control is broken with slave rebellions and wars deep underground where Fire Giants lie and keep their dealings hidden from those above. Conflict ends with a truce as the Fire Giants are punished and the dwarves retreat...but it is clear the Giant realms are shadows of their past glories as the giant types further divide culturally.",
      "tags": ["giants", "dwarves", "fire giants", "frost giants", "slave rebellion", "cultural divide"]
    },

    "9500": {
      enabled: true,
      "year": "-3610",
      "blurb": "Aetherese near completion of \"Grand Isherlon\" a massive floating city and declare it their capital",
      "tags": ["aetherese", "grand isherlon", "floating city", "capital"]
    },

    "9600": {
      enabled: true,
      "year": "-3609",
      "blurb": "Elven Coronals are worried about what this might mean and gather a conference from around the world to address the rising power of the Aetherese.",
      "tags": ["elven coronals", "aetherese", "global conference", "political concern"]
    },

    "9700": {
      enabled: true,
      "year": "-3609",
      "blurb": "The Half-Elves and various mixed raced groups and the Gem Dragonborn come to agree in the fear.",
      "tags": ["half-elves", "mixed races", "gem dragonborn", "aetherese threat"]
    },

    "9800": {
      enabled: true,
      "year": "-3609",
      "blurb": "The Hobgoblins Empire of the Jungles agrees that the Floating Aetherese are a danger and prepare for war.",
      "tags": ["hobgoblins empire", "jungles", "aetherese threat", "war preparation"]
    },

    "9900": {
      enabled: true,
      "year": "-3608",
      "blurb": "Goliaths, Giants, Dwarves, Elves, Half-Elves, Humans, and Hobgoblins all unite to form a force to move on Grand Isherlon before it is complete.",
      "tags": ["goliaths", "giants", "dwarves", "elves", "half-elves", "humans", "hobgoblins", "grand isherlon", "united force"]
    },

    "9950": {
      enabled: true,
      "year": "-3607",
      "blurb": "The Aetherese city of Shaal is lost over Verano. No survivors live to tell the tale leaving it a mystery. Artificer Hachaeus Anacar had been on it, and his loss gravely hurt the Aetherese War Effort",
      "tags": ["aetherese", "shaal", "destruction", "verano", "hachaeus anacar"]
    },

    "10000": {
      enabled: true,
      "year": "-3606",
      "blurb": "Empress Isherla has her armies keep the alliance at bay...while inviting in more dragons to help.",
      "tags": ["empress isherla", "military strategy", "alliance", "dragons"]
    },

    "10050": {
      enabled: true,
      "year": "-3605",
      "blurb": "Aetherese Apprentice Chronurgist Mahana Druhar discovers a break through that allows for a 11th level spell to time stop over the whole city.",
      "tags": ["mahana druhar", "aetherse"]
    },

    "10100": {
      enabled: true,
      "year": "-3605",
      "blurb": "As the alliance near Grand Ishleron, A desperate Isherla orders her Chronurgists to use the Reality Crystals to warp time and reality so that the crews working on her city can have more time...literally having a generation of workers trapped in a time bubble to finish it, and scholars to work on Dunmancy.",
      "tags": ["grand ishleron", "isherla", "chronurgists", "reality crystals", "time warp", "dunmancy"]
    },

    "10200": {
      enabled: true,
      "year": "-3604",
      "blurb": "With the time shenanigans the city is complete...it takes off and an aerial war rages. However, the combined force is too much. Realizing that they will be defeated, the Aetherese take their city into the Elemental Planes...but Metallic Dragons invited by the Alliance help cast a counter spell...but instead of stopping the spell it alters it...sending the Aetherese into the Far Realm instead...lost to the chaos and aberrations there",
      "tags": ["aetherese", "elemental planes", "metallic dragons", "counter spell", "far realm", "aerial war"]
    },

    "10205": {
      enabled: true,
      "year": "-3604",
      "blurb": "With the time shenanigans the city is complete...it takes off and an aerial war rages. However, the combined force is too much. Realizing that they will be defeated, the Aetherese take their city into the Elemental Planes...but Metallic Dragons invited by the Alliance help cast a counter spell...but instead of stopping the spell it alters it...sending the Aetherese into the Far Realm instead...lost to the chaos and aberrations there",
      "tags": ["aetherese", "elemental planes", "metallic dragons", "counter spell", "far realm", "aerial war"]
    },

    "10300": {
      enabled: true,
      "year": "-3604",
      "blurb": "The alliance divides up the former Aetherese lands amongst themselves and the Metallic Dragons. The Oxkadi, Enshullese became independent once again",
      "tags": ["alliance", "aetherese lands", "metallic dragons", "oxkadi", "enshullese", "independence"]
    },

    "10400": {
      enabled: true,
      "year": "-3601",
      "blurb": "The Giant Empire collapses. Struggles over power, dangerous forces, possible Dwarven interventions...all result in the crumbling of their ancient lands. Each type retreats into themselves...divided for now.",
      "tags": ["giant empire", "collapse", "power struggle", "dwarven intervention"]
    },

    "10500": {
      enabled: true,
      "year": "-3600",
      "blurb": "An era of peace begins...but little do they know that a lone surviving Aetherese mage, the Apprentice Chronurgist Mahana Druhar, bent on revenge works to destroy the magical protections preventing Dragons from entering the world",
      "tags": ["peace era", "aetherese mage", "sanna druhar", "revenge", "magical protections", "dragons"]
    },

    "10600": {
      enabled: true,
      "year": "-3588",
      "blurb": "The Alliance becomes weaker as drunk on power and majesty, the Coronal wizards dive into new magical realms, challenging the gods and morality in their pursuits. The mad mage under an assumed name starts convincing leading scholars to help her work in weakening a barrier from divine magic to resist the arcane...and vice-versa with the divine magic wielders. In reality she tricked both into weakening the Draconic Barrier",
      "tags": ["alliance", "coronal wizards", "magical realms", "mad mage", "draconic barrier", "divine magic", "arcane magic"]
    },

    "10700": {
      enabled: true,
      "year": "-3585",
      "blurb": "Lorian the Gloried takes becomes a post-Aetherese Empire Aethrerese leader...standardizes a lot of the knowledge, claiming that they had just done it wrong. Begins standard world wide calendar, land exploration, codes of law and fairness. Seen by many as the way to reconcile the past with the future.",
      "tags": ["lorian the gloried", "aetherese empire", "knowledge standardization", "world calendar", "land exploration", "law and fairness"]
    },

    "10800": {
      enabled: true,
      "year": "-3553",
      "blurb": "The mad mage on her death bed she gives her life force to finally open the barrier enough for Dragons to enter through a secret path.",
      "tags": ["mad mage", "deathbed", "life force", "barrier opening", "dragons", "secret path"]
    },

    "10900": {
      enabled: true,
      "year": "-3552 - -3452",
      "blurb": "Chromatic Dragons slowly enter the world into remote places to build their army. World is mostly at peace, but a peace of control and stability not of love. Divine vs Arcane magic increases tensions, while trade disputes increase tensions.",
      "tags": ["chromatic dragons", "army building", "peace", "control", "divine magic", "arcane magic", "trade disputes"]
    },

    "11000": {
      enabled: true,
      "year": "-3454",
      "blurb": "A charismatic woman starts gaining popularity as an Arcanist and popular leader. She travels the divided lands and starts spreading resistance to the Metallic Dragons....",
      "tags": ["charismatic woman", "arcanist", "resistance", "metallic dragons"]
    },

    "11100": {
      enabled: true,
      "year": "-3452",
      "blurb": "The limited number of Metallic Dragons attempt to detain her...but it turns out she is Tiamat in avatar form...the locals think the Metallics are overlords and resist them.",
      "tags": ["metallic dragons", "tiamat", "avatar", "resistance", "misunderstanding"]
    },

    "11200": {
      enabled: true,
      "year": "-3451",
      "blurb": "With this weakness, Tiamat launches her assault",
      "tags": ["tiamat", "assault", "weakness exploitation"]
    },

    "11300": {
      enabled: true,
      "year": "-3451",
      "blurb": "Within days the Primavera Sea is decimated by the Chromatic dragons. She is here as this it the First World and she wants it back. She reinstalls the magic hampering planar travel so Bahamut cannot send his forces. Her avatar is killed by the Solar Ragnarok, who came before she shielded it. But the Chromatic dragons are here to stay. Ragnarok himself stays for a while, has some kids, before finding a way to leave",
      "tags": ["primavera sea", "chromatic dragons", "tiamat", "planar travel", "bahamut", "solar ragnarok"]
    },

    "11400": {
      enabled: true,
      "year": "-3450 - -3420",
      "blurb": "The Chromatic Expansion: The Chromatic Dragons expand their reach while the limited Metallics flee or are slaughtered. Gem Dragons hide underground and plot their moves while the humanoids on the surface are subjugated. Elven Coronals survive with help of the Metallics...but barely. Ancient Gold Dragon Aurinthor sacrifices himself",
      "tags": ["chromatic expansion", "chromatic dragons", "metallic dragons", "gem dragons", "humanoids", "elven coronals", "aurinthor"]
    },

    "11500": {
      enabled: true,
      "year": "-3410",
      "blurb": "Cappadán the Lynx begins a guerilla campaign to liberate portions of the Oxkadi Valley long enough for them to build guerilla defenses and networks to last when the Chromatics come in.",
      "tags": ["cappadán the lynx", "oxkadi valley", "liberation", "guerrilla defenses"]
    },

    "11525": {
      enabled: true,
      "year": "-3407",
      "blurb": "Cappadán the Lynx slays the Chromatic puppet govenor and claiming Hozjal from him. The people rise up and they are able to train and prepare for when the Chromatics return.",
      "tags": ["cappadán the lynx", "oxkadi valley", "liberation", "guerrilla defenses"]
    },

    "11550": {
      enabled: true,
      "year": "-3392",
      "blurb": "Cappadán the Lynx is killed by the Blue Dragon Kurush the Dynast as the Oxkadi Valley rebellion is finally put down. However, the local guerilla networks are well established and prepared to defy the Chromatic Tyrants for generations to come",
      "tags": ["cappadán the lynx", "oxkadi valley", "chromatic conquest"]
    },

    "11600": {
      enabled: true,
      "year": "-3380 - -3350",
      "blurb": "First Red-Blue War As Blue Dragons clash with Red over inland Volcanoes",
      "tags": ["first red-blue war", "blue dragons", "red dragons", "inland volcanoes"]
    },

    "11700": {
      enabled: true,
      "year": "-3300- -2000",
      "blurb": "Thousand years of Chromatics ruling, infighting, expanding, etc as dragons slowly begin to rule more and more. As the year -2000 approaches, the Chromatic Dragons seem weakened.",
      "tags": ["chromatic rule", "dragon infighting", "expansion", "weakening"]
    },

    "11800": {
      enabled: true,
      "year": "-2460",
      "blurb": "Giant holdouts in the mountains of the world clash with Dragons who start a skirmish game with them...unwilling to risk a full assault as the giant-dragon conflict burns on",
      "tags": ["giants", "dragons", "mountain skirmishes", "giant-dragon conflict"]
    },

    "11900": {
      enabled: true,
      "year": "-2489",
      "blurb": "Last remnants of the Kinap-halic are defeated and enslaved",
      "tags": ["kinap-halic", "defeat", "enslavement"]
    },

    "12000": {
      enabled: true,
      "year": "-2047",
      "blurb": "Deep Gnome slave of Ancient Deep Dragon Marungi....Belnora \"Dracodoom\" Skraddeth...had built weapons and artifacts for the Draconic overlord for a century before engineering her escape. The Gnomish resistance leader Piratha Norav led Skraddeth and a small group of female Gnomes and male Dwarves (Dragons divided racial genders) into the underdark where they lived like nomads for hundreds of years...setting up camp in remote caves where Skraddeth created fantastic magic items. Several of her creations remain to this day. The Bastion claims Norav as their founder.",
      "tags": ["deep gnome", "ancient deep dragon", "belnora skraddeth", "escape", "gnomish resistance", "underdark", "magic items", "the bastion"]
    },

    "12100": {
      enabled: true,
      "year": "-2002",
      "blurb": "With the help of an ancient Elven Druid named Aravalya (like the month)...in the underdark near Thalas the Progeny begin to form via selective marriages. Aravalya's knowledge of nature and genetics form their foundation.",
      "tags": ["elven druid", "aravalya", "underdark", "thalas", "progeny", "selective marriages", "genetics"]
    },

    "12200": {
      enabled: true,
      "year": "-1998",
      "blurb": "Ancient (though young ancient) Red Dragon Qaushemman the Paleflayer with force unites the Red Dragons and slaughters upstarts free humanoids on the Primavera sea coasts",
      "tags": ["red dragon", "qaushemman the paleflayer", "dragon unification", "primavera sea", "humanoid slaughter"]
    },

    "12300": {
      enabled: true,
      "year": "-1995",
      "blurb": "The Reds call together the Black and Green Pentarch to propose an alliance...slaughter those around the Primaveran sea once and for all. The Primaveran Sea is important for Tiamat so must be for them too",
      "tags": ["reds", "black and green pentarch", "alliance", "primaveran sea", "tiamat"]
    },
    "12400": {
      enabled: true,
      "year": "-1995 - -1610",
      "blurb": "The Pentarchy forms around the Primaveran Sea, focusing on one area under Qaushemman instead of being divided through the world. Less conquest and more consolidation...with Chromatic Dragons playing ruler, trade masters, etc. The world slowly becomes their more and more as others are helpless",
      "tags": ["pentarchy", "primaveran sea", "qaushemman", "chromatic dragons", "consolidation", "rulers"]
    },
    "12500": {
      enabled: true,
      "year": "-1867",
      "blurb": "An aged Skraddeth meets the Progeny and forged magic items for them",
      "tags": ["skraddeth", "progeny", "magic items"]
    },
    "12600": {
      enabled: true,
      "year": "-1602",
      "blurb": "Night Green Dragons use Lloth Drow slaves turned Psionic assassins to assassinate countless non-Chromatic leaders (Metallic and Gem Dragons too) across the lands in just one night.",
      "tags": ["green dragons", "lloth drow", "psionic assassins", "assassination", "metallic dragons", "gem dragons"]
    },
    "12700": {
      enabled: true,
      "year": "-1600",
      "blurb": "The Pentarchy lashes out...Greens launch a renewed assault on the remnant of the Verdant Forest Lunar Coronal",
      "tags": ["pentarchy", "green dragons", "assault", "verdant forest", "lunar coronal"]
    },
    "12800": {
      enabled: true,
      "year": "-1589",
      "blurb": "Wicked wizards capture Metallic Dragons in a sort of stasis-demiplane, bringing them deep into Qaushemman's lair",
      "tags": ["wizards", "metallic dragons", "stasis-demiplane", "qaushemman", "lair"]
    },
    "12900": {
      enabled: true,
      "year": "-1575",
      "blurb": "Green Dragons range far North to attack the Isle of Aetherese Schismatics...but something happens....the entire city/island vanishes beneath the waves....a mystery to Chromatic Arcanists and scholars for generations",
      "tags": ["green dragons", "aetherese schismatics", "disappearance", "mystery", "chromatic arcanists"]
    },
    "13000": {
      enabled: true,
      "year": "-1558",
      "blurb": "Final Defeat of Lunar Coronal (Verdant Forest)",
      "tags": ["lunar coronal", "verdant forest", "defeat"]
    },
    "13100": {
      enabled: true,
      "year": "-1558",
      "blurb": "New calendar year start.... Year of Exile for the defeated Lunar Coronal. Ends up getting used by later scholars for this year",
      "tags": ["new calendar", "year of exile", "lunar coronal", "scholars"]
    },
    "13200": {
      enabled: true,
      "year": "-1552",
      "blurb": "Exilarch's son dedicates entire elven life span to exploring the Planes to find more paths into his world for heroes",
      "tags": ["exilarch", "planes", "exploration", "elven lifespan", "heroes"]
    },
    "13300": {
      enabled: true,
      "year": "-1225",
      "blurb": "Fall of Dwarven city now called Barren Mine. All traces of original name were destroyed on orders of Raging Ancient White Dragon",
      "tags": ["dwarven city", "barren mine", "destruction", "white dragon"]
    },
    "13400": {
      enabled: true,
      "year": "-1140",
      "blurb": "Jungle Hobgob Empire finally defeated by Black Dragons, Gobloids now all enslaved",
      "tags": ["jungle hobgob empire", "black dragons", "defeat", "enslavement", "gobloids"]
    },
    "13500": {
      enabled: true,
      "year": "-1100",
      "blurb": "Chromatic Dragons now rule pretty entire Primaveran region and beyond...though are highly divided",
      "tags": ["chromatic dragons", "primaveran region", "rule", "division"]
    },
    "13600": {
      enabled: true,
      "year": "-1027",
      "blurb": "Deep in the Eastern Mountains, groups of Dwarves shelter remnants of free peoples...including the Silver Exilarch and his Elves, the Diverse Mixed Races, etc",
      "tags": ["eastern mountains", "dwarves", "silver exilarch", "elves", "mixed races"]
    },
    "13700": {
      enabled: true,
      "year": "-1020 (538 LE)",
      "blurb": "Whispervale rediscovered A couple are Lunar Exiles so they use the LE calendar to start",
      "tags": ["whispervale", "rediscovery", "lunar exiles", "le calendar"]
    },
    "13800": {
      enabled: true,
      "year": "-925",
      "blurb": "Qaushemman Paleflayer allies with Green Dragons to conquer and enslave modern Free Coast",
      "tags": ["qaushemman paleflayer", "green dragons", "conquest", "enslavement", "free coast"]
    },
    "13900": {
      enabled: true,
      "year": "-905",
      "blurb": "Hysarius finally captured as Deep Dragons enter from below. Chromatic armies pillage and sack for a week.",
      "tags": ["hysarius", "deep dragons", "capture", "chromatic armies", "pillage", "sack"]
    },
    "14000": {
      enabled: true,
      "year": "-892",
      "blurb": "Chromatic rule engulfs nearly the whole of the world",
      "tags": ["chromatic rule", "global domination"]
    },
    "14100": {
      enabled: true,
      "year": "-890",
      "blurb": "Exilarch's son had died without finding the planar passage...but his son finds it. Faerûn, Metallic Dragons, etc are recruited",
      "tags": ["exilarch", "planar passage", "faerûn", "metallic dragons", "recruitment"]
    },
    "14200": {
      enabled: true,
      "year": "-825",
      "blurb": "In the Dwarven mountains, the newcomers help take the fight to the White Dragons and their armies, securing a foothold",
      "tags": ["dwarven mountains", "newcomers", "white dragons", "armies", "foothold"]
    },
    "14300": {
      enabled: true,
      "year": "-785",
      "blurb": "The established Free Drow help draw Drow exiles from Lloth dominated lands",
      "tags": ["free drow", "drow exiles", "lloth", "domination"]
    },
    "14400": {
      enabled: true,
      "year": "-707 - -680",
      "blurb": "Loxtor the Sapphire Dragon helps lead a campaign in the underdark which wrecks the Llothites and Abolethes...carving a stronghold of freedom under the mountains",
      "tags": ["loxtor", "sapphire dragon", "campaign", "underdark", "llothites", "abolethes", "freedom", "mountains"]
    },
    "14500": {
      enabled: true,
      "year": "-612",
      "blurb": "The Progeny establishes an Underdark realm with Loxtor's help in exchange for slaying Abberations. 1st Emperor Elcoril declared",
      "tags": ["progeny", "underdark realm", "loxtor", "abberations", "emperor elcoril"]
    },
    "14600": {
      enabled: true,
      "year": "-509",
      "blurb": "Empress Marfira in collaboration with scholars announces the Marfiran Calendar. Based on Elven and Faerûn calendar traditions...it fits the slightly longer orbital of the First World (370 Days)",
      "tags": ["empress marfira", "marfiran calendar", "elven traditions", "faerûn", "first world"]
    },
    "14700": {
      enabled: true,
      "year": "-442",
      "blurb": "Battle of Bright Cavern => Kinap-halic Greosass brings honor to his ancient line by helping Loxtor defeat a Drow Aboleth alliance. He slays an Aboleth and countless Driders from getting to the unprotected children.",
      "tags": ["battle of bright cavern", "kinap-halic greosass", "loxtor", "drow aboleth alliance", "aboleth", "driders"]
    },
    "14800": {
      enabled: true,
      "year": "-442",
      "blurb": "Giants attempt to reform their old glories with a band of Storm Giants creating the Ordning but looser...letting Frost and Fire giants run a lot of the day to day affairs in order to gain strength. Other Storm Giants reject this as oppression they believe caused Anman to lose his love for them...but they are silences or ignored.",
      "tags": ["giants", "storm giants", "ordning", "frost giants", "fire giants", "anman"]
    },
    "14900": {
      enabled: true,
      "year": "-441",
      "blurb": "Cirlemthe, Gold Dragon, arrives on world and starts taking charge with her charismatic personality",
      "tags": ["cirlemthe", "gold dragon", "arrival", "leadership"]
    },
    "15000": {
      enabled: true,
      "year": "-439 - -421",
      "blurb": "War of Chromatic Succession",
      "tags": ["war of chromatic succession", "chromatic dragons"]
    },
    "15100": {
      enabled: true,
      "year": "-439",
      "blurb": "Death of Red Pentarch...Ancient Red Dragon Qaushemman Paleflayer of Old Age",
      "tags": ["red pentarch", "death", "qaushemman paleflayer", "ancient red dragon"]
    },
    "15200": {
      enabled: true,
      "year": "-439",
      "blurb": "From other worlds of the Material Plane...Ancient Red Dragon Sirmoth and Ancient Red Ashlaradon arrive and marshal their forces to claim the Red Pentarchy",
      "tags": ["material plane", "ancient red dragons", "sirmoth", "ashlaradon", "red pentarchy"]
    },

    "15300": {
      enabled: true,
        "year": "-438",
        "blurb": "Ancient Green Gondrakrox allies with Ashlaradon, Taghiatan the Absolute and the Dracocracy. While Red Dragon Sirmoth allies with Skakrukath and various Black Dragons.",
        "tags": ["ancient green gondrakrox", "ashlaradon", "taghiatan the absolute", "dracocracy", "red dragon sirmoth", "skakrukath", "black dragons", "alliances"]
    },

    "15400": {
      enabled: true,
        "year": "-433",
        "blurb": "Weakening Chromatic rules allows Dwarves/Gnomes of the Bastion to trend towards the surface. From there Harper agents start to distribute arms to slaves across the land bridge. Runaways were trained to fight...and allied Metallic/Gem dragons arrived in the Bastion too...preparing",
        "tags": ["chromatic rule", "dwarves", "gnomes", "bastion", "harper agents", "arms distribution", "slaves", "land bridge", "metallic dragons", "gem dragons"]
    },

    "15500": {
      enabled: true,
        "year": "-433",
        "blurb": "Fire Giants begin expanding West out of the mountains, leaving Hill Giants to farm.",
        "tags": ["fire giants", "expansion", "west", "mountains", "hill giants", "farming"]
    },

    "15600": {
      enabled: true,
        "year": "-432",
        "blurb": "Sapphire Dragon Loxor The Just Architect arrives and brings free underdark warriors",
        "tags": ["sapphire dragon", "loxor the just architect", "arrival", "underdark warriors", "freedom"]
    },

    "15700": {
      enabled: true,
        "year": "-427",
        "blurb": "Clan Kinap-halic leader returns to the slaves from the tunnels of the Bastion. Starts fermenting resistance",
        "tags": ["clan kinap-halic", "leader", "slaves", "bastion tunnels", "resistance"]
    },

    "15800": {
      enabled: true,
        "year": "-424",
        "blurb": "Cirlemthe and a band of heroic adventurers sneak into Qaushemman's old lair, and free the Metallic Dragons",
        "tags": ["cirlemthe", "adventurers", "qaushemman", "lair", "metallic dragons", "rescue"]
    },

    "15900": {
      enabled: true,
        "year": "-424",
        "blurb": "On their way out, Simroh comes and attacks...Kinap-halic Greosass shoots two arrows into both eyes...slaying him. His Draconic gift is Dragon skin...but Cirlemthe also grants the draconic gift of fast Drakewardens into his bloodline.",
        "tags": ["simroh", "attack", "kinap-halic greosass", "draconic gift", "dragon skin", "cirlemthe", "drakewardens", "bloodline"]
    },

    "16000": {
      enabled: true,
        "year": "-422",
        "blurb": "The heroes start to establish the lands as their own.",
        "tags": ["heroes", "land establishment", "territory"]
    },

    "16100": {
      enabled: true,
        "year": "-421",
        "blurb": "Emperor Ianfelor slays the Ancient Green Gondrakrox in combat near modern day Chateaû Tyrgalos. This dominant Dragon of the Eastern forests are laid low as his realm is devastated. Gains Draconic gift and Draconic sorcery into bloodline",
        "tags": ["greosass", "ianfelor", "ancient green gondrakrox", "combat", "eastern forests", "dragon", "realm", "draconic gift", "draconic sorcery", "bloodline"]
    },

    "16200": {
      enabled: true,
        "year": "-419",
        "blurb": "Hobgoblins slaves rise up and slay masters on North of Primavera. Start gathering goblinoids from all around the world and fleeing there to form own state.",
        "tags": ["hobgoblins", "slaves", "uprising", "masters", "primavera", "goblinoids", "state formation"]
    },

    "16300": {
      enabled: true,
        "year": "-418",
        "blurb": "Emperor Ianfelor takes the progeny and rallies the slaves of the Eastern Primaveran Sea to revolt...the beginnings of the Empire of Blood on the surface",
        "tags": ["ianfelor", "progeny", "slaves", "eastern primaveran sea", "revolt", "empire of blood"]
    },

    "16400": {
      enabled: true,
        "year": "-417",
        "blurb": "Greosass reclaims the Kinaphalic Kingdom of old, centered square in the middle. His realm expands into the modern day Krateo and Timo lands.",
        "tags": ["greosass", "kinaphalic kingdom", "reclamation", "expansion", "krateo", "timo"]
    },

    "16500": {
      enabled: true,
        "year": "-414",
        "blurb": "Freed from their Dragon overlords...a massive Orc horde forms in the wilds to the North East under Tzakrakh.",
        "tags": ["orc horde", "freedom", "dragon overlords", "wilds", "north east", "tzakrakh"]
    },

    "16600": {
      enabled: true,
        "year": "-412",
        "blurb": "Future Free Coast overthrow Black Draconian overlords.",
        "tags": ["free coast", "overthrow", "black draconian overlords"]
    },

    "16700": {
      enabled: true,
        "year": "-411 (1147 LE)",
        "blurb": "Whispervale, a secret druid lair, established into a school under the guidance of the Gold Dragon...still secret",
        "tags": ["whispervale", "druid lair", "school", "gold dragon", "secret"]
    },

    "16800": {
      enabled: true,
        "year": "-410",
        "blurb": "With Various Chromatic Dragons dead their lands ruined...Ashalradon claims victory and declares fighting finished. The Pentarchy must deal with these new threats",
        "tags": ["chromatic dragons", "ashalradon", "victory", "pentarchy", "threats"]
    },

    "16900": {
      enabled: true,
        "year": "-409",
        "blurb": "Free Coast Cities...relatively distant from wars...begin producing weapons for those on the front lines",
        "tags": ["free coast cities", "distance from war", "weapons production", "front lines"]
    },

    "17000": {
      enabled: true,
        "year": "-406 - -401",
        "blurb": "1st Giant War => Empire drives giants who expanded down the hills as the Chromatics weakened...back towards the mountains. Their decades of expansion, wiped in a couple years.",
        "tags": ["1st giant war", "empire", "giants", "expansion", "chromatics", "mountains"]
    },

    "17100": {
      enabled: true,
        "year": "-402- -400",
        "blurb": "Verdant Invasion => Verdant Dracocracy launches massive attack on the Kinap-Halics....but they survive",
        "tags": ["verdant invasion", "verdant dracocracy", "attack", "kinap-halics", "survival"]
    },

    "17200": {
      enabled: true,
        "year": "-398",
        "blurb": "Platinum Allowance. The Leviathan Alliance grant the Metallic Dragons rule over the vast inland forests. Less Humanoids live there, but many Chromatic Dragons and allies hide out. Dwarves welcome this, granting allies in Silver and Gold Dragons to fight the returned Giants.",
        "tags": ["platinum allowance", "leviathan alliance", "metallic dragons", "rule", "inland forests", "chromatic dragons", "dwarves", "silver dragons", "gold dragons", "giants"]
    },

    "17300": {
      enabled: true,
        "year": "-395 - -350",
        "blurb": "Relative peace as everyone works to establish themselves and recover",
        "tags": ["peace", "establishment", "recovery"]
    },

    "17400": {
      enabled: true,
        "year": "-315",
        "blurb": "Free Coast, long distant from wars, form trading companies as the peace of the world allows for the increased purchase of weapons to prepare for war. Fox Fire Fletchers form as a small company in the city of Viathon",
        "tags": ["free coast", "trading companies", "peace", "weapons", "war preparation", "fox fire fletchers", "viathon"]
    },

    "17500": {
      enabled: true,
        "year": "-275",
        "blurb": "Giant withdrawl...as Giant political ventures become increasingly frantic and desperate for past glories, many Storm and Cloud giants retreat from giant realms. The Ordning is not broken however, with radical and greedy Storm and Cloud giants remaining in control over giant affairs in the Primaveran Mountains. They continue to fight the Dwarves, Metallic Dragons, and the Empire. ",
        "tags": ["giant withdrawal", "political ventures", "storm giants", "cloud giants", "ordning", "primaveran mountains", "dwarves", "metallic dragons", "empire"]
    },

    "17600": {
      enabled: true,
        "year": "-215 - -190",
        "blurb": "King Kinaphalic Gethiacnir leads an invasion of Velmard Kingdom, a puppet state of Chromatics. The area is subjugated, but the Kinaphalic realm is stretched thin.",
        "tags": ["king kinaphalic gethiacnir", "invasion", "velmard kingdom", "chromatics", "subjugation", "realm"]
    },

    "17700": {
      enabled: true,
        "year": "-212",
        "blurb": "The Kinaphlic war requires more and more arrows to help fight at a distance...boosting Fox Fire Fletcher profits as the Leviathan Alliance increase arrow purchases to keep their people alive",
        "tags": ["kinaphlic war", "arrows", "fox fire fletchers", "profits", "leviathan alliance", "purchases"]
    },

    "17800": {
      enabled: true,
        "year": "-205",
        "blurb": "Fox Fire Fletchers buy out remaining companies in Viathon as well as nearly all the property. Viathon is renamed Vixen City.",
        "tags": ["fox fire fletchers", "buyout", "viathon", "property", "vixen city"]
    },

    "17900": {
      enabled: true,
        "year": "-160 - -142",
        "blurb": "War of Traitors => A failed coup attempt that was supported by the Red Dragons leads to a long scale internal search for traitors in the Empire, along with a raging Naval and Aerial invasion of the Timocracy. Eventually The Black and Green Dragons join as well...pressuring the Kingdom and the Empire.",
        "tags": ["war of traitors", "coup attempt", "red dragons", "traitors", "empire", "naval invasion", "aerial invasion", "timocracy", "black dragons", "green dragons"]
    },

    "18000": {
      enabled: true,
        "year": "-141",
        "blurb": "The Empress and King meet to declare victory and implore all to rebuild quick",
        "tags": ["empress", "king", "victory declaration", "rebuilding"]
    },

    "18100": {
      enabled: true,
        "year": "-138 - - 123",
        "blurb": "2nd Orc - Hobgoblin War",
        "tags": ["2nd orc - hobgoblin war", "orcs", "hobgoblins", "war"]
    },

    "18200": {
      enabled: true,
        "year": "-135 - -124",
        "blurb": "Countless skirmishes by Dragon forces and their backed Orcs as the Orc - Goblin war Spreads",
        "tags": ["dragon forces", "orcs", "skirmishes", "war spread"]
    },

    "18300": {
      enabled: true,
        "year": "-109 - -104",
        "blurb": "2nd Giant War. ",
        "tags": ["2nd giant war", "giants", "war"]
    },

    "18400": {
      enabled: true,
        "year": "-108",
        "blurb": "Southern Frost Giant armies lead foray into Imperial Territory towards Ilyana",
        "tags": ["southern frost giants", "foray", "imperial territory", "ilyana"]
    },

    "18500": {
      enabled: true,
        "year": "-107",
        "blurb": "14th Legion with Future Emperor Norsalor II defeat the Giant army in route",
        "tags": ["14th legion", "norsalor ii", "giant army", "defeat"]
    },

    "18600": {
      enabled: true,
        "year": "-106",
        "blurb": "14th legion Lift the Giant siege of Harkuhm, earning Norsalor eternal thanks of the Dwarves",
        "tags": ["14th legion", "giant siege", "harkuhm", "norsalor ii", "dwarves", "thanks"]
    },

    "18700": {
      enabled: true,
        "year": "-105 - - 104",
        "blurb": "14th Legion lead siege of Giant town of Woodholm...stopped at THE CHAOS",
        "tags": ["14th legion", "siege", "giant town", "woodholm", "the chaos"]
    },

    "18800": {
      enabled: true,
        "year": "-104",
        "blurb": "The Chaos. Spellplague of Faerûn. Kinap-halic heartland sees all the Dragonborn disappear (scatter to many worlds including Faerûn) In the Chaos the Verdant Dracocracy strikes and takes it.",
        "tags": ["the chaos", "spellplague", "faerûn", "kinap-halic heartland", "dragonborn", "verdant dracocracy"]
    },

    "18900": {
      enabled: true,
      "year": "-104 - -23",
      "blurb": "The Chaos of Endless Strife: Near permanent state of Chaos as the weakened empire struggles to hold on",
      "tags": ["endless strife", "empire", "chaos", "struggle"]
    },
    "19000": {
      enabled: true,
        "year": "-104",
        "blurb": "Truce called at start of The Chaos as Giants and Empire both struggle to respond.",
        "tags": ["truce", "the chaos", "giants", "empire"]
    },
    "19050": {
      enabled: true,
      "year": "-104",
      "blurb": "Imperial Crown Prince Ianfelor killed by Chromatic assassins capitalizing on The Chaos",
      "tags": ["battle", "olyaroa", "norsalor ii", "the chaos", "empire"]
    },
    "19051": {
      enabled: true,
      "year": "-104",
      "blurb": "Imperial Prince Orixian and Princess Ophinoa slain by demons rampaging in the Aetherese Heartland.",
      "tags": ["battle", "olyaroa", "norsalor ii", "the chaos", "empire"]
    },
    "19060": {
      enabled: true,
      "year": "-104",
      "blurb": "Battle of Thalas. Empress Olyaroa dies in a valiant defense against a Chromatic raid.",
      "tags": ["battle", "olyaroa", "norsalor ii", "the chaos", "empire"]
    },
    "19070": {
      enabled: true,
      "year": "-104",
      "blurb": "With the death of the Empress and his three siblings, Norsalor II is swiftly crowned Emperor as the only surviving member of the Imperial Family.",
      "tags": ["battle", "norsalor ii", "the chaos", "empire"]
    },
    "19100": {
      enabled: true,
      "year": "-103 - -100",
      "blurb": "The Siege of Cynlona, as the Empire rushes to close the gap left by the vanished heartland of the Kinaphalic Kingdom. The Empire rushes the now vacant town of Cynlona and starts to fortify it. The Pentarchal forces arrived and lay sieged, but the Imperial army, Dragonborn survivors, a Fair Isle force, and a single Copper Dragon held on against impossible odds for years. Finally an Imperial relief army arrived and Cynlona became a frontline city.",
      "tags": ["siege of cynlona", "kinap-halic", "empire", "copper dragon", "fair isle"]
    },
    "19200": {
      enabled: true,
      "year": "-96",
      "blurb": "Timocracy and Krateocracy formally tell the Empire to treat them as distinct entities with no plan for reconciliation.",
      "tags": ["timocracy", "krateocracy", "empire", "political division"]
    },
    "19300": {
      enabled: true,
      "year": "-52",
      "blurb": "Explosion of Mt. Dynas as the interior Grimstone is Plane Shifted into it",
      "tags": ["mt. dynas", "explosion", "grimstone", "plane shift"]
    },
    "19400": {
      enabled: true,
      "year": "-44",
      "blurb": "Giant split as Mastamus, a radical Storm Giant in the south schemes to conquer the dwarf confederacy...while other Storm Giants, even the radical ones, argue for peace. Mastamus uses the Ordning to command lesser giants while ignoring other Storm Giants.",
      "tags": ["giants", "mastamus", "storm giant", "conflict", "dwarf confederacy", "ordning"]
    },
    "19500": {
      enabled: true,
      "year": "-42 - -36",
      "blurb": "3rd Giant War. Coalition of the Empire, Dwarves, Gilded Forest, and the Platinum Republic help defeat Southern Giants. Giant homes are destroyed in the Central and Southern mountains, though some giants flee north or to the far south. Securing a large border of the Empire for the first time. Woodholm taken by the Empire.",
      "tags": ["3rd giant war", "empire", "dwarves", "gilded forest", "platinum republic", "southern giants", "woodholm"]
    },
    "19600": {
      enabled: true,
      "year": "-40 - -38",
      "blurb": "3rd Orc - Hobgoblin War",
      "tags": ["3rd orc - hobgoblin war", "orc", "hobgoblin"]
    },
    "19700": {
      enabled: true,
      "year": "-38",
      "blurb": "The City of Lekospe destroyed in Demonic Incursion. Broken Orders charge and start holding it off as Pelor's paladins arrive. Religious militia groups grow in prestige as a result.",
      "tags": ["lekospe", "demonic incursion", "broken orders", "pelor", "paladins", "platinum phalanx", "bahamut"]
    },
    "19800": {
      enabled: true,
      "year": "-35",
      "blurb": "Southern Giant survivors arrive in Verano in the south after a long journey...they help invigorate smaller giants there to begin territorial expansion.",
      "tags": ["southern giants", "verano", "territorial expansion"]
    },
    "19900": {
      enabled: true,
      "year": "-34",
      "blurb": "1st Battle of Thon Daral. Adult Red Dragon Xerkzash slain by Ignatius Ragnarok and Kherson when attempting to setup a lair inside. Young Amethyst Dragon Thaler captured, Gem Dragon cult going for reviving Tiamat to save Saridor. Amali carves REDLAKE into the mountain, granting the Dwarven name Thon Daral...REDLAKE",
      "tags": ["1st battle of thon daral", "red dragon", "xerkzash", "ignatius ragnarok", "kherson", "amethyst dragon", "thaler", "gem dragon cult", "tiamat", "saridor", "redlake"]
    },
    "20000": {
      enabled: true,
      "year": "-34",
      "blurb": "2nd Battle of Thon Daral. Ancient Dragons Amethyst- Kythdar and Deep- Wyrstith move into the city as Loxor fights their minions...the Slayers return to fight them.",
      "tags": ["2nd battle of thon daral", "ancient dragons", "amethyst", "kythdar", "deep", "wyrstith", "loxor", "slayers"]
    },
    "20100": {
      enabled: true,
      "year": "-23",
      "blurb": "Korkds Orc Horde Converts the faith of Kord",
      "tags": ["korkds", "orc horde", "faith", "kord"]
    },
    "20200": {
      enabled: true,
      "year": "-23",
      "blurb": "Declaration of Victory over Chaos. Emperor Norsalor II declares the Strife ended and a new status quo enforced. Plenty of tensions remain, but the Empire is the most secure it has been. Norsalor declares the new calendar, Norsalorian-Marfiran Calendar, based off the old Marfiran Calendar but changing year 1 AND changing a few months around and adding intercalay instead of the last month being odd. The last month was also named after an ancient hero who a later traitor was named after. Henceforth 1 P (Peace) and 1 BP (Before Peace)",
      "tags": ["victory", "chaos", "norsalor ii", "strife", "empire", "norsalorian-marfiran calendar"]
    },
    "20300": {
      enabled: true,
      "year": "-15 - -18",
      "blurb": "The Southern War. The Viper Kingdom refused to join the Lord's Alliance, Imperials also claim an attempted poisoning. Meanwhile the pirate haven of Ovgoth The Empire launched an invasion on both shores of the Inland sea, supported by the Platinum Republic and others. A clear declaration that stand with or against the Free Alliance. No half means.",
      "tags": ["viper kingdom war", "viper kingdom", "lord's alliance", "poisoning", "empire", "inland sea", "platinum republic", "free alliance"]
    },
    "20400": {
      enabled: true,
      "year": "-9 - -8",
      blurb: "The pirate haven of Ovgoth allow attacks on Fair Isle vessels, brining the wrath of the Leviathan Alliance. General Sodret Caldera of the Empire commanded the assault which mainly comprised of Imperials and Fair Islanders under the Command of Morthwyl Munin. The forces moved methodically and the Empire added territory down to Ovgroth. The worship of Varthos was banned and the royal family fled. Various Varthos worshipping clans became mercenaries for hire, while the royal family's only surviving heir tried to rebrand as a servant of Kord",
      "tags": ["viper kingdom war", "viper kingdom", "lord's alliance", "poisoning", "empire", "inland sea", "platinum republic", "free alliance"]
    },
    "20500": {
      enabled: true,
      "year": "-5",
      "blurb": "Young people are less familiar with the panic of the past and get softer as their elders remember well the chaos and fear of the past years",
      "tags": ["young people", "past panic", "chaos", "fear"]
    },
    "20600": {
      enabled: true,
      "year": "-1 (22)",
      "blurb": "Tree Guild is Formed",
      "tags": ["tree guild", "formation"]
    },
    "20700": {
      enabled: true,
      "year": "-1 (22)",
      "blurb": "Broken Circle Conflict",
      "tags": ["tree guild", "broken circle"]
    },
    "21700": {
      enabled: true,
      "year": "-0",
      "blurb": "Portal Opens to Faerûn on Toril",
      "tags": ["portal", "faerûn", "toril"]
    },
    "21800": {
      enabled: true,
      "year": "-0",
      "blurb": "Capture of the last heir to the royal line from Ovgroth by the Phandalin Five",
      "tags": ["portal", "faerûn", "toril", "phandalin five"]
    },
    "21900": {
      enabled: true,
      "year": "-0",
      "blurb": "Imperial Trade Agreement with Phandalin",
      "tags": ["portal", "faerûn", "toril", "phandalin five"]
    }
  }
}

export default eventData
