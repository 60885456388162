import React                 from "react";

class DivineRow extends React.Component {
  aligned(input) {
    input = input.alignment;
    input = input.replace("E", "<span class='evil'>E</span>");
    input = input.replace("G", "<span class='good'>G</span>");
    input = input.replace("L", "<span class='lawful'>L</span>");
    input = input.replace("C", "<span class='chaotic'>C</span>");

    return (
      <span dangerouslySetInnerHTML={{ __html: input }} />
    );
  }

  render() {
    const data = this.props.divinity;

    return (
      <tr
        className="divine-row"
      >
          {/*<img
            src={data[divinityKey].src || "https://www.pngfind.com/pngs/m/5-53216_male-avatar-silhouette-man-hd-png-download.png"}
            alt="character-thumb"
          />*/}

        <td>
          <a href={`/characters/${this.props.divinityKey}`}>
            {data.name}
          </a>
        </td>

        <td>
          {this.aligned(data)}
        </td>

        <td>
          {data.divineTagLine}
        </td>

        <td>
          {data.divinePantheon}
        </td>

        {
          this.props.showRank &&
          <td>
            {data.divineRank}
          </td>
        }

        <td>
          {data.divineTitle}
        </td>

        <td>
          {data.plane}
        </td>

        {
          this.props.showDomain &&
          <td>
            {data.divineDomain}
          </td>
        }

        {
          this.props.showPatron &&
          <td>
            {data.patron}
          </td>
        }
      </tr>
    )
  }
}

export default DivineRow;
