const //imperialPurple = "#4B0082",
      // kinaphalicPurple = "#9966cc",
      // krateopolisRed = "#EB0033",
      gildedForest = "#FFD700",
      // basicSilver = "#C0C0C0",
      platinum = "#E5E4E2",
      // horseBarbs = "#00C0FF",
      ironLegion = "#535454",
      // verdantDracGreen = "#0F6400",
      ashCinderPink = "#FFAAAA",
      tyrannyBlue = "#AAAAFF"
      // powerfulDull = "#AAAADD"
      // timocPeach = "#FFE5B4",
      // ciphPurpRed = "#800042",
      // burnOrange = "#CC5500",
      // entropyDark = "#333333"

      // twinsPurple = "#8B008B",


const mapData = {
  "Toril": {
    "faerun": {
      enabled: true,
      src: "https://www.aidedd.org/atlas/images/RO-6420-4061.jpg",
      translation: {x: -2100, y: -550},
      points: [
        {
          label: "Wave Echo Cave",
          top: 943,
          left: 2774,
          enabled: true,
          color: "#D00"
        },
        {
          label: "Phandalin",
          top: 956,
          left: 2706,
          enabled: true,
          color: "#222",
          border: "#0F0"
        },
        {
          label: "Cragmaw Hideout",
          top: 881,
          left: 2672,
          enabled: true,
          color: "#D00"
        },

        {
          label: "Cragmaw Castle",
          top: 826,
          left: 2689,
          enabled: true,
          color: "#D00"
        },
        {
          label: "Thundertree",
          top: 750,
          left: 2614,
          enabled: true,
          color: "#D00"
        },
        {
          label: "Leilon",
          top: 1021,
          left: 2634,
          enabled: true,
          color: "#222",
          border: "#0F0"
        },
        {
          label: "Port Llast",
          top: 668,
          left: 2478,
          enabled: true,
          color: "#222",
          border: "#0F0"
        },
        {
          label: "Conyberry Ruins",
          top: 813,
          left: 2636,
          enabled: true,
          color: "#D00"
        },
        {
          label: "Cracked Crystal Ruins",
          top: 841,
          left: 2647,
          enabled: true,
          color: "#D00"
        },
        {
          label: "Ångel Åcres",
          top: 863,
          left: 2632,
          enabled: true,
          color: "#222",
          border: "#0F0"
        },
        {
          label: "Goblin Ambush",
          top: 903,
          left: 2674,
          enabled: true,
          color: "#F0F"
        },

        // {
        //   label: "Highcliff",
        //   top: 928,
        //   left: 2570,
        //   enabled: true,
        //   color: "#000",
        //   border: "#0F0"
        // },
        // {
        //   label: "Seaheart",
        //   top: 880,
        //   left: 2561,
        //   enabled: true,
        //   color: "#000",
        //   border: "#0F0"
        // }
      ]
    },

    "phandalin": {
      enabled: true,
      src: "https://5e.tools/img/adventure/LMoP/Phandalin.webp",
      translation: {x: -80, y: -50}
    },

    "phandalin zoom": {
      enabled: false,
      dmOnly: true,
      driveId: "1yQfQ72VbCcTATGhETnQY9TU9_-LiyN7T",
    },


    // "Faerün": {
    //   enabled: true,
    //   // translation: {x: -2100, y: -550},
    //   src: "https://i.imgur.com/vVpHSuD.jpeg",
    //   points: [
    //     {
    //       label: "Phandalin",
    //       top: 258,
    //       left: 90,
    //       enabled: true,
    //       color: "#222",
    //       border: "#0F0"
    //     },
    //     {
    //       label: "Cragmaw Hideout",
    //       top: 204,
    //       left: 39,
    //       enabled: false,
    //       color: "#D00"
    //     },
    //     {
    //       label: "Cragmaw Castle",
    //       top: 133,
    //       left: 89,
    //       enabled: false,
    //       color: "#D00"
    //     },
    //     {
    //       label: "Thundertree",
    //       top: 30,
    //       left: 12,
    //       enabled: true,
    //       color: "#D00"
    //     },
    //     {
    //       label: "Leilon",
    //       top: 421,
    //       left: 34,
    //       enabled: true,
    //       color: "#222",
    //       border: "#0F0"
    //     },
    //     {
    //       label: "Conyberry",p
    //       top: 780,
    //       left: 230,
    //       enabled: true,
    //       color: "#D00"
    //     },
    //     {
    //       // label: "Goblin Ambush",
    //       label: "You Are Here",
    //       top: 224,
    //       left: 34,
    //       enabled: true,
    //       color: "#F0F"
    //     }
    //   ]
    // },

    "World": {
      enabled: true,
      // translation: {x: -2100, y: -550},
      src: "https://i.imgur.com/Xhytbxm.jpeg",
      translation: {x: -2480, y: -100}
    },

    "cragmaw-hideout": {
      src: "https://i.pinimg.com/originals/77/4b/58/774b58baab95069bff02cb684cf88bca.jpg"
    },

    "Tresendar Manor Crypts": {
      dmOnly: true,
      enabled: true,
      // translation: {x: -2730, y: -1800},
      src: "https://5e.tools/img/adventure/LMoP/Redbrand%20Hideout%20(Player).webp",
      fog: false,
      // fogX: 28,
      // fogY: 18,
      fogX: 1,
      fogY: 1,
      fogIndentX: 152,
      fogIndentY: 300,
      fogDiff: 150
    },

    "Bandit Camp Day": {
      dmOnly: true,
      enabled: true,
      driveId: "1vH2ZFz6SuGNbXFU0_Hqmi_jj4qJMUrzC",
      fog: false
    },

    "Bandit Camp Night": {
      dmOnly: true,
      enabled: true,
      driveId: "1Akv68rIBhHNEWt6-6B4oBaiZPF5T67Pn",
      fog: false
    },

    "Cragmaw Castle": {
      dmOnly: true,
      enabled: false,
      src: "https://5etools-mirror-1.github.io/img/adventure/LMoP/Cragmaw%20Castle%20(Player).jpg",
      fog: true,
      startNoFog: true,
      fogClass: "cragmaw",
      measureMod: "cragmaw",
      fogDiff: 144,
      fogIndentX: 243,
      fogIndentY: 206,
      fogX: 25,
      fogY: 19,
    },

    "Cragmaw Castle Expanded": {
      enabled: true,
      src: "https://preview.redd.it/5iot7hvzo0s61.jpg?width=960&crop=smart&auto=webp&s=a6a378349f39935887a457adacbdd99837e14416"
    },

    "Skyreach Castle": {
      enabled: false,
      src: "https://5etools-mirror-1.github.io/img/adventure/HotDQ/050-map-8-2-skyreach-player.jpg"
    },

    "Open Plains": {
      driveId: "1dCjQTeUicSolPkZHBaUBDIx_E40mazY3",
      dmOnly: true,
      fog: true,
      fogClass: "neutralparty",
      measureMod: "neutralparty",
      fogX: 1,
      fogY: 1,
      fogIndentX: 4,
      fogIndentY: 4,
      fogDiff: 111
    },

    "Stonehill Inn 1st Floor": {
      enabled: true,
      src: "https://i.imgur.com/NlmtjHz.jpeg"
    },
    "Stonehill Inn 2nd Floor": {
      enabled: true,
      src: "https://i.imgur.com/gpQXs1Y.jpeg"
    },

    "Thundertree Old Map": {
      enabled: true,
      src: "https://5etools-mirror-1.github.io/img/adventure/LMoP/Ruins%20of%20Thundertree%20(Player).jpg",
    },

    "Thundertree": {
      enabled: true,
      // translation: {x: -2730, y: -1800},
      // fog: true,
      fogClass: "thunda",
      fogDiff: 200,
      fogIndentX: 5,
      fogIndentY: 5,
      fogX: 72,
      measureMod: "thunda",
      fogY: 41,
      driveId: "1abDPnAjivBvzq4Rh86ukLH5CNbzWbZ-X",
    },

    "Thundertree A": {
      enabled: true,
      fog: false,
      fogClass: "thunda",
      fogDiff: 200,
      fogIndentX: 92,
      fogIndentY: 92,
      fogX: 29,
      measureMod: "thunda",
      fogY: 40,
      driveId: "11R8nLncJ340y54klrca3cVZBx_xgyobg",
    },

    "Thundertree B": {
      enabled: true,
      fog: false,
      fogClass: "thunda",
      fogDiff: 200,
      fogIndentX: -55,
      fogIndentY: -35,
      fogX: 30,
      measureMod: "thunda",
      fogY: 41,
      driveId: "1qfUSaRhZdasUWvvn0zEroATh4kjv7wCk",
    },

    "Thundertree C": {
      enabled: true,
      fog: false,
      fogClass: "thunda",
      fogDiff: 200,
      fogIndentX: -105,
      fogIndentY: -107,
      fogX: 30,
      measureMod: "thunda",
      fogY: 41,
      driveId: "1_MUPJsNZYW2v4ruClLO_CmcD3Zv4OIj5",
    },

    "Duel Plains": {
      driveId: "1KUurYRnxAm6sd8uYnfHqCJ2zflH7ClZM",
      enabled: true,
      fog: false,
      fogClass: "neutralparty",
      measureMod: "neutralparty",
    },

    "Townhall": {
      driveId: "1ACRn64cZxLtrdxG37YBINi9KFoeZx6FR",
      enabled: true,
      fog: false,
      fogClass: "neutralparty",
      measureMod: "neutralparty",
    },
    "Agatha's Town": {
      driveId: "1aLawppvgh10x1-0qxIh6gMpGIdYoLUho",
      enabled: true,
      fog: false,
      fogClass: "neutralparty",
      measureMod: "neutralparty",
    },
    "Agatha's Town Night": {
      driveId: "127Ti-P9wwOZfjsqnrC7kNnSKIB5-qnQy",
      dmOnly: true,
      fog: false,
      fogClass: "neutralparty",
      measureMod: "neutralparty",
    },
    "Poolside Camp": {
      driveId: "17vWoUyjHOis8mNoEOD5vxX335b2BRYGd",
      enabled: false,
      fog: false,
      fogClass: "neutralparty",
      measureMod: "neutralparty",
    },
    "Shady Brewery": {
      driveId: "1R0vY9Hcsazyz0M_clJWhD9Ex80mphVhd",
      enabled: true,
      fog: true,
      fogClass: "neutralparty",
      measureMod: "neutralparty",
      fogX: 24,
      fogY: 35,
      fogIndentX: 4,
      fogIndentY: 4,
      fogDiff: 111
    },
    "Forked Road": {
      driveId: "13N7Zl23QvXHgICibnGNbx6q-XwAA5LyB",
      dmOnly: true,
      fog: false,
      fogClass: "neutralparty",
      measureMod: "neutralparty",
    },
    "Angel Acre": {
      driveId: "1VAod2BXeHYJpM-vTJQeC92ktLf6o01Dd",
      enabled: true,
      fog: false,
      fogClass: "neutralparty",
      measureMod: "neutralparty",
    },
    "Crumbling Facility": {
      driveId: "1044D-LxSEhPJpR2mxs7i4Iix-uMUMew3",
      enabled: true,
      // fog: true,
      // startNoFog: true,
      // fogClass: "neutralparty",
      // measureMod: "neutralparty",
      // fogX: 34,
      // fogY: 31,
      // fogIndentX: 4,
      // fogIndentY: 4,
      // fogDiff: 111
    },
    "Forest Canyon": {
      driveId: "1rc9LmASarmTkFPyKFdPGPg_GaCVarYTK",
      dmOnly: true,
      fog: true,
      fogClass: "neutralparty",
      measureMod: "neutralparty",
    },
    "Wave Echo Cave": {
      driveId: "1Zs9bdxCAbz1Hzt_gB2ITGrNxN9tq4fcX",
      enabled: true,
      fog: true,
      fogClass: "neutralparty",
      measureMod: "neutralparty",
    },

    "Blue Ice Flame": {
      driveId: "/1U5O8w1ZOgakm1NqjeK9Fay7VX4j_w2nE",
      dmOnly: true,
      enabled: true
    },
    "Giant Forge": {
      driveId: "1owYJzpAbHO0FK_pI6nZSBIpzL7hxbSXy",
      dmOnly: true,
      enabled: true
    },
    "Cultist Meeting Rooms": {
      driveId: "1vYmACJuw_dignQ4aa7FLD7bbjqQSYMHL",
      dmOnly: true,
      enabled: true
    },
    "Wizard Tower Dungeon 2": {
      driveId: "1pVEEqoY4vsfvEPp7FBIp308NzRURrRlK",
      dmOnly: true,
      enabled: true
    },
    "Wizard Tower Dungeon": {
      driveId: "1NKRe2RkTj3QmLryHR0ceqRqsWPB6p4Yi",
      dmOnly: true,
      enabled: true
    },
    "25 Main Vault 2": {
      driveId: "14DHUXx04TQta9g3pv8ZCwN8nOBxueCzZ",
      dmOnly: true,
      enabled: true
    },
    "25 Main Vault": {
      driveId: "1dloMlEeogZFbjfFzRKJNcpLiL2u6JM5x",
      dmOnly: true,
      enabled: true
    },
    "24 Servant Baracks": {
      driveId: "1b3qnwW5T-x0mZzYlbHOWc9wATOtW-QVI",
      dmOnly: true,
      enabled: true
    },
    "23 Giant Guest Chambers": {
      driveId: "1b3qnwW5T-x0mZzYlbHOWc9wATOtW-QVI",
      dmOnly: true,
      enabled: true
    },
    "22 Cloud Giant Tower 2": {
      driveId: "1EkZV3oMwemds-ga_nrsoiJlZz3vNiSDD",
      dmOnly: true,
      enabled: true
    },
    "22 Cloud Giant Tower 1": {
      driveId: "19-sZeal_BNteq4H-Ov6ObgaKPq0ke00g",
      dmOnly: true,
      enabled: true
    },
    "21 Esclarotta's Tomb": {
      driveId: "1F_GxFjUYfjiu8VHaRTLTowT_qqhZ0tE0",
      dmOnly: true,
      enabled: true
    },
    "19 Steering Tower": {
      driveId: "1l-ADewF2TgaqMcCBCH_DJfgXJ4Ktt50T",
      dmOnly: true,
      enabled: true
    },
    "18 Crumbling Tower 2": {
      driveId: "1VdrHe5Q5xoTHrZ10XHE5XnXexzvhVjSi",
      dmOnly: true,
      enabled: true
    },
    "18 Crumbling Tower 1": {
      driveId: "1Y82F-WhKcQldGbxwb4c8kR6R-xDHlMbL",
      dmOnly: true,
      enabled: true
    },
    "12 Red Wizards' Room 5": {
      driveId: "1VayWcUHFoJOvLp0P-FXX8FsfBIFOeCYv",
      dmOnly: true,
      enabled: true
    },
    "12 Red Wizards' Room 4": {
      driveId: "1VvhF-6MDTL5PeRA--PE2r70-Re_CpKn9",
      dmOnly: true,
      enabled: true
    },
    "12 Red Wizards' Room 3": {
      driveId: "1gOixWjFgqNvGn_Nzx0M_yxeUnABLgW31",
      dmOnly: true,
      enabled: true
    },
    "12 Red Wizards' Room 2": {
      driveId: "1jvBqw_1ZdAU_5VncpludRjci6PcZhQJf",
      dmOnly: true,
      enabled: true
    },
    "12 Red Wizards' Room 1": {
      driveId: "1evLVEvT-uuK9W-PjlIbgN7cJzoZ6icgS",
      dmOnly: true,
      enabled: true
    },
    "11 Rezmir's Chambers": {
      driveId: "19ksk_wX8_0Kayyzw67mi95Zhkqq5U7sK",
      dmOnly: true,
      enabled: true
    },
    "10 Stone Giant Guest Chambers": {
      driveId: "1uBpZIr8oi1G5CBlm74YrV5PtAx5KjwXK",
      dmOnly: true,
      enabled: true
    },

  },

  "First World": {
    "Thon Daral": {
      driveId: "1a3EcofNU3wMgBvrp9nUu5iIzP0JTacwH",
      enabled: true,
      translation: {x: -1080, y: -100},
      // translation: {x: -80, y: -500},
      points: [

        {
          label: "Nature's Blessing/Curse",
          larger: false,
          top: 832,
          left: 1464,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "The Gold Sanctuary of Waukeen",
          larger: false,
          top: 764,
          left: 1434,
          enabled: true,
          opacity: "0.1",
          spanColor: gildedForest,
          color: `${gildedForest}22`,
          border: "#FFF6",
          textColor: "#000"
        },
        {
          label: `"A Magic Item Shop"`,
          // https://5emagic.shop/shop/a-magic-item-shop-b64a18e06
          larger: false,
          top: 720,
          left: 1435,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Wonders of Wizardry",
          // https://5emagic.shop/shop/wonders-of-wizardry-c14804431
          larger: false,
          top: 742,
          left: 1413,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Garagum's General Goods",
          larger: false,
          top: 732,
          left: 1360,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Eel Soup Stand",
          larger: false,
          top: 830,
          left: 1399,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Zicklefockle Luthier",
          larger: false,
          top: 855,
          left: 1412,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Shadow and Blade",
          larger: false,
          top: 860,
          left: 1380,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Grand Market",
          larger: false,
          top: 805,
          left: 1387,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}11`,
          border: "#FFF4",
          textColor: "#FFF"
        },
        {
          label: "Tusk Splitter Gulash Stand",
          larger: false,
          top: 835,
          left: 1362,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },

        {
          label: "Bansas Bacon Bananas Stand",
          larger: false,
          top: 845,
          left: 1342,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },


        {
          label: "Café Kolé",
          larger: false,
          top: 803,
          left: 1335,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Koala Tea",
          larger: false,
          top: 753,
          left: 1330,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Outdark Flathouse",
          larger: false,
          top: 823,
          left: 1330,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Ancestor's Forge",
          larger: false,
          top: 868,
          left: 1329,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Pan Opticon",
          larger: false,
          top: 863,
          left: 1309,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Garten Faire",
          larger: false,
          top: 878,
          left: 1302,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          description: "Large restaraunt making great use of the beer garten all around it. Serves food from the Dwarven Peaks that Earth might relate to as 'German'",
          textColor: "#FFF"
        },
        {
          label: "Manez's Mezze",
          larger: false,
          top: 908,
          left: 1312,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Emperor's Epicureans",
          larger: false,
          top: 928,
          left: 1301,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Riverbank Grape",
          larger: false,
          top: 883,
          left: 1260,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Grand Meadhall and Gymansium of Kord",
          larger: false,
          top: 1160,
          left: 1680,
          enabled: true,
          opacity: "0.1",
          spanColor: "#00F",
          color: `#00F2`,
          border: "#FFF6",
          textColor: `${gildedForest}`
        },
        {
          label: "Lady Bewitching's Beautifully Blissful Bright Big Bodacious Boutique of Brilliant Bizarre Blusters and Whorehouse, Inc.",
          larger: false,
          top: 1188,
          left: 1659,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Gnoodnuck's Sushi",
          larger: false,
          top: 600,
          left: 1469,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Ecstasy and Fire: Monk Supply, Dojo, Icecream, and 'Massage' Parlor",
          larger: false,
          top: 1378,
          left: 1832,
          enabled: true,
          opacity: "0.1",
          spanColor: ashCinderPink,
          color: `${ashCinderPink}22`,
          border: "#FFF6",
          textColor: "#000"
        },
        {
          label: "Parti's Pawn Shop",
          larger: false,
          top: 1333,
          left: 1809,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Grimstone Tavern",
          larger: true,
          top: 899,
          left: 1266,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Hall of Moradin",
          larger: false,
          top: 964,
          left: 1352,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "The Iron Chancel of Bane",
          larger: false,
          top: 995,
          left: 1403,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "The Map Room",
          larger: false,
          top: 988,
          left: 1266,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Platinum Shrine",
          larger: false,
          top: 988,
          left: 1216,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Faerûn Portal",
          larger: true,
          top: 1144,
          left: 2098,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Air Plane Portal",
          larger: true,
          top: 144,
          left: 2019,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Water World Portal",
          larger: true,
          top: 170,
          left: 1944,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Otoño Portal",
          larger: true,
          top: 200,
          left: 1913,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Thalas Portal",
          larger: true,
          top: 210,
          left: 1830,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Sweltering World Portal",
          larger: true,
          top: 44,
          left: 1379,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Light of Life Chantry of Pelor",
          larger: false,
          top: 779,
          left: 1254,
          enabled: true,
          opacity: "0.1",
          spanColor: gildedForest,
          color: `${gildedForest}22`,
          border: "#FFF6",
          textColor: "#000"
        },
        {
          label: "Abode of Milritha's Peace",
          larger: false,
          top: 818,
          left: 1254,
          enabled: true,
          opacity: "0.1",
          spanColor: platinum,
          color: `${platinum}22`,
          border: "#FFF6",
          textColor: "#000"
        },
        {
          label: "Leaves of Grace Tea Shop",
          larger: false,
          top: 800,
          left: 1274,
          enabled: true,
          opacity: "0.1",
          spanColor: ironLegion,
          color: `${ironLegion}22`,
          border: "#FFF6",
          textColor: "#FFF"
        },
        {
          label: "Temple of Erathis the Wise",
          larger: false,
          top: 852,
          left: 1215,
          enabled: true,
          opacity: "0.1",
          spanColor: tyrannyBlue,
          color: `${tyrannyBlue}22`,
          border: "#FFF6",
          textColor: "#000"
        },
      ]
    },


    "The Lost": {
      driveId: "1dhFTq-E-UKoN67mwhGNef_I7ztrGGVyF",
      dmOnly: true,
      fog: true,
      fogClass: "neutralparty",
      measureMod: "neutralparty",
    },
    "Vixen City": {
      driveId: "1lbzSjEQOS6CfyQ74h-C0dHMDURLpq3y7",
      enabled: true,
      overlays: [
        {
          title: "Labels",
          enabled: true,
          driveId: "1oZdcFoYrnlvjhH0Bo7A-yHDshAFaXnu8"
        },
      ]
    },
    "Primavera": {
      driveId: "1MpKes32FfAQkuH_UbjjvjBOzwzZHIsvT",
      enabled: true,
      fog: false,
      fogClass: "neutralparty",
      measureMod: "neutralparty",
      translation: {x: -2280, y: -900},
      // translation: {x: -0, y: -1600},
      overlays: [
        {
          title: "Show All Borders",
          enabled: true,
          driveId: "1VX5302AYcaPv52IUtD8mVCWM9QARztWd"
        },
        {
          title: "The Empire",
          enabled: false,
          driveId: "1UrgV8ySytnDegREKGwDZMGwgRFEH__E5"
        },
        {
          title: "The Gilded Forest",
          enabled: false,
          driveId: "11gMK3TdVlnlB4IbSg5104eQb_xDbiG3i"
        },
        {
          title: "The Fair Isle",
          enabled: false,
          driveId: "1IgQktOmZJ7LDnZ7mxjdEf-r3DPcdC33X"
        },
        {
          title: "Viper Kingdom",
          enabled: false,
          driveId: "1osGP8strRvWH-mOlK0ZLODiSJ_RrklRP"
        },
        {
          title: "Two Banners Horde", //horse dukes
          enabled: false,
          driveId: "1AeP86hrwOkv1SD2BTGdiwkcxJ7AXKAEx"
        },
        {
          title: "Urzoth Orcs", //Korks
          enabled: false,
          driveId: "1ecTm5WqlwsuwZ5cIZnHCEyhJ6L91FU-4"
        },
        {
          title: "Oltgath Barbarian Kingdom", //Totem
          enabled: false,
          driveId: "1bTX1Qazo9FqbegFWAQYSoJM6MEFF3GCv"
        },
        {
          title: "Buramog Orc Horde", //west
          enabled: false,
          driveId: "1FEz4nTc9re3_BH6RiZYhu4Al4a9YncYf"
        },
        {
          title: "Prikdarok Orc Horde", //Middle
          enabled: false,
          driveId: "1hJ64YIWwSDST8bKxGKxXpsSTqSjoYkbo"
        },
        {
          title: "Aşağılık Orc Horde", //East
          enabled: false,
          driveId: "196RdJW9G6AYn0vKWqIMBjuJCeeAXDO83"
        },
        {
          title: "Iron Host",
          enabled: false,
          driveId: "1pBH3jykvEitHJ_e0KOKPJ0rbdIXHF5gi"
        },
        {
          title: "Timocracy",
          enabled: false,
          driveId: "1ieW6L4llmdjtdqJC5mQU2HkRVsDjZKuH"
        },
        {
          title: "Krateocracy",
          enabled: false,
          driveId: "1nQhWfLuApnKuPA6q_nlwI6HcewPjkIZv"
        },
        {
          title: "Bastion",
          enabled: false,
          driveId: "1A6XLsfu9WoIhXR0Sv2cNgbPcaBP3CmL8"
        },
        {
          title: "Giants",
          enabled: false,
          driveId: "1xZSj5LYgsmT1aGSZcBiRzdk_1BovsJKZ"
        },
        {
          title: "Platinum Republic",
          enabled: false,
          driveId: "1JErx4bCRhAoj1HMJOxWzInViW_Gl_WDF"
        },
        {
          title: "Confederation of the Peaks",
          enabled: false,
          driveId: "12LJoNaFBiKrT-1-SXNo3vV7BhfOFaBsV"
        },
        {
          title: "Entropocracy",
          enabled: false,
          driveId: "1y_UUDoBy86pa_7_1LewdZiUguD3pRQJ7"
        },
        {
          title: "Verdant Dracocracy",
          enabled: false,
          driveId: "1o8NgUBk_sU2PZFk6V2Ru3mVoLSDBl8sl"
        },
        {
          title: "Powerful Alliance",
          enabled: false,
          driveId: "1yW1qUGvr8Il-PcdD0Gu7zZyfO0vq4eN9"
        },
        {
          title: "Tyranny of Skakrukath",
          enabled: false,
          driveId: "122HLkmxfHX4chx8hzRBaLqlq_7hYJ5zU"
        },
        {
          title: "Dominance of the Twins",
          enabled: false,
          driveId: "1f6n-kr1LCac1NX-P2mJtaWdjct1BW4Hb"
        },
        {
          title: "Dominance of Cipherdo",
          enabled: false,
          driveId: "1qH7upjrdS4D5jaRrCEraPpFwXebq8e0l"
        },
        {
          title: "Dominance of Drinmerh",
          enabled: false,
          driveId: "1p8-DiBlO1u-_mXoVgWtq0H0L4SoUMFgO"
        },
        {
          title: "Dominance of Ash and Cinder",
          enabled: false,
          driveId: "14O_1_PpxqP7YPkc4MsT76cNTrFzyYfqI"
        },
        {
          title: "The Pentarchy",
          enabled: false,
          driveId: "1tFyiokM-Ii9CBqg_zF_LgSKGshgc65GL"
        },


      ],
      pointsLoad: "First World",
      points: [
        // {
        // driveId: "1kPxzdNJthtohMMasxlqMU5MD_Q1SgxC7",
        //    https://drive.usercontent.google.com/download?authuser=0&id=uc?id=1kPxzdNJthtohMMasxlqMU5MD_Q1SgxC7",
        // driveId: "1kPxzdNJthtohMMasxlqMU5MD_Q1SgxC7""
        //     top: 3120,
        //     left: 3437,
        //     enabled: true,
        //     opacity: "0.1",
        //     spanColor: imperialPurple,
        //     color: `${imperialPurple}22`,
        //     blurb: `Imperial capital built into remains of the massive Aetherese Dry Docks for Flying Cities built thousands of years ago. Renamed after Thalas a legendary progeny before the first Emperor.`,
        //     border: "#FFFA",
        //     textColor: "#FFF"
        //   },
      //   // Imperial
      //   {
      //     label: "Thalas",
      //     larger: true,
      //     top: 1608,
      //     left: 3002,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     blurb: `Imperial capital built into remains of the massive Aetherese Dry Docks for Flying Cities built thousands of years ago. Renamed after Thalas a legendary progeny before the first Emperor.`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Woodholm",
      //     larger: true,
      //     top: 1425,
      //     left: 3452,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Esari",
      //     larger: true,
      //     top: 1035,
      //     left: 2424,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Thacedia",
      //     larger: true,
      //     top: 1150,
      //     left: 2475,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Pelothen",
      //     larger: true,
      //     top: 1169,
      //     left: 2386,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Paialos City",
      //     larger: true,
      //     top: 1037,
      //     left: 2207,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Athonia",
      //     larger: true,
      //     top: 1080 ,
      //     left: 2331,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Hysarius",
      //     larger: true,
      //     top: 1256,
      //     left: 2193,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Sippar",
      //     larger: true,
      //     top: 1454,
      //     left: 2363,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Enshull",
      //     larger: true,
      //     top: 1622,
      //     left: 2645,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     visited: false,
      //     tagLine: "Historic Port at Mouth of Imperial River",
      //     type: "Settlement",
      //     size: "City",
      //     region: "Shull Gulf, Central Empire",
      //     nation: "The Empire of Blood",
      //     blurb: `Port city about mouth of the Imperial River. Thousands of years ago was the seat of a large confederacy that was early opponent of Aetherese. They controlled the Shull Gulf. Defeated by Aetherese, they proved to be their last major threat until Coronal War`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Ambal",
      //     larger: true,
      //     top: 1661,
      //     left: 2487,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Lemash",
      //     larger: true,
      //     top: 1722,
      //     left: 2541,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Srento",
      //     larger: true,
      //     top: 2030,
      //     left: 2555,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Iyfnaes",
      //     larger: true,
      //     top: 2053,
      //     left: 2090,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Onivine",
      //     larger: true,
      //     top: 2006,
      //     left: 2162,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Dubraes",
      //     larger: true,
      //     top: 2180,
      //     left: 2183,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Arpinum",
      //     larger: true,
      //     top: 1923,
      //     left: 2302,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Taboth",
      //     larger: true,
      //     top: 2280,
      //     left: 2495,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Caralis",
      //     larger: true,
      //     top: 2077,
      //     left: 2835,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Ovgroth",
      //     larger: true,
      //     top: 2960,
      //     left: 1965,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Sea Heart",
      //     larger: true,
      //     top: 1910,
      //     left: 3970,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Ryinora",
      //     larger: true,
      //     top: 2103,
      //     left: 3903,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Sabratha",
      //     larger: true,
      //     top: 2234,
      //     left: 3849,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Enthara",
      //     larger: true,
      //     top: 2324,
      //     left: 4005,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Upper Sabra",
      //     larger: true,
      //     top: 2080,
      //     left: 3420,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Boreana",
      //     larger: true,
      //     top: 2570,
      //     left: 3766,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Seyana",
      //     larger: true,
      //     top: 2700,
      //     left: 3942,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Gratsia",
      //     larger: true,
      //     top: 2970,
      //     left: 3969,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Cytana",
      //     larger: true,
      //     top: 2870,
      //     left: 3537,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Y'leriae",
      //     larger: true,
      //     top: 1931,
      //     left: 2030,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Cartennae",
      //     larger: true,
      //     top: 1722,
      //     left: 2087  ,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Cydaae",
      //     larger: true,
      //     top: 1684,
      //     left: 2190  ,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Citadel of the Seven",
      //     larger: true,
      //     top: 1571,
      //     left: 2208  ,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Cylethsin",
      //     larger: true,
      //     top: 1949,
      //     left: 2122,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Cynlona",
      //     larger: true,
      //     top: 1887,
      //     left: 1887,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Bastion of Erathis",
      //     larger: true,
      //     top: 1852,
      //     left: 1904,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Blood Coast Bay",
      //     larger: true,
      //     top: 1822,
      //     left: 1928,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },


      //   {
      //     label: "Umshara",
      //     larger: true,
      //     top: 1880,
      //     left: 3818,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     blurb: `Port river mouth `,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Ilyana",
      //     larger: true,
      //     top: 1799,
      //     left: 3666,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Thon Daral",
      //     larger: true,
      //     top: 993,
      //     left: 3286,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },


      //   {
      //     label: "Rusminda",
      //     larger: true,
      //     top: 687,
      //     left: 2740,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Qazol",
      //     larger: true,
      //     top: 666,
      //     left: 2546,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Zestaikha",
      //     larger: true,
      //     top: 688,
      //     left: 3125,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Vagharshin",
      //     larger: true,
      //     top: 562,
      //     left: 2795,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: ironLegion,
      //     color: `${ironLegion}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Grekven's Hold",
      //     larger: true,
      //     top: 337,
      //     left: 2892,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: ironLegion,
      //     color: `${ironLegion}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Shuldrerock",
      //     larger: true,
      //     top: 103,
      //     left: 2578,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: ironLegion,
      //     color: `${ironLegion}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Kapvir",
      //     larger: true,
      //     top: 533,
      //     left: 2990,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: ironLegion,
      //     color: `${ironLegion}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Qazol",
      //     larger: true,
      //     top: 501,
      //     left: 3344,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: platinum,
      //     color: `${platinum}22`,
      //     border: "#FFFA",
      //     textColor: "#000"
      //   },
      //   {
      //     label: "Zestaikha",
      //     larger: true,
      //     top: 399,
      //     left: 3727,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: ironLegion,
      //     color: `${ironLegion}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Seroshi",
      //     larger: true,
      //     top: 616,
      //     left: 3753,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: platinum,
      //     color: `${platinum}22`,
      //     border: "#FFFA",
      //     textColor: "#000"
      //   },
      //   {
      //     label: "Rusraq",
      //     larger: true,
      //     top: 654,
      //     left: 3927,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: platinum,
      //     color: `${platinum}22`,
      //     border: "#FFFA",
      //     textColor: "#000"
      //   },
      //   {
      //     label: "Oznaki",
      //     larger: true,
      //     top: 765,
      //     left: 4014,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: platinum,
      //     color: `${platinum}22`,
      //     border: "#FFFA",
      //     textColor: "#000"
      //   },
      //   {
      //     label: "Vanavan",
      //     larger: true,
      //     top: 567,
      //     left: 4118,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: platinum,
      //     color: `${platinum}22`,
      //     border: "#FFFA",
      //     textColor: "#000"
      //   },
      //   {
      //     label: "Ganisi",
      //     larger: true,
      //     top: 511,
      //     left: 4199,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: platinum,
      //     color: `${platinum}22`,
      //     border: "#FFFA",
      //     textColor: "#000"
      //   },
      //   {
      //     label: "Vardaran",
      //     larger: true,
      //     top: 419,
      //     left: 4180,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: platinum,
      //     color: `${platinum}22`,
      //     border: "#FFFA",
      //     textColor: "#000"
      //   },
      //   {
      //     label: "Dravether",
      //     larger: true,
      //     top: 841,
      //     left: 3902,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: platinum,
      //     color: `${platinum}22`,
      //     border: "#FFFA",
      //     textColor: "#000"
      //   },
      //   {
      //     label: "Sharmunu",
      //     larger: true,
      //     top: 1632,
      //     left: 4125,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: platinum,
      //     color: `${platinum}22`,
      //     border: "#FFFA",
      //     textColor: "#000"
      //   },
      //   {
      //     label: "Kherima",
      //     larger: true,
      //     top: 1468,
      //     left: 4173,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: platinum,
      //     color: `${platinum}22`,
      //     border: "#FFFA",
      //     textColor: "#000"
      //   },


      //   {
      //     label: "Terasha",
      //     larger: true,
      //     top: 775,
      //     left: 2928,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },


      //   {
      //     label: "Librajak",
      //     larger: true,
      //     top: 1002,
      //     left: 2749,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Krilok",
      //     larger: true,
      //     top: 1015,
      //     left: 3172,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },


      //   {
      //     label: "Vultu",
      //     larger: true,
      //     top: 916,
      //     left: 3328,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Livvo",
      //     larger: true,
      //     top: 1092,
      //     left: 3370,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Lucika",
      //     larger: true,
      //     top: 1165,
      //     left: 2952,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: imperialPurple,
      //     color: `${imperialPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },


      //   //////


      //   {
      //     label: "Rengroth",
      //     larger: true,
      //     top: 3001,
      //     left: 2782,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: horseBarbs,
      //     color: `${horseBarbs}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   // Plat Repub

      //   {
      //     label: "Sepruma",
      //     larger: true,
      //     top: 1969,
      //     left: 4028,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: platinum,
      //     color: `${platinum}55`,
      //     border: "#000A",
      //     textColor: "#000"
      //   },
      //   {
      //     label: "Sakta",
      //     larger: true,
      //     top: 1902,
      //     left: 4124,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: platinum,
      //     color: `${platinum}55`,
      //     border: "#000A",
      //     textColor: "#000"
      //   },


      //   // Gilded Forest
      //   {
      //     label: "Nilhlenora/Alanatalos",
      //     larger: true,
      //     top: 1667,
      //     left: 3926,
      //     enabled: true,
      //     opacity: "0.25",
      //     spanColor: gildedForest,
      //     color: `${gildedForest}55`,
      //     border: `${basicSilver}55`,
      //     textColor: `#FFF`
      //   },
      //   {
      //     label: "A'alrius",
      //     larger: true,
      //     top: 1787,
      //     left: 3881,
      //     enabled: true,
      //     opacity: "0.25",
      //     spanColor: gildedForest,
      //     color: `${gildedForest}55`,
      //     border: `${basicSilver}55`,
      //     textColor: `#FFF`
      //   },
      //   // Fair Isle
      //   {
      //     label: "Lyathyr",
      //     larger: true,
      //     top: 2382,
      //     left: 2287,
      //     enabled: true,
      //     opacity: "0.25",
      //     spanColor: gildedForest,
      //     color: `${gildedForest}55`,
      //     border: `${basicSilver}55`,
      //     textColor: `#000`
      //   },
      //   {
      //     label: "Cath Lenora",
      //     larger: true,
      //     top: 2240,
      //     left: 2289,
      //     enabled: true,
      //     opacity: "0.25",
      //     spanColor: gildedForest,
      //     color: `${gildedForest}55`,
      //     border: `${basicSilver}55`,
      //     textColor: `#000`
      //   },
      //   {
      //     label: "Shaan Shaeras",
      //     larger: true,
      //     top: 2589,
      //     left: 2287,
      //     enabled: true,
      //     opacity: "0.25",
      //     spanColor: gildedForest,
      //     color: `${gildedForest}55`,
      //     border: `${basicSilver}55`,
      //     textColor: `#000`
      //   },

      //   /// Giant Land
      //   {
      //     label: "Tiradër",
      //     larger: true,
      //     top: 849,
      //     left: 3246,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: ironLegion,
      //     color: `${ironLegion}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Riksar's Hall",
      //     larger: true,
      //     top: 746,
      //     left: 3452,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: ironLegion,
      //     color: `${ironLegion}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   //// Timoc

      //   {
      //     label: "Timther",
      //     larger: true,
      //     top: 755,
      //     left: 2386,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: timocPeach,
      //     color: `${timocPeach}22`,
      //     border: "#FFFA",
      //     textColor: "#000"
      //   },
      //   {
      //     label: "Sakruna",
      //     larger: true,
      //     top: 380,
      //     left: 2452,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: timocPeach,
      //     color: `${timocPeach}22`,
      //     border: "#FFFA",
      //     textColor: "#000"
      //   },
      //   {
      //     label: "Nebsim",
      //     larger: true,
      //     top: 485,
      //     left: 2545,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: timocPeach,
      //     color: `${timocPeach}22`,
      //     border: "#FFFA",
      //     textColor: "#000"
      //   },
      //   {
      //     label: "Nekhfumeru",
      //     larger: true,
      //     top: 835,
      //     left: 2187,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: timocPeach,
      //     color: `${timocPeach}22`,
      //     border: "#FFFA",
      //     textColor: "#000"
      //   },
      //   {
      //     label: "Apmahdet",
      //     larger: true,
      //     top: 815,
      //     left: 2099,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: timocPeach,
      //     color: `${timocPeach}22`,
      //     border: "#FFFA",
      //     textColor: "#000"
      //   },

      //   {
      //     label: "Saska",
      //     larger: true,
      //     top: 825,
      //     left: 1993,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: timocPeach,
      //     color: `${timocPeach}22`,
      //     border: "#FFFA",
      //     textColor: "#000"
      //   },
      //   {
      //     label: "Sesouruna",
      //     larger: true,
      //     top: 619,
      //     left: 2022,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: timocPeach,
      //     color: `${timocPeach}22`,
      //     border: "#FFFA",
      //     textColor: "#000"
      //   },

      //   {
      //     label: "Kisfudos",
      //     larger: true,
      //     top: 881,
      //     left: 2065,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: timocPeach,
      //     color: `${timocPeach}22`,
      //     border: "#FFFA",
      //     textColor: "#000"
      //   },
      //   {
      //     label: "Behnoyut",
      //     larger: true,
      //     top: 983,
      //     left: 1955,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: timocPeach,
      //     color: `${timocPeach}22`,
      //     border: "#FFFA",
      //     textColor: "#000"
      //   },
      //   {
      //     label: "Khatuna",
      //     larger: true,
      //     top: 1109,
      //     left: 1820,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: timocPeach,
      //     color: `${timocPeach}22`,
      //     border: "#FFFA",
      //     textColor: "#000"
      //   },



      //   {
      //     label: "Kinather Ruins",
      //     larger: true,
      //     top: 1845,
      //     left: 1820,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: kinaphalicPurple,
      //     color: `${kinaphalicPurple}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },


      //   {
      //     label: "Greother",
      //     larger: true,
      //     top: 1807,
      //     left: 1708,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: verdantDracGreen,
      //     color: `${verdantDracGreen}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Vythisk", //vyth steel isk star
      //     larger: true,
      //     top: 1726,
      //     left: 1742,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: verdantDracGreen,
      //     color: `${verdantDracGreen}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },


      //   {
      //     label: "Hysvear", //soar
      //     larger: true,
      //     top: 1976,
      //     left: 1564,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: verdantDracGreen,
      //     color: `${verdantDracGreen}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Nurhoth", //ugly tooth nurh-oth
      //     larger: true,
      //     top: 2037,
      //     left: 1468,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: verdantDracGreen,
      //     color: `${verdantDracGreen}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },



      //   {
      //     label: "Krather",
      //     larger: true,
      //     top: 1973,
      //     left: 1760,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: krateopolisRed,
      //     color: `${krateopolisRed}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Vivex", // Victory
      //     larger: true,
      //     top: 2045,
      //     left: 1725,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: krateopolisRed,
      //     color: `${krateopolisRed}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Versvesh Fortress",
      //     larger: true,
      //     top: 1876,
      //     left: 1786,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: krateopolisRed,
      //     color: `${krateopolisRed}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   // Verdant

      //   {
      //     label: "Vasilach",
      //     larger: true,
      //     top: 1870,
      //     left: 518,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: verdantDracGreen,
      //     color: `${verdantDracGreen}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Drohozansk",
      //     larger: true,
      //     top: 1921,
      //     left: 331,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: verdantDracGreen,
      //     color: `${verdantDracGreen}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },


      //   {
      //     label: "Navapovysk",
      //     larger: true,
      //     top: 2033,
      //     left: 22,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: verdantDracGreen,
      //     color: `${verdantDracGreen}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Krasnovodske",
      //     larger: true,
      //     top: 1497,
      //     left: 328,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: verdantDracGreen,
      //     color: `${verdantDracGreen}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Lipnț",
      //     larger: true,
      //     top: 1474,
      //     left: 462,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: verdantDracGreen,
      //     color: `${verdantDracGreen}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Barush",
      //     larger: true,
      //     top: 1391,
      //     left: 402,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: verdantDracGreen,
      //     color: `${verdantDracGreen}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Călășli",
      //     larger: true,
      //     top: 1748,
      //     left: 645,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: verdantDracGreen,
      //     color: `${verdantDracGreen}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Stratov",
      //     larger: true,
      //     top: 2056,
      //     left: 1018,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: verdantDracGreen,
      //     color: `${verdantDracGreen}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Bydry",
      //     larger: true,
      //     top: 2037,
      //     left: 915,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: verdantDracGreen,
      //     color: `${verdantDracGreen}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Bébony",
      //     larger: true,
      //     top: 1994,
      //     left: 754,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: verdantDracGreen,
      //     color: `${verdantDracGreen}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Tolbarcika",
      //     larger: true,
      //     top: 1816,
      //     left: 1177,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: verdantDracGreen,
      //     color: `${verdantDracGreen}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Myrchiv",
      //     larger: true,
      //     top: 1976,
      //     left: 1346,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: verdantDracGreen,
      //     color: `${verdantDracGreen}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Dobnica",
      //     larger: true,
      //     top: 1800,
      //     left: 1532,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: verdantDracGreen,
      //     color: `${verdantDracGreen}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Kiyeyka",
      //     larger: true,
      //     top: 1061,
      //     left: 166,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: verdantDracGreen,
      //     color: `${verdantDracGreen}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },


      //   // Ash n Cinder
      //   {
      //     label: "Nirest",
      //     larger: true,
      //     top: 1392,
      //     left: 1358,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: ashCinderPink,
      //     color: `${ashCinderPink}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Singelle",
      //     larger: true,
      //     top: 1467,
      //     left: 1905,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: ashCinderPink,
      //     color: `${ashCinderPink}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Charhelm",
      //     larger: true,
      //     top: 1487,
      //     left: 1598,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: ashCinderPink,
      //     color: `${ashCinderPink}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },


      //   {
      //     label: "Castle Dominant",
      //     larger: true,
      //     top: 1534,
      //     left: 2215,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: ashCinderPink,
      //     color: `${ashCinderPink}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //    // Cipherdo
      //   {
      //     label: "Loton",
      //     larger: true,
      //     top: 1169,
      //     left: 1218,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: ciphPurpRed,
      //     color: `${ciphPurpRed}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Cindiro",
      //     larger: true,
      //     top: 1060,
      //     left: 1459,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: ciphPurpRed,
      //     color: `${ciphPurpRed}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Fuegara",
      //     larger: true,
      //     top: 968,
      //     left: 1487,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: ciphPurpRed,
      //     color: `${ciphPurpRed}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Surch",
      //     larger: true,
      //     top: 747,
      //     left: 1501,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: ciphPurpRed,
      //     color: `${ciphPurpRed}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   // Drimneh

      //   {
      //     label: "Malimad",
      //     larger: true,
      //     top: 990,
      //     left: 1676,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: burnOrange,
      //     color: `${burnOrange}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Pyreloch",
      //     larger: true,
      //     top: 531,
      //     left: 1892,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: burnOrange,
      //     color: `${burnOrange}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Firefall",
      //     larger: true,
      //     top: 370,
      //     left: 1599,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: burnOrange,
      //     color: `${burnOrange}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Igneia",
      //     larger: true,
      //     top: 457,
      //     left: 1759,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: burnOrange,
      //     color: `${burnOrange}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },


      //   // Entrop


      //   {
      //     label: "Phurkulzuth",
      //     larger: true,
      //     top: 866,
      //     left: 1292,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: entropyDark,
      //     color: `${entropyDark}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Akros",
      //     larger: true,
      //     top: 794,
      //     left: 1127,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: entropyDark,
      //     color: `${entropyDark}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Oshox Khuz",
      //     larger: true,
      //     top: 664,
      //     left: 753,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: entropyDark,
      //     color: `${entropyDark}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Faenhil",
      //     larger: true,
      //     top: 695,
      //     left: 595,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: entropyDark,
      //     color: `${entropyDark}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Fylnthaes",
      //     larger: true,
      //     top: 596,
      //     left: 600,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: entropyDark,
      //     color: `${entropyDark}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Invabelle",
      //     larger: true,
      //     top: 497,
      //     left: 546,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: entropyDark,
      //     color: `${entropyDark}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Srenlion",
      //     larger: true,
      //     top: 406,
      //     left: 446,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: entropyDark,
      //     color: `${entropyDark}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },


      //   {
      //     label: "Omnthaes",
      //     larger: true,
      //     top: 522,
      //     left: 273,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: entropyDark,
      //     color: `${entropyDark}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Us Alari",
      //     larger: true,
      //     top: 779,
      //     left: 444,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: entropyDark,
      //     color: `${entropyDark}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },


      //   //Misc


      //   {
      //     label: "Birdridge",
      //     larger: true,
      //     top: 552,
      //     left: 1223,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: entropyDark,
      //     color: `${entropyDark}22`,
      //     border: "#FFFA",
      //     blurb: "Halfling village totally under thumb of Chromatics, no light no joy. Used as a trading center with the original Halflings kept as near slaves to upkeep for higher sublicants",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Mistbreak",
      //     larger: true,
      //     top: -10,
      //     left: 1276,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: entropyDark,
      //     color: `${entropyDark}22`,
      //     border: "#FFFA",
      //     blurb: "Halfling city but Halflings are treated as slaves more or less. Now a dusty, sleepy, dying town that just has passers through while Halflings tend to them",
      //     textColor: "#FFF"
      //   },


      //   {
      //     label: "Oakvale",
      //     larger: true,
      //     top: 43,
      //     left: 978,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: entropyDark,
      //     color: `${entropyDark}22`,
      //     border: "#FFFA",
      //     blurb: "Forested town near convergence of tributaries...float logs down to Firefall and Halflings are made to work and host other slaves that do not make it long ",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Elynarion",
      //     larger: true,
      //     top: 1155,
      //     left: 945,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: entropyDark,
      //     color: `${entropyDark}22`,
      //     border: "#FFFA",
      //     blurb: "Former Elven city, depopulated years back now filled with resettled slaves to work farm fields",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Cyfemel",
      //     larger: true,
      //     top: 1347,
      //     left: 865,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: entropyDark,
      //     color: `${entropyDark}22`,
      //     border: "#FFFA",
      //     blurb: "Elven Ruin now a hub to transport wood and goods from Verdant Forest to the coast",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Imyallian",
      //     larger: true,
      //     top: 1519,
      //     left: 1118,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: entropyDark,
      //     color: `${entropyDark}22`,
      //     border: "#FFFA",
      //     blurb: "City destroyed by Llothites generations back, now a hub of Underdark trade",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Catacadis",
      //     larger: true,
      //     top: 2647,
      //     left: 1188,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: ciphPurpRed,
      //     color: `${ciphPurpRed}22`,
      //     border: "#FFFA",
      //     blurb: "Capital near foot of Ashlaradon's Lair, lava symbolism and references everywhere. The steam of the heat ever seeps up from the ground in this hot city",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Littoloch",
      //     larger: true,
      //     top: 2509,
      //     left: 1026,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: ciphPurpRed,
      //     color: `${ciphPurpRed}22`,
      //     border: "#FFFA",
      //     blurb: "Rather desolate bay, though there is decent fishing. Desert city with tall walls and many trade ships",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Navacada",
      //     larger: true,
      //     top: 2414,
      //     left: 1393,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: ciphPurpRed,
      //     color: `${ciphPurpRed}22`,
      //     border: "#FFFA",
      //     blurb: "City straddling the Navacadan peninsula, the naval base serves as one of the largest for the Pentarchy on this coast",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Saphitas",
      //     larger: true,
      //     top: 2160,
      //     left: 1406,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: ciphPurpRed,
      //     color: `${ciphPurpRed}22`,
      //     border: "#FFFA",
      //     blurb: "Heavily fortified city on a passage from inland sea to the ocean. Lead Generals for the Twins spend much time here, as well as being a city of sin for soldiers and mercenaries to spend their coin when they can.",
      //     textColor: "#FFF"
      //   },
      //   // Tyranny

      //   {
      //     label: "Ovaturak",
      //     larger: true,
      //     top: 2556,
      //     left: 393,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: tyrannyBlue,
      //     color: `${tyrannyBlue}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Badebagi",
      //     larger: true,
      //     top: 2522,
      //     left: 534,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: tyrannyBlue,
      //     color: `${tyrannyBlue}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },

      //   {
      //     label: "Gümüsil",
      //     larger: true,
      //     top: 2451,
      //     left: 787,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: tyrannyBlue,
      //     color: `${tyrannyBlue}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Badelkan",
      //     larger: true,
      //     top: 2702,
      //     left: 243,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: tyrannyBlue,
      //     color: `${tyrannyBlue}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Hajirdir",
      //     larger: true,
      //     top: 2625,
      //     left: 107,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: tyrannyBlue,
      //     color: `${tyrannyBlue}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },


      //   // The Powerful Alliance

      //  {
      //     label: "Icehall",
      //     larger: true,
      //     top: 2277,
      //     left: 224,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: powerfulDull,
      //     color: `${powerfulDull}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },
      //   {
      //     label: "Woodsedge",
      //     larger: true,
      //     top: 2101,
      //     left: 776,
      //     enabled: true,
      //     opacity: "0.1",
      //     spanColor: powerfulDull,
      //     color: `${powerfulDull}22`,
      //     border: "#FFFA",
      //     textColor: "#FFF"
      //   },


      ]
    },
    "Otoño": {
      driveId: "1lfr0r8LXOERusSTKbn4UxlPjSsysxrIc",
      enabled: true,
      fog: false,
      fogClass: "neutralparty",
      measureMod: "neutralparty",
      translation: {x: -2280, y: -900},
      // translation: {x: -0, y: -1600},
      overlays: [
        {
          title: "Show All Borders",
          enabled: true,
          driveId: "1gNaiR7k0HCLNHjW8ZfD4rbnsM_RDyaj",
        },
      ]
    },
    "Verano": {
      driveId: "1YID43mnngB-6oguMNbYaHq020McCy06d",
      pointsLoad: "First World (Verano)",
      points: [],
      enabled: true,
      translation: {x: -2280, y: -900},
    },
    "Thalas": {
      driveId: "1H5_z9kiCtQL_QoGqukCxZ9GucsuBTACW",
      points: [],
      enabled: false,
      // translation: {x: -2280, y: -900},
    },
    "Kinaphalic Kingdom": {
      driveId: "1aoNzDiXbLCaIN_BFJVEILwzltlUZ-_s0",
      enabled: true,
      fog: false,
      fogClass: "neutralparty",
      measureMod: "neutralparty",
    },
    "Rough Map": {
      driveId: "1yZHWVu9HIiZlRSLYDOW5fqsGTMDA814G",
      enabled: true,
      fog: false,
      fogClass: "neutralparty",
      measureMod: "neutralparty",
    },

    "seaheart": {
      enabled: false,
      dmOnly: true,
      driveId: "1BX8S2ks0gkYppLKVJWmap4SBvrsnxyCU",
    },

    "fortified_cabin": {
      enabled: true,
      driveId: "1lJRCN6ZWprasT2ANP-xYI90Q-4q3DnRA",
    },

    "whispering_caverns": {
      driveId: "1M16eO-vikvs3r2lvccLLy8vuzuD6Q2wj",
      enabled: true,
      dmOnly: true,
      fog: false,
      fogClass: "neutralparty",
      measureMod: "neutralparty",
      fogX: 52,
      fogY: 78,
      fogIndentX: 800,
      fogIndentY: 800,
      fogDiff: 111
    },

    "Dusorol Docks": {
      enabled: true,
      dmOnly: true,
      driveId: "16oIAbx6uybY8ZdnAhEjW3UKbv1I1T2NQ",
    },

    "Srath Cànanach": {
      enabled: true,
      dmOnly: false,
      driveId: "1ksFPbKqCJk9whwxf7jNJkJZItd66USqm",
    },

    "City with Canals": {
      enabled: true,
      dmOnly: false,
      driveId: "1arCI0OJBNixDKUB6nfnEQnpiNfRJceW7",
    },

    "City with Canals (Night)": {
      enabled: true,
      dmOnly: false,
      driveId: "1tKJCFfVsezFnsxhE6m-qIzhMSqf8nC5N",
    },

    "Canals with City": {
      enabled: true,
      dmOnly: false,
      driveId: "1bn_kuqHpm9wj9ANUhB3jEZiZwJrYU2gl",
    },

    "Canals with City (Night)": {
      enabled: true,
      dmOnly: false,
      driveId: "1aF6EuxSS1lee_oONySLS0BpquApL1HkJ",
    },

    "Villa": {
      enabled: true,
      dmOnly: false,
      driveId: "17XzsB49V7JtAG0ivgMy9xquQbcDvWwN3",
    },

    "Warehouse Day": {
      enabled: true,
      dmOnly: false,
      driveId: "1FAc96XK8c_DajO2s7eAYh90eq93BMQ6R",
      fog: true,
      fogClass: "neutralparty",
      measureMod: "neutralparty",
      fogX: 16,
      fogY: 20,
      fogIndentX: 4,
      fogIndentY: 4,
      fogDiff: 111
    },

    "Warehouse Night": {
      enabled: true,
      dmOnly: false,
      driveId: "1XbyfCb30xyNHRa5tuoCytb1TZkkYtPX4",
      fog: true,
      fogClass: "neutralparty",
      measureMod: "neutralparty",
      fogX: 16,
      fogY: 20,
      fogIndentX: 4,
      fogIndentY: 4,
      fogDiff: 111
    },

    "Ruins Tomb": {
      enabled: true,
      dmOnly: false,
      driveId: "186dBPweo640xfI-KcK7lkfAPGgh8I8CS",
      fog: true,
      fogClass: "neutralparty",
      measureMod: "neutralparty",
      fogX: 20,
      fogY: 15,
      fogIndentX: 4,
      fogIndentY: 4,
      fogDiff: 111
    },

    "Kobold Tunnels": {
      enabled: true,
      dmOnly: false,
      driveId: "1ULt3U_hSN-fw8e0SelQB0uG7YKm_kgi0",
      fog: true,
      fogClass: "neutralparty",
      measureMod: "neutralparty",
      fogX: 30,
      fogY: 20,
      fogIndentX: 4,
      fogIndentY: 4,
      fogDiff: 111
    },

    "Klorinox Lair": {
      enabled: true,
      dmOnly: false,
      driveId: "1YDOtedMZ4jdtEzPDLaaHkjnZLr3g-IK3",
      fog: true,
      fogClass: "smalled",
      measureMod: "smalled",
      fogX: 26,
      fogY: 40,
      fogIndentX: 2,
      fogIndentY: 2,
      fogDiff: 70
    },


    "Night Forest Edge": {
      enabled: true,
      driveId: "1mS8mwHti_H_WhpHlTpsbgBCi4Z5r7iwZ",
    },

    "Haunted Farm": {
      enabled: true,
      driveId: "1uNP129mDQugnyYnDBHmKWnckXmM92cdc",
    },

    "Forest": {
      enabled: true,
      driveId: "1rnQv3pEic-TyrpdlQu7R5vdO29UyvhX-",
    },

    "Heart of the volcano": {
      driveId: "1Kgtz22k1-3sU4PmY_fMkSGXoSdioHPE3",
      enabled: true,
      dmOnly: true,
      // fog: true,
      fogClass: "neutralparty",
      measureMod: "neutralparty",
      fogX: 52,
      fogY: 78,
      fogIndentX: 80,
      fogIndentY: 80,
      fogDiff: 111
    },

    "Vulcan Mote Entrance": {
      driveId: "16h3FmALbLvcGUh4hqm0rsIDYMTRLuH_j",
      enabled: true,
      dmOnly: true,
      // fog: true,
      fogClass: "dungeon-alchemist",
      measureMod: "dungeon-alchemist",
      fogX: 40,
      fogY: 40,
      fogIndentX: 304,
      fogIndentY: 304,
      fogDiff: 300,
    },

    "Vulcan Mote Forges": {
      driveId: "1op1k_8Q5jw5JG4ycetw8aT3lZgIQsY4B",
      enabled: true,
      dmOnly: true,
      // fog: true,
      fogClass: "dungeon-alchemist",
      measureMod: "dungeon-alchemist",
      fogX: 33,
      fogY: 33,
      fogIndentX: 304,
      fogIndentY: 304,
      fogDiff: 300,
    },
    "Shadow Fang 1": {
      driveId: "1sIM99zJUwmpCU0sjUJRXe_NCjV_TKyqr",
      enabled: true,
      dmOnly: false,
      fog: true,
      fogClass: "neutralparty",
      measureMod: "neutralparty",
      fogX: 33,
      fogY: 50,
      fogIndentX: 1,
      fogIndentY: 1,
      fogDiff: 111
    },
    "Shadow Fang 2": {
      driveId: "1MQDJwSOZP8PCsBFahiCaOwfDECnl2uYJ",
      enabled: true,
      dmOnly: false,
      fog: true,
      fogClass: "neutralparty",
      measureMod: "neutralparty",
      fogX: 33,
      fogY: 33,
      fogIndentX: 1,
      fogIndentY: 1,
      fogDiff: 111
    },

    "Shadow Fang 2a": {
      driveId: "1MQDJwSOZP8PCsBFahiCaOwfDECnl2uYJ",
      enabled: true,
      dmOnly: false,
      fog: true,
      fogClass: "small-small-small",
      measureMod: "small-small-small",
      fogX: 50,
      fogY: 50,
      fogIndentX: 1,
      fogIndentY: 1,
      fogDiff: 19.5
    },

    "The Maze": {
      driveId: "1rQoawZEz3yzHShU7kz0AJFG9YOeI26_A",
      enabled: false,
      dmOnly: true,
      // fog: true,
      fogClass: "small-small-small",
      measureMod: "small-small-small",
      fogX: 125,
      fogY: 79,
      fogIndentX: 55,
      fogIndentY: 54,
      fogDiff: 19.5
    },

    "The Nicer Maze": {
      driveId: "1TTU4G7gDBHOWgzfIpk_zMl7Dz5-E6gXK",
      enabled: false,
      dmOnly: true,
      fog: true,
      fogClass: "dungeon-alchemist",
      measureMod: "dungeon-alchemist",
      fogX: 50,
      fogY: 41,
      fogIndentX: 304,
      fogIndentY: 304,
      fogDiff: 300,
    },

    "Hunter Stage 1": {
      driveId: "1gRDHQUw_ZbqnnpGSw3Nzq3NCl9X52DT0",
      enabled: true,
      dmOnly: true,
      // fog: true,
      fogClass: "dungeon-alchemist",
      measureMod: "dungeon-alchemist",
      fogX: 40,
      fogY: 48,
      fogIndentX: 304,
      fogIndentY: 304,
      fogDiff: 300,
    },
    "Hunter Stage 2": {
      driveId: "1CtQVj7j8aH0QyabyiDsENKGGheEQFqNy",
      enabled: true,
      dmOnly: true,
      fog: true,
      fogClass: "dungeon-alchemist",
      measureMod: "dungeon-alchemist",
      fogX: 40,
      fogY: 48,
      fogIndentX: 304,
      fogIndentY: 304,
      fogDiff: 300,
    },
  }
}

export default mapData
