import React                 from "react";

class FogOfWar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      enabled: !props.disabled
    }
  }

  batchEnter() {
    if(this.props.mode) {
      this.setState({enabled: false})
    }
  }

  backgroundColor() {
    if(this.props.locked) {
      return "#333";
    }
    else if(this.props.mode) {
      return "#999";
    }
    else {
      return "#733";
    }
  }

  render() {
    const fogDiff = this.props.fogDiff || 150;

    return (
      <div
        className = {`fog-of-war-box ${this.props.fogClass}`}
        onMouseEnter = {this.batchEnter.bind(this)}
        onClick={(e) => {this.setState({enabled: !this.state.enabled})}}
        style = {{
          left: (this.props.x * fogDiff) + this.props.fogIndentX || 0 , //+ 152,
          opacity: this.state.enabled ? 100 : 0,
          top: (this.props.y * fogDiff) + this.props.fogIndentY || 0, // + 300,
          backgroundColor: this.backgroundColor()
        }}
      >

      </div>
    )
  }
}

export default FogOfWar;
