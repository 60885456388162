import React                 from "react";

import ShopGenerator from '../../lib/ShopGenerator';
import ShopTable     from './ShopTable'

class NewShop extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      typeChecked: "standard",
      itemCounts: {
        none: 0,
        common: 0,
        uncommon: 0,
        rare: 0,
        "very rare": 0,
        legendary: 0,
        artifact: 0
      },
      items: {
        none: [],
        common: [],
        uncommon: [],
        rare: [],
        "very rare": [],
        legendary: [],
        artifact: []
      }
    }
  }

  generate() {
    this.generator = new ShopGenerator(
      {
        typeChecked: this.state.typeChecked,
        typeCheckedValue: this.state.typeCheckedValue,
        none:  this.state.itemCounts.none,
        common: this.state.itemCounts.common,
        uncommon: this.state.itemCounts.uncommon,
        rare: this.state.itemCounts.rare,
        "very rare": this.state.itemCounts["very rare"],
        legendary: this.state.itemCounts.legendary,
        artifact: this.state.itemCounts.artifact
      }
    );

    const items = this.generator.generate().items;
    // window.copy(items)
    this.setState({items: items})
  }

  setRarity(e, rarity) {
    let itemCounts = this.state.itemCounts;
    itemCounts[rarity] = e.currentTarget.value;
    this.setState({itemCounts: itemCounts});
  }

  setChecked(e, typeChecked) {
    let val = e.currentTarget.value
    val = val === "true" ? true : val;

    this.setState({
      typeChecked: typeChecked,
      typeCheckedValue: val
    });
  }

  render () {
    return (
      <div>
        <h1>New Shop</h1>

        <div className="rarity-controls">
          <div>
            <label>
              Mundane &nbsp;
              <input type="number" onChange={(e) => {this.setRarity(e, "none")}}/>
            </label>
          </div>

          <div>
            <label>
              Common &nbsp;
              <input type="number" onChange={(e) => {this.setRarity(e, "common")}}/>
            </label>
          </div>

          <div>
            <label>
              Uncommon &nbsp;
              <input type="number" onChange={(e) => {this.setRarity(e, "uncommon")}}/>
            </label>
          </div>

          <div>
            <label>
              Rare &nbsp;
              <input type="number" onChange={(e) => {this.setRarity(e, "rare")}}/>
            </label>
          </div>

          <div>
            <label>
              Very Rare &nbsp;
              <input type="number" onChange={(e) => {this.setRarity(e, "very rare")}}/>
            </label>
          </div>

          <div>
            <label>
              Legendary &nbsp;
              <input type="number" onChange={(e) => {this.setRarity(e, "legendary")}}/>
            </label>
          </div>

          <div>
            <label>
              Artifact &nbsp;
              <input type="number" onChange={(e) => {this.setRarity(e, "artifact")}}/>
            </label>
          </div>
        </div>

        <div className="type-controls">
          <div>
            <label>
              Weapons &nbsp;
              <input name="shop-cat" type="radio" value="true" onChange={(e) => {this.setChecked(e, "weapon")}}/>
            </label>
          </div>

          <div>
            <label>
              Staffs &nbsp;
              <input name="shop-cat" type="radio" value="true" onChange={(e) => {this.setChecked(e, "staff")}}/>
            </label>
          </div>

          <div>
            <label>
              Shields &nbsp;
              <input name="shop-cat" type="radio" value="S" onChange={(e) => {this.setChecked(e, "type")}}/>
            </label>
          </div>


          <div>
            <label>
              Ammo Weapons &nbsp;
              <input name="shop-cat" type="radio" value="A" onChange={(e) => {this.setChecked(e, "type")}}/>
            </label>
          </div>

          <div>
            <label>
              Armor &nbsp;
              <input name="shop-cat" type="radio" value="true" onChange={(e) => {this.setChecked(e, "armor")}}/>
            </label>
          </div>

          <div>
            <label>
              Scrolls &nbsp;
              <input name="shop-cat" type="radio" value="SC" onChange={(e) => {this.setChecked(e, "type")}}/>
            </label>
          </div>

          <div>
            <label>
              Potions &nbsp;
              <input name="shop-cat" type="radio" value="P" onChange={(e) => {this.setChecked(e, "type")}}/>
            </label>
          </div>

          <div>
            <label>
              Basic Spell Casting Focus &nbsp;
              <input name="shop-cat" type="radio" value="SCF" onChange={(e) => {this.setChecked(e, "type")}}/>
            </label>
          </div>

          <div>
            <label>
              Wands &nbsp;
              <input name="shop-cat" type="radio" value="WD" onChange={(e) => {this.setChecked(e, "type")}}/>
            </label>
          </div>

          <div>
            <label>
              Rings &nbsp;
              <input name="shop-cat" type="radio" value="RG" onChange={(e) => {this.setChecked(e, "type")}}/>
            </label>
          </div>

          <div>
            <label>
              Rod &nbsp;
              <input name="shop-cat" type="radio" value="RD" onChange={(e) => {this.setChecked(e, "type")}}/>
            </label>
          </div>

          <div>
            <label>
              Tatoo &nbsp;
              <input name="shop-cat" type="radio" value="true" onChange={(e) => {this.setChecked(e, "tattoo")}}/>
            </label>
          </div>

          <div>
            <label>
              Instrument &nbsp;
              <input name="shop-cat" type="radio" value="INS" onChange={(e) => {this.setChecked(e, "type")}}/>
            </label>
          </div>


        </div>


        <div>
          <button onClick={this.generate.bind(this)}>
            New
          </button>
        </div>

        <div>
          <ShopTable items={this.state.items}/>
        </div>
      </div>
    )
  }
}

export default NewShop;
