import React from "react";

import KillTableau   from "./KillTableau";
import KillTotalRow  from "./KillTotalRow";

class TabulaHomicidium extends React.Component {
  constructor(props) {
    super(props);
    this.characters = [
      "Sparka",
      "Corpo",
      "Cecil",
      "Sass",
      "Lars",
      "The Duchess",
      "GDE",
      "Torsus Skyrend"
    ]
  }

  render() {
    return (
      <div>
        <h3>
          Totals
        </h3>

        <table className="regular">
          <tbody>
            <tr>
              <td></td>

              {
                this.characters.map((character, indy) => {
                  return (
                    <td key={indy}>
                      {character}
                    </td>
                  )
                })
              }

              <td>
                Total
              </td>
            </tr>
            <KillTotalRow
              title={this.props.title}
              data={this.props.data}
              type={this.props.type}
              kind={this.props.kind}
              characters={this.characters}
            />
          </tbody>
        </table>

        <KillTableau kind={this.props.kind} data={this.props.data}/>
      </div>
    )
  }
}

export default TabulaHomicidium;
