import React                 from "react";
import { Link }              from "react-router-dom";
import Cookies               from 'universal-cookie';

import Collapsible           from "../shared/Collapsible"
import mapData               from "../../data/maps";
import Titleize              from "../shared/Titleize";


class Maps extends React.Component {
  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.state = {}
  }

  allowedMaps(key) {
    return Object.keys(mapData[key]).filter((mapKey) => {
      return mapData[key][mapKey].enabled // || (!!this.cookies.get("toBeAMaster") && mapData[key][mapKey].dmOnly);
    });
  }

  collapseToggle(kind) {
    this.state.show === kind ? this.setState({show: ""}) : this.setState({show: kind})
  }

  render() {
    return (
      <div className="standard-panel">
        <h1>
          Maps
        </h1>

        <Collapsible
          title="Toril"
          toggle={this.collapseToggle.bind(this)}
          kind="toril"
          show={this.state.show}
        >
          <ul>
            {
              this.allowedMaps("Toril").map((mapKey, indy) => {
                return (
                  <li key={indy}>
                    <Link to={`/maps/${mapKey}`}>
                      <Titleize word={mapKey} />
                    </Link>
                  </li>
                )
              })
            }
          </ul>
        </Collapsible>

        <Collapsible
          title="First World"
          toggle={this.collapseToggle.bind(this)}
          kind="first"
          show={this.state.show}
        >
          <ul>
            {
              this.allowedMaps("First World").map((mapKey, indy) => {
                return (
                  <li key={indy}>
                    <Link to={`/maps/${mapKey}`}>
                      <Titleize word={mapKey} />
                    </Link>
                  </li>
                )
              })
            }
          </ul>
        </Collapsible>

      </div>
    )
  }
}

export default Maps;
