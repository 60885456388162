import React                 from "react";
import Cookies               from 'universal-cookie';
import { withRouter }        from 'react-router-dom';

import { API_ROOT, HEADERS } from "../../constants/";

class EditCharacter extends React.Component {
  constructor(props, history) {
    super(props);

    this.history = history
    this.state   = {}
    this.cookies = new Cookies();
  }

  componentDidMount() {
    this.fetchCharacter();
  };

  fetchCharacter() {
    fetch(
      `${API_ROOT}/characters/edit`,
      {
        method: 'GET',
        headers: {...HEADERS, Authorization: this.cookies.get('userToken')}
      }
    ).then(res => res.json()
    ).then((res) => {
      this.setState({data: {...res.character.info, originalName: res.character.name}})
    });
  }

  updateCharacter() {
    fetch(
      `${API_ROOT}/characters/`,
      {
        method: 'POST',
        headers: {...HEADERS, Authorization: this.cookies.get('userToken')},
        body: JSON.stringify({
          character: {data: this.state.data}
        })
      }
    ).then(res => res.json()
    ).then((res) => {
      window.location.pathname = `/characters/${this.state.data.originalName}`
    });
  }

  handleUpdate(event) {
    const newState = {data: {...this.state.data, [event.target.name]: event.target.value}}
    this.setState(newState)
  }

  handleSubmit(event) {
    this.updateCharacter();
  }

  render() {
    return (
      <div className="standard-panel">
        {
          this.state.data &&
          <div>
            <div className="most-a-panel">
              <h1>
                Edit Your Character
              </h1>
              <div className="character-info">

                <label htmlFor="tagLine">
                  tagLine:&nbsp;
                  <input id="tagLine" name="tagLine" type="text" value={this.state.data.tagLine} onChange={this.handleUpdate.bind(this)}/>
                </label>

                <h2>
                  Background
                </h2>

                <textarea id="blurb" name="blurb" value={this.state.data.blurb} onChange={this.handleUpdate.bind(this)}/>

                <h2>
                  Recent Events
                </h2>

                <textarea id="recentEvents" name="recentEvents" value={this.state.data.recentEvents} onChange={this.handleUpdate.bind(this)}/>
              </div>
            </div>

            <div className="side-panel">
              <div className="info-card">
                <img
                  className="char-image"
                  src={this.state.data.src}
                  alt="character-portrait"
                />
                <h3>
                  Name:
                  <input id="name" name="name" type="text" value={this.state.data.name} onChange={this.handleUpdate.bind(this)}/>
                </h3>

                <div className="basic-info">
                  <label htmlFor="src">
                    <strong>
                      Image Src
                    </strong>

                    <br/>

                    <input id="src" name="src" type="text" value={this.state.data.src} onChange={this.handleUpdate.bind(this)}/>
                  </label>

                  <br/>
                  <label htmlFor="race">
                    <strong>
                      Race
                    </strong>

                    <br/>

                    <input id="race" name="race" type="text" value={this.state.data.race} onChange={this.handleUpdate.bind(this)}/>
                  </label>

                  <br/>

                  <label htmlFor="gender">
                    <strong>
                      Gender
                    </strong>

                    <br/>

                    <input id="gender" name="gender" type="text" value={this.state.data.gender} onChange={this.handleUpdate.bind(this)}/>
                  </label>

                  <br/>

                  <label htmlFor="status">
                    <strong>
                      Status
                    </strong>

                    <br/>

                    <input id="status" name="status" type="text" value={this.state.data.status} onChange={this.handleUpdate.bind(this)}/>
                  </label>
                </div>
              </div>

              <button onClick={this.handleSubmit.bind(this)}>
                Update Character For All!
              </button>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default withRouter(EditCharacter);
