const CastratiData = {
  2: {
    Lars: [
      "Castrated the Goblin's wolf before Sass mercy killed it"
    ],
  },
  3: {
    Lars: [
      "Castrated then killed Ripper the wolf"
    ],
  },
  6: {
    Lars: [
      "Castrate-Kill Bug Bear's Young Direwolf"
    ],
  },
  7: {
    Lars: [
      "Tarhun, though Sass later used Cure Wounds to reattach..."
    ],
  },
  8: {
    Lars: [
      "Castrate a zombie with final blow"
    ],
  },
  9: {
    Corpo: [
      "Hacks off Venomfang's phallus"
    ],
    Lars: [
      "Kills castrates the Cultist and throws his junk in Spark's bonfire",
      "Castrate Faveric before killing",
      "Ring of Jumping leaps up the tower, attacks a Giant Spider KILLS it and Castrates it's spinnerets",
      "Leaps off the tower, kills spider, castrates it, rides corpses 30foot down to the ground... all of this is amazing",
      "KILLS giant spider and castrates its spinnerts"
    ],

  },
  13: {
    Lars: [
      "Ghast, Axe castration, throwing nutsack at other Ghasts",
      "Ghast, Axe castrates another Ghast throwing ball sack down and stomps on them"
    ],
  },
  14: {
    Lars: [
      "Hobgoblin Captain, Axe Castrates and throws nuts outside yelling I AM THE DRAGON",
      "Kills the Bugbear between the legs, looks at his friends, and tea bags him",
      "Bugbear, Axe Castrates and hold nuts up yelling I AM THE DRAGON, and kicks him off the roof",
    ],
  },
  19: {
    Lars: [
      "Axe attack on Ogre before he could yell, slitting throat and whispering \"I am the dragon\" and chopping off dick",
    ]
  },
  20: {
    Lars: [
      "Kills guy hewing him in half...then slaps him with his dick on the bottom half cause he has bad initiative.",
      "Kills Brozig the Ogre chops off dick and kicks him in the knee...throws the dick at the other"
    ]
  },
  22: {
    Lars: [
      "Kills Hadrian and shoves balls in his mouth",
    ]
  },
  26: {
    Lars: [
      "Kills Bandit and flips balls at another dude does 1 bludgeoning damage. NO STI since incels",
      "Kills Barlgura severing the big scrotum and hanging on his waist"
    ],
    Corpo: [
      "Tanarukk with Staff of Thunder and Lightning. His eyes light up like Thor, he shoves staff up it's ass...exploding from inside out with dick flying into Sass' face but it misses",
    ]
  },
  34: {
    Lars: [
      "Kills Salamander with Axe up ther Cloaca"
    ]
  }
}

export default CastratiData
