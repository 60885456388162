import React                 from "react";
import { Link }              from "react-router-dom";

import locationsData         from "../../data/locations";
import mapData               from "../../data/maps";


class Locations extends React.Component {
  allowedChars(chars) {
    return Object.keys(chars).filter((locationKey) => {
      return chars[locationKey].enabled;
    });
  }

  unvisitedFirstWorld() {
    return mapData["First World"].Primavera.points.filter((point) => {
      return !point.visited && point.enabled;
    })
  }

  render() {
    return (
      <div className="standard-panel">
        <h1>
          Locations
        </h1>

        <div className="divisor">
          <h2>
            Visited Locations
          </h2>

          {
            this.allowedChars(locationsData.Toril.visited).map((locationKey, indy) => {
              return (
                <div
                  key={indy}
                  className="index-listing"
                >
                  <Link to={`/pages/${locationKey}`}>
                    <img
                      src={locationsData.Toril.visited[locationKey].src || (mapData[locationKey] || {}).src || "https://toppng.com/uploads/preview/location-png-icon-location-icon-png-free-11562933803vththezlcl.png"}
                      alt="character-thumb"
                    />

                    <div className="info-panel">
                      <h3>
                        {locationsData.Toril.visited[locationKey].name}
                      </h3>

                      <span>
                        {locationsData.Toril.visited[locationKey].tagLine}
                      </span>
                    </div>
                  </Link>
                </div>
              )
            })
          }
        </div>


        <div className="divisor">
          <h2>
            Others
          </h2>

          {
            this.allowedChars(locationsData.Toril.other).map((locationKey, indy) => {
              return (
                <div
                  key={indy}
                  className="index-listing"
                >
                  <Link to={`/pages/${locationKey}`}>
                    <img
                      src={locationsData.Toril.other[locationKey].src || (mapData[locationKey] || {}).src || "https://toppng.com/uploads/preview/location-png-icon-location-icon-png-free-11562933803vththezlcl.png"}
                      alt="character-thumb"
                    />

                    <div className="info-panel">
                      <h3>
                        {locationsData.Toril.other[locationKey].name}
                      </h3>

                      <span>
                        {locationsData.Toril.other[locationKey].tagLine}
                      </span>
                    </div>

                  </Link>
                </div>
              )
            })
          }
        </div>

        <div className="divisor">
          <h2>
            First World Visited
          </h2>

          {
            this.allowedChars(locationsData["First World"].visited).map((locationKey, indy) => {
              const pointData = locationsData["First World"].visited[locationKey];

              return (
                <div
                  key={indy}
                  className="index-listing"
                >
                  <Link to={`/pages/${locationKey}`}>
                    <img
                      src={pointData.src || (mapData[pointData] || {}).src || "https://toppng.com/uploads/preview/location-png-icon-location-icon-png-free-11562933803vththezlcl.png"}
                      alt="character-thumb"
                    />

                    <div className="info-panel">
                      <h3>
                        {pointData.name || pointData.label}
                      </h3>

                      <span>
                        {pointData.tagLine}
                      </span>
                    </div>

                  </Link>
                </div>
              )
            })
          }
        </div>

        <div className="divisor">
          <h2>
            First World Unvisited
          </h2>

          {
            this.allowedChars(locationsData["First World"].other).map((locationKey, indy) => {
              const pointData = locationsData["First World"].other[locationKey];

              return (
                <div
                  key={indy}
                  className="index-listing"
                >
                  <Link to={`/pages/${locationKey}`}>
                    <img
                      src={pointData.src || (mapData[pointData] || {}).src || "https://toppng.com/uploads/preview/location-png-icon-location-icon-png-free-11562933803vththezlcl.png"}
                      alt="character-thumb"
                    />

                    <div className="info-panel">
                      <h3>
                        {pointData.name || pointData.label}
                      </h3>

                      <span>
                        {pointData.tagLine}
                      </span>
                    </div>

                  </Link>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}

export default Locations;
