import React                 from "react";
import { MapInteractionCSS } from "react-map-interaction";
import mapsData              from "../../data/maps";
import locationsData         from "../../data/locations";
import Titleize              from "../shared/Titleize";
import DriveImage            from "../shared/DriveImage";

import FogOfWar              from "./FogOfWar";
import Measure               from "./Measure";


class Map extends React.Component {
  constructor(props) {
    super(props);

    this.mapData = mapsData["Toril"][this.props.map] || mapsData["First World"][this.props.map];

    this.state = {
      value: {
        scale: 1,
        translation: this.mapData.translation || { x: 0, y: 0 }
      },
      fullScreen: true,
      rotate: 0,
      measureRotation: 0,
      overlays: this.mapData.overlays || []
    };
  }

  componentDidMount() {
    document.addEventListener('keyup', this.pressItRealGood.bind(this));
  }

  pressItRealGood(e) {
    if(e.code === "KeyA") {
      this.setState({mode: !this.state.mode})
    }
    else if(e.code === "KeyQ") {
      this.setState({locked: !this.state.locked})
    }
    else if(e.code === "KeyR") {
      this.setState({rotate: (this.state.rotate + 90) % 360})
    }
    else if(e.code === "KeyF") {
      this.setState({measure: this.state.measure === "Fireball" ? null :"Fireball"})
    }
    else if(e.code === "KeyG") {
      this.setState({measure: this.state.measure === "smallerCircle" ? null :"smallerCircle"})
    }
    else if(e.code === "KeyT") {
      this.setState({measureRotation: (this.state.measureRotation + 10) % 360})
    }
    else if(e.code === "KeyD") {
      this.setState({measure: this.state.measure ===  "Dragonborn" ? null : "Dragonborn"})
    }
    else if(e.code === "KeyM") {
      this.setState({measure: this.state.measure ===  "MonkDragon" ? null : "MonkDragon"})
    }
    else if(e.code === "KeyN") {
      this.setState({measure: this.state.measure ===  "MonkLine" ? null : "MonkLine"})
    }
    else if(e.code === "KeyV") {
      this.setState({measure: this.state.measure ===  "Venomfang" ? null : "Venomfang"})
    }
  }

  allowedPoints() {
    let pointed = (this.mapData.points || []).filter((point) => {
      return point.enabled;
    });

    if(this.mapData.pointsLoad) {
      this.x=locationsData
      pointed = pointed.concat(Object.values(locationsData[this.mapData.pointsLoad].visited));
      pointed = pointed.concat(Object.values(locationsData[this.mapData.pointsLoad].other));
    }

    return pointed;
  }

  gridIt() {
    const x = this.mapData.fogX || 20;
    const y = this.mapData.fogY || 20;
    return [...Array(x)].map((e, xIndy) => {
      return [...Array(y)].map((e, yIndy) => {
        return  (
          <FogOfWar
            key={yIndy}
            x={xIndy}
            y={yIndy}
            mode={this.state.mode}
            disabled={this.mapData.startNoFog}
            locked={this.state.locked}
            fogClass={this.mapData.fogClass}
            fogDiff={this.mapData.fogDiff}
            fogIndentX={this.mapData.fogIndentX}
            fogIndentY={this.mapData.fogIndentY}
          />
        )
      })
    })
  }

  toggleOverlay(indy) {
    let newState = {...this.state}
    newState.overlays[indy].enabled = !newState.overlays[indy].enabled
    this.setState(newState);
  }

  render() {
    return (
      <div className="map">
        <h1>
          <Titleize word={this.props.map}/> Map
        </h1>

        {
          this.state.overlays &&
          <div className="overlay-list">
            {
              this.state.overlays.map((overlay, indy) => {
                return (
                  <div key={indy} onClick={() => { this.toggleOverlay(indy) }}>
                    <input type="checkbox" checked={overlay.enabled} readOnly={true}/>
                    {overlay.title}
                  </div>
                )
              })
            }
          </div>
        }

        <div
          className={`map-container ${this.state.fullScreen ? "full-screen" : ""}`}
          style={{
            transform: `rotate(${this.state.rotate}deg)`
          }}
        >
          <MapInteractionCSS
            value={this.state.value}
            onChange={(value, translation) => {
              if(!this.state.locked) {
                this.setState({value});
              }
            }}
          >
            {
              this.state.measure &&
              <Measure
                scale={this.state.value.scale}
                translation={this.state.value.translation}
                measure={this.state.measure}
                measureRotation={this.state.measureRotation}
                measureMod={this.mapData.measureMod}
              />
            }
            {
              this.mapData.fog &&
              this.gridIt()
            }
            <DriveImage
              driveId={this.mapData.driveId}
              src={this.mapData.src}
              alt="map"
            />

            {
              (this.state.overlays || []).filter((el) => { return el.enabled }).map((overlay, indy) => {
                return (
                  <DriveImage
                    key={indy}
                    alt={overlay.name}
                    className="overlay"
                    src={overlay.src}
                    driveId={overlay.driveId}
                  />
                )
              })
            }
            {
              this.allowedPoints().map((point, indy) => {
                if(point.src) {
                  return (
                    <img
                      key={indy}
                      style={{
                        top: point.top,
                        left: point.left
                      }}
                     src={point.src}
                     className="map-image"
                     alt="map"
                    />
                  )
                }
                else {
                  return (
                    <div
                      onMouseEnter={(e) => {this.setState({hovering: indy})}}
                      onMouseLeave={(e) => {this.setState({hovering: ""})}}
                      onTouchStart={(e) => {
                        this.setState({hovering: this.state.hovering === indy ? "" : indy})
                      }}
                      key={indy}
                      alt="map"
                      className={`marker ${point.larger ? "larger" : ""} ${point.klass}`}
                      style={{
                        top: point.top,
                        left: point.left,
                        backgroundColor: point.color,
                        borderColor: point.border
                      }}
                    >
                      {
                        this.state.hovering === indy &&
                        <span
                          style={{
                            backgroundColor: point.spanColor || point.color,
                            color: point.textColor ||  point.border
                          }}
                        >
                          {point.label}
                        </span>
                      }

                    </div>
                  )
                }
              })
            }

          </MapInteractionCSS>
        </div>
      </div>
    )
  }
}

export default Map;
