import React from "react";

import CritTotal from "./CritTotal"

class TotalRow extends React.Component {
  render() {
    return (
      <tr>
        <td>
          Nat {this.props.kind}
        </td>
        {
          this.props.characters.map((character, indy) => {
            return (
              <td key={indy}>
                <CritTotal character={character} kind={this.props.kind}/>
              </td>
            )
          })
        }
        <td>
          <CritTotal character={"all"} kind={this.props.kind}/>
        </td>
      </tr>
    )
  }
}

export default TotalRow;
