import eventData               from "../../data/events";

function Timeline(props) {
  // const allowedRecords = (key) => {
  //   return Object.keys(eventData[key]).filter((eventKey) => {
  //     return eventData[key][eventKey].enabled
  //   });
  // }

  const filteredRecords = (key) => {
    return Object.keys(eventData[key]).filter((eventKey) => {
      return eventData[key][eventKey].enabled
    });
  }

  const yep = 23

  const yearMath = (year) => {
    let res = parseInt(year) + yep

    if(res >= 0) {
      res += 1
    }

    return res
  }

  const yearCalculation = (year) => {
    if(year[0] !== "-" || parseInt(year[0])) {
      return year
    }


    const rangeCheck = /(?<start>-\d+) - (?<end>-\d+)/.exec(year)

    if(rangeCheck) {
      const start = rangeCheck.groups.start
      const end   = rangeCheck.groups.end

      return `${yearMath(start)} - ${yearMath(end)}`
    }

    const yeared = yearMath(year)

    if(yeared) {
      return yeared
    }
    else {
      return year
    }
  }

  return (
    <div className="timeline-container">
      <div className="timeline-event">
        <div className="col1">
          <h3>
            Year
          </h3>
        </div>

        <div className="col2 centered">
          <h3>
            Event
          </h3>
        </div>
      </div>

      {
        filteredRecords("First World").map((eventKey, indy) => {
          const record = eventData["First World"][eventKey];

          return (
            <div className="timeline-event">
              <div className="col1">
                {yearCalculation(record.year)}
              </div>

              <div className="col2">
                {record.blurb}
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default Timeline
