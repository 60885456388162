import itemsData           from "../data/items";
import baseItemsData       from "../data/base_items";
import magicalVariantsData from "../data/magic_variants";
import weaponMakeData      from "../../src/data/weapon_makes";


class ShopGenerator {
  constructor(opts) {
    this.opts = opts || {}
  }

  types = {
    "A": "ammunition",
    "AF": "ammunition",
    "AT": "artisan's tools",
    "EM": "eldritch machine",
    "EXP": "explosive",
    "FD": "food and drink",
    "G": "adventuring gear",
    "GS": "gaming set",
    "HA": "heavy armor",
    "INS": "instrument",
    "LA": "light armor",
    "M": "melee weapon",
    "MA": "medium armor",
    "MNT": "mount",
    "MR": "master rune",
    "GV": "generic variant",
    "P": "potion",
    "R": "ranged weapon",
    "RD": "rod",
    "RG": "ring",
    "S": "shield",
    "SC": "scroll",
    "SCF": "spellcasting focus",
    "OTH": "other",
    "T": "tools",
    "TAH": "tack and harness",
    "TG": "trade good",
    "$": "treasure",
    "VEH": "vehicle (land)",
    "SHP": "vehicle (water)",
    "AIR": "vehicle (air)",
    "SPC": "vehicle (space)",
    "WD": "wand",
  };


  generate() {
    return {
      items: this.generateItems()
    }
  }

  generateItems() {
    let items = {};

    let collection = itemsData.item;

    collection = this.addBaseItems(collection);
    this.magicalVariantsData = magicalVariantsData;

    collection = this.addMagicalVariants(collection);

    if(this.opts.typeCheckedValue === "SCF") {
      collection = this.addFocuses(collection);
    }


    collection = this.notByField(collection, "type", "$");
    collection = this.notByField(collection, "type", "AIR");
    collection = this.notByField(collection, "type", "SPC");
    collection = this.notByField(collection, "type", "EXP");
    collection = this.notByField(collection, "age", "renaissance");
    collection = this.notByField(collection, "age", "modern");
    collection = this.notByField(collection, "age", "futuristic");

    // if (this.opts.itemType) {
    //   collection = this.byField(collection, "type", this.opts.itemType);
    // }

    if (this.opts.typeChecked === "standard") {
      collection = this.notByField(collection, "weapon", true);
    }
    else {
      collection = this.byField(collection, this.opts.typeChecked, this.opts.typeCheckedValue);
    }

    collection = collection.sort((a,b) => {return a.name > b.name ? 1 : -1})

    this.baseItemsData = baseItemsData;

    // if (this.opts.itemFilter) {
    //   collection = this.byField(collection, "type", this.opts.itemFilter);
    // }

    ["none", "common", "uncommon", "rare", "very rare", "legendary", "artifact"].forEach((rarity) => {
      const rarityCount = parseInt(this.opts[rarity]);

      items[rarity] = this.sampleRarity(collection, rarity, rarityCount);
    })

    this.weaponMakeData = weaponMakeData;
    collection = collection.map((item) => {
      item.make = this.madeBy().name;
      return item;
    });
debugger

    return items;
  }

  addBaseItems(collection) {
    let newCollection = collection.map((item) => {

      if(item.baseItem) {
        const baseItemName = item.baseItem.split("|")[0];

        const foundBase = baseItemsData.baseitem.find((el) => {
          return el.name.toLowerCase() === baseItemName;
        });

        item.basedItem = foundBase;
      }

      return item;
    });

    newCollection = newCollection.concat(baseItemsData.baseitem)

    return newCollection;
  }

  madeBy() {
    return weaponMakeData[this.sample(Object.keys(weaponMakeData))];
  }

  addFocuses(collection) {
    return collection.map((item) => {
      if(!!item.focus) {
        item.type = "SCF";
      }

      return item;
    });
  }

  addMagicalVariants(collection) {
    this.magicalVariantsData.magicvariant.forEach((el, indy) => {
      let variantCollection = [];

      if(el.requires) {
        el.requires.forEach((requirement) => {
          const key = Object.keys(requirement)[0];
          const cloned = Array.from(this.byField(baseItemsData.baseitem, key, requirement[key]));
          variantCollection = variantCollection.concat(cloned);
        });
      }

      if(el.exclude) {
        Object.keys(el.exclude).forEach((keyed) => {
          const exclusion = el.exclude[keyed];
          const key = Object.keys(exclusion)[0];

          if(Array.isArray(exclusion[key])) {
            exclusion[key].forEach((subExclusion) => {
              variantCollection = this.notByField(variantCollection, key, subExclusion);
            })
          }
          else {
            variantCollection = this.notByField(variantCollection, key, exclusion[key]);
          }
        });
      }

      variantCollection.forEach((variant) => {
        let newThang = Object.assign({}, variant);
        if(el.inherits.namePrefix) {
          newThang.name = `${el.inherits.namePrefix} ${newThang.name}`;
        }
        if(el.inherits.nameSuffix) {
          newThang.name = `${newThang.name} ${el.inherits.nameSuffix}`;
        }


        ["rarity", "source", "renaissance", "modern"].forEach((field) => {
          if(el.inherits[field]) {
            newThang[field] = el.inherits[field];
          }
        });

        const isStaff = variant.name === "Staff" || variant.name === "Wooden Staff"

        if(isStaff) {
          newThang.staff = true;
        }

        if(this.opts.typeCheckedValue === "SCF"){
          if(isStaff || variant.name === "Wand" || variant.name === "Rod") {
            variant.type = "SCF"; // make more performant
            newThang.type = "SCF";
          }
        }
        else if(this.opts.typeCheckedValue === "WD" && variant.name === "Wand") {
          variant.type = "WD"; // make more performant
          newThang.type = "WD";
        }
        else if(this.opts.typeCheckedValue === "RD" && variant.name === "Rod") {
          variant.type = "RD"; // make more performant
          newThang.type = "RD";
        }

        collection.push(newThang);
      })
    });

    return collection;
  }

  sampleRarity(collection, rarity, num) {
    const newCollection = this.byField(collection, "rarity", rarity, true);
    return this.sampling(newCollection, num).filter(Boolean);
  }

  sampling(collection, num) {
    return [...Array(num)].map(() => {
      return this.sample(collection) ;
    });
  }

  sample(collection) {
    return collection[Math.floor(Math.random()*collection.length)];
  }

  byField(collection, field, value, ignoreBase) {
    return collection.filter((e) => {
      if(!ignoreBase && e.basedItem) {
        if(e.basedItem[field] === value) {
          return true;
        }
      }

      return e[field] === value;
    });
  }

  notByField(collection, field, value) {
    return collection.filter((e) => {
      return e[field] !== value;
    });
  }
}

export default ShopGenerator;
