const deathsData = {
  3: {
    Sass: [
      "Took all the hits from King Blargh, the wolf Ripper, and Goblins for party. Blargh's morning star took him down. Healed by Cecil.",
    ]
  },
  8: {
    Sass: [
      "Failed on Banshee Wail. Two Death saves and a failed medicine check by Sparka, was saved by Lars making deal with the Champion of Ravens",
      "Took full force of poison breath from Venomfang. Healed by Cecil.",
    ],
    Corpo: [
      "Failed on Banshee Wail. Perfect self-stablilization...don't need no man to help me",
    ],
    Cecil: [
      "Failed on Banshee Wail. Perfect self-stablilization...subconsiously humming music in own ears clearly",
    ]
  },
  14: {
    Sass: [
      "Thopleth yells at Bugbear'Eye for and Eye' NAT 20 Bugbear flings Sass' left eye gets flung across cavern, he takes two death saves and falls down the hole. Healed by Cecil",
      "Bugbear NAT 20 to Sass, knocks him down on the roof...flops over. Healed by Cecil.",
      "Hit in face by Bugbear despite yelling 'Not my face!'. Healed by Cecil",
    ],
    Corpo: [
      "Iron Shadow takes him out, Healed in Sigil due to payment tied to the gem."
    ]
  },
  20: {
    Corpo: [
      "Blago smacks him up with his golden mace. Healed by Cecil."
    ]
  },
  22: {
    Lars: [
      "Dropped by Ogre after he killed Hadrian. Healed by Cecil",
      "Clubbed back unconscious by Ogre without getting up. Healed by Cecil",
      "Crushed down by Ogre right back unconscious without getitng up. Given potion by Sparka"
    ],
    Corpo: [
      "Drops when castle creashes. Up by NAT 20 on Death Save ARE YOU SERIOUS"
    ]
  },
  26: {
    Sass: [
      "Dropped by Fireball in front of the Sanitorium...fails one death save, another from Cone of Cold. Healed by Cecil."
    ]
  },
  33: {
    Sass: [
      "Dropped by Cherufe punch to the face..."
    ],
    Lars: [
      "Dropped by Cherufe punch to the face. Healed by Corpo with a potion"
    ]
  },
  34: {
    Sass: [
      "Pulled into the Lava by Hadrian...healed by Cecil with Healing Word"
    ],
  },
  35: {
    Corpo: [
      "Pulled into the Lava by Hadrian...healed by Sass with a Potion"
    ],
    Cecil: [
      "Dropped by Fire Giant...healed by Lars with potion taken from his belt"
    ],
  }
}

export default deathsData
