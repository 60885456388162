import React from "react";

import measuresData from "../../data/measures";

class Measure extends React.Component {

  typed() {
    this.measuresData = measuresData
    let data = measuresData[this.props.measure].default;

    if(this.props.measureMod) {
      data = {...data, ...measuresData[this.props.measure][this.props.measureMod]}
    }

    return data;
  }

  move(e) {
    const el = document.getElementById("measure");

    const typed = this.typed();

    if(!el) { return }

    let modifier = 0;

    if(this.props.scale > 1.45) {
      modifier = (((1 - this.props.scale)) * 100) - 100;
    }
    else if(this.props.scale > 1.30) {
      modifier = -120;
    }
    else if(this.props.scale > 1.20) {
      modifier = -80;
    }
    else if(this.props.scale > 1.10) {
      modifier = -40;
    }
    else if(this.props.scale < 0.68) {
      modifier = ((0.73 - this.props.scale) / 0.1) * 150 + 100;
    }
    else if(this.props.scale < 0.73) {
      modifier = 280;
    }
    else if(this.props.scale < 0.83) {
      modifier = 160;
    }
    else if(this.props.scale < 0.93) {
      modifier = 80;
    }


    let heightModifier = typed.height;
    let widthModifier = typed.width;

    if(!heightModifier || heightModifier === 0) {
      heightModifier = parseInt(typed.borderLeft) * 2;
    }

    if(!widthModifier || widthModifier === 0) {
      widthModifier = parseInt(typed.borderLeft) * 2;
    }



    el.style.top = `${((e.screenY  - (heightModifier / 2) - 108  - this.props.translation.y) / this.props.scale) + modifier }px`;
    el.style.left = `${((e.screenX - (widthModifier / 2) - 4 - this.props.translation.x) / this.props.scale) + modifier }px`;
  }

  render() {
    document.addEventListener('mousemove', this.move.bind(this))

    let modStyle = {...this.typed(), transform: `rotate(${this.props.measureRotation}deg)`};

    return (
      <div
        id="measure"
        style={modStyle}
      >

        .
      </div>
    )
  }

}



export default Measure;
