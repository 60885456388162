import eventData         from "../data/events";
import factionsData      from "../data/factions";
import locationsData     from "../data/locations";
import characterData     from "../data/characters";
import mapsData          from "../data/maps";
import shopData          from "../data/shops";
import articleData          from "../data/articles";

class ItemFetcher {
  constructor(key) {
    this.key = key;
  }

  fetch(){
    return eventData.Toril[this.key] ||
    eventData["First World"][this.key] ||
    this.locationCheck(this.key) ||
    factionsData.encountered[this.key] ||
    factionsData.notMet[this.key] ||
    this.primaveraPoints(this.key) ||
    this.shopCheck(this.key) ||
    this.articleCheck(this.key) ||
    this.setCharacter();
  }

  primaveraPoints(keyed) {
    return mapsData["First World"].Primavera.points.filter((point) => {
      return point.label.toLowerCase() === keyed.toLowerCase();
    })[0];
  }

  locationCheck() {
    return locationsData.Toril.visited[this.key] ||
           locationsData.Toril.other[this.key] ||
           locationsData["First World"].visited[this.key] ||
           locationsData["First World"].other[this.key];
  }

  articleCheck() {
    return articleData.Toril[this.key] ||
           articleData.Toril[this.key] ||
           articleData["First World"][this.key] ||
           articleData["First World"][this.key];
  }

  shopCheck(keyed) {
    return shopData.filter((shop) => {
      return shop.name.toLowerCase() === keyed.toLowerCase();
    })[0];
  }

  setCharacter() {
    let found;

    let checkit = function(key) {
      if(characterData.npcs[key][this.key]) {
        found = characterData.npcs[key][this.key];
      }
    }

    checkit = checkit.bind(this);

    if(!found) {
      Object.keys(characterData.npcs).forEach(checkit);
    }

    return found;
  }

  shopsFromParent() {
    return shopData.filter((shop) => {
      return shop.parentLocation === this.key;
    });
  }
}

export default ItemFetcher;
