import React                 from "react";
import { Route, Redirect }   from "react-router-dom";
import Cookies               from 'universal-cookie';

// import { API_ROOT, HEADERS } from "../constants/";

class Authenticate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {redoPage: false}
    this.cookies = new Cookies();
  }

  authenticated() {
    return !!this.cookies.get('userToken');
  }

  sendIt(user, pass) {
    // return fetch(`${API_ROOT}/authenticate/`, {
    //   method: 'POST',
    //   headers: HEADERS,
    //   body: JSON.stringify({
    //     user: user,
    //     password: pass
    //   })
    // })
    return {
      Robert: '$muckfoash',
      Mike: '$kaladin',
      Ryan: '$brawndo',
      Ben: '1muckfoash',
      GM: '$splittheparty',
      Adam: '.muckfoash'
    }[user] === pass
  }

  tryAuth(event) {
    const nameInput = event.currentTarget.parentElement.parentElement.children[0].children[1].value,
          passInput = event.currentTarget.parentElement.parentElement.children[2].children[1].value;

    let token;

    if(nameInput === "test" && passInput === "test") {
      token = "test";

      this.cookies.set("userToken", token, { path: '/' });
      this.setState({redoPage: true});
    }
    else {
      // this.sendIt(nameInput, passInput).then((res) => {
      //   if(res.ok) {
      //     res.json().then((res) => {
      //       token = res.auth_token;
      //       this.cookies.set("userToken", token, { path: '/' });

      //       if(nameInput === "GM") {
      //         this.cookies.set("toBeAMaster", token, { path: '/' });
      //       }

      //       this.setState({redoPage: true});
      //     });
      //   }
      //   else {
      //     alert("Bad Username and/or Password");
      //   }
      // });
      const res = this.sendIt(nameInput, passInput);

      if (res) {
        this.cookies.set("userToken", "toke", { path: '/' });

        if(nameInput === "GM") {
          this.cookies.set("toBeAMaster", "toke", { path: '/' });
        }

        this.setState({redoPage: true});
      }
      else {
        alert("Bad Username and/or Password");
      }
    }
  }

  render() {
    return (
      <>
        <Route
          path="/signout"
          render={() => {
            this.cookies.remove("userToken");
            return (
              <Redirect to="/"/>
            )
          }}
        />

        <Route
          path="/"
          render={() => {
            if(this.authenticated() || this.state.redoPage) {
              return(
                <>
                  {this.props.children}
                </>
              )
            }
            return (
              <>
                {
                  (this.authenticated() || this.state.redoPage) &&
                  this.props.children
                }
                {
                  !this.authenticated() &&
                  <>
                    <div className="standard-panel">
                      <div>
                        <h1>
                          Authentication
                        </h1>
                      </div>

                      <div className="internal-panel">
                        <div className="login-box">
                          <div >
                            <label htmlFor="username"> Player: </label>
                            <input id="username" name="username"/>
                          </div>

                          <br/>

                          <div>
                            <label htmlFor="password"> Password: </label>
                            <input type="password" id="password" name="password"/>
                          </div>
                          <br/>
                          <div className="auth-button-container">
                            <button className="auth-button" href="#" onClick={this.tryAuth.bind(this)}>
                              Authenticate
                            </button>
                          </div>
                        </div>

                        <br/>
                      </div>
                    </div>
                  </>
                }
              </>
            )
          }}
        />
      </>

    )
  }
}

export default Authenticate;
