import React                 from "react";
import { Link }              from "react-router-dom";
import Cookies               from 'universal-cookie';

import { API_ROOT, HEADERS } from "../../constants/";

import characterData         from "../../data/characters";

import CharacterList         from "./CharacterList";
import Collapsible           from "../shared/Collapsible"


class Characters extends React.Component {
  constructor(props) {
    super(props);

    this.cookies = new Cookies();

    this.state = {pcCharacters: {}}
  }

  componentDidMount() {
    this.getCharacters();
  };

  collapseToggle(kind) {
    this.state.show === kind ? this.setState({show: ""}) : this.setState({show: kind})
  }

  getCharacters() {
    fetch(
      `${API_ROOT}/characters`,
      {
        headers: HEADERS
      }
    ).then(res => res.json()
    ).then((res) => {
      this.setState({pcCharacters: res.characters})
    });
  }

  setBlock() {

  }

  render() {
    return (
      <div className="standard-panel character-listage">
        <h1>
          Characters
        </h1>
        <Link to={`/characters/edit`}>
          Edit My Character
        </Link>

        <div>
          <Collapsible
            title="Major"
            toggle={this.collapseToggle.bind(this)}
            kind="pcs"
            show={this.state.show}
          >

            <ul>
              <li onClick={() => {this.setState({show: null, subShow: "pcCharacters", subShowTitle: "The Phandalin Five"})}}>
                The Phandalin Five
              </li>

              <li onClick={() => {this.setState({show: null, subShow: "elderFigures", subShowTitle: "Elder Figures"})}}>
                Elder Figures
              </li>
            </ul>
          </Collapsible>

          <Collapsible
            title="Faerûn"
            toggle={this.collapseToggle.bind(this)}
            kind="faerun"
            show={this.state.show}
          >
            <ul>
              <li onClick={() => {this.setState({show: null, subShow: "general", subShowTitle: "NPCs"})}}>
                General NPCs
              </li>
              <li onClick={() => {this.setState({show: null, subShow: "phandalin", subShowTitle: "Phandalin"})}}>
                Phandalin
              </li>
              <li onClick={() => {this.setState({show: null, subShow: "cultOfTheDragon", subShowTitle: "Cult of the Dragon"})}}>
                Cult of the Dragon
              </li>
              <li onClick={() => {this.setState({show: null, subShow: "shroomers", subShowTitle: "Zuggtmoy Fanatics"})}}>
                Zuggtmoy Fanatics
              </li>
              <li onClick={() => {this.setState({show: null, subShow: "foes", subShowTitle: "Foes/Rivals/Questionables"})}}>
                Foes/Rivals/Questionables
              </li>
              <li onClick={() => {this.setState({show: null, subShow: "nobility", subShowTitle: "Nobility/Notables"})}}>
                Nobility/Notables
              </li>
              <li onClick={() => {this.setState({show: null, subShow: "nomads", subShowTitle: "Agents of the Nomads"})}}>
                Agents of the Nomads
              </li>
              <li onClick={() => {this.setState({show: null, subShow: "wanderingPost", subShowTitle: "Wandering Post Merchants"})}}>
                Wandering Post Merchants
              </li>
              <li onClick={() => {this.setState({show: null, subShow: "cecilBackground", subShowTitle: "Cecil Background"})}}>
                Cecil Background
              </li>
              <li onClick={() => {this.setState({show: null, subShow: "corpoBackground", subShowTitle: "Corpo Background"})}}>
                Corpo Background
              </li>
              <li onClick={() => {this.setState({show: null, subShow: "larsBackground", subShowTitle: "Lars Background"})}}>
                Lars Background
              </li>
              <li onClick={() => {this.setState({show: null, subShow: "sassBackground", subShowTitle: "Sass Background"})}}>
                Sass Background
              </li>
              <li onClick={() => {this.setState({show: null, subShow: "sparkaBackground", subShowTitle: "Sparka Background"})}}>
                Sparka Background
              </li>
              <li onClick={() => {this.setState({show: null, subShow: "circus", subShowTitle: "Circus"})}}>
                Circus
              </li>
            </ul>
          </Collapsible>

          <Collapsible
            title="First World"
            toggle={this.collapseToggle.bind(this)}
            kind="firstWorld"
            show={this.state.show}
          >
            <ul>
              <li onClick={() => {this.setState({show: null, subShow: "thon_daral_imperial", subShowTitle: "Thon Daral (Imperials)"})}}>
                Thon Daral (Imperials)
              </li>

              <li onClick={() => {this.setState({show: null, subShow: "thon_daral_city", subShowTitle: "Thon Daral (City)"})}}>
                Thon Daral (City)
              </li>

              <li onClick={() => {this.setState({show: null, subShow: "oxkadi", subShowTitle: "Oxkadi Valley"})}}>
                Oxkadi Valley
              </li>

              <li onClick={() => {this.setState({show: null, subShow: "iron_legion", subShowTitle: "Iron Legion"})}}>
                Iron legion
              </li>
            </ul>
          </Collapsible>
        </div>

        {
          !this.state.subShow &&
          <em> Please select a category from above </em>
        }

        {
          this.state.subShow && characterData.npcs[this.state.subShow] &&
          <div>
            <h2> {this.state.subShowTitle} </h2>
              <CharacterList characters={characterData.npcs[this.state.subShow]}/>
          </div>
        }
        {
          this.state.subShow === "pcCharacters" &&
          <div>
            <h2> The Phandalin Five </h2>
            <CharacterList characters={this.state.pcCharacters} title={"PCs"}/>
          </div>
        }
      </div>
    )
  }
}

export default Characters;
