import React from 'react';

class Titleize extends React.Component {
  render() {
    return (
      <span>
        {this.props.word && this.props.word.charAt(0).toUpperCase() + this.props.word.slice(1)}
      </span>
    )
  }
}

export default Titleize;
