import React from "react";

import StiernanLetterTableRow from "./StiernanLetterTableRow"

class StiernanLetterTable extends React.Component {
  static get langTable() {
    return {
      a: {
        letter: "a",
        style: {
          "margin-top": "-59px",
        }
      },
      e: {
        letter: "e",
        style: {
          "margin-top": "-59px",
          "margin-left": "-51px"
        }
      },
      i: {
        letter: "i",
        style: {
          "margin-top": "-58px",
          "margin-left": "-108px"
        }
      },
      o: {
        letter: "o",
        style: {
          "margin-top": "-57px",
          "margin-left": "-159px"
        }
      },
      u: {
        letter: "u",
        style: {
          "margin-top": "-58px",
          "margin-left": "-212px"
        }
      },
      ae: {
        letter: "ae",
        style: {
          "margin-top": "-57px",
          "margin-left": "-265px"
        }
      },
      ea: {
        letter: "ea",
        style: {
          "margin-top": "-57px",
          "margin-left": "-319px"
        }
      },
      oe: {
        letter: "oe",
        style: {
          "margin-top": "-57px",
          "margin-left": "-368px"
        }
      },
      eo: {
        letter: "eo",
        style: {
          "margin-top": "-167px",
          "margin-left": "-368px"
        }
      },
      y: {
        letter: "y",
        style: {
          "margin-top": "-57px",
          "margin-left": "-422px"
        }
      },
      f: {
        letter: "f",
        style: {
          "margin-top": "-57px",
          "margin-left": "-478px"
        }
      },
      r: {
        letter: "r",
        style: {
          "margin-top": "-57px",
          "margin-left": "-535px"
        }
      },
      d: {
        letter: "d",
        style: {
          "margin-top": "-57px",
          "margin-left": "-594px"
        }
      },
      g: {
        letter: "g",
        style: {
          "margin-top": "-57px",
          "margin-left": "-650px"
        }
      },
      k: {
        letter: "k",
        style: {
          "margin-top": "-57px",
          "margin-left": "-708px"
        }
      },
      w: {
        letter: "w",
        style: {
          "margin-top": "-57px",
          "margin-left": "-761px"
        }
      },
      n: {
        letter: "n",
        style: {
          "margin-top": "-57px",
          "margin-left": "-816px"
        }
      },
      h: {
        letter: "h",
        style: {
          "margin-top": "-57px",
          "margin-left": "-869px"
        }
      },
      j: {
        letter: "j",
        style: {
          "margin-top": "-57px",
          "margin-left": "-922px"
        }
      },
      p: {
        letter: "p",
        style: {
          "margin-top": "-57px",
          "margin-left": "-980px"
        }
      },
      b: {
        letter: "b",
        style: {
          "margin-top": "-57px",
          "margin-left": "-1035px"
        }
      },
      q: {
        letter: "q",
        style: {
          "margin-top": "-57px",
          "margin-left": "-1091px"
        }
      },
      s: {
        letter: "s",
        style: {
          "margin-top": "-57px",
          "margin-left": "-1145px"
        }
      },
      t: {
        letter: "t",
        style: {
          "margin-top": "-57px",
          "margin-left": "-1199px"
        }
      },
      st: {
        letter: "st",
        style: {
          "margin-top": "-57px",
          "margin-left": "-1250px"
        }
      },
      þ: {
        letter: "þ",
        style: {
          "margin-top": "-57px",
          "margin-left": "-1303px"
        }
      },
      m: {
        letter: "m",
        style: {
          "margin-top": "-57px",
          "margin-left": "-1354px"
        }
      },
      l: {
        letter: "l",
        style: {
          "margin-top": "-57px",
          "margin-left": "-1405px"
        }
      },
      ŋ: {
        letter: "ŋ",
        style: {
          "margin-top": "-57px",
          "margin-left": "-1453px"
        }
      },
      x: {
        letter: "x",
        style: {
          "margin-top": "-57px",
          "margin-left": "-1504px"
        }
      },
      z: {
        letter: "z",
        style: {
          "margin-top": "-57px",
          "margin-left": "-1562px"
        }
      },
    }
  }

  render () {
    return (
      <div className="rune-box">
        <div className="flex-row">
          <div className="letter-col">
            Letter
          </div>
          <div className="rune-col">
            Rune
          </div>
          <div className="meaning-col">
            Significance
          </div>
        </div>
        {
          Object.keys(StiernanLetterTable.langTable).map((letter, indy) => {
            let record = StiernanLetterTable.langTable[letter]
            return (
              <StiernanLetterTableRow letter={record.letter} />
            )
          })
        }

      </div>
    )
  }
}

export default StiernanLetterTable;
