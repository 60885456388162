import React from "react";

class KillTotal extends React.Component {
  totalsSearch() {
    let total = 0;

    Object.keys(this.props.data).forEach((session) => {
      Object.keys(this.props.data[session]).forEach((character) => {
        if(character === this.props.character || this.props.character === "all") {
          if(this.props.kind === "dragonKills") {
            this.props.data[session][character].forEach((kill) => {
              if(kill.match("DRAGON SLAYER")) {
                total += 1;
              }
            })
          }
          else if(this.props.kind === "giantKills") {
            this.props.data[session][character].forEach((kill) => {
              if(kill.match("GIANT SLAYER")) {
                total += 1;
              }
            })
          }
          else {
            total += this.props.data[session][character].length;
          }
        }
      });
    });

    return total;
  }

  render() {
    return (
      <>
        {this.totalsSearch()}
      </>
    )
  }
}

export default KillTotal;
