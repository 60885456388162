import React from "react";

import critData from "../../data/crits";

class Tabuleau extends React.Component {
  render() {
    return (
      <div>
        <h3>{this.props.kind}</h3>

        <table>
          <thead>
            <tr>
              <th></th>
              <th>
                Character
              </th>
              <th>
                Session
              </th>
              <th>
                Skill
              </th>
              <th>
                Description
              </th>
            </tr>
          </thead>
          <tbody>
            {
              Object.keys(critData[this.props.kind]).map((session) => {
                return (
                  <React.Fragment key={session}>
                    {
                      critData[this.props.kind][session].map((record, indy) => {
                        return (
                          <tr key={indy}>
                            <td></td>
                            <td>
                              {record.character}
                            </td>
                            <td>
                              {session}
                            </td>
                            <td>
                              {record.check}
                            </td>
                            <td>
                              {record.description}
                            </td>
                          </tr>
                        )
                      })
                    }
                  </React.Fragment>
                )
              })
            }
          </tbody>
        </table>
      </div>
    )
  }
}

export default Tabuleau;
