import React                 from "react";
import { Link }              from "react-router-dom";

import Collapsible           from "../shared/Collapsible"
import articleData            from "../../data/articles";
import Titleize              from "../shared/Titleize";


class Articles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  allowedArticles(key) {
    return Object.keys(articleData[key]).filter((articleKey) => {
      return true //articleData[key][articleKey].enabled // || (!!this.cookies.get("toBeAMaster") && articleData[key][articleKey].dmOnly);
    });
  }

  collapseToggle(kind) {
    this.state.show === kind ? this.setState({show: ""}) : this.setState({show: kind})
  }

  render() {
    return (
      <div className="standard-panel">
        <h1>
          Articles
        </h1>

        <Collapsible
          title="Toril"
          toggle={this.collapseToggle.bind(this)}
          kind="toril"
          show={this.state.show}
        >
          <ul>
            {
              this.allowedArticles("Toril").map((articleKey, indy) => {
                return (
                  <li key={indy}>
                    <Link to={`/pages/${articleKey}`}>
                      <Titleize word={articleKey} />
                  </Link>
                  </li>
                )
              })
            }
          </ul>
        </Collapsible>

        <Collapsible
          title="First World"
          toggle={this.collapseToggle.bind(this)}
          kind="first"
          show={this.state.show}
        >
          <ul>
            {
              this.allowedArticles("First World").map((articleKey, indy) => {
                return (
                  <li key={indy}>
                    <Link to={`/pages/${articleKey}`}>
                      <Titleize word={articleKey} />
                    </Link>
                  </li>
                )
              })
            }
          </ul>
        </Collapsible>

      </div>
    )
  }
}

export default Articles;
