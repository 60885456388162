const imperialPurple = "#4B0082",
      kinaphalicPurple = "#9966cc",
      krateopolisRed = "#EB0033",
      gildedForest = "#FFD700",
      basicSilver = "#C0C0C0",
      platinum = "#E5E4E2",
      horseBarbs = "#00C0FF",
      ironLegion = "#535454",
      verdantDracGreen = "#0F6400",
      ashCinderPink = "#FFAAAA",
      tyrannyBlue = "#AAAAFF",
      powerfulDull = "#AAAADD",
      timocPeach = "#FFE5B4",
      ciphPurpRed = "#800042",
      burnOrange = "#CC5500",
      entropyDark = "#333333",
      dwarvenGreen = "#008800"


const locationsData = {
  Toril: {
    visited: {
      neverwinter: {
        name: "Neverwinter",
        enabled: true,
        tagLine: "City-State on Sword Coast",
        region: "Northern Sword Coast, Northwest Faerûn",
        type: "Settlement",
        size: "City",
        src: "https://static.wikia.nocookie.net/forgottenrealms/images/8/80/NeverwinterHarbor.jpg",
        blurb: "Neverwinter, also known as the City of Skilled Hands and the Jewel of the North, was a multi-racial city-state sitting on the northwestern Sword Coast of Faerûn. Neverwinter was regarded by Volo as the most cosmopolitan and the most civilized city in all of Faerûn. The city was a member in good standing of the Lords' Alliance. Neverwinter experienced disaster during the Spellplauge, with a giant Chasm opening in its south-eastern district. It was also devastated by the eruption of Mount Hotenow in 1451 DR, that even the royal family perished. Since 1467 DR, Lord Dagult Neverember has embarked on a rebuilding campaign. It has been fairly successful, but the locals still are hesitant to accept someone not from the royal family as their ruler. Currently Neverememer also is serving as Open Lord of Waterdeep leaving day-to-day operations in the hands of General Sabine and Mayor Soman Galt. This is yet another point of contention with the citizens of the city. In 1485 DR, Neverember and the city paid to have the Chasm filled in, which gained favor with residents, but the cost emptied much of the city's coffers, leaving walls and neighborhoods in need of repair. However, few can deny that Lord Neverember rebuilding the city and outlying areas has increased Neverwinter's political influence in the Sword Coast.",
        // https://www.belloflostsouls.net/2020/06/dd-an-adventurers-guide-to-neverwinter.html
        recentEvents: `
          <h4>Session 1</h4> The party was hired here by Gundren Rockseeker to escort Wildon Fiddlestump to Phandalin before Gundren left with Sildar Hallwinter.
          <h4>Session 7</h4> Maise Summerbluff told Sparka Redlake that she was going to Neverwinter to study with Phineas Stoneblood, who might know about his arcane focus
        `
      },

      phandalin: {
        name: "Phandalin",
        enabled: true,
        tagLine: "Resettled frontier settlement",
        type: "Settlement",
        size: "Village",
        region: "Northern Sword Coast, Northwest Faerûn",
        src: "https://calikatrathewarlock.files.wordpress.com/2020/02/371d005225a665a747017d42b423ef9c.jpg",
        blurb: "Phandalin was a mainly human village located northeast of Leilon along the Sword Coast, where the road that ran from the High Road to Triboar faded into a trail. It was raided by orcs in 951 DR and subsequently abandoned. Settlers from Neverwinter and Waterdeep began resettling the town in the 15th Century DR, and by the 1480, it attracted farmers and prospectors looking for wealth in the Sword Mountains.",
        recentEvents: `
          <h4>Session 3</h4> PCs arrive in Phandalin to find it under threat from local Redbrands. The party meets some locals as they realize the bandits are too dangerous to ignore, they will find Gundren next.
          <h4>Session 4</h4> Wildon Fiddlestump is captured by Redbrands while a gang went to find the PCs in the Stonehill Inn. Players enter tavern fight with Redbrands, kill all but one named Tris. They interrogate her, sleep for the night, then with support of locals, go undercover towards Tresendar Manor. Sparka burned down the Sleeping Giant Inn with thugs in it, after checking that no one owned it.
          <h4>Session 5</h4> Party assualts the Redbrands hideout under Tresendar Manor.
          <h4>Session 6</h4> Party emerges from the hideout to trade barbs with Harbin Wester, while townsfolk put out the fires.
          <h4>Session 7</h4> The Phandalin Five insert themselves into local politics by backing Qeline Alderleaf over Harbin. Harbin tries to scheme to destroy their credibility, but they defeat Dragonborn in an honor duel, then Sparka and Thistle Brathen frame Harbin. He is then arrested.
        `
      },

      angel_acres: {
        name: "Angel Acres",
        enabled: true,
        tagLine: "Small Frontier Farming Village",
        type: "Settlement",
        size: "Village",
        region: "Northern Sword Coast, Northwest Faerûn",
        src: "https://i.redd.it/w4vairyt9ca01.png",
        blurb: "A small settlement of farmers around an unusually rich ground north of the Triboar Trail. It was funded by Paladins of Bahamut to tame the wilderness and to serve as a safe stopover on the Frontier. The locally economy drives from travelers, relic hunters, and apothecaries hunting for rare herbs. ",
        recentEvents: `
          <h4>Session 8</h4> The Phandalin Five stop by on their way to the nearby Ruins where a Necromancer was spotted. They meet Lorc the Orc at the bar, Lars meets Ole, then they have dinner at the house of Zilestor the Cheerful. They learn about Hamun Kost before moving on.
        `
      },

      faerun: {
        name: "Faerûn",
        enabled: true,
        tagLine: "Major Continent on Toril",
        region: "Toril",
        type: "Continent",
        src: "https://static.wikia.nocookie.net/forgottenrealms/images/3/36/Sword-Coast-Map_HighRes-Compressed.jpg",
        blurb: "Major continent including the Sword Coast, Sea of Stars, High Ice, Chult, and the Unapproachable East",
        recentEvents: "<h4>Session 0 - ?</h4>The Heroes moved across the land and sea to gather in Neverwinter before starting this journey."
      },

      thundertree: {
        name: "Thundertree",
        enabled: true,
        tagLine: "Ruined Village",
        region: "Northern Sword Coast, Northwest Faerûn",
        type: "Ruin",
        src: "https://pbs.twimg.com/media/EYbDmtFXsAAehPj.jpg",
        blurb: "City on edge of the Neverwinter Wood that was leveled by the eruption of Mount Hotenow.",
        recentEvents: `
          <h4>Session 5</h4> The party is told of a lost heirloom by Mirna in Thundertree, she says that thye can have it in thanks for saving her.
          <h4>Session 7</h4> Party is headed there to look for Reidoth the Druid who might know the location of Cragmaw Castle and Wave Echo Cave.
          <h4>Session 8</h4> The Phandalin Five arrive, notice that there is a dragon in town due to the dragon sensing dagger. They meet Reidoth and make a deal him to kill Venomfang in exchange for the location of Wave Echo Cave.
          <br/><br/> They approach and then slay Venomfang
          <h4>Session 9</h4> The Phandalin Five are assaulted by Bamur and his shroom zombies while they defend the collapsing horde magic.
        `
      },

      diemondar_ruins: {
        name: "Diemondar Ruins",
        enabled: true,
        tagLine: "Ancient Ruins",
        region: "Northern Sword Coast, Northwest Faerûn",
        type: "Ruin",
        src: "https://64.media.tumblr.com/c059723c1e5196122b8aed6220f3cf4a/e72fe3096ab6c167-d4/s640x960/8cf288d1c4ae3674547e4397321f84322d28a086.gifv",
        blurb: "Ruins of an ancient civilization long destoryed, containing magical secrets.",
        recentEvents: `
          <h4>Session 7</h4> The Phandalin Five are told about a dangerous Necromancer, a Red Wizard of Thay (Hamun Kost) who is near them attempting to get in. Corpo Fugit was told that getting in is a priority for the Harpers.
          <h4>Session 8</h4> The party learns more about Hamun Kost before arriving. Hamun is attempting to get into the ruin, and needs the name of house that built the ruin...Corpo knows that it is House Diemondar, which Sparka knows were an ancient family of Elves that allies closely with the Netherese Humans.
          <br/><br/>They skirmish with zombies, then reject parley with a javelin. They kill Hamun, then slay the zombies. Corpo uses the name of House Diemondar to enter the first gate, but the second requires the Motto of House Diemondar which he is tasked with finding out.
          <h4>Session 10</h4> The Phandalin Five enter it after Sparka's blood opens the blood lock, showing that he has the Blood of House Diemondar.
          <br/><br/> They explore the ruins with technology greater than they could imagine. They avoid the Clay Golem to discover the Reality Crystals and the Sparka's alternate timeline message.
        `
      },

      wave_echo: {
        name: "Wave Echo Cave",
        enabled: true,
        tagLine: "Mine of Great Power",
        type: "Cavern/Mine",
        region: "Northern Sword Coast, Northwest Faerûn",
        src: "https://4.bp.blogspot.com/-zlpVx63Ga18/WtTxX_7mqGI/AAAAAAAAEbI/xMr-ZmWnsH8qANdiwjAuHBKDHDe_dhFQwCLcBGAs/s400/cave_by_nele_diel-d655qw5.jpg",
        blurb: `
          Dwarves and Gnomes discovered this great cavern for mining, but since both races found it at the same time, they made the Pact of Phandelver to share it's riches. While a great find, the ore was not all they found underground.
          <br/><br/>
          They discovered a powerful magic force running through the passages, raw power. The miners allied with Human wizards, and together harnessed this power into what they called the Forge of Spells. This Forge allowed them to create magical items, enriching the entire area, especially the nearby human town of Phandalin.
        `,
        recentEvents: `
          <h4>Session 2</h4> PC's discover that goblins who took Gundren Rockseeker were told to take him alive and find a map on him.
          <h4>Session 3</h4> Sildar Hallwinter tells the PCs that Gundren and his brothers think that they found Wave Echo Cave, and others surely want this location for themselves.
          <h4>Session 12</h4> The Phandalin Five enter the cave, find Tharden Rockseeker dead, then get attacked by Stirges but slay some Minotaur Skeletons.
          <h4>Session 13</h4> The Phandalin Five defeat a Wraith and find various treasures including the Scroll of Opening.
          <br/><br/> They convince the Spectators to leave for 24 hours, and find the Forge of Spells. The magic is weak, but can be fixed possibly.
          <br/><br/> They discover why the water is not flowing, an earthquake somewhere blocked the water. They then defeat a Flameskull guarding the bellows, and a horde of Ghasts before retreating to the Wraith's lair that Sparka put an Arcane Lock on the door.
        `
      },

      cragmaw: {
        name: "Cragmaw Castle",
        enabled: true,
        tagLine: "Small Harbor Town North of Neverwinter",
        region: "Neverwinter Wood, Sword Coast North",
        type: "Castle",
        src: "https://i.pinimg.com/originals/46/3e/1c/463e1c47107e485457b481fbf7e76a81.jpg",
        blurb: `
          Old castle originally built by a noble wizard, since abandoned and location unknown to civilization. Apparently it has since been claimed by goblinoids.
        `,
        recentEvents:
          `
            <h4>Session 2</h4>The party learned that King Blargh was a vassal of King Grol in Cragmaw Castle
            <h4>Session 3</h4>Sildar informed the party that the Goblins took Sildar's gear, along with Gundren Rockseeker and a map to Cragmaw Castle and King Grol as ordered by The Black Spider.
            <h4>Session 8</h4> The Phandalin Five learn that there is a sort of auction to sell Gundren and his map off in Cragmaw Castle, with multiple groups attempting to purchase.
            <h4>Session 10</h4> The Phandalin Five make a deal with Avlod that he will grant help to get into the castle in exchange for killing as few Hobgoblins as possible
            <h4>Session 11</h4> With part of the party joining the Circus...the other two undercover as Zhentarim agents...well...just insanity
            <h4>Session 12</h4> Avlod leaves Hobgoblin Waarario in charge as he leaves for back home.
          `
      },

    },

    other: {
      felbarr: {
        name: "Citadel Felbarr",
        enabled: true,
        tagLine: "Strong Dwarven Stronghold in the North",
        region: "Silver Marches, northwest Faerûn",
        type: "Settlement",
        size: "Small City",
        src: "https://db4sgowjqfwig.cloudfront.net/campaigns/167898/assets/764147/a845f31eaed8940fd3f029b8c37ff611.jpg?1501648672",
        blurb: "One of the main Dwarfholds of the North. It has fallen to the Orcs, yet retaken by Dwarves multiple times in its history.",
        recentEvents: `
          <h4>Session 0</h4>Lars Rutschignass was exiled from here for the implied poisoning of the king.
        `,
        subLocations: [
          {
            name: "The Hammer",
            blurb: "The first gate, comprising two 40-foot-high stone gates, on the path to The Runegate."
          },
          {
            name: "The Anvil",
            blurb: "The second gate, comprising two 30-foot-high stone gates, on the path to the Runegate, also where the path crossed the river."
          },
          {
            name: "The Runegate",
            blurb: "A pair of stone doors each 20-foot-square and 3-feet-thick. Covered in 32 deadly runes, these gates were considered to be the greatest accomplishment of the dwarves in the reoccupation of Citadel Felbarr."
          },
          {
            name: "North Vigil",
            blurb: "A defensive area overlooking the path to the Runegate, complete with heavy catapults and ballistae. Competed before the winter of 1372 DR."
          },
          {
            name: "South Vigil",
            blurb: "A defensive area overlooking the path to the Runegate, complete with heavy catapults and ballistae."
          },
          {
            name: "Hall of Ceremony",
            blurb: "A high cavern, part natural and part carved, with a large fireplace and the king's throne on a raised dais."
          },
          {
            name: "Cemetery",
            blurb: "The most revered part of the citadel. It was where Clan Warcrown was buried. It was always under construction for expansion. The oldest chamber was large with many tunnels and smaller rooms."
          }
        ]
      },

      grimstone: {
        name: "Grimstone Peak",
        enabled: true,
        tagLine: "Ruined Dwarfhold home of Lars Rutschignass",
        region: "Silver Marches, northwest Faerûn",
        type: "Ruin",
        src: "https://i.pinimg.com/originals/f7/cb/9b/f7cb9b836f77ecd3e732d26674566e8c.jpg",
        blurb: "Dwarfhold officially destroyed by mining accident when Lars Rutschignass was young, resulting in him with the twins Bamur and Ole (Balor) being resettled in Citadel Felbarr.",
        recentEvents: `
          <h4>Session 3</h4>As part of his ploy to gain cooperation from Lars Rutschignass, the Mysterious Gold Elf showed him a piece of stone work from his home.
          <h4>Session 8</h4>Lars meets Ole, and Ole says that he had to abandon his studies and volunteer for dangerous experiment to attempt to regain the honor of Grimestone after the lies about Lars and the path of evil that Bamur took.
        `
      },

      helms_hold: {
        name: "Helm's Hold",
        enabled: true,
        tagLine: "Fortified city and cathedral dedicated to Helm",
        region: "Northern Sword Coast, Northwest Faerûn",
        type: "Settlement",
        size: "Small City",
        src: "https://static.wikia.nocookie.net/forgottenrealms/images/6/69/Helms_hold.jpg",
        blurb: "The settlement centered around the Clerics and Paladins of dedicated to Helm. It is ruled by an elected City Council of Speakers, with the Chief Speaker leading them. The paladins served as a de facto local defenses and police. Lord Dagult Neverember's rebuilding in the area benefitted Helm's Hold, but also involved him sending mercenaries to try and enforce his will. Very recently, the brutal Order of the Gilded Eye made their headquarters here. They split with the Order of the Gauntlet by rejecting the ban on attacking without provocation.",
        recentEvents: ""
      },

      leilon: {
        name: "Leilon",
        enabled: true,
        tagLine: "Small Mining Town and Resting Place",
        region: "Sword Coast North, Northwest Faerûn",
        type: "Settlement",
        size: "Small Town",
        src: "https://inkarnate-api-as-production.s3.amazonaws.com/7v74t5q7vu6ji3d1hyqzf1f3mfbl",
        blurb: "This small mining town that serves as a convenient resting place for weary travelers on the High Road along the Sword Coast between Neverwinter and Waterdeep. Northerners generally pronounced it LIE-lon while non-locals who learned the name from a map typically chose one of the other possible pronunciations. It was a silver mine in centuries past but was abandoned and became the lair of monsters. A shrine to Tyr was established in Leilon by Lord Pelindar and was relatively new compared to the older shrines devoted to Lathander and Tymora. The spellplauge of 1385 caused a magic disaster, causing the residents to flee and travelers to take wide routes around the town. In 1485, Lord Neverember's forces made safe the town as part of his plan to increase trade on the Sword Coast. The town is being restored and repopulated with residents oweing allegiance to Lord Neverember.",
        recentEvents: ""
      },

      llast: {
        name: "Port Llast",
        enabled: true,
        tagLine: "Small Harbor Town North of Neverwinter",
        region: "Sword Coast North, Northwest Faerûn",
        type: "Settlement",
        size: "Small Town",
        src: "https://static.wikia.nocookie.net/forgottenrealms/images/4/49/PortMap.png",
        blurb: `
          This town north of Neverwinter is famed for it's excellent harbor and stonecutters. It has been allied closely with Neverwinter for centuries, largely to help defend against Luskan. The pirate lords of Luskan desire a Southern Harbor, when they are not attempting to conquer, at least harassing their ships.
          <br/><br/>
          The Spellplauge and the century of turmoil that followed was hard on the town, with changing tides harming it's economy. However, all of the city was liberated from Suhuagin in 1463, with help from Drizzt Do'Urden, Phineas Stoneblood, Geof the Shadow, and Ignatius Brassblood II. Many people moved back as they world calmed, Luskan itself was devastated by the Spellplauge, but new threats remained. In recent years, support from Neverwinter has been crucial in overcoming the impact of the Spellplauge and Second Sundering, bringing the increasingly under the sway of Lord Dagult Neverember though few are complaining since they feel safe finally.
        `,
        recentEvents: "<h4>Session 0</h4>Sparka was told by his adopted parents, the Gumtrees, that he his mother left him here, and was named Redlake."
      },

      luskan: {
        name: "Luskan",
        enabled: true,
        tagLine: "Northen Pirate Port City",
        region: "Sword Coast North, Northwest Faerûn",
        type: "Settlement",
        size: "Town",
        src: "https://swordcoastlegends.wiki.fextralife.com/file/Sword-Coast-Legends/Luskan%20Map.jpg",
        blurb: `
          Built on Orish ruin of Illusk, it was a city run by pirate captains often in conflict with the Lord's Alliance to the South. They often coveted Port Llast.
          <br/><br/>
          The Spellplauge and the chaos afterwards has left the city a fraction of its previous size, but it has slowly regained standing. Even the Arcane Brotherhood have returned.
        `,
        recentEvents: ""
      },

      silver_marches: {
        name: "Silver Marches",
        enabled: true,
        tagLine: "Wide area in the North of Faerûn",
        region: "Northwest Faerûn",
        type: "Region",
        src: "https://static.wikia.nocookie.net/forgottenrealms/images/4/4b/Luruar_1479_DR.jpg",
        blurb: "Silver Marches, a name for the north of Faerûn. Major cities include Silverymoon, Citadel Adbar, Deadsnows, Jalanthar, Quaervarr, Citadel Felbarr, Everlund, Mithral Hall, and Sundabar. It united as a Confederation under the name Luruar under the leadership of Alustriel Silverhand, former ruler of Silverymoon. Its goal was to protect the North against the growing horde of orcs in the mountains. The nation was also a member of the Lords' Alliance. The Confederation won a phyrrhic victory in the War of the Silver Marches (1484-1485 DR), leaving the lands devastated. The Confederation collpased in 1488 due to disagreements after the war, largely that Humans did render aid to a Dwarven city during the war.",
        recentEvents: "<h4>Session 0</h4>Lars left the Silvermarches and the Bloodaxe mercenaries to head to the coast."
      },

      triboar: {
        name: "Triboar",
        enabled: true,
        tagLine: "Crossroads Frontier Trading Town",
        region: "Savage Frontier, Northwest Faerûn",
        type: "Settlement",
        size: "Large Town",
        src: "https://i.redd.it/jvz8mzjempi41.jpg",
        blurb: "A trading settlement found in the North, a lively crossroads town full of the hustle and bustle of roaming merchants, caravaneers and other travelers. Triboar is full of activity all day and night. It is commonly used as a meeting point for a number of armies and militaries that banded together when threats appeared in the North. The landscape surrounding the town was lush and well-suited for farming. The town is ruled by a democratically-elected lord protector. His or her task is to command the town's militia and to settle disputes. The town's legal system was called the \"Lord's Decrees\" and the lord protector had the power to amend this system at will. It is said that the god of rangers, Gwaeron Windstrom, is laid to rest in Triboar.[1] The grove of trees that marked this spot is free of any shrine, but is protected by local laws forbidding its disturbance.",
        recentEvents: ""
      },



      waterdeep: {
        name: "Waterdeep",
        enabled: true,
        tagLine: "Largest Sword Coast City",
        region: "Northern Sword Coast, Northwest Faerûn",
        type: "Settlement",
        size: "Metropolis",
        src: "https://static.wikia.nocookie.net/forgottenrealms/images/5/51/Waterdeep_at_Night.jpg",
        blurb: "Waterdeep, also known as the City of Splendors or the Crown of the North, was the most important and influential city in the North and perhaps in all Faerûn. It was a truly marvelous cosmopolitan city of great culture that attracted the most talented artisans, artists, and scholars from across the Realms, as well as a commercial hub for financial interests along the coast and beyond. It was one of if not the most powerful and influential member-states of the Lords' Alliance, the coalition of nations and city-states that sought to maintain order along the Sword Coast and the North. Lord Dagult Neverember has been serving as Open Lord, but not without contention. The city itself is always vibrant and dynamic.",
        recentEvents: ""
      },

      // eanthalas: {
      //   name: "Eanthalas",
      //   enabled: true,
      //   tagLine: "Imperial Capital",
      //   region: "Imperial Heartland, Primavera, The First World",
      //   type: "Settlement",
      //   size: "Metropolis",
      //   src: "use_map",
      //   blurb: "The seat of the Imerium of Blood, the city is among the largest on the Material Plane",
      //   recentEvents: ""
      // }
    },
  },
  "First World (Verano)": {
    visited: {},
    other: {
      //The large island has more generic names as it is....dare I say...more like creepy and haunted...not a lot of permanent settlements
      stormhaven: {
        label: "Stormhaven",
        larger: true,
        top: 962,
        left: 1580,
        enabled: true,
        opacity: "0.1",
        nation: "???",
        region: "???",
        tagLine: "???",
        blurb: ``,
        spanColor: ironLegion,
        color: `${ironLegion}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      //This region is Congo-Amazon mixture
        //Zambazona Falls - Merging the grandeur of the Zambezi (reflecting African influences) with Amazon, this name suggests a powerful waterfall where two great river cultures meet.
        // Lungawari Basin - Combining "Lualaba" (Congo) and "Arawari" (an Amazonian twist), indicating a fertile and life-sustaining basin teeming with biodiversity.
        // Kapori Grove - Fuses "Kapo" (suggestive of Kapok trees from the Amazon) with "Bikoro" (reflective of places in the Congo), denoting a sacred grove where the spirits of the jungle are said to dwell.
        // Tukananga Cliffs - Blending "Tukano" (an Amazonian indigenous group) with "Lubananga" (suggestive of a Congolese origin), indicating towering cliffs that oversee the confluence of mystical rivers.
        // Yanombe River - A merger of "Yanomami" (an indigenous people of the Amazon) and "Kivu" (from Lake Kivu in the Congo), suggesting a river that runs deep through the heartlands of a lush, untamed wilderness.
        // Itumbolika Rapids - Combining "Ituri" (a forest in Congo) with "Ucayali" (a river in the Amazon), this name evokes the wild rapids of a river cutting through dense jungles.
        // MwarAmazona Isle - A blend of "Mwaro" (a name that could be of Congolese origin) with "Amazon," suggesting an island rich in myths and surrounded by mighty rivers.
        // Sankurúça Forest - Merging "Sankuru" (a river in the Congo) with "Açaí" (a fruit from the Amazon), indicating a forest that is a source of both sustenance and mystery.
        // Quimbamba Cove - Fuses "Quimbo" (suggesting Amazonian influence) with "Mbamba" (a Bantu element), denoting a secluded cove known for its tranquil waters and hidden treasures.
        // Tangarana-Mboka Village - Blending "Tangarana" (a tree species in the Amazon) with "Mboka" (meaning village in Lingala), suggesting a village that thrives at the junction of two worlds, embodying the spirit of both the Amazon and the Congo.
      riverfang_quay: {
        label: "Riverfang Quay",
        larger: true,
        top: 1768,
        left: 2663,
        enabled: true,
        opacity: "0.1",
        nation: "???",
        region: "???",
        tagLine: "???",
        blurb: ``,
        spanColor: ironLegion,
        color: `${ironLegion}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      highlands: {
        label: "Highlands",
        larger: true,
        klass: 'regional-marker',
        top: 1508,
        left: 2663,
        height: '500px',
        enabled: true,
        opacity: "0.1",
        nation: "???",
        region: "???",
        tagLine: "???",
        blurb: ``,
        spanColor: ironLegion,
        color: `${ironLegion}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      yanombe : {
        label: "Yanombe",
        larger: true,
        top: 1650,
        left: 2432,
        enabled: true,
        opacity: "0.1",
        nation: "???",
        region: "???",
        tagLine: "???",
        blurb: ``,
        spanColor: ironLegion,
        color: `${ironLegion}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      kapori_grove : {
        label: "Kapori Grove",
        larger: true,
        top: 1708,
        left: 2432,
        enabled: true,
        opacity: "0.1",
        nation: "???",
        region: "???",
        tagLine: "???",
        blurb: ``,
        spanColor: ironLegion,
        color: `${ironLegion}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
    }
  },
  "First World": {
    visited: {
      thon_daral: {
        label: "Thon Daral",
        larger: true,
        top: 993,
        left: 3286,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
    },
    other: {
      // Imperial
      thalas: {
        label: "Thalas",
        larger: true,
        top: 1608,
        left: 3002,
        enabled: true,
        opacity: "0.1",
        nation: "The Imperium of Blood",
        region: "Thalas Region, Central Empire ",
        tagLine: "Captial of the Empire",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        blurb: `
          Imperial capital built into remains of the massive Aetherese Dry Docks for Flying Cities built thousands of years ago, including the Grand Isherlon. Renamed after Thalas a legendary progeny before the first Emperor.
          The city is built into massive quarry lakes from where the land bergs were cut, resulting in terraced circles leading to a massive hole that sinks deep into the ground to the Underdark.
          Massive towers surround the terraced langs that they assume helped raise the land bergs out. The hole into the ground is filled with the remians of drydocks for smaller flying fortresses and ships.
          The metal of the Aetherese remains are in Aetherese stone, a name for the nearby unbreakable stone-like steel that the Aetherese used that none have been able to recreate.
          Most of the Imperial government and Bloodline ctiadels are deep in the hole, which is crossed back and forth by walkways, resevoirs to catch falling water, and facilities.
          Deep below in the hole as it approaches paths into the Underdark are where more of the slums are, but Thalas under walls and gates allow it to close before most of the Underdark paths begin.
        `,
        border: "#FFFA",
        textColor: "#FFF"
      },
      woodholm: {
        label: "Woodholm",
        larger: true,
        top: 1425,
        left: 3452,
        enabled: true,
        opacity: "0.1",
        nation: "The Imperium of Blood",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      esari: {
        label: "Esari",
        larger: true,
        top: 1035,
        left: 2423,
        enabled: true,
        opacity: "0.1",
        nation: "The Imperium of Blood",
        region: "Alipyrgos Coast, Alipyrgos River Valley",
        tagLine: "Bustling Port Since Ancient Times.",
        blurb: `This ancient port is the furthest north Imperial mainland port at the base of the Alipyrgos River. It was Ancient Aetherese Port, 30 miles inland are Aetherese capital's ruins. The modern city is a hub of trade and many trades.`,
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      aetheria: {
        label: "Aetheria Ruins",
        larger: true,
        top: 1042,
        left: 2457,
        enabled: true,
        opacity: "0.1",
        nation: "The Imperium of Blood",
        region: "Alipyrgos Coast, Alipyrgos River Valley",
        tagLine: "Ruins of Original Aetherese Capital",
        blurb: `These ruins were the original settlement of the Ancient Aetherese, a random village were a prodigious wizard settled and married a skilled metallurgist. Somehow they created the Aetherese Steel and the birth of an Empire was formed. The city was leveled near the end of the Aetherese Wars, but by then most of the capital aspects had transfered towards their flying city being made at modern day Thalas`,
        spanColor: `${ironLegion}CC`,
        color: `${ironLegion}11`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      thacedia: {
        label: "Thacedia",
        larger: true,
        top: 1150,
        left: 2475,
        enabled: true,
        nation: "The Imperium of Blood",
        tagLine: "Struggling Imperial coastal city turning into vacation hub",
        region: "Alipyrgos Sea, Northern Empire",
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        blurb: `City struggling as most merchants set up shop in nearby cities instead as they grow. Some nobles and rich citizens have been purchasing land as it becomes cheaper, treating it as a vacation city.`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      pelothen: {
        label: "Pelothen",
        larger: true,
        top: 1169,
        left: 2386,
        enabled: true,
        nation: "The Imperium of Blood",
        region: "Alipyrgos Sea, Northern Empire",
        tagLine: `"Burning Pelothen" an Imperial mining city known for Smelters and Blacksmiths`,
        blurb: `City known for nearby mines. Large number of Smelters and Blacksmiths give the city the name "Burning Pelothen"`,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      paialos_city: {
        label: "Paialos City",
        larger: true,
        top: 1037,
        left: 2207,
        nation: "The Imperium of Blood",
        region: "Alipyrgos Sea, Northern Empire",
        tagLine: "Militarized Imperial Island Port",
        blurb: `A port on Paialos Island but a major military hub. They struggle to attract civilian trade, but the military traffic makes up for it.`,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      athonia: {
        label: "Athonia",
        larger: true,
        top: 1080 ,
        left: 2331,
        enabled: true,
        nation: "The Imperium of Blood",
        tagLine: "Imperial island city built in Chromatic ruins",
        region: "Alipyrgos Sea, Northern Empire",
        blurb: `Originally a modest port town during Aetherese era, but destroyed in the wars. Refounded as a base during Chromatic Oppression using the island to keep distance from any rebellion fermenting on the mainland. The modern post-liberation city is built in/on/around fortresses, dragon lairs, and ruins.`,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      hysarius: {
        label: "Hysarius",
        larger: true,
        top: 1256,
        left: 2193,
        enabled: true,
        opacity: "0.1",
        nation: "The Imperium of Blood",
        region: "Alipyrgos Sea, Northern Empire",
        tagLine: "Stubborn Imperial upland Island city",
        blurb: `Ancient city founded after the retreat from the coasts as Aetherese collapsed and war ravaged the lands. Resisted Chromatic Occupation for nearly a thousand years, but finally fell. Now a fiercely independent people, well as independent as Imperials can get. Rumored that various Resistance groups find support there, especially Harpers`,
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      sippar: {
        label: "Sippar",
        larger: true,
        top: 1454,
        left: 2363,
        enabled: true,
        tagLine: "Imperial War Port for the Primaveran Sea",
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        nation: "The Imperium of Blood",
        blurb: `Too close to the war to be a normal city...but too far to be too endangered. The city economy is driven by massive naval base, one of largest centralized Naval commands. Could be a trading port but most fear setting up shop close to the war`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      enshull: {
        label: "Enshull",
        larger: true,
        top: 1622,
        left: 2645,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        visited: false,
        tagLine: "Historic Port at Mouth of Imperial River",
        type: "Settlement",
        size: "City",
        region: "Shull Gulf, Central Empire",
        nation: "The Imperium of Blood",
        blurb: `Port city about mouth of the Imperial River. Thousands of years ago was the seat of a large confederacy that was early opponent of Aetherese. They controlled the Shull Gulf. Defeated by Aetherese, they proved to be their last major threat until Coronal War`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      ambal: {
        label: "Ambal",
        larger: true,
        top: 1661,
        left: 2487,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      lemash: {
        label: "Lemash",
        larger: true,
        top: 1722,
        left: 2541,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      srento: {
        label: "Srento",
        larger: true,
        top: 2030,
        left: 2555,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      iyfnaes: {
        label: "Iyfnaes",
        larger: true,
        top: 2053,
        left: 2090,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      onivine: {
        label: "Onivine",
        larger: true,
        top: 2006,
        left: 2162,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      dubraes: {
        label: "Dubraes",
        larger: true,
        top: 2180,
        left: 2183,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      arpinum: {
        label: "Arpinum",
        larger: true,
        top: 1923,
        left: 2302,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      taboth: {
        label: "Taboth",
        larger: true,
        top: 2280,
        left: 2495,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      caralis: {
        label: "Caralis",
        larger: true,
        top: 2077,
        left: 2835,
        enabled: true,
        opacity: "0.1",
        nation: "The Imperium of Blood",
        tagLine: "Way point city between interior and coast",
        blurb: `City up river from Bellwater Bay, a stepping off point into the interior and also a path for shipping to the Kinasthmus`,
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      ovgroth: {
        label: "Ovgroth",
        larger: true,
        top: 2960,
        left: 1965,
        enabled: true,
        tagLine: "Recently Conquered Most Southern Imperial Port",
        blurb: `Furthest South of Imperial Rule, major center in otherwise fairly rural area. City of ancient pre-oppression Human Barbarian Empire. City has been ruled by dragons, barbarians to the east, multiple pirate lords, now the empire. The local culture, architeture, and history all reflect this past.`,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      sea_heart: {
        label: "Sea Heart",
        larger: true,
        top: 1900,
        left: 3965,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      vynthor: {
        label: "Vynthor",
        larger: true,
        top: 1914,
        left: 3999,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      whispering_caverns: {
        label: "Whispering Caverns",
        larger: true,
        top: 1919,
        left: 3974,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      pirate_hideout: {
        label: "Amberurge's Hideout",
        larger: true,
        top: 1942,
        left: 3985,
        enabled: true,
        opacity: "0.1",
        spanColor: ironLegion,
        color: `${ironLegion}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      starspear_seat: {
        label: "Starspear's Seat",
        larger: true,
        top: 1962,
        left: 3995,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      ryinora: {
        label: "Ryinora",
        larger: true,
        top: 2103,
        left: 3903,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      sabratha: {
        label: "Sabratha",
        larger: true,
        top: 2234,
        left: 3849,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      enthara: {
        label: "Enthara",
        larger: true,
        top: 2324,
        left: 4005,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      upper_sabra: {
        label: "Upper Sabra",
        larger: true,
        top: 2080,
        left: 3420,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      boreana: {
        label: "Boreana",
        larger: true,
        top: 2570,
        left: 3766,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      seyana: {
        label: "Seyana",
        larger: true,
        top: 2700,
        left: 3942,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      gratsia: {
        label: "Gratsia",
        larger: true,
        top: 2970,
        left: 3969,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      cytana: {
        label: "Cytana",
        larger: true,
        top: 2870,
        left: 3537,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      yleriae: {
        label: "Y'leriae",
        larger: true,
        top: 1931,
        left: 2030,
        enabled: true,
        opacity: "0.1",
        nation: "The Imperium of Blood",
        blurb: `City known for wine, it was founded as a city state thousands of years ago as part of the loose confederacy of the Coastal Coronal. It is one of the few cities that escaped the Chromatic Reign mostly unscathed due to wine production. Despite being close to the battlefield today, it has magic soil produces the best possible wine which keeps people living and working here.`,
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      cartennae: {
        label: "Cartennae",
        larger: true,
        top: 1722,
        left: 2087  ,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      cydaae: {
        label: "Cydaae",
        larger: true,
        top: 1684,
        left: 2190  ,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      citadel_of_the_seven: {
        label: "Citadel of the Seven",
        larger: true,
        top: 1571,
        left: 2208  ,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      cylethsin: {
        label: "Cylethsin",
        larger: true,
        top: 1949,
        left: 2122,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      blood_coast_bay: {
        label: "Blood Coast Bay",
        larger: true,
        top: 1822,
        left: 1928,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      bastion_of_erathis: {
        label: "Bastion of Erathis",
        larger: true,
        top: 1852,
        left: 1904,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      cynlona: {
        label: "Cynlona",
        larger: true,
        top: 1887,
        left: 1887,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },



      umshara: {
        label: "Umshara",
        larger: true,
        top: 1880,
        left: 3818,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        blurb: `Port river mouth `,
        border: "#FFFA",
        textColor: "#FFF"
      },

      ilyana: {
        label: "Ilyana",
        larger: true,
        top: 1799,
        left: 3666,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },



      rusminda: {
        label: "Rusminda",
        larger: true,
        top: 687,
        left: 2740,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      zaqor: {
        label: "Zaqor",
        larger: true,
        top: 666,
        left: 2546,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      zestaikha: {
        label: "Zestaikha",
        larger: true,
        top: 688,
        left: 3125,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      baizen: {
        label: "Baizen",
        larger: true,
        top: 663,
        left: 2989,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },


      mosodom: {
        label: "Mosodom",
        larger: true,
        top: 535,
        left: 2783,
        enabled: true,
        opacity: "0.1",
        spanColor: ironLegion,
        color: `${ironLegion}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      shayunzoda: {
        label: "Shayunzoda",
        larger: true,
        top: 548,
        left: 2642,
        enabled: true,
        opacity: "0.1",
        spanColor: ironLegion,
        color: `${ironLegion}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },



      vagharshin: {
        label: "Vagharshin",
        larger: true,
        top: 563,
        left: 2796,
        enabled: true,
        opacity: "0.1",
        spanColor: ironLegion,
        color: `${ironLegion}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },


      kokabz: {
        label: "Kokabz",
        larger: true,
        top: 576,
        left: 2851,
        enabled: true,
        opacity: "0.1",
        spanColor: ironLegion,
        color: `${ironLegion}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      orlorakol: {
        label: "Orlorakol",
        larger: true,
        top: 602,
        left: 2915,
        enabled: true,
        opacity: "0.1",
        spanColor: ironLegion,
        color: `${ironLegion}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },


      kolkand_citadel: {
        label: "Kolkand Citadel",
        larger: true,
        top: 617,
        left: 2820,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      dusurol: {
        label: "Dusurol",
        larger: true,
        top: 505,
        left: 2600,
        enabled: true,
        opacity: "0.1",
        spanColor: ironLegion,
        color: `${ironLegion}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },


      murar: {
        label: "Murar",
        larger: true,
        top: 523,
        left: 2600,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      jaenara: {
        label: "Jaénara",
        larger: true,
        top: 547,
        left: 2620,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },


      Ekibna: {
        label: "Ekibna",
        larger: true,
        top: 440,
        left: 2550,
        enabled: true,
        opacity: "0.1",
        spanColor: ironLegion,
        color: `${ironLegion}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },



      sarzow: {
        label: "Sarzow",
        larger: true,
        top: 588,
        left: 2681,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },





      barren_mine: {
        label: "Barren Mine",
        blurb: "Dwarven settlement wiped out by an Ancient White Dragon some thousand years ago. The Iron Legion drove out kobolds and now uses it as massive fortification to guard mountain passes along their eastern border",
        larger: true,
        top: 307,
        left: 2958,
        enabled: true,
        opacity: "0.1",
        spanColor: ironLegion,
        color: `${ironLegion}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      khagrads_crossing: {
        label: "Khagrad's Crossing",
        blurb: "Dual fortifications that exist several hundred feet into the river, connected to a bridge that spans it. Created by Iron Legion Engineering marvel, General Khagrad. It allows Iron Legion armies to swiftly move up and down the border to face threats as they arise.",
        larger: true,
        top: 411,
        left: 2940,
        enabled: true,
        opacity: "0.1",
        spanColor: ironLegion,
        color: `${ironLegion}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      grekvens_hold: {
        label: "Grekven's Hold",
        larger: true,
        top: 337,
        left: 2893,
        enabled: true,
        opacity: "0.1",
        spanColor: ironLegion,
        color: `${ironLegion}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      shalderok: {
        label: "Shalderok",
        larger: true,
        top: 103,
        left: 2578,
        enabled: true,
        opacity: "0.1",
        spanColor: ironLegion,
        color: `${ironLegion}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      kapvir: {
        label: "Kapvir",
        larger: true,
        top: 533,
        left: 2990,
        enabled: true,
        opacity: "0.1",
        spanColor: ironLegion,
        color: `${ironLegion}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      qazol: {
        label: "Qazol",
        larger: true,
        top: 501,
        left: 3344,
        enabled: true,
        opacity: "0.1",
        spanColor: platinum,
        color: `${platinum}22`,
        border: "#FFFA",
        textColor: "#000"
      },
      zestol: {
        label: "Zestol",
        larger: true,
        top: 399,
        left: 3727,
        enabled: true,
        opacity: "0.1",
        spanColor: ironLegion,
        color: `${ironLegion}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      seroshi: {
        label: "Seroshi",
        larger: true,
        top: 616,
        left: 3753,
        enabled: true,
        opacity: "0.1",
        spanColor: platinum,
        color: `${platinum}22`,
        border: "#FFFA",
        textColor: "#000"
      },
      rusraq: {
        label: "Rusraq",
        larger: true,
        top: 654,
        left: 3927,
        enabled: true,
        opacity: "0.1",
        spanColor: platinum,
        color: `${platinum}22`,
        border: "#FFFA",
        textColor: "#000"
      },
      oznaki: {
        label: "Oznaki",
        larger: true,
        top: 766,
        left: 4014,
        enabled: true,
        opacity: "0.1",
        spanColor: platinum,
        color: `${platinum}22`,
        border: "#FFFA",
        textColor: "#000"
      },
      vanavan: {
        label: "Vanavan",
        larger: true,
        top: 567,
        left: 4118,
        enabled: true,
        opacity: "0.1",
        spanColor: platinum,
        color: `${platinum}22`,
        border: "#FFFA",
        textColor: "#000"
      },
      ganisi: {
        label: "Ganisi",
        larger: true,
        top: 511,
        left: 4199,
        enabled: true,
        opacity: "0.1",
        spanColor: platinum,
        color: `${platinum}22`,
        border: "#FFFA",
        textColor: "#000"
      },
      vardaran: {
        label: "Vardaran",
        larger: true,
        top: 419,
        left: 4180,
        enabled: true,
        opacity: "0.1",
        spanColor: platinum,
        color: `${platinum}22`,
        border: "#FFFA",
        textColor: "#000"
      },
      dravether: {
        label: "Dravether",
        larger: true,
        top: 841,
        left: 3902,
        enabled: true,
        opacity: "0.1",
        spanColor: platinum,
        color: `${platinum}22`,
        border: "#FFFA",
        textColor: "#000"
      },
      sharmunu: {
        label: "Sharmunu",
        larger: true,
        top: 1632,
        left: 4125,
        enabled: true,
        opacity: "0.1",
        spanColor: platinum,
        color: `${platinum}22`,
        border: "#FFFA",
        textColor: "#000"
      },
      kherima: {
        label: "Kherima",
        larger: true,
        top: 1468,
        left: 4173,
        enabled: true,
        opacity: "0.1",
        spanColor: platinum,
        color: `${platinum}22`,
        border: "#FFFA",
        textColor: "#000"
      },
      whispervale: {
        label: "Whispervale Enclave",
        larger: true,
        top: 1458,
        left: 4000,
        enabled: true,
        opacity: "0.1",
        spanColor: platinum,
        color: `${platinum}22`,
        border: "#FFFA",
        textColor: "#000"
      },


      terasha: {
        label: "Terasha",
        larger: true,
        top: 775,
        left: 2928,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },


      librajak: {
        label: "Librajak",
        larger: true,
        top: 1002,
        left: 2749,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      chateau_tyrgalos: {
        label: "Château Tyrgalos",
        larger: true,
        top: 1010,
        left: 2773,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      krilok: {
        label: "Krilok",
        larger: true,
        top: 1015,
        left: 3172,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },


      vultu: {
        label: "Vultu",
        larger: true,
        top: 916,
        left: 3328,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      livvo: {
        label: "Livvo",
        larger: true,
        top: 1092,
        left: 3370,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      lucika: {
        label: "Lucika",
        larger: true,
        top: 1165,
        left: 2952,
        enabled: true,
        opacity: "0.1",
        spanColor: imperialPurple,
        color: `${imperialPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },


      //////

      harkuhm: {
        label: "Harkuhm",
        larger: true,
        top: 1081,
        left: 3614,
        blurb: "Main seat of the Dwarven Confederation of the Peaks",
        enabled: true,
        opacity: "0.1",
        spanColor: dwarvenGreen,
        color: `${dwarvenGreen}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      mornburin: {
        label: "Mornburin",
        larger: true,
        top: 958,
        left: 3607,
        enabled: true,
        opacity: "0.1",
        spanColor: dwarvenGreen,
        color: `${dwarvenGreen}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      kharndaruhm: {
        label: "Kharndaruhm",
        larger: true,
        top: 1103,
        left: 3710,
        blurb: "Main seat of the Dwarven Confederation of the Peaks",
        enabled: true,
        opacity: "0.1",
        spanColor: dwarvenGreen,
        color: `${dwarvenGreen}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },


      //////


      rengroth: {
        label: "Rengroth",
        larger: true,
        top: 3001,
        left: 2782,
        enabled: true,
        opacity: "0.1",
        spanColor: horseBarbs,
        color: `${horseBarbs}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      // Plat Repub

      sepruma: {
        label: "Sepruma",
        larger: true,
        top: 1969,
        left: 4028,
        enabled: true,
        opacity: "0.1",
        spanColor: platinum,
        color: `${platinum}55`,
        border: "#000A",
        textColor: "#000"
      },
      sakta: {
        label: "Sakta",
        larger: true,
        top: 1902,
        left: 4124,
        enabled: true,
        opacity: "0.1",
        spanColor: platinum,
        color: `${platinum}55`,
        border: "#000A",
        textColor: "#000"
      },


      // Gilded Forest
      nilhlenora_alanatalos: {
        label: "Nilhlenora/Alanatalos",
        larger: true,
        top: 1667,
        left: 3926,
        enabled: true,
        opacity: "0.25",
        spanColor: gildedForest,
        color: `${gildedForest}55`,
        border: `${basicSilver}55`,
        textColor: `#FFF`
      },
      aalrius: {
        label: "A'alrius",
        larger: true,
        top: 1787,
        left: 3881,
        enabled: true,
        opacity: "0.25",
        spanColor: gildedForest,
        color: `${gildedForest}55`,
        border: `${basicSilver}55`,
        textColor: `#FFF`
      },
      // Sea Elves
      shalasantha: {
        label: "Shalasantha", //Fortress of Shalas
        larger: true,
        top: 2299,
        left: 2022,
        enabled: true,
        opacity: "0.25",
        spanColor: basicSilver,
        color: `${basicSilver}55`,
        border: `${basicSilver}55`,
        textColor: `#000`
      },
      // Fair Isle
      lyathyr: {
        label: "Lyathyr",
        larger: true,
        top: 2382,
        left: 2287,
        enabled: true,
        opacity: "0.25",
        spanColor: gildedForest,
        color: `${gildedForest}55`,
        border: `${basicSilver}55`,
        textColor: `#000`
      },
      cath_lenora: {
        label: "Cath Lenora",
        larger: true,
        top: 2240,
        left: 2289,
        enabled: true,
        opacity: "0.25",
        spanColor: gildedForest,
        color: `${gildedForest}55`,
        border: `${basicSilver}55`,
        textColor: `#000`
      },
      drekafall: {
        label: "Drekafall",
        larger: true,
        top: 2525,
        left: 2323,
        enabled: true,
        opacity: "0.25",
        spanColor: gildedForest,
        color: `${gildedForest}55`,
        border: `${basicSilver}55`,
        textColor: `#000`
      },
      shaan_shaeras: {
        label: "Shaan Shaeras",
        larger: true,
        top: 2589,
        left: 2287,
        enabled: true,
        opacity: "0.25",
        spanColor: gildedForest,
        color: `${gildedForest}55`,
        border: `${basicSilver}55`,
        textColor: `#000`
      },

      /// Giant Land
      tiradër: {
        label: "Tiradër",
        larger: true,
        top: 849,
        left: 3246,
        enabled: true,
        opacity: "0.1",
        spanColor: ironLegion,
        color: `${ironLegion}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      riksars_hall: {
        label: "Riksar's Hall",
        larger: true,
        top: 746,
        left: 3452,
        enabled: true,
        opacity: "0.1",
        spanColor: ironLegion,
        color: `${ironLegion}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      kragthors_keep: {
        label: "Kragthor's Keep",
        larger: true,
        top: 759,
        left: 3480,
        enabled: true,
        opacity: "0.1",
        spanColor: ironLegion,
        color: `${ironLegion}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      //// Timoc

      timther: {
        label: "Timther",
        larger: true,
        top: 755,
        left: 2386,
        enabled: true,
        opacity: "0.1",
        spanColor: timocPeach,
        color: `${timocPeach}22`,
        border: "#FFFA",
        textColor: "#000"
      },
      sakruna: {
        label: "Sakruna",
        larger: true,
        top: 380,
        left: 2452,
        enabled: true,
        opacity: "0.1",
        spanColor: timocPeach,
        color: `${timocPeach}22`,
        border: "#FFFA",
        textColor: "#000"
      },
      nebsim: {
        label: "Nebsim",
        larger: true,
        top: 485,
        left: 2545,
        enabled: true,
        opacity: "0.1",
        spanColor: timocPeach,
        color: `${timocPeach}22`,
        border: "#FFFA",
        textColor: "#000"
      },
      nekhfumeru: {
        label: "Nekhfumeru",
        larger: true,
        top: 835,
        left: 2187,
        enabled: true,
        opacity: "0.1",
        spanColor: timocPeach,
        color: `${timocPeach}22`,
        border: "#FFFA",
        textColor: "#000"
      },
      apmahdet: {
        label: "Apmahdet",
        larger: true,
        top: 815,
        left: 2099,
        enabled: true,
        opacity: "0.1",
        spanColor: timocPeach,
        color: `${timocPeach}22`,
        border: "#FFFA",
        textColor: "#000"
      },

      saska: {
        label: "Saska",
        larger: true,
        top: 825,
        left: 1993,
        enabled: true,
        opacity: "0.1",
        spanColor: timocPeach,
        color: `${timocPeach}22`,
        border: "#FFFA",
        textColor: "#000"
      },
      sesouruna: {
        label: "Sesouruna",
        larger: true,
        top: 619,
        left: 2022,
        enabled: true,
        opacity: "0.1",
        spanColor: timocPeach,
        color: `${timocPeach}22`,
        border: "#FFFA",
        textColor: "#000"
      },

      kisfudos: {
        label: "Kisfudos",
        larger: true,
        top: 881,
        left: 2065,
        enabled: true,
        opacity: "0.1",
        spanColor: timocPeach,
        color: `${timocPeach}22`,
        border: "#FFFA",
        textColor: "#000"
      },
      behnoyut: {
        label: "Behnoyut",
        larger: true,
        top: 983,
        left: 1955,
        enabled: true,
        opacity: "0.1",
        spanColor: timocPeach,
        color: `${timocPeach}22`,
        border: "#FFFA",
        textColor: "#000"
      },
      khatuna: {
        label: "Khatuna",
        larger: true,
        top: 1109,
        left: 1820,
        enabled: true,
        opacity: "0.1",
        spanColor: timocPeach,
        color: `${timocPeach}22`,
        border: "#FFFA",
        textColor: "#000"
      },



      kinather_ruins: {
        label: "Kinather Ruins",
        larger: true,
        top: 1845,
        left: 1820,
        enabled: true,
        opacity: "0.1",
        spanColor: kinaphalicPurple,
        color: `${kinaphalicPurple}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },


      greother: {
        label: "Greother",
        larger: true,
        top: 1807,
        left: 1708,
        enabled: true,
        opacity: "0.1",
        spanColor: verdantDracGreen,
        color: `${verdantDracGreen}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      vythisk: {
        label: "Vythisk", //vyth steel isk star
        larger: true,
        top: 1726,
        left: 1742,
        enabled: true,
        opacity: "0.1",
        spanColor: verdantDracGreen,
        color: `${verdantDracGreen}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },


      hysvear: {
        label: "Hysvear", //soar
        larger: true,
        top: 1976,
        left: 1564,
        enabled: true,
        opacity: "0.1",
        spanColor: verdantDracGreen,
        color: `${verdantDracGreen}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      nurhoth: {
        label: "Nurhoth", //ugly tooth nurh-oth
        larger: true,
        top: 2037,
        left: 1468,
        enabled: true,
        opacity: "0.1",
        spanColor: verdantDracGreen,
        color: `${verdantDracGreen}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },



      krather: {
        label: "Krather",
        larger: true,
        top: 1973,
        left: 1760,
        enabled: true,
        opacity: "0.1",
        spanColor: krateopolisRed,
        color: `${krateopolisRed}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      vivex: {
        label: "Vivex", // Victory
        larger: true,
        top: 2045,
        left: 1725,
        enabled: true,
        opacity: "0.1",
        spanColor: krateopolisRed,
        color: `${krateopolisRed}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      versvesh_fortress: {
        label: "Versvesh Fortress",
        larger: true,
        top: 1876,
        left: 1786,
        enabled: true,
        opacity: "0.1",
        spanColor: krateopolisRed,
        color: `${krateopolisRed}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      // Verdant

      vasilach: {
        label: "Vasilach",
        larger: true,
        top: 1870,
        left: 518,
        enabled: true,
        opacity: "0.1",
        spanColor: verdantDracGreen,
        color: `${verdantDracGreen}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      drohozansk: {
        label: "Drohozansk",
        larger: true,
        top: 1921,
        left: 331,
        enabled: true,
        opacity: "0.1",
        spanColor: verdantDracGreen,
        color: `${verdantDracGreen}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },


      navapovysk: {
        label: "Navapovysk",
        larger: true,
        top: 2033,
        left: 22,
        enabled: true,
        opacity: "0.1",
        spanColor: verdantDracGreen,
        color: `${verdantDracGreen}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      krasnovodske: {
        label: "Krasnovodske",
        larger: true,
        top: 1497,
        left: 328,
        enabled: true,
        opacity: "0.1",
        spanColor: verdantDracGreen,
        color: `${verdantDracGreen}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      lipnț: {
        label: "Lipnț",
        larger: true,
        top: 1474,
        left: 462,
        enabled: true,
        opacity: "0.1",
        spanColor: verdantDracGreen,
        color: `${verdantDracGreen}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      barush: {
        label: "Barush",
        larger: true,
        top: 1391,
        left: 402,
        enabled: true,
        opacity: "0.1",
        spanColor: verdantDracGreen,
        color: `${verdantDracGreen}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      călășli: {
        label: "Călășli",
        larger: true,
        top: 1748,
        left: 645,
        enabled: true,
        opacity: "0.1",
        spanColor: verdantDracGreen,
        color: `${verdantDracGreen}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      stratov: {
        label: "Stratov",
        larger: true,
        top: 2056,
        left: 1018,
        enabled: true,
        opacity: "0.1",
        spanColor: verdantDracGreen,
        color: `${verdantDracGreen}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      bydry: {
        label: "Bydry",
        larger: true,
        top: 2037,
        left: 915,
        enabled: true,
        opacity: "0.1",
        spanColor: verdantDracGreen,
        color: `${verdantDracGreen}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      bébony: {
        label: "Bébony",
        larger: true,
        top: 1994,
        left: 754,
        enabled: true,
        opacity: "0.1",
        spanColor: verdantDracGreen,
        color: `${verdantDracGreen}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      tolbarcika: {
        label: "Tolbarcika",
        larger: true,
        top: 1816,
        left: 1177,
        enabled: true,
        opacity: "0.1",
        spanColor: verdantDracGreen,
        color: `${verdantDracGreen}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      myrchiv: {
        label: "Myrchiv",
        larger: true,
        top: 1976,
        left: 1346,
        enabled: true,
        opacity: "0.1",
        spanColor: verdantDracGreen,
        color: `${verdantDracGreen}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      dobnica: {
        label: "Dobnica",
        larger: true,
        top: 1800,
        left: 1532,
        enabled: true,
        opacity: "0.1",
        spanColor: verdantDracGreen,
        color: `${verdantDracGreen}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      kiyeyka: {
        label: "Kiyeyka",
        larger: true,
        top: 1061,
        left: 166,
        enabled: true,
        opacity: "0.1",
        spanColor: verdantDracGreen,
        color: `${verdantDracGreen}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },


      // Ash n Cinder
      nirest: {
        label: "Nirest",
        larger: true,
        top: 1392,
        left: 1358,
        enabled: true,
        opacity: "0.1",
        spanColor: ashCinderPink,
        color: `${ashCinderPink}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      singelle: {
        label: "Singelle",
        larger: true,
        top: 1467,
        left: 1905,
        enabled: true,
        opacity: "0.1",
        spanColor: ashCinderPink,
        color: `${ashCinderPink}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      charhelm: {
        label: "Charhelm",
        larger: true,
        top: 1487,
        left: 1598,
        enabled: true,
        opacity: "0.1",
        spanColor: ashCinderPink,
        color: `${ashCinderPink}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },


      castle_dominant: {
        label: "Castle Dominant",
        larger: true,
        top: 1534,
        left: 2215,
        enabled: true,
        opacity: "0.1",
        spanColor: ashCinderPink,
        color: `${ashCinderPink}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

       // Cipherdo
      loton: {
        label: "Loton",
        larger: true,
        top: 1169,
        left: 1218,
        enabled: true,
        opacity: "0.1",
        spanColor: ciphPurpRed,
        color: `${ciphPurpRed}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      cindiro: {
        label: "Cindiro",
        larger: true,
        top: 1060,
        left: 1459,
        enabled: true,
        opacity: "0.1",
        spanColor: ciphPurpRed,
        color: `${ciphPurpRed}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      fuegara: {
        label: "Fuegara",
        larger: true,
        top: 968,
        left: 1487,
        enabled: true,
        opacity: "0.1",
        spanColor: ciphPurpRed,
        color: `${ciphPurpRed}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      surch: {
        label: "Surch",
        larger: true,
        top: 747,
        left: 1501,
        enabled: true,
        opacity: "0.1",
        spanColor: ciphPurpRed,
        color: `${ciphPurpRed}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      // Drimneh

      malimad: {
        label: "Malimad",
        larger: true,
        top: 990,
        left: 1676,
        enabled: true,
        opacity: "0.1",
        spanColor: burnOrange,
        color: `${burnOrange}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      pyreloch: {
        label: "Pyreloch",
        larger: true,
        top: 531,
        left: 1892,
        enabled: true,
        opacity: "0.1",
        spanColor: burnOrange,
        color: `${burnOrange}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      firefall: {
        label: "Firefall",
        larger: true,
        top: 370,
        left: 1599,
        enabled: true,
        opacity: "0.1",
        spanColor: burnOrange,
        color: `${burnOrange}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      igneia: {
        label: "Igneia",
        larger: true,
        top: 457,
        left: 1759,
        enabled: true,
        opacity: "0.1",
        spanColor: burnOrange,
        color: `${burnOrange}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },


      // Entrop


      phurkulzuth: {
        label: "Phurkulzuth",
        larger: true,
        top: 866,
        left: 1292,
        enabled: true,
        opacity: "0.1",
        spanColor: entropyDark,
        color: `${entropyDark}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      akros: {
        label: "Akros",
        larger: true,
        top: 794,
        left: 1127,
        enabled: true,
        opacity: "0.1",
        spanColor: entropyDark,
        color: `${entropyDark}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      oshox_khuz: {
        label: "Oshox Khuz",
        larger: true,
        top: 664,
        left: 753,
        enabled: true,
        opacity: "0.1",
        spanColor: entropyDark,
        color: `${entropyDark}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      faenhil: {
        label: "Faenhil",
        larger: true,
        top: 695,
        left: 595,
        enabled: true,
        opacity: "0.1",
        spanColor: entropyDark,
        color: `${entropyDark}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      fylnthaes: {
        label: "Fylnthaes",
        larger: true,
        top: 596,
        left: 600,
        enabled: true,
        opacity: "0.1",
        spanColor: entropyDark,
        color: `${entropyDark}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      invabelle: {
        label: "Invabelle",
        larger: true,
        top: 497,
        left: 546,
        enabled: true,
        opacity: "0.1",
        spanColor: entropyDark,
        color: `${entropyDark}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      srenlion: {
        label: "Srenlion",
        larger: true,
        top: 406,
        left: 446,
        enabled: true,
        opacity: "0.1",
        spanColor: entropyDark,
        color: `${entropyDark}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },


      omnthaes: {
        label: "Omnthaes",
        larger: true,
        top: 522,
        left: 273,
        enabled: true,
        opacity: "0.1",
        spanColor: entropyDark,
        color: `${entropyDark}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      us_alari: {
        label: "Us Alari",
        larger: true,
        top: 779,
        left: 444,
        enabled: true,
        opacity: "0.1",
        spanColor: entropyDark,
        color: `${entropyDark}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },


      //Misc


      birdridge: {
        label: "Birdridge",
        larger: true,
        top: 552,
        left: 1223,
        enabled: true,
        opacity: "0.1",
        spanColor: entropyDark,
        color: `${entropyDark}22`,
        border: "#FFFA",
        blurb: "Halfling village totally under thumb of Chromatics, no light no joy. Used as a trading center with the original Halflings kept as near slaves to upkeep for higher sublicants",
        textColor: "#FFF"
      },

      mistbreak: {
        label: "Mistbreak",
        larger: true,
        top: -10,
        left: 1276,
        enabled: true,
        opacity: "0.1",
        spanColor: entropyDark,
        color: `${entropyDark}22`,
        border: "#FFFA",
        blurb: "Halfling city but Halflings are treated as slaves more or less. Now a dusty, sleepy, dying town that just has passers through while Halflings tend to them",
        textColor: "#FFF"
      },


      oakvale: {
        label: "Oakvale",
        larger: true,
        top: 43,
        left: 978,
        enabled: true,
        opacity: "0.1",
        spanColor: entropyDark,
        color: `${entropyDark}22`,
        border: "#FFFA",
        blurb: "Forested town near convergence of tributaries...float logs down to Firefall and Halflings are made to work and host other slaves that do not make it long ",
        textColor: "#FFF"
      },
      elynarion: {
        label: "Elynarion",
        larger: true,
        top: 1155,
        left: 945,
        enabled: true,
        opacity: "0.1",
        spanColor: entropyDark,
        color: `${entropyDark}22`,
        border: "#FFFA",
        blurb: "Former Elven city, depopulated years back now filled with resettled slaves to work farm fields",
        textColor: "#FFF"
      },
      cyfemel: {
        label: "Cyfemel",
        larger: true,
        top: 1347,
        left: 865,
        enabled: true,
        opacity: "0.1",
        spanColor: entropyDark,
        color: `${entropyDark}22`,
        border: "#FFFA",
        blurb: "Elven Ruin now a hub to transport wood and goods from Verdant Forest to the coast",
        textColor: "#FFF"
      },
      imyallian: {
        label: "Imyallian",
        larger: true,
        top: 1519,
        left: 1118,
        enabled: true,
        opacity: "0.1",
        spanColor: entropyDark,
        color: `${entropyDark}22`,
        border: "#FFFA",
        blurb: "City destroyed by Llothites generations back, now a hub of Underdark trade",
        textColor: "#FFF"
      },
      catacadis: {
        label: "Catacadis",
        larger: true,
        top: 2647,
        left: 1188,
        enabled: true,
        opacity: "0.1",
        spanColor: ciphPurpRed,
        color: `${ciphPurpRed}22`,
        border: "#FFFA",
        blurb: "Capital near foot of Ashlaradon's Lair, lava symbolism and references everywhere. The steam of the heat ever seeps up from the ground in this hot city",
        textColor: "#FFF"
      },
      littoloch: {
        label: "Littoloch",
        larger: true,
        top: 2509,
        left: 1026,
        enabled: true,
        opacity: "0.1",
        spanColor: ciphPurpRed,
        color: `${ciphPurpRed}22`,
        border: "#FFFA",
        blurb: "Rather desolate bay, though there is decent fishing. Desert city with tall walls and many trade ships",
        textColor: "#FFF"
      },
      navacada: {
        label: "Navacada",
        larger: true,
        top: 2414,
        left: 1393,
        enabled: true,
        opacity: "0.1",
        spanColor: ciphPurpRed,
        color: `${ciphPurpRed}22`,
        border: "#FFFA",
        blurb: "City straddling the Navacadan peninsula, the naval base serves as one of the largest for the Pentarchy on this coast",
        textColor: "#FFF"
      },
      saphitas: {
        label: "Saphitas",
        larger: true,
        top: 2160,
        left: 1406,
        enabled: true,
        opacity: "0.1",
        spanColor: ciphPurpRed,
        color: `${ciphPurpRed}22`,
        border: "#FFFA",
        blurb: "Heavily fortified city on a passage from inland sea to the ocean. Lead Generals for the Twins spend much time here, as well as being a city of sin for soldiers and mercenaries to spend their coin when they can.",
        textColor: "#FFF"
      },
      // Tyranny

      ovaturak: {
        label: "Ovaturak",
        larger: true,
        top: 2556,
        left: 393,
        enabled: true,
        opacity: "0.1",
        spanColor: tyrannyBlue,
        color: `${tyrannyBlue}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      badebagi: {
        label: "Badebagi",
        larger: true,
        top: 2522,
        left: 534,
        enabled: true,
        opacity: "0.1",
        spanColor: tyrannyBlue,
        color: `${tyrannyBlue}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },

      gümüsil: {
        label: "Gümüsil",
        larger: true,
        top: 2451,
        left: 787,
        enabled: true,
        opacity: "0.1",
        spanColor: tyrannyBlue,
        color: `${tyrannyBlue}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      badelkan: {
        label: "Badelkan",
        larger: true,
        top: 2702,
        left: 243,
        enabled: true,
        opacity: "0.1",
        spanColor: tyrannyBlue,
        color: `${tyrannyBlue}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      hajirdir: {
        label: "Hajirdir",
        larger: true,
        top: 2625,
        left: 107,
        enabled: true,
        opacity: "0.1",
        spanColor: tyrannyBlue,
        color: `${tyrannyBlue}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },


      // The Powerful Alliance

     icehall: {
        label: "Icehall",
        larger: true,
        top: 2277,
        left: 224,
        enabled: true,
        opacity: "0.1",
        spanColor: powerfulDull,
        color: `${powerfulDull}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
      woodsedge: {
        label: "Woodsedge",
        larger: true,
        top: 2101,
        left: 776,
        enabled: true,
        opacity: "0.1",
        spanColor: powerfulDull,
        color: `${powerfulDull}22`,
        border: "#FFFA",
        textColor: "#FFF"
      },
    }
  }
}

export default locationsData
