import React                 from "react";

import DivineRow             from "./DivineRow"

class DivineBlock extends React.Component {
  allowedChars(divinities) {
    return Object.keys(divinities).filter((divinityKey) => {
      return divinities[divinityKey].enabled
    }).sort();
  }

  render() {
    const data = this.props.divines;

    return (
      <table className="regular">
        <thead>
          <tr className="divine-row">
            <th>
              Name
            </th>

            <th>
              Alignment
            </th>

            <th>
              Title
            </th>

            <th>
              Pantheon
            </th>

            {
              this.props.showRank &&
              <th>
                Rank
              </th>
            }

            <th>
              Province
            </th>

            <th>
              Plane
            </th>

            {
              this.props.showDomain &&
              <th>
                Domain
              </th>
            }

            {
              this.props.showPatron &&
              <th>
                Patron
              </th>
            }
          </tr>
        </thead>
        <tbody>
          {
            this.allowedChars(data.good).map((divinityKey, indy) => {
              return (
                <DivineRow
                  key={divinityKey}
                  divinityKey={divinityKey}
                  showRank={this.props.showRank}
                  showDomain={this.props.showDomain}
                  showPatron={this.props.showPatron}
                  divinity={data.good[divinityKey]}
                />
              )
            })
          }

          {
            this.allowedChars(data.neutral).map((divinityKey, indy) => {
             return (
                <DivineRow
                  key={divinityKey}
                  divinityKey={divinityKey}
                  showRank={this.props.showRank}
                  showDomain={this.props.showDomain}
                  showPatron={this.props.showPatron}
                  divinity={data.neutral[divinityKey]}
                />
              )
            })
          }

          {
            this.allowedChars(data.evil).map((divinityKey, indy) => {
              return (
                <DivineRow
                  key={divinityKey}
                  divinityKey={divinityKey}
                  showRank={this.props.showRank}
                  showDomain={this.props.showDomain}
                  showPatron={this.props.showPatron}
                  divinity={data.evil[divinityKey]}
                />
              )
            })
          }
        </tbody>
      </table>
    )
  }
}

export default DivineBlock;
