const weaponMakes = {  
  aberration: {
    name: "Aberration",
    blurb: "The item was created by aberrations in ancient times, possibly for the use of favored humanoid thralls. When seen from the corner of the eye, the item seems to be moving."
  },

  other_human: {
    name: "Other Human",
    blurb: "The item was created during the heyday of a fallen human kingdom, or it is tied to a human of legend. It might hold writing in a forgotten tongue or symbols whose significance is lost to the ages."
  },

  barbarian_human: {
    name: "Southern Barbarian",
    blurb: "The item was created during the heyday of a fallen human kingdom, or it is tied to a human of legend. It might hold writing in a forgotten tongue or symbols whose significance is lost to the ages."
  },


  red_pentarchy: {
    name: "Pentarchy Humans (Red)",
    blurb: "Grim weapons often mass produced for the fodder that powers the war machine. More refined weapons are stlyed for one of the Chromatic Dragon color, often with things styled as the dragon that the human was a vassal of"
  },

  blue_pentarchy: {
    name: "Pentarchy Humans (Blue)",
    blurb: "Grim weapons often mass produced for the fodder that powers the war machine. More refined weapons are stlyed for one of the Chromatic Dragon color, often with things styled as the dragon that the human was a vassal of"
  },

  green_pentarchy: {
    name: "Pentarchy Humans (Green)",
    blurb: "Grim weapons often mass produced for the fodder that powers the war machine. More refined weapons are stlyed for one of the Chromatic Dragon color, often with things styled as the dragon that the human was a vassal of"
  },

  black_pentarchy: {
    name: "Pentarchy Humans (Black)",
    blurb: "Grim weapons often mass produced for the fodder that powers the war machine. More refined weapons are stlyed for one of the Chromatic Dragon color, often with things styled as the dragon that the human was a vassal of"
  },

  white_pentarchy: {
    name: "Pentarchy Humans (White)",
    blurb: "Grim weapons often mass produced for the fodder that powers the war machine. More refined weapons are stlyed for one of the Chromatic Dragon color, often with things styled as the dragon that the human was a vassal of"
  },

  ancient_imperial: {
    name: "Ancient Imperial",
    blurb: "Basic Imperial weapons are very basic and mass produced. However, refined items that progeny invest in for their long blood lines, and the ones nobles seek to elevate their statuses, are different entirely. Progeny smiths make weapons out of pure Imperial Steel and High Osage. Things like the leather grips, bowstring, etc are not meant to last and are replaced. This means that the metal itself is the decorative focus, often hued to the class color of the wielder and engraved with the line/house name and the name of the weapon. Imperial mottos, sayings, symbols, and prayers are also common."
  },

  older_imperial: {
    name: "Older Imperial",
    blurb: "Basic Imperial weapons are very basic and mass produced. However, refined items that progeny invest in for their long blood lines, and the ones nobles seek to elevate their statuses, are different entirely. Progeny smiths make weapons out of pure Imperial Steel and High Osage. Things like the leather grips, bowstring, etc are not meant to last and are replaced. This means that the metal itself is the decorative focus, often hued to the class color of the wielder and engraved with the line/house name and the name of the weapon. Imperial mottos, sayings, symbols, and prayers are also common."
  },

  newer_imperial: {
    name: "Newer Imperial",
    blurb: "Basic Imperial weapons are very basic and mass produced. However, refined items that progeny invest in for their long blood lines, and the ones nobles seek to elevate their statuses, are different entirely. Progeny smiths make weapons out of pure Imperial Steel and High Osage. Things like the leather grips, bowstring, etc are not meant to last and are replaced. This means that the metal itself is the decorative focus, often hued to the class color of the wielder and engraved with the line/house name and the name of the weapon. Imperial mottos, sayings, symbols, and prayers are also common."
  },

  different_imperial: {
    name: "Different Imperial",
    blurb: "Basic Imperial weapons are very basic and mass produced. However, refined items that progeny invest in for their long blood lines, and the ones nobles seek to elevate their statuses, are different entirely. Progeny smiths make weapons out of pure Imperial Steel and High Osage. Things like the leather grips, bowstring, etc are not meant to last and are replaced. This means that the metal itself is the decorative focus, often hued to the class color of the wielder and engraved with the line/house name and the name of the weapon. Imperial mottos, sayings, symbols, and prayers are also common."
  },

  aethers: {
    name: "Aetherse",
    blurb: "Ancient weapons of the Aetherese that remain are mostly made of Aetherese stone, metal appeared to mixed with a Obsidian-looking stone that is stronger than other materials but is unable to be recreated. The weapons appear to be metal with dark patches of stone in it with ripples around them."
  },

  ancient_oxkad: {
    name: "Ancient Oxkadi",
    blurb: "This item was made back in the anceint Oxkad basin. The oldest weapons are Sickle Shaped Akhs, later replaced by the steppe styles of sabers and weapons designed for use on horseback. They often have an engraving of the Oxkad River with an X where it was forged."
  },

  old_oxkad: {
    name: "Old Oxkadi",
    blurb: "This item was made back in the anceint Oxkad basin. The oldest weapons are Sickle Shaped Akhs, later replaced by the steppe styles of sabers and weapons designed for use on horseback. They often have an engraving of the Oxkad River with an X where it was forged."
  },

  new_oxkad: {
    name: "Newer Oxkadi",
    blurb: "This item was made back in the anceint Oxkad basin. The oldest weapons are Sickle Shaped Akhs, later replaced by the steppe styles of sabers and weapons designed for use on horseback. They often have an engraving of the Oxkad River with an X where it was forged."
  },

  celestial: {
    name: "Celestial",
    blurb: "The weapon is half the normal weight and inscribed with feathered wings, suns, and other symbols of good. Fiends find the item's presence repulsive."
  },

  dragon: {
    name: "Dragon",
    blurb: "This item is made from scales and talons shed by a dragon. Perhaps it incorporates precious metals and gems from the dragon's hoard. It grows slightly warm when within 120 feet of a dragon."
  },

  kin_dragonborn: {
    name: "Kinaphalic Dragonborn",
    blurb: "The single word for the majority of Dragonborn weapons is \"efficient\". Suffering under Draconic rule, rebellions and revolts needed lots of quality weapons fast. The results are dependable weapons without frills. However, more refined weapons usually have dragonborn heads on their pommels and the metal of blades appearing to have scales."
  },

  tim_dragonborn: {
    name: "Timocracy Dragonborn",
    blurb: "The single word for the majority of Dragonborn weapons is \"efficient\". Suffering under Draconic rule, rebellions and revolts needed lots of quality weapons fast. The results are dependable weapons without frills. However, more refined weapons usually have dragonborn heads on their pommels and the metal of blades appearing to have scales."
  },

  krateo_dragonborn: {
    name: "Krateocracy Dragonborn",
    blurb: "The single word for the majority of Dragonborn weapons is \"efficient\". Suffering under Draconic rule, rebellions and revolts needed lots of quality weapons fast. The results are dependable weapons without frills. However, more refined weapons usually have dragonborn heads on their pommels and the metal of blades appearing to have scales."
  },

  drow: {
    name: "Drow",
    blurb: "The item is half the normal weight. It is black and inscribed with spiders and webs in honor of Lolth. It might function poorly, or disintegrate, if exposed to sunlight for 1 minute or more."
  },

  dwarf: {
    name: "Dwarf",
    blurb: "The item is durable and has Dwarven runes worked into its design. It might be associated with a clan that would like to see it returned to their ancestral halls."
  },

  bastion: {
    name: "Bastion",
    blurb: "Similar to Dwarven, but influenced by Gnomes."
  },

  "elemental air": {
    name: "Elemental Air",
    blurb: "The item is half the normal weight and feels hollow. If it's made of fabric, it is diaphanous."
  },

  "elemental earth": {
    name: "Elemental Earth",
    blurb: "This item might be crafted from stone. Any cloth or leather elements are studded with finely polished rock."
  },

  "elemental fire": {
    name: "Elemental Fire",
    blurb: "This item is warm to the touch, and any metal parts are crafted from black iron. Sigils of flames cover its surface. Shades of red and orange are the prominent colors."
  },

  "elemental water": {
    name: "Elemental Water",
    blurb: "Lustrous fish scales replace leather or cloth on this item, and metal portions are instead crafted from seashells and worked coral as hard as any metal."
  },

  fair_elf: {
    name: "Fair Isle Gold Elf",
    blurb: "The item is three-quarters its normal weight. It is adorned with fine Elvish caligraphy, often in the name of the family of the wielder."
  },

  gilded_elf: {
    name: "Gilded Forest Gold Elf",
    blurb: "The item is half the normal weight. It is adorned with symbols of nature: leaves, vines, stars, and the like."
  },


  lunar_elf: {
    name: "Lunar Elf",
    blurb: "The item is half the normal weight. It is adorned with symbols of nature: leaves, vines, stars, and the like."
  },

  wood_elf: {
    name: "Wood Elf",
    blurb: "The item is half the normal weight. It is adorned with symbols of nature: leaves, vines, stars, and the like."
  },

  shadow_elf: {
    name: "Shadar-Kai",
    blurb: "The item is half the normal weight. It is adorned with symbols of nature: leaves, vines, stars, and the like."
  },

  fey: {
    name: "Fey",
    blurb: "The item is exquisitely crafted from the finest materials and glows with a pale radiance in moonlight, shedding dim light in a 5-foot radius Any metal in the item is silver or mithral, rather than iron or steel."
  },

  fiend: {
    name: "Fiend",
    blurb: "The item is made of black iron or horn inscribed with runes, and any cloth or leather components are crafted from the hide of fiends. It is warm to the touch and features leering faces or vile runes engraved on its surface. Celestials find the item's presence repulsive."
  },

  giant: {
    name: "Giant",
    blurb: "The item is larger than normal and was crafted by giants for use by their smaller allies."
  },

  gnome: {
    name: "Gnome",
    blurb: "The item is crafted to appear ordinary and it might look worn. It could also incorporate gears and mechanical components, even if these aren't essential to the item's function."
  },

  iron_hobgoblin: {
    name: "Iron Host Hobgoblin",
    blurb: "This weapon is the most utilitarian item you have probably ever seen. While serviceable, they win no awards for artistry. Even the finest magic weapons look unimpressive to the untrained eye and might be covered with knicks and scratches."
  },

  banner_hobgoblin: {
    name: "Unity Banner Hobgoblin",
    blurb: "This weapon is the most utilitarian item you have probably ever seen. While serviceable, they win no awards for artistry. Even the finest magic weapons look unimpressive to the untrained eye and might be covered with knicks and scratches."
  },

  lizardfolk: {
    name: "Lizardfolk",
    blurb: "This weapon appears as basic and simple as possible, made of wood and natural materials. However, the materials are expertly selected to result in a fairly adept weapon. Often decorated with natural elements, feathers, paint, marks, etc."
  },

  orc: {
    name: "Orc",
    blurb: "The weapon looks primative with rock, wood, and metal all together, but are incredibly durable. They are made to deal with powerful strikes over and over. Many Orcs have smithing knowledge, but the traditional aestetic remains the wild primative style from if they follow Gruumsh. Non-Gruumsh Orcs often make things less dark, but still fairly brutalistic."
  },

  korc: {
    name: "Urzoth Storm Orc",
    blurb: "The weapon looks primative with rock, wood, and metal all together, but are incredibly durable. They are made to deal with powerful strikes over and over. Many Orcs have smithing knowledge, but the traditional aestetic remains the wild primative style from if they follow Gruumsh. Non-Gruumsh Orcs often make things less dark, but still fairly brutalistic."
  },

  undead: {
    name: "Undead",
    blurb: "The item incorporates imagery of death such as bones and skulls, and it might be crafted from parts of corpses. It feels cold to the touch"
  }
}

export default weaponMakes;
