const critData = {
  "20s": {
    1: [
      {
        character: "Lars",
        check: "Melee Greataxe",
        description: "Greataxe cleave a lock on a chest that Corpo stole with levitation from some stranded travelers, but had convinced them it wasn't stealing but helping. The attack attack made them question Corpo's explanation."
      },
      {
        character: "Corpo",
        check: "Perception",
        description: "Noticed 5 Goblins about to ambush the party."
      },
    ],

    2:[
      {
        character: "Sass",
        check: "Melee Short Sword",
        description: "Decapitated fleeing wolf after Lars castrated it"
      },
      {
        character: "Corpo",
        check: "Perception",
        description: "Noticed Sparka murder a goblin by testing his mind sliver powers"
      },
      {
        character: "Sass",
        check: "Arcana",
        description: "Put together that Sparka is a manifesting Draconic Sorcerer"
      }
    ],

    3: [
      {
        character: "Cecil",
        check: "Investigation",
        description: "Noticed the silvery cord attached to Corpo, knows basics of Astral projection, including Gith and their silver swords "
      },
      {
        character: "Sass",
        check: "Strength Saving Throw",
        description: "To Brace as Goblins unleash pool, the wind from the water lit his torch even brighter"
      },
      {
        character: "Lars",
        check: "Insight",
        description: "Sees that Yeemik sees them as powerful threat to him and Blargh, so wants them to kill Blargh. BUT he plans to renegotiate if they are weakened when they return."
      },
      {
        character: "Cecil",
        check: "History",
        description: "He knows that Cragmaw Castle is an ancient ruin and details of it, but no one is quite sure where it is."
      },
    ],

    5: [
      {
        character: "Sparka",
        check: "Intimidation",
        description: "Scare Dbeccaw (The d is silent) into talking about the hiedout"
      },
      {
        character: "Corpo",
        check: "Acrobatics",
        description: "Backflip over the pit trap"
      },
      {
        character: "Sass",
        check: "Melee",
        description: "Assassinated unaware Redbrand Justin with short swords"
      },
      {
        character: "Cecil",
        check: "History",
        description: "To notice that 'Furry Ears' means Bug Bears"
      },
      {
        character: "Sass",
        check: "Deception",
        description: "To resist the Nothic's Weird Insight"
      },
      {
        character: "Corpo",
        check: "Melee",
        description: "Knocks the Nothic unconscious"
      },
    ],

    6: [
      {
        character: "Corpo",
        check: "Melee",
        description: "Hit Bug Bear Gogt with Quarter Staff"
      },
      {
        character: "Corpo",
        check: "Melee",
        description: "Hit Dire Wolf with Quarter Staff"
      },
      {
        character: "Lars",
        check: "Melee",
        description: "Clean slice clears head off Red Brand Summmmer, cradle the kill down"
      }
    ],

    7: [
      {
        character: "Corpo",
        check: "Gust",
        description: "Gust to blow up Elsa's dress"
      },
      {
        character: "Sass",
        check: "Melee",
        description: "Norixius charges Sass, Sass dual swords Critical slice with off hand"
      },
      {
        character: "Sass",
        check: "Persuasion",
        description: "Convince Tarhun of the truth of what happened with Cloudchaser and his sister"
      },
      {
        character: "Sparka",
        check: "Perception",
        description: "Notices Thistle in the trees gesturing that she planted the forged letter"
      }
    ],
    8: [
      {
        character: 'Sparka',
        check: "History",
        description: "Knowing about Half Orcs from tavern liife, they are from varied backgrounds, not all...ya know"
      },
      {
        character: 'Sass',
        check: 'Con Save',
        description: 'Resist the 40% booze of the Giant Slayer '
      },
      {
        character: "Sass",
        check: "Perception",
        description: "Sass crests the hill to see the zombies notices 8 zombies and smells more"
      },
      {
        character: "Sparka",
        check: "History",
        description: "Diemondar House, he knows House of Elves connected to the Human Netherese, which is odd"
      },
      {
        character: "Sparka",
        check: "Initiative",
        description: "On Venomfang fight, as Sparka was so damn ready"
      },
      {
        character: "Corpo",
        check: "Con Save",
        description: "Save on Venomfang Breath Attack",
      },
    ],
    9: [
      {
        character: "Sass",
        check: "Perception",
        description: "Sass notices a cloud of mushroom spores rolling into the town."
      },
      {
        character: "Sparka",
        check: "Firebolt",
        description: "Hits Faveric while he is Charming Lars"
      },
      {
        character: "Lars",
        check: "Greataxe",
        description: "Cultist tries to disengage and flee..but Sentinel! Lars Kills"
      },
      {
        character: "Cecil",
        check: "Arcana",
        description: "Know that Twig Blights are awakened plants, no reasoning"
      },
      {
        character: "Corpo",
        check: "Punch a Ghost",
        description: "Flurry of Blows to Punch a Shadow"
      },
    ],
    10: [
      {
        character: "Cecil",
        check: "Perception",
        description: "USING BOWSER To find Ichorcheeks hiding in the woods"
      },
      {
        character: "Cecil",
        check: "Stealth",
        description: "To wake everyone up without Ichorcheeks noticing"
      },
      {
        character: "Sparka",
        check: "Initiative",
        description: "To put Ichorcheeks to sleep"
      },
      {
        character: "Sass",
        check: "Investigation",
        description: "Figures out room in ruind was a structure not original meant for living quarters, storeroom converted to place to crash"
      },
      {
        character: "Sparka",
        check: "Perception",
        description: "Find water basin notices that there are water openings and paths in pipes"
      }
    ],
    11: [
      {
        character: "Cecil",
        check: "Arcana",
        description: "Knowing about Netherese Reality Crystals"
      },
      {
        character: "Cecil",
        check: "Arcana",
        description: "Recognize Instrument of the Bard on bardette"
      },
      {
        character: "Lars",
        check: "Athletics",
        description: "Playing \"Throw Rocks\" with Fuboi"
      },
      {
        character: "Corpo",
        check: "Acrobatics",
        description: "Seducing Lady Carnival with amazing dual wine pouring"
      },
    ],
    12: [
      {
        character: "Sass",
        check: "Survival",
        description: "Noticing False Elsa's trail"
      },
      {
        character: "Sass",
        check: "Investigation",
        description: "Checking for traps around False Elsa's hiding hole for Real Elsa"
      },
      {
        character: "Corpo",
        check: "Initiative",
        description: "When the Striges dive bomb them"
      },
      {
        character: "Corpo",
        check: "Punch",
        description: "Acid punching a locked Strongbox open"
      },
      {
        character: "Corpo",
        check: "Punch",
        description: "Acid punches open a locked chest"
      },
      {
        character: "Corpo",
        check: "Staff",
        description: "Staff attack on Minotaur Skeleton"
      },
    ],
    13: [
      {
        character: "Sass",
        check: "Investigation",
        description: "Check Forge door for traps, sees none but figures out how to pop the pins and get door open."
      },
      {
        character: "Sass",
        check: "Perception",
        description: "To check if anything on the island in Wave Echo Cave, spotting a Treasure Table Roll."
      },
      {
        character: "Sass",
        check: "Melee Greataxe",
        description: "Greataxe cleave a lock on a chest that Corpo stole with levitation from some stranded travelers, but had convinced them it wasn't stealing but helping. The attack attack made them question Corpo's explanation."
      },
      {
        character: "Corpo",
        check: "Melee Mace (Lightbringer)",
        description: "Kills the Shadow with the Mace, then grabs the Shadow by the head and pelvic thrusts it to dust."
      },
    ],
    14: [
      {
        character: "Corpo",
        check: "Perception",
        description: "On watch to notice the Black Spider's forces investigating the cave"
      },
      {
        character: "Cecil",
        check: "Perception",
        description: "Check to notice forces coming to jump them"
      },
      {
        character: "Bowser",
        check: "Perception",
        description: "Check to notice forces coming to jump them."
      },
      {
        character: "Lars",
        check: "Initiative",
        description: "Waking up to the ambush in the Arcane Lock Room"
      },
      {
        character: "Corpo",
        check: "Thorwing caught arrow back",
        description: "Catches arrow from Hobgoblin, goes to throw it back and fails"
      },
      {
        character: "Sparka",
        check: "Arcana",
        description: "Check on Iron Shadow..only notices boobs...Corpo would not have left this monastery"
      },
    ],
    15: [
      {
        character: "Sparka",
        check: "Dex Save",
        description: "To avoid ceiling collapse"
      },
      {
        character: "Sparka",
        check: "Investigation",
        description: "Searching North Barracks in Wave Echo Cave, found a +1 Arcane Grimoire "
      },
      {
        character: "Sparka",
        check: "Arcana",
        description: "Check to see how strong Severin was based on glimpse through portal"
      },
      {
        character: "The Duchess",
        check: "Bite Attack",
        description: "Stuns squirrel with lightning breath then lazily eats it"
      },
      {
        character: "Corpo",
        check: "Performance",
        description: "Check to stall/convince the crowd that the Phandalin 5 is good guys."
      },
      {
        character: "Corpo",
        check: "Persuasion",
        description: "Check to get virgins to come forward"
      },
      {
        character: "Corpo",
        check: "Religion",
        description: "Check to read runes on the lamp in ancient odd Draconic"
      },
    ],
    17: [
      {
        character: "Cecil",
        check: "Insight",
        description: "Notices Halia with a small wine glass looks disgusted by Qeline."
      },
      {
        character: "Corpo",
        check: "Staff Melee Attack",
        description: "On Scout to then drag in bushes and break neck."
      },
      {
        character: "Lars",
        check: "Javelin Throw Attack",
        description: "Hurls at Archer during ambush."
      },
      {
        character: "Corpo",
        check: "Unarmed Strike",
        description: "Archer during Ambush"
      },
      {
        character: "Sass",
        check: "Shoots",
        description: "Archer in other breast straight through the nipple...no lactation"
      }
    ],
    18: [
      {
        character: "Corpo",
        check: "Persuasion",
        description: "Convince Shazeem to talk and we won't hurt you."
      },
      {
        character: "Sass",
        check: "History",
        description: "White Dragons are dumb brutes, the barbarians of the dragon world no offense Lars."
      },
      {
        character: "Cecil",
        check: "Arcana",
        description: "Ice Dragons are immune to cold, not weak to fire, but nothing on poison."
      },
      {
        character: "Sparka",
        check: "Stealth",
        description: "Cast Haste without Cloudchaser hearing."
      },
      {
        character: "Lars",
        check: "Melee Greataxe",
        description: "Massive strike at Cloudchaser while hasted"
      },
      {
        character: "Sparka",
        check: "Arcana",
        description: "If he stays in Cloudchaser's collapsing horde for 8 hours it will unlock Dragonshard."
      },
    ],
    19: [
      {
        character: "Lars",
        check: "Melee Greataxe",
        description: "Kill Ogre before he can holler by slitting throat and whispering \"I am the dragon\""
      },
      {
        character: "Corpo",
        check: "Melee Staff",
        description: "Staff hit on the Ogre"
      },
      {
        character: "Cecil",
        check: "Lockpick",
        description: "Really hard lock picking...is able to get it open with a creak"
      },
      {
        character: "Lars",
        check: "Melee Greataxe",
        description: "Attacking Abbishai"
      },
    ],
    20: [
      {
        character: "Corpo",
        check: "Perception",
        description: " \"You can tell me, I am now a god\" as he figures out base of castle."
      },
      {
        character: "Lars",
        check: "Melee Great Axe",
        description: "Hew guy in half...then slaps him with his dick on the bottom half"
      },
      {
        character: "Sass",
        check: "Bow Shot",
        description: "Ogre in Blago tower"
      },
      {
        character: "Corpo",
        check: "Strength Save",
        description: "Hold omn as Air Elemental's whirlind howls"
      },
      {
        character: "Cecil",
        check: "Thieves Tools",
        description: "Picking the steering tower door, realizes Arcane Lock"
      },

    ],
    21: [
      {
        character: "Sparka",
        check: "Insight",
        description: "To see the Succubus is messing with him, figures out the contract was in Abyssal not Infernal so not real"
      },
      {
        character: "Corpo",
        check: "Short Bow",
        description: "shoots happy Giant neighbor"
      },

      {
        character: "Lars",
        check: "Javelin",
        description: "KILLS Ogre by throwing it through his throat...falls on 2 Kobolds"
      },

      {
        character: "Sparka",
        check: "Arcana",
        description: "To identify the Gravity Sinkhole, Cecil rolled high too. Know it is Dunamancy Space Time Manipulation. "
      },

      {
        character: "Sass",
        check: "Init Roll",
        description: "for cultist attack"
      },
    ],
    22: [
      {
        character: "Corpo",
        check: "Dex Save",
        description: "To holds onto Brian's saddle"
      },
      {
        character: "Sass",
        check: "Arrow",
        description: "Nail and arrow at Thopleth. Used Squire ability to hit for a shit ton of damage total as he just walked in"
      },
      {
        character: "Corpo",
        check: "Perception",
        description: "Sees that 'Brian'...is not a Wyvern....but a Pegasus made of star energy with two people riding"
      },

      {
        character: "Sparka",
        check: "Firebolt",
        description: "Kill Ogre him by turning him to ash"
      },

      {
        character: "Corpo",
        check: "Death Save",
        description: "Dying all alone in the crashed castle and he pops up"
      },
    ],
    23: [
      {
        character: "Sparka",
        check: "Persuasion",
        description: "To convince Mist to give Lars a great deal since he is going to help so much"
      },
      {
        character: "Cecil",
        check: "Arcana",
        description: "To understand how the connected lamps work in Primavera"
      },
      {
        character: "Corpo",
        check: "Insight",
        description: "To figure out Elmar is paying him to get a whore in the hopes it keeps him away from Thistle"
      },

      {
        character: "Corpo",
        check: "Acrobatics",
        description: "Wrestling against Storm Orcish princess, she also gets a NAT 20 but his total is higher"
      },
    ],
    24: [
      {
        character: "Corpo",
        check: "Nature",
        description: "To know that the mountain drainage will be high so river should be fairly fast"
      },


      {
        character: "Cecil",
        check: "Persuasion",
        description: "Pressing Mavol to admit he wanted something more with Kulvesa than friendship"
      },
    ],
    25: [
      {
        character: "Cecil",
        check: "Nature",
        description: "To figure out catfish-like creatures, other fish too. He realizes a ton of fish can get caught."
      },


      {
        character: "Cecil",
        check: "Insight",
        description: "To see that Mavol is nervous is getting nervous as they walk inland"
      },


      {
        character: "Sparka",
        check: "Firebolt",
        description: "To hit Mavol"
      },


      {
        character: "Sass",
        check: "Arrow shot",
        description: "On the Oxkadi Barb"
      },


      {
        character: "Sass",
        check: "Arrow shot",
        description: "Fleeing Oxkadi rogue, KILLSHOT but not as he ensnares him down"
      },


      {
        character: "Raven Friend",
        check: "Perception",
        description: "to scout the enemy camp"
      },
    ],
    26: [
      {
        character: "Lars",
        check: "Axe",
        description: "Charge and attacking Barlgura recklessly"
      },
      {
        character: "Lars",
        check: "Axe",
        description: "Charge and attacking Barlgura recklessly"
      },
      {
        character: "Sparka",
        check: "Persuasion",
        description: "Sparka uses cantrips to create nice music notes then calms the kidnapped girls down"
      },
    ],
    27: [
      {
        character: "Lars",
        check: "Intimidation",
        description: "To get Tharizdun Princess to talk more: 'Why is the Dreadflame worrying about us? he always knew he was the weaker of the brothers.'"
      },

      {
        character: "Cecil",
        check: "Arcana",
        description: "To recognize the Blackrazor sword."
      },

      {
        character: "Sparka",
        check: "Persuasion",
        description: "To convince Kulvesa to not tell Mavol's parents he was going to start a war for no reason unknown."
      },

      {
        character: "Corpo",
        check: "Persuasion",
        description: "Giving Elmar a super knowing glance...which...is...so knowin."
      },
    ],
    28: [
      {
        character: "Sparka",
        check: "Perception",
        description: "To find the Rogue Prince in the crowd. Behind a roped off area with Centaur guard and another couple guards. He gets their vibes too"
      },
      {
        character: "Corpo",
        check: "Performance",
        description: "Check to play the pan flute like the Sax Man from Careless Whisperto make sure the band is offsensive"
      },
      {
        character: "Korg",
        check: "Performance",
        description: "Check to rap Hot n Here while playing the lute terribly to make sure the band is awful"
      },
      {
        character: "Lars",
        check: "Persuasion",
        description: "To buy a female centaur guard from the prince for 1K gold...as a...idk...servant? whore? slave?"
      },
      {
        character: "Lars",
        check: "Athletics",
        description: "to leap off headfirst at the step mom...she is surprised...with all the extra jumping power spear the shit out her and she get's non-lethal knocked."
      },
    ],
    30: [
      {
        character: "Sass",
        check: "Persuasion",
        description: "To convince the Ho not to be a Ho"
      },
    ],
    31:  [
      {
        character: "Lars",
        check: "Insight",
        description: "To notice the Waukeen flinches when scoping out a Zhentarim"
      },

      {
        character: "Lars",
        check: "Deception",
        description: "To have urchins convince people he isn't leaving for the Air Plane"
      },
    ],
    32: [
      {
        character: "Cecil",
        check: "Insight",
        description: "To catch a small business owner start running away as the Cult of the Dragon is mentioned."
      },


      {
        character: "Sparka",
        check: "Persuade",
        description: "Tea Shop guy that big business is bad"
      },


      {
        character: "Corpo",
        check: "Persuasion",
        description: "T intro legate to his friends via ahh straight of shot and wide of girth"
      },


      {
        character: "Bowser",
        check: "Stealth",
        description: "Check to hide from Cultist"
      },


      {
        character: "Sparka",
        check: "Investigation",
        description: "To search cultist bags, finds cool ass poison"
      },
    ],
    33: [


      {
        character: "Lars",
        check: "Dex Save",
        description: "To hold onto ship in storm"
      },

      {
        character: "Sass",
        check: "Longbow",
        description: "Hail of Thorns kill Sootborn"
      },

      {
        character: "GDE",
        check: "Polehammer Melee",
        description: "Whack a Salamander"
      },

      {
        character: "Corpo",
        check: "Perception",
        description: "To see everything in Sass anatomy as he stands over him"
      },

      {
        character: "Sparka",
        check: "Investigation",
        description: "To get Kerym is a wizard of higher rank than him"
      },

      {
        character: "Lars",
        check: "Initiative",
        description: "To assault Volcanmote Castle"
      },

      {
        character: "Sass",
        check: "Longbow",
        description: "Shoot Pyrothar the Fire Giant in Volcanmote Castle Assault"
      },

      {
        character: "Lars",
        check: "Axe of Ysgard",
        description: "Attack of Opp on Fire Giant in Volcanmote Castle Assault"
      },

      {
        character: "Lars",
        check: "Javelin",
        description: "Throw at Giant in Volcanmote Castle Assault"
      },

      {
        character: "Sass",
        check: "Longbow",
        description: "To hit Salamander in Volcanmote Castle Assault"
      },

      {
        character: "Sass",
        check: "Dex Save",
        description: "To Dodge Cherufe Magma Geyser"
      },

      {
        character: "Sass",
        check: "Longbow",
        description: "Shoot at Cherufe as it emerged from the ground"
      },

      {
        character: "Lars",
        check: "Dex Save",
        description: "To dodge Cherufe Magma geyser"
      },

      {
        character: "Cecil",
        check: "Athletics",
        description: "To leap over magma fleeing crumbling chamber...lands and finds 5 gold"
      },

      {
        character: "Sparka",
        check: "Investigation",
        description: "To find treasure the bottom of the hole Lars dug in the ruins"
      },

      {
        character: "Sass",
        check: "Survival",
        description: "To figure out how to stop a magic volcano"
      },

      {
        character: "Sparka",
        check: "Insight",
        description: "To see if old man actually been down 7 years. Realize no night or day unable to detect time probably gone crazy"
      },

      {
        character: "Sparka",
        check: "Steath",
        description: "To sneak into the Fire Giant mini-town in Volcanmote"
      },
    ],
    34: [
            {
        character: "Lars",
        check: "Perception",
        description: "To notice similarity between Brondar Ironfist and the statue from growing up"
      },

      {
        character: "Cecil",
        check: "Deception",
        description: "Act like he belongs as they work way over towards the alchemist and others lave quarters "
      },

      {
        character: "Corpo",
        check: "Persuasion",
        description: "Asking slaves how long have you languished under an uncruel master to get them fired up"
      },

      {
        character: "Sparka",
        check: "Investigation",
        description: "To search the Wizard backroom"
      },

      {
        character: "Cecil",
        check: "Arcana",
        description: "To ID the permanent Wall of Force"
      },

      {
        character: "Sass",
        check: "Longbow",
        description: "KILL Salamander by shooting in the neck...gasping through mouth ....help me...to his friend"
      },

      {
        character: "Corpo",
        check: "Punch",
        description: "Salamander in the face attacking Duchess"
      },

      {
        character: "Lars",
        check: "Greataxe",
        description: "KILL Salamander by cutting him in half"
      },

      {
        character: "Sass",
        check: "Lightning Strike Arrow",
        description: "Fired at Fire Elemental"
      },

      {
        character: "Lars",
        check: "Dex Save ",
        description: "On Hadrian Fireball"
      },

      {
        character: "Corpo",
        check: "Dex Save ",
        description: "On Hadrian Fireball"
      },

      {
        character: "Sass",
        check: "Dex Save ",
        description: "On Hadrian Fireball"
      },
    ],
    35: [
      {
        character: "Lars",
        check: "Javelin",
        description: "Throw at the Fire Giant with Junk in Cecil's Face"
      },
      {
        character: "Sass",
        check: "Perception",
        description: "to find Hadrian"
      },
      {
        character: "Corpo",
        check: "Investigation",
        description: "to see if the Carpets having flying capabilities (they don't)"
      },
      {
        character: "Cecil",
        check: "Investigation",
        description: "via Bowser's eyes to find the pipe goes down all the way"
      },
      {
        character: "Sass",
        check: "Perception",
        description: "to see where all the elementals are"
      },
      {
        character: "Lars",
        check: "Initative",
        description: "Flying down out of the elevator shaft screaming KNOCK KNOCK SUCK A DICK"
      },


      {
        character: "Lars",
        check: "Greataxe",
        description: "Lars Sentinel hits Efreeti, stopping him from getting Cecil"
      },

      {
        character: "Lars",
        check: "Greataxe",
        description: "Wailing on the Efreeti (Also got a 19 Crit right after)"
      },

      {
        character: "Corpo",
        check: "Unarmed Strike",
        description: "Punches Safavi and hits him 4 times"
      },
    ],
    36: [
      {
        character: "Cecil",
        check: "Perception",
        description: "Finding 3 exits from the chamber using Bowser"
      },

      {
        character: "Cecil",
        check: "Luck Check",
        description: "That no one is in the room they dash to...just dry goods"
      },

      {
        character: "Lars",
        check: "MeeleGreat Axe",
        description: "Recklessly attacks the door to burst down the door. Lars rushes in and sees the Magical Armory"
      },

      {
        character: "Sparka",
        check: "Stealth",
        description: "To sneak through the city as Giant Klaxons go off"
      },

      {
        character: "Sparka",
        check: "Stealth",
        description: "Double from advantage"
      },

      {
        character: "Corpo",
        check: "Insight",
        description: "check to know that he was trapped"
      },

      {
        character: "Sparka",
        check: "Con Save",
        description: "To resist strong Air Genasi Drank"
      },
    ],
    37: [
      {
        character: "Corpo",
        check: "Investigation",
        description: "As he puts pieces together...realizes Severin has Daddy in a lamp but Haalinith wasn't surprised. Connected?"
      },

      {
        character: "Lars",
        check: "Athletics",
        description: "Morning Fanging with Thistle"
      },

      {
        character: "Corpo",
        check: "Persuasion",
        description: "To convince Asmodeus to make a deal with them"
      },
    ]
  },
  "1s": {
    1: [
      {
        character: "Cecil",
        check: "Perception",
        description: "On watch, thought a wolf was attacking Wildon, then nearly insta-killed him with Vicious Mockery"
      },
      {
        character: "Lars",
        check: "Perception",
        description: "On watch, thought a wolf was attacking Wildon, then...see following entry"
      },
      {
        character: "Lars",
        check: "Melee",
        description: "Chained from previous...tried attacking imaginary wolf mauling Wildon, but missed and cut the last remaining support for the tent."
      },
    ],

    2: [
      {
        character: "Lars",
        check: "Persuasion",
        description: "Tried to convincing Fiddlestump that he is a hero and should go with them to the Cragmaw Hideout"
      }
    ],

    3: [
      {
        character: "Cecil",
        check: "Nature",
        description: "To examine the found Sabertooth Tiger Tooth"
      },
      {
        character: "Sass",
        check: "Nature",
        description: "To examine the found Sabertooth Tiger Tooth"
      },
      {
        character: "Sparka",
        check: "Persuasion",
        description: "Convincing Yeemik to not leave his cave"
      },
      {
        character: "Sparka",
        check: "History",
        description: "Know anything about the Lionshields after seeing symobl on a crate"
      },
      {
        character: "Sparka",
        check: "Perception",
        description: "To find further treasure in Blargh's cave"
      }
    ],

    4: [
      {
        character: "Sparka",
        check: "Fire burning chance",
        description: "How fast the Sleeping Giant would burn when he set it on fire"
      },
    ],

    5: [
      {
        character: "Cecil",
        check: "Perception",
        description: "To see if anything else hidden in guards barracks"
      },
      {
        character: "Sparka",
        check: "Arcana",
        description: "To decypher odd symbol around Skeletons' neck"
      },
      {
        character: "Lars",
        check: "Athletics",
        description: "To move a coffin"
      },
      {
        character: "Sparka",
        check: "Perception",
        description: "Listening at the jail door"
      },
      {
        character: "Lars",
        check: "Deception",
        description: "Resisting the Nothic's weird insight"
      },
      {
        character: "Lars",
        check: "Ranged",
        description: "Tried shooting a crossbow...clearly not used to the new design"
      }
    ],

    6: [
      {
        character: "Lars",
        check: "Melee",
        description: "Trying to hit Red Brands"
      },
      {
        character: "Lars",
        check: "Melee",
        description: "Unarmed Strike on Skylar, turning her around and revealing name tag"
      },
      {
        character: "Cecil",
        check: "Thieves Tools",
        description: "Failed to lockpick door into the chambers of Iarno \"Glasstaff\" Albrek"
      }
    ],
    7: [
      {
        character: "Corpo",
        check: "Persuasion",
        description: "Convincing the tavern something bad about Harbin Wester"
      },
      {
        character: "Corpo",
        check: "Slight of Hand",
        description: "Tried to....crush Harbin Wester's balls?...what?"
      },
    ],
    8: [
      {
        character: "Lars",
        check: "Persuasion",
        description: "Fails to Seduce Elsa",
      },
      {
        character: "Sass",
        check: "Survival",
        description: "Setup campsite in a gully, its rains and all are miserable for a bit",
      },
      {
        character: "Corpo",
        check: "Investigation",
        description: "Trying to find the Goblin rustling outside the camps",
      },
      {
        character: "Cecil ",
        check: "Perception",
        description: "Delayed notice the rotting bag, then thought it was nefarious",
      },
      {
        character: "?",
        check: "?",
        description: "NAT 1 somehow? Check? 2:04",
      },
      {
        character: "Lars",
        check: "Initiative",
        description: "Lars trying to fight the Banshee",
      },
      {
        character: "Sass",
        check: "Con Save",
        description: "Save on Banshee wail",
      },
      {
        character: "Corpo",
        check: "Con Save",
        description: "Save on Banshee wail",
      },
      {
        character: "Corpo",
        check: "Deception",
        description: "Telling Reidtoth the Cultists attacked him",
      },
    ],
    9: [
      {
        character: "Cecil",
        check: "Insight",
        description: "Notice that Lars acting weird",
      },
      {
        character: "Corpo",
        check: "Punch a Ghost",
        description: "Flurry of Blows to Punch a Shadow"
      }
    ],
    10: [
      {
        character: "Sparka",
        check: "History",
        description: "Knowing about the Harpers"
      },
      {
        character: "Sparka",
        check: "Arcana",
        description: "Identify Golem",
      },
      {
        character: "Cecil",
        check: "Arcana",
        description: "ID scribbles",
      },
    ],
    11: [
      {
        character: "Sass",
        check: "Survival",
        description: "Checking for footprints on the trail"
      },
      {
        character: "Corpo",
        check: "Performance",
        description: "Iron Man land on cart next to Maniere, breaks her leg"
      },
      {
        character: "Lars",
        check: "Insight",
        description: "To see if Hadrian recognizes him...Lars thinks no so goes and winks at him"
      },
    ],
    12: [
      {
        character: "Sass",
        check: "Perception",
        description: "To listen at Minotaur Skeleton Door"
      },
      {
        character: "Cecil",
        check: "Lockpicking",
        description: "Attempting to get a strongbox open, gets thieves tools jammed in there"
      },
      {
        character: "Cecil",
        check:  "Thieves Tools",
        description: "Gets lockipicks jammed in the old chest"
      },
      {
        character: "Lars",
        check: "Nature",
        description: "Identify mushrooms poison mushrooms, just notices the yummy ones"
      }
    ],
    13: [
      {
        character: "Lars",
        check: "Nature",
        description: "Harvesting the health mushrooms...loses 1 of the shrooms"
      },
      {
        character: "Lars",
        check: "Survival",
        description: "Investigating the water channel, he thinks only of it as a great to seduce Halfling women."
      },


      {
        character: "Sparka",
        check: "Dex Save",
        description: "To dodge Flameskull's fireball."
      },
    ],
    14: [
      {
        character: "Corpo",
        check: "Initiative",
        description: "Locked Room Fight"
      },
      {
        character: "Sass",
        check: "Longbow",
        description: "The Hobgoblin Captain"
      },
      {
        character: "Lars",
        check: "Axe",
        description: "Castrates Hobgoblin Captain, throws nuts outside and yells I AM THE DRAGON"
      },
      {
        character: "Sparka",
        check: "Arcana",
        description: "Check to figure out that the dagger must have been a portal key triggered by Corpo dying"
      },
      {
        character: "Sparka",
        check: "Arcana",
        description: "Knows that Thopleth used Blink and will return on his turn"
      },
      {
        character: "Corpo",
        check: "Unarmed Strike",
        description: "Attack Bugbear"
      },
      {
        character: "Corpo",
        check: "Staff",
        description: "Attack Bugbear"
      },
      {
        character: "Corpo",
        check: "Staff",
        description: "Attack Bugbear"
      }
    ],
    16: [
      {
        character: "Cecil",
        check: "Persuasion",
        description: "Check to convince Imperilas not to follow"
      }
    ],
    17: [
      {
        character: "Corpo",
        check: "Arcana",
        description: "Check to know about Giants and Dragons."
      },


      {
        character: "Sass",
        check: "Arcana",
        description: "To figure out Scroll of Opening"
      },


      {
        character: "Lars",
        check: "Persuasion",
        description: "To think on the Scroll of Opening"
      },
    ],
    18: [
      {
        character: "Corpo",
        check: "Animal Handling",
        description: "tries to calm Brian...is hurt  and offended since he is a vegetarian."
      },
      {
        character: "Cecil",
        check: "Perception",
        description: "Looking for Clouchaser....hmmmmmmmm"
      },
    ],
    19: [
      {
        character: "Corpo",
        check: "Melee Punch",
        description: "Whiffs punch on Ogre"
      },
      {
        character: "Sass",
        check: "Initiative",
        description: "Slow start on initiative for Jazelle fight"
      },
      {
        character: "Lars",
        check: "Melee Greataxe",
        description: "Attacking Abbishai"
      },
      {
        character: "Sass",
        check: "Arcana",
        description: "Figuring out where the chest contetns went...obviously distressed by killing Jazelle"
      },
      {
        character: "Lars",
        check: "Melee Greataxe",
        description: "Attacking Abbishai"
      },
    ],
    20: [
      {
        character: "Lars",
        check: "Initiative",
        description: "While slapping hewed in half guy with his dick on the bottom half"
      },

      {
        character: "Sass",
        check: "Stealth",
        description: "Has a momentary lapse of battle boy and lets out a moan of sorrow"
      },

      {
        character: "Sass",
        check: "Strength Save",
        description: "Gets flung down tower by Air Elemental"
      },

      {
        character: "Cecil",
        check: "History",
        description: "To know about runes in steering tower"
      },
    ],
    21: [
      {
        character: "Lars",
        check: "Persuasion",
        description: "Check to convince the giants he is with Blago and to go kill Hadrian"
      },

      {
        character: "Cecil",
        check: "Perception",
        description: "To see check what Hadrian is doing to the Ogres"
      },

    ],
    22:[
      {
        character: "Sass",
        check: "Arcana",
        description: "Check on Thopleth's perfect duplicate "
      },

      {
        character: "Lars",
        check: "Arcana",
        description: "Check on Thopleth's perfect duplicate "
      },

      {
        character: "Lars",
        check: "Javelin",
        description: "Throwing a Javelin at an Ogre"
      },

     {
        character: "Sass",
        check: "Arrow",
        description: "Shot to miss an Ogre"
      },

     {
        character: "Sparka",
        check: "Firebolt",
        description: "Shot to miss an Ogre"
      },

     {
        character: "Sass",
        check: "Arrow",
        description: "Shot to miss an Ogre"
      },

      {
        character: "Lars",
        check: "Dex Save",
        description: "Save to dodge rocks as the castle crashes"
      },


      {
        character: "Corpo",
        check: "Dex Save",
        description: "Save to dodge rocks as the castle crashes"
      },
    ],
    23: [
      {
        character: "Sparka",
        check: "Insight",
        description: "To try to read/look at Maisie, he is distracted by pretty girl"
      },
      {
        character: "Lars",
        check: "Athletics",
        description: "To wrestle GDE"
      },
    ],
    24: [
      {
        character: " Lars",
        check: "Perception",
        description: "At the bar looking for Thistle"
      },


      {
        character: " Sparka",
        check: "Insight",
        description: "On Dorrissa to check the two of them interacting"
      },


      {
        character: " Cecil",
        check: "Persuasion",
        description: "To convince Dorissa of his apology"
      },
    ],
    25: [
      {
        character: " Lars",
        check: "Persuasion ",
        description: "He just drops to his knees and bows down to the north bank woman"
      },

      {
        character: " Lars",
        check: "Stealth ",
        description: "Sneaking up to the cabin but the others catch him"
      },
    ],
    27: [
      {
        character: "Corpo",
        check: "Athletics",
        description: "To row away from Sober One"
      },
      {
        character: "Cecil",
        check: "Medicine",
        description: "To stabilize Corpo instead of doing a healing word"
      },
    ],
    32: [
      {
        character: "Yentil Wantanabe ",
        check: "Wisdom Save",
        description: "To save Cecil's Charm Person...something about how he is gay"
      },

      {
        character: "Lars ",
        check: "Animal Handling",
        description: "To make his dick big"
      },
    ],
    33: [
            {
        character: "Lars",
        check: "Perception",
        description: "To notice Shahmata"
      },

      {
        character: "Sparka",
        check: "Wisdom Save",
        description: "Shahmata's Frightening Presence"
      },

      {
        character: "Corpo",
        check: "Initiative",
        description: "To assault Volcanmote Castle"
      },

      {
        character: "GDE",
        check: "Lifeboat Piloting",
        description: "To pilot lifeboat in during assault on Volcanmote Castle"
      },

      {
        character: "Sparka",
        check: "Firebolt",
        description: "To hit a Sootborn named PeePeeHead"
      },

      {
        character: "Lars",
        check: " Investigate",
        description: "Checking for Treasure in the Fissure"
      },

      {
        character: "Corpo",
        check: "Investigate",
        description: "Checking for Treasure in the Fissure"
      },
    ],
    34: [
      {
        character: "Corpo",
        check: "Investigate",
        description: "Stealth as Corpo steps on Sass' white dragon wing cloak peaking around the corner"
      },

      {
        character: "Corpo",
        check: "Investigate",
        description: "Insight by staring at Torsus Skyrend trying to figure out if they are attracted the Dragon"
      },
    ],
    35: [
      {
        character: "Sass",
        check: "Longbow",
        description: "Miss the Efreeti"
      },
    ],
    36: [
      {
        character: "Sass",
        check: "Deception",
        description: "using Thamaturgy to say 'Just kidding not a problem) NAT 1 -1 for 0'"
      },

      {
        character: "Lars",
        check: "Persuasion",
        description: "to convince the Air Genasi to leave him alone with these children"
      },
    ],
    37: [
      {
        character: "Sparka",
        check: "Insight",
        description: "To see if Drussila's boss is big deal"
      },
    ]
  },
}

export default critData
