const itemTranslator = {
  types: {
    A: "ammunition",
    AF: "ammunition",
    AT: "artisan's tools",
    EM: "eldritch machine",
    EXP: "explosive",
    FD: "food and drink",
    G: "adventuring gear",
    GS: "gaming set",
    HA: "heavy armor",
    INS: "instrument",
    LA: "light armor",
    M: "melee weapon",
    MA: "medium armor",
    MNT: "mount",
    MR: "master rune",
    GV: "generic variant",
    P: "potion",
    R: "ranged weapon",
    RD: "rod",
    RG: "ring",
    S: "shield",
    SC: "scroll",
    SCF: "spellcasting focus",
    OTH: "other",
    T: "tools",
    TAH: "tack and harness",
    TG: "trade good",
    $: "treasure",
    VEH: "vehicle (land)",
    SHP: "vehicle (water)",
    AIR: "vehicle (air)",
    SPC: "vehicle (space)",
    WD: "wand",
  },

  getType: (type) => {
    return itemTranslator.types[type];
  },

  getPricing: (item) => {
    let basePrice;

    if(item.value === "?") {
      return item.value;
    }
    else if(item.value) {
      basePrice = item.value / 100
    }
    else {
      basePrice = itemTranslator.baseSelling[item.rarity];

      if (basePrice && basePrice !== "?" && (item.poison || item.type === "P" || item.type === "SC")) {
        basePrice = basePrice / 2;
      }
    }

    return `${basePrice} gp`;
  },

  priceRange: {
    none: "?",
    common: "50-100",
    uncommon: "101-500",
    rare: "501-5,000",
    "very rare": "5,001-50,000",
    legendary: "50,001-50,000",
    artifact: "???"
  },

  baseSelling: {
    none: "?",
    common: 80,
    uncommon: 400,
    rare: 4000,
    "very rare": 40000,
    legendary: 200000,
    artifact: "?"
  }
}

export default itemTranslator;
