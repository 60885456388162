const characterData = {
  pcs: {
    linkPlaceholders: {
      "Lars Rutschignass": {name: "Lars Rutschignass"},
      "Cecil": {name: "Cecil Solonna"},
      "Sparka Redlake": {name: "Sparka Redlake"},
      "Greosass": {name: "Greosass"},
    }
  },

  npcs:{
    general: {
      gundren: {
        name: "Gundren Rockseeker",
        race: "Dwarf",
        gender: "Male",
        class: "Commoner",
        status: "Alive",
        enabled: true,
        src: "https://static.wikia.nocookie.net/brightsidedm/images/9/9f/Gundren.png",
        tagLine: "Dwarven adventurer keen on a big discovery",
        blurb: "A dwarf hiring adventurers to escort supplies to the rough mining town of Phandalin. He is the brother of Tharden Rockseeker, and the older brother of Nundro Rockseeker.",
        recentEvents: `
          <h4>Session 1</h4>Hired the adventurers to escort Wildon Fiddlestump to Phandalin. They convinced him to hint that he thinks he's about to make a big find, and could make them rich if they keep working with him. He set out with a warrior escort named Sildar Hallwinter "take care of business" before the adventurers and supplies arrive.
          <h4>Session 2</h4> Party finds his and Sildar's horses dead on the side of the road, blood trail showing that they were drug away.
          <h4>Session 3</h4> Upon freeing Sildar from Cragmaw Hideout, they learn that Sildar's gear, Gundren, and Gundren's map was sent to Cragmaw Castle to King Grol.
          <h4>Session 8</h4> The Phandalin Five learn that there is a sort of auction to sell Gundren and his map off in Cragmaw Castle, with multiple groups attempting to purchase.
          <h4>Session 10</h4> The Phandalin Five gets invites to the King Grol auction from Avlod that implies a prisoner and map will be there too.
          <h4>Session 11</h4> With the Goblins msotly distracted by Lars as Genobli the Most Endowded, Sass and Sparka snuck into the castle and freed him. They briefly left him in a prepared hideout near the castle before getting him and the gear and heading out.
          <h4>Session 12</h4> Party left him with mystery Hobgoblin and Elsa in the woods
          <h4>Session 15</h4> Phandalin Five barter his offer of 10% of profits once the mine starts running to 15%. He takes unnamed Hobgob into the mine abnd tells the adveturers that he'd hire people from Phandalin.
        `
      },

      tharden: {
        name: "Tharden Rockseeker",
        race: "Dwarf",
        gender: "Male",
        class: "Commoner",
        status: "Dead",
        enabled: true,
        src: "https://www.scabard.com/user/agoodhew/image/tharden.png",
        tagLine: "Gundren's Deceased Brother",
        blurb: "A dwarf who found Wave Echo Cave with his brothers, but the Phandalin Five found him dead. He is the brother of Gundren Rockseeker, and the older brother of Nundro Rockseeker.",
        recentEvents: `
          <h4>Session 12</h4> Party find his skeleton at the entrance to Wave Echo Cave
        `
      },

      nundro: {
        name: "Nundro Rockseeker",
        race: "Dwarf",
        gender: "Male",
        class: "Commoner",
        status: "Dead",
        enabled: true,
        src: "https://www.scabard.com/user/agoodhew/image/tharden.png",
        tagLine: "Gundren's Other Deceased Brother",
        blurb: "A dwarf who found Wave Echo Cave with his brothers, but is currently missing. He is the brother of Gundren Rockseeker and younger brother of Tharden Rockseeker.",
        recentEvents: `
          <h4>Session 12</h4> Party find his skeleton in the Black Spider's Lair in Wave Echo Cave
        `
      },

      sildar: {
        name: "Sildar Hallwinter",
        race: "Human",
        gender: "Male",
        class: "Fighter: Battle Master",
        status: "Alive",
        enabled: true,
        src: "https://i.pinimg.com/originals/4b/1d/d8/4b1dd8eae00f93ff6066a103ec7caa78.png",
        tagLine: "Agent of the Lord's Alliance",
        blurb: "He is a kindhearted man of nearly 50 years old. Sildar says that he is a retired mercenary captain, but it seems that there is more to him than that. At first he was secretly undercover, but later revealed himself as an Agent of the Lord's Alliance.",
        recentEvents: `
          <h4>Session 1</h4>Cecil noticed he did not seem like a simple bodyguard. He left to Phandalin esorting Gundren Rockseeker.
          <h4>Session 2</h4>The party discovers the horses for him and Gundren Rockseeker dead on a trail as Goblins ambush them. From interrogating Goblins the party learns that Sildar is back in the Cragmaw Hideout in the "Eatin' Cave"
          <h4>Session 3</h4>He is barely alive when the party parleys with Yeemik. He speaks in Elven, saying not to trust the Goblin, and Sass telepathically tells him to hang on, Sildar is surprised. Cecil then uses an illusion of King Blargh to scare Yeemik off, leaving Sildar free. He says to get to Phandalin, where he gives some nice narrative exposition about the town. Later, he reveals to Sparka and Corpo that he is an Agent of the Lord's Alliance. He is sent to take over the mission, securing the town and finding the mine to create prosperity, of a missing agent, Iarno Albrek. Then he is to discover what happened to his comrade. He is exhausted from being tortured by Goblins and passes out.
          <h4>Session 4</h4>His near death experience leaves him exhausted. He sleeps soundly through the tavern brawl and the interrogation/murder of Tris.
          <h4>Session 6</h4>Lars Rutschignass informs Sildar Hallwinter that Iarno was Glasstaff...was cause he's dead. Sildar Hallwinter says he will bring stability to the town, tells party to keep after Gundren.
          <h4>Session 7</h4> Sildar informed the party that he hopes they track down Gundren Rockseeker. He also introduces them to Qelline Alderleaf and says she has a Druid contact who will lead them to Cragmaw Castle. He says that the Townmaster is not his favorite person.
          <h4>Session 11</h4> Sparka and Sass find Sildar's sword in Cragmaw Castle when liberating Gundren.
          <h4>Session 15</h4> He arrrests Harbin after Succubus reveals herself

        `
      },

      willie: {
        name: "Wildon Fiddlestump",
        race: "Halfling",
        gender: "Male",
        class: "Monk / Cleric:Mooon",
        status: "Convalescence",
        enabled: true,
        src: "https://2.bp.blogspot.com/-pKKhpiMsrRI/WGT2Gbl82gI/AAAAAAAABuE/kLXnwlVvjzkD8jIAuyepK3pP35shf-dyQCLcB/s1600/PZO8500-GaltHalfling.jpg",
        tagLine: "Aspiring halfling merchant",
        blurb: "Wildon talks as if he is a successful merchant, but signs suggest otherwise. He dreams that he is a wealthy merchant in the making, and talks as if he is already threre. He has reasons to be desperate to make a name and coin for himself.",
        recentEvents: `
          <h4>Session 1</h4> Gundren Rockseeker hired his wagon to deliver supplies to Phandalin. He makes bold claims that are clearly lies, which Corpo despises. The first night adventuring, Corpo made him sleep in a collapsed tent. Cecil Solonna then unleashed vicious mockery on him, but then healed him just in time. An hour later, Lars Rutschignass nearly murdered him with a greataxe. It was an eventful night.
          <h4>Session 2</h4> Corpo shoves back into the cart by a gust of wind, saving his life from a Goblin sneak attack. He survives the attack, then agrees to take his wagon with them towards thre Goblin lair.
          <h4>Session 3</h4> When Corpo goes comatose, he volunteers to guard the monk with his dull dagger. After dropping off supplies in Phandalin, he agrees to hide the wagon by the Lionshield's.
          <h4>Session 4</h4> When Redbrands find the wagon, he hurls insults and spit to prevent them from discovering Corpo's body. Corpo hears that he was taken to the slave pens.
          <h4>Session 5</h4> Interrogating Redbrands the party discovers Wildon's clothes under "Khad's Guide to Adventuring". But he used Cecil Solonna's insults and was beaten and taken away.
          <h4>Session 6</h4> The Bug Bears who were torturing him
          <h4>Session 7</h4> Resting off being tortured and a near-death and experience
          <h4>Session 11</h4> Sparka sees a vision of Wildon being hung by Harbin Wester.
          <h4>Session 15</h4> Halia Thornton reveals Wildon fought off thugs on a roof declsring that the Phandalin Five were innocent. Is going to be hung as Lars enters the green. When Lars walks away, Harbin Wester slits his throat, Corpo gusts over to attack Harbin and give Wildon a potion. Wildon thanks Corpo woth words out of Brokeback Mountain.
        `
      },

      leosin: {
        name: "Leosin Erlanthar",
        race: "Half-Elf",
        gender: "Male",
        class: "Monk: Open Hand",
        status: "Alive",
        enabled: false,
        src: "https://static.wikia.nocookie.net/forgottenrealms/images/7/75/Leosin.png",
        tagLine: "Harper Agent",
        blurb: "",
        recentEvents: ""
      },

      reidoth: {
        name: "Reidoth the Druid",
        race: "Human",
        gender: "Male",
        class: "Druid: Circle of Spores",
        status: "Alive",
        enabled: true,
        src: "https://whfrp.weebly.com/uploads/1/2/4/0/12408478/439775023_orig.jpg?307",
        tagLine: "Dedicated Druid",
        blurb: "Reidoth is a gaunt, white-bearded human druid of the Circle of Spores. Though he receives very few visitors, he is reasonably hospitable and enjoys gardening in Thundertree. In general he seems to be more than he seems.",
        recentEvents: `
          <h4>Session 7</h4> The Phandalin Five was told by Qelline Alderleaf that he knows 'everything' about the area and would surely know about Cragmaw Castle and maybe even Wave Echo Mine.
          <h4>Session 8</h4> The Phandalin Five Make their way towards Thundertree to speak to him. He is disguised, but greets the party warmly. Lars notices his conspiracy board about the Retun of the Queen, which Reidoth explains he believes relates to him...despite many clues referencing Lars if one is a Native speaker of Dwarven.
          <br/><br/> Reidoth gladly tells them the location of Cragmaw Castle, and says he will tell them of Wave Echo Cave if they defeat the Dragon Venomfang for him. The Five goes to fight Venomfang...though the Green Dragon tells many tales about Reidoth
          <h4>Session 9</h4> He comes to the tower after the party slays Venomfang, excited. Sparka Redlake queries him about what Venomfang said, eventually allowing him to enter into his mind to Detect Thoughts unopposed. Sparka sees that Reidoth, while into undead, is not malicious towards them.
          <br/><br/> Reidoth says that he saw the mushroom-spore cloud and is not sure what it is. He starts hacking and trembling as Bamur reveals himself to Lars. Bamur starts to puppet Reidoth, but Sparka and Corpo tie him up. He is unable to control the spores, but is unable to attack the party. After the battle, Lars gives him a psychadellic mushroom and the mental change kills the spores.
          <h4>Session 10</h4> Bids the party good-bye as he recovers.
        `
      },

      zilestor: {
        name: "Zilestor the Cheerful",
        race: "Human",
        gender: "Male",
        class: "Commoner",
        status: "Alive",
        enabled: true,
        src: "https://drive.google.com/uc?id=1r6A0AWAnsaIIGz2SwzsBDqisI9nfFGcB",
        tagLine: "Family Man Farmer",
        blurb: `
          Happy family man settled in Angel Acres with his wife Charmayne. Together they have 6 kids: twins Amali and Alais, Alynalore, Kurva, Jacinthe, Hilda, one one the way. Animals are huskies named Ragnarok and Dionysius, along wiith an ox named Ignatius.
        `,
        recentEvents: `
          <h4>Session 7</h4> Daran Edermath asked the party to investigate rumors that a Red Wizard was spotted to the north. He points them to the settlement of Angel Acres, a village to the North just under a day from the reported site. While he knows most of the community, Daran asks them to deliver some cider to Zilestor and his family. He says that Zilestor will make sure they are taken care of.
          <h4>Session 8</h4> The Phanadlin Five are sent to Zilestor and his family from Half-Orc Lorc. He relays information about the Red Wizard and the Banshee before being hospitable.
          <br/><br/>
          He has his twins Amali and Alais relay their story about Hamun Kost and what the zombies were like. Sparka notices that Charmayne seems nervous about feeding them and Cecil noticed that they had a grain bag rot away.
          <br/><br/>
          Sparka Redlake mage hand slips one gold per each member of the family into the chimney. He winks at Jacinthe and Hilda who noticed.
        `
      }
    },

    nobility:{
      stoneblood: {
        name: "Phineas Stoneblood",
        race: "Rock Gnome",
        gender: "Male",
        class: "Artificer: Battlesmith",
        status: "Alive",
        enabled: true,
        src: "https://i.pinimg.com/originals/5d/9f/6f/5d9f6fa01825e470a2ff523f1f5af6d4.jpg",
        tagLine: "Historical and Maigcal Item Expert",
        blurb: "A mighty hero of the North, the first artificer, and top historian, the legend of Phineas Stoneblood knows no bounds. Currently he splits his time between being a Provost for the College of Heralds, researching magical items, and of course slaying evil.",
        recentEvents: `
          <h4>Session 0</h4> He has visited Semla and Karvin's Stonecutter Inn multiple times, including with Drizzt Do'urden, Ignatius Brassblood II, and Geoff.
          <h4>Session 7</h4> Maise Summerbluff told Sparka Redlake that she was going to Neverwinter to study with Phineas Stoneblood, who might know about his arcane focus.
        `
      },
      neverember: {
        name: "Lord Dagult Neverember",
        race: "Human",
        gender: "Male",
        class: "Fighter: Samurai",
        status: "Alive",
        enabled: true,
        src: "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/cd0917f8-e55f-454a-aeae-7d3d7c3bb79a/dcxj9zl-761fe2f9-1fbb-4e5e-87dc-6ba3c84fdb6b.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2NkMDkxN2Y4LWU1NWYtNDU0YS1hZWFlLTdkM2Q3YzNiYjc5YVwvZGN4ajl6bC03NjFmZTJmOS0xZmJiLTRlNWUtODdkYy02YmEzYzg0ZmRiNmIuanBnIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.llZfvGlGsy3Xl7g4KUkKQ3sQO5JHr0sFQanoTEViLXs",
        tagLine: "Lord Protector of Neverwinter",
        blurb: "Young man dreamed of becoming a merchant, he found success and married into a successful family as well. After the destruction of Neverwinter, he led the New Neverwinter campaign to rebuild the city. Dagult claimed descent from kings of old, then used his own fortune to rebuild the city and pay for refugees to resettle. He wished to gain the support of the people, many of whom resisted his rule due to his lineage. Multiple groups resisted him, some with limited Harper supporter due to fears of his Imperialist ambitions. <br/><br/>  However, his campaign appeared to be succeessful. The surrounding areas, still dangerous from the Spellplauge, accepted Neverwinter support. This resulted in towns like Port Llast and Leilon being drawn into to Neverwinter's orbit. More importantly however, they viewed Neverwinter and Neverember as their saviors <br/><br/> However, by 1487 DR with the city's influence surging and trade safely reaching the city's gates, the locals of Neverwinter finally accepted his rule. As this success comes with Neverwinter's vaults being emptied, the underground movements even volunteered to help. There is finally a sense of unity and acceptance that his ambitions could just help the city.",
        recentEvents: ""
      },
    },

    corpoBackground: {
      black_storm: {
        name: "The Black Storm",
        race: "Human...?",
        gender: "Female",
        class: "???",
        status: "Alive",
        enabled: true,
        src: "https://drive.google.com/uc?id=14ffeVfYAJX70devM2McMu1lwkHSEYBpm",
        tagLine: "Corpo's Grandmother/Mariner's Posrt Llast Legend",
        blurb: "Legendary figure around Port Llast that tales claimed would drive Pirates. Also apprently the mothrer of Mirin, making her Corpo's grandmothrer.",
        recentEvents: `
          <h4>Session 11</h4>
        `
      },
      mirin: {
        name: "Mirin",
        race: "Air Genasi",
        gender: "Female",
        class: "???",
        status: "Dead",
        enabled: true,
        src: "https://icon2.cleanpng.com/20180402/sgw/kisspng-headstone-grave-cemetery-clip-art-rip-5ac2eaa9500ec1.9494589415227234973279.jpg",
        tagLine: "Corpo's Deceased Mother",
        blurb: "She was an accomplished air genasi warrior and, during a training exercise with Corpo in the Stormhill Mountains, Corpo used levitate but went too far sending his mother thousands of feet into the air. She had already used levitate in the course of training and did not have the stamina to cast a spell powerful enough to break her fall. Corpo watched in horror as his mother plummeted to the earth. By the time he reached her, he knew this was the end. She let her hand fall to her side allowing a bright gemstone containing a raging storm to fall to the ground. Her last words to Corpo were, \"Find Tel'Amoth dear, he is your father and the key to a power within you that you could never imagine - you will be as terrible as a whirlwind my love.\" Supposedly the daughter of The Black Storm",
        recentEvents: `
          <h4>Session 11</h4> The Black Storm references her daughter and grandson when telepathically talking to Sparka.
        `
      },
      telamoth: {
        name: "Tel'Amoth ",
        race: "Djinni (?)",
        gender: "Male (?)",
        class: "???",
        status: "???",
        enabled: true,
        src: "https://media.tenor.com/images/8af08fee80d6176f9da9d33fab375926/tenor.gif",
        tagLine: "Corpo's Alleged Father",
        blurb: "Corpo's mother told him the name Tel'Amoth as she died, giving Corpo a gem that will fit in Tel'Amoth's sword. She told her son to find his father, and he shall become powerful.",
        recentEvents: `
          <h4>Session 0</h4>Corpo is searching for him.
          <br/><br/> <h4>Session 14</h4> Corpo appears to see Tel' Amoth's lamp with Severin Solonna
        `
      },
      nestempus: {
        name: "Nes'Tempus",
        race: "Air Genasi",
        gender: "???",
        class: "???",
        status: "Alive",
        enabled: true,
        // src: "",
        tagLine: "Elder Air Genasi",
        blurb: "Old and Wise Air Genasi living in The Shinning Plains.",
        recentEvents: "<h4>Session 0</h4>Told Corpo to search for a Djinn living in the middle of the High Ice."
      },
    },
    cecilBackground:{
      matrim: {
        name: "Matrim Solonna",
        race: "Gold Elf",
        gender: "Male",
        class: "Commoner",
        status: "Alive",
        enabled: true,
        // src: "",
        tagLine: "Cecil's Runaway Elven Father",
        blurb: "He is a high elf history keeper who ventured from his Gold Elf House of Bannighymn to seek knowledge and wisdom of the world. Despite Matrim’s family being the Holders of the Forgotten Ways they did not approve of his leaving the security of his families enclave, believing him to be too rash and immature as he had only recently been declared an adult. During his travels and adventures Matrim met and ultimately fell in love with with Fortuona Paendrag, a human orphan with no known relatives. Since Matrim had left the family under less than ideal circumstances his relationship never found the blessing of the House Bannighymn.",
        recentEvents: "<h4>Session 4</h4>A Gold Elf offered Lars Rutschignass money to gain information on Cecil's parents, but Lars refused, saying he valued belonging."
      },
      fortuona: {
        name: "Fortuona Paendrag",
        race: "Human",
        gender: "Female",
        class: "Commoner",
        status: "Alive",
        enabled: true,
        // src: "",
        tagLine: "Cecil's Mother, the Orphan",
        blurb: "She met Matrim Solonna as a human orphan with no known relatives. Fortuona spend her years roaming around Turmish, gathering stories and telling tales of adventure on the Sea of Fallen Stars to earn a living.",
        recentEvents: "<h4>Session 4</h4>A Gold Elf offered Lars Rutschignass money to gain information on Cecil's parents, but Lars refused, saying he valued belonging."
      },
    },
    larsBackground: {
      ole: {
        name: "Ole",
        race: "Dwarf",
        gender: "Male",
        class: "Ghostslayer:Blood Hunter/Wizard",
        status: "Alive",
        enabled: true,
        src: "https://cdna.artstation.com/p/assets/images/images/025/106/716/large/casey-holzinger-transparent-gamil.jpg",
        tagLine: "Twin of Bamur and former friend of Lars",
        blurb: "A Dwarf from the original home of Lars Rutschignass and taken into new colony. At the Dwarphanage Lars recalls playing games with the brothers, Ole played smart and Lars read people. Like his brother and Lars, became mushroom farmers. However, Lars quickly outpaced them with his success.",
        recentEvents: `
          <h4>Session 8</h4> Ole meets Lars in Angel Acres in the Platinum Inn, when Ole convinces his dwarven comrades not to fight Lars. He reveals that he came back from university where he studiies as a Wizard to fight in the War of the Silvermarches to redeem the name of Grimstone Peak.
          <br/> He stopped his wizard studies and signed up for a dangerous experimental procedure that not all survive. He did and now hunts the undead, hoping his exploits outshine his brother's shame.
          <br/> Ole tells Lars that Bamur worships Zuggtmoy, the Queen of Mushrooms, and has been spreading prophecies that Lars is her herald to bring about her return to this Plane. Bamur believes that The Return of the Queen prophecy applies to her.
          <br/> Lars recalls that she wishes to spread like Fungi, controlling and destroying all. Ole says that Bamur led a revolution in the small Dwarf stronghold of Iron Deeper, resulting in chaos and anarchy for his Demon Queen. Ole makes Lars promise to kill Bamur if he sees him.
        `
      },
      aubemoux: {
        name: "Aubemoux",
        race: "Dworc",
        gender: "Male",
        // class: "Ghostslayer:Blood Hunter/Wizard",
        status: "Alive",
        enabled: false,
        src: "https://www.worldanvil.com/uploads/images/f8cc09c6dd573773c3bbc49d9972c302.jpg",
        tagLine: "Young Dworc Orphan",
        blurb: "",
        recentEvents: `
        `
      },

    },
    sassBackground: {
      jazelle: {
        name: "Prexijandilin Jazelle",
        race: "Dragonborn",
        gender: "Female",
        class: "Dragonborn",
        status: "Dead",
        enabled: true,
        src: "https://icon2.cleanpng.com/20180402/sgw/kisspng-headstone-grave-cemetery-clip-art-rip-5ac2eaa9500ec1.9494589415227234973279.jpg",
        tagLine: "Deceased Lover of Greosass",
        blurb: "Lover of Greosass, threatened by Glazhael to force Greosass to kill clan leaders, then he killed her anyway.",
        recentEvents: `
          <h4>Session 7</h4> Her brother Tarhun arrives in Phandalin, believing that Greosass killed her.
        `
      },
      surina: {
        name: "Drachedandion Surina",
        race: "Blue Dragonborn",
        gender: "Female",
        class: "Scout",
        status: "Alive",
        enabled: true,
        src: "https://i.pinimg.com/564x/29/ef/f4/29eff4bfdfa397b95a3fc05bf56dde34.jpg",
        tagLine: "Niece of Clan Elder Sass Killed",
        blurb: "Niece of Clan Elder Sass Killed, swore blood oath on Strength and Honor to kill him.",
        recentEvents: `
          <h4>Session 7</h4> Arrive in Phandalin to challenge Sass to a duel. Is restrained by the Earthen Grasp of Sparka Redlake, then put to sleep.
        `
      },
      tarhun: {
        name: "Prexijandilin Tarhun",
        race: "Red Dragonborn",
        gender: "Male",
        class: "Fighter",
        status: "Alive",
        enabled: true,
        src: "https://cdn11.bigcommerce.com/s-dhlsncdqp9/images/stencil/1280x1280/products/4426/4108/wzk93006_web_content__40575.1592774182.jpg",
        tagLine: "Brother of Jazelle",
        blurb: "Brother of Jazelle who knew Sass from back in the day.",
        recentEvents: `
          <h4>Session 7</h4> Arrive in Phandalin to challenge Sass to a duel. Is castrated by Lars and is dying but Sass heals him (and his balls). Believes Sass that Cloudchaser forced him to slay the elders and then killed Jazelle. Tells Sass to leave, he will escape since Oath of Strength and Honor demands him.
        `
      },
      Rhogar: {
        name: "Norixius Rhogar",
        race: "Green Dragonborn",
        gender: "Male",
        class: "Halberd",
        status: "Alive",
        enabled: true,
        src: "https://i.pinimg.com/564x/77/d4/77/77d4770943e19ca1dcea6641e4ca3ae9.jpg",
        tagLine: "Grandson of Clan Elder Sass Killed",
        blurb: "Grandson of Clan Elder Sass Killed, then swore on Strength and Honor to kill Sass.",
        recentEvents: `
          <h4>Session 7</h4> Arrive in Phandalin to challenge Sass to a duel. Is restrained by the Earthen Grasp of Sparka Redlake, then put to sleep.
        `
      },
    },
    wanderingPost: {
      rico: {
        name: "Rico",
        race: "Dohwar",
        gender: "Male",
        class: "Commoner",
        status: "Alive",
        enabled: true,
        src: "https://preview.redd.it/cdbxzfcl0yb81.png?auto=webp&s=1d0cfa759e957b3ea7ea674f268f0d26bfdb4e6c",
        tagLine: "Trader Mafia Pirate Penguin",
        blurb: "High ranking Captain in his Cartel (Dohwar clans work in units called Cartels) Can be pushy but will pay under price for everything.",
        recentEvents: "<h4>Session 10</h4>Sparka contacts him via Thrift's Wandering Post. He is in the Air Elemental Plane and yells at his children."
      },
      wemnetan: {
        name: "Wemnetan al-Fakhuwr",
        race: "Noble Dao",
        gender: "Male",
        class: "Noble Genie",
        status: "Alive",
        enabled: true,
        src: "https://angrygolem-games.com/wp-content/uploads/2021/11/Dao.jpg",
        tagLine: "Powerful Dao Merchant with an eye for extravagant finished goods.",
        blurb: "A noble Dao of great wealth and power, ranking Member of the Outer Plane Khanate Trading Company. Currently his Earthen Mansion is adjacent to the Grand Market, Sevenfold Mazework, Plane of Earth",
        recentEvents: "<h4>Session 12</h4>Sparka contacts him via Thrift's Wandering Post to buy potions and sell treasures. Sparka asks him about Yan-C-Bin, to which the Dao is like yeah he big deal. Sparka ends the spell when Wemnetan starts suggesting to give him Corpo."
      },
      daurotham: {
        name: "Daurotham",
        race: "Emerald Dragon",
        gender: "Male",
        class: "Ancient",
        status: "Alive",
        enabled: true,
        src: "https://static.wikia.nocookie.net/forgottenrealms/images/5/5d/Emerald_dragon_5e.jpg",
        tagLine: "Emerald Dragon of Otoño, premier merchant of secrets.",
        blurb: "Emerald Dragon living in a ruin from Otoño of the First World. Is a merchant of secrets, an information broker raising some young at the same time. A working dad.",
        recentEvents: `
          <h4>Session 13 (Flashback to previous)</h4>Sparka contacts him via Thrift's Wandering Post as he is in disguise as Yanni Yanniki
          <br/><br/> Daurotham reveals that he specializes in being a merchant of secrets.
          <br/><br/> Sparka notices that there are baby dragons crying out for food in the background...he pays a platinum for secrecy and shows his true form, a towering Ancient Emerald Dragon.
          <br/><br/> He spends some platinum and gold to buy Halia Thornton's info from Sparka
          <br/><br/> Sparka pays him a decent amount of platinum to get info on his Arcane Focus and...well like everything
          <br/><br/> Dauro relates that the Arcane Focus is a Dragonshard. He says that Dragonshards contain a Draconic Echo that did not have an actual Dragon on the First World. He explains their power how they are similar to Moonblades.
          <br/><br/> Dauro detects that Sparka has some of his blood...granting Emerald Dragon powers. But, it is recessive in House Amarillis, so whichever of Sparka's parent was of House Amarillis, probably the other had Brass Draconic and Divine Soul Sorcerer heritage.
        `
      }
    },
    sparkaBackground: {
      semla: {
        name: "Semla Gumtree",
        race: "Human",
        gender: "Female",
        class: "Commoner",
        status: "Alive",
        enabled: true,
        // src: "",
        tagLine: "Sparka's Adopted Mother",
        blurb: "Co-proprietor of the Stonecutter's Solace Inn with her husband, best and only inn in Port Llast. Very popular in town, and even legends of Faerûn have stayed there, including legendary Drow ranger Drizzt Do'Urden, Phineas Stoneblood, Geof the Shadow, and Ignatius Brassblood II. Raised Sparka after he was left at their door.",
        recentEvents: "<h4>Session 0</h4>Told Sparka everything they know about his mother, which is __________________________________. Then she gave him plenty of snacks and sent him on his way."
      },
      karvin: {
        name: "Karvin Gumtree",
        race: "Human",
        gender: "Male",
        class: "Commoner",
        status: "Alive",
        enabled: true,
        // src: "",
        tagLine: "Sparka's Adopted Father",
        blurb: "Co-proprietor of the Stonecutter's Solace Inn with his wife, best and only inn in Port Llast. Very popular in town, and even legends of Faerûn have stayed there, including legendary Drow ranger Drizzt Do'Urden, Phineas Stoneblood, Geof the Shadow, and Ignatius Brassblood II. Raised Sparka after he was left at their door.",
        recentEvents: "<h4>Session 0</h4>Told Sparka everything they know about his mother, which is __________________________________. Then he told him to stay strong, not trust someone who won't look you in the eye, how to change a wagon wheel, don't leave candles lit when you leave a room, and to make sure you take the standard deduction."
      },
      redlake: {
        name: "Mother Redlake",
        race: "Elf(?)",
        gender: "Female",
        class: "???",
        status: "???",
        enabled: true,
        src: "https://www.howdoyouplay.net/wp-content/uploads/2013/01/mysteryperson.jpg",
        tagLine: "Sparka's Alleged Mother",
        blurb: "All Sparka knows about his mother is _____________________ and that her surname was Redlake.",
        recentEvents: `
          <h4>Session 0</h4>Sparka is searching for clues about her name Redlake.<h4>Session 3</h4>Spark alearned from Maise Summerbluff that the name Redlake to Chromatic Dragons is a dark tale of an unknown force of death that devastates Dragon lairs while they are out.
        `
      },
    },
    phandalin: {
      daran: {
        name: "Daran Edermath",
        race: "Half-Elf",
        gender: "Male",
        class: "Fighter: Arcane Archer - Paladin: Devotion",
        status: "Alive",
        enabled: true,
        src: "https://images.kanka.io/user/qIbGzrNn5wJkcnn1LXPBNMbkszo=/src/characters%2FC2g3BCg0oIFdcULSnEHjnPT1GGF8qrCdIRsJdr5W.jpeg",
        tagLine: "Retired adventurer wanting cider and peace",
        blurb: "Daran Edermath is a retired adventurer who lives in a tidy little cottage beside an apple orchard, called Edermath Orchard. A fit, silverhaired half-elf well over a hundred years old, had joined the Order of the Gauntlet, and served as a marshal and herald for many years in the lands of the Dragon Coast, far to the southeast. There he battled countless enemies, earned glory, but gave a bandit a powerful sword and lost his love when he was victim to treachery. He continued on, but was not the same person any more. Upon retiring, he returned to the Neverwinter region, his original home. While retired, he keeps in touch with the Order of the Gauntlet in the region, keeping tabs on Phandalin for them.",
        recentEvents: `
          <h4>Session 3</h4> Is playing a soulful, mournful bittersweet tune of loss yet love when Greosass hears him. They talk and Sass notices that the old Half-Elf was part of the Order of thre Gauntlet. He tells Sass the story of his lost love. Then he grants Sass some special arrows, incluidng a minor dragon slaying arrow if Sass also promised to take the arrow he made of his love's staff and use it if he ever met the Black-Flame-Sword Mercenary. He then offers Sass to sleep in the hayloft.
          <h4>Session 4</h4> He finds Sass with the Thunder Drake, says it had been snooping around for a while. He then tells Sass that Redbrands are heading towards the Inn where his friends are. He later tosses a healing potion to Sass on the way to fight the Redbrands.
          <h4>Session 6</h4> He helps put out the Sleeping Giant Inn blaze
          <h4>Session 7</h4> Extols the Order of the Gauntlet and their values to the party. Asks them to look for a Red Wizard north of here, if they notice anything tell him or report the Hall of Justice in Neverwinter. He later tells Sass that two Dragonborn wrote to him, saying that a Young Green Dragon was spotted flying in the area, and that their honor depends on it dying.
        `
      },

      elmar: {
        name: "Elmar Barthen",
        race: "Human",
        gender: "Male",
        class: "Commoner",
        status: "Alive",
        enabled: true,
        src: "https://db4sgowjqfwig.cloudfront.net/images/4783881/roll20_ElmarBarthen.jpg",
        tagLine: "Proprietor of Barthen's Provisions",
        blurb: "Elmar Barthen is a kindly middle-aged shopkeeper. Lean and bearded, Elmar is the proprietor of Barthen’s Provisions with his the help of his son, Anders, and daughter, Thistle. Born in 1451 DR, Elmar was one of the settlers who helped re-establish Phandalin in 1474 DR after his wife was killed. He and his children wanted a change of scenery. His shop helped bring all manner of supplies and equipment for those wishing to build a life in the rugged frontier. During this time, Elmar struck up a firm friendship with Gundren Rockseeker whose excited forays into the hills around Phandalin made generous use of Barthen’s Provisions.",
        recentEvents: `
          <h4>Session 1</h4>The party was hired by Gundren Rockseeker to escort Wildon and supplies to Barthen.
          <h4>Session 3</h4>Party arrived in Phandalin and dropped supplies at Elmar's. He is mad at Gundren for going alone without the full party, but says the bandits are a worse problem. His daughter Thistle wanted to go help the adventurers, but Elmar curtly ordered her back to the backroom.
          <h4>Session 4</h4> Party attempts to seel Goblin weapons, but he sells supplies.
          <h4>Session 6</h4> He helps put out the Sleeping Giant Inn blaze
          <h4>Session 7</h4> At the party seems dour as usual, though the party noticed an usual marbled crystal around his neck.
          <h4>Session 18</h4> Sparka goes to warn the town to get ready to get for the portal...and Sparka sees his crystal. He is like my kids made it in art class. Sparka sees that he like can't see it? But like Sam in LOTR can't quite let it go. Arcana to see magic is making him not see it but refuses to let it go.
          <h4>Session 22</h4> See him toast the party and see his crystal around his neck.
          <h4>Session 23</h4> At the we didn't die party, Elmar gives Corpo money for pleasure to keep him from his daughter...but....LARS GETS IN THERE
          <h4>Session 27</h4> The P5 hear that the Empire is essentially looking for his crystal, so they try to convince him to hand it over. He is the same freaking out about it, so Lars gets Thistle to help. He still is resisting, so Corpo tries...to...cup his balls? Lars says I'll return it when I ask for your daughter's hand...he isn't happy for some reason. Anyway eventually Sparka Mage Hand steals it, he freaks and Sparka casts Sleep. He awakes and Spark convinces him someone stole it
        `
      },

      thistle: {
        name: "Thistle Barthen",
        race: "Human",
        gender: "Female",
        class: "Rogue: Soulknife",
        status: "Alive",
        enabled: true,
        src: "https://i.pinimg.com/564x/18/63/8c/18638cea0b3e7b164e39d574b744713e.jpg",
        tagLine: "Clerk in Barthen's Provisions",
        blurb: "Thistle Barthen is the 21-year-old daughter of Elmar Barthen and helps in the store. She has only vague memories of her mother, killed when she was an infant. Thistle wants to get out and leave her claimed boring town, more than anything and go adventuring.",
        recentEvents: `
          <h4>Session 3</h4> She looks impressed at Sparka for declaring he will rid the town of these bandits. She begs to go with but her father curtly tells her to go in the back.
          <h4>Session 6</h4> She helps put out the Sleeping Giant Inn blaze
          <h4>Session 7</h4> Gives the party a stolen ledger from Harbin Wester showing that he embezzled. She later plants a forged letter to incriminate him.
          <h4>Session 22</h4> Thistle yells "WE DIDN'T DIE" when the town walks through the portal
          <h4>Session 23</h4> Elmar tries to bribe Corpo to not flirt with his Daughter but instead Lars is like super flirty and goes downtown with her and says she is a step up...multiple two feet up from Qelline. They go downtown
          <h4>Session 24</h4> Lars after the tournament goes to find Thistle...can't find her...but finds his flask is missing. Can't find her, gets the bar owner to check her room...and steals 3 pairs of her underwear. At the bar then sees her flirting with soliders. Corpo notices she is actually just stealing from them, using his flask to get them drunk. The two go up together, rejecting Corpo's request for tag team. After Lars finishes...ahem...he leaves. Corpo thanks her for making his friend happy...weirdly.
        `
      },

      anders: {
        name: "Anders Barthen",
        race: "Human",
        gender: "Male",
        class: "Paladin",
        status: "Alive",
        enabled: true,
        src: "https://i.pinimg.com/564x/02/1f/25/021f25a4de4dbe94c9c2e323075259f7.jpg",
        tagLine: "Clerk in Barthen's Provisions",
        blurb: "Anders Barthen is the 18-year-old son of Elmar Barthen and helps in the store. Unlike his sister, Anders has no desire for traveling far, he mainly just wants to protect the town.",
        recentEvents: `
          <h4>Session 3</h4> PCs notice him watching them on their way into Phandalin. He says that the town is overrun by bandits and that he was waiting for the help they asked for. Tells Sildar and the party to go to his father, then be careful.
          <h4>Session 6</h4> He helps put out the Sleeping Giant Inn blaze
          <h4>Session 7</h4> Present to show disgust at Harbin Wester at the party.
        `
      },

      wallach: {
        name: "Wallach son of Ironhand",
        race: "Human",
        gender: "Male",
        class: "Artificer",
        status: "Alive",
        enabled: true,
        src: "https://i.pinimg.com/564x/3a/3b/2c/3a3b2c6fd0b0888235c364b558e8894c.jpg",
        tagLine: "Blacksmith of Phandalin",
        blurb: "",
        recentEvents: ""
      },

      elsa: {
        name: "Elsa",
        race: "Human",
        gender: "Female",
        class: "Commoner",
        status: "Alive",
        enabled: true,
        src: "https://i1.wp.com/angeliquednd.com/wp-content/uploads/2020/02/Elsa_Stonehill.jpg",
        tagLine: "Barmaid of Stonehill Inn",
        blurb: "Elsa is a youthful, cheery, bubbly barmaid who loves to gossip, but rarely knows any to trade. She is from Waterdeep, but does not speak of her past.",
        recentEvents: `
          <h4>Session 12</h4> Found in secret hole in woods, reveals that shapeshifter has taken her place. The False Elsa gave her food, but also posed as Corpo and made her fear him.
        `
      },

      garaele: {
        name: "Sister Garaele",
        race: "Moon Elf",
        gender: "Female",
        class: "Cleric: Trickery",
        status: "Alive",
        enabled: true,
        src: "https://i.pinimg.com/564x/63/96/3e/63963e7b25a7b9c933e7ba978d836008.jpg",
        tagLine: "Cleric of Avandra",
        blurb: "Garaele is a Moon Elf cleric of Avandra, the goddess of change and luck and runs the Shrine in Phandalin. She is an acolyte sent to tend to the locals. She has spent most her life in rural areas, but is a scholarly and zealous young elf who despairs over local troubles.",
        recentEvents: `
          <h4>Session 3</h4> Party is told that she can help heal.
          <h4>Session 6</h4> She helps heal Wildon Fiddlestump, then attempts to recruit, or at least enlist, Corpo in helping the Harpers, finally is kissed by Corpo Fugit.
          <h4>Session 7</h4> Is happy at the party that there was a large donation to the Orphans.
          <h4>Session 8</h4> Corpo is going to the Ruins on a mission from her.
        `
      },

      harbin: {
        name: "Harbin Wester",
        race: "Human",
        gender: "Male",
        class: "Commoner",
        status: "In Prison",
        enabled: true,
        src: "https://db4sgowjqfwig.cloudfront.net/images/5162002/Harbin_Wester.jpg",
        tagLine: "Phandalin Townmaster",
        blurb: "Phandalin has no functioning government, but the townsfolk elect someone to serve as town master each year. The townmaster serves as a judge in minor disputes and keeps any records that need to be kept. The current town master is a male human banker named Harbin Wester, a fat, pompous fool.",
        recentEvents: `
          <h4>Session 3 </h4> Party hears that he is ineffective and unable to deal with the bandit threat.
          <h4>Session 4 </h4> Halia Thornton tells Sparka that the mayor is a pushover and worthless
          <h4>Session 6 </h4> Confronts Sparka, who yells at him for failing the Small Business owners. Sparka threatens to support a rival candidate, he says bring it on.
          <h4>Session 7 </h4> Is hostile to whole group, when Sparka Redlake accuses him of embezzlement with the ledger that Thistle Barthen  stole...he claims the anonymous donation for the orphans. Starts bringing up various accusations against the party, some true some lies. Lars notices a suspiciously familiar money pouch on his waist. He calls in the three Dragonborn hunting Sass, though feigns ignorance. He finally is tricked by Sparka to go to the Townhall where Thistle had planted the forged letter. Sparka convinces the town to throw him in jail.
          <h4>Session 11</h4> Sparka sees a vision of Wildon being hung in Phandalin by Harbin Wester.
        `
      },

      linene: {
        name: "Linene Graywind",
        race: "Human",
        gender: "Female",
        class: "Commoner",
        status: "Alive",
        enabled: true,
        src: "https://i.pinimg.com/originals/80/1f/ae/801fae28b1b3ea50b97f91b1c832930b.jpg",
        tagLine: "Lionshield's Phandalin Branch Master",
        // blurb: "The Lionshields are a merchant company based in the city of Yartar, over a hundred miles to the east. They ship finished goods to Phandalin and other small settlements throughout the region, and Linene is the master of the Phandalin post; the Lionshield Coster. Linene is a sharp-minded, and sharp-tongued woman of thirty-five, with stern looks. Her raven-black hair is always pulled up neatly, and while she is well dressed to represent the Lionshields appropriately, she moves with the ease of someone more at home in the wild than indoors behind a counter. However, her baby has kept her awake that night recently, so she looks like an exhausted worn down woman trying her best to put on a show. Because of her connections to the Lionshields, Linene tends to have goods available that others in Phandalin can not easily get. She has a few scruples, however, and won’t sell weapons to anyone she thinks might be a threat to the town. ",
        blurb: "",
        //Among those with whom she refuses to do business are the Redbrands and she is one of the few openly speaking out against them.
        recentEvents: `
          <h4>Session 3</h4> The Phandalin Five found her face down sleeping at her desk when thery returned her shipment that the Cragmaw Goblins had stolen. She was grateful, handing over gold and offering to help. Gave info on the Bandits as well, claiming they could threaten her husband and baby soon.
          <h4>Session 4</h4> Party sold Goblin weapons to her.
          <h4>Session 7</h4> Sleepily attends the party
        `
      },

      toblen: {
        name: "Toblen Stonehill",
        race: "Human",
        gender: "Male",
        class: "Commoner",
        status: "Alive",
        enabled: true,
        src: "https://db4sgowjqfwig.cloudfront.net/campaigns/169243/assets/783269/toblen-stonehill.jpg",
        tagLine: "Co-Proprietor Stonehill Inn",
        blurb: "The proprietor of Stonehill Inn is a friendly male human named Toblen Stonehill. Toblen is a native of the town of Triboar to the east. He came to Phandalin to prospect, but soon realized that he knew a lot more about running an inn than he did about mining. The new town offered a good opportunity to become established and his inn -that he runs with the help of his wife Trilena- is now considered the only place in Phandalin for visitors to find proper lodgings.",
        // Toblen is upset that the Redbrands have been allowed to terrorize the town, and that Harbin Wester, the townmaster, has done nothing to curtail them. However, he tries not to stir up trouble, for fear that the Redbrands might retaliate against his wife and son Pip. Instead, he tries his best to keep his head low and focuses on doing his job, running an inn of good repute. He takes pride in his establishment, focusing on keeping patrons happy, rooms fixed up, and tap flowing.
        recentEvents: `
          <h4>Session 3</h4> Cecil dominates a battle of the bards againt GOB Halen, Toblen gives them 3 free rooms in exchange for further performances.
          <h4>Session 4</h4> As the Redbrands bother him, Sparka backs him up and the fight breaks out. Afterwards, Toblen mobilizes the patrons to clear up the bodies and watch to see if more bandits are coming. He has his wife Trilena Stonehill clean up the blood, she sighs. He then offers as much assistance to the travelers as he can muster. Toblen tells them about how the bandits frequent the Sleeping Giant Inn, but that it is not owned by anyone in time anymore.
          <h4>Session 7</h4> Throws a party for the party, gives them some free food if they do not go too crazy. Displays unease when Harbin Wester reveals that Lars Rutschignass is wanted for not paying his bar tabs in Neverwinter.
        `
      },

      narth: {
        name: "Ol' Man Narth",
        race: "Human",
        gender: "Male",
        class: "Commoner",
        status: "Dead",
        enabled: true,
        src: "https://i.pinimg.com/originals/e0/11/94/e01194383ef812577fd27c3069b17ea1.jpg",
        tagLine: "Late Farmer from Phandalin",
        blurb: "Old farmer from Phanadlin, had an identical sabertooth tiger necklace with his son. His son boldly went out of town to seek help to deal with the bandits, but was killed.",
        recentEvents: `
          <h4>Session 3</h4> Cecil Solonna meets him in the Stonehill Inn, notices the sabertooth tiger necklace
          <h4>Session 4</h4> Lars plants the necklace on the Mysterious Gold Elf who tried to hire him to spy on Cecil. Ol' Man Narth follows him out. Later, Lars sees his dead body with an ornate dagger stuck in it.
        `
      },


      trilena: {
        name: "Trilena Stonehill",
        race: "Human",
        gender: "Female",
        class: "Commoner",
        status: "Alive",
        enabled: true,
        src: "https://www.scabard.com/user/johnnyphoenix/image/barmaid.jpg",
        tagLine: "Co-Proprietor Stonehill Inn",
        blurb: "A young human woman, with auburn wavy hair pulled up in a loose bun, Trilena is always on the move, running the tavern with her husband. As she rushes through her work, strands of her hair always come loose, giving her a disheveled look by the end of the day. Her apron is always damp and stained, and the keys to the rooms of the inn hang on her belt to her side. She is friendly and courteous to everyone, but always looks worried, or stressed, or in a hurry. She’s clearly the workhorse of the less-flashy jobs, with Toblen doing more talking to patrons and heavy lifting. She runs the kitchen and watches the barmaid. She is good with numbers, and quick on her feet, but not the most sociable. Nor is she the most diplomatic, preferring blunt honesty instead.",
        recentEvents: `
          <h4>Session 3</h4> The Phandalin Five notices that she is working hard running around.
          <h4>Session 4</h4> Toblen Stonehill tells her to clean up the dead bodies, she sighs but gets to it.
          <h4>Session 7</h4> Working hard setting up for the party.
        `
      },

      halia: {
        name: "Halia Thornton",
        race: "Human",
        gender: "Female",
        class: "Rogue: Mastermind",
        status: "Alive",
        enabled: true,
        src: "https://db4sgowjqfwig.cloudfront.net/campaigns/98684/assets/456702/Halia_Thornton.jpg?1431037654",
        tagLine: "Guildmistress of Phandalin Miner’s Exchange",
        blurb: "Since the early days of the town’s existence, Halia Thornton has made coin as the owner and manager of Phandalin’s Miner Exchange, a place where prospectors can have their valuable finds weighed and sold for cash. Her sweet, softspoken demeanor gets them in the door, while her nimble, cunning mind keeps her accounts full",
        recentEvents: `
          <h4>Session 4</h4> Sparka met her in the Exchange, where she bought the Jade Frog from Cragmaw Hideout. She asked him to kill only the Redbrand leader, Glasstaff, claiming the rank and file just need to be freed of the bad influence. When Sparka asked why she was unbothered by the Redbrands, she gave vague answers about money and power.
          <h4>Session 5</h4> Sparka found a note on his person from her offering payment for whatever papers and correspondence Iarno "Glasstaff" Albrek has.
          <h4>Session 7</h4> Walks by the party, supplies Sparka Redlake with a forged note.
          <h4>Session 10</h4> Sparka reveals that he worked with her, handing over items from the desk of Glasstaff in exchange for gold that he then donated to the orphans. They figure out that she probably put Lars up to killing the Red Wizard as well. The Phandalin Five agree to not let her come between them, hopefully taking the Zhentarim down a few pegs.
          <h4>Session 11</h4> While the Zhentarim refused invitations to King Grol's Garden Party, the Newbrands imply that they work for her. Sparka threatens Newbrands telling them that he is Akraps, a ranking member of the Zhentarim, and that they should spy on Halia Thornton for him.
          <h4>Session 13</h4> In a flashback, Sparka sold her identity as the Zhentarim agent in Phandalin to Daurotham.
        `
      },

      plata: {
        name: "Plata",
        race: "Human",
        gender: "Female",
        class: "Gladiator", //14 in Int, Wis and EXPERTISE in History
        status: "Alive",
        enabled: true,
        src: "",
        tagLine: "Philosophical Merc",
        blurb: "",
        recentEvents: `
          <h4>Session 15</h4> Met the group on Halia's orders and helped them re-enter Phandalin
        `
      },

      kettenraw: {
        name: "Kettenraw",
        race: "Human",
        gender: "Male",
        class: "Fighter",
        status: "Alive",
        enabled: true,
        src: "",
        tagLine: "Quiet Merc",
        blurb: "",
        recentEvents: `
          <h4>Session 15</h4> Met the group on Halia's orders and helped them re-enter Phandalin
        `
      },

      qelline: {
        name: "Qelline Alderleaf",
        race: "Halfling",
        gender: "Female",
        class: "Commoner",
        status: "Alive",
        enabled: true,
        src: "https://db4sgowjqfwig.cloudfront.net/images/5161914/9a866a0f1499fb5980f54c79812ec497.jpg",
        tagLine: "In-the-Know Farmer from Phandalin",
        blurb: "A wise female halfling of forty-seven, Qelline Alderleaf is a pragmatic farmer who seems to know everything that goes on in town.",
        recentEvents: `
          <h4>Session 4</h4> The Phandalin Five saw her in the inn as the fight went down. Later she subtly cheered them on their way to the manor.
          <h4>Session 7</h4> Tells Party about all sorts of gossip, from Reidoth the Druid knowing the area really well, to the Barthen family coming after his wife died. The party then convinces her to run for Townmaster. Lars finally...makes a move with her and is rewarded with her house key.
          <h4>Session 8</h4> Lars wakes up next to her, asks for round 2. She agrees, they bang. She makes him breakfast and gives him a goody bag of Vitamin Water and a squirrel broach. Her husband comes home...she tries to hide Lars but Lars is like hell with that. Tells her husband that he should check Qeline out...she gives out goody bag.
          <h4>Session 15</h4> Is going to be hung after the Succubus framed her. Is freed by Corpo but then claims to be a virgin.
        `
      },

      maise: {
        name: "Maise Summerbluff",
        race: "Half Sun Elf",
        gender: "Female",
        class: "Artificer / Wizard: Scribes",
        status: "Nervous Drinking in a Tavern Hoping for Sparka to Return to Her Since It Seemed Like it Was Going So Well",
        enabled: true,
        src: "https://pbs.twimg.com/media/EzmY39iXEAAMlj9.jpg:large",
        tagLine: "Student of Magical Items and Agent of the Heralds",
        blurb: "Has revealed that she was raised in Candlekeep, under tutledge of wizards for knowledge and magic and guards for fighting and street smarts.",
        recentEvents: `
          <h4>Session 3</h4> Meets Sparka, reveals what she knows of the name Redlake, then proposes an agreement. Sparka gets the Mayor's necklace back, it was stolen at some point from her client, she will use it to cast identify on his arcane focus.
          <h4>Session 4</h4> Calmly eats breakfast in the Inn.
          <h4>Session 6</h4> Uses Control Flames cantrip to help put out the Sleeping Giant Inn blaze.
          <h4>Session 7</h4> Meets Sparka to perform Identify on his Arcane Focus. Sees that it is magiclaly protected from Divination, but that it appears to be acting as both a Shadowfell Shard and a Blood Well Vial, though unclear how to make that work. She tells him it is a massively powerful artifact, worthy of an Elven Royal household. She suggests he visit Phineas Stoneblood and ask him as he is the premier expect in magical items and history. She also in not clear on the metallic bowl either.
          <h4>Session 9</h4> Cecil reveals that he has been in a little bit of correspondence with her for the College of the Heralds.
          <h4>Session 14</h4> Cecil tests her on the notebook, she passes. She reveals that the Cult us attacking all over, that Sister Garaele was arrested and offers Cecill to join the Heralds.
          ...
          <h4>Session 22</h4> Maisie tries the Scroll of Opening but it failed. When the party arrives she asks for help and Sparka is like turned on. His charms work soooo well.
        `
      },
    },

    foes: {
      brughor: {
        name: "Brughor Axe-Biter",
        race: "Orc",
        gender: "Male",
        class: "Fighter: Champion",
        status: "Alive",
        enabled: false,
        src: "https://db4sgowjqfwig.cloudfront.net/images/5829864/Brughor_Axe-Biter.jpg",
        tagLine: "Fundamentalist Orc",
        blurb: "Brughor believes he is the descendant of the legendary war chief Uruth who had laid waste to Phandalin and Wave Echo Cave nearly 500 years ago.",
        recentEvents: ""
      },

      yeemik: {
        name: "Yeemik",
        race: "Goblin",
        gender: "Male",
        status: "Captured",
        enabled: true,
        class: "Goblin Boss",
        tagLine: "Goblin Boss",
        blurb: "Yeemik had plans to take over from King Blargh, but that was beefore he met he party.",
        src: "https://i.pinimg.com/originals/c8/26/9c/c8269c5d8631f0081b84de0e481542bb.jpg",
        recentEvents: `
          <h4>Session 3</h4> Party negotiated with Yeemik to kill King Blargh in exchange for Sildar Hallwinter. After defeating Blargh, Cecil Solonna cast an illusion to convince Yeemik that Blargh was coming to kill him. Yeemik and his goblins fled.
          <h4>Session 11</h4> Cecil sees Yeemik on the wall of Cragmaw Castle as he sings the tale of how he fooled him.
        `
      },

      grol: {
        name: "King Grol",
        race: "Bugbear",
        gender: "Male",
        enabled: true,
        class: "Bug Bear Chief",
        tagLine: "King of the Cragmaw Goblinoids",
        blurb: "Head of the Cragmaw Goblins, Blargh was a vassal of his. Apparently working with the Black Spider. Master of Cragmaw Castle",
        src: "https://db4sgowjqfwig.cloudfront.net/campaigns/110358/assets/480745/Bugbear_Chief.jpg?1436563591",
        status: "Dead",
        recentEvents: `
          <h4>Session 3</h4> Party discovered that King Blargh worked for him, and that Gundren Rockseeker had been sent to him along with a suspected map to Wave Echo Cave. This was partially done at the request of the Black Spider.
          <h4>Session 10</h4> The Phandalin Five recieve invites from Avlod to King Grol's party.
          <h4>Session 11</h4> His slave auction garden party ends up a failure as the Phandalin Five liberate Gundren and the map. This after Hadrian Solonna arrives and threatens him from breaking his agreement with the Black Spider. Avlod says that he will remove him now that he is emabarassed.
          <h4>Session 12</h4> Avlod is seen with Grol's head on his belt.
        `
      },

      glasstaff: {
        name: "Iarno \"Glasstaff\" Albrek",
        race: "Human",
        gender: "Male",
        class: "Wizard",
        status: "Dead",
        enabled: true,
        src: "https://static.wikia.nocookie.net/plan-b/images/f/f5/Glasstaff.jpg",
        tagLine: "Leader of the Redbrands",
        blurb: "A former member of the Lords' Alliance, larno seized an opportunity in Phandalin to line his own pockets. Originally tasked with setting up a constabulary, the mage instead assembled a group of outlaws and local ruffians to secure his own position in town. Worked with the Black Spider, told to be on the search for a Dragon Mask.",
        recentEvents: `
          <h4>Session 4</h4>Party learned via interrogation that the Redbrands leader is a wizard named Glasstaff
          <h4>Session 6</h4>Party discovers he Iarno is Glasstaff, after he fireballed them then tried to flee. He attempts to talk himself out of trouble, revealing all information he has on the Black Spider. Lars Rutschignass kills him.
        `
      },

      hamun: {
        race: "Human",
        gender: "Male",
        name: "Hamun Kost",
        class: "Wizard: Necromancer",
        status: "Burnt to a Crsip and Decapitated",
        enabled: true,
        src: "https://db4sgowjqfwig.cloudfront.net/images/5162770/redwizard__1_.jpg",
        tagLine: "Red Wizard of Thay",
        // blurb: "Kost is a stout, red-robed figure with sallow skin, a shaved scalp, and a black tattoo on his forehead. He is a Red Wizard of Thay, far from home",
        recentEvents: `
          <h4>Session 7</h4> Members of the party are told that a Red Wizard of Thay has been spotted to the North. Daran Edermath asked them to deliver the report to himslef of the Hall of Justice in Neverwinter, the Order of the Gauntlet would handle it.
          <h4>Session 8</h4> The Phandalin Five finds him with a dozen of zombies investigating the Netherese ruins. He attempts to parley a lot, even after struck with a Javelin. Hamun tells the party that he will leave if he is given a the answer to the riddle of the door. The party knows the answer, but also knows that there is another question after that one.
          <br/><br/>
          Lars Rutschignass shuffles as a zombie, but Hamun eventually notices. Lars needs to kill him, so essentially picks a fight. The party easily ends him, with Sparka killing him with a firebolt, then Lars adding his head as a trophy.
          <h4>Session 11</h4> At King Grol's garden party the Red Wizards shows up alone since his partner was killed.
        `
      },


      spider: {
        // name: "Nezznar Xorlarrin \"The Black Spider\"",
        name: "The Black Spider",
        // race: "Drider",
        race: "???",
        // gender: "Male",
        gender: "???",
        // class: "Wizard",
        class: "???",
        status: "Alive",
        enabled: true,
        // src: "https://static.wikia.nocookie.net/forgottenrealms/images/d/db/Nezznar.png/revision/latest?cb=20191023140821",
        src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpcMC0LAOq9cKWedEFBMHolIcbdNnrnjfneg&usqp=CAU",
        tagLine: "Shadowy Figure",
        blurb: "???",
        recentEvents: `
          <h4>Session 2</h4>A interrogated goblin revealed that the Bugbears were taking orders from The Black Spider to capture Gundren Rockseeker and a map.
          <h4>Session 3</h4>Sildar Hallwinter reveals that Gundren Rockseeker and his map were taken to Cragmaw Castle on orders of The Black Spider.
          <h4>Session 6</h4>Iarno "Glasstaff" Albrek and his letters reveal that he has been working with The Black Spider to control the town and look for a Dragon Mask. Three Bug Bears and a Dire Wolf were sent to help Glasstaff from The Black Spider.
          <h4>Session 11</h4> His agent arrives with Hadrian Solonna at King Grol's garden party, revealing that the Black Spider has been working with/for the Reformed Cutl of the Dragon.
          <h4>Session 12</h4> The Phandalin Five assume the Black Spider's agents killed Gundren's brother Tharden, but Nundro is nowhere to be seen.
        `
      },

      dreadFlameCaptain: {
        // name: "Nezznar Xorlarrin \"The Black Spider\"",
        name: "The Dread Flame Captain",
        // race: "Drow",
        race: "???",
        // gender: "Male",
        gender: "???",
        class: "Paladin: Oathbreaker, Barbarian: Zealot",
        status: "Alive",
        enabled: true,
        src: "https://i.pinimg.com/564x/9c/d4/a2/9cd4a251180d69e5f1cb3b658f450136.jpg",
        tagLine: "Leader of Elite Planar Mercenaries",
        blurb: "???",
        recentEvents: "<h4>Session 4</h4>Lead attack on the Nomads who captured Corpo, rode a Nightmare, burned their sails, and defeated the crew. Executed crew slowly by cutting Astral connection with a magic silver sword, demanding they reveal who they just picked up. A gem dragonborn crewmember told Corpo not to reveal anything as he was killed. Corpo unleashed a mysterious force inside of him to deveaste the deck of the ship with thunder strike. The Dread Flame captain was not seriously injured."
      },

      // vishkana: {
      false_elsa: {
        name: "Not Elsa",
        // race: "Succubus",
        race: "????",
        gender: "Female",
        class: "Rogue",
        status: "Alive",
        enabled: true,
        // src: "https://i.pinimg.com/originals/f0/76/f2/f076f2e0df5f281b92cf97a8a8f1b363.png",
        src: "https://i1.wp.com/angeliquednd.com/wp-content/uploads/2020/02/Elsa_Stonehill.jpg",
        tagLine: "Apparent Shapeshifter posing as Barmaid",
        blurb: "???",
        recentEvents: `
          <h4>Session 4</h4> She alledgely hides in the back while the tavern fight happens.
          <h4>Session 6</h4> Brings the party rounds of both their finest ales and asks what happened. They tell her literally everything.
          <h4>Session 7</h4> Serves the party drinks, Corpo slaps her ass and uses Gust to blow up her skirt, she is not amused. She does say that she will tolerate them and give free round of drinks if they tell her adventure stories. They agree to.
          <h4>Session 8</h4> Meets party for breakfast, Corpo notices he is not wearing pants under her dress. Party tells her all their plans and where they are going. Lars fails to seduce her...despite her very much being game it was too embarassingly bad.
        `
      },


      // loretta: {
      deep_gnome: {
        // name: "Loretta Lunarluck",
        name: "Zhentarim Deep Gnome",
        race: "Deep Gnome",
        gender: "Female",
        class: "Rogue: Soulknife/Druid: Moon",
        status: "Alive",
        enabled: true,
        src: "https://i.pinimg.com/originals/ea/24/62/ea2462ce2b5467b9bf4be44803fea73b.jpg",
        tagLine: "Zhentarim Druidic Assassin",
        blurb: "Druid/Assassin who works for Halia Thornton. In wildshape tends to keep a dagger pattern in feathers/fur/scales/etc.",
        recentEvents: `
          <h4>Session 7</h4> As a weasel, hands Sparka Redlake
          <h4>Session 8</h4> Sparka caught her as a bird late at night, placed her in the fire so she dropped wildshape. She appeared and said that she was sent by Halia Thornton to watch him since he is important. She flew away after.
        `
      },
    },

    cultOfTheDragon: {
      faveric: {
        name: "Faveric Widespire",
        race: "Human",
        gender: "Male",
        // class: "Cultist",
        class: "Cultist",
        status: "Dead and Castrated",
        enabled: true,
        src: "https://static.wikia.nocookie.net/verum/images/f/f4/Clyde01.jpg",
        tagLine: "Aspiring Dragon Cultist",
        blurb: "Faveric Widespire is a young man with refined manners suggesting merchant-class or better birth.",
        recentEvents: `
            <h4>Session 1</h4>The party met Faveric, though did not learn his name, on the High Road along with his 5 companions. The party refused to help them with their broken wheel when they thought the travelers shifty. Eventually they levitated Faveric's treasure chest, and Lars hacked off the lock with an axe. Shockingly they didn't like this, but Corpo convicned them it was to make the cart lighter. They placed the wheel on and fled like scared children...though not until after Corpo used a gust of wind to take a look inside the chest, seeing diamonds and Dragon Cultist robes.
            <h4>Session 9</h4> Faveric comes running from the Mushroom Spore Cloud to the party, thinking they are Venomfang. A standoff resolves when the party allows them in in exchange for the treasure they brought for for Venomfang. Faveric cast charm person on Lars Rutschignass to hopefully prevent them from getting killed. A quick interrogation reveals that he is a follow of Severin Solonna, who he gives some information about. He also lists Talis and Cloudchaser as members of the reformed cult of the Dragon. Sparka figured it out and broke concentration, freeing Lars to brutally interrogate, castrate, and kill Faveric.
          `
      },

      severin: {
        name: "Severin Solonna",
        race: "Gold Elf",
        gender: "Male",
        class: "Wizard: Chronurgy",
        status: "Alive",
        enabled: true,
        src: "https://static.wikia.nocookie.net/forgottenrealms/images/2/2f/4e_wizard.jpg",
        tagLine: "Prophet of Tiamat",
        blurb: "",
        recentEvents:
          `
            <h4>Session 9</h4> Faveric reveals that the Prophet of Tiamat who has formed a sect of the Cult of the Dragon bent on bringing Tiamat to the Material Plane is Severin Solonna. Severin is on the "World of Dragons"
            <h4>Session 11</h4> Hadrian Solonna says that he is there to represent his father.
          `
      },

      hadrian_solonna: {
        name: "Hadrian Solonna",
        race: "Human",
        gender: "Male",
        class: "Wizard: Chronurgy",
        status: "Alive",
        enabled: true,
        src: "https://i.pinimg.com/564x/67/15/ca/6715ca81f506f1f016303f45c3aa9e5d.jpg",
        tagLine: "Son of Severin, in the Cult of the Dragon",
        blurb: "Originally a Gold-Elf of unknown origins, but apparently wealthy. Later revealed to be the son of Severin Solonna and a high ranking member of the Cult of the Dragon.",
        recentEvents: `
          <h4>Session 4</h4>He offered Lars plenty of gold and to pay off his bar tabs if he acquired some information on Cecil. Lars refused but the Gold Elf left a fine bottle, telling him to reconsider. Later Lars convinced him to take a Sabertooth tiger necklace that Ol' Man Narth's son had been wearing. The Old man followed the Gold Elf out, and later Lars and Cecil saw his dead body with a fine dagger in it.
          <h4>Session 7</h4>Lars notices same sack of money he offered to Lars on the belt of Harbin Wester
          <h4>Session 11</h4> Arrives at the King Grol party and is noticed by Lars as the same elf from earlier. He is a total snob, spilling drinks and threatening people. He is bombastic to King Grol who cowers. He reveals that the Cult of the Dragon is directing the Black Spider. He appears to notice that the Phandalin Five are there.
        `
      },

      thopleth: {
        name: "Thopleth",
        race: "Black Dragonborn",
        // race: "Shapeshifter / Black Dragonborn",
        gender: "Male",
        class: "Cleric: Trickery",
        status: "Alive",
        enabled: false,
        src: "https://i.pinimg.com/originals/d3/ba/5b/d3ba5bc8b0c24f69880b9ca84b845bc8.png",
        tagLine: "Cloudchaser's Loyal Cleric of Tiamat",
        blurb: "Loyal lackey of Cloudchaser and Zealot of Tiamat. Sass knows him from growing up as the traitor who would tell Cloudchaser on anyone.",
        recentEvents: ``
        // recentEvents: `
        //   <h4>Session 11</h4> In disguise as Drow at Grol's Garden Party.
        // `
      },


      talis: {
        name: "Talis",
        race: "???",
        gender: "???",
        class: "???",
        status: "???",
        enabled: true,
        src: "http://2.bp.blogspot.com/-8lWoA0-ZkMM/VJJPnb3hDMI/AAAAAAAACuU/Pnja0vVrsUs/w1200-h630-p-k-no-nu/talis_sm_by_bryansyme-d7wvvm5.jpg",
        tagLine: "Alleged Member of the Cult",
        blurb: "",
        recentEvents:
          `
            <h4>Session 9</h4> Faveric lists Talis as a name of someone that he knows higher up in the Reformed Cult of the Dragon.
          `
      },

      blueeyes: {
        name: "Glazhael the Cloudchaser",
        race: "White Dragon",
        gender: "Male",
        class: "Adult Dragon",
        status: "???",
        enabled: true,
        src: "https://pemburydnd.files.wordpress.com/2018/09/f0462-ep8_whitedragon_in_icecavern_guidokuip_2014.jpg",
        tagLine: "Blue Eyes White Dragon, Past master of Greosass",
        blurb: "Blue Eyes White Dragon, former master of Greosass who sought to exterminate the Kindaphalic Clan. He forced Sass to kill other clan members, before killing Sass' love interest. Sass blinded him his breath weapon and a single shot to the eye before escaping. <br/> <br/> He participated in the War of the Silver Marches, slaughtering Lars' unit.",
        recentEvents: `
          <h4>Session 7</h4> Sass' almost brother-in-law and two relatives of the ones he slain come hunting him in Phandalin. They say that Cloudchaser lied, claiming that Sass slaughtered them on his own.
          <h4>Session 9</h4> Faveric reveals that Cloudchaser is high ranking in Severin's reformed Cult of the Dragon.
        `
      },
    },

    shroomers: {
      bamur: {
        name: "Bamur",
        race: "Dwarf",
        gender: "Male",
        class: "Oathbreaker:Paladin/Undying:Warlock",
        status: "Alive",
        enabled: true,
        src: "https://i.pinimg.com/236x/a9/99/c0/a999c0d7b7c6bbecc72294a738b0da0c--fantasy-dwarf-fantasy-rpg.jpg",
        tagLine: "Twin of Balor and former friend of Lars",
        blurb: "A Dwarf from the original home of Lars Rutschignass taken into new colony. At the Dwarphanage Lars recalls playing games with the brothers, Ole played smart and Lars read people. Bamur had a tell, scratching his ear to reveal when he was bluffing. Like his brother Ole and Lars, he became a mushroom farmer. However, Lars quickly outpaced them with his success.",
        recentEvents: `
          <h4>Session 8</h4> Ole tells Lars that Bamur worships Zuggtmoy, the Queen of Mushrooms, and has been spreading prophecies that Lars is her herald to bring about her return to this Plane. Bamur believes that The Return of the Queen prophecy applies to her.
          <br/> Lars recalls that she wishes to spread like Fungi, controlling and destroying all. Ole says that Bamur led a revolution in the small Dwarf stronghold of Iron Deeper, resulting in chaos and anarchy for his Demon Queen. Ole makes Lars promise to kill Bamur if he sees him.
          <h4>Session 9</h4> Lars sees "Ole" run into Venomfang's tower after they Phanadlin Five defeat him. "Ole" acts odd, encouraging Lars to take over the world, and asking odd questions.
          <br/><br/> Lars notices Bamur's tell, scratching his ear. Lars leads Bamur outside, then barricades the door. "Ole" drops the Disguise Self spell, revealing Bamur. Bamur tells Lars that he is the chosen of the Zuggtmoy, the axe can bring her back.
          <br/><br/> He tells Lars that these heathens are terrible, come with him and overthrow it all the corrupt rich...but veers into crazy talk too.
          <br/><br/> Bamur raises his hand and starts to puppet control Reidoth, revealing that the mushroom spores are his to control beings with. Lars rejects him and Bamur Fungus Steps away.
          <br/><br/> Bamur launches an assault on the Phandalin Five to get to the collapsing dragon horde magic. The party holds them off, Bamur attempts one last time to convince Lars to join him...and fails.
        `
      },

      lady_warden: {
        name: "???",
        race: "???",
        gender: "Female",
        class: "Ranger:Drakewarden",
        status: "Alive",
        enabled: true,
        // src: "https://4.bp.blogspot.com/-WtgP23Ci6jE/W7hO9q8OucI/AAAAAAAAAec/1r3DRA3RKkAoOBESxihNpctlUIx_3rVrgCLcBGAs/w1200-h630-p-k-no-nu/BloodbondVampire_posting.jpg",
        // https://i.imgur.com/sNlQg8V.jpeg
        tagLine: "Mysterious Ranger",
        blurb: "",
        recentEvents: `
          <h4>Session 9</h4> A crossbow bolt infused with necrotic energy shattered the party's barricade. Sass noticed that his drake also was active stranger like a previous owner was nearby. Another bolt missed Lars, and then the mysterious ranger vanished with Bamur.
        `
      },
    },

    nomads: {
      tide: {
        // name: "Rip Tide",
        name: "Unnamed Water Genasi Nomad",
        race: "Water Genasi",
        gender: "Female",
        class: "Monk: Way of the Ascendant Dragon",
        status: "Alive",
        enabled: true,
        src: "https://i.pinimg.com/originals/ec/1d/c0/ec1dc06137b17cb48f4003cf4b96e173.png",
        tagLine: "Agent of the Nomads",
        blurb: "Agent of the Nomadic Keepers of the Planes",
        recentEvents: `
          <h4>Session 4</h4> She met Corpo in the Astral Plane, telling him rules and codes. However, she did not know his identity and he did not tell her when the Dread Flame Ship attack. She was sent from the Astral Plane when Corpo exploded his power.
        `
      },
      janzor: {
        // name: "Janzor Gladeshadow",
        name: "Unnamed Halfling Nomad",
        race: "Lightfoot Halfling",
        gender: "Male",
        class: "Ranger: Horizon Walker",
        status: "Alive",
        enabled: true,
        src: "https://pbs.twimg.com/media/DendVFmVQAAyyuJ.jpg",
        tagLine: "Agent of the Nomads",
        blurb: "Agent of the Nomadic Keepers of the Planes",
        recentEvents: `
          <h4>Session 4</h4> Corpo, while distrusting him for being a halfling. Noticed him climb the crow's nest and spot the Dread Flame Ship. He was sent from the Astral Plane when Corpo exploded his power.
        `
      },
    },

    elderFigures:{
      // https://stone-3.obsidianportal.com/wikis/the-dawn-war-pantheon
      avandra: {
        name: "Avandra",
        race: "Human",
        gender: "Female",
        plane: "Arborea but Travels",
        alignment: "CG",
        class: "Divinity",
        status: "Divinity Granting Spells",
        divineRank: "Exalted",
        divinePantheon: "Dawn War",
        divineTitle: "goddess of Change, Luck, and Travel",
        divineDomain: "Trickery",
        enabled: true,
        src: "https://static.wikia.nocookie.net/criticalrole/images/3/3f/Avandra_-_Claudio_Pozas.png",
        // https://i.pinimg.com/564x/23/23/95/2323959c27594d50c1d12b7fd89896d3.jpg
        tagLine: "Change and Luck Exalted Deity",
        adherents: ["Sister Garaele"],
        divineTagLine: "The Changebringer",
        piety: [
          `As a devotee to tending the ebbs and flows of change, you have proven your allegiance to the ideals of the Chagnebringer. You know that bonds are meant to be broken, and what is restrained should be set free. You may cast the spell <a href="https://5etools-mirror-1.github.io/spells.html#knock_phb">Knock</a> without spending a spell slot, up to your proficiency bous per long rest.`,
          `Stagnation resist the flows of change. Avandra's followers know one must be a step ahead of them. Once per long rest you may cast <a href="https://5etools-mirror-1.github.io/spells.html#haste_phb">Haste</a> without spending as spell slot`,
          `Tyrants overrule the will of others, restraining and controling them. Whenever you make a saving throw against being charmed, or any check against being restrained, you may add a d4 to the final roll.`
        ],
        commandments: [
          "Luck favors the bold, for to be bold is to live. Your fate is your own to grasp, and to do so is to have the Changebringer behind you.",
          "Change is inevitable. The righteous can ensure that such change is for the better.",
          "Rise against tyranny. Fight for the freedom of yourself and others when you can, and inspire others to fight when you cannot."
        ],
        blurb: `
          Avandra currently sits as the goddess of Luck, Travel, Liberty, and Change. Befitting the goddess of change, she has multiple views or personas that adherents are attracted to.
          <br/><br/>
          When thought of as Changebringer or Lady Luck, she is loved by gamblers, daring explorers, and traveling merchants. Many of those who gamble to travel put stock in her.
          <br/><br/>
          When thought of as the Changebringer, she is loved by those who recognize problems in the world. These adherents believe that institutions and traditions are often twisted to cause injustice, and therefore seek to change them, rooting injustice and evil out at the root.
          <br/><br/>
          Either as Changebringer or Changebringer, her adherents strive against not just oppression and tyrants, but anytime the status quo is lacking. They despise suffering.
          <br/><br/>
          Her goals are not just doing good, but to change whatever condition, tradition, law, or common sense that allowed the problem in the first place.
        `,
        recentEvents: `
          <h4>Session 6</h4> Sister Garaele in Phandalin mentioned that she is a cleric of Avandra
          <h4>Session 14/15</h4> Corpo saw writing about Avandra on his the lamp Severin claimed to be his father's.
        `
      },
      birghtaxe: {
        name: "Haela Brightaxe",
        race: "Dwarf",
        gender: "Female",
        plane: "Beastlands",
        alignment: "N-CG",
        class: "Divinity",
        status: "Divinity Granting Spells",
        divineRank: "Lesser Idol",
        divineTitle: "goddess of Combat Prowess, Luck in Battle",
        divineDomain: "War",
        divinePantheon: "Dwarven",
        enabled: true,
        src: "https://static.wikia.nocookie.net/forgottenrealms/images/f/f1/Brightaxe_symbol.jpg",
        tagLine: "Prowess and Luck Dwarven War Lesser Idol",
        adherents: [""],
        divineTagLine: "The Lucky Lady of the Fray",
        piety: [
          "???"
        ],
        commandments: [
          "Through battle there is validation, liberation, and exultation. Hone your skills and trust in luck.",
          "Show mercy on noble foes, but stay not your hand against treacherous, liars, and honorless.",
          "Wander to find and aid those in need. Trust that you will be guided to where needed."

        ],
        blurb: `

        `,
        recentEvents: `
          <h4>Session 7</h4> Sister Garaele mentioned hosting a ritual for Haela Brightaxe's clerics on Greengrass
        `
      },
      maglubiyet: {
        name: "Maglubiyet",
        race: "Goblinoid",
        gender: "Male",
        plane: "Acheron",
        alignment: "N-LE",
        class: "Divinity",
        status: "Divinity Granting Spells",
        divineRank: "Intermediate",
        divineTitle: "god of War and Leadership",
        divineDomain: "War",
        divinePantheon: "Goblinoid",
        enabled: true,
        src: "https://www.aidedd.org/assets/univers/dieux/maglubiyet-symbol.jpg",
        tagLine: "War and Autocratic Goblinoid War Deity",
        adherents: ["Yeemik", "King Grol"],
        divineTagLine: "The High Chieftain",
        piety: [
          "???"
        ],
        commandments: [
          "The weak are meant to be slaves, and will never rise above such rank.",
          "Crush failure before it spreads",
          "Conquer, dominate, and establish laws in Maglubiyet's name"
        ],
        blurb: `

        `,
        recentEvents: `
        `
      },

      hruggek: {
        name: "Hruggek",
        race: "Bug Bear",
        gender: "Male",
        plane: "Pandemonium/Acheron",
        alignment: "CE-N",
        class: "Divinity",
        status: "Divinity Granting Spells",
        divineRank: "Lesser Idol",
        divineTitle: "god of Ambush and Combat",
        divineDomain: "War",
        divinePantheon: "Goblinoid",
        enabled: true,
        src: "https://static.wikia.nocookie.net/penrith/images/b/b0/180px-Hruggek_symbol.jpg",
        tagLine: "Ambush and Combat Bug Bear War Lesser Idol",
        adherents: ["King Grol"],
        divineTagLine: "Tne Mighty Abmusher",
        piety: [
          "???"
        ],
        commandments: [
          "",
          "",
          ""

        ],
        blurb: `

        `,
        recentEvents: `
          <h4>Session 3</h4> Cecil Solonna insulted King Blargh by saying that his mother was so ugly that Hruggek wouldn't even want her. Hurtful since Hruggek typically cares little for how attractive Bug Bears are.
        `
      },

      bahamut: {
        name: "Bahamut",
        race: "Platinum Dragon",
        gender: "Male",
        alignment: "LG",
        plane: "Mount Celestia",
        class: "Divinity",
        status: "Divinity Granting Spells",
        divineRank: "Exalted",
        divinePantheon: "Dawn War, Draconic",
        divineTitle: "god of Justice and Nobility",
        divineDomain: "Order, War, Life",
        enabled: true,
        src: "https://cdnb.artstation.com/p/assets/images/images/031/315/903/large/joshua-raphael-322623-bahamut-upload2.jpg?1603266724",
        tagLine: "Lord of Platinum Dragons and Justice/Nobility Exalted Deity ",
        adherents: ["Daran Edermath"],
        divineTagLine: "The Platinum Dragon",
        commandments: [
          "Stand as a paragon of honor and justice.",
          "Smite evil wherever it is found, yet show compassion to those who have strayed from righteousness.",
          "Defend the weak, bring freedom to those without, and protect the ideals of just order.",
        ],
        piety: [
          "As a devotee of Bahamut, you have earned his favor through worthy victories. You can cast <a href=\"https://www.dndbeyond.com/spells/compelled-duel\">Compelled Duel</a> with this trait per Proficiency Bonus Modifier per long rest. Wisdom is your spellcasting ability for this spell.",
          "You have advantage on saving throws against being frightened. You can cast <a href=\"https://www.dndbeyond.com/spells/crusaders-mantle\">Crusader's Mantle</a> with this trait without concentration. Once you cast the spell in this way, you can't do so again until you finish a long rest. ",
          "There is no room for cowardice in the pursuit of Justice and Mercy. You are immune to fear."
        ],
        blurb: `
          Called the Platinum Dragon, Bahamut is the god of justice, protection, nobility, and honor. Lawful good Paladins often revere him, and metallic dragons revere him as the first of their kind. Monarchs are crowned in his name.
          <br/><br/>
          Myth and Legends relate his long rivalry with Tiamat his "sister". The Song of the First World claims that they worked together to create this "First World" before humanoids and their gods ruined everything. This however seem dubious given how much they have clashed since across worlds of the Material Plane.
        `,
        recentEvents: `
          <h4>Session 3</h4> Daran Edermath is shown to have a symbol of Bahamut in his home, as a Paladin of him.
          <h4>Session 8</h4> The Phandalin Five heads to Angel Acres, a small settlement of Bahamut adherents that are mostly friendly and ask the party to investigate Hamun Kost.
        `
      },
      tiamat: {
        name: "Tiamat",
        race: "Chromatic Dragon",
        plane: "The Nine Hells",
        gender: "Female",
        alignment: "L-NE",
        class: "Divinity",
        status: "???",
        divineRank: "Exalted",
        divinePantheon: "Dawn War, Draconic",
        divineTitle: "goddess of Wealth, Greed, and Vengeance",
        divineDomain: "War, Order, Trickery",
        enabled: true,
        src: "https://d1w82usnq70pt2.cloudfront.net/wp-content/uploads/2021/07/Magic_Tiamat.jpg",
        tagLine: "Queen of Chromatic Dragons, Wealth, and Greed Exalted Deity ",
        adherents: [],
        divineTagLine: "The Dragon Queen",
        piety: [
          "???"
        ],
        commandments: [
          "Amass wealth, and spend little. The gold, and the power that comes with it, is sufficient reward.",
          "Do not forgive or forget an indignity to yourself. Let no affront go unpunished.",
          "Take what you covet. Those without the strength to defend their dominion are not worthy to have a dominion."
        ],
        blurb: `
        `,
        recentEvents: `
          <h4>Session 8</h4> Lorc the Half-Orc bartender says that he thinks the Cult of the Dragon worships Tiamat, but this is not the standard case.
        `
      },
      corellon: {
        name: "Corellon",
        race: "Prime Elf",
        alignment: "CG",
        plane: "Arborea",
        gender: "He/???",
        class: "Divinity",
        status: "Divinity Granting Spells",
        divineRank: "Exalted",
        divinePantheon: "Dawn War, Seldarine",
        divineTitle: "god of Magic and the Arts",
        divineDomain: "Light, Arcana, Nature",
        enabled: true,
        src: "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/4d320876-b006-4cee-82b7-9aa3d6c14a0f/d2q0ykv-66d7fff1-9fbc-43f5-877c-a2aecd1e8622.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzRkMzIwODc2LWIwMDYtNGNlZS04MmI3LTlhYTNkNmMxNGEwZlwvZDJxMHlrdi02NmQ3ZmZmMS05ZmJjLTQzZjUtODc3Yy1hMmFlY2QxZTg2MjIucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.1KJwkEQrvmV_WGCWqvdedn-9YRwDLiBpOXGBI1ZU8uU",
        tagLine: "First elf and Magic/Art Exalted Deity ",
        // adherents: [],
        divineTagLine: "The Archeart",
        piety: [
          "???"
        ],
        commandments: [
          "Create, inspire, and find beauty in all that you do.",
          "Follow the echoes of lost magics, forgotten sites, and ancient art, for within these lie the Archeart's first works.",
          "Thwart the followers of Lolth and Gruumsh at every opportunity.",
        ],
        blurb: `

        `,
        recentEvents: `
        `
      },
      loth: {
        name: "Loth",
        race: "Drow (Prime Elf)",
        alignment: "CE",
        plane: "Abyss",
        gender: "Female",
        class: "Divinity",
        status: "Divinity Granting Spells",
        divineRank: "Exalted",
        divinePantheon: "Dawn War, Drow",
        divineTitle: "goddess of Spiders and Lies",
        divineDomain: "Trickery, Knowledge",
        enabled: true,
        src: "https://static.wikia.nocookie.net/criticalrole/images/9/96/Spd.png",
        tagLine: "Lies Greater Drow Deity ",
        // adherents: [],
        divineTagLine: "The Spider Queen",
        piety: [
          "???"
        ],
        commandments: [
          "It is better to be loved than feared, but you may certainly try to be both.",
          "Misdirection, slander, and shadowed steps have more function than direct conflict.",
          "Death to the elves who live under the sun. Death to all their allies!"
        ],
        blurb: `

        `,
        recentEvents: `
        `
      },
      erathis: {
        name: "Erathis",
        alignment: "LN-G",
        race: "Human",
        gender: "Female",
        plane: "Arcadia",
        class: "Divinity",
        status: "Divinity Granting Spells",
        divineRank: "Exalted",
        divinePantheon: "Dawn War",
        divineTitle: "goddess of Civilization, Wisdom, Strategy",
        divineDomain: "Order, War",
        enabled: true,
        src: "https://static.wikia.nocookie.net/dnd-campaign/images/4/4a/Erathis_Symbol.jpg",
        // https://static.wikia.nocookie.net/vsbattles/images/b/b7/AthenaRiordan.jpg/revision/latest?cb=20170716205734
        // https://i.pinimg.com/736x/95/92/dc/9592dc8ae1f54d3390a9b80818fd645a--archer-armor-female-archer.jpg
        // https://external-preview.redd.it/bWC_1MhbehNKXuwpBEHcqrN03V6r0cGhfhDtuAR1Yzw.jpg?auto=webp&s=f8e5d05b5bb381c13550e7f2da523e2867530e27
        tagLine: "Lawbearer and Civilization Exalted Deity ",
        // adherents: [],
        divineTagLine: "The Lawbearer",
        piety: [
          "???"
        ],
        commandments: [
          "Work with others to achieve your goals. Community and order are always stronger than the disjointed efforts of lone individuals.",
          "Tame the wilderness to make it fit for habitation, and defend the light of civilization against the encroaching darkness.",
          "Seek out new ideas, new inventions, new lands to inhabit, new wilderness to conquer. Build machines, build cities, build empires.",
        ],
        blurb: `

        `,
        recentEvents: `
        `
      },
      waukeen: {
        name: "Waukeen",
        alignment: "N",
        race: "Human",
        gender: "Female",
        plane: "Market Eternal",
        class: "Divinity",
        status: "Divinity Granting Spells",
        divineRank: "Intermediate",
        divinePantheon: "Human",
        divineTitle: "goddess of Commerce, Trade, Wealth",
        divineDomain: "Knowledge, Trickery",
        enabled: true,
        src: "https://static.wikia.nocookie.net/forgottenrealms/images/d/d1/Waukeensymbol.png",
        tagLine: "Commerce and Wealth Deity",
        adherents: ['Sparka Redlake'],
        divineTagLine: "The Coinmaden",
        piety: [
          "As a devotee of Waukeen, you have proven yourself a worthy potential champion of the goddess of Commerce and Trade. One cannot trade without communication, and any barrier to exchange is anathema to the Coinmaiden. Up to your Proficiency Bonus per day, you may cast <a href=\"https://www.dndbeyond.com/spells/comprehend-languages\">Comprehend Languages</a>.",
          `The Coinmadien adores bartering, guile, and some trickery in making deals. The more skillful the merchant is, the more loved by the Coinmaiden. However, she has limits to what is considered "fair play", including theft from a non-miser. Once per day, you may cast <a href="http://dnd5e.wikidot.com/spell:arcane-lock" target="_blank">Arcane Lock</a> to prevent such disrespect.`,
          "The negotiation of merchants is the highest art to the Coinmaiden. You may add a d4 to all social rolls involving merchants or the exchange of services."

        ],
        commandments: [
          "Horde not. Wealth must flow, all die in stagnation.",
          "Innovate with vigor! Invent new products, open trade routes, invest in struggling businesses.",
          "Freedom and competition are the lifeblood of commerce, oppose slavery, monopolies, guilds.",
          "Accept reality, be not a zealot of law, liberty, greed, or self-sacrifice.",
        ],
        blurb: `
          A deity of Faerûn popular amongst merchants and business owners. The most known of her orders is the Goldpalms. They promote the exchange of goods and services daily, spread business knowledge, and protect trade routes. Though scholars do not list her as an Exalted Deity, she is known on some others worlds.
          <br/><br/>The greedy and altruistic clash with her followers, as her world-view is shaped by what is best for commerce. She allies with Good deities against evil however, as greed and domination is a larger threat than the annoyance of well-intentioned laws that harm trade.
          <br/><br/>She smiles upon charity, as the poor cannot participate without funds, but her faithful are given latitude on how to give their coins. As many invest in businesses as help the poor.
        `,
        recentEvents: `
        `
      },
      yhtris: {
        name: "Yhtris",
        alignment: "LE-N",
        race: "Human",
        gender: "Female",
        plane: "Acheron",
        class: "Divinity",
        status: "Divinity Granting Spells",
        divineRank: "Intermediate",
        divinePantheon: "Human",
        divineTitle: "goddess of Business, Ambition, Monopolies",
        divineDomain: "Order",
        enabled: true,
        // src: "",
        tagLine: "Large Business Deity",
        adherents: ['Halia Thornton'],
        divineTagLine: "The Coin Conqueror",
        piety: [
          "???",
          "???",
          "???"
        ],
        commandments: [
          "Expand your company, find new markets, build monopolies",
          "Innovate, invent, open, expand all in the name of your company.",
          "Cultivate your ambition. There is no mercy in commerce. The greatest companies thrive.",
        ],
        blurb: `
          The goddess of ambitious business conquerors as they attempt to create monopolies. Though scholars do not list her as an Exalted Deity, her status as wife of Bane keeps her important.
          <br/><br/>Her followers can clash with those of Waukeen, as their goals of creating corporations and survival of the fittest economics clash with her general gold must flow attitude.
          <br/><br/>Usually worshipped where Bane is, as her attitude towards business in essentially his in war.
        `,
        recentEvents: `
        `
      },
      moradin: {
        name: "Moradin",
        race: "Dwarf",
        alignment: "LG",
        gender: "Male",
        class: "Divinity",
        plane: "Mount Celestia",
        status: "Divinity Granting Spells",
        divineRank: "Exalted",
        divinePantheon: "Dawn War, Dwarven",
        divineTitle: "god of Creation and Industry",
        divineDomain: "Forge",
        enabled: true,
        src: "https://static.wikia.nocookie.net/forgottenrealms/images/7/70/Moradin_symbol_-_Mike_Schely.jpg",
        tagLine: "Dwarven Creation Exalted Deity",
        // adherents: [],
        divineTagLine: "The Soul Forger",
        piety: [
          "???"
        ],
        commandments: [
          "Remain stoic and tenacious in the face of catastrophe.",
          "Uphold and promote loyalty to family, loyalty to clan, and loyalty to your people.",
          "Legacy is paramount. Whether smith or ruler, to create something that lasts the ages is to change the world for the better."
        ],
        blurb: `

        `,
        recentEvents: `
        `
      },
      kord: {
        name: "Kord",
        race: "Human",
        gender: "Male",
        class: "Divinity",
        plane: "Ysgard",
        alignment: "CN-G",
        status: "Divinity Granting Spells",
        divineRank: "Exalted",
        divinePantheon: "Dawn War",
        divineTitle: "god of Storms and War",
        divineDomain: "War, Tempest",
        enabled: true,
        src: "https://static.wikia.nocookie.net/criticalrole/images/b/bb/Kord.png",
        tagLine: "War and Storms Exalted Deity",
        // adherents: [],
        divineTagLine: "The War Storm",
        piety: [
          "As a devotee of Strength, you have proven your wisdom and your allegiance to the ideals of the Storm Lord. Once on each of your turns when you hit a creature with a weapon attack, you can deal an extra 1d6 (1d8 if using a Legendary Ax) lightning damage to the target. You can use this trait a number of times equal to your Proficiency bonus. You regain all expended uses when you finish a long rest.",
          `While wielding a Legendary Axe, you may cast <a target="_blank" href="http://dnd5e.wikidot.com/spell:erupting-earth">Erupting Earth</a> using your Wisdom, channeling the Strength of Kord through the very ground you walk`,
          `Many contests are decided in the first few moments. You may add a d4 to all initiative rolls.`
        ],
        commandments: [
          "Bravery above all. There is no glory in cowardice.",
          "Strength is the path to greatness, in war and peace. However, greatness is the wise use of strength",
          "Your passions and desires fuel your strength, heed them. All emotions are valid but cowardice.",
        ],
        blurb: `
          A deity in unknown to most in Faerûn, but listed by scholars as an Exalted Deity renknown across many worlds of the Material Plane.
        `,
        recentEvents: `
        `
      },
      varthos: {
        name: "Varthos",
        alignment: "N-CE",
        race: "Human",
        gender: "Male",
        plane: "Pandemonium",
        class: "Divinity",
        status: "Divinity Granting Spells",
        divineRank: "Intermediate",
        divinePantheon: "Human",
        divineTitle: "god of Strife, Savagery, Plunder",
        divineDomain: "War",
        enabled: true,
        // src: "",
        tagLine: "Deity of Savage War and Plunder",
        adherents: [],
        divineTagLine: "The Iron Reaver",
        piety: [
          "???",
          "???",
          "???"
        ],
        commandments: [
          "Only the strong have a right to happiness.",
          "Wealth and power are claimed not given; raid, steal, and prosper.",
          "Take what you desire; the weak serve the strong.",
          "Answer every insult, lie when necessary, and covet what is not yours",
        ],
        blurb: `
          There are many legends of Varthos, some claiming he was the first human to kill another, while others claim him to be the brother of Erathis.<br/>
          He is worshipped by raiders, pirates, and barbrians south of the Empire. However, clerics of Kord converted many from the more evil ways by promises of prosperity from peace with the Empire.<br/>
          His worship is waning in the South, but those who detest the control of peace keep him in their hearts.
        `,
        recentEvents: `
        `
      },
      melora: {
        name: "Melora",
        race: "Human",
        alignment: "N",
        plane: "???",
        gender: "Female",
        class: "Divinity",
        status: "Divinity Granting Spells",
        divineRank: "Exalted",
        divinePantheon: "Dawn War",
        divineTitle: "goddess of the Wilderness and the Sea",
        divineDomain: "Nautre",
        enabled: true,
        src: "",
        // "I usually don't meddle in the affairs of humans. People pray to gods to save them, but there's a natural order to things. What would happen to the lion if I were to answer the prayer of every antelope? Death is a part of life, it is natural."
        tagLine: "Nature Exalted Deity",
        // adherents: [],
        divineTagLine: "The Wildmother",
        piety: [
          "???"
        ],
        commandments: [
          "Protect the untamed wildernesses of the realm from exploitation and destruction.",
          "Slay abominations and other dark mockeries of nature.",
          "Neither condemn nor apologize for the savagery of nature. Exist in harmony with it."
        ],
        adherents: [
          "Reidoth the Druid"
        ],
        blurb: `

        `,
        recentEvents: `
        `
      },
      milritha: {
        name: "Milritha",
        race: "Half-Elven",
        gender: "Female",
        alignment: "L-NG",
        plane: "Bytopia",
        class: "Divinity",
        status: "Divinity Granting Spells",
        divineRank: "Intermediate",
        divinePantheon: "Human",
        divineTitle: "goddess of the Peace, Hearth, Family",
        divineDomain: "Peace, Life",
        enabled: true,
        src: "https://icon2.cleanpng.com/20180204/ctw/kisspng-drawing-rose-fire-flower-flaming-rose-cliparts-5a772eaa01ebc1.0136256815177601700079.jpg",
        tagLine: "?",
        // adherents: [],
        divineTagLine: "The Peace Flame",
        piety: [
          "???"
        ],
        commandments: [
          "The Abode of Peace is for all, share it.",
          "Form a peaceful, wholesome, joyful, and home, homes are the cornerstone of civilization",
          "Love the next generation by ensuring it is equipped to heal the world",
          "Violence is a weapon of last resort. Diplomacy and understanding are the paths to long-lasting peace.",
          "Seek the innocence of all beings. Friendship and hospitality can convert even the most hardened fiend."
        ],
        blurb: `
        `,
        recentEvents: `
        `
    },
    torog: {
        name: "Torog",
        race: "???",
        gender: "Male",
        alignment: "NE",
        plane: "Material Plane",
        class: "Divinity",
        status: "Divinity Granting Spells",
        divineRank: "Exalted",
        divinePantheon: "Dawn War",
        divineTitle: "god of Underdark",
        divineDomain: "Death, Blood",
        enabled: true,
        src: "https://static.wikia.nocookie.net/criticalrole/images/e/e5/Crwkng.png",
        tagLine: "?",
        // adherents: [],
        divineTagLine: "The Crawling King",
        piety: [
          "???"
        ],
        commandments: [
          "Seek and exalt places where no light touches.",
          "Revel in the pain you inflict on others, and relish the pain you suffer yourself as an offering to the Crawling King.",
          "Imprison those who cannot resist you, and drag all life into the darkness."
        ],
        blurb: `

        `,
        recentEvents: `
        `
      },
      zehir: {
        name: "Zehir",
        race: "Human",
        gender: "Male",
        alignment: "N-CE",
        plane: "Astral Plane",
        class: "Divinity",
        status: "Divinity Granting Spells",
        divineRank: "Exalted",
        divinePantheon: "Dawn War",
        divineTitle: "god of Darkness and Poison",
        divineDomain: "Trickery, Death, Blood",
        enabled: true,
        src: "https://static.wikia.nocookie.net/criticalrole/images/b/bf/CloakedSerpent.png",
        tagLine: "?",
        // adherents: [],
        divineTagLine: "The Cloaked Serpent",
        piety: [
          "???"
        ],
        commandments: [
          "Keep your acts obfuscated and secret. The night is your greatest ally.",
          "Strike quickly and without reason. Blind the target with their own confusion.",
          "Kill slowly. Agonizingly. Or worse, make them enjoy it."
        ],
        blurb: `

        `,
        recentEvents: `
        `
      },
      pelor: {
        name: "Pelor",
        race: "Human",
        gender: "Male",
        alignment: "NG",
        plane: "Elysium",
        class: "Divinity",
        status: "Divinity Granting Spells",
        divineRank: "Exalted",
        divinePantheon: "Dawn War",
        divineTitle: "god of the Sun and Agriculture ",
        divineDomain: "Light, Life, Nautre",
        enabled: true,
        src: "https://static.wikia.nocookie.net/criticalrole/images/b/bf/BLackSalander_Dawnfather.jpg",
        // https://static.wikia.nocookie.net/vsbattles/images/b/b7/AthenaRiordan.jpg/revision/latest?cb=20170716205734
        // https://i.pinimg.com/736x/95/92/dc/9592dc8ae1f54d3390a9b80818fd645a--archer-armor-female-archer.jpg
        tagLine: "Dawnfather and Sun Exalted Deity ",
        // adherents: [],
        divineTagLine: "The Dawnfather",
        piety: [
          "???"
        ],
        commandments: [
          "Be ever vigilant for evil. People are quick to forget the lessons of the past.",
          "Help relieve the suffering of the innocent wherever it exists.",
          "Deliver the light of the Dawnfather where darkness dwells, with kindness, compassion, and mercy."
        ],
        blurb: `
          Captured Tharizdun deep in the Abyss with the help of other deities.
        `,
        recentEvents: `
        `
      },
      raven_queen: {
        name: "The Raven Queen",
        race: "Prime Elf",
        alignment: "LN",
        gender: "Female",
        plane: "Shadowfell",
        class: "Divinity",
        status: "Divine",
        divineRank: "Exalted",
        divinePantheon: "Dawn War, Shadar-Kai",
        divineTitle: "goddess of Death and Memory",
        divineDomain: "Grave, Blood, Twilight",
        divineTagLine: "The Matron of Death",
        enabled: true,
        piety: [
          "???"
        ],
        commandments: [
          "Death is the natural end of life. There is no pity for those who have fallen.",
          "The path of Fate is sacrosanct. Those who pridefully attempt to cast off their destiny must be punished.",
          "Undeath is an atrocity. Those who would pervert the transition of the soul must be brought down.",
        ],
        src: "https://static.wikia.nocookie.net/wolfpack-legends-dd/images/c/cf/Raven_queen.jpg",
        tagLine: "Death Exalted Deity of Shadowfell",
        blurb: "Shrouded in mystery, a shadow of enigma, with various claims of her origin, few things known with certainty about her. The Raven Queen rules the Fortress of Memories in Shadowfell and command the pale shadow-elves, the Shadar-Kai elves, her devout worshipers.",
        recentEvents: `
          <h4>Session 6</h4> During a vision of Shadowfell, Sparka meets a Shadar-Kai who explains that The Raven Queen ha granted him a boon. A raven landed on his shoulder and spoke to him in The Raven Queen's voice and explained that his focused was imbued with the power of Shadowfell, like a Shadowfell Shard would be.
        `
      },
      ioun: {
        name: "Ioun",
        race: "Human",
        gender: "Female",
        class: "Divinity",
        alignment: "N",
        plane: "Endless Ethereum",
        status: "Divinity Granting Spells",
        divineRank: "Exalted",
        divinePantheon: "Dawn War",
        divineTitle: "goddess of Knowledge",
        divineDomain: "Knowledge, Arcana",
        enabled: true,
        src: "https://db4sgowjqfwig.cloudfront.net/campaigns/178962/assets/819580/200px-Ioun_Symbol.png",
        tagLine: "Knowing Mentor Knowledge Exalted Deity",
        // adherents: [],
        divineTagLine: "The Knowing Mentor",
        piety: [
          "As a devotee of Ioun, you are a font of knowledge on all things. You can cast identify without needing to provide material components with this trait a number of times equal to your Proficiency modifier. You regain all expended uses when you finish a long rest. ",
          "As a champion of knowledge and seeker of knowledge, pnce per day, you may access the Ioun's Library. For 1 minute you gain knowledge from a work that could be found in the library of Endless Ethereum. This can manifest as gaining a proficiency in a skill, tool, or language. Less mechanically, it could be most recent map of an area, a book on clothing styles, cooking recipes, military strategies, cults, magic weapons, etc. It should be noted that works are not perfect and might have errors. It is up to the DM to decide what knowledge is known or not. Once per day if the DM rules that Endless Ethereum would not contain a work, this trait maybe be cast again. ",
          "Your connection to the goddess of knowledge flows through you. You may add a d4 to all knowledge checks."
        ],
        commandments: [
          "Seek the perfection of your mind by bringing reason, perception, and emotion into balance with one another.",
          "Accumulate, preserve, and distribute knowledge in all forms. Pursue education, build libraries, and seek out lost and ancient lore.",
          "Do not discriminate on how knowledge is passed on, distill truth from legends, songs, and art.",
          "Unmask those who use knowledge to enslave and lie without moral reason. Learn their secrets and unveil them to the world.",
        ],
        blurb: `
          In Faerûn, Ioun is known by the "Ioun Stones" powerful magical items originally discovered from other worlds where Ioun appears to be revered as a deity.
        `,
        recentEvents: `
          <h4>Session 8</h4> Cecil Solonna noticed the symbol of Ioun on his hand when he cast Identify using a Divine Boon.
        `
      },
      sanıl: {
        name: "Sanıl",
        race: "???",
        gender: "???",
        class: "Divinity",
        alignment: "CN",
        plane: "Limbo",
        status: "Divinity Granting Spells",
        divineRank: "Intermediate",
        divinePantheon: "Dawn War",
        divineTitle: "god of Chaos and Entropy",
        divineDomain: "Trickery",
        enabled: true,
        src: "https://i.pinimg.com/originals/67/1b/69/671b692596ba9e93eb13f11fda5a4682.jpg",
        tagLine: "Chaos Deity",
        // adherents: [],
        divineTagLine: "The Wild Fox",
        piety: [
          "???"
        ],
        commandments: [
          "Order stagnates, change brings evolution.",
          "Accept your passions and desires to pursue all pleasures and amusements.",
          "Accept no law over you and ferment revolution against all authority",
        ],
        blurb: `

        `,
        recentEvents: `
        `
      },
      ragnarok: {
        name: "Ragnarok",
        race: "???",
        gender: "???",
        class: "Solar",
        alignment: "CN-G",
        plane: "???",
        status: "Deceased",
        divineRank: "Solar",
        divinePantheon: "Humanoid",
        divineTitle: "",
        patron: "Celestial",
        enabled: true,
        // src: "https://i.pinimg.com/originals/67/1b/69/671b692596ba9e93eb13f11fda5a4682.jpg",
        tagLine: "Slain Solar",
        // adherents: [],
        divineTagLine: "The Solar",
        piety: [
          "???"
        ],
        commandments: [
        ],
        blurb: `
          Solar who was slain near Phandalin. A statute to him stands watch over the cemetary with him and companions, including family, who fell in the battle.
        `,
        recentEvents: `
        `
      },
      asmodeus: {
        name: "Asmodeus",
        race: "Devil",
        gender: "Male",
        plane: "The Nine Hells",
        class: "Divinity",
        alignment: "LE",
        status: "Divinity Granting Spells",
        divineRank: "Exalted",
        divinePantheon: "Dawn War, The Nine Hells",
        divineTitle: "god of Tyranny",
        divineDomain: "Trickery, Order, Blood",
        enabled: true,
        src: "https://static.wikia.nocookie.net/forgottenrealms/images/4/42/Asmodeus_AFR.jpg",
        tagLine: "Helllord and Tyranny Exalted Deity",
        // adherents: [],
        divineTagLine: "The Lord of the Nine Hells",
        piety: [
          "???"
        ],
        commandments: [
          "Assert dominance and power over others. Show your strength of will in the image of the Lord of Hells.",
          "Repay cruelty done unto you with further evil. If others show you kindness, exploit it.",
          "As you ascend to power, do not pity or show mercy to those caught udnerfoot as you climb. The weak deserve not compassion."
        ],
        blurb: `

        `,
        recentEvents: `
        `
      },
      bane: {
        name: "Bane",
        race: "Human",
        gender: "Male",
        plane: "Acheron",
        alignment: "LE-N",
        class: "Divinity",
        status: "Divinity Granting Spells",
        divineRank: "Exalted",
        divinePantheon: "Dawn War",
        divineTitle: "god of War and Conquest",
        divineDomain: "War, Order",
        enabled: true,
        src: "https://static.wikia.nocookie.net/forgottenrealms/images/c/c3/SymboIOfBane.png",
        tagLine: "Conquest Exalted Deity",
        // adherents: [],
        divineTagLine: "The Conqueror",
        piety: [
          "???"
        ],
        commandments: [
          "Fear is your ally. Conquer yours, and draw it from your foes. Blades end lives, fear ends empires.",
          "Tolerate no disent. Decimate disorder and rebellion. Douse the flame of hope.",
          "To kill a dissident is life's greatest pleasure, perfecting the art of slaughter in pursuit of order is life's greatest pursuit.",
        ],
        blurb: `

        `,
        recentEvents: `
        `
      },
      gruumsh: {
        name: "Gruumsh",
        alignment: "CE-N",
        plane: "Acheron/Pandemonium",
        race: "Orc",
        gender: "Male",
        class: "Divinity",
        status: "Divinity Granting Spells",
        divineRank: "Exalted",
        divinePantheon: "Dawn War, Orcish",
        divineTitle: "god of Destruction",
        divineDomain: "War, Tempest, Death",
        enabled: true,
        src: "https://static.wikia.nocookie.net/criticalrole/images/4/44/Gruumsh_-_Claudio_Pozas.png",
        tagLine: "Orc Destruction Exalted Deity",
        // adherents: [],
        divineTagLine: "The One-Eyed Ruiner",
        piety: [
          "???"
        ],
        commandments: [
          "Ruin. Conquer. Kill.",
          "There are no emotions but fury and joy. The rest are weakness.",
          "Do as you will, and let no one stop you."
        ],
        blurb: `

        `,
        recentEvents: `
        `
      },
      tharizdun: {
        name: "Tharizdun",
        plane: "Abyss",
        // https://www.google.com/search?q=Tharizdun&rlz=1C5CHFA_enUS796US796&oq=thari&aqs=chrome.1.69i57j69i59j46i512j0i512j0i10i512j69i60l3.2684j0j1&sourceid=chrome&ie=UTF-8
        race: "Multi-mouthed Serpent Demon",
        gender: "Genderless",
        alignment: "CE",
        class: "Divinity",
        status: "Chained in Bottom of the Abyss",
        divineRank: "Exalted",
        divinePantheon: "Dawn War",
        divineTitle: "god of Madness and Chaos",
        divineDomain: "War, Tempest",
        enabled: true,
        src: "https://static.wikia.nocookie.net/criticalrole/images/3/3e/ChainedOblivion.png",
        tagLine: "Chained Chaos Exalted Deity",
        // adherents: [],
        divineTagLine: "The Chained Oblivion",
        piety: [
          "???"
        ],
        commandments: [
          "Uncover, restore, and exalt forgotten shrines in his honor.",
          "Offer and siphon power to the Chained Oblivion until his liberation comes.",
          "Ruin and raze the realms to prepare for the Epoch of Ends."
        ],
        blurb: `
          ...
          <br/><br/>
          Chained in the depths of the Abyss, with the location of the chains scattered and protected across the Multiverse. Memory spells make one only known and guarded by each of the following Pelor, Erathis, Bahamut, Moradin, Ioun, and one known to no one.
        `,
        recentEvents: `
        `
      },
      vecna: {
        name: "Vecna",
        race: "Undead",
        plane: "Hades",
        gender: "Male",
        alignment: "NE",
        class: "Divinity",
        status: "Divinity Granting Spells",
        divineRank: "Exalted",
        divinePantheon: "Dawn War",
        divineTitle: "god of Evil Secrets",
        divineDomain: "Death, Knowledge, Arcana",
        enabled: true,
        src: "https://static.wikia.nocookie.net/criticalrole/images/b/bc/Vecna_-_Claudio_Pozas.png",
        // https://images-geeknative-com.exactdn.com/wp-content/uploads/2019/11/27171128/vecna_by_maradraws_dcj5utv-fullview.jpg
        tagLine: "Chained Chaos Exalted Deity",
        // adherents: [],
        divineTagLine: "The Undying",
        piety: [
          "???"
        ],
        commandments: [
          "Learn all forms of knowledge. Never reveal all you know.",
          "Find the seed of Evil in your heart and nourish it; find and exploit it in others to your advantage.",
          "Oppose the followers of all other deities so that all kneel to Vecna."
        ],
        blurb: `
          While scarce, there is evidence that Vecna was a necromancer from another world of the Material Plane who ascended to Exalted level. While unclear, rumors persist as to how he did it.
        `,
        recentEvents: `
        `
      },
      sehanine: {
        name: "Sehanine",
        race: "Prime Elf",
        plane: "Arborea",
        // alignment:
        gender: "Female/???",
        alignment: "CN-G",
        class: "Divinity",
        status: "Divinity Granting Spells",
        divineRank: "Exalted",
        divinePantheon: "Dawn War, Seldarine",
        divineTitle: "goddess of Freedom and Trysts",
        divineDomain: "Twilight, Trickery, Moon",
        enabled: true,
        src: "https://www.worldanvil.com/uploads/images/026155574f5559a442933a05c94ef524.jpg",
        tagLine: "Moon and Freedom Exalted Deity",
        // adherents: [],
        divineTagLine: "The Moonweaver",
        piety: [
          "As a devotee of Sehanine, you have proven yourself a worthy potential champion of the Moonweaver. As a bonus action, you can invoke her protection from the prying eyes of busy bodies to follow your passions; twilit shadows cover you, providing you with a +8 bonus to Stealth for 1 minute. If it is at night, the bonus is +12. Once you use this trait, you can't use it again until you finish a long rest.",
          `The truth can hide in the shadows, but can be revealed in the Moonlight. You can cast <a target="_blank" href="http://dnd5e.wikidot.com/spell:moonbeam">Moonbeam</a> using Wisdom at 3rd Level with this trait. Once you cast the spell in this way, you can't do so again until you finish a long rest. Wisdom is your spellcasting ability for this spell.`,
          "???"
        ],
        commandments: [
          "Seize your own destiny by pursuing your passions.",
          "The shadows protect you from the burning light of fanatical good and the absolute darkness of evil.",
          "Walk unbridled and untethered, finding and forging new memories and experiences."
        ],
        blurb: `
          Sehanine the Moonweaver was one of the Prime Elves formed first with Corellon.
        `,
        recentEvents: `

        `
      },

      primus: {
        name: "Primus",
        race: "Modron",
        plane: "Mechanus",
        // alignment:
        gender: "???",
        alignment: "LN",
        class: "Divinity",
        status: "Divinity Granting Spells",
        divineRank: "Intermediate",
        divinePantheon: "Human",
        divineTitle: "god of Unflinching Order",
        divineDomain: "Order",
        enabled: true,
        src: "https://static.wikia.nocookie.net/forgottenrealms/images/e/e0/Mechanus.png",
        tagLine: "Moon and Freedom Exalted Deity",
        // adherents: [],
        divineTagLine: "The One and the Prime",
        piety: [
          "???"
        ],
        commandments: [
          "Chaos destroys, Order preserves",
          "There is no passion, no peace, no greed, no mercy, no calm, no fury...there is order",
          "Yielding, manipulating, altering Order for any reason is chaos, oppose chaos.",
        ],
        blurb: `

        `,
        recentEvents: `
        `
      },

      zariel: {
        name: "Zariel",
        gender: "Female",
        blurb: "Lord of Avernus, the 1st Layer of the 9 Hells",
        enabled:  true,
        plane: "The Nine Hells",
        divineRank: "Lesser Idol",
        race: "Archdevil (former Solar)",
        patron: "Fiend",
        divineTagLine: "Archduchess of Avernus",
        divinePantheon: "The Nine Hells",
        alignment: "LE"
      },
      dispater: {
        name: "Dispater",
        gender: "Male",
        blurb: "Lord of Dis, the 2nd Layer of the 9 Hells",
        enabled:  true,
        race: "Archdevil",
        patron: "Fiend",
        divineRank: "Lesser Idol",
        divineTagLine: "The Master of the Game",
        plane: "The Nine Hells",
        divinePantheon: "The Nine Hells",
        alignment: "LE"
      },
      mammon: {
        name: "Mammon",
        gender: "Male",
        blurb: "Lord of Minauros, the 3rd Layer of the 9 Hells",
        enabled:  true,
        race: "Archdevil",
        patron: "Fiend",
        divineRank: "Lesser Idol",
        divineTagLine: "The Financier of the Hells",
        plane: "The Nine Hells",
        divinePantheon: "The Nine Hells",
        alignment: "LE"
      },
      levistus: {
        name: "Levistus",
        gender: "Male",
        blurb: "Lord of Stygia, the 5th Layer of the 9 Hells",
        enabled:  true,
        plane: "The Nine Hells",
        divinePantheon: "The Nine Hells",
        race: "Archdevil",
        patron: "Fiend",
        divineRank: "Lesser Idol",
        divineTagLine: "The Frozen Prince of Last Resort",
        alignment: "LE"
      },
      glasya: {
        name: "Glasya",
        gender: "Female",
        blurb: "Lord of Malbolge, the 6th Layer of the 9 Hells",
        enabled:  true,
        race: "Archdevil",
        patron: "Fiend",
        divineRank: "Lesser Idol",
        divineTagLine: "The Dark Prodigy",
        plane: "The Nine Hells",
        divinePantheon: "The Nine Hells",
        alignment: "LE"
      },
      baalzebul: {
        name: "Baalzebul",
        gender: "Male",
        blurb: "Lord of Maladomini, the 7th Layer of the 9 Hells",
        enabled:  true,
        plane: "The Nine Hells",
        divinePantheon: "The Nine Hells",
        divineRank: "Lesser Idol",
        divineTagLine: "Lord of the Flies",
        race: "Archdevil",
        patron: "Fiend",
        alignment: "LE"
      },
      fierna: {
        name: "Fierna",
        gender: "Female",
        blurb: "Co-Lord of Phlegethos, the 4th Layer of the 9 Hells",
        enabled:  true,
        plane: "The Nine Hells",
        divinePantheon: "The Nine Hells",
        divineRank: "Lesser Idol",
        divineTagLine: "Fiery Lady of Phlegethos",
        race: "Archdevil",
        patron: "Fiend",
        alignment: "LE"
      },
      belial: {
        name: "Belial",
        gender: "Male",
        blurb: "Co-Lord of Phlegethos, the 4th Layer of the 9 Hells",
        enabled:  true,
        plane: "The Nine Hells",
        divinePantheon: "The Nine Hells",
        divineRank: "Lesser Idol",
        divineTagLine: "Lord of Pain and Suffering",
        race: "Archdevil",
        patron: "Fiend",
        alignment: "LE"
      },

      mephistopheles: {
        name: "Mephistopheles",
        gender: "Male",
        blurb: "Lord of Cania, the 8th Layer of the 9 Hells",
        enabled:  true,
        plane: "The Nine Hells",
        divinePantheon: "The Nine Hells",
        divineTagLine: "The Cold Lord",
        race: "Archdevil",
        patron: "Fiend",
        divineRank: "Lesser Idol",
        alignment: "LE"
      },


      baphomet: {
        name: "Baphomet",
        gender: "Male",
        enabled:  true,
        src: "https://static.wikia.nocookie.net/forgottenrealms/images/3/3e/Baphomet-5e.png",
        plane: "The Abyss:Layer 600: Endless Maze",
        divinePantheon: "Demons",
        divineTagLine: "The Demon Prince of Beasts",
        tagLine: "The Horned King Prince of Beasts",
        race: "Demon Prince",
        status: "Patron Granting Power",
        patron: "Fiend",
        divineRank: "Lesser Idol",
        alignment: "CE",
        blurb: `
          Known as the Horned King, Baphomet divides the creatures of the world into two groups. Those who acknowledge his power are his servants, and he endows them with savagery and a hunter's cunning. The rest are prey, creatures to be hunted and slaughtered. His aim is to transform the cosmos into his personal hunting ground.
          <br/>Baphomet is a savage entity, but he tempers his ferocity with shrewdness. He loves the hunt and the sense of impending doom that comes over prey that can't escape his pursuit. His fondness for labyrinths, instilled in the minotaurs he created, reflects this aspect of his personality. Baphomet studies every detail of the mazes he creates and exults in the dread that overcome those who become lost in them. He tracks them at his leisure, striking only after the maze's contorted corridors have exhausted the energy and the hope of his victims.
        `,
        recentEvents: ``
      },
      zuggtmoy: {
        name: "Zuggtmoy",
        gender: "Female",
        enabled:  true,
        src: "https://i.pinimg.com/originals/f7/98/0f/f7980f8d731d730650ad7a5cbf009f3a.jpg",
        plane: "The Abyss:Layer 222: Shedaklah",
        divinePantheon: "Demons",
        divineTagLine: "The Demon Lord of Fungi, Rot, and Decay",
        tagLine: "Demon Mushroom Queen",
        adherents: [
          "Bamur"
        ],
        race: "Demon Prince",
        status: "Patron Granting Power",
        patron: "Fiend/Undying",
        divineRank: "Lesser Idol",
        alignment: "CE",
        blurb: `
          From the pit of the Abyss, Zuggtmoy is a powerful demon obsessed with fungi, rot, and decay. She battles Juiblex to control of Shedaklah the 222nd level of the Abyss.
        `,
        recentEvents: `
          <h4>Session 8</h4> Ole tells Lars that Bamur worships Zuggtmoy, the Queen of Mushrooms, and has been spreading prophecies that Lars is her herald to bring about her return to this Plane. Bamur believes that The Return of the Queen prophecy applies to her.
          <br/> Lars recalls that she wishes to spread like Fungi, controlling and destroying all. Ole says that Bamur led a revolution in the small Dwarf stronghold of Iron Deeper, resulting in chaos and anarchy for his Demon Queen.
        `
      },
      yan_c_bin: {
        blurb: `
          The Prince of Elder Elemental Air, older than Jinns, fought the gods alongside others Primordials. Evil and want revenge on the gods and all their mortal followers.
        `,
        recentEvents: `
          <h4>Session 12</h4> Sparka conveys that Yan-C-Bin is the one who wants Corpo. Corpo remembers that his mother refused to let him say the name...and gets chills from hearing it outloud.
        `
      }

    },
    circus: {
      lady_carnival: {
        name: `??? "Lady Carnival`,
        race: "Human",
        gender: "Female",
        class: "Noble",
        status: "Alive",
        enabled: true,
        src: "https://i.pinimg.com/originals/a5/3e/05/a53e05298333dc5764b143c4a3a5a194.png",
        tagLine: "Circus Owner/Operator",
        blurb: "Owner of Lady Carnival’s Crazy Carnival Circus of Curios, Curiosities, and Courtesies. Claims to not be afraid of anyone but herself in the mirror on a bad day. Also claimns she loves and pays for the whirlybird so much in Neverwinter they call her the whirlybird queen.",
        recentEvents: `
          <h4>Session 11</h4> Allows Lars, Cecil, and Corpo to join her circus on the way to Cragmaw Castle in exchange for them getting no payment. Is clearly wowed by Genobli the Most Endowded, but says she wants a private audition with him after the performance.
          <br/><br/> She openly mentions multiple times she will pimp the party out since she gets all the payment they would receive.
          <br/><br/> She blatantly lies that she does not know who they work for, not caring that Corpo knows she is lying.
          <br/><br/> Lady Carnival later gives into the "charms" of Corpo when he effortlessly pours wine across her tent into her glass.
          <br/><br/> She is worried at the performance but uses the three party members to wow the audience.
        `
      },
      fuboi: {
        name: "Fuboi",
        race: "Hellfire Tiefling",
        gender: "Male",
        class: "Doofus",
        status: "Alive",
        enabled: true,
        src: "https://pbs.twimg.com/media/EnxkVM5XMAA5KXF.jpg",
        tagLine: "Himbo Fire Dancer",
        blurb: "Member of Lady Carnival’s Circus, stuck on the Fart Wagon since he is too dumb to get out of it. Clearly an idiot.",
        recentEvents: `
          <h4>Session 11</h4> Sits on the fart wagon with Corpo, Cecil, and Lars as they are stuck with him. Mentions that he has a restraining order against him from Maniere for randomly complimenting her calves, but gives Corpo info on Maniere. Is clearly a buffon. Plays Throw Rocks with Lars.
        `
      },
      maiere: {
        name: "Maniere",
        race: "Fire Genasi",
        gender: "Female",
        class: "Bard: Glamour",
        status: "Alive",
        enabled: true,
        src: "https://preview.redd.it/1d83iofwqaf61.png?auto=webp&s=bbaa9093837837d2535af48e4c34768a28ad68c5",
        tagLine: "The Burning Ballerina",
        blurb: "Member of Lady Carnival’s Circus, known as one of the best dancers around. Said her family cast her out after she burnt down the house one too many times.",
        recentEvents: `
          <h4>Session 11</h4>
        `
      },
      elvish: {
        name: "Keryketh \"Elvish Vresley\" Ahmaquissar",
        race: "Moon Elf",
        gender: "Male",
        class: "Bard: Cowardice",
        status: "Alive",
        enabled: true,
        src: "https://img-9gag-fun.9cache.com/photo/aW0mw0x_460s.jpg",
        tagLine: "Sweaty Singing Bard",
        blurb: "Elven Bard known infamously as such a bad bard people pay to see him.",
        recentEvents: `
          <h4>Session 11</h4>
          <h4>Session 12</h4> Avlod captured him with the rest of the carnies, but lets him go at the party's request.
        `
      },
      leila: {
        name: "Leila Saffavi",
        race: "Human",
        gender: "Female",
        class: "Bard: Lore",
        status: "Alive",
        enabled: true,
        src: "https://i.pinimg.com/originals/7e/ee/75/7eee75118b845dcfe925a595c0dafbf7.jpg",
        tagLine: "Skilled Bard with Magical Lyre",
        blurb: "Lady Carnival bard contractor hired for the Bugbear slave auction garden party gig. Has high magical bard talent but not a following like Elvish.",
        recentEvents: `
          <h4>Session 11</h4> Has amazing jam session with Cecil Solonna where she reveals more of her backstory. He catches her in a lie about where they are going, but she reveals it supposedly is a Bugbear slave auction...she is doing for the exposure.
          <h4>Session 12</h4> Avlod says he did not see her when they captured the circus members.
        `
      },
    },
    thon_daral_imperial: {
      zorrakir: {
        name: "Zorrakir Sulon",
        race: "Imperial Progeny",
        gender: "Male",
        class: "Fighter: Psi Warrior",
        status: "Alive",
        enabled: true,
        src: "https://media.dndbeyond.com/compendium-images/tcoe/0gqawlEa2tjXGxpc/01-033.png",
        tagLine: "Progeny staff officer assigned to Thon Daral",
        blurb: `
          Zorrakir Sulon, Psionic Scion of Clan Unistus of Line Dreamstone, Lt Colonel of Imperial Army, Command Banner 3rd Legion Nikia, Operations Center Thon Daral, War Institute Alumni
          <br/><br/>
          Imperial Officer trained in Operations. At the War Institute he recieved top marks Campaign Operations, Strategy, Tactics, and Irregular Warfare
          <br/><br/>
          Assigned with his Legion to Thon Daral to help deal with the rapid expansion there.
        `,
        recentEvents: `
          <h4>Session 14</h4> Behind a squad of Imperial Soldiers, confronted Lars as he realized that he was in Grimstone.
        `
      },
      Xaryax: {
        name: "Uummith Xaryax",
        race: "Sikver Dragonborn",
        gender: "Male",
        class: "Cleric: Order",
        status: "Alive",
        enabled: true,
        src: "https://pbs.twimg.com/media/FJ9ygl4XoAMDhxR.jpg",
        tagLine: "Cleric of Bahamut and Sergeant assigned to Zorrakir",
        blurb: `
          Uummith Xaryax, Honorary Imperial Citizen, Cleric of Bahamut, Sergeant of The Order of the Wings, Chaplain of Command Banner 3rd Legion, Retainer Lt. Colonel Zorrakir Thon Daral
          <br/><br/>
          Assigned to 3rd Legion to help Thon Daral as the military takes more and more duties there. Selected by Zorrakir as his Aide de Camp.
        `,
        recentEvents: `
          <h4>Session 14</h4> Behind a squad of Imperial Soldiers, confronted Lars as he realized that he was in Grimstone.
        `
      },
      misspira: {
        name: "Misspira Rarel",
        race: "Imperial Progeny",
        gender: "Female",
        class: "Sorcerer: Divine Soul",
        status: "Alive",
        enabled: true,
        src: "https://i.pinimg.com/564x/61/6c/48/616c48ab90a6c7ccd001b461cba1655b.jpg",
        tagLine: "Progeny Legate Commanding Officer of Thon Daral military base",
        blurb: `
          Misspira Rarel, Angel Scion, of Clan Aftab of Line Majestic, Legate of Imperial Army, Command Banner 3rd Legion Nikia, Commanding Military Officer of Thon Daral, War Institute Alumni
          <br/><br/>
          She has slightly glowing amber eyes.
        `,
        recentEvents: `
        `
      },
      // Legate Scion Misspira Rarel
      dorrissa: {
        name: "Dorrissa Zaloth",
        race: "Imperial Progeny",
        gender: "Female",
        class: "Sorcerer: Divine Soul / Warlock: Celestial",
        status: "Alive",
        enabled: true,
        src: "https://i.pinimg.com/564x/bf/63/2f/bf632fc2c6a9b31d58e823646e6c7124.jpg",
        tagLine: "Progeny rising in the ranks of the Onyx Inquisition. Assigned to Thon Daral Station",
        blurb: `
          Dorrissa Zaloth,  Conquest Psionic Scion of Clan Tuŝebla of Line Iron, Agent Black-Class Onyx Blood, Sub-Inquisitor Thon Daral
          <br/><br/>
          She has red skin with golden hair with orange streaks. She also has the standard Tiefling Horns and a Tail. She often uses Mask of Many Faces to smooth out appearance to be standardly attractive, though her real appearance has patchy hair, one eye all black and the other all white.
        `,
        recentEvents: `
          <h4>Session 22</h4> Meets the party with Bargomkk Chreetuk in tow at the Kord Tournament. She gives them the options for the jobs she needs help with, explaining that in 5-6 days higher ranking Imperials will arrive for negotiations about Faerûn and hunting down the Cult of the Draogn.
          <br/><br/>
          Corpo offended her, Sass hit on her mostly unsuccessfully.

        `
      },
      chreetuk: {
        name: "Bargomkk Chreetuk",
        race: "Bugbear",
        gender: "Female",
        class: "Barbarian: Path of the Giant / Fighter: Battle Master",
        status: "Alive",
        enabled: true,
        src: "https://i.pinimg.com/736x/f3/3c/af/f33caf4af9be98767cafa117670b81c0--bugbear-hobgoblin.jpg",
        tagLine: "Progeny rising in the ranks of the Onyx Inquisition. Assigned to Thon Daral Station",
        blurb: `
          Bargomkk son of Chreetuk, Honorary Imperial Citizen, Sergeant in Onyx Arm, Retainer Sub-Inquisitor Dorrissa Zaloth
          <br/><br/>
          Hulking, massive Bugbear with a Dragon Wrath Polehammer. Body guard of Dorrissa Zaloth.
        `,
        recentEvents: `
          <h4>Session 22</h4>
          Wrestled Lars and won, though he gave pointers along the way. After Dorrissa and Corpo clashed he showed spite towards him.
          <br/><br/>
          Pointed to his temple to tell Sparka to read his mind. Thinks "You use the name Redlake? Don't you hurt my friend"

        `
      },
    },
    thon_daral_city: {
      gnoodnuck: {
        name: "Gnoodnuck",
        race: "Gnome",
        gender: "Male",
        class: "Soulknife Rogue",
        status: "Alive",
        enabled: true,
        src: "https://mj-gallery.com/f119944a-48b2-4402-9706-746d991947ea/grid_0_640_N.webp",
        tagLine: "Psionic Sushi expert with a portable hole filled with fish",
        blurb: "Psionic Sushi expert with a portable hole filled with fish",
        recentEvents: `

        `,
      },

      dhudaric_darkflayer: {
        name: "Dhudaric Darkflayer",
        tagLine: "Proprietor of Ancestor's Forge",
        src: "https://www.worldanvil.com/uploads/images/3a1566a84be328eb5c9459354da32052.jpg",
        enabled: true
      },

      ivlathra: {
        name: "Ivlathra",
        src: "https://pbs.twimg.com/media/ExAw6BQXEAAwBS2.jpg",
        tagLine: "Proprietor of Shadow and Blade",
        enabled: true
      },

      morning_mist_of_solemn_gulch: {
        name: "Morning Mist of Solemn Gulch",
        tagLine: "Proprietor of A Magic Item Shop",
        src: "https://i.redd.it/autsrsuk52461.jpg",
        enabled: true
      },

      oshra_beach: {
        name: "Oshra Beach",
        tagLine: "Proprietor of Nature's Blessing and Curse",
        src: "https://64.media.tumblr.com/d3be041f49739a32abae123bad538364/tumblr_p9gtydNQva1tqcvoho1_500.jpg",
        enabled: true
      },

      yashazha: {
        name: "Yashazha",
        tagLine: "Proprietor of Forked Tongue Fletching",
        src: "https://i.imgur.com/5eNsbn3.png",
        enabled: true
      },

      ianaros_zicklefockle: {
        name: "Ianaros Zicklefockle",
        tagLine: "Proprietor of Zicklefockle Luthier",
        src: "https://i0.wp.com/www.sageadvice.eu/wp-content/uploads/2017/08/DFMegs1UMAACBXG.jpg",
        enabled: true
      },

      spudi: {
        name: "Rirspudinzarkanata",
        tagLine: "Proprietor of Ecstasy and Fire: Monk Supply, Dojo, Icecream, and 'Massage' Parlor",
        src: "https://drive.google.com/uc?id=1KOzJStn_n5H9PAuSai8ToSC2iu59GNr6",
        enabled: true
      },

      gire: {
        name: "Gire Pebblesweat",
        race: "Gnome",
        gender: "Male",
        class: "Commoner",
        status: "Alive",
        enabled: false,
        src: "",
        tagLine: "",
        blurb: "Trader merchant who wants to be a bard, but is terrible at music",
        recentEvents: `

        `,
      },
    },
    oxkadi: {
      shakda: {
        name: "Shakda",
        tagLine: "Teenage girl from Murar",
        blurb: "Shakda daughter Velnehr, missing 18 year old from the Oxkadi town of Murar.",
        // src: "",
        enabled: true,
        recentEvents: `
          <h4> Session 24 </h4> When the party arrives in Murar, she is reported missing.
        `
      },
      tasja: {
        name: "Tasja",
        tagLine: "Teenage girl from Murar",
        blurb: "Tasja daughter Görnau, missing 19 year old from the Oxkadi town of Murar.",
        // src: "",
        enabled: true,
        recentEvents: `
          <h4> Session 24 </h4> When the party arrives in Murar, she is reported missing.
        `
      },
      kulvesa: {
        name: "Kulvesa",
        tagLine: "Teenage girl from Murar, sister of Jhorsa",
        blurb: "Kulvesa daughter Duunzuduhr",
        // src: "",
        enabled: true,
        recentEvents: `
          <h4> Session 24 </h4> When the party arrives in Murar, she is reported missing.
        `
      },
      mavol: {
        name: "Mavol",
        tagLine: "Young man from Murar",
        blurb: "Mavol son Obiyon son Makat",
        // src: "",
        enabled: true,
        recentEvents: `
          <h4> Session 24 </h4> When the party arrives in Murar, Mavol tells them about how he and Kulvesa were shot at from the middle island, some people swarmed the boat and took her, then he swam to safety.
        `
      },
      yulven: {
        name: "Yulven",
        tagLine: "Bar Keep of the Murar Tavern",
        blurb: "Yulven",
        // src: "",
        enabled: true,
        recentEvents: `
          <h4> Session 24 </h4> When the party arrives in Murar, he talks to Corpo and Lars as they investigate the tavern.
        `
      },
      zaldir: {
        name: "Zaldir",
        tagLine: "townspeaker for Murar",
        blurb: "Zaldir son Darnar, townspeaker of Murar",
        // src: "",
        enabled: true,
        recentEvents: `
          <h4> Session 24 </h4> When the party arrives in Murar, he talks to them about the situation of the missing girls to fill them in.
        `
      },
      sylara: {
        name: "Sylara",
        tagLine: "Proprietor of Forked Tongue Fletching",
        blurb: "Sylara",
        // src: "",
        enabled: true,
        recentEvents: `
          <h4> Session 24 </h4> When the party arrives in Murar, she is reported missing.
        `
      },

    },
    iron_legion: {
      avlod: {
        name: "Avlod the Blade",
        race: "Hobgoblin",
        gender: "Male",
        class: "Hobgoblin Warlord",
        status: "Alive",
        enabled: true,
        src: "https://i.pinimg.com/474x/ce/57/0d/ce570db19dfae5b62d4823b3876447ba.jpg",
        tagLine: "Hobgoblin Warlord from The First World",
        blurb: "",
        recentEvents: `
          <h4>Session 11</h4> Avlod appears and says that they have upheld their end of the bargain. He pays some gold as his forces move on the Castle. He shows Sparka where a Hobgoblin he did not recognize brutally murdered Zephyr Wind. Cecil says they should be in touch. Sparka asks who will be in charge if he is returning to his homeworld, he tells them.
          <br/> He says they are worthy of being enemies or allies, nothing in between brefore leaving.
          <h4>Session 12</h4> Avlod rides back up on his dire-tiger (the head of Grol on his belt) to ask the party if they used magic to get a Hobgoblin to betray his oath to King Grol.
          <br/> Avlod says he captured some carnies (not the dignitaries though) implying what he does with them will depend on how Sparka answers. Sparka lies, saying he used magic. It is believed.
          <br/> Avlod casts the Shamed Hobgoblin out, giving him a life debt to Sass until Sass lifts it. The Hobgoblin is furious, begs for death, but Avlod is firm. The party tells him to let the carnies go, so he does not make them slaves.
        `
      },
    }







    // darkChapels: {
    //   scipia: {
    //     name: "Scipia Stormchapel",
    //     race: "Mostly Human",
    //     gender: "Female",
    //     class: "Paladin:Vengeance, Warlock:Hexblade ",
    //     status: "Alive",
    //     enabled: false,
    //     src: "https://i.pinimg.com/originals/1d/81/a2/1d81a2fe985fee53bd9ed6f6c681daf4.png",
    //     tagLine: "Popular Heroine, Leader of Dark Chapel",
    //     blurb: "Massively Popular heroine, people know her name and bards sing of her battles and feats. A sworn Paladin of Vengeance with a Hexblade pact, she exterminates dangerous cults and monsters from Sword Coast to Silver Marches. Her loyal party members, The Dark Chapel, assist her in earning glory and fighting darkness. She has a +1 longsword that does 1d6 radiant damage",
    //     recentEvents: ""
    //   },

    //   caldera: {
    //     name: "Caldera Demonfall",
    //     race: "Tiefling",
    //     gender: "Female",
    //     class: "Paladin: Watcher, Sorcerer: Divine Soul",
    //     status: "Alive",
    //     enabled: false,
    //     src: "https://i.pinimg.com/originals/f2/c0/41/f2c041e4ec7c38cf0ae0598e94a58b86.png",
    //     tagLine: "Hunter of Demons and Fiends in The Dark Chapel",
    //     blurb: "Born to a Paladin Tiefling and a descendant of Helm. Caldera's mother had escaped her Asmodeus-worshipping cult of a family, then dedicated her life to ridding the world of evil. She then met the charming Paladin and got married. Her parents are semi-retired in Waterdeep, working with the Order of the Gauntlet in the city. Caldera struck off to become a Watcher Paladin to follow her parents in fighting evil, and also manifested Divine Sorcerer powers. Lover of Algar, they met as working together to hunt some Barghests and continued on together. They joined the Dark Chapel and boldly follow Scipia, where Caldera slays demons by the gross. She has a reserved yet blessed personality. She wields a +1 katana that has a marbled-blade, being made of silver and cold iron. Extra damage to Fiends and Fey.",
    //     recentEvents: ""
    //   },

    //   algar: {
    //     name: "Algar Monsterdam",
    //     race: "Human",
    //     gender: "Male",
    //     class: "Ranger: Monster Hunter, Fighter",
    //     status: "Alive",
    //     enabled: false,
    //     src: "https://qph.fs.quoracdn.net/main-qimg-1c91364b551738031cbbfde260c4fc14",
    //     tagLine: "Hunter of Monsters in The Dark Chapel",
    //     blurb: "Lover of Caldera, they met as working together to hunt some Barghests and continued on together. They joined the Dark Chapel and boldly follow Scipia, where Algar gets to track and kill monsters with powerful allies. He is fairly warm and joyous",
    //     recentEvents: ""
    //   },

    //   xindove: {
    //     name: "Xindove Brassblood",
    //     race: "Half-Elf",
    //     gender: "Female",
    //     class: "Sorcerer: Draconic, Warlock: Celestial",
    //     status: "Alive",
    //     enabled: false,
    //     src: "https://i.pinimg.com/564x/86/38/3b/86383b1b4556ae8b8770e74cd28908b5.jpg",
    //     tagLine: "Magical Scion in The Dark Chapel",
    //     blurb: "From the Brassblood clan, her father being the heir to the family title. She manifested as a Brass Blood Draconic Sorcerer, while also earning the attention of the family's patron Unicorn. She is now adventuring to earn her stripes on her own so to speak. She is close with her great-grandfather, wielding his +1 Cold Iron Dagger.",
    //     recentEvents: ""
    //   }
    // }
  }
}

export default characterData;
