import React                 from "react";

import Titleize              from "../shared/Titleize";
import itemTranslator        from "../../lib/itemTranslator";


class NewShop extends React.Component {
  render () {
    return (
      <tr key={this.props.indy}>
        <td></td>
        <td>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://5etools-mirror-1.github.io/items.html#${this.props.item.name.replace(",", "%2c")}_${this.props.item.source}`}
          >
            {this.props.item.name}
          </a>
        </td>

        <td>
          {this.props.item.staff ? 'Staff ' : ' ' }

          {this.props.item.wondrous ? 'Wondrous Item ' : ' ' }

          {this.props.item.poison ? 'Poison ' : ' ' }

          {this.props.item.tattoo ? 'Tattoo ' : ' ' }

          {
            this.props.item.weapon &&
            <>
              <Titleize word={this.props.item.weaponCategory}/> Weapon,&nbsp;
            </>
          }

          <Titleize word={itemTranslator.getType(this.props.item.type) || ''}/>
        </td>

        <td>
          {this.props.item.make}
        </td>

        <td>
          <Titleize word={this.props.item.rarity === "none" ? "mundane" : this.props.item.rarity}/>
        </td>

        <td>
          {this.props.item.reqAttune ? "X" : ""}
          {this.props.item.reqAttune}
        </td>

        <td>
          {itemTranslator.getPricing(this.props.item)}
        </td>
      </tr>
    )
  }
}

export default NewShop;
