function DriveImage(props) {
  const driveLink = (driveId) => {
    // return `https://drive.google.com/thumbnail?id=${driveId}&sz=w10000`
    // return `https://drive.lienuc.com/uc?id=${driveId}`?
    return `https://drive.google.com/thumbnail?id=${driveId}&sz=w10000`

  }

  const src = props.driveId ? driveLink(props.driveId) : props.src

  return (
    <img
      className={props.className}
      src={src}
      alt={props.alt}
    />
  )
}

export default DriveImage;
