const killsData = {
  2: {
    Lars: [
      "Hacked Goblin in half and then decapitated",
      "One tied up Goblins after another agreed to talk if the party killed them",
      "Another tied up Goblins after third agreed to talk if the party killed them",
      "Goblin guard with a Javelin pinned to the ground"
    ],
    Sparka: [
      "Mindsliver on captured, unconscious, bound, and helpless Goblin"
    ],
    Sass: [
      "Decapitated wolf",
      "Goblin guard trying to flee into cave",
    ],
    Corpo: [
      "Bashed in Goblin ambusher's head with staff"
    ]
  },

  3: {
    Lars: [
      "King Blargh, cuts out his guts and says the bard was right, his mother is ugly, then decapitated him",
      "Castrated and decapitated Blargh's pet wolf Ripper",
    ],
    Sparka: [
      "Magic Missile finish Force-Breath hurt Goblin",
      "Magic Missile finish Force-Breath hurt Goblin",
      "Magic Missile finish Force-Breath hurt Goblin"
    ],
    Sass: [
      "One goblin died outright from Force Breath Attack",
      "Goblin guard trying to flee into cave",
    ]
  },

  4: {
    Corpo: [
      "Lightning-Strike Thunder Explosion killed a Dread Flame Pirate",
      "Lightning-Strike Thunder Explosion killed a Dread Flame Pirate",
      "Lightning-Strike Thunder Explosion killed a Dread Flame Pirate"
    ],
    Lars: [
      "Decapitates Redbrand thug Bret",
    ],
    Sparka: [
      "Crushed to death Redbrand Thug Chad",
      "Crushed to death Redbrand Thug Chet",
      "Burned to death passed out Redbrand in the Sleeping Giant Inn when he set it aflame",
      "Burned to death passed out Redbrand in the Sleeping Giant Inn when he set it aflame",
    ],
    Sass: [
      "Shot with Hail of Thorns into Redbrand Chaz's left eye",
      "Kneecap Redbrand Thug Trey, interrogate, he insults dragonborn, kills him",
    ],
    Cecil: [
      "Rapier stab Redbrand Thug lookout Tanner, interrogates, then kills swiftly with rapier"
    ]
  },

  5: {
    Corpo: [
      "Murder Tristan by taking his dart from the balls to the Lungs",
      "Took out the Nothic with rapid martial arts strikes",
    ],
    Lars: [
      "Javelin into Redbrand Brad, which burst through him",
      "Javelin went through Brad into Redbrand Bryce, killing him too",
    ],
    Sparka: [
      "Coup de grace killed an unconscious Nothic that Corpo knocked out",
    ],
    Sass: [
      "Kills Rebrand goon Hunter with slice and dice short swords",
      "Assassianted Redbrand Justin after he thought the party was friendly"
    ]
  },

  6: {
    Sass: [
      "Arrow to kill Bug Bear Mosk right under the bejewled eye patch",
      "Arrow to kill Gogt square in the pelvis, cuts belt which lowers Wildon's dagger to the ground",
      "Arrow to kill Qevin through the throat that burst a hail of thorns behind him"
    ],
    Lars: [
      "Decapitates Bug Bear Kuggek saying...'They drew first blood...'",
      "Castrate-Kill Direwolf",
      "Cleaves Redbrand Devin in half whil saying 'You only get one shot, one oppurtunity...'",
      "Axe Slaps Skymmer to unconscious",
      "Executes the jailed Glasstaff"
    ],
    Corpo: [
      "Kills Skylar with a whirlwind Curb stomp"
    ]
  },
  7: {
    Lars: [
      "Castrate slice Tarhun, though Sass later used Cure Wounds to stabilize"
    ],
  },
  8: {
    Sass: [
      "Main-takes down fleeing Goblin that had been spying on them...Sparka then finishes it",
      "Zombie with a crisp shot",
      "Zombie with Hail of Thorns and shot infused by unnamed Drake",
      "Zombie with shot infused by unnamed Drake"
    ],
    Sparka: [
      "Firebolt incinerates Goblin Sass maimed to take alive",
      "Firebolt incinerates Red Wizard Hamun Kost",
      "Magic Missiles Banshee after realizing force damage would be good to end her"
    ],
    Lars: [
      "Executes charmed Goblin captured by Corpo after it was super nice and told them lots of things. Adds head to necklace",
      "Zombie...while its buddy gets up",
      "Castrate-kill last Zombie"
    ],
    Corpo: [
      "Kills one zombie with thunder kick",
      "Kills one zombie with martial arts",
      "Kills one zombie with Flurry of Blows",
      "Kills zombie with Breath of the Dragon",
      "Kills one zombie with Breath of Dragon...somewhere in there",
    ],
    Cecil: [
      "Zombie with Vicious Mockery",
      "DRAGON SLAYER VENOMFANG: A mind melting solo with Dissonant Whispers crushed his skull to oblivion. Draonic Gift of Pseudodragon Familiar."
    ],
  },
  9: {
    Sparka: [
      "Telekinetic shoves Cultist onto bonfire, immediately burns them to death",
      "Mushroom possessed Twig Blights charge barricade through Sparka's bonfire, die.",
      "Mushroom possessed Twig Blights charge barricade through Sparka's bonfire, die.",
      "Mushroom possessed Twig Blights charge barricade through Sparka's bonfire, die.",
      "Mushroom possessed Twig Blights charge door through Sparka's bonfire, die.",
      "Mushroom possessed Twig Blights charge door through Sparka's bonfire, die.",
      "Sparka kills Twig Blight with Firebolt"
    ],
    Lars: [
      "Kills castrates the Cultist and throws his junk in Spark's bonfire",
      "Castrate KILLS Faveric by beheading",
      "Ring of Jumping leaps up the tower, attacks a Giant Spider KILLS GIANT SPIDER and Castrates it's spinnerets",
      "Leaps off the tower, kills spider, castrates it, rides corpses 30foot down to the ground... all of this is amazing",
      "KILLS giant spider and castrates its spinnerts"
    ],
    Corpo: [
      "Kills Shade horrifyingly with a Dragon Phallus",
      "Charges and punches at Shadow to Kills it by using the Dragon Phallus shot through it"
    ],
    Sass: [
     "Shoots Giant Spider climbing up wall with infused and radiant damage KILLS it by staking it to the ground with the arrow through it"
    ]
  },
  11: {
    Sparka: [
      "Fire ball Dire Wolf in Grol's chambers",
    ],
    Sass: [
      "Split Dire Wolf in Grol's chambers in two with an arrow",
    ]
  },
  12: {
    Corpo: [
      "Staff kill a Stirge",
      "Staff kill a Stirge",
      "Staff kill a Stirge",
      "Staff kill a Stirge",
      "Punch kill a Stirge",
      "Punch kill a Stirge",
      "Punch kill a Stirge",
      "Flurry Blow Punch kill a Stirge",
      "Flurry Blow Punch kill a Stirge",
      "Flurry Blow Punch kill a Stirge",
      "Minotaur, yelled 'Dick Train Express', pulling its pelvis out so it falls apart"
    ],
    Lars: [
      "Headbutt kill a Stirge",
      "Elbow kill a Stirge",
    ],
    Cecil: [
      "Vicious Mockery kills Stirge",
    ],
    Sass: [
      "Short Sword a Stirge",
      "Short Sword a Stirge",
      "Minotaur by Arrow through skull to mount to the wall",
      "Minotaur by Arrow hitting its own axe into it and shattering it",
    ]
  },
  13: {
    Sass: [
      "Wraith, arrow to the incorporeal face, shocked it could hurt then he died",
      "Shadow, arrow in the mouth and it electrify dies like it is eating a sandwich",
      "Ghast, shot in the head, shocking him so he felldown into bonfire and burned Darth-Vader-style",
      "Shadow, with Bowser's help dead kills by bouncing an arrow off the ceiling",
      "Zombie,shooting it through the head into the wall"
    ],

    Corpo: [
      "Ghast, pulled out holy water, drips on his hands, Lebron James sprayed up in air, Flipped self up with Gust, punches the Ghast's head into the immovable door",
      "Zombie, with Moonbeam Arc of the Covenant Style",
      "Another Zombie, with Moonbeam Arc of the Covenant Style",
      "Another Zombie, with Moonbeam Arc of the Covenant Style",
      "Another Zombie, with Moonbeam Arc of the Covenant Style",
      "Another Zombie, with Moonbeam Arc of the Covenant Style",
      "Another Zombie, with Moonbeam Arc of the Covenant Style",
      "Gnome zombie, gusting over them and then pulling Dawnbringer through it's ass through its mouth",
      "Another Zombie, grabs by scruff of neck to throw it up and mace bash it across the room",
      "Shadow, hits with Mace...then grabs the Shadow by the head and pelvic thrust kills him",
      "Ghast, says remember your brother then shoves Dawnbringer through it's ass through its mouth",
      "Ghast, Dragon Ball Z fusion dance one at temple and one at dick",
    ],

    Sparka: [
      "Shadow, it screams at it burns out",
      "Ghast, with Fireball as it burns to nothing",
      "Shadow, with Wand of Magic Missile at 6 Level",
      "Ghast, with Wand of Magic Missile at 6 Level",
    ],

    Lars: [
      "FLAMESKULL, yells 'If you had a dick I'd cut it off' then bashes him to pieces",
      "Ghast, Axe castration, throwing nutsack at other Ghasts",
      "Ghast, Axe castrates another Ghast throwing ball sack down and stomps on them"
    ]
  },
  14: {
    Lars: [
      "NAT 20 KILL/Castrate Hob Captain's nuts. Throws nuts outside and yells I AM THE DRAGON",
      "Rage chop Iron Shadow in half, thrown down body Thump...then Thump",
      "Kills the Bugbear between the legs, looks at his friends, and tea bags him?",
      "Lars kills him. Cuts off his nuts, holds em up. Yells 'I am the Dragon' and kicks him off the roof"
    ],
    Corpo: [
      "Attacks the devastator on the roof. Punch, staff, KILL by pushing self with staff and shoving it's dagger into face",
      "Swings at Iron Shadow with staff, one punch, Flurry of Blows KILLS. Corpo is not happy, the least sexual he has been. Grabs the dagger with its pommel and shoves it into it's face",
      "Kills sleep Hobgob with spinny-thing with staff and rams up the sleeping Hobgob butt hole...smashes him...Tabula Recti?"
    ],
    Sass: [
      "Hail of thorns hits two hobgoblins. KILLS ONE so hard its head gets plastered to the floor in front of other."
    ],
    Sparka: [
      "Hit Thopleth with a Firebolt. Kill Thopleth who blinks back to see fire strike him in the face",
      "Magic missile and KILLS sleeping Bugbear? Just pummeling his on the ground"
    ]
  },
  15: {
    "The Duchess": [
      "Kill and lazily eat the squirrel Lars tricked"
    ],
    "Corpo": [
      "Slit the throat of Harbin Wester while whispering 'Shhhhh'"
    ]
  },
  17: {
    Corpo: [
      "Eldritch Maul powered staff hit on Scout, dragging her into bushes, and snapping her neck"
    ],
    Sass: [
      "Shooting Archer in breast through her nipple",
      "Shoots Scout straight through wiener and then body falls into the fire "
    ],
  },
  18: {
    Sass: [
      "DRAGON SLAYER CLOUDCHASER: Arrow pierces the cloud to lodge right in his eye as hail of thorns pins him to thr ground."
    ],
  },
  19: {
    Lars: [
      "Kill Ogre before he could yell, slitting throat and whispering \"I am the dragon\" and chopping off dick",
      "Kill Ogre by slicing opening his gut as his intenstines fall out",
      "Kill Ogre by shoving other ogre dick in his mouth, then strangling him with his own intestines after the ogre said I smoke life I don't need drugs to get high."
    ],
    Sass: [
      "Kill Ogre by shooting his club so hard it clubs him and impales his own head",
    ],
    "The Duchess": [
      "Kill Jazelle by Ordering Duchess to sick the neck, and electricity bite to decapitate the body, heads roll off"
    ],
    Corpo: [
      "Kill White Guard Drake with punch to kill scooby white guard drake. Slices its next slowly in front of the White Abbishai",
      "Kill White Abbishai by uttering under breath \"daddy\" juming off Lars's shoulder and double crack of staff to snap the neck",
      "Mercy kill the remaining Guard Drake"
    ],
    Sparka: [
      "Kills Guard Drake by hunting it down on the stairs and firebolts immolating it mid-bark"
    ]
  },
  20: {
    Sass: [
      "Kills Kobold by shooting awake one in back of the head, slumps down in its soup",
      "Kills sleeping kobold with sword",
      "Kills person by arrow through the head and pins to training dummy",
      "Kills Ogre via a theme so shoots him through his pee hole",
      "Kills ogre by shooting two arrows on the artery on his dick"
    ],
    Lars: [
      "Kills guy hewing him in half...then slaps him with his dick on the bottom half cause he has bad initiative.",
      "Kills Brozig the Ogre chops off dick and kicks him in the knee...throws the dick at the other"
    ],
    Corpo: [
      "Kills ogre with a staff then buries dagger in his skull",
      "Kills Irub the Ogre, breathin' heavy dashes, slides around his legs, staff straight through the rectum, then on his back kicks the staff the rest of the way up."
    ],
    Sparka: [
      "Kills Air Elemental by evaporating/blowing it away with wall of fire"
    ],
    Cecil: [
      "GIANT SLAYER Kills Blago with a Vicious Mockery that explodes his damn head like a cherry tomato cause that's how he do"
    ]
  },
  21: {
    Sparka: [
      "KILL Dragon Scale Sorcerer ",
      "KILL kobold to it's death off the bridge ",
      "GIANT SLAYER  KILLS Firebolt to the head falls",
      "Giant corpse crushes kobold",
      "Giant corpse crushes kobold",
      "Giant corpse crushes kobold",
      "Giant corpse crushes kobold",
      "GIANT SLAYER Sparka KILL Giant right in head....dies from suffocation BA Quickened Spell Firebolt for 20 damage ",
    ],

    Sass: [
      "KILL Ogre shoots penis off such that he flops on such one kobold is KILLED by penis falling",
      "KILL Dr. Steel Night arrow through his weener",
      "KILL Feral Shadow arrow through his weener"
    ],
    Lars: [
      "NAT 20 KILLS Ogre by throwing it through his throat...falls on 2 Kobolds",
      "Ogre corpse crushes kobold",
      "Ogre corpse crushes kobold",
      "Kill Joy is a Myth after Interrogation attempt while Corpo unbuckles his belt she spits in Lars face, he spits back, grabs a phallus and hits her with it, spays her...shoves her out window and high fives with a bloody hand Corpo"
    ],
    Corpo: [
      "KILL run away Kobold with an arrow",
      "KILL Carnal Serpent with Georgian dancing on his knees, spinning around elbowing the guy's leg so he is crippled to the ground, then when he is on his knees he stands over him and says \"Be grateful you don't meet my Carnal serpent today\" and then snaps his neck. His last words are...what?"
    ],
    Duchess: [
      "KILL Infernal Ashe by biting her head off. Head falls over in front of other Cultist "
    ],
    Cecil: [
      "Convinced Ogre to charge Hadrian and die",
      "Convinced Ogre to charge Hadrian and die",
      "Convinced Ogre to charge Hadrian and die",
    ]
  },
  22: {
    Corpo: [
      "Knocked October Tempest off Brian during a barrel roll to his death",
      "Kills Abara the wizard by leaping out a window, casting Levitate, catching Wildon, haivng Wildon hold onto his dick, drawing Father's Love, shooting at disadvantage, hitting the Vage using minor illusion to make arrow look like a dick."
    ],
    Sparka: [
      "Thopleth with a firebolt Sparka puts on the mask and stares at Thopleth to scare him...he dies burning and screaming ",
      "Ogre with a firebolt NAT 20 and turned to ash"
    ],
    Sass: [
      "Ogre with Precise Strike through his ears so he falls in front of other dude.",
      "Ogre by shooting through his bicep and knocks his own head Harry Potter style ."
    ],
    Lars: [
      "Hadrian by slicing off legs. Asks Hadrian where Corpo's daddy is. 'With my DADDY!'' Lars chops his balls off, shoves them down his mouth, Cecil yells bring me his head, Lars gets a silver platter."
    ]
  },
  25: {
    Sparka: [
      "Kills him Mavol by putting a burning hands in his face. He passes but don't matter.  He crisps and literally crumbles to a dust",
      "On ensnared prisoner...leans in real close..looks deep in his eyes....Says I am going to spare you more pain...softly kisses his forehead and does create bonfire",
      "TRIP KILL Arrow, Firebolt, Javelin....Arrow him to a tree, javelin catches on fire, and on tree burning there is an abandoned tree house"
    ],

    Sass: [
      "Kill Oxkadi Barb by shooting the arrow in a way that the arrow makes him fly up then crash back down",
      "NAT 20 Shoots fleeing Oxkadi. Knock out strike by ensnaring him down",
      "TRIP KILL Arrow, Firebolt, Javelin....Arrow him to a tree, javelin catches on fire, and on tree burning there is an abandoned tree house",
    ],

    Lars: [
      "TRIP KILL Arrow, Firebolt, Javelin....Arrow him to a tree, javelin catches on fire, and on tree burning there is an abandoned tree house",
    ],

    Corpo: [
      "Guy sneaking up on the others on the middle island...Staff of storm of lightning with bolts around it and the dude's head pops off with his eyes burned out"
    ]
  },
  26: {
    Sass: [
      "Pops out of bag of holding and shoots guy three times in the face so hard he flies down the hill into bushes",
      "Shoot down cultist attacking Lars who recongized him",
      "Tanarukk Sharpshot him by shooting sword into his face",
    ],
    Lars: [
      "Splits cultist in half",
      "Cultist Alonso by twirling axe around and then sever neck",
      "Bandit and flips balls at another dude does 1 bludgeoning damage. NO STI since incels",
      "Axe slash to the head then severing the big scrotum and hanging on his waist",
      "Wizard Halfling by cutting both his legs off..but dying. Cauterize his wounds",
    ],
    Cecil: [
      "Kills cultist by gravity well sucking them up into the air and dropping down"
    ],
    Sparka: [
      "Cultist downhill via Fireball",
      "Cultist downhill via Fireball",
    ],
    Corpo: [
      "Tanarukk with Staff of Thunder and Lightning. His eyes light up like Thor, he shoves staff up it's ass...exploding from inside out with dick flying into Sass' face but it misses",
      "Femme cultist by snapping her neck with staff",
      "Lilith Blood Moon...last thing she sees...him standing over her with staff...saying goodbye ",
      "Cultist Priestess like Rafiki knocks her unconscioously by chanting Asanta Sanna squash banana ",
    ]
  },
  27: {
    Corpo: [
      "Elmeth, a senior citizen male centaur with a thunderclap from the staff of storm and lightning at the party at Rengroth...a pious holy one",
      "Gavian, a middle aged male human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a very kind being",
      "Aldric, a young adult male human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a happy soul",
      "Eamon, youth male human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a generous soul",
      "Lysandra, a middle aged female human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a bit of an asshole",
      "Bertram, a senior citizen male human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a bit of an asshole",
      "Thokar, an elderly male half orc with a thunderclap from the staff of storm and lightning at the party at Rengroth...a hilarious soul",
      "Elara, a young adult female human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a murderer",
      "Grukka, youth female orc with a thunderclap from the staff of storm and lightning at the party at Rengroth...a murderer",
      "Liora, a young adult female human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a pious holy one",
      "Malda, an elderly female half orc with a thunderclap from the staff of storm and lightning at the party at Rengroth...a happy soul",
      "Durin, elderly male dwarf with a thunderclap from the staff of storm and lightning at the party at Rengroth...a very kind being",
      "Thalos, elderly male dwarf with a thunderclap from the staff of storm and lightning at the party at Rengroth...a generous soul",
      "Vorika, a youth female half orc with a thunderclap from the staff of storm and lightning at the party at Rengroth...a very kind being",
      "Ryden, a young adult male human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a very kind being",
      "Eudora, elderly female human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a hilarious soul",
      "Alden, youth male human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a happy soul",
      "Gromak, a senior senior citizen male half orc with a thunderclap from the staff of storm and lightning at the party at Rengroth...a generous soul",
      "Kieran, a young adult male human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a philanthropist",
      "Cyra, youth male centaur with a thunderclap from the staff of storm and lightning at the party at Rengroth...a murderer",
      "Bryant, a senior citizen male human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a murderer",
      "Mara, an elderly female half orc with a thunderclap from the staff of storm and lightning at the party at Rengroth...a murderer",
      "Thurin, a young adult male dwarf with a thunderclap from the staff of storm and lightning at the party at Rengroth...a generous soul",
      "Darian, a young adult male human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a murderer",
      "Gralk, a middle middle aged male half orc with a thunderclap from the staff of storm and lightning at the party at Rengroth...a bit of an asshole",
      "Ghurza, a senior citizen female orc with a thunderclap from the staff of storm and lightning at the party at Rengroth...a hilarious soul",
      "Isla, a young adult female human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a happy soul",
      "Miranda, a senior citizen female human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a very kind being",
      "Adelia, a senior citizen female human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a drunkard",
      "Leona, a young adult female human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a drunkard",
      "Valna, a middle middle aged female half orc with a thunderclap from the staff of storm and lightning at the party at Rengroth...a patron of the arts",
      "Verna, a senior citizen female human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a drunkard",
      "Aldrik, a senior citizen male human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a cruel teacher",
      "Elara, a young adult female human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a philanderer",
      "Roderic, a young adult male human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a pious holy one",
      "Braelyn, a senior citizen male centaur with a thunderclap from the staff of storm and lightning at the party at Rengroth...a happy soul",
      "Mabel, a senior citizen female human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a very kind being",
      "Thaddeus, a middle aged female human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a hilarious soul",
      "Eilwen, elderly female human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a very kind being",
      "Ghorka, youth female orc with a thunderclap from the staff of storm and lightning at the party at Rengroth...a philanderer",
      "Edgar, elderly male human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a bit of an asshole",
      "Bhertram, a middle aged male human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a patron of the arts",
      "Skull Breaker, a senior citizen female human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a patron of charity",
      "Swadtgait, a young adult male centaur with a thunderclap from the staff of storm and lightning at the party at Rengroth...a hilarious soul",
      "Jame, a middle aged female human with a thunderclap from the staff of storm and lightning at the party at Rengroth...a patron of barbarians",
    ]
  },
  32: {
    Sparka: [
      "Sleep to KO Cultist of the Dragon before giving to the Inquisition"
    ]
  },
  33: {
    Lars: [
      "Sootborn with Erupting Earth up his smokey ass",
      "Salamander with Erupting Earth Skewering through his tail",
      "Salamander with Javelin and yelling I am the dragon",
      "DRAGON SLAYER SHAHMATA: With double javelin toss from long ass range down throat",
    ],

    Sass: [
      "Sootborn with NAT 20 Hail of Thorns",
      "Sootborn with NAT 20 Hail of Thorns explosion",
      "Sootborn by hitting him really hard",
      "Salamander with frozen arrow to face...freezing it as he is freaking then SHATTERS into oblivion",
    ],

    Corpo: [
      "Sootborn by triple punch Flury of Blows Heating Up",
      "Cherry pick Sass' kill of a Salamander by WWE neck snap",
      "Sootborn...it is trembling turns to see him licking lips....spinning staff with lightning....then pushes against wall and he ashes himself",
      "Salamander by Hercules chucking it into space",
    ],

    Cecil: [
      "Sootborn by shoving via Pulse Wave off their Ship",
      "Sootborn by shoving via Pulse Wave off their Ship",
      "Sootborn by shoving via Pulse Wave off their Ship",
      "Salamander by shoving via Pulse Wave off the ship but moving the other ship so it fell",
      "GIANT SLAYER! With Vicious Mockery...head exploding Denethor Tomato",
    ],

    Sparka: [
      "Cherufe with a Cold bolt the second he sees him",
      "GIANT SLAYER! Kills Giant with Mindsliver as it flees...it hearing 'COWARD'"
    ]
  },
  34: {
    Lars: [
      "Salamander by decapitating the head and slapping it at their friend",
      "By shoving up Axe Salamander's cloaca CASTRATION",
      "Greataxe Salamander by cutting him in half",
    ],
    Sparka:[
      "Firebolt over Lars head KILL Goliath Wizard",
      "Sootborn by shoving into Lava"
    ],
    Sass: [
      "Salamander shoot in the next...gasping through mouth ....help me...to his friend ",
      "Sootborn with Longbow"
    ],
    Corpo: [
      "Fire Elemental by sucking him in and farting him out"
    ],
    "Torsus Skyrend": [
      "Fire Myrmidon \"The slave is a fit of horny rage...\""
    ]
  },
  35: {
    Corpo: [
      "PUNCH kill Sootborn by grabbing by the next and holds it over the lava and drops it into the lava"
    ],

    Cecil: [
      "GIANT SLAYER KILLS Fire Giant with Dissonant Whispers"
    ],

    Sparka: [
      "GIANT SLAYER KILLS Fire Giant with double Mind Sliver, spin and TK shove sootborn as it falls",
      "TK shove Sootborn into Lava as Fire Giant falls to the ground",
      "Salamander with a small ice crystal EXPLODES so that just Lars' nose is cold...Max Power Cold Ball",
      "Salamander with a small ice crystal EXPLODES so that just Lars' nose is cold...Max Power Cold Ball",
      "Fire Elemental Myrmidon with a small ice crystal EXPLODES so that just Lars' nose is cold...Max Power Cold Ball",
    ],

    Lars: [
      "Recklessly Kills Slamander by slicing up its head and then bats it into the lava"
    ]
  },
  36: {
    Corpo: [
      "GIANT SLAYER runs up and punches the giant to it's knees, climbs up to its head and dragon breath down on its face"
    ]
  }
}

export default killsData
