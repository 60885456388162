import React from "react";

import TabulaCritica    from "./tabula_critica/TabulaCritica"
import TabulaHomicidium from "./kills/TabulaHomicidium"
import Collapsible      from "./shared/Collapsible"

import killsData        from "../data/kills";
import deathsData       from "../data/deaths";
import tearsData        from "../data/tears";
import castratiData     from "../data/castrati";
import smoochesData     from "../data/smooches";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {show: ""}
  }

  collapseToggle(kind) {
    this.state.show === kind ? this.setState({show: ""}) : this.setState({show: kind})
  }

  render() {
    return (
      <div className="standard-panel">
        <h1>
          The Phandalin Five:
        </h1>
        <h2>
          The Return of The Queen
        </h2>

        <div className="internal-panel">
          <h2>
            <br/>
            Stat Tables
            <br/><br/>
          </h2>

          <Collapsible
            title="Tabula Critica"
            toggle={this.collapseToggle.bind(this)}
            kind="critical"
            show={this.state.show}
          >
            <TabulaCritica/>
          </Collapsible>

          <Collapsible
            title="Tabula Homicidium"
            toggle={this.collapseToggle.bind(this)}
            kind="kills"
            show={this.state.show}
          >
            <TabulaHomicidium title={"KILLS/KOs"} data={killsData}/>
          </Collapsible>

          <Collapsible
            title="Tabula Morior Invictus"
            toggle={this.collapseToggle.bind(this)}
            kind="deaths"
            show={this.state.show}
          >
            <TabulaHomicidium title={"NEAR DEATHS"} data={deathsData}/>
          </Collapsible>


          <Collapsible
            title="Tabula Lacrimae"
            toggle={this.collapseToggle.bind(this)}
            kind="tears"
            show={this.state.show}
          >
            <TabulaHomicidium title={"TEARS"} data={tearsData}/>
          </Collapsible>

          <Collapsible
            title="Tabula Castratus"
            toggle={this.collapseToggle.bind(this)}
            kind="castrati"
            show={this.state.show}
          >
            <TabulaHomicidium title={"CASTRATED FOES"} data={castratiData}/>
          </Collapsible>

          <Collapsible
            title="Tabula Osculum"
            toggle={this.collapseToggle.bind(this)}
            kind="osculum"
            show={this.state.show}
          >
            <TabulaHomicidium title={"SMOOCHES"} data={smoochesData}/>
          </Collapsible>

          <Collapsible
            title="Tabula Dracocidium"
            toggle={this.collapseToggle.bind(this)}
            kind="dragonKills"
            show={this.state.show}
          >
            <TabulaHomicidium kind="dragonKills" title={"DRAGONS SLAYED"} data={killsData}/>
          </Collapsible>

          <Collapsible
            title="Tabula Gigantumocidium"
            toggle={this.collapseToggle.bind(this)}
            kind="giantKills"
            show={this.state.show}
          >
            <TabulaHomicidium kind="giantKills" title={"GIANTS SLAYED"} data={killsData}/>
          </Collapsible>

          <h2>
            <br/>
            Part 1: Lost Mine of Phandelver
            <br/><br/>
          </h2>

          <Collapsible
            title="The Gates of Neverwinter (#1 - 6/26)"
            toggle={this.collapseToggle.bind(this)}
            kind="session0"
            show={this.state.show}
          >
            <ul>
              <li>
                Character Creation
              </li>
              <li>
                Revealing Backgrounds, why did you take the job?
              </li>
              <ul>
                <li>
                  Lars has a large bar tab, so takes a job.
                </li>
                <li>
                  Sparka is ready to get out and explore the world! Takes job to head to Phandalin since seems like a good place to look for mother and/or father.
                </li>
                <li>
                  Cecil is wanting to explore to get adventures to write stories about.
                </li>
                <li>
                  Corpo is wanting to be in search of his Djinn father
                </li>
                <li>
                  Grios just needs to get out of Neverwinter, get moving, get on the road
                </li>
              </ul>
              <li>
                <strong>END SESSION 0</strong>
              </li>
              <li>
                Meeting Gundren, Sildar, and Wildon at the gates
              </li>
              <li>
                Lars and Gundren teach the group that dwarves do predator handshakes and headbutt each other.
              </li>
              <li>
                Cecil notes that Sildar is not a simple bodyguard, staring off into the distance not watching Gundren, but can't figure out really what he is. Cecil then joins group.
              </li>
              <li>
                Grios and Sparka walk up. Sparka mentions name and Grios is triggered by name Redlake. HJe recognizes it as a dragon clan name. Lars joins the group and hands him a flask. Grios drinks it up.
              </li>
              <li>
                Fiddlestump is talking about killing 3 dragons with an unsharp knife. Corpo is not amused with lies, walking over and pushing him out of the way.
              </li>
              <li>
                Gundren tells them (after successful persuasion), that he and his brothers have found something big and buried and ancient, possibly worth a ton of gold. Says he should have more gold to pay more if they complete getting Wildon to Phandalin.
              </li>
              <li>
                ¿Corpo flirts with Gundren? (My notes are confused at this point)
              </li>
              <li>
                Gundren leaves with Sildar to go on ahead.
              </li>
              <li>
                Party heads off, Fiddlestump playing terrible music but Cecil is kind and harmonizes with him. Wildon is a huge fan of Cecil.
              </li>
              <li>
                Wildon keeps calling Oxen by different male names...despite them being clearly female.
              </li>
              <li>
                Players shred the plans, DM cries and has another beer to drown his pain and pays for it with terrible handwritting.
              </li>
              <li>
                Corpo hates liars, has exchange with Cecil:
                <code>
                  <br/>
                  Corpo: "We have no use for liars."
                  <br/>
                  Cecil: "I am a storyteller...is that lying?"
                  <br/>
                  Corpo: "No, to tell a story you must first know truth"
                </code>
              </li>
              <li>
                Party stops to take watch, Grios gets a badass survival check and finds them a perfect campsite overlooking the road and very safe.
              </li>
              <li>
                Wildon says he will take watch and they all shove his dumbass in his tent...he talks more big game and Corpo blows the tent pole over, tent mostly collapsed on him. Wildon says "I like it better this way!"
              </li>
              <li>
                On Cecil's watch, he rolls a NAT 1 he thinks a wolf is attacking Wildon, and casts dissonant whispers on the sleeping commoner halfling....doing 13 damage. 1 short of perma-instant-death.
              </li>
              <li>
                Wildon is dying with blood oozing from ears. Sparka tries to stabilize him but fails. Cecil uses last spell slot to heal the halfling he nearly (1hp off) killed.
              </li>
              <li>
                Next is Lars turn at watch...NAT 1 he also thinks a wolf is attacking Wildon, charges over to attack and gets NAT 1. The tent collapse more on the hapless Halfling who at this point is having a bad night.
              </li>
              <li>
                On the road the next day, they see individuals (never asked names) on the side of the road needing to get a wheel back on their wagon.
              </li>
              <li>
                They lie saying that they are a family going to Neverwinter, but party notices that they are clearly not related.
              </li>
              <li>
                They ask the party for help. Sparka notices that they are eyeing a chest in the wargon nervously. He tells Lars.
              </li>
              <li>
                 Lars then asks for money to help them. They are caught off guard by random well-armed strangers demanding payment for help. They offer 15cp. Lars pushes on what they got in the chest.
              </li>
              <li>
                Someone notices that they are reaching for weapons, clearly defensive.
              </li>
              <li>
                Corpo without warning levitates the chest out of the wagon towards the party. Sparka is not pleased, and neither are the travelers.
              </li>
              <li>
                The travelers are about to attack due to the chest theft, but Corpo calms them with bold persuasion, saying that he is just helping to lighten the load. The travelers are like....uhhhhhhhhh...sure? Since they actually are able to put the wheel back on they sort of buy it.
              </li>
              <li>
                They walk for the chest, but Lars NAT 20 hits the lock with his greataxe, breaking it. The cultists grab the chest and try to flee quickly from these odd fellows.
              </li>
              <li>
                Corpo uses a gust of wind to take peak into chest. Sees Dragon Cultist robes and expensive diamonds. Does not inform rest of party.
              </li>
              <li>
                Party camps for night, less eventful in the near Halfling murder variety.
              </li>
              <li>
                Next day, they hit the road and they reach a spot a couple of them realize would do well as an ambush. With a NAT 20 Perception, Corpo notices 5 goblins lying in wait to ambush.
              </li>
              <li>
                He knows that they are ambushers and greedy. They will probably hit Wildon first.
              </li>
              <li>
                <strong>END SESSION 1</strong>
              </li>
            </ul>
          </Collapsible>

          <Collapsible
            title="First Castration (#2 - 7/10)"
            toggle={this.collapseToggle.bind(this)}
            kind="session2"
            show={this.state.show}
          >
            <ul>
              <li>
                Retcon-ed Sparka having a vision on the trail, he rolled high and got control over what he saw
              </li>
              <li>
                The party realizes they are in a good ambush site, then notices dead horses. Sass sees that one has a dwarf saddle and one has a human saddle.
              </li>
              <li>
                Corpo sees the 5 Goblins (Nat 20 last session) debates letting the Goblins shoot at Fiddlestump...but ends up using a gust of wind to shove him back into the wagon with a "Fuck you Fiddledick"
              </li>
              <li>
                Corpo did not tell the others though, so they just think he bullied Fiddle.
              </li>
              <li>
                Sparka goes first but notices two Goblins before trying to channel Mind Sliver on accident.
              </li>
              <li>
                Corpo rushes Goblins up top, one hit kills one with a staff attack, smashes in skull with "50 strikes" Punches another one.
              </li>
              <li>
                Lars charges the punched Goblin and slashes him in half with his axe, then beheads him
              </li>
              <li>
                Cecil unleashes a Goblin specific vicious mockery, referencing the Goblin god not wanting this puny Goblin. Also had inspiring music for Lars.
              </li>
              <li>
                Sass notices one Goblin is holding an arm down, shoots at him but misses
              </li>
              <li>
                The Goblin unleashes the wolf who attacks Lars. Lars is then shot with an arrow.
              </li>
              <li>
                Sass is then shot with arrow as the party thinks they might be in trouble.
              </li>
              <li>
                Cecil unleashes another vicious mockery, so brutal!
              </li>
              <li>
                Sparka lands a mind sliver, Goblin is messed up.
              </li>
              <li>
                The crew has hard time shooting and hitting, while the wolf mauls Lars more.
              </li>
              <li>
                Sparka lands sleep on the three Goblins, even one who is hiding
              </li>
              <li>
                The wolf panics and tries to flee. Lars castrates him, Sass NAT 20 decapitates him.
              </li>
              <li>
                Lars checks the horses, finds non-horse blood, sees that Gundren and Sildar were drug off north and finds the path.
              </li>
              <li>
                Goblins are tied up as they wake up, but they refuse to talk. Cecil talks in Goblin says he won't kill them, but Goblin is worried the others in the party kill him.
              </li>
              <li>
                Cecil says in Common to party to just nod, but the Goblin knows Common. Lars has to intimidate...Goblin says kill the other two golbins, since they will snitch on him he cooperates. LARS KILLS THEM others are disturbed.
              </li>
              <li>
                Goblin calls Lars "god amongst mortals" spills that THE BLACK SPIDER hired Bugbear King Grol who had lesser Bugbear King Blarg ambush and take Gundren and Sildar. Told to make sure they got a map from Gundren.
              </li>
              <li>
                Lars knows that Black Spider is the symbol of Drow, with their evil Spider goddess Lolth, and that an elite group of Drow special forces during the war. So THE BLACK SPIDER probably a Drow.
              </li>
              <li>
                Goblin agrees to lead them to the hideout.
              </li>
              <li>
                NAT 1 Lars tries to convince fiddlestump that he is such a hero and should go...but Corpo is able to convince him.
              </li>
              <li>
                They have Fiddlestump bring wagon so they might be able to get haul.
              </li>
              <li>
                Corpo interrogates Sparka about his magic usage...Sparka is unsure. Sass says "Strength and Honor" in Draconic, a common saying. Sparka understands it, but keeps a stone face, not sure what is happening.
              </li>
              <li>
                The Goblin (Kree Yark) is leading Lars...starts acting suspicious around a certain location on the trail and runs, Lars impales him to the ground with a javelin.
              </li>
              <li>
                He then sees a trap that the Goblin had hoped that it would catch Lars. Sparka Mage Hand triggers it.
              </li>
              <li>
                Goblin goes unconscious, they throw him next to Fiddlestump who talks some trash.
              </li>
              <li>
                Sparka tries to use his powers, uses Mind Sliver again and kills the Goblin, blood out ears.
              </li>
              <li>
                Corpo NAT 20 notices, calls him on it, but Sparka doesn't know what happened.
              </li>
              <li>
                Sass NAT 20 on Arcana check, sees a Brass Scale on Sparka and figures out that he is Draconic Sorcerer. In Draconic he tells him "I know what you are"
              </li>
              <li>
                Corpo is like wtf is this Draconic? Anyone heard it? Some good some bad?
              </li>
              <li>
                "There were only bad dragons on that battlefield" - Lars
              </li>
              <li>
                Sass then telepathically tells Sparka that he is a Draconic Sorcerer, gets blood from Brass ancestor. Lars says they should move on.
              </li>
              <li>
                "Timeout no Lars, we ain't done with this shit" - Corpo
              </li>
              <li>
                Characters spar a bit over Sparka's secret-ness, but Lars brings up saving the Goblin body to feed to the wolves that they had heard about.
              </li>
              <li>
                Sass creeps ahead to spy on the Goblins...hears waterfall and wolves inside, sees a Goblin sitting up in the brambles being a terrible guard.
              </li>
              <li>
                Sass telepathically tells Sparka that there is a Goblin, Sparka tells the rest what Sass said, but Corpo is like wait wait wtf how did he tell you?
              </li>
              <li>
                Sparka walks off from the forming argument and sees a different Goblin, but thinks it's same one Sass saw.
              </li>
              <li>
                Lars Javelin-One-Shots the Goblin, sticking it to the ground on it's back.
              </li>
              <li>
                Sass sees his Goblin freak and charges. Goblin panics and tries to run inside, opportunity attacks slaughter it dead.
              </li>
              <li>
                The party throws the three Goblins to the wolves who are confused but not mad at them.
              </li>
              <li>
                END SESSION
              </li>
              <li>
                LEVEL 2!
              </li>
            </ul>
          </Collapsible>

          <Collapsible
            title="Cragmaw Cavern (#3 - 7/24)"
            toggle={this.collapseToggle.bind(this)}
            kind="session3"
            show={this.state.show}
          >
            <ul>
              <li>Lars and Sass notice a mostly eaten body of a human and a horse in the wolf den....human had a sabertooth tiger tooth as a necklace</li>
              <li>Corpo comes up and grills Sparka about the mind powers, speaking in draconic, and the telepathy thing with Sass</li>
                <ul>
                  <li>CORPO BLACKS OUT AND FALLS FLAT ON BACK</li>
                  <li>Sparka fails to see what is happening, others are suspicious and he is too</li>
                  <li>NAT 20 Cecil Investigation notices the silvery cord attached to Corpo...knows Astral projection</li>
                    <li>Knows that the Gith have super special swords that can sever the connection so nothing here probably would </li>
                </ul>
              <li>Decide to leave Corpo in the wagon, Fiddle says he will guard him with is dull dagger</li>
              <li>In the wolf den they see a chimney up the back, with skree and bones tossed down like it is a garbage dump</li>
                <ul>
                  <li>Decide not to go up, since too narrow they could be ambushed.</li>
                </ul>
              <li>Sparka uses Mage Hand to grab the Sabertooth necklace...rips head off skeleton</li>
                <ul>
                  <li>Thinks about keeping it...gives it to Lars though after he wanted it and decided there was little monetary value in it (since Goblins had not taken it)</li>
                </ul>
              <li>NAT 1 Cecil and Sass to determine what up with sabertooth</li>
              <li>They enter cavern...Sass lights a torch well Sparka uses Prestidigitation to light it</li>
                <ul>
                  <li>This alerts goblins sentries</li>
                </ul>
              <li>Goblins start releasing the dammed pools</li>
                <ul>
                  <li>Party tries DEX check to avoid it</li>
                </ul>
              <li>Sparka triggers the Skree on side tunnel to collapse and falls into the path of the water surge</li>
              <li>Sass fails dex check to get away but NAT 20 Sass saving throw strength to deal with water...wind of water lights torch even brighter</li>
              <li>Cecil hears Goblins say "Again" and the party scurries up the side tunnel to escape</li>
              <li>Cecil has Lars get infront as they pop into the hall</li>
              <li>Lars sees Yeemik with scimitar to Sildar's throat demanding a true and parley. </li>
              <li>Rest join out of the tunnel, Yeemik says that if they kill King Blargh he will let Sildar go...also if they leave half the treasure.</li>
                <ul>
                  <li>Party rigorously debates, Sildar says in Elven to not trust Goblins, but Cecil hears in Goblin how excited they are to have someone kill Blargh</li>
                </ul>
              <li>Lars NAT 20 Insight into Yeemik, identifies that he sees them as powerful so wants them to kill Blargh. BUT he plans to renegotiate if they are weakened...like he sees them as a tool to displace Blargh and take over.</li>
              <li>Sparka NAT 1 persuasion that Yeemik and crew should not leave the cave</li>
              <li>Go to Pools cave...Lars fails Stealth and gets shot at.</li>
              <li>INITIATIVE</li>
              <li>Goblins fire arrows at Lars..dude is hurt</li>
              <li>Sass runs forward and does FORCE BREATH on the 5 gobs...kills one of them wounds the othrer</li>
              <li>BUG BEAR BOSS attacks Sparka and Sass</li>
              <li>Sparka kills three Goblins with magic missile</li>
              <li>Cecil launches hideous laughter on Blargh and succeeds...Blargh falls down laughing about a mom joke...his mom is so ugly ____ god of Goblins doesn't even want her</li>
                <ul>
                  <li>Blargh is laugh-crying on the ground</li>
                </ul>
              <li>Wolf Ripper charges and attacks, Lars and Sass get on low health but keep fighting, with Hunter's Mark and Reckless Attack. One Goblin keeps shooting arrows in.</li>
              <li>Blargh recovers but screams about his mother being pretty constantly</li>
              <li>Sass does FORCE BREATH again on Blargh and wolf</li>
              <li>Sparka discovers firebolt</li>
              <li>Sass goes down</li>
              <li>Lars kills Blargh....cuts out his guts and says the bard was right before decapitating him.</li>
                <ul>
                  <li>Keeps head on a chain</li>
                </ul>
              <li>Lars kills wolf, castrates and decapitates him</li>
              <li>5th Goblins flees into Blargh's cave...goes to chimney. Sparka follow and takes a pot shot...fails</li>
              <li>Crew finds treasure....finds pipe of remembrance which goes to bard, dragon sensing dagger (Sass recognizes as the weapon of Dragon Slayers, saw Cloudchaser freak and destroy one...Party gives to him)</li>
                <ul>
                  <li>Also find ring of water walking...Sparka keeps...ring of jumping Lars keeps</li>
                </ul>
              <li>Sparka NAT 1 to ID the Lionshields logo on crates...others notice though and assume a delivery would be a thing that they reward for</li>
              <li>Sparka NAT 1 checking Blargh's Cave for more treasure or stuff</li>
              <li>Go to confront Yeemik....Cecil uses minor illusion to show King Blargh...passes Deception claiming Blargh said to flee he knows that they allied with the adventures but he killed them. Yeemik and crew flees...so scared that they run past Corpo and Fiddle-D</li>
              <li>Go out with Sildar, he says he will tell more when they close on town.</li>
                <ul>
                  <li>Gets noticeabley worried when they say that they discovered a Black Spider was involved...knows about the Black Spider work in the War like Lars.</li>
                </ul>
              <li>Says they took Gundren, the gear, and the map to Cragmaw Castle</li>
                <ul>
                  <li>NAT 20 Cecil to know that it is an ancient ruin but no one is quite sure where it is</li>
                </ul>
              <li>As they get close to town</li>
                <ul>
                  <li>Lars notices Anders Brathen in the trees watching..says he is keeping an eye out for the help that is supposed to be coming. Says town is dangerous with the Bandits. Sildar introduces self as "Sir Sildar"</li>
                  <li>Sparka checks out the Statue of the Angel Ragnarok who has the Axe that Lars has in his vision...</li>
                  <li>Sass tries checking flowers but doesn't notice much</li>
                  <li>Cecil talks to Sildar...gets lowdown on the history of Phandalin, Phandelver, Wave Echo Cave...including that Gundren and his brothers think they found it...that's why the Black Spider was after him.</li>
                </ul>
              <li>In town...main wagon goes to Lionshield Coster to drop off supplies, sleep deprived Lienne wakes up and points hand crossbow...she explains why she is exhausted and pours ink in her coffee...Sparka uses Prestidigitation and cleans the coffee of the ink and her teeth too. She asks him to clean the corners and he does. Sparka says if she'd married an elf he could watch baby...but she wonders if baby would sleep even less then?!?!?!?</li>
              <li>Gives Sparka 50gp to split as reward for delivering supplies. </li>
              <li>Sass is exhausted and goes towards Inn...but hears a haunting song..follows it to he orchard and finds Daran Edermath. </li>
                <ul>
                  <li>Sass notices the Order of the Gauntlet symbol nearby</li>
                  <li>They talk, Daran reveals that he is old and tells how he lost his fiancé years ago to a bandit lord who took a Doppelgänger disguised as his fiancé and demanded a black flaming magic swords that Daran had. Daran gave it...but Doppelgänger revealed self ands they laughed. His love was actually dead. He has been haunted ever since. </li>
                  <li>Hits a floor board, which opens revealing a collection of arrows. He gives Sass two silvered and two walloping...then gave him an arrow that he carved from his dead love's walking staff to kill the black-flame-sword bandit...if Sass promised to carry it and kill the bandit...he also gave him an arrow of minor dragon slaying</li>
                  <li>Sass agrees and they bond, notices his badass bow on the wall but doesn't ask</li>
                  <li>Sass goes to sleep in Daran's barn loft...when he hears a drake calling to him</li>
                </ul>
              <li>Cecil goes into the Inn, meet Toblen and then ends up in a battle of the bards with GOB Halen. With his wildly original song, Cecil crushes Halen, winning free rooms for self and friends. Keeps playing and is a big hit.</li>
              <li>Ol' Farmer Nath asks him for any sign on son, says his son went out to get help to fight the Redbrands.</li>
              <li>Cecil notices that the Ol' Farmer is wearing a Sabertooth tiger tooth necklace. So the heroes know that no one else is coming to help.</li>
              <li>Sparka goes and delivers the supplies to Elmar Brathen who is quite upset at Gundren for getting himself captured, has 0 clue who Sildar is.</li>
                <ul>
                  <li>Tells Sparka that the Redbrands killed a man for standing up to them, now his family is missing.</li>
                </ul>
              <li>His daughter Thistle asks to go help them fight, but Elmar orders her into the backroom, she groans that she wants adventure</li>
              <li>By this point multiple people have told the party that the bandits are trouble, showed up two tenday ago, but only started causing real trouble past 3-4 days</li>
              <li>Cecil is taking a break from his adoring fans and meets up with Sparka and Sildar heading towards the inn</li>
              <li>Sildar explains further that that he is a Member of the Lord's Alliance, he agreed to pose as Gundren's bodyguard to get to Phandalin.</li>
                <ul>
                  <li>Lord's Alliance is a loose federation of governing bodies focused on bringing stability to bring peace</li>
                  <li>They want the mine reopened as means of bringing income and thus investment and defenses to the area</li>
                  <li>Lord's Alliance Agent Iarno Albrek was sent to stabilize the town but lost contact. Sildar has been sent to investigate what happened and take over the mission</li>
                </ul>
              <li>Sparka walks into the Inn, Toblen talks while his wife is doing all the real work</li>
              <li>Sparka notices a half-elf watching him, gives an excuse to stop talking to Toblen, but Toblen knows what's up and says go on.</li>
              <li>Talks to the half-elf, she says name is Maisie Summerbluff and can help him identify his arcane focus but needs his help.</li>
              <li>She has heard Cecil's tales and assumes that these they are badasses</li>
              <li>Sparka says his name and notices her raise an eyebrow at Redlake.</li>
              <li>She says she will tell him about his last name if she helps get a necklace back from the Townmaster that isn't his. She was hired to get it, it happens to be a pearl to help do the Identify spell. </li>
              <li>He persuades her and she gives info on his last name. Redlake is a horror story among Chromatic Dragons</li>
                <ul>
                  <li>Story goes that an Ancient Red Dragon came back home to find REDLAKE all over the lair. spelled with dead wyrmling bodies, carved in the wall, spelled with guts of slain dragon cultists, and so soaked deep with blood it had to use fire breath to deal with it.</li>
                  <li>Maisie has not known anyone with the name, just the Chromatic Dragon legend. says if he gets the necklace back she will try to research more.</li>
                  <li>She says that she is from Candlekeep, the scholarly center, and was partially raised by wizards, bladesingers, and the rough and tumble guards.</li>
                  <li>One of her instructors is a Dragon Magic Expert and taught her all sorts of things. </li>
                </ul>
            </ul>
          </Collapsible>

          <Collapsible
            title="Astral Plane and Tavern Brawls (#4 - 7/30)"
            toggle={this.collapseToggle.bind(this)}
            kind="session4"
            show={this.state.show}
          >
            <ul>
              <li>Corpo is on the ship in the Astral Plane.</li>
                <ul>
                  <li>He refuses to talk when they start worrying about Dread Flame Pirates</li>
                  <li>The Pirates start boarding, and he does help them fight</li>
                  <li>Comment about they don't know who is father is...Water Genasi hears but he refuses to elaborate</li>
                  <li>They start losing when a black-armor knight riding a Nightmare floats overhead and burns down their sails</li>
                  <li>They lose and are all bound...Dread Flame demands that they tell him who they picked up</li>
                  <li>A Gem Dragonborn tells Corpo not to tell. Dread Flame uses a magic sword to sever the silvery cord and kills a Nomad. He comes to the Dragonborn...he says do not tell. </li>
                  <li>Corpo feels something building inside of him...tries to release it and fails.</li>
                  <li>Dragonborn coldly stares as Dread Flame kills him</li>
                  <li>Corpo is pissed and tries unleashing it as Dread Flame goes to the Water Genasi. Corpo sees static lightning forming as clouds drift around him.</li>
                  <li>Last chance before she dies....he connects to the power and a bolt of lightning hits him. A thunder burst explodes outward knocking everyone back. It ends Astral Projection for the Nomads, and killing the pirate crew, but Dread Flame braces and seems fine</li>
                </ul>
              <li>Corpo wakes up unable to move, he is under a blanket hearing Fiddle talk with Thugs</li>
                <ul>
                  <li>They are about to find him, when Fiddle uses Cecil's Bugbear insult, then spits on a Thug.</li>
                  <li>The Thug says send him to the slave pens, he can serve lunch to the slavers and if they want him they can take him too</li>
                  <li>Fiddle makes a comment about Sparka being alone in the Inn, so Thug sends Chad, Chet, Bret, and Tris to the Inn to capture Sparka.</li>
                </ul>
              <li>Sass bonds with his Drake</li>
                <ul>
                  <li>Uses telepathy, gets feelings back from the Drake</li>
                  <li>It is a small drake...but through a bond with a Drakewarden can go into beast mode.</li>
                  <li>It is blue, a thunder Drake</li>
                  <li>Drake has been looking for previous Drake Warden...can't find...so looking for a bond until it can find old one.</li>
                  <li>Daran comes over and says he heard trouble going towards the Inn where his friends are. Sass heads out.</li>
                </ul>
              <li>Lars throws axe in the door as Gold Elf leaves, rejecting his monetary offer to spy on Cecil.</li>
                <ul>
                  <li>Lars says he values belonging and will not betray his friends.</li>
                  <li>Gold Elf says he will change his mind...leaves bottle of Sagecourse.</li>
                </ul>
              <li>Lars meets up with Cecil and Sparka in the common room of the Inn,</li>
                <ul>
                  <li>Lars tells Cecil about the Gold Elf</li>
                  <li>Cecil tells Lars that the sabertooth is Old Man Narth's son's</li>
                  <li>Sparka is pissed about harm done to small businesses</li>
                  <li>Lars calls Gold Elf over...he refuses to stand near Cecil. Lars convinces him to take the Sabertooth (and apparently King Blargh's head since the DM wasn't clear on that) so Old Man Narth follows him out</li>
                </ul>
              <li>Corpo gains the magical ability to move his muscles, meets Sass outside the inn.</li>
                <ul>
                  <li>Corpo is still coming to and the conversation gets intense</li>
                  <li>Corpo wants to go save Fiddlestump, but Sass convinces him to help the others first</li>
                  <li>See Bandits go in while two others are walking out and about</li>
                </ul>
              <li>Cecil hears bandits coming into bar, warns Sparka</li>
              <li>As Thugs enter bar, Sparka goes and stands by Toblen, they start to threaten Toblen and Sparka is having none of it. </li>
                <ul>
                  <li>Casts Sleep on Chet and Tris</li>
                  <li>Mind Sliver on Chad</li>
                </ul>
              <li>INITATIVE!</li>
              <li>Sass fires an arrow with Hail of Thorns...kills Chaz and hurts Trey</li>
              <li>Cecil attacks Bret</li>
              <li>Lars decapitates Bret</li>
              <li>Sparka kills Chad by crushing him with an Earthen hand</li>
              <li>Cecil says don't kill em all...but Lars says keep two alive for interrogation, best practice to keep them seperate</li>
              <li>Sass kneecaps Trey...then hits femoral artery and interrogates him</li>
                <ul>
                  <li>Shit head calls him a Lizard Freak</li>
                  <li>"So, I am offended"</li>
                  <li>"You have no strength, no honor" then slits his throat</li>
                </ul>
              <li>Sparka, enraged at offenses done to small business owners, kills Chet with Earthen Grasp but not Tris</li>
                <ul>
                  <li>Lars says "You might make a barbarian"</li>
                </ul>
              <li>Toblen says whatever they need, says go interrogate Tris, get info, they support party if they rid town of these bandits.</li>
              <li>They interrogate Tris</li>
                <ul>
                  <li>See players for notes?</li>
                  <li>The bandits hideout is in the old Manor, they hangout in catacombs, not up top where it is ruined.</li>
                  <li>She says that they typically raid around town, they killed the one guy and took family to slave pens</li>
                  <li>Mentions a one-eyed monster</li>
                  <li>Boss is Glasstaff...a wizard </li>
                  <li>Is flippant about helping kill people</li>
                  <li>Sparka tries to kill her with ___(wind?) ends up killing her</li>
                </ul>
              <li>Corpo reveals that he is Way of Ascendant Dragon now, that Dragonborn who died to save him granted him powers while appearing to have 5 hands</li>
              <li>Lars looks out window...sees Ol' Man Narth killed with a fancy dagger, they scavenge the dagger. Tells Cecil</li>
              <li>In the morning request for breakfast "All the Bacon. and Eggs"</li>
              <li>Learn that Bandits took over the sleeping giant tap house</li>
                <ul>
                  <li>Also that they like parties</li>
                  <li>Confirm that Redbrands killed Thel Dendar after he told the bandits off for making passes at his wife</li>
                  <li>Then his family, wife Mirna, 18 year old Nilsa, and 13 year old Nars all disappeared.</li>
                </ul>
              <li>Decide they need to free Fiddle and end these bandits</li>
              <li>Sparka tries to sell things</li>
                <ul>
                  <li>gets some basic money from Lienne for goblin weapons</li>
                  <li>Goes to Halia, notices the place is nice and undisturbed by the Redbrands</li>
                    <ul>
                      <li>Figures out she deals with land claims and is quite powerful</li>
                      <li>Notices that Elmar Brathen came to town with his two children about 16 years ago</li>
                      <li>Says the mayor is ineffective </li>
                      <li>Says to not kill all the bandits...just the leader Glasstaff...she knows the rank and file just need chance at redemption without a bad influence.</li>
                    </ul>
                </ul>
              <li>They hide Sass and Corpo in the wagon, borrow kegs from Toblen and head out</li>
              <li>Townsfolk give them nods, middle-aged halfling woman gives them a rock on sign</li>
              <li>On way past Sleeping Giant Inn, Sparka checks if anyone inside...notices two passed out bandits</li>
                <ul>
                  <li>Fire a firebolt to light the place on fire (he confirmed that no one owned it but the bandits)</li>
                  <li>rolled a NAT 1 to make it burn fast...good though so town doesn't notice</li>
                </ul>
              <li>Cecil persuades the guard that they are here for the party and guard Tanner lets em pass</li>
              <li>Sparka tells Lars to get the guard...Lars tries to be sneaky but is noticed</li>
                <ul>
                  <li>Corpo distracts guard to give Lars advantage...he uses jumping ring but misses</li>
                  <li>Cecil gets Tanner with Rapier...then interrogates learning not much then kills him</li>
                </ul>
              <li>END SESSION</li>
            </ul>
          </Collapsible>

          <Collapsible
            title="The Nothic (#5 - 9/11)"
            toggle={this.collapseToggle.bind(this)}
            kind="session5"
            show={this.state.show}
          >
            <ul>
              <li>
                Retcon-ed Daran tossing a potions of healing to Sass before he got in the keg
              </li>
              <li>
                Sparka note from halia with job offer
              </li>
              <li>
                Party enters the door, Lars first
              </li>
              <li>
                Find cellar seemingly pretty official, barrels with food stores
              </li>
              <li>
                Sparka walks across the water (ring of water walking) to be absurd
              </li>
                <ul>
                  <li>
                    Sees the "Go Bag" picks it up, gets scrolls, gold, and nondescript clothes.
                  </li>
                </ul>
              <li>
                Moving the barrels around, salted pork, flour, etc.
              </li>
                <ul>
                  <li>
                    Alerts the Redbrands
                  </li>
                  <li>
                    Burst open door and miss free attack on Sparka
                  </li>
                </ul>
              <li>
                Get into fight at door, breath attack by Sass, ki breath attack by Corpo, fighting the Lined Up Redbrands
              </li>
              <li>
                Vicious Mockery by Cecil vs. Brad and Bryce
              </li>
              <li>
                Sass short swords slice and dice kills Hunter, injuries Brad
              </li>
              <li>
                Lars throws javelin through Brad and into Bryce, killing both
              </li>
              <li>
                Becca surrenders
              </li>
              <li>
                Sparka NAT 20 Intimidates Becca, learns direction of the prison, talks about Nothic, where Glassstaff works, names of the jailers.
              </li>
              <li>
                Take four Redbrand cloaks and money pouches
              </li>
              <li>
                NAT 1 Cecil perception in the small barracks to find anything, thinks cool things but just flour
              </li>
              <li>
                Go into the corridor, Lars leading way, falls into the pit trap, but acrobatically avoids things.
              </li>
                <ul>
                  <li>
                    Triggers shrieking mushrooms, ax kills it but alarm is raised
                  </li>
                </ul>
              <li>
                Rest climb over, leave tied up Becca with a W, the D is silent back behind the pit
              </li>
              <li>
                NAT 20 Corpo backflips over the pit, got 1HP back for style
              </li>
              <li>
                Sparka Religion check to see that angels on the doors are of an old variety, popular with nobles of the area.
              </li>
                <ul>
                  <li>
                    Also would be near a crypt
                  </li>
                </ul>
              <li>
                Lars opens doors to see Skeletons standing by sarcophagi, they observe him but don't seem to care
              </li>
                <ul>
                  <li>
                    He goes down the hallway to find the armor, takes light crossbow and some bolts
                  </li>
                  <li>
                    Cecil follows, finds trap door
                  </li>
                  <li>
                    Figure out Skeletons do not attack those in Redbrand cloaks
                  </li>
                </ul>
              <li>
                Notice that skeletons have odd metal on their necks
              </li>
                <ul>
                  <li>
                    NAT 1 Arcana check by Sparka to get Illefarn symbol
                  </li>
                  <li>
                    Cecil notices Illefarn symbol (ancient Elven Kingdom), says the name, skeletons go inert
                  </li>
                </ul>
              <li>
                Sparka wants to raid the tombs
              </li>
                <ul>
                  <li>
                    NAT 1 Lars to move a coffin
                  </li>
                  <li>
                    Find ancient platinum ring of the Family
                  </li>
                </ul>
              <li>
                Listen at jail door
              </li>
                <ul>
                  <li>
                    NAT1 Sparka listening at the door, but others hear whimpering
                  </li>
                </ul>
              <li>
                Kick open door to see Justin and Tristan in the jail cells holding prisoners hostage
              </li>
                <ul>
                  <li>
                    Lars lies saying he's with glassstaff, rest come in and they con the guards that they are new
                  </li>
                  <li>
                    Guards open the door and walk out
                  </li>
                </ul>
              <li>
                NAT 20 Sass assassinated Justin
              </li>
              <li>
                Tristan attacked, start interrogating him
              </li>
                <ul>
                  <li>
                    Corpo starts torturing him to talk with a dart
                  </li>
                  <li>
                    Corpo murders Tristan by taking a dart from his balls to his lungs
                  </li>
                </ul>
              <li>
                Sparka feeds salted pork to the prisoners
              </li>
              <li>
                Lars "tends" to the 18 years old
              </li>
              <li>
                Mirna thanks them, says her family had an alchemy shop in Thundertree...says they can have her family heirloom...the ring
              </li>
              <li>
                NAT 20 Cecil realizes that "Furry Ears" something Bugbears call each other
              </li>
                <ul>
                  <li>
                    Redbrands said "Furry Ears are here"
                  </li>
                </ul>
              <li>
                Cecil notices "KHAD'S GUIDE TO BEING AN ADVENTURER" on top of Wildon's clothes
              </li>
              <li>
                The boy asks them to "save his friend...save Wildon" the Furry Ears took him
              </li>
              <li>
                Give the family swords
              </li>
                <ul>
                  <li>
                    Sparka prestidigitation cleans up the cell including them, makes pork taste grreeeeaaat
                  </li>
                  <li>
                    Tell them to stay there, they will be back
                  </li>
                </ul>
              <li>
                Go into the secret door, see crates of stolen wares from Redbrand's exploits
              </li>
              <li>
                Sass and Lars step forward, Nothic starts the telepathy
              </li>
                <ul>
                  <li>
                    Tells them secret things about themselves that few know
                  </li>
                  <li>
                    Asks for payment to not attack them, magical items or sweet flesh
                  </li>
                  <li>
                    Dumps the bodies in the Crevasse
                  </li>
                </ul>
              <li>
                The others walk forward, get thoughts in their heads of secrets
              </li>
                <ul>
                  <li>
                    Sparka is not a fan of the reveals on his mother and Semla
                  </li>
                  <li>
                    Keep giving hints as he tries to get them to agree to his terms.
                  </li>
                </ul>
              <li>
                Spara figures out it is a Nothic with "Weird Insight"
              </li>
                <ul>
                  <li>
                    Tells party to ignore the attempts to divide them
                  </li>
                </ul>
              <li>
                Nothic tells the other info to divide them, like Lars being told that Sparka has visions
              </li>
              <li>
                NAT 1 Lars to resist the Nothic's probe
              </li>
              <li>
                NAT 20 Sass resists
              </li>
              <li>
                Sparka find the Nothic's chest, starts taking things out, calling it a coward and that it won't fight
              </li>
              <ul>
                <li>
                  Finds Talon +1 Longsword (Lars Takes It)
                </li>
                <li>
                  Find Wand of Pyrotechnics
                </li>
              </ul>
              <li>
                Perception checks that they hear it behind the pilar, Sparka blindfires sleep but it is too strong
              </li>
                <ul>
                  <li>
                    The party engages the Nothic, it says it will cry out
                  </li>
                </ul>
              <li>
                Sparka uses Scroll of Augury, gets weal vision meaning that the Nothic can call for help
              </li>
              <li>
                Savage fight, hitting it, damaging it.
              </li>
              <li>
                NAT 1 Lars Crossbow shot
              </li>
              <li>
                They keep fighting it while the Nothic tempts them with more fables
              </li>
              <li>
                Corpo follows it under bridge, into the corner
              </li>
              <li>
                NAT 20 Corpo hits the Nothic, with unarmed strike knocks him out
              </li>
              <li>
                Sparka kills the Nothic, despite Corpo wanting to talk to it
              </li>
                <ul>
                  <li>
                    Nothic telepathically tells Corpo that Sparka is killing him because he is afraid of them knowing
                   the truth</li>
                </ul>
              <li>
                They hear a door open.........
              </li>
              <li>
                END SESSION 6
              </li>
            </ul>
          </Collapsible>
          <Collapsible
            title="What About the Small Business Owners (#6 - 10/02)"
            toggle={this.collapseToggle.bind(this)}
            kind="session6"
            show={this.state.show}
          >
            <ul>
              <li>
                Initiative, free round to prep
              </li>
              <li>Cecil finds Mac-Fuirmidh Cittern</li>
              <li>Lars notices the crevasse can grow crazy mushrooms with the magic energy</li>
              <li>Sass smells a Dire Wolf</li>
              <li>Sparka journey into Shadowfell he picks learning about the focus</li>
              <ul>
                <li>Reveals it is a thing but it is imbued with the powers of a Shadowfell Shard</li>
                <li>Raven Queen implies that she granted the power of Shadow fell to the focus</li>
              </ul>
              <li>Mosk burst out and Lars chucks a Javelin at him, misses</li>
              <ul>
                <li>They notice the Fancy Eye Patch</li>
                <li>Lars hits with improper use of Sentinel</li>
              </ul>
              <li>Cecil heals Lars, starting the improper use of the new Instrument of the Bards</li>
              <li>Sass sharpshoot kill Mosk right under the eye patch.</li>
              <li>Lars decapitates Kuggek saying..."They drew first blood..."</li>
              <li>Cecil Vicious Mockery on Gogt "Out the door last cause you worthless"</li>
              <li>NAT 20 Corpo hit Gogt</li>
              <li>Gogt calls for the Thugs</li>
              <li>NAT 20 Corpo hit Dire Wolf</li>
              <li>Lars Castrate-Kill Direwolf</li>
              <li>Drunk thugs burst out, but are drunk</li>
              <li>Sass KILL Gogt with shot square in the pelvis, cuts belt which lowers Wildon's dagger to the ground</li>
              <li>Cecil Vicious Mockery on Qevin, is the name tag for you to remember?</li>
              <li>Sass kills Qevin with an arrow through the throat that burst hail of thorns behind him</li>
              <li>NAT 1 Lars trying to hit the Thugs</li>
              <li>Devin sends tells girls to "Get the Hostage"</li>
              <li>Cecil Vicious Mockery on Devin "Was your name reused??"</li>
              <li>Lars cleaves Devin in half and says "You only get one shot, one oppurtunity..."</li>
              <li>Corpo goes mobile to get back to save Wildon...but NAT 1 on Skyler, turning her around and revealing name tag</li>
              <li>Skylar Holds Wildon hostage...screaming match with Corpo</li>
              <li>Cecil casting Hideous Laughter on Skylar, she collapses to the ground, dropping the knife and releasing Wildon from danger</li>
              <li>Glasstaff ambushes from behind with a FIREBALL scroll! Low damage</li>
              <li>Corpo kills Skylar with a whirlwind Curb stomp</li>
              <li>NAT 20 Lars clears head off Summmmer, cradle the kill down</li>
              <li>Sass and Sparka chase Glasstaff into his workshop</li>
              <li>Lars Axe Slaps Skymmer unconscious</li>
              <li>Corpo performs mouth to mouth on Wildon...rolls for tongue...success...?</li>
              <li>Can't open the door into Glasstaff room, Sparka refuses to Parlay, hears packing</li>
              <li>NAT 1 Cecil lockpicking</li>
              <li>Sass busts down the door</li>
              <li>Glassstaff is escaping, they chase</li>
              <li>Sparka puts him to sleep, loots his body, and takes Staff of Defense</li>
              <li>They put him in a jail cell, interrogate him, get deets on Black Spider, generally hate this guy</li>
              <li>Reveals he works for Black Spider, he was supposed to look out for the Dragon Masks, and that the slavers are coming at noon</li>
              <li>Lars kills him once they decide that they are done</li>
              <li>Sparka impersonates Glassstaff when Drow slavers arrive, they take their gold then lock them in the cells to die</li>
              <li>Lars and Corpo head out while others scour the dungeon.</li>
              <ul>
                <li>Townsfolk have put out the Sleeping Giant Inn fire..."Those bastards started burning the town"</li>
                <li>Rich man looking for child who joined the Redbrands, (roll) one they killed</li>
              </ul>
              <li>Lars gets to the Stonehill Inn, tells Sildar that Iarno was Glassstaff.</li>
              <li>Corpo goes to Sister's house, she is gambling and winning but stopes when she sees injuried Wildon</li>
              <ul>
                <li>As she is healing Wildon, Corpo lies that he beat the Redbrands singlehandedly</li>
                <li>Sister says she is part of the Harpers and asks his help in finding ancient forgotten knowledge...had hoped the Nothic could be used. Gives Corpo the mother-pearl inlayed brush to take to Auglatha to answer a question</li>
                <li>Corpo roll for kiss-nitative and gets a kiss on her cheek before heading out.</li>
              </ul>
              <li>Sparka and Cecil come out of the Hideout, and Harbin Wester comes up to them</li>
              <ul>
                <li>Angry, demanding what they did</li>
                <li>Sparka says he is an ineffective townmaster, let the town get overrun with Redbrands</li>
                <li>YOU DID NOTHING TO PROTECT THE SMALL BUSINESS OWNERS!</li>
                <li>Sparks fly from his Focus</li>
                <li>Harbin leans forward and says election is in a week and a half...bring it on bitch</li>
              </ul>
              <li>Whole group goes to talk to Sildar</li>
              <ul>
                <li>Talk about how Glasstaff had taken over</li>
                <li>Cecil questions if he is with the Black Spider</li>
                <li>Says he must secure the town, says that they should deal with finding Gundren</li>
              </ul>
            </ul>
          </Collapsible>

          <Collapsible
            title="Honor of Dragonborns (#7 - 11/5)"
            toggle={this.collapseToggle.bind(this)}
            kind="session7"
            show={this.state.show}
          >
            <ul>
              <li>
                Sparka talks with Halia Thornton, visits Wildon Fiddlestump in the care of Sister Gaerele.
              </li>
              <li>
                Party sits down in Stonehill Inn, maybe they should talk?
              </li>
              <li>
                Party orders drinks
              </li>
                <ul>
                  <li>
                    Shimmering White, Corpo and Sparka
                  </li>
                  <li>
                    Ale Cecil
                  </li>
                  <li>
                    Two Well Whiskeys Lars
                  </li>
                  <li>
                    One Whiskey Sass
                  </li>
                </ul>
              <li>
                Party sits down and has talks....
              </li>
                <ul>
                  <li>
                    Corpo slaps Elsa's rear, she is not amused
                  </li>
                  <li>
                    Sparka tells Lars they will talk outside about the visions
                  </li>
                  <li>
                    Corpo, Sass, and Cecil are like....WTF why not here?
                  </li>
                  <li>
                    They talk about what the Nothic revealed, mostly the group dismissed them as lies
                  </li>
                  <li>
                    Lars says that the Nothic said *Corpo's Mother so fat joke*
                  </li>
                  <li>
                    Agree to stay together and go find Gundren
                  </li>
                  <li>
                    Corpo NAT 20 gust to blow up Elsa's dress
                  </li>
                </ul>
              <li>
                Sildar walks over, talks about what he's doing, but that they should go get Gundren
              </li>
              <li>
                Daran tells them about the Order of the Gauntlet, and that there is a Red Wizard sighted to the North and asks if they could check in on it. Let him or the Order know in Neverwinter and they will takecare of it.
              </li>
                <ul>
                  <li>
                    Comment about he just fights evil, no other policy
                  </li>

                  <li>
                    Mentions a safe area called Angel Acre on the Way north toward Thundertree and super close to the Red Wizard sighting
                  </li>
                </ul>
              <li>
                Qeline Alderleaf comes to tell them about Reidoth, then tells them like fifty other things
              </li>
                <ul>
                  <li>
                    Brathens came without the wife
                  </li>
                  <li>
                    The Townmaster had hemorrhoids
                  </li>
                  <li>
                    Wildon is trying to woo her niece but her brother thinks he's weak (gave him Khad's adventuring guide)
                  </li>
                  <li>
                    They convince her to run for Townmaster
                  </li>
                </ul>
              <li>
                Elsa says she will give them a round of drinks free of they tell her stories next time they come back
              </li>
              <li>
                Party starts, Townmaster Harbin walks over and is super aggressive
              </li>
              <li>
                Thistle comes over and has a forged letter to frame Harbin
              </li>
              <li>
                NAT 1 Corpo convincing the tavern something bad about Harbin
              </li>
              <li>
                NAT 1 Corpo tried slight of hand to....crush Harbin's balls?  ...what?
              </li>
              <li>
                Corpo hears something in his head....walks outside and meets someone
              </li>
              <li>
                Harbin takes credit for the 50 gold donation to the orphans
              </li>
                <ul>
                  <li>
                    Tells everyone HOW CAN WE tRuST tHEsE pEOplE?
                  </li>
                  <li>
                    Shows the "No Bars Lars" poster
                  </li>
                  <li>
                    Says Corpo will seduce your children
                  </li>
                  <li>
                    Cecil seduces people (crowd is hesitant to believe him)
                  </li>
                  <li>
                    Lars notices the money bag from the Gold Elf on Harbin's waist
                  </li>
                  <li>
                    Says that Sass killed his people Elders! Says is three times
                  </li>
                </ul>
              <li>
                Los Tres Dracones enter, blood on faces, challenge Sass to Duel to the Death
              </li>
                <ul>
                  <li>
                    Say they have strength and Honor...he has cowardice and weakness
                  </li>
                  <li>
                    Sass accepts
                  </li>
                  <li>
                    Sparka sends Thistle with the Forged Letter to plant it while Duel Happens
                  </li>
                  <li>
                    3 v 3 but his people don't really accept Bardic magic, so Cecil can participate
                  </li>
                </ul>
              <li>
                THE DUEL in the blood circle
              </li>
                <ul>
                  <li>
                    Earthen grasp two of Surina and Norixius
                  </li>
                  <li>
                    Heat Metal on Tarhun (Should have taken half damage as Red Dragonborn)
                  </li>
                  <li>
                    Norixius charges Sass, Sass dual swords NAT 20 on off hand
                  </li>
                  <li>
                    Sparka puts Surina and Norixius to sleep
                  </li>
                  <li>
                    Lars CASTRATES Tarhun
                  </li>
                  <li>
                    Sass Heals Tarhun's grievous wounds and puts him back together.
                  </li>
                  <li>
                    Sass NAT 20 Persuasion to convince Tarhun of the truth of what happened
                  </li>
                  <li>
                    Tarhun accepts and says get out of here since Strength and Honor oath is hard to dispute
                  </li>
                </ul>
              <li>
                Lars convinces half the crowd to go drink
              </li>
              <li>
                Sparka conns Townmaster into taking crowd to Townhall
              </li>
              <li>
                NAT 20 Sparka notices Thistle in the trees gesturing that she did it
              </li>
              <li>
                Inside the townhall they find the letter, he denies everything
              </li>
              <li>
                Lars tries to rile crowd for an execution, not quite that much
              </li>
              <li>
                Corpo drops the mayor's pants
              </li>
              <li>
                Sparka convinces crowd to throw him in jail, takes the necklace from him
              </li>
              <li>
                Lars seduces Qeline, she tosses her key, he rolls a 12
              </li>
              <li>
                Party agrees to head out in the morning
              </li>
              <li>
                Maise helps with the Focus, says it has a powerful Aura that blocks identify
              </li>
                <ul>
                  <li>
                    Shows the 7 little bloods swimming inside
                  </li>
                  <li>
                    Says it acts as a Bloodwell Vial, but like it being a Shadowfell Shard IT ISN'T ACUTALY ONe
                  </li>
                  <li>
                    This is an ancient ancient powerful item, a legacy item that elder houses would fight over
                  </li>
                  <li>
                    Says Phineas Stoneblood is only one that might know (and the odd dish in his dream) she is going to study with him in Neverwinter...Sparka can come find her at the Tavern of a Thousand Faces
                  </li>
                </ul>
            </ul>
          </Collapsible>

          <Collapsible
            title="Necromancers, Banshees, and a Dragon Slayer (#8 - LAKE GENEVA 1/14)"
            toggle={this.collapseToggle.bind(this)}
            kind="session8"
            show={this.state.show}
          >
            <ul>
              <li>
                Sparka has dream of being in a marble hall and people pointing at his focus and wondering where he got it from
              </li>
              <li>
                Lars morning with Qeline
              </li>
              <ul>
                <li>
                  Lars is still drunk in the morning
                </li>
                <li>
                  Asks for round 2, he is skilled at acceptable
                </li>
                <li>
                  She gives him a goody bag and he notices plenty are missing
                </li>
                <li>
                  Her husband comes home and Lars is like straight up says that he likes roll in the hall and that dude should check his wife, she gives out goody bags
                </li>
                <li>
                  Goody bag has vitamin water and a broach
                </li>
              </ul>
              <li>
                Lars notices that there is a dot in his vision...can't figure it out
              </li>
              <ul>
                <li>
                  Orders a drink...notices it is North/North-West but that is all
                </li>
              </ul>
              <li>
                Group meets up at breakfast at the Stonehill Inn
              </li>
              <ul>
                <li>
                  Drake drinks water in the corner
                </li>
                <li>
                  Sass is yoked
                </li>
                <li>
                  Elsa carefully walks over....is not wearing pants under her dress
                </li>
                <li>
                  Comes up with name "Phandalin 5"
                </li>
                <li>
                  Elsa asks where they are going...they say "north"
                </li>
                <li>
                  NAT 1 Lars fails to Seduce Elsa
                </li>
                <li>
                  Daran talks to Sass about the Dragonborn who need to kill a dragon for their honor, he needs to help them slay the Green Dragon which Sass knows things about...THEIR HONOR IS AT STAKE
                </li>
                <li>
                  Daran also points out Angel Acres
                </li>
                <li>
                  Sass ends up having a drink as a recovering Alcoholic who had a Whiskey Check last night
                </li>
                <li>
                  Lars says lets go clear my bar tab if I can kill a Red Wizard
                </li>
                <li>
                  Cecil makes sure that they will be back for the election
                </li>
              </ul>
              <li>
                Hit the road going to Angel Acres
              </li>
              <ul>
                <li>
                  1.5 days through generally safe area
                </li>
                <li>
                  Sass collects firewood
                </li>
                <li>
                  Sass tries to hunt...gets a crappy old deer
                </li>
                <li>
                  Lars still can't figure out which was the dot is
                </li>
              </ul>
              <li>
                Make camp site as it is getting too dark
              </li>
              <ul>
                <li>
                  NAT 1 Sass Setup campsite, gully,  rains and is miserable for a bit
                </li>
                <li>
                  Sass asks for a midnight lullaby
                </li>
                <li>
                  Sparka uses create bonfire
                </li>
                <li>
                  Cecil activates Identify for the first time, sees mark he identifies as Ioun on his hand
                </li>
                <li>
                  Corpo gives himself a tattoo....A dolphin jumping over a rainbow tramp stamp
                </li>
                <li>
                  Corpo says that the tattoo will make noises as it activates
                </li>
                <li>
                  Lars is drinking he always has tea, Sass is drinking tea
                </li>
              </ul>
              <li>
                Corpo takes first shift
              </li>
              <ul>
                <li>
                  Hears rustling in the darkness
                </li>
                <li>
                  Knows that Lars has nightvision but does not wake him up
                </li>
                <li>
                  Rushes out with step of the wind
                </li>
                <li>
                  NAT 1 Corpo investigate Goblin to find Goblin, can't find him
                </li>
                <li>
                  Goblin is dashing away from him
                </li>
                <li>
                  Corpo YELLS GOBLIN
                </li>
                <li>
                  With leaping via Step of the WInd, Tackles the Goblin
                </li>
                <li>
                  Cecil casts Fairie Fire to light it up...Corpo sees another light up 7 feet away
                </li>
                <li>
                  Corpo slams the Goblin into the ground, knocks him out
                </li>
                <li>
                  Cecil rouses everyone ("I am aroused" "You were dreaming of Qeline")
                </li>
                  - "Corpo tackled a Goblin he's outlined in blue, there is another one 64 feet away also outlined in blue" 51:30
                <li>
                  Sass shoots at the Goblin to maim him, Sparka firebolt kills him
                </li>
                  - "You shoot him in the shoulder and he goes down maimed and then bursts into fire and die"
                <li>
                  Corpo sets him uncomfortably close to the fire by gruff of his neck
                </li>
                  - Mike "So uncomfortable"
              </ul>
              <li>
                Interrogate first Goblin
              </li>
              <ul>
                <li>
                  The Goblin mutters in Goblin "Shit Shit Shit Shit Shit" translation? "Shit shit shit shit shit shit"
                </li>
                <li>
                  Lars kicks him for no reason
                </li>
                <li>
                  Corpo threatens him that Lars will fuck him and castrate him....Corpo squeezes his testicles
                </li>
                <li>
                  Corpo intimidates him by the balls
                </li>
                <li>
                  Asks why he is following us? He says that they are in his territory, says he is part of the Cragmaw Tribe. Yeemik sent him
                </li>
                <li>
                  Corpo fondles the Goblin's balls
                </li>
                <li>
                  Sparka asks how many they are and where most or them are....DETECT THOUGHTS "Shit this guy has my balls" Corpo loosens his grip
                </li>
                <li>
                  Detect thoughts hears the Goblin thinks that they are not united and about Avlod
                </li>
                <li>
                  Question him about Avlod...where is he located...gets that he is Hobgoblin
                </li>
                <li>
                  "Eliminate people who are creeping up on us in the woods"
                </li>
                <li>
                  Cecil knows that Hobgoblins are Lawful Evil  and rule the Goblins
                </li>
                <li>
                  Figure out that Avlod is trying to remove King Grol and install Hobgoblins
                </li>
                <li>
                  Ask about the Red Wizard, he does not know, thinks that Avlod wouldn't know he isn't from this planet
                </li>
                <li>
                  Sparka and Lars knows that there are multiple worlds of the Material Plane...including different worlds
                </li>
                <li>
                  Sparka tries to interrogate after detect thoughts are gone, Goblin tells him that Avlod is interested in who is ruling this area and this area is important...he doesn't like King Grol
                </li>
                <li>
                  Cecil uses Charm Person
                </li>
                <li>
                  Dude loves him and starts telling him everything, asks him to give him the poem he wrote about scaring him and Yeemik away
                </li>
                <li>
                  Tells him that Avlod is here because his world is connected to this world
                </li>
                <li>
                  Punched a cow in the face and the cow said thank you and died
                </li>
                <li>
                  Says his name is Mehnaa and is going to tell Avlod about it. His cousin is Mehnaa Mehnaa.
                </li>
                <li>
                  Mehnaa does not like his friend so fine with dying
                </li>
                <li>
                  Mehnaa says he is supposed to go sit at a place and Avlod would find him
                </li>
                <li>
                  Lars finds a key on the murdered burnt Goblin
                </li>
                <li>
                  Confirms Gundren is with Grol
                </li>
                <li>
                  Says Avlod would probably work with them to get rid of Grol
                </li>
                <li>
                  Tells the party that Grol has Gundren
                </li>
                <li>
                  Avlod is awesome and been here about 7 days, Corpo is at first surprised but then realizes that Hobgoblins
                </li>
                <li>
                  Explains Yeemik and he found Avlod
                </li>
                <li>
                  Cecil casts message and tells Sparka that they are done, Sparka tells him to lay down, LARS DECAPITATES HIM and adds the head to the necklace
                </li>
                <li>
                  Robert singing Sweet Dreams needs an audio clip
                </li>
                <li>
                  Sparka dreams, notices that Cecil is like level or two higher
                </li>
              </ul>
              <li>
                Sass takes second watch, "It's just me and my thoughts"
              </li>
              <ul>
                <li>
                  Sass (not Mike) is getting sleepy
                </li>
              </ul>
              <li>
                Cecil and Lars notice nothing
              </li>
              <li>
                Sparka notices a day bird in a tree...it has the same mark as the weasel
              </li>
              <ul>
                <li>
                  Catches the bird in mage hand...it does not do anything
                </li>
                <li>
                  "Sparka puts the bird in the fire"
                </li>
                <li>
                  Transforms into a female Deep Gnome
                </li>
                <li>
                  Confirms that she is working for Halia
                </li>
                <li>
                  Sparka tries to Mind Sliver her but she is fine
                </li>
                <li>
                  She does not care about Sparka, but is a paid assassin to kill anyone who bothers them,
                </li>
                <li>
                  Turns into a bird and flies away
                </li>
              </ul>
              <li>
                Wake up, leave the goblin corpses
              </li>
              <li>
                Corpos has morning wood and does not hide it
              </li>
              <li>
                Sass pisses so hard he breaks a twig
              </li>
              <li> Enter Angel Acres</li>
              <ul>
                <li> the little provincial town of Angel Acre</li>
                <li> Enter at 11:30AM</li>
                <li> Yes the people are out singing obnoxiously</li>
                <li> Lars says Bonjour (apparently an odd dialect of Dwarven) which local say...cool </li>
                <li> yes Corpo asked if they had kids...</li>
                <li> "SEND ME YOUR TEENS!" </li>
              </ul>
              <li> See the GCP tavern</li>
              <ul>
                <li> Corpo does a saunter with mental music</li>
                <li> Sparka uses detect thoughts to get on same beat</li>
                <li> Corpo butt bumps the doors to the inn adequately.</li>
                <li> See Half -Orc bartender</li>
                <li> NAT 20 Sparka about Half Orcs...knows that they are from varied backgrounds</li>
                <li> See Giant Slayer Ale and order 5 using Mehnaa's money</li>
                <li> NAT 20 Sass to resist the Giant Slayer </li>
                <li> The Half-Orc's accent keeps changing for totally in universe reasons...not because the DM was Drunk</li>
                <li> Bartender says that the Cult of the Dragon worships Tiamat or something</li>
                <li> Corpo is sassy with the barkeep, Barkeep is not into the Necromancer as he is a NECROMANCER, they aren't welcome</li>
                <li> He points them towards Zilvestor, who he will help them</li>
                <li> Lars asks where the willing ladies are because of corse he does</li>
              </ul>
              <li> Cecil notices Dwarves in the corner</li>
              <ul>
                <li> Goes and approaches them</li>
                <li> Notices they are Citadel Felbarr Dwarves, recognizes many of them from Dwarf High School (Rockeater high)</li>
                <li> He tells them that he is Lars...the guy who accused them of killing their king</li>
                <li> Ole intervenes, then catches up with Lars</li>
                <li> Lars tells Ole that he bedded a halfling woman</li>
                <li> Ole tells Lars that Bamur is preaching out there that Lars is the messiah of the Dark Demon Lady of the mushrooms</li>
                <li> Ole gives Lars a vial of blessed oil that will give them 3 uses of Radiant 1 points of damage</li>
                <li> Ole asks Lars to kill Bamur, Lars says he will, Corpo has a chub, "Kill Evil",  "That is slightly erotic"</li>
              </ul>
              <li> They head to Zilestor</li>
              <li> Carrie Underwood appears and plays Smoke Break</li>
              <li>Approach Zilestor's Farm</li>
              <ul>
                <li>See him 40's working in the field</li>
                <li>Damn he's a silver fox</li>
                <li>Tells them he smells death near the Red Wizard, but he has not been in the Ruins</li>
                <li>His daughters saw the Necromancer, 13 year old twins Amali and Alais while picking mushrooms</li>
                <li>Tries Daran's Cider, spits it out but tells Sass to tell Daran that he loves it</li>
                <li>Invites them for lunch which they accept due to having giant slayers</li>
              </ul>
              <li>Enter Zilvestor's House</li>
              <ul>
                <li>NAT 1 Cecil so delayed notice the rotting bag</li>
                <li>6 Minute Mark</li>
                <li>Enter his home and see his six daughters and wife</li>
                <li>Offers food, but Cecil notices they have little food and holds off them eating too much</li>
                <li>Corpo asks about that Banshee and says that it lives...it likes vain gifts</li>
                <li>Lars says no thanks to food...Zilvestor's daughters are not plump enough...does not offend him</li>
                <li>His daughter Kuvra apparently means something in Polish</li>
                <li>The Necromancer likes necks? The locals are pretty clear he is evil and the party is still like...but....</li>
                <li>Cecil messages Sparka that the bags missing are sketchy....</li>
                <li>Sparka "Sketchy or Poor?" </li>
                <li>Sparka notices that Charmayne is nervous that she might not have enough food...</li>
                <li>Sparka Mage Hands one gold per each of them into the Chimney...little Hilda and Jacinthe notice</li>
                <li>Sparka Tells rest of Team to eat knowing that he has given them gold</li>
                <li>Corpo asks about the Banshee</li>
                <li>Lars tries to get Zilvestor to send his daughters with them...failed</li>
                <li>Twins saw the necromancer a week ago, say bodies walking and smelled like death (and grandma)</li>
                <li>8 days ago (not same day as Avlod shows up)</li>
              </ul>
              <li>Head out towards Red Wizards and the Ruins</li>
              <li>Corpo and Cecil talk about being paranoid about the family feeding them.</li>
              <li>22 MINUTE MARK</li>
              <li>Arrive to the red wizard </li>
              <ul>
                <li>Tell Lars about his radiant potion and how it helps zombies</li>
                <li>Smell of death which reminds Lars of qeline</li>
                <li>Corpo is aroused and makes it look bigger </li>
                <li>Sass crests the hill NAT 20 to see the zombies notices 8 zombies and smells more </li>
                <li>He freak out yells in telepathy please knows zombies are not viral zombies due to earlier check</li>
                <li>Lars says they should pretend to shuffle like zombies and Cecil yells his in affirmation</li>
                <li>Lars actually tries to shuffle up.</li>
                <li>Sparka debates just picking them off one by one. </li>
                <li>They advance and shoot</li>
                <li>Hamun comes out yelling to parley</li>
                <li>Lars acts like a zombie and passes</li>
                <li>They tell Hamun to get out, he says he is trying to get an answer, they will help him then he will leave.</li>
                <li>Corpo says he knows the answer to the house that built the ruins…</li>
                <li>Cecil asks why and he says none of my business </li>
                <li>They tell Lars to back off…he says "no" ... “not when your bar tab is as big as mine”</li>
                <li>Can see 4 more zombies </li>
                <li>Lars is getting agitated </li>
                <li>Says he needs name of the house to get in to the ruins. Will leave after getting his information</li>
                <li>The team is wondering what to do..telepathic conversation </li>
                <li>Lars Says he needs his head…necromancer is a little uneasy…shows him bugbear head</li>
                <li>Lars is tired of talking</li>
                <li>Lars hits him with a javelin…party joins in and fight time </li>
              </ul>
              <li>Fight the wizard </li>
              <ul>
                <li> crown of madness fails to get Sass</li>
                <li>Mike asks if ki points are like key limes</li>
                <li>Corpo changes and does breath of dragon with fire, I miss calculate Ki Save DC but still wizard and zombies fail </li>
                <li>Zombie gets hot and party realizes they have close to 20 Hp</li>
                <li>Corpo kills one zombie with thunder kick </li>
                <li>Lars hits with javelin  and he looks grim…Robert can count cards and tells me what his health is</li>
                <li>4 zombies swarm corpo…then another group, then last 5 attack. Only a few hit cause DM rolls terribly</li>
                <li>Sass wades up with bow and shoots a hail of thorns right at the zombies KILL one of them with infused strike </li>
                <li>Sparka cast fire bolt at nécromancie…Kills him down. Burns him to a crisp.</li>
                <li>Zombies start shuffling around </li>
                <li>Cecil viciously mocked “you’re not going to be shuffling anymore” but it gets back up.</li>
                <li>Ben asked if the zombies look dead? Yes…yes zombies are dead </li>
                <li>Corpo kills two zombies </li>
                <li>Lars kills one, doesn’t get up. </li>
                <li>zombies swarm Corpo..do some damage</li>
                <li>Zombies attack Lars for 9 damage </li>
                <li>Sass kills a Zombie and it stays down</li>
                <li>Sparka loots the body before battle is over, finds spell book, dagger, and the nécromancie mission on a note </li>
                <li>Cecil mocks zombie by saying he doesn’t even have his full base. </li>
                <li>Lars hits and get hits by zombies </li>
                <li>Corpo dodges zombie horde </li>
                <li>Corpo kills one with breath of dragon</li>
                <li>Corpo fire punches another to death</li>
                <li>Drake tries but fails to bring down sass </li>
                <li>Sass shoots with infused strike kills zombie </li>
                <li>Sparka firebolt but doesn’t kill</li>
                <li>Cecil vicious mockery janky legs “can’t even do the Cupid shuffle” KILL</li>
                <li>Corpo fails to kill last zombie with staff. Then unarmed strike also doesn’t drop him</li>
                <li>Lars castrated kills a zombie</li>
                <li>Lars adds Hamun head to his necklace </li>
              </ul>
              <li>Investigating the Ruins</li>
              <ul>
                <li>Corpo says "Diemondar" and the first door opens..and the second door revealed</li>
                <li>The second door asks "Say Diemondar Motto"</li>
                <li>Corpo says "Diemondar Motto"</li>
                <li>NAT 20 Sparka on Diemondar House, he knows House of Elves connected to the Human Netherese</li>
                <li>Corpo starts actually telling them about how he was supposed to talk to the Banshee</li>
                <li>Sparka insights Corpo and he comes out truthful</li>
                <li>They have a little "Secrets Secrets are no fun" moment</li>
                <li>Cecil reveals he got a letter from Maise</li>
                <li>Party FREAKING OUT WHY ARE WE DOING THIS? "THE BARTAB" "YOU FUCKERS" "SECRETS" "I GOT HIS HEAD ON A NECKLACE" "NOTHING BUT TRUTH BITCHES"</li>
                <li>Decide it will be time to go to the Banshee. They don't know much about the Banshee...but Corpo wants to find the Banshee </li>
                <li>NAT 1 somehow? Check? 2:04</li>
                <li>ADAM ADMITS HE GOT ME DRUNK 2:05</li>
                <li>Cecil takes out his journal and writes in it...Sparka notices but is like oh a journal cool</li>
                <li>Corpo tells them about the Harpers wanting him to get the motto...and that Banshee is the Agulatha</li>
                <li>Cecil knows that Banshees are undead Elven women that are super vain (no they don't have nuts)</li>
                <li>We essentially sing a lot of Jack Sparrow</li>
              </ul>
              <li>Go to the Banshee</li>
              <ul>
                <li>Sparka "Oh nice" noticing Lars soiled his pants</li>
                <li>They walk 3 hours into the middle of Agulatha's village</li>
                <li>In the ruins of the ruined towns...see nothing....Sass casts Detect Magic "GUYSGUYSGUYSGUYSGUYSGUYS that house is glowing"</li>
                <li>Corpo goes in by himself, "Just don't do anything"</li>
                <li>Corpo tries to flatter her...fails...calls her a witch, then offers her a comb...says baby I'm not here to fight...says its jade...hey sweetheart I'm just asking a question...</li>
                <li>BEN NONONONONO as Cecil and Lars come into the house 2:23 Make a clip</li>
                <li>Cecil casts protection from Good and Evil</li>
                <li>Corpo is attracted to her, so Persuasion not Deception</li>
                <li>Corpo passes check, asks what Motto of House of Diemondar</li>
                <li>Says the motto was "Knowledge and Power"</li>
                <li>Banshee is fading away, Corpo cool with that. Cecil asks about the Cult of the Dragon...she asks for Payment. She flies out and asks for payment</li>
                <li>They notice she has a mini-horde...and dead bodies...Sparka starts noticing and wondering if they could take her</li>
                <li>CLIP ABOUT SOMEONE FARTING 2:30</li>
                <li>"What if some of the skeletons were small business owners?" 2:31 CLIP </li>
                <li>Sass and Corpo don't want to find</li>
                <li>Sparka and Lars talk about wanting to take her...Cecil sends message to Sparka...warning if Cecil fails and Sass fails...NO ONE CAN HEAL THEM</li>
              </ul>
              <li>Fight the Banshee</li>
              <ul>
                <li>She screams what the offer, Lars offers an Axe and Sparka offers Fire...</li>
                <li>NAT 1 Init from Lars</li>
                <li>Cecil casts hex on the Banshee (DM let Hex be too powerful) and then Dissonant Whispers she NAT 1s. She floats away through the wall </li>
                <li>Corpo hits with a staff as Attack of opportunity, Drake infuses it with lightning but notice it is resistant</li>
                <li>Corpo then charges and uses flurry of blows...from his rear buttocks the dolphin leaps over his shoulder to his fist and it goes over the rainbow but had a hard time hitting her more</li>
                <li>Sparka casts chaos bolt as lightning, but it is resistant. Then hits it with a firebolt</li>
                <li>"kIlL tHE BanSHeE" they said</li>
                <li>BANSHEE SCREAMS</li>
                <li>NAT 1 Sass to save on Banshee wail</li>
                <li>NAT 1 Corpo to save on Banshee wail</li>
                <li>Cecil also goes down.</li>
                <li>Lars and Sparka are only ones standing...Ben and Robert are surprised despite being warned this would happen multiple times</li>
                <li>SASS Death Save Fail</li>
                <li>Lars hits Banshee with Javelin and it has resistance</li>
                <li>Cecil Death Save Pass</li>
                <li>Corpo Death Save Pass</li>
                <li>Sparka does seeking spell on chaos bolt...does force bolt and it takes full damage</li>
                <li>Corpo is farting on the ground </li>
                <li>"I could have force pushed her with my mouth but..."</li>
                <li>Banshee tries to terrify Sparka and Lars but they are fine</li>
                <li>Sass Death Save Fail...two strikes</li>
                <li>Lars charges past the dying Sass and attacks recklessly with magical sword...still misses</li>
                <li>BEN and ADAM commentary</li>
                <li>Sparka Misty Steps and then runs to try save Sass...medicine check.....fails...</li>
                <li>"NO WE CAN KILL IT" </li>
                <li>Time Freezes </li>
                <li>Sparka refuses to offer a favor to the Raven Queen...</li>
                <li>Lars accepts the deal without hesitation...Sass stabilizes</li>
                <li>Lars runs away but then throws a Javelin...</li>
                <li>Cecil Stabilizes</li>
                <li>Corpo Stabilizes</li>
                <li>Sparka Magic Missile Kills the Banshee</li>
                <li>Sparka and Lars search the horde before they wake up like selfish peeps</li>
                <li>Sparka takes the comb, 2 platinum bars (500 gp a piece) drunk ass DM fail) and the rest of the treasure</li>
                <li>Lars notices that image stuck in his eye is an axe...and it is in Thundertree</li>
                <li>Set up camp north of the lair </li>
              </ul>
              <li>Break for Night</li>
              <li> Wake up in the morning, Sparka had a dream </li>
              <li> Sass says normally nothing can break us </li>
              <li> "Going to be early like responsible adults" </li>
              <li> Survival checks to know 2 days to Thundertree </li>
              <ul>
                <li> Need healing potions, can stop by Helm's Hold or a random town </li>
                <li> Know that Helm's hold is a religious town...but decide to take the more direct route </li>
              </ul>
              <li> Sparka asks Cecil to cast Identify on +1 Dagger with 3/day 1d4 Radiant Charge </li>
              <ul>
                <li> Sparka keeps it as others have daggers and such</li>
              </ul>
              <li> Corpo says AND BY MY STORM POMMEL I MEAN MY...</li>
              <li> We confirm that Sparka has two 1lb Platinum Barss </li>
              <li> Sass figures out that a Divine Boon allows him cast Command Duel x times per day </li>
              <li> Arrive at Blackbriar town </li>
              <ul>
                <li> Super small town, no children..Sparka is like wait what where are we? </li>
                <li> No children, just a skeletal old man </li>
                <li> They yell praise Bahamut He spits on the ground </li>
                <li> "We don't praise him here" </li>
                <li> Lars spits and says damn right he says "Well shit" </li>
                <li> Opens his coat and reveals 6 health potions....other side of coat is a dead rabbit. </li>
                <li> Old man gives offer for 85 gold. </li>
                <li> Sparka fails to bring price to 55 </li>
                <li> Lars asks for his boss...says dont go in there... </li>
                <li> Sass is like what? He is like you sass me...Sass is like see how big I am? </li>
                <li> Sass casts Thamaturgy....voice booming 3 times as loud, and shakes the ground Intimidate </li>
                <li> Old man backs down says 4 for 55....goes in the house is still shaking </li>
                <li> Lars peaks in the window and he's just sitting on a bucket </li>
                <li> "Did we just hustle a..." "No, YOU just hustled a..." </li>
                <li> They divide up healing potions </li>
              </ul>
              <li> Go to night time...Sparka dreams again. </li>
              <li> They make it to Thundertree the next day...with old map Qeline gave them </li>
              <li> They know undead ash zombie business going on </li>
              <li> Do not know where Reidoth lives...randomly choose to approach right next to his house. </li>
              <li> Find a sign warning to go away...assume Reidoth made it </li>
              <li> Hear no animal noises as they approach.... </li>
              <li> Sass pulls out Dragon Sensing dagger, it is glowing light green </li>
              <li> Cecil knows that Green Dragons are Poison Breath </li>
              <li> Corpos knows that Green Dragons are typically schemers, run mob ring, be wormtongue </li>
              <li> You rolled a 9, that's exactly how it works </li>
              <li> Do we know if we stand a chance against dragons? LARS KNOWS HE DOES</li>
              <ul>
                <li> Cecil knows that an adult dragon slaughter them...Wyrmling they'd slaughter...Young Dragon be a toss up </li>
              </ul>
              <li> "We got a 2/3rds chance" Lars </li>
              <li> There is no such thing as a good grown adult green dragon </li>
              <li> Phandalin Five decide to take on the dragon...Sass reveals that he needs to kill the young dragon to avenge for sake of Dragonborn's honor </li>
              <li> They see a single house that is not abandoned, smoke rising from chimney.  </li>
              <li> Corpo knocks, hears a noise, and enters to see fresh veggies roasting on the fire. Bad perception so thinks it is a nice room </li>
              <li>Corpo sees an odd bird sitting on a rafter staring at him and then transforms into an old man as Reidoth appears</li>
            <li>Corpo gives a profound bow and says he is nothing but appropriate </li>
            <li>Corpo is very polite, Reidoth is impressed but still confused that they are in a death zone. Corpo invites others in</li>
            <li>"Oh wait...I remember why we are here" Robert</li>
            <li>Lars sees a conspiracy theorist board with "The Return of the Queen" in Dwarven</li>
            <ul>
              <li>Lars asks him (rest of party can't read) uneasy but Corpo was nice</li>
              <li>Says he has been researching, says Dwarven legends</li>
              <li>Lars notices he is a Circle of Spores Druid</li>
              <li>Reidoth tells Lars about the Prophecy about the Return of the Queen but they are talking in Dwarven so the others just keep going like...uhhh </li>
              <li>"He who harvests the mushrooms like a true Dwarf will bring about or stop the Return of the Queen" and...I harvest them...</li>
              <li>Some people think that the Return of the Queen is about the Demon Queen, but others about the  Dragons</li>
              <li>He says a bit he hasn't figured out is about neutering mushrooms....Lars smiles</li>
              <li>Reidoth says that there are Dragon Cultists...cloaks and masks. Hasn't attacked since he is outnumbered. Points to where they are</li>
              <li>The Cultists are in town due to the...Dragon . Which he says is in the tower and is a young dragon named Venomfang</li>
              <li>Sass asks his nps promoter score</li>
              <li>Lars asks about Gundren..."fellow truth seeker"</li>
              <ul>
                <li>Super smooth asks about Cragmaw Castle </li>
                <li>Reidoth says Cragmaw Castle has a slave auction going on that they are inviting groups to bid</li>
                <li>Lars says "Well we could always use more slaves!"</li>
                <li>"What the fuck is going on man?" Mike</li>
              </ul>
              <li>Lars asks about Wave Echo Cave...</li>
              <ul>
                <li>Reidoth is hesitant to tell them</li>
                <li>Sparka asks if they drive off the Dragon...Reidoth says that he will help him</li>
              </ul>
              <li>Lars sees that the axe is close in the tower</li>
              <li>The Phandalin Five decide to fight the dragon, sneaking up the hill</li>
              <li>"I like that idea...sneaking up hills" </li>
            </ul>
            <li>Group agrees that the Cultists would take a hot minute to get to the battle with the dragon</li>
            <li>"What are you rolling?" Robert asks Mike who is getting ready </li>
            <li>"THESE ARE MY PERCEPTIONS" </li>
            <li>"ARE WE GOING TO KILL A FUCKING DRAGON???" then they Avengers it </li>
            <li>Head up the Hill to Venomfang</li>
            <ul>
              <li>Sparka does checks and sees that there is no odd-swallow </li>
              <li>They see gnarled trees and smell acrid stench....as they approach</li>
              <li>See a dead giant spider..."Guys I think we are getting close"</li>
              <li>Corpo tries to harvest spider poison...but Sass Animal Handlings and harvests vials of 1d4 poison</li>
              <li>The order Lars, Corpo, Sass, Sparka, Cecil</li>
              <li>Door to the tower is open...but can't tell what is happening</li>
              <li>Lars gets the axe out...</li>
              <li>They know that it will be resistance to poison, but everything else fine</li>
            </ul>
            <li>Enter the tower!</li>
            <ul>
              <li>Venomfang tells them to come in, says neighborhood is sketchy</li>
              <li>Lars agrees neighborhood has gone to shit </li>
              <li>They all come in..he invokes Draconic Guest Rite</li>
              <li>See small dragon horde...Lars sees an amazing axe...no one else does</li>
              <li>Sparka gets greedy-ass eyes on the horde</li>
              <li>Venomfang asks if they are here to clean up the town...about Cultists, zombies, and a Druid with zombie experiences....</li>
              <li>"You could say that" Sass quip</li>
              <li>Says his name is Lakhim but goes by Venomfang</li>
              <li>Lars says that he killed the Druid on the way into town....Venomfang seems to take it</li>
              <li>Lars shows them the mushrooms as proof that they murdered him</li>
              <li>Venomfang says that he and the Druid had an arrangement with the Druid that they would permit the other to live in exchange Druid would send adventurers to the tower to be eaten.</li>
              <li>Party like whoaaaa?</li>
              <li>Asks about cleaning up the cultists...Venomfang says they have tried going up and back the hill like trying to build courage</li>
              <li>NAT 1 Corpo on Deception check that the cultists attacked him</li>
              <li>Corpo asks why he is here, Venomfang says his sister drove him off</li>
              <li>Dragon doesn't have thumbs</li>
              <li>Lars tries to parley about the axe...good insight roll notices that the Dragon isn't really paying attention to the axe, it is in the lesser horde</li>
              <li>Lars offers to trade axes....Lars rolls like a beast and Venomfang accepts the trade...LARS DOES AND GETS HIS AXE..."fondles it"</li>
              <li>Sass telepathically tells them to spread out.</li>
              <li>Everyone is like wtf is with that axe? Cecil casts identify on it and sees a ton of magic swirling on it</li>
              <ul>
                <li>You see written on it "Pick me up"</li>
                <li>It has attunement</li>
                <li>Lars is crying he is so happy</li>
              </ul>
              <li>"Oh slithery one" Corpo says in Draconic to flatter him</li>
              <li>Sparka tries to get him to kill the cultists and they will watch his horde...he is like...uhhhh</li>
            </ul>
            <li>They all wait for Lars to Leory Roy Jenkins....</li>
            <li>Lars looks at the axe....says time to see what this beautiful baby can do....ROLL FOR INITIATIVE </li>
            <li>DRAGON FIGHT</li>
            <ul>
              <li>Roll initiative! Sparka NAT 20!</li>
              <li>Venomfang rolled terrible....bad times</li>
              <li>Venomfang was thrown off by their hospitality...Did you even kill the druid??</li>
              <li>Round 1</li>
              <ul>
                <li>Sparka casts Chaos Bolt and hits with Fire Damage, Quickens Firebolt for a productive round, Sparka notes he is not fire resistant</li>
                <li>Corpo attunes to his tattoo and charges into combat, misses with Quarter Staff, hits with one Eldritch-Dolphin-Empowered-Flurry of Blows </li>
                <li>Sass lights up Hunter's Mark with the Dragon Slaying Arrow...misses</li>
                <li>Lars throws a Javelin with Drake infused strike and hits the Dragon then RAGE</li>
                <li>Cecil Hexes Venomfang and unleashes Dissonant Whispers (we add Hex incorrectly)</li>
                <li>Venomfang walks forward and unleashes dragonbreath!</li>
                <li>NAT 20 Corpo Con Save against breath</li>
                <li>Lars laughs in resistant dwarven against poison</li>
                <li>Sass fails and is out</li>
                <li>How is the dragon looking? ..... "OK"</li>
              </ul>
              <li>Round 2</li>
              <ul>
                <li>Sparka is pissed and Earthen Grasps Venomfang with Shadowfell shard to curse him, but misses a firebolt</li>
                <li>Corpo uses breath of the dragon then Flurry of Blows, hit restrained Venomfang for </li>
                <li>Sass DEATH SAVE....Pass</li>
                <li>Lars runs at him from behind and attacks with his Greataxe to give him a colonoscopy...but....misses</li>
                <li>Cecil casts Dissonant Whispers again...but Venomfang passes. Then raises Sass up</li>
                <li>Venomfang does not get his breath weapon back....bursts out of the grip</li>
              </ul>
              <li>Round 3</li>
              <ul>
                <li>Sparka grabs him again with the Earthen Grasp again! Quickened Chaos Bolt Lightning Strike</li>
                <li> Corpo does not drink a potion. Rocks unarmed strikes and hits him solid</li>
                <li>Sass debates going for the self-heal, but shoots him instead</li>
                <li>Lars RAGES and attacks with Great Axe</li>
                <li>Cecil casts Dissonant Whispers and Venomfang Fails save and Cecil...</li>
                <li>THE DRAGON SLAYER! Brutal HOW DO YOU WANT TO DO THIS</li>
                <ul>
                  <li>How do you want to do this? While fighting with the Earthen Grasp his head gets crushed to a singularity</li>
                </ul>
              </ul>
            </ul>
          </ul>

          </Collapsible>

          <Collapsible
            title="Mushroom Cloud (#9 - 2/04)"
            toggle={this.collapseToggle.bind(this)}
            kind="session9"
            show={this.state.show}
          >
            <ul>
              <li>
                Party realizes that they need 8 hours to upgrade Sass and Corpo's weapons
              </li>
              <li>
                Draconic Death Throes Reveries
              </li>
                <ul>
                  <li>
                    Corpo supposed to be meditating, but was staring at his pommel
                  </li>
                  <li>
                    Lars remembers the Dwarphanage, playing a game with Ole and Bamur. Ole was smart,  Bamur tried to bluff but Lars could read him.
                  </li>
                  <li>
                    Corpo hears 5 heads of Tiamat calling for him, but hears Bahamut also claim him as his champion
                  </li>
                  <li>Cecil remembers talking to his father about his family. There was secrecy, he never got deep into it. Cecil asked if his father had siblings, father said that's enough and stopped
                    up.
                  </li>
                  <li>
                    Sparka had his vision
                  </li>
                </ul>
              <li>
                They see Cecil with his Pseudodragon and green scales, Sparka knows that he could be ancestor of a Draconic Sorcerer
              </li>
              <li>
                See a swirl of magical energy around Venomfang as his horde magic collapses
              </li>
              <li>
                Sass wants to take a long rest and simmer his bow in this magic stew...he is also almost dead
              </li>
              <li>
                Lars wants to drop his Dwarven stew in the corner
              </li>
              <li>
                They start resting, Cecil takes out journal
              </li>
              <li>
                Lars lays down in the magical swirling energy and beard grows AND the carpet matches the drapes
              </li>
              <li>
                Sass climbs the tower to keep an eye out, Drake follows him
              </li>
              <li>
                Sass NAT 20 Perception Check. Notices a cloud of mushroom spores rolling into the town.
              </li>
              <li>
                Sass hollers to party about mushroom spores...not a mushroom cloud...a cloud of mushroom spores
              </li>
              <li>
                They see "Ole" running up the path
              </li>
                <ul>
                  <li>
                    "Ole" asks what happened
                  </li>
                  <li>
                    "Ole" sees that the axe is beautiful
                  </li>
                  <li>
                    "Ole" asks about the prophecy...Lars is unsure But Corpo asks if he Prophe-sees this dragon dick
                  </li>
                  </ul>
              <li>
                Sass sees Reidoth running in
              </li>
                <ul>
                  <li>
                    He is excited that they killed Venomfang...
                  </li>
                  <li>
                    But the party is like Venomfang said some stuff....
                  </li>
                  <li>
                    He's like whaaaaaaa???
                  </li>
                  <li>
                    Sparka asks if he sent them to their death, he says no I knew you could handle it!
                  </li>
                  <li>
                    Sparka with Insight thinks he might be hiding something
                  </li>
                  <li>
                    Lars asks about mushroom cloud, he says he went through it with a cough but is unsure
                  </li>
                  <li>
                    Sparka uses Detect Thoughts, gets that Reidoth is confused about what they are thinking...SPARKA PROBES DEEPER
                  </li>
                  <li>
                    Reidoth resists at first, but then admits the town is special to Circle of Druids who are interested in zombies
                  </li>
                  <li>
                    Says the Ash (not Ass) Zombies are unusual and he wanted to figure out what was up with them
                  </li>
                  <li>
                    Cecil is about to Charm Person when Reidoth tells Sparka to try Detect Thoughts again...he won't resist
                  </li>
                  <li>
                    Sparka reads thoughts, he says green dragons lie so that's probably why it lied
                  </li>
                  <li>
                    Sparka also asks about The Return of the Queen. He says three groups believe it will happen
                  </li>
                  <li>
                    Says Dragon Cultists in Town believe it is about Tiamat
                  </li>
                  <li>
                    Says Mushroom Cultists think it's about Mushroom Demon
                  </li>
                  <li>
                    Says Elemental Cultists believe it's about Elemental Princes
                  </li>
                  <li>
                    Pulls out maps, shows the Kinaphalic Kingdom map...but Sass hasn't told anyone his name yet. Date 1350s
                  </li>
                  <li>
                    Next map is Rough Map of Empire of Blood, Sparka rolls well with Religion and identifies a bunch of religious symbols, like Gruumsh, Maglubiyet, Tiamat, Bahamut
                  </li>
                  <li>
                    Notices the Elvish Calligraphy, including one with (A-E-E-L-N) that is scratched on his focus
                  </li>
                  <li>
                    Also notices that one Elvish calligraphy is matched by the name at the bottom Fete Amarillis 1485
                  </li>
                  <li>
                    Also assumes gold and silver calligraphy
                  </li>
                </ul>
              <li>
                Sass sees the cloud getting closer....Nature check...it ain't natural but is slowing down...but it's there and freaking me out
              </li>
              <li>
                Robert makes comments about zombies
              </li>
              <li>
                "Ole" asks what would happen next...Lars jokes about taking over the world..."Ole" says that is a great idea
              </li>
              <li>
                Lars gives him his old axe, "Ole" is so touched...Sparka thinks that something might be up
              </li>
              <li>
                "Ole" goes to talk to Reidoth, asks Reidoth about the Return of the Queen...but Reidoth starts coughing (HE HAS COVID!)
              </li>
              <li>
                "Ole" goes to talk to Lars about taking over the world but Lars notices him scratching his ear
              </li>
              <li>
                Lars says hey let's walk outside. Once "Ole" is outside lars shoves furniture in front of the door.
              </li>
              <li>
                Corpo is pleased and it is visible
              </li>
              <li>
                "Ole" asks what is wrong, Lars says it's your tell.
              </li>
              <li>
                "Ole" drops the disguise and Bamur, slightly methed out, is revealed
              </li>
              <li>
                Bamur says Lars you have found the axe, you will bring about the Return of the Queen
              </li>
              <li>
                Lars warns Bamur about the mushroom cloud...Bamur says..."The Queen's Mushroom Cloud"
              </li>
              <li>
                He starts using hand gestures to puppet control Reidoth but is rusty. Corpo and Sparka run over to tie him up
              </li>
              <li>
                Bamur tells Lars last chance to leave these Heathens who worship false gods
              </li>
              <li>
                Lars: "These heathens are my friends!"
              </li>
              <li>
                Lars drops to ground weeping
              </li>
              <li>
                "My friend you bow to no one" Sparka
              </li>
              <li>
                Reidoth
              </li>
              <li>
                "You have no need to serve a god, you are a god" Corpo to Lars
              </li>
              <li>
                Lars is reassured...downs sagecourse and the shakes go away
              </li>
              <li>
                Bamur says fine I'll kill your friends then you will only have me left...
              </li>
              <li>
                Bamur turns into mushrooms and then flies away
              </li>
              <li>
                Cecil sends Bowser out the scout as they hear a shriek
              </li>
              <li>
                Cultists running afraid to the tower...they start running towards the door
              </li>
              <li>
                Bowser sees other cultists spasming on the ground
              </li>
              <li>
                Sass notices that the mushroom cloud isn't getting any higher (Corpo is not familiar with this...can get wider)
              </li>
              <li>
                They are shouting and Corpo recognizes the voice of Faveric Windspire from earlier on the road
              </li>
              <li>
                Corpo asks if he can see through, notices that he could hide...does not
              </li>
              <li>
                Faveric is yelling for Venomfang when...sees Corpo "Sup shit for brains"
              </li>
              <li>
                Faveric is like no time for this...spasming friends...where is the great and mighty Venomfang
              </li>
              <li>
                Cecil walks over and Faveric sees Bowser on his shoulder as Cecil is like "We took care of Venomfang"
              </li>
              <li>
                Cultists are dry heaving...Faveric is like worst day ever and breaks down
              </li>
              <li>
                "...uhhh truce?" he offers, Lars rejects
              </li>
              <li>
                Cecil asks what he is doing...Faveric says trying to recruit Venomfang to impress the Prophet of their sect
              </li>
              <li>
                Cecil asks who the Prophet is...."Severin Solonna" Cecil sits dumbfounded
              </li>
              <li>
                Lars holds up the claw "I AM THE DRAGON NOW"
              </li>
              <li>
                Corpo asks if Faveric remembers him, he says yes,
              </li>
              <li>
                Corpo tells him "you gave life to a purposeless cause and all that awaits you is possession by fungus"
              </li>
              <li>
                Says they are just trying to impress a Gold Elf...Lars like whoaaaaa Mysterious Gold Elf from earlier..is that Solonna?
              </li>
              <li>
                They are looking at Cecil like wth?
              </li>
              <li>
                Cecil asks if they know about the mushroom fog..he's like I'm fleeing it
              </li>
              <li>
                Faveric is like ok you the dragon now? Ok. Fine. How about we give treasure we meant for dragon and you let us in.
              </li>
              <li>
                Lars is like show us first...Sparka yells out deal without hesitation
              </li>
              <li>
                Faveric opens the chest, revealing 3 diamonds worth 100 gp each, and a potion
              </li>
              <li>
                Sparka says they can sit the corner...no moves
              </li>
              <li>
                Faveric is like safe passage? Party says "If you bow to the dragon"
              </li>
              <li>
                Cecil says parley with us now instead of Venomfang
              </li>
              <li>
                Faveric is clearly worried, all three bow to the dragon "Passage granted" "Lars the well-endowed"
              </li>
              <li>
                Sparka checks on Reidoth...not getting any worse...Sparka gags him so he can't cast
              </li>
              <li>
                The party allows the cultists in...Lars fails a wisdom saving throw. Lars is like Faveric is the BEST!!!
              </li>
              <li>
                Party does not notice the cast
              </li>
              <li>
                Lars brings them in, hands the chest to the dragon...sits in the corner
              </li>
              <li>
                Cecil walks towards them and asks
              </li>
              <li>
                Sparka grabs diamond and the potion (tell Robert what it was)
              </li>
              <li>
                Cecil asks him about Severin Solonna...tells that Severin was linguistic scholar who re-translated an old text that challenged undead dragons ruling the world...should be Tiamat so that' s what he is doing! Returning the Queen
              </li>
              <li>
                Sass can hear this noise coming up to his tower...is like OH HELL NO
              </li>
              <li>
                Faveric is like Severin left his Elven finery to save us all by Tiamat...well not the Dragon he is saved
              </li>
              <li>
                Lars is like What What Boyyyyeeee
              </li>
              <li>
                Sass is still on top of the tower
              </li>
              <li>
                Sass leans over and is like whoa this is bad
              </li>
              <li>
                Lars wonders if the Gold Elf from town was Severin...who was asking about Cecil about parents. Notices Severin sounds older.
              </li>
              <li>
                Cecil asks where Severin is...says he does not tell the lowly...he is off world at the Home of Dragons
              </li>
              <li>
                Sparka asks if he has heard of Cloudchaser, Faveric is like yes Cloudchaser is part of the reformed order
              </li>
              <li>
                Cloudchaser is out there trying to find the Dragon Masks
              </li>
              <li>
                Sass is booooing from the tower
              </li>
              <li>
                Sparka is like "That's rich coming from you" but mutters so Sass can't hear. Corpo hears...looks like huh?
              </li>
              <li>
                Faveric tries to walk towards Lars "back in the corner bitch"
              </li>
              <li>
                Lars is like keep all my friends happy
              </li>
              <li>
                Sass is like...someone say something about me
              </li>
              <li>
                Cecil NAT 1 to notice that Lars acting weird
              </li>
              <li>
                Sparka notices Lars acting odd...with Arcana of check knows about Charm Person
              </li>
              <li>
                Sparka decides to cast a Firebolt to break concentration (I MESS UP CHARM PERSON IS NOT CONCENTRATION)
              </li>
              <li>
                Spark and Lars roll initiative
              </li>
              <li>
                Lars sees Sparka about to attack, tries to tackle Sparka so he can't hurt Faveric, but Lars misses
              </li>
              <li>
                NAT 20 Sparka hits Faveric with a Firebolt...breaks concentration
              </li>
              <li>
                Lars figures out what happened goes into rage "The Dragon is pissed"
              </li>
              <li>
                Cecil declares it Worst long rest ever
              </li>
              <li>
                Lars attacks Faveric Recklessly, fucks him up
              </li>
              <li>
                Sparka casts bonfire on his square
              </li>
              <li>
                Faveric is on fire, terrified
              </li>
              <li>
                Sparka Telekinetic shoves the other Cultist onto the fire, immediately burns them to death
              </li>
              <li>
                Cultist tries to disengage..but Sentinel! Lars gets NAT 20
              </li>
              <li>
                Robert and Ryan make sure the DM knows that the guy with 7 HP who get critical hit by the raging barbarian with a +1 Greataxe for 18 HP cannot move due to the Sentinel Feat
              </li>
              <li>
                Lars kills castrates the Cultist and throws his junk in the fire
              </li>
              <li>
                Lars drags Faveric out of the fire "Like Anakin Episode 3"
              </li>
              <li>
                Cecil is like whaaa?? Corpo is horny.
              </li>
              <li>
                Interrogate Faveric, asks him why he fucked with the dragon
              </li>
                <ul>
                  <li>
                    Faveric says the Cult got him on the right path, took a gamble
                  </li>
                  <li>
                    Took a gamble today, either died out there or charmed him in here
                  </li>
                  <li>
                    "Any questions before I neuter this bitch?" Lars asks
                  </li>
                  <li>
                    Cecil asked him about his past, Faveric says joined about year ago, tried to get in higher up in the org
                  </li>
                  <li>
                    Cecil asks about anyone else higher up. Talis and Cloudchaser
                  </li>
                  <li>
                    Corpo is just eating watching this
                  </li>
                  <li>
                    Lars neuters the KILLS Faveric by beheading
                  </li>
                </ul>
              <li>
                Sparka loots the bodies, most money in the bodies
              </li>
                <ul>
                  <li>
                    7 silver 2 copper
                  </li>
                  <li>
                    Two regulars have daggers
                  </li>
                  <li>
                    No Robes of the Archmagi, but a Cloak of the Shithead, but it is burned
                  </li>
                </ul>
              <li>
                Barricade the door, Corpo and Sparka terrible Survival check
              </li>
              <li>
                Corpo oh contraire he knows about good wood
              </li>
              <li>
                Lars notices they suck at furniture poorly and helps out
              </li>
              <li>
                Sass comes down leaves Drake up top, sees three burnt dead bodies
              </li>
              <li>
                Lars notices poor wood choice...fixes it
              </li>
              <li>
                It's quiet...Bowser checks...fog receives...but silent
              </li>
              <li>
                Reidoth is trying to scream...but...rest try to sleep Lars does some dip
              </li>
              <li>
                Corpo lops off the Venomfang's dragon Phallus
              </li>
              <li>
                Bowser notices some plants are moving
              </li>
              <li>
                Party granted a short rest...Cecil plays the Song of Rest
              </li>
              <li>
                Twig Blights are coming
              </li>
              <li>
                Sass sees incoming Shrubberies
              </li>
              <li>
                Sparka creates bonfire in front of the door
              </li>
              <li>
                Sass is like should we talk to them? "Trees shouldn't walk"
              </li>
              <li>
                Cecil NAT 20 Arcana to know Twig Blights are awakened plants, no reasoning...echo the wisdom of Lars about trees
              </li>
              <li>
                Sass uses Thamaturgy with fire to scare back Blights, but mushrooms grow in them to drive them forward
              </li>
              <li>
                Sparka rushes up the stairs
              </li>
              <li>
                Corpo uses breath of dragon and super burns a Twig Blight
              </li>
              <li>
                Sass hears something creeping up the tower
              </li>
              <li>
                Lars to do Ring of Jumping to leap up leaving cloud of dust Sass is like what?
              </li>
              <li>
                Cecil hears a noise as he starts his turn, uses Citern to fly up to Lars' better side
              </li>
              <li>
                Twig Blight on fire runs away until mushrooms grow out and grab him
              </li>
              <li>
                They see Mushroom-possessed Cultists coming for them out of the building
              </li>
              <li>
                Sass goes to check on the noise....AHHHH TWO GIANT SPIDERS with mushrooms
              </li>
              <li>
                Sass shoots with the high ground the closer spider...misses...
              </li>
              <li>
                Sparka goes and looks at the spider sees that they look mindless
              </li>
              <li>
                Sparka shoots one with a firebolt, tries telekinetic push on the other
              </li>
              <li>
                Cecil has Bowser support Lars
              </li>
              <li>
                Giant Horse-sized Spider attacks Sass, hits him for damage, but passes the Con save, resisting the poison, flexes peck to squirt back one of spider's eyes.
              </li>
              <li>
                Corpo Addresses Reidtoh...diiscusses dragon dick slapping
              </li>
              <li>
                Corpo almost slaps Reidoth across the face with a dragon phallus but instead just yells at him
              </li>
              <li>
                Reidoth is in and out of it...says don't worry about him
              </li>
              <li>
                Corpo sees an Ashen Fungal-infested Shadow sneak through the wall
              </li>
              <li>
                Cecil needing rest as Corpo mutters under his breast
              </li>
              <li>
                Corpo fearlessly punches a ghost. Then Flurry of Blows NAT 20 Punch a Ghost
              </li>
              <li>
                Corpo one round kills ghost horrifyingly with a Dragon Phallus
              </li>
              <li>
                When Shadow dies the spores die too
              </li>
              <li>
                Sass has vision of Bahamut saying that he is his champion and granting him Crusader's Mantle. Sees a shimmer of Platinum scales on his hand
              </li>
              <li>
                Lars attacks the Giant Spider atop the wall..but saves his rage. KILLS GIANT SPIDER. Castrates it's spinnerets
              </li>
              <li>
                Lars This is Sparta kicks the Spider over the edge...lands on another spider to damage it
              </li>
              <li>
                Corpo who has a crazy AC and DM challenged him
              </li>
              <li>
                Second shadow comes at Corpo to soul sucking kiss...Corpo puckers up and resists it "French or Puritan"
              </li>
              <li>
                Cecil casts vicious mockery on Giant Spider. "You're not even sure if you are a spider of a horse and what the hell are these mushrooms"
              </li>
              <li>
                Mushroom possessed Twig Blights charge door. Three die...so Sparka kills Three
              </li>
              <li>
                Three more Twig Blights show up
              </li>
              <li>
                The cultists with their eyes replaced with mushrooms are stumbling towards them
              </li>
              <li>
                Sass shoots climbing spider with infused and radiant damage KILLS it by staking it to the ground with the arrow through it
              </li>
              <li>
                Sparka casts firebolt but misses, shoves the spider down so it does not make the top
              </li>
              <li>
                Bowser goes to help Sass
              </li>
              <li>
                Mysterious Crossbow bolt infused with Necrotic energy explodes the barricade
              </li>
              <li>
                Sass' Drake freaks out like its old master is nearby
              </li>
              <li>
                Corpo thinks it unfortunate Reidoth was not killed
              </li>
              <li>
                Corpo punches the Shade, Flurry of blows NAT 1 first strike, hits the second
              </li>
              <li>
                Lars Leaps off the tower, kills spider, /castrates it, rides corpses down to the ground... all of this is amazing
              </li>
              <li>
                Sees another spider right near him...Corpo aroused?
              </li>
              <li>
                Corpo is kissed by Shadow and sucks 4 strength out
              </li>
              <li>
                Cecil Vicious Mockery Shadow says "Why only Jello in intestines you need protein"
              </li>
              <li>
                3 Twig Blights charge fire and open door
              </li>
              <li>
                Sparka kills two Twig Blights that charge through fire, one gets through
              </li>
              <li>
                Sass shoots from the high ground misses
              </li>
              <li>
                Sparka kills Twig Blight with Firebolt, Telekinetic shoves Shadow
              </li>
              <li>
                Bowser goes to help Lars
              </li>
              <li>
                Crossbowbolt misses Lars "Rather be lucky than good"
              </li>
              <li>
                Corpo charges and punches at Shadow to Kills it by using the Dragon Phallus shot through it
              </li>
              <li>
                Ben discusses solving the Opiod Crisis with a Dragon Dong
              </li>
              <li>
                Spider attacks Lars, he flexes to squirts the poison out of his peck all over the spider
              </li>
              <li>
                Lars kills spider and castrates its spinnerts
              </li>
              <li>
                Bamur sends a message to Lars saying we should have joined but...Lars is like "I am the dragon"
              </li>
              <li>
                Bamur, flees, Lars knows he was a coward
              </li>
              <li>
                Lars decides not to leap at Bamur....somehow he is wise
              </li>
              <li>
                They see the Mushroom forces scamper away
              </li>
              <li>
                Drake calms down...so Crossbow blaster is gone
              </li>
              <li>
                Reidoth is still writhing on the ground, Lars figures out a psychadellic mushroom that can f-up his mind
              </li>
              <li>
                Reidoth trips and it breaks the spore-hold on him...Lars says bow to the Dragon
              </li>
              <li>
                Lars fully attunes to the ax
              </li>
                <ul>
                  <li>
                    So he can read/write Celestial
                  </li>
                  <li>
                    Sees "Pick me up" "Cast me down"
                  </li>
                  <li>
                    The Middle says twist, he twists figures he can toggle Great Ax into Halberd
                  </li>
                  <li>
                    Ax says "I look forward to working with you, I also detest spinnerets being on spiders"
                  </li>
                  <li>
                    Lars looks dope as the axe does not change
                  </li>
                  <li>
                    Ax tells him "Command Word Show me the Money" and Ax toggles for others
                  </li>
                  <li>
                    Notices he can always see the ax...through walls and self too.
                  </li>
                </ul>
              <li>
                Dragon Horde items level up...Crew Rests
              </li>
              <li>
                DM goes through the Divine Boons since he had not explained due to him being whatever
              </li>
                <ul>
                  <li>
                    He also fails at viewing such things
                  </li>
                  <li>
                    Sass has two boons
                  </li>
                  <li>
                    Sparka has one
                  </li>
                  <li>
                    Cecil has two
                  </li>
                  <li>
                    Lars dreams of Kord, a Hulk Hogan/Thor/Yukon Cornelius combo, and gets his 1d8 Lightning power
                  </li>
                  <li>
                    Corpo has his stealthy at night ability
                  </li>
                </ul>
              <li>
                Sparka pilfers the dead bodies....rolls 69 so we introduce absurd rules
              </li>
              <li>
                Find a platinum dragon phallus worth 4 platinum
              </li>
              <li>
                DM clarifies that it is an Effigy to Bahamut...not the real one
              </li>
            </ul>
          </Collapsible>

          <Collapsible
            title="The Blood of House Diemondar (#10 - 2/18)"
            toggle={this.collapseToggle.bind(this)}
            kind="session10"
            show={this.state.show}
          >
            <ul>
              <li>Late night in the tower, talking</li>
              <li>Reidoth is sleeping from his traumatic experience </li>
              <li>As they start passing out, they chat, decide they need to pass by the Ruins to get through the door</li>
              <li>Cecil "does not talk" to Sparka about getting a diamond</li>
              <ul>
                <li>Sparka refuses to share the diamond</li>
                <li>Sparka knows that pseudodragons are the cats of the dragon world</li>
                <li>Cecil is the prodigal son and wants his inheritance now</li>
                <li>Sparka throws a ball of yarn or something just shiny at it so no diamond, gives silver goblet</li>
                <li>DM is grateful it did not give get the Platinum Phallus</li>
              </ul>
              <li>Cecil asks Corpo about the Harpers but Corpo is like hold on</li>
              <li>Lars is like wait "Have you ever run a cult before" to Cecil</li>
              <ul>
                <li>Cecil says same last name but that he knows nothing about running a cult or his father's family</li>
                <li>Cecil is like I know nothing about Severin</li>
              </ul>
              <li>Cecil asks Corpo about Harpers and why sent to Banshee</li>
              <li>Corpo says Harpers are trying to keep magic away from evil people</li>
              <ul>
                <li>Haven't sworn my life to them...they gave me a pin</li>
                <li>Corpo forgot Sister Gaerelle...then remembered that he kissed her</li>
                <li>They ask if Corpo would be handing over the magic items in the ruins to the Harpers?</li>
                <li>Corpo is not  married to them, they not married to me</li>
              </ul>
              <li>Sparka recalls that Harpers are super decentralized and probably cool if they keep things as long as not tyrants</li>
              <li>Cecil asks about the Mushroom Queen</li>
              <ul>
                <li>Corpo thinks she is kinda hot</li>
              </ul>
              <li>Hearts to Heart</li>
              <ul>
                <li>Sparka like whoa why you asking sooooo many questions</li>
                <li>Cecil reveals his journal is a paired journal to Maise</li>
                <li>At least been giving her info and getting info from her about the Cult of the Dragon</li>
                <li>"Oh just that?" Sparka is like whaaaa</li>
                <li>Maise is part of a College about truth and finding everything </li>
                <ul>
                  <li>(Mike is like whoa they keepers!)</li>
                  <li>Cecil says just trying to get as much info as possible</li>
                  <li>Cecil says he is trying to find as much info as he can connect to his paternal family</li>
                  <li>He is a little more hesitant now....knowing about Severin</li>
                </ul>
                <li>Lars is like anyone else getting recruited by the Zhentarim?</li>
                <ul>
                  <li>Lars says they like elite...like the Marines and they are trying to recruit him</li>
                  <li>Getting heads to prove he is good</li>
                  <li>Cecil assumes his letter was lost in the mail</li>
                </ul>
                <li>Cecil claims he is not working for the Heralds</li>
                <ul>
                  <li>just sharing info and working together</li>
                  <li>Focus on Magic artifacts...SPARKA LIKE SOUNDS LIKE THE HARPERS!</li>
                </ul>
                <li>Corpo like the Harpers are Chaotic Good...Corpo all about the Harpers says they good</li>
                <li>Sass is like ALL ABOUT REVENGE!</li>
                <li>Cecil is like let's go back to the ruins, figure it out, note beheld to anyone</li>
                <li>Lars explains he has the heads to prove he killed the Red Wizard and get plenty of gold</li>
                <ul>
                  <li>Sparka is like why...Lars is like I kill everyone anyway</li>
                </ul>
                <li>Corpo is like is Reidoth here? Is asleep unless DM needs to say anything</li>
                <li>Sparka asked if Cecil if Maise asked about Phineas?</li>
                <ul>
                  <li>He says yes and wants to see his Arcane Focus</li>
                  <li>Sparka like...wait.....WHAAAAAAAAAA?</li>
                </ul>
                <li>Group figures out they haven't talked in a long time</li>
                <li>Lars is like wait what about the Zhentarim anyone else?</li>
                <li>Sass knows they are the Illuminati like Jay Z</li>
                <li>Sparka is like...ok....Phineas was mentioned by Maise as an Artificer who could know about the arcane focus</li>
                <li>Sass like I am not working for anyone...just want Cloudchaser</li>
                <li>Spraka looks uncomfortable when he mentions Cloudchaser</li>
                <ul>
                  <li>"Dude why you look so uncomfortable</li>
                </ul>
                <li>"We should fight him a little later" "Did he touch you?" </li>
                <li>Sparka asked if anyone else has had visions</li>
                <ul>
                  <li>Corpo thought his astral trip was a vision</li>
                  <li>Cecil had flashback of his father</li>
                  <li>Sparka tells everyone that he has had visions...they last about as long as a 3x5 notecard</li>
                  <li>Sparka says he think they are in the future..distrubing ones about Sass</li>
                  <li>"Dude just live in the now" Sass</li>
                  <li>Sparka like we come into Nothic and stuff we can't just live in the now</li>
                  <li>Sass is like why you worried about me?</li>
                  <li>Sparka is like you fight for Cloudchaser in my visions</li>
                  <li>Sass is like I'm champion of Bahamut...whose dick you hold...Players ignore logiic and continue this insane line</li>
                  <li>Sparka like I only see darkness around you. "Maybe it's up to you to save me" </li>
                  <li>Lars you told me I had one arm but I have two...three actually</li>
                  <li>Sparka thinks his dreams have purpose...maybe not to save him but they can help?</li>
                  <li>Sass wonders if mind control or shapeshiting?</li>
                  <li>"We have been through so much..." "WE BEEN THROUGH SO MUCH"</li>
                  <li>They say that they will Battle together forever</li>
                  <li>Sass is like I have no plans on turning on this group "I would never turn against my family"</li>
                  <li>"You shoot and arrow at me" Sparka says...Sass says "Perhaps it's a symbolic arrow" </li>
                </ul>
                <li>Sparka  comes clean that he gave money to orphans that he got the money from Halia</li>
                <ul>
                  <li>Sparka like Lars I know Halia...she's in the Zhentarim</li>
                  <li>He tells them all that he had gone and met Halia that first night in town and that she wanted Glass staff  correspondence</li>
                  <li>He SANTA-ed money to the orphans</li>
                  <li>"As a DWORPHAN I SYMPATHIZE"</li>
                  <li>"I know what it is like to be an orphan"</li>
                  <li>Cecil is the only non-orphan?</li>
                  <li>He says he has no interest in working with the Zhentarim...</li>
                  <li>Says Halia has stuff in her basement</li>
                  <li>Sparka says sorry he didn't tell anyone about it...she wanted the Red Wizard</li>
                  <li>"I have no other secrets"..."Lars we can pay off your bartab"</li>
                  <li>Sparka thinks the Zhentarim are secretive and working in the background</li>
                  <li>Sparka like whoa Cecil who you working with</li>
                  <li>"Maybe you should ask better questions" Cecil says when pushed</li>
                  <li>NAT 1 Sparka history to know about the Harpers</li>
                  <li>Corpo knows that the College of the Heralds work with the Harpers...the Harpers are more open source magic...but unlike the College of the Heralds they are more focused on preventing Tyrants. </li>
                </ul>
                <li>Sparka wants to know who he is and if he takes the Zhents down a few pegs along the way that's cool</li>
                <li>Sparka tells them that Deep Gnome from Halia checked up on them</li>
              </ul>
              <li>Corpo inquires about The Teen situation </li>
              <li>Crew grabs the Mirna's hidden heirloom</li>
              <li>They try to check the corpses but the corpses are gone</li>
              <li>The crew heads out of town...Day and Half trip to Blackbriar. Decide why not to head there</li>
              <li>Corpo makes a potion of Greater Healer</li>
              <li>Make camp...make camp Sparka takes first watch</li>
              <ul>
                <li>Sparka was not overly religious growing up...they did the generic panetheon</li>
                <li>Sparka is getting into Waukeen like adopted parent sort of knew</li>
                <li>Without knowing casts Thrift's Wandeirng Post</li>
                <li>Sees Rico and realizes that he is a Merchant Mobster Pirate</li>
                <li>Says he is flying through the Air Elemental Plane...yells at children</li>
                <li>Sparka sells stuff and ends decent with Rico</li>
              </ul>
              <li>Sparka wakes up Corpo...was awake with his olive oil and Necrotic Penis</li>
              <ul>
                <li>Corpo hears a noise and tries throwing his Necrotic Phallus to get him to move</li>
                <li>You don't just blindly attack? Lars asks</li>
                <li>Ben doesn't roll a D20</li>
                <li>Finds a Goblin and it kills itself since they have killed all that they have captured</li>
                <li>Drags body to camp and just chills</li>
              </ul>
              <li>Lars is woken up and sees a dead Goblin</li>
              <ul>
                <li>Corpo like I second hand killed him by throwing a dick at him</li>
                <li>Lars is fairly perceptive...sips a drink listening to crickets</li>
              </ul>
              <li>Wakes up Sass</li>
              <ul>
                <li>Sass like wtf is this dead? Corpo hit it with a what??</li>
                <li>Enjoys a quiet nice night....though would be nicer if his love wasn't dead</li>
                <li>He has a slow quiet cry</li>
              </ul>
              <li>Wakes up Cecil</li>
              <ul>
                <li>Explains again that Corpo killed the goblin in a very fun game of telephone</li>
                <li>Bowser and Cecil hears something in the woods...</li>
                <li>NAT 20 Bowser Perception</li>
                <li>NAT 20 Cecil stealthily waking up everyone up</li>
                <li>NAT 20 Sparka initiative to put Goblin to sleep</li>
                <li>Corpo checks Goblin opens his mouth and removes the poison cap</li>
                <li>They saved the poison capsule</li>
                <li>Drag the goblin back to camp</li>
              </ul>
              <li>Interrogate the goblin</li>
              <ul>
                <li>Lars kicks the goblin in the ribs...doesn't kill it</li>
                <li>Corpo shows the capsule and reveals Dongula</li>
                <li>They ask why and where Avlod is</li>
                <li>Goblin is like no Avlod did not tell us where he is cause duh</li>
                <li>They decide to keep a prisoner ...was supposed to kill itself</li>
                <li>They like Avlod is near the ruins and wants to make a deal with them</li>
                <li>Lars is interesting in hearing a deal</li>
                <li>Corpo is tossing the tooth up in the air</li>
                <li>The gag him...Corpo brings out Count Dongula and gags Ichorcheeks with Dongula long ways</li>
                <li>Find out that Moldnose was other name.</li>
              </ul>
              <li>Head to Blackbriar </li>
              <ul>
                <li>Arrive in "No Kids Town"</li>
                <li>The Swagger into ghost town</li>
                <li>See a lemonade stand with old man...Lemonade 1cp Buy 6 for each of them and Ichorcheeks</li>
                <li>They get one for Ichorcheeks and take dick out of her mouth</li>
                <li>Old man with toothpick in his mouth says he won't trade business secrets to Lars when asked how many takers</li>
                <li>Says never had a Goblin have his lemonade...only ladies of the night...Corpo says he hasn't stopped giving it only to whores.</li>
                <li>He starts drinking lemonade out of the pitchers...running down his beard while he leans back</li>
                <li>Says his name is "None of ya business"...it's a family name</li>
                <li>need anything else? "Which way did the whores go?" Lars asks</li>
                <li>"We already gave him 6 whole copper! What more does he want?"</li>
                <li>Sells 10 regular arrows, sells a +1 arrow for 4gp after negotiating him down</li>
                <li>Ichorcheeks says she likes it...Corpo tells her to be nice</li>
              </ul>
              <li>Sass catches that tracks of Goblins, Hobgobs, and Wargs </li>
              <ul>
                <li>Heading in the two most likely directions</li>
                <li>Can tell at least half a dozen in each direction</li>
                <li>The middle path which is slowest path they did not go</li>
                <li>Cecil tells Sass to take Count Dongula out of Ichorcheeks mouth and asks what is up with that</li>
                <li>They pull a Robert Frost and take the road less traveled</li>
              </ul>
              <li>DM pulls out a map</li>
              <li>They approach and see a fire with a Hobgoblin sitting in front of the campfire.</li>
              <ul>
                <li>Ichor confirms that it is Avlod</li>
                <li>Cecil knows that Hobgoblins say "You can't conquer the world if no one trusts you"</li>
                <li>Avlod is sitting awaiting parley with his kettle on the fire</li>
                <li>Lars takes off his shoes to respect the space</li>
                <li>Cecil follows and mutters under his breath "Lars there's going to be wine"</li>
                <li>Avlod puts his sword and dagger just out of reach</li>
                <li>Corpo puts Count Dongula into the ground forever...</li>
                <li>Sass stands back out of the parley </li>
                <li>Lars straps axe to back...Cecil says leave weapons with Sass who stays back...notices sparks between him and the ax as he hands it off</li>
                <li>Sparka leaves weapons, Bowser disappears into his arms</li>
                <li>They see Hobgoblins with weapons at their feet standing back</li>
                <li>Sass names the Drake finally the Duchess to await what happens by Sparka cause she likes Sparka...purring rubbing by his legs</li>
                <li>Avlod opens parley in the name of Maglubiyet and his Legion the Crimson Legion</li>
                <li>Lars introduces himself as the Dragon. Avlod is like...well met the dragon</li>
                <li>Avlod pours the hot wine and sips it first to show it is not poisoned</li>
                <li>Says their interests are aligned...Sparka is like Cragmaw Castle</li>
                <li>King Grol is auctioning Gundren and the Map</li>
                <li>Avlod is disgusted with King Grol turning the Legion of Maglubiyet into Crime lords without honor</li>
                <li>"I see we share a disdain for those of lesser standing and subspecies" Corpo says </li>
                <li>Avlod says they can come to an accord (his honda)</li>
                <li>Avlod tells that King Grol has been working with the Redbrands, Black Spider, and Cult of the Dragon </li>
                <li>Cecil hears Avlod tells his Iron Devastator to watch Sass...</li>
                <li>Says that Grol has invited others to the Castle for a circle of </li>
                <ul>
                  <li>Black Spider</li>
                  <li>Cult of the Dragon</li>
                  <li>Arcane Brotherhood</li>
                  <li>Red Wizard(s)</li>
                  <ul>
                    <li>Says one is missing...looks at head on Lars' belt</li>
                  </ul>
                  <li>Newbrands </li>
                  <ul>
                    <li>They ask who organized under ("brass staff? Sass") some fools</li>
                  </ul>
                  <li>Elder Elemental Eye</li>
                  <ul>
                    <li>Cult believing in the primordials</li>
                    <li>Corpo knows they are dedicated to coming back into the Material Plane</li>
                  </ul>
                  <li>The Zhentarim declined</li>
                  <ul>
                    <li>Avlod hands over the invitation...Lars winks at Sparka...Sparka winks back</li>
                  </ul>
                </ul>
                <li>Cecil tells Sass message cantrip that the Devastator </li>
                <ul>
                  <li>Sass telepathically like...soooooooooo?</li>
                </ul>
                <li>Says Avlod has invited bard and a circus...will tell them where they are heading</li>
                <li>They ask Avlod what's in it for him?</li>
                <ul>
                  <li>He says he wants victory</li>
                </ul>
                <li>Sparka asks what he will help...are you using us to soften their defenses</li>
                <ul>
                  <li>he says..yes?</li>
                </ul>
                <li>Sparka asks for a pact </li>
                <ul>
                  <li>He cannot move against King Grol by its own</li>
                  <li>Avlod says he does not wish for the Hobgoblins...5 gold per living Hobgoblin</li>
                  <li>Says this is one of the few places between his world and this</li>
                  <li>Even if they fail...it will embarrass King Grol</li>
                  <li>Avlod wants Grol DEAD...so...</li>
                  <li>Avlod says his end no Goblinoids will attack Phanadlin or convoy bound for it 3 years </li>
                  <li>Sparka says also the Cave...rolls persuasion and passes he agrees</li>
                </ul>
                <li>They hand back Ichorcheeks We didn't kill the others...actually we did....</li>
                <li>He allows them through , says figured they were looking for the ruins</li>
                <li>He snaps fingers to have Iron Devastator put out the fire..Sparka create bonfires it to bring it back</li>
                <li>He moves out of the way...exaggeratedly to give them pass on</li>
                <li>Sparka asks if he is staying here...gestures to one of his soldiers and says they will inherit his accord</li>
                <li>Bugbear growls as they are leaving...Corpo grabs crotch..Bugbear drops pants</li>
                <li>Sass bows honorably, all Hobgobs bow back....</li>
              </ul>
              <li>They reach the ruins</li>
              <li>They smell the site before they get there with a burned Hamun Kost and 12 undead dead zombies as they arrive</li>
              <li>Sparka recalls that he took the wizard spell books</li>
              <li>Lars recalls that he pretended to be a zombie </li>
              <li>Corpo recalls that it was house diemondar motto and name that was needed to get the Doors open…the motto is said and it all opens</li>
              <li>It reveals an ancient hallway…rusty musty and mildew hall</li>
              <li>Small globes are on the wall… or lit and unknown</li>
              <li>They rusty door has the glyph of diemondar on the door…metal and rusted…a square metal panel </li>
              <li>The peeps are not familiar with the local ancient tech…pumps are ancient but above modern tech </li>
              <li>Sass figures out the pump…Cecil figures out that there is a blood lock </li>
              <li>Cecil pricks finger and puts blood on the lock nothing happens</li>
              <li>Sparka puts blood on the lock and it just opens.</li>
              <li>Sparka like more on that later…Corpo like hey you are surprised…</li>
              <li>It’s dark Sparka creates bonfire and they see plumbing…extrapolate what plumbing would be </li>
              <li>See a weird machine with odd instructions…follows crystal instructions and activates the machine…water starts flowing and the odd globes light like arch lamps </li>
              <li>They start down one way but rubble covered </li>
              <li>Follow path sparka water walks </li>
              <li>Enter round room and NAT 20 Sass figures out room was a  structure not original meant for living quarters, storeroom converted to place to crash </li>
              <li>Find water basin…NAT 20 Sparka notices that there are water openings and paths in pipes </li>
              <li>Lars does not have to take a shit to figure out where it is going </li>
              <li>Corpo debates sending bowser out then like whatever</li>
              <li>In a large room they see more pumps..rubble…and they see a thing</li>
              <li>NAT 1 pars to identify the GOLEM</li>
              <li>Sparka walks up and holds out his bloody hand and the golem checked…then ignored him </li>
              <li>Corpo tried walking over the line and the golem tried to track him down...Corpo steps back</li>
              <li>The Golem assumes a wrestling pose</li>
              <li>Lars attempts to pass off Sparka's blood on his hand as his...but Golem is not a fan</li>
              <li>Sparka looks for a mechanism to control him...find a panel, touches it with blood hand...it shuts down...touches it again it comes back up...toggles again</li>
              <li>Lars/Cecil catches the Eye of Ioun carved above a pipe on a wall</li>
              <li>Three...doors...they start checking...Sparka is orienteering where the rubble was...finds a machine and all follow</li>
              <li>Find room with tons of </li>
              <li>NAT 1 Cecil Arcana to ID scribbles</li>
              <li>Lars and Corpo recognize Chronurgy schools ()</li>
              <li>See four posts with crystal slots...one fell out....</li>
              <ul>
                <li>Sparka puts it back in and the area around the posts starts warping</li>
                <li>They see a blank scroll in the field...see an arm start reaching to start writing</li>
                <li>Corpo and Spark recognize that it is part Silver Elf and part Aasimar</li>
                <li>It is gibberish Draconic...but Sparka realizes it is his handwriting...it is in Halfling a cypher that Semla and Karvin would use</li>
                <li>Sparka can now read it</li>
                <li>Sparka reads it to them...see the letter</li>
                <li>Sees 6 tallies...one feminine silver elf arm...the rest look like the first one...but Sparka notices that the last arm is wearing the clothes he is wearing in his vision/dreams</li>
                <li>They debate taking the crystal or not....decide to check if more in the </li>
              </ul>
              <li>LEVEL 5! ROLL FOR HP</li>
              <li>Corpo swims in a basin...swims through pipe...finds a crystal and places it around his neck...nothing happens</li>
              <li>Corpo swims under the Ioun Eye pipe</li>
              <ul>
                <li>Finds blood door...goes back to grab team</li>
                <li>Gets Sparka...opens door and finds chest</li>
                <li>Find Ioun Stone of Reserve, Sustenance,  Historical Knowledge (Cecil takes that one)</li>
              </ul>
              <li>Corpo tries to explore pipe but it is darkness</li>
              <li>They enter a room and </li>
              <ul>
                <li>Corpo and Lars recognize a teleportation circle...</li>
                <li>But the chalk symbols are broken</li>
                <li>Would have to fix it to get it to work...know that the runes are the destination</li>
              </ul>
            </ul>

          </Collapsible>

          <Collapsible
            title="Ginobli the Most Endowed (#11 - 3/26)"
            toggle={this.collapseToggle.bind(this)}
            kind="session11"
            show={this.state.show}
          >
            <ul>
              <li> Morning, day and half from the Cragmaw Castle</li>
              <ul>
                <li> Could go straight to get there by nightfall</li>
                <li> meeting up with circus would get there on time</li>
                <li> Going cause Gundren is friend and had more detailed map of the inside of the Cave</li>
                <li> They know where it is from Reidoth...but not what Black Spider might be doing inside</li>
              </ul>
              <li> We remember that Sparka has the Ioun of Reserve</li>
              <li> NAT 20 Cecil Arcana Check on the Reality Crystals</li>
              <ul>
                <li> He knows that the Crystals are used are components  in Chronugry to alter time/reality or prevent it</li>
              </ul>
              <li> They take the crystals so that no one else can use them to read/destroy Sparka's self-addressed letter</li>
              <li> On the way out they complete the teleportation circle and have the sequence for the future</li>
              <li> They start planning...Lars debates if they should pretend that they captured Sass as the Zhentarim and brought him as exotic cuisine to eat</li>
              <li> They know that Corpo and Sass...stand out the most</li>
              <li> They get their flashback points</li>
              <li> Recall that the following groups are coming:</li>
              <ul>
                <li> Arcane Brotherhood Pirates</li>
                <li> Black Spider / Cult of the Dragon</li>
                <li> Elder Elemental Eye</li>
                <li> New Brands</li>
                <li> Zhentarim declined but party has their invite</li>
              </ul>
              <li> Corpo has vaguely heard of the circus name...</li>
              <ul>
                <li> Knows they might be sketchy since doing work for a Bugbear</li>
                <li> Probably rotates a lot of people</li>
              </ul>
              <li> Decide to have Sass go as a Zhent since he most obvious make him official</li>
              <li> Lars asks "Do we need to murder the circus?" Sparka and Cecil think "We don't need to murder the circus"</li>
              <ul>
                <li> They say if Circus is in small size, they could just buy off and replace them...</li>
              </ul>
              <li> Not sure if they trust carnies...smell like cabbage and Corpo got crabs from one</li>
              <li> Sparka thinks about taking the golem...but decides to leave it to protect it and come to get the teleportation circle later?</li>
              <li> NAT 1 Sass checking the trail "Don't worry about the footprints"</li>
              <li> Approach the carnies, see colorful wagon, loud </li>
              <li> Cecil sends Bowser up ahead to scout out...sees a tent as they set up some sort of brunch</li>
              <ul>
                <li> Sees a good number of people, not overly armed, fairly confident</li>
                <li> Bowser tries to check on races...whoa racist </li>
                <li> Bunch of </li>
                <li> Sees Maniere in her Ballerina outfit...see is...hot...a "Molotov Cocktease"</li>
                <li> Sees Girbonzo with his trained Owlbear</li>
              </ul>
              <li> They decide to wander up...</li>
              <li> Lars is like lets cover in mud and tell them don't go near cragmaw castle</li>
              <li> "How many of them fit in a 30-ft Cube?" Cecil meta-thinks</li>
              <li> Sparka and Sass decide to not let the carnies see them...so hangs back.</li>
              <li> Two groups hanging roughly 100ft so in range of Message Cantrip</li>
              <li> Sparka tells crew make a distraction if they see us...Lars is good to do that...Cecil is nervous at the thought of what Lars might come up with </li>
              <li> Carnie Crew approaches and Jimli the Tallest Dwarf (actually a human) says hi. Lars calls out that he is lacking a beard...he hyperventilates and runs away</li>
              <li> Say hi to Girbonzo, he is supposed to be Macho Man Randy but sounds like Hulk Hogan</li>
              <ul>
                <li> They ask where he got the Owl Bear....says he was drunk in Neverwinter alley and you just don't ask questions when people get you cool shit</li>
              </ul>
              <li> Enter Lady Carnival's tent</li>
              <ul>
                <li> See Kobold and various others</li>
                <li> Cecil NAT 20 Arcana to recognize Instrument of the Bard on the bard</li>
                <li> Maniere is twirling</li>
                <li> She asks who they are and Cecil says they want to join</li>
                <li> She asks what they can do</li>
                <li> "Clearly I am  a Bard" Cecil says</li>
                <li> Cecil starts playing his music, </li>
                <li> "I literally blow audiences away" Corpo then inquires about Maniere</li>
                <li> "I am Ginobli, the most well endowed of the dwarves" Lars also mentions his axe extends...he stands at attention...mentions a backroom audition later....</li>
                <li> She says that Ginboli will be taking his pants off so be ready....he says he is comfortable with that</li>
                <li> They see Elfis....white jumpsuit and eating a Fat Elvis Sandwhich</li>
                <li> They sort of insult Elfis...</li>
                <li> Lady bard says we can take another Bard if he does't cut in</li>
                <li> Lady Carnival does not just take tips</li>
                <li> Confer if the proper term</li>
                <li> Lady Carnival accepts them if she keeps 100% of the proceeds...</li>
                <li> Lars'...performance...is...impressive?</li>
                <li> Corpo's intro.....says he will blow audiences away</li>
                <li> Corpo asks about Maniere and keeps telling they can put on a wowing performance</li>
                <li> "Everyone feels dirty but than you"</li>
                <li> Corpo shows her the Netherese Crystal</li>
                <li> Corpo does an illusion to put a coin in her purse...she is like cool money</li>
                <li> Corpo does a trick....sword swallowing...what...the....</li>
                <li> She is oddly impressed</li>
                <li> "That was oddly arousing...you are right...."</li>
                <li> Cecil asked where going...confidentiality is important to her clients....can make extra by blowing the audience away and she is keeping the money</li>
                <li> Sass asked Sparka if they stroking weapons? Looks like they have been accepted</li>
              </ul>
              <li> Sass and Sparka are not super stealthy but not enough for carnies to notice</li>
              <li> Ben keeps farting</li>
              <li> They meet Fuboi...Corpo messes with him</li>
              <ul>
                <li> He says the big Genie is named Jonathan...dude told him his fortune</li>
                <li> Fuboi says he can't be 10 feet of Maniere since he complimented her calves and it made her "feel uncomfortable" </li>
                <li> Lars says you need to compliment a lady Genasi on her glutes...just grab on</li>
                <li> Fuboi says Maniere likes the color purple, dancing, and sleeping</li>
                <li> Fuboi has been with them for a week or a year....got paid once and got room and board</li>
                <li> "Fuboi and I think you and I have a promising double act together"</li>
                <li> "Fuboi, you don't avoid as many STIs as I have...." Corpo</li>
                <li> Fuboi is increasingly pathetic </li>
                <li> "I am beginning to feel bad about taking advantage of him" Corpo</li>
                <li> Lars guesses that Fuboi got punched in the head</li>
                <li> Corpo tells Fuboi that he is awesome</li>
                <li> Fuboi tells them the horse farts and they play game "Throw Rocks" and Lars plays with him</li>
                <li> Lars Throw Rocks NAT 20 Athletics Fuboi asks "Are you like god?" Lars says yes...then I am the dragon</li>
              </ul>
              <li> Corpo goes to land with style next to Maniere </li>
              <ul>
                <li> CORPO NAT 1 PERFORMANCE to land next to her lands on her leg</li>
                <li> Compound breaks her leg...tries to heal her with his bracers</li>
                <li> Corpo water boards her with a potion of greater healing while she is screaming</li>
                <li> "Now that it comes to now this is super awkward...Corpo is a lot of talk..."</li>
                <li> Corpo covers her mouth and says sshhhhhhh</li>
                <li> He sets her leg</li>
                <li> "I realize that a lot happened and we probably avoided a lot of story deviation" </li>
                <li> Corpo asks what a Genasi like you are doing in a troupe like this....she says you can only burn the house down so many times....he's like I killed my mom</li>
                <li> I know what it is like to be cast out...I know I just cast you out...</li>
                <li>Corpo is like wind and fire amplify....we could still perform together....</li>
                <li>Corpo tells the fart wagon crew it went swimmingly...he broke her leg</li>
                <li>Fuboi is that is how you swim? "Fuboi...shut the fuck up"</li>
              </ul>
              <li>Sass and Sparka are like 100 yards away in woods...don't lit a fire</li>
              <li>Cecil goes to look....Sass telepathically says "....we're here......what happened with Corpo..."</li>
              <li>Cecil goes to talk to Layla Saffavi </li>
              <ul>
                <li>Been their only for a few days</li>
                <li>Says Elfish is the sharknado...wagon wreck...says that theoretically like a dude hitting on a girl and breaking her leg</li>
                <li>She says that she doesn't know where they are going...but Cecil gets vibe she is hiding something</li>
                <li>Cecil pushes and says she has magic Lyre but does not have a name. Elvish is terrible a music but everyone knows his name</li>
                <li>Getting exposure at a Slave auction....not a good idea? She feels bad but doesn't want to back out</li>
                <li>She is like ohhh really??? They start Dueling Lyres together to play</li>
                <li>Sass claims he starts rapping in the dark</li>
                <li>Maniere starts to dance then limps up... "She is fucking healed" Corpo bitter</li>
                <li>Cecil starts Minor Illusions and she does too...amazing beats</li>
                <li>The whole group is jamming with them</li>
                <li>Cecil is like no more questions</li>
              </ul>
              <li>Lars approaches Maniere</li>
              <ul>
                <li>He apologizes on behalf of his Awkward Genasi friend </li>
                <li>Lars asks for some vodka from Maniere</li>
                <li>Lars says Fuboi is simple like Corpo</li>
                <li>She asks where he is from...he says a Dworphan</li>
                <li>He gets a drink, strikes out, but implies Corpo is simple</li>
              </ul>
              <li>Cecil clarifies that his Ioun Stone is not orbiting around his head...does not get benefit but is incognito</li>
              <li>Sass and Sparka sitting in pitch dark and have a convesration</li>
              <ul>
                <li>"We need to have a conversation about me accusing you of being agent of Cloudchaser" -Sparka</li>
                <li>"Did you see me cry back there?" -Sass "I saw my love ones get kill-ified</li>
                <li>Sparka says that his visions happened in a different timeline...Sass is like whaaaaaa</li>
                <li>Sparka says it will take a while to understand it...the choices they make are quite important...we can do great evil or great good</li>
                <li>They say they trust each other...Sass trust Sparka more than others</li>
                <li>They say we are going to take down Cloudchaser together</li>
              </ul>
              <li>Corpo goes to Lady Carnival (Is like screw this Sass and Sparka conversation)</li>
              <ul>
                <li>Gusts open tent</li>
                <li>Asks what she knows about the audience...she teaseingly lies</li>
                <li>He says his bard is good but needs to know it</li>
                <li>Corpo says his group does the whirlybird...and prudish audiences are not a fan</li>
                <li>She is like well I love the whirlybird...I buy the whirlybird in Neverwinter so much I am the Whirlybird queen</li>
                <li>"The Whirlybird gets the worm" Sparka mutters to self in the dark</li>
                <li>Corpo asks if she is afraid of who she is working for...she says she only fears herself in the mirror on a bad day</li>
                <li>He says she looks fine....get a Dirty 20</li>
                <li>Corpo gets a bottle of wine and pours while staring at her...we make Ben roll and NAT 20 DEX to pour wine</li>
                <li>He pours wine like a super maniac...refills her wine glass too...she says close the door</li>
                <li>The others hear loud noises..."No not in there"</li>
              </ul>
              <li>Sparka has dream about the Cloudchaser dream again...</li>
              <ul>
                <li>finds detail of him having two eyes to know it is a different timeline where Sass was Cloudchaser's Champion then now when Sass shot out White Dragon </li>
                <li>He also notices that his skin and vambraces look like that last iteration...so confirms vision is last try</li>
              </ul>
              <li>Mike had fallen asleep last time and is like wtf multiple timelines </li>
              <li>Corpo wakes up under a wagon hungover but sore.</li>
              <li>Sparka and Sass go ahead to get to the Party for Qoqtail hour</li>
              <li>Sass sends a telepathic message of "CawCaw" in Draconic to tell them all good </li>
              <li>Troupe starts walking, Corpo found and grabbed his clothes as the ten was taken down</li>
              <li>The party is walking near the fart wagon...not on it for obviously reasons</li>
              <li>Sparka and Sass have a cover story....Zhentarim members....they act undercover</li>
              <ul>
                <li>They just are traveling salesmen looking for slaves and info on the map</li>
              </ul>
              <li>Hobgoblin guard sees them and asks for invitation</li>
              <ul>
                <li>Sparka says he is Akraps from Phandalin and Otto</li>
                <li>Sass speaks in German accent as Otto and the Hobgob tries speaking back</li>
                <li>Hobgob is sorta depressed at inviting them to refreshments</li>
                <li>Akraps is having none of this...can't be bothered...he's the shit</li>
              </ul>
              <li>Cecil has a quick team huddle saying be on the lookout for a distraction to get the hell out of here</li>
              <li>Lars asks for the Disguise Kit since he promised shit...Corpo says it will not be a minor illusion</li>
              <li>Cecil realizes that that Leia Saffavi knows about magic but Lady Carnival might not? She might notice the minor illusion</li>
              <li>They approach the castle lot of me setting up the map and setting the scene</li>
              <li>Lars mentions that he has...sown oats for possible children out there</li>
              <li>See pirates, red wizard, bandits all hanging out while goblins serve drinks</li>
              <li>The Phandalin Five realizes there are a lot of people at the party....more than they can kill?</li>
              <li>The crier asks their names </li>
              <ul>
                <li>Sparka says "The Zhentarim only go by first names"</li>
                <li>"The Akraps of Phandalin and THE Otto"</li>
              </ul>
              <li>They see Pirates, Thugs, and some sort of odd clerics/monks</li>
              <li>Sparka takes some Hors De Vours</li>
              <li>Sass take a drink despite being a recovering Alcoholic</li>
              <li>Sparka talks to Zephyr</li>
              <ul>
                <li>Says he and associate are of the Elder Elemental Eye</li>
                <li>He is flippant in tone about the event so Sparka matches</li>
                <li>Says he is here less for slaves, more for villain networking and other oppurtunities to increase position since they are down this quarter</li>
                <li>Sparka: "Everyone wants to call us the bad guys"</li>
                <li>Zephyr: "Just because we want to bring back the Elemental Princes and rule the material plane we are 'the bad guys'</li>
                <li>Sparka says just there for the map. Asks about him. Says they take slaves in to indoctrinate them but here to see what is happening</li>
                <li>Has never dealt with this Grol...says Grol is using an info broker as he tries to be a crime lord</li>
                <li>Sparka asks where the Cult is today...he says is all around trying to find someone</li>
                <li>Reveals he hired a goofy Astral Plane pirate to find this Air Genasi</li>
                <li>Sparka asks for reward....200 Gold easy for info on his place...much more for capture</li>
                <li>Sparka pushes to find more info about them looking for Corpo....pushes lie that the Zhentarim are super helpful </li>
                <li>He relents and says that it comes from the top with a Yan-C-Bin...Sparka is not aware who that is</li>
                <li>Says that the Fugitive goes by many names...Mojo Pants....but also Corpo Fugit</li>
                <li>Zephyr super excited for the circus to come! Sparka like...uhhhh....shit</li>
              </ul>
              <li>Sass goes to talk to pirates</li>
              <ul>
                <li>They tell him he is yoked</li>
                <li>ask why he is here...like you ...slaves</li>
                <li>Not here to cause waves...here to harness the waves and make gold</li>
                <li>Trying to establish the aRcane bRotheRhood...telling everyone that the legends around Port Llast are just legends. (Black Storm)</li>
                <li>Sass says yes I am wealthy that's why they call me "The Otto" </li>
                <li>"The Otto" is also a business man</li>
                <li>The Otto tells the pirate that Akraps is fine...I roll, the pirate is gay. Goes to talk to Sparka</li>
                <li></li>
              </ul>
              <li>"There is 40 percent that isn't alcohol" Ryan tells Mike IRL</li>
              <li>The Red Wizard shows up by himself...alone....</li>
              <li>Sparka clarifies that the he has Glasstaff's staff out and about</li>
              <li>Newbrands come to talk to Sparka after seeing his staff</li>
              <ul>
                <li>Sparka asks why the loses are the Newbrands and laughs in his face</li>
                <li>Sparka treats them as total crap</li>
                <li>Sparka says he is from Phandalin and got the Staff</li>
                <li>The guy says he doesn't see any Zhentarim...Sparka like I AM THE ZHENTARIM</li>
                <li>Sparka accuses them of stealing the invite...which they say eh someone went and gave Glasstaff's things to someone....</li>
                <li>Sparka leans in says Halia isn't the only Zhentarim around Phandalin...</li>
                <li>Guy buys it and Sparka orders him to spill his beans and Boris says I await your command</li>
                <li>Sparka sends Lehqsi to get a drink. Tells Boris to Halia is failing and Boris needs to report to him on Halia's dealings</li>
                <li>Sparka's Deception check gets Boris to think he should play both sides.</li>
              </ul>
              <li>As they start hearing Prince Ali music for the circus, a Drow and Purple Robed Figure (Hadrian Solonna)</li>
              <ul>
                <li>Hadrian looks fancy and cocky and shit, dumps crappy wine out</li>
              </ul>
              <li>Sparka like holy crap we need to let Corpo know people looking for him</li>
              <li>Sass "whips it out" to take a Wiz Khalifa...telepathy to Lars to warn him and to hide Glassstaff's head</li>
              <li>Corpo is like I have shown multiple talents, neither involving Air...asks Corpo to give him a Fu-Hawke with frosted tips</li>
              <li>Cecil like ok I do not do anything that he wanted...I make him look like a Halfling and his hairy feet with frosted tips </li>
              <li>Sparka hears "What are you doing here? There is danger here?" in his head</li>
              <ul>
                <li>Notices one of the Hobgoblins is looking at him while...knows telepathy has line of sight...looks a little off</li>
                <li>Hears "No No No No" as he walks forward..pretends to find Hours De Ourvs grabs Lehqsi</li>
                <li>Starts talking to Lehqsi as proxy....totally ignoring what Lehqsi is saying</li>
                <li>"To brutally kill Zephyr" Sparka interrupts Lehqsi and turns out Black Storm is there and knows about Sparka</li>
                <li>"But where will you be after this" "On the wind...killing all who wronged me and my daughter...protect my grandson"</li>
                <li>Lehqsi talks about being a Libra...different size toes</li>
                <li>Sparka notices a fly hit invisible thing around the hobgob...Disguise Kit</li>
                <li>"I feel like I need to more..." Lehqsi says she does "I do Taebo..." "Get Corpo out of here" "Yes my body is my temple" from Lehqsi "Get Corpo out of here" </li>
                <li>"Sooner the better Lehqsi...gust of wind might blow in in the next few minutes" Hobgob sighs</li>
                <li>Sparka runs to Zephyr and tells him my information..."which is never wrong"  "He is part of Phandalin Five and heading North...need to leave now to catch him...All the info I have first one is free" Zephyr is convinced and heads out to get Corpo Fugit and the Phandalin Five</li>
                <li>Guy is heading out down the road...Sparka sees </li>
                <li>Corpo hears Black Storm in his head say to hide...give middle finger to air...she is like excuse me...but he is like I look like and idiot. </li>
              </ul>
              <li>King Grol starts the shingdig</li>
              <ul>
                <li>appears atop the battlements of the castle</li>
                <li>"Time to start...the meeting...err.....and have entertainment..."</li>
                <li>Hadrian Solonna starts a confrontational dinner spiel about ooooh going back on our deal</li>
                <li>Says he is Cult of Dragon with Black Spider...representing his father</li>
                <li>"Perhaps the circus is something for me to not kill you..."</li>
              </ul>
              <li>Circus Arrives</li>
              <ul>
                <li>Lars runs ahead and tells announcer it is Lady Carvinale and Ginboli the most endowed </li>
                <li>Lars sees Hadrian and recognizes him as the Gold Elf who tried to bribe him for Info on Cecil's family</li>
                <li>NAT 1 Lars Insight to see if Hadrian recognizes him...Lars thinks no and winks at him</li>
                <li>Sparka and Sass decide good idea for Sass to tell Cecil his cousin </li>
                <li>"So yoked so awake"</li>
                <li>Cecil is back so quick disguises himself very well</li>
              </ul>
              <li>Lady Carnival is looking for Corpo to start but can't find him....he is a full sized halfling with frosted tips</li>
              <ul>
                <li> He says people don't always react well to Genasi so he is pretending to be woke and acceptable </li>
                <li>She is like I reacted well...he lightly taps her ass she says you are paying for that tonight</li>
                <li>Corpo says..."leather"....rather "pleather" we aren't rich</li>
              </ul>
              <li>Sparka is like what it is a garden party?</li>
              <li>Sass notices that a door to the castle is less watched with the circus here</li>
              <li>Corpo starts his performance</li>
              <ul>
                <li>"SINCE TIME IMMEMORIAL MAN SOUGHT TO CONQUER THE STARS...BUT INSTEAD WE CONQUERED YOUR SENSES OF HUMOR. TODAY WE PROUDLY PRESENT LADY CARNIVAL'S CRAZY CARNIVAL CIRCUS OF CURIOS AND CURIOSITIES. WE PROMISE TO BEWILDER YOUR EYE TO PLEASURE THE SENSES AND LEAVE YOU IN A STATE OF PURE ORGASMIC ECTASY. WE GOT BARDS, WE GOT THE MOST ENDOWED GUY THIS SIDE OF THE CONTINENT, AND WE GOT A KINDA GIMPY FIRE GENASI" (could have been you girl)</li>
                <li>Does acrobatics super good</li>
              </ul>
              <li>Sass and Corpo sneak into the castle </li>
              <ul>
                <li>Door has no traps and is unlocked.</li>
                <li>Hear a goblin but duck to hide as it passes...speaking in Goblinoid which neither understand</li>
              </ul>
              <li>Lady Carnival tells Lars she needs a big next performance...Corpo lit a huge fire</li>
              <ul>
                <li>Lars says I got you...she says....I know</li>
                <li>Cecil casting minor illusion so DAMN he is the most endowed</li>
              </ul>
              <li>Sparka and Sass realize the design of castle one corner would have the most likely place for the leader's quarters</li>
              <li>Sass hears noise at the door, open the door anyway and see Hobgoblins inside...pretend to be looking for the bathroom </li>
              <ul>
                <li>"This is the lavatory?"</li>
                <li>"Who Sass did we get our invitations form?" "You mean...THE Otto?" "Avlod"</li>
                <li>Sparka name drops Avlod...they freeze...Sparka stands off</li>
                <li>They are like we took oath to Grol not Avlod</li>
                <li>Gets a 24 Deception...one tells the others to leave he has it...</li>
                <li>He makes comment about oaths...</li>
                <li>Sparka asks what he knows...he says he isn't sure...then he goes out</li>
                <li>"I scan the room for anything of value....I forgot about Gundren" Sparka </li>
                <li>Sass hears animal at the door...it is a female but he hears it</li>
                <li>"I hear it's female groans" Sass talking about the wolf</li>
                <li>Hear a second wolf</li>
                <li>They check the other door...hear nothing</li>
                <li>Go back to wolf door...Sparka casts sleep into the room then burst open door</li>
                <li>One wolf falls down...Sass double shoots one of them</li>
                <li>Sparka goes to cast firebolt and sends out a bead of fire instead.....FIREBALL</li>
                <li>Sass is like how did you miss he was ten feet in front....</li>
                <li>Sparka kills the shot wolf in a conflagration drowning in fire</li>
                <li>Sleeping wolf is not dead...Firebolt to the face but on death's door</li>
                <li>Sass follow up shoots arrow down it's throat as it goes to howl....splits it in two from uvula to its anus ROBIN HOOD the WOLF</li>
                <li>Blood puts out the fire...neat</li>
                <li> Gundren is in corner in and out unconscious due to being beaten</li>
                <li>Sass gives Gundren a heal...he says the map</li>
                <li>Sparka finds the treasure under Grols bed along with the map</li>
                <li>Gundren surprised by wolf split in half .Sass asks if he wants already cooked Wolf </li>
                <li>NAT 20 Sparka luck check to see if he can get out of the Rubble at back of his latrine</li>
                <li>The three slip out north to try to escape whether or not guards are there depends on....</li>
              </ul>
              <ul>
                <li>Ginboli walks out..."Ladies and Gentlemen...mostly gentlemen, which makes this is weird.....I am Ginboli the most Endowed, note I did not endowed I said the most. Prepare to feast your eyes and be amazed. My legend proceeds me, tales will follow me. But live in the moment" And Ginobli drops them.</li>
                <li>It is.....incredible. The distraction works well enough for Sparka and Sass to use a FLASHBACK point to have prepared a hide spot to stash Gundren</li>
                <li>SASS talking with Goblins about how does it work..biologically should be impossible </li>
                <li>Then he shimmies and twirls. Says he is available after but Lady Carnivale afterwards says nooooope</li>
              </ul>
              <li>Cecil is next</li>
              <ul>
                <li>Leila says she will back up Cecil...and he tokes the pipe of remembrance </li>
                <li>I cannot write it out it is amazing...says the tale of Yeemik and Blargh but changes the names</li>
                <li>Bowser flies out when he sings bout Venomfang</li>
                <li>Grol is like was that...Blargh...? Cecil says he just heard it in a Tavern</li>
                <li>I am rolling like shit</li>
                <li>Grol is like...you met the Phandalin Five (Ben plays Avengers)</li>
              </ul>
              <li>FLASHBACK to say non-Cecil group Stealth away during the insane performance</li>
              <li>Hadrian takes off his mask and seems interested in Cecil...goes to talk to Grol...</li>
              <li>Elvish is up next...Cecil makes a disguise as he runs away</li>
              <ul>
                <li>Hears someone yell at Hadrian how will you deal with the Phandalin Five?</li>
                <li>He says oh they will be heading back to the Phandalin after what he has done with it</li>
              </ul>
              <li>Meet up with Avlod</li>
              <ul>
                <li>They shamed Grol by stealing Gundren </li>
                <li>They ALSO had Grol pay for a Dwarf to twirl a giant a dick around</li>
                <li>Says he hopes to either die by their side or by their blade, Worth of enemy or ally...that is it</li>
                <li>Pays them 30 gold for keeping Hobgobs alive</li>
                <li>Concord is accepted...will not attack Phandalin or the Cave for 3 years</li>
                <li>Ask if he is taking over...he says...now as they see camo Hobgobs stalking towards castle</li>
                <li>Sparka asks about Zephyr...Avlod shows hung from a tree by entrails</li>
                <li>NOTHING IS IN POCKETS SPARKA...just a kidney</li>
                <li>Cecil asks about Cult of the Dragon...Avlod says they are a threat...trying to take over the world..he can't rule it if they do</li>
                <li>Cecil is convincing that they could ally in the future...Avlod says contact his man he is leaving</li>
                <li>Waarario "it's a family name" "WAAAAAAAA"</li>
              </ul>
              <li>Sparka has a dream of Wildon hanging from the gallows as Harbin Wester yells criminal for associating with the Phandalin Five! (Corpo wakes up slightly aroused)</li>
              <li>Party has a map of Wave Echo where they mining things are</li>
              <li>Elmar Brathen has a crystal around his neck....but they decide to go to Wave Echo since Black Spider will be digging in</li>
              <li>Need to talk about Cecil's relations...</li>
              <li>Need to talk about Corpo and his grandma..."A fable from my childhood who also is your grandmother" SO MUCH for being transparent like Lars</li>
              <li>Lars was the most transparent</li>
              <li>Maternity Leave is for DnD</li>
            </ul>
          </Collapsible>

          <Collapsible
            title="The Echoing Waves (#12 - 5/6)"
            toggle={this.collapseToggle.bind(this)}
            kind="session12"
            show={this.state.show}
          >
            <ul>
              <li>Sparka mentions to Corpo about Yan-C-Bin...Corpo knows how he is prince of Elder Elemental Air, and his mother forbid him from saying the name ever.</li>
              <li>Avlod rides up riding on a Tiger</li>
              <ul>
                <li>Bring the Hobgoblin that Sparka convinced to betray</li>
                <li>Wears Grol's head on his belt</li>
                <li>Avlod asks if Sparka magically manipulated the Hobgobs mind</li>
                <li>Avlod mentions he found some carnies....</li>
                <li>Sparka lies that he charmed his mind...Avlod buys it</li>
                <li>Avlod does not kill but casts the Hobgob out, and tosses the holy symbol to Sass and says to release him from his oath when he has earned it</li>
                <li>Avlod says that most dignitaries escaped...but they captured an incompetent New Brand named Lehqqxsi</li>
                <li>Avlod asks if he should release the carnies or kill them...Corpo is like maybe kill them...</li>
                <li>Corpo says Lady Carnival did many things wrong</li>
                <li>Party like just let em go.</li>
                <li>Avlod rides away</li>
              </ul>
              <li>Hobgoblin who they don't ask for his name...openly scheme on what to do with him</li>
              <li>Party continues and heads to sleep...but Sparka is like Hey Thrift's Wandering Post</li>
              <ul>
                <li>Gets Wemnetan al-Fakhuwr in the Elemental Plane of Earth</li>
                <li>Sparka recognizes the Elemental Plane of Earth</li>
                <li>Sparka buys two potions</li>
                <li>Has done business with Rico</li>
                <li>Very quick and straight to the point...sorta surly</li>
                <li>Wishes to buy finished goods..pays extra for a goblet</li>
                <li>Sparka asks about Yan-C-Bin...mentions that Yan-C-Bin is looking for a friend</li>
                <li>Wemnetan is like you could make some good money that way</li>
                <li>Wemnetan is like we make good business deals with Yan's people....Wemnetan asks to buy Corpo...Sparka hangs up</li>
              </ul>
              <li>NAT 20 Sass survival check to find the trail leading away from Phandalin into the woods</li>
              <ul>
                <li>Follows the path</li>
                <li>Party decides maybe the send Gundren and the Hobgob back to Phandalin </li>
                <li>Sass finds a cellar door off the path</li>
                <li>NAT 20 Sass Investigate for traps around the trap door</li>
                <li>Finds ten foot hole with a figure in the bottom whimpering and crying don't hurt me</li>
                <li>They see it is Elsa...confirm none have bed her yet</li>
                <li>Sass declare that he is a horny Dragonborn</li>
                <li>Vigorouss discussion about Dragonborn genitalia</li>
                <li>She sees Corpo and he starts freaking the hell out</li>
                <li>Lars lowers halberd for her to grab, and twists it to pull her up into his arms...</li>
                <li>Sparka uses magic to clean her off</li>
                <li>Corpo is like I think she is confused...Sprak is like keep telling yourself that </li>
                <li>Sparka asks how long...she says two weeks...longer than Corpo has been near them</li>
                <li>Sass wonders if Shapeshifter...figure out that this Elsa is not the Elsa they know</li>
                <li>Figure out they walked into the bar and slapped the ass of a shapeshifter</li>
                <li>Recall that Hadrian Solonna shouted his agents in Phandalin would make them pay</li>
                <li>Lars says in fairness we didn't know Corpo when she went into this hole</li>
                <li>Corpo asks if he said anything to her...she relates that the Shapeshifter was trying to talk through her to them</li>
                <li>The shapeshifter's said throug her when you get back you'll pay...I'll be meeting a meal in the bar, who else will be missing?</li>
                <li>Lars mentioning burning down the bar, Sparka like whoa Small Business owner no way. Sass thinks they might have property insurance</li>
                <li>They debate what to do...after debating what to do decide to have her Gundren and mystery Hobgoblin in the woods</li>
                <li>Corpo debates "scarecrow-ing" her</li>
                <li>THE PARTY SERIOUSLY DEBATES PUTTING HER BACK IN THE HOLE</li>
                <li>Lars is the source of reason...has them camp in the woods</li>
                <li>Sass orders mystery Hobgoblin to push shapeshifter into the hole</li>
                <li>Sass resets the trap</li>
              </ul>
              <li>Party goes to Wave Echo Cave</li>
              <li>Gundren says brother Tharden and Nundro</li>
              <li>Sass sees Bugbear, spiders, etc tracks...Lars relates that Black Spider were like Drow Special Ops</li>
              <li>They enter the entrance of the cave...see a dead Dwarf Body amongst ton of mining supplies</li>
              <ul>
                <li>Supplies are not worth a ton...but that is still something to Sparka</li>
              </ul>
              <li>They hear a low roar...Sass and Sparka can tell it is water coming from the North East of the cave...a Wave Echo every two minutes. Sass can tell there is a spring that boils up and flows out</li>
              <li>Every two minutes will muffle some sound</li>
              <li>Sparka starts casting create bonfire</li>
              <li>They find Boots of Striding and Springing...Lars puts them on...has that AND his ring of jumping</li>
              <li>Climb down the rope ladder as Sparka moves the bonfire</li>
              <li>The group loves blackberry mandarin le croi</li>
              <li>They use last flashback point to have Gundren have explained brothers names...figure out Tharden is dead</li>
              <li>Lars is in front twisting and untwisting his ax</li>
              <li>Duchess goes with Lars while Glass Cannons hang in the back</li>
              <li>Lars is like hey dwarf skeletons as he enters a chamber...Sass like this distress you being your kin? Lars like my kin all died along time ago.</li>
              <li>ROLL INITIATIVE! Striges Dive Bomb</li>
              <ul>
                <li>NAT 20 Corpo Initiative</li>
                <li>5 Striges dive bomb Lars...3 hit and suck his blood 16 Damage</li>
                <li>5 Striges dive bomb Corpo...2 hit and suck his blood 9 Damage</li>
                <li>5 Striges dive bomb Sass...4 hit and suck his blood 22 Damage</li>
                <li>Corpo Quarter Staff swings killing 4 of them (8 damage)</li>
                <li>Corpo punch kills 3 of them for 7 damage hanging around Lars</li>
                <li>Corpo Flurry of blows to kill 3 of them for 6 damage around Sass</li>
                <li>Lars head butt for 8 damage, kills 2 of them around Sass</li>
                <li>Cloud around Lars dive bombs him...but misses</li>
                <li>Cecil VICIOUS MOCKERY that they suck at not sucking blood. Kills it 2 points damage</li>
                <li>1 digs into Corpos and does 7 damage</li>
                <li>Sparka shoots firebolt but misses</li>
                <li>Sass attacks with short swords, kills two of them ends combat</li>
              </ul>
              <li>They move to bottom of the cave and find the original entrance</li>
              <li>See the two side doors....two perception checks</li>
              <ul>
                <li>NAT 1 Sass listened to West door</li>
                <li>Sparka heard nothing at his door</li>
              </ul>
              <li>Go into the Assayer's office, see dead Gnomes and Orcs</li>
              <ul>
                <li>Sparka moves bonfire inside the office, sees lockbox while telling Cecil there are papers</li>
                <li>NAT 1 Cecil thieves tools gets them jammed in the chest</li>
                <li>Corpo uses acid punch to harm the chest and gets Cecil's lockpick out</li>
                <li>NAT 20 Corpo acid punches open the chest entirely</li>
                <li>Chest is revealed to have money inside of it (Sparka notes it)</li>
                <li>Lars sees the papers but they are boring old records..nothing about killing things</li>
              </ul>
              <li>Go to other door, Sass is like nope didn't hear anything...</li>
              <li>Go into the Assayer's office, see dead Gnomes and Orcs</li>
              <ul>
                <li>Sparka moves bonfire inside the office, sees lockbox while telling Cecil there are papers</li>
                <li>NAT 1 Cecil thieves tools gets them jammed in the chest</li>
                <li>Corpo uses acid punch to harm the chest and gets Cecil's lockpick out</li>
                <li>NAT 20 Corpo acid punches open the chest entirely</li>
                <li>Chest is revealed to have money inside of it (Sparka notes it)</li>
                <li>Lars sees the papers but they are boring old records..nothing about killing things</li>
              </ul>
              <li>Go to other door, Sass is like nope didn't hear anything...</li>
              <li>They open door Sass thought was not anything to worry about</li>
              <ul>
                <li>See like a locker room for guards...see bunch of bones....and MInotaur bones</li>
                <li>The bones start forming...PCs get surprise round</li>
                <li>Sass hits with ensnaring strike, hits one forming skeleton and ensnares it for. Traps it and does 10 damage</li>
                <li>Sparka does twin Earthen Grasp and uses Shadowfell Shard...skeleton who failed save takes 16 damage</li>
                <li>Sparka shoves back with telekinesis</li>
                <li>Sass forgot an attack, to hit with longbow again for 6</li>
                <li>Corpo activates dolphin tattoo</li>
                <li>Corpo attacks with bludgeoning Staff 22</li>
                <li>Corpo punches for 10 </li>
                <li>NAT 20 Corpo 2nd bludgeoning for 36</li>
                <li>Corpo...kills minotaur by yelling Dick Train Express by reaching in and pulling his pelvic bone apart and then grinds his bones to dust with foot</li>
                <li>Cecil Hexes the Minotaur who isn't restrained</li>
                <li>Cecil does Vicious Mockery but it fails</li>
                <li>Lars whips out Divine Blessing for Earthen Eruption...points ax at the two and stomps foot does 58 damage to both of them</li>
                <li>Lars grabs his crotch and taunts</li>
                <li>Sass kill second one....straight through skull, pops off and mounts it on the wall, his hand still in the hand</li>
                <li>Sass shoots last one...hitting in the axe and into him and it shatters</li>
                <li>Lars puts one of the heads on his head</li>
                <li>Corpo takes a pelvic bone to kill future things</li>
                <li>They walk into a cavern with mushrooms...</li>
                <li>NAT 1 Lars Nature check to identify poison mushrooms...look so yummy....</li>
              </ul>
            </ul>
          </Collapsible>

          <Collapsible
            title="A Deadly Encounter...FOR THEM! (#13 - 5/27)"
            toggle={this.collapseToggle.bind(this)}
            kind="session13"
            show={this.state.show}
          >
            <ul>
              <li>Lars sees all sorts of massive mushrooms, EVERYWHERE</li>
              <ul>
                <li>This cavern is glowing with mushroom light</li>
                <li>Some edible mushrooms 1 HP</li>
                <li>Lars triggers a mushroom cloud, he coughs it off</li>
                <li>Corpo blows hard to stop the poison cloud from spreading to him</li>
                <li>NAT 1 Lars Survival check to harvest the shrooms...loses 1 of the shrooms</li>
                <li>The poison cloud dissipates slowly as Lars just like basks inside it</li>
                <li>While it does not dissipate, Lars looks for other mushrooms, finding a poison mushroom DC 15 Con Save</li>
                <li>Poison dissipates and they group moves on</li>
                <li>Sass notices that tracks stopped at the cave, as if the shrooms scared off other people</li>
                <li>Corpo openly wonders if they should light the shrooms on fire</li>
                <li>Sparka lights some on fire....but nasty colored smoke. </li>
                <li>"I AM THE DRAGON" Lars yells while breathing in poison fumes</li>
                <li>Sparka puts fire out</li>
                <li>Sass is doing Chin-Ups in the corner</li>
              </ul>
              <li>Party moves north into the large cavern with the multiple ruined buildings</li>
              <ul>
                <li>Sass is bringing up rear making sure now one sneaks up on them</li>
                <li>Covered with dwarven, orcish, and gnomish skeletons</li>
                <li>Buildings have structural damage from the fight</li>
                <li>Ceiling is covered with geodes, gorgeous starlight shimmering off Sparka's cantrip</li>
                <li>Dozens of skeletons crushed under debris</li>
                <li>Two standing structures, they notice doors are human, not Dwarven size</li>
                <li>Door appears cracked...door is not wood since original fire would have taken it out</li>
                <li>Cecil notices that a magic aura is building up north of them at the other building</li>
                <li>Lars leaps up onto the roof cause of course he does...well onto the wall as it is a support structure. Lands with style on the wall </li>
                <li>Lars find a crack and sees a Ghast inside the room</li>
                <li>Corpo is concerned about the length of his tongue </li>
                <li>Lars hits a ghast with holy water for 11 Damage</li>
                <li>Undead shirek...sees a shadow rise through the floor</li>
                <li>Cecil and Sparka try to listen to the sounds...recognizes three different kinds of shrieks</li>
                <li>Sparka and Cecil was checking the door for traps</li>
              </ul>
              <li>COMBAT with the undead</li>
              <ul>
                <li>Round 1</li>
                <ul>
                  <li>Corpo holds action?</li>
                  <li>Mormesk hollers at them who dare oppose me</li>
                  <li>Bowser helps Lars</li>
                  <li>Mormesk reaches through the wall for 27 damage, but Lars resists the HP Max drain</li>
                  <li>Mormesk yells "THIS IS WHAT HAPPENS WHEN YOU DON'T KNOCK</li>
                  <li>Ghasts attack the door but can't open it up</li>
                  <li>Sparka casts Arcane Lock on the door. </li>
                  <li>Lars notices treasures inside through the door</li>
                  <li>Corpo takes a Holy Water from Sparka as he flips up on the roof</li>
                  <li>Corpo does breath of the dragon lightning at the Ghast. 6 to wraith 3 to Shadow</li>
                  <li>Sass holds action to wait to shoot Wraith</li>
                  <li>Cecil casts Protection from Evil and Good on Lars for Undead</li>
                  <li>Ben declares Holy Water here not sacrilegious since not real so slap your dick in it</li>
                  <li>Cecil casts Healing Word</li>
                  <li>The Shadow goes to soul suck but Cecil's musical magical protection kicks in</li>
                  <li>Lars swings and attacks Wraith for 12 damage</li>
                </ul>
                <li>Round 2</li>
                <ul>
                  <li>Corpo does 8 Damage, 8 Damage, to the wraith</li>
                  <li>Bowser helps Lars to attack wraith</li>
                  <li>Wraith reaches out...Sass held action fires for 32 damage. Sass kills with an arrow to the incorporeal face, he is shocked it could hurt him and then he died</li>
                  <li>Sass holds</li>
                  <li>Sparka holds action waiting to blast with firebolt</li>
                  <li>Cecil holds action too</li>
                  <li>Shadow comes out...Sparka shoots it with firebolt but it is resistant</li>
                  <li>Sass shoots and KILLS the shadow with Deathstringer. He shoots it in the mouth and it electrify dies like it is eating a sandwich</li>
                  <li>Lars twists his axe to halberd and slashes a Ghast</li>
                  <li>Corpo jumps into the room to flurry of blows punches Ghast. KILLS Ghast but pulls out holy water, drips on his hands, Lebron James, Flips up with Gust, punches the Ghast into the immovable door</li>
                  <li>Remaining Ghast slashes at Corpo, for 5 damage. But he resists the paralyzing </li>
                  <li>Sparka opens the Arcane Lock and lights a bonfire under the Ghast...but does not kill him</li>
                  <li>Sass hops up onto the wall to KILLS the last Ghast, shoots the Ghast in the head, shocking him to fall down into the bonfire and it burns Darth Vader style</li>
                  <li>TREASURE TABLE ROLL</li>
                  <li>They find an open scorched chest, gets cash</li>
                  <li>Sparka finds a Bag of Holding on the shelf...shoves everything he has into it</li>
                  <li>Sparka finds a Chaos Bolt Scroll</li>
                  <li>Cecil takes histories of Phandalin</li>
                  <li>Cecil also finds the "Scroll of Opening"</li>
                  <ul>
                    <li>He Sparka and Sass look at it</li>
                    <li>Figure out it is a Spell scroll that unblocks portals</li>
                    <li>There is a note with it, says "One Scroll of Opening for the Rift in Phandalin"</li>
                    <li>Note also mentions the lamp's partner's is in Primavera...find a junky old lamp that they assume it is</li>
                    <li>Assume that this might be related to Avlod saying that this area is important and connected to his world of Primavera</li>
                  </ul>
                  <li>Discuss that Corpo took vows of obedience to his carnal desires</li>
                  <li>Cecil opens the closet</li>
                  <li>Group take a short rest with Sparka's Arcane Lock Locked down</li>
                </ul>
              </ul>
              <li>Finish short rest and notice the Enchantment magic coming from building up north</li>
              <li>Sass asks if there are enchanted gems he can encrust in himself</li>
              <li>Approach the Forge of Spells building</li>
              <ul>
                <li>Lars jumps to the wall of magical aura building</li>
                <li>Sees a small hole in the roof, just a brazier in a work shop</li>
                <li>Sass checks NAT 20 Investigation for traps..figures out how to pop the pins and get door open</li>
                <li>Lars covers him with the high ground</li>
                <li>They open door and see an Aberration that they recognize as a Spectator, not full on Beholder</li>
                <li>Spectator telepathically speaks...Sass responds in Telepathy we mean no harm...there is Enchantment</li>
                <li>Spectator is like Enchantment of course, I was hired by LaLaBoo to watch this in an active mine</li>
                <li>Lars was like they hired us too...it does not buy it</li>
                <li>Sparka like ok LaLaBoo sent me...Spectator is like aaaah ok...</li>
                <li>Sparka tells Spectator to return in exactly 24 hours....they leave</li>
                <li>THEY WILL BE BACK in 24 HOURS</li>
                <li>Approach the forge of spells and realize that the magic has faded, it is weaker...magic only lasts at most 12...might be able to fix it later on</li>
                <li>Corpo puts his staff in and the magic lasts an hour</li>
                <li>Lars and Cecil go Treasure Table look into closet and find</li>
                <li>An Immovable Rod, Serpent Scale Armor, Lightbringer Mace of Sun Pelor (Corpo takes it), find Dragonguard </li>
                <li>COrpo rubs Lightbringer on his taint?</li>
                <li>Corpo does not make breast jokes, only dick jokes</li>
              </ul>
              <li>NAT 1 Lars on Survival check to figure out the water channel...he thinks great to seduce Halfling women</li>
              <li>Sass figures out via the map and sound that the water should be down the channel</li>
              <li>Sparka votes to go north towards the loud water</li>
              <li>Sparka casts light on Duchess' nose.</li>
              <li>They see the water rolling in every two minutes...eroding thing</li>
              <li>NAT 20 Sass Perception to check if anything on the island so Treasure Table ROLL instead of 3 Copper</li>
              <li>Sparka water walks over the Wand of Magic Missiles...keeps it</li>

              <li>They check to see why things aren’t working…why the water is stuck </li>
              <li>Figure out probably an earthquake and need to check later on how to stop water from flowing other way</li>
              <li>Corpo has a vision of a gorgeous Androgynous Elven figure arrives</li>
              <ul>
                <li>Angel of the Moonweaver saying he can cast Moonbeam once a day</li>
                <li>He flirts...of course</li>
                <li>It flirts...of course</li>
                <li>He is like...oooooh I can cast it to save myself from a lady boy</li>
                <li>Angel says lies of the tongue differ from lies of the flesh...be you</li>
              </ul>
              <li>Bellows</li>
              <ul>
                <li>Corpo uses Moonweaver shadows to stealth ahead...sees dim green light up ahead of flame skull</li>
                <li>Corpo sees fallen skeletons, 2 shadows, 8 zombies</li>
                <li>Zombies "look like they could attack twice" a chowd muncher flameskull, and two shadows...gird your loins</li>
                <li>Corpo uses MOONBEAM</li>
                <li>Shadow comes to find him</li>
                <li>Sparka YEETS a Fireball at the flameskull...only one passes</li>
                <li>Sparka kill a shadow, it screams at it burns out (DOES 204 damage</li>
                <li>Lars charges Shadow 2 (Jude), 12 damage</li>
                <li>Sass with Bowser's help dead kills Shadow 2 by bouncing an arrow off the ceiling </li>
                <li>Sass kills a zombie, shooting it through the head into the wall 19 damage</li>
                <li>FLAMESKULL passes save to not not take full damage from Moonbeam</li>
                <li>FLAMESKULL casts fireball while Lars grabs his dick</li>
                <li>NAT 1 Sparka to save on FLAMESKULL Fireball</li>
                <li>Corpo kills Zombie 3 with Moonbeam Arc of the Covenant Style</li>
                <li>Corpo kills Another Zombie with Moonbeam Arc of the Covenant Style</li>
                <li>Cecil casts healing word on Sass for 9</li>
                <li>Zombie charges Corpo but he's like fuck whatever  and dodges</li>
                <li>Corpo kills Another Zombie with Moonbeam Arc of the Covenant Style</li>
                <li>Corpo kills Another Zombie with Moonbeam Arc of the Covenant Style</li>
                <li>Corpo kills Another Zombie with Moonbeam Arc of the Covenant Style</li>
                <li>Corpo kills Another Zombie with Moonbeam Arc of the Covenant Style</li>
                <li>Zombie saves and charges Corpo</li>
                <li>Corpo wields Dawnbringer at two Zombies</li>
                <li>Corpo kills a gnome zombie by gusting over them and then pulling Dawnbringer through it's ass through its mouth</li>
                <li>Corpo kills another zombie by grabbing by scruff of neck to throw it up and mace bash it across the room</li>
                <li>Lars in his rage kills FLAMESKULL "If you had a dick I'd cut it off" then bashes him to pieces</li>
                <li>"Deadly encounter...for them"</li>
                <li>NAT 20 Sass Arcane to know the FLAMESKULL would come back without holy water getting sprinkled on it</li>
                <li>Lars teabags the FLAMESKULL</li>
                <li>Corpo says "I killed 8 zombies" Sparka says nothing about his 204 points of damage</li>
              </ul>
              <li>Sparka is hurt actually for once</li>
              <li>The party does some healing with potions, Corpo yells at the bellows to bellow him</li>
              <li>They want to get into the barracks door, but Sass realizes it is barred</li>
              <li>Cecil listens at the door and hears in Goblinoid "Confuse your body pushups cardio push ups shock the muscles.. REPS REPS REPS"</li>
              <li>Cecil hears Drow complaining about maggoty bread and smelly Bug Bears</li>
              <li>Cecil tries to hear how many voices and thinks at least 2 Drow 2 Bugbears</li>
              <li>Party knows Drow are from deep underdark, the Lloth Drow worship her, The Black Spider is the name of the Evil Drow spec ops of Drow evil, Matrilineal, hedonist </li>
              <li>Cecil relays that the Bugbear and Drow would be masculine for Lars to castrate</li>
              <li>Party debates what to do...go south? Rest? </li>
              <li>South to the Great Cavern</li>
              <li>They go together with Lars first, Cecil looking at ceiling, Corpo has dick first</li>
              <li>Lars leaps into the room  "Make way for the Dragon"</li>
              <li>He sees a 6 Ghasts and 2 Shadow</li>
              <li>ROLL INITIATIVE</li>
              <ul>
                <li>Round 1</li>
                <ul>
                  <li>CORPO does poison breath but immunity to poison</li>
                  <li>Ghasts charge at Lars</li>
                  <li>Sparka peers round corner..is like shit, casts quickened Fireball at the Ghasts</li>
                  <li>Sparka kills one of the Ghasts as it burns to nothing</li>
                  <li>Sparka Firebolts a Ghast</li>
                  <li>Bowser helps Sass</li>
                  <li>Sass shoots Ghast twice </li>
                  <li>Cecil casts Hypnotic Pattern</li>
                  <li>Lars KILLS Ghast with a castration, throwing nutsack at other Ghasts</li>
                  <li>Lars KILLS Ghasts castrates another Ghast throwing ball sack down and stomps on them</li>
                  <li>Corpo dodges Shadow</li>
                  <li>Lars gets Sentinel strike on Shadow</li>
                </ul>
                <li>ROUND 2</li>
                <ul>
                  <li>NAT 20 CORPO Kills the Shadow with the Mace...then grabs the Shadow by the head and pelvic thrust kills him</li>
                  <li>CORPO kills Ghast, says remember your brother, rams Mace through the anus through it's mouth</li>
                  <li>CORPO punches another Ghast, then KILLS it with a Dragon Ball Z fusion dance one at temple and one at dick</li>
                  <li>Sparka KILLS Shadow KILLS Ghast with Wand of Magic Missile at 6 Level. Takes this thing he just fucking found and send 8 fucking homing missiles out and destroys</li>
                </ul>
                <li>"Deadly Encounter...For Them!"</li>
              </ul>
              <li>They find a copper piece on one Ghast</li>
              <li>They retreat to Room of Waukeen to regroup</li>
              <li>FLASH BACK TO SPARKA BUYING THE HOLY WATER</li>
              <ul>
                <li>Sparka casts TWP and sees a deep and dark cavern with rustling noise of a large animal</li>
                <li>Multiple hallways, when creepy guy Yanni Yanniki pops up</li>
                <li>It is topsy turvy day since he looks like Feast of Fools</li>
                <li>Yanni says his speciality is secrets though he has other things like 4 vials of Holy Water</li>
                <li>Sparka knows that many creatures are into knowledge and secrets...but that this is probably a shapeshifter</li>
                <li>Sparka asks what he knows of the Zhentarim</li>
                <ul>
                  <li>...they love their secrets</li>
                  <li>Yanni says their secrets are delicious, flips up a Platinum coin</li>
                  <li>Says he is not familiar with Phandalin but knows of Faerûn</li>
                  <li>Trades Halia being in the Zhentarim for 4 Holy Waters and a Platinum piece</li>
                  <li>Sparka writes it on a paper and Yanni has it float away</li>
                </ul>
                <li>Sparka dirty 20 hears two baby dragons in the background. Sparka calls it out</li>
                <li>Yanni passes him another platinum coin for secrets and transforms into Daurotham the Ancient Emerald Dragon</li>
                <li>Says he likes people to keep his secrets...and says he will do business with him again</li>
                <li>Sparka asks, and he says he is on the First World...in Otoño, south of Primavera.</li>
                <li>Dauro smells him and can tell he is a Draconic Sorcerer....</li>
                <ul>
                  <li>Notices he has multiple sources, can tell he has more than just Draconic...</li>
                  <li>Can smell Brass dragon...but also other sorcery powers...emerald?</li>
                  <li>Sparka offers his two Platinum Pieces back for info</li>
                  <li>Dauro says he has had multiple liaisons with Elven house</li>
                  <li>Sparka asks if he is of Dauro...Dauro says more money</li>
                  <li>Sparka says he has the name Redlake...passes insight check to know that Dauro notices thing</li>
                  <li>Dauro admits he thinks Redlake is a person...despite Chromatics thinking it is a person</li>
                  <li>Dauro thinks a humanoid...the best dragon slayer</li>
                  <li>Dauro says he should look into House Amarillis when he gets here...says he might have had liaison with one of them centuries back</li>
                  <li>Gives his name</li>
                  <li>Explains that Sorcerers are very rare and recessive....not any Sorcerers of House Amarillis in a long time</li>
                  <li>Sparka shows his focus...Dauro says money in exchange...Sparka throws a Platinum Bar at him</li>
                  <li>Dauro takes the bar and explains it is a Dragonshard...a sort of Sorcerer version of Moonblade</li>
                  <li>Dragonshards were formed from a giant gem...a long time ago Chromatic Dragons ruled...</li>
                  <li>Some people hiding realized that Sorcerers were related to genetics...deep in the underdark the refugees hid and started marrying sorcerer to sorcerer...</li>
                  <li>Found giant gems that could take a Draconic Shard (explains Dragon Echos) Gem and Metallic Dragons could not get into the First World. Their echoes on the world were not born...so these Sorcerers and Arcanists were able to give the Gem and Platinum Echo a home in the Dragonshard. </li>
                  <li>Says that Dragonshards can serve as Bloodwell vials....if you can convince the Echo to accept your blood...the previous bloods have been accepted by the shard.</li>
                  <li>He assumes that the scratch of Amarillis name of Emerald blood would have his lineage....no idea on the Storm Sorcerer.</li>
                  <ul>
                    <li>Sparka puts together than only Storm Sorcerer he knows of is the Black Storm</li>
                    <li>Unsure if Corpo said more details</li>
                  </ul>
                  <li>Dauro says that an uprising of the Imperial Sorcerers with House Kinaphalic....now after the Spellplague he says calm is returning...also Monopolists figure out</li>
                  <li>Sparka asks how to get accepted....he says it is unknown...the dragon hides its type so it can be taken care of</li>
                  <li>Dauro says it has seven upgrades...two are Blood Well Vial...one Shadowfell </li>
                  <li>Dauro tells that the Empire of Blood is ruled by Sorcerers who care about your powers less than your parents. Dauro says these lines have those Dragonshards are the pieces of the rulers</li>
                  <li>He says Empires span thousands of miles ruled by elites that only a few have Dragonshards</li>
                  <li>Sparka has a moment of greed thinking of ruling Empires....Sparka asks about if he thinks it could happen in Faerûn.</li>
                  <li>Dauro says that Sparka could just find someone with Sorcerer blood...all his spawn powerful, no studying...just BAM powerful. That is where the Empire of Blood comes from</li>
                  <li>Sparka asks about Metallics....Dauro says that he and Metallics came into the First World by the Sorcers of the Empire to help fight the Chromatics</li>
                  <li>Now says that the Platinum Republic exists...lots of Druids and internal of the continent....ally of the Empire and allies against the Empire.</li>
                  <li>Sparka asks if he knows Avlod...Dauro pays 5 gold...says he doens't know Avlod but that multiple Hobgoblins border the Empire. </li>
                  <li>Sparka asks why Avlod might be interested...Dauro is unsure...but says that the First World is hard to get to...perhaps this Avlod is trying to find connections between the worlds.</li>
                  <li>It would be an incredibly suuuuuuuupppppper valuable secret. </li>
                  <li>Ben calls to tell Robert to hurry the fuck up.</li>
                  <li>Dauro says that Cecil would wish to speak to him...he says of course.</li>
                  <li>Dauro says last thing for his Platinum...if his shard is seen in the Empire...he would probably sucked into their world</li>
                  <li>Dauro last guesses that Sparka has some Divine, Emerald, and Brass in his blood.....</li>
                  <li>LAST BIT Was that someone from House Amarillis with recessive Emerald DNA had a child with someone with powerful Brass and Divine bloodlines....</li>
                  <li>They end cordially and say to return sometime. Sparka persuasion check....to throw something else in...gives him another free holy water and a healing potion.</li>
                  <li>Also says he likes good fish.</li>
                </ul>
              </ul>
            </ul>
          </Collapsible>

          <Collapsible
            title="An Eye for an Eye(#14 - 6/24)"
            toggle={this.collapseToggle.bind(this)}
            kind="session14"
            show={this.state.show}
          >
            <ul>
              <li>Picking up from the Arcane-Locked-Down-Room</li>
              <li>Everyone jokes that we are picking up from the end of a long rest, Mike actually clicks the button</li>
              <li>"Every Rose Has it's Thorn" cause he is a cute little dwarf but if you touch him you get hit</li>
              <li>Sparka finds a gold ring of Waukeen</li>
              <li>We find that Arcane Lock is permanent</li>
              <li>Decide to take long rest, in cavern with dim light due to glowing rocks</li>
              <li>Cecil checks the hole in the ceiling to see how they could look in</li>
              <li>Sparka casts create bonfire....goes out when he sleeps in the closet</li>
              <li>Sparka casts light on a stone</li>
              <li>Corpo is on 1st watch...passes uneventfully...he thinks</li>
              <ul>
                <li>Goes onto the roof</li>
                <li>NAT 1 Perception check</li>
                <li>Sparka has a dream but can't remember (pick when he gets his notebook)</li>
                <li>Corpo goes to wake Sparka...sits next to him and caresses his head...Sparka wakes up and Sparka casts create bonfire on him...he fails and burns</li>
              </ul>
              <li>Sparka is on 2nd watch...passes uneventfully...he thinks</li>
              <ul>
                <li>Wakes Lars nice a normal person would</li>
              </ul>
              <li>Lars is on 3rd watch...passes uneventfully...he thinks</li>
              <li>Cecil is on 4th watch....gets my boy Bowser on north side of the roof</li>
              <ul>
                <li>NAT 1 Cecil Perception Check</li>
                <li>NAT 1 Bowser Perception Check for outside his range</li>
              </ul>
              <li>Cecil hears silence...ROLL INITATIVE</li>
              <ul>
                <li>ROUND 1</li>
                <ul>
                  <li>Gets hit with Javelin...doesn't hear it hit or himself scream</li>
                  <li>I said it wasn't a surprise roll cause he had good perception....NAT 1s??????</li>
                  <li>Cecil hops down into the silence of the building to wake up Corpo and Sass</li>
                  <li>NAT 20 Corpo initiative</li>
                  <li>They attack Bowser...he goes down</li>
                  <li>"Bad Things happen when I am on watch" Cecil</li>
                </ul>
                <li>ROUND 2</li>
                <ul>
                  <li>Corpo gestures to ask if the javelin came from outside...CECIL SILENTLY LIKE NO SHIT</li>
                  <li>Corpo stealthy peaks out and gets a quick peak </li>
                  <li>He sees hobgobs and Bug bears</li>
                  <li>"WHAT HAVE WE DONE" "WHAT THE FUCK ERIC"</li>
                  <li>Corpo gives weird ass hand signs...Cecil was in navy not army</li>
                  <li>Corpo wakes up Lars</li>
                  <li>NAT 1 Lars Initiative</li>
                  <li>Sass wakes up Sparka with his telepathy</li>
                  <li>Sass casts Spike Growth outside the door cause he can see through the wall</li>
                  <li>Cecil walks over to Corpo....is LIKE WHAT THE FUCK WERE THOSE HAND SIGNALS</li>
                  <li>Corpo tries to explain, none understand</li>
                  <li>Sparka is like Cecil is racist for thinking Bugbears can't cast spells</li>
                  <li>Cecil heals himself</li>
                  <li>Sparka goes over and asks WTF is happening</li>
                  <li>Sparka moves to base of stairs to prepare to get out</li>
                  <li>Bugbears are hitting the door</li>
                  <li>Cecil knows that Bugbears are weak...without heads?</li>
                  <li>Lars goes to under the whole like Sparka</li>
                </ul>
                <li>ROUND 3</li>
                <ul>
                  <li>Corpo goes to the base of the whole and sticks his head out</li>
                  <li>Corpo pokes his head out...two Hobgoblin archers shoot at him. One misses Corpo deflects the other one...NAT 1 Corpo throws it back</li>
                  <li>Corpo breath of the dragon: 3 Damage to Hobgob captain, 7 damage to Bugbear</li>
                  <li>Sass drops concentration and Sparka tells him to prep...</li>
                  <li>Sparka pops his head out...</li>
                  <li>DM says how are we doing this and I get chastised </li>
                  <li>...Sparka casts Vortex Warp and sucks the captain inside next to Lars</li>
                  <li>Sass is like "INTRUDER...ALERT" and shoots him up</li>
                  <li>NAT 20 Sass Shooting the Hobgoblin Captain for 28 points of damage poison and lightning</li>
                  <li>Hobgob captain yells fuck in Hobgoblin</li>
                  <li>Sparka yells fuck as he turns around and sees 2 Iron Shadows and the Devastator</li>
                  <li>NAT 1 Sparka Arcana check...notices the Shadow monks have boobs...Corpo would not have left this monastery </li>
                  <li>Sparka runs to the corner, drawing Opportunity Attacks but blocks with Shield</li>
                  <li>Sparka sees Thopleth and yells there are 4 of them and a Dragonborn</li>
                  <li>One Shadow hits Sparka around his shield for 6 damage then Shadow Jaunts</li>
                  <li>Bugbear Captain, disoriented sprints at Lars and Lars Oppurtunity Attack....</li>
                  <li>NAT 20 Lars melee KILLS chop off nuts. Throws nuts outside and yells I AM THE DRAGON</li>
                  <li>My big bad Hobgob Captain got vortex warped inside, got a NAT 20 from Sass, a NAT 20 from Lars and died....</li>
                  <li>Hobgob archers shoot at Sparka but his shield</li>
                  <li>Lars sees the axe say in celestial "True heroes save their friends" the axe drifts and lights up. Axe says "Be careful with fire...HAHAHA" Code word is "where there's flame their chronic"</li>
                  <li>Devastator pops down to the hole and FIREBALL for 24 damage BUT Cecil does Cutting Words to bald Devastator yelling "How's Chemo Going?"</li>
                  <li>Duchess goes out</li>
                  <li>Devastator runs away...Opportunity attack takes 5 damage with Glass Staff</li>
                  <li>Casters know that there is probably are more fireballs coming</li>
                  <li>Lars leaps to the roof and swings at the Devastator Twice and hits twice</li>
                  <li>Cecil remembers that Devastator only learn destructive magic</li>
                </ul>
                <li>ROUND 4</li>
                <ul>
                  <li>Corpo goes up the hole and attacks the devastator, punch, staff...KILLS by pushing self off staff and shoving it's dagger into its face</li>
                  <li>Corpo then staff hits Iron Shadow 1, then Flurry of Blows hits too</li>
                  <li>Sass climbs on the roof...recognizes Thopleth. He knows Thopleth was his master's right hand...know Thopleth is the reason Jaezelle is dead....SAYS DIE MOTHER FUCKER</li>
                  <li>Sass knows Thopleth would be here only to get him...if he was on watch they would have captured him "Good Things happen when I am on watch" Cecil</li>
                  <li>Sass casts 2nd Level Hail of Thorns at Thopleth...hits. </li>
                  <li>Thopleth says Kinaphalic Greosass, did you think that master would forget his eye? Did you suck his dick?</li>
                  <li>Bugbear climbs roof and attacks Sass....with mace hits and knocks him out. Thopleth yells the left one..."Eye for and Eye" NAT 20 Bugbear his left eyes gets flung across cavern, he takes two death saves and falls down the hole. LARS CORPO AND SPARKA GO FUCKING RAGE</li>
                  <li>Cecil casts healing word to heal him up. "Not on you on the guy who fucking died"</li>
                  <li>"What the fuck just happened, where is my eye? Jaezelle loved the eye the most"</li>
                  <li>Sparka notices with Arcana check that Thopleth has diamonds for Revivify...as if Cloudchaser wants him alive</li>
                  <li>Cecil HYPNOTIC PATTERNS the Bugbears</li>
                  <li>Shadow Monk attacks Corpo...Corpo goes down. His storm gem flashes red and starts spinning. Then Shadow dissipate teleports away</li>
                  <li>SPARKA is pissed as shits. Twin Firebolt hitting Thopleth and Shadow Monk...then Telekinesis pushes other Monk off the roof</li>
                  <li>BREAK</li>
                  <li>Shadow NAT 20 hits Cecil...but he keeps concentration</li>
                  <li>Shadow NAT 20 hits Corpo...gem starts glowing and he disappears into the gem and the dagger vanishes </li>
                  <li>Shadow hits Cecil but he keeps concentration again</li>
                  <li>NAT 20 Sparka Arcana check to figure out that the dagger must have been a portal key triggered by Corpo dying</li>
                  <li>Thopleth tells Sparka...tell Sass you can't always trust what you see. Shapeshifts into Aberration Doppleganger. He then turns into various Dragonborn...lastly turns into Jaezelle...lovely curvaceous dragonborn with blue eyes and brunette hair. "What kind of dragonborn is she...Hooooottttttt...I mean like color....white yeah my girl white" He shows her face with the crushed lines from her death then flees then vanishes</li>
                  <li>NAT 20 Arcana Sparka an Cecil also knows that Thopleth used Blink and will return on his turn</li>
                  <li>One Hobgob shot at Sparka but SHIELD</li>
                  <li>Another Hobgob wakes up Bugbear</li>
                  <li>Bugbear swings at Sparka, hits and Sparka looks grim</li>
                  <li>Lars rage kills shadow, chopped in half, thrown down body Thump...then Thump</li>
                  <li>Lars misses a Javelin</li>
                </ul>
                <li>ROUND 5</li>
                <ul>
                  <li>Corpo lands on table in like medieval....two guys.....Aararokora and Tiefling. Check the gem and says one last payment to heal...a Genasi tells them they were paid, handle it. Corpo sees like Mirror Dimension outside the door, Victorian Era town bending up like a Donut</li>
                  <li>Corpo pops back up and...no pants? </li>
                  <li>Swings with staff hits, one punch (Journey blaring makes it hard to tell) Flurry of Blows kills the other Monk. Corpo is not happy. Savage KILLS. The least sexual he has been. Grabs the dagger with its pommel and shoves it into it's face KILLED</li>
                  <li>Sass is back up but can't see</li>
                  <li>Sass Hail of Thorns shoots at Hobgob misses, second hits. Hail of thorns hits both hobgobs. KILLS ONE so hard its head gets plastered to the floor in front of other. BUT wakes up other Bug Bear</li>
                  <li>Cecil casts Vicious Mock on a Hobgob that he is worthless without captain and comrade</li>
                  <li>Cecil Healing Words himself</li>
                  <li>Bugbear climbs up roof and attacks Sass they were paid to get Sass. Bugbear gets NAT 20 to do 28 damage to Sass, knocks him down on the roof...flops over.</li>
                  <li>Sparka goes and holds action to hit Thopleth with a Firebolt. KILL Thopleth who blinks back to see fire strike him in the face</li>
                  <li>"You can't tell from where you're at cause your dead"</li>
                  <li>As he falls down a Dimension Door opens as Severin Solonna brings the Cleric to him. He picks up a genie lamp with mage hand...and goes "Good luck finding daddy" then collapses the door</li>
                  <li>Last Hobgob shoots and misses</li>
                  <li>Lars goes reckless against the Bugbear to hit twice for a ton of damage</li>
                </ul>
                <li>ROUND 6</li>
                <ul>
                  <li>Corpo attacks against Bugbear since WOLF TOTEM barbarian gives advantage within 5 foot</li>
                  <li>Corpo misses with one staff, hits with second staff, NAT 20 punch for 11, Ki point to hit with a punch </li>
                  <li>Sass fails a death save, coughs up blood</li>
                  <li>Cecil heals and gets Sass back up</li>
                  <li>Cecil Vicious Mockery that the DM can't even print anything for you so you are Chewie</li>
                  <li>Bugbear misses Lars twice</li>
                  <li>Sparka casts Sleep at 2nd Level on three of the remaining ones...rolled 23 HP. Puts both Hobgob and Bugbear out</li>
                  <li>Sparka yells FOCUS FIRE at the remaining Bugbear as he Telekinesis shoves him back</li>
                  <li>Bugbear attacks Corpo, hits Corpo with a Morning Star.</li>
                  <li>Lars triggers Sentinel, twists into Halberd mode...does 18 damage to Bugbear 3</li>
                  <li>Corpo is hurt but attacks NAT 20 Staff attack fo 10. NAT 20 staff, punch miss, punch miss</li>
                  <li>Sass hit...then sharpshooter misses</li>
                  <li>Cecil vicious mockery Bug bear with "you skipped leg day"</li>
                  <li>Cecil finally doesn't have to heal someone and gives Bardic to Lars</li>
                  <li>Bugbear misses...Sass like "I only have one eye and you still missed...your legs or so small"</li>
                  <li>Sparka uses magic missile and fucking KILLS the sleeping...Bugbear? Just pummeling his on the ground</li>
                  <li>Lars between the legs kills the Bugbear between the legs, looks at his friends, and tea bags him?</li>
                </ul>
                <li>ROUND 7</li>
                <ul>
                  <li>Corpo is hurt but attacks the sleeping Hobgob but....misses? But kills Hobgob with spinny-thing with staff and rams up the sleeping Hobgob butt hole...smashes him...Tabula Recti?</li>
                  <li>Sass walks up to hypnotic pattern Bugbear and goes BLACK MAMBA but ensnaring strikes but he passes. Then second strike hits</li>
                  <li>Bugbear swings at Sass  "Not my fucking face!" Sass yells as he attacks but Cecil "Pins and needles Pins and Needles...I don't know it's been 4 hours" misses...then attacks Sass again and drops him</li>
                  <li>Lars hits as Sentinel to hit Bugbear</li>
                  <li>Cecil HEALS SASS AGAIN</li>
                  <li>Cecil uses last spell slot heat metal</li>
                  <li>Sparka Firebolts him</li>
                  <li>Lars kills him. Cuts off his nuts, holds em up. Yells "I am the Dragon" and kicks him off the roof</li>
                </ul>
              </ul>
              <li>"Your Daddy's in a lamp" Corpo realizes</li>
              <li>Cecil notices a Drow check them out but leave....</li>
              <li>Party finally rests, wakes up and sees the rest of the cave...</li>
              <li>Sparka has dreams of meeting Imperials saying come let us conquer the multiverse</li>
              <li>Seeing nothing is seen...rooms are all empty.</li>
              <li>Black spider fled, leaving a note "Clearly you mother fuckers aren't going to die, even if I kill you who am I going to trade with, since my allies and cult of the Dragon have fled. Bravo. Until we meet again PS the Cult of the Dragon was looking for what you found since then they pissed off so...fuck off"</li>
              <li>They find Nundro dead in the closet...Lars has single tear drip down his face</li>
              <li>LEVEL 6</li>
              <li>Lars and Sparka dig in the ground with Investigation (Sparka using Earthen Hand)</li>
              <li>Lars finds Gauntlets of Ogre Strength and Corpo takes em</li>
              <li>Finished Long Rest at 10pm at night. Dark Creepy Cave</li>
              <li>ROLL FOR HP</li>
              <li>Pick up in the Star Room next time with the statue</li>
            </ul>
          </Collapsible>


          <h2>
            <br/>
            Part 2: Worlds Apart
            <br/><br/>
          </h2>

          <Collapsible
            title="The Succubus (#15 - 7/??)"
            toggle={this.collapseToggle.bind(this)}
            kind="session15"
            show={this.state.show}
          >
            <ul>
              <li> They pick up in Wave Echo Cave </li>
              <li> Find temple of Dwarven Deity  </li>
              <ul>
                <li> Statue in the corner with gleaming Emerald Eyes </li>
                <li> Spiderwebs everywhere as The Black Spider and crew hurried up </li>
                <li> Sass doing chin ups in the corner doing chin ups talking about how he died 3 times and lost an eye </li>
                <li> Sass detects Abjuration aura around the statue </li>
                <li> Sparka notes Abjuration magic is like protecting or blocking </li>
                <li> They decide not to touch the Emerald eyes...and does mage hand to grab the emerald eyes </li>
                <li> Sparka pries it out as the entire Ceiling Falls </li>
                <li> NAT 20 Sparka on Dex Save </li>
                <li> Sparka takes the Emerald and THINKS they are 200 gold a piece </li>
                <li> They survey the room...find nothing.... </li>
              </ul>
              <li> Cecil reveals Maisie sent in his notebook that: </li>
              <ul>
                <li> Sister Gaerelle was taking into custody for suspected being with the Black Spider </li>
                <li> Mushroom Zombie Horde heading towards Phandalin </li>
                <li> Cult of Dragon attacks on Triboar and Leilon...Neverwinter and Waterdeep defending </li>
                <li> She is coming to Phandalin to figure it out </li>
                <li> Realize that the shapeshifter probably framed Sister Gaerelle...hmmmm </li>
                <li> Corpo suggests they test Maisie to make sure she isn't the shapeshifter...then is upset that there are no Safe Words </li>
              </ul>
              <li> They clean up on the walk out, ochre jellies, gricks, etc </li>
              <li> Each party member gets to check one room on the way out </li>
              <ul>
                <li> NAT 20 Sparka Investigation in North Barracks... +1 Arcane Grimoire </li>
                <li> Lars finds a Decanter of Endless Bad Brandy </li>
                <li> Cecil finds a carving of a wooden animal from the people who fell here </li>
                <li> Corpo finds a skeleton down in the pool of water..finds a Tattoo Needle of X </li>
                <li> Cecil IDs the Tattoo as a Tattoo of Featherfall </li>
                <li> Gives it to Lars, jabs into Bicep...Tattoo of a giant Bald Eagle on his Bicep </li>
                <li> Sass finds a badass Platinum Eye Patch that is magical...doesn't impose disadvantage on him </li>
              </ul>
              <li> "We fought a ceiling and we lost" </li>
              <li> Party does a Long Rest while looking over news form Maisie </li>
              <ul>
                <li> Laugh that their man-slave Hobgob, Gundren, and Elsa are like 1.5 hours away </li>
                <li> Smaller towns being hit by Cult of the Dragon, not killing as much but pillaging </li>
              </ul>
              <li> Post-Facto rolls on what they might have seen </li>
              <ul>
                <li> Lars does animal handling check...21 </li>
                <ul>
                  <li> Sees a red pseudodragon that looks like a mean creature </li>
                  <li> Hears an odd goat noise...super high up in the Mountains species BUT a little different than here. Eastern vs Western Meadow Lark </li>
                </ul>
                <li> NAT 20 Sparka Arcana to see how strong Severin was... </li>
                <ul>
                  <li> Level 8 Spell Sloths </li>
                  <li> His study covered in scrolls </li>
                  <li> Books "Idiot's Guide to Summoning Tiamat", "Breaking gods out of Hell" </li>
                </ul>
                <li> Corpo Religion check dirty 20 </li>
                <ul>
                  <li> The Genie lamp...has text as a prayer to Avandra. This Djinn has a connection to Avandra  </li>
                  <li> Most Djinn don't care about anything outside their plane...but this one clearly does </li>
                  <li> Runes around that Severin made including "Fuck you Tel Amoth"  </li>
                  <li> SO IT IS DADDY! </li>
                </ul>
                <li> Lars empties the Sage Course and his cheap Brandy..shares with Sass </li>
                <li> Sparka fills up Stone of Reserve </li>
                <li> Sparka talks to Sass about his last name Kinaphalic...and how Sparka knew the name from the lost kingdom Kinaphalic Kingdom in Primavera. </li>
                <li> Sun comes up and Corpo feel the warmth of the sun on his tattoo </li>
              </ul>
              <li> Gundren runs up and he asks what happened...if they found his brotherss </li>
              <ul>
                <li> Lars is like...they are dead....my apologies brother and they head bash </li>
                <li> He offers them 10% of yearly revenue....they start bartering </li>
                <li> Sparka is like whoa maybe we are being greedy when he finds out it was revenue and not profits </li>
                <li> 15% of profits </li>
                <li> Gundren asks for and they assign the Hobgob slave to follow him </li>
                <li> He says to tell Sildar that he would be hiring mercs to help in the cave </li>
                <li> They take Elsa back towards Phandalin </li>
              </ul>
              <li> Sparka tells the crew about visions </li>
              <ul>
                <li> Specifically that he sees Phandalin under attack </li>
                <li> That Sass fought them...but he and Cloudchaser had both their eyes </li>
              </ul>
              <li> They walk through the day, they bypass encounters and Maisie is like code phrase...WTF? </li>
              <li> Lars starts doing his ritual </li>
              <ul>
                <li> Takes some shrooms...pulls down his wolf hat and tugs on his skulls on his waist... </li>
                <li> Girds his loins...staff in hand </li>
                <li> He is doing the HAKKA to Wolf Totem </li>
                <li> He is sweating profusely </li>
                <li> He tries to talk to a mink </li>
                <li> Lars finds a squirrel..."friend squirrel" "calm your shit" asks him to go up and scout ahead into Phandalin </li>
                <li> Lars talks to Bowser and The Duchess </li>
                <li> They wait for the return  </li>
              </ul>
              <li> Two Mercs show up, Sparka notices Zhentarim logo hidden in their merc logo </li>
              <ul>
                <li> Sparka says Halia sent them...Lars is like I got it </li>
                <li> Lars hands over the Red Wizard's head. </li>
                <li> They said Halia sent them to meet them, haven't been in town but stuff going crazy...people going out of sorts. </li>
                <li> They are like "Qeline, you were backing her for mayor?" Lars said "I fronted her as well...she gave me a to-go package" </li>
                <li> Tell party that Qeline confessed to planting the evidence that got Harbin arrested...so Harbin got out of jail and she got thrown in jail...Thistle too </li>
                <li> Wildon got on a roof shouting that the Phandalin 5 were heroes and not evil, Harbin tried to arrest him and he defeated 3 guards....at least drop kicking a guy off the roof by yelling "FOR CORPO!"  </li>
                <li> Corpo adjusts self at hearing this </li>
              </ul>
              <li> Lars sees the Squirrel returns </li>
              <ul>
                <li> Tells tons of people to the south of Phandalin in tents </li>
                <li> Farmer with a missing hand walking this way </li>
                <li> Ton of people around Phandalin </li>
                <li> Asks for nuts...Lars tells The Duchess to give em the nuts  </li>
                <li> NAT 20 Duchess stuns with lightning breath then lazily eats it </li>
              </ul>
              <li> Corpo and Sass sneak ahead </li>
              <ul>
                <li> See Zilestor is the one-handed farmer coming towards him </li>
                <li> Zilestor calls Corpo "His Airness" </li>
                <li> Corpo bows to Zilestor as uncharacteristic show of respect </li>
                <li> Zilestor says that Mushroom spore cloud comes and takes their dead and other people </li>
                <li> Peeps ran away </li>
                <li> Graves were emptied...mushrooms all over plants </li>
                <li> They started to rebuild when One-Eyed-White-Dragon swooped down low </li>
                <li> People in purple, Kobolds killed the priest </li>
                <li> Drakes got sicked on his on family...ate his arthritic hand hahaha </li>
                <li> "To Tiamat with it's belly" </li>
                <li> Bahamut "May he soar forever" they desecrated his shrine </li>
                <li> Zilestor saw posters that the Phandalin Five were evil.... </li>
                <ul>
                  <li> Cecil stealing daughters </li>
                  <li> Lars kills puppies "gotta spay and neuter your pets" </li>
                  <li> Halia asked why he was freaking the fuck out in the middle of the street </li>
                  <li> Halia sent Zilestor towards the Mercs and the Phandalin Five...said great job too </li>
                </ul>
                <li> Halia and Zilestor said the town is absurd, people doing weird shit. Party assumes it Shapeshifter getting into shit </li>
                <li> They know that with Arcana check that Disguise Self is illusory and shows on Detect Magic. Shapeshifters are not Detecting Magic...functionally new things, not magic. Mystique </li>
                <li> Corpo knows with religion that Dragons can shapeshift, Outer Planes... </li>
                <li> They know that Moonbeam can work </li>
              </ul>
              <li> They start debating what to do...realize they told Elsa nearly everything about their plans </li>
              <ul>
                <li> The town both thinks they are evil...but the shapeshifter wants them back </li>
                <li> So much planning...did not write them all down </li>
                <li> Debate how to deal with the shapehifter...sending the Zhent mercs in? </li>
                <li> They go into town, have Plata the Zhent go into town (with Sparka doing Presti to mark her to make sure he knows if it's her) </li>
              </ul>
              <li> Halia comes back with a glass of wine </li>
              <ul>
                <li> A WASP and Corpo's least favorite person </li>
                <li> Lars asks about his payment...she would pay his bar tabs </li>
                <li> Everyone is like WTF LARS SERIOUSLY </li>
                <li> 7 taverns had a bounty on him...some brothels too that  </li>
                <li> LARS IS A FREE MAN </li>
                <li> She explains that Lars was trying out to the Zhents....has been accepted </li>
                <li> They show Halia the real Elsa...is like ahhhh  Shapeshifter </li>
                <li> Halia says that Pip the Stonehill's boy is missing...signs saying come to me </li>
                <li> "A question, how high can I fly?" good ol Corpo </li>
                <li> "If nothing else we are good at causing a scene" -Lars </li>
                <li> Sparka and Halia spar.... </li>
                <ul>
                  <li> He is like you did nothing? </li>
                  <li> Sparka is like oh helping refugees? How much did you charge? She is like nothing? He is like strings attached? She is like no? </li>
                  <li> Lars is nervous as mom and dad are fighting </li>
                  <li> Sparka is like Halia has been increasing her power sitting idly she is like how could I when they publicly confess to things </li>
                  <li> She is like why not help them...sure it will help me eventually but still helping them...Lars nods eagerly </li>
                </ul>
                <li> They send Halia to check out the Stonehill tavern </li>
                <ul>
                  <li> Toblen is glossy eyed with a kitchen knife near wife tied to a chair </li>
                  <li> Fake Elsa is just eating like a crazy thing </li>
                  <li> Sign on the door says talk before fighting </li>
                  <li> Lars knows that hunting creatures have an out </li>
                  <li> Sparka knows that Toblen is charmed </li>
                  <li> Corpo knows that Fiends can Shape Shift AND charm </li>
                  <li> Corpo knows that Fiends are just lower planes denizens </li>
                  <li> They debate what to do...Lars is like I can jump 300ft in the air let the people try to attack me </li>
                  <li> ...Sparka would burn it down to the ground if no one was in the latrine... </li>
                  <li> Cecil doesn't think she will be drawn out. </li>
                </ul>
                <li> Kettenraw reports that Halia went into the tavern..."Elsa" said go away not the one I want to talk to </li>
              </ul>
              <li> Rest from Midnight til morning </li>
              <li> LAUNCH THE PLAN during Saturday Farmer's Market Vibe </li>
              <ul>
                <li> Cecil disguises self </li>
                <li> Corpo up on the Stonehill Inn roof </li>
                <li> Cecil makes Sass invisible, he goes on a roof </li>
                <li> Lars has rope around neck for fake caught plan </li>
                <li> Sparka leaning like a dope ass </li>
                <li> "PEOPLE OF PHANDALIN BRING ME YOUR PUPPIES I CRAVE BLOOD. I AM THE DRAGON" </li>
                <li> They see the Gallows...Harbin tells his people to add another rope for Lars </li>
                <li> "I demand a trial of my peers!" "Dog killer! There are no peers of you worthless Dog Killer" Lars points at his Wolf pelt hat </li>
                <li> Qeline, Wildon,and Thistle brought out in chains...they don't see fake Elsa </li>
                <li> Lars undoes the fake knots, Show me the money Axe and reveals his amazing ax...Townspeople keep a circle </li>
                <li> Lars goes toward the tavern as the towns people are like....wtf....while Cecil feigns attempt to hold him back </li>
                <li> Lars splinters door with flame axe </li>
                <li> Shifter is like...I wanted to talk...but...guess we have to do it this way </li>
                <li> "Your tavern is burning...." "Not my tavern" </li>
                <li> Shifter is like you need me to clear your names. Implies she seduced people as Corpo but had it be....reaalllll small. </li>
                <li> Harbin stabs Wildon in the Neck...Wildon lands on the ground as Harbin walks towards Thistle </li>
                <li> Corpo flies from roof with Taint Mace and attacks Harbin...he is on deaths door and yells "SEE! THEY ARE MURDERERS! SEE THE VIOLENCE" </li>
                <li> Sparka does not reveal himself </li>
                <li> Corpo throws Harbin heals Wildon </li>
                <ul>
                  <li> "I knew you'd return" "I got you" "You've been in my every dream" "I can't quit you" </li>
                  <li> Crowd is unconvinced about what the hell is happening </li>
                </ul>
                <li> Lars is standing in the burning doorway...looks pretty dope. </li>
                <li> Shifter talks to Lars...says make it worth my while </li>
                <li> Shifter eats a blood sausage violently...Lars' sack shrinks a little </li>
                <li> Lars is like I am going to kill you. Succubus goes ethereal and moves around invisible </li>
                <li> She says make it worth my while, you were in Wave Echo Cave...I like magic things </li>
                <li> Cecil tries negotiating her to come out to the Green </li>
                <li> She is like you gonna burn down someone else's tavern  "I can't control the dragon" Cecil </li>
                <li> NAT 20 Corpo performance check to stall/convince the crowd that the Phandalin 5 is good guys, protecting them. </li>
                <li> Guy in the crowd yells "He killed my dog!" "He seduced my daughter!" "My wife doesn't like you much..." </li>
                <li> "Someone sullied the good reputation of my member!" But wait he doesn't know that yet "You turn on us for this little leech" and Corpo plant foot on his back to exacerbate pain </li>
                <li> Sparka approaches stealthily and does Detect Thoughts...is in the mood to get something out of this before leaving...her contract is complete </li>
                <li> The Succubus is like pay me something from that cave or I have Toblen kill his wife  </li>
                <li> Lars offers his brandy, she spits it out, he is like what plane do they not teach you to appreciate Brandy which convinces her to reveal the Hells Lawful Evil </li>
                <li> Cecil goes full on hostage negotiator "If there is a dead body the talking will cease" convinces her to stop the knife moving closer to the neck. </li>
                <li> Cecil and Sparka plan with message cantrip </li>
                <li> Sparka casts Sleep on the Toblens and they pass out. She is like ahhh money person </li>
                <li>  Sparka is like the boy's death is on your conscience not ours. Sparka threatens to kill her and she goes Ethereal again.  </li>
                <li> Sparka is being are hard ass negotiator. She offers to enter a contract, expose to the town the get the payment. </li>
                <li> "TIMEOUT CORPO CAN SOLVE THIS SHIT RIGHT NOW"  </li>
                <li> Sparka offers 150 gold...then 200 gold in exchange for the deal </li>
                <li> The location of the boy and info about the Cult of the Dragon </li>
                <li> She proposes contract </li>
                <ul>
                  <li> She signs contract </li>
                  <li> Clause 1:  Goes out and tells the people </li>
                  <li> Clause 2:  Tells about location of boy and more info on the cult </li>
                  <li> Clause 3: No one harms each other </li>
                  <li> Lars proposes Clause 4:  3 does not kick in until 1 and 2 are complete </li>
                </ul>
                <li> Sparka is like uhhh counter proposal </li>
                <ul>
                  <li> Clause 1:  Goes out and tells the people </li>
                  <li> Clause 2:  Tells about location of boy and more info on the cult </li>
                  <li> Clause 3: You don't harm me (Cecil counters with each other) </li>
                </ul>
                <li> Languages float into the air in various languages...Sparka demands to see it in Common </li>
                <li> Sparka shakes her hand and the infernal words float up his arm </li>
                <li> They walk out to Corpo as he goes "AND ANOTHER THING! My chain hang low" </li>
                <li> The shifter goes out and shifts to prove to the town that she is a shifter. Say she was paid to mess up the town </li>
                <li> A random guy is like "Yeah she looked like all of us" "No Todd/Chad you cheated on your wife" </li>
                <li> He clears their names...Corpo drops pants...."Women cover their children's eyes but are unable to look away" But rolls and turns out it is a cold day </li>
                <li> Sildar arrested Harbin </li>
              </ul>
              <li> Shifter goes back into the tavern </li>
              <ul>
                <li> Cecil asks about the Gold Elf contact...confirms it was Hadrian </li>
                <li> "His daddy has his daddy" about Hadrian and Corpo "That tracks" from shifter </li>
                <li> Asks her why they wanted the cave.  </li>
                <ul>
                  <li> The Cult is amassing treasure and the enchanting powers of the cave would make it more valuable </li>
                  <li> The Cult knew about the lamp and scroll of opening  </li>
                </ul>
                <li> Lars asks why they hired her to mess with the town </li>
                <ul>
                  <li> The cult wanted to mess up the town to, among other things, draw the Phandalin 5 back </li>
                  <li> She just kept going in hopes of getting something from them </li>
                </ul>
                <li> Cecil asked about what Hadrian ask about him </li>
                <ul>
                  <li> If he was cool, flirted with ladies, played good instruments...things insecure kid would ask </li>
                  <li> Also asked where to find Cecil's parents </li>
                  <li> She said he was skilled with instruments and Hadrian didn't like that </li>
                  <li> Also the story of beating of Yeemik...he punched a wall...angry that Cecil had control over the Story (House of Bannignhymn's whole deal) </li>
                </ul>
                <li> Lars asks about Thopleth </li>
                <ul>
                  <li> Thopleth not a fiend, just a Doppleganger who is often Dragonborn since obsessed with Dragons </li>
                </ul>
                <li> Corpo asks about future Phandalin plans </li>
                <ul>
                  <li> They wanted the magic items and Phandalin something about getting to the other world </li>
                </ul>
                <li> Corpo asks if anything with Sister Gaerelle </li>
                <ul>
                  <li> She's like oh yeah I pretend to be her and sent her Harper messages to my people </li>
                </ul>
                <li> Lars...if you were to destabilize the Cult of the Dragon what would you do </li>
                <ul>
                  <li> Hmmmmm....destabilize them...fuck with their plans currently...stop them from getting to the other world </li>
                </ul>
                <li> Cecil asks about other world </li>
                <ul>
                  <li> She's like eh...dirt world...I don't know </li>
                </ul>
                <li> Corpo asks about the Elder Elemental Eye </li>
                <ul>
                  <li> She's like yeah there is talk of parley...Tiamat cares not for the elemental planes that they could at least rule </li>
                  <li> Shroomers...they don't get along with them since they just want to destroy everything and Tiamat can't rule... </li>
                  <li> Shifter says Elder Elemental Eye is paying 500 Platinum for an Air Genasi...it's cool we have an agreement </li>
                </ul>
                <li> "Do you find this fulfilled?" they agree and she gives the location of the child. They send Sass to get the kid </li>
                <li> They sit crisscross applesauce while they wait for Sass to get back  </li>
                <li> She says cool, now I want my payment of the blood of 5 virgins in a cup and disappears </li>
                <li> Sparks is like fuck that not paying, he was like not in Common...it's in Infernal. She is like ok. He is like you harmed me by the clause...she is like...noooooo not at all.  He is like nope no time limit..she is like well 3 days from now...he is like you only made deal with me...she is like I know </li>
                <li> She shows up...reverts to her Succubus form then leaves  </li>
                <li> "Ladies of Phandalin...lend me your virginity" </li>
                <ul>
                  <li> Qeline walks forward </li>
                <li> Shows up again and clarifies male or female virgins are fine. </li>
                <li> Wait it can be males too? "Cecil get over here" "....guys...." </li>
                <li> Sparka does not worry about this deal going sour...the others do. Sparka is rules lawyering about Infernal </li>
                <li> Sparka is also a virgin 2/5 virgins </li>
                <li> Corpo NAT 20 Persuasion to get virgins to come forward. 5/5 </li>
              </ul>
              <li> Sparka is going to bed...it's like 11am...Sparka is taking a break! </li>
              <ul>
                <li> Sparka is going to cast Thrift's Wandering Post to buy Dimensional Shackles </li>
                <li> Sparka takes Cecil upstairs with him... "Shit I did not see that coming" "Good thing we got their blood before hand!" "Does that mean it got ruined? No it was virginal at the time" Corpo thinks </li>
                <li> Cecil asks for diamonds for Revivify. </li>
              </ul>
              <li> Cecil casts identify on the lamp..Conjurationing Port-key like </li>
              <ul>
                <li> NAT 20 Corpo religion to notice runes on the lamp in ancient odd Draconic that he must have studied "work hard play hard" </li>
                <li> The lamp says "Gateway to the First World" </li>
              </ul>
              <li> Cecil is like forgot something downstairs....what does the lamp say "Rub me tug me see where it takes you...no it says Gateway to the First World" </li>
              <ul>
                <li> Implies the scroll will get to Primavera </li>
                <li> Cecil goes Votary about the lamp...asks what is needed to activate it. </li>
                <li> Figures out that to activate the lamp it needs to be not dun...taking to a planar rift or something like that. </li>
                <li> Lars remembers a weird rift in under Tresedar Manor that had odd magical energy coming from it. </li>
                <li> Cecil knows that the Scroll of Opening implies creating/splitting a small rift into a large rift...the lamp needs to be charged by rift </li>
              </ul>
              <li> Sparka and crew debate destroying the scroll...or hiding it on the other wise. Cecil is like...where put the scroll? How about Netherese Ruins where only Sparka can go </li>
              <li> Sparka is like eh Wandering Post later...let's talk to Corpo's Uppity Waspy Bitch Halia wants to protect Phandalin. </li>
              <ul>
                <li> Lars wants to throw a rager, Corpo wants to flip it. </li>
                <li> Halia gives them the deed </li>
                <li> Sparka is like we do disagree on methods...Sparka likes the flow, Halia likes power </li>
                <li> Halia is like would Waukeen like Phandalin connected to other worlds??? </li>
              </ul>
              <li> Plan to have a montage, create jobs, get drywall up, hire contractors FIX UP THEIR NEW HOUSE </li>
              <li> Lars drops the Sagecourse bottle </li>
              <li> Lars hears sound in the lamp </li>
              <li> Corpo goes to the town hall prison </li>
              <ul>
                <li> Corpo sees a guard...tries to persuade </li>
                <li> It's a girl...she asks "Is it really an inch" "No...it's an inch and a half" </li>
                <li> Harbin that the Taint of Mace felt tainted </li>
                <li> Corpo leans over "You fucked with Wildon" </li>
                <li> Corpo lights the mace </li>
                <li> Corpo lifts Harbin up in the air with gust and slams him down </li>
                <li> Corpo pulls out his dagger as he lays dying..."Shhhhhhhhh" slowly slices his neck </li>
                <li> Corpo tells the guard "your shift is over...permanently" he doesn't look back "cool guys don't look at social explosions" </li>
              </ul>
              <li> Lars takes the lamp and it starts to glow </li>
              <ul>
                <li> They walk in like they own the place...cause they do </li>
                <li> "I figured glowing lamps were a good thing" - Ryan "I couldn't think of a situation where they are a bad things" </li>
                <li> Walk down memory lane as they enter the manor </li>
                <li> Lamp glows more and more and the chasm glows </li>
                <li> Lars keeps walking closer </li>
                <li> Sparka feels the scroll resonating...begging </li>
                <li> The others see Lars...swoop into the lamp as it falls...like Corpo did. Corpo dives and catches the lamp </li>
                <li> Lars is seeing vision of where he was when Grimstone fell...where he Balor and Bamur were playing...but...where they were running was a solid wall. </li>
                <li> It is not a dream...he is back in Grimstone </li>
                <li> Non-dwarven soldiers in pseudo-Imperial show up "Who are you?" </li>
                <li> Lars answers "I AM IN THE DRAGON!" </li>
              </ul>
              </ul>
            </ul>
          </Collapsible>

          <Collapsible
            title="Welcome to Thon Daral (#16 - 8/26)"
            toggle={this.collapseToggle.bind(this)}
            kind="session16"
            show={this.state.show}
          >
            <ul>
              <li>DM clarified that the Scroll of Opening can control how it opens...not like a sealed portal but a sealed potential. Can direct where the portal opens</li>
              <li>Sass is visible but still doing chin ups</li>
              <li>Lars is facing down the soldiers</li>
              <ul>
                <li>Soldiers have locked shields but spears at the ready not leveled</li>
                <li>Sgt Dragonborn (Uummith Xaryax) behind him and Officer (Zorakir)</li>
                <li>Zorakir is a super mutt of things</li>
                <li>He says I am the dragon...Xaryax does holy Symbol of Bahamut to cast Detect Magic and figures he is NOT a transfigured dragon</li>
                <li>He is like I am from here! I thought it was destroyed. </li>
                <li>They say that's complicated.</li>
                <li>They go to get someone higher up...he is like I can go too</li>
                <li>"Nothing Straight" Corpo</li>
                <li>Lars see that the town is inhabited, not just dwarves</li>
                <li>Some new squares, massive military structure</li>
                <li>Grimstone is in a different dome...it was moved....?</li>
              </ul>
              <li>Corpo caught the lamp as Lars disappeared</li>
              <ul>
                <li>Corpo is like...do I get sucked in?</li>
                <li>The lamp has dulled...dun...it is recharging for 7 minutes</li>
                <li>Corpo rubs the lamp like it owes you money. Lamp doesn't work???</li>
                <li>Corpo doesn't jump...but gets close as possible to the little ravine</li>
                <li>Lamp is recharging...they decide to all hold on at the same time and jump in the ravine</li>
                <li>While waiting Sass does pushups...Corpo watches and is impressed</li>
              </ul>
              <li>While waiting, Sildar, Qeline, and Fiddle come on in</li>
              <ul>
                <li>Sildar is like hell of a few weeks...glad not killing puppies</li>
                <li>Sparka says puppy killer is taking a break (lies)</li>
                <li>Sildar says about best fighters he's ever met</li>
                <li>Sass is like thanks!</li>
                <li>Sildar curses and is like dang</li>
                <li>Corpo asks if Sildar has considered running for public office</li>
                <li>He is like I can't I am in the Lord's Alliance..QELINE IS LIKE I AM RIGHT HERE!</li>
                <li>Corpo is like "Quiet Woman"</li>
                <li>Sildar say took vows...Corpo is like I took vows too....</li>
                <li>Zilestor is helping at Stonehill Inn since they have had a day. One handed cooking</li>
                <li>War room meeting in 45 minutes</li>
                <li>Says he needs their help</li>
                <li>The Zhent Mercs are going to arrest some baddies...but know where a Cult of the Dragon Camp is</li>
                <li>Sildar leaves</li>
                <li>Wildon says he was like a monk...but failed...is ripped with a Colonial Hat. Then prayed WWCD and Moonweaver answered</li>
                <li>Wildon tried giving his dull knife to Corpo but is like you don't need it...here...Sparka</li>
                <li>Wildon says he can help heal them</li>
                <li>Corpo gently places kiss on Wildon's forehead. Wildon blushes</li>
                <li>Qeline asks about Lars...he is resting</li>
                <li>She decides to take a special election since there is no town master currently</li>
                <li>Sparka asks for her platform...if she is for the small business owners?</li>
                <li>Corpo notices that Wildon has the same broach pin that Qeline had given Lars</li>
              </ul>
              <li>Meta discussion about how they get like 3-4 hour chunks to decide what their characters would do (guess what they will do?)</li>
              <li>The lamp is recharging...meeting is in 45 minutes</li>
              <li>They are like wait why hasn't Lars tried to contact us?</li>
              <li>Lars does not try to come back...he has a plan that can go terribly right or terribly wrong</li>
              <li>Cecil is like we are more loyal to Lars than the town...the others agree. More than a day or so</li>
              <li>Corpo decides that Wildon can handle himself</li>
              <li>EVERYONE TOUCH MY LAMP</li>
              <li>The group all touches the lamp and jump? walk? into the glowing ditch</li>
              <li>Lars enters the military courtyard...is looking for the general</li>
              <li>Legate Misspira Rarel approaches with her glowing eyes</li>
              <ul>
                <li>She speaks to Lars and asks where he is from in town, she asks for his clan name and he translates</li>
                <li>Legate explains that the city arrived 52 years ago, somehow the city was planeshifted...not the people</li>
                <li>She says it is smacked into the middle of an abandoned mine...a Dragon came to squat...heroes slew the dragon...now there are portals appearing</li>
                <li>Says it  is Thon Daral "Red Lake" in Dwarven when they found it based on the carving name in the ceiling</li>
                <li>Says that Lars should meet the surviving other member of Grimstone...who gave them permission.</li>
                <li>She salutes and turns to leave</li>
              </ul>
              <li>The lamp is glowing..Corpo pushes the button</li>
              <ul>
                <li>Corpo disappears into the lamp as it recharges for 11 minutes</li>
                <li>Lars is standing there as the lamp shakes...he is like...whaaaaaaaaa GENIE OF THE LAMP</li>
                <li>Corpo comes out of the Lamp holding it</li>
                <li>Soldiers lower weapon as a blue man showed up</li>
                <li>Lars introduces him as his man servant</li>
                <li>"Man servants are highly useful" the Legate</li>
                <li>Uummith Xaryax is like I will show your man servant where the servant things are in the fortress</li>
                <li>Corpo declares his master is known as "His rigidness" "He of profound girth" "The dragon most mighty" "Take me to his bedchamber!"</li>
                <li>Lars tells him to keep Corpo busy</li>
                <li>Corpo averts his gaze as he takes the lamp from Lars</li>
              </ul>
              <li>Lars, Zorakir, and Xaryax go walk towards the central tavern</li>
              <ul>
                <li>Down the cleared path towards the center</li>
                <li>"Is he a good man servant?" "I've had better, but he is loyal" "There is nothing like loyalty"</li>
                <li>Zorakir is unsure how to proceed....explains that they honored his people</li>
                <li>The Empire is often orderly, but the Waukeenar convinced them to have less control as you move out. An idea for trade</li>
                <li>Lars says chaotic is not offensive term for his people</li>
                <li>Zorakir says the officers love to leave here, tons of food such good food</li>
                <li>Lars asks about the local, says he can meet him, he likes mushrooms</li>
                <li>Lars pushes, Zorakir explains that the other resident isn't permanently die...no one is sure</li>
                <li>When officers come on leave they HAVE to go see him</li>
              </ul>
              <li>Corpo goes with the servant-esque people</li>
              <ul>
                <li>Notices they are wearing clothes of rank like they are in a very organized civil service</li>
                <li>They are chatting amongst themselves, Corpo noticing what is happening</li>
                <li>Corpo initiates conversation with their privates</li>
                <li>"I see that we all serve greater being than ourselves. Who is your most commanding of officers...mine is his erectitude"</li>
                <li>When Corpo takes his hood off they are like DAMN it is a Genasi???? What are you???</li>
                <li>"I am a traveler from far away, my life brought me to illrepute" "You can say that again...." Adam "....now I serve my penance serving the grandest phallus in the land"</li>
                <li>The servants salute him as he says he is a Genasi. </li>
                <li>The noble is like "My Lord!"</li>
                <li>"This is amazing" says Ben</li>
                <li>Corpo is like please don't bow but he is like I must bow for this is the Empire of Blood. </li>
                <li>Corpo asks his name...Marruelius....Corpo declares that he will be Marruelius the Veinious...and you bow to no one</li>
                <li>Marruelius says take me to your leader</li>
                <li>People stand at attention...Corpo gets a dirty 20 to hide his erect</li>
                <li>Corpo sees the lamp fully charges...smile</li>
              </ul>
              <li>The lamp is charging...10 more minutes to charge and 35 minutes</li>
              <ul>
                <li>"Do we trust Corpo to convey to Lars..." "NO. NO" "Do we keep sending people through...." "Yes but someone responsible...not the drunk or serial philanderer" Cecil and  Sparka</li>
                <li>Sass is like what is happening?</li>
                <li>Sparka is like I'd like to see someone come back</li>
                <li>Sass gets ready to go through</li>
                <li>Cecil gives Sass the paired notebook and runs to see Maisie and steal hers</li>
              </ul>
              <li>As Corpo is lead by Marruelius who says that The Progeny led him there</li>
              <li>Marruelius is like you want to see internal intelligence or governor...Corpo asks for his name</li>
              <li>Sass appears from the lamp in a super organized military base</li>
              <li>"You may refer to him as the scaley one" </li>
              <li>"I am taking him to someone high born like him...." "HIM????"</li>
              <li>"Dongus minimus" Corpo calls him, "YES...TAKE NOTES!"</li>
              <li>Sass writes: "We are good...? Apparently Corpo is 'High Born'"</li>
              <li>Cecil runs to Stonehill to get the notebook...Maisie is like wtf Corpo is high born.</li>
              <li>Cecil writes: "WTF is happening"</li>
              <li>Sass writes: "Still don't know...Lars isn't here...but Corpo is being treated like a god...?"</li>
              <li>Corpo says "From where I am from we treat the lowest with dignity....take notes that more of us should come through more often.....</li>
              <li>Sass says: "Sure......"</li>
              <li>Marruelius "So hard to find good help these days"</li>
              <li>Sass is like wtf can I have a sidebar</li>
              <li>"Did I speak over you conversation with your man servant?"</li>
              <li>Corpo grabs Sass' ass to pull him aside.</li>
              <li>"Sass....I'm a fucking god" YOU ALREADY THOUGHT THAT. BUT HERE IT IS TRUE</li>
              <li>Lars is great, I gave him the best titles...I took off my hood and they all got raging boners (Sass knows he is lying)</li>
              <li>"When I say come...you'll know"</li>
              <li>Corpo says hey Sass write down "Come through but when you come through....pretend that Corpo is important...this is not a joke"</li>
              <li>Cecil writes back: "OK....are you safe? I don't care about fucking high born shit are you safe" like Cecil is like </li>
              <li>"Apparently there is no danger but I am not sure what we are doing right now"</li>
              <li>Lars, Corpo, and Sass are like we can get an army here....</li>
              <li>Maisie is like surely you can keep it for now if talking with someone on another world...can talk later about this</li>
              <li>Cecil goes back to Sparka and is like....whaaaaaaaa?</li>
              <li>Sparka is worried abut the small business owners</li>
              <li>Murrelius comes back to "Highest Blood" "You may call me Bluest of Balls"</li>
              <li>Murrelius lists Vice Chamberlain Falfinas and  Zaloth as options...Corpo picks the Vice Chamberlain</li>
              <ul>
                <li>Murrelius introduces a Genasi who pretended to be a man servant who came through a lamp</li>
                <li>Corpo calls for his servant scribe to come in and take notes. Falfnas "Notes can be taken"</li>
                <li>Falfinas drinks from his glass after Corpo refuses to drink out of fear</li>
                <li>Corpo toasts to each other's health and "your empire"</li>
              </ul>
              <li>Lars sees that the middle square is larger Coin Maiden Square</li>
              <ul>
                <li>Zor tells him that the Dragon had knocked down many buildings..the slayers REDLAKE carved it in the ceiling</li>
                <li>Tons of food from around the multiverse</li>
                <li>Military always come here for leave</li>
                <li>The Tavern was not changed....</li>
                <li>Story about "A giant as tall as my balls hang low. I was a goner but then the beautiful maiden rode up topless and slew the dragon. And that's how I met my wife"</li>
                <li>He sees his grandfather as a ghost telling stories as the officers keep demanding more</li>
                <li>Gpa sees Lars and goes "Larsius?"</li>
              </ul>
              <li>Falfinas asks him if he likes to know why people are bowing to Corpo?</li>
              <ul>
                <li>He's like normally I am used to being the runt of the group</li>
                <li>Corpo starts going back and forth between lies and truth..like he has people follow him around but is also the runt</li>
                <li>Corpo is like they come out of my lamp....</li>
              </ul>
              <li>Sparka takes the notebook to the meeting as Cecil pushes the button </li>
              <li>Sparka writes in the notebook "Cecil is on his way, I am on my way to the meeting in Stonehill"</li>
              <li>Sass writes: "Cool. Why?"</li>
              <li>Sparka writes "....For fuck's sake is it all ADHD cats on catnip here?"</li>
              <li>Sass: "Come hang out here, it's pretty cool"</li>
              <li>Sparka: "Our friends are in danger...the town we have tried to save is about to be overrun by cults of dragons."</li>
              <li>Sass: "Don't you want to see Corpo treated like a god?"</li>
              <li>Sparka: "I have absolutely no interest in that. Well I'll be at the meeting when you guys are done from your little whatever you are doing that's where I'll be. Changing the names on the deed to the manor"</li>
              <li>Sparka puts the lamp in the sarcophagus and a light casted coin</li>
              <li>Cecil appears from the lamp in the middle of the office seeing Corpo drinking with the Vice Chamberlain while Sass is taking notes</li>
              <li>"Ah me Jester! Thanks for joining us! Now you see two of my merry men"</li>
              <li>Corpo shushes the Vice Chamberlain who is quite irritated...Corpo is like can you help me find your Dwarf friend. He knocks and Murrelius to take him to the lobby</li>
              <li>Cecil's Draconic Gift is visible, Falfinas talks to him about the Venomfang marks</li>
              <li>"I'll stay with the Jester"</li>
              <li>"I take it you are not a manservant" "HAVE YOU SEEN HOW BIG I AM?"</li>
              <li>Falfinas is super impressed and starts calling in officers to see. "How did you do it?" Cecil is just like "Where the fuck are we?"</li>
              <li>Sparka writes: "Tell me before you come back through"</li>
              <li>Falfinas explains that they are in the First World, Thon Daral was Dwarven city with large amounts of trade. </li>
              <li>Way point and hub of portals to their own world some of other worlds...etc</li>
              <li>Explains that the leaders of the Empire, the Progeny, they were underdark while dragons ruled as Tyrants, but then showed up</li>
              <li>Says Empire keeps small folk, describes Empire as like 80 Million people</li>
              <li>"That's pretty dope" "It is my yoked friend...what routine are you on? Protein powder?"</li>
              <li>"Your friend offended me" "Yeah, he does that" </li>
              <li>"He introduced himself as the dragon" "Yeah, he does that"</li>
              <li>Is concerned about Dwarven friend...they are looking for him</li>
              <li>He points from his balcony at the various tunnels that they have dug, including water world and Kevin Costner</li>
              <li>Sparka writes: "What happens to the lamp on that side when we leave?"</li>
              <li>They figure out that they should do something about the lamp here just landing and falling and maybe someone else taking it</li>
              <li>Sass asks Falfinas </li>
              <ul>
                <li>If his people are in peace...he says on the edges it can be dangerous, but yes</li>
                <li>Sass asks if he would help deal with the Cult of the Dragon (bitches) and fight against evil</li>
                <li>Falfinas is unfamiliar but says he can introduce them to the Legate</li>
                <li>When they ask questions, Falfinas says let me take you to a scholar</li>
                <li>Falfinas, calls for Murrelius to bring the fool and take them to Arxius</li>
                <li>Falfinas gives them a map of Thon Daral and the Empire</li>
                <li>Falfinas says Gnoodnik Sushi is amazing..."I love local" Sass says "Fish is fish" Sass continues</li>
              </ul>
              <li>Should we not split up?</li>
              <li>Sparka attends the meeting</li>
              <ul>
                <li>Sparka is like Cult of the Dragon? Mushroom Queen? Halia?</li>
                <li>Halia turns around and is like whaaaaaaa? Just kidding</li>
                <li>Sildar is like we have to not die first</li>
                <li>Sildar asks Sparka about the Cult of the Dragon...says the group were weak but then they slew Venomfang..but he doesn't think just that is coming</li>
                <li>Sparka is like Adult Dragon might be coming...Blue-Eye White Dragon</li>
                <li>Maisie gives the one bit of intel...that White Dragon is leading from a flying castle serving as a mobile base. </li>
                <li>Sparka identifies that Cloud Giants have flying cities...but Giants tend to fight dragons so....weird</li>
                <li>Sparka knows that to summon Tiamat or any major dragon requires treasure...and a deity out of the lower planes involves blood....</li>
                <li>Sparka puts it together that the Cult is going to use Phandalin to kill all the people and the treasure AND to Summon Tiamat</li>
              </ul>
              <li>SPARKA: "Someone has to get back now but give me like 20 minutes. Send Corpo first. Shits about to get real over here"</li>
              <li>CECIL: "I have no idea where Lars is?"</li>
              <li>SPARKA: "I'll let you know when I leave the meeting"</li>
              <li>CECIL: "Ok let us know when to come back"</li>
              <li>Larsius is like "You alive?" Lars is like you are? Or...are you? "Can't keep a good dwarf done"</li>
              <li>Lars asks if he still has it. Larsius pulls up his robes and shows the birthmark</li>
              <li>Larsisus asks where he has been "All over the world. I was  in the war, I was raised in a Dworphanage, it was dark I got framed for murdering the king, I have a drinking problem"</li>
              <li>It'd be a problem if you didn't have a drinking problem like the sober one....He spits on the ground</li>
              <li>"I'm sorry you got framed for murdering a king"</li>
              <li>"I had debts, whores and drink" "I am proud...how? None of us have ever gotten rid of our debt"</li>
              <li>Lars is like I am trying to save this town, are there brave warriors here. They cheer, and he starts saying they love dragon slayers and that he lied about killing one by wrestling the night he met Lars' grandmother. </li>
              <li>Lars is like lets get these soldiers to come save our town</li>
              <li>Lars asks if he has heard of Ginobli the Most Endowed. Larius asks who he is....ME. I am proud, Glad I made you proud grandfather.</li>
              <li>Larsius doesn't remember if he doesn't recall the end if he was drunk or if he doesn't remember the end cause he's a ghost</li>
              <li>Lars asks what happened since...he says he woke up there was a dragon...folk slew it...now he is here</li>
              <li>People say he has unfinished business....be nice to drink or to go on</li>
              <li>Lars is like there is a guy I know called Thon Daral...Redlake...carved in all languages</li>
              <li>Lars like you want to come back to our world for your unfinished business</li>
              <li>Larsius has drinking game to get the officers drunk  (every time I pass through something) </li>
              <li>Then they leave the soldiers drunk and head back with Zor</li>
              <li>Cecil, Sass, Corpo go to meet Arxius</li>
              <ul>
                <li>"I am waiting for Sparka to tell us to come through since I assume he did something spiteful with the other lamp"</li>
                <li>"Do you think he needs immediate healing?" - Corpo "I don't know what the hell he needs" - Cecil</li>
                <li>CECIL: "Who do we send first?"</li>
                <li>SPARKA: "Corpo first, definetly"</li>
                <li>"How long as he been here?" "Like him...long enough" CORPO </li>
                <li>They ask Arxius how to make a larger portal to get more people through</li>
                <li>They refuse to give the lamp to Arxius...look with eyes</li>
                <li>Arxius is like Grimstone was shot through the planes to weaken the barrier...the lamp was cloned in a closest spot...the tunnel?</li>
                <li>Cecil had no clue about the tunnel lol</li>
                <li>Corpo asks to go to a bathroom with the lamp. Asks Sass to go with him</li>
                <li>Arxius tells Cecil that there are borders between planes that are thin...they ended up here since it would have been one of the path of least resistance</li>
                <li>Could have opened up in the Chromatic Pentarchy lands</li>
                <li>Arxius explains that if you are the one to break through you can control where</li>
                <li>On the edges they have dug and made runes </li>
                <li>CECIL: The lamp will stay here when we leave, it cloned itself at the path of least resistance</li>
              </ul>
              <li>In the bathroom Corpo says I'm going to press the button</li>
              <ul>
                <li>Corpo grabs Sass hand....opposing Athletics check....Corpo grabs Sass' hand and shoves him into the lamp.</li>
                <li>Sass wakes up in the tomb</li>
                <li>Sass is claustrophobic and starts screaming in the dark </li>
                <li>"You guys were supposed to send Corpo first!" -Sparka "He over powered my thumb!" -Sass</li>
                <li>Sparka explains that Cloudchaser is trying to bring Tiamat</li>
                <li>"We need to get the band back together" -Sass  "Yeah no shit" - Sparka</li>
                <li>Sass explains somethings and getting them to come through Avengers Endgame</li>
                <li>They decide they need a better name....Sass offers the rift and Sparka is like no the Bard will get it stay in your lane</li>
                <li>Sparka explains he didn't explain the Sky Castle yet</li>
                <li>Sass recalls CC talking about giants he knew and worked with...he'd tried to make an alliance with Giants that were in the Elder Elemental Eye</li>
                <li>CC wanted a castle that could fly cause that'd be cool as shit.</li>
                <li>"He tried to ally with them before...cause he thought it'd be cool...cause he's a d-bag" - Sass</li>
                <li>Sass says CC was an idiot...but an idiot totally devoted to Tiamat</li>
                <li>Sass is like hey we should summon Bahamut....Rolls NAT 1 and NAT 2</li>
                <li>Sass is like we got some people who are not just our friends to help us (more than just friends)</li>
                <li>Sparka is like wtf going to bring a whole army across one by one???</li>
                <li>Sass is like please let me talk..please</li>
                <li>Sass is like the Imperials have people that fight Dragons</li>
                <li>Sparka is like have we thought through letting the Empire of Blood who just likes to conquer through to our lands</li>
                <li>"Maybe it's an Empire of Virgin Blood" -Sass "That doesn't make it better, in fact, it makes it arguably worse" - Sparka </li>
                <li>SPARKA: Get back now, we need to talk, who is coming through next</li>
              </ul>
              <li>Lars wants to jump to the Flying City</li>
              <li>Corpo is walking around with the charged lamp "You may bow before my gigantic big dong"</li>
              <li>Corpo goes to search for Lars. They have the squardron</li>
              <li>Lamp is glowing and Sparka </li>
              <li>CECIL: Someone come through</li>
              <li>SPARKA: Yeah Sass is here?</li>
              <li>CECIL: Sass? Corpo was supposed to come through</li>
              <li>Cecil asks Arxius what about fighting dragons?</li>
              <ul>
                <li>Says the First World is special to them</li>
                <li>Chromatic dragons conquered, the free peoples hid underground</li>
                <li>Good drow allied with them to beat the bad drow, then came back on line</li>
                <li>He is a Progeny born but did not get powers so trained as Wizard</li>
                <li>120 years of absolute chaos when Kinaphalic Kingdom vanished</li>
                <li>Cecil asks what other planar travels?</li>
                <li>Air Elemental planes with Penguins....Jungle World....Water World</li>
                <li>Cecil asks him to hold onto the lamp and protec it for em</li>
              </ul>
              <li>CECIL: We need to find a place to secure the lamp</li>
              <li>Lars tells Legate that his town is under attack by dragons...and every empire needs a despot....if she can help defend the town of Faerûn...Phandalin...we have an election you can put anyone you want on the throne of a tiny. </li>
              <li>She is like that's a lot doesn't want the possible connection point overrun by Dragons....fungus....</li>
              <li>Legate sends Zor to go with Lars to scout out </li>
              <li>SPARKA: I want to try something let me know if the lamp stops glowing</li>
              <li>CECIL: I have no idea where the fuck the lamp is</li>
              <li>SPARKA: What the fuck</li>
              <li>CECIL: Corpo never brought it back</li>
              <li>Sparka walks out of the hideout and it stops glowing. Corpo also sees it stop glowing. Sparka pushes the button when it is not glowing and it does nothing. Corpo is like...uhhhh</li>
              <li>SPARKA: Find a place to hide it there, we can make it inert here</li>
              <li>Zor gives Sgt orders to get his squad ready to meet at the tunnel</li>
              <li>Lars tells him to vote Rutingnass </li>
              <li>Cecil shows Bowser to impress him as a slayer and asks Arxius since he is Erudite could he hold it for them</li>
              <li>Cecil is like you are fairly forthcoming...could you protect the lamp for us? Come to a deal? An accord?</li>
              <li>He is like sure...Cecil is like well I have no idea where the lamp is</li>
              <li>CECIL: I have no idea where in this world we know nothing about we are going to secure the lamp but I found a wizard scholar who has a mission to increase planar travel and he agreed to increase planar travel and he agreed to store the lamp for us</li>
              <li>SPARKA: Do you trust him?</li>
              <li>CECIL: Seems trust worthy</li>
              <li>SPARKA: We can make it not work here</li>
              <li>Cecil mutters that he needs to find the fucking lamp</li>
              <li>Cecil just starts blind launching Message to find him and finds Corpo</li>
              <li>Soldiers say I will meet you in the tunnel</li>
              <li>Lars is like Loyal Corpo, Your Erectitude</li>
              <li>Lars is like I secured an escort...not that kind of escort</li>
              <li>Cecil messages to Corpo Sparka needs us back. Corpo says sour pants needs us back</li>
              <li>Corpo says the cave</li>
              <li>Cecil says what cave</li>
              <li>Corpo says we are right outside..says he will light a fire??</li>
              <li>Sass is like I am with Sparka I am having plenty of fun...hey Sparka want to go to a meeting</li>
              <li>Lars slams axe "Boom Shaka Laka" and Cecil sees it. Soldiers are confused but one is like "It's his Erectitude he can do that"</li>
              <li>Cecil takes Arxius to hand him the lamp after they leave</li>
              <li>The party goes to the tunnel with 15 soldiers plus Lt Col and Sgt</li>
              <li>Lars shouts the Dragon entered through the wall since he had no idea</li>
              <li>CECIL: We have 17 soldiers</li>
              <li>SPARKA: Do not let anyone else through</li>
              <li>Lars walks into the wall... bonk</li>
              <li>Cecil tells Corpo goes through first</li>
              <li>Sparka is like wtf let's talk</li>
              <li>Cecil whispers to Lars saying Sparka says no one else for now</li>
              <li>Lars is like cool...you go first...Cecil is like wtf no me last so I can hand it off the Arxius </li>
              <li>Larsius is like the Genie went in the bottle!</li>
              <li>CECIL: The grandfather the ghost is coming over</li>
              <li>SPARKA: Is that ok?</li>
              <li>CECIL: Does he even have a thumb...I don't know</li>
              <li>Lars pushes the button with arm wrapped around grandfather...but only he comes through</li>
              <li>Cecil tells the sober soldiers to not come through...they are like we have a deal we have to come. Cecil is like don't come...they are like no...he is like I will return to our world then when we get the rest of you through I will let you know</li>
              <li>Cecil NAT 1 Persuasion Check to convince them not to follow</li>
              <li>Cecil comes through and then runs and hides the lamp in one of the sheds near the manor</li>
              <li>Sass wants to trust the Empire of Blood</li>
              <li>Corpo is like why do we have to come back from the ostensibly the coolest place ever</li>
              <li>Sparka is telling Lars that Tiamat is coming, Flying Castle, Refugee Crisis on south of town is a ploy for the sacrifice</li>
              <li>Lars is like that's fucked up, but this place your name was carved in stone...merchants everywhere...the air was free...the town of Waukeen</li>
              <li>Sparka is like...wha...whoa...what</li>
              <li>Lars is like can we go back right now?</li>
              <li>Sparka is like I just explained....it won't...exist</li>
              <li>Lars is like the other place will still be there</li>
              <li>Sparka is like WTF no</li>
              <li> Lars is like let's stand on each other's shoulders</li>
              <li>Sparka is like we need to check the encampment to the south of town and figure out what we can do</li>
              <li>Sparka is like we need to disperse the treasure in the castle or disperse the people</li>
              <li>Corpo is like can we just kill the people so they aren't there? NO</li>
              <li>Ryan asks about Wildon's broach everyone is like THAT'S WHAT YOU ARE CONCERNED ABOUT</li>
              <li>Lars is like we need to scatter them</li>
              <li>Lars thinks Qeline is spreading disease</li>
              <li>Sparka is like should we send refugees to Empire of Blood?</li>
              <li>Corpo is like should we kill them all with fire?</li>
              <li>Cecil is like...the trolley problem?</li>
              <li>Then they are like we could use the Scroll of Opening to send them to? It seems to keep the portal open permanently. You could get rid of refugees there and and army here</li>
              <li>Sass is like let's send Tiamat there? SPARKA IS LIKE WE DO NOT WANT TO DO THAT THEY ARE THE BEST</li>
              <li>Corpo still wants to burn the innocents</li>
              <li>Sparka is like lets double prong this...try stopping the ritual without needing the Empire</li>
              <li>Sparka is like maybe we send the diplomats instead of drunks to talk to the Empire of Blood</li>
              <li>Corpo says some stay here...some go to the camp to scope it out and figure out if we can throw a wrench into the ritual....also trying to find a way to evacuate the village</li>
              <li>They are unsure how to get the treasure out of the castle....</li>
              <li>Wildon arrives and says a band of refugees came in saying the camp is a small band led by Cloudchaser's champion in a white dragon mask. Flying up and back from the Skycastle on Wyverns</li>
              <li>Wildon says apt at stealth you can possibly sneak in. The Cult is super confident.</li>
              <li>Sparka asks how many are there. They say that the cultists are super confident, 3-4 sometimes but it can vary</li>
              <li>Sparka says we will see you at 8PM tonight at the inn. </li>
              <li>Wildon says Zilestor cooking at the Inn</li>
              <li>Wildon sees the broach on Lars...Lars is like it itchy and bumping yet....Wildon is like I can heal baby</li>
              <li>They debate what to do with the Scroll of Opening</li>
              <li>They recall Avlod took a long path to get from the worlds. Avlod would have loved the scroll of opening</li>
              <li>Sparka recalled that the Empire was surrounded by others...the Hobgoblins and them had been allies of necessity and might have to be again.</li>
            </ul>
          </Collapsible>


          <Collapsible
            title="Birthrights and Plans (#17 - 9/9)"
            toggle={this.collapseToggle.bind(this)}
            kind="session17"
            show={this.state.show}
          >
            <ul>
              <li>Crew does a recap to figure out exactly what is happening</li>
              <li>They debate what is the deal with Empire of Blood...human sacrifice? No evidence so far...</li>
              <li>They figure out that they need Wyvern to fly up...like stealing an irritable dragon-horse</li>
              <li>There are 3 wyverns hanging around...they can carry two</li>
              <li>Sparka is like wait ah it's the Empire of Blood? No one said that in the paired notebook. </li>
              <ul>
                <li>Sparka is like Waukeen he a fan of...like his adopted father cause she helped take care of the Tavern Owners</li>
                <li>He begins to explain Thrift's Wandering Post...says once a sun he can travel to plane and trade</li>
                <li>He shows em the ring says those potions? I traded them from an inter-dimensional penguin merchant</li>
                <li>Says he met Yanni, figured out with his amulet that it who was actually an Ancient Emerald Dragon...Daurotham </li>
                <li>He was from the First World...Primavera...</li>
                <li>Says Empire of Blood is cause they believe power comes from blood</li>
                <li>Explains Dragon Echoes sorta correctly but does a little more like DOTA</li>
                <li>He shows them the amulet and the blood swimming around inside</li>
                <li>He says he doesn't think that place is THAT dangerous</li>
              </ul>
              <li>So the party is like maybe they would accept the refugees? Need to do scroll of opening</li>
              <li>I ruined my reputation</li>
              <li>Sass is like no one knows where Daran is...everyone is like wait he is right there..</li>
              <li>Sparka is like maybe Cecil and I go negotiate and the three of you to go do the scouting party</li>
              <li>Sass is like you want the three of us here?????</li>
              <li>Sparka is like where do we open the portal with the Scroll of opening...they recall the rift in Tresador Manor goes through the center of town</li>
              <li>Waukeen is about taxes if they keep the gold flowing</li>
              <li>Sparka is like Slavery bad to Waukeen you idiot Corpo is like SERFDOM Look what happened with capitalism</li>
              <li>Cecil is like lets take one paired notebook and leave the other one with the most responsible person....lars is like ME!</li>
              <li>Lars is like...so we got two hours...might be our last night in Phandalin.... NO NO NO</li>
              <li>Sparka is like let's take away the blood not by killing the people Corpo</li>
              <li>We need to get the treasure A for ourselves B so they can't complete the ritual</li>
              <li>TIAMAT IS COMING</li>
              <li>His uncle is trying to summon Tiamat...and...that's fine...</li>
              <li>...It's in a castle 2,000 feet in the sky...</li>
              <li>Sass knows that they </li>
              <li>YOU HAVE TO DISPERSE THE TREASURE</li>
              <li>"It sounds like you are saying win the election"</li>
              <li>"In the words of Bahamut 'YEET'</li>
              <li>Qeline smells like Fish</li>
              <li>It's still daytime so they decide that Cecil and Sparka will go to there while the other three rep at the meeting...give the </li>
              <li>"Our plan is to open a portal to another plane and convince 200 peasants to walk through it..which might be difficult but we will get to that"</li>
              <li>"...and the city is named after my last name which is fucking weird and possibly important"</li>
              <li>They grab the lamp and go into the manor and Xaryax pops out, says he took oath to Lars to come so he did, but told others to wait</li>
              <li>He is like whoa...this Sparka Redlake guy has a crazy last name and a dragonshard and...whoa...</li>
              <li>Lars is like perfect you are here we have many uses for your soldiers....SPARKA LIKE NO WE ARE NOT</li>
              <li>Sparka takes the lamp to where it does not work, tells Xaryax can we bring 200? He says probably but check with Legate or Vice Chamberlain</li>
              <li>Leave me out of any conversations, Corpo</li>
              <li>Sparka is like hey we need the people to decide to leave or not...Sass is like or be sacrificed...Sparka is like we will deal with this</li>
              <li>Sparka is big on consent...not us are</li>
              <li>Take the span read and let us...WORST DECISION EVER</li>
              <li>I'll take Tweedle Dee and Tweedle Dum</li>
              <li>Send Lars and Corpo on their own???</li>
              <li>Sass was a secretary and then got accosted in a bathroom</li>
              <li>Sass goes through and meets the soldiers, Xaryax, ghost Larsius, Arxius, and Zorarkir</li>
              <li>Sass is like we are waiting for my party member Sparka Redlake</li>
              <li>BUT Sass gives some background on the urgency...town under attack by former master...he keeps going</li>
              <li>The lamp slightly glows as Sparka emerges</li>
              <li>Mike calls Xaryax Sgt. Xar-san and asks about the crest on his "Chesticles"</li>
              <ul>
                <li>He says their great kingdom of Dragonborn was divided 120 years ago</li>
                <li>Krateo went way of Strength</li>
                <li>Timoc went way of Honor</li>
                <li>Used to be Strength and Honor under Bahamut</li>
                <li>"Shiny may his scales be" "Fly so high"</li>
                <li>Xar came to serve in the Empire under oath until he can find his missing king in the line of Kinaphalic Amethyst Dragonborn. He is working in this area to hopefully find their king and restore the kingdom</li>
                <li>PRAISE BAHAMUT: Fo sho fo sho" "Wide may his wings be" "Low may his _____ hang"</li>
              </ul>
              <li>They welcome Sparka and he is publicly wearing the Dragonshard</li>
              <li>Zorakir is like who shall I take you to?</li>
              <ul>
                <li>Vice-Chamberlain Falfinas most power but red tape</li>
                <li>Legate military but might be not as comfy</li>
                <li>Sub-Inquisitor might be fastest to get in but might ruffle feathers</li>
              </ul>
              <li>It is explained to Sparka there are like a few hundred of these Shards</li>
              <li>"Anything to help defeat evil Dragons" "TIAMAT BAD" the soldiers repeat "Bahamut Good!"</li>
              <li>"Time is of the essence we have like an hours and half"</li>
              <li>So much sass from Sass about Primavera being tasty</li>
              <li>Sparka sees Redlake carved into the wall...notices a scorch mark from the Sunbeam from the battle with the Dragon</li>
              <li>Cecil takes out Span reed "hope you guys made it off to the meeting" </li>
              <li>Lars wants to pregame the meeting, no one says anything</li>
              <li>The three enter the Stonehill Inn</li>
              <ul>
                <li>Cecil brings Sildar, Qeline, Maisie up to speed</li>
                <li>Lars buy them all a round</li>
                <li>Cecil is like btw we have portal to another plane and we will evacuate the town to avoid the blood sacrifice</li>
                <li>Lars gets them all a shot</li>
                <li>"I'll take your bottom-est shelf brown stuff" "What if it is supposed to be clear but is brown?" "Even better"</li>
                <li>Sildar is like why didn't you tell us sooner? </li>
                <li>Qeline is like how are we going to evacuate? Lars is like we have ways. She is like oooooooh? Cecil is like keep it in your pants. Qeline like It's in his pants now</li>
                <li>Cecil like we want to talk to everyone like Qeline the assumed next town master...Lars like wait welll maybe not</li>
                <li>Cecil is like we need consent to permanently open a portal...Sildar like not sure we have a chance...don't want them to die</li>
                <li>Qeline is asking for Lars' input</li>
                <li>It's this or I try jumping to the sky</li>
                <li>Corpo asks what they have discovered, Not much new but the ritual will be in the castle, Wyverns coming up and down. They noticed Gold Elf (Hadrian) and a Champion of Cloudchaser. The soldiers are super lax and overconfident</li>
                <li>The group discusses if the Giants and Dragons are working together.  Cecil knows via Arcana check that Controls for flying castles can be quite complicated. Best guess is giants are working with the Cult or blackmailed to work with them</li>
                <li>NAT 1 Corpo Arcana check to know about Giants and Dragons.</li>
                <li>Lars and Corpo debate tying Cecil up surprisingly in a non-kinky way</li>
                <li>Cecil explains that the scroll as an evac plan and asks if they consent to plan and they are like can't not...Cecil is like yeah  "It seems like a safer place than a fucking dragon being summoned."</li>
                <li>Corpo takes notes in spanreed about the meeting</li>
                <li>When Sildar is concerned about them following....Corpo says Sparka is part of the Mile High club of Primavera...no one knows what exactly it means but Qeline moans. Cecil is like they are more Explorers...as Robert points out no one says anything about their name</li>
                <li>Oh why they won't follow? Corpo is like we working on that...maybe the portal is at least a bottleneck</li>
                <li>Reveal that Dragons attacked Neverwinter but were repulsed</li>
                <li>Is that Michael Jackson's place</li>
                <li>Cecil relays the info via Span Reed</li>
                <li>Sparka is like raise the men of the town who could fight...and be ready in case they have to hunt down the portal</li>
                <li>Corpo is like...the election?</li>
                <li>Cecil gets a 28 on Persuasion and they are buying it. Qeline asks him to talk later</li>
                <li>They debate when to do it. Lars is like...under cover of dark? Best fucking idea Lars has ever had </li>
                <li>Cecil relays we are good on plan here...should we try starting the scroll....Sparka is like after we get permission from the Chamberlain</li>
                <li>Corpo is like all three of us are going...</li>
                <li>Cecil tells the people to start resting but be ready</li>
                <li>Qeline is like so...we should drink now? Lars is like we should drink and bed now</li>
                <li>Qeline points to a Half-Giant in the corner and a bulky Half-Elf on a rugby team from Neverwinter....says she is hosting the whole team. Lars is like ewwww. They all have broaches</li>
                <li>Lars mutters...I'm the most endowed...Qeline shrugs</li>
                <li>Cecil NAT 20 Insight notices Halia with a small wine glass looks disgusted by Qeline.</li>
                <li>Sass wonders aloud if Halia is available...it's been a while for Sass</li>
                <li>Corpo saddles up and is like village bicycle eh? Halia is like yes and...THE ONLY ONE RUNNING FOR PUBLIC OFFICE</li>
                <li>Corpo asks if she has considered public office...she says her path in life is not elected office</li>
                <li>She says people saving the town would be great way to endear the town to them</li>
                <li>Corpo pushes but she is like all of you could be candidates. Lars gets happy despite sad drinking</li>
                <li>Corpo asks if the people need a strong right hand..or....Mike: a gentle left hand?</li>
                <li>Corpo asks if they need to be massaged to be lead?</li>
                <li>Halia says somewhere in the middle probably... the town she says has been through a ton. </li>
                <li>Halia also says that the town has a priceless mine nearby, possibly a portal to another world...Corpo shifts his pants trying to hide his raging boner. Everything he wanted to hear. Robert thought it was shit his pants. Cecil is like what does he have to hide if it is 1.5 inches. Genasi culture 1.5 inches is quite a bit</li>
                <li>Cecil has no interest in running for public office...what do you think Cecil of Qeline..."The Harlot" running unopposed? She is about to get Team-Banged... Cecil is like I said it moments ago but no one laughed? </li>
                <li>Corpo mentions that Sparka is like Elite status which could help him</li>
                <li>Lars is a stable-ish Corpo says...Cecil is like Lars is a man of the people</li>
                <li>Lars is noticing other people with broaches as he drinks more and more...sad about Qeline being the first love he hadn't paid for in a while</li>
                <li>Corpo is like should we interrupt Qeline mid-Coitus?</li>
                <li>Cecil is like maybe we should worry later about the election later....blood sacrifice first....</li>
                <li>Corpo goes to check with Sildar</li>
                <li>Adam's reaction to the Lars-Corpo plot</li>
                <li>Corpo and Lars officially decide not to subdue Cecil and run off</li>
                <li>Corpo is like we should scout...Lars is like we were going to wait til dark I've been drinking</li>
              </ul>
              <li>Sparka is talking to Arxius </li>
              <ul>
                <li>Sparka is like you have studied portals</li>
                <li>He is a bit of Scientist himself</li>
                <li>So we are trying to open a portal...is there a way to keep people from using it poorly?</li>
                <li>What if the portal opens in to Qeline's Room? An entire regiment?</li>
                <li>Arxius says that most worlds are like Faerûn, small city states that get fucked over whenever a dragon or whatever shows up...so Qelines? Oh you have Qelines? It's Canon now. Wide Swaths of Qeline get destroyed by dragons, demons, rugby teams?</li>
                <li>He says at the end of the day they have some gates but in general opening portals the Empire is confident that their military can hold it off like they have held off countless other terrors</li>
                <li>Sparka asks Arxius, as a non-military man, should they have concerns?</li>
                <li>He tells Sparka that large organizations are helpful, but can be dangerous if taken over by another. Currently multiple factions, religious groups really...at the end of the day you have to ask your self what's worse. </li>
                <li>Join a multi-planar organization...lot of stakeholders</li>
                <li>He asks Arxius about the Zhentarim...he says factions from Faerûn came here, Lord's Alliance evolved into a large group that the Empire is a part of. Zhentarim might not exist in same form...differing </li>
                <li>He says that he Empire is not a huge fan of Chaotic...but Harpers are underground now. Most people in the Empire ignore them, let them be...but some would like to hunt them. Chaotic Deities are somewhat ignored</li>
                <li>PRAISE BAHAMUT: Long may his long wings shield us from the sun. </li>
                <li>PRAISE BAHAMUT: Long may he soar so gloriously in my face</li>
                <li>PRAISE BAHAMUT: May his wingspan shadow my nether regions</li>
                <li>Arxius says please come talk to me each time you have questions...says he will get Amarillis next time who would love to talk to you</li>
                <li>Sparka asks about the Spellplague, says when it happened here the Kinaphalic Kingdom disappeared</li>
                <li>Sparka is like hey I mighta found the Kinaphalic, gesturing towards Sass. The Empire would love the Dragonborn to be united, so he might be taken away from them.</li>
                <li>PRAISE BAHAMUT: Long may his talons scratch out the eyes of his enemies. </li>
                <li>PRAISE BAHAMUT: May his horns be so sharp...and horny</li>
                <li>PRAISE BAHAMUT: May the shimmering light of Platinum illuminate our lives</li>
                <li>PRAISE BAHAMUT: May he be so shiny....so shiny</li>
                <li>Sass is about to talk about Kinaphlic...when Sparka is like can I bend your ear, he doesn't have ears....but he tells Sass not to bring up the Kinaphalic Kingdom</li>
                <li>I'm gonna hold you to it, cause that's my last name....you get to have all the last name fun...it has your name on it...do you own this mountain...I don't own this mountain</li>
              </ul>
              <li>They are taken to the Chamberlain Falenas Polilli</li>
              <ul>
                <li>He has a slight halo</li>
                <li>Not my consort</li>
                <li>Sparka is like we need...begging you...permission to bring them here to save them and prevent the summoning of Tiamat (oh that is where they are trying it these days)</li>
                <li>Sparka is like my bro Cecil has the means to open a permanent portal</li>
                <li>Sparka is like just one dragon...Chamberlain is like surely there are others around</li>
                <li>Sparka is like we have 200 or so peasants you have an epic military that could kick ass. Sparka is like we can make it hidden hopefully keep them from knowing about it</li>
                <li>Murrelius comes in, Chamberlain is like hey lets make sure we are ready</li>
                <li>He is like I am going to treat you Dragonshard bearer as the ambassador of your entire world (Sparka is like...ok....)</li>
                <li>Sparka is like we give you what you want...a portal open...you help these people...just keep lines open.</li>
                <li>They will discuss the portal and the Dragonshard afterwards</li>
                <li>They clasp forearms as they way of sealing an agreement in this world</li>
                <li>Sparka is like please take special care of the orphans</li>
                <li>Sparka writes to Cecil we are ready to open the portal they have agreed we can open mere hours from now. Will be over as soon as we can.</li>
                <li>PRAISE BAHAMUT: Long may the Platinum Light Shine</li>
                <li>PRAISE BAHAMUT: May his wings be so unfurled..so unfurled...no furling</li>
                <li>Cecil goes back with the lamp as Lars buys more rounds</li>
                <li>Sparka is like I approve this and thank you much and I look forward to continuing this opportunity. We need a safe place to head back to our world</li>
                <li>You are in Sales</li>
                <li>Sparka says lets talk next time about the Dragonshard</li>
                <li>Sparka goes first through the lamp</li>
                <li>Sass hangs for 5 minutes, keeps mouth shut for a bit...then asks about badass dragon killing weapons. Chamberlain hands him an Arrow of Dragon Slaying... then follows back through</li>
              </ul>
              <li>They meet up</li>
              <ul>
                <li>Cecil  wrangles Lars from the booze</li>
                <li>SOBRIETY CHECK</li>
                <li>Sass NAT 1 Arcana to figure out Scroll of Opening</li>
                <li>Lars NAT 1 Persuasion to think on the Scroll of Opening</li>
                <li>Corpo, Cecil, Sparka figure out that the ritual to open the scroll will be like loud, bright, very explosive, beam of light up into the cloud</li>
                <li>Will take 10 minutes to do the Scroll</li>
                <li>So it is Bob Rohrman</li>
                <li>They will know where the portal opens...will not be subtle</li>
                <li>Sparka says take the wand of pyrotecknitcs "As a sign of my love and fidelity"</li>
                <li>Cecil is like ready the town?</li>
                <li>It will be super bright...why don't we go high noon when lights aren't as bright</li>
                <li>Lars tries weather forecasting..not great...DOesn't think the heavy stuff will be coming down for a while yet</li>
                <li>Sparka and Cecil will go through the town to warn them while other three head out and scout</li>
              </ul>
              <li>At 8 o Clock Lars drums up support in the bar</li>
              <ul>
                <li>Lars drums up some support in the bar, low bar to convince them not to just sit and die. My grandfather is there...the EMPIRE OF ....wait....uh.....love</li>
              </ul>
              <li>Sparka is going around to other people's houses, warning them all</li>
              <li>Some people are like one last drink before the end of the world</li>
              <li>Corpo checks for Tiamat worshippers, Independence Day....finds them. One 70 year old man who he throws into the jail cell with the corpse</li>
              <li>Sass goes to Daran Daran before going to scout</li>
              <ul>
                <li>He has a table like civilized people...Sass places the claw from Venomfang on the table</li>
                <li>Daran will spread word that the honor has been restored</li>
                <li>Daran has magic arrows, doesn't recall what he told him to come back for he was drinking his cider too much</li>
                <li>Gives him an Arrow of Dragon Slaying</li>
                <li>PRAISE BAHAMUT: May his wings be so wide and girth-y (girth-y or veiny?) There been both</li>
                <li>Sass tells Daran that the Cult is trying to summon Tiamat, we could use your aid or please go towards the giant light signal...jk just meet in the square</li>
                <li>Daran is like I can help you...let me know...Bahamut blessed me. Otherwise I am ready to retire....if you need me to keep gong on a few more decades....let me know...most. </li>
                <li>Yeah we are going to need you...if I have to stayu</li>
                <li>PRAISE BAHAMUT: Get him a bourbon</li>
                <li>PRAISE BAHAMUT: May his bourbun glass be full</li>
                <li>PRAISE BAHAMUT: May he drink the blood not of virgins</li>
              </ul>
              <li>Cecil and Sparka go about rousing the town...Sparka does ok and Cecil rolls a 27 so town is generally buying it.</li>
              <ul>
                <li>They think it is a great idea and are willing</li>
                <li>Are they as willing as Qeline?</li>
                <li>They debate not telling Qeline of the Rugby Team</li>
                <li>Lars did not inform the rest about Qeline having a husband...Sparka's jaw hits the floor</li>
                <li>Sparka debates killing him...then realizes he should put a chastity belt on Qeline...it might be a tough roll</li>
              </ul>
              <li>The three of them approach....naked from the north in mother nature's night camo</li>
              <ul>
                <li>Lars is drunk</li>
                <li>Corpo and Sass notice 4 beings fairly spreadout</li>
                <li>They see 3 Wyverns swoop in and land, they trade out for the other 3</li>
                <li>Lars is doing his dance??? I assume not...actually it is a belly dance. CANON</li>
                <li>Sass notices one of the riders is a Gold Elf (noticed by Lars as the one) Sass notices a grunt Dragonborn. He sees a being in a White Dragon Mask wearing Gaezelle's body. But they also last saw Thopleth looking Gaezelle's body looking like it has been infused with powers of Hells. Sass and Corpo know about Abishai</li>
                <li>Cecil has no relation with that side of the family</li>
                <li>They say next watch will see you at 9AM</li>
                <li>Cecil writes on Spanreed: Hey guys how's it going?</li>
                <li>Sass is like I was able to hear with my tiny earholes</li>
                <li>I said it was 11 PM but actually more like 9 PM</li>
                <li>Corpo, Sass, and Cecil decide to ambush them</li>
                <li>They realize that they have done nothing with the mushroom fuckers who are also coming to the town</li>
                <li>Sass writes note back: My tiny earholes heard next watch is at 9AM. We are going to take these MF-ers with us out at 8:30AM (Lars wrote the last part to intentionally lie) </li>
                <li>Cecil writes back: The next watch is at 9, do not jump them before 9 and we can jump them at first light when no watch is going on</li>
                <li>Lars writes: Just come when you can we are going to rest</li>
                <li>Sparka and Cecil write: Ok come back to town and good a good night's rest</li>
                <li>Lars writes "..."</li>
                <li>Lars: Pretty tired going to stay here</li>
                <li>Cecil: What about the Wyverns and the people here?</li>
                <li>Lars: Good night</li>
                <li>Sparka and Cecil are like..shit...let's go</li>
                <li>Cecil: We have plenty to talk about coming to you</li>
                <li>Lars says nothing</li>
              </ul>
              <li>AMBUSH THE CAMP</li>
              <ul>
                <li>4 locals seem to hear and start scanning</li>
                <li>Corpo rushes sneakily towards a half-elven woman....activates the tattoo as like Venom...but blue</li>
                <li>Corpo attacks with staff NAT 20 for 17 damage Kills her by dragging her into the bushes and snapping her neck</li>
                <li>Sass hits stronger one with ensnaring strike for 17 damage, shoots again with a +1 arrow for 17 damage arrow breaks</li>
                <li>Sass is like feel a little bad it's a girl and Corpo is like they are summoning Tiamat so they can go to hell. Arrow prevents lactation </li>
                <li>Scout and Archer take cover</li>
                <li>LARS throws javelin, misses first, second NAT 20 for 8 damage</li>
                <li>Archer shot Sass</li>
                <li>Corpo NAT 20 punches for 15 damage, misses punch, misses staff, hits staff</li>
                <li>Sass NAT 20 kills archer by shooting in other breast straight through the nipple</li>
                <li>Corpo goes to tea bag her...but it out of movement!</li>
                <li>Sass kills the scout male straight through his wiener into the fire </li>
                <li>Remaining archers miss Lars</li>
                <li>Lars hits twice for 28 damage</li>
                <li>Corpo does summersaults  and breath of dragon with LIGHTNING BREATH and misses LIGHTNING PUNCH</li>
                <li>Sass hits with ensnaring strike</li>
                <li>Last Archer surrenders and is restrained</li>
                <li>Sass find 24 normal arrows and a +1 arrow</li>
                <li>Wyverns are freaking out</li>
                <li>They hear and see Cloudchaser flying in the sky in the distance</li>
                <li>Sass shouts PRAISE BAHAMUT: MOTHERFUCKER at Cloudchaser</li>
              </ul>
            </ul>
          </Collapsible>

          <Collapsible
            title="Cloudchaser (#18 - 10/4)"
            toggle={this.collapseToggle.bind(this)}
            kind="session18"
            show={this.state.show}
          >
            <ul>
              <li> Recall that Corpo found a 70 year old man </li>
              <li> Sparka takes North side of town </li>
              <ul>
                <li> Sees Elmar Brathen with a Diemondar Crystal </li>
                <li> He is groggy...Sparka is like hey dude Cult of Dragon is overhead with a Sky Citadel  </li>
                <li> I know it sounds crazy but we have a way out...a portal to another realm  </li>
                <li> He asks "What about my small business?" "Hopefully will be here when you get back"  </li>
                <li> Says he knows that Sparka would not have said so unless super important </li>
                <li> Sparka asks about the Crystal...he is like whatever...idk...huh </li>
                <li> He is like idk kid got it at school? That's what being a parent is...throwing art projects away? </li>
                <li> Sparka pushes him...he is like idk how long I've had it...I've...had it...idk...been a while...what.... </li>
                <li> He sorta weird, Sparka can't quite get a read on him </li>
                <li> Corpo whispers somewhere else to use Fireball </li>
                <li> Sparka debates using Detect Thoughts </li>
                <li> Elmar is like it's a piece of junk who care...Sparka offers to pay...and he is like Sam in LOTR with the Ring...can't let it go...something odd. </li>
                <li> Sparka with Arcana knowledge thinks there is magic on it to make Elmar not think about it but forget it </li>
              </ul>
              <li> Maisie approaches Cecil </li>
              <ul>
                <li> Drunk saying Lars bought the bar brown stuff that's supposed to be clear </li>
                <li> YEP that's the plan </li>
                <li> Maisie gives a Rhythm Maker's Tuning Fork </li>
                <li> Maisie is like some guy is walking up to him...it's his Type...Half-Elven </li>
                <li> IT IS THE SUCCUBUS </li>
                <li> She proposes helping him know things about his Uncle, his Cousin, Cult Summoning Tiamat...etc </li>
                <li> He is like what you want? </li>
                <li> She is like first person to make an offer is at a disadvantage </li>
                <li> He is like no thanks </li>
                <li> She is like ok bye </li>
                <li> He is like how I get a hold of you </li>
                <li> She is like I'll be around...deal was that I should stop messing with the town...not that I should actually leave </li>
              </ul>
              <li> Sparka and Cecil meet up on way out of town. Cecil asks about Diamonds for Revivify </li>
              <li> Cecil has one Revivify Diamond. Sparka gives him two more. SO HE HAS THREE </li>
              <li> Sparka hears in his head: </li>
              <code>
                SPARKA!! It’s me Selma and
                Karvin!! A traveling wizard charged
                Us to send a sending
                spell of 25 words to
                You! So we want to
              </code>
              <li></li>
              <code>
                Ahh! We hit 25 words!
                Silly me! I’m so happy
                At the thought of talking
                To you that I lost
                Track of the count. You
              </code>
              <li></li>
              <code>
                Ahhhhh! Again!! Ok. Love you.
                Where you. Miss you!
                We on a trip now.
                Hope to see you. We go
                To the south east to
              </code>
              <li> (Know that most beings have 3 spell slots at 3rd level) </li>
              <li> Realizes too late that he has 25 words to reply...only has 15 due to low Arcana roll </li>
              In Phandalin. Neverwinter is safe. Go there. Dragons Here
              <li> Sparka is like...ohh...I really should have called more...  </li>
              <li> Homesick a little, resolves to get in touch with them in the next couple days </li>
              <li> Is sure that they will listen to him </li>
              <li> Corpo and Cecil hear Lightning punches from Corpo. Run to ambush site </li>
              <ul>
                <li> Sass sees Cloudchaser fly into the side of the floating castle island...not the castle itself </li>
                <li> "I surrender" as an arrow hits the last cultist and brambles and vines spring up to hold them in place </li>
                <li> "CORPO WHAT THE FUCK?" Cecil asks </li>
                <li> Corpo ignores him and attacks </li>
                <li> Cecil "Lars what happened?" </li>
                <li> Lars: "We woke up. Decided it might be morning" </li>
                <li> Cecil: " You're naked!" </li>
                <li> Lars: "That's how I sleep!" </li>
                <li> Corpo attacks the restrained surrendered prisoner without asking a question </li>
                <li> Corpo undoes Sass' spell for no reason </li>
                <li> Corpo started to quarter him? </li>
                <li> Corpo yells WHERE DID THE DRAGON GO? </li>
                <li> Sparka casts Earthern grasp under Corpo...Corpo breaks out with his inky black tentacles </li>
                <li> Corpo asks where they land the Wyverns...he says HERE. They are over there!  </li>
                <li> Corpo says where up there. He is like I DON'T KNOW I DON'T FLY THEY LAND THEM HERE </li>
                <li> Lars intimidates prisoner asking what do the scouts look like.  </li>
                <ul>
                  <li> He is like it depends on the time...last one were the big deal </li>
                  <li> One with White Dragon Mask </li>
                  <li> Other one looks like the fucking devil (actually is) </li>
                  <li> And another guy </li>
                  <li> They come check and leave </li>
                  <li> Next is do at 9AM </li>
                  <li> WHAT is the trick to getting on the Wyvern? Lars asks..what do scouts do? </li>
                  <li> he says they walk up, hold up hands, command words in Draconic I don't speak it </li>
                  <li> Lars is like hey give me what it sounds like </li>
                  <li> He tries....Lars asks if they know what it is?  </li>
                  <li> It says please massage my Kidney  </li>
                  <li> Lars was like we done with this mofo? </li>
                  <li> SPARKA LEAVE HIM ALONE </li>
                </ul>
                <li> He is like I just signed up to pay for my college...they are crazy </li>
                <li> Cecil your turn </li>
                <ul>
                  <li> Cecil: "WHAT THE SHIT" </li>
                  <li> Lars "You want us to put our pants?" </li>
                  <li> Cecil "FOR STARTERS YES" </li>
                  <li> Lars "Nothing gay going on" </li>
                  <li> Cecil: "What the hell happened? Last I heard you said good night now you can't see  I see there are corpses" </li>
                  <li> Lars: "If you haven't noticed in the last week I get the shakes a couple hours after sleep" </li>
                  <li> Cecil: "So...what is going on?" </li>
                  <li> Lars: "We decided to take action into our own hands" </li>
                  <li> Sparka: "That's your first mistake" </li>
                  <li> Sass: "We did take care of four of them" </li>
                  <li> Lars: "We handled it" </li>
                  <li> Sparka: "You decided that was your mistake...you were supposed to wait!" </li>
                </ul>
                <li> They were like we got their uniforms, Sparka points out they did not need to kill them </li>
                <li> Cecil is like we had a plan to open the portal at noon and now we have to clean this mess up </li>
                <li> Lars wonders if they can get citizens to fill in </li>
                <li> Sass wants to weekend at Bernies the guards and thinks it will work </li>
                <li> Cecil is dumbfounded he thought he had no reason not to trust them previously  </li>
                <li> Lars. is like that's fair </li>
                <li> Cecil is like it was impressive to shoot through the nipple...but it was dumb. Great costume outfit to put on... </li>
                <li> Cecil like you were sent to watch </li>
                <li> Sass well yeah they looked devious </li>
                <li> It's like you only think 30 seconds ahead? </li>
                <li> Lars: "We place checkers not chess here"  </li>
                <li> Confirms that they saw Abbishai, Cloudchaser's champion wearing the mask </li>
              </ul>
              <li> Large Wyverns are like REEEEAAAAAAAAAHHHHHH </li>
              <li> Lars is like do we hop on them now?? Sparka is like....noooooooooooooo </li>
              <li> Cecil is like WHAT ABOUT NOON FUCKER </li>
              <li> Sass goes Animal Handling amazing with a dance and the two male and bow...the female curtsies </li>
              <li> It will buy them a little time.....SHOULD LARS COME HANDLE THE ANIMALS? </li>
              <li> Sparka walks up and reads their names </li>
              <ul>
                <li> Rufio </li>
                <li> Deirdre Anastasia Haspburg Alexandarite Bonaparte IV  </li>
                <li> Brian, he looks the most chill </li>
              </ul>
              <li> Corpo says he likes the dumb one, Brian points to Ruffio and snickers </li>
              <li> Sass like...wait what are we trying to accomplish </li>
              <li> Corpo drops the prisoner and asks his name </li>
              <li> I am Shazzeem </li>
              <li> Corpo asks Shazzeem if you know my...daddy...Tel-Amoth </li>
              <ul>
                <li> Asks...what is it in for me </li>
                <li> Corpo is like...I am not going to kill him to the party...but in Draconic so Lars has no idea and gets excited....and he is stark naked  </li>
                <li> He holds him up to kill him </li>
                <li> "There are fairly simple options here Shazzeem"  </li>
                <li> Shazzeem is like...why should I trust you? Corpo tells Sparka to fireball him if he lies </li>
                <li> NAT 20 on Persuasion check Corpo to convince him </li>
                <li> He is like idk much...but he has made my master mad.  </li>
                <li> Works with a group on multiple planets....he works with different factions....the group is called the Harpers </li>
                <li> He was getting in the way so Severin Solonna captured Tel-Amoth...is in his headquarters </li>
                <li> They ask where the headquarters and Shazeem is just like another world </li>
                <li> Corpo does kiss to each side...Shazeem returns cheeks kiss </li>
                <li> He is like I will go....I won't tell anyone you did this naked </li>
              </ul>
              <li> It's 9:15PM at the camp...calm Wyverns...what do we do </li>
              <li> Lars is like do we release the Wyverns or do use them </li>
              <li> Cecil is like...NOON OPEN THE PORTAL </li>
              <li> Sparka and crew debate ambushing the scouts </li>
              <li> "That is a valid point" "No shit because I think about things before I do them"  </li>
              <li> "We never told you we weren't going to do what we did" "YES YOU DID YOU LITERALLY DID"  </li>
              <li> Sparka tells Lars to lay off the drink </li>
              <li> "I was betrayed, made you will get betrayed"  </li>
              <li> Sparka is like wait...... </li>
              <li> "Only room for three betrayers on this team" 38:50 </li>
              <li> Cecil mentions the Succubus, Lars is like was she hot? Cecil was like....yes </li>
              <li> Sparka thinks it counts as breaking the deal since it "bothered" him </li>
              <li> Cecil is like I turned her down but am SHARING WITH THE GROUP. Lars appreciates his honesty.  </li>
              <li> Cecil gets hit with a sending spell from his father. </li>
              <code>
                It’s father. Are you safe?
                Mother taken by wyvern riders
                days ago. Cult of Dragon,
                unknown why us. Was unconscious
                but awake now. Where you?
              </code>
              <li>Cecil:</li>
              <code>
                Outside Phandalin. Aware of the Cult.
                Planning to attack.
                Your brother and nephew involved.
                What else do you know of the Cult
                and your brother?
              </code>
              M<li>atrim:</li>
              <code>
                Which brother?
                Did not know Cult of Dragon
                Brothers were not evil
                I have a nephew?
                Are you in Phandalin?
                Coming to you.
              </code>
              <li>Cecil:</li>
              <code>
                Severin and his son Hadrian involved.
                Trying to summon Tiamat
                When do we expect you
                Trying to determine next move
                ? More...unclear ?
              </code>
              <li> Corpo is pulling up his pants at this point....he thinks others are naked but is not </li>
              <li> I put my pants on...let the recorder show!!  </li>
              <li> Discuss plans... </li>
              <li> "We aren't able to make decisions..." team yolo laments </li>
              <li> Plan is to ambush the scouts when they arrive in the morning at 9AM </li>
              <li> Murdering them was a good idea...JK Sparka was like ugggghhhhh </li>
              <li> GO OVER AGAIN that at noon they are opening the portal in the town square so it is a backup plan </li>
              <li> So party needs to go up to the castle to get rid of the treasure so if they do capture the people fleeing they won't  </li>
              <li> DON'T SPLIT THE PARTY! Sass knows his master and the giants would be bad to tango with with out the full party </li>
              <li> 9:30 </li>
              <li> Cecil tells them that pops sending spelled them that ma was captured by wyvern riders....Cult of Dragon </li>
              <li> Told his dad where they were....said wasn't sure which brother </li>
              <li> Didn't know that he had a nephew. </li>
              <li> Lars recalls that Hadrian was trying to figure out where Cecil's parents were....he found em </li>
              <li> Meanwhile Corpo and Sparka demand Sass and Duchess go in shame to his tent </li>
              <li> Cecil asks if we should just stop playing and start watching The Aristocats </li>
              <li> Plan recap...realize if they slaughter to patrol the next camp will know something's up </li>
              <li> Cecil is like do we open the portal now? </li>
              <li> Sparka is like the town will be ready at noon....needs to happen while light out </li>
              <li> Cecil: Let's have a blue genie run through town naked that will get their attention  </li>
              <li> Cecil is like I don't need to sleep...you do? Idiots M </li>
              <li> Sparka is like we are a team! </li>
              <li> Cecil like why attack at 9AM and lose resources...is like let's take the wyverns...leave an empty camp...scout take em a while to sort it out </li>
              <li> Lars is like good someone has on thinking cap (Adam wearing thinking cap) </li>
              <li> Sass leaves a note says gone fishin' </li>
              <li> Sparka and crew is like let's attack castle at 5AM then back by portal </li>
              <li> Lars asks if Maisie could open the portal...Cecil says he left her the spanreed....write her to say if we die open the portal </li>
              <li> Spanreed currently scribbles.....What if we are just.....here....writes Matrix Plot...Faerûn drunk dial </li>
              <li> Corpo makes one potion in the morning </li>
              <li> Lars goes to harass Sass in the woods </li>
              <li> "I CAN'T WAIT TIL I LET ONE OF YOU DIE" from Cecil </li>
              <li> Sparks rocks Thrift's Wandering Post...not to Penguin but Darauthom  </li>
              <ul>
                <li> Introduces Cecil to Dragon after others go to bed </li>
                <li> "What the shit"  </li>
                <li> Cecil sees the interior of Daro's lair </li>
                <li> Daro shows up as Yanni...sees Sparka...Sparka like sup Yanni! Turns into Dragon </li>
                <li> Cecil shows off Bowser...Daro asks if that gift was given or taken...TAKEN but he seems cool </li>
                <li> Daro laments no one ever calls him just for fun...always business </li>
                <li> Sparka hands him a lovely fish which he loves Faerûn fish and pays 50 gold. </li>
                <li> Sparka like...well...lot has happened since we talked  </li>
                <li> Sparka tells him he came to his world..he is like ahhh I heard of new portal in Thon Daral </li>
                <li> Says he has eyes and ears on the ground there  </li>
                <li> Gets a scroll and writes it down </li>
                <li> Sparka tells him that they are having difficulties with...the Queen goddess of Chromatic Dragons </li>
                <li> "Oh just her...just a problem with the goddess of greed and chromatic dragons" </li>
                <li> Sparka says the cult is about to open a portal...Daro is like ahh active on your world these days...trying to disperse treasure and people to prevent the blood sacrifice </li>
                <li> Suddenly asks Cecil if he is a bard. Gets excited. Brings them to a hall covered in scrolls </li>
                <li> He explains many JUST focus on grand figures but he loves stories from even the smallest village where the greatest stories of love and woe could be found </li>
                <li> Tells Cecil that he will sponsor stories from him, from about a random mouse to major king </li>
                <li> Sparka is like hey gave you some info....how about some potions in return? </li>
                <li> He was like hey...you brought a story teller...as a heads up many come to me asking about secrets about you.... </li>
                <li>  Cecil is like what if I give you first rights to the tale I am working on...DARO takes it. In exchange for the secrets </li>
                <li> Daro is optioning the story </li>
                <li> Daro says that the secret is that in the Empire of Blood is the Onyx Conclave. They are part of the Empire but go Lawful Evil Lawful Neutral.  </li>
                <li> They push to be brutal when conquering, ends justify the means, kill anyone who opposes them... </li>
                <li> He says a Onyx Conclave member who pays well...asks if he had heard of a missing Dragonshard....Daro is like my knowledge more valuable than a dragonshard....and guy was like ok </li>
                <li> Daro claims they are not allowed in Thon Daral due to whole conquering other realms and being war mongers...but might be interested in him </li>
                <li> Daro lets them know they probably will end up allying with or against them...Sparka asks for advice...he says talk first but hide Dragonshard </li>
                <li> Sparka is like would they steal it? He says they are lawful so more like a boring trial  </li>
                <li> Cecil asks if any big time Onyx people...Daro says many are in the Inquisition...not all super bad just their ideals and means are radical </li>
                <li> Daro says if they go to Thon Daral to swing by the bakery Pan Opticon, best bread, Sparka says he will buy a loaf. He says hey there is a half-elf woman with dark skin and dark hair sitting alone...talk to her </li>
                <li> Babies cry he is like my wife no longer here...he says I look forward to the story. </li>
                <li> Cecil is like that was pretty fucking cool. </li>
              </ul>
              <li> They put the dead bodies in tents </li>
              <li> Cecil takes first watch...uneventful </li>
              <li> Cecil wakes up Sparka for his turn...quiet night </li>
              <li> Sparka wakes up Lars...takes hit of never ending brandy is reminded of life before the war when he went town to town wench to wench racking up that bar tab </li>
              <li> Sass hears Cloudchaser...filled with fear and rage...desires to finish the job more than he fears them </li>
              <li> Sass wakes up Corpo...he has pants on </li>
              <li> "No that's Lars' thing...he sleeps in the nude" </li>
              <li> Duchess stays back from Corpo for obvious reasons </li>
              <li> Corpo notices that the Wyverns are starting to get rowdy. Asks Sass how watch went </li>
              <li> Sass like I heard him...Corpo is like....daddddddy....he was a slave owner you dick head...I didn't call him that...not even once?  </li>
              <li> Corpo pats on shoulder...Sass is like don't touch me...Corpo slaps ass </li>
              <li> Quiet night...Corpo reflects that his Daddy being part of the Harpers makes him feel like he actually fits in somewhere. Corpo is consoled by the Harpers reached out to him. He wonders what might bring this up next time </li>
              <li> He isn't sure what is happening with the gem being used for the Reincarnate spell before </li>
              <li> He hears a frog and gets turned on because of course he does "There was one time..."  </li>
              <li> Corpo notices no Cloudchaser and sees constellation made of booooobz </li>
              <li> Corpo makes breakfast as food from Zilestor </li>
              <li> Sparka wakes up to smelling herb Ore-Gano...only found in Port Llast and the smell of it used this way reminds Sparka of Karvin's food </li>
              <li> Sun starts to return to the day...Wyverns getting antsy  </li>
              <li> Corpo...is a genius and knows that Wyverns are carnivorous </li>
              <li> They strip the bodies and feed them to the Wyverns </li>
              <li> They split up to fly them.... </li>
              <li> Lars/Cecil on Diedre </li>
              <li> Sass/Sparka on Ruffio </li>
              <li> Corp on Brian the über chill just smoked a J Wyvern </li>
              <li> Corpo has Animal Handling....he gets a 24 and damn what is happening  </li>
              <li> Diedre flys wrong way but Corpo helps get her on track with Brian </li>
              <li> They decide they are going to the Dragon Lair and getting dat Horde. Sass saw it.  </li>
              <li> Party guesses that there is a secret cavern under the castle where Cloudchaser is. </li>
              <li> They aren't sure how much they need to take to stop the ritual </li>
              <li> Corpo is like wait Cloudchaser is a....dragon? Wait...what? Everyone like....yeaaaahhhhh </li>
              <li> The lair is an anus or urethra </li>
              <li> Sass NAT 20 White Dragons are dumb brutes, the barbarians of the dragon world  no offense Lars (Ah speaks my language)  </li>
              <li> Sass knows White Dragons keep hoard frozen...along with defeated enemies </li>
              <li> Cecil realizes that Cloudchaser won't be the one doing the ritual </li>
              <li> Brian moves wings slower but still moves as fast </li>
              <li> They Wyverns are in a flying V </li>
              <li> Sass is like I want revenge but also we should kill the wizards to stop the ritual </li>
              <li> Lars wants a Draconic Power....and to do the opening of Frozen to axe the ice out </li>
              <li> They think to attack up top means Cloudchaser anyway </li>
              <li> Lars wants to murder...everyone...Sparka is like they bad </li>
              <li> Corpo knows that about Flying Castles....more complex ones run by souls of giants </li>
              <li> Time to attack! </li>
              <li> As the fly closer...they notice ogres with ballistae in the roof...on patrol </li>
              <li> They see other patrols landing...decide to go under and come up underneath </li>
              <li> NAT1 Lars Stealth on sneaking up....but Brian and Ruffio bring Deidre back inline </li>
              <li> They land Wyverns in Dragon Lair entrance </li>
              <li> NAT 1 Corpo tries to calm Brian...is hurt  and offended since he is a vegetarian  </li>
              <li> They enter cave.... </li>
              <li> Sparka readies twinned haste </li>
              <li> Sass is trying to shoot his other eye  </li>
              <li> NAT 20 Cecil check on Ice Dragons....Immune to cold...not weak to fire </li>
              <li> Corpo noted his dagger-gem is not quite glowing the same </li>
              <li> Adam offers to drive Mike home </li>
              <li> PRAISE Bahamut may his tail be as long as Corpo is not </li>
              <li> Sass and Lars stealth it up </li>
              <li> NAT 1 Cecil looking for the dragon </li>
              <li> They leave the Wyverns and hear Cloudchaser breathing </li>
              <li> Sparka ducks in cave out of breath weapon path </li>
              <li> Corpo does sneaky down a path...Cecil turns invisible (Cecil internally was like fuck why did I help him) </li>
              <li> Sass can tell CC is awake </li>
              <li> Corpo flies down the tunnel and gets dirty 22....CC PP is 21 </li>
              <li> Sees CC looking at his hoard </li>
              <li> Cecil sends message to Corpo asking what up...he gives the idea </li>
              <li> Lars grabs dick and nods to cast Haste </li>
              <li> NAT 20 Sparka Stealth check to cast Haste </li>
              <li> Sparka uses Dragonshard it turns purple and Lars and Sass look blurry </li>
              <li> LARS RAGES </li>
              <li> They know CC has 3 LRs </li>
              <li> Roll Initiative to Fight CC </li>
              <li> ROUND 1 </li>
              <ul>
                <li> LARS:  </li>
                <ul>
                  <li> Muscles bulging as he slams axe down and casts "Boom ShakaLaka" Erupting Earth on CC for 19 damage </li>
                  <li> Cecil and Corpo are like WTF Haste? </li>
                  <li> Charge and Axe-tack hits once for 20 damage </li>
                </ul>
                <li> CORPO:  </li>
                <ul>
                  <li> Charges and does breath o Dragon for 5 damage </li>
                </ul>
                <li> CC: </li>
                <ul>
                  <li> Not surprised </li>
                  <li> Turns to face Lars and Corpo </li>
                  <li> "I AM THE DRAGON" Lars yells he screams back </li>
                </ul>
                <li> CECIL: </li>
                <ul>
                  <li> Moves up 30, </li>
                  <li> Cast Vicious Mock </li>
                  <li> CC fails but takes LR </li>
                  <li> BardIns to Sass </li>
                </ul>
                <li> SPARKA: </li>
                <ul>
                  <li> Looks out and sees CC....casts FIREBALL  </li>
                  <li> CC Fail save...28 damage </li>
                  <li> Runs away </li>
                </ul>
                <li> SASS: </li>
                <ul>
                  <li> Sass knows CC breath weap is a 60ft Cone </li>
                  <li> He and Dychess move up </li>
                  <li> Arrow of slayin' HIT for 17 (with Duchess)  Failed Save for throw but LR so takes half: 40 so 20 </li>
                  <li> "Greosass...the fuck...the loser...." </li>
                  <li> "Greosass going fuck you up bitch" </li>
                  <li> "....didn't I kill your  woman?" </li>
                  <li> "Didn't I kill your fucking eye ball?" </li>
                  <li> "I can still see...how's fucking you hand?" </li>
                  <li> BA: Ensnaring Strike 2nd Level </li>
                  <li> HIT with Arrow of slaying 13 damage 19 arrow of slaying damage </li>
                  <li> HIT Blurred Hasted Shot 14 </li>
                </ul>
                <li> LA:  </li>
                <ul>
                  <li> Tail Attack Lars. 10 Damage halved to 5 </li>
                </ul>
                <li> BOWSER: </li>
                <ul>
                  <li> Help Sass </li>
                </ul>
                <li> LA:  </li>
                <ul>
                  <li> Tail Attack Lars. 16 Damage halved to 8 </li>
                </ul>
              </ul>
              <li> ROUND 2 </li>
              <ul>
                <li> LARS: </li>
                <ul>
                  <li> Moves around to the back </li>
                  <li> NAT 20   38 DAMAGE </li>
                  <li> Dirty 20 19 DAMAGE </li>
                  <li> HIT for 12 DAMAGE </li>
                </ul>
                <li> CORPO: </li>
                <ul>
                  <li> Punch for 6 Damage </li>
                  <li> Punch for 7 Damage for 1 Ki Point </li>
                  <li> Staff hit for 8 Damage </li>
                  <li> Staff hit for 8 Damage </li>
                </ul>
                <li> CC: </li>
                <ul>
                  <li> Lars is behind him  </li>
                  <li> BREATH WEAPON </li>
                  <li> Sass pass, Duchess fail, Corpo Pass, Bowser fail,  Lars Pass  </li>
                  <li> SERIOUSLY HOW DID THEY ALL PASS???? </li>
                  <li> 49 Cold Damage, halved </li>
                  <li> Cecil does Cuttin' Words to 47 </li>
                  <li> Fuckin' Lars got Sentinel attack 23 Damage </li>
                </ul>
                <li> CECIL: </li>
                <ul>
                  <li> Heals self for 13 with the Cittern </li>
                  <li> Backs up 30ft </li>
                </ul>
                <li> SPARKA: </li>
                <ul>
                  <li> Does Perception check to see if about to die...doesn't think he could do it, but thinks Lars and Sass will handle it </li>
                  <li> Sparka peeks out and Firebolt hits for 11 damage </li>
                </ul>
                <li> LA </li>
                <ul>
                  <li> Tail Attack Lars for NAT 20 32 damage halved to 16 </li>
                </ul>
                <li> SASS </li>
                <ul>
                  <li> Never seen masta so grim </li>
                  <li> "I'd fucking kill her again if I could...I've killed all your blood..." </li>
                  <li> BA Hail of Thorns, +1 Arrow, Sharpshooter, Bardic Inspiration </li>
                  <li> Lars is like "AGAIN???" </li>
                  <li> Gets him to 309 </li>
                  <li> Aims for his Eye, makes a cloud....guesses...HITS IN HIS EYE </li>
                  <li> Hits him in his right eye.  </li>
                  <li> Sticks face in the ground with Thorns with mouth open looking at Corpo. </li>
                  <li> It's so degrading for dragons to not have wings...so he cuts off his wings as he saws through his wings.... </li>
                  <li> Lars is like wait...is he like....sawing....through wings....? </li>
                </ul>
              </ul>
              <li> Dies with a scream </li>
              <li> LEVEL UP TO LEVEL 7 </li>
              <li> They eye the hoard </li>
              <li> Lars cuts off a tooth </li>
              <li> Sass looks for arrows...both +1's broken...but he feels like he has closure...happy </li>
              <li> Corpo goes to check on Brian...he's chilling...others freaking...he's cool </li>
              <li> NAT 20 Sparka Arcana figures out if he stays for 8 hours it will unlock </li>
              <li> Lars sits to get healed...has a boner for Dragons </li>
              <li> Sparka smiles a shit-eating-grin that he realizes his visions/dreams do not have to come true...White Dragon is dead </li>
              <li> Corpo drinks his potion of healing...gets...on Brian...says going to not trust Maisie....decides to stay </li>
              <li> Gaezelle is in deep ice...figures out with 4 hours of constant bonfires to get to her </li>
              <li> Sees Vorel-Vivex sword saying strength and honor...made of purple gem...says Strength and Kinaphalic </li>
              <li> Cecil checks if anything isn't frozen... </li>
              <li> Sees a chair cut in half that says Kinaphalic...where he keep the Kinaphalic things broken and frozen </li>
              <li> Sass realizes CC wanted to bring Tiamat back but really wanted to die if he could make Sass just cry once </li>
              <li> Concerned about Gaezelle OH NO...but also damn that sword </li>
              <li> Sparka looks under Ice and can see the stolen stuff...3700 CP 13000 SP 25000 GP 3400 PP </li>
              <li> Also Sass sees the 3 frozen elders cut in half that he killed </li>
              <li> The opposite of Daddy </li>
              <li> Sees the arrow he shot through his eye since Sass marks his arrows HE KNOWS </li>
              <li> Lars and Corpo can see at least 21 Sapphires in the chest glowing 300gp a piece </li>
              <li> They argue about returning the treasure...Sparka like Thieving is Anathema to Waukeen...Corpo is like what we just gonna talk to people </li>
              <li> Whatever they go for to melt will be like within an hour </li>
            </ul>
          </Collapsible>

          <Collapsible
            title="Jazelle (#19 - 11/25)"
            toggle={this.collapseToggle.bind(this)}
            kind="session19"
            show={this.state.show}
          >
            <ul>
              <li> The magic of the horde breaking down </li>
              <li> THE DUCHESS evolves  </li>
              <li> She has wings and lets out a roar </li>
              <li> Mike is spent, rolls over, has a cigarette  </li>
              <li> Lars gets flashback of being 7 and hearing people yelling outside </li>
              <ul>
                <li>
                ```
                LARSIUS: Please, I want you here, don't go. We love you
                SOBER_1: sO yOU can all snicker at the SOBER ONE?
                LARSIUS: You can be part of this family, we will love you even if you are Sober
                SOBER_1: I won't sit around while you waste your lives! I hoped you'd see reason, that your lifestyles are rot and your gods are evil detestable pieces of shit.
                LARSIUS: Grow your mushrooms how you like, I need no help in my garden child.
                SOBER_1: *Spits* It was a mistake to return
                LARSIUS: It's never a mistake to return home, now stay, let us love you.
                SOBER_1: I only came back to save him, I hear they even named him Larsius too as if I had not lived up to the name. I couldn't suffer this family ruining another child like they did me.
                LARSIUS: He had 2 cow pies already today. He can chose for himself one day, just like you.
                SOBER_1: He will choose to leave your dirty, lecherus, ways in the dust grandfather AND FIND the only person worthy of true worship. Worthless Patriarch not worthy.
                ```
                </li>
                <li> Lars hears footsteps as the Søber one tells someone to come with him </li>
                <li> Lars climbs out of the window and stealths down to follow </li>
                <li> He mumbling about they will rot </li>
                <li> Airy female voice says my lord they will see the truth that you are the only true one </li>
                <li> Lars sees that it is a female Genasi that is Corpo's mom </li>
                <li> Lars hears her say "My Lord Dreadflame we will be ready" </li>
                <li> Grandfather catches him, says come back, maybe one day you might be able to convince him but not today....here come drink a cow pie </li>
              </ul>
              <li> Corpo has a reverie of being in a monastery waking up not in his cell with two others </li>
              <ul>
                <li> Corpo is in an all female monastery....a...nunnery </li>
                <li> Half Elf and Halfing females in his bed </li>
                <li> A stern human Abbess walks in a kicks the girls out, even though it's one of the girl's room </li>
                <li> "It is the fifth time I have found you like this" </li>
                <li> She is like you are leaving Brother Fugit....you were trying to find knowledge about your daddy and now none here can say the word daddy </li>
                <li> She is like if you actually wanted to look....you could but you are just banging nuns? Are you afraid? </li>
                <li> Corpo is like I'm bored. Daddy doesn't want to be found...all leads run cold. Mere female accompaniment has run cold... </li>
                <li> Also your library is shit, she is like we have a big section, he is like that's what you are for. </li>
                <li> She says he is an excellent monk meditates under waterfalls longer than the others, etc </li>
                <li> Says to come back with Daddy if he find him...she has to know </li>
                <li> She says if you focused you'd probably find him </li>
                <li> She says she has 3 bottles of wine....cut scene </li>
              </ul>
              <li> Cecil has reverie  of leaving home as parent talk to him </li>
              <ul>
                <li> His parents are saying good bye, another knock draws father away </li>
                <li> His mom paid someone to distract them </li>
                <li> She is like avoid bandits...there is danger out there! </li>
                <li> Cecil is like I hear Djinn are bad.... </li>
                <li> But she is like hey your father has opinions on his family, but he got to choose </li>
                <li> She is like I was an orphan, I didn't get to choose, I want you to choose what to do with them, to be with them or not </li>
                <li> He asks Cecil if he has questions...Cecil asks about the family... </li>
                <li> He says that his family are Elves are the part that think humans should be away </li>
                <li> Half-elves are killed souls to them </li>
                <li> He says Forgotten Ways are those intentionally forgotten, like old deities when new things come to town </li>
              </ul>
              <li> Sparka vision of being in the tavern helping Karvin as patrons shout back </li>
              <ul>
                <li> Phineas Stoneblood is in the tavern and yelling asking what the secret ingredient is to the meat pies </li>
                <li> Karvin talks like Burl Ives </li>
                <li> Karvin says the difference between Oregano and Oregayno? NOTHING </li>
                <li> Says that Phineas tried to make Oregano taste like Oregayno but doing magic and trying to growing it all around the world </li>
                <li> He says nope, just let it grow...right here in the dirt, he looks to see if Selma is watching </li>
                <li> When I make a decision I try to keep it here </li>
                <li> Selma comes back and he sorta shifts convo </li>
                <li> Sparka is like were you going to say something else? </li>
                <li> He is like uhhhh Trees...trees are best grown right here in Port Llast </li>
                <li> Sparka notices the bloom where you are planted sign </li>
                <li> Be thankful for you life in Port Llast, granted fish people invade and shit but whatever </li>
                <li> Remember one day the tree grows, it can do seeds and whatever, it has to grow the roots </li>
                <li> Sparka goes off to get Oregano when he says he wants to make meatballs. </li>
              </ul>
              <li> Sass vision of knock at the door as he throws cloak over Jazelle </li>
              <ul>
                <li> She is covering her cloaca </li>
                <li> Thopleth is at door saying he heard someone </li>
                <li> Sass is like I am Stoic and gruff I like being alone </li>
                <li> Thopleth is like master wants you, Sass sasses Thopleth </li>
                <li> Sass closes the door, gets a quickie in </li>
                <li> Sass goes pants on, no shirt, no pants </li>
                <li> Cloudchaser is like you are hunting for the Giants to visit, go hunt for the feast </li>
                <li> He says also there will also be a wedding, Thopleth will marry Jaezelle...get furs to keep her warm </li>
                <li> The elders are coming so do well </li>
                <li> Sass tells a friend to kill Thopleth on the way out </li>
              </ul>
              <li> Sass is all shook.  </li>
              <li> Cecil affirms that they finish business here for the 8 hour maker </li>
              <li> Lars wants to piss on treasure to get through the ice </li>
              <li> Some scouts might be out since it's like 6AM </li>
              <li> Sass has a penis </li>
              <li> They focus bonfire to get to a treasure table within an hour </li>
              <li> Lars pissing on ice and bonfire lights which freaks him out </li>
              <li> Take short rest </li>
              <li> Lars, Sass, Corpo short rest while Cecil does short rest jams not sure if Cecil healed. </li>
              <li> They go for Vorel-Vivex, being inspired by Sass venging master </li>
              <ul>
                <li> After half-hour they get to the sword, Sparka hands to Sass </li>
                <li> One side says Strength, one honor, </li>
                <li> Crossguard say Kinaphalic </li>
                <li> Elven writing in Draconic style says for pact between Clan Kinaphalic and the Lunar Coronal </li>
                <li> The words light up purple flame when Sass touches </li>
              </ul>
              <li> Sparka retargets a different treasure table spot </li>
              <li> Going to have to feed Wyverns soon...hack apart CC corpse </li>
              <li> They wait 20 minutes to get a treasure table roll </li>
              <li>  Lars recalls that you want to move quick when ambushing before they rally </li>
              <li> Cecil knows lot of peeps up there </li>
              <li> Party knows that Giants and Dragons be ancient enemies....very unclear why they together now. Why?? Assume not a ton of giants due to that </li>
              <li> Reach the Treasure table item..find Bracers of Archery. Sass identifies them </li>
              <li> Horde magic starting to flow around...will be full aurora borealis in like 3 hours </li>
              <li> Lars says Slap Wyverns on ass so they fly away, distract and confuse up there. Cecil like you don't have many good ideas but when you do...   </li>
              <li> They slowly walk up the caves to tunnel into the castle </li>
              <li> Sass sneaks up to the inner courtyard...see some people walkin around and ogres on roof...morning like 5:30 </li>
              <li> They debate what to do....go in? Clear outside? </li>
              <li> Sass is like let's just burn it...Cecil is like it's made of ice.... </li>
              <li> Lars sees birds far away </li>
              <li> They see their Wyverns go back and land </li>
              <li> Sparka does Minor Illusion to make sound of breaking glass </li>
              <li> Sass hears ogres mumbling about checking it out....one comes to check it out </li>
              <li> They are like shit time to quick kill...debate what to do exactly </li>
              <li> ROLL INITATIVE </li>
              <ul>
                <li> ROUND 1 </li>
                <ul>
                  <li> CECIL: </li>
                  <ul>
                    <li> Vicious Mockery:  You musta lost a bet cause they sent you out </li>
                  </ul>
                  <li> CORPO: </li>
                  <ul>
                    <li> Runs to attack </li>
                    <li> Staff attack Misses, hits with punch for 5, NAT 1 punch...misses punch? </li>
                  </ul>
                  <li> LARS: </li>
                  <ul>
                    <li> Hit for 23 damage, NAT 20 for 33 damage KILL by slitting throat and whispering "I am the dragon" </li>
                    <li> Lars Chops off his dick </li>
                  </ul>
                </ul>
              </ul>
              <li> Lars covered in blood panic and kicks snow on it </li>
              <li> Sparka uses presti to clean blood </li>
              <li> Sparka and Cecil Minor Illusion to hide the ogre as they move it </li>
              <li> Sparka does minor illusion to say "Hey buddy come check it out" </li>
              <li> Gok and Bork, two Ogres come to check it out </li>
              <li> AMBUSH </li>
              <li> NAT 1 Cecil group stealth check  </li>
              <li> INITATIVE </li>
                <li> CORPO: </li>
                <ul>
                  <li> HIT punch, HIT staff,  NAT 20 staff hit...33 total </li>
                </ul>
                <li> CECIL: </li>
                <ul>
                  <li> Vicious Mock 8 damage to Gok saying QM musta run out of equipment cause he just gave you a loin cloth </li>
                </ul>
                <li> BOWSER: </li>
                <ul>
                  <li> holds action to help Lars </li>
                </ul>
                <li> SPARKA </li>
                <ul>
                  <li> Hits with Firebolt for 9 </li>
                </ul>
                <li> SASS </li>
                <ul>
                  <li> HIT bow, HIT bow KILL by shooting his club so hard it clubs him and impales his own head </li>
                  <li> Duchess BITES hard </li>
                </ul>
                <li> bork </li>
                <ul>
                  <li> Attacks Duchess </li>
                </ul>
                <li> LARS </li>
                <ul>
                  <li> Bowser moves forward with him </li>
                  <li> Calmly....serenely </li>
                  <li> HIT axe, HIT axe, KILL slice opening his gut as his intenstines fall out </li>
                </ul>
              </ul>
              <li> Interrogate the dying ogre....how many other bad guys are there </li>
              <li> He is dumber than Lars...says at least 17 ogres...bunch of people...giants... </li>
              <li> Asks how it flies...he says the big guy talks to his wife...she's dead so it's the soul </li>
              <li> Final question is who is your weed man and how do you get high...he is like I smoke life I don't need drugs... </li>
              <li> Lars is like f-that noise so shoves other ogre dick in his mouth and then strangles him with his own intestines </li>
              <li> Sparka Prestidigitation the blood away </li>
              <li> Corpo noticed the hand that is a shrine to a giant god and is unused....he stealths over reaaaallllly well to piss on the shrine. </li>
              <li> Sparka like hey don't do that again </li>
              <li> Sass creepin in the windows, sees that first room is a simple store room </li>
              <li> Sass creeps to the Champion's suite...listens at door, hears just some basic noise but not much </li>
              <li> There are windows...Sass peaks inside, sees giant bear rug, bedroom door ajar </li>
              <li> Cecil NAT 20 Lockpick....but total is not quite enough so opens with a creak </li>
              <li> They try to sneak in...Sparka tries to listen at door hears a small animal </li>
              <li> NAT 1 Sass on initative </li>
              <li> Sass's dick is rock hard cause he is a gem dragon. It has lasted 35 years...needs to call it in </li>
              <li> INITATIVE </li>
              <ul>
                <li> ROUND 1 </li>
                <ul>
                  <li> WHITE GUARD DRAKE  </li>
                  <ul>
                    <li> Bursts open and jumps through, they see an Abishai </li>
                    <li> Bites Corpo for 7 </li>
                  </ul>
                  <li> SPARKA </li>
                  <ul>
                    <li> Fire bolt White Guard Drake for hit (should have been a crit) </li>
                    <li> moves </li>
                  </ul>
                  <li> CECIL </li>
                  <ul>
                    <li> Blesses Corpo, Sass, Lars with "Those about to rock we salute you" </li>
                    <li> INSPIRES Lars with  </li>
                  </ul>
                  <li> CORPO </li>
                  <ul>
                    <li> Attack White Guard Drake HIT staff, MISS staff, HIT punch to kill scooby white guard drake. Slices its next slowly in front of the White Abbishai </li>
                  </ul>
                  <li> LARS </li>
                  <ul>
                    <li> Rushes into the room and sees the Jaezelle part-Abbishai with bow in there and another drake </li>
                    <li> RAGE </li>
                    <li> Attack Abbishai NAT 1 Axe NAT 20 Axe </li>
                  </ul>
                  <li> ABBISHAI </li>
                  <ul>
                    <li> Attack Lars HIT bite, HIT claw, HIT sword </li>
                  </ul>
                  <li> JAEZELLE </li>
                  <ul>
                    <li> Misses Lars twice as he SHOUTS I AM THE DRAGON </li>
                  </ul>
                  <li> BOWSER </li>
                  <ul>
                    <li> Ready action to help Lars </li>
                  </ul>
                  <li> SASS </li>
                  <ul>
                    <li> Shoots at Abbishai with Ensnaring Strike damage and restrained </li>
                    <li> Shoots and hits with bless </li>
                  </ul>
                  <li> GUARD DRAKE </li>
                  <ul>
                    <li> Bites Lars </li>
                  </ul>
                </ul>
                <li> ROUND 2 </li>
                <ul>
                  <li> SPARKA </li>
                  <ul>
                    <li> Moves up and Firebolt misses Jaezelle </li>
                    <li> TK shove the guard drake from Lars </li>
                  </ul>
                  <li> CECIL </li>
                  <ul>
                    <li> Moves up and forgoes his attack to have bowser attack </li>
                    <li> Bowser stings but Abbishai avoids damage </li>
                    <li> Bardic Inspiration </li>
                  </ul>
                  <li> CORPO  </li>
                  <ul>
                    <li> Charges the Abbishai  </li>
                    <li> HIT staff, HIT staff for KILL the White Abbishai by gutteral uttering under breath "daddy" then jumped off Lars's shoulder and double crack to snap it's neck. </li>
                    <li> Bonus Action to punch the Drake, Flurry blows hit it again </li>
                  </ul>
                  <li> LARS </li>
                  <ul>
                    <li> Rushes around Jaezelle </li>
                    <li> HIT axe, HIT axe </li>
                    <li> Declares that he is going to hump that fine ass that he sees under the cloak  </li>
                  </ul>
                  <li> JAEZELLE </li>
                  <ul>
                    <li> Stows the bow </li>
                    <li> MISS bite, MISS claw, HIT sword </li>
                  </ul>
                  <li> BOWSER </li>
                  <ul>
                    <li> Moves to help Lars </li>
                  </ul>
                  <li> SASS</li>
                  <ul>
                    <li> Robert chants to kill Jaezelle  </li>
                    <li> Party sees her wearing a dragon mask </li>
                    <li> HIT bow, HIT bow to KILL by....HOLY FUCK </li>
                    <li> Orders Duchess to sick the neck....and electricity bite to decapitate the body...heads roll off </li>
                  </ul>
                  <li> GUARD DRAKE </li>
                  <ul>
                    <li> Freaks and flees </li>
                  </ul>
                <li> ROUND 3 </li>
                <ul>
                  <li> SPARKA </li>
                  <ul>
                    <li> Hunts down the Guard Drake and firebolts it KILLED by immolating it mid-bark </li>
                  </ul>
                </ul>
                <li> Corpo goes and mercy kills the guard drake </li>
              </ul>
              <li> Sass searches the body which he notices stills looks like Jaezelle </li>
              <li> Sass is starting to get a little worried...Lars is like...uhhhh </li>
              <li> Sparka takes mask off and it's Jaezelle's face </li>
              <li> They take her +1 Longsword and Sass takes her 10 arrows and a +1 arrow </li>
              <li> Sass is like is the mask evil? Sparka is like it is not a thing </li>
              <li> Body of Jazelle's body shits itself </li>
              <li> Sass is like this looks like her body....can I like.... </li>
              <li> Sparka is like hey it is a Dragonborn body right? </li>
              <li> They are like wait it is not Thopleth..... </li>
              <li> Sass fires Detect Magic </li>
              <li> Cecil finds a letter saying "Keep an eye on Hadrian, the Cult might fail, but Tiamat comes first" from Arkhan the Cruel </li>
              <li> Sparka find a map of the area with Hadrians handwriting of Greosass sightings bottom says we will kill him together (all since they have been together) </li>
              <li> Lars sees that two people have been sleeping in the bed </li>
              <li> Corpo and Lars are like hey that was Jaezelle you killed her. Sass like no I saw her die and in the ice.... </li>
              <li> Sass is starting to freak out </li>
              <li> They know that as long as the head has not been severed from the body you can bring back to life with Revivify. </li>
              <li> Lars even knows that he should not have chopped her head off </li>
              <li> SASS IS LIKE WHAAAAAAAA </li>
              <li> Sparka places his hands on his shoulder and was like she was dead...it wasn't her </li>
              <li> Sparka like it just looked like her...Corpo is LIKE NO IT WAS HER </li>
              <li> Cecil is about to pick the lock but Sparka is like whoa here is the keys </li>
              <li> "That's what you get for having emotional attachments. Bang them and leave" Corpo </li>
              <li> Sass is a bout to spiral....Lars gives him neverending brandy </li>
              <li> They get some good shit....but half the treasure disappears away </li>
              <li> Sass NAT 1 Arcana to figure out where the chest went..HOW FUCKING APPROPRIATE </li>
              <li> Corpo finds letters from Jaezelle and Thopleth. The Thopleth letter says Hadrian wants to renew the modify memory spell. They together figure out the spell can mess them up. </li>
              <li> As they die some people say that the modify memory spell is lifted and they see clearly as their life force leaves them.... </li>
              <li> The letter to Jaezelle is all about getting vengeance on Sass for killing the Elders....enjoy second honeymoon with Thopleth </li>
              <li> DM says there were maps they can check back on later </li>
              <li> Ben threatens to give the letter to Sass next time he is at his lowest </li>
              <li> They figure out the other Abbishai was a rabid animal...so Thopleth is out there still </li>
              <li> Corpo is like...wait...what happens after death....to afterlife if you were forced into a Pact with Tiamat?? </li>
              <li> Corpo asks about air burial? </li>
              <li> Sparka places mask in the bag of holding... </li>
              <li> They figure out that collecting all 5 masks is another way to bring Tiamat back </li>
              <li> Sparka remembers that Severin had a Red Dragon Mask </li>
            </ul>
          </Collapsible>


          <Collapsible
            title="They Are All Guilty (#20 - 11/26)"
            toggle={this.collapseToggle.bind(this)}
            kind="session20"
            show={this.state.show}
          >
            <ul>
              <li> Out the window they see people running around, a castle coming to life as the day starts </li>
              <li> No alarm or anything, but alert alert alert </li>
              <li> Cecil is like hey we can't do anything about that </li>
              <li> They plot what to do and if they can hide in </li>
              <li> Sass wants to go eat his feelings...Lars is like hey might find a spoon with her saliva...FUCK YOU LARS </li>
              <li> They decide to do a short rest, to let Sass sorta be miserable </li>
              <li> Party watches out window while Lars gets some healing </li>
              <li> Do they move the immovable rod? No? </li>
              <li> At 40 minutes they hear giant steps coming near them...ground is shaking </li>
              <li> Sparka looks out the window and sees a Stone Giant who knocks at the door </li>
              <li> "What's up pup, little pup we heard noise?" </li>
              <li> Sparka minor illusions Jaezelle's voice sayin that they are ok. </li>
              <li> Sass rocks back and forth saying "...make it stop...make it stop..." and Lars hands him infinite flask </li>
              <li> Giant buys it and heads back to his rooms next store </li>
              <li> Discussion how damn tall giants are </li>
              <li> Sass is with the body, hands in head. Totally in disbelief...how could she have died in my memories but here she is.... </li>
              <li> Sass is just sitting by her body and is very upset, very mad, knows Thopleth is involved and MUST KILL IT </li>
              <li> Thopleth is the first on his Terminal List </li>
              <li> He is no longer Lego Batman and Aragorn....but also Chris Prat from Terminal List </li>
              <li> Sparka puts his hand on Sass' shoulder and is like we going to kill back. </li>
              <li> "Promise me we will kill Thopleth" Sparka answers in Draconic "Yes"....Corpo solemn for only other time but when he killed his mother </li>
              <li> Sass shit list 1. Thopleth 2. Hadrian (since he did the memories) </li>
              <li> Sparka takes Cecil into the corner and talks about the mask...says it is very unnerving...says none should use it yet but leave it in the bag of holding </li>
              <li> Sparka knows they are important from his dream/vision...thinks they should find them not to use them but to hide them </li>
              <li> Sparka is like maybe we put it in the Diemondar Ruins that only his blood can get it in </li>
              <li> Sparka wraps the mask in a cloth so he doesn't touch it and puts it in the bag of holding </li>
              <li> Corpo whispers to Lars they could get all 5 masks and have a mask orgy </li>
              <li> They see a cultist sprinting from the caves to realize that CC is dead </li>
              <li> Lars is about to hurl a javelin when Sparka and Cecil put a hand on. his shoulder and are like hey not yet buddy </li>
              <li> Sparka leads the Prestidigi effort, hides bodies under the bed </li>
              <li> They charge up the stairs and put the body in the bag of holding </li>
              <li> They take the spiral stair case up into the citadel castle, multiple flights but nothing so far to get up </li>
              <li> Staircase is tight but no way to get off til the top, bowser is like right above him </li>
              <li> Lars is leading...the axe is wiser than him...words show up as !!!!! to hey their is stuff...he makes a fist to stop </li>
              <li> Lars at top of the stairs sees a sleeping Kobold through the crack...passed out on the table </li>
              <li> Lars is like hey there is a slumped over thing </li>
              <li> "They are all guilty" Sass says over and over </li>
              <li> Sass creeps up and with Vorel Vivex and as cold as possible Sass slits his throat...as cold as possible </li>
              <li> Lars is excited Corpo is "excited" Bowser is like damn he is coping well. </li>
              <li> Sass has look that he is going to kill them all </li>
              <li> Sees that this is a servant barracks...pots...pans...etc </li>
              <li> Sass listens at the door but can't hear with the ear holes so small </li>
              <li> Corpo NAT 20 Perception "You can tell me, I am now a god" </li>
              <li> Corpo sees a dope blue flame and gets feel for the floor...hears subdued noises, a general low din </li>
              <li> Lars is like lets go upstairs...Sparka like but who might be upstairs....Lars like uhhh ask the axe </li>
              <li> They start creeping around...slight frost on the ground </li>
              <li> Cecil knows that the blue flame is an elemental node taken from the Elemental Plane of Ice...what seems to make it float...assuming  teering would be above it.</li>
              <li> "That explains the tingling sensation in my nether regions" Corpo </li>
              <li> Lars asks if he could pee on the blue flame to make it land </li>
              <li> Cecil assumes that the control room would be easier to control it than stopping the flame </li>
              <li> The runes around the base they notices are in giant, supernatural...but don't yet know what the runes are for...deep chiseled </li>
              <li> Only see that the stairs go down but unable to see. </li>
              <li> They walk by doors and hear noises in some rooms...Sass hears two people Cecil hears crackling fire in a different room </li>
              <li> They check the crackling fire room first </li>
              <li> Lars looks in and sees more sleeping Kobolds...one is awake but looking at cards.... </li>
              <li> Sass kills by shooting awake one in back of the head, slumps down in its soup </li>
              <li> Sass kills the other with sword </li>
              <li> Lars fist bumps Sass then slaps his ass and then winks at others </li>
              <li> Sass not in the mood, Lars trying to lighten the mood </li>
              <li> Lars leads the way into the room with two dudes practicing swords work </li>
              <li> Lars NAT 20 Axe attack into the guy's armor from behind...killed by hew him in half...then slaps him with his dick on the bottom half cause  he has bad initiative. </li>
              <li> Lars NAT 1 Initiative </li>
              <li> Sass kills other through the head and pins to training dummy </li>
              <li> They find nice weapons but nothing good for them </li>
              <li> Corpo touches the blue flame...dancing between the worlds. Pulls back before it does cold damage </li>
              <li> Find Giant and Ogre furnace </li>
              <li> Corpo realizes that giants often bury tombs at lowest level, so the grated path is towards tombs </li>
              <li> They start heading up the stairs </li>
              <li> Sass NAT 1 Stealth has a momentary lapse of battle boy and lets out a moan of sorrow </li>
              <li> Lars hears breathing....hey guys heavy as hell </li>
              <li> ROLL INITIATIVE </li>
              <ul>
                <li> Round 1 </li>
                <ul>
                  <li> Hear Ogres shouting little folk and BOOM they are top of stairs </li>
                  <li> CORPO </li>
                  <ul>
                    <li> "DICK MOUTHS ON APPROACH" he yells </li>
                    <li> I just flee </li>
                    <li> Warns the others then charges at an Ogre HITS staff for 10, HITS staff for 11, HITS punch for 8, HITS punch for 10 </li>
                  </ul>
                  <li> SPARKA </li>
                  <ul>
                    <li> Walks up and FIREBOLT and hits! 13 damage </li>
                  </ul>
                  <li> BOWSER </li>
                  <ul>
                    <li> Holds action to assist Lars </li>
                  </ul>
                  <li> brozig </li>
                  <ul>
                    <li> Clubs Corpo in face </li>
                  </ul>
                  <li> LARS </li>
                  <ul>
                    <li> Not raging...perfectly calm and collected...not reckless </li>
                    <li> KILL BROZIG chops off dick and kicks him in the knee...throws the dick at the other </li>
                    <li> Axes Izuk for 11 </li>
                  </ul>
                  <li> SASS </li>
                  <ul>
                    <li> Hits for NAT 20 for 37 damage </li>
                    <li> KILLS with archer hit 9 damage. Figures out there is a theme so shoots him through his pee hole </li>
                    <li> BA has Duchess attack hits Ogre for 9 damage </li>
                  </ul>
                  <li> CECIL </li>
                  <ul>
                    <li> Vicious Mockery on Ogre fails... "You suck" </li>
                  </ul>
                  <li> Ogre? </li>
                  <ul>
                    <li> Runs to and whacks Sass with Club...but tears from Vicious Mockery makes him miss </li>
                    <li> "...I feel nothing...but white hot rage" Sass say </li>
                  </ul>
                  <li> Dukorg </li>
                  <ul>
                    <li> Runs and hits Duchess with a club </li>
                  </ul>
                  <li> OGRES yell up to master intruders...Sass yells hey master a dick for you to suck! </li>
                </ul>
                <li> Round 2 </li>
                <ul>
                  <li> CORPO </li>
                  <ul>
                    <li> Attacks Dukorg...staff 12, staff 11, miss punch, punch 9 damage (DM gave Corpo a free punch) </li>
                  </ul>
                  <li> SPARKA </li>
                  <ul>
                    <li> Mindsliver ogre for 6 damage </li>
                    <li> TK push away then run away </li>
                  </ul>
                  <li> BOWSER </li>
                  <ul>
                    <li> Fly over to ogre to ready to help Lars </li>
                  </ul>
                  <li> LARS </li>
                  <ul>
                    <li> Hit twice for total of 38 damage.... </li>
                  </ul>
                  <li> SASS </li>
                  <ul>
                    <li> Hits with arrow twice...KILL ogre by shooting artery on his dick </li>
                    <li> BA Duchess sick balls and hits </li>
                  </ul>
                  <li> CECIL </li>
                  <ul>
                    <li> Vicious Mockery fails.... "You said master but what's your safe word" But it doesn't work </li>
                  </ul>
                  <li> Trukorg </li>
                  <ul>
                    <li> Went to run...but held fast and attacked </li>
                    <li> Panic attacks Lars and hits with a club </li>
                  </ul>
                </ul>
                <li> Round 3 </li>
                <ul>
                  <li> TOP STAIRS OGRE </li>
                  <ul>
                    <li> Throws Javelin down at Sass </li>
                  </ul>
                  <li> CORPO </li>
                  <ul>
                    <li> KILL ogre with a staff then buries dagger in his skull </li>
                    <li> Cannot reach the Ogres chucking javelins...says next opponent awaits...Sass like...GOOD </li>
                  </ul>
                  <li> SPARKA </li>
                  <ul>
                    <li> Sees ogre upstairs and it has same honor guard hat </li>
                    <li> Fires a firebolt at Orag to hit </li>
                    <li> Ducks behind wall to take cover </li>
                  </ul>
                  <li> UPSTAIRS OGRE </li>
                  <ul>
                    <li> Hurls javelin down at Sass but misses </li>
                  </ul>
                  <li> BOWSER </li>
                  <ul>
                    <li> Hangs tight by Cecil </li>
                  </ul>
                  <li> BLAGOTH </li>
                  <ul>
                    <li> Teleports to activate Alarm </li>
                    <li> BA summon AIR ELEMENTAL </li>
                  </ul>
                  <li> AIR ELEMENTAL </li>
                  <ul>
                    <li> Flies down the stairs  </li>
                    <li> Does is swirl attack </li>
                    <ul>
                      <li> Corpo NAT 20 Strength Save </li>
                      <li> Sass NAT 1 Strength Save for 19 </li>
                      <li> Sass flung down the hall for 10 fall damage "Not looking very handsome" </li>
                    </ul>
                    <li> Flies back upstairs...Corpo gets AOO but with staff is not magical </li>
                    <li> "What is its name? No name? That is so racist...." Corpo says  </li>
                  </ul>
                  <li> LARS </li>
                  <ul>
                    <li> Throws Ogre javelins back for 19 damage at Orag </li>
                    <li> SCREAMS I AM THE DRAGON </li>
                  </ul>
                  <li> SASS </li>
                  <ul>
                    <li> Uses spectral wings and flies up </li>
                    <li> KAWKAW MOTHERFUCKERS </li>
                    <li> Shoots Orag, hit for 20 points, hit for HDYWTDT shoots in the neck and he is like UGGHGHGHGH then falls down and bounces into the  </li>blue flame...freezes and slowly burns into snowflakes and burns away like the end of Frozen when he finds love is the answer
                  </ul>
                  <li> CECIL </li>
                  <ul>
                    <li> Not so sure how close he wants to get the edge </li>
                    <li> Holds action to cast VM if Ogre gets within 60 ft </li>
                  </ul>
                </ul>
                <li> Round 4 </li>
                <ul>
                  <li> CORPO </li>
                  <ul>
                    <li> Runs up and does breath of the dragon on Irub for 8 damage </li>
                  </ul>
                  <li> SPARKA </li>
                  <ul>
                    <li> Readies action to cast magic missile at 6th level if the Air Elemental comes back in range </li>
                  </ul>
                  <li> Irub </li>
                  <ul>
                    <li> Runs away! </li>
                  </ul>
                  <li> BOWSER </li>
                  <ul>
                    <li> Flies up and sees that the ogres have backed up </li>
                  </ul>
                  <li> BLAGO </li>
                  <ul>
                    <li> Sets off alarm for the whole castle. They hear KLAXON but it is windy noises </li>
                  </ul>
                  <li> AIR ELEMENTAL </li>
                  <ul>
                    <li> does not recharge </li>
                  </ul>
                  <li> LARS </li>
                  <ul>
                    <li> Runs up next to Corpo, readies action to attack </li>
                  </ul>
                  <li> SASS </li>
                  <ul>
                    <li> Delayed cause he was in the crapper </li>
                    <li> Assumed he ran up the stairs </li>
                  </ul>
                  <li> CECIL </li>
                  <ul>
                    <li> Moves 15 feet and readies to VM anyone he sees in 60ft of him </li>
                  </ul>
                </ul>
                <li> Round 5 </li>
                <ul>
                  <li> CORPO </li>
                  <ul>
                    <li> Lars is like I will join you! </li>
                    <li> Uses step of the wind and wings unfurled and jumps from stairs up to the top level </li>
                    <li> Sees the ogres and air elemental up there enjoying life, yells down what is it like up there..including the rubbed one and the Ghost  </li>of Xmas Future.... "What's future"
                    <li> Tells Lars he should directly jump up </li>
                    <li> Sass is like hey throw down a rope. Corpo lowers down 50 ft of hempen rope  </li>
                    <li> "Are you going to tie it off to something?" "No" but then he does </li>
                  </ul>
                  <li> SPARKA </li>
                  <ul>
                    <li> Runs up the stairs. DASH past the stairs </li>
                    <li> BA shove the ogre towards him "That would be a pull"  </li>
                  </ul>
                  <li> Irub </li>
                  <ul>
                    <li> Retreats </li>
                  </ul>
                  <li> AIR ELEMENTAL </li>
                  <ul>
                    <li> Retreats </li>
                  </ul>
                  <li> BOWSER </li>
                  <ul>
                    <li> Flies up and hangs with Lars </li>
                  </ul>
                  <li> BLAGO </li>
                  <ul>
                    <li> Calls down "PEASANTS YOU ARE LOST. IF YOU GET UP HERE I WILL KILL YOU. I HAVE CALLED FOR HELP THEY ARE COMING. YOUR BEST CHOICE NOW  </li>IS TO SURRENDER TO THE GLORY OF THE GIANTS"
                    <li> Corpo yells back will we slaughter you...then something about twist his...d </li>
                    <li> He is you are too small to do so </li>
                  </ul>
                  <li> LARS </li>
                  <ul>
                    <li> Leaps up to next floor </li>
                    <li> Dos Javelinos at the Ogre hits both for 16 </li>
                  </ul>
                  <li> SASS </li>
                  <ul>
                    <li> Climbs up the rope, Duchess flies up </li>
                  </ul>
                  <li> CECIL </li>
                  <ul>
                    <li> Sees no action down below, makes a break for the rope, dashes to get to the top </li>
                  </ul>
                </ul>
                <li> Round 6 </li>
                <ul>
                  <li> CORPO </li>
                  <ul>
                    <li> Breath of Dragon on Ogre </li>
                  </ul>
                  <li> SPARKA </li>
                  <ul>
                    <li> Perception check sees nothing below... </li>
                    <li> Runs up stairs </li>
                  </ul>
                  <li> Irub </li>
                  <ul>
                    <li> Attacks Corpo for 19 </li>
                  </ul>
                  <li> BOWSER </li>
                  <ul>
                    <li> Helps Lars with Next attack </li>
                  </ul>
                  <li> BLAGO </li>
                  <ul>
                    <li> Barreling around the corner </li>
                    <li> Corpo yells Daddy...he is like I will...make you...ugh...I'm bad at this </li>
                    <li> Attacks Corpo...miss but then smacks him in the face and drops him </li>
                    <li> He recognizes Corpo as he drops him...master wants you... </li>
                  </ul>
                  <li> AIR ELEMENTAL </li>
                  <ul>
                    <li> Swirls in and attacks Lars </li>
                    <li> Slams Lars twice...hit and then NAT 20 damage for total 37 damage </li>
                    <li> Swirls away...Lars AOO hits for 20 damage </li>
                  </ul>
                  <li> LARS </li>
                  <ul>
                    <li> LARS IS PISSED Corpo who understood him most is down </li>
                    <li> RAGE with Bowser helps. Hits Blago twice for 20 damage </li>
                  </ul>
                  <li> SASS </li>
                  <ul>
                    <li> Crusader's Mantle => Praise BAHAMUT May his.....talons....be....sharp as fuck </li>
                    <li> Cecil is like let Ramsay Debt this shit and kill one at a time </li>
                    <li> Shoots the Ogre twice </li>
                  </ul>
                  <li> CECIL </li>
                  <ul>
                    <li> "Corpo's going to roll a death save..." "NO HE'S NOT!" Cecil yells </li>
                    <li> Cecil is like ugh Corpo...but really wants another Martial in this fight </li>
                    <li> BA Healing Word on Corpo and he pops up </li>
                    <li> VM on Blago but he gets a NAT 20....Cecil is dumbfounded  </li>
                  </ul>
                </ul>
                <li> Round 7 </li>
                <ul>
                  <li> CORPO </li>
                  <ul>
                    <li> Debates taking a potion but Sparka and Lars are LIKE NO ATTACK! </li>
                    <li> Attacks Irub....KILLS HIM on first hit. Corpo breathin' heavy dashes at him, slides around his legs...staff straight through the  </li>rectum...then on his back kicks the staff the rest of the way up.
                    <li> Attacks the Air Elemental next...punches and FLURRY of BLOWS for 23...but misses his last ki point  </li>
                    <ul>
                      <li> BUT CECIL POINT OF ORDER he gets advantage due to WOLF TOTEM so he hits instead of miss.  </li>
                      <li> SASS ALSO POINT OF ORDER Crusaders mantle. </li>
                    </ul>
                    <li> Elemental is fairly grim </li>
                    <li> Stay and bang. </li>
                  </ul>
                  <li> SPARKA </li>
                  <ul>
                    <li> Holy crap Corpo did a shit ton of damage and he is feeling classy </li>
                    <li> BA Misty Step and casts Opaque WALL OF FIRE 20 feet high </li>
                    <li> Lars...feels nothing...heat only on other side </li>
                    <li> Puts it in a ring </li>
                    <li> Kills Air Elemental...KILLS it by evaporating/blowing it away with fire </li>
                  </ul>
                  <li> BOWSER </li>
                  <ul>
                    <li> Helps Lars with his attack </li>
                  </ul>
                  <li> Blago </li>
                  <ul>
                    <li> Takes fire damage and runs through fire </li>
                    <li> NAT 20 attacks Sparka for 35 damage...drops concentration </li>
                    <li> Misses second attack </li>
                    <li> BA Misty Step away </li>
                  </ul>
                  <li> LARS </li>
                  <ul>
                    <li> Attacks Blago misses twice </li>
                  </ul>
                  <li> SASS </li>
                  <ul>
                    <li> Hits Blago for 21 damage, and again for 20 </li>
                    <li> Duchess attacks for 14 </li>
                  </ul>
                  <li> CECIL </li>
                  <ul>
                    <li> Cecil kills Blago with a Vicious Mockery that explodes his damn head cause that's how he do </li>
                    <li> HEAD WAS A CHERRY TOMATO </li>
                  </ul>
                </ul>
              </ul>
              <li> Lars twists the axe to extend and winks at Cecil </li>
              <li> They search the floor, realize control is upstairs </li>
              <li> Team YOLO waits below to watch the stairs as Lars farts in his chair </li>
              <li> Team Responsible goes up to the control tower </li>
              <ul>
                <li> Cecil NAT 20 to pick the lock...realizes Arcane Lock </li>
                <li> Cecil casts Dispel magic so since picked the door opens </li>
                <li> They see controls everywhere....Dwarven/Giant runes </li>
                <li> Cecil NAT 1 History check to know about runes </li>
                <li> Sparka figures out what Alarm is and turns it off </li>
                <li> Voice of Ghostly Escarlotta asks what happened.  </li>
                <li> Sparka is like "He's dead...your husband bitch" </li>
                <li> She lurches the castle to kamikaze into Phandalin </li>
              </ul>
              <li> Sparka and Cecil run back to tell them it's Dwarven/Giant upstairs </li>
              <li> Corpo raids body...finds an amulet and not much else </li>
              <li> Sass casts Detect Magic and sees some random magic after effects </li>
              <li> Sass and Lars run upstairs and see the runes for....(see list) </li>
              <li> They hit home </li>
              <li> "You killed my husband" "Oh so you single?" "He tried to summon an evil" Lars has bad roll </li>
              <li> She is like oh he tries to do that from time to time...but he's never summoned Yan-C-Bin </li>
              <li> They are like whoa....Yan-C-Bin </li>
              <li> She says his soul will merge now and have full control </li>
              <li> They hit south and descend </li>
              <li> They asks what happens to her...she says not sure...Lars tries to ask her to go to a bar...she like no </li>
              <li> Cecil debates dispel magic on the blue flame but it'd be damn hard </li>
              <li> Lars and Sass tell Corpo what they did and that it was about Yan-C-Bin </li>
              <li> Sparka and co find that hard to believe since he appaers to cooperate with CC and Hadrian </li>
              <li> Corpo runs upstairs </li>
              <ul>
                <li> Corpo notices he could fuck up controls to impair the giant soul from controling it </li>
                <li> Corpo apologizes to her for killing husband thought he was summoning Tiamat </li>
                <li> She is like no we want Yan-C-Bin to destroy this world </li>
                <li> Corpo takes out 10K gold pieces of gems as he rips apart the controls </li>
                <li> Escrolotta is like wtf you doing </li>
                <li> He is like we would have made a great pair, he is like sorry can't let husband control this. </li>
                <li> She says the blue flame is above her tomb so binds her here </li>
                <li> When castle is destroyed she gets to go to giant afterlife...his afterlife only bigger </li>
              </ul>
              <li> Sparka sees people getting to battle stations as Ogres and Cultists walking towards main paths into it </li>
              <li> They debate taking over the castle </li>
              <li> They been there 3 hours </li>
              <li> They worry if they could move her body and break her control over the castle </li>
              <li> They pilfer it </li>
              <ul>
                <li> His chest </li>
                <ul>
                  <li> 12K silver and gold </li>
                  <li> 4K (4 gold bars) </li>
                  <li> 4K Gold in bars but each bar weighs 5 lbs </li>
                  <li> Another bag of holding </li>
                </ul>
              </ul>
              <li> Cecil takes bag of holding </li>
              <li> Sass and Corpo know that Roc landing platform is there </li>
              <li> Cecil takes book of Giants </li>
              <li> In a globe is nice Giant bourbon that Cecil gives to Lars </li>
              <li> Corpo runs back upstairs to try baiting her saying her husband would not be tricked...Cloud Giants are the trickers </li>
              <li> Corpo holds up the Giant dick....remember this? She goes on 10 minute rant of things she used to do to it...Corpo listens intently as a  </li>good listener
              <li> She says in throne room Incel Wizard (Hadrian) and Thopleth  talk about brining back Tiamat </li>
              <li> Corpo asks what the ritual for Yan-C-Bin was...she says the runes would hijack it </li>
              <li> They figure out that Yan-C-Bin or Tiamat both bad news but....here we go.... </li>
            </ul>
          </Collapsible>

          <Collapsible
            title="The High Ground (#21 - 12/30)"
            toggle={this.collapseToggle.bind(this)}
            kind="session21"
            show={this.state.show}
          >
            <ul>
              <li> Corpo is like hey we gonna be rich....But it was stolen....they are like do we need to spend it...Commerce? Rain down economics not trickle down </li>
              <li> Corpo finds Father's Love Shortbow for Corpo </li>
              <ul>
                <li> Sees it is made from Eternal Ice and Many Hue-ed Air Steel...with parts of it made of Elemental Air that is like solidish air </li>
                <li> Corpo crumbles to the floor </li>
                <li> Sees "Father's Love" and "I love you son" in Primordial </li>
                <li> In Dwarven sees a different language...Lars reads Tel Amoth </li>
                <li> OH DADDY </li>
                <li> Property of Haalinith son of Tel Amoth </li>
                <li> "Fuck...Daddy has other kids" "What the shit" </li>
                <li> "Nice bow it is magical.." "...but it was meant for someone else" </li>
              </ul>
              <li> Axe of Ysgard is holding off the horde magic, while the others are absorbing and Bowser is lapping it up </li>
              <li> Realize they need 5 more hours...the aurora is starting to rise up. See a fancy tower and observe Hadrian is there and saw Cultists book it from the cave into the tower...assume Hadrian knows </li>
              <li> Debates how to lure them in </li>
              <li> Wyverns flying around </li>
              <li> Sparka looks out window and sees Giants, ogres, kobolds making their way to the main door </li>
              <li> "In a rare moment of lucidity, Corpo suggests they don't scream their names from the rooftops" </li>
              <li> Can see Wyverns being within 120 ft of the tower to be within message cantrip range </li>
              <li> Debate what to do...Sass is like I say we ride out to meet them! </li>
              <li> REMINDER THEY MIGHT WANT TO CHECK OUT THE CONTROLS </li>
              <li> Cecil goes to check on the roof </li>
              <ul>
                <li> Goes more perceiving as he comes out onto the roof...don't notice Cecil...mumbling about some kinda drill? </li>
                <li> Cecil messages down that at least 3 ogres on the roof </li>
                <li> Cecil comes back into the stairwell </li>
                <li> Leaves the immovable rod </li>
              </ul>
              <li> Are like you could jump off the wyvern through the windows </li>
              <li> Decide to hang by the windows and prepare  </li>
              <li> Takes some potions </li>
              <li> Realize that they will figure out in a bit that Jazelle is dead due to the whole chest being empty </li>
              <li> Cecil hears father.  </li>
              <ul>
                <li> Stopped in Phandalin. Did you find mother? We are on way. Look for me high in the sky </li>
                <li> On flying castle. Didn't find mom. About to look for Hadrian et all. </li>
              </ul>
              <li> Lars is like would Cecil's mom be grateful for her rescuers? Something about mustache rides </li>
              <li> Hear a voice "Well you guys look like you are screwed" Succubus shows up </li>
              <ul>
                <li> Lars is like I'll make a deal, Corpo is like I'll sleep with you </li>
                <li> She is like LATER </li>
                <li> Lars is like we could do with some healing and baddies delivered right to our front step </li>
                <li> NAT 20 Sparka Insight check to see the Succubus is messing with him, figures out the contract was in Abyssal not Infernal so not real </li>
                <li> "What am I supposed to do with this Virgin Blood?" </li>
                <li> Says she has ideas to help them....where Cecil's mom </li>
                <li> She says Hells are like a scheme in a triangle-shape...client patrons client patrons </li>
                <li> Her patron is big time </li>
                <li> She shows them illusion of Avernus and the Blood War raging with Devil shield walls vs Demon horde </li>
                <li> Lars is going to turn his life around </li>
                <li> Sparka sees Temple of Tiamat in Avernus </li>
                <li> Explains when you die you can go on...or go to an outer plane </li>
                <li> They see the souls streaming in and becoming larva lemures... </li>
                <li> Evil planes are you think you can come out on top </li>
                <li> See the souls going to Tiamat are becoming Abbishai </li>
                <li> She shows Jazelle stopped from being fully a fiend...shifting back and forth... </li>
                <li> Says her patron has paused the transformation... </li>
                <li> Says hey when you get to Thon Daral find someone who will help you make a deal to make Jazelle into an Abbishai  </li>
                <li> Sees Abbishai dying...if you die on your home plane you die and go away </li>
                <li> Lars negotiates 8HP for a vial of virgin blood  </li>
                <li> Sass is like I work or BAHAMUT BABY </li>
                <li> "We carry around Dragon phalluses but not bodies of a dead loved ones" Draw a line </li>
                <li> Corpo offers her his dagger for healing...she is like eh maybe not </li>
                <li> Sass is like are we making deals with a Succubus? </li>
                <li> Cecil is like I know where my mom is in the fancy fucking tower. </li>
                <li> Sass hollered that fuck you bitch...she is like fine I'll be nice </li>
                <li> She is like eh powerful people might be interested in you </li>
              </ul>
              <li> Cecil transported to the Endless Aethereum and saw whispy Librarian Angel. Says this d4 is the last boon he gets for just being him...must makes the world more for her, spread knowledge, stories...etc </li>
              <li> Sass Cure Wounds himself..how did it go... </li>
              <li> They see the Giants coming up the stairs </li>
              <li> Sparka tries to brainstorm giants </li>
              <li> Kill anything with a dick, keeps your mom alive says Lars </li>
              <li> Lars decides to convince Giants to join them since the whole scheming who know </li>
              <li> Sparka goes minor illusion as Blago "The Elf and Dragonborn have turned on us, we need to attack them immediately" </li>
              <li> Stone Giant gets a NAT 20...is sus </li>
              <li> Sass goes with Thamaturgy to shake the ground to give Sparka help...still sus </li>
              <li> Lars pokes head out window. Says he is a peaceful dwarf emissary of Blago...the Gold Elf and Dragonborn have turned against them...please join us in fighting them....Lars NAT 1 Persuasion check to convince the giants </li>
              <li> Giant yells to the castle and yells "THEY ARE IN THE KEEP" </li>
              <li> Lars feels lightnings coursing through him as his vision blurs and he sees Vikings longships....his axe floats up and Valkyrie says he is at his last boon receive for just being a man himself. He needs to start competition in bars with arm wrestling....dice games...battle of bands....RISE AND RAID...FUCK SHIT UP  </li>
              <li> Sees 20 kobols...two giants....ogres... </li>
              <li> ROLL INITIATIVE  </li>
              <ul>
                <li> Round 1 </li>
                <ul>
                  <li> kobolds </li>
                  <ul>
                    <li> charge door, can't break it down </li>
                  </ul>
                  <li> CORPO </li>
                  <ul>
                    <li> NAT 20 Short bow shoots happy Giant neighbor (only one shot? </li>
                  </ul>
                  <li> SPARKA </li>
                  <ul>
                    <li> Thinks for a second...but...is like damn ok FIREBALL for 24 and hits all but kobolds </li>
                    <li> KILL Dragon Scale Sorcerer </li>
                    <li> KILL  kobold to it's death off the bridge </li>
                  </ul>
                  <li> SASS </li>
                  <ul>
                    <li> they are all guilty </li>
                    <li> Hail of Thorns hits and explodes for damage </li>
                    <li> Shoots the big giant for 14 more </li>
                  </ul>
                  <li> Giant </li>
                  <ul>
                    <li> Hurls boulder up at Lars in the window, but Lars does not get knocked prone </li>
                  </ul>
                  <li> LARS </li>
                  <ul>
                    <li> Hits Giant with two Javelin...and tucks behind wall </li>
                  </ul>
                  <li> CECIL </li>
                  <ul>
                    <li> Vicious Mockery you are not our neighbor anymore to hit Giant </li>
                  </ul>
                  <li> BOWSER </li>
                  <ul>
                    <li> Moves down </li>
                  </ul>
                  <li> Hulda Giant </li>
                  <ul>
                    <li> Hits door, hurts but not breaking </li>
                  </ul>
                  <li> Ogre </li>
                  <ul>
                    <li> Charges </li>
                  </ul>
                  <li> Ogre </li>
                  <ul>
                    <li> Runs away </li>
                  </ul>
                </ul>
                <li> Round 2 </li>
                <ul>
                  <li> Kobold </li>
                    <li> Attack door again </li>
                  </ul>
                  <li> CORPO </li>
                  <ul>
                    <li> Shoots Giant for 10 damage </li>
                    <li> Shoots Giant for 7 more damage </li>
                  </ul>
                  <li> SPARKA </li>
                  <ul>
                    <li> KILLS Firebolt blasts Giant, falls to kill 4 kobolds </li>
                    <li> BA TK shove Hulda off the bridge! </li>
                  </ul>
                  <li> SASS </li>
                  <ul>
                    <li> KILLS Ogre with Sarpshooter does not decapitate...shoots penis off such that he flops on such one kobold is KILLED by penis falling </li>
                    <li> Sharpshoots other Ogre </li>
                  </ul>
                  <li> LARS </li>
                  <ul>
                    <li> Throws Javelin at Ogre hit </li>
                    <li> NAT 20 KILLS Ogre by throwing it through his throat...falls on 2 Kobolds </li>
                  </ul>
                  <li> CECIL </li>
                  <ul>
                    <li> Vicious Mockery Ogre "You're the last one left" then runs away </li>
                  </ul>
                  <li> BOWSER </li>
                  <ul>
                    <li> Chills </li>
                  </ul>
                </ul>
                <li> Round 3 </li>
                <ul>
                  <li> CORPO </li>
                  <ul>
                    <li> Shoots and KILL run away Kobold </li>
                  </ul>
                  <li> SASS </li>
                  <ul>
                    <li> Hits twice...? damage and then 24 damage </li>
                  <li> LARS </li>
                  <ul>
                    <li> Throws 2 Javelins at Running lady Giant for 14 damage </li>
                  </ul>
                  <li> SPARKA </li>
                  <ul>
                    <li> FIREBOLT Hits </li>
                    <li> KILL Giant right in head....dies from suffocation BA Quickened Spell Firebolt for 20 damage </li>
                  </ul>
                </ul>
              </ul>
              <li> Repulsed attack </li>
              <li> Debate what to do...Corpo wants to charge, others are like lets let them come to us </li>
              <li> Wait Cecil's dad is coming they recall </li>
              <li> Lars swings dick out the window...Hadrian misses a Firebolt shot </li>
              <li> Cecil notices the circling Wyverns dive and dip under the castle...two on each Wyvern...as if they coming up from below </li>
              <li> A golden canary flies up to Sass and chirps to him...he chirps back </li>
              <ul>
                <li> He feels inside understanding...that Bahamut's path is hard...you have to walk justice and place. </li>
                <li> He gets his last boon without making the world a more just and merciful place </li>
                <li> The scale above his heart turns Platinum </li>
                <li> He is immune to being Frightened </li>
                <li> He can replace a breath weapon with a healing breath attack by subbing for a damage one </li>
                <li> PRAISE BAHAMUT MAY HIS BREATH SMELL OF DAISIES </li>
                <li> PRAISE BAHAMUT MAY HIS CLOACA BE PLATINUM </li>
                <li> PRAISE BAHAMUT MAY HE BLOW YOU ALL </li>
              </ul>
              <li> They start hearing upstairs ogres pounding the door </li>
              <li>  Decide to deal with the Roof Ogres by pretending to be BLAGO </li>
              <ul>
                <li> Cecil does Minor Illusion "STOP POUNDING THE DOOR. Attack the Gold Elf and Dragonborn, they've duped us, they are going to back stab us" </li>
                <li> The fail and only way through is through the door.... </li>
                <li> So they open the door and hide behind it...group Stealth check passes </li>
                <li> Take immovable rod with them </li>
                <li> They see the dead bodies...."THE GOLD ELF DID THAT" </li>
                <li> "ALREADY THEY HAVE FAILED TO STOP YAN C BIN RETURN" </li>
                <li> They charge at the tower...Hadrian casts Fireball, the Gravity Sinkhole to suck them up in the air </li>
                <li> Cecil NAT 1 to see check what Hadrian is doing to the Ogres </li>
                <li> Sparka NAT 20 Arcana to Identify the Gravity Sinkhole, Cecil rolled high too. Know it is Dunamancy Space Time Manipulation.  </li>
              </ul>
              <li> Take up positions and hear wings flapping </li>
              <li> Corpo cracks the door and hears a Wyvern landing </li>
              <li> Immovable Rod the door, Grey November Pentagram knocks and demand that they surrender </li>
              <li> Corpo is like we are with BLAGO....and Grey is like nope we know BLAGO is dead </li>
              <li> Sass is like I demand you suck my dick </li>
              <li> Lars drops blood and drops pants to summon succubus </li>
              <ul>
                <li> "WE LEFT THEM ALONE" yells Cecil </li>
                <li> Corpo and Lars are staring at each other with pants down and virgin blood on the floor </li>
                <li> She is like uh bedroom?  </li>
                <li> "There are people outside the door, I want you to light them on fire" </li>
                <li> Lars is like you saw my dick you are in my debt...she is like I can't light on fire but I can kill them with a kiss </li>
                <li> Corpo offers the dagger...she takes the dagger and walks through the door and has killed him...they hear corpse fall down </li>
              </ul>
              <li> ROLL INITIATIVE </li>
              <ul>
                <li> Sass NAT 20 Init roll </li>
                <li> Round 1 </li>
                <ul>
                  <li> SASS </li>
                  <ul>
                    <li> Holds to shoot </li>
                  </ul>
                  <li> CECIL </li>
                  <ul>
                    <li> Holds to cast VM </li>
                  <li> Cultist - Dr. Steel Night </li>
                    <li> Hops in window </li>
                    <li> Gets shot by Sass...called a bitch </li>
                  </ul>
                  <li> SPARKA </li>
                  <ul>
                    <li> BA TK shove Cultist out the window to nearly his death </li>
                    <li> Yells Lars Corpo get over here </li>
                    <li> Hold action to firebolt </li>
                  <li> Cultist  </li>
                    <li> Hops in bedroom </li>
                  <li> LARS </li>
                    <li> Sprints back, sees Cultist </li>
                  </ul>
                  <li> CORPO </li>
                  <ul>
                    <li> Moves rod, sees dead body </li>
                    <li> Pilfers Grey body...finds a scimitar </li>
                    <li> "I hope you felt useful" </li>
                    <li> BA to dash 55 towards Lars </li>
                  <li> Cultist Infernal Ashe </li>
                    <li> Hops in window </li>
                    <li> Cecil calls her a poke trainer but survives VM </li>
                    <li> Sparka Firebolts her face </li>
                  </ul>
                  <li> BOWSER </li>
                  <ul>
                    <li> Chills </li>
                  </ul>
                  <li> Brian drops  someone off </li>
                </ul>
                <li> Round2 </li>
                <ul>
                  <li> SASS </li>
                  <ul>
                    <li> Shoots Infernal Ashe </li>
                    <li> Sharpshoot misses </li>
                    <li>  BA Duchess Attack Infernal Ashe KILL by biting her head off. Head falls over in front of other Cultist </li>
                  </ul>
                  <li> CECIL </li>
                  <ul>
                    <li> VM Carnal Serpent that he should meet Lars </li>
                    <li> BA to give Bardic to Lars....never Corpo "You're Fucking Reckless" </li>
                  </ul>
                  <li> SPARKA </li>
                  <ul>
                    <li> BA TK shove fails </li>
                    <li> Firebolt misses </li>
                  </ul>
                  <li> LARS </li>
                  <ul>
                    <li> Hits Joy is a Myth twice  </li>
                    <li> Knocks down and asks how many more there are (while Corpo unbuckles his belt) That's why I don't give you bardic </li>
                    <li> Interrogation fails, spits in Lars face, he spits back, grabs a phallus and hits her with it, spays her...shoves her out window and high fives with a bloody hand Corpo </li>
                  </ul>
                  <li> CORPO </li>
                  <ul>
                    <li> His pants are up </li>
                    <li> Sprints to attack Carnal Serpent Hits with Staff, Hits with Staff, Hits with Punch to KILL Carnal Serpent with Georgian dancing on his knees, spinning around elbowing the guy's leg so he is crippled to the ground, then when he is on his knees he stands over him and says "Be grateful you don't meet my Carnal serpent today" and then snaps his neck. His last words are...what? </li>
                  </ul>
                  <li> BOWSER </li>
                  <ul>
                    <li> Flies to helps Sass </li>
                  </ul>
                  <li> Cultist - Feral Shadow </li>
                  <ul>
                    <li> Charges Sass, misses twice </li>
                    <li> Runs and jumps out the window after failing </li>
                    <li> Sass slices with Vorel Vivex as AOO </li>
                    <li> Lands next to other dying body </li>
                  </ul>
                </ul>
                <li> Round 3 </li>
                <ul>
                  <li> SASS </li>
                  <ul>
                    <li> KILL Dr. Steel Night arrow through his weener </li>
                    <li> KILL Feral Shadow arrow through his weener </li>
                  </ul>
                </ul>
                <li> Debating if 3 Ogres count as kills </li>
                <li> Other Wyverns fly away...withdraw as plan failed </li>
                <li> THAMATURGY Thopleth yells hide behind your walls, come out and face me bitch </li>
                <li> Hadrian Sending to Lars about leave the half mongrels come with me. Lars answers Never, Fuck you, no, for 25 words </li>
                <li> Ben and Mike clash over if they are on same team </li>
                <li> Corpo tries to win back Brian but he shits on his foot </li>
                <li> Castle shudders as Blago rolls against Corpo's fuckery (roll per hour) </li>
              </ul>
            </ul>
          </Collapsible>

          <Collapsible
            title="The Cousin, the Shapeshifter, and the Fabio - 2 LAKE 2 GENEVA (#22 - 1/30)"
            toggle={this.collapseToggle.bind(this)}
            kind="session22"
            show={this.state.show}
          >
            <ul>
              <li> Corpo vs Blago roll off. Blago got a NAT 1 vs Corpo's 15 so delayed Blago taking over from an hour </li>
              <li> Realize that currently it will reach Phandalin and crash before 8 hours is complete </li>
              <li> They have 5 hours to complete hoard magic absoprtion </li>
              <li> Thopleth is taunting them....hide behind your walls Greosass...That's why she chose me </li>
              <li> Thopleth: "YOU COWARD!" </li>
              <li> Lars doesn't think it is smart to just walk over there (Tower is over 130 feet) </li>
              <li> Cecil's Dad is incoming </li>
              <li> Sass goes to the window to yell at Thopleth... "Come at me bro, we going to crash this mother fucker" Ballistae misses him </li>
              <li> Sass stands anyway but sees Thopleth wearing Jazelle's body. Investigation check passes that he is using his illusion duplicate as he watches a snow flake pass through him. Thopleth is hollering back </li>
              <li> "Come over here and I'll...come on...your face..." </li>
              <li> "Listen not to the false ho..she deserved not your attention in life she does not deserve it in death" Corpo says </li>
              <li> NAT 1 Sass Arcana check on perfect duplicate </li>
              <li> NAT 1 Lars Arcana check on perfect duplicate </li>
              <li> Sparka knows that Thopleth's illusion can't be hurt but can cast spells </li>
              <li> ELECTRUM is worth 1/2 piece of gold </li>
              <li> "I saw a snow flake go through it's head..." "YES GET AWAY FROM THE WINDOW!" </li>
              <li> Sass steps back and Thopleth hollers "RUNNING AWAY AGAIN. I'll go back to Jazelle and have her now" </li>
              <li> They realize Thopleth doesn't know she is dead </li>
              <li> "Should we throw her body out the window?" </li>
              <li> Sparka knows that Bahamut faith does not throw corpses of loved ones out of windows </li>
              <li> Sparka also is like...hey....he doesn't know we have the mask.... </li>
              <li> "Can we negotiate what does it take to get you to fuck off?" "You can suck this dick" "Which one" "Definitely mine" </li>
              <li> "Sparka is like we will leave for percentage of the hoard and this castle on the ground...not near Phandalin" </li>
              <li> Corpo watching the stairwell </li>
              <li> If they peak out windows might get ballista-ed </li>
              <li> "Thopleth is like fine I am going back to complete the ritual to summon Tiamat" </li>
              <li> Sparka is like "Where you gonna get the blood?" </li>
              <li> Thopleth noticed the castle shifting south..."What did you do?" </li>
              <li> "I butt fucked the giant's soul" by casting minor illusion </li>
              <li> Cecil and Sparka plot if telling him would about Yan C Bin would be good idea </li>
              <li> Sparka hollers asked her if Thopleth talked to Escarlotta </li>
              <ul>
                <li> ...and he says no... </li>
                <li> "Maybe you should have talked to the person driving the castle"  </li>
                <li> "You think you are summoning Tiamat" </li>
                <li> "We are" </li>
                <li> "Tell that to the summoning circle that is actually going to bring Yan C Bin here"  </li>
                <li> Cecil amazing Persuasion check </li>
                <li> "YOU'RE AN IDIOT THOPLETH" Sparka "All your plan will amount to nothing... </li>
                <li> Sass: "AND YOUR TINY PENIS" </li>
                <li> "So leave the treasure for us and leave or stay and summon Yan C Bin for Blago, who is dead now  </li>
                <li> ...."We figured you had killed Blago" </li>
                <li> "NEVER GET IN BED WITH A GIANT" Sparka hollers "Did you really think they would let you summon Tiamat and not do something for their own ends..." </li>
                <li> "...we thought that they..." </li>
                <li> "YOU DIDN'T THINK TO TALK TO THE PERSON DRIVING THE CASTLE?!?!?" </li>
                <li> "...it's impolite to go into someone else's bedroom" </li>
                <li> "Ironic coming from you..." Sparka looks at Sass crying in the corner...  </li>
                <li> Sass: "...yeah...bitch" </li>
              </ul>
              <li> Thopleth is like sure you went into my bedroom you have been through our things. Luckily she was out scouting. Sass I know you know she is alive. </li>
              <li> Lars and Corpo start giggling </li>
              <li> Sparka de-attunes to the Stone of Reserve (fuck that) and starts attuning to the Mask </li>
              <li> Sparka is like you have no point in being here...just leave </li>
              <li> Thopleth is clearly flustered... </li>
              <li> Sparka is like We take orders form no one, just ourselves...."Sometimes not even then" says Cecil  </li>
              <li> Cecil is like give me my mom </li>
              <li> Thopleth is like ahh yesss....give me an offer to get her back </li>
              <li> Corpo whispers to ask if she was any good...Cecil is like yeah she was the best...Corpo wanted a tragic abuse thing to just let her die </li>
              <li> Corpo jokes he can be sent over as an envoy </li>
              <li> Sass is like shit he has your mom....Sparka is like shit he had YOUR WIFE! </li>
              <li> .....Corpo makes a Wyvern call....gets a 19...as </li>
              <li> Corpo takes a ballistae shot..deflects missile 20/22 damage.  </li>
              <li> Lars starts doing mushrooms and doing WOLF TOTEM to do speak with animals as he starts taking it off </li>
              <li> Sparka shows Cecil the Dragon Mask...says needs 45 minutes to attune and asks Cecil for time </li>
              <li> No one but Lars can hear the music as he is going nuts </li>
              <li> Corpo thinks all Wyverns all look the same </li>
              <li> Sparka thinks he and Cecil can do better than Corpo </li>
              <li> Corpo Cecil plot </li>
              <ul>
                <li> Cecil does a Minor Illusion of Corpo in all his Androgynous Glory in one window to attract the Ballistae </li>
                <li> Only one buys it, Sparka does minor illusion on his side and both do </li>
                <li> Sparka gonna popout other window to Firebolt the ballistae to burn it and light on fire </li>
                <li> Ogres try to put the fire out, more cagey now due to the illusions (can do a Stealth check) </li>
                <li> Sass stealths to  Sharpshooter and snipes an Ogre </li>
                <li> Ogres are all taking cover now </li>
              </ul>
              <li> They recall that Cecil daddy said "we" are on the way </li>
              <li> Lars completes his ritual and talks to Brian the Wyvern...calls him "Master Brian" and is super respectful </li>
              <li> Lars asks him to fly close to the window so Master Corpo can jump </li>
              <li> DM NOTES THAT LARS CANNOT SEE BRIAN </li>
              <li> Corpo-Action Hero </li>
              <ul>
                <li> Corpo wings unfurled jumps onto Brian as he flies by... </li>
                <li> But Brian already has a rider </li>
                <li> Initiative Corpo gets a dirty 20 </li>
                <li> Corpo attacks the rider October Tempest Death as Lars hears Brian yell welcome to the party </li>
                <li> Corpo attacks twice with staff </li>
                <li> The Ogres are hiding and have not seen yet </li>
                <li> Corpo punches twice and hits but only does total of 33/35 </li>
                <li> Rider is like AHHHHHHHH, takes turn to turn Brian upside down </li>
                <li> Corpo NAT 20 Dex Save he holds on by one hand </li>
                <li> Corpo KILL October Tempest Death says October is basic bitch month as he pokes October over and over, then jabs in nuts as October falls from the sky...splatters into red mist. </li>
                <li> "Good job Brian you are doing great" </li>
                <li> Sparka sees it all </li>
                <li> Corpo nose dives Brian down to do a flyby of the tower to get out of sight of everyone </li>
                <li> DM: "I HATE YOU" </li>
                <li> Corpo leaps off the tower to fall onto the tower. DM is like You are single handedly going to go land... </li>
                <li> Cecil: "What the hell is going on?" Sparka: "He is on the other side now" Cecil: "The tower we didn't want to go to?" Lars is like "I didn't talk to him" </li>
                <li> Corpo rolls stealth against Hadrian's perception and passes </li>
                <li> Corpo lights a cigar </li>
                <li> He is on a slanted conical tower...and...he is just laying down having a blast </li>
                <li> Corpo Minor Illusions Thumbs up </li>
                <li> "Even Lars is shaking his head now"  </li>
              </ul>
              <li> It's 9:30...35 minutes to complete short rest "That was super restful" Corpo says </li>
              <li> Corpo cannot see beyond the edge of the conical tower without being seen </li>
              <li> Thopleth walks out as Jazelle but with less coverings...booms voice "You need to leave the tower now or the human whore dies then he leaves" "...I think that is my mom...." "Is she a whore?" </li>
              <li> The debate throwing her corpse out the window or showing the mask to force his hand </li>
              <li> "Hey Thop-dick, get over here...I don't know if you know who I am...I am the Dragon also known as Ginobli the well endowded." Thopleth is like I have heard of one who claims to be a dragon but is not </li>
              <li> "I am going to call bullshit on your Jazelle. We ran into here, we ran a train on her, now I am going to cut her head off just to fuck you"  He stacks her head on the corpse and pretends to decaptiate her and kick her out the window </li>
              <li> Thopleth is surprised.... </li>
              <li> Note...they throw her body out instead of casting minor illusion </li>
              <li> "I AM THE DRAGON" he yells as he watches the body falls. Sparka catches the head with Telekinetic hand so it does not smash </li>
              <li> Thopleth is silent. Lars has a crazy look in his eyes </li>
              <li> Sparka says "Draw your own conclusions as to what else that means" to hint at the mask </li>
              <li> Corpo casts minor illusion of question mark between the tower....then an ellipsis </li>
              <li> Thopleth "So that is how it is going to be...That's the way you want to play..."  </li>
              <li> "We've only just begun Thop-dick" Lars yells out </li>
              <li> Ballistae start hitting the door below "Sounds like what we did to Jazelle" </li>
              <li> Sparka peaks out and casts Firebolt at the Ballistae </li>
              <li> Sass peeks but Thopleth sees him and casts dominate person....but resists. SUPER LUCKY </li>
              <li> Meanwhile Corpo is playing with himself meanwhile </li>
              <li> Cecil sees like it recognizes the charming/domination bit and starts Countercharm song </li>
              <li> Corpo hears people looking for Brian the Wyvern and the rider...Corpo holds down longer </li>
              <li> 20 minutes from the rest being done. </li>
              <li> "We can play this all day...I think we know why I am angrier now..." "Cause your wife is dead..." "...cause you got blue balls!" He says "She was moderate at best...." Sass is not afraid and yells back. "I think we know what is going to happen here...you are going to hand over everything else that was on her body or you are going to rue the day your pathetic, miserable, lives were born on this material plane...."  </li>
              <li> Lars yells "We handed over some stuff into her body...."  </li>
              <li> Sparka "Thopleth why don't you come over here...or go back into your tower and think about it and still do what we have already said..."  </li>
              <li> Sass "...and suck a dick...." </li>
              <li> Corpo sneaks a peak and sees ballistae are being aimed now. </li>
              <li> Thopleth "Fine  deal with this!" As three cages are pushed out with ropes are on fire. "They don't have long, the ropes are burning. One of them is...Mommy"Sparka is like hey "What mask?" Thopleth is a little confused...but is like "Well...you better find it before they fall" </li>
              <li> Corpo is like.....I am close </li>
              <li> Cecil peaks out the window stealthily to see Thopleth...looks at the cages. Can tell top is the dummy, mother is in the middle one, lower one just a dude </li>
              <li> Sparka makes a minor illusion out the window to show three cages with an X through the top one to show a dummy </li>
              <li> Realize Corpo has Featherfall, Lars is prepped to jump if need be </li>
              <li> Cecil debates having Bowser fly over and cast one of them fly </li>
              <li> Lars hears noise in the Tower </li>
              <li> ROLL INIT </li>
              <ul>
                <li> Round 1 </li>
                <ul>
                  <li> LARS </li>
                  <ul>
                    <li> Faces back door and holds action to chuck Javelin at anyone entering the door </li>
                  </ul>
                  <li> SPARKA </li>
                  <ul>
                    <li> Holds action to Firebolt first ENEMY walking through the door </li>
                  </ul>
                  <li> CORPO </li>
                  <ul>
                    <li> Thinks ropes will burn for a minute. </li>
                    <li> "What are you doing Corpo?" "Hold on I need to setup my music"  </li>
                    <li> Free fall 15 ft and wings unfurled and is about to use Breath of Dragon cold...then realizes cold damage might destroy the ropes. Goes with Gust instead. Rolls a 20 + Wisdom and blows out the fire on the second level. </li>
                    <li> Goes back up to the roof </li>
                  </ul>
                  <li> Abara Red Wizard </li>
                  <ul>
                    <li> Scorching Ray miss then hit and hit the two cages with people  </li>
                    <li> CORPO uses reaction to Featherfall the cages...Cecil sees them falling with Air Magic...Corpo claims it is Marylin Monroe moment. Cecil thinks they should hit the ground safely </li>
                  </ul>
                  <li> CECIL </li>
                  <ul>
                    <li> Makes eye contact with CORPO and for the first times gives him a nod. First time hey you didn't fuck up </li>
                    <li> Readies Vicious Mockery for first opponent </li>
                  </ul>
                  <li> Ogre Ikurk </li>
                  <ul>
                    <li> Moves into room </li>
                    <li> LARS holds action to hit with Javelin, NAT 1 missed with Javelin </li>
                    <li> CECIL VM "get ready to get a train run on you"  </li>
                    <li> SPARKA hits with Firebolt </li>
                    <li> He charges and chuck a javelin at Sparka but misses </li>
                  </ul>
                  <li> Thopleth </li>
                  <ul>
                    <li> Walks on in and Sparka notices it is not his illusion </li>
                    <li> Low cut top all the way to the belly button </li>
                    <li> Casts Banishment on Lars...briefly is in the Feywild...but passes the save so comes back </li>
                  </ul>
                  <li> Hadrian Solonna </li>
                  <ul>
                    <li> Casts Fireball at 4th....BUT Cecil Counterspells it. Shreds his Lute and counters the fireball so it does not explodes </li>
                    <li> Looks at Cecil and says "Mongrel Bitch" </li>
                    <li> Cecil identifies Globe of Invulnerability 10 ft around him to block magic </li>
                  </ul>
                  <li> BOWSER </li>
                  <ul>
                    <li> Holds to Help Lars attack Hadrian </li>
                  </ul>
                  <li> Ogre </li>
                  <ul>
                    <li> Throws a Javelin at Sparka...but slams down staff to cast Shield </li>
                  </ul>
                  <li> Ogre </li>
                  <ul>
                    <li> Throws a Javelin at Sparka not knowing about shield...HITS anyway </li>
                  </ul>
                  <li> SASS </li>
                  <ul>
                    <li> Gordon Ramsay Snowball this shit </li>
                    <li> Shoots at Thopleth, NAT 20 Arrow, uses Squire ability to hit for a shit ton of damage total. Seriously walked in and got F-ed up immediately. </li>
                  </ul>
                </ul>
                <li> Round 2 </li>
                <ul>
                  <li> LARS </li>
                  <ul>
                    <li> A little drunk as SPARKA yells goes for the WIzard </li>
                    <li> RAGE as he charges into the Globe of Invulnerability </li>
                    <li> Hadrian tries to filter Lars out of existence briefly...but passes the check damnit  </li>
                    <li> Hits twice...for 11...passes con...16...fails con. Globe Invulnerability FALLS </li>
                    <li> Mage Slayer yells "I am the DRAGON" and humps the air </li>
                  </ul>
                  <li> SPARKA </li>
                  <ul>
                    <li> Notices Thopleth more f-ed up than Hadrian </li>
                    <li> Firebolt on Thopleth TO KILLS HIM. Sparka puts on the mask and stares at Thopleth to scare him...dies burning and screaming </li>
                    <li> Hadrian does not notice </li>
                    <li> Quicken Firebolt to blast Hadrian broadside </li>
                  </ul>
                  <li> CORPO </li>
                  <ul>
                    <li> Cannot see anyone else as he sees the cages falling </li>
                    <li> Make a "Siren Call" to bring in Brian (will take him 2 rounds) </li>
                  </ul>
                  <li> Abara Red Wizard </li>
                  <ul>
                    <li> Pops out the window and Scorching Ray shoots at Corpo...hits 1/3 </li>
                    <li> "It's a she? I seduce" "IT'S NOT YOUR TURN" </li>
                  </ul>
                  <li> CECIL </li>
                  <ul>
                    <li> Sees Hadrian...cousin who kidnapped and nearly killed Cecil's mom. </li>
                    <li> "Cecil normally keeps a cool head but...this might be a little overkill but fuck it" </li>
                    <li> Casts Dissonant Whispers on Hadrian...NAT 1 Hadrian...leaves Hadrian on 1 Health!!! </li>
                    <li> Hadrian must run away...Lars gets an AoO...uses the cross bow...but....misses </li>
                  </ul>
                  <li> Ogre Ikurk </li>
                  <ul>
                    <li> Misses Lars with a club </li>
                  </ul>
                  <li> Hadrian Solonna </li>
                  <ul>
                    <li> Blasts a fireball...Duchess down </li>
                    <li> Ran into his room </li>
                  </ul>
                  <li> BOWSER </li>
                  <ul>
                    <li> Helps attack a certain ogre </li>
                  </ul>
                  <li> SASS </li>
                  <ul>
                    <li> KILLS ogre with Precise Strike through his ears so he falls in front of other dude. </li>
                  </ul>
                  <li> Ogre </li>
                  <ul>
                    <li> Misses Lars </li>
                  </ul>
                  <li> Ogre </li>
                  <ul>
                    <li> Hits Lars but he is not dead yet </li>
                  </ul>
                  <li> Kobold  </li>
                  <ul>
                    <li> Throws a spear at Corpo...does 2 damage </li>
                  </ul>
                  <li> Kobold  </li>
                  <ul>
                    <li> Throws a spear at Corpo...does 13 damage </li>
                  </ul>
                </ul>
              </ul>
              <li> Round 3 </li>
              <ul>
                <li> LARS </li>
                <ul>
                  <li> Holds turn for Sparka.... </li>
                </ul>
                <li> SPARKA </li>
                <ul>
                  <li> Sparka says "Let it happen Lars"  </li>
                  <li> Creates space around Lars and casts  vortex warp....shuttles Lars into the room where Hadrian has run away... </li>
                  <li> LARS Takes turn </li>
                  <ul>
                    <li> "We could have made a deal" </li>
                    <li> "The only deal you can make is with the axe of YSGARD! BY THE THIGHS OF KORD" </li>
                    <li> First attack Hadrian stops with Shield </li>
                    <li> With second strike KILLS Hadrian by slicing off legs. Asks Hadrian where Corpo's daddy is. "With my DADDY!" Lars chops his balls off, shoves them down his mouth, Cecil yells bring me his head, Lars gets a silver platter. Will place in a bag of holding </li>
                  </ul>
                </ul>
                <li> CORPO </li>
                <ul>
                  <li> Steps back and holds turn </li>
                </ul>
                <li> Abara Red Wizard </li>
                <ul>
                  <li> Shoots fireball at the cages </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> Dissonant Whispers at Ogre...fails and runs away towards Lars </li>
                  <li> Healing Word on Sass </li>
                </ul>
                <li> BOWSER </li>
                <ul>
                  <li> Holds to help attack an Ogre </li>
                </ul>
                <li> SASS </li>
                <ul>
                  <li> Shoots Ogre that Bowser is distracting and hits twice.  </li>
                </ul>
                <li> Ogre </li>
                <ul>
                  <li> Hits Lars and takes him down...he specifically falls so the head stays in his hands </li>
                </ul>
                <li> Ogre </li>
                <ul>
                  <li> Misses Lars to Coup De Grace him </li>
                </ul>
                <li> ??? </li>
                <ul>
                  <li> NAT 20 Corpo Perception check sees that Brian...is not a Wyvern....it is a Pegasus made of star energy and he sees two people on top of it </li>
                </ul>
                <li> Kobold </li>
                <li> Kobold </li>
              </ul>
              <li> Round 4 </li>
              <ul>
                <li> LARS </li>
                <ul>
                  <li> Death Save Fail...1 Strike </li>
                </ul>
                <li> SPARKA </li>
                <ul>
                  <li> NAT 20 Firebolt Ogre KILLS him by turning him to ash </li>
                </ul>
                <li> CORPO </li>
                <ul>
                  <li>  Assumes it is Cecil's Daddy coming </li>
                  <li>  "I'm counting on your dad to save me" </li>
                  <li> Adam: "I like that you are on the roof you are trying to catch on fire and you have 3 hit points...but please continue" </li>
                  <li> Uses Minor Illusion to screams to Cecil's Daddy SAVE THE CAGES </li>
                </ul>
                <li> Abara Red Wizard </li>
                <ul>
                  <li> Blasts another Fireball </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> Viscous Mockery "YOU'RE NEXT FUCKER" </li>
                  <li> Healing Word to bring Lars back </li>
                </ul>
                <li> BOWSER </li>
                <ul>
                  <li> Helps Sass attack the Ogre </li>
                </ul>
                <li> SASS </li>
                <ul>
                  <li> Precise Strike on Ogre </li>
                  <li> NAT 1 Arrow shot at Ogre...misses </li>
                </ul>
                <li> Ogre </li>
                <ul>
                  <li> Clubs LARS to KILLS  </li>
                </ul>
                <li> Matrim Solonna </li>
                <ul>
                  <li> Flies by Corpo standing on his Spectral Steed shredding his lyre as he casts healing word on his wife to stabilizer her </li>
                </ul>
                <li> Wildon </li>
                <ul>
                  <li> Hops off Matrim's steed, invisible but tucks and rolls and lands next to Corpo </li>
                  <li> "HOLY FUCKING SHIT THIS IS CRAZY" he drops invisibility  </li>
                  <li> "Oh my white knight" "As you go down..." "ON WILDON"  </li>
                  <li> Casts Cure Wounds on Corpo </li>
                </ul>
              </ul>
              <li> ROUND 5 </li>
              <ul>
                <li> LARS </li>
                <ul>
                  <li> Death save pass </li>
                </ul>
                <li> SPARKA </li>
                <ul>
                  <li> NAT 1 Firebolt at ogre misses </li>
                  <li> Shoves Ogre towards Sass </li>
                </ul>
                <li> CORPO </li>
                <ul>
                  <li> Grasps Wildon to his bosom and kisses him  </li>
                  <li> "GET IN CHARACTER BOY" "My character is..." "Your character is fucked up..." "My character is like WTF is going on over there" "Your character married a HO" "I'm not like dry humping a midget" "I DIDN'T DRY HUMP HIM I PULLED HIM CLOSE"  </li>
                  <li> Wildon gives him a Leia kiss "Good Luck" "I love you" "I know" </li>
                  <li> Spider mans off the roof into the window </li>
                  <li> He enters the room and sees two kobolds and Abara Red Wizardess </li>
                  <li> Corpo attacks Wizard with staff for 12, staff for 10, punches for 6, punches again for 7 </li>
                </ul>
                <li> Abara Red Wizardess </li>
                <ul>
                  <li> Runs and jumps out the window and jumps out the window </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> Has Bowser attack with sting Ogre. Ogre fails </li>
                  <li> BA Healing Word on Lars to bring him back </li>
                </ul>
                <li> SASS </li>
                <ul>
                  <li> Shoots Ogre </li>
                  <li> NAT 1 shoots Ogre but misses </li>
                </ul>
                <li> Ogre </li>
                <ul>
                  <li> KILLS Lars again </li>
                </ul>
              </ul>
              <li> Round 6 </li>
              <ul>
                <li> LARS </li>
                <ul>
                  <li> Death Save Fail </li>
                </ul>
                <li> SPARKA </li>
                <ul>
                  <li> Firebolt Ogre but not dead yet </li>
                </ul>
                <li> Kobold </li>
                <ul>
                  <li> Charge Corpo...spear misses </li>
                </ul>
                <li> Kobold </li>
                <ul>
                  <li> Charge Corpo...spear hit for 9 damage </li>
                </ul>
                <li> Wildon </li>
                <ul>
                  <li> Cure Wounds Corpo </li>
                </ul>
                <li> CORPO </li>
                <ul>
                  <li> BA Touches the Tattoo  </li>
                  <li> Attacks One Kobold for 15 damage. Attacks Second Kobold and hits so he can disengage </li>
                  <li> Leaves Wildon with two Kobolds and then he jumps out the window </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> Foregoes action to have BOWSER to attack Ogre </li>
                </ul>
                <li> BOWSER </li>
                <ul>
                  <li> Helps Sass </li>
                </ul>
                <li> SASS </li>
                <ul>
                  <li> Shoots Ogre KILLS by shooting through his bicep and knocks his own head Harry Potter style </li>
                </ul>
                <li> Matrim </li>
                <ul>
                  <li> Trying to heal the other cage person </li>
                </ul>
                <li> Wildon </li>
                <ul>
                  <li> Survives AoO Jumps out the window yelling "SAVE ME" as he jumps into CORPO arms </li>
                </ul>
              </ul>
              <li> Round 7 </li>
              <ul>
                <li> LARS </li>
                <ul>
                  <li> Death Save Passes </li>
                </ul>
                <li> SPARKA </li>
                <ul>
                  <li> Runs over and gives LARS a potion </li>
                </ul>
                <li> CORPO </li>
                <ul>
                  <li> Casts Levitate, Wildon moves to hold onto his "unit" as he shoots Abara at disadvantage...and hits her. KILLS Abara with Father's Love by aiming at the...Vagee using minor illusion to make arrow look like a dick. </li>
                  <li> Grabs Wildon back in his arms, kisses him on the forehead. </li>
                  <li> Wildon is like "HOLY SHIT! IS THIS ALWAYS WHAT IT IS LIKE?" </li>
                </ul>
              </ul>
              <li> Cecil searches Hadrian's corpse </li>
              <li> Lars rolls over coughing up blood...hands over the head to Hadrian </li>
              <li> Cecil finds scroll of Fireball, Featherfall, and Staff of Dunamancy. Checks it out </li>
              <li> Sparka (?) searches Thopleth but he has devout stuff of Tiamat </li>
              <li> Sparka takes mask off but is attuned to it. </li>
              <li> Blago takes full control and the castle shifts towards Phandalin </li>
              <li> The existing Ogres and cultists are freaking out </li>
              <li> Main group can't see Matrim sees he is down low </li>
              <li> Blago will get there in roughly 4 hours they have 4.5 hours til the hoard magic is done </li>
              <li> They Arcana check to figure out they need to damage the blue iceberg </li>
              <li> Matrim soars up on his Pegasus </li>
              <ul>
                <li> No longer shredding but IS SHREDDED </li>
                <li> He is looking like a Dime Store romance male lead on a cover </li>
                <li> Thanks Corpo for saving Wildon...who he then mocks.  </li>
                <li> Corpo is like whoa, but Matrim is like on the flight here we like razz each other, it's our relationship now </li>
                <li> Corpo points to tower and says he and Cecil have a special bond....Matrim doesn't believe him </li>
                <li> Matrim flies up to Cecil and heals his wife again. </li>
                <li> Cecil pulls Hadrian's head out of the bag with a cock in its mouth...and is like look what I did daddy! </li>
                <li> Cecil is like his father your brother did it all </li>
                <li> Matrim is like hey lets talk family history after...WTH is happening </li>
                <li> Cecil is like going to Phandalin, Blago trying to summon Yan-C-Bin </li>
                <li> Matrim casts Legend Lore to say hey super heat the iceberg...chance bad shit can happen </li>
              </ul>
              <li> SPARKA IS LIKE I CAN DO FIRE DAMAGE. Casts firebolt. The crystal cracks a bit, falls 100ft, starts erratically going up and down. Hits it again and starts falling more...falling more and more....hard to tell if crash before or after Phadalin </li>
              <li> They are like...what...what are we doing.... </li>
              <li> They decide to wait it out and see what happens </li>
              <li> Matrim takes his wife onto Estran and takes Wildon. Will be back...if see Minor Illusion will soar into check </li>
              <li> Corpo asks if he can get a ride....Matrim is like no only room for 3...flies away.  </li>
              <li> Cecil like...atta boy dad </li>
              <li> Corpo grabs onto legs and Matrim sighs </li>
              <li> Corpo sneaks to attack the Ice Shard...Sparka doesn't notice. </li>
              <li> Corpo is going to walk down. "WHAT ARE YOU DOING" "Idk don't we have to punch this thing?" "YOU WEREN'T HERE" "That's why I am asking...." </li>
              <li> Sparka is just watching Cultists running around toward the dragon's cave </li>
              <li> They take a short rest </li>
              <li> It's 10 O'Clock </li>
              <li> Get Jazelle's body...then goes to the cave </li>
              <li> They hear cultists run away as they enter the hoard </li>
              <li> Cloudchaser's body is gone... </li>
              <li> NAT20 Sass Arcana check knows that the Cultists can bring dead dragon back as a Dracolich </li>
              <li> They melt through the hoard </li>
              <ul>
                <li> Find Cloudchaser's other eye attached to his first arrow </li>
                <li> Finds a chest of Sass' childhood toys...Sass is like burn them, I don't want to think about what he took from many anymore </li>
                <li> SPARKA GOING CRAZY BURNING ICE TO GET SHIT </li>
                <li> See that he killed and dressed another Dragonborn dressed as Jazelle </li>
                <li> 1.5 hour to Phandalin...2 hour to Absorption </li>
                <li> Find Sapphires, potion of gaseous form, moon touched scimitar, +1 celestial dagger, +1 studded leather armor (Dropkick Murphy's Song) </li>
                <li> TREASURE TABLE ROLLS </li>
                <ul>
                  <li> 86 Corpo finds amulet of health </li>
                  <li> 96 Sparka ROLL TWICE </li>
                  <li> 15 Sparka dagger of guitar solos </li>
                  <li> 100 Sparka ROLL THREE TIMES </li>
                  <li> 12 Sparka cloak of billowing </li>
                  <li> 68 Sparka boots of elven kind </li>
                  <li> 90 Sparka ring of spell storing </li>
                </ul>
                <li> Missed the money counts but Robert has it </li>
              </ul>
              <li> Corpo heads back up to the Ice Crystal..hits with quarter staff </li>
              <li> Corpo goes to control room and tries to talk to Blago...asks why get Yan C Bin.... </li>
              <li> Blago is like the giants lost their way...we are the elements we need to remind them by release Yan C Bin </li>
              <li> Corpo asks what next steps....Yan C Bin would tear this to pure elements again...Corpo is baffled not rulers or whatever...says suck my dick and walks away.... </li>
              <li> 45 minutes to impact </li>
              <li> Sparka find 13 Art Objects </li>
              <li> Corpo hits the crystal again...does damage and the castle is starting to fall </li>
              <li> Corpo hits it again and they are falling...negative Gs time </li>
              <li> Corpo hits it again and shatters the crystal....FALLING </li>
              <li> Lars does Feather Fall on everyone else...they don't die </li>
              <li> Corpo takes 34 bludgeoning damage...dex save to barely dodge a slab table </li>
              <li> NAT 1 Lars Dex Save to dodge rocks as they fall 17 pts of damage </li>
              <li> NAT 1 Corpo Dex Save to dodge rocks as they fall 17 pts of damage </li>
              <li> NAT 20 Corpo Death save...back to 1 health HE IS ALL ALONE </li>
              <li> Ogres fall collapsing in front of you </li>
              <li> Cecil's dad is like....pyrotechnics  </li>
              <li> LEVEL UP but not Lars </li>
              <li> Sass Bow LEVEL UP to get 1d6 col damage and breath attack </li>
              <li> Corpo gauntlets level up </li>
              <li> Bowser levels up and Cecil sends him  </li>
              <li> Lars looks at the axe....great deeds are worthless without those to tell the tale of glory. Bards are vital to glory. </li>
              <li> Lars apologizes to Cecil for lack of scrupulosity earlier. Cecil is like damn a big word...not a drink. His axe levels up as well </li>
              <li> Sparka dragon shard levels up...ROLLS to get Ring of Spell Storing </li>
              <li> They walk into Phandalin and are like hey...how's it going that was a crashed castle...staring at the party covered in blood and hurt.  </li>
              <li> Lars shouts "BRING US YOUR WHORES"...Corpo is like "Those who consent?" </li>
              <li> "Are whores are packed" </li>
              <li> Maisie is like hey...can you help it's time for the portal </li>
              <li> Sparka is super turned on by Maisie, offers to help </li>
              <li> The magic surges and they see the portal start to open in the middle of the town </li>
              <li> Sparka can feel if it would open or not in the middle of town...pushes it into the basement of his basement. </li>
              <li> She is like whoa, Sparka is like "I can do more" she blushes and offers to buy him a drink </li>
              <li> He is like, no, I will buy you a drink </li>
              <li> Lars is like hey can someone buy me a drink? She is like uhhh...actually...had a thing...and takes Sparka's arm </li>
              <li> Sass asks if Maisie has a friend..as does Lars </li>
              <li> Sildar is like...WHAT IS HAPPENING Lars slapped his ass to Intimidate to just follow them </li>
              <li> Qeline walks out and is like Lars...that Rugby team was subpar...I called out your name...come with me now. Holds out whiskey and she is like just stay with me...Lars is like let's see what's on the other side of the portal...my grandfather is waiting </li>
              <li> Qeline is like....is he corporeal? Does he have crabs? They ignore her </li>
              <li> The portal is in the ravine and opens on a wall in Thon Daral...Sparka is like hmmm going to be a pain to get carts through... </li>
              <li> PORTAL TAX Sass and Cecil are like hell yes </li>
              <li> Corpo is melancholy, waiting at the bar for the end of the world and the zombie horde. </li>
              <ul>
                <li> Grandmother enters the tavern </li>
                <li> Corpo experiences mixed feelings since she is 90 </li>
                <li> "I was fighting dragons in Neverwinter when I heard you guys crashed a castle"  </li>
                <li> "I crashed a castle" Corpo corrects her </li>
                <li> Corpo is aroused at the lightning...the others are not there but still disgusted since she aged well </li>
                <li> She drops haste...which she requires just to get by </li>
                <li> She is like only one surprise left at my age...hoping to get to know my grandson since I can't get to know her </li>
                <li> Corpo has not told her about how mommy died </li>
                <li> She is like...ok...I don't need this any more....and hands over Staff of Thunder and Lightning </li>
                <li> All 1.5 inches is aroused </li>
                <li> She is like...you haven't asked about Grandpa...Grandpa is in the Air Elemental Plane </li>
                <li> She is like...he is...Yan C Bin....</li>
                <li> Ooooooh  </li>
                <li> Corpo sees that she is wearing the Death Walker's Ward </li>
              </ul>
              <li> The others enter Thon Daral.  </li>
              <ul>
                <li> Grandpa Larsius is like WELCOME standing by the soldiers. </li>
                <li> "MY GRANDSON THE MOST ENDOWED...All from his grandmother's side" </li>
                <li> Xaryax is standing with Zorrakir and the soldiers </li>
                <li> "COME WITH US THON DARAL! DRINKS ON SPARKA THON DARAL" </li>
                <li> People come to talk to them and they are like fuck it we are drinking </li>
                <li> Thistle yells "WE DIDN'T DIE!" They all cheer. </li>
                <li> Zorrakir is like hey our soldiers will watch the Phandalin side against zombies </li>
                <li> Xaryax sees Sass... "BROTHER BORN!" is pumped that he slayed the dragon </li>
                <li> Xaryax sees the sword...asks him to wave it around. The Strength and Honor words light up.... </li>
                <li> He asks Sass to follow, they head to a place together...a bunch of dragonborn have the Bahamut on the Purple that is symbol of the true king </li>
                <li> The words only glow in the hands of the true heir!!! </li>
                <li> They all go "It's him...IT'S HIM!" and the kneel" </li>
                <li> HAIL to the True King! Kinaphalic Greosass, Taker of Eyes, Third of his Name, King of all Dragonborn, Lord of Kinther, Master of the Isthmus, Arbiter of Justice, Soldier of Mercy, Vanquisher of Slavers, The Champion of Bahamut, The Dragonslayer, The Drakewarden, The Unbroken. Long May He Reign, Long May He Slay </li>
                <li> They are like we will fight for your til your dying breath </li>
                <li> "Rise brave dragonborn. Together we will fuck all the Dragons! PRAISE BAHAMUT" </li>
                <li> Xaryax is like let's keep it on the DL...go party...we will party. </li>
              </ul>
              <li> GOOD NIGHT LAKE GENEVA </li>
              <li> It is approaching Noon  </li>
              <li> Corpo hears the bow says "A Father will always be there for his son" Corpo feels nothing</li>
              <li> Revisit Thon Daral </li>
              <li> Walk towards Central Tavern, over four story tall like Globe Theater with random openings with people </li>
              <li> German-vibes </li>
              <li> Sparka is like...I am putting money down? Sass is like let's support local businesses. They realize they probably have their actual money </li>
              <li> Like all of Phandalin just walks into the middle of the town </li>
              <li> Do not notice temples to chaotic deities of Moonweaver, Correlion, or Avandra </li>
              <li> Arixus hands back the teleportation lamp.  </li>
              <ul>
                <li> Tells Cecil and Sparka to stop by The Map Room where cartographer friend Fete Amarillis is hanging out. Wants to meet them </li>
                <li> Gives them the knock Knock DEEP KNOCK </li>
              </ul>
              <li> Zorrakir is like lets meet tomorrow...higher ranking people will want to meet you. I will deploy troops to secure Phandalin. Will need to talk but for now rest </li>
              <li> Old ladies were ready to receive refugees with pots of hot soup. Tents in military camp area </li>
              <li> Zorrakir has a priest use Thaumaturgy to explain what to do </li>
              <li> Blues Brothers soldier start going around to get ready to go to defend Phandalin </li>
              <li> Elmar Brathen gives toast to the party, they notice the crystal again </li>
              <li> Corpo and the Imperial Troops are holding down Phandalin </li>
              <li> Cecil has not seen his parents...Corpo assumes they sexing it up but like his mother did almost die </li>
              <li> Sparka is talking to Maisie...something like level 6. Gold Half-Elf </li>
              <li> Waukeen is pleased at how much they spending </li>
              <li> Sass and Lars go to Gnoodnick's Sushi  </li>
              <ul>
                <li> Go down random alley </li>
                <li> Super old Gnome hanging out in an alley staring straight aghead </li>
                <li> "Brother Gnome do you know where to find Gnoodnick?" </li>
                <li> Reveals portable hole on the wall...want some Sushi? </li>
                <li> Says his specialty Yellow-Indigo Tail Tuna from a couple realms over...Lars asks for it </li>
                <li> Sass asks for dangerous blowfish? Dangerous enough to kill a man but not a Dragonborn </li>
                <li> He has a "Mankiller Blower" Sparka mutters Qeline  </li>
                <li> Looks hungover...asks how much </li>
                <li> He uses Telekinesis to grab the tuna fish...throws fish up and Soulknife hands to perfectly slice it up </li>
                <li> The Mankiller blower looks like a pure demon fish....he dangerously fights it as it spits poison at him </li>
                <li> Has Nuclear Death Wasabi...Lars passes Con saves...Sass it burns but good </li>
                <li> Probably the best meal they've ever had...he did the whole fish so soooo much to eat </li>
                <li> Lars orders bottom shelf ale. Sass asks for not shitty ale </li>
                <li> Sass asks to learn about what he did (Soulknife Rogue)  </li>
                <li> He starts flipping psy knives around...doing the knife hand game </li>
                <li> He is impressed at them asking for cool food </li>
                <li> He asks if Sass wants alcoholic Dragonborn tea...Sass is like I grew up in Slavery  </li>
                <li> Gnoodnick..is like whoa....pulls open another case of fine tea and whiskey.  </li>
                <li> You in Lars? Lars is like I was a Dworphan... </li>
                <li> He is like OK time to learn about your new shit </li>
                <li> THEY CAN GO BACK AND GET RUMORS FROM HIM HE LOVES THEM. He is like come back to me </li>
                <li> They are having a blast </li>
                <li> Sass asks for fletchers? Gnoodnick tells him about Forked Tongue Fletchers </li>
                <li> Lars asks about whores...he says Lady B had just gotten back. Says he never goes there...but there are a couple creaky floorboards </li>
                <li> He tells Lars Kord Temple was on the way....9AM tomorrow is a tournament he says that is pretty lame. Some places throw tables and fight but here it is just board games....if that </li>
                <li> LARS IS LIKE KORD IS DISPLEASED </li>
                <li> Sass is like lemme get arrows then I'll help you honor Kord </li>
              </ul>
              <li> Maisie is telling Sparka about Phineas Stoneblood. Says Arxius invited Phineas to come visit so he should be coming </li>
              <li> She tells that her mother was lady of the night...her mother ran away and worked in a tavern. Her mother got her education. Father is unknown Gold Elf....Sparka is like....Cecil??? I am tooo drunk. In this moment Sparka's type is...Cecil??? Cecil is pretty handsome guy. Matrim is a Fabio man </li>
              <li> Cecil goes to Zicklefocker Luthier </li>
              <ul>
                <li> ZF recognizes a true bard. </li>
                <li> He loves doing special custom job...Cecil asks about turning staff into an instrument </li>
                <li> He is like whoa Staff of Dunamancy....POWERFUL </li>
                <li> Super excited to do this...asks what he would be best to be. Can make a woodwind but would not be perfect sound....a stringed instrument would be perhaps the best sounding one. </li>
                <li> Cecil picks a Lute. Says can make for free if Cecil goes and kicks ass in battle of the bands...says that the greatest Luthier Zicklefocker made it. </li>
                <li> Starts making a new pipe to get him through this </li>
                <li> Says you might need friends....not sure they are musical? Cecil like they can do </li>
                <li> Cecil wanted to check out the Map Room...but wait for Sparka </li>
                <li> Heads back to talk to Maisie </li>
              </ul>
              <li> Sparka is digging super respectful Maisie who is not trash. Cecil chills for a minute...not going to interrupt.  </li>
              <li> She goes to the bar, Cecil says hi...Damn Sparka told me you all are heroes </li>
              <li> Cecil asks what her plans are....she doesn't look at Sparka as she plays with her keys </li>
              <li> She is going to stay here for now, helping with Harpers and Heralds for now...new information with a whole new world </li>
              <li> Sparka excuses himself...Maisie is like uhhh....he says he will be back later he assumes </li>
              <li> Maisie scribbles in span reed not realizing..."Maisie Redlake" and sketches his ass...gets embarrassed when realizes </li>
              <li> Lars and Sass strut to the Fletchers </li>
              <ul>
                <li> Sass flirts about her tongue being long...she thinks creepy...even Lars feels uncomfortable... </li>
                <li> Sass checks out the arrows... </li>
                <li> Lars starts touching arrows...SHE IS LIKE WHOA IT IS POISON ARROW </li>
                <li> Lars buys 20 Javelins </li>
                <li> She is like whoooooa someone wants to buy and not creep? </li>
                <li> Sass buys some arrows </li>
              </ul>
              <li> Cecil ate the Ioun's Stone...merged with it. Opens his mouth and spits out facts </li>
              <li> Sparka is heading to temple of Waukeen </li>
              <li> Corpo is in the tavern </li>
              <ul>
                <li> The Black Storm places money as they eat and drink. </li>
                <li> She is like...something you want to tell me... </li>
                <li> He is like what's your name...she is like they took it from me...once I was called Biatha </li>
                <li> Corpo says she would get along with Sass...whole self-pity thing </li>
                <li> She is like I didn't leave the party of hundred to drink by myself </li>
                <li> Corpo is like your daughter was a wonderful mother....in a tragic training accident I forgot I could only use levitate once per day....she fell and died </li>
                <li> She is like sure that was pretty rough for you...he is like I am with a new band of merry gentlemen </li>
                <li> Corpo is like we try to do good...there is this bard that is always just talking and singing... </li>
                <li> He asks about her cloak...she shows the Raven wings. She is like when I was cast out...I became a Storm Sorcerer from being in the plane...but I was going to die in the ditch...an Angel of the Raven Queen visited me...I am a Warlock of that angel now. </li>
                <li> She says she had nowhere to go...hunted down and killed Lyches and Necromancer. Says she is slayed dragons...someone else got the Dragon killing! He is like I know! </li>
                <li> Corpo asks how to follow in her footsteps in slaying evil...in the Raven Queen ways </li>
                <li> She says go to a temple, forsake your old diety. Death is cold and orderly. The only certainty </li>
                <li> She is like I am sure the Matron of Ravens will be looking for a new champion </li>
                <li> She is like I see in your eyes you blame yourself for your mother's death... </li>
                <li> Corpo is like I use humor as a coping mechanism...she is like I used alcohol to cope for years you probable healthier </li>
                <li> Corpo says you'd get along great with my good friend Lars...she agrees </li>
                <li> She is like I am a 92 year old woman I don't drink as much...I am a little older </li>
                <li> She says don't blame yourself. She went out each night to fight pirates...there was a problem. No more pirates? No...there are always more pirates </li>
                <li> No..eventually you have to sleep...pirates murdered people...it is their fault not mine. Death is a certainty...I didn't worship death but I served her </li>
                <li> Corpo is like I can serve death but worship another one </li>
                <li> She says go find a temple of the raven queen and pray </li>
                <li> Lastly she is like she didn't want to go back...says she was there when the got carved on the ceiling </li>
              </ul>
            </ul>
          </Collapsible>

          <Collapsible
            title="The Douche Canoe  (#23 - 3/17)"
            toggle={this.collapseToggle.bind(this)}
            kind="session23"
            show={this.state.show}
          >
            <ul>
              <li>Sass goes to Nature's Blessing and Curse</li>
              <li>Corpo goes to get gold from Sparka</li>
              <li>Corpo goes to Ecstasy and Fire </li>
              <li>Sparka is at A Magic Item Shop</li>
              <li>Cecil bought something from A Magic Item Shop</li>
              <li>Locals have joined in the party</li>
              <li>Lars enters A Magic Item Shop</li>
              <ul>
                <li>"My fine feline friend" he starts saying he wants the butcher's bib and the amulet of the drunkard</li>
                <li>Lars offers 3K for both...he is like eh</li>
                <li>Mist is like...uhhhhh</li>
                <li>Lars is like we are going to spend a ton of money here..."you aren't going home empty paw-ed"</li>
                <li>NAT 20 Sparka Persuasion check...says like I am going to help you so much....you can do 3K</li>
                <li>Lars gets the amulet of drunkard and the butcher's bib</li>
                <li>Mist tries a sign saying Phanadalin pay 5% more</li>
                <li>Sparka explains lost leaders to Mist...lose money on the douce canoe...but then buy potions and mark them up</li>
                <li>Mist: "SELL THE DOUCHE CANOE FOR 1CP?"</li>
                <li>Sparka is like no no sell, tell his friend Corpo that it is worth 7K gold and if you cut it up it becomes staves of Thunder and Lightning</li>
                <li>Mist is taking notes on paper with ink on claws</li>
                <li>They have 138K gold....each got 10K each but they are holding to a lot to save up for more thing</li>
              </ul>
              <li>Corpo enters</li>
              <li>Mist walks up and sells the Canoe and lies to Corpo about the canoe</li>
              <li>Corpo Insights him..."Staff of Slarm and Lightning"</li>
              <li>Corpo like hold on Mist.... </li>
              <li>Corpo like SPARKA 1st Gimme the money</li>
              <li>Corpo like the Black Storm is my grandmother and she was here when the ceiling was carved with your name</li>
              <li>Sparka is like wait...what...what....huh?</li>
              <li>Sparka is like is she here can I talk to her?</li>
              <li>Corpo is like I may have hit on her...</li>
              <li>Sparka is like YOUR GRANDMA?</li>
              <li>Lars is like are you surprised?</li>
              <li>Corpo is like she was a sex worker</li>
              <li>Corpo says the sunbeam into the ceiling she can't remember but is like the reality warping crystals</li>
              <li>Corpo did not figure out how to contact her...though she coming back to watch for Zombie Army</li>
              <li>Sparka is like I would like to meet with her....NOT THAT CAN STOP THE EYEBROWS</li>
              <li>Corpo is like...MEAT and greet</li>
              <li>But cool I can direct us to her</li>
              <li>Corpo mentions he is going to lose Moonbeam...Sparka is like WTF</li>
              <li>Oh yeah Sparka shudders and is like you going to the Raven Queen</li>
              <li>Corpo is like I am going to change religious allegiances...</li>
              <li>Sparka is like for a suit of armor?</li>
              <li>Corpo is like I can't wear the armor</li>
              <li>Sparka is LIKE WHY THEN</li>
              <li>Mist is taking notes and Lars is just having cascading blood</li>
              <li>Corpo is like I will get a different power...</li>
              <li>Sparka is like...wh....why.....?</li>
              <li>Sparka hands over the money</li>
              <li>Corpo says: "Hey there Pussy Cat"</li>
              <li>Mist is like: "Is that how you talk to customers? Dead parents and relgion?</li>
              <li>Sparka is like he is a friend..we talked about this...</li>
              <li>Mist is like Shouldn't you be friends with your customers?</li>
              <li>Sparka is like you just met he is an intimate friend of mine...</li>
              <li>Mist is like you guys are intimate??</li>
              <li>Sparka is like no...but he would like that</li>
              <li>Sparka says asks how he can help</li>
              <li>Corpo is like...the canoe...how much</li>
              <li>Mist is like it is 50 gold but you look...good..so I will give it to you for 45......platinum</li>
              <li>Corpo thinks...</li>
              <li>Cecil is like...45 Platinum? That is a really good deal</li>
              <li>Sass is like why...CAUSE IT'S A CANOE</li>
              <li>Mist throws in a knife</li>
              <li>Corpo asks Sparka if it fit in the bag of holding...he says no....Cecil has room Cecil like ITS FULL</li>
              <li>Mist like oh well that guy (random guy outside) will buy the canoe</li>
              <li>Corpo realizes he could carry it around himself</li>
              <li>CORPO TAKES THE DEAL</li>
              <li>Robert is like ok start an excel sheet...Ben uses a calculator to do 10000-450</li>
              <li>Mist is like you a good customer with a keen eye for wood...explains</li>
              <li>He puts the canoe on Corpo and it says "DOUCHE" Corpo's eyes light up</li>
              <li>Corpo is like I want the thing that made the noise...He doesn't realize it was him</li>
              <li>Corpo is like So everytime I get in the canoe it will say "DOUCHE"</li>
              <li>Mist is like....unless you change your ways....</li>
              <li>Sparka takes Mist...he thinks he is slaying it....Sparka is like you cheated a customer...sure was hilarious</li>
              <li>Sparka is like walk through what would happen...he is like ooooh he would tell people he got it from A Magic Item Shop....(Terrible name)</li>
              <li>Sparka is like yes always do right for your customers....treat them better...marketing, advertising not manipulating</li>
              <li>HE IS HELPING A SMALL BUSINESS OWNER</li>
              <li>Get low as possible price for customer</li>
              <li>Sparka is like lets do a loss leader....MIST IS LIKE Let's do potions....drop em by 5 gold</li>
              <li>Sparka is like know your prices</li>
              <li>He asks if he should make another Douche Canoe...Sparka like not many rivers here I am surprised...well not surprised you sold to him...</li>
              <li>Match inventory to your customer base...get rock climbing pamphlets next to rock</li>
              <li>Sparka feels warmth in heart as the gold lady is pleased</li>
              <li>PRAISE BAHAMUT may his wings be unfurled</li>
              <li>PRAISE BAHMUT may they spread so wide...be wide and ready</li>
              <li>Lars has purchased things</li>
              <li>Sass is not purchasing things there</li>
              <li>Sass goes to nature's Blessing and Curse</li>
              <ul>
                <li>"May I help you my fellow chloaca-ed person"</li>
                <li>Sass says she is dripping...she is like...what?</li>
                <li>Sass says he is looking for healing potions</li>
                <li>She licks her eyes</li>
                <li>The red ones are based price, blue, green,  purple all cost more</li>
                <li>Sass asks why</li>
                <li>She says red is her least favorite color</li>
                <li>She advertises her Lover's Bane potions</li>
                <li>Lars asks if she has heard of the tournament...she has not. Says there is a massive prize</li>
                <li>Sass thinks she's just weird that she think different color potions different prices...no real issue</li>
                <li>Lars is like please tell all the toughs that visit about the tournament</li>
                <li>Sass checks the greater potions of healing...he tries to haggle</li>
                <li>Sass is like I get in a lot of fights...I'll be a repeat customer...she isn't swayed but gives discount if he buys 3</li>
              </ul>
              <li>Arxius gave the lamp back to Cecil we established</li>
              <ul>
                <li>NAT 20 Arcana Cecil check to know about the lamp</li>
                <li>He knows that the one lamp must be in the manor and the other can go all around Primavera at least as it is tied to the rift in Phandalin</li>
              </ul>
              <li>Corpo goes back to Ectasy and Fire</li>
              <ul>
                <li>Now that he has the moola</li>
                <li>Kicks in the door and says "PUDIIIIII I got the money"</li>
                <li>Pudi is like Mother of Pearl...you have money? You Monk who fucks and you have money?</li>
                <li>Pudi: What do you want to do with money? </li>
                <li>Corpo:  What do you suggest? </li>
                <li>I mean, just give me all the money. Spit on my floor and leave. </li>
                <li>Corpo: That's not happen. </li>
                <li>Corpo: Pudi we can have a better relationship than this</li>
                <li>Pudi: Because but you would have to get naked. </li>
                <li>Corpo is not leaving staff and storm lightning anywhere near Pudi </li>
                <li>Corpo: Let's start with ice cream.</li>
                <li>Pudi: Two copper</li>
                <li>Corpo:  Oh with the cherry on top</li>
                <li>Pudi: ....you mean ice cream?</li>
                <li>Other monks walks into the Massage Parlor</li>
                <li>Corpo: I see  I'm not the only monk who fucks</li>
                <li>One monk comes out of the massage parlor and goes into the ice cream parlor </li>
                <li>Pudi: DO not talk to the children!!</li>
                <li> They're going wrong door sometime very very bad</li>
                <li> The incense is thick</li>
                <li> You know, they wrote song about me where I was from they said it was a shame what...I did </li>
                <li> I am but here, They not here</li>
                <li>Corpo: PUDI! I'd like to buy a Seeker Dart</li>
                <li>Pudi: Like his canoe</li>
                <li>Corpo decides he wants a massage</li>
                <li>Pudi:  How much do you want to spend on a massage, if you catch my drift?</li>
                <li>Corpo: How much does a monk typically spend on a massage here?</li>
                <li>Pudi: A monk who fucks? More</li>
                <li>Corpo: Give me a second</li>
                <li>Corpo walks into the ice cream shop to sit next to the monk that just came out of the massage parlor</li>
                <li>He is sitting between the monk and a 12 year old girl with a bow in her hair...Sally</li>
                <li>Corpo slaps other monk on back</li>
                <li>Corpo: BROTHER!  </li>
                <li>Other Monk: Yes</li>
                <li>Corpo: I see you share probably similar interests of ice cream and massages.</li>
                <li>Other Monk: Yes</li>
                <li>Corpo:  How much would you normally pay for what you just enjoyed?</li>
                <li>Other Monk:  It depends on day.</li>
                <li>Corpo: Average</li>
                <li>Other Monk:  30 Gold, and that got me two massages</li>
                <li>Corpo: What's your name, my fellow monk?</li>
                <li>Other Monk: Why do you want my name? Would you try to put curse on me?</li>
                <li>Corpo: No,  I don't deal in curses.  I deal in blessings.</li>
                <li>Starflower: My name is Staflower.  I was given that by the guru of the mountain.  He told me to come here and massage, so  I'm definitely keeping that name.  He's pretty cool.</li>
                <li>Corpo: That is amazing. Well Starflower it has been a pleasure</li>
                <li>Starflower: Thank you</li>
                <li>Corpo:  Enjoy your ice cream and according to Pudi, stay away from kids.</li>
                <li>Corpo walks back to Pudi, says.... let's go with 30 gold</li>
                <li>Pudi: Oh 30 gold</li>
                <li>Corpo: Two massages!</li>
                <li>Pudi: Me or one of the girls?</li>
                <li>Corpo:  One of the WOMEN</li>
                <li>Corpo enters as steam rushes out</li>
                <li>Corpo takes the canoe in with him</li>
              </ul>
              <li>Cecil and Sparka go to the Map Room</li>
              <ul>
                <li>Secret knock, Arxius meets them and leads them in</li>
                <li> It's like how a speakeasy looks kind of like</li>
                <li> And in the room it is legitimately filled with cartography</li>
                <li> There are maps some you recognize of that you've seen like from Primavera in this area, but there are just maps everywhere</li>
                <li>Sees Moon Elf sitting there with like a fine glass looking sort of like a 1800s for a kind of that vibe</li>
                <li>See a Half-Orc, but realizes some features are Elven so an Uniya</li>
                <li>They are drinking port and wine</li>
                <li>The moon elf is Amarillis and is quite bombastic. Says he wants to visit this NEW WORLD</li>
                <li>The Uniya does prestidigitation applause.</li>
                <li>He has been to 13 worlds but not that one yet</li>
                <li>Sparka: "We've actually come across your work before."</li>
                <li>Fete: "Oh my goodness. So you're a fan? Yes, I do autograph things." Pulls out a quill</li>
                <li>Sparka: "No, not looking for an autograph."</li>
                <li>They are like oh yeah Mushroom Druid gave him a map</li>
                <li>Sparka pulls out the map. He is embarrassed it is such an early work.</li>
                <li>Sparka is like oh do you have an updated version? Fete gives them the world map that is enchanted to pop up when they touch it</li>
                <li>Fete: "I could not have you walking around with inferior cartography."</li>
                <li>Sparka looks at Fete, got the name, tries to see if he is related...and....nothing...not at all</li>
                <li>Sparka is relieved he is not related to this dude high on his own supply...though knows that the name is on his dragonshard</li>
                <li>Fete notes the dragonshard, asks where he got it from.</li>
                <li>Sparka is like I didn't grow up with parents or siblings...foster parents in Port Llast</li>
                <li>Fete yells to no one to make a note of it.</li>
                <li>Sparka is like...that's very odd...Fete is like very odd. Only Imperials...well my family had one but that's a long story...</li>
                <li>Sparka is like tell us? The two persuade him let's share a drink.</li>
                <li>Tells the tale....Back in the day my friend they were had my family was given one as alliance. </li>
                <li>Describes how his family ruled a vast forest on the west side in Chromatic Dragon lands now. Lothlorien essentially. They were driven out</li>
                <li>A Dragonshard was given to them from the Imperials as part of an alliance...many Emerald Dragon Sorcerers were in the clan</li>
                <li>Sparka: You didn't want to become more terrible than the Dawn.</li>
                <li>Fete: We did, actually.</li>
                <li>Sparka: Oh, okay.</li>
                <li>Fete: Because the bad dragons were attacking us, and we wanted to fuck them up.</li>
                <li>Sparka: All would fear you in despair?</li>
                <li>Fete: Yes. I don't know, that's really good lines.</li>
                <li>Sparka: In place of a dark lord, you would have a queen!</li>
                <li>Fete: Did you meet an evil lord on a raft, and you said this, and put it in your mind, and you said it again thousands of years later? Is that what happened?</li>
                <li>Sparka: Basically</li>
                <li>Eventually the line of Sorcerers stopped and it became a family heirloom...</li>
                <li>Fete: "One of my cousins, you know had a fight with dad and she took it on the way out the door"</li>
                <li>Sparka: "What's her name?" </li>
                <li>Fete: "Amali"</li>
                <li>Sparka: "Surname?"</li>
                <li>Fete: "Amaraillis I believe...We Elves can be chaotic but we keep track of important families</li>
                <li>Sparka: "Where did she go?"</li>
                <li>Fete: "Not sure, haven't heard, she had a fight with her mother or father, never heard which"</li>
                <li>Sparka: "What are their names?"</li>
                <li>Fete: "Her father is Niat Amaraillis the current Exilarch of the Silver..."</li>
                <li>Sparka: "And no one ever chased her? It was an artifact of power..."</li>
                <li>Fete: "When you are an elf and you life for so long...they eventually come back. Something missing for 10 years is not that big of a deal"</li>
                <li>Cecil asks how long ago it was...Fete is like 40 some years ago..38 years ago when my father died</li>
                <li>She came back for the funeral, brought her human friend and they they were there and it was a whole to-do and fought with her siblings who hated her. She took it and ran away...maybe her friend was a sorcerer</li>
                <li>Fete says no one has even seen them...Sparka wonders if there is a connection...Fete is like yep I can take you.</li>
                <li>Sparka is like her family is still around? Yep her father is still in charge. Multiple siblings, her mother...her mother is a severe woman I don't get along with</li>
                <li>Sparka asks if Cecil would go, He is like sure</li>
                <li>Cecil is like...does the family travel here any time?</li>
                <li>Fete is like I can ask my uncle to visit if he can?</li>
              </ul>
              <li>Lars has grandfather start ghost like swazee through walls to tell everyone about the party</li>
              <li>Corpo tells Pudi it is great...slings canoe on shoulder DOUCHE</li>
              <li>At 5pm the bow says "Father's care for their sons"</li>
              <li>Corpo does a fist bump with Pudi...did you wash that hand? NO HAHAHAHA</li>
              <li>Sparka checks in on Mist, is a proud Waukeen dad...buys sending stones</li>
              <li>Everyone meets back at the Tavern</li>
              <ul>
                <li>Elmar Brathen is there with his crystal</li>
                <li>Maisie spots Sparka and is like sorta awkwardly trying to play it cool</li>
                <li>Lars is pushing drinks on people</li>
                <li>Sass goes to sit alone and smoke a pipe, Cecil sits next to him</li>
                <li>Corpo is like THROW ME A DRINK! TEAM YOLO We need to go to Ectasy and Fire...it's run by a cat. I smell of ice cream and massage oils</li>
                <li>Lars and Corpo try upping the crowd for the tournament...not...not great but not terrible</li>
                <li>Elmar Brathen comes up to Corpo (REMINDER ON THE REALITY CRYSTAL) tells Corpo...YOU. "Are you going to seduce the women of Phandalin" gives him 5 gold says buy a whore then leaves. </li>
                <li>NAT 20 Corpo Insight to figure out he is trying to keep Corpo away from Thistle</li>
                <li>Lars notices this and goes to hit on Thistle. "How is it going Young Thistle, I am a middle aged Dwarf" "Better now" she looks down at him. Corpo does an eye glance at Elmar then to Lars. Elmar storms out cursing while he passes Sparka</li>
                <li>Lars buys a drink for Thistle...they are vibing and Corpo notices. "Let's take it downtown Thistle" A big step up fro Qaleen...multiple feet actually.</li>
                <li>Sparka talks to Maisie, asks what she is going to do next. She is going to work for Heralds here for a bit and a lot things are going to change...hoping to be around. She really just wants to learn. See what Professor Stoneblood says when he comes in a few days</li>
                <li>NAT 1 Sparka Insight to try to identify if Maisie is related to anyone</li>
                <li>Recap her family</li>
                <li>Sparka is like not sure what will be doing next...we will be meeting with leaders here and what we can do to keep Phandalin protected...the army of undead mushroom people....he has no idea what the future will hold....</li>
                <li>...Lars whispers so live tonight...</li>
                <li>Sparka is like hey I got you something...you got a notebook but here is a sending stone so just the two of us could talk.</li>
                <li>Sparka purposely grazes her hand and they have more drinks</li>
                <li>Corpo is just putting canoe on and off constantly so "DUCHE"</li>
                <li>Cecil asks Dad about Moon Elves in Primavera. He has never been here but they didn't send people to Evermeet. He says he hasn't seen them at racist elf meetings</li>
                <li>Lars is making a move</li>
                <li>Sparka is being a gentleman and playing hard to get while Maisie is also playing hard to get back and forth....TRYING TO BE ANYTHING BUT CORPO AND LARS</li>
                <li>Lars seals the deal with a 19 year old and they go to her penthouse</li>
                <li>Lars is...mildly successful...slept well at least</li>
              </ul>
              <li>GOOD NIGHT</li>
              <li>Wake at 8AM with a Thunderclap (IT'S TOURNEY DAY!)</li>
              <li>Tourney at 9AM, they are all going</li>
              <li>At Temple</li>
              <ul>
                <li>The old lady that Sass gave gold to for Canasta is there</li>
                <li>She threw down the gold and yelled "Canasta bitches" and won a ton</li>
                <li>Lars is like YESSSS!</li>
                <li>She says I know worship Kord</li>
                <li>Lars: "May his thighs be huge!"</li>
                <li>Old Lady: "As a connoisseur of thighs his are very nice" gets back to the floor</li>
                <li>Sass: "May his veins be pulsating"</li>
                <li>Only one person in line...Wildon</li>
                <li>He is exhausted, didn't sleep, guarded their new house for them. He kicked the Imperial Guard out...said I'm here ILL MAKE YOU LEAVE</li>
                <li>Wildon tells Corpo he got no spells. Prays to Moonweaver like usual in the name of her Champion Corpo Fugit...but nothing...no answer</li>
                <li>Sparka looks knowingly at Corpo...</li>
                <li>Corpo's face turns black</li>
                <li>He pulls Wildon aside and whispers in his ear that alignment with death might be more suitable going forward</li>
                <li>Wildon: "Do I need to worship death now too?"</li>
                <li>Corpo: "Yes you do" without missing a beat</li>
                <li>Wildon: "I will now worship death"</li>
                <li>The cleric of Kord enters (face cast Paul Giamati, not muscular)</li>
                <li>He asks who put the money down angrily</li>
                <li>Lars is like me the aspiring champion of Kord</li>
                <li>He is like I AM THE CLERIC OF KORD</li>
                <li>Lars is like I put the money down it is for the champion...wrestle me if you dare</li>
                <li>He agrees, and throws off his over robe dramatically...but...is just like not very muscular</li>
                <li>They see Storm Orcs enter...not wild Orc but Viking Kord Orcs</li>
                <li>They are like..."A true worshipper of Kord has entered we hear"</li>
                <li>Lars: "It is I...do you worship Kord"</li>
                <li>They look at their Krod symbols...yes....</li>
                <li>They say they have shunned this temple...due to elder Paul...they Predator Handshake and say they are here</li>
                <li>Lars is like we need a gong...single elimination</li>
                <li>Two Orcs and a Half-Orc...one flips a table while screaming "KOOOOORD!!!" "KOOOORRRRDDDD!"</li>
                <li>Sass is like I will back off...then goes for it when Lars is like nope no killing</li>
                <li>Lars starts with wrestling elder Paul</li>
                <li>Another bard comes with a Harp and is really bad singing hot cross buns....Cecil is like fuck that I am taking over</li>
                <li>Corpo, Sass, and Lars enter</li>
                <li>Lars Bearhugs Paul and then tea bags Elder Paul</li>
                <li>Corpo is doing flips and shit and is winning </li>
                <li>Sass is pinned then goes into consolation bracket. The female Orc has challenged him. He gets an 18...but she gets a NAT 20 and yells "FOR KORD!" He tries to pin her one last time and she jumps off a table.</li>
                <li>Sass is shamed but Lars is like you honored Kord...she tells Sass to get the sand out of his Cloaca</li>
                <li>Cecil goes crazy bongo music</li>
                <li>Lars and Corpo defeat next opponents</li>
                <li>Corpo takes on the Orcish princess</li>
                <li>NAT 20 Corpo vs NAT 20 but he wins</li>
                <li>Lars wins!</li>
                <li>Corpo is super turned on...but pins her with his thighs</li>
                <li>Princess Orc is like you are worthy princess of your people....thinks he is a girl</li>
                <li>Lars wrestles Wildon...gentle puts him out</li>
                <li>Corpo defeats another opponent</li>
                <li>Corpo vs Lars for final...Cecil stops the music</li>
                <li>As Lars wins...."...I didn't get to wrestle..." as Bugbear walks in with Dorissa Inquisitor</li>
                <li>"Championship...competition...not weapon"</li>
                <li>The Inquisitor with him walks in and is like...can we talk while your friends are done with their homo-erotic dance...oiled up Corpo is massaging Oiled up Lars...Sparka says lets wait</li>
                <li>Braggomkk throws Lars but is like no no try again</li>
                <li>NAT 1 Lars Athletics to wrestle</li>
                <li>NAT 20 Braggomkk to throws Lars through a table</li>
                <li>"Good! KORD BE PRAISED" he takes the pot, hands it back, and adds 500 more gold "Competition is its own reward"</li>
                <li>Corpo is grooving on him and is slightly aroused...1 inch....Genasi survival tactic since aroused so often</li>
                <li>Lars "My Kords glutes be strided" </li>
                <li>He grabs and ale and says...I like you Lars</li>
              </ul>
              <li>Sub Inquisitor Dorrissa Zaloth sits by them to chat</li>
              <ul>
                <li>A teenage page comes to tell her that she has meetings about Core Pox, Demon Incursions, and another one</li>
                <li>She is like with all these meetings when am I supposed to get any work done?</li>
                <li>She has 7 cases on her desk right now!</li>
                <li>Tells them that the Empire and generals are trying to sort out what to do.</li>
                <li>The current Legate and Governor of Thon Daral told her to give them ideas to impress the soon to be arriving generals and Imperial High Prince... 5-6 days</li>
                <li>Qeline comes to arrive...Election is 5 days away...says you will vote for me and just leaves</li>
                <li>So she has tasks for unofficial representation and show the higher ups that they will have merit and Faerûn is cool</li>
                <li>She is like OH I GET ANOTHER CASE now Severin Solonna...I haven't read it who is related? They point to Cecil she like oh ok cool that will help me find</li>
                <li>Complete some tasks and help her focus on the case and the Cult of the Dragon</li>
                <li>The Imperial Economic Council will be arriving...she is like I have heard of Halia and Sparka are economic..she offers Sparka who should negotiate trade deals and he says that both should</li>
                <li>Sparka is like we should move up the Election to sooner. Need to have a debate. 3-4 days to have it done before the meeting</li>
                <li>Corpo gives a wink and is creepy, she is like...gotcha</li>
                <li>Cecil notices that she is using Disguise Self for just like touch up make up</li>
                <li>Cecil: "What is going on with your face"</li>
                <li>She is like...it's a face</li>
                <li>Cecil: "Your face doesn't seem to be natural..."</li>
                <li>Sparka: "....my exceptionally Charismatic friend here is trying to say you just look beautiful..."</li>
                <li>She explains that she is using magic since not all are born beautiful</li>
                <li>Corpo is like in the words of this world's lady KAKA You were born this way baby embrace it</li>
                <li>She is like...I'll consider it</li>
                <li>They pull out their map</li>
                <li>TASK 1 on the Northern Border, Oxkad River Valley. Dispute between Imperial and Hobgoblin controlled villages are clashing...doesn't want to send official Imperial envoy as it might draw too much attention or be rejected</li>
                <li>TASK 2 Southern Borders...in Rengroth the Barbarian Hub...a pretender to a defeated kingdom and he needs to die (IN says Corpo)</li>
                <li>TASK 3 on shores on Inland Sea by Umshara...missing people and afraid official presence might scare them away</li>
                <li>So if they can complete tasks it would be helpful</li>
                <li>Cecil is like...the Rengroth pretend...what concern of ours....</li>
                <li>She is like politics...oh but we can pay</li>
                <li>SPARKA IS LIKE FOR PAY! SIGN US UP FOR ALL THREE</li>
                <li>Sparka: Should we be back for the election? </li>
                <li>Corpo: We are going to rig it anyway....</li>
                <li>Sparka: WE ARE NOT GOING TO RIG IT</li>
                <li>Cecil asks about the Oxkadi...blood feuds and kidnapping. If they go to war with Hobgoblins we will not have resources to hunt his Uncle</li>
                <li>Umshara she mentions it might have been Foxfire and their monopoly....SPARKA SQUINTS AT THE MENTION OF MONOPOLY. NOT GOOD FOR BUSINESS</li>
                <li>Southern job is a straightforward assassination</li>
                <li>Cecil asks which would curry the most favor...Sparka like which has the best Curry...she is like best Curry would be Oxkadis</li>
                <li>She says most favor is up to resolution. If Oxkadi or Umshara are a big deal...then BIG favor. But if no big deal then eh whatever. Assassination is middle of the road no matter what, no secrets</li>
                <li>Rengroth trip can teleport to Storm Orcs</li>
                <li>Sparka is like hey payment....</li>
                <li>"Short dicks don't need money, they just need sex"</li>
                <li>Sparka is like ok we want 750GP per person....3250 even so 750 per person</li>
                <li>She is like...uhhhhh ok....3250 is 650 per person</li>
                <li>He says first timer discount fine</li>
                <li>They are talking about assassination...Cecil is like how about we talk amongst ourselves</li>
                <li>Corpo calls her Sloth ....he asks if she is a she/he situation...what to call her</li>
                <li>She is like you can call me "My Lady/Sir/Sub Inquisitor" He's like oh Sir so you one of those...</li>
                <li>Dorissa: I AM ONE OF THOSE OUT OF YOUR LEAGUE</li>
                <li>Lars: ...never had one of them before</li>
                <li>Dorissa: "I am not one of your hands leave me alone"</li>
                <li>Corpo: Clearly she hasn't heard...</li>
                <li>Dorissa: CLEARLY I HAVE holds up the poster, then yells "Inch and a half"</li>
                <li>Cecil:  Assassination seems most straightforward...but is straight up murder</li>
                <li>Sparka: ....true....</li>
                <li>They discuss maybe there could be a different path...</li>
                <li>But Cecil is like tracking down lost people sees dangerous in world they have nothing about</li>
                <li>Cecil: "I am not necessarily against assassinating this guy...we should just know more first"</li>
                <li>Sass like...wait...who is asking us to kill people?</li>
                <li>They like cool lets go to the Hobgoblins negotiation but let's ask questions</li>
                <li>Corpo calls her Slothicus loudly</li>
                <li>Bargomkk Chertunkk leans over, Corpo tries to complement him and asks for his name</li>
                <li>Corpo is like that name is...no...new nick name is Big Dick Energy...BDE</li>
                <li>Bargomkk is doing the bodyguard glare at Corpo</li>
                <li>Dorrissa compliments the ale</li>
                <li>Cecil so you want this guy dead...Dorrissa is like oh THE EMPIRE wants him dead....says last mercenaries didn't ask questions she is out of practice...Ceicl is like why though?</li>
                <li>She describes Ovgroth as where his lineage ruled, pirates, scoundrels...Sass asked about Ass pirates she was like...probably yes</li>
                <li>Anyway his family loosely ruled, his father united pirates, barbarians attacked the Empire thinking they'd be too busy with Chromatics to fight back...but....they neutralized that threat and totally handled them</li>
                <li>Corpo being annoyed is good.</li>
                <li>Cecil is like what is Imperial interest there? </li>
                <li>Dorrissa explains it prevents the pirate haven from reforming and is a solid port to the South to help control the sea</li>
                <li>Sass sorta hits on her...she is like maybe later...always curious if its purple everywhere...does the marble match the....IT IS ALWAYS HARD...IT IS ALWAYS HARD...AMETHYST PENIS BABY</li>
                <li>They are like could we do something besides kill? They ask about embarrassing him...she says maybe...that could work</li>
                <li>Sparka thinks...Clever Girl Cecil</li>
                <li>Corpo is like...could we sodomize them in front of their friends? I AM THE REAR ADMIRAL NOT YOU</li>
                <li>BDE leans over and is like no...I am the real admiral</li>
                <li>Cecil messages Sparka with this distraction...are we getting embroiled in politics in a different world. The assassination seems more involved than up North</li>
                <li>Corpo says..BDE...he is like...no...GDE. Then he leans forward and is like...Oh nevermind you disgust me. </li>
                <li>Corpo says that was fast....he was like...oh not as fast as you I hear</li>
                <li>Corpo doesn't say anything about how many Bugbears you have killed...Lars is thinking DID YOU SEE HIM WRESTLE</li>
                <li>Dorrissa is like whoa interesting exchange</li>
                <li>The party decides to go north</li>
                <li>She says up North new warlord...Avlod</li>
                <li>She says hey the mayor of your town is now going to negotiate trade deals for your entire planet</li>
                <li>Dorrissa every time she speaks is glaring at them about any innuendo </li>
                <li>She says meet in two hours, your friend Arixus will be doing the teleportation circles</li>
                <li>As leaving GDE points at head at Sparka...points at his own head</li>
                <li>Sparka goes Detect Thoughts into surface thoughts...gets "Why is your name REDLAKE...careful with that name you better not be messing with my friend"</li>
                <li>Cecil wants to chat with Arixus about Dorrissa</li>
              </ul>
            </ul>
          </Collapsible>

          <h2>
            <br/>
            Interlude 1: Shopping/Jobs/Diplomacy
            <br/><br/>
          </h2>

          <Collapsible
            title="The Missing Flask, Underwear, and Girls (#24 - 5/5)"
            toggle={this.collapseToggle.bind(this)}
            kind="session24"
            show={this.state.show}
          >
            <ul>
              <li>Lengthy Recap</li>
              <li>People incorrectly said Pedro is no longer the Mando</li>
              <li>Stensland kids are stuck outside</li>
              <li>They are in the Grand Meadhall and Gymnasium of Kord</li>
              <li>They have 2 hours</li>
              <li>Arixius is the Military Base Prepping</li>
              <li>Everyone up from Corpo's Staff Thunder Clap</li>
              <li>ZF finds Cecil with the Lute</li>
              <ul>
                <li>He doesn't sleep..has to finish it</li>
                <li>Hands it over, gorgeous</li>
                <li>4 gemstones set at the bottom, Amethyst, Emerald, Crystal, Topaz</li>
                <li>The Time Crystal is in the neck...it glows like a disco ball</li>
                <li>Shows that the gemstones are like recording backing tracks</li>
                <li>It tracks his feet like a whammy bar</li>
                <li>There is another gem behind the neck that changes the voice</li>
                <li>There is a ZF on it for branding</li>
                <li>He...says...hey I have never seen anything like it. This old gnome knows not to cast identify on things that people pay to work on...then he doesn't have to lie. Says some people might steal it from him...</li>
                <li>He said has not named it...says must go to sleep</li>
                <li>The Zicklefockle 8-Song Lute</li>
                <li>He faints at the name...Lars catches him</li>
              </ul>
              <li>Sass is embarrassed that he got his ass kicked by Orcish Princess and is licking wounds and praying to Bahamut</li>
              <li>Sparka recalls he gave Maisie sending stones</li>
              <li>Cecil casts ID on the crystal. NEED TO GIVE HIM MORE INFO</li>
              <li>Lars goes to look for Thistle</li>
              <ul>
                <li>...isn't sure where she is...BUT HIS FLASK IS GONE. Goes to look for it on night stand in her room</li>
                <li>In the bar...same guy behind counter, hasn't slept but is functional</li>
                <li>Lars says he needs a key to Thistle's room...dude is like sure I give out keys to 19 year old's rooms to rando middle aged Dwarves</li>
                <li>"You know how many rounds of beer I bought here? I am trustworthy" Barkeep points out it was his friend not him...</li>
                <li>Lars says he can escort him...he is like ok. Barkeep knocks...opens...no one...but tidied up</li>
                <li>Lars doesn't see his flask. Can tell Thistle is out to the market, not left town.</li>
                <li>Lars is like...well if I leave without finding anything he will think it odd...so takes three pairs of ladies underwear...deception fails...Lars gives him a copper and gold and says "I was never hear" Lars adds 3 gold to his inventory</li>
              </ul>
              <li>Sparka goes to Morning Mist</li>
              <ul>
                <li>10ish, he is setting up</li>
                <li>Says he sold some good items...loss leaders helped</li>
                <li>Ton of climbing gear</li>
                <li>Sold compass that points to one true love...didn't point to guy's wife. Morning asks if he should have given refund for ruining marriage...Sparka is like NO he ruined his marriage</li>
                <li>Got a guy coming with a catalog...he is going to make...asks Sparka if he should make more little stuff or big bang stuff</li>
                <li>Sparka is like leave big bangs closer to register...give impulse buys by the counter...like a whole display of canoes.</li>
                <li>Sparka is like candy? Morning is like...magic candy?</li>
                <li>Morning is like can you help with a new name? </li>
                <li>Morning: "I am not that creative"</li>
                <li>Sparka: "Oh I know..leave it to me"</li>
                <li>Sparka asks about other Tabaxi in town...and says who else can we convince to go with the glory of Waukeen?</li>
                <li>There is a small business chamber of commerce meeting in 7 days! SPARKA IS LIKE WHOA</li>
                <li>Sparka is like competition is good but money flowing is best</li>
                <li>He is like oh yeah Magic Depot and Looowes are like next to each other? Sparka is like don't forget Men-hards</li>
              </ul>
              <li>Lars is sitting at bar...not being perceptive</li>
              <li>Cecil goes to meet Arxius</li>
              <ul>
                <li>Sees old ladies with soup with no one to feed</li>
                <li>Asks Arxius about Dorrissa and her shifting face.</li>
                <li>Arxius is like she is my half-sister, she got the powers I did not</li>
                <li>Says she has patchy hair, one eye all black and the other all white. Some weird purple pulses on your eyes....sometimes magical DNA comes out weird</li>
                <li>He is like how can I know if we can trust her if she is openly disguising herself</li>
                <li>Lars came with: "Where we come from only important people are hot"</li>
                <li>Arxius is like can you trust a woman in makeup? </li>
                <li>Arxius is like I teased her as a kid and she blasted me...I deserved it...I did not speak to her insecurities and consider the pressure that the world places on her</li>
                <li>Lars is glad we are on the same page there too?</li>
                <li>Corpo arrives and is like do her looks give her insecurities? Relationship with father? Cecil leaves</li>
              </ul>
              <li>NAT 1 Lars Perception at the bar looking for Thistle</li>
              <ul>
                <li>Corpo is just playing with Staff of Storm and Lightning?</li>
                <li>Corpo goes into the bar...Lars is buying drinks he is like wth where is your flask? This is a problem...</li>
                <li>Lars is like a gentleman never tells...but I'm no gentleman</li>
                <li>Corpo gives bar tender a gold to bribe bartender to tell him where Thistle is...he is like I need more than that to betray the location of a young lady to a stalker....Lars is like nah he won't</li>
                <li>Corpo is like I need to find her for your safety...if this man runs out of drink...will your bar be safe??</li>
                <li>Bar keep is like did you threaten me?? Soldiers come here. </li>
                <li>Corpo is like they like us</li>
                <li>No they like Lars, his gpa buys them drinks. </li>
                <li>Lars says they tolerate you</li>
                <li>Gpa is a ghost..."we don't say that word"</li>
                <li>Can I have a drink with my friend? OF COURSE IT IS A BAR PAY FOR IT</li>
                <li>Corpo perception check for Thistle...sees here flirting it up with a bunch of imperial soldiers</li>
                <li>Lars sees her flirting with the guys...Corpo notices that she is just flirting with soldiers just to pick pocket their gold</li>
                <li>Corpo gets a raging 1.5</li>
                <li>Uses the flask to get one drunk enough to take his dagger</li>
                <li>She is like hey I didn't just bury it I used it...hands a flask, and hands Lars 50 gold to split...</li>
                <li>She is like anyway...not like I was flirting with you...nervous...</li>
                <li>Lars can tell she took the flask she told herself to make money but really to give her excuse to chat again</li>
                <li>Corpo Insight to tell Thistle is all about Lars</li>
                <li>Lars is like...are you summoning me...she is like...yes...</li>
                <li>CORPO IS LIKE YOU WOULDN'T EVEN NOTICE I AM THERE</li>
                <li>She is like...uhhhh it's kinda new thing</li>
                <li>Corpo is like I learned you can always ask</li>
                <li>Lars' roll...dirty 20 and then rolled 29 on...chance</li>
              </ul>
              <li>Sparka goes to talk to GDE</li>
              <ul>
                <li>Dorrisssa is excited to start the job...he is like I'd like to talk to GDE</li>
                <li>She is like...uhhh...ooookkkkk....then walks away. No one has even asked to talk to her Bugbear bodyguard</li>
                <li>NAT 1 Sparka Insight on Dorrissa to check the two of them interacting</li>
                <li>Sparka and GDE sit...he mentions the ale at the Kord event was nicer...Sparka Prestidigitation the ale to be the same</li>
                <li>GDE: Prestidigitation...runs in the family</li>
                <li>He is like what? My name is Redlake...no one knows my family</li>
                <li>GDE: Some do...</li>
                <li>Sparka: Care to share more...no one knows about my father or mother...</li>
                <li>GDE: What do you know of your mother</li>
                <li>Sparka: That she left me this...holds up dragon shard. Says that she dropped him off to her adopted parents but said never heard from her again...</li>
                <li>Sparka can read his face...GDE looks a little torn...</li>
                <li>Sparka is like I know you are being cryptic I get it..but PLEASE</li>
                <li>GDE is like ok...you look like her...from what I remember...I was one of the best dragon slayers...says he was here when the ceiling was carved</li>
                <li>GDE: They say Redlake is some mythic dragon killer...but....it's your mother...you know what I remember about her....nothing. My battles she is a blur. I know only she was skilled with memory spells. </li>
                <li>Sparka: Huh...</li>
                <li>GDE: Inquisition can pry, so we can it on the DL and encourage the boogey man story of Redlake....also I am working for the Inquisition...</li>
                <li>Sparka: ...to protect her?</li>
                <li>GDE: he can be taught....</li>
                <li>Sparka: what about Dorrissa...</li>
                <li>GDE: I won't talk about her...I...let's just say I wouldn't be with her if it was a danger</li>
                <li>Sparka: You don't remember anything?</li>
                <li>GDE: I remember our battles...but I look and she is blurred. Also a Paladin is blurred...</li>
                <li>Sparka: Who else was there?</li>
                <li>GDE: Gal with Black Wings and storm (Sparka IDs as the Black Storm)</li>
                <li>Sparka: Whoaa.......</li>
                <li>GDE is like hey I can tell she did it</li>
                <li>Sparka: Who else was there?</li>
                <li>GDE: No one else here...</li>
                <li>GDE says that Black Storm followed his mother around</li>
                <li>GDE: Careful with the name...some deeper in the Inquisition might be on you</li>
                <li>Sparka: Anyone else was there...</li>
                <li>GDE: A fairy...was here then shipped out</li>
                <li>Sparka: Name?</li>
                <li>GDE: Stardust Buttersprinkle</li>
                <li>Sparka: Who else??</li>
                <li>GDE: A female druid...didn't know her that well...was really old</li>
                <li>Sparka: From Primavera?</li>
                <li>GDE: Most were from Primavera</li>
                <li>GDE describes his mother as moving fast and having memory spells</li>
                <li>GDE: The paladin jumped across streets and shoved a spear down the Dragon's throat. (Describes the Paladin as like a black hole in memories...described like the memory reality crystals)</li>
                <li>GDE hoped Sparka would know shit...</li>
                <li>Sparka tells him about Amarillis on the Dragonshard</li>
                <li>GDE knows about Fete...says sometimes the memories are chained...like it can take an hour to remember basic things. GDE likes Fete...but the rest of the family are assholes...</li>
                <li>GDE: Can I trust you??</li>
                <li>Sparka: Is like what can I do...</li>
                <li>GDE: Talk to that black storm, I'd love to talk to her...if you see her tell her Bargomkk says hi</li>
                <li>GDE describes how Black Storm twinned Hasted the two of them and then Dragon slaying</li>
                <li>Sparka: Why did you kill Amethyst Dragon? Aren't they neutral</li>
                <li>GDE: I SPIT OUT LUKEWARM...jk Some Gem Dragons believed Saridor's death could be undone by Tiamat and Bahamut reconciling</li>
                <li>GM confirms that Corpo is not Sparka's dad</li>
                <li>Sparka thanks him greatly...</li>
                <li>Sparka had the time crystal out...the Empire invaded a kingdom 50 years ago for a crystal 25% that size....they were slavers and all but they still chosen them</li>
                <li>GDE doesn't know why he knows about the crystals...mother must have left them?</li>
                <li>GDE: Trust me and keep looking...maybe I need to trust you</li>
                <li>Sparka: Easy for you to say, you at least have some memories of her</li>
                <li>GDE: You want to read my mind?</li>
                <li>Sparka immediately casts detect thoughts and sees blurry image of her face...same nose as Sparka and looks like him...he can tell she is an elf due to his knowledge of elves. Full on Elf. Black hair with blue/sliver highlights. Generally beautiful. First person he has ever seen that looks like her</li>
                <li>He then goes to show Sparka the Black Storm hastening him and the paladin as the two of them attack dragon. Briefly sees a moonblade...knows what it is</li>
                <li>GDE: Says we will talk again. Sparka can't stop smiling.</li>
                <li>GDE says Empire is not good, Empire is not bad, Empire is what it is to not all be dead. </li>
                <li>Sparka says he'd call that good. </li>
                <li>GDE says the last 30 years  when invasions are not constant there are questions of freedom...control...duty....interesting time</li>
                <li>Sparka thanks him again</li>
              </ul>
              <li>Sass went to go the Temple of Bahamut. No Pegging there. His loyal followers tell him to be careful and hide himself and they look for an adviser and they believe in him</li>
              <li>Sass: I believe in you too</li>
              <li>They cry</li>
              <li>Warn not to let other Dragonborn know...kingdoms might send assassins</li>
              <li>Lars says once you go Dwarf you need a wheel chair.</li>
              <li>Lars rolled a 29...under 30%</li>
              <li>Sass goes to the legion camp to teleport</li>
              <li>Ben saying Corpo is into old ladies possibly grandma </li>
              <li>Cecil goes to Dorissa and apologies</li>
              <ul>
                <li>He is into sharing knowledge and being open by Ioun</li>
                <li>He is like sorry I didn't mean to be there</li>
                <li>NAT 1 Persuasion Cecil to convince her of his apology</li>
                <li>Cecil doesn't give a fuck about his uncle</li>
                <li>Cecil is like whoa you have a lot of information that could be shared amongst the world???</li>
                <li>She points to the Silver Dome and is like tons of info in the library</li>
                <li>Cecil is like I am a Votary of Ioun so I would love to go check it out and share this knowledge with the world</li>
              </ul>
              <li>They see Sass arrive. 30 minutes til go time. </li>
              <li>Lars has finished...in multiple ways. Leaves without Corpo, so confident. BUT Corpo is at the bar and notices Lars strutting</li>
              <li>Corpo closes up, gives another gold</li>
              <li>Corpo then goes up to Thistle's room...he thanks Thistle for making his friend happy...then just leaves as he hear Thistle look for her underwear</li>
              <li>Sparka messages Maisie...quick peace keeping mission for the empire...would love to get dinner when I return</li>
              <li>They all arrive...Lars hands Sass a pair of ladies underwear says he might need it after getting his ass whooped by that princess </li>
              <li>LARS NEEDS TO MARK DOWN 3 => 2</li>
              <li>Arxius asks if ready to go...Corpo says lets ASSASSINATE PEOPLE and he was like wait...what??? NO the "one that is fairly ethically sound"</li>
              <li>There is a river?? IF ONLY SOMEONE HAD A WOODEN FLOATING RECEPTACLE  </li>
              <li>The river is like a mile plus</li>
              <li>NAT 20 Corpo Nature To know that the mountain drainage will be high so river should be fairly fast</li>
              <li>They walk into the teleportation circle through heavy gates and the runes are covered by an illusion to keep it</li>
              <li>Arxius casts the spell as they suddenly are outside in a military camp. That was his one spell slot so off to sleep. </li>
              <li>First he intros them to the military commander. They will help with transport</li>
              <li>Dorissa reiterate that their mission is to resolve it...the Phandalin Five don't want a war.</li>
              <li>The LT is like boat or land? boat is shorter and they choose boat cause of f-ing course they do.</li>
              <li>REMINDER that they have the lamp in his bag of holding and left it hidden in range. Also have Lars' Axe</li>
              <li>3-4 days til election. 5 days Imperial council arrive. MOST IMPORTANT 7 days til small business chamber of commerce</li>
              <li>Terrain is not quite arid...but heavy forest around the river</li>
              <li>Corpo is in the canoe next to the boat...Sass gets in too. The boat doesn't say anything</li>
              <li>They see the giant Hobgoblin fortress with star-fort tech and anti-aerial features on the north bank. They noticed crimson banners...just like Avlod</li>
              <li>Lars is handing the captain the flask. Captain Zaltus and Lars swap tales. Lars tells him about he had a Dwarophan on the boat once who stole his shoes...blabs for 45 minutes. Lars tries to untie his shoes...gets caught trying to make his story true. Persuasion check fails, captain is only so impressed</li>
              <li>5 hours later so it is 3-4pm</li>
              <li>Sparka and Cecil discuss before they get there how they are going as the Phandalin Five</li>
              <li>They arrive Murar....no bridges. Perhaps they should build bridges</li>
              <li>Reminder that Dorissa and co just don't know what is happening right now</li>
              <li>Corpo wants to go to the North Bank...Sass is like NO and paddles backwards</li>
              <li>Corpo does not submit...DEX OFF. 24-23 Sass is able to turn the canoe towards Murar</li>
              <li>They arrive in Murar and split up. TEAM YOLO go to the tavern and Team Reasonable go looking for the Mayor. Sass chooses Team Reasonable</li>
              <li>TEAM YOLO kicks in the door at 4:30 and the 9 people in the tavern are like....uhhhh. Entire tavern is human</li>
              <li>Corpo declares self a topographic expert ready to navigate the water.</li>
              <li>Robert says yes...topography is the study of water</li>
              <li>Team Reasonable goes to village hall</li>
              <ul>
                <li>Hail and well met.</li>
                <li> Sparka uses Gumtree. We are the Phandalin 5...missing two members. He is like here to offer our services to the town and the one across the river.</li>
                <li>Guy is staring at their pointed ears....and ya know dragoness</li>
                <li>Zalidr: Oh those North Bankers that stole our girls?</li>
                <li>Sparka: What happened?</li>
                <li>Zaldir: Pretty clear cut, they stole our girls</li>
                <li>Sparka: How old are they?</li>
                <li>Zaldir: The girls are 18, 17, 18</li>
                <li>Sparka: Can we just pretend that they are 12...some of our group are....</li>
                <li>Zalidr: no......</li>
                <li>Corpo screams yes somewhere</li>
                <li>Cecil: When did the YOUNG girls go missing?</li>
                <li>Zaldir: 3-4 day ago</li>
                <li>Sparka: All at once</li>
                <li>Z: 2 were kidnapped, then the other the next day</li>
                <li>Cecil: Kidnapped by whom?</li>
                <li>Z: Those Northbankers and their Hobgoblin overlords obviously...the Reddies</li>
                <li>Cecil: What do they want with those 3...</li>
                <li>Z: ...son you gay? I mean...read a history book...people don't take women to give them massages</li>
                <li>Cecil: Where were they taken from?</li>
                <li>Z: Two on a fishing boat, the later one went with a boy and they got jumped day later</li>
                <li>Cecil: Ah they were all on the water</li>
                <li>He gives directions to the surviving boy</li>
                <li>He pushes them why they are coming...Sparka like heard tensions and we wanted to prevent war. </li>
                <li>He is skeptical...but seems to accept that Sparka is like no one wants war...helps no one</li>
                <li>Sparka asks about the North Bank...everyone has a person to feud with across the river. </li>
                <li>It's been generations since overlords...over 25 years </li>
              </ul>
              <li>In the Tavern...</li>
              <ul>
                <li>The two throw gold and ask for main shot here...Mexcal mixed with Sirracha.</li>
                <li>They take the shot. Lars is good. Corpo waddles out and then hurls outside</li>
                <li>They are impressed by the Dwarf</li>
                <li>Corpo comes back and asks for rumplemints...bar keep like we have ale and Mexcal that's it</li>
                <li>They ask about the village</li>
                <li>Dude be like the North Bank fuckers stole our women</li>
                <li>Lars: ALL OF THEM?</li>
                <li>Dude: No I would have died</li>
                <li>Lars: Same!</li>
                <li>Dude: No just the three...lists the names</li>
                <li>Corpo: Are any of the fathers here?</li>
                <li>Dude: No their daughters were kidnapped you asshat</li>
                <li>Corpo: They are across the river??</li>
                <li>Dude: THEY HAVE HOUSES! Oxadi way...your daughter missing..you at home</li>
                <li>Corpo: Is if they didn't come back how you know they missing</li>
                <li>Lars: HAVE YOU SEEN THAT RIVER</li>
                <li>Dude: Tells him the other two saw the missing girls boat</li>
                <li>Corpo notices religious symbols on the wall, the Raven Queen. </li>
                <li>Corpo goes to bathroom to change</li>
                <li>Lars: Why did they take your girls?</li>
                <li>Dude: HAVE YOU READ A HISTORY BOOK? Do horrible things. Must get girls back and castrate them as revenge</li>
                <li>Corpo hears a voice to his left. All Black figure with all black wings and a mask. DO YOU SERVE THE MATRON? Corpo says yes and then catches a single feather while holding his dick with a pair of tweezers </li>
                <li>Lars told the Good Samaritan but from Durusol</li>
                <li>Dude: Fucking Durusol never helped anybody</li>
                <li>Corpo walks back from the bathroom in all black....they are like...coool. into the Raven Queen</li>
                <li>Lars is like I used to have a heavy bar tab...had visions of hell and heard bad things..trying to help...common folk trying to do good things.</li>
                <li>Lars: Can I get you other girls....</li>
                <li>Dude: You can get me other girls...but...still want those girls back...</li>
                <li>Random guy draws a couple sketches of the girls</li>
                <li>They are back looking at Corpo...Lars buys another round </li>
                <li>Corpo has freaked the locals. Wonders why the bar has religious affiliation if they are freaked out.</li>
                <li>Corpo is like do the north bankers worship the Queen too....</li>
                <li>He is like they claim to...but...noooo</li>
                <li>Corpo asks if the women stealing came out of nowhere...</li>
                <li>He is like yeah nothing recently</li>
                <li>Corpo is like I am a descendant of the most recent champion of the goddess of death...dude is like....oh...ok...I will get your girls back</li>
                <li>He gives name Salara is the townspeaker...but...no clue who else would be instigators...</li>
                <li>Townspeaker election is year and a half</li>
                <li>Corpo asks if the theft of people...</li>
                <li>Dude: you mean...kidnapping....</li>
                <li>Corpo: Yes?</li>
                <li>Dude: I don't think so</li>
                <li>Corpo: Did anyone here...do...anything</li>
                <li>Dude: DID WE DO ANYTHING  WORTH GIRLS GETTING KIDNAPPED! WHAT ARE YOU IMPLYING</li>
                <li>Corpo: Defensive?</li>
                <li>Dude: Well you claim to be champion of our goddess and come here</li>
                <li>Corpo: And THEIR GODDESS. Both AND</li>
                <li>Everyone stares at him....Corpo doesn't back down</li>
                <li>Lars walks Corpo out of the bar</li>
              </ul>
              <li>Team Reasonable walks to Mavol son Obiyon house and knock</li>
              <ul>
                <li>Say they want to talk to Mavol about the incident</li>
                <li>Asks if they want tea of taquitos with some dip</li>
                <li>Sparka just wants tea</li>
                <li>Mavol comes out... 19 year old boy</li>
                <li>Cecil: How are you holding up</li>
                <li>Says Kulvesa and and he went out...they jumped..shot by arrows near middle island. Bad swam out of the water...STAB STAB STAB Punched guy and swam home</li>
                <li>Guy said the arrows were Oxkadi make so yes north bankers</li>
                <li>Cecil asks about guy punched in face...</li>
                <li>It was covered...</li>
                <li>Cecil asks what color the blood was...</li>
                <li>Red, definitely red</li>
                <li>Sparka asks who would want her dead...</li>
                <li>No one...she was lovely. The other two? No idea</li>
                <li>He asks what his relation to Kulvesa...</li>
                <li>He is flustered says just friends</li>
                <li>Sparka like...was there something more...SHE WAS BEAUTIFUL AND SMART AND KIND</li>
                <li>NAT 20 Cecil Persuasion Pressing Mavol to admit he wanted something more with Kulvesa than friendship</li>
                <li>He opens and cry...don't tell her please she is so cool....</li>
                <li>They ask if he had wet dreams about her...he breaks down and admits it....</li>
                <li>They ask if the others had lovers...he isn't sure</li>
                <li>Ask about the center isle....says he approached at midday</li>
                <li>He is openly weeping...I hope she is alive I hope she loves me</li>
                <li>Sparka asks if he wants to help get her back...show us the way?</li>
                <li>Asks when to show him??</li>
                <li>They tell Mavol they are going to ask other families...CRYING EATING TACQUITOS</li>
              </ul>
              <li>Corpo and Lars staring at the river...yearning. </li>
              <ul>
                <li>What's just around the river bend</li>
                <li>They know nothing about the middle isle...going to investigate with their brilliant criminal minds</li>
                <li>Look at the DC and start paddling across...</li>
                <li>...FUCKING IDIOTS...</li>
                <li>...much like Sam and Frodo...</li>
                <li>NO! SHUT UP</li>
              </ul>
              <li>Cecil N Sparka chat with 1st two missing girl fams</li>
              <ul>
                <li>See a bunch of idiots paddling the canoe, they fail their sneak</li>
                <li>Cecil: Hey tweedle dee and tweedle dumb are paddling across the river</li>
                <li>Sparka: Remember the idea you had on the boat?</li>
                <li>Cecil: I had sooo many ideas on the boat</li>
                <li>Sparka: Gravity well....honestly sometimes I feel like that would be a better use</li>
                <li>They are out of range...but can see that they are not not heading to the center isle...other side. Well they are out of range</li>
                <li>Cecil tells Sparka they are out of range...could try sending bowser after them....Sparka is like f-it</li>
                <li>They asks the fathers why the kids were out checking traps for fish</li>
                <li>OOC Murmuring that they were lesbians</li>
                <li>Good girls and always did their chores </li>
                <li>Fathers say the girls knew Kulvesa but weren't best friends</li>
                <li>Cecil: How close are the traps to the center isle</li>
                <li>They say some were nearer to the center isle</li>
                <li>He says not further than the center isle</li>
                <li>The isle and roughly the border....Empire and Hobgobs are not allowed to go to center isle...locals can but ya know most don't</li>
                <li>Center isle mostly trees</li>
                <li>But the treaty between Imps and Hobs they can't go on and cross</li>
                <li>Not many go to the center isle.</li>
                <li>They don't go to talk to Kulvesa's father...decide to go to center isle but not catch the others</li>
              </ul>
            </ul>
          </Collapsible>

          <Collapsible
            title="Team Responsible <=> Team YOLO (#25 - 6/3)"
            toggle={this.collapseToggle.bind(this)}
            kind="session25"
            show={this.state.show}
          >
            <ul>
              <li>Comment middle isle had a Sanitorum on it</li>
              <li>Revise that band talked about the Cult in this world...the Cult is in the Pentarchy, so fairly unknown</li>
              <li>Sparka realizes that team YOLO is out of reach</li>
              <li>In Murar</li>
              <ul>
                <li>A kid sees bowser and yells "DRAGON" the town flips, people pulling daggers, old man in the street, an old house the walls fall down and a ballistae is revealed, kids hide under a fake rock into a cellar, short bows drop from eaves of buildings</li>
                <li>Cecil poofs Bowser out of existience</li>
                <li>They realize nation of rogues...guerilla fighters</li>
                <li>Cecil calms them down, poofs bowser out</li>
                <li>Clarify Sass is a walking weapon with his breath so they on edge</li>
                <li>Clarify Cold War with Iron Legion and Empire</li>
                <li>Kid asks Cecil why his ears are pointy...Cecil like I'll tell you bout it when you are older. Cecil like f-this kid but Sparka gives a Prestidigitation to make kid happy</li>
                <li>Don't give us Half-Elves bad name Cecil</li>
                <li>"BEHOLD MY CLOACA" Sass doesn't say</li>
                <li>Ask Mavol if they can catch em...nope</li>
                <li>Sparka clarifies range to the DC...over 2K feet</li>
              </ul>
              <li>They get in Mavol's rowboat</li>
              <ul>
                <li>Sass rows like a champ</li>
                <li>Cecil asks what they were fishing for in the traps...he describes stuff</li>
                <li>NAT 20 Cecil Nature To figure out catfish-like creatures, other fish too. He realizes a ton of fish can get caught.</li>
                <li>Cecil asks Mavol about the center isle...people were sent there in the past...he doesn't know. Cecil pushes...they said it was TB...but Mavol has never seen anyone get sent there. Mavol assumes...that the illnesses were less standard are more....Vamp/Werewolf</li>
                <li>"Future Eric Mike is rowing"</li>
              </ul>
              <li>Mavol brings them into the middle isle...you see the missing boats...7 boats beached behind some logs</li>
              <ul>
                <li>He sorta points a direction and asks they want him to lead the way...</li>
                <li>Sass doesn't see many trails...just nature</li>
                <li>Cecil checks out the other boats...see they are all about the same design...can't tell...but then ask Mavol and he is like ours are different those are North bankers</li>
                <li>Isle is like 6 hour to walk around...a big one</li>
                <li>Perception check...can see footmarks leading inland...lose them quick</li>
                <li>They send up Bowser but it is heavy foliage</li>
                <li>Sparka asks if Mavol can lead the way...he is like...sure....</li>
                <li>Perception check...don't see things</li>
                <li>NAT 20 Cecil Insight to see that Mavol is nervous is getting nervous as they walk inland</li>
                <li>Cecil asks if he would rather wait by the boat..he is like uhhh</li>
                <li>Sass is like ARE YOU A PUSSY? He sprints into the woods</li>
                <li>Arrows hit the trees</li>
                <li>"Too bad you guys don't have your muscle" Ryan</li>
                <li>"Uh it's right here" Mike</li>
                <li>"You're more for show" Ryan</li>
              </ul>
              <li>TEAM YOLO PADDLES</li>
              <ul>
                <li>Notice signs as they approach the shore</li>
                <li>Lars sees odd language...sees loan word in it from Celestial for Smiting. Lars knows there are 35 words in celestial for smiting...smiting while they beg, smiting while they run away, smiting while they defy....this is a judicial-I-don't-want-to-but-I-have-to smite</li>
                <li>Corpo notices a word in Draconic....assumes it is loan word...it means something about customs</li>
                <li>They assume that they should check in...be reasonable....unlike the rest of the party</li>
                <li>They are going for cover story of missionaries since Corpo is Raven garb and Lars is like KORD</li>
                <li>Lars makes axe look crappy</li>
                <li>They arrive and the local does not speak any of their languages...</li>
                <li>NAT 1 Lars Persuasion he just drops to his knees and bows down to the north bank woman</li>
                <li>They notice Sylara gets an idea and then rouses the people and tells the two to follow</li>
                <li>They hear the word for customs....debate fleeing...then follow them</li>
                <li>They hear Hobgoblin chattering...as Hobgobs show up on roofs, behind walls...Shadow Monks...a Caster</li>
                <li>The Hobgob in common says that the locals have ID-ed you as Imperials and kidnappers of their women....defend yourself or be arrested</li>
                <li>Lars is like I am Lars Rutignass..The Dragon...you may have heard tales of my prowess and my digit...they seem to have recognized him</li>
                <li>Lars like I am missionary of Kord....</li>
                <li>He is like we only allow the worship of Maglubiyet//exarch of Bane unless express permission of the Council of Warlords</li>
                <li>Corpo knows about Bane...lawful-lawful evil...pushes into the Evil. Knows Maglib is his big old deal</li>
                <li>Corpo knows they might allow Raven Queen</li>
                <li>Lars is like ID LOVE TO HEAR MORE ABOUT YOUR god</li>
                <li>Corpo says I am a missionary...but I come representing that which comes for us all.... he ain't buying it</li>
                <li>So he is like...here just as missionaries...no business of Empire</li>
                <li>Avlod walks out...is like...speak plainly</li>
                <li>Lars is like we will speak privately with you</li>
                <li>Avlods nods...says will be in sight of his people</li>
                <li>He hops down and walks towards the river</li>
                <li>He is like why are you in my land?</li>
                <li>Outloud they are like truth?</li>
                <li>Corpo is like we were told the Southsiders had had their women were kidnapped by northsiders but seems like the reverse all is true</li>
                <li>Lars is like doing goodwill work got tied up with the empire...not...official</li>
                <li>he lowers hand and soldiers react</li>
                <li>Corpo like good to see you...he is surprised to see him</li>
                <li>He is like I am a new warlord on the council</li>
                <li>They congrats him</li>
                <li>He is like yes my exploits in your world got me here...but as junior member they sent me to this problem. So it might destroy me</li>
                <li>Lawful Evil...ISB in Andor level...Banality of evil</li>
                <li>Says any Imperial involvement he sees he will go to war</li>
                <li>Says that two girls missing here</li>
                <li>They went fishing</li>
                <li>Avlod relates multiple missing traders....</li>
                <li>Avlod says the town speaker says you did it....you say....</li>
                <li>Lars is like literally the first time we step foot here...</li>
                <li>Corpo is like why would we come here if we were trying to sneak in?</li>
                <li>Avlod calls forward a mind reader...enters Lars' mind...sees the truth</li>
                <li>Avlod is like.....60 to 19 is quite a difference...he then gives Avlod a very simple ring and says be formal, Lars pockets it under his blood soaked Butcher's Bib</li>
                <li>Avlod sends the mage to mind read the townspeaker....LIAR</li>
                <li>Lars is like Execute her?? Sorry new to this religion. Corpo is like interrogate?</li>
                <li>Avlod is serious...like yes...</li>
                <li>Avlod says as the offended party he gets first Ax strike....</li>
                <li>Lars and Corpo going to help with interrogation</li>
              </ul>
              <li>the island...ROLL INITIATIVE. They see that they are surrounded with people hiding</li>
              <ul>
                <li>ROUND 1</li>
                <ul>
                  <li>Mavol</li>
                  <ul>
                    <li>Dashed away</li>
                  </ul>
                  <li>SPARKA</li>
                  <ul>
                    <li>NAT 20 Firebolt to hit Mavol 25</li>
                    <li>Tried to TK shove him back</li>
                  </ul>
                  <li>SASS</li>
                  <ul>
                    <li>Hits Mavol in the leg 32 points</li>
                    <li>Everyone is shocked that he is alive</li>
                    <li>Shoots in his ass for 23 but he is like nearly dead</li>
                    <li>He has the Netflix equivilent of ninja training</li>
                  </ul>
                  <li>Rogue 1 </li>
                  <ul>
                    <li>Misses Sparka...good 70 foot away but then he loses him</li>
                  </ul>
                  <li>Archer</li>
                  <ul>
                    <li>Misses Cecil with two shots...a good 150 feet away. Sass is like damn yall missed</li>
                  <li>Barbarian</li>
                    <li>RAGES Spirits float around</li>
                    <li>Axe strike on Sass, the spirits prevent him from attacking others</li>
                  </ul>
                  <li>BOWSER</li>
                  <ul>
                    <li>Help Sass</li>
                  </ul>
                  <li>CECIL</li>
                  <ul>
                    <li>Cast Faerie Fire...catches Mavol. He can't hide</li>
                    <li>Bardic Inspiration to Sass</li>
                  </ul>
                  <li>Rogue 2</li>
                  <ul>
                    <li>NAT 20 hits Sparka</li>
                    <li>Tries to hide but Cecil catches him</li>
                  </ul>
                </ul>
                <li>ROUND 2</li>
                <ul>
                  <li>Mavol</li>
                  <ul>
                    <li>Charges back to Sparka..attacks...but Cecil Cutting words and then Sparka SHIELDS and Mavol misses</li>
                    <li>Cutting word was "I CAN'T WAIT TO FUCKING KILL YOU"</li>
                  </ul>
                  <li>SPARKA</li>
                  <ul>
                    <li>Kills him Mavol by putting a burning hands in his face. He passes but don't matter.  He crisps and literally crumbles to a dust</li>
                    <li>Looks at another dude and is like BRING IT</li>
                  </ul>
                  <li>SASS</li>
                  <ul>
                    <li>Bowser helps and he hits the barb ahead of time</li>
                    <li>NAT 20 Sass Arrow shot on the Barb</li>
                    <li>Duchess SICK BALLZ</li>
                  </ul>
                  <li>Rogue 1</li>
                  <ul>
                    <li>Arrow...but Sparka blocks it with Shield</li>
                  </ul>
                  <li>Archer</li>
                  <ul>
                    <li>Shoots Sass and gets Wails of the dead on Sparka. Sparka knows about the Wails of the dead and Phantom Rogues how the ancestors due these things</li>
                    <li>Hits Sass again</li>
                  </ul>
                  <li>BOWSER</li>
                  <ul>
                    <li>Help Sass</li>
                  </ul>
                  <li>CECIL</li>
                  <ul>
                    <li>Casts Rautholaum Psychic Lance (WE MISS HALF DAMAGE)</li>
                    <li>BA to HW Sass</li>
                  </ul>
                  <li>Rogue 2</li>
                  <ul>
                    <li>Runs away...SASS THAMATURGY calls him pussy</li>
                  </ul>
                </ul>
                <li>ROUND 3</li>
                <ul>
                  <li>SPARKA</li>
                  <ul>
                    <li>Quicken Firebolt against Archer hits once</li>
                  </ul>
                  <li>SASS</li>
                  <ul>
                    <li>Hits barb</li>
                    <li>KILLS Barb by shooting the arrow in a way that the arrow makes him fly up then crash back down</li>
                    <li>Sees the other guy</li>
                  </ul>
                  <li>Rogue 1</li>
                  <ul>
                    <li>Shoots Sparka but misses</li>
                    <li>Then runs</li>
                  </ul>
                  <li>Archer </li>
                  <ul>
                    <li>Shoot Sass but Cutting WORDS from Sass says YOU SUCK</li>
                    <li>Starts booking it</li>
                  </ul>
                </ul>
                <li>POST ROUND FOOT RACE</li>
                  <li>Athletic check as they run at them</li>
                  <li>Baddie trips and falls</li>
                  <li>Sass NAT 20 Shoots fleeing guy. KILLS but not as he ensnares him down</li>
                </ul>
              </ul>
              <li>Back to LARS</li>
              <ul>
                <li>Avlod looks to Lars...as the wronged party...how shall we proceed</li>
                <li>Soldiers grab the mayor, hold her arms and lower her to the ground</li>
                <li>"Legion business" the translator says</li>
                <li>Local parent throws a bottle at the mayor. Loyal but step out of line and F-em</li>
                <li>"Let's see how deep this goes...same story both sides, lady seemed to know who we were"</li>
                <li>The Human mage casts ability to talk Oxkadi on</li>
                <li>Avlod asks in Dwarven...should we break an arm first....Lars is like yes.</li>
                <li>They break arm, she screams, Lars kicks dirt in her face</li>
                <li>Corpo is playing with his staff</li>
                <li>Lars: "Who put you up to this??"</li>
                <li>She is like I PUT MYSELF UP TO IT TO FREE FROM IMPERIALS AND HOBGOBS</li>
                <li>Lars: "Who else is involved? Same story on the South"</li>
                <li>She: "It's them!" </li>
                <li>Lars reads her as lying, slaps he upside the head "SWEAR TO ME"</li>
                <li>She: "FINE.. approached by agent, shield with fire symbol on it...said someone wanted to start war and it would help us. RAISE UP LIBERATE! I AGREE AND BELIEVED IT"</li>
                <li>Corpo is like...that symbol is the symbol of Dread Flame</li>
                <li>Lars is lke Fuck and takes a drink of booze</li>
                <li>Lars asks how long....she is broken...says started few days ago. They worship a demonic deity</li>
                <li>She spills that the girls are on middle isle</li>
                <li>She said "We" Lars notices so must be another one in town...smacks her upside the head</li>
                <li>She points to some random guy...he gets NAT 1 Lars gets 11....Lars nods to Avlod...Avlod snaps and an archer takes out his knees, soldiers grab, break arms, and drag over</li>
                <li>Lars "WHO ELSE! I'll let you live if you tell the truth"  </li>
                <li>Dude says two on the isle...Lars says keep him in jail until we know the truth</li>
                <li>Lars is like I'm done with her...are you done...Avlod the GREAT?</li>
                <li>Avlod likes that</li>
                <li>He nods and walks away</li>
                <li>Lars makes Ax look good and then ignites and beheads her</li>
                <li>Corpo knows about the Dread Flame and The Military Industrial Complex...</li>
                <li>Corpo also recognizes that they must worship Tharizdun...the Chained Oblivion and most powerful of all Demons. Tharizdun chained in Abyss...DEVOUR ALL</li>
                <li>Lars recaps Corpo</li>
                <li>Tell Avlod they heading to the center isle</li>
                <li>"Never Trust Sober People" Corpo</li>
                <li>Avlod is like treaty means we can't go to the center isle, super lawful and the mage gives you a gust of wind to get going</li>
                <li>Lars' Axe absorbs Oxkadi</li>
                <li>Corpo is like if we can prove South wasn't responsible...would that keep the peace...Avlod is like yes PEACE</li>
                <li>Avlod is like we need some sort of evidence</li>
                <li>Corpo is like ahh Military Industrial Complex....DICK CHENEY IS BEHIND IT ALL</li>
                <li>They make Survival Check to find the others</li>
              </ul>
              <li>BACK TO THE OTHERS</li>
              <ul>
                <li>They have a prisoner and two more out in the woods</li>
                <li>Cecil interrogates...spits in his face</li>
                <li>Sparka is like you choose Death? He is like I chose death long ago pointy-eared-fuck</li>
                <li>WHOA</li>
                <li>Sparka is like who all involved</li>
                <li>He is like SOUTH AND NORTH WILL UNITE AND RISE UP BE FREE!</li>
                <li>Sparka like free from who?</li>
                <li>THE EMPIRE! HOBGOBLINS!</li>
                <li>Sparka laughs</li>
                <li>He's like free? united with those ingrates from the south?</li>
                <li>Sparka interrogates he spits more</li>
                <li>WE WILL RISE AND FREE!</li>
                <li>He is like the girls will be fine, they are safe</li>
                <li>he is like heehee you don't know North and South both had girls stolen</li>
                <li>He is like we will bring them home safe....</li>
                <li>Sass tries to intimidate him...fails...did you accept humiliation? Before he can answer takes off his pants and comments on his small genitalia</li>
                <li>He is like SHAME IS NOTHING COMPARED TO THE LIBERATION OF MY PEOPLE!</li>
                <li>Sparka casts Detect Thoughts. He flashbacks of the Dread Flame symbol on their shields...Sober One's merc pirate group...approached firebrands...Sparka can tell the mercs are plaiyng them...sees a memory of Mavol and Kulvesa failure to convince</li>
                <li>Cecil intimidates to get more info of who is there...from his side there was him and another...only seen 3 mercs. 2 girls from north 2 girls from south</li>
                <li>Sparka in his head....figures out he was running to mercs, the mercs don't know they are there but the Archer and Rogue still in forest might tell them</li>
                <li>Sparka leans in real close..deep in his eyes.... Says I am going to spare you more pain...softly kisses his forehead and does create bonfire KILL</li>
              </ul>
              <li>Lars and Corpo and hear screaming and find Archer about to shoot at their friends</li>
              <ul>
                <li>Both attack and do hits....Lars is not raging...responsible...an emissary of peace...totally in control</li>
                <li>The two hack this guy brutally. Corpo then hits and punches... KILL  staff of storm of lightning with bolts around it and the dude's head pops off with his eyes burned out</li>
              <li>Cecil, Sparka, and Sass see this</li>
              <ul>
                <li>Corpo: "We realize their may be hurt feelings" </li>
                <li>Corpo is like we went to the North shore...they are much wealthier than the south bank....ran into avlod</li>
                <li>Cecil: "How's he doing?"</li>
                <li>Corpo: "Avlod is killlling it"</li>
                <li>Lars: "He's a minor warlord now...moving up the corporate chain"</li>
                <li>Corpo: Is like warlords are hankering up north...up north had women kidnapped too</li>
                <li>Lars: Two guys from the North and his bro is here....</li>
                <li>MILITARY INDUSTRIAL COMPLEX</li>
                <li>They snuffed out a traitor in the north and have one in prison...</li>
                <li>If they can bring evidence....the war is off</li>
                <li>Sass applauds how good they did</li>
                <li>Never trust a sober dwarf...don't trust a sober dwarf</li>
                <li>Don't drink and you go Chaotic Evil</li>
              </ul>
              <li>They are like we need to get rid of this guy...in Mother Nature's night camo...not sure if they actuall did</li>
              <li>As stars come out, they notice new stars...there is a Phallic constellation.</li>
              <li>Sparka suggests to send Lars bumbling through the forest...carrying burnt out head of other guy</li>
              <li>Lars kicks shit and yells "The DRAGON OWES THIS ISLAND" playing with the guy's head.</li>
              <li>Lars gets hit for 18 damage</li>
              <li>TRIP KILL Arrow, Firebolt, Javelin....Arrow him to a tree, javelin catches on fire, and on tree burning there is an abandoned tree house</li>
              <li>They find some Iron Legion coins and some Imperial coins to hopefully prove both sides where here</li>
              <li>They stealth up towards the fort....</li>
              <li>NAT 1 Lars stealth....but the others catch him</li>
              <li>Find cabin in the woods...but surrounded by a wooden palisade</li>
              <li>Sass and Lars wonder openly how grateful the women would be...</li>
              <li>Corpo spots a Merc on the wall....smoke rising from inside</li>
              <li>Corpo notes that the guard is an incel....120 feet away</li>
              <li>The palisade has a gate that is closed</li>
              <li>Sass investigates any weak points in the walls....finds a weak point 30 feet left of the gate</li>
              <li>Lars goes ritual and talks to the a Raven</li>
              <ul>
                <li>FRIEND RAVEN go scout...tell me who is inside and if weapons</li>
                <li>Fly you fool!</li>
                <li>Gets a NAT 20 on Perception. ADD TO THE TABLEAU</li>
                <li>Says 6 armed....others in a pen...pyre with a stake in the middle</li>
                <li>THANK YOU FRIEND RAVEN YOU HAVE SERVED WELL</li>
                <li>....I can talk to you for another hour...</li>
                <li>Lars is like...go hit that guy on the wall....</li>
                <li>Raven is like I will if you hit Corpo in the balls...</li>
                <li>Raven knocks guy off the wall</li>
              </ul>
              <li>Figured out the bag of holding Corpo doesn't need to breath</li>
              <li>Debating seeing the house </li>
              <li>Cecil's bag is Corpo and Sparka. Lars' bag is Sass</li>
            </ul>
          </Collapsible>

          <Collapsible
            title={`"Bye Bye" Demon (#26 - 6/30)`}
            toggle={this.collapseToggle.bind(this)}
            kind="session26"
            show={this.state.show}
          >
            <ul>

              <li> DICKIUS CHENIOUS IS King of Halburtonia </li>
              <li> See pen of prisoners, pyres for sacrifices, tents, bridge, flowers...cabin...etc </li>
              <li> Dimension Door up to the cabin...Cecil has Sparka/Corpo and Lars has Sass in bags </li>
              <li> Cecil casts #Bless on,,,? </li>
              <li> Sparka twins haste on Lars and Sass...they feel like they did a line of coke. Then Lars actually does a line of coke </li>
              <li> Ex post facto short rest and song of rest </li>
              <li> Lars sees his friend Ole tied up to one of the posts...sees other bodies bled out and ritually sacrificed. Not kidnapped girls </li>
              <li> ROLL INITIATIVE </li>
              <li> ROUND 1 </li>
              <ul>
                <li> SASS </li>
                <ul>
                  <li> Acrobatics his way out of the bag with Haste boon </li>
                  <li> Guards are like WHAAAAAAT </li>
                  <li> Sass unleashes 3 arrows at the guy  charging up the hill. 26 26 26 KILL by shooting in the face so hard he flies down the hill into bushes </li>
                  <li> Peak in the window and sees the four hostage girls tied/gagged and some baddies. Thugs and a priestess KNOW THEY ARE THERE </li>
                  <li> Sass does telepathy to Lars that the ladies are inside..include a bad to bone evil priestess...she could use a dwarf's touch </li>
                </ul>
                <li> LARS </li>
                <ul>
                  <li> RAGE </li>
                  <li> Runs to save Ole </li>
                  <li> KILL Rando cultist thug by spitting him up in half </li>
                  <li> Frees Ole </li>
                  <li> KILLS Alonso by twirling axe around and then sever neck </li>
                  <li> Turns to face another guy and scream </li>
                </ul>
                <li> SPARKA </li>
                <ul>
                  <li> Acrobatics to get out of the bag of holding </li>
                  <li> Notes that goons are melee with crossbows on back </li>
                  <li> Misses with a firebolt....around the back of the building to hide </li>
                </ul>
                <li> Mormon Bloodsail </li>
                <ul>
                  <li> Attacks Lars (Lars insights that he knows what he is doing </li>
                </ul>
                <li> BOWSER </li>
                <ul>
                  <li> Flies up and perception to look around...can tell peeps in tents that's it </li>
                </ul>
                <li> Wizard </li>
                <ul>
                  <li> Can't get a good line on a fireball....casts buff </li>
                </ul>
                <li> Priestess </li>
                <ul>
                  <li> Blood jar and she summons Demon...Tanaruk recognizes it and knows that the priestess can lose control. (LARS IS LOOK I CAN CONTROL THEM) </li>
                  <li> Tried to cast Spiritual weapon but can't do that! </li>
                </ul>
                <li> Tanarukk </li>
                <ul>
                  <li> Hits Sass twice for 27 damage </li>
                </ul>
                <li> Bandit Capt </li>
                <ul>
                  <li> Hits Lars  </li>
                  <li> "It's the brother! It's not his time to die! Leave him for last!" </li>
                </ul>
                <li> CORPO </li>
                <ul>
                  <li> Fails to get out of the bag...burns turn to get out. </li>
                  <li> Goes over next to Sass </li>
                </ul>
                <li> Femme Bandit Captain </li>
                <ul>
                  <li> KILL THE HOSTAGES </li>
                  <li> Kills one of the girls SASS Yells "The hot one killed one of them!" </li>
                </ul>
                <li> Bandit Cultist </li>
                <ul>
                  <li> Damages Duchess </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> Gravity Well MF inside to hit the baddies </li>
                  <li> YOU RAISE ME UPPPPPP does 37 damage. </li>
                  <li> KILL by gravity sucking up into the air and kill </li>
                  <li> Lifted up Priestess and Captain and they got pummeled </li>
                  <li> Bardic to Sass </li>
                </ul>
                <li> OLE </li>
                <ul>
                  <li> He goes and gets handaxes from Lars </li>
                </ul>
              </ul>
              <li> ROUND 2 </li>
              <ul>
                <li> SASS </li>
                <ul>
                  <li> SHARPSHOOTER Hits 36 Damage (1 attack what the hell) </li>
                  <li> 2nd SHARPSHOOTER hit 27 Damage with Ensnaring Strike but busts out </li>
                  <li> 3rd SHARPSHOOTER 30 Damage </li>
                </ul>
                <li> LARS </li>
                <ul>
                  <li> ATTACK Bandit HITS! </li>
                  <li> Attack KILL Bandit and flips balls at another dude does 1 bludgeoning damage. NO STI since incels </li>
                  <li> HITS other dude and screams at him. </li>
                </ul>
                <li> SPARKA </li>
                <ul>
                  <li> FIREBALL at Bandits down the hill for 33 damage KILL BOTH </li>
                </ul>
                <li> BOWSER </li>
                <ul>
                  <li> Doth nothin </li>
                </ul>
                <li> Wizard </li>
                <ul>
                  <li> FIREBALL Sass, Corpo, Cecil get FIREBALL </li>
                </ul>
                <li> Priestess </li>
                <ul>
                  <li> Drops concentration and drops in Barlgura </li>
                </ul>
                <li> Tanarukk </li>
                <ul>
                  <li> Slices up Sass BAD </li>
                </ul>
                <li> Bandit Capt </li>
                <ul>
                  <li> Hits Larrs twice  </li>
                  <li> "not half the man your brother is"  "TWICE AS LONG!" </li>
                </ul>
                <li> CORPO </li>
                <ul>
                  <li> Hits with Staff of T&L...KILL Tanarukk by eyes lighting up like Thor....shoving staff up it's ass. Exploding from inside out with dick flying into Sass' face.  "We established that flying genitalia do damage" </li>
                  <li> BUT the dick misses him. </li>
                  <li> He jumps in window "SUP BITCH" she replies "Not your tiny dick" "Actually...it is always up...." </li>
                  <li> Attacks femme bandit and KILLS her by snapping her neck </li>
                  <li> Attacks Femme Bandit Captain Lilith Blood Moon with a punch </li>
                  <li> KILLS Lilith Blood Moon...last thing she sees...him standing over her with staff...saying goodbye </li>
                  <li> Time slows and he sees a red world with Angel of the Raven Queen.   </li>
                  <ul>
                    <li> Angel: "Will you serve" </li>
                    <li> Corpo: "Yes I will" </li>
                    <li> Sees yellow fate lines...he sees a vision of a perfect idyllic life...children, drinking, breakfast, love </li>
                    <li>  Naked forearms.... </li>
                    <li> Angel hold up alien dagger to the line.... </li>
                    <li> Corpo quotes John wick "I will serve, I will be of service" </li>
                    <li> Angel cuts the line of fate and it wraps around his knuckles to get his Artifact of the Deity </li>
                  </ul>
                  <li> HE SODOMIZED A DEMON...THEN KILLED TWO GIRLS IN 6 SECONDS </li>
                  <li> He looks at imprisoned ladies </li>
                </ul>
                <li> Bandit Cultist </li>
                <ul>
                  <li> Shoots a crossbow at Lars </li>
                </ul>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> Dissonant Whispers from Ring of Spell Storing and she gets hit and has to flee </li>
                  <li> Debates misty stepping behind Sass so the Barlgura hits him...but goes behind Lars </li>
                </ul>
                <li> OLE </li>
                <ul>
                  <li> Attacks and misses </li>
                </ul>
              </ul>
              <li> ROUND 3 </li>
              <ul>
                <li> SASS </li>
                <ul>
                  <li> Dave Ramsay DEATH SNOWBALL </li>
                  <li> SHARPSHOOTER HITS Priestess BREAKS Concentration </li>
                  <li> KILLS the bandit </li>
                  <li> Pops a potion </li>
                </ul>
                <li> LARS </li>
                <ul>
                  <li> Erupting Earth on Barlgura for 10 damage </li>
                  <li> HITS Barlgura </li>
                </ul>
                <li> SPARKA </li>
                <ul>
                  <li> Says "Bye Bye" and Vortex Warps him towards the Bandit. Bandit Cultist shits himself </li>
                </ul>
                <li> Barlgura </li>
                <ul>
                  <li> murders the Bandit Cultist </li>
                  <li> Hits Sass but only 5 damage </li>
                </ul>
                <li> BOWSER </li>
                <ul>
                  <li> Flies up and looks for the wizard </li>
                </ul>
                <li> Wizard </li>
                <ul>
                  <li> Fireball  </li>
                  <li> Sass goes down </li>
                </ul>
                <li> Priestess </li>
                <ul>
                  <li> Walks to Corpo..."We looked for you across planes of existience...and...you just came to us" </li>
                  <li> "Boss, the Genasi is here along with your brother" on sending stone </li>
                  <li> summons another Tanarukk </li>
                </ul>
                <li> Tanarukk </li>
                <ul>
                  <li> Hits Corpo </li>
                </ul>
                <li> CORPO </li>
                <ul>
                  <li> Non-lethal "Corpo never kills a bitch...Corpo slays" </li>
                  <li> KILLS the priestess but Rafiki knocks her unconscioously by chanting Asanta Sanna squash banana </li>
                  <li> Attacks Tanarukk </li>
                  <li> Flurry of Blows to hit twice </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> Casts Gravity Sinkhole (I think) and damages unseen wizard and removes Bowser </li>
                  <li> Wizard fails invisibility </li>
                </ul>
              </ul>
              <li> ROUND 4 </li>
              <ul>
                <li> SASS </li>
                <ul>
                  <li> Death Save Fail </li>
                </ul>
                <li> LARS </li>
                <ul>
                  <li> Attack Barlgura </li>
                  <li> Attacks NAT 20 Barlgura Reckless 41 damage </li>
                  <li> Attacks NAT 20 Barlgura Reckless 20 damage </li>
                  <li> KILLS by hitting in head then severing the big scrotum and hanging on his waist </li>
                  <li> Bonus Action to close Sass eyelids </li>
                </ul>
                <li> SPARKA </li>
                <ul>
                  <li> Points at creature to do Vortex Warp to give disadvantage....but...passes </li>
                  <li> Sparka pissed his one liner didn't land... </li>
                  <li> Firebolts the middle tent </li>
                </ul>
                <li> Wizard </li>
                <ul>
                  <li> Casts Cone of Cold BUT Cecil Counterspell BUT Cecil Counterspell failed </li>
                  <li> Damages the lot of them...Sass TWO DEATH SAVES. Ole Down </li>
                </ul>
                <li> CORPO </li>
                <ul>
                  <li> Lightning Bolt! </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> Walks over to see the Wizard. Vicious Mockery "I know why my mother never hugged you, it is hard to embrace your mistakes" </li>
                  <li> Healing Word Sass </li>
              </ul>
              <li> ROUND 5 </li>
              <ul>
                <li> SASS </li>
                <ul>
                  <li> Gets back up again </li>
                  <li> SHARPSHOOT Tanarukk HIT </li>
                  <li> SHARPSHOOT Tanarukk HIT KILLS him by shooting sword into his face </li>
                  <li> Blows himself to heal </li>
                </ul>
                <li> LARS </li>
                <ul>
                  <li> Sprints behind tent to see Halfling wizard </li>
                  <li> Goes to hit...but SHIELD </li>
                  <li> Hits! </li>
                  <li> KILLS HALFLING by cutting both his legs off..but dying. Cauterize his wounds </li>
                  <li> Cecil heals OLE </li>
                </ul>
              </ul>
              <li> Cecil can't tell if dead girl is from north or south </li>
              <li> Cecil Revivifies the dead girl...she is forever in her debt </li>
              <li> They don't untie the kids cause Cecil is afraid they are trying to kill each other </li>
              <li> Lars thinks one might be a scooby doo villain </li>
              <li> Cecil is like we here to help...THEY TOOK FREAKING OUT </li>
              <li> Sparka uses presti to create nice music notes NAT 20 to calm them down </li>
              <li> Adam Rizz shames Robert </li>
              <li> Sparka is like they all dead...well minus that chick who he asks Corpo to tie up....and then Lars is like I have this half a halfling...a quarterling </li>
              <li> Corpo strips her naked....the girls are freaking out (she was asking for it...look what she was wearing) </li>
              <li> Sparka tells Kulvesa that Mavol is dead </li>
              <li> Corpo does minor illusion a blanket over the priestess...Corpo keeps pants on since one girl is 17 </li>
              <li> Sparka has dream where he gets the YOU'VE MADE YOUR CHOICE AND I'VE MADE MINE </li>
              <li> Recognizes the Black Storm's voice </li>
            </ul>
          </Collapsible>

          <Collapsible
            title="The Sober One and Manta Rays (#27 - 7/28)"
            toggle={this.collapseToggle.bind(this)}
            kind="session27"
            show={this.state.show}
          >
            <ul>
              <li> Recap including telling Ben things he forgot and that St. Thomas Aquinas wrote a lesser known book about your grandmother trying to kill someone's mom OH and Mom's Spaghetti  </li>
              <li> Merchants are still in the pen </li>
              <li> Interrogations! </li>
              <li> 5 merchants...one Twi'lek in the pen </li>
              <li> Quarterling is bleeding out screaming </li>
              <li> Priestess who was searched/stripped by Corpo covered by a minor illusion </li>
              <li> Ole runs and opens a crate to get his stuff </li>
              <li> Only like 3 minutes since battle ended </li>
              <li> They look for proof for it wasn't worthy for war...girls from both sides...coins from both sides </li>
              <li> Sparka walks out to see a few merchants. One from north, one from south, Half-Orc from upstream near Orcish lands </li>
              <li> One merchant is like...praise Waukeen we are safe!  </li>
              <li> Sparka asks how long they have been there...couple days...they bled out two people last night. </li>
              <li> Sparka is like we should do proper Waukeen burial...coins on eyes of course but we take them back. </li>
              <li> Sign of Waukeen is touching your wallet </li>
              <li> Sparka gives rations to the starving ones </li>
              <li> Robert shows Waukeen image...Mike like...really you...want to see Bahamut </li>
              <li> Sparka tells them what the plan was....the North and South agree gold spends the same. The two agree to tell the truth to their sides </li>
              <li> They shake on gold.....could shake on copper or money sacks </li>
              <li> The merchants go to pilfer items and points out where to get their gold...agree that no sharing gold is the key </li>
              <li> Corpo interrogates priestess... </li>
              <ul>
                <li> Says she doesn't drink to keep her mind ready for pure chaos. </li>
                <li> He is like you had me at chaos...what kind of chaos? </li>
                <li> She spits in his face </li>
                <li> Corpo is like uhhh </li>
                <li> She spits again.... </li>
                <li> Corpo is like....hot </li>
                <li> She recoils and is like I am going to find something that doesn't turn you on </li>
                <li> Lars is like...good luck! </li>
                <li> The two fist bump </li>
                <li> Corpo is like you worship...the no bueno one? the void? THARIZDUN </li>
                <li> She is like all will be destoryed </li>
                <li> I can't hear cause Lars is talking to Sass about the color green </li>
                <li> Sparka finds 71 gold </li>
                <li> CREATION WILL BE DEVOURED IN CHAOS! she hollers as Cecil asks what the end game is </li>
                <li> She says weak and pathetic. </li>
                <li> Cecil is like who did you reach out to </li>
                <li> THE CAPTAIN! HE KNOWS YOU! she points to Corpo and Lars </li>
                <li> NAT 20 Lars Intimidation to get her to talk more: "Why is the Dreadflame worrying about us? he always knew he was the weaker of the brothers" </li>
                <li> Priestess: "He is power he is mighty" </li>
                <li> Lars: He can't even hold his booze! </li>
                <li> Priestess: He doesn't need booze! </li>
                <li> Lars: Oh it is added to it </li>
                <li> Priestess goes on about how Lars is a replacement by his Lecherous family to deal with missing the sober one </li>
                <li> Lars has Corpo do a minor illusion to cover up Lars hitting her with his...salami </li>
                <li> Reveal that Entropy is his goals! Break apart anything that is coming together </li>
                <li> Corpo says sounds like an emo bitch </li>
                <li> They are like fuck this person </li>
                <li> Cecil gets that she might have had a plan until Lars pissed her off. She wanted just die...maybe she is stalling?? But really just ready to go </li>
                <li> Cecil debates snuffing her out....Sass like put her in front of waters. CECIL DECIDES TO CUT OFF HER HANDS AND HER TONGUE. Encounter the Dreadflame with no hands or tongue. </li>
                <li> Cecil does it well..so well Lars is like surprised. The priestess screams but sorta into it....girls now totally freaking again </li>
              </ul>
              <li> Sparka sees a hawk and realizes it is a Wizard's familiar </li>
              <li> Runs inside and is like let's go we are being watched....Corpo is like is that a voyeuristic...SHUT UP WE NEED TO GO </li>
              <li> Lars is like grab the prisoners </li>
              <li> Sparka like...wtf happened here?? </li>
              <li> Sparka tries to calm them down...fails </li>
              <li> Sparka casts Sleep and they pass out to grab them </li>
              <li> As they walk out...Lars and Corpo recall that the hawk is from Avlod's crew </li>
              <li> Sass uses Thamaturgy to bellow to the hawk that "We have the girls, and the merchants from both sides...and...are...bringing them home so rightly and justly....we appreciate some help" </li>
              <li> It replies "on the way" </li>
              <li> Sparka and Cecil split the party to take the south bankers....sending Sass with Lars and Corpo...Sass like...why? Sparka is like...uhhhhhhhhhhhhhh Sass is like ooooh ok I get it </li>
              <li> They are going north...Lars is like WE will keep an eye on Sass don't worry....Sparka is like yes of course thank you give Avlod our best </li>
              <li> Cecil jams his old dagger into hands and tongue into the door. Adam is like WAIT I LOVE THIS CECIL </li>
              <li> Athletic checks as the able-bodied merchants help the party help move the girls.... </li>
              <li> Lars is just jumping for fuck knows why. Ole went with him. He tells Lars that his bro is the Dreadflame...Lars like I know your bro is into shrooms. Oh yeah...brothers...fuck it. </li>
              <li> Ole is like I was captured and tied up but they said to stop since I "wasn't on the list yet" </li>
              <li> Lars is like...important note he is "the sober one" can't hold his booze </li>
              <li> The north sprinters do much better. The see Avlod right by the Douche Canoe...assume he was allowed to see the neutral island if fireballs and such </li>
              <li> Lars is like we found the rest of our party...others taking people south.  </li>
              <li> Lars goes on...My brother the dreadflame is coming, not sure if you have heard of him but he can't handle his booze. MAKE CLIP 39:39 </li>
              <li> Avlod is like that is dishonorable in your culture? </li>
              <li> Lars is like yes, and he probably isn't that well endowed either </li>
              <li> Avlod is like so like a Genasi </li>
              <li> Lars yes but without the androgynous body </li>
              <li> Corpo is flirty and Avlod says the wrong tree </li>
              <li> Avlod says he gave word and now will tell the Council of Warlords that the Empire was innocnet. </li>
              <li> He asks who hired the Dreadflame...Lars and co realize that someone probably hired them...but...unsure who hired them </li>
              <li> Lars says they did all the spells....Avlod is like sure </li>
              <li> Avlod says honor...walks with Lars....says he is honorable and skilled warrior....if he needs work to come join him. His lands might be restrictive but he needs an executioner. </li>
              <li> Lars headbutts him in the Dwarven fashion. He is like ok that is fine. Then hands him 50 Platinum </li>
              <li> That crew sprints to catch up and rolls decent so they catch the others </li>
              <li> Sparka and Cecil see them...We gave you one job </li>
              <li> Lars hands over the platinum as they can get in the boat </li>
              <li> Lars says he gave him a retirement job..Sparka...notices </li>
              <li> Lars, Corpo, Sass hears the birds stop as they get in the boats.... </li>
              <li> Sparka is like GET IN THE BOATS! Leads them to get in </li>
              <li> Lars tightens butchers bip and grips axe </li>
              <li> Sparka says get in, and stands on the shore..... </li>
              <li> Sparka sees the Dreadflame get out of the woods...casts Wall of Fire </li>
              <li> Dreadflame is drinking milk. </li>
              <li> Corpo notices that his armor is different....red armor and black trimming with a gold crown and pint of milk </li>
              <li> They each get in a different boat to spread out everyone....AND THEY ROW </li>
              <li> NAT 1 Corpo Athletics check to row </li>
              <li> Sparka and Corpo are not to far away...they see Dreadflame casually walk through the fire taking 34 damage </li>
              <li> Sparka is like damn he is a lot stronger than his brother </li>
              <li> Lars is like hey sober one...want a drink of my never ending decanter? You probably prefer water </li>
              <li> He is about to dispell magic but yells "Brother milk is better" since he is out of range....Adam saves with rule question...Lars just chugs the drink </li>
              <li> Sass shoots at Dreadflame....24 does not hit MAKE CLIP 52:20 Blocks with his shield hits on other shot to do 30 damage </li>
              <li> Corpo fails an Int save for Synaptic Static 32...now has muddled thoughts for a minute </li>
              <li> Corpo and Spark row to about 90 feet </li>
              <li> Lars is taunting and dancing  </li>
              <li> Cecil goes a little further....but doesn't want to out pace them </li>
              <li> Corpo doesn't break the mind muddling </li>
              <li> Sparka casts Fireball at 4th level...he burns a legendary resistance </li>
              <li> He takes a sip of milk from his never ending flask of milk....casts Eldritch Blast with grasp to rip him out of the water </li>
              <li> Hits him twice and Corpo DOWN as he flies out of the boat into the water </li>
              <li> Corpo has a feeble sleeping girl in his boat... </li>
              <li> Sparka yells Corpo down he isn't breathing...and...it's bad this time </li>
              <li> Sass shoots him again and hits and does a ton of damage cause of course he fucking does </li>
              <li> Cecil casts mirror image and then goes cloak of the Manta Ray and dives into the water "for some fucking reason" to retrieve and unconscious Corpo. </li>
              <li> Lars: takes his dick out and puts it in the water to show his brother.  </li>
              <li> DF: taunts him that he will finish the job he started for him and all the lecherous fools of Grimstone </li>
              <li> Lars: Did you destroy Grimstone? Bad insight, can't tell </li>
              <li> DF: Coward! </li>
              <li> Lars: Meet us on the north side ( he is going to the north side) </li>
              <li> DF: ...you are going...south? </li>
              <li> Lars: using currents to slingshot around the island...you wouldn't understand! </li>
              <li> DF: You are as dumb as our parents and stupid as Grandfather </li>
              <li> Lars: You have the constitution of a little girl...that's why you left the Dwarven community...you couldn't handle it </li>
              <li> Sparka: MILK DRINKER! </li>
              <li> DF: I am more than you! </li>
              <li> Lars: a little person? </li>
              <li> Meanwhile Cecil tells unconscious Corpo that he owes him a life debt </li>
              <li> Lars has been turning his life around </li>
              <li> DF: I have seen hell.... </li>
              <li> Lars: I have to, it's bad! </li>
              <li> DF: I will take you to the Abyss....it's better there. I'll drag you down </li>
              <li> Lars: 0 chance that is better </li>
              <li> Sparka Fireballs him while he is being a prissy little bitch </li>
              <li> Lars very dramatically turns his back on him </li>
              <li> DF says see you soon brother...you are weak </li>
              <li> Lars does two double birds up in the air </li>
              <li> Corpo's boat was left....they figure it out </li>
              <li> DF is like you ruined my plan... </li>
              <li> NAT 20 Cecil Arcana to recognize the Blackrazor  </li>
              <li> DF walks back trough the damage </li>
              <li> Cecil brings Corpo to his boat...doesn't want to heal him to "deal with that" just stabilize him </li>
              <li> NAT 1 Cecil Medicine check to stabilize Corpo </li>
              <li> Corpo fails a death save....Cecil eventually gives in and uses a spell slot </li>
              <li> Sparka vortex warps the woman left in Corpo's old boat into...not his...someone else's to deal with it. </li>
              <li> Water leaks into my house because I tried to kill Corpo.... I have to caulk it and Corpo "I can help small gets into small places" </li>
              <li> Corpo has subconscious attraction to manta rays and when he sees one will get noticeably aroused. MAKE CLIP 1:13:35 </li>
              <li> Sparka is...thinking in the back...none else notice... </li>
              <li> The girls woke up...a little more calmed down </li>
              <li> FLASHED BACK TO HERE:  </li>
              <ul>
                <li> Sparka wakes Kulvesa up nicely...tells her...we are going to get you back soon...I know you have been through a lot.  </li>
                <li> Sparka: Mavol... </li>
                <li> Kulvesa: I am glad you gave him an unclean death </li>
                <li> Sparka: ...uh yeah.... were you close with his parents? </li>
                <li> Kulvesa: Sorta, they were nice people... </li>
                <li> Sparka: I assume they had no idea? </li>
                <li> Kulvesa: I assume or I would kill them too </li>
                <li> Sparka: In this case...maybe we should keep it this way... </li>
                <li> NAT 20 Sparka Persuasion to convince Kulvesa to not tell them. Keep that he was going to start a war for no reason unknown. </li>
                <li> Kulvesa: There is no harm in this lie, only gain. She nods </li>
                <li> Kulvesa: He was such a dick... </li>
                <li> Sparka: AMEN Yes he was...I will know that til the day I die. Believe me...I burned his head to a crisp... </li>
              </ul>
              <li> Lars: "Southern bank we have brought your women back!" </li>
              <li> The people are coming out...were reserved but letting shit out...hugging...freaking out. </li>
              <li> Lars is high five-ing and giving drinks...Corpo is limping </li>
              <li> Mavol's father walks up to Sparka...Sparka is like how do I tell this guy that his son was an incel who listened to Joe Rogan. FLASHBACK ABOVE DONE Kulvesa takes him away </li>
              <li> They see three moons in the sky </li>
              <li> Lars goes to the tavern. Corpo goes with him...dressed in black and soaking wet and practically dead </li>
              <li> Cecil goes to confirm that the town is not going to blood feud with north bank </li>
              <li> Lars tried to sneak some coin out of the Platinum Bag...Sparka feels the bag and can tell there was one piece less....BUT LET THE RECORD SHOW I FUCKING KNEW </li>
              <li> Lars throws down Platinum piece in the tavern...says drinks til this is gone. Bar keep is like don't know if I have the drink... </li>
              <li> Bar keep is like I thought you were weirdo for hanging out with that weirdo...but....turns out you are so cool you have to hang out with a weirdo to bring yourself down to his level </li>
              <li> The town loves them </li>
              <li> 3AM...Corpo goes to look for Doctor, Chiropractor, and Psychiatrist </li>
              <li> Bar keep gives them four free rooms </li>
              <li> Sparka goes up early </li>
              <li> Corpo goes up early </li>
              <li> Cecil goes up early </li>
              <li> Sass goes up early </li>
              <li> Lars does not </li>
              <li> Corpo tells know one but he had an orgasmic experience near death. MAKE CLIP 1:26:00 </li>
              <li> Corpo falls asleep and see the Raven Queen...he is like back again...she is like that's not how this usually..ok </li>
              <li> Sass mumbles in his sleep...if the Raven Queen is sitting on Corpo's face </li>
              <li> She says that he served well and has a chance to be a champion....but for now he must serve </li>
              <li> ---Audio cuts out--- </li>
              <li> Sparka sees in dream that Black Storm is wearing the Dragon Shard around her neck with the storm blood glowing like it is dominant...knows Black Storm came back home with his mother to the funeral...knows they were fighting and wonders if it was about the time looping </li>
              <li> Lars encourages contests in the bar in the name of KORD! </li>
              <li> Lars was gonna party all night but passes out in another hour at the bar </li>
              <li> Corpo goes to Cecil's door....Cecil locks his damn door, immovable rod..Corpo humps the door knowing that there is a manta ray inside...I THOUGHT IT WAS GOING TO BE SUBCONSCIOUS?!?!?!? MAKE CLIP 3:55 </li>
              <li> Corpo looks at his tattoo and that it is no longer a dolphin...but a manta ray....he is self aroused? But he calms it down and sleeps on the ground </li>
              <li> The 3 normal people wake up at normal hours...left the others sleep...Cecil steps over Corpo carefully </li>
              <li> LONG REST </li>
              <li> Corpo's short bow wakes him in the dawn "Father's must always be there for their sons...Haalinith I love you my son..." cries back to sleep </li>
              <li> Continent Breakfast....gives them beans and weiners </li>
              <li> They wave...good bye Phandalin 5! They gave them free food...Sparka is like...money....? They pass hat around and get 11 gold </li>
              <li> Lars PRAISE KORD and they grab on and are back in the Temple of Kord </li>
              <li> Brother Paul is preaching that we must restrain competition...LARS yells BULLSHIT CHARGES AND RUSHES AND PINS HIM </li>
              <li> COMPETITION IS WHAT WE NEED! IT'S WHAT THE PLANTS CRAVE </li>
              <li> They see the old Lizardfolk woman who had been cleaning...covered in gold chains... "Competition...is good" snaps fingers and little children carry her on a gold palanquin </li>
              <li> SHE IS THE PRIESTESS NOW then throws gold and the children fight ...She starts preaching </li>
              <li> Ole is like....WAIT WTF LARS WE ARE HOME. Lars calms him down..sends to see his gpa </li>
              <li> The go to the general. GDE is standing by. He smiles and nods. He is like a proud uncle situation </li>
              <li> I assume you were successful they are like YES! They make fun of Sass for being sleepy. </li>
              <li> Election in 2 days, negotiations in 4 days, small business meeting in 6 days  </li>
              <li> Dorrissa comes out...she is like successful? War? No? Good! </li>
              <ul>
                <li> She is like ah that is faster than I expected </li>
                <li> Lars: that is what they usually say with Corpo </li>
                <li> Dor: Is that what you say? </li>
                <li> Lars: I do </li>
                <li> Corpo: Some might take it as a compliment </li>
                <li> Dor: I don't </li>
                <li> GDE shakes his head </li>
              </ul>
              <li> Nothing weird in Phandalin...no shroomers </li>
              <li> The missing people job is done </li>
              <li> They decide to go get the rouge Barbarian Prince </li>
              <li> She tells them...been trying to track the cult...looks at Corpo....she thinks.... not long and hard but short and limpy </li>
              <li> She reveals the cult is treated by the Pentarchy as a curiosity....trying to sort out their meaning </li>
              <li> The cult is working with a Younger Adult Dragon that went to the Fire Plane </li>
              <li> The Efreeti are on verge of conquering the Air Plane....not sure </li>
              <li> The Empire is discussing what support to send...but they can help this group get there </li>
              <li> The current one rallying the Air Plane is...Haalinith </li>
              <li> CORPO LIKE DAMN MY BROTHER...let him die.... </li>
              <li> Cecil is like please ignore Corpo </li>
              <li> They get their 600 per person </li>
              <li> Cecil asks what is bigger deal....Dorrissa is like no clue on the Air Plane...the rogue prince is clear cut if you deal it will be happy if you win </li>
              <li> Dorrissa says trying to put decent force together to send to the Plane of Air....could pay decently </li>
              <li> Dorrissa says the lyrics to All Star </li>
              <li> "Dangerous and longer is practically our middle names" says Corpo </li>
              <li> "I was psychically attacked recently and I like trains now....I AM AN asshole" says Corpo </li>
              <li> Sparka asks how much $$$ for the Prince Job....she says at least 300 gp a piece...possibly more but that would be someone up top paying more... </li>
              <li> Like killing pays well, turning to side pays better... </li>
              <li> The battle is going to be insane...two major planes against each other </li>
              <li> Corpo is fine if bro dies so he is the favorite one </li>
              <li> Dorrissa is like we can teleport you there </li>
              <li> Wind Duke Haalinith is a goliath-genasi MAKE CLIP 16:00 </li>
              <li> Corpo is scratching things into a tablet...to not be celibate forever... DORRISSA LAUGHS </li>
              <li> They accept the job...Sparka is like the prince job should get them to level 9 </li>
              <li> What's a prince job? If you have to ask, you can't afford it </li>
              <li> They go to meet with Arixus  </li>
              <li> Corpo is sassing Sass...Sass is like hey didn't you die and need to get saved by a Manta Ray </li>
              <li> They meet Arixus...has a device that glows around one...is always glowing near the Phandalin refugees...weird must be broken. (They are like oh wait it's Elmar Brathen) </li>
              <li> Arixus hands it over to an assistant to go find it (Elmar) </li>
              <li> The party is like let's go grab Elmar.... </li>
              <li> Cecil knows the crystals can be spell components...alter reality around someone...part of reversing time...can use crystals to force dreams of older timelines </li>
              <li> "What am I looking at?" -Adam   "Autism" - Ben </li>
              <li> Cecil asks why Arxius is looking for the crystals....Arxius is like they are powerful and valuable.... </li>
              <li> Lars is like why don't I tell Elmar Thistle needs it </li>
              <li> They decide they are more valuable to the Phandalin-ites than The EMPIRE OF BLOOD people they don't fully know </li>
              <li> They track down Elmar with high Investigation rolls </li>
              <ul>
                <li> Lars: We here for your crystal... </li>
                <li> Elmar: What? </li>
                <li> Lars: You know... </li>
                <li> Sparka: ...no he doesn't... </li>
                <li> Elmar: My kids made this in art class... </li>
                <li> Lars: The Empire knows you have it.... </li>
                <li> Elmar: Whats the big deal the kids made it... </li>
                <li> NAT 20 Corpo Persuasion gives him a super knowing glance...which...is...so knowing </li>
                <li> Corpo: We know they didn't make it in art class... </li>
                <li> Elmar and Sparka: ...they did.... </li>
                <li> Sparka: ....in art class... </li>
                <li> Corpo: Can we hold it for safe keeping? </li>
                <li> Elmar: ...no...Thistle made it for safe keeping </li>
                <li> Lars goes to get Thistle to help...he convinces her to get the crystal from her dad.... </li>
                <li> The crystal looks fragile like a spell...it could be broken </li>
                <li> Corpo rolls to cup Elmar's balls </li>
                <li> Lars tells Thistle and Elmar he is going to return the crystal when he asks for his daughter's hand....Elmar is like I am going to need that back now </li>
                <li> Sparka Mage hands it to take it away </li>
                <li> He is freaking out and Corpo is about to kill him </li>
                <li> Sparka casts Sleep on him to calm him down </li>
                <li> Thistle is like WTF </li>
                <li> Sparka asks Thistle if she remembers...she is like uhhhh....maybe....Sparka convinces her to not say anything to the empire.  </li>
                <li> She says fine if Lars holds it. Lars hides it in his bib...hopefully it doesn't break.  </li>
                <li> Sparka rouses Elmar and convinces him a random person stole it </li>
                <li> Thistle is like I trust you short man and winks at Lars </li>
              </ul>
              <li> They go to Arxius...he is like I can get into Rengroth but that has higher risk of mishap going somewhere else...or 6 hours away 3/4 perfect safe transport </li>
              <li> Lars has to wait til dawn to get teleportation back </li>
              <li> Team YOLO wants to risk it....Team Responsible be safer....Sass is tie breaker and goes for team responsible </li>
              <li> They teleport fine there to 6 hours away. </li>
              <li> Corpo the mofo... </li>
              <ul>
                <li> Ben: "I don't go in" </li>
                <li> Robert: "What?" </li>
                <li> Ben: "I don't go in!" </li>
                <li> DM: "Tell me EXACTLY what you do..." </li>
                <li> Robert: "...oh fuck...." </li>
                <li> Ben: "As they are all excitedly...ooooh risk adverse this is safe..." </li>
                <li> Adam: "...I am regretting saving you..." </li>
                <li> Ben: "as we feel the constriction he hops the back off" </li>
                <li> Robert: "READ THE WORDS OF TELEPORT...oh...a willing creature?" </li>
                <li> DM: "...so you are willing to teleport but to somewhere else?" </li>
                <li> Ben: "Yes" </li>
                <li> Corpo gets a dirty 22 Acrobatics check....as he tries backflipping out of the way. It is the DnD wild west as they teleport in the middle of the street </li>
                <li> They don't see Corpo...as he sees clouds....he is 60 foot in the air as he uses Feather Fall as he falls </li>
              </ul>
              <li> Arxius is like welp I should go sleep...Cecil is like why don't you come with us? And he is like that's a great idea </li>
              <li> CRYSTAL The crew thinks that if they break the crystal whatever spell would be broken? Lars is smart </li>
              <li> Sparka recalls that Elmar had scars all over his face in his dream of the alternate timeline </li>
            </ul>
          </Collapsible>

          <Collapsible
            title="The Barbmitzvah (#28 - 8/19)"
            toggle={this.collapseToggle.bind(this)}
            kind="session28"
            show={this.state.show}
          >
            <ul>
              <li> Mike resists drinking bourbon but then agrees to do just a sip and/or the tip</li>
              <li> Jokes about Smash Mouth</li>
              <li> Reminder the Reality Crystals are breakable</li>
              <li> Corpo lands from his dumb featherfall</li>
              <li> Bring Arixus</li>
              <li> Lars needs past dawn to recharge his ax</li>
              <li> 6 hours from Rengroth...just after noon so get there at like 7</li>
              <li> Cecil retcons buying diamonds from Jawal a very Fancy Elf</li>
              <li> Sparka retcons buying poison</li>
              <li> Something about German and Spanish</li>
              <li> "What are you guys doing" me "Each other" Robert</li>
              <li> Off to Rengroth...walking on foot. Arixus gives the details on the town. </li>
              <li> Explains the town has less fighting...no full scale war. The Waukeenar help influence that</li>
              <li> He has a map...the town is pure chaos...no city planners. A large drum tower where the exiled prince has been living</li>
              <li> Cecil asks Arixus about the Library of Ioun...Arixus says only special people have access but others can get in with an escort...people...looks at Corpo...do weird things with books</li>
              <li> Lars mutters...pages stick together</li>
              <li> Cecil says he would love to go see they will have him deal with it</li>
              <li> I can't hear for a minute cause Robert ben and Ryan are talking about people living in libraries as "real Corpos"</li>
              <li> Random Encounter checks are all nothing. See some caravans...</li>
              <li> They are whistling Rain Drops keep falling on my head</li>
              <li> Cecil asks Arixus about the blood...the ancestry not about murdering. In GENERAL Cecil says it is more about the ancestry than the murdering</li>
              <li> Arixus does say the Onyx Conclave is Lawful Evil and more into murdering and conquering...Platinum Phalanx is more law and mercy...and other groups. Lawful neutral.</li>
              <li> Corpo asks about the Sandstone Sphincters (I roll a d20 to see if they are real...NAT 20...they are real f-sake Ben) Arixus stops and is like........how do you know about them...Corpo casts a knowing glance</li>
              <li> Lars asks if they will be weirded out with these weird mofos. "Points to eye patch Barney...I LOVE YOU!"</li>
              <li> Arixus says plenty of mercs come. Krateo Dragonborn might challenge to arm wrestling</li>
              <li> Mention of people watching ONE PIECE</li>
              <li> They remember they don't have to kill...just remove him from the board. Corpo doesn't want to kidnap...wants to kill....uhhhh</li>
              <li> Sparka is like kidnapping him to publicly join the Empire be good. Why not already join the Empire? Arixus is like he didn't want to submit to the rules. The Empire would have moved him around....</li>
              <li> Lars is like I am sure the Empire of BLOOD is likely to give semi-autonomy...sure sure the ancestry..</li>
              <li> Prince's name is Phelona....probably should have asked already</li>
              <li> Cecil asks what Arixus is up to...so...what you up to? Oh I am having a blast just walking around! He is EXCITED!</li>
              <li> We need a weekend to watch Lilo and Stitch and Ryan's midget movie</li>
              <li> 3 Margaritas and Ben will open Mike's legs</li>
              <li> Corpo can't wait for the deep backstory about the Sandstone Sphincter's</li>
              <li> Wait to cook....Sparka and Corpo cook off</li>
              <ul>
                <li> Corpo prepares an extra planar stew with mushrooms ROLLS a 16</li>
                <li> Sparka uses Spaghetti and Meatballs Prestidigitation ROLLS a 18</li>
                <li> Arixius thinks Sparka's food smells great</li>
                <li> The mushrooms don't hit Corpo</li>
              </ul>
              <li> Cecil asks Corpo why he was stuck in the air....cold feet...tried to pull out...but here I am now...couldn't get it up now...snap back to reality. Cold feet about Barbarian lands....Cecil can't really read if he is lying to himself or you</li>
              <li> Arixius is like THIS IS EXCITING</li>
              <li> They reach the edge of town</li>
              <li> Ben reeeallllly tries to split the party</li>
              <li> Ben wants a DM description of Barbarians...DM is like there are all kinds...Ryan is like we don't all look a like... Robert: "Tell me what Fire fighters look like" Ryan: "Mustaches"</li>
              <li> Generally Humans....some Centaurs, Orcs, Dragonborn...generally look like leathers and such. A lot of people look like the big dude from Brave.</li>
              <li> Not many shields</li>
              <li> They enter town only knowing that the prince has been staying in the big Tower</li>
              <li> Robert wakes up Mike and they offer him a bump of coke</li>
              <li> They go to a Tavern...Sass looks for a tavern that serves common ales but also a house brew. He only finds a tavern with a garbage house ale. Too awash in smells</li>
              <li> Lars orders two ales...decent crowd playing danger darts. Mix of genders and race</li>
              <li> Lars hands the biggest baddest guy and cheers without matching eyes.</li>
              <li> Says his name is Korg Ironjaw...asks if he knows why...Lars is like no...he is like I DONT KNOW WAS HOPING YOU DID!</li>
              <li> Korg downs the ale and breaks the tavern over his head</li>
              <li> I AM THE DRAGON! lars says...but in fairness he uses that more than his name.</li>
              <li> Corpo gives the name Beauregard Lightningfirst</li>
              <li> He catches the lie and says I love a stage name...called myself jaw of steel</li>
              <li> Sparka says his name is Elmar...and Korg says he likes the cut of his jib.</li>
              <li> Cecil says his own name...Korg is like what is your trade? </li>
              <li> Cecil: "I have a lute on my back I am a bard"</li>
              <li> Korg didn't want to assume anything...walk up to a woman dressed proactively...turns out it is your mother! Don't assume!</li>
              <li> Cecil is like Kord? No no a g...Korg is human not made of rocks</li>
              <li> Corpo asks why he comes here.</li>
              <li> Korg: I mercenary myself...then come back and drink away my money</li>
              <li> Lars is like I lived that way for hundred years then I found...Kord</li>
              <li> They scream and arm wrestle yelling COMPETITION Korg wins</li>
              <li> Corpo buys shots. Korg talks while companions</li>
              <li> He asks in town for work, pleasure...looks at corpo...weird shit?</li>
              <li> Lars says pleasure. What kind of pleasure? THE CARNAL KIND</li>
              <li> Lars is like hear fantastic things of the local prince...Fealona...he asks. He says don't say FiFi he hates that shit. Sass jokes he FEALONAS on people....I said that once and got hoofed on the head</li>
              <li> Lars asks if he lets them ride them </li>
              <li> Not publically at least...but...you got to wonder....</li>
              <li> Sass LITERALLY HUNG LIKE A HORSE</li>
              <li> Lars is like I am too. Sass he will show you if you ask. Cecil sometimes if you don't!</li>
              <li> Korg is into seeing it apparently??</li>
              <li> He hates Genasi....ALL GENASI...since he lied about his name</li>
              <li> Cecil asks about the prince's father. He is like yep father was pirate king and got destroyed</li>
              <li> He is like why you interested? The cut of his jib is someone with more gold in his pockets...so not talking til Corpo buys more shots. Corpo does is.</li>
              <li> He doesn't get metaphors. The night is so young it has a diaper</li>
              <li> Sparka "jokes" to kidnap the prince</li>
              <li> He is like...I don't like it</li>
              <li> He has no idea who the other guys are...no loyal people</li>
              <li> Lars asks how the town likes the price....but doesn't get anywhere</li>
              <li> Cecil looks around...sees someone look over...when they talk about the prince.</li>
              <li> PEOPLE HEARD THEM JOKE ABOUT KIDNAPPING THE PRINCE</li>
              <li> Sparka notes a half-orc was interested in kidnapping the prince..but disappointed </li>
              <li> Sass tries to open his beer with his floating horny.</li>
              <li> Sass says he calls himself the horny float.</li>
              <li> He has a punch card from the brothel. He give Sass a completed punchcard. </li>
              <li> Corpo flirts....Korg doesn't take it...Corpo is used to rejection.</li>
              <li> A bard gets on stage and SUCKS. Cecil is like...ehhh</li>
              <li> Sparka is like what's the point of the prince...he isn't in charge....but Korg is like he does have the gold so he makes the rules.</li>
              <li> Sass quotes the ChristEYEna Aguila to rub the lamp the right way.</li>
              <li> Korg cries since his love loved ChristEYEna and was an Air Genasi (why he hates Corpo)  and she broke his heart.</li>
              <li> Corpo is like is that when you became gay? YES</li>
              <li> Corpo buys more drinks.</li>
              <li> Sparka like he got the money? Father? Sparka asks about coming out of the tower</li>
              <li> Korg is like one of the elite tribal leaders is 13 and coming of age...BARBmitzvah  </li>
              <li> Read from the sacred texts of Kord...the cardboard picture books</li>
              <li> Korg knows the prince is invited and setting it up</li>
              <li> It's a good reception hall...but not that big of a deal</li>
              <li> Cecil is like...how that leader feel about the prince....</li>
              <li> Korg is like I know everyone...they don't like me...but word on the street is that the leader invited him cause he is rich and would but a lot of gold in a card....but....might be time to move on</li>
              <li> They wear black and kill someone and wear the skull as a skull cap</li>
              <li> Cecil and Sparka notice the half-orc listening....Corpo notices cause can't stop rolling</li>
              <li> Sparka goes to talk...he reaclls the name Elmar. Asks about the Empire. Business is booming...little sore about the pirate kingdom...but business is booming! </li>
              <li> He throws a silver to show Sparka he gets economic theory and clunks someone on the head</li>
              <li> Empire agreed to not fight if they worshipped Kord and not devils and BOOM all is good. Empire doesn't want these plains and we get $$$.</li>
              <li> PRAISE BE KORD. Lars talks about the Striations on his thighs</li>
              <li> He is like we can make drink, love, fight, rock on head</li>
              <li> Clarify that the Prince is trying to get support from leading tribal allies to take the Empire back</li>
              <li> Cecil notes the Half-Orc grimaces when talk of taking the city back</li>
              <li> Korg is like...CAN YOU IMAGINE HAVING 10 GOLD AT ONCE! Sparka gives him 6. His mind melts</li>
              <li> Corpo orders 8 shots and orders sparklers for some reason</li>
              <li> Lars can understand Barbarian trade language due to his axe</li>
              <li> Cecil and Sparka are like hey check that half-orc</li>
              <li> Corpo brings shots to the half-orc's table...let our fun spill over</li>
              <li> Ben can suck a golf ball through a garden hose. Korg feints</li>
              <li> Wolfslayer is invited to their table....Corpo says he is Beauregard Lightningfist</li>
              <li> Corpo starts abusing the staff of storm and lightning to jolt Wolfslayer. He is like...uhhh</li>
              <li> Corpo goes take it from here</li>
              <li> Cecil is like WTF I didn't want that attention.</li>
              <li> Wolfslayer is like your friends seem in the clouds (out smoking)</li>
              <li> Cecil is like I see you were interested in our conversation about the prince....</li>
              <li> He is like I heard your conversation...says his father is the cheiftain of the Storm Orcs would NOT like that. They made deal with the Empire...Cecil is you don't like the guy</li>
              <li> Does the sign of the lightning with Lars</li>
              <li> Cecil and Lars are like...so.....if we are getting rid of the prince....</li>
              <li> He is like we would like that as long as...nothing big comes to bite us...no sacred rules broken</li>
              <li> They know who is daddy is...he can't be involved.</li>
              <li> Says the Storm Orcs think more than the other Barbarians.</li>
              <li> Hands some Jerky to Lars and Cecil</li>
              <li> The Storm Orcs would be pleased if the prince...went away</li>
              <li> Cecil pries...Wolfslayer says the seafaring pirate barbs might be loyal...Cecil rightly points out pretty far inland...he is like the usual people despise that.</li>
              <li> Korg is punching self in the jaw saying "IF IT IS IRON WHY DOES IT HURT?? WHY DID THEY CALL ME THAT???"</li>
              <li> Lars asks about the Barbmitzvah...he gives him two tickets...says a lot of people are there. Embarrass him in front of everyone that would END the shit.</li>
              <li> Wolfslayer has not ended him so far...cause he pays...takes jobs for him...but take the money for fun despite hating him. But fine if he goes away....</li>
              <li> Cecil asks who all he has tried to bring over. Wolfslayer says he has invited like dozen of warlords. Even invited his uncle to try and ply him to overthrow his bro</li>
              <li> Says Uncle and Father are tight...headbutted tables together</li>
              <li> Lars is like you only do that if you are close!</li>
              <li> The side convo (smokers) return</li>
              <li> Cecil is like...they need a band...</li>
              <li> Wolfslayer says he heard that the hired band might becoming through the Western Gate (cue jokes)</li>
              <li> Corpo can do a chair dance</li>
              <li> The Prince was supposed to hire a band...Cecil clarifies that it is an exotic band</li>
              <li> The Tribe leader...Fulfar Stormrider who knows the Prince hires the band.</li>
              <li> Cecil is like so if band doesn't arrive it will embarrass the Prince</li>
              <li> Sick Will Klein Meme discussion</li>
              <li> Lars finds his finest black garb</li>
              <li> Ben goes off on smoked salmon</li>
              <li> They head quickly to the western gate</li>
              <li> They find a classically trained string quartet Elvish being kicked in the balls by barbarian children</li>
              <li> Corpo goes to hail them as Elvish friends in Common and he looks away</li>
              <li> Sparka talks to them in the true tongue...they say if you slap him we will be happy.</li>
              <li> Cecil doesn't wait...he just slaps him right away</li>
              <li> Sparka notes them Moon Elf...they say to the Moon to Sparka...Silver in your Eye...Silver in your Pants...and the Sun/Gold version to Sparka</li>
              <li> They are like we were hired to pay ahead of time...supposed to come for a party to read a cardboard book</li>
              <li> Sparka and Cecil are like you are week early...you'll have stay a week...23...</li>
              <li> The string quartet panics...STAY HERE FOR A WEEK!</li>
              <li> Corpo asks his name...Sparka/Cecil say ignore him</li>
              <li> He says saw a group of the blue once...bang drums well</li>
              <li> He tells Sparka to in Draconic tell Sass he appreciates him and hopes he finds true love. He assumes most women have not found him attractive with his smooth skin and show muscles</li>
              <li> Lars, Corpo, and Sass get more Barbarian to scare them away</li>
              <ul>
                <li> Lars wipes blood from his apron and it keeps coming back with the castrated balls hanging from his belt</li>
                <li> Corpo plays pocket pool saying "I like trains in Elvish"</li>
                <li> Sass walks to a bush and takes a shit...then starts grunting yelling in Draconic "COME OUT!!! COME OUT!!!"</li>
                <li> He uses Thamaturgy to make it sound more.</li>
              </ul>
              <li> Cecil tells them this is a tame day</li>
              <li> Cecil and Sparka clock that the head of the quartet shits himself</li>
              <li> Sparka is like see you in a week! Cecil is like we won't tell the Prince you arrived early</li>
              <li> The drop their tea kettle and it breaks.</li>
              <li> Cecil pays them to make sure they never come back. He gives him 40 gold and he gets the hell out of here</li>
              <li> They clock that the guy takes the gold and will never return</li>
              <li> Band scared away...successfully</li>
              <li> Cecil takes their name. The Leaves of the Eldertree Falling on the Grace</li>
              <li> "I just want to reiterate, they are gone" I say</li>
              <li> Maybe will see when go to see Mother's family</li>
              <li> Expecting a String Quartet</li>
              <li> Mike plays a sound effect called "Chewbacca Fart"</li>
              <li> Sparka says lets be a vuvuzela band</li>
              <li> Cecil is like let's get our band name to be super offensive</li>
              <li> I get called a "Denier"</li>
              <li> Ryan rips farts and I can't hear what Adam is talking about</li>
              <li> Cecil Flashback to ask Wolfslayer any gossip about the Tribal leader</li>
              <ul>
                <li> Chieftain married a younger woman...rumor that he had the husband killed</li>
                <li> Also finds out a horse once fell on him...so they called him horse fucker of course</li>
              </ul>
              <li> They figure out two tickets and 4 band slots</li>
              <li> What is worse....no band or more offensive band that does a terrible announcement like committing a song to "Horse fucker and the Cradle Robbers" They decide that is the deal </li>
              <li> They decide to bring Korg and Arixus in on it...Arixus can be the roadie and Korg can be the spokesman.</li>
              <li> Arixus is impressed with Sass' pooping...Sass says he has eaten like 4 bowls of raisin bran</li>
              <li> They plan on the band being Korg, Corpo, Cecil, and Sass...Arixus is the tech guy</li>
              <li> Sparka and Lars going into the crowd...Lars is going to be in the audience and embarrass the prince....Spraka going to enjoy</li>
              <li> Corpo notices the smash-nora in celebration of Rage-ukah where Kord had nothing to smash for 8 days. See some skullcaps</li>
              <li> Robert decides we will be in the cabinet...I am the DM to the President</li>
              <li> Tough guy checks them at the door...Cecil only gets an 11...guard is going to check with the prince's guys but Cecil pushes like maybe I'll just leave....</li>
              <li> Korg...the random Irishy-pirate I made up is the lead man.</li>
              <li> Cecil gives out instruments....Pan Flute for Corpo...Shawm for Sass...Korg gets the lute...well a lute...he has no idea how to play it.</li>
              <li> Cecil is like how aware is Korg...he is having fun and probably won't remember. But he is in it</li>
              <li> Arixus is just like THIS IS AWESOME</li>
              <li> Cecil coaches Korg a bit...</li>
              <li> Lars sees an attractive legs for days Goliath. He can only see Legs...LEEEEGGGGSSS FOR DAYS</li>
              <li> NAT 20 Sparka Perception check to find the Prince in the crowd. Behind a roped off area with Centaur guard and another couple guards</li>
              <li> Prince is sipping ale...about to finish his glass. Nods to someone to get them a drink Sparka notices</li>
              <li> Sparka tails the person getting the drink...just to observe</li>
              <li> Lars tries crawling on the ground to sneak into the VIP section...is seen by Goliath gal</li>
              <li> The Goliath is like I will smush you with my heeeel. He is like let's get out of here...he lies but she goes with it. Hears her say, sure he is small but he wants me I DESERVE THIS</li>
              <li> Ben doesn't like himself</li>
              <li> Lars athletically rolls into the VIP section and tells the prince he should have some of this finest brandy</li>
              <li> OH MY KORD...praise Kord..is that what we are doing now? Takes a drink</li>
              <li> He says Kord is lit. Lars is like KORD IS NOT LIT. But his thighs!</li>
              <li> Lars says Barbarian sports include throwing rocks and wrestling</li>
              <li> Lars's Butcher's Bib bleeding everywhere...moon is full can't stop the flow</li>
              <li> The Prince gives Lars lobster and he just feasts</li>
              <li> Sparka hears the father tell the son it is his now his night to read the sacred cardboard book Kord goes smash. Sparka drunkenly yells I HEARD THE PRINCE BROUGHT THE ENTERTAINMENT</li>
              <li> I briefly let Ben take control of the son as he complains that he didn't get the car he wanted. The father slaps him and says you are a man now no complaining</li>
              <li> He opens the sacred BORA the cardboard book and starts to read.</li>
              <li> He starts reading "Kord Confuse Emotions" "Kord Fight Feelings" "Kord Punch Feelings" "Kord Crunch" "The word of Kord"</li>
              <li> Father says thank you my son you are a man and they cheer</li>
              <li> The band then will prepare a song to do a slow dance with his mother....well...his step-mother</li>
              <li> Korg steps up "Prince Filona! We are if there's grass on the field you can wrestle...our first song is watch out for that horse!"</li>
              <li> The Band plays</li>
              <li> NAT 20 Corpo Performance Check</li>
              <li> Sass doesn't do great with the Sham that he doesn't know how to play</li>
              <li> NAT 20 Korg Performance Check to rap Hot n Here</li>
              <li> Cecil notes Fulfar is pissed as hell</li>
              <li> Corpo is doing Sax Man with a Pan Flute</li>
              <li> Korg's dulcet tones turns on the Step Mom but also plays Lute awfully. </li>
              <li> She is pulling at her clothes and dancing dirty</li>
              <li> Lars notes that the Prince is furious...says OMK what I hired Elves</li>
              <li> Lars says I need to drink some more</li>
              <li> Sparka notes that Fulfar looks at the Prince but the Prince hasn't figured it out yet</li>
              <li> Fulfar is pissed</li>
              <li> Sass is shredding on the Shawm</li>
              <li> Sparka notes the Prince's guards are going into defense mode</li>
              <li> Lars notes a Female Centaur guard (he rolls for it)</li>
              <li> Fulfar is furious. </li>
              <li> The Prince is like what a weird band...where is my string quartet and starts putting it together about a song about a horse...</li>
              <li> Lars is like what's wrong about a joke about a horse</li>
              <li> The Prince gets a NAT 1 Con save and drunkenly prances about whinnying like a horse </li>
              <li> Corpo stops pan fluting to yell "if you want to fuck a foal, or a minor YOU DO YOU" and then points at Fulfar</li>
              <li> Sparka points at the Prince pretending to be a horse and is like CAN YOU BELIEVE WHAT HE SAID!?! You gonna take that?</li>
              <li> Fulfar YELLS TO ARMS and random people pull weapons</li>
              <li> Ben asks if she is mounting the stepson, I make him roll...NO NO NO NO NO SHE IS NOT</li>
              <li> Corpo catches the Step Mom slits the throat as everyone is throwing chairs and Hot N Here plays</li>
              <li> RED BARBMITZVAH</li>
              <li> The son's throat is slit and he is bleeding out kosher as his skullcap rattles to the ground</li>
              <li> Fulfar's couple dozen got great weapons</li>
              <li> Prince is like fight for me and I will buy you cats</li>
              <li> Lars looks at the Female Centaur and says I will pay you 1K gold to let me ride your female Centaur back</li>
              <li> NAT 20 Lars Persuasion to buy the female centaur. Riding the female centaur by holding on to her chest. "....master where are we galloping to" </li>
              <li> Cecil and Sparka note the murder</li>
              <li> Corpo jumps at the stepmom to subdue her...he doesn't touch the tattoo</li>
              <li> Cecil notes no one is pissed at the band. Barbarians 100% believe that the Prince did it...non noticed the murder...most simplistic solution</li>
              <li> Lars is riding her around and sees the murder</li>
              <li> Sass is just playing the SHWAMMMMMMMMMMM decently and a few women keep dancing. A dragonborn chick licks her lips at him. Sass is aroused</li>
              <li> Corpo pulls the staff out and does Thunderclap on the crowd...45 people die</li>
              <li> Corpo successfully does a sleight of hand to hide it...</li>
              <li> Sparka runs to the prince...OMK what is happening. Sparka is like I can help you get out. He buys it</li>
              <li> Sparka Vortex warps and then misty step and then runs. </li>
              <li> The Prince is not likeable</li>
              <li> The rest know that Corpo did it</li>
              <li> Fulfar has not figured it out yet...Lars goes to tell him on the Centaur...KORD'S WRATHFUL THIGHS UNLEASHED</li>
              <li> Corpo subudes the step mom with a high roll. DM says he hates COrpo</li>
              <li> Sass drops the shawm at seeing 45 dead people. He is enraged by the death of innocents. Saw the Step mom slit a throat. He is enraged by the injustice feeling platinum in his heart. He is struggling through his anxiety attack. Freezes up and has a panic attack. Sees in his mind Cloudchaser killing Jaezelle then duchess kill her. head bouncing like the dropped Shawm</li>
              <li> Robert says YOU WILL PAY FOR THIS BEN. Raven Queen not a fan of people dying before their times. Gonna be un-booned</li>
              <li> Ben admits not knowing it would kill people...Adam says that's why it is important to know what your character can do...also...we all know you didn't know</li>
              <li> Lars says CECIL SAVE THE BOY and jumps off the Centaur to catch Step Mom </li>
              <li> NAT 20 Athletics to leap off headfirst at the step mom...she is surprised...with all the extra jumping power spear the shit out her and she get's non-lethal knocked. Flies through</li>
              <li> "To clarify, I like Ben" "We don't hate Ben. We fucking hate Corpo. We are done with Corpo. We love Ben. Fucking Hate Corpo. Corpo sucks dick, literally and metaphorically.</li>
              <li> 3 margaritas gonna</li>
              <li> Cecil Revivifies the slain son</li>
              <li> Ben tries to clarify that the barbarians are chaotic so by killing the 45 people he was actually killing baby Hitler and saved all of them</li>
              <li> Corpo arrives next to Lars as he subdues the mostly naked step mom</li>
              <li> Sparka slaps the Prince across the face. Ben asks with what... HAND</li>
              <li> Sparka is like listen to me, your life is in danger and your status in the city is coming to an end</li>
              <li> Sparka deception check to take the Essence of Ether and knocks him out. Ties him up and puts him in the bag</li>
              <li> Sparka casually walks out with the bag on. Checks if anyone noticed...NOPE people are super distracted cause of the whole murder. Sparka casts minor illusion to blur his face</li>
              <li> The Barb Lord is like why are you hands on my wife </li>
              <li> Lars "She slashed your son's throat"</li>
              <li> Corpo helps and is the only reason the lord buys it...ugh</li>
              <li> Fulfar orders his guards to take away the whore of a wife...says he knew she hated that wall papaer</li>
              <li> He tells Lars you will drink with me. Corpo walks away "I have done enough damage"</li>
              <li> Sparka is keeping track of air so prince doesn't die</li>
              <li> Sass is starting to calm down. Sass is alone on the stage</li>
              <li> Sparka walking back to the building</li>
              <li> Lars gives the decanter to the chief. </li>
              <li> Chief Fulfar: "Barb-fucking-mitzvahs"</li>
              <li> Lars: "Never know what's going to happen" </li>
              <li> Lars: "I remember my barbmitzvah...good times, some brawling, drinking, deaths...but not 57..."</li>
              <li> Cecil is helping the kid. Kid says I was turned on and then was murdered. Cecil gets a 26 to calm him down</li>
              <li> Lars is going to drink with him. Lars says Corpo is his man servant...Corpo sucks at rolling. He is like you are a true servant of Kord...but Corpo...he is false and has two guards drag him away.</li>
              <li> Cecil tells him that his son is alive but passed out.</li>
              <li> Fulfar thanks Cecil and says he gets a favor...also what to do with this blue fool?</li>
              <li> Ben tries a DM flag for this but everyone is like HELL YES</li>
              <li> Cecil says...I can't be certain but I believe his intent was to assist in the capture of your wife </li>
              <li> Robert yells "NOW'S OUR CHANCE!"</li>
              <li> Cecil shows mercy and Corpo is freed</li>
              <li> Fulfar is like I assume the gold digger song was a warning...</li>
              <li> Fulfar thanks them and lets them. </li>
              <li> Cecil asks if he will ever work with the prince again...says he will die before allowing the prince to do anything. </li>
              <li> Fulfar says terrible party, but my eyes are open..time to smash things...</li>
              <li> Corpo asks if women...he is like WTF no chairs...I respect women...</li>
              <li> Cecil is like I already used my favor</li>
              <li> Corpo backs off and eats some Chex Mix. </li>
              <li> Fulfar asks if he respects women...Corpo says he does. Fulfar doesn't buy it...says 24 hours to get of the city or he will wring his neck and shove head up his ass</li>
              <li> Fulfar says shots with Lars</li>
              <li> Cecil specifies his favor is over...if he fucks up it's over</li>
              <li> Adam does Cecil not Adam</li>
              <li> Sparka goes to talk to Cecil...Cecil says we did good...Chief and Prince are blood feud</li>
              <li> Decide to put Prince in a chair and say WE DID THIS, YOUR LIFE IS OVER, MAKE NO MOVE</li>
              <li> Arixus is like...whoa</li>
              <li> They all have to wait til dawn to teleport via Arixus or Lars</li>
              <li> Find place to crash...Sparka only tells Cecil he is the bag</li>
              <li> Sass and Lars drink with Fulfar and loves them. Say find me again</li>
              <li> Corpo is a little aghast he killed the people</li>
              <li> Sparka has not said a word to  Corpo</li>
              <li> Sparka asks Arixus about Imperial laws...and....ya...know....Corpo</li>
              <li> Arixus is like well the Empire is not going to crack down on Corpo...but...if you are in another house and your child misbehaves....YOUR deal with your child.</li>
              <li> Sparka tries to convince him to wait until after Elemental Air Plane...he is not totally convinced only a 11. Says his sister is most reasonable person in the entire inquisition</li>
              <li> Arxius says her sister is one of the good ones...we good to here and then no harsher Inquisitors come down on us. He says will debate telling sister after...is torn.</li>
              <li> Says sister doesn't love Corpo...Sparka says she isn't alone</li>
              <li> iLEVEL 9!</li>
            </ul>
          </Collapsible>

          <Collapsible
            title="The Trial of Corpo Fugit (#29 - 9/30)"
            toggle={this.collapseToggle.bind(this)}
            kind="session29"
            show={this.state.show}
          >
            <ul>
              <li>The silverbullet was a strip club at U of I...not Coors Light</li>
              <li>Lars and Sass pass out to get enough sleep</li>
              <li>Corpo has to get out of town in 24 hours by chieftain...doesn't know about the murders</li>
              <li>Corpo...all night wanders in a stupor thinking what he has done</li>
              <li>Corpo is told by Angel of RQ to go catacombs </li>
              <li>Unlike Ben...Corpo is not freaking out that a deity just injected themselves into his thoughts</li>
              <li>The Prince wakes up by Sparka, Arixus, and Cecil</li>
              <ul>
                <li>He is flailing tied up</li>
                <li>Sparka is like I saved you yes...and the town...FROM YOU. You served no function here</li>
                <li>Sparka:  He is like taking you back to the Imperial Court</li>
                <li>Prince: THEY WILL KILL ME</li>
                <li>Sparka: I don't THINK they will kill you....they just want you out of the picture </li>
                <li>Prince: THAT'S CALLED MURDER</li>
                <li>Sparka: You can try but you aren't going anywhere</li>
                <li>Arixus is like we got a lot of spell slots between us so chill</li>
              </ul>
              <li>The rest gather up...Corpo not there....</li>
              <li>Sparka: What was he like before us?</li>
              <li>DM NOTE He killed his mother...he brings it up a lot </li>
              <li>Lars axe recharges</li>
              <li>They debate Arixus teleport vs Axe...go Teleport</li>
              <li>Rainbow colors and they are back in the Imperial camp</li>
              <li>Arixus is like cool...I need to sleep I couldn't last night. Says sure sister will want to meet you...PEACE</li>
              <li>A raven comes and caws at Corpo...others notice....Corpo is like don't squak at me....Bird waits...Corpo is like FUCK</li>
              <li>Corpo I gotta go...got a thing with the bird...well...a different thing....</li>
              <li>Corpo follows the bird it takes him on a long walk like it is being a dick to slow him down</li>
              <li>Angel of the Raven Queen is waiting for him...Corpo don't know the name just he's from a different world in last generation or so</li>
              <li>Corpo asks if he is getting a weapon to attune to.</li>
              <li>Angel asks if he know why he is there.... "for the most part" "WHAT DO YOU MEAN FOR THE MOST PART"?</li>
              <li>Corpo plays the race card....?</li>
              <li>Corpo says "Your macabre-ness I take full responsibly for my actions at the Barb mitzvah" I detest my transgressions...</li>
              <li>Corpo asks if he gets Three Hail Marys? </li>
              <li>Corpo says he did realize the power here-in....and did not mean</li>
              <li>Corpo is like...your name? I am Exarch of Ravens?</li>
              <li>Corpo says he did not know it would kill...Exarch is like OH so you acted impulsively??? Corpo like yes but with care for my friends in my heart. </li>
              <li>Admits his friends would not believe.</li>
              <li>Corpo: I do act impulsively...but in my defense oh Exarch of Ravens it has worked out pretty good so far. I jumped out a window and landed on a Wyvern....but this is a low point for me</li>
              <li>EoR is like I was adventurer and did dumb shit</li>
              <li>Corpo asks him what he did...empathize with me bro....EoR is like I slayed dragons...killed gods...well one god</li>
              <li>Asked what he would have done differently...he would have tempered the emission from the staff so only mild concussion....</li>
              <li>EoR is like do you value Barb lives? Corpo says Barb Lives matter.</li>
              <li>EoR is like you can't limit it...but I appreciate your intelligence that you think so....</li>
              <li>Corpo feels the presence of the RQ...bows his head to her....</li>
              <li>Corpo asks for a phagellum and whips himself whispering daddy....he gets Dirty 20 stealth...but EoR has PP of 26....</li>
              <li>Corpo does damage to himself</li>
              <li>EoR says judges ask what you will do to show repentance...Corpo is like...only the good ones? NO</li>
              <li>THE TEARS OF THE WIDOWS ARE REAL! HOW MANY MUST DIE FOR YOU TO HAVE YOUR HAPPY LIFE?</li>
              <li>COrpo will be celibate...JK chaste (Robert...chased by women??)</li>
              <li>A judge snickers...</li>
              <li>Corpo offers to wear THE BELT and give the key to Lars</li>
              <li>EoR asks what he thinks of Lars....Corpo is like he is a being of exceptional prudence and good character.</li>
              <li>Judges laugh and EoR says they know...That is not helpful from Corpo</li>
              <li>Then he goes to Sparka holding the key....Describe Sparka...he's a good boy a good guy he likes people...sometimes he gets angry and fire shit happens and it gets weird so some therapy needs to be done</li>
              <li>JUDGEMENT TIME to to side chamber. </li>
              <li>Last thing he says to judges...is This was abominable what I did...I had no idea the strength of the weapon in my possession...and it is terrifying what happened to those poor Barbs and I want nothing but the best for the families going forward and will keep my penance</li>
              <li>EoR is like...ok judges....Lars drops hood and is like what the hell</li>
              <li>They debate the penance being good...taking the staff..chastity belt? Ball gag? Gimp mask?</li>
              <li>Sass and Cecil are like chastity belt is a start</li>
              <li>Sass has 0 interest in knowing more about his sex life...</li>
              <li>Cecil is like he didn't sleep with 45 people HE KILLED 45 people</li>
              <li>Cecil says fine no belt....we don't want to be in charge...</li>
              <li>Sparka: IMAGINE IF A MONK WOULD TAKE A VOW OF CHASTITY</li>
              <li>Cecil is like no forced chastity...he has to choose it. </li>
              <li>Cecil is like a test to show he contrition and act not impulsively ....he wants us to control him</li>
              <li>Sparka is like he can change the tattoo? Move it? Let him see it?</li>
              <li>Cecil how do we define Chastity? Sparka...you know it when you see it? It's a relatively low bar</li>
              <li>EoR is like...does is he actually good at it? A friend of mine talked a big game.... Sparka IT IS VERY UNCLEAR</li>
              <li>The leader of the clowns and the Ecstasy AND FIRE massage</li>
              <li>They will move the tattoo to the hand and make it an unsexy Raven</li>
              <li>Cecil asks the Raven Queen if she approves of the punishment....her voice says it is a start....we will see. They are judges because they are the only 4 who might mourn him...don't count Wildon who is a goth kid now</li>
              <li>It is acceptable...she will inform them if he breaks them</li>
              <li>...other worlds people are killing gods but here I am</li>
              <li>EoR is like ok peace may I not see you for a long time.</li>
              <li>Corpo is taken for judgement and claims to have smelled booze...EoR says no booze in the hallway</li>
              <li>Corpo is brought back in and gauge your seriousness. The chastity belt will be in your mind...a test if you can control yourself...brought back here if you do break it. Your lust is not an issue...the issue is not being able to control yourself. Stop and think before you act even if you only have a few brain cells...go forth and fuck no more</li>
              <li>Tattoo moves onto your hand of Raven jump over the sun...on both hand....the least sexual raven...it has an applesauce butt...beak is at an odd angle.</li>
              <li>Corpo Fugit you can leave....your macabre-ness and Corpo leaves</li>
              <li>They are waiting for Corpo looks down...Cecil asks if he wants a whore...he is like....ugh...no</li>
              <li>Lars tells the joke about Dragonborn, halfling, half-elf</li>
              <ul>
                <li>Dborn says he is the most yoked and gets it proven</li>
                <li>Half-elf says his ears is the most pointy...and gets it proven</li>
                <li>Halfling is like I got the tiniest dick...gets a letter and is like WHO THE FUCK  IS CORPO </li>
              </ul>
              <li>Random soldiers mock Corpo for having a little dick and being chaste</li>
              <li>Corpo is like I recommended chastity for myself....I will not be celibate I will get married to many women...Josephite marriage</li>
              <li>Feels like I am back at the monastery</li>
              <li>Cecil asked Corpo and he is like brought before the Tribunal of the Raven Queen</li>
              <li>Corpo is like you can stop me if I am....they are like hell no you on you</li>
              <li>Corpo lies about the Exarch being racist</li>
              <li>Lars is like did your tattoo get moved? SO UGLY Yes no self pleasure here</li>
              <li>"You should probably work on your self control" Lars says as he hits the flask</li>
              <li>We got Prince in a Bag</li>
              <li>Arixus returns to check on them, says Dorrissa is good to talk to them</li>
              <li>Qeline has an election banner raised up...as she is running unopposed</li>
              <li>"A vote for Qeline is a vote for pleasure"</li>
              <li>Tomorrow Election</li>
              <li>3 Days is the Imperial-Faerûn meeting</li>
              <li>6 Days small business council</li>
              <li>Debate leaving for Air Plane first or wait through meetings</li>
              <li>Go to Dorrissa...GDE posted against the wall...wait why you sad blue boy? Why so blue?</li>
              <li>Corpo is like....</li>
              <ul>
                <li>I CAN MAKE JOKES. </li>
                <li>Corpo: But I made a mistake</li>
                <li>GDE: So you messed up..what did you do?</li>
                <li>Corpo: Have you ever committed genocide? (Cecil asks and totally people heard)</li>
                <li>GDE: No</li>
                <li>Corpo: Good answer...don't do it</li>
                <li>GDE: My older man wisdom doesn't apply to war crimes</li>
              </ul>
              <li>We establish they brought Korg back...sleeps on a park bench.</li>
              <li>Dorrissa asks how it was...</li>
              <ul>
                <li>Sparka is like see for yourself...</li>
                <li>Dumps prince out...</li>
                <li>Lars drops pants and teabags him</li>
                <li>Dorrissa is like...that is very large...Corpo says it is called the spatula</li>
                <li>She is like...did you embarrass him? He agrees...</li>
                <li>No one will ever gather under his banner again</li>
                <li>300 gold...then call over the Legate</li>
                <li>Legate is like you found a threat to civility...dealt with him...and brought him before me.</li>
                <li>She snaps fingers and GDE gives 1K gold</li>
                <li>Legate will be part of the delegation...will put in a good word since they did good</li>
                <li>Lars is like...you should put in a good word for the election....for one of the...4 of you...</li>
                <li>She says they try not to interfere but Dorrissa can help as much as she can</li>
                <li>She says she will be leading the expeditionary force leaving in 6 days...merchant leaving in the morning</li>
                <li>Corpo is like we are a chaste escort</li>
                <li>She doesn't know what escort means in this case</li>
                <li>Good day and glad of your service to the Empire and Stability. </li>
                <li>Cheers to stability</li>
              </ul>
              <li>Cecil talks to Dorrissa about the library...asks who to talk to get in...she can do a word to get him in</li>
              <li>Group talks about who would be better than Qeline</li>
              <li>Sparka: "Someone we know who is level headed...steady"</li>
              <li>Lars: "Who the hell do we know like that?"</li>
              <li>Sparka is like...what about Elmar? Lars is like lets break this first</li>
              <li>Korg shows up and has the shakes...</li>
              <ul>
                <li>KORG FOR MAYOR? </li>
                <li>Wait...what is Phandalin?</li>
                <li>Sparka...uhhhh still workshopping it...Lars interrupts yes you are the major</li>
                <li>Korg: I've never been to that world</li>
                <li>Lars: Have you ever let a rugby team run train on you?</li>
                <li>Korg: A RUBGY team? No</li>
                <li>Corpo: Have you committed genocide?</li>
                <li>Sparka: These are our based requirements?</li>
              </ul>
              <li>Corpo/Lars is like we need someone to be under our thumb but not us</li>
              <li>All agree not Qeline...let's check on...Daran?</li>
              <li>Sass is like I will go ask Daran...convince him. </li>
              <li>Mike falls asleep but is like...ok...go get him....? Corpo make him the sleepy king?</li>
              <li>Sparka is like I think he wants to be king of something bigger than Phandalin...like an actual king</li>
              <li>Lars gives 10 gold each to 10 urchins to have them campaign for Daran</li>
              <li>Korg like wtf I thought I was gonna mayor! Ok I'll be assistant </li>
              <li>Sparka tells Lars intro Korg to his grandfather and the temple of Kord</li>
              <li>Korg walks towards the temple of Korg but accidentally walks towards the lawless ring</li>
              <li>Sparka is like wake up Mike go find Daran and tell him that we are going to make him the mayor</li>
              <li>Sass talks to Daran</li>
              <ul>
                <li>tells him bout the wing cape...asks for a gin and tonic</li>
                <li>Sass forgets why he is there and just drinks</li>
                <li>Mike wakes up and is like shit mayor</li>
                <li>Sass is like you would be a perfect candidate for mayor</li>
                <li>We are already campaigning in your stead since election is tomorrow</li>
                <li>You would be a write in candidate but we can do it</li>
                <li>We want you to be mayor cause Qeline is a big time Ho</li>
                <li>Daran is like she ain't a ho...hos take money.</li>
                <li>Difference between 3 dicks and a joke? Qeline can't take a joke</li>
                <li>Says tell your friends if I win I want an assistant and my platform is the refugee work</li>
                <li>Sass appreciates him and the constituents will appreicate helping the  kids who can't read good</li>
                <li>Remember...find the one with the flaming black sword...kill em</li>
                <li>Sass gives him a dragon claw</li>
                <li>He tells the yoked Dragonborn joke</li>
                <li>Daran: That Corpo guy has such a tiny member</li>
                <li>Sass: Especially compared to a dwarf dick</li>
                <li>Daran: Kinda sad</li>
                <li>Sass: I know a dwarf with a huge..</li>
                <li>Daran: Lars?</li>
                <li>Sass: Yeah</li>
                <li>Daran: He came to my house, he was there with you</li>
                <li>Sass: Dude is hung like a dragon</li>
                <li>Daran: Make sense...balance of nature</li>
              </ul>
              <li>The rest and go check for Elmar</li>
              <ul>
                <li>Thistle and Elmar at the tavern</li>
                <li>Elmar asks if they found the thief</li>
                <li>Lars steps forward and winks at Thistle..she is like uhh</li>
                <li>Lars takes it off his neck, throws it on the ground and SMASHES IT</li>
                <li>He freaks</li>
                <li>Starts seeing Thistle transforms into a Tiefling as reality changes</li>
                <li>Elmar gets scars across his face now and finger is gone</li>
                <li>Elmar screams</li>
                <li>Thistle is like...wait...what?</li>
                <li>Sass beat boxes ME SO HORNY</li>
                <li>Lars comes up and is like it's ok...but rolls crappy</li>
                <li>She is freaking</li>
                <li>Elmar is like WHY DID YOU BREAK IT?</li>
                <li>Sparka asks if he knows anything..he is confused, starts weeping</li>
                <li>Sparka: Where did it come from?</li>
                <li>Elmar: I don't know...she gave it to us...my ex-wife.</li>
                <li>Elmar clarifies that it was a Moon Elven woman who used the crystal to pseudo alter reality to make Thistle's mother his first wife...so a human so no one would know</li>
                <li>2nd wife Tiefling was serial killer who had killed 37 PEOPLE CAN YOU BELIEVE??</li>
                <li>She attacked him</li>
                <li>She said "I am the Redlake I will enchant this crystal I will change your life"</li>
                <li>Lars points at Spark and goes OOOOOH</li>
                <li>Sparka is like who is this woman?</li>
                <li>Elmar: Redlake...the dragon slayer she said?</li>
                <li>Lars takes Thistle away, some yokels make faces at her Tiefling horns</li>
                <li>She had been tracing the Tiefling, had escaped and tracked her....</li>
                <li>Change history and altered reality...said go to Phandalin and start life anew</li>
                <li>WHAT DID SHE LOOK LIKE?</li>
                <li>Sword, staff...white trimmed robes of the Arch Magi....</li>
                <li>He is freaking out...</li>
                <li>She was protecting him...he asked Redlake to do it....Thistle as a Tiefling in rural areas be judged a bit (Lars notes people looking at her....)</li>
                <li>He has multiple time streams...can't figure out which is which....</li>
                <li>Thistle is freaking out...MY MOM ISN'T MY MOM</li>
                <li>Lars...is like...wait......little Lars.........Tiefling???</li>
                <li>Sparka is like you need to rest...takes Elmar to go rest</li>
                <li>Cecil like...damn good thing we didn't pick him as mayor....</li>
                <li>Sparka goes to Thistle...he asks for Minor Illusion to cover the horns for now...Lars is like noooooooo.</li>
                <li>SHE IS LIKE I AM NOT MAD BUT DAFUQ I HAVE HORNS AND A TAIL</li>
                <li>Sparka politely says you are beautiful and takes care of Elmar</li>
                <li>Lars walks freaking out Thistle away</li>
                <li>Sparka says once you have sorted stuff out...let me know....PLEASE HELP ME SLEEP. Casts sleep on him</li>
                <li>Sparka is like WTF</li>
              </ul>
              <li>Corpo goes to find the merchant</li>
              <li>Maisie tries to call Sparka but mutters and ruins 25 words....nervous...gives him a gift</li>
              <li>A tome of local business laws in Thon Daral and the Empire...including loopholes and rules. Sparka is beaming, grabs her hand and they get lunch</li>
              <li>Korg walks up on their date...says Sparka is party animal...and...respectable towards women</li>
              <li>Thistle and Lars and yelling at people to stare as they smoke cigarettes</li>
              <li>Corpo hears the bow say "for a father who is not there for his son, is no father at all. I will never tell you to stifle your emotions"</li>
              <li>Cecil goes to the Library...</li>
              <ul>
                <li>Clarifies with Dorrissa that Arixus has not told her anything yet</li>
                <li>Asks about Air Plane...clarify CORPO step bro and others are calling to get support for the Air Plane...war is happening</li>
                <li>Cecil is like...would Corpo be a loose cannon on this Air Plane?</li>
                <li>Dorrissa assumes half-bro will handle it....either Haal is happy to see Corpo or throws him in jail...either way a win. Cecil is cool with that as someone Lawful leaning good (that's why Corpo is a problem)</li>
                <li>Finds out that if he pays 100s of gold...they can hire a bunch of people to make sure there are chaperones so more can get in...PRAISE IOUN</li>
                <li>They are not 100% full time workers...but get badges that say Cecil loves knowledge and Ioun</li>
              </ul>
              <li>Corpo goes to meet Penguin person</li>
              <ul>
                <li>PIrate-Penguin-Gangastas</li>
                <li>Waddle waddle waddle dafuq up?</li>
                <li>The captain is Captain The Situation</li>
                <li>What you looking at me for? Da Fuq?</li>
                <li>How often you go there?????</li>
                <li>20 Gangsta's talking</li>
                <li>Corpo pays 20 gold for pAHHHSSSAGE. 40 gold to avoid deck chores</li>
                <li>Going to Haalinith...Corpo is like what up with him? He says he ok...would go to war if he paid</li>
                <li>Corpo pays. </li>
                <li>Leaving in the morning ....we leave by third bell...all money up front</li>
                <li>If you snore bring a cork and shove it up your ass. 20 more gold and you can snore</li>
                <li>He pays 60 total</li>
                <li>Don't insult his mama</li>
                <li>Gonna Scrooge McDuck his fucking money if he ain't there...goes to the whore house cause Penguins FUCK</li>
              </ul>
              <li>Cecil Mother mentions she can scry...Cecil debates using cousin's head to check on uncle...</li>
            </ul>
          </Collapsible>

          <Collapsible
            title="The Election! (#30 - 10/27)"
            toggle={this.collapseToggle.bind(this)}
            kind="session30"
            show={this.state.show}
          >
            <ul>
                    <li> Man's giving discussion </li>
              <li> They are meeting up near dinner time </li>
              <li> Corpo is wandering in the "pale moonlight" of midafternoon underground </li>
              <li> Sparka is with Maisie </li>
              <li> Cecil goes to Mother and tries scrying on uncle with cousin's head </li>
              <ul>
                <li> Lars is trying to get Cecil's mom drunk </li>
                <li> BUT cousin is alive damn </li>
                <li> Sees cloaked figure surrounded in ash...on deck of ship that passes into the AIR PLANE </li>
                <li> Other scurry around as another comes up </li>
                <li> See Hadrian standing on the bow of ship...They will never see him coming as Thopleth stands there </li>
                <li> They are like...inform the Dreadflame I will call him tomorrow </li>
                <li> Lars and Cecil see some generic dragon masks BUT NOT THE MAGIC ONES </li>
                <li> Clarifies it was in real time </li>
                <li> Cecil thinks it is Clone spell? Lars is like wait did we do anything wrong? Nope just need to kill them before the 120 day maturity...also probably kill Severin's clone before freeing him </li>
                <li> Cecil Mom is like...he is alive? </li>
                <li> Lars...we killed him once we can do it again </li>
                <li> So Hardian is raiding the AIR PLANE </li>
                <li> Cecil and Lars agree not to tell Corpo so doesn't do anything rash... Mike: "like genocide?" </li>
              </ul>
              <li> Sass goes shopping for more arrows </li>
              <li> Corpo is one marg in as he does a Shakespearean soliloquy  </li>
              <li> Cecil's dad comes by to talk to Cecil...Cecil tells him about Hadrian and he is distraught </li>
              <li> Matrim then tells Cecil about how Wildon is not sleeping and full on goth...Matrim is concerned what Corpo said to him...(Corpo told him to worship the Raven Queen but he can't so he is a cleric without spells and sad) </li>
              <li> Corpo debates getting 10 Teleportation circle tattoos...for....6400 gold....then is like wait what </li>
              <li> Lars riles up the bar! </li>
              <li> Corpo heads to Ecstasy and Fire </li>
              <ul>
                <li> Puti is like RA RA RA </li>
                <li> Corpo punches himself in the dick for one damage </li>
                <li> They say it was a shame how he carried on...but you need no shame </li>
                <li> "Shower me with nonsexual monkish delights" </li>
                <li> Corpo wants ice cream but no massage....5 cp </li>
                <li> Our campaign is property </li>
                <li> He has intense eyes and was the lover of the Trushi queen </li>
                <li> Corpo buys a Dragon Hide Belt....then is like nope not giving you 4K gold...just 5cp for icecream </li>
                <li> Massage is how he makes his money....icecream and monk supplies are loss leaders </li>
                <li> Corpo does buy a razor chain </li>
              </ul>
              <li> Cecil buys diamonds </li>
              <li> Ben makes a joke about being a fruit and a vegetable...then about vinegar </li>
              <li> Robert sings "It's racist joke nighttttt...on recoooordinggggg" </li>
              <li> The party starts to coalesce </li>
              <li> Mike sees STILL INTO YOU as Maisie had to leave Sparka but is happy </li>
              <li> Lars starts a drinking contest to PLEASE KORD. He is doing pretty good! </li>
              <li> Ole comes up to Lars as he is  killing the contest.  </li>
              <ul>
                <li> Ole tells him...he found out other survivors of Grimstone...people who had been traveling or moved away.... </li>
                <li> Found as shriveled up corpses turned to dust.... </li>
                <li> One dies every few months...not sure how many are left </li>
                <li> Recall the sober one said "I can't kill you yet" </li>
                <li> Ole thinks that the prophecy of the greatest warrior ever was the blood of Grimstone...so maybe....he is killing everyone so it is technically him... </li>
                <li> Lars is like that's brilliant...but....he can't handle a beer </li>
                <li> Ole is like...anyone with the blood of Grimstone is a possible target for your brother....as Lars sees Thistle puke over his shoulder </li>
                <li> Lars pays a deviant urchin squire to find out what a Dwarf-Tiefling hybrid is like in the library.  </li>
                <li> Cecil says tell them CECIL sent you....  </li>
                <li> "OY DWARPH MAN. I CANT READT BUT I"LL TRY" </li>
                <li> Lars says look at the pictures...gives him two Coppers </li>
              </ul>
              <li> Group back at a table in the Central Tavern waiting for Daran </li>
              <ul>
                <li> Lars is like a conspiracy to kill Grimstone survivors... </li>
                <li> Corpo like nobody fucks with you...wait...welllllll </li>
                <li> Know Qeline has a rival event </li>
                <li> Lars doesn't say the legend/prophecy part </li>
                <li> Cecil recognizes the shriveled up corpses as the Dreadflame's sword </li>
                <li> Cecil tells the party that Hadrian and Thopleth are a live </li>
                <li> Lars tells Sass some Dragonborn were with them...but then Sass is like Jazelle's soul is trapped in hell </li>
                <li> Lars speculates wildly. Low INT BABY </li>
                <li> Sass is like WTF why don't all the Dragonborn obey ME....cause hiding </li>
                <li> They are like we need to deal with the Election before Hadrian </li>
                <li> Daran comes over </li>
                <li> Corpo says he is Spock....Daran is like what...? </li>
                <li> Lars says speeches are good to pound the table and yell </li>
                <li> Sass is like do we endorse you? YES Qeline only doing good cause you supported her </li>
                <li> Daran is like...do you really need to lie to smear Qeline? </li>
                <li> Sass is like she gave me STD and my dick fell off </li>
                <li> Lars stands on a table and gives a speech in honor of Daran.  </li>
                <li> Lars: FINE PEOPLE! PHANDALIN! TRAVELRS! A GREAT CANDIDATE FOR MAYOR! DARAN! You may have heard some rumors about me...but I bet Daran is even bigger. That's why he cares so much for the refugees...he has so much to give! </li>
                <li> Sass: You know what they say...big dick... </li>
                <li> Lars and Sass: BIG HEART </li>
                <li> Corpo: GIRTH IS WORSE </li>
                <li> Lars: If I had a vote to cast...not sure if I do not sure how the rules work...but I would </li>
                <li> The crowd loves it </li>
                <li> Sass Thaumaturgy to make the cheers loud </li>
                <li> A full 4 dozen of Phandalin are there...1/3 of the town </li>
                <li> Thistle is in the background...feeling ill...a headache...not sure why </li>
                <li> They go looking for others...Sass wants to find the white senior citizens </li>
                <li> Lars takes the urchins....one is curious about Political Science. Yelling about how cool Daran is and how big his dick is </li>
                <li> Sparka always takes political advice from street urchins  </li>
              </ul>
              <li> Reminder Daran makes DnD 4Loko </li>
              <li> They go to Qeline's event </li>
              <ul>
                <li> Her husband (!!!!) is speaking about great wife and mother </li>
                <li> Corpo in the crowd </li>
                <li> Sparka stands up and gives a speech at her event </li>
                <li> Sparka: People of Phandalin...you have heard from Qeline's husband...as it were </li>
                <li> Corpo: WHAT A CUCK </li>
                <li> Sparka: But there is another running in this election...you all know him...love him...DARAN local small business owner. Get what you need to get it done 4LOKO from his orchard. You all know this is a farce. Sir...I feel for you but you are blind and deaf. Sir you wife, if you can call her that, has slept with more people in this town than votes she will get in this election. So if you like, the endorsement of the heroes of Phandalin is Daran, come over to his event. </li>
                <li> 20 of the people leave </li>
                <li> Sparka: Sorry Qeline I'd say let the best man win, but we both know that's not you </li>
                <li> Qeline: ...you're not sorry yet </li>
                <li> Sass gives a Kamal Harris speech </li>
                <li> Corpo measures up Qeline...she does not carry self like a warrior or have much strength </li>
              </ul>
              <li> Voting laws are anyone over 20 that own property or live in a home </li>
              <li> 150 total voters include them 5 </li>
              <li> Lars Urchin returns from the library...DONT TELL ME JUST IMPRESSION...it's like a Tiefling but shorter. Yes they have beards. I AM 7 I DON'T KNOW WHAT IS HAPPENING. </li>
              <li> Lars asks the Urchin why the sun sets in the east </li>
              <li> The Urchin can get to all the dark books cause he knows Cecil the donor...goes to leave to study necromancy books. Something about killing a lot of cows </li>
              <li> Lars takes Thistle to bed...orders stew and puts her to bed </li>
              <li> Corpo wants to seduce Qeline in front of her husband and record it and leak it. Sparka is like WTF it's medieval fantasy we can't record things </li>
              <li> Lars stays behind to watch Thistle while the other 4 head to their manor </li>
              <li> Wildon is in the manor...like you just got back I've been guarding your house for days </li>
              <li> He is looking super goth/emo since he is trying to worship death </li>
              <li> He asks Corpo if he can switch back to the Moonweaver...Corpo is like I know you well "Bob" and you can return  to the Moonweaver.  </li>
              <li> He is super happy, wipes makeup off and goes to sleep. Corpo calls him Bob again </li>
              <li> Lars sits in chair by the door </li>
              <li> They hear outside the manor "Heyyyyy" in dragonborn voices </li>
              <li> Sass sees a group of DB prostitutes (Scaley T*** in the moonlight) </li>
              <li> Sass is horny but is more Monogamous....does an Insight check to find a hooker that doesn't want to be a Hooker </li>
              <li> Goes Full on to Gentlemanly solicit a Prostitute for the evening as a Prostitute to talk to her.  </li>
              <li> Ryan had odd Uber experiences in Cincinnati  </li>
              <li> Her name is Razorfang Kierasha </li>
              <li> Sass 100% has a conversation with Razorfang... </li>
              <ul>
                <li> Sass: Do you know why I invited you here tonight? </li>
                <li> RF: I am a prostitute, you gave me money, obviously you want me to paint your house </li>
                <li> Sass: It's because I think you could be so much more </li>
                <li> NAT 20 Sass persuasion check to convince the Ho not to be a Ho </li>
                <li> They talk to the wee hours of the night with a simple embrace...asleep on each other intimately but not sexually on a couch </li>
                <li> He is turned out but a horny gentleman </li>
              </ul>
              <li> Corpo pretends to sleep near the portal </li>
              <li> Sass really admires Snoop Dogg, he is married to same woman since 1997 </li>
              <li> Snoop Dogg drops to his knees and thanks god before he does his thing....the Book of Tobit...before saying get a gap in your teeth so my balls fit yo mouth </li>
              <li> I totally lose control of the party </li>
              <li> Lars sees Thon Daral on the morning of in the past... </li>
              <ul>
                <li> Leaving with Bamur and Ole... </li>
                <li> His parents were Barbarians....loud love making </li>
                <li> He had a Barbmitzvah and a Barb-Kris </li>
                <li> TSO why did they send you away that morning? </li>
                <li> Lars: Cause my dick was too big </li>
                <li> Milk in his beard...TSO says parents were cowards and if they weren't drunk  </li>
              </ul>
              <li> Cecil has drummed up the vote...people want to move in they are so pumped </li>
              <li> NEVER QUESTION THE DM </li>
              <li> Sass is spooning with the prostitute that he doesn't want to call a prostitute </li>
              <li> Corpo rolls through the portal </li>
              <ul>
                <li> Sparka secretly noticed and started following to the tavern and waiting for DREAM </li>
                <li> Corpo sees guards but no one he knows. </li>
                <li> Mike is like Fucking rustling paper like he is making a paper airplane. And he has 0 clue what is happening and is asking Ryan </li>
                <li> Lars is awoken....Corpo is at the door holds ax up </li>
                <li> TELL ME SOMETHING ONLY CORPO WOULD KNOW </li>
                <li> Corpo is like I was condemned to a life of chastity </li>
                <li> Corpo is worried about the AIR PLANE </li>
                <li> Lars is like I am worried about right here </li>
                <li> WHY?  </li>
                <li> ...no... </li>
                <li> I am being vulnerable here tell me </li>
                <li> You are always vulnerable I am a tank </li>
                <li> Corpo is like I need to go to the Air Plane with Pirate Penguins and fight in glorious battle with my brother </li>
                <li> Lars reveals that the Dreadflame and Hadrian and Thopleth are there too </li>
                <li> Lars headbutts Corpo KORD SPEED </li>
                <li> Lars locks the door....Corpo goes to the docks </li>
                <li> Fuck-wuins are not there....whoring </li>
              </ul>
              <li> Corpo goes to sleep on the docks.... </li>
              <ul>
                <li> "The second you fall asleep" Sparka takes over with Dream spell </li>
                <li> Sparka arrives and Corpo is jacking it MAKE 1:34:00 </li>
                <li> References to St Thomas Aquinas </li>
                <li> His dream dick is 2 inches....Sparka controls the room </li>
                <li> Drunk ass Mike won't shut up so I have to move </li>
                <li> Sparka is like talking to Corpo like Gandalf in ROTK before the gay part...looking like an older Sparka </li>
                <li> Mike needs more whiskey and Bourbon </li>
                <li> Sparka puts his hands on his shoulder and shows him on the road to Phandalin </li>
                <li> Mike's employee is a fucking idiot even with a Master's Degree </li>
                <li> Sparka is like where did you go Corpo? Why do you need to go? </li>
                <li> THE AIRPLANE </li>
                <li> "DUDE WHERE DO YOU GET THESE" </li>
                <li> Sparka is like why? Corpo is like I need to go see my brother </li>
                <li> Mike told Bridget how a gun shoots </li>
                <li> Ben asks if he could buy prostitutes to help our wives in Lake Geneva </li>
                <li> Mike thinks Ben is fucking dumb....and his employee is a dumb bitch </li>
                <li> Robert is like if you hired her than whose fault is it???? </li>
                <li> Sparka is like I am in the dream on the road to Emmaus....I mean Phandalin </li>
                <li> The wagon with Fiddle is in the distance </li>
                <li> Robert is like we are going to drink so many cutwaters </li>
                <li> Ben is going to grab someone's dick on Friendsgiving </li>
                <li> Corpo is like I need to find my brother my only family...the beloved one...if I help him I might be able to be worth it.  </li>
                <li> Sparka is like...uhhh....some unresolved trauma there..... </li>
                <li> I totally lose control even more and literally move over to Robert and Ben </li>
                <li> Mike yells "13%!!!" </li>
                <li> Sparka is like you say you are alone...but.........we are with you know </li>
                <li> Corpo has flashbacks to the castle...the dragons....everything </li>
                <li> Corpo says you shouldn't have to suffer for what my family is doing... </li>
                <li> Sparka is we all have that but don't go alone... </li>
                <li> Corpo is like I could just leave and go alone.... </li>
                <li> Sparka is like you could...not here to control you...but want to have a conversation with you harder to talk in the morning.... </li>
                <li> Corpo I'll meet you when you get here </li>
                <li> Sparka....we are leaving in 6 days.... </li>
                <li> Corpo....but these are penguins..... </li>
                <li> Sparka....do you realize what you said?? Talk to us. You have never met your brother....why are you so attached to this idea of family you don't see what you already have? </li>
                <li> Sparka shows him getting saved, him saving them...slaying dragons...slayed undead together...found Chrono Crystals.....Phandalin....he killing Harbin...finding Thon Daral.....DEED FOR THE MANOR!  </li>
                <li> Sparka....this is family. Decide what you want </li>
                <li> Corpo dream punches his dick to try and wake up...but.... </li>
                <li> Ben claims Genasi don't get paralyzed during REM sleep..I roll...not true </li>
                <li> Sparka is like seems to me all this bluster....we are better together. You've never met your brother or father...we are bounded </li>
                <li> Corpo is internally admitting that he makes good points </li>
                <li> Sparka says make your choice....but you can only do running away so many times before it becomes you </li>
                <li> Sparka says you have a chance to have a family...we are here...I know family isn't just by blood. </li>
                <li> Make your choice....but it is your decision if you want to be part of our family </li>
                <li> Corpo looks for contraband...doesn't see any....decides to light the ship on fire </li>
                <li> Corpo is like "OH NO A PENGUIN MIGHT DIE" </li>
                <li> Corpo is like "I'm lighting a ship on fire cause FAMILY" </li>
                <li> Exarch of Ravens shows up and is like don't burn it </li>
                <li> Corpo is what do you care about property damage goddess of DEATH </li>
                <li> Exarch is like FINE if you fine with property deception....he burns 1K of Corpos gold </li>
                <li> Corpo finds a couple traps and tries sneaky on their ship </li>
                <li> Corpo is like you NEED TO EXPLAIN WHY I can't burn a random person's ship </li>
                <li> Exarch is like...impulsivity is the problem. Corpo says Penguin lives don't matter </li>
                <li> Exarch IS LIKE LEAVE or we fight </li>
                <li> Corpo is like what is arguing with the Exarch and he does a necrotic touch and he takes 22 damage. LEAVE OR I WILL KILL YOU Corpo feels half is life float away </li>
                <li> He hits him in the face with a psychic damage knife....and has blood pouring out of his mouth </li>
                <li> He pulls off his mask and Corpo hobbles away saying that's all he wanted </li>
              </ul>
              <li> Sass spoons all night </li>
              <li> Corpo wakes to Penguins kicking him saying dafuq you going? </li>
              <li> Penguins say bread and supplies for....Corpo leaves </li>
              <li> Corpo goes to Lars...looks like shit </li>
              <li> Razorfang wakes...says has to go work...Sass is like you don't have to go work...he gives her money and she gets problems at shops them judging her </li>
              <li> Sass is turned on in a respectful way...half mast </li>
              <li> WAKE UP Lars and Cecil go persuading people to vote does great.  </li>
              <li> Ben makes racist comments </li>
              <li> They also realize Qeline supporters just give it up and do go in </li>
              <li> Ben keeps asking Mike if he likes various Phillipino food coded as a penis </li>
              <li> "What kind of death does the goddess of death approve of?" as Corpo is pissed about not being able to kill Qeline...he wants a chaotic evil death deity. Ben has a god problem and Ryan wants me to save it now.  </li>
              <li> Ben is like wait the Chaotic Neutral Moonweaver would be a good idea </li>
              <li> Corpo is like fuck it fine go Lars. </li>
              <li> Cecil goes to scry on Haalinth with his mom </li>
              <ul>
                <li> They see an 8 foot tall Genasi-Goliath...Taller than Arnold and with Danny Davito as a penis </li>
                <li> See him on a throne with 4 women around him </li>
                <li> Says to tell the Djinn he will hear their appeals but his coalition will be run his way </li>
                <li> He has vision in the face of such reckless hate of the Fire Plane </li>
                <li> Orders to call for allies in the Material Plane </li>
                <li> Says no one is raiding... </li>
                <li> The concubines all hell death.  </li>
                <li> He yells death </li>
                <li> Corpo figures out he is not a Waco situation </li>
              </ul>
              <li> Cecil goes to the library </li>
              <ul>
                <li> Doesn't know what Lars researched </li>
                <li> Figures out Haalinth is a Wind Duke...a noble title for leaders of the Plane as the Djinn are chaotic and flighty </li>
                <li> He is currently the most powerful </li>
                <li> Find out Daddy is a noble Djinn and can cast wish once every 100 years...and it's been 99 years in the record </li>
                <li> So Hadrian is holding Corpo Daddy so he can cast the wish spell (yeah...I planned that shit out) </li>
              </ul>
              <li> Willis Tower has good Sushi...jk Ben is like that's awful I'm taking you to get Sushi. Ryan agrees to do Early Dinner...because of that I can't hear shit about what I said to Adam </li>
              <li> Ben is wondering who has a 401K </li>
              <li> I try standing up </li>
              <li> "IF YOU ARE GOING TO MAKE FUN OF ME AT LEAST GET IT RIGHT!" Mike yells after Ben mispronounces Philippino food </li>
              <li> Corpo doesn't tell the others what he was up to....Sparka doesn't tell others either </li>
              <li> Sass checks in with Razorfang...set her up at a fancy inn in Thon Daral. She is in a fancy ballgown. </li>
              <ul>
                <li> But a loyal attendant interrupts saying they are looking for marriage alliances to secure his political power </li>
                <li> Razor Fang: are you busy? </li>
                <li> Sass: I think I am now </li>
                <li> Razorfang: Like the dress? You paid for it? </li>
                <li> Sass: I think I'd like it better off of you </li>
                <li> Razorfang: I agree  </li>
                <li> They walk in and go BOOM BOOM BOOM </li>
                <li> Sass gets over 20 Pretty Woman-ing a Whore </li>
              </ul>
              <li> Sparka checks in with Mist...Sparka is going to bring 3 finalists before the meeting </li>
              <li> Mist has sold 3 douce canoes at this point... </li>
              <li> Adam catches Ben scrolling through Filipino dishes </li>
              <li> I explain something about Djinn to Adam but Ben and Mike are threatening to punch each other even when though he has a hernia. Something about you can't stop it any other way </li>
              <li> Ben asks if Mike knows what dishes are based on different sauces </li>
              <li> Cecil researches how to kill a Djinn in a lamp....summon it out, no other crazy ritual </li>
              <li> Sass sees the Succubus in the morning...asking if he forgot how his fiancé is trapped in and out of hell until he bargains with her benefactor. I'm sure she has a lovely personality  </li>
              <li> 48 HOURS ultimatum or soul is gone </li>
              <li> Mike tells Ben to eat a Grape Leaf...Ben forgets what it is called...I help him out </li>
              <li> Cecil goes to ask Dorrissa what Corpo's presence will be there in the Imperial meetings </li>
              <li> Dorrissa is like there are still posters....but otherwise as long as he doesn't do awful things....all done </li>
            </ul>
          </Collapsible>

          <Collapsible
            title="The Summit (#31 - 11/18)"
            toggle={this.collapseToggle.bind(this)}
            kind="session31"
            show={this.state.show}
          >
            <ul>
              <li> Today is the Day after the Election </li>
              <li> Sass made love to Razorfang respectfully </li>
              <li> You can claim all the deductions you want </li>
              <li> Lars sets up Urchins and Ole to protect the Concubine of the Dragon </li>
              <li> Sass is having plenty of afternoon delights with Razorfang </li>
              <li> Corpo is chilling out </li>
              <li> Sparka is hanging with Maisie and chatting up Small Business owners </li>
              <li> Cecil goes to the Library to research the AIR PLANE...knows about gravity being odd, earth bergs....some the size of cities. Ships can fly. Yan C Bin and plenty of other factions </li>
              <li> Day ends and they get ready for the meeting </li>
              <li> Next day is more the same </li>
              <li> Meet Daran for breakfast before the Council. </li>
              <ul>
                <li> He is like I am a simple country paladin...not used to running no interplanetary conferences </li>
                <li> Lars drinks whiskey in his coffee </li>
                <li> Daran is hoping for peace between worlds...doesn't want to make them angry since their military is protecting us </li>
                <li> Cecil casts Rary's telepathic bond but NOT on Daran so Corpo can't just mentally send "DICK DICK DICK" to Daran </li>
                <li> Lars is like we want them happy to protect our town but we can help them </li>
                <li> Lars is speaking telekinetically I mean telepathically </li>
              </ul>
              <li> Halia walks up to the group  </li>
              <ul>
                <li> Telepathic bond Lars is like should I walk away? yes </li>
                <li> Lars asks to step over and they both have business </li>
                <li> Lars interrupts her and is like sorry had drinks this morn </li>
                <li> She says to look out for the Zhentarim of this world...they are secretive and gone to the ground </li>
                <li> Zhents are idealogy freaks but could be a major problem </li>
                <li> She asks what he wanted...he is like oh yeah I just wanted to see how you were doing </li>
                <li> Halia reveals there were more debts...BUT one lied and did fake tabs. Turns out he stole Lars' recipe he drew on a napkin (assuming shrooms) for a lager and has been selling it under the name "Drunken Dick Lager" </li>
                <li> Of course the Zhents have the best IP lawyers so the bar keep is dead </li>
                <li> Zhents are going to bring the operation to brew it under Daran's operation </li>
                <li> Lars says needs someone protected.  </li>
                <li> Halia is like Plata and Revanshaw an handle it </li>
                <li> Lars is like yes combined with my urchins we will be unstoppable! </li>
                <li> Lars tries to make an excuse for her not to be at the meeting but then she is like I wasn't going to go you handle it </li>
                <li> She says main goal is that the town is safe and trade continue....obviously if town wrecked by zombies that's bad </li>
                <li> We win if Phandalin is the trading port for an entire planet...and everyone drinking your lager </li>
                <li> Lars broadcasts to the group </li>
                <li> Half-Orc holds an umbrella for Halia as Lars hits his flask </li>
                <li> Lars smokes to make Daran think he did something with Halia...Daran buys it </li>
                <li> Daran is like...uhhhhh...I just wanted to make cider and have Sass kill the black flame sword man who killed my love </li>
              </ul>
              <li> THE CONFERENCE </li>
              <ul>
                <li> Go deeper into Imperial compound that Corpo had gone nuts into the first time he got here </li>
                <li> Up in a larger conference room high up the wall  with view of the city and the carving in the wall </li>
                <li> Imperials enter....Dorrissa...the Legate Rael...governor...and Iron Chained Onyx Conclave member </li>
                <li> Corpo thinks some of the women have Sandstone Sphincters....it's how they walk (The DM makes horrible shit up and Ben just does weird shit) </li>
                <li> Reinassance cleric style Waukeenar wakes in </li>
                <li> Victrix speaks...we are here to discuss Phandalin in Faerün and by default the world of Toril </li>
                <li> Lars steps forward "I want to be the best of the best the best" and steps back </li>
                <li> Lars checks for a Waukeen person...NAT 20 INSIGHT notices the Waukeen flinches </li>
                <li> Victrix talks to Sparka seeing his dragon shard </li>
                <li> Sparka gives nod to Daran as the duly elected mayor </li>
                <li> An urchin runs in and hands note to Lars "IT'S STILL BIG" Lars sends a mental image through the bond </li>
                <li> Sparka is like we can bring stuff to the table...our world can be a great benefitted. </li>
                <li> Lars is like we are the producer of the most popular lager known to anyone </li>
                <li> Sass is like I reformed a prostitute </li>
                <li> Sparka is like excuse them...UH we appreciate it in our time of need...not just altruistic but all free peoples must keep Tiamat at bay </li>
                <li> PRAISE BAHAMUT </li>
                <li> Sparka is like than you and bows and sits </li>
                <li> Corpo elbows Cecil...Cecil is like what we doing here.... </li>
                <li> Cecil asks what they want from them </li>
                <li> She is like I am The Victrix of Internal Terror...90 million people in the empire and many factions </li>
                <li> She is like our soldiers are watching you...not going to challenge you on that. For now...helps us secure the portal </li>
                <li> Cecil asks how they secured other portals...she says other times it has been empty on the other sides...so they made a fort on the portal side </li>
                <li> So in this case they help reinforce their town as a beachhead their own region </li>
                <li> The other Waukeen looks at Sparka with a heh heh LET THE GOLD FLOW </li>
                <li> Sparka asks if their threat they mentioned is the biggest...she is like honestly we have many threats. They are on their list so far </li>
                <li> Victrix says Dorrissa has said that they are trustworthy and and has spoken highly of 7 of them.....(4+duchess+bowser+Wildon) </li>
                <li> She says many people say that things are the most dangerous...in general they would have died by now if they did not take each seriously </li>
                <li> Cecil is like wait...can anyone disable the portal? But figure they should stay </li>
                <li> "They should recall that the dildo of punishment rarely arrives lubed" Corpo says </li>
                <li> The Legate is leaning forward liking them, says why don't we do a 6 months initial thing? </li>
                <li> Ben's mother spoke to their first exchange student like CLEAN THE BED!  </li>
                <li> Corpo might we do a probationary period </li>
                <li> Legate and Victrix are like that makes sense as in 6 months you might be on the verge of collapse or overflowing with trade </li>
                <li> Lars is like we need 6 months 15% tariffs to help bolster our own military </li>
                <li> Sass is like can you equate money for political stability?  </li>
                <li> The Victrix is like I can't the Gold one is like I can. As Sparka tips his head WAUKEEN STYLE </li>
                <li> Sparka is like he said 50% I meant 35%...on goods from the Empire to our land </li>
                <li> Sass is like wait what goods...edibles? </li>
                <li> 37.5% tariff to put guardians on the basement... </li>
                <li> Lars makes notes about career goals to be like the Victrix of Internal Terror </li>
                <li> The Goldone is like no tariffs on the people of Phandalin coming into our world and purchasing items </li>
                <li> 6 Months is agreed upon </li>
                <li> Sparka is like a meeting SUPER IMPORTANT MEETING will be in a couple days and the gold one and Sparka will be vicars for small  business </li>
                <li> Discuss the location of Thalas and the Emperor is there with the portal  </li>
                <li> "I'M SORRY WHERE THE MOUNTAIN LANDED?" Robert like wait whaaaaa this is fairly recent (Yes this world is dynamic mother fucker) </li>
                <li> Victrix is like so far our soldiers have been treated kindly but I'd hope they be given some leniency </li>
                <li> The group chat is about being formal Faerün delegates when Daran like hey let me be Phandalin let them be the world (but I can't hear soft spoken Eric on the train) </li>
                <li> The empire is like...good for us but you must accept for your world </li>
                <li> Legate asks if they can scout around Phandalin...but have not left the borders of Phandalin...ask if they can? </li>
                <li> Lt Col Zorir would do the training and help with them </li>
                <li> Cecil is like hey lets have the Empire train the militia...and have them mirror them in organization too </li>
                <ul>
                  <li> Sparka and Cecil lead political matters </li>
                  <li> Lars and Sass lead military and terror </li>
                  <li> Corpo is a jester </li>
                  <li> POWER PLAY TO PUT IN POWER OVER THE DECENTRALIZED SWORD COAST </li>
                </ul>
                <li> Meeting Adjured.... </li>
                <li> Corpo Insight checks if Victrix is into him.... ROLLS A 24! no </li>
              </ul>
              <li> BREAK </li>
              <li> Cecil asks Dorrissa about why finish before other people show up </li>
              <ul>
                <li> Dorrissa is like some important people are arriving who might throw their weight around and put a stamp of approval on it </li>
                <li> Mike mentions mushroom stamping </li>
                <li> Cecil asks which side? She says depends on the person </li>
                <li> Mike talks about getting into Dragon Ball Z </li>
                <li> Cecil clarifies none will  try and change the agreement after done </li>
                <li> The people possibly coming were Imperial Princes </li>
                <li> They figure out Norsalor II is the Empeoror </li>
                <li> Cecil vibes in Dorrissa is that in the room were confident they were not getting away with anything </li>
              </ul>
              <li> The Victrix talks to Sparka, is like nods to the dragonshard...says hooray stability...curious about how the rest of their world will react </li>
              <li> Ryan or Mike crumbles chip bag </li>
              <li> Sparka is like....do we get power in a side quest over Faerün? </li>
              <li> They decide to rebuild their castle first </li>
              <li> Not many goods from Primavera transporting through yet (HOW DO WE CALCULATE TARIFF COSTS </li>
              <li> Daran is like...going to have myself a drink and go to my stomping grounds </li>
              <li> Sparka thanks him for what he said.... </li>
              <li> Daran is like I've walked on this earth...well....an earth for years and I know I can't handle a whole planet. And if you guys fail to represent the whole planet well....not on Phandalin </li>
              <li> Corpo is like I love you...Daran is like I took a vow when my lady died...Corpo is like me too....he's like who died...?  </li>
              <li> Lars is like change the sheets in the mayoral mansion...he's like there is none but I'll change my house to be safe </li>
              <li> Major Story Threads Currently </li>
              <ul>
                <li> Zombie Mushroom Army </li>
                <li> Imperial-Fearün Exchange </li>
                <li> AIR PLANE </li>
                <li> Severin's Tiamat cult...but...partially in AIR PLANE </li>
              </ul>
              <li> 2PM....the group huddles </li>
              <ul>
                <li> They like well that went well... </li>
                <li> Guess we reps of the whole world? </li>
                <li> Recap of Sass' kingdom...and the Strength vs Honor fragments (can't have one with either) so liberate the middle then deal with the others. UNITE THEM </li>
                <li> Sparka like....any ties to power in Faerün? </li>
                <li> Lars: My home got teleported here </li>
                <li> Sass: His kingdom is here.... </li>
                <li> Cecil: We are fighting my father's family.... </li>
                <li> Corpo: DERRRRRPPPPPPPP IM AN IDIOT </li>
                <li> Hey the mystery works out marketing-wise </li>
                <li> Wait should we like deal with building Phandalin up to be A WHOLE NEW WORLD to give reputation on both sides of the portal </li>
                <li> Realize if they go to the Air Plane they'd also be a major representative of Faerün to his brother </li>
                <li> Corpo is like screw connections IMPOSE OUR WILL! OUR DICKS ARE LONG!  </li>
                <li> Others are not convinced </li>
                <li> Phandalin needs to be secured </li>
                <li> Sass says the Mushroom Queen is a bitch </li>
                <li> Sparka is like I signed up to find my mom and dad...not this.  </li>
                <li> Lars is like Found my name carved in a mountain </li>
                <li> Mike sings "The Circle Jerk of Life" </li>
                <li> Wait...the Diemondar ruins with the alt timelines.... THIS HAS HAPPENED BEFORE </li>
                <li> They recall the hypothetical chrono-dish might need a wish spell to make it...and probably need DADDY to get it </li>
                <li> So making a dish as a back up in case he fails </li>
                <li> Corpo is like I need to meet dick sucker my half brother....Small business will continue </li>
                <li> Corpo wants to talk to his half-bro....says....his issues are trans-genism. </li>
                <li> Ben can't recall why Corpo hates his brother....it's the daddy love business </li>
                <li> Sparka does insight check to know Corpo is lying. </li>
                <li> Haalinith doesn't know Hadrian is raiding </li>
                <li> Sparka: "When is the last time you saw him?" </li>
                <li> Corpo: "A long time ago..." </li>
                <li> DM: "Never" </li>
                <li> Corpo: "Never" </li>
                <li> Corpo is like trying to get Daddy's wish </li>
                <li> Mike says we don't need Dragonballs...Ben what our Dragonballs...You better not say shit like that again to me </li>
              </ul>
              <li> Succubus walks in and is like hey sup </li>
              <ul>
                <li> Sass is like nothing I can do to help </li>
                <li> Sparka is like STOP DOING THAT. GO AWAY </li>
                <li> Sass is like if I see you again I will kill you </li>
                <li> She is like fiiiiiiiine I'm out of here and tell my boss know Jazelle's soul being released </li>
                <li> The succubus is like...wait...you letting her...ok? </li>
                <li> Corpo is like wait....is the Fang that good? </li>
                <li> Sass is like she knows what to do? </li>
                <li> If Jazelle shows up again...Sass will bite her head off...NOT DUCHESS SASS </li>
              </ul>
              <li> Lars goes to Thistle </li>
              <ul>
                <li> Plata is watching the door...talking about reading and a cave </li>
                <li> He tells Thistle...she freaks </li>
                <li> Tells her she has protection now (Mike says a giant condom over the whole tavern) </li>
              </ul>
              <li> Sparka looks for the crew to fix up the house...Wildon as a Foreman </li>
              <li> Debate the name...House of the Five? </li>
              <li> Wildon walks up </li>
              <ul>
                <li> He is like I AM REFRESHED! LIGHT OF THE MOON. Corpo fist bumps him. Had some Dborn hookers last night but best one was taken </li>
                <li> Sass nods </li>
                <li> Sparka is like "Wildon" </li>
                <li> Wildon: "Yo what's up" </li>
                <li> Corpo: "WHOA YOU DO NOT ADDRESS SPARKA LIKE THAT" and bitchslaps him </li>
                <li> Wildon is like wtf? I have been defending your home for free and fighting off urchins (not yours)  </li>
                <li> One urchin is Pancake...Poli Sci major...so cool </li>
                <li> Wildon has killed 4 rats </li>
                <li> Sparka is like need people to be moving back... </li>
                <li> Wildon has been encouraging them saying the 5 will protect them... </li>
                <li> Sparka is like well we aren't there it's the empire... </li>
                <li> Wildon like yeah BUT I am saying that through you guys </li>
                <li> P5 like whoa legit </li>
                <li> Wildon is like I can spin things...I once convinced myself I could fight </li>
                <li> Wildon been keeping Sildar and Lord's Alliance at bay...in their corner despite not know wtf that means </li>
                <li> Corpo hates the name....Wildon like how bout the Pentagon? </li>
                <li> Corpo is like how about Mojo Dojo Fuck House? </li>
                <li> Wildon will look into making it 5-walled instead of 4 </li>
                <li> Wildon asks for a Tchotchke from their trip...loves them Tchotchkes </li>
                <li> Lars is like can it brew Drunk Dick Lager? </li>
                <li> Lars tells him hey remind peeps the 5 have been prophesied to save the world... </li>
                <li> He's like I am saying the Material Plane..FUCK Toril (well I would if I could but I got a small dick) </li>
                <li> Sass asks for his own bathroom </li>
                <li> Wildon going to get the architects...they give 50 gold each... </li>
                <li> He is like will fix basic bones...then expand. Make sure we will need arcane masons and wood </li>
                <li> Their home will be the bastion of walls...Sparka says expand larger Phandalin gonna grow </li>
                <li> Sparka like make sure to do walls then inside for defense </li>
                <li> Lars like greenery landscaping into a chokepoint </li>
                <li> They are like wait can we deal Mushroom zombies? </li>
                <li> Ole is like I am a Blood Hunter who hath trained to kill zombies...I'll check </li>
                <li> Cecil is like can the Urchins be trusted? Can they read? </li>
              </ul>
              <li> Maisie gives Daran the spanreed so they can talk to him </li>
              <li> Cecil looks up Zugg mushroom demon queen/prince </li>
              <ul>
                <li> Figures out she is a demon price...lesser deity...weird that she has such power acting more like a cleric power </li>
                <li> Corpo also knows it is weird she has such issues </li>
              </ul>
              <li> Sass fucks the Fang gently and a dirty 21....this girl is turning </li>
              <ul>
                <li> Sass is like wait for me in my room (there are no rooms..only Coffin) </li>
                <li> The Fang can be a mason...father was a mason before he expelled.... </li>
                <li> Ben: for doing  Only Faerûns </li>
                <li> She is like GTFO Blue Person </li>
                <li> She is like these hips don't lie and these are massive DBorn titties </li>
                <li> She will wear a Burqa....Sass is like wait here is some money go buy shopping </li>
                <li> Sass instead goes shopping. </li>
                <li> Corpo wants details...I saw detail is Corpo go fuck yourself </li>
                <li> Fang is going to wear a cozy sweater over a tshirt that says I HEART PHANDALIN </li>
              </ul>
              <li> Cecil goes back to the Library </li>
              <ul>
                <li> Wants a public facing endowment </li>
                <li> He sets up a Phandalin Five Bookmobile </li>
                <li> The librarian is like we really can't have Corpo associated with children.... </li>
                <li> Cecil is like the Phandalin 5 with the Ducchess </li>
                <li> Sees peeps with Ducchess tshirts and stuffies </li>
                <li> Cecil gives 800 gold for a book mobile in Thon Daral and in Phandalin </li>
                <li> "...praise Ioun...may her eye shine with knowledge..." like 30 kids chant </li>
              </ul>
              <li> NEXT DAY </li>
              <li> Lars is not telling Elmar...but has Urchin check that Tiefling horns don't show up at birth </li>
              <li> Mike "Don't take this wrong but is her skin weird?" </li>
              <li> Robert: "Yours is purple Barney" </li>
              <li> Lars pays urchins to spread legend of the Five </li>
              <li> Pancake had studied Mythopedic so he's into it </li>
              <li> DAY ENDS </li>
              <li> Lars has a dream </li>
              <ul>
                <li> Sees brother with milk running down his beard....don't use my slave name </li>
                <li> Anger me...and the shrooms will kill you </li>
                <li>  Shows an image of Bamur...he says "Be ready my servant" and Bamur bows in prayer </li>
                <li> Dont make me mad...mom and dad made me mad and they are dead </li>
                <li> He is drinking 50% whole milk and 50% cream </li>
                <li> He wakes up sweaty next to Thistle </li>
              </ul>
              <li> Lars tells them all </li>
              <li> Cecil like...can we leave??  </li>
              <li> Sparka: Why doesn't your brother want you to go to the air plane </li>
              <li> Lars: ....cause my dick is big.... </li>
              <li> Lars I can make them think I am here </li>
              <li> Lars NAT 20 Deception to have urchins convince people he isn't leaving for the Air Plane </li>
              <li> The Urchins spread rumors he is going on competitive retreat to see who can stay out longer </li>
              <li> Cecil like can we scry on your bro? </li>
              <ul>
                <li> Lars like HERE IS MY BLOOD cuts arm </li>
                <li> Cecil's mom is like WHOA </li>
                <li> Dream Flame fails Scry save </li>
                <li> See Dreamflame answering a prayer to Yan C Bin </li>
                <li> See Dreamflame answering a prayer to Mushroom Queen </li>
                <li> Sparka how is your bro so powerful? </li>
                <li> Lars: No alcohol? </li>
                <li> Corpo: Semen retention? </li>
                <li> Mike: WTF is happening </li>
                <li> DM: We will catch you up </li>
              </ul>
            </ul>
          </Collapsible>

          <h2>
            <br/>
            Part 3: Inter Planar War
            <br/><br/>
          </h2>

          <Collapsible
            title="Small Business and AIR PLANE (#32 - 12/2)"
            toggle={this.collapseToggle.bind(this)}
            kind="session32"
            show={this.state.show}
          >
            <ul>
              <li> Sparka and Cecil go to Small Biz Council </li>
              <li> Sees a Barbish fish monger in a rice hat and barbaka and hands in Asian love </li>
              <li> Also a Half-Orc looking at flowers </li>
              <li> Sparka talks to the Barbish One.. </li>
              <ul>
                <li> It's Ben </li>
                <li> Has a storke and switches personality from Jewish to Asian </li>
                <li> They were from Phandalin....whole drama things coming out of the sky </li>
                <li> My people are industrious wherever we go </li>
                <li> Our people built in Faerûn made ice pyramids </li>
                <li> Met a sexy Air Genasi....Sparka has never met one </li>
                <li> Something about a smorgashboard and cream cheese </li>
                <li> Sparka is like dafuq is Sunfire.... </li>
                <li> My full name is Yentil Wantanabe...DID diagnosed by a wonderful Bard </li>
                <li> Here to network </li>
                <li> Cecil does not give his last name </li>
                <li> Cecil asks why he came...says his wife left him for a barbarian with a giant DONG flask that never ends </li>
                <li> He went through a portal...a little person SUNFIRE LIKE WIRDON FIDDERDICK </li>
                <li> He had always rived there. </li>
                <li> The canned fish business went under so.... </li>
              </ul>
              <li> Half Orc walks up. Are you looking for new business oppurtunities </li>
              <ul>
                <li> Mike Hamoy IS Orklahomo </li>
                <li> They ask how long he has been a while...WE have been here a while. Run a flower shop </li>
                <li> We is not his pronouns....the mafia </li>
                <li> Sells...product...flowers? First couple might be free.... </li>
                <li> Sparka would trying some later...send some to your room....wait....whaaaaaaat </li>
                <li> Some roses have flowered....others deflowered... </li>
                <li> Sparka is like you and the tin fish man would be into it </li>
                <li> Orklahomo is like wait....we got leaves? Leaves for inflamation? Information? No information freaks us out? </li>
                <li> Mums the word? YOU WANT A MOM? </li>
                <li> We can be discretely...we can send to your room? Anyone who might want some flowers... </li>
                <li> A friend of mine picked a flower from a garden...messing with business if the flower doesn't get back....so do you have dragonborn flowers? </li>
                <li> No, vajandras....pheresas...pussy willows...snap dragons....the flower doesn't sound familiar....certain breeds and colors but not that </li>
              </ul>
              <li> The Waukeen Cleric comes over </li>
              <ul>
                <li> Orklahomo comes over and asks if the Vajandras were good....did they...do your service? </li>
                <li> The cleric is like I planted them in front yard...he is like they like it great in the back </li>
                <li> No one has a flower garden except for us...my flower garden will come to you....PLANT EM IN THE BACK </li>
                <li> You don't want me to come? NOT HERE </li>
                <li> Want our flower gardens to stay in their respective areas </li>
              </ul>
              <li> The talk begins with Sparka talking to the group </li>
              <ul>
                <li> Covers the arrangements with Faerûn and how they can come shop here.  </li>
                <li> Thanks for the hospitality from Phandalin refugees </li>
                <li> Also about how Cecil made a bookmobile </li>
                <li> Establish well used trade between worlds...yes yes no flower garden mixing </li>
                <li> FLOW OF GOLD </li>
                <li> Yentil is like wait....did he say GOLD???? </li>
              </ul>
              <li> Pounding on the door as a new one enters </li>
              <ul>
                <li> I OBJECT! </li>
                <li> Jakarius Welchstone the Gnome is a fine wolf suit </li>
                <li> SMALL BUSINESS IS THE PAST </li>
                <li> We need corporations, they need cheap consumer goods. Lets find some cheap labor and get people fat happy and lazy </li>
                <li> Sparka like who wants to be fat and lazy? Cheap crappy goods? </li>
                <li> Jakarius is like Bad Jobs? That's bad </li>
                <li> Who do I speak for? Jakarius is like I speak for Big Business </li>
                <li> I work on Wallington Street....get money for shareholders? </li>
                <li> Who are shareholders? </li>
                <li> ME </li>
                <li> Bad jobs? Intellectual jobs you get to sit and think? </li>
                <li> Yentil is like Ergonomic chairs? If you are valued enough </li>
                <li> IF THE SHAREHOLDERS VALUE YOU! Sparka is like he has a small dick! no one laughs though </li>
                <li> Small business is actually ASPIRING BIG BUISINESS COUNCIL </li>
                <li> Who backs me? Some mysterious Wet Stone and Black stone and my friends on Wallington Street....better you don't know who backs them </li>
                <li> Cecil mentions the Cult of the Dragon  </li>
                <li> Cecil NAT 20 Insight to catch a small business owner start running away as the Cult of the Dragon is mentioned. </li>
                <li> Sparka calls after him. I do Koala Tea. He gets his tea from a wholesaler </li>
                <li> Sparka: Why did you get into the tea business? </li>
                <li> Him: I like talking to people </li>
                <li> Jakarius: Wouldn't you like to make more people tea and make more conversations fluid.... </li>
                <li> He: Ooooh if I can see their faces.... </li>
                <li> Sparka is like no no no no more in the back being enslaved getting next to nothing...how little would they be paid </li>
                <li> Jakarius whoa more than they have made before....how low would they go? </li>
                <li> Sparka NAT 20 to persuade him that big business is bad </li>
                <li> Jakarius is losing the room...come on trickle down it down! </li>
              </ul>
              <li> Cecil then does Charm Person... </li>
              <ul>
                <li> Tea Guy, Jakari </li>
                <li> Yentil Wantanabe NAT 1 Wisdom to save and says something about how he is gay... </li>
                <li> Oklahomo charmed and asks him if he wants to buy flowers.... </li>
                <li> Cecil like nooot interested in them but maybe what info they might collect </li>
                <li> Jakari is like blast your face all over the Empire...you have skills! </li>
                <li> Cecil is like hey Koala Tea....what's with your deal </li>
                <li> He is like we don't grind our Koalas anymore </li>
                <li> Cecil is like send him some flowers to then send me the info </li>
                <li> Yentil mentions having a tiny Kooara. In Faerûn we have word...choad </li>
                <li> Koala Tea guy doesn't get the metaphor he asks for younger flowers and leaves since you make tea.... </li>
                <li> Mike admits he went to far by asking if he wants a little fuzz </li>
                <li> Cecil asks where he gets grinding tea now...he says a Greg...Ol Greg....a Water Genasi </li>
                <li> Cecil is like hey when I said Cult of Dragon you freaked out.... </li>
                <li> Koala tea guy like YEAH...everyone looking at me... </li>
                <li> He is like they Cult came round a week ago and were like hey your tea is kinda shitty...he is like that hurts want some they like noooo </li>
                <li> Orklahomo offered some pusss...flowers....they liked some Cliterisias flowers...Yentil looking for a canoe </li>
                <li> He says if he told the Cult about the Phandalin 5 the Cult would reward them by not killing my uncle! </li>
                <li> Said they'd be watching....Sparka and Cecil assume they'd be around. They were wearing cruddy masks.... </li>
                <li> Orklahomo is like some flowers like that...Cecil is...confused.... </li>
                <li> Yentil asks if flowers can wear twigs to peg and Mike is like WTF BEN </li>
                <li> Lars somewhere yells every Rose has its thorns </li>
                <li> Tea shop describes a Half-Elf and 2 humans </li>
                <li> Cecil is like got anything from your Uncle? Hands a quarter from his uncle </li>
                <li> Jakarius is like Big Business you get an Aldi shopping cart </li>
                <li> Cecil is like we don't like big business... </li>
                <li> Jarkarius is like why not?? </li>
                <li> Sparka is like the shareholders, not the people profit </li>
                <li> Jarkarius is like hell yea Jakarius wants to profit </li>
                <li> Yentil is like my people like to profit.... </li>
                <li> He had to put a red stone from them on a certain fountain when he had info </li>
                <li> Cecil is like please don't tell him....gets dirty 21 without anything else..... </li>
                <li> Orklahomo heard it all and works for the Yakuza.... </li>
                <li> Tea Guy...never got his name....goes to shop to cry </li>
                <li> Cecil like Orklahomo...you were listening...I wouldn't say anything... </li>
                <li> Cecil like they are serious </li>
                <li> Orklahomo like serious about getting some vajandres </li>
                <li> Cecil like no about killing people </li>
                <li> Ork ...oooooooh </li>
                <li> Orklahomo like that is bad for inventory </li>
                <li> Cecil like go have some leaf? </li>
                <li> Nah he likes keeping his sensitivity high </li>
                <li> Yentil follows to ask about the twig service </li>
              </ul>
              <li> Sparka ends saying at the end of the conference saying all will benefit and we should all be good in small business...keeping $$$ here. NOT BIG BUSINESS </li>
              <li> Sparka is like I will help any of you with help, advice, or marketing to increase trade between our lands </li>
              <li> Sparka also mentions...for Lars...Dawrven Dick Lager and they like...ok </li>
              <li> Sparka is like we are helping the Empire to secure their borders and we will keep helping them </li>
              <li> Cecil wants to operate a spy ring out of Orklahomo's prostitute shop (Eric will cover them next time since Orklahomo) </li>
              <li> Bridget read books seamstresses spying on German women </li>
              <li> Robert debated Psychic Lancing...and I'm like would probably kill him...BEN IS LIKE WTF YOU TELL PEOPLE </li>
              <li> Mike is like he asked me fo sho before I had Duchess bite a head off </li>
              <li> Adam like that first night I almost killed Wildon I realized ACTIONS HAVE CONSEQUENCES </li>
              <li> BREAK </li>
              <li> Lars searches for a disguise to hide himself since he said he wasn't going </li>
              <li> Lars/Corpo/Sass decide to go in the Cosmic Deuce Canoe </li>
              <ul>
                <li> The canoe is loud of course and guards are...like..uhhhhh </li>
                <li> Corpo is dressed as a f-ing Yentil though he doesn't know him </li>
                <li> Guard is like you can go through one of these three portal </li>
                <li> Lars shaves his beard to look like a Mormon </li>
                <li> Sass sits regally in the middle </li>
                <li> Go through the middle on a lake in the middle of a floating island </li>
                <li> Can see flying ships floating on the other docks. </li>
              </ul>
              <li> Team YOLO arrives at the docks </li>
              <li> A guard is like Master Dwarf how can I help? </li>
              <li> Lars: I AM HERE FOR THE WAR! </li>
              <li> Ships are leaving in the morn...Lars asks if nearby bar....guard points out the nearby bar and they see the giant blue yonder </li>
              <li> Lars, Corpo, Sass go to the bar </li>
              <li> Sparka and Cecil go back to the Grimstone tavern...they gone </li>
              <ul>
                <li> Cecil goes to Thistle's room </li>
                <li> two urchins with a sharp knives and Plata are there.  </li>
                <li> Cecil asks where's Lars....CLASSIFIED  </li>
                <li> Sparka pushes past the urchins draw knives.... </li>
                <li> DONT do it </li>
                <li> Plata is like fine THE LADY THISTLE WILL SEE YOU </li>
                <li> She is in a full Maternity gown but like a month in </li>
                <li> How are you? PURPLE </li>
                <li> They like COME ON THISTLE TELL ME </li>
                <li> Even with disadvantage they clear both 20 persuasion </li>
                <li> Ben tries to say it's getting hot...NO say Robert </li>
                <li> She is holding what to expect when you are expecting a Tiefling...notes say "dwarf .... smaller baby? Tiefling..... tail?" </li>
                <li> She hesitates and the two know Lars and them left </li>
                <li> Sparka is like Lars is the father of your child...you can't let him make decisions </li>
                <li> They get Thistle get freaked and Cecil is like you don't want to raise.... </li>
                <li> "You don't need to tell me to be nervous I got that own...if I ever calm down then tell me" </li>
                <li> They are like we are going to go with the military convoy...we will keep an eye out for them </li>
                <li> Cecil is like No we don't change our plan via telepathic bond </li>
                <li> Plata is like tell the Master Dwarf I hope he has a wonderful retreat.... </li>
                <li> Sparka: DONT EVER CALL HIM THAT </li>
                <li> Sparka tells her they have their good interests at heart </li>
                <li> Cecil tells her on the way out the door... You can control him...oh....and congratulations </li>
                <li> Half Elves stamp feet frustratedly...refuse to buy their own Deuce Canoe </li>
                <li> Cecil wonders if going Manta Ray would kill Corpo via Orgasm </li>
              </ul>
              <li> Team YOLO </li>
              <ul>
                <li> Enters 3 story bar </li>
                <li> Soldiers and sailors everywhere. Multiday trip so all getting drunk </li>
                <li> Lars buys a round of drinks for the bar...they like MUSTACHE DWARF! </li>
                <li> They ask Sass if the curtains match the drapes....ROCK EVERYWHERE? He is like I'm all hard.... </li>
                <li> Corpo Minor Illusions slanty eyes? What in the Orientalism.... </li>
              </ul>
              <li> Cecil goes to Mom...scry on the coin </li>
              <ul>
                <li> They are in a room not Grimstone </li>
                <li> Tell boss on speaking stone that Dwarf is on a silent retreat...yea...can't believe it either  </li>
                <li> We will check on the P5 and report back...we will monitor the Temple of Kord and go from there... </li>
                <li> HAIL TIAMAT (Mike is like WTF hail Tiamat? Bitches gonna die) </li>
                <li> Decide to go watch the Temple  </li>
              </ul>
              <li> Hop to Cecil and Sparka sneaking on the Temple </li>
              <ul>
                <li> Hoods up to hide ears </li>
                <li> Catch a guy sneaking around </li>
                <li> Korg Up is all like WOOOOOOO GAME AND COMPETITION </li>
                <li> An urchin is like THE MASTER IS GONE BUT WE ARE TO PLAY </li>
                <li> The spy comes talk to Korg...looking for the Dragon! </li>
                <li> Korg is like spirit of competition moves him...he will return if he wants to be.... </li>
                <li> She scans the crowd </li>
              </ul>
              <li> TEAM YOLO </li>
              <ul>
                <li> Lars is chatting people up </li>
                <li> They think Corpo is weird...in his Japanese voice.... </li>
                <li> Corpo buys shots </li>
                <li> He is like whoa all the planes are coming to help </li>
                <li> WE HAVE TO GO TO HELP SO THE PLANES LIKE OUR PLANET! FOR OUR CHILDREN'S CHILDREN MOTHERFUCKER I LOVE THIS SHOT </li>
                <li> Sailor is like wtf you have minor illusion on your eyes </li>
                <li> Corpo is like my eyes are f-ed up </li>
                <li> Sailor is like oh so it's for us...respect </li>
                <li> Lars talking about bars </li>
                <li> How do you get to upstairs? </li>
                <li> You have to be invited </li>
                <li> Corpo tries flying and a dude flies out and gets stopped.... </li>
                <li> Of course in Japanese accent </li>
                <li> Lars challenges biggest baddest dude to wrestling...the muscular Nazi from Indiana Jones </li>
                <li> Sass challenges a modest archery challenge of shooting throw a hole in 20 axes...dude with 3 longbows like...you shoot? </li>
                <li> Corpo goes to the 2nd floor and tells bouncer he is related to Haalinith....discards rice hat dramatically </li>
                <li> An officer takes Corpo to the 3rd floor </li>
                <li> Sass wins twice! Just beating him. Like dude gets near bullseye...Sass does better </li>
                <li> The archer is like ATTENTION BAR! This morning I was the greatest archer to ever live...now it is him </li>
                <li> Tells barkeep if he doesn't give Sass free drinks he will find all his family and destroy them </li>
                <li> Lars does fireman toss, crowd cheers...then he humps him and the crowd is confused </li>
                <li> Someone then brings Lars and Sass to level 2 </li>
                <li> Officer on floor two chucks bottles off the edge and is like hit them archer or people get hurt. </li>
                <li> Sass snipes them both LIKE A BOSS </li>
                <li> They are like HOT FUCK. Sass is like yeah I'm hot fuck </li>
                <li> They ask Lars if he is talented...Lars decides to be modest....he says I have the biggest DICK </li>
                <li> Lars NAT 1 Animal Handling to make his dick big </li>
                <li> Other guy and Lars both roll low...stagger to bar to have another drink...then Lars gets it up and big </li>
                <li> Someone takes Lars and Sass upstairs to entertain and calm the aristocrats...they super drunk </li>
                <li> Corpo talks to them upstairs a Legate....makes a comment about being related to Haalinith but also about sucking his own dick? (hard to hear) </li>
                <li> They get Corpo a glass of scotch and say tell us about your brother....that he doesn't know </li>
                <li> Sass and Lars get up there...and the aristocrats .... </li>
                <li> Corpo NAT 20 Persuasion to say ahh straight of shot and wide of girth to get them introduced to the legate crowd </li>
                <li> Lars is like we want the war </li>
                <li> Corpo yells Justice! Then internally tells himself to suck his own dick </li>
                <li> Ben claims this is all Corpo acting out being rejected by Cecil. </li>
                <li> Sass is like I am going to serve Bahamut...PRAISE BAHAMUT may his claws be sharper than Corpo's needle dick </li>
                <li> Sass like he doesn't like homelands taken...not just </li>
                <li> They give super fancy scotch and try to build camaraderie with these high ranking officers  </li>
                <li> Sass asks who they are bringing... </li>
                <li> Legate is like we are bringing standard soldiers only (wink wink) 700 from the Imperium, couple hundred from dborn realms, couple hundred elves, maybe some more Imperials that they teleport in later, top billing who don't ride on ships </li>
                <li> Generally ok vibes. Legate says you guys are fairly cool... "We know" ... so he orders pork butt </li>
                <li> Lars is like who is gonna be leading the war </li>
                <li> Legate says me </li>
                <li> Lars is like but...who really is.... </li>
                <li> Legate is slightly offended and asks Sass (Greosass the Clever...no clan name) if he associates with this mustachioed dwarf) </li>
                <li> 10 Imperial ships...mercs from other planes, Feywild. </li>
                <li> Lars like I am not rude...mustache is intimidating....but I want to kill the most people </li>
                <li> Legate is like I'll get you into the shit </li>
              </ul>
              <li> Cecil and Sparka track her </li>
              <ul>
                <li> Goes into Bowser's senses to spy and follow the agent </li>
                <li> Get to a 4 story shitty hotel that is not open for business...boarded up windows </li>
                <li> An agent notes Bowser...Bowser hides NAT 20 Stealth check </li>
                <li> Can't see anyone inside...an alley and 4 story building </li>
                <li> The crew peak in as the window opens...they see just the person alone with a go bag on the bed </li>
                <li> Minor Illusion knock on the door </li>
                <li> Cecil does message to her "The Dragon didn't actually stay back"....person runs to grab their speaking stone </li>
                <li> Cecil is like do we chill... RYAN IS LIKE ATTACK!!! </li>
                <li> They roll initiative!  </li>
                <li> Bowser does not see any other ways in or out...holds action </li>
                <li> Sparka shoves the person away from their speaking stone </li>
                <li> Cecil Misty Steps into their room between person and their speaking stone...grabs speaking stone </li>
                <li> Vicious Mockery LOOKING FOR THIS?? </li>
                <li> Agent is like wtf who are you </li>
                <li> "...no one comes after us" Sparka says </li>
                <li> I thought you were helping me! </li>
                <li> "You were mistaken" </li>
                <li> They try to grab the speaking stone...grappling with Cecil </li>
                <li> Sparka Max Grasp him, grasping Cecil </li>
                <li> Cecil rips out stone and is like WHO IS ON THE OTHER </li>
                <li> ...my boss </li>
                <li> WHO IS YOUR BOSS </li>
                <li> Javier...Darbem...not Bardem </li>
                <li> He sent him to track the Phandalin 4 (Ben: Fuck you) </li>
                <li> We watch you cause you the biggest threat to the Cult's plans... </li>
                <li> Boss is across the sea...she is from across the sea to the Pentarchy </li>
                <li> Sparka like wanna go to the inquisition prison? </li>
                <li> Spits in her face... </li>
                <li> "That's where you gonna wake up" Sparka casts sleep </li>
                <li> They rifle through her shit </li>
                <li> Sparka NAT 20 Investigation to search her bags...finds cool ass poison </li>
              </ul>
              <li> Cecil and Sparka take the agent to Dorrissa </li>
              <ul>
                <li> She is like...oh! An agent from the Pentarchy </li>
                <li> Sparka catches Dorrissa looking at Cecil </li>
                <li> GDE grabs her by the head...and drags to a cell </li>
                <li> Stardust Buttersparkle...drunkenly takes the speaking stone to reverse engineer it </li>
                <li> GDE looks at Sparka and he recalls that there was a Fairy at the Battle along with his mother </li>
              </ul>
              <li> Ben tells Mike to twist his own dick </li>
              <li> Cecil and Sparka decide to tell Dorrissa that Fire Plane scouting Air Plane </li>
              <li> Oh that is very important </li>
              <li> Cecil and Sparka are like can we not stay in the tavern since it was being watched </li>
              <li> Dorrissa sets them up in a barracks in the air plane </li>
              <li> They see tons...light from everywhere...ambient light...no shadows in the air plane </li>
              <li> They spot the Cosmic Deuce Canoe </li>
              <li> Sparka Arcane Locks the barracks suite door as team YOLO comes in </li>
              <li> Sparka is like "Occupied" </li>
              <li> Lars like "SPARKA!" tried to open the door but Cecil does Immovable Rod and let's just say it doesn't open </li>
              <li> "Let us in!" </li>
              <li> "No" </li>
              <li> "Wake us in the morning?" </li>
              <li> "ugh" </li>
              <li> Everybody wakes up in the Air Plane and sees each other out the door </li>
              <li> Lars calls them assholes...Sparka "WE ARE???" </li>
              <li> Lars: "Go to your small business council meeting and leave us" </li>
              <li> Sparka: "YOU LEFT YOUR PREGNANT WIFE.." </li>
              <li> Lars: "I DON"T HAVE A PREGNANT WIFE I HAVE A CONCUBINE" </li>
              <li> Cecil "She gonna keep you on a much shorter leash" </li>
              <li> Lars: "...I do like to be on the collar" </li>
              <li> Korg Up comes up to them covered in water. He swam there after leaving an urchin in charge until out wrestled </li>
              <li> They get their own smaller vessel as Korg... the ship "Darius Brenda" </li>
              <li> On the ship Lars holds up Corpo "WE DID IT! IM FLYING!" </li>
              <li> Lars paints Corpo nude like one of his Faerûnian women </li>
              <li> Dorissaa walks up with GDE, sends them off...warning some factions in the Imperium that don't ally with her...and other worlds and factions are a problem </li>
              <li> GDE walks on their ship...she says take care of him </li>
              <li> GDE says he snores...walks into the ship and snores....if you can walk don't run... </li>
              <li> Sleeping in a comically small hammock with Glaive in his arms </li>
              <li> Sparka joins the presti fireworks as the armada goes off! </li>
              <li> 12 imperials ships...1 ship of sun elves...1 ship of moon elves....1 ship of dwarves </li>
              <li> Ben plays Muppets Treasure Island and I don't hear shit. </li>
              <li> Cover how gravity is for sentient beings in the AIR PLANE...Corpo knows about it.  </li>
              <li> So perception of gravity....so...Cognitive Behavioral Therapy? </li>
              <li> STORM BREWING a couple hours into the voyage </li>
            </ul>
          </Collapsible>

          <Collapsible
            title="The Battle of Cinderhaven - Lake Geneva Tokyo Drift  (#33 - 1/5)"
            toggle={this.collapseToggle.bind(this)}
            kind="session33"
            show={this.state.show}
          >
            <ul>
              <li> START ON THE SHIP </li>
              <li> Storm is coming </li>
              <li> The front of the Darius Brenda is a random old Muppet Man </li>
              <li> Cecil asks Korg what to do </li>
              <li> Lars and Sass grab ropes </li>
              <li> Corpo gusts into the sails </li>
              <li> Wind and rain in their faces...Con saves ALL PASS as they hold on it </li>
              <li> Some lines get free...Lars grabs one </li>
              <li> Ship lurches...Sparka and Corpo fly off into the storm </li>
              <li> NAT 20 Lars Dex Save to hold on </li>
              <li> Cecil sends bowser to help Sparka...Corpo "fuck you" Cecil "YOU ARE MADE OF WIND" </li>
              <li> Sparka mentally controls gravity to move himself back to ship perfectly </li>
              <li> Corpo flies back over....backstrokes it </li>
              <li> GDE sleeps it </li>
              <li> 17 other ships pull selves together </li>
              <li> NEXT DAY IS SMOOTH </li>
              <li> dim day light alllllllll day like Alaska </li>
              <li> Lars goes to tan sooooo well </li>
              <li> Cecil and Sparka chat on the ship...talk about what is next is probably Uncle since Tiamat summoning still #1 priority </li>
              <li> Sparka decides to use mask after consulting with the party </li>
              <li> DAY SMOOTH </li>
              <li> Mist arrives as people say they are nearing destination </li>
              <li> Cinderhaven in distance as they see the Ash Plane's boundary...often a trade post near the boundary </li>
              <li> See mist and fog moving in...as Cinderhaven covered by ships cannot be seen </li>
              <li> They hear noises.....see fire bursts near the clouds ala Pirate combat movie </li>
              <li> Take positions...Corpo to the front, Lars twitching axe...Cecil holds the safety rail....Sass draws bow...Sparka in the middle...Duchess by Sass...GDE sleeps </li>
              <li> Sparka and Corpo give Sass shit about  </li>
              <li> NAT 1 Lars Perception to check for Dragon </li>
              <li> Sass sees glowing red on Sass' arm....THE DRAGON SENSING DAGGER </li>
              <li> Lydia says slay all the dragons...Ray says don't die </li>
              <li> See an Red Dragon...coming at him...can see it is an Adult </li>
              <li> Robert has Dragonbreath out his ass </li>
              <li> Crew doesn't know much about Salamanders and Sootborn </li>
              <li> They know Red Dragons are Chaotic Evil...immune to fire...most elevated of the Chromatic dragons...etc. Has Daddy and Mommy issues (since Chaotic evil) </li>
              <li> ROLL INITIATIVE  </li>
              <li> ROUND 1 </li>
              <ul>
                <li> LARS </li>
                <ul>
                  <li> Hurls javalin hits Shahmata for 9 as he yells I AM THE DRAGON! </li>
                  <li> Red Dragon yells IMPOSTER RODENT! </li>
                </ul>
                <li> Sootborn Soldier </li>
                <ul>
                  <li> Ship pulls up </li>
                </ul>
                <li> SASS </li>
                <ul>
                  <li> Shoots at Shahmata but misses twice...Shahmata yells pitiful insect at him </li>
                </ul>
                <li> CORPO </li>
                <ul>
                  <li> Moonbeam but Shahmata resists as it builds fire in it's mouth...then runs to another ship to get away from the Dragon breath </li>
                </ul>
                <li> Shahmata </li>
                <ul>
                  <li> BREATH WEAPON for 74  72 cutting wordsw but people pass as Sparka uses LR </li>
                </ul>
                <li> Salamander </li>
                <ul>
                  <li> Tail grapples Corpo and hits him </li>
                </ul>
                <li> Sootborn Soldier </li>
                <ul>
                  <li> Sword swipes Corpo </li>
                </ul>
                <li> SPARKA </li>
                <ul>
                  <li> Casts fireball BUT transmutes it to COLD BALL and wrecks some Salamanders and Sootborn </li>
                  <li> Double damage to Salamanders </li>
                  <li> TK shove Salamander away from Corpo </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> ZF LUTE gravity well lifts a bunch of foes up </li>
                </ul>
                <li> Sootborn Soldier #3 B1 </li>
                <ul>
                  <li> Misses Corpo </li>
                </ul>
                <li> Sootborn Soldier #4 B2 </li>
                <ul>
                  <li> Shoots and misses Sass </li>
                </ul>
              </ul>
              <li> ROUND 2 </li>
              <ul>
                <li> LARS </li>
                <ul>
                  <li> ERUPTING EARTH on Salamanders </li>
                  <li> Kill Sootborn soldier up his smokey ass </li>
                  <li> Kills Salamander with Erupting Earth Skewering through his tail </li>
                  <li> SECOND WIND </li>
                </ul>
                <li> Sootborn Soldier </li>
                <ul>
                  <li> Bolt bounces off mask </li>
                </ul>
                <li> SASS </li>
                <ul>
                  <li> NAT 20 HAIL OF THORNS Kills Sootborn </li>
                  <li> Kill Sootborn with Hail of Thorns explosion </li>
                  <li> Kill Sootborn by hitting him really hard </li>
                </ul>
                <li> CORPO </li>
                <ul>
                  <li> KILL Sootborn by triple punch Flury of Blows Heating Up! </li>
                </ul>
                <li> Shamata </li>
                <ul>
                  <li> Robert rolled 11/20 so incinerates another ship as people scream burning </li>
                </ul>
                <li> Salamander </li>
                <ul>
                  <li> Another ship pulls up and jumps on </li>
                  <li> Throws a spear at Corpo...catches it....chucks it back being charged by Duchess </li>
                </ul>
                <li> GDE </li>
                <ul>
                  <li> Wakes the fuck up and charges the other ship </li>
                  <li> Path of Giant Lightning Rages so gets 12 foot tall </li>
                  <li> NAT 20 Polehammer to fuck up Salamander </li>
                  <li> Misses the butt Bonus Action </li>
                </ul>
                <li> SPARKA </li>
                <ul>
                  <li> Icebolt but misses </li>
                  <li> Jumps to other ship </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> PULSE WAVE at the newly arrived ship at Salamander and 3 on the ship </li>
                  <li> Kill Sooborn by shoving via Pulse Wave off their Ship </li>
                  <li> Kill Sooborn by shoving via Pulse Wave off their Ship </li>
                  <li> Kill Sooborn by shoving via Pulse Wave off their Ship </li>
                  <li> Shoves the ship </li>
                  <li> Kill Salamander by shoving via Pulse Wave off the ship but moving the other ship so it fell </li>
                  <li> Damages the ship </li>
                </ul>
                <li> Sootborn Soldier </li>
                <ul>
                  <li> Shoots and misses </li>
                </ul>
              </ul>
              <li> ROUND 3 </li>
              <ul>
                <li> LARS </li>
                <ul>
                  <li> Kills Salamander with Javelin and yelling I am the dragon </li>
                  <li> Misses other Javelin </li>
                </ul>
                <li> SASS </li>
                <ul>
                  <li> Comes over to new ship </li>
                  <li> Heals Sparka via Blowing on him </li>
                </ul>
                <li> CORPO </li>
                <ul>
                  <li> Debates killing Korg and splitting the party </li>
                  <li> Goes to original ship as Corpo mini-fig breaks...and starts flying the ship </li>
                  <li> Turns the boat to start turning the burning boat </li>
                </ul>
                <li> GDE </li>
                <ul>
                  <li> Goes and grabs a new ship and steers it off into the clouds </li>
                </ul>
                <li> Shahmata </li>
                <ul>
                  <li> Turns and comes back... </li>
                  <li> NAT 1 to see the party is on the other ship </li>
                  <li> FRIGHTFUL PRESENCE welp Sass is immune </li>
                  <li> NAT 1 Sparka to resist Frightening Presence </li>
                  <li> Attack and sink teeth into Corpo </li>
                  <li> Drop Down Corpo with a Claw Attack </li>
                  <li> Coup de Grace Corpo as he falls so 2 death saves </li>
                </ul>
                <li> WE ALL AGREE ADAM SHOULD DRINK MORE </li>
                <li> This is not the darkest Timeline for Corpo </li>
                <li> SPARKA </li>
                <ul>
                  <li> Twins Haste on Sass and Lars </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> Psychic Lances Sahmata...resists </li>
                  <li> Still afraid </li>
                </ul>
              </ul>
              <li> ROUND 4 </li>
              <ul>
                <li> LARS </li>
                <ul>
                  <li> Runs below deck </li>
                  <li> Is still afriad </li>
                </ul>
                <li> SASS </li>
                <ul>
                  <li> Cure Wounds Corpo </li>
                  <li> NAT 20 Corpo Perception check to see Sass standing above him and EVERYTHING </li>
                  <li> Shoots the dragon with Haste attack..Sharpshooter HITS </li>
                </ul>
                <li> CORPO </li>
                <ul>
                  <li> Coughs up blood and has claw marks through his flesh </li>
                  <li> Runs below deck...leaving Sass alone to get attacked.  </li>
                  <li> Sass: calls him not a decent fucking team mate. </li>
                  <li> Sass: MOVEMENT DOES NOT HEAL PEOPLE </li>
                  <li> Sass: "YOU LEFT ME TO DIE TO THE DRAGON NOW YOU WANT TO SUCK HIS DICK" </li>
                </ul>
                <li> Shamata </li>
                <ul>
                  <li> BREATH WEAPON BACK! </li>
                  <li> Strafes Sass, Cecil, and Korg.... </li>
                  <li> Cecil Down...Sass Down...Korg Down...Bowser gone... </li>
                  <li> Shahmata lands in front on the boat </li>
                </ul>
                <li> SPARKA </li>
                <ul>
                  <li> SMOKE BREAK? Did I miss it? </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> Pass Death Save </li>
                </ul>
              </ul>
              <li> ROUND 5 </li>
              <ul>
                <li> LARS </li>
                <ul>
                  <li> RAGES </li>
                  <li> Walks parallel with the dragon to attack...RECKLESS </li>
                  <li> ACTION SURGE + Haste 5 ATTACKS. </li>
                  <li> Hits 3 Times </li>
                  <li> Missed Opportunity attack </li>
                </ul>
                <li> SASS </li>
                <ul>
                  <li> Fail Death Save  </li>
                </ul>
                <li> CORPO </li>
                <ul>
                  <li> Goes up above deck...not afraid </li>
                  <li> Goes up to the Dragon's face...YOU KNOW WHO I SERVE...tried to convince him....but </li>
                  <li> LIGHTNING BOLT. Shamata burns a LR to take 12 damage </li>
                  <li> Misses punches </li>
                </ul>
                <li> Shamata </li>
                <ul>
                  <li> I skipped his turn....did it later for him to down Corpo...then  </li>
                </ul>
                <li> SPARKA </li>
                <ul>
                  <li> Afraid but cold Fire Ball...he passes the Dex </li>
                  <li> NOT AFRAID! </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> Fail Death Save so 1 and 1 </li>
                </ul>
              </ul>
              <li> ROUND 6 </li>
              <ul>
                <li> LARS </li>
                <ul>
                  <li> Hits Dragon twice </li>
                </ul>
                <li> SASS </li>
                <ul>
                  <li> Pass Death Save </li>
                </ul>
                <li> Shamata </li>
                <ul>
                  <li> Skipped so did it now...Kills Corpo...rips steering wheel...the flies away </li>
                </ul>
                <li> CORPO </li>
                <ul>
                  <li> Death Save </li>
                </ul>
                <li> Shamata </li>
                <ul>
                  <li> Did dragon turn out of order but let him fly further </li>
                </ul>
                <li> SPARKA </li>
                <ul>
                  <li> Runs up after Shamata....unloads Wand of Magic Missile </li>
                  <li> Doesn't heal Cecil...does Quickened Spell to do firebolt to have the cold damage from Winter's Fury </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> Pass Death Save </li>
                </ul>
              </ul>
              <li> ROUND 7 </li>
              <ul>
                <li> LARS </li>
                <ul>
                  <li> Comes up stairs and hurls 2 Javelin's at disadvantage </li>
                  <li> ALL HIT... </li>
                  <li> DRAGON SLAYER LARS BECOMES THE DRAGON! SHOT OF MALORT </li>
                  <li> First one gets in wing...turns....another between the eyes </li>
                  <li> Gets his red scales on his dick and a scale under eye like a tattoo...ribbed for her pleasure </li>
                  <li> Takes Draconic Presence </li>
                  <li> ALSO gets a d4 when he throws a Javelin </li>
                </ul>
                <li> SASS </li>
                <ul>
                  <li> Fail death saves </li>
                </ul>
                <li> CORPO </li>
                <ul>
                  <li> Fail death saves </li>
                </ul>
                <li> SPARKA </li>
                <ul>
                  <li> Gives Cecil potion off his belt </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> Healing Word Sass </li>
                  <li> F-Corpo he starts Find Familiar Ritual </li>
                </ul>
              </ul>
              <li> ROUND 8 </li>
              <ul>
                <li> LARS </li>
                <ul>
                  <li> Potion to Corpo </li>
                </ul>
                <li> SASS </li>
                <ul>
                  <li> Potion of Greater Healing to Korg </li>
                </ul>
              </ul>
              <li> NOTE NEW SHIP FIGUREHEAD IS A FEMALE EFRETI </li>
              <li> GDE jumps off a sinking ship...3 of them go down and he is singed and bloody...Salamander dying by the neck </li>
              <li> See other ships collecting survivors and in the far distance some Fire Plane ships escaping </li>
              <li> Korg and them try to rig ship to fly without the steering column. </li>
              <li> Have a short rest of couple hours to Cinderhaven </li>
              <li> They abandon the burning Darius Brenda... </li>
              <li> GDE reveals in convo that he took a vow of celebacy....Corpo like I did that once 5 days ago </li>
              <li> Head to Cinderhaven...medieval Bespin City....they know that it is not the same process to fly like the ice shard...has a reactor of sorts....slower to move larger things </li>
              <li> One of the thousands of people arriving on the jutting out docks </li>
              <li> Corpo bow like father who leaves his child does not love him </li>
              <li> Arrive at the Dock </li>
              <ul>
                <li> Breathy air Genasi meets them and for some reason Corpo says he there to help his bro </li>
                <li> They are like...your brother? Wind Duke Hallinith? </li>
                <li> Why...why did you tell me? You know the complex air plane politics?  </li>
                <li> Corpo: Not at all </li>
                <li> We...we don't like him here... </li>
                <li> She says that the Mat Plane is all meeting in the Hall of Thunder...says leave a person to watch ship </li>
                <li> Corpo gets her name as Zephyria....he thinks that is female </li>
                <li> She's like....yes..I have clear breasts... </li>
                <li> She explains that he is consolidating power over other wind dukes...he hates the Djinn....they work together though now. He has taken Wind Duke titles from children as other Wind Dukes died....she is unsure if they died naturally </li>
              </ul>
              <li> See people all over place....humans, genasi, winged elves...they catch Yan C Bin priest on the hills </li>
              <li> Lars hopes they don't notice him....he is in a mustache and black face after all </li>
              <li> Hall of Thunder is Acropolis, Lincoln Memorial... </li>
              <li> They have some chouta </li>
              <li> At the bar Lars buys drinks for people.... </li>
              <ul>
                <li> A cool Gnome talks to Lars...he says only eyes for one lady </li>
                <li> Gnome rights a song about Ginobli, having eyes for one lady </li>
                <li> Trades his hat to Lars in exchange for meeting ladies... </li>
                <li> Corpo is like hey I have a muscular guy who loves pegging and points to Sass </li>
                <li> Scanalan takes it....Corpo says keep hitting on him and be gentle. Lars gives him a strong drink </li>
                <li> Corpo is like...he likes trains...becareful...autistic  </li>
                <li> Scanlan hits on Sass...you look very muscular.. </li>
                <li> Cecil walks up and tells Scan Man he is barking up the wrong tree and Corpo is the one into Pegging </li>
                <li> Cecil 23 Persuasion </li>
                <li> Sass is like my heart is so distraught.... </li>
                <li> Scanlan casts Modify Memory on Sass so Sass doesn't recall that he hit on him </li>
                <li> Cecil like the blue one....whispers...tells Scanlan he is into Manta Rays </li>
                <li> YOU TEED YOUR FUCKING SELF UP -Adam </li>
                <li> Scanlan Polymorphs into a Manta Ray and says hey....you...casts magnificent mansion </li>
                <li> Scanlan hands him a drink via Manta Ray fin </li>
                <li> Grog comes in the door and grabs Scanlan </li>
              </ul>
              <li> They see other groups from Primavera walk in....Gold, Moon Elves...Dragonborn sit apart....Togas and wild leather </li>
              <li> Moon Elves walk up and talk about slaying the dragons...but....ignore the Half Elves. Purchased ale for Lars </li>
              <li> Sparka noted the guy had similar nose as him...he follows him </li>
              <ul>
                <li> He is like...can I help you? </li>
                <li> Sparka is like idk I heard Moon Elves are respectful....maybe you are just not a good representation of these people </li>
                <li> Kerym Amarillis is his name and is like HAVE YOU HEARD OF US? KINDA BIG DEAL </li>
                <li> Sparka is like oh I know the name and you don't do it justice.... </li>
                <li> He is like how do you know that name PEASANT? </li>
                <li> My mother is Amali  </li>
                <li> He looks at the dragonshard and says "oh...your mother is the fucking whore..fucking piece of shit" </li>
                <li> Sparka like how did you know that?? </li>
                <li> He is like she stole that (dragonshard) </li>
                <li> Sparka is like PROBABLY TO KEEP OUT OF SHITHEAD HANDS LIKE YOURS </li>
                <li> Kerym says after this let's duel...Sparka reveals </li>
                <li> Sparka NAT 20 Investigation to get Kerym is a wizard of higher rank than him </li>
                <li> Sparka yells some of us HOPE YOUR BOOKS SERVE YOU WELL IN OUR DUEL...SOME OF US HAVE MAGIC IN OUR BLOOD </li>
                <li> He retorts that not from your piece of shit mother...must have found a good lay in he whorehouse </li>
                <li> Sparka laughs and says see you at the duel... </li>
                <li> Sass is like that was heated but he can't hear his voice...want me to shoot him? </li>
                <li> Sparka explains it </li>
                <li> Lars yells hey elves! and dumps beer on the floor but doesn't start a food fight </li>
              </ul>
              <li> Aral comes over and is like </li>
              <ul>
                <li> ...starts explaining his blood line..Sparka like we don't have to explain blood lines no matter what that dickhead said </li>
                <li> Aral is like I would delay the duel he is powerful </li>
                <li> He has heard of their features </li>
                <li> Lars asks him to spread the word of Ginobli the well endowded </li>
                <li> Corpo asks if he has met Haalinith....Aral is like he is super cunning and practically conquered the air plane </li>
                <li> Aral calls him respectable and the others are like wtf.. </li>
                <li> Tells Sparka Kerym has killed many of them </li>
              </ul>
              <li> Floating platform  </li>
              <ul>
                <li> Wind Duke Marwan Jaziran the Zaddy shows up </li>
                <li> Mike is like Habib Marwan...gotta be 24 Season 4 </li>
                <li> Marwan says tonight we can party a bit....but tomorrow we shall plan and distribute plans </li>
                <li> FIRE PLANE WILL ATTACK BY NOON TOMORROW </li>
                <li> He mentions lesser elemental deities like Akadi and Cham </li>
                <li> They can sleep on ships or in rooms </li>
                <li> He thanks them by the grace of the wind and floating of the thunderstorm </li>
              </ul>
              <li> Lars goes to buy housing </li>
              <li> Cecil and Corpo go to track Marwan </li>
              <ul>
                <li> Cecil asks what they know about the raiders </li>
                <li> He is breathy and says dozens of ships, dozens of floating earth bergs, floating fortresses... </li>
                <li> Trying to figure out how to attack them </li>
                <li> Cecil asks about Cult of the Dragon....but covered in rags in the Ash Plane so hard to tell </li>
                <li> Word they will attack by noon </li>
                <li> Corpo puts on airs...esteemed Wind Duke... </li>
                <li> Corpo is like what are your thoughts on my brother...Haalinith...he's like I've never met him </li>
                <li> The son of Marwan reaches for son...he asks Corpo if father or mother.... </li>
                <li> Father... </li>
                <li> Ah...they relax </li>
                <li> Corpo asks who his mother is...a Storm Giant </li>
                <li> HIS DICK IS HUGE??? Corpo thinks </li>
                <li> They are like yeah your dad has...a lot of kids </li>
                <li> Corpo is like I am working towards the Air Plane...not my brother's plans </li>
                <li> Cinderhaven is run by oligarchy of air noble families </li>
                <li> Haalinith and his forces are here...but not friends </li>
                <li> Marwan is like cool...in the morning I will put you in the epic strike force </li>
              </ul>
              <li> Sass goes looking for arrow purchasing </li>
              <li> Find a potion seller...Karnathian the Aarakokra and they buy a few </li>
              <li> They find room with a cranky old lady...Wyvern point overlooking the docks...she is mean </li>
              <li> Sparka tells Maisie they arrived...nearly escaping a dragon.... </li>
              <ul>
                <li> She is scratching stuff out... </li>
                <li> Thistle tells them to make sure Lars doesn't do things stupid </li>
                <li> Tells Sparka his parents are there and nice...they arrived to look for you. </li>
                <li> Someone told them she is like dating Sparka and they hugged her and gave her cakes </li>
                <li> Sparka is like please take care of them </li>
                <li> Karvin threw out cooks and has taken over </li>
                <li> Semla is wringing bad tippers by the ears </li>
                <li> They are asking a lot of her </li>
                <li> They tell him they love him and watch out for pick pockets </li>
              </ul>
              <li> Go to bed </li>
              <li> Sass gets a vision  </li>
              <ul>
                <li> Lars in full Paladin armor in the alt timeline </li>
                <li> Massive army </li>
                <li> Cecil playing 17 instruments with magic </li>
                <li> Amazing music plays </li>
                <li> A monk comes up to him...give the order....as his eyes shimmer platinum </li>
                <li> Figures out it is Bahamut as they see the Tiamat place.... </li>
                <li> Sass goes go....Bahamut goes full dragon.... </li>
                <li> See Sparka talk to his bio parents </li>
                <li> Lars gives speech about order </li>
                <li> They charge! </li>
                <li> Jazelle is there </li>
                <li> WAKE UP COLD SWEAT </li>
              </ul>
              <li> Sparka gets vision </li>
              <ul>
                <li> At the final battle....his mother is with him....other moon elf....says grandsom </li>
                <li> His father blurred paladin walks up and says love him </li>
                <li> A jack Nordic Angle comes up and covers in dream and is his grandfather </li>
                <li> They are like confident.... </li>
                <li> His grandfather mounts his flying mammoth </li>
                <li> Angels descend from skies to help fight </li>
              </ul>
              <li> WAKE UP </li>
              <li> Hear sound of drums rousing people... </li>
              <li> The D Canoe is not there </li>
              <li> 6 Magic Missile charges back </li>
              <li> Off to the briefings </li>
              <li> Sass talks to the Imperial Legate in Air Genasi Breathy tone </li>
              <li> Aral is giving the briefing </li>
              <ul>
                <li> Scanlan, Keyleth, Grog...and other people all hanging around </li>
                <li> Scouts see three large targets...earth motes in the middle </li>
                <li> Haalinith will assault the left....Marwan the right </li>
                <li> The adventuring team will stealthily hit the middle </li>
                <li> A floating castle bristling with Ballistae will be guarding the middle...needs to be taken out to protect the other landings </li>
                <li> Grog and Co take the left...the right and middle taken by adventuring teams </li>
                <li> The P5 will sneak in and break the ballistae so it can't hurt  while the Imperial forces frontal assault the castle as a distraction </li>
                <li> Aral is like we should cripple their attack by destroying these middle fortresses.... </li>
                <li> Sass is like cripple them, then they'll come crawling back </li>
                <li> The main city is moving back slowly...tells them to have their ships float in front.  </li>
                <li> The main city will not be able to escape with all the civilians fully in time so necessary to win </li>
                <li> Aral says that the best weapons of the Fire Plane do not function out of that plane....so being here should COOL them off </li>
                <li> So their mission is to destroy those ballistae  </li>
                <li> May the good gods be with you </li>
              </ul>
              <li> Clerics of various faiths are blessing people from small boats </li>
              <li> They see....a Genasi getting up on the platform </li>
              <ul>
                <li> 8ft tall super defined genasi arrives....such great delts </li>
                <li> Holds the dildo of consequences aloft </li>
                <li> Says for the glory and freedom of the Air Plane...we will not be enslaved </li>
                <li> Haalinth has a tramp stamp...Ben loves me </li>
                <li> Corpo randomly throws the magic bow at Haalinith hundreds of yards away...it just....floats away.... </li>
                <li> Corpo's bow like 1,000s gold away...he just chucks it away....everyone like how you going to attack from range?????? </li>
                <li> Corpo....we don't attack from range </li>
              </ul>
              <li> I give a response fart salvo to Robert </li>
              <li> They take positions.... </li>
              <li> Quiet....silent....Helms Deep...everyone standing still...with the Ashes roiling.... </li>
              <li> Corpo feels lightning coursing through his bodice....wtf his bodice </li>
              <li> Here it comes....</li>
              <ul>
                <li> They hear nothing but their breath....dozen ships slowly start moving out of the ash... </li>
                <li> Earth bergs emrge next </li>
                <li> then 3 giant earth bergs...volcanoes erupting with lava flowing up into space </li>
                <li> Marwan yells....STEADY </li>
                <li> Yell DEATH! </li>
                <li> Fire plane ships turn parallel with projectiles Launch.... </li>
                <li> Air Elementals start blocking incoming.... </li>
                <li> The volcanmotes start forming their lava up above into a growing orb of lava.... </li>
                <li> Aral sending that they need to go NOW stop it </li>
              </ul>
              <li> Boulders flying through the air...GO GO GO GO GO! </li>
              <li> Korg is like HEADS UP! They are escorting he Griffin riders with banners </li>
              <li> The shimmer on the magma ball up in the air...same shimmer of their crystals....the center volcano  </li>
              <li> The Volcano combo is opening a portal to the fire plane....so a MASSIVE fire fathom serpent curls out. It can only get so far but the Volcanoes are advancing </li>
              <li> The assault ship is devoured in flame...as they are told now to take the castle by themselves </li>
              <li> They brace....GDE grabs the mast....debris hits. Lars casts Cecil and Corpo casts Sass </li>
              <li> Ship comes behind them LETS GO...explodes </li>
              <li> Lightning bolts pass by them... </li>
              <li> The ship going forward burns to ash.... </li>
              <li> No one else is going to the middle Volcano.... </li>
              <li> They decide to push through...Korg says BRACE </li>
              <li> Cecil finds a path....guiding to zig zag out of the way </li>
              <li> Sparka fires the ballista at Fire Elementals to keep em back </li>
              <li> Sass sees that the power to the Volcano is DEEP inside </li>
              <li> Lars helps Korg turn the ship....but....it takes damage as the ship is limping along </li>
              <li> Sass snipes some ballista crews but another hits the ship </li>
              <li> Corpo thinks about what he can do...Cecil is like DO YOU do anything? </li>
              <li> Mike is exasperated by Robert's ass smell </li>
              <li> Sparka misses a fire elemental which scrapes the side of the ship </li>
              <li> Ton of Soaking conversation</li>
              <li> Lars grabs the wheel (LARS TAKE THE WHEEEEEEHEEEEHEEEEL) and Sass grabs ropes... </li>
              <li> Ship is coasting in though heavily damaged </li>
              <li> They see the castle on the Volcan Mote </li>
              <li> COMING IN HOT  </li>
              <li> Ship is falling apart...ship falling apart...burning...full power </li>
              <li> See an entrance to the volcano as their ship is burning up and crashing </li>
              <li> Ryan can't think of anything but the Barbmitzvah when Hot n Herrre comes on </li>
              <li> Mike tells Robert how to not fart </li>
              <li> ROLL INITIATIVE </li>
              <li> NAT 20 Lars Initiative </li>
              <li> NAT 1 Corpo Initiative  </li>
              <li> Korg jumps in a lifeboat, Cecil/Sparka with him....GDE in the other with Lars and Sass </li>
              <li> Corpo locks the ship wheel in place...JUMP OFF wings unfurled to crash into the gate </li>
              <li> People at the gate as the ship blow takes tons of damage </li>
              <li> Manstration is a thing </li>
              <li> NAT 1 GDE to pilot lifeboat in so the three take damage </li>
              <li> Drawbridge busts and they see a Fire Giant </li>
              <li> ROUND 1 </li>
              <ul>
                <li> SASS </li>
                <ul>
                  <li> Shoot Giant  </li>
                  <li> NAT 20 Sass shoots Pyrothat/Tweedle Dee the Fire Giant </li>
                  <li> Mounts Duchess </li>
                </ul>
                <li> LARS </li>
                <ul>
                  <li> FRIGHTFUL PRESENCE! Shouts I AM THE DRAGON </li>
                  <li> Makes a ton afraid....INCLUDING the giant </li>
                </ul>
                <li> BADDIES </li>
                <ul>
                  <li> Shoot at Sass </li>
                  <li> NAT 20 Lars Attack of Opp on Fire Giant </li>
                  <li> Fire Giant yells at GDE YOU KILLED ME KIN! LETS FINISH THING </li>
                  <li> Salamander throws spears at Corpo </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> Cast VM on GDE giant duel...COULDN'T GET FROSTED TIPS! </li>
                  <li> Bowser help Sparka </li>
                </ul>
                <li> GDE </li>
                <ul>
                  <li> Attacks Giant </li>
                </ul>
                <li> Giant </li>
                <ul>
                  <li> Misses Sass with rock </li>
                </ul>
                <li> Salamanders </li>
                <ul>
                  <li> One hits Sass with spear </li>
                </ul>
                <li> SPARKA </li>
                <ul>
                  <li> Transmuted FIreball </li>
                </ul>
                <li> CORPO </li>
                <ul>
                  <li> Cherry pick Salamander kill by WWE neck snap </li>
                </ul>
                <li> Sootborn </li>
                <ul>
                  <li> Shoots Lars with Crossbow bolt </li>
                </ul>
                <li> KORG </li>
                <ul>
                  <li> Hides </li>
                </ul>
              </ul>
              <li> ROUND 2 </li>
              <ul>
                <li> SASS </li>
                <ul>
                  <li> Hail of thorns on the giant </li>
                  <li> Misses sharpshoot....Giant yells RODENT! </li>
                </ul>
                <li> LARS </li>
                <ul>
                  <li> leaps into the tower </li>
                  <li> NAT 20 Lars Javelin throw at Giant </li>
                  <li> Giant grim but not dead </li>
                </ul>
                <li> BADDIES </li>
                <ul>
                  <li> Giant misses GDE </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> GIANT SLAYER! Kills giant by head exploding Denethor Tomato </li>
                  <li> Bowser helps Sass </li>
                </ul>
                <li> Giant </li>
                <ul>
                  <li> Hits Lars with a rock </li>
                  <li> Runs towards cavenr </li>
                </ul>
                <li> Salamander </li>
                <ul>
                  <li> Hits Lars </li>
                </ul>
                <li> Salamander </li>
                <ul>
                  <li> Misses Sparka </li>
                </ul>
                <li> Salamander </li>
                <ul>
                  <li> Misses Corpo </li>
                </ul>
                <li> Salamander </li>
                <ul>
                  <li> Hits Lars </li>
                </ul>
                <li> SPARKA </li>
                <ul>
                  <li> GIANT SLAYER kills Giant with Mindsliver as it flees...it hearing "COWARD" </li>
                </ul>
                <li> CORPO </li>
                <ul>
                  <li> Corpo kills Sootborn....it trembling turns to see Corpo licking lips....spinning staff with lightning....then pushes against wall and he ashes himself </li>
                  <li> Dart at Salamander </li>
                </ul>
                <li> GDE </li>
                <ul>
                  <li> Misses with Hachet </li>
                </ul>
              <li> Mike yells SALLY SOAKS SEA SHELLS BY THE SEA SHORE </li>
              </ul>
              <li> ROUND 3 </li>
              <ul>
                <li> SASS </li>
                <ul>
                  <li> NAT 20 Sass Shoot Salamander </li>
                  <li> Sass KILLS Salamander with frozen arrow to face...freezing it as he is freaking then SHATTERS into oblivion </li>
                </ul>
                <li> LARS </li>
                <ul>
                  <li> Hits with Javelin </li>
                  <li> Second wind </li>
                </ul>
                <li> GDE  </li>
                <ul>
                  <li> Hurls ax up </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> Hides under and waits to hit....holds and hits Salamander on VM COWARD! </li>
                </ul>
                <li> SPARKA </li>
                <ul>
                  <li> NAT 1 Sparka Firebolt a Sootborn named PeePeeHead </li>
                </ul>
                <li> CORPO </li>
                <ul>
                  <li> Jumps off the castle and slow falls </li>
                  <li> Chases fleeing Salamander "Sally" </li>
                  <li> Punches 3 times... </li>
                  <li> KILLS Corpo Salamander by Hercules chucking Salamander </li>
                </ul>
              </ul>
              <li> Battle over! </li>
              <li> See combat ahead...giant serpent </li>
              <li> Korg hacking at a dead body.... </li>
              <li> GDE and Korg go to fine a ship </li>
              <li> Mike gets more whiskey and everyone else passing out and then feels shamed </li>
              <li> Mike is like IM AWAKE MOTHER FUCKER </li>
              <li> Obsidian tunnel they slowly...enter </li>
              <li> Mike draws a picture of a snipped dick....says he likes to snip then shrugs and drink </li>
              <li> Cecil checks the volcano....notes footprints end about 30 feet in....no one comes in </li>
              <li> Sass starts Detect Magic Ritual </li>
              <li> Sparka will cast Haste on Lars and Sass if detects a threat </li>
              <li> Find a large Magma cavern </li>
              <li> See ruins on an island in the middle </li>
              <li> See openings and some paths </li>
              <li> Mike loves Egg McMuffins...but not McGriddles cause he is Sweet and Salty...needs one then the other </li>
              <li> Corpo is into his half-brother's ass and leather pants </li>
              <li> They carefully walk along the land to avoid the magma </li>
              <li> Mike is very confused there is no obvious path into the volcano...he's seen Mt. Doom </li>
              <li> A sign says here Isildur fucked up (Mike lets us know his opinion on Elrond </li>
              <li> Darkvision as they creep forward </li>
              <li> The ruins are some sort of ancient Primordial/Giant civilization that most of it have been destroyed by the magma in the room...just some remains </li>
              <li> Casters hang back... </li>
              <li> Bowser and Duchess are flying (not triggering termorsense) </li>
              <li> They slowly move...unaware what danger lurks.....going towards the peninsula and the island </li>
              <li> They see no footprints...but...see some paths into the treasure...but again weirdly no footprints or anything... </li>
              <li> Ryan "The would be an incredible beast to burrow in Volcanic Rock"   </li>
              <li> Robert "Eric is like...yesss....yess it would be" </li>
              <li> Sass sees words in giant....super super super ancient giant ruins </li>
              <li> Lars can jump as much as he wants </li>
              <li> They find a +1 Giant Dagger (+1 Short short)  that is obsidian with lava cracks that glow and give a d4 of fire damage as it heats and glows </li>
              <li> Lars jumps to the middle isle and starts looking </li>
              <li> Note a little bit of the ground shifting in the corner </li>
              <li> Lars starts digging deeper and 92 finds a wand of earthbinding with 4 charges 1d4 at dawn...spell caster's DC 10% chance of turn into a giant...it's ancient giant so more like a rod NOTE FOR LATER THE GIANTS HATED DRAGONS SO THE GIANT TEXT TALK ABOUT F-TO YOU DRAGON </li>
              <li> Lars digs deeper....88 and finds some vials </li>
              <li> ROLL INITIATIVE as the ground moves towards them </li>
              <li> ROUND 1 </li>
              <ul>
                <li> SPARKA </li>
                <ul>
                  <li> Close to edge then casts haste on Lars and Sass </li>
                </ul>
                <li> LARS </li>
                <ul>
                  <li> Leaps out of hole </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> Actual Bless cast </li>
                </ul>
                <li> LR Magma pillar shoots up </li>
                <ul>
                  <li> NAT 20 Sass Dex to dodge </li>
                </ul>
                <li> SASS </li>
                <ul>
                  <li> Holds action to shoot </li>
                </ul>
                <li> Cherufe </li>
                <ul>
                  <li> Surges out of the ground </li>
                  <li> Corpo knows religious tales that some elementals can be twisted by the Lower PLanes </li>
                  <li> SASS Held action NAT 20 Sass shoots Cherufe...Precise Strike and Sharpshoot hits...3rd Shot hits...Holy cow Sass F-ed it up </li>
                  <li> EARTHQUAKE!  </li>
                  <li> Screaming it goes back under the ground...Cecil clocks it go underground... </li>
                </ul>
                <li> CORPO </li>
                <ul>
                  <li> Picks up Lars </li>
                  <li> If only he had something to attack at range... </li>
                </ul>
              </ul>
              <li> ROUND 2 </li>
              <ul>
                <li> SPARKA </li>
                <ul>
                  <li> Establish it doesn't know where Sparka is cause boots </li>
                  <li> Hold to FrostBolt it </li>
                </ul>
                <li> LARS </li>
                <ul>
                  <li> Runs to an island and stomps feat to try and make it think he was there.... </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> Holds </li>
                </ul>
                <li> LR EARTHQUAKE </li>
                <li> SASS </li>
                <ul>
                  <li> Mounts duchess and dashes </li>
                </ul>
                <li> Cherufe </li>
                <ul>
                  <li> OPENS 50 ft FISSURE...Lars and Corpo fall down </li>
                  <li> NAT 1 Lars  Investigate Checking for Treasure in the Fissure </li>
                  <li> NAT 1 Corpo Investigate Checking for Treasure in the Fissure </li>
                  <li> They find a giant pricelss gold egg as large as the table is there and they can't move it </li>
                </ul>
                <li> CORPO </li>
                <ul>
                  <li> Tries to climb out but struggles....only gets partially way out...slow going </li>
                </ul>
              </ul>
              <li> ROUND 3 </li>
              <ul>
                <li> SPARKA </li>
                <ul>
                  <li> Holds Frost Bolt </li>
                </ul>
                <li> LARS </li>
                <ul>
                  <li> Finds the Cherufe and Erupting Earth bursts him out of the ground </li>
                  <li> Keeps con check to not close FISSURE </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> Moves towards Lars and does Healing Word </li>
                  <li> ...then moves back </li>
                </ul>
                <li> LR Magma column </li>
                <ul>
                  <li> NAT 20 Lars Dex Save </li>
                </ul>
                <li> SASS </li>
                <ul>
                  <li> ...3 shots.....3 opportunities....to shoot everything you ever wanted </li>
                  <li> Lights him up once then misses twice </li>
                </ul>
                <li> Cherufe </li>
                <ul>
                  <li> Rises to attack Sass...Lars hits with AOO </li>
                  <li> Punch Sass and he drops </li>
                  <li> Punches Lars twice and he drops </li>
                  <li> Punches Corpo but...he holds on </li>
                </ul>
                <li> CORPO </li>
                <ul>
                  <li> Climbs out </li>
                </ul>
              </ul>
              <li> ROUND 4 </li>
              <ul>
                <li> SPARKA </li>
                <ul>
                  <li> Holds for Cold Bolt </li>
                </ul>
                <li> LARS </li>
                <ul>
                  <li> Pass Death Save </li>
                  <li> Corpo missed turn give him a potion </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> Healing Word on Sass, gets him up </li>
                </ul>
                <li> SASS </li>
                <ul>
                  <li> Holds to Shoot </li>
                </ul>
                <li> Cherufe </li>
                <ul>
                  <li> Pops out...Sparka lights him up with coldbolt </li>
                  <li> Sparka Kills him with a Cold bolt the second he seeshim </li>
                </ul>
              </ul>
              <li> Corpo knows Cherufe is crazy and wild...not standard...chaos....room will be collapsing...islands moving </li>
              <li> Lars runs and jumps to get out </li>
              <li> Lars lies to Sparka about the middle island...Sparka is like....Lars...might have missed stuff </li>
              <li> Sass and Corpo GTFO </li>
              <li> Cecil uses Bowser to help </li>
              <li> NAT 20 Cecil Athletics to leap over magma...lands and finds 5 gold </li>
              <li> Sparka looks into the hole..it has been churned up by the Cherufe </li>
              <li> The ground starts shaking </li>
              <li> NAT 20 Sparka Investigation at the bottom of the hole </li>
              <li> Treasure Table 97...62 Finds a Pearl of Power....Censor of Controlling Air Elementals  </li>
              <li> Sparka climbs out...gets stuck but is almost out as the lava is filling under him </li>
              <li> Runs to the exist...is gonna fall into lava so Misty Steps </li>
              <li> Sparka is sliding down as the island is sinking </li>
              <li> Runs out just in time. </li>
              <li> Cecil IDs Potion of Rage and Potion of Max Power </li>
              <li> Lars pisses in Lava </li>
              <li> NAT 20 Sass Survival check to figure out how to stop a volcano </li>
              <li> Sass and Cecil combo knowledge that this isn't natural...lava isn't from here...so...something should be able to shut it off., </li>
              <li> Know there is the Magic Chrono Crystal Shimmer but also the Fire node is here </li>
              <li> SHORT REST healing </li>
              <li> Going down the dark passage with Sparka holding out a light rock with magehand in front of them </li>
              <li> Fork in the way....left lava flows down but a rocky passage up </li>
              <li> They want to go down...but Corpo resists </li>
              <li> Corpo wants to sneak up the passage...goes up and hits a rock...hits rocks that hit rocks....a hobo warning system </li>
              <li> The others hear noise...Corpo tells them and they all sneak up. </li>
              <li> Sneak up and see a haggard ol man with wild beard and soot </li>
              <ul>
                <li> Looking like Jafar looking old </li>
                <li> Old Man: Are you here to kill me? </li>
                <li> Cecil: I don't think so? Persuasion 31 </li>
                <li> Old Man: I'm hiding here from the Fire Giants...they don't come down here much. </li>
                <li> He says he's been down here 7 years </li>
                <li> NAT 20 Sparka Insight to see if actually 7 years...what's he been eating. Realize no night or day unable to detect time probably </li>
                <li> He has been living in a side chamber off the tunnel </li>
                <li> Cecil asks where the paths go...he says this one up to the giants, that one to the Cherufe, and that one to the Efreeti in the Caldera </li>
                <li> Has a glowing mushroom </li>
                <li> Says he was building a path into a large room...recently saw a wizard up with the Fire Giants that...looked a bit like Cecil. </li>
                <li> So Hadrian is upstairs  </li>
                <li> Lars goes to ask if he could lead the way...and...freaks out </li>
                <li> Discuss how it is a heavy flow of magma. Can't stop it....so heavy... </li>
                <li> Cecil and Sparka are like lets spell slot war with Hadrian first </li>
                <li> They ask where he is from...mentions village and world they've never heard of </li>
                <li> Corpo asks if he has seen the dildo of consequence...he is like as a slave...yes </li>
              </ul>
              <li> Clear that Hadrian's clone is not mature yet.. </li>
              <li> They creep up the path </li>
              <li> NAT 20 Sparka Steath to sneak into the Fire Giant </li>
              <li> It looks like a small city....Starkiller Base like cave to structure </li>
              <li> Fire Giant Forge in the middle....a big freaking deal for Fire Giants. Salamanders outside guarding it </li>
              <li> The city isn't heavy bustle...they see Ash Guys, Fire Elementals, Salamanders....and some random slaves looking people </li>
              <li> Battle outside so not that populated inside... </li>
              <li> Slave Uprising? </li>
            </ul>
          </Collapsible>

          <Collapsible
            title="Hail Solonna! (#34 - 2/17)"
            toggle={this.collapseToggle.bind(this)}
            kind="session34"
            show={this.state.show}
                    >
            <ul>


              <li> Picking up where they are looking at the giant base...gold stone floor...they see Salamanders guarding doors </li>
              <li> Discussion of getting a Deaf Bartender </li>
              <li> They sneakin' on in....Sparka got his mask on and Lars got his soot-face on </li>
              <li> NAT 1 Corpo Stealth as Corpo steps on Sass' white dragon wing cloak peaking around the corner </li>
              <li> Lars waltz out and yells HAIL SOLONA as slaves walk up to him.... </li>
              <li> They are like...we are loyal to the Fire Giants don't know about high elves. </li>
              <li> Lars says WE are here...special forces...heard of rumblings </li>
              <li> He scares them away but they go back towards giants </li>
              <li> CORPO CAME UP WITH A PLAN AND THEY ALL AGREE </li>
              <li> They go into the slave quarters in the corner....Sass Investigates at the door </li>
              <li> Sass catches a bell on the door...undoes it so doesn't make noise </li>
              <li> Slaves do not have chains on them...sweaty and singed...the Fire Giants are immune to fire but don't care if their slaves are ok </li>
              <li> Arindor Brightblade talks to the party...Cecil says Raffaj sent them </li>
              <li> Dirty 30 on Cecil to be like TRUST ME we are going to free the slaves and take down the Fire Giants </li>
              <li> Cecil is like when are you expected next....they say they are in process of removing the fire giants </li>
              <li> NAT 20 Lars Perception to note Brondar Ironfist is the statue from growing up </li>
              <ul>
                <li> Turns out Brondar was captured before Grimstone had the event </li>
                <li> Knows gpa Larsius...SOB owes him a drink </li>
                <li> Lars is like what is it like....they make us work hard... </li>
                <li> He explains the machine here is what is making the lava glow...crazy wizard. </li>
                <li> Says wizard was here....left...came back </li>
                <li> Hundred of total slaves....dozen down here </li>
                <li> Says giants don't track slaves that close </li>
              </ul>
              <li> Cecil notes Arindor Brightblade grimaces when mentions Solonna </li>
               <ul>
                <li> He is like I thought he'd save me but he is BAD </li>
                <li> Was a paladin swashbuckler of the planes til captured </li>
                <li> Also from House Banninghymm </li>
              </ul>
              <li> Corpo talks to the Fire Genasi... </li>
              <ul>
                <li> SON OF FIRE! SON OF TEMPE </li>
                <li> Torsus Skyrend </li>
                <li> From world of Umbre that Corpo never heard of </li>
                <li> At least 20 years a slave </li>
                <li> He's liked since I am fire resistance they love me </li>
                <li> Says he is a monk who Fs and Corpo is like I WILL TAKE YOU TO ICE CREAM AND MONK PLEASURE I WILL PLAY FOR DAYS </li>
                <li> He is like after years of slavery anything sounds good but THAT SOUNDS GREAT </li>
                <li> Corpo like I'll pay for DAYS </li>
                <li> Cecil mutters that Corpo has his own pretty woman </li>
                <li> NAT 1 Corpo Insight by staring at Torsus Skyrend trying to figure out if they are attracted the Dragon </li>
                <li> Corpo has no gaydar I mean Dragonborn-love-dar </li>
              </ul>
              <li> Cecil gives a rapier to the ELf </li>
              <li> Lars gives the +1 Longsword to the Dwarf </li>
              <li> Corpo gives a water skin to the Fire Genasi </li>
              <li> They disguise self as slaves covering selves with rags...Corpo TAKES Torsus' Slave Rags </li>
              <li> Sass tucks in his dragon wing cape </li>
              <li> Slaves explain the layout...the alchemy rooms are where they are going for first </li>
              <li> The slaves say they can privately spread messages...Cecil thinks </li>
              <li> ANDOR IS THE BEST </li>
              <li> The party is like...idk to go through a fire giant city...let's do this and get the slaves with us by going back down towards Raffaj </li>
              <li> They bring just the P5...the others stay back </li>
              <li> Debate if saving the slaves or let them die in a distraction </li>
              <li> They cooly walk like they belong towards the alchemists and other slaves...WITH PURPOSE </li>
              <li> NAT 20 Cecil Deception to act like he belongs as they work way over towards the alchemist and others lave quarters  </li>
              <li> Other slaves don't pay them mind...a Fire Giant leaves the forge but don't notice them </li>
              <li> Ben plays where there is a Whip There is a Way </li>
              <li> They enter other slave quarters </li>
              <ul>
                <li> Cecil is like we came across the hall </li>
                <li> LARS IS LIKE SLAVE REVOLT </li>
                <li> Corpo NAT 20 Persuasion to ask how long have you languished under an uncruel master </li>
                <li> Slave is like there is a creepy Goliath that can hear next door... </li>
              </ul>
              <li> Kick down the door to the dissection creep room. </li>
              <li> Large doors, creepy morgue vibes </li>
              <li> INITATIVE </li>
              <ul>
                <li> LARS </li>
                <ul>
                  <li> RAGE! RECKLESS! Attacks Wizard </li>
                </ul>
                <li> SPARKA </li>
                <ul>
                  <li> Firebolt over Lars head KILL Goliath Wizard </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> Tries VM that giants are bored by his lack of wit...but he passes </li>
                </ul>
                <li> CORPO </li>
                <ul>
                  <li> Punches Goliath Wizard a bunch </li>
                </ul>
                <li> SASS </li>
                <ul>
                  <li> Shoots Goliath through the head...but arrow is caught in it </li>
                </ul>
              </ul>
              <li> One slave is like HOLY FUCK </li>
              <li> Lars finds a door </li>
              <li> Sass hears some traitor slaves telling giants about the "Special Forces" Lars liar </li>
              <li> Find a scroll of Ice Knife </li>
              <li> Cecil takes two spellbooks </li>
              <li> Sparka finds a +1 Wizard Wand </li>
              <li> They hide in the Wizard's backroom as the giants are coming to look...they tell the slaves to lie that they went back out that way...down by the Efreeti </li>
              <li> They hide behind the door quietly....not rummaging around to stay quiet </li>
              <li> Sparka holds a spell.....while Cecil listens through the wall </li>
              <li> Sparka uses Comprehend Languages to listen in </li>
              <ul>
                <li> The slaves lie to the giants.... </li>
                <li> Sparka hears them coming to check the room.... </li>
                <li> Sparka Arcane Locks the door...Cecil does immovable rod? </li>
                <li> They find an emergency exit out the back... </li>
                <li> Sparka casts minor illusion through the keyhole through giant's legs....GO TO THE EFREETI </li>
                <li> They find a stone of good luck </li>
              </ul>
              <li> LOST SOME NONSENSE and people are rolling? Perhaps checking items </li>
              <li> They risk staying longer start searching </li>
              <li> NAT 20 Sparka Investigation to search the room </li>
              <li> Most find generic wizard supplies </li>
              <li> Corpo finds a whip.....a whip of imomlation </li>
              <li> Sparka finds and Ebony Fly </li>
              <li> They scurry out the backdoor...Steathy.... </li>
              <li> Sparka closes back door behind them and dismisses Arcane Lock....quietly as they are in the dugout escape fire escape.... </li>
              <li> Got to have a fire escape in a volcano </li>
              <li> They stealth around the corner....they hear Giants open the back door... </li>
              <li> THEY START SCURRYING and y'all said I said screwing but lingering impact on my accent of my times in the Hoosier inlet impacts MAKE CLIP 6:45 </li>
              <li> The giants go down to Raffaj....and are like...eh he's fine </li>
              <li> Sparka and Cecil help Lars be like SLAVES COME WITH US! </li>
              <li> They get 6 slaves from each room so 12 followers.... </li>
              <li> Then "Let's get it started in here" </li>
              <li> There is no time to setup a table for the last supper...Corpo wants to see if there is a Judas but wait...it is him. Sparka also yells no time </li>
              <li> They see Salamanders guarding....they see a faint blue glow like a Force Field... </li>
              <li> Sparka goes first </li>
              <li> Ben talks about 9/11 </li>
              <li> Cecil does Identify on: </li>
              <ul>
                <li> Ebony Fly (Robert had figured out what it is already) </li>
                <li> Grasping Whip (+1 and pull things towards him) </li>
              </ul>
              <li> Mike is struggling...Ryan is like go to 10:30 you can do 45 minutes (he actually goes 2 hours) </li>
              <li> Lars walks up to the Salamanders and yell Hail Solonna  </li>
              <ul>
                <li> They don't speak Common </li>
                <li> Corpo walks up and is like apologies for my dumb dumb dumb friend.... </li>
                <li> Dumb on the Spectrum joke </li>
                <li> Sass and Corpo are like we are coming back to the forge with all those slaves? </li>
                <li> Namaste and bow profusely. </li>
                <li> Corpo is like I heard issues at the forge.... </li>
                <li> They don't buy it </li>
              </ul>
              <li> INITATIVE </li>
              <li> NAT 20 Cecil Arcana to ID the permanent Wall of Force that could be dispelled.... </li>
              <li> The orb inside is powered by a reality crystal 1/4 the size </li>
              <li> Ben is going to get wasted and trash talk the band at Star Wars land </li>
              <li> LAVA IS DANGEROUS </li>
              <li> Ben: I am actually going to use Stunning Strike </li>
              <li> Narrator: He didn't </li>
              <li> ROUND 1 </li>
               <ul>
                <li> CORPO </li>
                <ul>
                  <li> Cold Punches Salacious Salamander </li>
                  <li> Breath of Dragon on Salmonella and Salacious  </li>
                </ul>
                <li> SPARKA </li>
                <ul>
                  <li> Haste on Lars and Sass </li>
                  <li> Sparka says: "You better suck my dick for that" </li>
                </ul>
                <li> Salamander  </li>
                <ul>
                  <li> comes round corner </li>
                </ul>
                <li> Salamander  </li>
                <ul>
                  <li> comes round corner </li>
                </ul>
                <li> Ashen </li>
                <li> Salamander </li>
                <ul>
                  <li> Attack Lars...hit twice and is on 1? </li>
                </ul>
                <li> LARS </li>
                  <li> ATTACKS SALAMANDER Recklessly hits 3 times </li>
                  <li> KILLS SALAMANDER by decapitating the head and slapping it at their friend </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> Casts Gravity Sinkhole and rips the Salamanders up into the air </li>
                </ul>
                <li> SASS </li>
                <ul>
                  <li> SHOOTS Hail of Thorns on Salamander </li>
                  <li> NAT 20 KILL Salamander shoot in the next...gasping through mouth ....help me...to his friend </li>
                  <li> Duchess bites a Salamander </li>
                </ul>
                <li> Fire Giant </li>
                <ul>
                  <li> Chucks rock at Lars...he is hurt </li>
                  <li> Sparka yells WHY DIDN'T YOU TAKE A POTION BEFORE FIGHT </li>
                </ul>
                <li> Fire Elemental </li>
                <ul>
                  <li> Comes out of the lava </li>
                </ul>
                <li> Fire Elemental Myrmidon </li>
                <ul>
                  <li> Comes out of the lava </li>
                </ul>
                <li> Salacious Salamander </li>
                <ul>
                  <li> Hits Duchess twice....including NAT 20 </li>
                <li> Fire Giant </li>
                <ul>
                  <li> Hurls rock at Duchess....but misses </li>
                </ul>
              </ul>
              <li> ROUND 2 </li>
              <ul>
                <li> CORPO </li>
                <ul>
                  <li> Sass yells...want to do some damage this time bitch? </li>
                  <li> NAT 20 Punch Salamander attacking duchess </li>
                  <li> Flurry of Blows to punch punch....doesn't kill but step back </li>
                </ul>
                <li> SPARKA </li>
                <ul>
                  <li> Mindsliver at Salacious Salamanderbut not dead </li>
                </ul>
                <li> Salamander  </li>
                <ul>
                  <li> Downs Duchess </li>
                  <li> Hits Lars with a spear </li>
                </ul>
                <li> Ashen </li>
                <ul>
                  <li> Misses Lars with Crossbow </li>
                </ul>
                <li> Salamander </li>
                <ul>
                <li> LARS </li>
                  <li> Attacks Salutations thrice.. </li>
                  <li> KILLS by shoving up Salamander's cloaca CASTRATION </li>
                  <li> Second Wind and Action SURGE! </li>
                  <li> NAT 20 Greataxe to KILL Salamander by cutting him in half </li>
                  <li> Charges Ashen and slices into him </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> Is not a fan of how many people are on the field </li>
                  <li> HYPNOTIC PATTERN. Charms a few of em </li>
                  <li> Inspiration! </li>
                </ul>
                <li> SASS </li>
                <ul>
                  <li> PRECISE STRIKE </li>
                  <li> NAT 20 Fire Elemental gets Lightning Strike...can no longer Revivify Cecil.  </li>
                  <li> CECIL IS LIKE FUCK IT FINE I"LL BURN THIS ALL </li>
                  <li> Sass debates tying his hair back so he can blow Lars...but Lars would needs to smoke..... </li>
                  <li> So decides to shoot instead </li>
                </ul>
                <li> Fire Giant </li>
                <ul>
                  <li> CHARMED </li>
                </ul>
                <li> Fire Elemental </li>
                <ul>
                  <li> CHARGE </li>
                </ul>
                <li> Fire Elemental Myrmidon </li>
                <ul>
                  <li> CHARGE </li>
                </ul>
                <li> Salacious Salamander </li>
                <ul>
                <li> Ash </li>
                  <li> Misses Lars </li>
                </ul>
                <li> Fire Giant </li>
                <ul>
                  <li> CHARGES and is actually IKEA blinds </li>
                </ul>
              </ul>
              <li> ROUND 3 </li>
               <ul>
                <li> CORPO </li>
                <ul>
                  <li> Flurrrrrrry of blows on the Fire Elemental </li>
                  <li> KILL Fire Elemental by sucking him in and farting him out </li>
                  <li> Casper the Fiery Ghost </li>
                  <li> Punch the Myrmidon then run away </li>
                </ul>
                <li> SPARKA </li>
                <ul>
                  <li> DM rains on creative parades </li>
                  <li> Hands Lars the Air Censor </li>
                  <li> Wall of Ice Fire? </li>
                  <li> Kills Sootborn by shoving into a lava stream </li>
                </ul>
                <li> Hadrian </li>
                <ul>
                  <li> ....FIREBALL! </li>
                  <li> NAT 20 Lars Dex Save on Hadrian Fireball </li>
                  <li> NAT 20 Corpo Dex Save on Hadrian Fireball </li>
                  <li> NAT 20 Sass Dex Save on Hadrian Fireball </li>
                </ul>
                <li> LARS </li>
                <ul>
                  <li> Summon Air Elemental </li>
                  <li> Sends Air Elemental at the Fire Giant </li>
                  <li> Drinks the Potion </li>
                  <li> DRANKS potion </li>
                  <li> Runs away and takes attack and runs towars the rope (DONT YOU DARE CUT THAT FUCKING ROPE Sparka yells) </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> Ring o Spell Store Psy Lance Fire Giant AND HE FAILS </li>
                  <li> Scatters </li>
                </ul>
                <li> SASS </li>
                <ul>
                  <li> Shoots Fire Myrmidon with Precise Strike and two other times </li>
                  <li> Scatters but is worried about the lava </li>
                </ul>
                <li> Fire Giant </li>
                <ul>
                </ul>
                <li> Fire Elemental </li>
                <ul>
                </ul>
                <li> Fire Elemental Myrmidon </li>
                <ul>
                  <li> Attacks Sparka but SHIELD </li>
                </ul>
                <li> Salacious Salamander </li>
                <li> Fire Giant </li>
                <ul>
                  <li> Misses Air Elemental but then hits </li>
                </ul>
              </ul>
              <li> ROUND 4 </li>
              <ul>
                <li> CORPO </li>
                <ul>
                  <li> Attacks Myr-mo-dan </li>
                  <li> DOESN'T Kill Myrmidon but does tons of damage </li>
                  <li> Runs towards the lava...DM is like..you sure.... </li>
                </ul>
                <li> SPARKA </li>
                <ul>
                  <li> Tells slaves to TAKE MYRMIDON DOWN </li>
                  <li>  Holds til Giant moves </li>
                </ul>
                <li> Hadrian </li>
                <ul>
                  <li> Casts Gravity Sinkhole....Lars, Sass and Corpo fail </li>
                  <li> They 21 damage get pulled 20 feet into lava </li>
                  <li> Corpo lands just out of lava </li>
                  <li> Sass Drops in Lava and takes two death saves </li>
                  <li> Sass wants to be Darth GOOD GUY </li>
                </ul>
                <li> LARS </li>
                <ul>
                  <li> With a negative intelligence...recklessly cuts the mine cart rope and it falls </li>
                  <li> Pulls Sass back to shore with his attack </li>
                  <li> TO BE CLEAR IF SASS WAS BEFORE SASS IN ORDER HE WAS DEAD </li>
                </ul>
                <li> CECIL </li>
                <ul>
                  <li> Vicious Mockery on giant => you ancestors turning in grave for making such a lumbering oaf </li>
                  <li> Healing Word on Sass to get him up </li>
                </ul>
                <li> SASS </li>
                <ul>
                  <li> SHOOT THE GIANT A TON </li>
                  <li> Kills Sootborn...hits a giant and a ton </li>
                </ul>
                <li> Fire Giant </li>
                <ul>
                  <li> Charges Corpo and chucks rock...misses </li>
                  <li> SPARKA CASTS FIREBALL as ICEBALL </li>
                </ul>
                <li> Fire Elemental Myrmidon </li>
                <li> Fire Giant </li>
                <ul>
                  <li> Air Elemental hits him on AOO </li>
                  <li> Charges and hits Cecil twice for 48 pts of damage </li>
                </ul>
                <li> SLAVES </li>
                <ul>
                  <li> Kill Myrmidon.... "The slave is a fit of horny rage..." </li>
                </ul>
              </ul>
            </ul>
          </Collapsible>

          <Collapsible
            title="The Fire and The Forge (#35 - 4/12)"
            toggle={this.collapseToggle.bind(this)}
            kind="session35"
            show={this.state.show}
          >
            <ul>
                            <li>ROUND 5</li>
              <ul>
                <li>CORPO</li>
                <ul>
                  <li>Punches Sootborn Hits for 9 damage, miss, PUNCH kill by grabbing by the next and holds it     over the lava and drops it into the lava</li>
                  <li>Stays where he is at on the side of the lava</li>
                </ul>
                <li>SPARKA</li>
                <ul>
                  <li>Firebolt Mind Sliver Fire Giant who FAILS</li>
                </ul>
                <li>Hadrian</li>
                <ul>
                  <li>Gravity Fissures Corpo..he fails...and flies into the Lava</li>
                  <li>Corpo: "I am going to die" Cecil: "...I don't know why you are telling me"</li>
                  <li>Corpo goes unconscious burning up in the lava......</li>
                </ul>
                <li>LARS</li>
                <ul>
                  <li>After a huge debate on how Lars can save Corpo with crazy stuff....we are like oh the Air Elemental</li>
                  <li>Erupting Earth under Giant with Junk in Cecil's Face</li>
                  <li>NAT20 Javelin Throw at the Fire Giant with Junk in Cecil's Face</li>
                </ul>
                <li>CECIL</li>
                <ul>
                  <li>GIANT SLAYER Kill Fire Giant with Dissonant Whispers</li>
                  <li>Bardic Inspiration to Sass</li>
                </ul>
                <li>AIR ELEMENTAL</li>
                <ul>
                  <li>Saves Corpo</li>
                  <li>"Taking a shot when I kill one of you..." "....then you gonna be thirsty" MAKE CLIP 1st Clip 32:30</li>
                </ul>
                <li>SASS</li>
                <ul>
                  <li>Take a Greater Potion</li>
                  <li>Hasten Action Shoot at Giant With Precise Strike...34 Damage</li>
                </ul>
                <li>Fire Giant</li>
                <ul>
                  <li>Charges and Attacks Cecil. Misses...Silvery Barbs...but still hits. Adv to Lars. Then Hits and CECIL UNCONSCIOUS</li>
                </ul>
                <li>Stooborn</li>
                <ul>
                  <li>Attacks Sparka</li>
                </ul>
                </ul><li>ROUND 6</li>
                <ul>
                <li>CORPO</li>
                    <ul>
                <li>Fail Death Save</li>
                </ul>
                  <li>SPARKA</li>
                <ul>
                  <li>MM wand at 7th level...26 damage to Giant</li>
                  <li>TK shove Corpo 5ft from lava so he can't get pulled in THE ONLY REASON CORPO DIDNT DIE</li>
                </ul>
                <li>Hadrian</li>
                <ul>
                  <li>Casts Fire Ball, Windy, Lars, Corpo fail</li>
                </ul>
                <li>LARS</li>
                <ul>
                  <li>Two Potions and runs away from area</li>
                </ul>
                <li>CECIL</li>
                  <ul>
                    <li>Failed Death Save</li>
                  </ul>
                <li>AIR ELEMENTAL Windy McGustious III</li>
                <ul>
                  <li>SLAMS the Giant twice</li>
                </ul>
                <li>SASS</li>
                <ul>
                  <li>Sass pours a potion for Corpo</li>
                  <li>Snipes the Giant but misses</li>
                </ul>
                <li>Fire Giant</li>
                <ul>
                  <li>Attacks Windy McGustious III and Dissipates him</li>
                </ul>
              </ul>
              <li>ROUND 7</li>
                <ul>
                  <li>CORPO</li>
                <ul>
                  <li>On his back cause he's a pillow princess</li>
                  <li>Stands up and take greater potion</li>
                  <li>Moves Away</li>
                </ul>
                <li>SPARKA</li>
                <ul>
                  <li>GIANT SLAYER KILLS Fire Giant with double Mind Sliver. It falls to the ground as Sparka TK shoves Sootborn into the Lava</li>
                </ul>
                <li>Hadrian</li>
                <ul>
                  <li>....casts sending....</li>
                </ul>
                <li>LARS</li>
                <ul>
                  <li>Takes a potion from Cecil and Heals him</li>
                </ul>
                <li>CECIL</li>
                <ul>
                  <li> Gets up and walks into the chamber</li>
                  <li>Takes a Potion</li>
                </ul>
                <li>SASS</li>
                <ul>
                  <li>Charges at Hadrian...puts back to wall</li>
                  <li>NAT 20 Sass Perception to find Hadrian</li>
                  <li>Shoots at Hadrian using Precise Strike...FAILS CONCENTRATION Hadrian is visible</li>
                </ul>
              </ul>
              <li>ROUND 8</li>
                <ul>
                <li>CORPO</li>
                <ul>
                  <li>Bum Rushes Hadrian with his Monk speeeeeeeed. Back to Back with Corpo...rubbin' asses</li>
                  <li>Got community Nachjos</li>
                </ul>
                <li>SPARKA</li>
                <ul>
                  <li>Vortex Warps Lars to get closer...yells ENJOY HADRIAN!</li>
                </ul>
                <li>Hadrian</li>
                <ul>
                  <li>Hadrian is afraid...Lars sees him scared as he does a Mage Slayer attack...but does not kill him</li>
                  <li>TELEPORTATION OFF BY 80%</li>
                  <li>58 Random Encoutner</li>
                </ul>
              </ul>
              <li>80% of the time...he's gay everytime ... 22:00 MAKE CLIP</li>
              <li>They see how the lava flows into the area...gets charged....then flows up and out to open the portal</li>
              <li>Sparka Misty Steps across the way to get into Hadrian's cave and starts going through it all</li>
              <li>Lars tries to mess with the Lavagates</li>
              <li>They looked for an off but couldn't find one</li>
              <li>Cecil starts trying to figure out what it is doing?</li>
              <li>Ben is racist against Asians "Less time to fall asleep" "EAT A GRAPELEAF AND RIDE ON A MAGIC CARPET BITCH"</li>
              <li>They all start investigating...Cecil and Corpo on the reality crystal holding it up</li>
              <li>Lars shoves a bar into the lava dam to jam it...but has to hold it</li>
              <li>Corpo and Sparka get Treasure Table</li>
              <ul>
                <li>Wand of Alarm by Corpo</li>
                <li>Cloak of Billowing by Sparka</li>
                <li>Books of Arcane Lore they can't read</li>
                <li>Potion of Max Power by Sparka</li>
                <li>Deck of Wild Cards for Corpo</li>
                <ul>
                  <li>"I do I never run out?" "You will probably find a way"</li>
                </ul>
                <li>Find some Gold and 9 silver</li>
              </ul>
              <li>Ben didn't know what refractory period was and Adam never gets away from work</li>
              <li>Mike is like...why would anyone use Coitus?</li>
              <li>Cecil pulls the lever and holds the dam bad</li>
              <li>Lars hits one of the orbs with axe and with Storm Lord's fury knocks it out</li>
              <li>Mike thinks I can be a beat boxer and I play it cool but secretly am fucking floooooored</li>
              <li>Cecil casts Dispel Magic on the glowing around the Reality Crystal (with the help from Lars) and the crystals and everything collapses</li>
              <li>Cecil feels a minor lurch...like a turbulence feel</li>
              <li>Cecil picks up reality crystal</li>
              <li>They debate what to do...Short Rest..Lars is like why don't we split up? NO</li>
              <li>Remember the the Elemental Core is with the Efreeti</li>
              <li>They rest in Hadrian's cave.....</li>
              <li>Robert wanted the record to show Ryan but his dick in Mike's Ear</li>
              <li>Mike wanted the record to show it was tiny</li>
              <li>Encounter roll like shit so 4 Fire Giants form earlier arrive</li>
              <li>Retcon that they sent the slaves to hide in a side spur....found they were digging out a corner but didn't figure it out</li>
              <li>Sparka is like wait the lava pipe...was empty for now....</li>
              <li>Corpo NAT 20 Investigation to see if the Carpets having flying capabilities (they don't)</li>
              <li>Ben makes a 3/5th jokes</li>
              <li>As the giants wade through...they hide...Sparka uses Minor Illusion to help Lars hide...</li>
              <li>Corpo is fucking invisible</li>
              <li>Sass hears the Giants talk....figure out the chamber is f-ed up...order one to stay and start fixing stuff up</li>
              <li>The Giant who was left....goes back realizing he can't find anything...</li>
              <li>Two Giants and two salamanders are guarding the exit....</li>
              <li>They realize the pipes, the outflow, and the elevator shaft are open to leave</li>
              <li>Mike will not give the Hamoy family recipe to Ben's parents</li>
              <li>Finish Short Rest, song of rest is a canonically a lullaby</li>
              <li>Taco Bell has a new menu line up</li>
              <li>They creep...athletics and acrobatics...towards the shaft</li>
              <li>Mike has a complex plan involving time of day that was all for nothing cause he based it off something Ben said that was bullshit</li>
              <li>They open the pipe...send Bowser in</li>
              <li>NAT 20 Cecil Investigation via Bowser's eyes to find the pipe goes down all the way</li>
              <li>They debate pipe vs shaft...Cecil makes a point that the lava pipe probably ends up...in lava....</li>
              <li>They get to the shaft sneakily...it is stated that they all enjoy it together</li>
              <li>They start falling and featherfall as they hold hands as they exit the shaft and cast featherfall...land in the open Lava cavern</li>
              <li>They see a shattered elevator...and a glowing shard of pure fire across the lava lake</li>
              <li>Lars screams KNOCK KNOCK SUCK A DICK</li>
              <li>NAT 20 Sass Perception to see where all the elementals are</li>
              <li>They are like let's Dim Door across Cecil....he debates going Team YOLO just once</li>
              <li>ROLL INITATIVE</li>
              <li>NAT 20 Lars Initative</li>
              <li>Mike says he can't play any more but he goes another 40 minutes</li>
              <li>MAJOR DEBATE TO Dim Door or NOT to Dim door</li>
              <li>Corpo is unsure if the Fire Elemental is Autistic</li>
              <li>Did someone got a Ring of Mind Shielding....</li>
              <ul>
                <li>There is currently a soul in it</li>
              </ul>
              <li>ROUND 1</li>
              <ul>
                <li>LARS</li>
                <ul>
                  <li>Holds to go with Cecil</li>
                </ul>
                <li>CORPO</li>
                <ul>
                  <li>Gets in the Bag of Holding</li>
                </ul>
                <li>CECIL</li>
                <ul>
                  <li>Dimension Doors himself, Lars and brings Corpo in Bag of holdin</li>
                  <li>Says use my potion on my belt if I die</li>
                </ul>
                <li>SASS</li>
                <ul>
                  <li>Misses Efreeti first. Hits Efreeti with second hit</li>
                </ul>
                <li>Saltine the Salamander</li>
                <ul>
                  <li>Attacks Cecil twice, Lars misses Sentinel...but Cecil escapes the grapple</li>
                </ul>
                <li>SPARKA</li>
                <ul>
                  <li>Readies an action....</li>
                </ul>
                <li>Salsa the Salamander</li>
                <ul>
                  <li>Chucks two Javelins at Lars and misses</li>
                </ul>
                <li>Fire Elemental</li>
                <ul>
                  <li>Charged across at them</li>
                </ul>
                <li>Fire Elemental Myrmidon</li>
                <ul>
                  <li>Slashes into Lars</li>
                </ul>
                <li>LARS HELD TURN</li>
                <ul>
                  <li>Rages and attacks and ACTION SURGE salamander</li>
                  <li>Uses Bardic to hit</li>
                </ul>
                <li>Efreeti</li>
                <ul>
                  <li>Casts Wall of Fire...but Cecil COUNTERSPELLS</li>
                </ul>
                <li>Salamander</li>
                <ul>
                  <li>Chuck spears at Lars and hits once</li>
                </ul>
                  <li>SPARKA HELD TURN Make Clip idk something like 44's</li>
                <ul>
                  <li>Transmutes Cold Fire Ball...POTION OF MAX POWER</li>
                  <li>KILLS Salamander with a small ice crystal EXPLODES so that just Lars' nose is cold...Max Power Cold Ball</li>
                  <li>KILLS Salamander with a small ice crystal EXPLODES so that just Lars' nose is cold...Max Power Cold Ball</li>
                  <li>KILLS Fire Elemental Myrmidon with a small ice crystal EXPLODES so that just Lars' nose is cold...Max Power Cold Ball</li>
                </ul>
              </ul>
              <li>ROUND 2</li>
              <ul>
                <li>LARS</li>
                <ul>
                  <li>Recklessly Kill Slamander by slicing up its head and then bats it into the lava</li>
                  <li>Second Wind</li>
                </ul>
                <li>CORPO</li>
                <ul>
                  <li>Gets out of the bag</li>
                </ul>
                <li>CECIL</li>
                <ul>
                  <li>Gravity Sinkhole 16 damage to Safavi</li>
                  <li>Jumps lava to run away</li>
                  <li>Bardic for lars</li>
                </ul>
                <li>SASS</li>
                <ul>
                  <li>SHOOTS the Fire Elemental on first hit</li>
                  <li>Ensnaring Strike on Fire Elemental on second hit</li>
                  <li>The Duchess attacks</li>
                </ul>
                <li>SPARKA</li>
                <ul>
                  <li>Charges forward but misses fadeaway jumper firebolt at fire bolt</li>
                </ul>
                <li>Fire Elemental</li>
                <ul>
                  <li>Fails to escape...takes damage</li>
                </ul>
                <li>Efreeti Safavi</li>
                <ul>
                  <li>Goes after Cecil....AOO from Lars</li>
                  <li>NAT 20 Lars swipes him...he can't go further so has to attack Lars</li>
                  <li>Hits Lars with its Scimitar twice</li>
                </ul>
              </ul>
              <li>ROUND 2</li>
              <ul>
                <li>LARS</li>
                <ul>
                  <li>NAT 20 hits Efreeti</li>
                  <li>NAT 19 Crit...total hit 59 damage</li>
                </ul>
                <li>CORPO</li>
                <ul>
                  <li>NAT 20 punches Safavi and hits him 4 times</li>
                </ul>
                <li>CECIL</li>
                <ul>
                  <li>Psy Lances Safavi</li>
                </ul>
                <li>SASS</li>
                <ul>
                  <li>Hits Fire Elemental twice</li>
                  <li>Duchess bites it</li>
                </ul>
                <li>SPARKA</li>
                <ul>
                  <li>KILL Fire Elemental with Mind Sliver...it snaps its neck and dissipates witch king into nothing</li>
                  <li>KILL Efreeti with Psy Lance as it snaps out of existence into a puff of fire...leaving its sword</li>
                </ul>
              </ul>
              <li>LEVEL UP</li>
              <li>Checks the fire gem shard...a connection conjuration magic to the fire plane...the platonic ideal of fire</li>
              <li>Cecil casts dispel magic twice...the lava flow is down to a trickle...a low flow...then it falls to the ground clanging like a dun sphere. Cecil picks it up</li>
              <li>Treasure Table</li>
              <ul>
                <li>+1 Amulet of the Devout</li>
                <li>Sparka finds: Amulet of Health</li>
                <li>Sass finds: Deck of Cards....Deck of Wonder</li>
              </ul>
                <li>Feel some creaking...but it's not about to crash. They should notice in like 5 minutes?</li>
                <li>Debate what to do.....</li>
                <ul>
                <li>We should probably get ready to get back and get rewarded</li>
                <li>GDE and Korg waiting for them</li>
                <li>Can go back through tunnels?</li>
                <li>They started a Slave Revolt...Sparka wants to get slaves out...Corpo is like 1000% leave them</li>
                <li>Cecil like we can't take out the whole Fire Giant town..Sparka just like how about the 4 Fire Giants and the 2 Salamander</li>
              </ul>
            </ul>
          </Collapsible>

          <Collapsible
            title="Sneaking and Purging (#36 - 5/24)"
            toggle={this.collapseToggle.bind(this)}
            kind="session36"
            show={this.state.show}
          >
            <ul>
              <li>In the depths post-Efreeti defeat and finish leveling up</li>
              <li>Decide maybe they should leave...slaves should be fine?</li>
              <li>NAT 20 Cecil Perception finding 3 exits from the chamber using Bowser</li>
              <li>One goes to the city...one back to where they were from....one out somewhere</li>
              <li>They all want to leave...but...Sparka is like whoa....they will have loot...treasure...weapons</li>
              <li>They are like...maybe we go free slaves and they help carry the loot</li>
              <li>They go up to the heart of the city</li>
              <li>Cecil almost lied to them that this path went to the city...but didn't</li>
              <li>Corpo and Sass fight over leading....Sparka in back...eventually Sass was in the middle with Lars in front</li>
              <li>DAMN STEALTHY</li>
              <li>Sneak up to the city...see an entry with main area overlooking the caldera with slaves being moved in. It's not living quarters but a like business area</li>
              <li>They hide quick from Salamander guards</li>
              <li>They see Salamanders on guard at some doors that seem important...Giants moving in and out of rooms</li>
              <li>Cecil sees that they haven't noticed the lava stop yet...</li>
              <li>They quick take potions (helpful Corpo) as they think of what to do</li>
              <li>Decide to make a distraction...after ducking into the random rooms....</li>
              <li>They sneak to a room...Sass trips but Lars captures him</li>
              <li>NAT 20 Cecil Luck check that no one is in the room they dash to...just dry goods</li>
              <li>Hear panic outside as the locals notice the magma flow is off</li>
              <li>Sass does Thamaturgy (Bardic Inspiration and Corpo help by cupping his ass) the Salamanders buy it but not the far away Fire Giants</li>
              <li>Lars Erupt earth closes the tunnel</li>
              <li>There were two guarded doors....locked doors. Sass goes with Team Responsible (Mike hasn't had enough to drink)</li>
              <li>Lars recklessly attacks the door...NAT 20 to burst down the door. Lars rushes in and sees the Magical Armory</li>
              <ul>
              <li>Lars finds belt of Fire Giant Strength</li>
              <li>Corpo finds Cloak of Displacement</li>
              </ul>
              <li>Sparka helps Cecil open the door to the Treasury....</li>
              <ul>
              <li>Sparka find LOT of GOLD</li>
              <li>Cecil finds 17,000 SILVER</li>
              <li>Sass 170 Platinum</li>
              </ul>
              <li>They see the open area ahead Giants had gone in the main chamber but they avoided it...Sass deduces that to the right is where they had been. Cecil messages Lars to coordinate</li>
              <li>Corpo wills himself free of Moon Siphylis...and since immune to disease if the disease model of mental illness is true Corpo is also the most sane</li>
              <li></li>
              <li>The party creeps back towards they had been</li>
              <li>NAT 20 Sparka Stealth Check to sneak through the city as Giant Klaxons go off</li>
              <li>NAT 20 Sparka Stealth Check (Double from previous advantage)</li>
              <li>It was a high stealth DC check but Sparka with his double NAT 20 Stealth Advantage I am like fine I am not in control here</li>
              <li>I set a DC 20 Int check for Sparka to know what a TRex is and the little shit hits it. I am like fine I am not in control here</li>
              <li>The Giant is walking down the Hall...they try to hide but he catches them yells intruders</li>
              <li>ROLL INITIATIVE</li>
              <li>ROUND 1</li>
              <ul>
              <li>CORPO</li>
              <ul>
              <li>Lightning Strike as he hits with Staff of Storm and Lightning</li>
              </ul>
              <li>LARS</li>
              <ul>
              <li>Doth not Rage...super in control. Edging</li>
              <li>NAT 20 on First Strike on the Giant who found them in the hall</li>
              <li>Second strike hits</li>
              <li>63 freaking damage</li>
              </ul>
              <li>SASS</li>
              <ul>
              <li>HITS with precise strike (not Precise Trike)</li>
              <li>Only does 53 damage....wtf</li>
              </ul>
              <li>Fire Giant</li>
              <ul>
              <li>Yells intruders</li>
              <li>Attacks Lars twice</li>
              <li>Does 45+ damage to Lars</li>
              <li>Tries to flee but Lars Sentinel Swipes him so he can't flee</li>
              </ul>
              <li>CECIL</li>
              <ul>
              <li>Dissonant Whispers...Giant fails so he has to flee...so runs from Lars</li>
              </ul>
              <li>SPARKA</li>
              <ul>
              <li>Mind Sliver ALMOST kills him</li>
              </ul>
              </ul>
              <li>ROUND 2</li>
              <ul>
              <li>Corpo swoops in on hurt Giant</li>
              <li>GIANT SLAYER runs up and punches the giant to it's knees, climbs up to its head and dragon breath down on its face</li>
              </ul>
              <li>NAT 1 Sass Deception using Thamaturgy to say "Just kidding not a problem) NAT 1 1 for 0</li>
              <li>They scramble down the hall to where they had been earlier</li>
              <li>The live action Dora was amazing</li>
              <li>Ben made a "German" joke and by "German" joke we all know what kind of joke Ben made</li>
              <li>Mike ate pork kneecap in Germany, Ben in Lithuania to one up him. Ben is like you think Germans only ones who know about the whole pig</li>
              <li>Ben likes when Cyclops lands in Superhero pose with a giant bulge</li>
              <li>Corpo shows the Wyrm Rune to Sass who is like it mean Wyrm (Dragon) Sparka thinks it is something to help fight dragon but requires Attunement. Lars is like...wait...what.....</li>
              <li>Lars is actually...Bahamut???</li>
              <li>They run through the area...think someone is chasing them...yell rise up slaves but don't stay and help. Good distractions?</li>
              <li>Cecil gets 24....NOW IS THE TIME THE THROW OFF YOUR SHACKLES! Sparka yells LOOT THAT BODTEE</li>
              <li>They sprint back into the caves they had been in towards the Cherufe lair</li>
              <li>They run into Raffaj...Lars is like you first lead us!</li>
              <li>Raffaj is like did you free the slaves? Lars lies and he catches it</li>
              <li>They start climbing through the tunnels...four of them. Cecil sends Bowser ahead and thinks the middle two are best</li>
              <li>Lars goes into path B...Cecil follows...everyone goes and Sparka is using Mage Hand to make a path</li>
              <li>Bowser leads the way...it takes them awhile. The unhoused enslaved person Raffaj</li>
              <li>Sass rips a weak fart....Raffaj thinks the toxic fumes are actually easier here</li>
              <li>They head down the path...getting to the bust in castle.</li>
              <li>Raffaj is like the fresh air burns!</li>
              <li>Cecil, Sass, Lars, and Sparka go to the edge seeing like hundred near a thousand random ships fighting the battle</li>
              <li>The fire serpent is squirm dying as Sass and Lars give it the finger but Cecil does not. Sparka didn't specify</li>
              <li>The battle appears like the Air Forces are starting to win...they feel the Vulcan Mote start to turn around</li>
              <li>GDE arrives George Washington it on a floating boat...covered in blood and spitting blood up</li>
              <li>So much Vietnam music references as he arrives</li>
              <li>They are like it was successful? You sons of bitches....</li>
              <li>They hop on and start flying back</li>
              <li>Korg is helping steer but covered in soot</li>
              <li>Mike Farted</li>
              <li>They are dodging flying Ballistae and molten rocks and lightning bolts</li>
              <li>Corpo has "Lot's of Dexterous" and is being pure of body</li>
              <li>Sparka uses firebolt and mage hand to deflect shit</li>
              <li>Sass gives Shield of Faith of the Driver</li>
              <li>Corpo gusts the sails...he blows ok...</li>
              <li>Lars helps the sails</li>
              <li>Cecil gives an inspiring tune</li>
              <li>They smash wood like Corpo loves</li>
              <li>As they get closer to the Air lines....the Fire forces start retreating</li>
              <li>Air force boats start floating towards them and cheering</li>
              <li>Corpo sees his dick-ass brother in the distance.</li>
              <li>Haalinith is in the distance coming back from the left flank...Corpo notes his fleet didn't take much battle</li>
              <li>Mike forgot he made me name Wind Duke Marwan...Marwan Habib and got excited all over again. The best thing about Mike drinking and being tired is he forgets jokes he made and I get to tell him them again and be so dupe as hell</li>
              <li>Marwan Habib comes near by, Sparka notes that Marwan is an amazing father and probably read his son stories each night</li>
              <li>he starts to be like you did it...and Corpo interrupts with "Ha-Babe Marwan"</li>
              <li>They are like...we stopped the Volcano and saved you all.</li>
              <li>Harwan is like y'all are Goram heroes.</li>
              <li>They see shattered debris across the field...bodies...etc</li>
              <li>He says lets feast!</li>
              <li>The party is like...re...reward?</li>
              <li>YES OF COURSE! You will be my guests of honor as we shower with gifts</li>
              <li>Sass is like shower together? Marwan is like well you are heroes....I am never nude so I'm cool with it</li>
              <li>Sparka asks and yes Haalinith will be there</li>
              <li>Ewok Halflings playing drums and flutes</li>
              <li>Air Genasi Hashish getting smoked</li>
              <li>A bunch of people have left/died. Scanlan is gone</li>
              <li>Some Djinn are floating, other Wind Dukes</li>
              <li>Marwan leads them to the table</li>
              <li>Haalinith walked over to Corpo</li>
              <ul>
              <li>Corpo and sizes him up...he's stronger....wants to fight him....but Corpo notes his 3 concubine monks and 2 other Half-Genasi monks</li>
              <li>Haalinith asks Marwan if it is them....he nods</li>
              <li>Haalinith says thank you...you helped save the Air Plane</li>
              <li>He says ok lets feast...but...points at Corpo and is like...walk with me</li>
              <li>Corpo follows</li>
              <li>Haalinith is "We are kin are we not. I can tell how you hold yourself and regard me"</li>
              <li>Corpo is like...indeed</li>
              <li>He is like have you seen our father lately? No</li>
              <li>Corpo is like I need therapy</li>
              <li>Haalinith is like no you are lucky</li>
              <li>Corpo says well father is trapped...he is shocked but Corpo clocks it is a lie...he knew</li>
              <li>NAT 20 Corpo Insight check to know that he was trapped</li>
              <li>Corpo calls him on it, Haalinith plays it off like oh well I had guesses. He stopped visiting</li>
              <li>C: WTF</li>
              <li>H: You what I wanted all my life?</li>
              <li>C: Approval?</li>
              <li>H: ...He told me he would give me a treasure...a powerful thing...his most valuable asset. And I knew what it was</li>
              <li>C: Was it a bow?</li>
              <li>H: Psssh no he gave me a talking bow. He said I was his most important son and he just gave me a bow. I wanted that gem with a storm in it, the only thing he ever cared about. He never cared about me</li>
              <li>Corpo is like.....FUCK</li>
              <li>H: He'd leave dinner to check on the gem...make sure it was safe</li>
              <li>C: I found the bow...but I got rid of it</li>
              <li>H; I thought I heard it on the wind before the battle...</li>
              <li>Corpo says the bow made it clear he was the favorite son.</li>
              <li>H: One can record a voice but it does not make it true. Just cause you can make a velvet painting of a whale and a dolphin getting it on...doesn't make it art</li>
              <li>C: You and I are more alike than I thought. The fact we could be friends</li>
              <li>Corpo thinks he has that painting</li>
              <li>Haalinith is like go feast, I will have a proposition to discuss with you in a minute</li>
              </ul>
              <li>Lars is seated...asks if there are orphans...they are like of course....BRING THEM TO ME</li>
              <li>The orphans come over as people bring fine wine.</li>
              <li>"MY ORPHANS"</li>
              <li>"We the Orphans of Earthmote 5 have written a song for you...THANK YOU FOR KILLING THE GIIIIIANTS. THANK YOU FOR SLICING OFF THEEEEEEIIIIR HEADS. PLEASE PISS ON THEIR CORPSES"</li>
              <li>Lars: "Orphans come close I have a surprise"</li>
              <li>NAT 1 Lars Persuasion to convince the Air Genasi to leave him alone with these children</li>
              <li>Lars give one gold per child</li>
              <li>"Thank you mister"</li>
              <li>Lars is like my name is Ole...spread word of the Phandalin 4 and their friend Ole....also ask on the Dreadflame</li>
              <li>Drunken song, wine, people dancing</li>
              <li>Marwan stands and goes TO THE HEROES!</li>
              <li>I can't hear cause someone is blaring shots</li>
              <li>Super strong drinks</li>
              <li>NAT 20 Sparka Con Save to resist strong Air Genasi Drank</li>
              <li>Sparka gives a quick spanreed to Maisie saying they are ok</li>
              <li>Marwan gives them the title "Defender of Air" Then 700 gold pieces each.</li>
              <li>One Orphan comes up to Lars and says about the Dreadflame. Some people knew who he was....some here are in his name. One was a cleric of Yan-C-Bin. He goes to spend his gold on booze</li>
              <li>Lars is in coal covered black face</li>
              <li>Another orphan gives Lars a shining rock cause it is all he had found</li>
              <li>Haalinith begins his plan</li>
              <ul>
              <li>He brings Corpo over and says...I have a proposition</li>
              <li>You seen what happens when the Djinn run the Air Plane...our father and the like are flighty fools</li>
              <li>Join me brother. We will rid the Air Plane of the Djinn and the wind dukes will no longer stand in our way</li>
              <li>He then stands up. He thanks the visitors....thanks for helping. Now all of you get safe passage from the air plane</li>
              <li>He then says....Marwan you are a traitor....sentence to death.</li>
              <li>BEGIN THE PURGE</li>
              <li>Haalinith is like...you can leave now</li>
              <li>Corpo is like...unanticipated</li>
              <li>Monks are punching up Marwan...his followers getting beaten as they fight</li>
              <li>The group chat in their head</li>
              <li>Lars is like...we were Marwan's guests....</li>
              <li>Sparka is like would the Empire care? Thinks they are mainly sure we should just wanted the Fire Plane not to conquer. Would the Empire rather be Balkanized? He isn't sure (Above table good question for Sparka)</li>
              <li>Corpo checks that there are more monks than they can probably fight</li>
              <li>Corpo tells Haalinith these fellow travelers are a sort of family...and I have unfinished business</li>
              <li>Corpo says can we keep in touch? I may have a lead on the gem you seek?</li>
              <li>Haalinith gives him a speaking stone</li>
              <li>Corpo slips the poison coin into his pants (but he is immune from poison)</li>
              <li>The Orphans are concerned...their adult master was murdered...Lars is like fine use the gold to buy booze</li>
              <li>They are torn up as hell about this. Sparka no likey</li>
              <li>Corpo debates giving Marwan 4 potions at once</li>
              <li>They see the purging</li>
              <li>Cecil walks over to Haalinith...asks what is happening.</li>
              <li>He is like we are going to unite this land under peace</li>
              <li>Cecil pushes about Marwan</li>
              <li>Haalinith is like he is an obstacle to peace...raiders got through his realm</li>
              <li>Corpo is like...well if it brings down oil prices that's ok</li>
              <li>Cecil relays it to the group</li>
              <li>Ben is muttering the F word and no bueno</li>
              <li>Corpo debates Barbmitzvah 2.0....slaughter people to stop a slaughter. can't kill people if you kill them</li>
              <li>Corpo engages Haalinith .... what up with Marwan...he is like he will be arrested and hopefully see the light.</li>
              <li>Corpo is like don't execute him...a key to the gem we seek. Corpo fails roll. BUT KNOWS HOW TO PLAY HIS CHARACTER AND REROLLS AGAIN AND FAILS</li>
              <li>He is like I will ....consider it</li>
              <li>Cecil almost Charms him....</li>
              <li>Haalinith is like you may return any time to visit oh heroes of air! The party grumbles</li>
              </ul>
              <li>They meet GDE at the boat...what is happening?</li>
              <li>They reveal it to GDE...they ask what the Empire would think?</li>
              <li>GDE is like...the empire would probably not love it but they are practical. Too bad I killed seven giants and burned all my rages</li>
              <li>They get on the boat</li>
              <li>Cecil summons his Dragonnel (Needs a name) He has him be a green Dragonnel like Bowser. It starts playing with Bowser and growling at Duchess</li>
              <li>They take a long rest as they drift back for another day</li>
              <li>They sleep through the ambient light</li>
              <li>Corpo does know how to summon the succubus....smear blood in a symbol</li>
              <li>Robert wanted a strong raging cliffy</li>
              <li>Next time Cecil will ID stuff</li>
              <li>In the book Sparka sees a note...responding that she misses him and his parents are amazing</li>
              <li>Maisie relays that your friend Ole said the zombie army is on the move.</li>
              <li>Mike: "Good, those mother fuckers are going to die"</li>
              <li>Everyone else: "...they are already dead"</li>
              <li>Mike: "...OK well it's time to stop good night"</li>
            </ul>
          </Collapsible>

          <h2>
            <br/>
            Interlude 2: The New Reality
            <br/><br/>
          </h2>

          <Collapsible
            title="Bookmobiles, Devils, and Emotional Vulnerablitiy (#37 - 6/13)"
            toggle={this.collapseToggle.bind(this)}
            kind="session37"
            show={this.state.show}
          >
            <ul>
              <li>On the ship on the Air Plane</li>
              <li>Cecil does some Identifying</li>
              <li>Sparka is thinking about Severin, does he have seconds and powerful seconds? Not caring about mushrooms unless impacts Phandalin. Maise and parents well he will be seeing them shortly</li>
              <li>Lars is thinking if Thistle will like his dragon scale dick</li>
              <li>Corpo is a little off character....just thinking no bueno and fuck muttering to himself. Thinking about summoning the buss</li>
              <li>Hamoy household talks about Ryan's nipples a lot</li>
              <li>Cecil debating giving the reality crystal over....to Dorrissa? Not to? Get mom to scry on cousin</li>
              <li>Sass is horny after all the death and paranoid his ho be cheating. Tried to pray to calm himself...but is like ugh tried</li>
              <li>Everyone glum at being used and the purge</li>
              <li>Talking to GDE about how Empire would see it</li>
              <ul>
                <li>They won't be pleased...but in general wanted it to not be out of balance.</li>
                <li>Not blaming crew for not stopping it</li>
              </ul>
              <li>Knew Haalinith played em...not sure if he planned whole war but knew his people hadn't gotten hurt</li>
              <li>Gut feels something there for Haalinith, Hadrian...something</li>
              <li>NAT 20 Corpo Investigation....realizes Severin has Daddy in a lamp but Haalinith wasn't surprised</li>
              <li>Not sure Haalinith is a Tia-truther....but got what he wanted?</li>
              <li>Streak of mentioning refractory period continues</li>
              <li>Corpo explains how he got the dagger and orb from mother but Haalinith wanted it. Succubus has it</li>
              <li>Sparka like WHY BRING HER BACK Corpo is like rather us have it not her if Haalinith wants it</li>
              <li>Sparka like...USE IT AS BAIT get the orb and tell Empire Haalinith is in league with Cult of Dragon...get him to come and get the orb AND KILL HIM!</li>
              <li>Corpo recalls he has a speaking stone...send djinn picks</li>
              <li>Sparka is like of yeah the time dish needs a wish spell which is why Severin has Zaddy in lamp</li>
              <li>Cecil assumes Haalinith and Severin not in league but temp allies</li>
              <li>Daddy's greatest possession was not Corpo</li>
              <li>Sparka writes to Maisie and sees if she could think it through....she thinks they are an odd..pair maybe a broker between them?</li>
              <li>Sparka is like I was writing to Maisie and theory crafting...</li>
              <li>Crew like...that's what they call it these days....</li>
              <li>...but he is like wait yeah could be a broker</li>
              <li>LARS IS LIKE AAHHHH THE DREADFLAME!</li>
              <li>Robert spoils the Falcon show that Agent 13 is evil</li>
              <li>HOW IS Dreadflame involves in all of this? Why would anyone have Severin do the dirty work to collect stuff? But swoop in and take it all away?? Makes no sense?</li>
              <li>Halia? Duran? Imperium?</li>
              <li>Sparka recalls his blood parents fought alongside GDE against Ancient dragons</li>
              <li>Cecil calls father and asks about Severin</li>
              <ul>
                <li>His father found College of Creation Bards but Severin found Dunamancy and Tiamat stuff...thinking he'd be rewarded for freeing Tiamat.</li>
                <li>Less fanatic worship of Tiamat but he will get rewarded</li>
                <li>REFERENCE TO PEOPLE ON ROOF OF INDEPENDCE DAY</li>
                <li>Family was about uncovering secrets...so wide spread</li>
                <li>Severin is kicked out though but he don't care about family anyway</li>
              </ul>
              <li>Corpo having night terrors about being touched sexually despite it not happening</li>
              <li>Cecil gets a spell from a wizard spellbook</li>
              <li>Return to Thon Daral</li>
              <li>GDE is like damn and passes out</li>
              <li>We all have a Cloaca to begin with</li>
              <li>DM owes Cecil a spell from the spellbooks he was reading</li>
              <li>Dorrissa is waiting for them</li>
              <li>Ben calls Dorrissa a pizza face...Mike is offended...Ben is like you weren't at the Jew jokes? Gojirra?</li>
              <li>RACISM</li>
              <li>Sparka tells Dorrissa to talk to Air Plane and invite Haalinith to talk here</li>
              <li>Corpo makes a sock puppet to talk to her..."IM THE PHANDALIN FREAK"</li>
              <li>As the sock puppet Corpo sasses at Dorrissa...she isn't happy</li>
              <li>Mike has a drink smelling like a sock</li>
              <li>Korg yells at people while running towards the temple</li>
              <li>Sparka talks about summoning the Succuubus in a magic circle to be trapped...</li>
              <li>BONDAGE Corpo yells</li>
              <li>Magical Bondage Sparka corrects</li>
              <li>So no bruises Cecil says</li>
              <li>Robert states for the record DM gave Cecil MASSIVE side eye</li>
              <li>SPLIT UP GANG!</li>
              <li>Sass goes back to Razorfang as a mixture of horny and freaking out</li>
              <li>Cecil goes to his mom to scry on his cousin</li>
              <li>Lars goes to blow off steam in the tavern</li>
              <li>END CLIP 1</li>
              <li>Corpo and Sass decide to go to Arxius Atical to see if he can help with magic circle</li>
              <ul>
                <li>Mention...fiendish things....he is like let's go for a walk...he gets some chips from a street vendor</li>
                <li>Corpo asks about binding succubus....he is like not really but yes I have Magic Circle and Planar Binding</li>
                <li>Sparka: "We....that's a loose term...ONE of us gave something of importance.."</li>
                <li>Arixus: "Your virginity?"</li>
                <li>Corpo: "I wish..."</li>
                <li>Sparka is like he made an exchange and the item exchanged was disproportionate for the favor</li>
                <li>Arixus like possibility she doesn't have it...but might have gotten rid of it...probably should summon her anyway</li>
                <li>Debate if the sigil would actually summon her? Or just a call? Unclear but probably only a one time deal</li>
                <li>Sparka like maybe we don't trap her at first in case she moved it along she might be angry. So if it is fine Arixus can ruin the circle</li>
                <li>Arixus is like I got a place we can do it in...over by Gymnasium of Kord</li>
                </ul>
                <li>Cecil's mom hugs him, catches up....says cousin is actually alive. Cecil's mom scries on his cousin.</li>
                <li>Sees Hadrian just eternally falling through the Air Plane...beaten up by a Beholder and dove into the Air and is falling until gets spells back.</li>
                <li>Cecil talks to his father about Uncle. Says his brother was easily taken by Tiamat...could have been easily taken if someone approached him with a plan. Dumbass serves a</li>
                <li>Lars arrives to Thistle's hallway</li>
                <ul>
                <li>He is in Blackface and Urchins are like we memorized your face in all colors cause we never knew what it might be. Praise Ioun as he turns from bad Cockney to bad Australian</li>
                <li>Thistle is in a full matron dress. She is like I was told to be firmer with you by your high presuasion check rolling friends. You are not allowed to lie to me and run away and almost die.</li>
                <li>Lars says fine and drops his pants...DIRTY 25 on Animal Handling and she is like fine I can't get pregnant twice.</li>
                <li>Lars is unclear if Tieflings actually can?</li>
                <li>Urchins hear noises and decide they need to go to the book mobile to figure out what that means</li>
                <li>Lars is giving a play by play of his time with Thistle</li>
                </ul>
                <li>Sass goes back to Phandalin to check in on Da Fang</li>
              <ul>
                <li>Goes through the portal and sees a MASSIVE construction project underway</li>
                <li>Wildon has a desk setup on a crate...he is like we aren't ahead of schedule but not behind schedule and IS THIS BUSINESS that is what matters...</li>
                <li>Sass cuts him off and is like Sorry I need to get Razor Fang now</li>
                <li>Wildon says she is on top floor being a Mason, apprentice Arcane Mason</li>
                <li>Sass flies up</li>
                <li>MAKE CLIP 17:55ish</li>
                <li>In the TP bonds Sparka jokes she is getting railed by 5 guys, Lars that she called for a plumber, Cecil something about a train, and Corpo just makes Fuck-Wario noises</li>
                <li>He sees Razorfang laying brick by herself</li>
                <li>Sass sees a big muscular guy bigger muscles than her...come up and place his hand on her...she moves it away and he insight checks to know she was better</li>
                <li>He walks away and she is grabbing a magic brick and continues the process</li>
                <li>Before she sees Sass...he is relieved she was truthful...but then he shoots the guy's hand</li>
                <li>Cecil feels the concern...and is like SASS ZIG INSTEAD OF ZAG! Too late</li>
                <li>Corpo feels the concern and is horny</li>
                <li>So Sass Zigs instead of Zag and shoots him in the human male dick...</li>
                <li>As he is about to kill him via a dick shot...</li>
                <li>Time freezes and a young monk with Canaries is standing by the guy...Sass doesn't ID him but thinks he is handsome</li>
                <li>Greosass what are you killing this man? Is that Justice and Mercy?</li>
                <li>Sass: "It is justice but probably not mercy"</li>
                <li>Bahamut: "I don't think that is even justice...."</li>
                <li>Bahamut: "You want to take this arrow back and zig instead of zag? My champion must be careful"</li>
                <li>Arrow returns to THE NEWLY APPOINTED WAR PRIEST</li>
                <li>She sees Sass and is excited! You are back! You can fly?</li>
                <li>You've been laying SOOOOO many bricks</li>
                <li>Sass does a deception check that the arrow was a bird....and....gets a 2.</li>
                <li>She is like what you jealous he talked to me? Sass gives an honest answer that he is insecure and last relationship ended up betrayed and he killed her</li>
                <li>She is like I know I was a Ho but I'm not any mo'. She is like I am into this, you got to trust me, I know it is hard.</li>
                <li>She says I've been laying brick by myself, Sass is like I don't want you to lay brick by yourself anymore</li>
                <li>MIKE thinks this is so weird MAKE CLIP 27ish</li>
                <li>Sass pauses the bond...disconnect to wifi</li>
                <li>Corpo is horny, confused, and has a sock puppet</li>
              </ul>
              <li>Cecil checks on the library and the bookmobile</li>
              <ul>
                <li>Solona-mobile are great successes</li>
                <li>Cecil asks what they need</li>
                <li>Says kids love Kord goes punch and Kord goes smash</li>
                <li>Cecil gives them a 1K gold for knowledge....to name the mobile vans IN IOUN'S NAME</li>
                <li>Ioun is quite pleased</li>
                <li>Lars is against literacy</li>
                <li>Cecil sends message to Sparka...before we talk to Dorrissa the two should talk</li>
                <li>Sparka goes to librsry...Cecil like what we do with the new reality crystal</li>
                <li>Sparka is like....Arixus is a friend?</li>
                <li>Sparka like what you doing in the morning?</li>
                <li>Cecil like spreading knoweldge</li>
                <li>Sparka like want to come seal a succubus?</li>
                <li>Ceicl like sure got nothing else</li>
                <li>Sparka like any luck with your mom scrying?</li>
                <li>Cecil like yeah Hadrian floating in the Air Plane...all beat up</li>
                <li>Cool</li>
              </ul>
              <li>CENTRAL TAVERN</li>
              <ul>
                <li>Sparka arrives to see parents</li>
                <li>Sass and the fang are Fanging all night</li>
                <li>Sparka sees Maisie...his parents cut him off</li>
                <li>Semla is like no no no you aren't eating. Losing weight. Get the pork fat!! NOW! PORK RINDS</li>
                <li>Maisie will leave, no women like skinny boy</li>
                <li>Lars gets the parents to come to the bar...says catch up with Maisie</li>
                <li>Some guy tries to blame the waitress for spilling something and Semla goes to brow beat him</li>
                <li>Maisie like they are nice, Sparka is grateful</li>
                <li>Lars gives them strong drinks...they aren't sure whom sending but assume</li>
                <li>Maisie says she has checked on Wildon...it is fantastic...</li>
                <li>Spraka is surprised</li>
                <li>Maisie says he got a fairy Artificer and off world union rules are weird</li>
                <li>She is busy with learning but his parents are giving her lot of food</li>
                <li>They covered for a person at the bar who was sick and best food of the year</li>
                <li>She is afraid to leave his parents like they might think she hates them so she tries to hang in each room they are in like on the edge</li>
                <li>Karvin was like you may leave</li>
                <li>Sparka talks to Maisie about who the broker could be</li>
                <li>Sparka is on Halia but Maisie is like probably someone with multi planar abilities</li>
                <li>She is not sure the Zhentarim have planar contacts....more just when blood is on the street buy property types</li>
                <li>Sass and Lars and Corpo are completely exhausted at them not boing</li>
                <li>Lars tries to offer them the penthouse to bone</li>
                <li>Corpo has the sock puppet saying don't be repressed or something</li>
                <li>Maisie is like ah your friends are back...Sparka is like they are a bit much yes?</li>
                <li>Sass and the Fang show up DRESSED TO THE 9S...Tiger Woods/Ron Swanson Red Polo</li>
                <li>They go for a walk</li>
                <li>Semla walks up to Lars and gives him a stare....Karvin walks up, buys him a shot...says next morning you will feel guilt and take a massive shit but will be ok later...happens to everyone</li>
                <li>Sass and Razorfang goes to ask Lars if they do it</li>
                <li>Corpo goes to the VIP lounge with the sock puppet</li>
                <li>Sparka and her walk...says glad he is back</li>
                <li>Her mother was a woman of the night in Candlekeep who later was a cook and her father is an unknown elf</li>
                <li>They hold hands and walk back</li>
                <li>I once again can't hear what Mike said that was the line</li>
              </ul>
              <li>IN MORNING they don't feel like PDiddy as they go to summon succubus</li>
              <ul>
                <li>Don't tell Lars and Sass who are MORNING FANGING (not each other)</li>
                <li>Get there and Arixus was up all night but is ready!</li>
                <li>Cecil asks about Chrono-Reality Crystals</li>
                <li>He is like they are super rare! Were tracking two? Now seems like one?</li>
                <li>Explains how the Chrono Reality Crystals are quintessential time/space...fragments of the platonic ideal of time and space that were used to create the universe...the other 4 elements have them too</li>
                <li>They are like...would you hand over to the Imperium RIGHT AWAY if you got one?</li>
                <li>He hem haws</li>
                <li>Sparka is like what would the Imperium do with it?</li>
                <li>He is like we don't know haven't had many</li>
                <li>Explains a frustrating thing about Empire is the concern less of what you can do with something and more fear of what someone else could do with it...so reactive defensive. Risks are amplified the more people and organization you have</li>
                <li>Is there a time plane? Material Plane?</li>
                <li>Fire shard is something charged with pure fire but not quintessential fire</li>
                <li>Circle is under the rug but need the blood. Blood on the table</li>
                <li>START THE RITUAL</li>
                <li>After 30 seconds she shows up</li>
                <li>She is like wait you told me never to come back</li>
                <li>Corpo like that was him not me.</li>
                <li>Corpo asks her name..Drusilla</li>
                <li>Corpo like remember when we saw you last and I traded you a dagger? I want it back since it was last thing from my father now</li>
                <li>Succubus gave it to her boss...doesn't have it. She got a promotion</li>
                <li>They ask boss name</li>
                <li>Succubus is like I can arrange meeting but not supposed to say name</li>
                <li>He put it in a vault...can't get it back</li>
                <li>Group insights can't tell if fully truthful</li>
                <li>NAT 1 Sparka Insight if her boss is big deal</li>
                <li>Asks if she has changed boss...she says nope...he is a biiiiig deal</li>
                <li>She is pushing to call him but they are like nah. She gives them a different sigil</li>
                <li>Sparka is like is he good with his boss? She sassily responds oh yeah</li>
                <li>She is like see you later! Sparka pulls back the rug and she is like whyyyyyyyy</li>
                <li>Sparka like cause you couldn't get us what we want</li>
                <li>Sparka is an expert at negging..her like others are above her...that she isn't that great</li>
                <li>It works and she is like MY BOSS DOESN"T HAVE A BOSS</li>
                <li>Corpo gets that must be Asmodeus</li>
                <li>They start pushing her more and mocking her</li>
                <li>BEN FUCKING RAHIMI HAS ARRIVED to DND =&gt; They debate if they talk to Asmodeus or not...since right now she is trapped so he can't talk to her.</li>
                <li>Sparka is like let's talk in front of her to fuck with her</li>
                <li>She mocks Sparka and he is like you are insecure and stupid</li>
                <li>They are like lets call your boss and lie about the terms of the deal</li>
                <li>Corpo talks with the sock puppet</li>
                <li>Corpo notes she is a little nervous...she is silent</li>
                <li>Corpo sock puppet says lets go Cecil</li>
              </ul>
                <li>NAT 20 Lars Athletics Morning Fanging with Thistle</li>
                <li>Razorfang is having a 14 level Fang</li>
                <li>They go to next room to call Asmodeus</li>
                <ul>
                <li>They are like wait wtf we do</li>
                <li>Arixus is like THIS IS AMAZING YOU ARE OFF THE FUCKING CHANGE</li>
                <li>They are like cool lets call Asmodeus....</li>
                <li>Sparka notes he is not infinitely powerful...maybe interested in Fire Shard</li>
                <li>The do it...</li>
                <li>RITUAL HAPPENS AND THEY ARE IN A WHITE PLAIN SPACE</li>
                <li>Asmodeus is like Drussilith is welching on a bet</li>
                <li>Corpo TALKS TO ASMODEUS WITH A SOCKPUPPET</li>
                <li>Sparka says Drussillith gave tons of details on you. Says they broke a deal with us and we would like to trade back for the dagger</li>
                <li>He laughs and is like...we work in souls what you got</li>
                <li>NAT 20 Corpo Persuasion to convince Asmodeus to play ball</li>
                <li>They offer the Fire Shard</li>
                <li>They say Drusillith didn't uphold her end...she gazed on the girth of the Dragon and did not comply</li>
                <li>Asmodeus doesn't believe them</li>
                <li>MAKE CLIP 1:27:05ish</li>
                <li>Asmodeus is like sure I can give the dagger but not the thing it is attached to it.</li>
                <li>THAT is of cosmic importances</li>
                <li>Ben: "SHIT"</li>
                <li>He explains that the gem is the seed of chaos that formed the abyss and could be used for MANY THINGS</li>
                <li>Asmoedus makes a perfect replica of the dagger</li>
                <li>He says ok perfect replica of the dagger in exchange for the fire shard</li>
                <li>Corpo bluffs that there was a deal for seeing the well endowed</li>
                <li>He scries on Lars having sex and is like well it is large</li>
                <li>He shows the scene...there was no deal</li>
                <li>Cecil is like wait what about the gem? Asmodeus is like yep</li>
                <li>Seed of Chaos from alternate universe Tharizdun used to create the Abyss and firmly created many of the planes</li>
                <li>Tel Amoth stole it from demons and Asmodeus will never give it back to them since it would allow the Abyss to expand and be FAR more powerful</li>
                <li>He gives dagger into never mentioning Lars' penis again. Also giving them info into why Haalinith wants it....and deal</li>
                <li>Checks his day planner (a human's flesh that he turns like a book) and says she has no appt for hours she can stay</li>
                <li>Sparka asks where he stands on Tiamat coming back. Calls Tiamat his guard dog to help fight demons so he wants her to stay there</li>
                <li>Corpo asks if the gem has grown in luster...lust of course cause it is hell. But not getting anything left</li>
                <li>Says if they piss him off he will make Jazelle appear next to Sass and Fang, Fanging for a minute</li>
                <li>Asmodeus will make the deal to free Jazelle in a better deal</li>
                <li>Cecil is like you are calm about trying to free Tiamat...</li>
                <li>Asmodeus is like they have tried many times over last 8K years...usually doesn't work</li>
                <li>Has to go, meeting about plucking eyeballs</li>
                <li>Corpo gets the dagger</li>
                <li>The DM is floored it worked</li>
                <li>They taunt Drussilla and leave her</li>
              </ul>
            </ul>
          </Collapsible>

          <Collapsible
            title="Family Reunions and Revelations (BUNKER HILL) (#38 - 8/19)"
            toggle={this.collapseToggle.bind(this)}
            kind="session38"
            show={this.state.show}
          >
            <ul>
              <li>Recapping</li>
              <li>Bridget and Lydia walk by and chat</li>
              <li>Mike is like WHHHOOOOOA BRIDGET</li>
              <li>Ryan is drunk</li>
              <li>Bartender is...slow....</li>
              <li>Mike is talking about being wet</li>
              <li>In the Tavern</li>
              <ul>
                <li>Lars in the Tavern gets a 100 Gold challenge going</li>
                <li>Sass bets on Lars 100 gold</li>
                <li>Lars starts WINNING and Sass doubles his winnings</li>
                <li>Lars donates gold to orphans</li>
                <li>Razor fang is glowing</li>
                <li>Cecil works with mother to scry on Hadrian and sees him</li>
                <li>Sees Hadrian in Catacomb-like area going to talk to his father...father I can explain...</li>
                <li>Sees him hesitantly walk under an arch divination spell blocked on the other side</li>
                <li>Catacombish arch has an ancient circle with a sword going down...notes the family crest of Clan Kinaphalic</li>
                <li>Wine on top of bourbon is a hangover in a glass</li>
                <li>Ben rips ass</li>
                <li>Man the drinks are slow</li>
                <li>Sparka demands they increase the drink strength...I mean...Robert</li>
                <li>Ben is a people pleaser except at work where he throws his weight around</li>
              </ul>
              <li>Head to meet with Dorrissa</li>
              <li>Meet with Dorrissa</li>
              <ul>
                <li>News from Air Plane...they sent a fast ship to ascertain what happened in Cinderhaven....</li>
                <li>Wanted to talk to the "Wind King" as he is calling himself now</li>
                <li>BUT They found the place ruined</li>
                <li>Haalinith decalred Cinderhaven a foreign influence open to trade with others and home to many non-Air Planers...so...</li>
                <li>Destroyed the shard...the magic holding it steady and had it drifted into the Plane of Ash border and the inhabitants killed</li>
                <li>The party are like wait what?</li>
                <li>Haalinith had moved along to the Citadel of Ice and Steel and is besieging the Caliph of the Djinn himself</li>
                <li>Cecil asks what the Empire thinks of this</li>
                <li>The Empire is concerned...but also it is a long way away....</li>
                <li>....we installed him says Cecil....Sparka like wait...</li>
                <li>No the Empire asked us to be there so YOU installed him Dorrissa Cecil wildly accuses</li>
                <li>Corpo insults her face again</li>
                <li>SO SHE CALLED IN A Pirate wizard...clear drunk</li>
                <li>He owes Black Storm a favor...He plucks a crystal and creates a Corpo Simulcrum out of snow</li>
                <li>Corp2 has a 4inch...so massive.</li>
                <li>Corpo catches Corp2's hand as he goes to nut tap...instead pulls him closer.</li>
                <li>Of course he likes it cause he's Corpo</li>
                <li>Corp2 then just runs and jumps out a window and flies away</li>
                <li>Black Storm told Dorrissa said best way to teach a lesson is to meet himself</li>
                <li>Corpo deflates</li>
                <li>She then says triangulating Severin's teleportation two places in the Pentarchy...</li>
                <li>Cecil with Scrying notes that Severin would be in the Ruins of Kinther</li>
                <li>She shows the map...</li>
                <li>Sparka pulls a Buster Bluther and can't figure out the water on the map</li>
                <li>Notes that Kinther was rumored to be built on a source of dangerous dragon power</li>
                <li>Ben goes to get a drink...Adam takes his phone and figures out Chris Smith is from Willis Tower</li>
                <li>Robert says text Julie a dick emoji and Ryan adds drops</li>
                <li>They instead texting Liz saying they miss her but were going to call her Lizzo</li>
                <li>Staff asks if we want music and Robert says no, Mike says yes, Robert is like we are supposed to be listening...Mike is like...wait....whaaaaaaaa? LISTEN TO THE DM?</li>
                <li>Dorrissa is like ok so...we can arrange getting there....</li>
                <ul>
                  <li>Teleporting but is SUPER risky</li>
                  <li>Boat behind the lines</li>
                  <li>The Underdark</li>
                </ul>
                <li>She hesitates about the Underdark</li>
                <li>NAT 1 Cecil Arcana Check on the Underdark</li>
                <li>Ben returns and Adam wants credit for not texting dick picks on his phone</li>
                <li>They know the Underdark is super dangerous....Lars find Druegar cool but then unnatural cause pale</li>
                <li>It's hard to control cause vertical up and down...</li>
                <li>I have to recap everything for Ben</li>
                <li>Mike's shorts are so high up Ben can see his pumpkins and Ryan his squash....they note Bridget isn't here....</li>
                <li>Mike then just walks away as I am giving info about his family's ancestral castle</li>
                <li>She is like....YOU WILL find trouble on a boat but not as crazy...</li>
                <li>Underdark is either smooth or HOLY SHIT</li>
                <li>The goal is to figure out what Severin up to BUT killing him and Hadrian be also good</li>
                <li>Ray bet Robert would be passed out by 6</li>
                <li>TEAM YOLO is excited for the Teleporting....</li>
                <li>Cecil a boat</li>
                <li>Mike then ditches</li>
                <li>"Mike gets horny as fuck when drinking" Ben</li>
                <li>"GET AWAY FROM HER! (Bridget)" Robert</li>
                <li>Sparka and Cecil are like...yeah...it's our call</li>
                <li>Cecil and Sparka are like Cecil are teleportation too dangerous....only 25% chance on target</li>
                <li>Meta to run into shit in the Underdark and XP!</li>
                <li>Sparka pushes that they can go through the Underdark as good cause they get right into the city</li>
                <li>THEY VOTE CHOOSE THE UNDERDARK To get the high ground by going low ground</li>
                <li>"Mike has Fuck me eyes" According to Ben</li>
                <li>IT'S GETTING HOT IN HERE</li>
                <li>Arixus can go with</li>
                <li>NAT 1 Lars Asking for GDE to go with them</li>
                <li>Corpo hears women screaming...is told HE is doing it...actually is Corp2</li>
                <li>Sparka puts on the Dragon Mask to try and help convince her</li>
                <li>They establish Arixus isn't the best in war...but said he would go with</li>
                <li>Cecil gets a 30 Persuasion to ask for Dorrissa's favor, offers a scarf</li>
                <li>"Arixus likes to come out of the city" Sparka</li>
                <li>"Erectus does like to cum" Sass</li>
                <li>Ben and Mike just fuck off and leave</li>
                <li>Dorrissa says ok meet in the morning</li>
              </ul>
              <li>Sparka stays to talk to Dorrissa</li>
              <ul>
                <li>Asks what the end game is...the Cold War with the Pentarchy</li>
                <li>She is like we are holding the line....well...general plan is to stabilize the borders....THEN attack</li>
                <li>Sparka is like what a waste of lives...what could turn the side...</li>
                <li>She is like well stopping him...Tiamat BAD...also they are burning a lot of resources on it</li>
                <li>Discuss how they could unite other lands under the banner of stopping Tiamat</li>
                <li>Once you start winning...people start trying to divide it up</li>
                <li>Sparka asks if anyone in the Empire would want them to succeed to help rally people?</li>
                <li>She admits some might want to START the process but to lose to Tiamat in the end would be bad</li>
                <li>He asks who work with them...she says the Dragonborn groups hate each other but they manage to keep them apart....have worked with Hobgobs before but is enemy of my enemy</li>
                <li>Sparka is like thank you, helpful for me to understand...</li>
                <li>She warns that if you talk to Dragonborn realms DONT mention going to Kinther....they like NOT being under the Kinaphalics anymore</li>
                <li>Sparka is like well Sass is the heir to that...since they would not want to serve again...probably kill him so be careful</li>
                <li>The empire would like the Dragonborn to unite but very complicated</li>
                <li>"Mike trips over his boner"</li>
                <li>Sparka we didn't sign up for any of this....she's like I started just capturing kidnappers</li>
                <li>Sparka just wants to find parents...is like hey you know that's my name up there...</li>
                <li>NAT 20 Sparka Persuasion to get Dorrissa to spill beans on his mother</li>
                <li>Dorrissa puts up silence spell around them and says she only still works for the Inquisition cause his mother told her to</li>
                <li>Reveal she works for the  with Amali secretly...doesn't know much about her since memory fun but ya know</li>
                <li>She is a champion of Avandra Chaotic Good Changebringer</li>
                <li>Sure she is a greatest dragonslayer but that's only a piece of it</li>
                <li>Sparka asked if she knows he is there...mother never mentioned him</li>
                <li>Corpo somewhere mutters YOU ARE NEGLECTED TO!</li>
                <li>Amali communicates with Dorrissa via GDE and wipes his mind</li>
                <li>She believes in the Empire...but when things need to be done Amali does them</li>
                <li>Sparka thinks the reality crystals must make her modify memory or level 9 that's much in</li>
              </ul>
              <li>Nat 20 Sparka Perception Check to notice changes to Phandalin</li>
              <li>Nat 20 Cecil Perception Check to notice changes to Phandalin</li>
              <li>Nat 1 Corpo Perception Check to notice changes to Phandalin</li>
              <li>Phandalin Status</li>
              <ul>
                <li>Wildon is like we are 17.5 gold over budget BUT NOT BEHIND SCHEDULE</li>
                <li>He says timeline non-full arcane infused protection walls and stuff in a month....arcane defense couple months after that</li>
                <li>Razor Fang is following along....Wildon is like BEST MASON!</li>
                <li>Isn't sure if union/guild rules apply in a different world?</li>
                <li>Shows some charts</li>
                <li>Sparka is like...I am impressed...not what I expected</li>
                <li>Wildon winks with both eyes....too many smoke breaks on the north side and goes that way</li>
              </ul>

              <li>Sass flirts with Razorfang and Mike can't handle DM's rizz</li>
              <li>Sparka goes and talked to adopted parents in the tavern who are helping people</li>
              <li>He says amazing...AMAIZZZZING</li>
              <li>Sparka asks if they have seen reality crystals before....they say no</li>
              <li>He asks about if they recall anyone else the night before....says just a knock but otherwise he was just there</li>
              <li>NO INSIGHT CHECK</li>
              <li>"Hear that? The noise of sexual tension" Ben</li>
              <li>"There is not sexual tension between me and my adopted parents" Robert</li>
              <li>He takes adopted dad on walk to get advice on talking or not to adopted father but he's not going to tell him one way or the other</li>
              <li>He is noncommittal BUT gives a maybe best to not</li>
              <li>Lars in Tavern...barkeep is like wow....weird...someone came to a bar and order a pint of milk</li>
              <li>Lars sends bro a large tankard of ale</li>
              <li>Lars is like you are in a tavern WHAT ARE YOU DOING!</li>
              <li>He is like I said you don't go but YOUR FRIENDS WENT</li>
              <li>Shows minor illusion of him killing the cinderhaven survivor...killing off</li>
              <li>He shows the list of him killing people....Lars is like you have kids out there to kill you? He says no I'll be careful</li>
              <li>Lars doesn't catch list...there is one more who survived....in a way...now here to kill grandpa </li>
              <li>Hand goes to sword....you going to stop me little brother</li>
              <li>ROUND 1</li>
              <ul>
                <li>CECIL</li>
                <ul>
                  <li>
                    Vicious Mockery Fails
                  </li>
                </ul>
                <li>LARS</li>
                <ul>
                  <li>RAGE and Reckless Attack brother. Hits for 19 but takes armor of ag damage</li>
                </ul>
                <li>Dreadflame</li>
                <ul>
                  <li>Runs away to kill gpa...Attacks of Opp mostly miss but Corpo hits</li>
                  <li>Casts Spirit Shroud with souls of those he has killed and kept in his sword</li>
                </ul>
                <li>CORPO</li>
                <ul>
                  <li>Does lightning strike across bar to do 39 damage to Dreadflame</li>
                  <li>OH also kills 12 people</li>
                  <li>Corpo will commit "ALL the war crimes if he has to kill Milk Drinker</li>
                </ul>
                <li>SASS</li>
                <ul>
                  <li>Misses Precise Strike</li>
                  <li>NAT 1 Sass Longbow shooting Dreadflame</li>
                  <li>Ben bitches that Sass didn't hurt people BUT we are like he cares so much for the little guy he took the sharpshooter feat. Mike's blood rises</li>
                </ul>
                <li>SPARKA</li>
                <ul>
                  <li>Takes a shit for like 10 minutes</li>
                  <li>Ben says homeless people have no value of life...see Mike?</li>
                  <li>Water to corpse/shit ratio discussion</li>
                  <li>PUTS ON THE MASK...tells Semla to go back into the bar</li>
                  <li>Sparka tried Polymorph but LR resist</li>
                </ul>
              </ul>
              <li>ROUND 2</li>
              <ul>
                <li>CECIL</li>
                <ul>
                  <li>Debates hurting Corpo too but...wouldn't hurt an innocent person...even though Corpo isn't</li>
                  <li>Psy lnaces to force a LR</li>
                  <li>Loses concentration on Spirit Shroud</li>
                  <li>Gives BI to Sass</li>
                </ul>
                <li>LARS</li>
                <ul>
                  <li>Action Surge to charge and Reckless Attack</li>
                  <li>Damage</li>
                </ul>
                <li>Dreadflame</li>
                <ul>
                  <li>Attacks with Blackrazor on Grandpappy</li>
                  <li>Stares at Lars and Howls as he Shifts into a Werewolf...which they note is controlled</li>
                </ul>
                <li>CORPO</li>
                <ul>
                  <li>Punches werewolf Dreadflame once otherwise misses</li>
                </ul>
                <li>SASS</li>
                <ul>
                  <li>Shoots at Dreadflame</li>
                </ul>
                <li>SPARKA</li>
                <ul>
                  <li>TWIN haste Lars and Corpo</li>
                  <li>TK shove towards Lars</li>
                </ul>
              </ul>
              <li>ROUND 3</li>
              <ul>
                <li>CECIL</li>
                <ul>
                  <li>Attaboy to Sass</li>
                </ul>
                <li>LARS</li>
                <ul>
                  <li>RECKLESS attack bro multiple times</li>
                </ul>
                <li>Dreadflame</li>
                <ul>
                  <li>Frothy mouth...is it rabies? Is it spoiled milk?</li>
                  <li>ATTACKS Grandpappy...slays him and takes his soul torn into his sword</li>
                  <li>Hits Lars twice</li>
                </ul>
                <li>CORPO</li>
                <ul>
                  <li>NAT 20 Corpo punches Dreadflame</li>
                  <li>He just keeeeeps hitting with insane rolls</li>
                  <li>TRIGGERS Contingency to teleport him away</li>
                  <li>Cecil tried to Counter Spell...another tried to Counterspell but he counterspells that</li>
                </ul>
              </ul>
              <li>They look around..can't tell...Though Sparka knows probably subtle spell</li>
              <li>Lars is weeping</li>
              <li>Sass is looking to Razor Fang</li>
              <li>Cecil and Sparka with Insight...catch Sparka Semla looking at the counter spell and disappearing into the crowd</li>
              <li>Maisie and guards are like WTF with the line of dead bodies</li>
              <li>Sparka and Corpo go chase Semla down</li>
              <li>Razorfang is freaking out...Sass calming her down</li>
              <li>Lars cries and then goes up stairs to thistle</li>
              <li>Corpo catches up to her and ITS HIS THE GMA THE BLACK STORM</li>
              <li>"ARE YOU FUCKING KIDDING ME?"</li>
              <li>She goes invisible...then he grabs her...GREAT COMMA GRANDMA</li>
              <li>I DO THE FUCKERY! THAT'S MY THING! I KILLED 10 PEOPLE TODAY! THEY WERE SAN FRAN HOMELESS WHO SHIT OFF BRIDGES</li>
              <li>She thundersteps away and Corpo takes some thunder damage</li>
              <li>"What if she was posing as fucking shit???"</li>
              <li>Corpo tells Sparka.... "WHAT!"</li>
              <li>"It's all coming together"</li>
              <li>Maisie says the person was very stealthy and rolled a NAT 20</li>
              <li>Maisie goes to get drinks and is away from the table</li>
              <li>Cecil has mother scry on the mushroooms owner....in the crystal ball they see the table</li>
            </ul>
          </Collapsible>

          <Collapsible
            title="Fork in the Road BUNKER HILL: First Blood (#39 - 9/13)"
            toggle={this.collapseToggle.bind(this)}
            kind="session39"
            show={this.state.show}
          >
            <ul>

            </ul>
          </Collapsible>

          <h2>
            <br/>
            Part ?: Long Live the Queen
            <br/><br/>
          </h2>
        </div>
      </div>
    )
  }
}

export default Home;
