import React                 from "react";
import { Link }              from "react-router-dom";

import factionsData          from "../../data/factions";


class Factions extends React.Component {
  allowedChars(chars) {
    return Object.keys(chars).filter((locationKey) => {
      return chars[locationKey].enabled;
    });
  }

  render() {
    return (
      <div className="standard-panel">
        <h1>
          Factions
        </h1>

        <div className="divisor">
          <h2>
            Encountered Factions
          </h2>

          {
            this.allowedChars(factionsData.encountered).map((factionKey, indy) => {
              return (
                <div
                  key={indy}
                  className="index-listing"
                >
                  <Link to={`/pages/${factionKey}`}>
                    <img
                      src={factionsData.encountered[factionKey].src  || "https://toppng.com/uploads/preview/location-png-icon-location-icon-png-free-11562933803vththezlcl.png"}
                      alt="character-thumb"
                    />

                    <div className="info-panel">
                      <h3>
                        {factionsData.encountered[factionKey].name}
                      </h3>

                      <span>
                        {factionsData.encountered[factionKey].tagLine}
                      </span>
                    </div>
                  </Link>
                </div>
              )
            })
          }
        </div>


        <div className="divisor">
          <h2>
            Others
          </h2>

          {
            this.allowedChars(factionsData.notMet).map((locationKey, indy) => {
              return (
                <div
                  key={indy}
                  className="index-listing"
                >
                  <Link to={`/pages/${locationKey}`}>
                    <img
                      src={factionsData.notMet[locationKey].src  || "https://toppng.com/uploads/preview/location-png-icon-location-icon-png-free-11562933803vththezlcl.png"}
                      alt="character-thumb"
                    />

                    <div className="info-panel">
                      <h3>
                        {factionsData.notMet[locationKey].name}
                      </h3>

                      <span>
                        {factionsData.notMet[locationKey].tagLine}
                      </span>
                    </div>

                  </Link>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}

export default Factions;
